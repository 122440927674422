import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Injectable,
  HostListener,
  ElementRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { Material } from 'src/app/interfaces/material.interface';
import { User } from 'src/app/interfaces/user.interface';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { UtilitiesService } from 'src/app/services/generic/utilities.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { DownloadFileService } from 'src/app/services/generic/download-file.service';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import * as FileSaver from 'file-saver';
import { TableManagerComponent } from 'src/app/components/table-manager/table-manager.component';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal-table/modal-table.component';
import { columnsStyle } from 'src/app/components/table/table.interface';
import {
  DynamicData,
  NetOpenPoData,
} from 'src/app/interfaces/netOpenPo.interface';
const INDEX_INTRO_TABLE = 0;
const INDEX_MIDDLE_TABLE = 1;
const INDEX_END_TABLE = 2;

const INDEX_ATS = 0;
const INDEX_MLS = 1;

@Component({
  selector: 'app-ppm',
  templateUrl: './ppm.component.html',
  styleUrls: ['./ppm.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class PpmComponent implements OnInit {
  @ViewChild('tableManager') tableManager:
    | TableManagerComponent
    | undefined
    | any;
  @ViewChild('dialogConfirmationDiscardSaveChanges')
  dialogConfirmationDiscardSaveChanges: any;
  viewButtonSave: boolean = true;
  translateYAbsoluteItem: number = 0;
  offsetTopSubTotal: number = 0;
  crudService: CrudService<Material>;
  downloadFileService: DownloadFileService<any> | undefined;
  setCrudService: any;
  putCrudService: any;
  filter: any = {};
  searchInputs: InputBase<string>[];
  tooltipMessage: String = 'Export';
  disabledButtonExport: any;
  showNoResults = false;
  showTable = false;
  geosEntities: { [key: string]: any } = {};
  countriesEntities: { [key: string]: any } = {};
  loadedDataPPM: any;
  origins: any[] = [];
  dataSourceTable: any[] = [];
  valuesTotalAllocation: { col: string; value: number }[] = [];
  valuesSubMlsAndTotalAllocation: { col: string; value: number }[] = [];
  valuesMLSsummarized: { col: string; value: number }[] = [];
  valuesDelta: { col: string; value: number }[] = [];
  valuesSubstraction: { col: string; value: number }[] = [];
  valuesSubTotal: any = [{}];
  valuesMLSexpanded: { col: string; value: number; origin: string }[] = [];
  valuesTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  valuesSubMlsAndTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  valuesMLSsummarizedExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  valuesDeltaExpanded: { col: string; value: number; origin: string }[] = [];
  valuesSubstractionExpanded: { col: string; value: number; origin: string }[] =
    [];
  lastValueDistribution: {
    col: string;
    value: number;
    customerShortId: string;
  }[] = [];
  atsAndNop: any;
  //table last page
  loadedDataPPMTableVisible: boolean = false;
  loadedDataPPMTableshowMenuColumns: boolean = true;
  loadedDataPPMTableTab: string = 'To Book';
  loadedDataPPMTableThead: any = [
    // Author: Ronaldo Reis
    // Date: 03/01/2023
    // Obs: Temporarily hiding for production
    // {
    //   label: 'Allocation',
    //   thead: [
    //     [
    //       {
    //         label: 'PPM',
    //         key: 'ppm',
    //       },
    //       {
    //         label: 'Origin',
    //         key: 'origin',
    //       },
    //       {
    //         label: 'Customer Short',
    //         key: 'customerShort',
    //       },
    //       {
    //         label: 'Channel',
    //         key: 'channel',
    //       },
    //       {
    //         label: 'Released Allocation',
    //         key: 'releasedAllocation',
    //       },
    //       {
    //         label: 'Total Quarter',
    //         key: 'totalQuarter',
    //       },
    //       {
    //         label: 'Missing',
    //         key: 'missing',
    //       },
    //       {
    //         label: 'NET Open PO',
    //         key: 'netOpenPo',
    //       },
    //       {
    //         label: 'To Book',
    //         key: 'toBook',
    //       },
    //       {
    //         label: 'Overbooked',
    //         key: 'overBooked',
    //       },
    //       {
    //         label: 'Booked',
    //         key: 'booked',
    //       },
    //       {
    //         label: 'Sell IN',
    //         key: 'sellIn',
    //       },
    //       {
    //         label: 'Average ACT',
    //         key: 'averageActivate',
    //       },
    //       {
    //         label: 'WOI',
    //         key: 'woi',
    //       },
    //       {
    //         label: 'WOI PREDICTION',
    //         key: 'woiPrediction',
    //       },
    //       {
    //         label: 'SIC',
    //         key: 'sic',
    //       },
    //     ],
    //   ],
    // },
    {
      label: 'To Book',
      thead: this.getTheadFromToBook([]),
    },
    {
      label: 'Booked',
      thead: this.getTheadFromDataPPM(this.filter),
    },
    {
      label: 'Sell In',
      thead: this.getTheadFromDataPPM(this.filter),
    },
  ];
  loadedDataPPMTableData: any = [];
  enableApplyFilter = false;
  formSearchValues!: { [key: string]: any };
  isEditTableMode = false;
  isDiscardChanges = false;
  isSubTotalEmpty: boolean = true;
  keysFilters = [
    'ppm',
    'orderAdminId',
    'salesManagerId',
    'customerShortId  ',
    'origin',
    'fiscalYear',
    'quarter',
    'geoId',
    'countryId',
  ];
  eventTable!: string;
  messages: { [key: string]: string } = {
    save: 'Do you confirm new changes on allocation?',
    discard: 'Are you sure you want to exit before saving your changes?',
    export: 'This process will take a few minutes. Do you confirm?',
  };
  messageModalChanges!: string;
  clickScreenSub!: Subscription;
  tabs: { [key: string]: number } = {
    ALLOCATION: 0,
    'CUSTOMER ANALYSIS': 1,
    'WOW ANALYSIS': 2,
    'WOW TARGET': 3,
    'MARGIN RANKINGS': 4,
  };
  selectedTabIndex = 0;
  noFilterSelected = true;
  allocationLabels: { [key: string]: any } = {};
  productDescription!: string;
  currentUser!: any;
  isLoading = false;
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.allocationRetail },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.allocationRetail },
    },
    {
      entity: 'customer-short',
      query: {
        status: true,
        sort: 'customerShort',
        pageName: PAGE_NAME_RESOURCE.allocationRetail,
      },
    },
    {
      entity: 'material/products/summary',
      query: { pageName: PAGE_NAME_RESOURCE.allocationRetail },
    },
    {
      entity: 'user',
      query: {
        status: true,
        order_admin: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.allocationRetail,
      },
    },
    {
      entity: 'user',
      query: {
        status: true,
        sales_manager: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.allocationRetail,
      },
    },
    {
      entity: 'setupManagement/currentSetup',
      query: { pageName: PAGE_NAME_RESOURCE.allocationRetail },
    },
  ];
  totalToBook: any = 0;
  disabledSaveButton: boolean = false;
  hasPermissionCreate!: boolean;
  simpleViewOrNot: boolean = true;

  constructor(
    protected http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: ModalActionsService,
    private utilitiesService: UtilitiesService,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    public dialog: MatDialog
  ) {
    this.filter = {
      quarter: this.getQuarterFromDate(new Date()),
    };
    this.filter = {
      ...this.filter,
      fiscalYear: this.checkCurrentYearAndQuarter(),
      pageName: PAGE_NAME_RESOURCE.allocationRetail,
    };
    this.crudService = new CrudService<Material>(this.http, '');
    this.setCrudService = new CrudService<Material>(
      this.http,
      'allocation-customer/calculate-taxes-values'
    );
    this.putCrudService = new CrudService<Material>(
      this.http,
      'allocation-customer'
    );
    this.downloadFileService = new DownloadFileService<any>(this.http);
    this.searchInputs = [
      new SelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        classCss: 'full-width-panel-customer-short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        classCss: 'full-width-panel-sales-manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Fiscal Quarter',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        disabled: true,
        hasAutocomplete: true,
        hasCheckFlowException: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        disabled: true,
        hasAutocomplete: true,
        hasCheckFlowException: true,
        notClearable: true,
      }),
    ];
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('allocation-retail');
    });
  }

  ngOnInit(): void {
    [this.currentUser] = this.authService.getUserPermissions();
    this.getDataFilter();

    this.disabledButtonExport =
      this.getExportStatus() && this.getExportStatus()
        ? this.getExportStatus()
        : false;
  }

  ngAfterViewInit() {
    this.toggleBreadcrumb(false);
    this.clickScreenSub = this.utilitiesService.documentClickedTarget.subscribe(
      (target: any) => this.verifyClickChangeRoute(target)
    );
  }

  ngOnDestroy() {
    this.toggleBreadcrumb(false);
    this.clickScreenSub.unsubscribe();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const offsetTopElement =
      this.tableManager?.tableDynamic.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
    const tableHeight =
      document.getElementsByClassName('table-dynamic')[0]?.clientHeight;
    const showButtonCancel = tableHeight - 600;

    if (this.translateYAbsoluteItem >= showButtonCancel) {
      this.viewButtonSave = false;
    } else {
      this.viewButtonSave = true;
    }
  }

  getDataFilter() {
    const countryFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const currentYear = new Date().getFullYear();
    const fiscalYearFilter: any[] = [];
    const geoFilter: any[] = [];
    const ppmFilter: any[] = [];
    const orderAdminsFilter: any[] = [];
    let quarterFilter: any[] = [];
    const salesManagerFilter: any[] = [];
    let customerShortEntities: any;
    let materialsEntities;
    let orderAdminsEntities;
    let salesManagersEntities;
    let setupEntity;
    this.spinner.show();
    this.crudService
      .getDataFilters(this.entities)
      .subscribe((response: any) => {
        [
          this.geosEntities,
          this.countriesEntities,
          customerShortEntities,
          materialsEntities,
          orderAdminsEntities,
          salesManagersEntities,
          setupEntity,
        ] = response;

        const initialValueGeoFilter: { [key: string]: any } = {};
        this.geosEntities.forEach((item: Geo) => {
          geoFilter.push({ value: item.geo, id: item.id });
          if (item.id === this.currentUser.geoId) {
            initialValueGeoFilter.value = item.geo;
            initialValueGeoFilter.id = item.id;
          }
        });

        const initialValueCountryFilter: { [key: string]: any } = {};
        this.countriesEntities.forEach((item: Country) => {
          countryFilter.push({ value: item.country, id: item.id });
          if (item.id === this.currentUser.countryId) {
            initialValueCountryFilter.value = item.country;
            initialValueCountryFilter.id = item.id;
          }
        });

        customerShortEntities.forEach((item: any) => {
          customerShortFilter.push({
            value: item.customerShort,
            id: item.id,
          });
        });

        materialsEntities.forEach((item: any) => {
          if (
            item.productDesc &&
            ppmFilter.findIndex(
              obj =>
                obj.value.toUpperCase() === item.allocationBz?.toUpperCase()
            ) === -1
          ) {
            ppmFilter.push({
              key: item.productDesc,
              value: item.productDesc,
              id: item.productId,
            });
          }
        });

        const initialValueOrderAdmin: { [key: string]: any } = {};
        orderAdminsEntities.forEach((item: User) => {
          const viewName: string = `${item.last_name}, ${item.name}`;
          if (
            orderAdminsFilter.findIndex(
              obj =>
                obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
            ) === -1
          ) {
            orderAdminsFilter.push({ value: viewName, id: item.id });

            if (
              item.email.includes(this.currentUser.email) &&
              this.currentUser.profileType.includes('orderAdmin')
            ) {
              initialValueOrderAdmin.value = viewName;
              initialValueOrderAdmin.id = item.id;
            }
          }
        });

        const initialValueSalesManager: { [key: string]: any } = {};
        salesManagersEntities.forEach((item: User) => {
          const viewName: string = `${item.last_name}, ${item.name} (${item.email})`;
          if (
            salesManagerFilter.findIndex(obj => obj.value === viewName) === -1
          ) {
            salesManagerFilter.push({
              value: `${item.last_name}, ${item.name} (${item.email})`,
              id: item.id,
            });

            if (
              item.email.includes(this.currentUser.email) &&
              this.currentUser.profileType.includes('salesManager')
            ) {
              initialValueSalesManager.value = viewName;
              initialValueSalesManager.id = item.id;
              [initialValueSalesManager.orderAdmin] = item.orderAdmins.map(
                (orderAdmin: any) => ({
                  id: orderAdmin.orderAdmin.id,
                  value: `${orderAdmin.orderAdmin.last_name}, ${orderAdmin.orderAdmin.name}`,
                })
              );
              this.searchInputs[1].value = initialValueSalesManager.orderAdmin;
            }
          }
        });
        this.allocationLabels = this.removePastWeek(setupEntity);
        for (
          let year = currentYear - 5, i = 0;
          year <= currentYear + 5;
          year++, i++
        ) {
          fiscalYearFilter.push({ key: i, value: `${year}/${year + 1}` });
        }

        quarterFilter = [
          { key: '1', value: 'FQ1' },
          { key: '2', value: 'FQ2' },
          { key: '3', value: 'FQ3' },
          { key: '4', value: 'FQ4' },
        ];

        const selectPpmFilter = new SelectSearchInput({
          key: 'ppm',
          hover: 'PPM',
          type: 'text',
          classCss: 'full-width-panel-ppm',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: ppmFilter,
          largeInput: true,
        });

        const selectCustomerShort = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          classCss: 'full-width-panel-customer-short',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
          options: customerShortFilter,
          largeInput: true,
        });

        const selectMultiSalesManager = new MultiSelectSearchInput({
          key: 'salesManagerId',
          hover: 'Sales Manager',
          type: 'text',
          classCss: 'full-width-panel-sales-manager',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
          options: salesManagerFilter,
          value: [initialValueSalesManager],
          largeInput: true,
        });

        const selectMultiOrderAdmin = new MultiSelectSearchInput({
          key: 'orderAdminId',
          hover: 'Order Admin',
          type: 'text',
          classCss: 'filter-order-admin',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
          options: orderAdminsFilter,
          value: [initialValueOrderAdmin],
        });

        const selectGeo: any = new SelectSearchInput({
          key: 'geoId',
          hover: 'GEO',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: geoFilter,
          value: initialValueGeoFilter.value,
          disabled: true,
          notClearable: true,
        });

        const selectMultiCountry = new SelectSearchInput({
          key: 'countryId',
          hover: 'Country',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: countryFilter,
          disabled: true,
          value: initialValueCountryFilter.value,
          notClearable: true,
        });

        const selectFiscalYear = new SelectSearchInput({
          key: 'fiscalYear',
          hover: 'Fiscal Year',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
          options: fiscalYearFilter,
          value: `${setupEntity.fiscalYear}/${setupEntity.fiscalYear + 1}`,
        });

        const selectFiscalQuarter = new SelectSearchInput({
          key: 'quarter',
          hover: 'Fiscal Quarter',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
          options: quarterFilter,
          value: `${setupEntity.quarter}`,
        });

        this.searchInputs[0] = selectPpmFilter;
        this.searchInputs[1] = selectCustomerShort;
        this.searchInputs[2] = selectMultiSalesManager;
        this.searchInputs[3] = selectMultiOrderAdmin;
        this.searchInputs[5] = selectFiscalYear;
        this.searchInputs[6] = selectFiscalQuarter;
        this.searchInputs[7] = selectGeo;
        this.searchInputs[8] = selectMultiCountry;
        this.searchInputs = [...this.searchInputs];
        this.spinner.hide();
      });
  }

  removePastWeek(setup: any) {
    let toAllocate: string = '';
    let toBook: string = '';
    const allocate: any = [];
    const book: any = [];
    const currentWeek = setup.currentWeek.replace('week', '');
    setup.toAllocate.split(',').forEach((item: any) => {
      allocate.push(item.trim());
    });
    allocate.sort().forEach((item: any) => {
      let allocate = item.replace('Wk', '').trim();
      if (allocate >= currentWeek) {
        toAllocate += `${item},`;
      }
    });
    setup.toBook.split(',').forEach((item: any) => {
      book.push(item.trim());
    });
    book.sort().forEach((item: any) => {
      let book = item.replace('Wk', '').trim();
      if (book >= currentWeek) {
        toBook += `${item},`;
      }
    });

    setup.toAllocate = toAllocate.slice(0, -1);
    setup.toBook = toBook.slice(0, -1);
    return setup;
  }

  getValuesSubTotal(search: any) {
    this.valuesSubTotal = [];

    this.crudService
      .getEntity('allocation-customer/subtotal', search)
      .subscribe((response: any) => {
        this.valuesSubTotal = response;
        if (Object.keys(this.valuesSubTotal.expanded['JAG']).length === 0) {
          this.isSubTotalEmpty = true;
        } else {
          this.isSubTotalEmpty = false;
        }
        // this.startingUpdateTableValuesSummarized();
        // this.startingUpdateTableValuesExpanded();
        this.noFilterSelected = false;
        this.showNoResults = false;
        this.spinner.hide();
      });
  }

  resetStates(): void {
    this.loadedDataPPM = [];
    this.dataSourceTable = [];
    this.isEditTableMode = false;
    this.showNoResults = true;
    this.noFilterSelected = true;
    this.showTable = false;
  }

  verifyPermissionEdit(data: any) {
    const userPermission = this.authService.getUserPermissions();
    const canEditAllocation = userPermission.some(
      (permission: any) =>
        permission.resource === 'allocation-retail' &&
        (permission.permission === 'UPDATE' ||
          permission.permission === 'CREATE')
    );

    if (!canEditAllocation) {
      // Disable inputs for non-ATS/MLS rows in expanded content
      data.middleTable.content[0].expanded.forEach((row: any) => {
        Object.values(row.rows).forEach((cell: any) => {
          if (!cell.enableATS && !cell.enableMLS) {
            Object.values(cell.value).forEach((value: any) => {
              if (value.enableInput) value.enableInput = false;
            });
          }
        });
      });

      // Disable inputs for non-ATS/MLS rows in summarized content
      const currentMonthOrWeek = (header: any) =>
        header.currentMonth ||
        header.weekInTime === 'current' ||
        header.weekInTime === 'next';
      data.middleTable.headers.forEach((header: any) => {
        data.middleTable.content[0].summarized[0].rows.forEach((row: any) => {
          Object.entries(row.value).forEach(([prop, cell]: any) => {
            if (
              currentMonthOrWeek(header) &&
              cell.customerShort !== 'ATS' &&
              cell.customerShort !== 'MLS'
            ) {
              if (cell.enableInput) cell.enableInput = false;
            }
          });
        });
      });
    }
  }

  getDataTables(search: any) {
    this.spinner.show();
    this.eventTable = 'hide-columns-rows';
    this.resetStates();

    this.crudService
      .getEntity('allocation-customer', search)
      .subscribe((response: any) => {
        if (response.introTable.content.length > 0) {
          this.verifyPermissionEdit(response);
          this.loadedDataPPM = response;
          this.loadedDataPPM = JSON.parse(JSON.stringify(this.loadedDataPPM));
          this.productDescription =
            this.loadedDataPPM.introTable.content[0].summarized[0].rows[2].value.ppm.value;
          this.getUpdateSubTotal();
          this.getValuesSubTotal(search);
          this.disabledButtonExport = false;
          this.noFilterSelected = false;
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.disabledButtonExport = true;
          this.showNoResults = true;
          this.noFilterSelected = false;
          this.productDescription = '';
        }
        this.getTableEndPage(search, this.loadedDataPPMTableTab);
      });
  }

  dynamicDataSource(event?: any): DynamicData[] {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dynamicData: DynamicData[] = [];

    months.forEach((month: any) => {
      if (event.rowValue.value.netOpenPo.modal.hasOwnProperty(month)) {
        const dataMonth = event.rowValue.value.netOpenPo.modal[month];

        if (dataMonth.length > 0) {
          dataMonth.forEach((data: NetOpenPoData) => {
            dynamicData.push({
              customerShort: event.rowValue.customerShort,
              month: data.month,
              taxed: data.tax,
              soLineNumber: data.so_line_number,
              customerPoLineNumber: data.cust_po_line_num,
              QTY: data.qty,
            });
          });
        }
      }
    });

    return dynamicData;
  }

  dynamicColumns(): columnsStyle[] {
    return [
      { label: 'CUSTOMER SHORT', prop: 'customerShort' },
      { label: 'MONTH', prop: 'month' },
      { label: 'TAXES', prop: 'taxed' },
      { label: 'SO LINE NUMBER', prop: 'soLineNumber' },
      { label: 'CUSTOMER PO LINE NUMBER', prop: 'customerPoLineNumber' },
      { label: 'QTY', prop: 'QTY' },
    ];
  }

  openModalNetOpenPo(rowValue: any): void {
    const dynamicData = this.dynamicDataSource(rowValue);
    const uniqueMonths = Array.from(
      new Set(dynamicData.map((item: any) => item.month))
    );

    // Calculando o subtotal
    const calcValue = dynamicData
      .filter((item: any) => item.month === uniqueMonths[0])
      .reduce((acc: any, item: any) => acc + item.QTY, 0);

    const modalData = {
      title: 'NET OPEN PO',
      subtitle: rowValue.rowValue.ppm,
      titleExport: 'netOpenPo',
      nameModal: 'netOpenPo',
      dataSource: dynamicData,
      dynamicPropertyWithSameValue: uniqueMonths,
      dynamicCalcSubtotal: calcValue,
      displayedColumns: this.dynamicColumns(),
      hasRecords: dynamicData.length > 0,
      messageNoRecords: 'THERE ARE NO ASSOCIATED SO LINE NUMBERS',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog-container',
    });
  }

  dynamicDataSourceLast(data?: any, headers?: any): any[] {
    const dynamicData: any[] = [];

    if (data) {
      data.forEach((row: any) => {
        const dynamicRow: any = {};
        for (const key in headers) {
          if (headers.hasOwnProperty(key) && row.hasOwnProperty(key)) {
            dynamicRow[key] = row[key];
          }
        }
        dynamicData.push(dynamicRow);
      });

      // Se family && Origin forem iguais, family deve ficar ''
      // Se customerShort iguais, customerShort = ''
      dynamicData.forEach((row, index) => {
        if (index > 0) {
          const previousRow = dynamicData[index - 1];
          if (
            row.family === previousRow.family &&
            row.origin === previousRow.origin
          ) {
            const currentCustomerShort = row.customerShort;
            const currentOrigin = row.origin;

            // Procura o primeiro índice com o mesmo customerShort e origin
            let firstMatchingIndex = index - 1;
            while (firstMatchingIndex >= 0) {
              const prevCustomerShort =
                dynamicData[firstMatchingIndex].customerShort;
              const prevOrigin = dynamicData[firstMatchingIndex].origin;

              if (
                prevCustomerShort === currentCustomerShort &&
                prevOrigin === currentOrigin
              ) {
                break;
              }
              firstMatchingIndex--;
            }

            // Se encontrar um índice com o mesmo customerShort e origin, definir como vazio
            if (firstMatchingIndex >= 0) {
              for (let i = index; i > firstMatchingIndex; i--) {
                const item = dynamicData[i];
                if (
                  item.customerShort === currentCustomerShort &&
                  item.origin === currentOrigin
                ) {
                  item.customerShort = '';
                }
              }
            }
            row.secondTitle = '';
          }
        }
      });
    }

    return dynamicData;
  }

  dynamicColumnsLast(): columnsStyle[] {
    return [
      { label: 'PPM', prop: 'family' },
      { label: 'CUSTOMER SHORT', prop: 'customerShort' },
      { label: 'CHANNEL', prop: 'channel' },
      { label: 'ORIGIN', prop: 'origin' },
      { label: 'TAXES', prop: 'taxes' },
      {
        label: 'ALLOCATED',
        prop: 'allocated',
        summable: true,
        total: 0,
      },
      {
        label: 'INVOICED',
        prop: 'invoiced',
        summable: true,
        total: 0,
      },
      { label: 'DELTA', prop: 'delta', summable: true, total: 0 },
    ];
  }

  openModalLastWeek(): void {
    const dynamicData: any = this.dynamicDataSourceLast(
      this.loadedDataPPM.lastWeekModal?.content,
      this.loadedDataPPM.lastWeekModal?.headers[0]
    );

    const resultTotal: any = this.loadedDataPPM.lastWeekModal;
    const columns = this.dynamicColumnsLast();
    const combinedUnique: any[] = [];

    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const family = item.family;
      const found = combinedUnique.find(
        elem => elem.origin === origin && elem.family === family
      );

      if (!found) {
        combinedUnique.push({ origin, family });
      }
    });

    // Cálculo do subtotal por origem para 'allocated', 'invoiced' e 'delta'
    const originSubtotals: {
      [key: string]: { allocated: number; invoiced: number; delta: number };
    } = {};
    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const allocated = item.allocated;
      const invoiced = item.invoiced;
      const delta = item.delta;

      if (!originSubtotals[origin]) {
        originSubtotals[origin] = { allocated: 0, invoiced: 0, delta: 0 };
      }

      originSubtotals[origin].allocated += allocated;
      originSubtotals[origin].invoiced += invoiced;
      originSubtotals[origin].delta += delta;
    });

    const totalSubtotals: any = {};
    const processedCombinations = new Map();

    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const family = item.family;
      const combinationKey = `${origin}-${family}`;

      // Verifica se a combinação origin-family já foi processada para que não
      // adicione subtotais em todas as rows, apenas no primeiro index por family
      if (!processedCombinations.has(combinationKey)) {
        item.subtotals = {};

        columns.forEach((header: any) => {
          if (header.summable) {
            const subtotal = dynamicData
              .filter((dataItem: any) => {
                return dataItem.family === family && dataItem.origin === origin;
              })
              .reduce(
                (sum: number, dataItem: any) => sum + dataItem[header.prop],
                0
              );

            item.subtotals[header.prop] = subtotal;

            if (!totalSubtotals[header.prop]) {
              totalSubtotals[header.prop] = subtotal;
            } else {
              totalSubtotals[header.prop] += subtotal;
            }
            resultTotal.total = totalSubtotals;
          }
        });

        processedCombinations.set(combinationKey, true);
      }
    });

    let modalData = {
      title: 'LAST WEEK',
      nameModal: 'lastWeek',
      dataSource: dynamicData,
      displayedColumns: columns,
      total: resultTotal?.total,
      dynamicPropertyWithSameValue: combinedUnique,
      hasRecords: dynamicData.length > 0,
      messageNoRecords: 'THERE ARE NO LAST WEEK DATA',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog-container',
    });
  }

  onSearchEvent(form: any) {
    this.formSearchValues = { ...form };

    const originFilter: any = [];

    if (this.formSearchValues.geoId) {
      const currentGeo = this.geosEntities.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(this.formSearchValues.geoId.toLocaleUpperCase())
      );
      this.formSearchValues.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      this.formSearchValues.geoId = '';
    }

    if (this.formSearchValues.countryId) {
      const currentCountry = this.countriesEntities.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(this.formSearchValues.countryId.toLocaleUpperCase())
      );
      this.formSearchValues.countryId = currentCountry[0]
        ? currentCountry[0].id
        : 0;
    } else {
      this.formSearchValues.countryId = '';
    }
    if (this.formSearchValues.ppm) {
      this.crudService
        .getEntity(
          `material/origin/summary?product_desc=${this.formSearchValues.ppm}`,
          { pageName: PAGE_NAME_RESOURCE.allocationRetail }
        )
        .subscribe((response: any) => {
          response.forEach((item: any) => {
            originFilter.push({ value: item.origin });
          });

          if (originFilter.length !== this.searchInputs[4].options.length) {
            const selelecMultiOrigin = new MultiSelectSearchInput({
              key: 'origin',
              hover: 'Origin',
              type: 'text',
              classCss: 'filter-origin',
              hasAutocomplete: true,
              hasCheckFlowException: true,
              disabled: false,
              options: originFilter,
            });
            this.searchInputs[4] = selelecMultiOrigin;
            this.origins = originFilter;
          }
          this.searchInputs = [...this.searchInputs];
        });
    } else if (!this.formSearchValues.ppm) {
      this.formSearchValues.ppm = '';
    }

    if (this.formSearchValues.orderAdminId) {
      const currentOrderAdminFullName = this.formSearchValues.orderAdminId.map(
        (item: User) => item.id
      );
      this.formSearchValues.orderAdminId = currentOrderAdminFullName;
    } else {
      this.formSearchValues.orderAdminId = '';
    }

    if (this.formSearchValues.salesManagerId) {
      const currentSalesManagerId = this.formSearchValues.salesManagerId.map(
        (item: User) => item.id
      );
      this.formSearchValues.salesManagerId = currentSalesManagerId;
    } else {
      this.formSearchValues.salesManagerId = '';
    }

    if (this.formSearchValues.customerShortId) {
      const currentCustomerShort = this.formSearchValues.customerShortId.map(
        (item: any) => item.id
      );
      this.formSearchValues.customerShortId = currentCustomerShort;
    } else {
      this.formSearchValues.customerShortId = '';
    }

    if (this.formSearchValues.origin) {
      const currentOrigin = this.formSearchValues.origin.map(
        (item: any) => item.value
      );
      this.formSearchValues.origin = currentOrigin;
    } else {
      this.formSearchValues.origin = '';
    }

    this.formSearchValues.fiscalYear =
      this.formSearchValues.fiscalYear?.split('/')[0] || '';
    this.formSearchValues.quarter = this.formSearchValues.quarter || '';
    this.enableApplyFilter = Boolean(
      this.formSearchValues[this.keysFilters[0]] &&
        this.formSearchValues[this.keysFilters[7]] &&
        this.formSearchValues[this.keysFilters[8]]
    );

    if (this.enableApplyFilter) {
      this.searchInputs[1].disabled = false;
      this.searchInputs[2].disabled = false;
      this.searchInputs[3].disabled = false;
      this.searchInputs[4].disabled = false;
      this.searchInputs[5].disabled = false;
      this.searchInputs[6].disabled = false;
    }

    this.searchInputs = [...this.searchInputs];
  }

  applyFilter() {
    const formSearchValues = { ...this.formSearchValues };
    delete formSearchValues.action;
    delete formSearchValues.nameFormControl;
    delete formSearchValues.action;
    delete formSearchValues.formControl;
    delete formSearchValues.event;
    delete formSearchValues.panelTrigger;
    formSearchValues.simpleView = this.simpleViewOrNot;
    this.filter = {
      ...formSearchValues,
      pageName: PAGE_NAME_RESOURCE.allocationRetail,
    };

    this.checkDiscardAllocation('applyFilter');
  }

  setExportStatus(status: string) {
    localStorage.setItem('disabled_button_export_ppm', status);
  }

  getExportStatus() {
    const status = localStorage.getItem('disabled_button_export_ppm');
    return !!status;
  }

  destroyExportStatus() {
    localStorage.removeItem('disabled_button_export_ppm');
    this.disabledButtonExport = false;
  }

  public base64ToBlob(b64Data: string, sliceSize = 512) {
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  exportTableRequest() {
    this.setExportStatus('true');
    this.disabledButtonExport = true;
    this.tooltipMessage = 'Waiting...';
    const subtitleMessage = 'Generating Excel file for background download!';
    const titleMessage = 'Download Excel';
    this.toastr.success(`${subtitleMessage}`, `${titleMessage}`, {
      timeOut: 10000,
    });
    this.downloadFileService
      ?.downloadFile(
        'allocation-customer/export-report-ppm',
        this.filter,
        'text'
      )
      .subscribe(
        (response: any) => {
          const file = this.base64ToBlob(response);

          // IE
          const nav = window.navigator as any;
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return;
          }
          FileSaver.saveAs(file, `PPM_REPORT.xlsx`);
          const subtitleMessage = 'Successfully generated file!';
          const titleMessage = 'Download Excel';
          this.toastr.success(`${subtitleMessage}`, `${titleMessage}`, {
            timeOut: 10000,
          });
          this.destroyExportStatus();
          this.tooltipMessage = 'Export';
        },
        (err: any) => {
          const subtitleMessage =
            'An error occurred while generating the Excel file!';
          const titleMessage = 'Download Excel';
          this.toastr.error(`${subtitleMessage}`, `${titleMessage}`, {
            timeOut: 10000,
          });
          this.destroyExportStatus();
          this.tooltipMessage = 'Export';
        }
      );
  }

  exportTable() {
    if (this.formSearchValues?.ppm) {
      this.exportTableRequest();
    } else {
      const dialog = this.getDialogModal('export');

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.exportTableRequest();
        }
      });
    }
  }

  startingUpdateTableValuesSummarized() {
    this.valuesDelta = [];
    this.valuesSubstraction = [];
    this.valuesMLSsummarized = [];
    this.valuesSubMlsAndTotalAllocation = [];
    this.valuesTotalAllocation = [];
    /*  */
    Object.entries(
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0].rows[
        INDEX_MLS
      ].value
    ).forEach((row: any) => {
      this.dataSourceTable[INDEX_MIDDLE_TABLE].headers.forEach(
        (header: any) => {
          if (header.weekInTime === 'current' || header.weekInTime === 'next') {
            if (row[0] === header.props) {
              const hasColumnInvaluesMLS = this.valuesMLSsummarized.filter(
                (v: any) => v.col === header.props
              );
              if (!hasColumnInvaluesMLS.length)
                this.valuesMLSsummarized.push({
                  col: row[0],
                  value: parseFloat(row[1].value),
                });
              this.valuesMLSsummarized.forEach((key: any) => {
                if (key.col === row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumnInValuesSubstraction =
                this.valuesSubstraction.filter((v: any) => v.col == row[0]);
              if (!hasColumnInValuesSubstraction.length)
                this.valuesSubstraction.push({ col: row[0], value: 0 });
              this.valuesSubstraction.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumnInValuesDelta = this.valuesDelta.filter(
                (v: any) => v.col == row[0]
              );
              if (!hasColumnInValuesDelta.length)
                this.valuesDelta.push({ col: row[0], value: 0 });
              this.valuesDelta.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = 0;
                }
              });

              const hasColumnInValuesTotalAllocation =
                this.valuesTotalAllocation.filter((v: any) => v.col == row[0]);
              if (!hasColumnInValuesTotalAllocation.length)
                this.valuesTotalAllocation.push({ col: row[0], value: 0 });
              this.valuesTotalAllocation.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = 0;
                }
              });
            }
          }
        }
      );
    });

    /* Armazenando o total de alocações em um array de referência */
    for (
      let i = 0;
      i <
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0].rows
        .length;
      i++
    ) {
      this.dataSourceTable[INDEX_MIDDLE_TABLE].headers.forEach(
        (header: any) => {
          if (
            header.currentMonth ||
            header.weekInTime === 'current' ||
            header.weekInTime === 'next'
          ) {
            Object.entries(
              this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0]
                .rows[i].value
            ).forEach((row: any) => {
              if (
                row[1].customerShort !== 'ATS' &&
                row[1].customerShort !== 'MLS'
              ) {
                if (row[0] === header.props) {
                  const hasColumn = this.valuesSubMlsAndTotalAllocation.filter(
                    (v: any) => v.col === header.props
                  );
                  if (!hasColumn.length)
                    this.valuesSubMlsAndTotalAllocation.push({
                      col: row[0],
                      value: 0,
                    });
                  this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
                    if (key.col === row[0]) {
                      key.col = row[0];
                      key.value += parseFloat(row[1].value);
                    }
                  });
                }
              }
            });
          }
        }
      );
    }

    this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
      this.valuesTotalAllocation.forEach((value: any) => {
        if (key.col === value.col) value.value = key.value;
      });
    });

    /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    Object.entries(
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0].rows[
        INDEX_MLS
      ].value
    ).forEach((row: any) => {
      this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
        if (key.col === row[0])
          key.value = parseFloat(row[1].value) - parseFloat(key.value);
      });
    });

    let acumulativeDelta: any = 0;

    this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
      this.valuesSubstraction.forEach((value: any) => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    for (let i = 0; i < this.valuesSubstraction.length; i++) {
      if (this.isSubTotalEmpty) {
        acumulativeDelta =
          this.valuesSubstraction[i]?.value +
          this.valuesMLSsummarized[i + 1]?.value;
      } else {
        acumulativeDelta =
          this.valuesSubstraction[i]?.value -
          this.valuesSubTotal.summarized[this.valuesSubstraction[i]?.col]
            ?.value +
          this.valuesMLSsummarized[i + 1]?.value;
      }
      this.valuesSubstraction.forEach((row: any, indexRow: number) => {
        if (indexRow === i + 1)
          row.value =
            parseFloat(acumulativeDelta) -
            this.valuesTotalAllocation[i + 1]?.value;
      });
    }

    if (!this.isSubTotalEmpty) {
      for (let i = 0; i < this.valuesSubstraction.length; i++) {
        this.valuesSubstraction[i].value =
          Number(this.valuesSubstraction[i].value) -
          Number(
            this.valuesSubTotal.summarized[this.valuesSubstraction[i]?.col]
              ?.value
          );
      }
    }

    this.valuesSubstraction.forEach((key: any) => {
      this.valuesDelta.forEach((value: any) => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    /* Adicionando na tabela summarized os valores de ATS */
    Object.entries(
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0].rows[
        INDEX_ATS
      ].value
    ).forEach((row: any) => {
      this.valuesDelta.forEach((key: any) => {
        if (key.col === row[0]) {
          row[1].value = parseFloat(key.value);
        }
      });
    });

    let valuesTotalATS: { col: string; value: number }[] = [];

    /* Atualizando os valores de Sub Total no Mês atual */
    this.dataSourceTable[INDEX_MIDDLE_TABLE].headers.forEach((header: any) => {
      Object.entries(
        this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0].rows[
          INDEX_ATS
        ].value
      ).forEach((row: any) => {
        if (header?.props.includes('wk')) {
          if (row[0] === header.props) {
            const column = row[0].split('wk');

            const hasColumnInValuesTotalATS = valuesTotalATS.filter(
              (v: any) => v.col == column[0]
            );
            if (!hasColumnInValuesTotalATS.length)
              valuesTotalATS.push({
                col: column[0],
                value: Number(row[1].value),
              });
            valuesTotalATS.forEach((key: any) => {
              if (key.col == column[0]) {
                key.col = column[0];
                key.value = Number(row[1].value);
              }
            });
          }
        }
      });
    });

    Object.entries(
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0].rows[
        INDEX_ATS
      ].value
    ).forEach((row: any) => {
      valuesTotalATS.forEach((key: any) => {
        if (row[0] === key.col) {
          row[1].value = key.value;
        }
      });
    });
  }

  startingUpdateTableValuesExpanded() {
    this.valuesMLSexpanded = [];
    this.valuesSubstractionExpanded = [];
    this.valuesSubMlsAndTotalAllocationExpanded = [];

    for (
      let i = 0;
      i < this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.length;
      i++
    ) {
      Object.entries(
        this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded[i].rows[
          INDEX_MLS
        ].value
      ).forEach((row: any) => {
        this.dataSourceTable[INDEX_MIDDLE_TABLE].headers.forEach(
          (header: any) => {
            if (
              header.weekInTime === 'current' ||
              header.weekInTime === 'next'
            ) {
              if (row[0] === header.props) {
                const hasColumnInvaluesMLS = this.valuesMLSexpanded.filter(
                  (v: any) =>
                    v.col === header.props && v.origin === row[1].origin
                );
                if (!hasColumnInvaluesMLS.length)
                  this.valuesMLSexpanded.push({
                    col: row[0],
                    value: parseFloat(row[1].value),
                    origin: row[1].origin,
                  });
                this.valuesMLSexpanded.forEach((key: any) => {
                  if (key.col === row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = parseFloat(row[1].value);
                    key.origin = row[1].origin;
                  }
                });
                const hasColumnInvaluesSubMLS =
                  this.valuesSubMlsAndTotalAllocationExpanded.filter(
                    (v: any) =>
                      v.col === header.props && v.origin === row[1].origin
                  );
                if (!hasColumnInvaluesSubMLS.length)
                  this.valuesSubMlsAndTotalAllocationExpanded.push({
                    col: row[0],
                    origin: row[1].origin,
                    value: 0,
                  });
                this.valuesSubMlsAndTotalAllocationExpanded.forEach(
                  (key: any) => {
                    if (key.col == row[0] && key.origin === row[1].origin) {
                      key.col = row[0];
                    }
                  }
                );

                const hasColumn = this.valuesSubstractionExpanded.filter(
                  (v: any) =>
                    v.col === header.props && v.origin === row[1].origin
                );
                if (!hasColumn.length)
                  this.valuesSubstractionExpanded.push({
                    col: row[0],
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesSubstractionExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = parseFloat(row[1].value);
                  }
                });

                if (!hasColumn.length)
                  this.valuesDeltaExpanded.push({
                    col: row[0],
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesDeltaExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = 0;
                  }
                });

                if (!hasColumn.length)
                  this.valuesTotalAllocationExpanded.push({
                    col: row[0],
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesTotalAllocationExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = 0;
                  }
                });
              }
            }
          }
        );
      });
    }

    /* Armazenando o total de alocações em um array de referência */

    this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
      (row: any, indexRow: number) => {
        Object.entries(
          this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded[indexRow]
            ?.rows
        ).forEach((key: any) => {
          if (!key[1].enableATS && !key[1].enableMLS) {
            Object.entries(key[1].value).forEach((value: any) => {
              this.valuesSubMlsAndTotalAllocationExpanded.forEach(
                (subTotal: any) => {
                  if (
                    subTotal.col == value[0] &&
                    subTotal.origin == value[1]?.origin
                  ) {
                    subTotal.value += parseFloat(value[1].value);
                  }
                }
              );
            });
          }
        });
      }
    );

    this.valuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesTotalAllocationExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin)
          value.value = key.value;
      });
    });

    /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    this.valuesMLSexpanded.forEach((row: any) => {
      this.valuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
        if (key.col === row.col && key.origin === row.origin) {
          key.value = parseFloat(row.value) - parseFloat(key.value);
        }
      });
    });

    this.valuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesSubstractionExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    let acumulativeDelta: any = 0;

    for (let i = 0; i < this.valuesSubstractionExpanded.length; i++) {
      if (
        this.valuesSubstractionExpanded[i]?.origin ===
        this.valuesMLSexpanded[i + 1]?.origin
      ) {
        if (this.isSubTotalEmpty) {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value;
        } else {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value -
            this.valuesSubTotal.expanded[
              this.valuesSubstractionExpanded[i]?.origin
            ][this.valuesSubstractionExpanded[i]?.col].value;
        }
        this.valuesSubstractionExpanded.forEach(
          (row: any, indexRow: number) => {
            if (indexRow === i + 1)
              row.value =
                parseFloat(acumulativeDelta) -
                this.valuesTotalAllocationExpanded[i + 1]?.value;
          }
        );
      } else {
        acumulativeDelta = 0;
      }
    }

    if (!this.isSubTotalEmpty) {
      Object.entries(this.valuesSubTotal.expanded).forEach((subTotal: any) => {
        this.valuesSubstractionExpanded.forEach((delta: any) => {
          if (Object.keys(subTotal[1]).length !== 0) {
            if (delta.origin === subTotal[0]) {
              delta.value =
                parseFloat(delta.value) -
                parseFloat(subTotal[1][delta.col].value);
            }
          }
        });
      });
    }

    this.valuesSubstractionExpanded.forEach((key: any) => {
      this.valuesDeltaExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    this.valuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesDeltaExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          key.value = parseFloat(value.value);
        }
      });
    });

    /* Adicionando na tabela expanded os valores de ATS */
    this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
      (row: any, indexRow: number) => {
        Object.entries(
          this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded[indexRow]
            ?.rows
        ).forEach((key: any) => {
          if (key[1].enableATS) {
            Object.entries(key[1].value).forEach((value: any) => {
              this.valuesSubMlsAndTotalAllocationExpanded.forEach(
                (subTotal: any) => {
                  if (
                    subTotal.col == value[0] &&
                    subTotal.origin === value[1]?.origin
                  ) {
                    value[1].value = parseFloat(subTotal.value);
                  }
                }
              );
            });
          }
        });
      }
    );

    /* Atualizando os valores de Sub Total no Mês atual */

    let valuesExpandedTotalATS: {
      col: string;
      value: number;
      origin: string;
    }[] = [];

    this.valuesSubMlsAndTotalAllocationExpanded.forEach((subTotal: any) => {
      if (subTotal.origin) {
        const column = subTotal.col.split('wk');

        const hasColumnInValuesTotalATS = valuesExpandedTotalATS.filter(
          (v: any) => v.col == column[0] && v.origin === subTotal.origin
        );
        if (!hasColumnInValuesTotalATS.length)
          valuesExpandedTotalATS.push({
            col: column[0],
            value: Number(subTotal.value),
            origin: subTotal.origin,
          });
        valuesExpandedTotalATS.forEach((key: any) => {
          if (key.col == column[0] && key.origin === subTotal.origin) {
            key.col = column[0];
            key.value = Number(subTotal.value);
          }
        });
      }
    });

    this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
      (row: any, indexRow: number) => {
        Object.entries(
          this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded[indexRow]
            ?.rows
        ).forEach((key: any) => {
          if (key[1].enableATS) {
            Object.entries(key[1].value).forEach((value: any) => {
              valuesExpandedTotalATS.forEach((subTotal: any) => {
                if (
                  value[0] === subTotal.col &&
                  value[1].origin === subTotal.origin
                ) {
                  value[1].value = Number(subTotal.value);
                }
              });
            });
          }
        });
      }
    );
  }

  updateTableValuesTotalMonths() {
    this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
      (row: any, indexRow: number) => {
        Object.entries(
          this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded[indexRow]
            ?.rows
        ).forEach((key: any) => {
          if (!key[1].enableATS && !key[1].enableMLS) {
            const objetoValue = key[1].value;
            let weekValue: number = 0;
            for (const key in objetoValue) {
              if (key.includes('wk')) {
                const monthKey = key.replace(/wk.+$/, ''); // Extrai o mês da chave
                weekValue += objetoValue[key].value; // Obtém o valor da semana

                // Adiciona o valor da semana ao valor total do mês
                if (objetoValue[monthKey]) {
                  objetoValue[monthKey].value = weekValue;
                }
              }
            }
          }
        });
      }
    );
  }

  updateTableValues(item: {
    indexTable: number;
    indexContent: number;
    indexGroup: number;
    content: any;
    statusTable: string;
    columnNameWeek: string;
    columnNameMonth: string;
  }) {
    this.dataSourceTable[item.indexTable].content[
      item.indexContent
    ].summarized[0].rows.forEach((row: any, indexRow: number) => {
      row.value[item.columnNameWeek].value =
        item.content.summarized[0].rows[indexRow].value[
          item.columnNameWeek
        ].value;
    });

    if (item.statusTable === 'expanded') {
      this.atsAndNop = undefined;

      this.dataSourceTable[item.indexTable].content[item.indexContent].expanded[
        item.indexGroup
      ].rows.forEach((row: any, indexRow: number) => {
        row.value[item.columnNameWeek] =
          item.content.expanded[item.indexGroup].rows[indexRow].value[
            item.columnNameWeek
          ];
      });
    } else {
      this.disabledSaveButton = true;
    }

    this.isEditTableMode = true;
    this.toggleBreadcrumb(true);
  }

  toggleTable(event: string) {
    this.eventTable = event;
    this.startingUpdateTableValuesSummarized();
    this.startingUpdateTableValuesExpanded();
  }

  getCalculateTaxesValues(params: any, item: any) {
    const updateMiddleTableValues = () => {
      this.dataSourceTable[INDEX_MIDDLE_TABLE].displayedColumns =
        item.displayedColumns;
      this.configMiddleTable(this.dataSourceTable[INDEX_MIDDLE_TABLE]);
      this.disabledSaveButton = true;
    };

    const resetInsertedValueToTaxes = () => {
      this.dataSourceTable[INDEX_MIDDLE_TABLE]?.content[0]?.summarized.forEach(
        (summarized: any) => {
          summarized?.rows.forEach((row: any) => {
            if (!row.enableATS && !row.enableMLS) {
              if (
                row.value[item.value.indexCol].customerShortId ===
                params.customerShortId
              ) {
                row.value[item.value.indexCol].value = 0;
              }
            }
          });
        }
      );

      updateMiddleTableValues;
      this.startingUpdateTableValuesSummarized();
      this.getUpdateSubTotal();

      const valuesSubTotal =
        this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0]
          .subtotal;
      this.tableManager?.updateValuesTable(params, item, valuesSubTotal);
    };

    const updateValueAfterTaxes = (totalResult: any) => {
      this.dataSourceTable[INDEX_MIDDLE_TABLE]?.content[0]?.summarized.forEach(
        (summarized: any) => {
          summarized?.rows.forEach((row: any) => {
            if (!row.enableATS && !row.enableMLS) {
              if (
                row.value[item.value.indexCol].customerShortId ===
                params.customerShortId
              ) {
                row.value[item.value.indexCol].value = Number(totalResult);
              }
            }
          });
        }
      );

      updateMiddleTableValues;
      this.startingUpdateTableValuesSummarized();
      this.getUpdateSubTotal();

      const valuesSubTotal =
        this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].summarized[0]
          .subtotal;
      this.tableManager?.updateValuesAfterTaxesTable(
        params,
        item,
        valuesSubTotal,
        totalResult
      );
    };

    const updateValuesTotalMonth = (): void => {
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].summarized[0].rows.forEach((row: any, indexRow: number) => {
        row.value[item.value.columnNameMonth].value =
          item.content.summarized[0].rows[indexRow].value[
            item.value.columnNameMonth
          ].value;
      });

      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].expanded[0].rows.forEach((row: any, indexRow: number) => {
        row.value[item.value.columnNameMonth].value =
          item.content.expanded[0].rows[indexRow].value[
            item.value.columnNameMonth
          ].value;
      });

      this.getUpdateSubTotal();

      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].summarized[0].subtotal[item.value.indexCol] =
        item.content.summarized[0].subtotal[item.value.indexCol];

      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].expanded[0].subtotal[item.value.indexCol] =
        item.content.expanded[0].subtotal[item.value.indexCol];
    };

    if (this.atsAndNop.value > 0) {
      this.setCrudService.createEntity(params).subscribe((response: any) => {
        this.dataSourceTable[INDEX_MIDDLE_TABLE]?.content[0]?.expanded.forEach(
          (expanded: any) => {
            expanded?.rows.forEach((product: any) => {
              const itemFilter: any = response?.result.filter(
                (obj: any) =>
                  obj.key === product.value[item.value.indexCol]?.key
              );

              if (itemFilter.length > 0) {
                product.value[item.value.indexCol].value = itemFilter[0].value;
                product.value[item.value.indexCol].edit = true;
              }
            });
          }
        );

        updateMiddleTableValues;
        this.isEditTableMode = true;
        this.disabledSaveButton = false;
        this.isLoading = false;
        this.spinner.hide();

        const titleMessage = 'PPM allocation';
        switch (response?.message.status) {
          case 'SUCCESS':
            this.toastr.success(
              `${response.message.value}`,
              `${titleMessage}`,
              { timeOut: 10000, enableHtml: true }
            );
            updateValueAfterTaxes(response.totalResult);
            // this.updateTableValuesTotalMonths();
            updateValuesTotalMonth();
            break;
          case 'WARNING':
            this.toastr.warning(
              `${response.message.value}`,
              `${titleMessage}`,
              { timeOut: 10000 }
            );
            resetInsertedValueToTaxes();
            break;
          case 'ERROR':
            this.toastr.error(`${response.message.value}`, `${titleMessage}`, {
              timeOut: 10000,
            });
            resetInsertedValueToTaxes();
            break;
        }
        this.startingUpdateTableValuesExpanded();
      });
    } else {
      this.disabledSaveButton = true;
      this.setCrudService.createEntity(params).subscribe((response: any) => {
        this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
          (expanded: any) => {
            expanded.rows.forEach((product: any) => {
              const itemFilter: any = response?.result.filter(
                (obj: any) => obj.key === product.value[item.value.indexCol].key
              );

              if (itemFilter.length > 0) {
                product.value[item.value.indexCol].value = itemFilter[0].value;
                product.value[item.value.indexCol].edit = true;
              }
            });
          }
        );
        updateMiddleTableValues();
        updateValuesTotalMonth();
        this.disabledSaveButton = false;
        this.startingUpdateTableValuesExpanded();
      });
    }
  }

  updateSummarizedTable(item: any) {
    const getValuesATSinPayload = (): void => {
      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ]?.expanded.forEach((rows: any) => {
        rows.rows.forEach((row: any) => {
          this.origins.forEach((origin: any) => {
            if (row?.enableATS) {
              if (origin.value && row.origin === 'JAG') {
                const atsJAG: any = {
                  atsJAG: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToUpdate, atsJAG);
              }
              if (origin.value && row.origin === 'MAN') {
                const atsMAN: any = {
                  atsMAN: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToUpdate, atsMAN);
              }
            }
          });
        });
      });
    };

    const { idPpm } =
      this.loadedDataPPM.middleTable.content[item.value.indexContent];
    const valueMiddleTableRow =
      this.loadedDataPPM.middleTable.content[item.value.indexContent]
        .summarized[0].rows[item.value.indexRow].value[item.value.indexCol];
    const valueEndTableRow =
      this.loadedDataPPM.endTable.content[item.value.indexContent].summarized[0]
        .rows[item.value.indexRow].value;
    const monthWeek = item.value.indexCol.split('wk')[0];
    // const atsValue = Number(
    //   this.dataSourceTable[INDEX_MIDDLE_TABLE].content[item.value.indexContent]
    //     .summarized[0].rows[0].value[item.value.indexCol].value
    // );

    const valueTyped = item.value.valueTyped
      ? parseInt(item.value.valueTyped.toString().replaceAll(',', ''))
      : 0;
    const netOpenPoValue = Number(valueEndTableRow.netOpenPo.value);
    const filtersToSend = { ...this.filter };
    filtersToSend.customerShortId = this.filter.customerShortId.toString();

    const valueToUpdate: any = {
      customerShort: valueMiddleTableRow.customerShort,
      customerShortId: valueMiddleTableRow.customerShortId,
      idPpm,
      value: valueTyped,
      month: monthWeek,
      week: item.value.rowWeek.week,
      yearMonth: item.value.rowWeek.yearMonth,
      netOpenPo: netOpenPoValue,
      filters: filtersToSend,
    };

    getValuesATSinPayload();

    if (item.value.statusTable === 'summarized') {
      this.disabledSaveButton = true;

      this.atsAndNop = valueToUpdate;
      if (this.atsAndNop.value > 0) {
        this.spinner.show();
        this.isLoading = true;
      }

      const hasLastValue = this.lastValueDistribution.filter(
        (value: any) =>
          value.col === item.value?.indexCol &&
          value.customerShortId === item.value?.rowWeek?.customerShortId &&
          value.value > 0
      );
      if (!hasLastValue.length) {
        this.lastValueDistribution.push({
          col: item.value?.indexCol,
          value: valueTyped,
          customerShortId: item.value?.rowWeek?.customerShortId,
        });
      }
      const valueToReset: any = Object.assign({}, valueToUpdate);
      valueToReset.value = 0;

      this.dataSourceTable[INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ]?.expanded.forEach((rows: any) => {
        rows.rows.forEach((row: any) => {
          this.origins.forEach((origin: any) => {
            if (row?.enableATS) {
              if (origin.value && row.origin === 'JAG') {
                const atsJAG: any = {
                  atsJAG: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToReset, atsJAG);
              }
              if (origin.value && row.origin === 'MAN') {
                const atsMAN: any = {
                  atsMAN: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToReset, atsMAN);
              }
            }
          });
        });
      });

      this.setCrudService
        .createEntity(valueToReset)
        .subscribe((response: any) => {
          this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
            (expanded: any) => {
              expanded.rows.forEach((product: any) => {
                const itemFilter: any = response?.result.filter(
                  (obj: any) =>
                    obj.key === product.value[item.value.indexCol].key
                );

                if (itemFilter.length > 0) {
                  product.value[item.value.indexCol].value =
                    itemFilter[0].value;
                  product.value[item.value.indexCol].edit = true;
                }
              });
            }
          );
          this.dataSourceTable[INDEX_MIDDLE_TABLE].displayedColumns =
            item.displayedColumns;
          this.configMiddleTable(this.dataSourceTable[INDEX_MIDDLE_TABLE]);
          this.startingUpdateTableValuesExpanded();

          getValuesATSinPayload();
          this.getCalculateTaxesValues(valueToUpdate, item);
        });
    }
  }

  checkDiscardAllocation(sourceMethod?: string) {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.discardChanges();
          if (sourceMethod === 'applyFilter') {
            this.getDataTables(this.filter);
          }
        }
      });
    } else {
      this.discardChanges();
      if (sourceMethod === 'applyFilter') {
        this.getDataTables(this.filter);
      }
    }
  }

  saveAllocation() {
    const dialog = this.getDialogModal('save');
    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'save') {
        this.spinner.show();
        const DTOForApi: any = [];

        this.dataSourceTable[INDEX_MIDDLE_TABLE].content[0].expanded.forEach(
          (expanded: any) => {
            expanded.rows
              .filter(
                (obj: any) => obj.enableATS !== true && obj.enableMLS !== true
              )
              .forEach((row: any, indiceRow: any) => {
                const valuesWeek = Object.keys(row.value).map(key => {
                  row.value[key].indice = key;
                  return row.value[key];
                });

                valuesWeek
                  .filter((obj: any) => obj.edit === true)
                  .forEach((week: any) => {
                    DTOForApi.push({
                      id: week.id,
                      materialId: week.idProduct,
                      customerShortId: week.customerShortId,
                      originPlan: week.origin,
                      yearMonth: week.yearMonth,
                      week: week.week,
                      value:
                        typeof week.value === 'string'
                          ? Number(week.value.replaceAll(',', ''))
                          : week.value,
                      customerTaxId: week.taxId,
                    });
                  });
              });
          }
        );
        this.putCrudService.updateEntity('', DTOForApi).subscribe(() => {
          const subtitleMessage = 'Updated successfully!';
          const titleMessage = 'PPM allocation';
          this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
        });

        this.discardChanges();
        this.applyFilter();
      }
    });
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  configMiddleTable(middleTable: any) {
    const editableColumns: any[] = [];
    const columnsNamesHasSubtotal: any[] = [];
    middleTable.headers.forEach((header: any) => {
      if (header.enableContent && header.props !== header.otherContent) {
        editableColumns.push(header.props);
      }
      if (header.hasSubtotal) {
        columnsNamesHasSubtotal.push(header.props);
      }
      if (header.props === 'ppm') {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });

    middleTable.content.map((content: any) => {
      const columnsWeeks = Object.keys(
        content.expanded[0].rows[0].value
      ).filter((column: string) => column.includes('wk'));
      const columnsMonth = Object.keys(
        content.expanded[0].rows[0].value
      ).filter((column: string) => !column.includes('wk'));

      content.expanded.map((expanded: any) => {
        columnsMonth.forEach((columnMonth: string) => {
          expanded.rows.map((row: any) => {
            if (!row.enableMLS) {
              row.value[columnMonth].value = 0;
            }
            return row;
          });
        });

        columnsWeeks.forEach((columnWeek: string) => {
          const columnTotalMonth = columnWeek.split('wk')[0];
          expanded.subtotal[columnWeek] = 0;
          expanded.rows.map((row: any) => {
            if (!row.enableATS && !row.enableMLS) {
              expanded.subtotal[columnWeek] += Number(
                row.value[columnWeek].value
              );
              row.value[columnTotalMonth].value += Number(
                row.value[columnWeek].value
              );
            }
            return row;
          });
        });

        return expanded;
      });

      return content;
    });

    middleTable.hasScroll = true;
    middleTable.style =
      'overflow-x: auto; overflow-y: hidden; min-width: 290px;';
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) return 'FQ1';
    if (month >= 7 && month <= 9) return 'FQ2';
    if (month >= 10 && month <= 12) return 'FQ3';
    return 'FQ4';
  }

  toggleBreadcrumb(toDisable: boolean) {
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/customer-allocation-retail', {
      disable: toDisable,
    });
    this.breadcrumbService.set(
      '/home/customer-allocation-retail/allocation-retail',
      { disable: toDisable }
    );
  }

  verifyClickChangeRoute(event: any) {
    const classElementsChangesRoutes = ['xng-breadcrumb-item ng-star-inserted'];

    const isElementChangeRoute =
      classElementsChangesRoutes.filter((className: string) =>
        event.target.className.includes(className)
      ).length > 0;

    if (isElementChangeRoute && this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      const originUrl = event.target.children[0].origin;
      const routeToNavigate = event.target.children[0].href.replace(
        originUrl,
        ''
      );
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate([routeToNavigate]);
        }
      });
    }
  }

  tabClick(event: any) {
    if (
      event.target.className.includes('mat-tab-label') &&
      this.isEditTableMode
    ) {
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.discardChanges();
          this.changeTab(event.target.innerText);
        }
      });
    } else {
      this.changeTab(event.target.innerText);
    }
  }

  changeTab(label: string) {
    if (this.tabs[label] || this.tabs[label] === 0) {
      this.selectedTabIndex = this.tabs[label];
    }
  }

  changeTabDataPPMTableTab(tab: any) {
    this.getTableEndPage(this.filter, tab);
    this.loadedDataPPMTableTab = tab;
  }

  getTableEndPage(filters: any, tab: any) {
    this.loadedDataPPMTableVisible = false;
    this.loadedDataPPMTableData = [];
    this.totalToBook = 0;
    const newHead: any = [];
    this.loadedDataPPMTableThead.map((row: any, key: any) => {
      newHead.push(row);
    });

    if (tab === 'Sell In') {
      this.spinner.show();
      newHead[2].thead = this.getTheadFromDataPPM(filters);
      this.crudService
        .getEntity('allocation-customer/report-ppm', {
          ...this.filter,
          type: 'sell_in',
        })
        .subscribe((response: any) => {
          if (response.length > 0) {
            this.loadedDataPPMTableThead = newHead;
            this.loadedDataPPMTableData = response;
            this.loadedDataPPMTableTab = tab;
            this.loadedDataPPMTableVisible = true;
            this.loadedDataPPMTableshowMenuColumns = true;
          } else {
            this.loadedDataPPMTableVisible = false;
          }
          this.showTable = true;
          this.spinner.hide();
        });
    } else if (tab === 'Booked') {
      this.spinner.show();
      newHead[1].thead = this.getTheadFromDataPPM(filters);
      this.crudService
        .getEntity('allocation-customer/report-ppm', {
          ...this.filter,
          type: 'booked',
        })
        .subscribe((response: any) => {
          if (response.length > 0) {
            this.loadedDataPPMTableThead = newHead;
            this.loadedDataPPMTableData = response;
            this.loadedDataPPMTableTab = tab;
            this.loadedDataPPMTableVisible = true;
          } else {
            this.loadedDataPPMTableVisible = false;
          }
          this.showTable = true;
          this.spinner.hide();
        });
    } else if (tab === 'To Book') {
      this.spinner.show();
      this.crudService
        .getEntity('allocation-customer/report-ppm/tobook', {
          ...this.filter,
        })
        .subscribe((response: any) => {
          if (response.rows) {
            newHead[0].thead = this.getTheadFromToBook(response.headers);
            this.loadedDataPPMTableData = response.rows;
            this.loadedDataPPMTableTab = tab;
            this.loadedDataPPMTableVisible = true;
            this.loadedDataPPMTableThead = newHead;
            this.loadedDataPPMTableshowMenuColumns = false;
            this.totalToBook = response.totalToBook;
            this.getValuesToBook(response.customerTaxToBooks);
          } else {
            this.loadedDataPPMTableVisible = false;
          }
          this.showTable = true;
          this.spinner.hide();
        });
    } else {
      this.loadedDataPPMTableshowMenuColumns = true;
    }
  }

  getValuesToBook(values: any) {
    if(!this.dataSourceTable.length) {
      return;
    }
    
    const content = this.dataSourceTable[INDEX_END_TABLE].content[0];

    for (const expanded of content.expanded) {
      for (const product of expanded.rows) {
        if (values[product.id]) {
          product.value.toBook.value = values[product.id].value;
        }
      }
    }

    for (const key of Object.keys(values)) {
      const summarized =
        this.dataSourceTable[INDEX_END_TABLE].content[0].summarized;
      for (const item of summarized) {
        for (const product of item.rows) {
          if (
            values[key].ppm === product.ppm &&
            values[key].customerShort === product.customerShort
          ) {
            product.value.toBook.value += parseFloat(values[key].value);
          }
        }
      }
    }

    this.getUpdateSubTotal();
  }

  getUpdateSubTotal() {
    this.dataSourceTable = [];

    Object.keys(this.loadedDataPPM).forEach((keyTable: string) => {
      const editableColumns: any[] = [];
      const columnsNamesHasSubtotal: any[] = [];
      this.loadedDataPPM[keyTable].headers.forEach((header: any) => {
        if (header.enableContent && header.props !== header.otherContent) {
          editableColumns.push(header.props);
        }
        if (header.hasSubtotal || header.title?.includes('Total'))
          columnsNamesHasSubtotal.push(header.props);
        if (header.props === 'ppm') {
          header.typeShowEvent = 'show-columns-rows';
          header.typeHideEvent = 'hide-columns-rows';
          header.contentPpmWidth = true;
        } else {
          header.typeShowEvent = 'show-columns';
          header.typeHideEvent = 'hide-columns';
        }
        return header;
      });

      this.loadedDataPPM[keyTable].content.map(
        (content: any, indexContent: number) => {
          content.summarized?.forEach((summary: any) => {
            summary.subtotal = {};

            columnsNamesHasSubtotal.forEach((columnName: string) => {
              summary.subtotal[columnName] = 0;
              summary.rows.forEach((row: any) => {
                if (!row.enableATS && !row.enableMLS) {
                  summary.subtotal[columnName] += row.value[columnName].value
                    ? Number(row.value[columnName].value)
                    : 0;
                  summary.textAlignCenter = true;
                }
                row.contentAlignCenter = keyTable !== 'introTable';
              });
            });
          });

          content.expanded?.forEach((expand: any) => {
            expand.subtotal = {};
            columnsNamesHasSubtotal.forEach((columnName: string) => {
              expand.subtotal[columnName] = 0;
              expand.rows.forEach((row: any) => {
                if (!row.enableATS && !row.enableMLS) {
                  expand.subtotal[columnName] += Number(
                    row.value[columnName].value
                  );
                  expand.textAlignCenter = true;
                }
                row.contentAlignCenter = keyTable !== 'introTable';
              });
            });
          });

          return content;
        }
      );
      if (keyTable !== 'lastWeekModal')
        this.dataSourceTable.push(this.loadedDataPPM[keyTable]);
    });
    this.dataSourceTable[INDEX_MIDDLE_TABLE].hasScroll = true;
    this.dataSourceTable[INDEX_MIDDLE_TABLE].style =
      'overflow-x: auto; overflow-y: hidden; min-width: 290px;';
    this.dataSourceTable[INDEX_INTRO_TABLE].showSubtotalTitle = true;
    this.dataSourceTable[INDEX_MIDDLE_TABLE].showSubtotalTitle = false;
    this.dataSourceTable[INDEX_END_TABLE].showSubtotalTitle = false;
  }

  getTheadFromDataPPM(filters: any) {
    const dataSplit: any = [];
    const data = [
      {
        label: 'PPM',
        key: 'ppm',
      },
      {
        label: 'Origin',
        key: 'origin',
      },
      {
        label: 'Customer Short',
        key: 'customer_short',
      },
      {
        label: 'Channel',
        key: 'channel',
      },
    ];
    dataSplit.push(data);

    const weeks: any = [];
    this.getMonthsWeeksForQuarter(filters.quarter).map((row: any) => {
      weeks.push(row);
    });
    weeks.push({
      label: 'Total Quarter',
      key: 'totalQuarter',
    });
    dataSplit.push(weeks);

    return dataSplit;
  }

  getTheadFromToBook(weeks: any) {
    const dataSplit: any = [];

    const data = [
      {
        label: 'PPM',
        key: 'ppm',
        description: ' ',
      },
      {
        label: 'Origin',
        key: 'origin',
        description: ' ',
      },
      {
        label: 'Customer Short',
        key: 'customerShort',
        description: ' ',
      },
      {
        label: 'Taxes',
        key: 'tax',
        description: '',
      },
    ];

    dataSplit.push(data);

    const weeksAll: any = [];
    if (weeks !== undefined) {
      weeks.map((row: any) => {
        weeksAll.push(row);
      });
    }

    dataSplit.push(weeksAll);

    return dataSplit;
  }

  getMonthsWeeksForQuarter(quarter: any) {
    const data: any = [];
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];

    if (quarter === 'FQ1') {
      data.push({
        label: months[3],
        key: months[3],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[3] + '_WK0' + i,
          parent: months[3],
        });
      }
      data.push({
        label: months[4],
        key: months[4],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[4] + '_WK0' + i,
          parent: months[4],
        });
      }
      data.push({
        label: months[5],
        key: months[5],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[5] + '_WK0' + i,
          parent: months[5],
        });
      }
    } else if (quarter === 'FQ2') {
      data.push({
        label: months[6],
        key: months[6],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[6] + '_WK0' + i,
          parent: months[6],
        });
      }
      data.push({
        label: months[7],
        key: months[7],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[7] + '_WK0' + i,
          parent: months[7],
        });
      }
      data.push({
        label: months[8],
        key: months[8],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[8] + '_WK0' + i,
          parent: months[8],
        });
      }
    } else if (quarter === 'FQ3') {
      data.push({
        label: months[9],
        key: months[9],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[9] + '_WK0' + i,
          parent: months[9],
        });
      }
      data.push({
        label: months[10],
        key: months[10],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[10] + '_WK0' + i,
          parent: months[10],
        });
      }
      data.push({
        label: months[11],
        key: months[11],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[11] + '_WK0' + i,
          parent: months[11],
        });
      }
    } else if (quarter === 'FQ4') {
      data.push({
        label: months[0],
        key: months[0],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[0] + '_WK0' + i,
        });
      }
      data.push({
        label: months[1],
        key: months[1],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[1] + '_WK0' + i,
        });
      }
      data.push({
        label: months[2],
        key: months[2],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[2] + '_WK0' + i,
        });
      }
    }

    return data;
  }

  checkSimpleOrNot(event: any) {
    if (event.srcElement.checked) {
      this.simpleViewOrNot = event.srcElement.checked;
      if (this.enableApplyFilter) {
        this.applyFilter();
      }
    } else {
      this.simpleViewOrNot = event.srcElement.checked;
      if (this.enableApplyFilter) {
        this.applyFilter();
      }
    }
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate([
            'home/customer-allocation-retail/allocation-retail',
          ]);
        }
      });
    } else {
      this.router.navigate([
        'home/customer-allocation-retail/allocation-retail',
      ]);
    }
  }

  checkCurrentYearAndQuarter() {
    if (!this.filter.fiscalYear && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }
}
