<div class="navbar">
  <div class="navbar-logo">
    <img src="../../../assets/images/logo-motorola.svg" alt="My ICON SVG" />
    <span class="navbar-title">GLOBAL CUSTOMER ALLOCATION</span>
  </div>
  <div *ngIf="hasUserPermission" class="navbar-user">
    <span class="navbar-username"
      >Hi, {{ this.userDatails.lastName }} {{ this.userDatails.name }} !</span
    >
    <div class="navbar-signout">
      <div class="navbar-signout-icon">
        <span (click)="signOut()" matTooltip="Sign Out"> </span>
      </div>
    </div>
  </div>
</div>
