import {
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';

@Component({
  selector: 'app-view-table',
  templateUrl: './view-table.component.html',
  styleUrls: ['./view-table.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class ViewTableComponent implements OnInit {
  @Input() dataTable: any = {};
  @Input() dataTerritory: any = {};

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dataTerritory?.currentValue) {
      this.dataTerritory = changes.dataTerritory.currentValue;
    }

    if (changes?.dataTable?.currentValue) {
      this.dataTable = changes.dataTable.currentValue;

      this.dataTable.forEach((obj: any) => {
        obj.value.sort();
      });
    }
  }
}
