export const customerSettingsFiltersMockList = [{
  geo_id: 2,
  geo: "EMEA",
  country: "GB",
  customer: "02 (UK)",
  customer_number: "1215134800"
}, {
  geo_id: 10,
  geo: "EMEA",
  country: "ZA",
  customer: "3G Mobile Pty LtdRTT - Head office, HVDC Building",
  customer_number: "1214798495"
}, {
  geo_id: 27,
  geo: "EMEA",
  country: "AE",
  customer: "3Ns Telecom FZCO",
  customer_number: "1216589946"
}]

export const customerSettingsTableMockList: any = {
  table: {
    headers: [
      {
        title: 'Geo',
        props: 'geo'
      },
      {
        title: 'Country',
        props: 'country'
      },
      {
        title: 'Customer',
        props: 'customer'
      },
      {
        title: 'Customer Number',
        props: 'customer_number'
      },
    ],
    totalRows: 0,
    rows: [
      {
        id: 10,
        geo: 'EMEA',
        country: "Iberia",
        customer: {
          value: '02 (UK)',
        },
        customer_number: {
          value: '1215134800',
        },
        // geo_id: 10,
        status: true
      },
      // {
      //   id: 83,
      //   geo: 'EMEA',
      //   countries: {
      //     country: "",
      //     geo_id: 2
      //   },
      //   customer: {
      //     value: '3G Mobile Pty LtdRTT - Head office, HVDC Building',
      //   },
      //   customer_number: {
      //     value: '1214798495',
      //   },
      // },
      // {
      //   id: 89,
      //   geo: 'EMEA',
      //   countries: {
      //     country: "",
      //     geo_id: 10
      //   },
      //   customer: {
      //     value: '3Ns Telecom FZCO',
      //   },
      //   customer_number: {
      //     value: '1216589946',
      //   },
      // },
    ],

    totalPages: 0,
    page: 0
  }
}
