import { HttpClient } from '@angular/common/http';
import {
  Component,
  HostListener,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CoordinatesModule } from 'ngx-color';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import {
  ActionEventData,
  DataRow,
  EntityItem,
} from 'src/app/interfaces/ka-execution-interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { TableMarketName } from './table-market-name-mockup';
import { CONSTS } from '../../../../../assets/constants/consts';

import { TableBooked } from './table-booked-mockup';
import { TableSellIn } from './table-sell-in-mockup';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import { ExcelService } from 'src/app/services/generic/excel.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Material } from 'src/app/interfaces/material.interface';
import { RemovePastWeekService } from 'src/app/helpers/remove-past-week.service';
import { DownloadFileService } from 'src/app/services/generic/download-file.service';
import * as FileSaver from 'file-saver';
import {
  DynamicData,
  NetOpenPoData,
} from 'src/app/interfaces/netOpenPo.interface';
import { columnsStyle } from 'src/app/components/table/table.interface';
import { ModalComponent } from 'src/app/components/modal-table/modal-table.component';
import { MatDialog } from '@angular/material/dialog';
import { TableManagerMarketNameComponent } from 'src/app/components/table-manager-market-name/table-manager-market-name.component';
import { Subscription } from 'rxjs';
import { UtilitiesService } from 'src/app/services/generic/utilities.service';

@Component({
  selector: 'app-family-name',
  templateUrl: './family-name.component.html',
  styleUrls: ['./family-name.component.scss'],
})
export class FamilyNameComponent implements OnInit {
  @ViewChild('tableManagerMarketName') tableManagerMarketName:
  | TableManagerMarketNameComponent
  | undefined
  | any;
  @ViewChild('dialogConfirmationDiscardSaveChanges')
  dialogConfirmationDiscardSaveChanges: any;
  crudService: CrudService<any>;
  downloadFileService: DownloadFileService<any> | undefined;
  putCrudService: any;
  setCrudService: any;
  entities: EntityItem[];
  searchInputs!: InputBase<string>[];
  disableApply: boolean = false;
  currentUser!: any;
  formSearchMarketName!: ActionEventData;
  filter!: ActionEventData;
  initialValueGeoFilter: any[] = [];
  initialValueFamilyFilter: any[] = [];
  initialValueCountryFilter: any[] = [];
  responseFilters: Array<any>;
  thead: any = [
    { label: 'To Book', status: true },
    { label: 'Booked', status: false },
    { label: 'Sell In', status: false },
  ];
  dataBooked: any;
  columnsBooked: any;
  dataSellIn: any;
  columnsSellIn: any;
  disabledButtonExport: boolean = false;
  simpleViewOrNot: boolean = false;
  allocationLabels: { [key: string]: any } = {};
  showNoResults: boolean = false;
  noFilterSelected: boolean = true;
  showTable: boolean = false;
  valuesCustomerTaxToBooks: { [key: string]: any } = {};
  tableResult: any = TableMarketName;
  eventTable!: string;
  key!: number;
  origins: any[] = [
    { id: 1, value: 'JAG' },
    { id: 2, value: 'MAN' },
  ];
  loadedDataTableData: any;
  dataSourceTable: any[] = [];
  tooltipMessage: String = 'Export';
  viewButtonSave: boolean = true;
  isEditTableMode: boolean = false;
  messageModalChanges!: string;
  messages: { [key: string]: string } = {
    save: 'Do you confirm new changes on allocation?',
    discard: 'Are you sure you want to exit before saving your changes?',
    export: 'This process will take a few minutes. Do you confirm?',
  };
  isDiscardChanges = false;
  disabledSaveButton: boolean = false;
  translateYAbsoluteItem: number = 0;
  valuesMlsTotalAllocation: { col: string; value: number }[] = [];
  valuesMLSsummarized: { col: string; value: number }[] = [];
  valuesDeltaSummarized: { col: string; value: number }[] = [];
  valuesSubstractionSummarized: { col: string; value: number }[] = [];
  valuesSubTotalSummarized: any = [{}];
  valuesTotalAllocation: { col: string; value: number }[] = [];

  valuesMLSexpanded: { col: string; value: number; origin: string }[] = [];
  valuesSubstractionExpanded: { col: string; value: number; origin: string }[] =
    [];
  valuesMlsTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  valuesDeltaExpanded: { col: string; value: number; origin: string }[] = [];
  valuesTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  currentYear!: number;
  currentQuarter!: string;

  isSubTotalEmpty: boolean = true;
  applyTTo: boolean;
  changeTab: boolean;
  filterBooked: any;
  filterSellIn: any;
  totalToBook: any = 0;
  valuesSubTotal: any = [{}];
  isLoading = false;
  atsAndNop: any;
  lastValueDistribution: {
    col: string;
    value: number;
    customerShortId: string;
  }[] = [];
  familyEntities: any[] = [];
  customerShortEntities: any[] = [];
  salesManagerEntities: any[] = [];
  orderAdminEntities: any[] = [];
  originEntities: any[] = [];
  fiscalYearEntities: any[] = [];
  fiscalQuarterEntities: any[] = [];
  geoEntities: any[] = [];
  countriesEntities: any[] = [];
  clickScreenSub!: Subscription;

  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private excelSheetsService: ExcelSheetsService,
    private breadcrumbService: BreadcrumbService,
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private router: Router,
    private removePastWeekService: RemovePastWeekService,
    public dialog: MatDialog,
    private utilitiesService: UtilitiesService,
  ) {
    this.crudService = new CrudService<any>(this.http, 'marketName');
    this.putCrudService = new CrudService<any>(
      this.http,
      'allocation-customer'
    );
    this.setCrudService = new CrudService<Material>(
      this.http,
      'allocation-customer/calculate-taxes-values'
    );
    this.downloadFileService = new DownloadFileService<any>(this.http);
    this.entities = this.getEntities();
    this.searchInputs = this.createSearchInputs();
    this.responseFilters = [];
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;
    this.filter = {
      ...this.filter,
      fiscalYear: this.currentYear,
      quarter: `F${this.currentQuarter}`,
    };
    this.applyTTo = false;
    this.changeTab = false;
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    await this.getDataFilter();
    this.responseResultBookedAndSellIn();
  }

  async dataBookedAndSellIn(params?: any) {
    this.columnsSellIn = TableSellIn.headers;
    this.dataSellIn = TableSellIn.rows;
  }

  ngAfterViewInit() {
    this.toggleBreadcrumb(false);
    this.clickScreenSub = this.utilitiesService.documentClickedTarget.subscribe(
      (target: any) => this.verifyClickChangeRoute(target)
    );
  }

  ngOnDestroy() {
    this.toggleBreadcrumb(false);
    this.clickScreenSub.unsubscribe();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const clientHeightTop = appHeaderHeight + breadCrumbHeight + headerTitle;
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const offsetTopElement =
      this.tableResult?.tableDynamic?.nativeElement?.offsetTop +
      clientHeightTop;

    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
    const tableHeight =
      document.getElementsByClassName('table-dynamic')[0]?.clientHeight;
    const showButtonCancel = tableHeight - 600;

    if (this.translateYAbsoluteItem >= showButtonCancel) {
      this.viewButtonSave = false;
    } else {
      this.viewButtonSave = true;
    }
  }

  receiveData(data: any) {
  }
  responseResultBookedAndSellIn(response?: any) {
    
    this.totalToBook = response?.totalToBook;
    if (this.showTable) this.spinner.hide();
  }

  getValuesToBook(): void {
    if(this.valuesCustomerTaxToBooks === undefined || Object.entries(this.valuesCustomerTaxToBooks).length === 0) return;

    this.dataSourceTable[CONSTS.INDEX_END_TABLE]?.content.forEach((content: any) => {
      content.expanded.forEach(
      (expanded: any) => {
        expanded?.rows.forEach((product: any) => {
          if (this.valuesCustomerTaxToBooks[product.id]) {
            product.value.toBook.value = this.valuesCustomerTaxToBooks[product.id].value;
          }
        });
      });
    });

    for (const key of Object.keys(this.valuesCustomerTaxToBooks)) {
      this.dataSourceTable[CONSTS.INDEX_END_TABLE]?.content.forEach((content: any) => {
        content.summarized.forEach(
        (summarized: any) => {
          summarized?.rows.forEach((product: any) => {
            if (
              this.valuesCustomerTaxToBooks[key].family.includes(product.ppm) &&
              this.valuesCustomerTaxToBooks[key].customerShort === product.customerShort
            ) {
              product.value.toBook.value += parseFloat(this.valuesCustomerTaxToBooks[key].value);
            }
          });
        });
      });
    }
  }

  getEntities() {
    return (this.entities = [
      {
        entity: 'material-family',
        query: { pageName: PAGE_NAME_RESOURCE.toBook },
      },
      {
        entity: 'customer-short',
        query: {
          status: true,
          sort: 'customerShort',
          pageName: PAGE_NAME_RESOURCE.allocationRetail,
        },
      },
      {
        entity: 'user',
        query: {
          status: true,
          sales_manager: true,
          sort: 'last_name',
          pageName: PAGE_NAME_RESOURCE.allocationRetail,
        },
      },
      {
        entity: 'user',
        query: {
          status: true,
          order_admin: true,
          sort: 'last_name',
          pageName: PAGE_NAME_RESOURCE.allocationRetail,
        },
      },
      {
        entity: 'geo',
        query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.allocationRetail },
      },
      {
        entity: 'country',
        query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.profile },
      },
      {
        entity: 'setupManagement/currentSetup',
        query: { pageName: PAGE_NAME_RESOURCE.allocationRetail },
      },
      {
        entity: 'setupManagement/currentSetup',
        query: { pageName: PAGE_NAME_RESOURCE.allocationRetail },
      },
    ]);
  }

  async getDataFilter(): Promise<void> {
    this.spinner.show();
    try {
      const customerShortFilter: any[] = [];
      const salesManagerFilter: any[] = [];
      const orderAdminFilter: any[] = [];
      const geosFilter: any[] = [];
      const countrysFilter: any[] = [];

      [this.currentUser] = await this.authService.getUserPermissions();

      this.responseFilters = await this.crudService
        .getDataFilters(this.entities)
        .toPromise();

      this.familyEntities = this.responseFilters[0];
      this.customerShortEntities = this.responseFilters[1];
      this.salesManagerEntities = this.responseFilters[2];
      this.orderAdminEntities = this.responseFilters[3];
      this.geoEntities = this.responseFilters[4];
      this.countriesEntities = this.responseFilters[5];
      this.allocationLabels = this.removePastWeekService.removePastWeek(
        this.responseFilters[6]
      );

      this.familyEntities.forEach((family: any) => {
        this.initialValueFamilyFilter.push({
          value: family.family,
          materialId: family.ids,
        });
      });

      this.customerShortEntities.forEach((customerShort: any) => {
        customerShortFilter.push({
          value: customerShort.customerShort,
          id: customerShort.id,
        });
      });

      this.salesManagerEntities.forEach((salesManager: any) => {
        const viewName: string = `${salesManager.last_name}, ${salesManager.name} (${salesManager.email})`;
        salesManagerFilter.push({ value: viewName, id: salesManager.id });
      });

      this.orderAdminEntities.forEach((orderManager: any) => {
        const viewName: string = `${orderManager.last_name}, ${orderManager.name}`;
        orderAdminFilter.push({ value: viewName, id: orderManager.id });
      });

      this.geoEntities.forEach((geo: any) => {
        geosFilter.push({ value: geo.geo, id: geo.id });

        if (geo.id === this.currentUser.geoId) {
          this.initialValueGeoFilter.push({ value: geo.geo, id: geo.id });
        }
      });

      this.countriesEntities.forEach((country: any) => {
        countrysFilter.push({ value: country.country, id: country.id });

        if (country.id === this.currentUser.countryId) {
          this.initialValueCountryFilter.push({
            value: country.country,
            id: country.id,
          });
        }
      });

      this.createSearchInputs(
        this.initialValueFamilyFilter,
        customerShortFilter,
        salesManagerFilter,
        orderAdminFilter,
        geosFilter,
        countrysFilter,
        this.initialValueGeoFilter,
        this.initialValueCountryFilter
      );

      this.spinner.hide();
    } catch (error) {
      console.error(error);
      this.spinner.hide();
    }
  }

  getDataTable(params?: any): any {
    this.resetValues();
    this.eventTable = 'hide-columns-rows';
    this.tableResult = JSON.parse(JSON.stringify(this.tableResult));
    this.getUpdateTable(params);
  }

  private resetValues() {
    this.loadedDataTableData = [];
    this.dataSourceTable = [];
    this.isEditTableMode = false;
    this.showTable = false;
    this.noFilterSelected = true;
    this.showNoResults = false;
  }

  getUpdateTable(params: any): void {
    this.spinner.show();
    this.crudService
      .getEntity('allocation-customer/family-name', params)
      .subscribe(
        (response: any) => {
          if (response.introTable.content.length > 0) {
            this.verifyPermissionEdit(response);
            this.loadedDataTableData = response;
            this.loadedDataTableData = JSON.parse(
              JSON.stringify(this.loadedDataTableData)
            );
            // this.getUpdateSubTotal();
            this.getUpdateSubTotal();
            this.getValuesSubTotal(params);
          } else {
            this.disabledButtonExport = true;
            this.showNoResults = true;
            this.noFilterSelected = false;
            this.spinner.hide();
          }
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
        }
      );
  }

  getTableToBook() {
    // this.valuesCustomerTaxToBooks = {};
    
    this.crudService
      .getEntity(`allocation-customer/report-ppm/tobook`, { ...this.filter, pageName: PAGE_NAME_RESOURCE.familyView })
      .subscribe((response: any) => {
        if (Object.keys(response).length > 0) {
          this.valuesCustomerTaxToBooks = response?.customerTaxToBooks
          this.getValuesToBook();
          this.getUpdateSubTotal();
        }
          this.disabledButtonExport = false;
          this.noFilterSelected = false;
          this.showNoResults = false;
          this.showTable = true;
          this.spinner.hide();
      });
  }

  getUpdateSubTotal() {
    this.dataSourceTable = [];

    Object.keys(this.loadedDataTableData).forEach((keyTable: string) => {
      const editableColumns: any[] = [];
      const columnsNamesHasSubtotal: any[] = [];
      this.loadedDataTableData[keyTable].headers.forEach((header: any) => {
        if (header.enableContent && header.props !== header.otherContent) {
          editableColumns.push(header.props);
        }
        if (header.hasSubtotal || header.title?.includes('Total'))
          columnsNamesHasSubtotal.push(header.props);
        if (header.props === 'ppm') {
          header.typeShowEvent = 'show-columns-rows';
          header.typeHideEvent = 'hide-columns-rows';
          header.contentPpmWidth = true;
        } else {
          header.typeShowEvent = 'show-columns';
          header.typeHideEvent = 'hide-columns';
        }
        return header;
      });

      this.loadedDataTableData[keyTable].content.map(
        (content: any, indexContent: number) => {
          content.summarized?.forEach((summary: any) => {
            summary.subtotal = {};

            columnsNamesHasSubtotal.forEach((columnName: string) => {
              summary.subtotal[columnName] = 0;
              summary.rows.forEach((row: any) => {
                if (!row.enableATS && !row.enableMLS) {
                  summary.subtotal[columnName] += row?.value[columnName]?.value
                    ? Number(row.value[columnName].value)
                    : 0;
                  summary.textAlignCenter = true;
                }
                row.contentAlignCenter = keyTable !== 'introTable';
              });
            });
          });

          content.expanded?.forEach((expand: any) => {
            expand.subtotal = {};
            columnsNamesHasSubtotal.forEach((columnName: string) => {
              expand.subtotal[columnName] = 0;
              expand.rows.forEach((row: any) => {
                if (!row.enableATS && !row.enableMLS) {
                  expand.subtotal[columnName] += Number(
                    row?.value[columnName]?.value
                  );
                  expand.textAlignCenter = true;
                }
                row.contentAlignCenter = keyTable !== 'introTable';
              });
            });
          });

          return content;
        }
      );
      if (keyTable !== 'lastWeekModal')
        this.dataSourceTable.push(this.loadedDataTableData[keyTable]);
    });

    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].hasScroll = true;
    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].style =
      'overflow-x: auto; overflow-y: hidden; min-width: 290px;';
    this.dataSourceTable[CONSTS.INDEX_INTRO_TABLE].showSubtotalTitle = true;
    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].showSubtotalTitle = false;
    this.dataSourceTable[CONSTS.INDEX_END_TABLE].showSubtotalTitle = false;
  }

  configMiddleTable(middleTable: any) {
    const editableColumns: any[] = [];
    const columnsNamesHasSubtotal: any[] = [];
    middleTable.headers.forEach((header: any) => {
      if (header.enableContent && header.props !== header.otherContent) {
        editableColumns.push(header.props);
      }
      if (header.hasSubtotal) {
        columnsNamesHasSubtotal.push(header.props);
      }
      if (header.props === 'ppm') {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });

    middleTable.content.map((content: any) => {
      const columnsWeeks = Object.keys(
        content.expanded[0].rows[0].value
      ).filter((column: string) => column.includes('wk'));
      const columnsMonth = Object.keys(
        content.expanded[0].rows[0].value
      ).filter((column: string) => !column.includes('wk'));

      content.expanded.map((expanded: any) => {
        columnsMonth.forEach((columnMonth: string) => {
          expanded.rows.map((row: any) => {
            if (!row.enableMLS) {
              row.value[columnMonth].value = 0;
            }
            return row;
          });
        });

        columnsWeeks.forEach((columnWeek: string) => {
          const columnTotalMonth = columnWeek.split('wk')[0];
          expanded.subtotal[columnWeek] = 0;
          expanded.rows.map((row: any) => {
            if (!row.enableATS && !row.enableMLS) {
              expanded.subtotal[columnWeek] += Number(
                row.value[columnWeek].value
              );
              row.value[columnTotalMonth].value += Number(
                row.value[columnWeek].value
              );
            }
            return row;
          });
        });

        return expanded;
      });

      return content;
    });

    middleTable.hasScroll = true;
    middleTable.style =
      'overflow-x: auto; overflow-y: hidden; min-width: 290px;';
  }

  verifyPermissionEdit(data: any) {
    const userPermission = this.authService.getUserPermissions();
    const canEditAllocation = userPermission.some(
      (permission: any) =>
        permission.resource === 'allocation-retail' &&
        (permission.permission === 'UPDATE' ||
          permission.permission === 'CREATE')
    );

    if (!canEditAllocation) {
      // Disable inputs for non-ATS/MLS rows in expanded content
      data.middleTable.content[0].expanded.forEach((row: any) => {
        Object.values(row.rows).forEach((cell: any) => {
          if (!cell.enableATS && !cell.enableMLS) {
            Object.values(cell.value).forEach((value: any) => {
              if (value.enableInput) value.enableInput = false;
            });
          }
        });
      });

      // Disable inputs for non-ATS/MLS rows in summarized content
      const currentMonthOrWeek = (header: any) =>
        header.currentMonth ||
        header.weekInTime === 'current' ||
        header.weekInTime === 'next';
      data.middleTable.headers.forEach((header: any) => {
        data.middleTable.content[0].summarized[0].rows.forEach((row: any) => {
          Object.entries(row.value).forEach(([prop, cell]: any) => {
            if (
              currentMonthOrWeek(header) &&
              cell.customerShort !== 'ATS' &&
              cell.customerShort !== 'MLS'
            ) {
              if (cell.enableInput) cell.enableInput = false;
            }
          });
        });
      });
    }
  }

  updateSummarizedTable(item: any) {
    const getValuesATSinPayload = (): void => {
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ]?.expanded.forEach((rows: any) => {
        rows.rows.forEach((row: any) => {
          this.origins.forEach((origin: any) => {
            if (row?.enableATS) {
              if (origin.value && row.origin === 'JAG') {
                const atsJAG: any = {
                  atsJAG: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToUpdate, atsJAG);
              }
              if (origin.value && row.origin === 'MAN') {
                const atsMAN: any = {
                  atsMAN: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToUpdate, atsMAN);
              }
            }
          });
        });
      });
    };

    const familyName =
      this.dataSourceTable[CONSTS.INDEX_INTRO_TABLE].content[
        item.value.indexContent
      ]?.summarized[0].rows[2].value.ppm.value;

    const materialId: any[] = [];
    const expandedContent =
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ]?.expanded;

    if (expandedContent.length > 1) {
      if(expandedContent[0].rows.length > 1) {
        const idProductJAG: any = Object.values(
          expandedContent[0]?.rows[2]?.value
        )[0];
        materialId.push(idProductJAG?.idProduct);
      }
      if(expandedContent[1].rows.length > 2) {
        const idProductMAN: any = Object.values(
          expandedContent[1]?.rows[2]?.value
        )[0];
        materialId.push(idProductMAN?.idProduct);
      }
    } else {
      const idProduct: any = Object.values(
        expandedContent[0]?.rows[2]?.value
      )[0];
      materialId.push(idProduct?.idProduct);
    }

    const { idPpm } =
      this.loadedDataTableData.middleTable.content[item.value.indexContent];
    const valueMiddleTableRow =
      this.loadedDataTableData.middleTable.content[item.value.indexContent]
        .summarized[0].rows[item.value.indexRow].value[item.value.indexCol];
    const valueEndTableRow =
      this.loadedDataTableData.endTable.content[item.value.indexContent]
        .summarized[0].rows[item.value.indexRow].value;
    const monthWeek = item.value.indexCol.split('wk')[0];
    // const atsValue = Number(
    //   this.dataSourceTable[INDEX_MIDDLE_TABLE].content[item.value.indexContent]
    //     .summarized[0].rows[0].value[item.value.indexCol].value
    // );

    const valueTyped = item.value.valueTyped
      ? parseInt(item.value.valueTyped.toString().replaceAll(',', ''))
      : 0;
    const netOpenPoValue = Number(valueEndTableRow.netOpenPo.value);
    const filtersToSend = { ...this.filter, familyName };
    filtersToSend.customerShortId = this.filter.customerShortId.toString();
    filtersToSend.materialId = materialId;

    const valueToUpdate: any = {
      customerShort: valueMiddleTableRow.customerShort,
      customerShortId: valueMiddleTableRow.customerShortId,
      idPpm,
      familyName,
      value: valueTyped,
      month: monthWeek,
      week: item.value.rowWeek.week,
      yearMonth: item.value.rowWeek.yearMonth,
      netOpenPo: netOpenPoValue,
      filters: filtersToSend,
    };

    getValuesATSinPayload();

    if (item.value.statusTable === 'summarized') {
      this.disabledSaveButton = true;

      this.atsAndNop = valueToUpdate;
      if (this.atsAndNop.value > 0) {
        this.spinner.show();
        this.isLoading = true;
      }

      const hasLastValue = this.lastValueDistribution.filter(
        (value: any) =>
          value.col === item.value?.indexCol &&
          value.customerShortId === item.value?.rowWeek?.customerShortId &&
          value.value > 0
      );
      if (!hasLastValue.length) {
        this.lastValueDistribution.push({
          col: item.value?.indexCol,
          value: valueTyped,
          customerShortId: item.value?.rowWeek?.customerShortId,
        });
      }
      const valueToReset: any = Object.assign({}, valueToUpdate);
      valueToReset.value = 0;

      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ]?.expanded.forEach((rows: any) => {
        rows.rows.forEach((row: any) => {
          this.origins.forEach((origin: any) => {
            if (row?.enableATS) {
              if (origin.value && row.origin === 'JAG') {
                const atsJAG: any = {
                  atsJAG: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToReset, atsJAG);
              }
              if (origin.value && row.origin === 'MAN') {
                const atsMAN: any = {
                  atsMAN: Number(row?.value[item.value.indexCol]?.value),
                };
                Object.assign(valueToReset, atsMAN);
              }
            }
          });
        });
      });

      this.setCrudService
        .createEntity(valueToReset)
        .subscribe((response: any) => {
          this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content.forEach(
            (content: any) => {
              content.expanded.forEach((expanded: any) => {
                expanded.rows.forEach((product: any) => {
                  const itemFilter: any = response?.result.filter(
                    (obj: any) =>
                      obj.key === product.value[item.value.indexCol].key
                  );

                  if (itemFilter.length > 0) {
                    product.value[item.value.indexCol].value =
                      itemFilter[0].value;
                    product.value[item.value.indexCol].edit = true;
                  }
                });
              });
              this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].displayedColumns =
                item.displayedColumns;
              this.configMiddleTable(
                this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE]
              );
              this.startingUpdateTableValuesExpanded(item.value.indexContent, valueToUpdate.idPpm);

              getValuesATSinPayload();
            }
          );
          this.getCalculateTaxesValues(valueToUpdate, item);
        });
    }
  }

  getCalculateTaxesValues(params: any, item: any) {
    const updateMiddleTableValues = () => {
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].displayedColumns =
        item.displayedColumns;
      this.configMiddleTable(this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE]);
      this.disabledSaveButton = true;
    };

    const resetInsertedValueToTaxes = () => {
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE]?.content.forEach(
        (content: any) => {
          content?.summarized.forEach((summarized: any) => {
            summarized?.rows.forEach((row: any) => {
              if (!row.enableATS && !row.enableMLS) {
                if (
                  row.value[item.value.indexCol].customerShortId ===
                  params.customerShortId
                ) {
                  row.value[item.value.indexCol].value = 0;
                }
              }
            });
          });
        }
      );

      updateMiddleTableValues;
      this.startingUpdateTableValuesSummarized(item.value.indexContent, params.idPpm);
      this.getUpdateSubTotal();

      const valuesSubTotal =
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[item.value.indexContent].summarized[0]
          .subtotal;
      this.tableManagerMarketName?.updateValuesTable(params, item, valuesSubTotal);
    };

    const updateValueAfterTaxes = (totalResult: any) => {
      // this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE]?.content.forEach((content: any) => {
      //   content.summarized.forEach(
      //     (summarized: any) => {
      //       summarized?.rows.forEach((row: any) => {
      //         if (!row.enableATS && !row.enableMLS) {
      //           if (
      //             row.value[item.value.indexCol].customerShortId ===
      //             params.customerShortId
      //           ) {
      //             row.value[item.value.indexCol].value = Number(totalResult);
      //           }
      //         }
      //       });
      //     }
      //   );
      // });

      updateMiddleTableValues;
      // this.startingUpdateTableValuesSummarized();
      this.getUpdateSubTotal();

      // const valuesSubTotal =
      //   this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[0].summarized[0]
      //     .subtotal;
      // this.tableManager?.updateValuesAfterTaxesTable(
      //   params,
      //   item,
      //   valuesSubTotal,
      //   totalResult
      // );
    };

    const updateValuesTotalMonth = (): void => {
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].summarized[0].rows.forEach((row: any, indexRow: number) => {
        row.value[item.value.columnNameMonth].value =
          item.content.summarized[0].rows[indexRow].value[
            item.value.columnNameMonth
          ].value;
      });

      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].expanded[0].rows.forEach((row: any, indexRow: number) => {
        row.value[item.value.columnNameMonth].value =
          item.content.expanded[0].rows[indexRow].value[
            item.value.columnNameMonth
          ].value;
      });

      this.getUpdateSubTotal();

      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].summarized[0].subtotal[item.value.indexCol] =
        item.content.summarized[0].subtotal[item.value.indexCol];

      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
        item.value.indexContent
      ].expanded[0].subtotal[item.value.indexCol] =
        item.content.expanded[0].subtotal[item.value.indexCol];
    };

    if (this.atsAndNop.value > 0) {
      this.setCrudService.createEntity(params).subscribe((response: any) => {

        for (const content of this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE]?.content || []) {
          for (const expanded of content.expanded || []) {
            for (const product of expanded.rows || []) {
              const matchingResult = response.result.find((result: any) => result.key === product.value[item.value.indexCol].key);
              if (matchingResult) {
                product.value[item.value.indexCol].value = matchingResult.value;
                product.value[item.value.indexCol].edit = true;
              }
            }
          }
        }

        updateMiddleTableValues;
        this.isEditTableMode = true;
        this.disabledSaveButton = false;
        this.isLoading = false;
        this.spinner.hide();

        const titleMessage = 'Family Name Allocation';
        switch (response?.message.status) {
          case 'SUCCESS':
            this.toastr.success(
              `${response.message.value}`,
              `${titleMessage}`,
              { timeOut: 10000, enableHtml: true }
            );
            updateValueAfterTaxes(response.totalResult);
            // this.updateTableValuesTotalMonths();
            updateValuesTotalMonth();
            updateMiddleTableValues();
            this.disabledSaveButton = false;
            break;
          case 'WARNING':
            this.toastr.warning(
              `${response.message.value}`,
              `${titleMessage}`,
              { timeOut: 10000 }
            );
            resetInsertedValueToTaxes();
            break;
          case 'ERROR':
            this.toastr.error(`${response.message.value}`, `${titleMessage}`, {
              timeOut: 10000,
            });
            resetInsertedValueToTaxes();
            break;
        }
        this.startingUpdateTableValuesExpanded(item.value.indexContent, params.idPpm);
      });
    } else {
      this.disabledSaveButton = true;
      this.setCrudService.createEntity(params).subscribe((response: any) => {
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content.forEach(
          (content: any) => {
            content.expanded.forEach((expanded: any) => {
              expanded.rows.forEach((product: any) => {
                const itemFilter: any = response?.result.filter(
                  (obj: any) =>
                    obj.key === product.value[item.value.indexCol].key
                );

                if (itemFilter.length > 0) {
                  product.value[item.value.indexCol].value =
                    itemFilter[0].value;
                  product.value[item.value.indexCol].edit = true;
                }
              });
            });
          }
        );

        updateMiddleTableValues();
        updateValuesTotalMonth();
        this.disabledSaveButton = false;
        // this.startingUpdateTableValuesExpanded();
      });
    }
  }

  updateTableValues(item: {
    indexTable: number;
    indexContent: number;
    indexGroup: number;
    content: any;
    statusTable: string;
    columnNameWeek: string;
    columnNameMonth: string;
  }) {
    this.dataSourceTable[item.indexTable].content[
      item.indexContent
    ].summarized[0].rows.forEach((row: any, indexRow: number) => {
      row.value[item.columnNameWeek].value =
        item.content.summarized[0].rows[indexRow].value[
          item.columnNameWeek
        ].value;
    });
    if (item.statusTable === 'expanded') {
      this.dataSourceTable[item.indexTable].content[item.indexContent].expanded[
        item.indexGroup
      ].rows.forEach((row: any, indexRow: number) => {
        row.value[item.columnNameWeek] =
          item.content.expanded[item.indexGroup].rows[indexRow].value[
            item.columnNameWeek
          ];
      });
    } else {
      this.disabledSaveButton = true;
    }

    this.isEditTableMode = true;
    this.toggleBreadcrumb(true);

    const idPpm = this.dataSourceTable[item.indexTable].content[
      item.indexContent
    ].idPpm;

    this.startingUpdateTableValuesSummarized(item.indexContent, idPpm);
    this.disabledSaveButton = false;
  }

  getValuesSubTotal(search: any) {
    this.valuesSubTotal = [];

    this.crudService
      .getEntity('allocation-customer/subtotal', { ...search, pageName: PAGE_NAME_RESOURCE.familyView })
      .subscribe((response: any) => {
        this.valuesSubTotal = response.content;
        if (this.valuesSubTotal.length > 0) {
          this.isSubTotalEmpty = false;
        } else {
          this.isSubTotalEmpty = true;
        }

        this.getTableToBook();
        // this.startingUpdateTableValuesSummarized();
        // this.startingUpdateTableValuesExpanded();

      });
  }

  startingUpdateTableValuesSummarized(indexContent: number, idPpm: number): void {
    this.valuesMlsTotalAllocation = [];

    Object.entries(
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
        .summarized[0].rows[CONSTS.INDEX_MLS].value
    ).forEach((row: any) => {
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].headers.forEach(
        (header: any) => {
          if (header.weekInTime === 'current' || header.weekInTime === 'next') {
            if (row[0] === header.props) {
              const hasColumnInvaluesMLS = this.valuesMLSsummarized.filter(
                (v: any) => v.col === header.props
              );
              if (!hasColumnInvaluesMLS.length)
                this.valuesMLSsummarized.push({
                  col: row[0],
                  value: parseFloat(row[1].value),
                });
              this.valuesMLSsummarized.forEach((key: any) => {
                if (key.col === row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumnInValuesSubstraction =
                this.valuesSubstractionSummarized.filter(
                  (v: any) => v.col == row[0]
                );
              if (!hasColumnInValuesSubstraction.length)
                this.valuesSubstractionSummarized.push({
                  col: row[0],
                  value: 0,
                });
              this.valuesSubstractionSummarized.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumnInValuesDelta = this.valuesDeltaSummarized.filter(
                (v: any) => v.col == row[0]
              );
              if (!hasColumnInValuesDelta.length)
                this.valuesDeltaSummarized.push({ col: row[0], value: 0 });
              this.valuesDeltaSummarized.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = 0;
                }
              });

              const hasColumnInValuesTotalAllocation =
                this.valuesTotalAllocation.filter((v: any) => v.col == row[0]);
              if (!hasColumnInValuesTotalAllocation.length)
                this.valuesTotalAllocation.push({ col: row[0], value: 0 });
              this.valuesTotalAllocation.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = 0;
                }
              });
            }
          }
        }
      );
    });

    /* Armazenando o total de alocações em um array de referência */

    for (
      let i = 0;
      i <
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
        .summarized[0].rows.length;
      i++
    ) {
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].headers.forEach(
        (header: any) => {
          if (
            header.currentMonth ||
            header.weekInTime === 'current' ||
            header.weekInTime === 'next'
          ) {
            Object.entries(
              this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
                indexContent
              ].summarized[0].rows[i].value
            ).forEach((row: any) => {
              if (
                row[1].customerShort !== 'ATS' &&
                row[1].customerShort !== 'MLS'
              ) {
                if (row[0] === header.props) {
                  const hasColumn = this.valuesMlsTotalAllocation.filter(
                    (v: any) => v.col === header.props
                  );
                  if (!hasColumn.length)
                    this.valuesMlsTotalAllocation.push({
                      col: row[0],
                      value: 0,
                    });
                  this.valuesMlsTotalAllocation.forEach((key: any) => {
                    if (key.col === row[0]) {
                      key.col = row[0];
                      key.value += parseFloat(row[1].value);
                    }
                  });
                }
              }
            });
          }
        }
      );
    }

    this.valuesMlsTotalAllocation.forEach((key: any) => {
      this.valuesTotalAllocation.forEach(value => {
        if (key.col === value.col) value.value = key.value;
      });
    });

    /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    Object.entries(
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
        .summarized[0].rows[CONSTS.INDEX_MLS].value
    ).forEach((row: any) => {
      this.valuesMlsTotalAllocation.forEach((key: any) => {
        if (key.col === row[0]) {
          key.value = parseFloat(row[1].value) - parseFloat(key.value);
        }
      });
    });

    let acumulativeDelta: any = 0;

    this.valuesMlsTotalAllocation.forEach((key: any) => {
      this.valuesSubstractionSummarized.forEach(value => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    const valuesSubTotalOnlyProduct = this.valuesSubTotal.find((item: any) => item.idPpm === idPpm);

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    for (let i = 0; i < this.valuesSubstractionSummarized.length; i++) {
      if(this.isSubTotalEmpty) {
        acumulativeDelta =
          this.valuesSubstractionSummarized[i]?.value +
          this.valuesMLSsummarized[i + 1]?.value;
      } else {
        acumulativeDelta =
          this.valuesSubstractionSummarized[i]?.value -
            valuesSubTotalOnlyProduct.summarized[this.valuesSubstractionSummarized[i]?.col]
              ?.value +
            this.valuesMLSsummarized[i + 1]?.value;
      }

      this.valuesSubstractionSummarized.forEach(
        (row: any, indexRow: number) => {
          if (indexRow === i + 1)
            row.value =
              parseFloat(acumulativeDelta) -
              this.valuesTotalAllocation[i + 1]?.value;
        }
      );
    }

    if (!this.isSubTotalEmpty) {
      for (let i = 0; i < this.valuesSubstractionSummarized.length; i++) {
        this.valuesSubstractionSummarized[i].value =
          Number(this.valuesSubstractionSummarized[i].value) -
          Number(
            valuesSubTotalOnlyProduct.summarized[this.valuesSubstractionSummarized[i]?.col]
              ?.value
          );
      }
    }

    this.valuesSubstractionSummarized.forEach((key: any) => {
      this.valuesDeltaSummarized.forEach((value: any) => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    /* Adicionando na tabela summarized os valores de ATS */
    Object.entries(
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
        .summarized[0].rows[CONSTS.INDEX_ATS].value
    ).forEach((row: any) => {
      this.valuesDeltaSummarized.forEach((key: any) => {
        if (key.col === row[0]) {
          row[1].value = parseFloat(key.value);
        }
      });
    });

    let valuesTotalATS: { col: string; value: number }[] = [];

    /* Atualizando os valores de Sub Total no Mês atual */
    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].headers.forEach(
      (header: any) => {
        Object.entries(
          this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
            .summarized[0].rows[CONSTS.INDEX_ATS].value
        ).forEach((row: any) => {
          if (header?.props.includes('wk')) {
            if (row[0] === header.props) {
              const column = row[0].split('wk');

              const hasColumnInValuesTotalATS = valuesTotalATS.findIndex(
                v => v.col === column[0]
              );

              if (hasColumnInValuesTotalATS === -1)
                valuesTotalATS.push({
                  col: column[0],
                  value: Number(row[1].value),
                });
              valuesTotalATS.forEach((key: any) => {
                if (key.col == column[0]) {
                  key.col = column[0];
                  key.value = Number(row[1].value);
                }
              });
            }
          }
        });
      }
    );

    Object.entries(
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
        .summarized[0].rows[CONSTS.INDEX_ATS].value
    ).forEach((row: any) => {
      valuesTotalATS.forEach((key: any) => {
        if (row[0] === key.col) {
          row[1].value = key.value;
        }
      });
    });

    this.configMiddleTable(this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE]);
  }

  startingUpdateTableValuesExpanded(indexContent: number, idPpm: number): void {
    this.valuesMLSexpanded = [];
    this.valuesSubstractionExpanded = [];
    this.valuesMlsTotalAllocationExpanded = [];
    this.valuesTotalAllocationExpanded = [];

    for (
      let i = 0;
      i <
      this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
        .expanded.length;
      i++
    ) {
      Object.entries(
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
          .expanded[i].rows[CONSTS.INDEX_MLS].value
      ).forEach((row: any) => {
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].headers.forEach(
          (header: any) => {
            if (
              header.weekInTime === 'current' ||
              header.weekInTime === 'next'
            ) {
              if (row[0] === header.props) {
                const hasColumnInvaluesMLS = this.valuesMLSexpanded.filter(
                  (v: any) =>
                    v.col === header.props && v.origin === row[1].origin
                );
                if (!hasColumnInvaluesMLS.length)
                  this.valuesMLSexpanded.push({
                    col: row[0],
                    value: parseFloat(row[1].value),
                    origin: row[1].origin,
                  });
                this.valuesMLSexpanded.forEach((key: any) => {
                  if (key.col === row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = parseFloat(row[1].value);
                    key.origin = row[1].origin;
                  }
                });
                const hasColumnInvaluesSubMLS =
                  this.valuesMlsTotalAllocationExpanded.filter(
                    (v: any) =>
                      v.col === header.props && v.origin === row[1].origin
                  );
                if (!hasColumnInvaluesSubMLS.length)
                  this.valuesMlsTotalAllocationExpanded.push({
                    col: row[0],
                    origin: row[1].origin,
                    value: 0,
                  });
                this.valuesMlsTotalAllocationExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                  }
                });

                const hasColumn = this.valuesSubstractionExpanded.filter(
                  (v: any) =>
                    v.col === header.props && v.origin === row[1].origin
                );
                if (!hasColumn.length)
                  this.valuesSubstractionExpanded.push({
                    col: row[0],
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesSubstractionExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = parseFloat(row[1].value);
                  }
                });

                if (!hasColumn.length)
                  this.valuesDeltaExpanded.push({
                    col: row[0],
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesDeltaExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = 0;
                  }
                });

                if (!hasColumn.length)
                  this.valuesTotalAllocationExpanded.push({
                    col: row[0],
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesTotalAllocationExpanded.forEach((key: any) => {
                  if (key.col == row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value = 0;
                  }
                });
              }
            }
          }
        );
      });
    }

    /* Armazenando o total de alocações em um array de referência */

    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
      indexContent
    ].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
          .expanded[indexRow]?.rows
      ).forEach((key: any) => {
        if (!key[1].enableATS && !key[1].enableMLS) {
          Object.entries(key[1].value).forEach((value: any) => {
            this.valuesMlsTotalAllocationExpanded.forEach((subTotal: any) => {
              if (
                subTotal.col == value[0] &&
                subTotal.origin == value[1]?.origin
              ) {
                subTotal.value += parseFloat(value[1].value);
              }
            });
          });
        }
      });
    });

    this.valuesMlsTotalAllocationExpanded.forEach((key: any) => {
      this.valuesTotalAllocationExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin)
          value.value = key.value;
      });
    });

    /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    this.valuesMLSexpanded.forEach((row: any) => {
      this.valuesMlsTotalAllocationExpanded.forEach((key: any) => {
        if (key.col === row.col && key.origin === row.origin) {
          key.value = parseFloat(row.value) - parseFloat(key.value);
        }
      });
    });

    let acumulativeDelta: any = 0;

    this.valuesMlsTotalAllocationExpanded.forEach((key: any) => {
      this.valuesSubstractionExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    const valuesSubTotalOnlyProduct = this.valuesSubTotal.find((item: any) => item.idPpm === idPpm);

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    for (let i = 0; i < this.valuesSubstractionExpanded.length; i++) {
      if (
        this.valuesSubstractionExpanded[i]?.origin ===
        this.valuesMLSexpanded[i + 1]?.origin
      ) {
        if (this.isSubTotalEmpty){
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value;
        } else {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value -
            valuesSubTotalOnlyProduct.expanded[
              this.valuesSubstractionExpanded[i]?.origin
            ][this.valuesSubstractionExpanded[i]?.col].value;
        }

        this.valuesSubstractionExpanded.forEach(
          (row: any, indexRow: number) => {
            if (indexRow === i + 1)
              row.value =
                parseFloat(acumulativeDelta) -
                this.valuesTotalAllocationExpanded[i + 1]?.value;
          }
        );
      } else {
        acumulativeDelta = 0;
      }
    }

    if (!this.isSubTotalEmpty) {
      Object.entries(valuesSubTotalOnlyProduct.expanded).forEach((subTotal: any) => {
        this.valuesSubstractionExpanded.forEach((delta: any) => {
          if (Object.keys(subTotal[1]).length !== 0) {
            if (delta.origin === subTotal[0]) {
              delta.value =
                parseFloat(delta.value) -
                parseFloat(subTotal[1][delta.col].value);
            }
          }
        });
      });
    }

    this.valuesSubstractionExpanded.forEach((key: any) => {
      this.valuesDeltaExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    this.valuesMlsTotalAllocationExpanded.forEach((key: any) => {
      this.valuesDeltaExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          key.value = parseFloat(value.value);
        }
      });
    });

    /* Adicionando na tabela expanded os valores de ATS */
    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
      indexContent
    ].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
          .expanded[indexRow]?.rows
      ).forEach((key: any) => {
        if (key[1].enableATS) {
          Object.entries(key[1].value).forEach((value: any) => {
            this.valuesMlsTotalAllocationExpanded.forEach((subTotal: any) => {
              if (
                subTotal.col == value[0] &&
                subTotal.origin === value[1]?.origin
              ) {
                value[1].value = parseFloat(subTotal.value);
              }
            });
          });
        }
      });
    });

    /* Atualizando os valores de Sub Total no Mês atual */

    let valuesExpandedTotalATS: {
      col: string;
      value: number;
      origin: string;
    }[] = [];

    this.valuesMlsTotalAllocationExpanded.forEach((subTotal: any) => {
      if (subTotal.origin) {
        const column = subTotal.col.split('wk');

        const hasColumnInValuesTotalATS = valuesExpandedTotalATS.filter(
          (v: any) => v.col == column[0] && v.origin === subTotal.origin
        );
        if (!hasColumnInValuesTotalATS.length)
          valuesExpandedTotalATS.push({
            col: column[0],
            value: Number(subTotal.value),
            origin: subTotal.origin,
          });
        valuesExpandedTotalATS.forEach((key: any) => {
          if (key.col == column[0] && key.origin === subTotal.origin) {
            key.col = column[0];
            key.value = Number(subTotal.value);
          }
        });
      }
    });

    this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[
      indexContent
    ].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(
        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content[indexContent]
          .expanded[indexRow]?.rows
      ).forEach((key: any) => {
        if (key[1].enableATS) {
          Object.entries(key[1].value).forEach((value: any) => {
            valuesExpandedTotalATS.forEach((subTotal: any) => {
              if (
                value[0] === subTotal.col &&
                value[1].origin === subTotal.origin
              ) {
                value[1].value = Number(subTotal.value);
              }
            });
          });
        }
      });
    });
  }

  createSearchInputs(
    familyFilter?: InputBase<string>[] | undefined,
    customerShortFilter?: InputBase<string>[],
    salesManagerFilter?: InputBase<string>[],
    orderAdminFilter?: InputBase<string>[],
    geosFilter?: InputBase<string>[],
    countrysFilter?: InputBase<string>[],
    initialValueGeoFilter?: any | undefined,
    initialValueCountryFilter?: any | undefined
  ) {
    const fiscalYearFilter: any[] = [];
    const quarterFilter: any[] = [];
    const currentYear = new Date().getFullYear();
    let geoInitialValue: string = '';
    let countryInitialValue: string = '';

    const originFilter: any = [
      { id: 1, value: 'JAG' },
      { id: 2, value: 'MAN' },
    ];

    for (
      let year = currentYear - 5, i = 0;
      year <= currentYear + 5;
      year++, i++
    ) {
      fiscalYearFilter.push({ value: `${year}/${year + 1}`, key: i });
    }

    quarterFilter.push(
      { key: '1', value: 'FQ1' },
      { key: '2', value: 'FQ2' },
      { key: '3', value: 'FQ3' },
      { key: '4', value: 'FQ4' }
    );

    if (initialValueGeoFilter) {
      geoInitialValue = initialValueGeoFilter[0].value;
    }
    if (initialValueCountryFilter) {
      countryInitialValue = initialValueCountryFilter[0].value;
    }

    return (this.searchInputs = [
      new MultiSelectSearchInput({
        key: 'family',
        hover: 'Family',
        hasCheckFlowException: true,
        disabled: false,
        options: familyFilter,
        classCss: 'full-width-panel-family',
        limitedValue: true,
        module: 'family-name',
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        hasCheckFlowException: true,
        options: customerShortFilter,
        type: 'text',
        classCss: 'full-width-panel-customer-short',
        limitedValue: true,
        module: 'customer-view',
        limit: true,
        valueForFilterLimit: 5,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasCheckFlowException: true,
        options: originFilter,
        disabled: false,
        largeInput: true,
        classCss: 'panel-origin',
      }),
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasCheckFlowException: true,
        disabled: false,
        options: orderAdminFilter,
        classCss: 'panel-order-admin',
      }),
      new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasCheckFlowException: true,
        disabled: false,
        options: salesManagerFilter,
        classCss: 'full-width-panel-sales-manager',
        largeInput: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
        type: 'text',
        hasCheckFlowException: true,
        options: fiscalYearFilter,
        disabled: false,
        value: `${this.currentYear}/${this.currentYear + 1}`,
        classCss: 'panel-fiscal-year',
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Fiscal Quarter',
        type: 'text',
        hasCheckFlowException: true,
        value: `F${this.currentQuarter}`,
        options: quarterFilter,
        disabled: false,
        classCss: 'panel-fiscal-quarter',
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasCheckFlowException: true,
        options: geosFilter,
        classCss: 'panel-geo',
        value: geoInitialValue,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        options: countrysFilter,
        classCss: 'panel-country',
        value: countryInitialValue,
        disabled: true,
        notClearable: true,
      }),
    ]);
  }

  createSearchInputsFamily(familyFilter?: InputBase<string>[] | undefined) {
    const currentFamily = new MultiSelectSearchInput({
      key: 'family',
      hover: 'Family',
      hasCheckFlowException: true,
      disabled: false,
      options: familyFilter,
      classCss: 'full-width-panel-family',
      limitedValue: true,
      module: 'family-name',
    });

    this.searchInputs[0] = currentFamily;
    this.searchInputs = [...this.searchInputs];
  }

  createSearchInputsCustomerShort(customerShortFilter?: InputBase<string>[]) {
    const currentCustomerShort = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      hasCheckFlowException: true,
      disabled: false,
      options: customerShortFilter,
      classCss: 'full-width-panel-customer-short',
      limitedValue: true,
      limit: true,
      valueForFilterLimit: 5,
    });

    this.searchInputs[1] = currentCustomerShort;
    this.searchInputs = [...this.searchInputs];
  }

  onSearchEvent(form: ActionEventData) {
    this.formSearchMarketName = { ...form };
    this.dataFormSearchFamily(this.formSearchMarketName);
    this.dataFormSearchCustomerShort(this.formSearchMarketName);
    this.dataFormSearchSalesManager(this.formSearchMarketName);
    this.dataFormSearchOrderAdmin(this.formSearchMarketName);
    this.dataFormSearchOrigin(this.formSearchMarketName);
    this.dataFormSearchFiscalYear(this.formSearchMarketName);
    this.dataFormSearchGeo(this.formSearchMarketName);
    this.dataFormSearchCountry(this.formSearchMarketName);

    this.deleteMore(this.formSearchMarketName);

    const formSearchValues = { ...this.formSearchMarketName };

    if (!this.formSearchMarketName.materialId.length || 
      this.formSearchMarketName.materialId === undefined ||
      this.formSearchMarketName.materialId === '') {
        this.resetValues();
        this.applyTTo = false;
    }

    if (this.formSearchMarketName.materialId.length > 0 && 
      this.formSearchMarketName.customerShortId.length) {
      this.disableApply = true;
    } else {
      this.disableApply = false;
    }

    this.filter = {
      ...this.filter,
      ...formSearchValues,
    };
  }

  dataFormSearchFamily(formSearchName: ActionEventData) {
    const currentIdsFamily: any = [];

    this.initialValueFamilyFilter?.forEach((item: any) => {
      if (formSearchName.family) {
        formSearchName.family?.forEach((data: any) => {
          if (item.value === data.value) {
            item.materialId.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
        formSearchName.materialId = currentIdsFamily.map((item: any) => item);
      }
    });

    formSearchName.materialId = formSearchName.materialId
      ? formSearchName.materialId
      : '';
  }

  dataFormSearchCustomerShort(formSearchName: ActionEventData) {
    if (formSearchName.customerShortId) {
      const currentCustomerShortId = formSearchName.customerShortId.map(
        (item: any) => item.id
      );
      formSearchName.customerShortId = currentCustomerShortId;
    } else {
      formSearchName.customerShortId = '';
    }
  }

  dataFormSearchSalesManager(formSearchName: ActionEventData) {
    if (Array.isArray(formSearchName.salesManagerId)) {
      const currentSalesManager = formSearchName.salesManagerId.map(
        (item: any) => item.id
      );
      formSearchName.salesManagerId = currentSalesManager;
    } else {
      formSearchName.salesManagerId = [];
    }
  }

  dataFormSearchOrderAdmin(formSearchName: ActionEventData) {
    if (Array.isArray(formSearchName.orderAdminId)) {
      const currentOrderAdmin = formSearchName.orderAdminId.map(
        (item: any) => item.id
      );
      formSearchName.orderAdminId = currentOrderAdmin;
    } else {
      formSearchName.orderAdminId = [];
    }
  }

  dataFormSearchOrigin(formSearchName: ActionEventData) {
    if (Array.isArray(formSearchName.origin)) {
      let currentOrigin: string[] = [];

      formSearchName.origin.forEach((item: any) => {
        currentOrigin.push(item.value);
      });

      formSearchName.origin = currentOrigin;
    } else {
      formSearchName.origin = [];
    }
  }

  dataFormSearchFiscalYear(formSearchName: ActionEventData) {
    formSearchName.fiscalYear = formSearchName.fiscalYear?.split('/')[0] || '';
  }

  dataFormSearchGeo(formSearchName: ActionEventData) {
    if (formSearchName.geoId) {
      const currentGeo = this.initialValueGeoFilter[0].id;
      formSearchName.geoId = currentGeo;
    } else {
      formSearchName.geoId = '';
    }
  }

  dataFormSearchCountry(formSearchName: ActionEventData) {
    if (formSearchName.countryId) {
      const currentCountry = this.initialValueCountryFilter[0].id;
      formSearchName.countryId = currentCountry;
    } else {
      formSearchName.countryId = '';
    }
  }

  deleteMore(formSearchName: ActionEventData) {
    delete formSearchName.action;
    delete formSearchName.event;
    delete formSearchName.formControl;
    delete formSearchName.nameFormControl;
    delete formSearchName.panelTrigger;
    delete formSearchName.family;
  }

  applyFilter() {
    const formSearchValues = { ...this.formSearchMarketName };
    formSearchValues.simpleView = this.simpleViewOrNot;
    this.applyTTo = true;
    this.filter = {
      ...formSearchValues,
    };
    this.filterBooked = {
      ...formSearchValues,
      type: 'booked',
      pageName: PAGE_NAME_RESOURCE.familyView,
    };

    this.filterSellIn = {
      ...formSearchValues,
      type: 'sell_in',
      pageName: PAGE_NAME_RESOURCE.familyView,
    };
    this.checkDiscardAllocation('applyFilter');
  }

  onChangeTab(tab?: any, row?: any) {
    this.spinner.show();
    this.thead.forEach((item: any) => {
      if (tab === item.label) {
        item.status = true;
        this.changeTab = true;
      }
      if (tab !== item.label) {
        item.status = false;
      }
    });
  }

  private getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  getResultResponse(data: any, name: string) {
    const colum = data.headers.map((item: any) => {
      return { title: item.title, props: item.props };
    });
    const row = data.rows.map((item: any) => {
      return item;
    });
    const fullJsonExport = { columns: colum, rows: row };
    Object.defineProperty(fullJsonExport, 'sheet', {
      value: `${name}`,
    });
    Object.defineProperty(fullJsonExport, 'headers', {
      value: true,
    });
    return fullJsonExport;
  }

  exportTable() {
    const dialog = this.getDialogModal('export');

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.exportTableRequest();
      }
    });
  }

  setExportStatus(status: string) {
    localStorage.setItem('disabled_button_export_family-name', status);
  }

  destroyExportStatus() {
    localStorage.removeItem('disabled_button_export_family-name');
    this.disabledButtonExport = false;
  }

  public base64ToBlob(b64Data: string, sliceSize = 512) {
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  exportTableRequest() {
    this.setExportStatus('true');
    this.disabledButtonExport = true;
    this.tooltipMessage = 'Waiting...';
    const subtitleMessage = 'Generating Excel file for background download!';
    const titleMessage = 'Download Excel';
    this.toastr.success(`${subtitleMessage}`, `${titleMessage}`, {
      timeOut: 10000,
    });
    this.downloadFileService
      ?.downloadFile(
        'allocation-customer/export-report-ppm',
        this.filter,
        'text'
      )
      .subscribe(
        (response: any) => {
          const file = this.base64ToBlob(response);

          // IE
          const nav = window.navigator as any;
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return;
          }
          FileSaver.saveAs(file, `FAMILY_NAME_REPORT.xlsx`);
          const subtitleMessage = 'Successfully generated file!';
          const titleMessage = 'Download Excel';
          this.toastr.success(`${subtitleMessage}`, `${titleMessage}`, {
            timeOut: 10000,
          });
          this.destroyExportStatus();
          this.tooltipMessage = 'Export';
        },
        (err: any) => {
          const subtitleMessage =
            'An error occurred while generating the Excel file!';
          const titleMessage = 'Download Excel';
          this.toastr.error(`${subtitleMessage}`, `${titleMessage}`, {
            timeOut: 10000,
          });
          this.destroyExportStatus();
          this.tooltipMessage = 'Export';
        }
      );
  }

  checkSimpleOrNot(event: any) {
    if (event.srcElement.checked) {
      this.simpleViewOrNot = event.srcElement.checked;
      if (this.disableApply) {
        this.applyFilter();
      }
    } else {
      this.simpleViewOrNot = event.srcElement.checked;
      if (this.disableApply) {
        this.applyFilter();
      }
    }
  }

  toggleTable(event: string) {
    this.eventTable = event;
  }

  dynamicDataSource(event?: any): DynamicData[] {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dynamicData: DynamicData[] = [];

    months.forEach((month: any) => {
      if (event.rowValue.value.netOpenPo.modal.hasOwnProperty(month)) {
        const dataMonth = event.rowValue.value.netOpenPo.modal[month];

        if (dataMonth.length > 0) {
          dataMonth.forEach((data: NetOpenPoData) => {
            dynamicData.push({
              customerShort: event.rowValue.customerShort,
              month: data.month,
              taxed: data.tax,
              soLineNumber: data.so_line_number,
              customerPoLineNumber: data.cust_po_line_num,
              QTY: data.qty,
            });
          });
        }
      }
    });

    return dynamicData;
  }

  dynamicColumns(): columnsStyle[] {
    return [
      { label: 'CUSTOMER SHORT', prop: 'customerShort' },
      { label: 'MONTH', prop: 'month' },
      { label: 'TAXES', prop: 'taxed' },
      { label: 'SO LINE NUMBER', prop: 'soLineNumber' },
      { label: 'CUSTOMER PO LINE NUMBER', prop: 'customerPoLineNumber' },
      { label: 'QTY', prop: 'QTY' },
    ];
  }

  openModalNetOpenPo(rowValue: any): void {
    const dynamicData = this.dynamicDataSource(rowValue);
    const uniqueMonths = Array.from(
      new Set(dynamicData.map((item: any) => item.month))
    );

    // Calculando o subtotal
    const calcValue = dynamicData
      .filter((item: any) => item.month === uniqueMonths[0])
      .reduce((acc: any, item: any) => acc + item.QTY, 0);

    const modalData = {
      title: 'NET OPEN PO',
      subtitle: rowValue.rowValue.ppm,
      titleExport: 'netOpenPo',
      nameModal: 'netOpenPo',
      dataSource: dynamicData,
      dynamicPropertyWithSameValue: uniqueMonths,
      dynamicCalcSubtotal: calcValue,
      displayedColumns: this.dynamicColumns(),
      hasRecords: dynamicData.length > 0,
      messageNoRecords: 'THERE ARE NO ASSOCIATED SO LINE NUMBERS',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog-container',
    });
  }

  dynamicDataSourceLast(data?: any, headers?: any): any[] {
    const dynamicData: any[] = [];

    if (data) {
      data.forEach((row: any) => {
        const dynamicRow: any = {};
        for (const key in headers) {
          if (headers.hasOwnProperty(key) && row.hasOwnProperty(key)) {
            dynamicRow[key] = row[key];
          }
        }
        dynamicData.push(dynamicRow);
      });

      // Se family && Origin forem iguais, family deve ficar ''
      // Se customerShort iguais, customerShort = ''
      dynamicData.forEach((row, index) => {
        if (index > 0) {
          const previousRow = dynamicData[index - 1];
          if (
            row.family === previousRow.family &&
            row.origin === previousRow.origin
          ) {
            const currentCustomerShort = row.customerShort;
            const currentOrigin = row.origin;

            // Procura o primeiro índice com o mesmo customerShort e origin
            let firstMatchingIndex = index - 1;
            while (firstMatchingIndex >= 0) {
              const prevCustomerShort =
                dynamicData[firstMatchingIndex].customerShort;
              const prevOrigin = dynamicData[firstMatchingIndex].origin;

              if (
                prevCustomerShort === currentCustomerShort &&
                prevOrigin === currentOrigin
              ) {
                break;
              }
              firstMatchingIndex--;
            }

            // Se encontrar um índice com o mesmo customerShort e origin, definir como vazio
            if (firstMatchingIndex >= 0) {
              for (let i = index; i > firstMatchingIndex; i--) {
                const item = dynamicData[i];
                if (
                  item.customerShort === currentCustomerShort &&
                  item.origin === currentOrigin
                ) {
                  item.customerShort = '';
                }
              }
            }
            row.secondTitle = '';
          }
        }
      });
    }

    return dynamicData;
  }

  dynamicColumnsLast(): columnsStyle[] {
    return [
      { label: 'PPM', prop: 'family' },
      { label: 'CUSTOMER SHORT', prop: 'customerShort' },
      { label: 'CHANNEL', prop: 'channel' },
      { label: 'ORIGIN', prop: 'origin' },
      { label: 'TAXES', prop: 'taxes' },
      {
        label: 'ALLOCATED',
        prop: 'allocated',
        summable: true,
        total: 0,
      },
      {
        label: 'INVOICED',
        prop: 'invoiced',
        summable: true,
        total: 0,
      },
      { label: 'DELTA', prop: 'delta', summable: true, total: 0 },
    ];
  }

  openModalLastWeek(): void {
    const dynamicData: any = this.dynamicDataSourceLast(
      this.loadedDataTableData.lastWeekModal?.content,
      this.loadedDataTableData.lastWeekModal?.headers[0]
    );

    const resultTotal: any = this.loadedDataTableData.lastWeekModal;
    const columns = this.dynamicColumnsLast();
    const combinedUnique: any[] = [];

    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const family = item.family;
      const found = combinedUnique.find(
        elem => elem.origin === origin && elem.family === family
      );

      if (!found) {
        combinedUnique.push({ origin, family });
      }
    });

    // Cálculo do subtotal por origem para 'allocated', 'invoiced' e 'delta'
    const originSubtotals: {
      [key: string]: { allocated: number; invoiced: number; delta: number };
    } = {};
    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const allocated = item.allocated;
      const invoiced = item.invoiced;
      const delta = item.delta;

      if (!originSubtotals[origin]) {
        originSubtotals[origin] = { allocated: 0, invoiced: 0, delta: 0 };
      }

      originSubtotals[origin].allocated += allocated;
      originSubtotals[origin].invoiced += invoiced;
      originSubtotals[origin].delta += delta;
    });

    const totalSubtotals: any = {};
    const processedCombinations = new Map();

    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const family = item.family;
      const combinationKey = `${origin}-${family}`;

      // Verifica se a combinação origin-family já foi processada para que não
      // adicione subtotais em todas as rows, apenas no primeiro index por family
      if (!processedCombinations.has(combinationKey)) {
        item.subtotals = {};

        columns.forEach((header: any) => {
          if (header.summable) {
            const subtotal = dynamicData
              .filter((dataItem: any) => {
                return dataItem.family === family && dataItem.origin === origin;
              })
              .reduce(
                (sum: number, dataItem: any) => sum + dataItem[header.prop],
                0
              );

            item.subtotals[header.prop] = subtotal;

            if (!totalSubtotals[header.prop]) {
              totalSubtotals[header.prop] = subtotal;
            } else {
              totalSubtotals[header.prop] += subtotal;
            }
            resultTotal.total = totalSubtotals;
          }
        });

        processedCombinations.set(combinationKey, true);
      }
    });

    let modalData = {
      title: 'LAST WEEK',
      nameModal: 'lastWeek',
      dataSource: dynamicData,
      displayedColumns: columns,
      total: resultTotal?.total,
      dynamicPropertyWithSameValue: combinedUnique,
      hasRecords: dynamicData.length > 0,
      messageNoRecords: 'THERE ARE NO LAST WEEK DATA',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog-container',
    });
  }

  saveAllocation() {
    const dialog = this.getDialogModal('save');
    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'save') {
        this.spinner.show();
        const DTOForApi: any = [];

        this.dataSourceTable[CONSTS.INDEX_MIDDLE_TABLE].content.forEach(
          (content: any) => {
            content.expanded.forEach((expanded: any) => {
              expanded.rows
                .filter(
                  (obj: any) => obj.enableATS !== true && obj.enableMLS !== true
                )
                .forEach((row: any) => {
                  const valuesWeek = Object.keys(row.value).map(key => {
                    row.value[key].indice = key;
                    return row.value[key];
                  });

                  valuesWeek
                    .filter((obj: any) => obj.edit === true)
                    .forEach((week: any) => {
                      DTOForApi.push({
                        id: week.id,
                        materialId: week.idProduct,
                        customerShortId: week.customerShortId,
                        originPlan: week.origin,
                        yearMonth: week.yearMonth,
                        week: week.week,
                        value:
                          typeof week.value === 'string'
                            ? Number(week.value.replaceAll(',', ''))
                            : week.value,
                        customerTaxId: week.taxId,
                      });
                    });
                });
            });
          }
        );

        this.putCrudService.updateEntity('', DTOForApi).subscribe(() => {
          const subtitleMessage = 'Updated successfully!';
          const titleMessage = 'Family Name allocation';
          this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
        });

        this.discardChanges();
        this.applyFilter();
      }
    });
  }

  toggleBreadcrumb(toDisable: boolean) {
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/customer-allocation-retail', {
      disable: toDisable,
    });
    this.breadcrumbService.set(
      '/home/customer-allocation-retail/allocation-retail',
      { disable: toDisable }
    );
  }

  @HostListener('window:click', ['$event'])
  verifyClickChangeRoute(event: any) {
    const classElementsChangesRoutes = ['xng-breadcrumb-item ng-star-inserted'];

    const isElementChangeRoute =
      classElementsChangesRoutes.filter((className: string) =>
        event.target.className.includes(className)
      ).length > 0;

    if (isElementChangeRoute && this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      const originUrl = event.target.children[0].origin;
      const routeToNavigate = event.target.children[0].href.replace(
        originUrl,
        ''
      );
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate([routeToNavigate]);
        }
      });
    }
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  checkDiscardAllocation(sourceMethod?: string) {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.discardChanges();
          if (sourceMethod === 'applyFilter') {
            this.getDataTable(this.filter);
          }
        }
      });
    } else {
      this.discardChanges();
      if (sourceMethod === 'applyFilter') {
        this.getDataTable(this.filter);
      }
    }
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate([
            'home/customer-allocation-retail/allocation-retail',
          ]);
        }
      });
    } else {
      this.router.navigate([
        'home/customer-allocation-retail/allocation-retail',
      ]);
    }
  }

}
