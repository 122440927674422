import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  setNotificationStorage(data: any) {
    localStorage.setItem('notifications', JSON.stringify(data));
  }

  getNotificationStorage() {
    const hasNotifications = localStorage.getItem('notifications');
    const hasNotificationsArray = hasNotifications ? JSON.parse(hasNotifications) : [];
    return hasNotificationsArray;
  }

  deleteNotificationStorage(index: any) {
    const hasNotifications = localStorage.getItem('notifications');
    const hasNotificationsArray = hasNotifications ? JSON.parse(hasNotifications) : [];
    hasNotificationsArray.splice(index, 1);
    localStorage.setItem('notifications', JSON.stringify(hasNotificationsArray));
  }
}
