import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ColumnMarkerChartComponent } from './column-marker-chart.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ColumnMarkerChartComponent],
  exports: [ColumnMarkerChartComponent],
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    CommonModule,
  ],
})
export class ColumnMarkerChartModule {}
