<h1 class="title-modal">PARAMETRIZATION DETAILS - {{ dataTerritory }}</h1>
<div class="container">
  <div class="data-view" *ngFor="let item of dataTable">
    <h1 class="title">{{ item.title }}</h1>
    <div class="box-value">
      <p *ngFor="let data of item.value">
        <span class="value">{{ data }}</span>
      </p>
    </div>
  </div>
</div>
