import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnAttributes } from '../../table/table.interface';

@Component({
  selector: 'app-table-inputs',
  templateUrl: './table-inputs.component.html',
  styleUrls: ['./table-inputs.component.scss'],
})
export class TableInputsComponent implements OnInit {
  @Input() dataTable!: any;
  @Input() quarter!: any;
  @Input() typeTable!: any;
  @Input() zoomIn: boolean = false;
  @Output('inputChanged') inputChangedEvent = new EventEmitter();

  headers: any = [];
  content: any = [];
  dataSource: any = [];
  displayedDef: any[] = [];
  displayedColumns: any = [];
  displayedContent: any = [];
  columnsNames: string[] = [];
  hasRowsExpanded: boolean = false;
  rowHighlight: number[] = [];
  translateYAbsoluteItem: number = 0;
  @Input() eventTable!: string;
  @Output('toggleTable') toggleTableEvent = new EventEmitter();
  @Output('callActionsComments') callActionsComments = new EventEmitter();
  @Output('callViewComments') callViewComments = new EventEmitter();
  @Output('eventActions') eventActions = new EventEmitter();
  @Output('toggleColumnTable') toggleColumnTableEvent = new EventEmitter();
  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;

  constructor(private cd: ChangeDetectorRef, public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialogCreateGeoComments(data: any) {
    this.callActionsComments.emit({ data, type: 'editGeoComments' });
  }

  openDialogViewGeoComments(data: any) {
    this.callViewComments.emit({ data, type: 'viewGeoComments' });
  }

  openDialogCreateSalesComments(data: any) {
    this.callActionsComments.emit({ data, type: 'editSalesComments' });
  }

  openDialogViewSalesComments(data: any) {
    this.callViewComments.emit({ data, type: 'viewSalesComments' });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTable?.currentValue) {
      this.headers = [];
      this.content = [];
      this.dataSource = [];
      this.displayedDef = [];
      this.displayedColumns = [];
      this.displayedContent = [];
      this.columnsNames = [];

      this.loadTable(changes.dataTable.currentValue);
      this.content = changes.dataTable.currentValue.table;
    }
    if (changes.displayedColumns?.currentValue) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }

    if (changes.zoomIn && changes.zoomIn.currentValue) {
      this.dataTable.columns.forEach((header: ColumnAttributes) => {
        if (header?.expanded) {
          delete header.expanded;
        }
      });
    }
  }

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0]?.clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0]?.clientHeight;
    const headerTitle =
      document.getElementsByClassName('title')[0]?.clientHeight;

    let clientHeightTop: number = 0;
    clientHeightTop = appHeaderHeight + breadCrumbHeight + headerTitle - 100;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement.offsetTop + clientHeightTop;

    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  loadTable(updateDataTable?: any) {
    this.dataTable = updateDataTable || this.dataTable;

    this.dataSource = this.dataTable?.table?.rows;
    this.displayedDef = this.dataTable?.table?.columns;

    this.displayedColumns = this.displayedDef
      ?.filter((item: ColumnAttributes) =>
        // logica para evitar sideEfect em telas que não tem expansão de colunas (alwaysVisible)
        item.props && item.alwaysVisible
          ? item.props && item.alwaysVisible
          : item.alwaysVisible != false
      )
      .map((item: ColumnAttributes) => item.props);

    this.currentWeekMoreTwo();
  }

  currentWeekMoreTwo() {
    const dataTable = this.dataTable?.table?.columns;
    const startIndex = dataTable?.findIndex(
      (col: ColumnAttributes) =>
        col.weekInTime === 'current' &&
        col.title !== 'OPPORT' &&
        col.title?.slice(0, 2) === 'WK'
    );

    if (startIndex !== -1) {
      for (
        let i = startIndex;
        i < Math.min(startIndex + 3, dataTable?.length);
        i++
      ) {
        dataTable[i].isCurrentMoreTwo = true;
      }
    }
  }

  onChangeEvent(
    item: { indexRow: number; columnName: string; row: any; col: any },
    event: any
  ) {
    let valueInput: any;

    if (
      item.columnName === 'twoTierCustomer' ||
      item.columnName === 'geoComments' ||
      item.columnName === 'salesComments'
    ) {
      valueInput = event.target.value ? event.target.value : '';
    } else {
      valueInput = event.target.value
        ? event.target.value.replaceAll(',', '')
        : 0;
    }

    const dataRowColumn =
      this.dataSource.filteredData[item.indexRow][item.columnName];

    this.dataSource.filteredData[item.indexRow][item.columnName].value =
      valueInput;
    this.dataSource.filteredData[item.indexRow][item.columnName].edit = true;

    if (this.typeTable === 'order') {
      this.dataSource.filteredData[item.indexRow][item.columnName].order_id =
        item.row.order_id ? item.row.order_id : '';
      this.dataSource.filteredData[item.indexRow][item.columnName].fiscal_year =
        item.row.rsd.fiscal_year ? item.row.rsd.fiscal_year : '';
      this.dataSource.filteredData[item.indexRow][
        item.columnName
      ].fiscal_quarter = item.row.rsd.quarter ? item.row.rsd.quarter : '';
    } else {
      this.dataSource.filteredData[item.indexRow][item.columnName].territoryId =
        item.row.territory.id ? item.row.territory.id : '';
    }

    const value = {
      indexRow: item.indexRow,
      columnNameWeek: item.columnName,
      oldValue: dataRowColumn.value,
      value: valueInput,
      col: item.col,
    };

    this.inputChangedEvent.emit(value);
    this.cd.detectChanges();
  }

  // expansão da tabela conforme o evento
  toggleColumnTable(col: any, event: string) {
    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContent === col.otherContent
    );
    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );
    const indexCol = this.displayedColumns.findIndex(
      (item: any) => item === col?.otherContent
    );
    columsToShowHide.shift();
    if (event === 'show') {
      col.expanded = true;
      groupColumnsToShowHide[0].isFirstCard = true;
      groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
        true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expanded = true;
      });

      this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
    } else {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
    }

    this.toggleColumnTableEvent.emit({
      col,
      event,
    });
  }

  showTitleHeader(header: string) {
    if (header.includes('TOTAL+SAB')) {
      return `${this.quarter} TOT + SAB`;
    }
    return header;
  }

  editEvent(item: any) {
    this.eventActions.emit({ item, type: 'edit' });
  }

  deleteEvent(item: any) {
    this.eventActions.emit({ item, type: 'delete' });
  }

  handleHighlight(indexRow: any) {
    const index = this.rowHighlight.indexOf(indexRow);
    if (index === -1) {
      this.rowHighlight.push(indexRow);
    } else {
      this.rowHighlight.splice(index, 1);
    }
  }
}
