import { HttpClient } from '@angular/common/http';
import {
  Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { CardInterface } from 'src/app/components/cards/card.interface';
import { InputBase, SelectSearchInput } from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { TransformText } from 'src/app/helpers/transformText';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from '../../../services/authorization/permission.service';

@Component({
  selector: 'app-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.scss'],
})
export class GeoComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit: TemplateRef<any> | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation: TemplateRef<any> | any;
  regexCheck = new TransformText();
  searchInputs!: InputBase<string>[];
  dataSource: any;
  displayedColumns: any;
  result!: ResultInterface;
  geoForm: FormGroup;
  isEdit: boolean = false;
  hasPermissionCreate!: boolean;
  geos: any[] = [];
  crudService: CrudService<Geo>;
  pagination: Pagination;
  filter: any = { sort: 'geo', pageName: 'geo' };
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  currentUser!: any;

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private authService: AuthService,
  ) {
    this.pagination = { page: 0, size: 10 };
    this.crudService = new CrudService<Geo>(this.http, 'geo');
    this.geoForm = this.formBuilder.group({
      id: [''],
      geo: ['', Validators.required],
      country: [''],
    });
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate = this.permissionService.setButtonCreatePermission('geo');
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter.geo = this.currentUser.geo;
  }

  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'geo',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getGeos(this.pagination || undefined, this.filter);
    this.getDataFilter();
  }

  getGeos(page?: any, params?: any) {
    this.spinner.show();
    this.crudService.getPaged(page, params || this.filter).subscribe((response: any) => {
      this.updateComponents(response);
      this.spinner.hide();
    });
  }

  getDataFilter() {
    const filter = { geo: false };
    const geosFilter: any[] = [];
    this.spinner.show();

    this.crudService.getEntity('geo', { sort: 'geo', pageName: 'geo' }).subscribe((response: any) => {
      filter.geo = true;
      const initialValue: { [key: string]: any } = {};
      if (filter.geo) {
        response.forEach((geo: any) => {
          geosFilter.push({ key: geo.id, value: geo.geo, id: geo.id });
          if (geo.id === this.currentUser.geoId) {
            initialValue.value = geo.geo;
            initialValue.id = geo.id;
          }
        });
      }

      const selectGeo = new SelectSearchInput({
        key: 'geo',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        options: geosFilter,
        value: initialValue.value,
      });

      this.searchInputs[0] = selectGeo;
      this.searchInputs = [...this.searchInputs];
    });
    this.searchInputs = this.createSearchInputs();
  }

  updateComponents(geo: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(geo.rows)),
      table: {
        columns: [
          { label: 'geo', sticky: true, style: { width: '40%' } },
          { label: 'countries', sticky: true, style: { width: '55%', 'padding-right': '10px' } },
        ],
        rows: new MatTableDataSource<Geo>(this.dataTables(JSON.stringify(geo.rows))),
      },
      pageIndex: geo.page,
      pageSize: geo.totalRows,
      pagesTotal: geo.totalPages,
    };
    const actions = this.permissionService.setTablePermissions('geo');
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataTables(data: any) {
    const geo = JSON.parse(data);
    const dataTables: any[] = [];
    geo.forEach((item: any) => {
      let count: string = '';
      item.countries.forEach((value: any, index: number) => {
        count += `${value.country}${index + 1 === item.countries.length ? '' : ', '}`;
      });
      item.countries = count;
      item.active = true;
      dataTables.push(item);
    });
    return [...dataTables];
  }

  dataCards(data: any) {
    const geo = JSON.parse(data);
    const dataCards: CardInterface[] = [];
    const actionCards = this.permissionService.setActionsCardsPermission('geo');
    geo.forEach((item: Geo) => {
      const countries: any[] = [];
      item.countries?.forEach((value: any) => {
        countries.push(value.country);
      });
      dataCards.push({
        id: item.id,
        title: item.geo,
        iconEdit: { visible: actionCards.iconEdit, enabled: true },
        iconDelete: { visible: actionCards.iconDelete, enabled: true },
        attributes: [{
          key: 'Country',
          value: countries,
          isTag: true,
        }],
      });
    });
    return dataCards;
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getGeos(paginated, this.filter);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.prepareToEdit(event.item.id);
    } else {
      this.onDelete(event.item.id);
    }
  }

  prepareToEdit(id: number) {
    this.spinner.show();
    this.isEdit = true;
    this.crudService.fetchEntity(id).subscribe((geo: any) => {
      this.geoForm.controls.id.setValue(geo.id);
      this.geoForm.controls.geo.setValue(geo.geo);
      this.geoForm.controls.country.setValue(geo.country);
      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.geoForm.reset();
      });
      this.spinner.hide();
    });
  }

  prepareToCreate() {
    this.isEdit = false;
    const createModal = this.modalService.createModal(this.modalCreateEdit);
    createModal.afterClosed().subscribe(() => {
      this.geoForm.reset();
    });
  }

  onCreate() {
    this.geoForm.controls.geo.setValue(this.regexCheck.alphanumeric(this.geoForm.controls.geo.value || '').trim());
    if (this.validateFormService.onValidateFields(this.geoForm)) {
      delete (this.geoForm.value.id);
      this.spinner.show();
      this.crudService.createEntity(this.geoForm.value).subscribe(() => {
        this.modalService.closeAll();
        this.toastr.success(
          `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
          `${this.translatorService.getTitle('CRUD_SUCCESS')} Geo`,
        );
        this.filter = { sort: 'geo', pageName: 'geo' };
        this.loadData();
      }, (err: any) => {
        this.toastr.error(err.error.message, err.error.title);
        this.spinner.hide();
      });
    }
  }

  onUpdate() {
    this.isEdit = true;
    this.geoForm.controls.geo.setValue(this.regexCheck.alphanumeric(this.geoForm.controls.geo.value || '').trim());
    if (this.validateFormService.onValidateFields(this.geoForm)) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          const { id, geo } = this.geoForm.value;
          this.crudService.updateEntity(this.geoForm.controls.id.value, { id, geo })
            .subscribe(() => {
              this.toastr.success(
                `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
                `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Geo`,
              );
              this.filter = { sort: 'geo', pageName: 'country' };
              this.loadData();
              this.modalService.closeAll();
            }, (err: any) => {
              this.toastr.error(err.error.message, err.error.title);
              this.spinner.hide();
            });
        }
      });
    }
  }

  onDelete(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.crudService.deleteEntity(id)
          .subscribe((data: any) => {
            this.toastr.success(
              `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
              `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Geo`,
            );
            this.filter = { sort: 'geo', pageName: 'geo' };
            this.loadData();
          }, (err: any) => {
            this.toastr.error(err.error.message, err.error.title);
            this.spinner.hide();
          });
      }
    });
  }

  onSearchEvent(value: any) {
    if (!value.geo) {
      value.geo = '';
    }
    this.pagination.page = 0;
    this.filter = { ...value, sort: 'geo' };
    this.getGeos(this.pagination, this.filter);
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }
}
