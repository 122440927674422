import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSyncScrollModule } from 'ngx-syncscroll';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ColorCompactModule } from 'ngx-color/compact';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { VersionComponent } from './version/version.component';
import { CustomTooltipTableComponent } from './custom-tooltip-table/custom-tooltip-table.component';
import { CardColorComponent } from './card-color/card-color.component';
import { NavCardComponent } from './nav-card/nav-card.component';
import { PermissionsComponent } from './tabPermissions/tabPermissions.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { InputComponent } from './input/input.component';
import { SearchComponent } from './search/search.component';
import { TableComponent } from './table/table.component';
import { ResultsComponent } from './results/results.component';
import { HeaderComponent } from './header/header.component';
import { CardsComponent } from './cards/cards.component';
import { CustomTooltipTableDirective } from '../helpers/CustomTooltipTableDirective';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { TableExpandableComponent } from './table-expandable/table-expandable.component';
import { TableCardComponent } from './table-card/table-card.component';
import { ShareDirectiviesModule } from '../directives/share.directivies.module';
import { TableExpandableManagerComponent } from './table-expandable-manager/table-expandable-manager.component';
import { TableManagerComponent } from './table-manager/table-manager.component';
import { KeysTablePipePipe } from './table-manager/keys-table-pipe.pipe';
import { ConvertValuePipe } from './table-manager/convert-value.pipe';
import { ValueCurrencyComponent } from './value-currency/value-currency.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AccordionTableComponent } from './accordion-table/accordion-table.component';
import { InputDateComponent } from './input-date/input-date.component';
import { TableAccordionComponent } from './table-accordion/table-accordion.component';
import { TableColumnsTabsComponent } from './table-columns-tabs/table-columns-tabs.component';
import { TableLineInputComponent } from './table-line-input/table-line-input.component';
import { InformationTableComponent } from './emea/information-table/information-table.component';
import { TableInputsComponent } from './emea/table-inputs/table-inputs.component';
import { TableMissingComponent } from './table-missing/table-missing.component';
import { TableBookComponent } from './table-book/table-book.component';
import { LineMarkerChartComponent } from './line-marker-chart/line-marker-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AppEchart } from './stacked-column-chart/echart';
import { ColumnMarkerChartModule } from './column-marker-chart/columnMarkerChart.module';
import { TableExpansiveComponent } from './table-expansive/table-expansive.component';
import { TableColorMixComponent } from './table-color-mix/table-color-mix.component';
import { ClustedColumnChartModule } from './clusted-column-chart/clustedColumnChart.module';
import { SimpleTableExpansiveComponent } from './simple-table-expansive/simple-table-expansive.component';
import { BarChartComponentModule } from './bar-chart/barChart.module';
import { CardAllocationComponent } from './card-allocation/card-allocation.component';
import { FormsModule } from '@angular/forms';
import { SimpleTableExpansiveTabsComponent } from './simple-table-expansive-tabs/simple-table-expansive-tabs.component';
import { TabComponent } from './tab/tab.component';
import { TableSubtotalComponent } from './table-subtotal/table-subtotal.component';
import { HeaderBreakerComponent } from './header-breaker/header-breaker.component';
import { TableOverbookedComponent } from './table-overbooked/table-overbooked.component';
import { SimpleTableEmeaComponent } from './emea/simple-table-emea/simple-table-emea.component';
import { SimpleTableHeaderFixedComponent } from './simple-table-header-fixed/simple-table-header-fixed.component';
import { SimpleTableCheckComponent } from './simple-table-check/simple-table-check.component';
import { ModalComponent } from './modal-table/modal-table.component';
import { KaExecutionTableComponent } from './ka-execution-table/ka-execution-table.component';
import { TableCustomerViewComponent } from './table-customer-view/table-customer-view.component';
import { MknToBookTableComponent } from './mkn-to-book-table/mkn-to-book-table.component';
import { TableManagerMarketNameComponent } from './table-manager-market-name/table-manager-market-name.component';
import { ViewTableComponent } from './view-table/view-table.component';
import { ColumnToggleComponent } from './column-toggle/column-toggle.component';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { CardsColorComponent } from './cards-color/cards-color.component';
import { TableFilteringComponent } from './table-filtering/table-filtering.component';
import { TableNotificationsComponent } from './table-notifications/table-notifications.component';
import { ThousandSeparatorPipe } from '../helpers/thousand-separator-pipe.pipe';
@NgModule({
  declarations: [
    NavCardComponent,
    HeaderComponent,
    TableComponent,
    CardsComponent,
    ResultsComponent,
    SearchComponent,
    InputComponent,
    DynamicFormComponent,
    PermissionsComponent,
    VersionComponent,
    ViewTableComponent,
    CustomTooltipTableComponent,
    CustomTooltipTableDirective,
    CardColorComponent,
    SimpleTableComponent,
    ViewTableComponent,
    TableExpandableComponent,
    TableCardComponent,
    TableExpandableManagerComponent,
    TableManagerComponent,
    KeysTablePipePipe,
    ConvertValuePipe,
    ValueCurrencyComponent,
    AccordionTableComponent,
    InputDateComponent,
    TableAccordionComponent,
    TableColumnsTabsComponent,
    InformationTableComponent,
    TableInputsComponent,
    TableLineInputComponent,
    TableMissingComponent,
    TableOverbookedComponent,
    TableBookComponent,
    LineMarkerChartComponent,
    TableExpansiveComponent,
    TableColorMixComponent,
    SimpleTableExpansiveComponent,
    CardAllocationComponent,
    SimpleTableExpansiveTabsComponent,
    TabComponent,
    TableSubtotalComponent,
    HeaderBreakerComponent,
    SimpleTableEmeaComponent,
    SimpleTableHeaderFixedComponent,
    SimpleTableCheckComponent,
    ModalComponent,
    KaExecutionTableComponent,
    TableCustomerViewComponent,
    MknToBookTableComponent,
    TableManagerMarketNameComponent,
    ColumnToggleComponent,
    TablePaginationComponent,
    CardsColorComponent,
    TableFilteringComponent,
    TableNotificationsComponent,
    ThousandSeparatorPipe,
  ],
  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatGridListModule,
    MatTabsModule,
    NgxSpinnerModule,
    NgxSyncScrollModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
    ColorCompactModule,
    MatCheckboxModule,
    ShareDirectiviesModule,
    NgxMatSelectSearchModule,
    MatSnackBarModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AppEchart,
    ColumnMarkerChartModule,
    ClustedColumnChartModule,
    BarChartComponentModule,
    CurrencyMaskModule,
    FormsModule,
    MatExpansionModule,
    CdkTableModule,
    ScrollingModule,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
  ],
  exports: [
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTabsModule,
    NavCardComponent,
    HeaderComponent,
    TableComponent,
    CardsComponent,
    ResultsComponent,
    SearchComponent,
    MatGridListModule,
    NgxSpinnerModule,
    NgxSyncScrollModule,
    InputComponent,
    DynamicFormComponent,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    MatListModule,
    PermissionsComponent,
    MatTooltipModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    VersionComponent,
    ViewTableComponent,
    CardColorComponent,
    ColorCompactModule,
    MatCheckboxModule,
    SimpleTableComponent,
    ViewTableComponent,
    TableExpandableComponent,
    TableExpandableManagerComponent,
    TableCardComponent,
    ShareDirectiviesModule,
    NgxMatSelectSearchModule,
    TableManagerComponent,
    ValueCurrencyComponent,
    AccordionTableComponent,
    InputDateComponent,
    TableColumnsTabsComponent,
    InformationTableComponent,
    TableInputsComponent,
    TableLineInputComponent,
    TableMissingComponent,
    TableOverbookedComponent,
    TableBookComponent,
    AppEchart,
    ColumnMarkerChartModule,
    ClustedColumnChartModule,
    LineMarkerChartComponent,
    TableExpansiveComponent,
    SimpleTableExpansiveComponent,
    TableColorMixComponent,
    BarChartComponentModule,
    CardAllocationComponent,
    SimpleTableExpansiveTabsComponent,
    TabComponent,
    MatExpansionModule,
    TableSubtotalComponent,
    CdkTableModule,
    SimpleTableEmeaComponent,
    SimpleTableHeaderFixedComponent,
    SimpleTableCheckComponent,
    ModalComponent,
    KaExecutionTableComponent,
    TableCustomerViewComponent,
    MknToBookTableComponent,
    TableManagerMarketNameComponent,
    ColumnToggleComponent,
    TablePaginationComponent,
    CardsColorComponent,
    TableFilteringComponent,
    MatSidenavModule,
    MatMenuModule,
    MatToolbarModule,
    TableNotificationsComponent,
    ThousandSeparatorPipe,
  ],
})
export class ShareComponentsModule {}
