import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { CardInterface } from 'src/app/components/cards/card.interface';
import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { Reg } from 'src/app/helpers/Regex';
import { TransformText } from 'src/app/helpers/transformText';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { Profile } from 'src/app/interfaces/profile.iterface';
import { User } from 'src/app/interfaces/user.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class UserComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  searchInputs!: InputBase<string>[];
  regexCheck = new TransformText();
  dataSource: any;
  displayedColumns: any;
  result!: ResultInterface;
  userForm: FormGroup;
  isEdit: boolean = false;
  hasPermissionCreate!: boolean;
  user: any[] = [];
  countryEntity: any[] = [];
  countries: any[] = [];
  geosEntity: any[] = [];
  statusEntity: any[] = [];
  profileEntity: any[] = [];
  salesManagerEntity: any[] = [];
  orderAdminEntity: any[] = [];
  crudService: CrudService<User>;
  profileService: CrudService<Profile>;
  pagination: Pagination;
  filter: any = { sort: 'last_name', pageName: PAGE_NAME_RESOURCE.user };
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  selected_sales_manager = '';
  selected_order_admin = '';
  statusPreviousSalesManager: boolean = false;
  statusPreviousOrderAdmin: boolean = false;
  actualUser: any;
  filteredOptionsUser!: Observable<any[]>;
  filteredOptionsProfile!: Observable<any[]>;
  currentUser!: any;
  updateAllowed = true;
  entities: Array<getEntities> = [
    { entity: 'user', query: { sort: 'last_name' } },
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.user },
    },
    {
      entity: 'country',
      query: { sort: 'country', distinct: "country", pageName: PAGE_NAME_RESOURCE.user },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.user },
    },
    {
      entity: 'profile',
      query: {
        status: true,
        sort: 'profile',
        pageName: PAGE_NAME_RESOURCE.user,
      },
    },
  ];

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.pagination = { page: 0, size: 10 };
    this.crudService = new CrudService<User>(this.http, 'user');
    this.profileService = new CrudService<Profile>(this.http, 'profile');
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('user');
    });

    this.userForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [
        '',
        [Validators.pattern(Reg.EmailMotorolaOrLenovo), Validators.required],
      ],
      country_id: ['', Validators.required],
      status: ['', Validators.required],
      profile_id: ['', Validators.required],
      sales_manager: ['', Validators.required],
      order_admin: ['', Validators.required],
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geo_id: this.currentUser.geoId,
      country_id: this.currentUser.countryId,
    };
  }

  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'last_name',
        hover: 'Last Name',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'name',
        hover: 'Name',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'country_id',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        classCss: 'status-user',
      }),
      new SelectSearchInput({
        key: 'profile_id',
        hover: 'Profile',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  ngOnInit(): void {
    this.loadData();
    this.filterOptionsUser();
    this.filterOptionsProfile();
  }

  loadData() {
    this.getUsers(this.pagination || undefined, this.filter);
    this.getDataFilter(this.entities);
  }

  filterOptionsUser() {
    this.filteredOptionsUser =
      this.userForm.controls.country_id.valueChanges.pipe(
        startWith(''),
        map(value => this.filterOptions(value, 'country'))
      );
  }

  filterOptionsProfile() {
    this.filteredOptionsProfile =
      this.userForm.controls.profile_id.valueChanges.pipe(
        startWith(''),
        map(value => this.filterOptions(value, 'profile'))
      );
  }

  private filterOptions(value: string, value2: string): any[] {
    const filterValue = value ? value.toLocaleUpperCase() : '';
    switch (value2) {
      case 'profile':
        return this.profileEntity.filter((option: any) =>
          option.profile.toLocaleUpperCase().includes(filterValue)
        );
      default:
        return this.countryEntity.filter((option: any) =>
          option.country.toLocaleUpperCase().includes(filterValue)
        );
    }
  }

  getUsers(page?: any, params?: any) {
    this.spinner.show();
    this.crudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        this.updateComponents(response);
        this.spinner.hide();
      });
  }

  getDataFilter(filters: any) {
    const allLastsNames: any[] = [];
    const allNames: any[] = [];
    const allCountries: any[] = [];
    const allStatus: any[] = [];
    const allProfiles: any[] = [];
    const orderAdmin: any[] = [];
    const salesManager: any[] = [];
    const allGeos: any[] = [];
    const initialValueGeoFilter: { [key: string]: any } = {};
    const initialValueCountryFilter: { [key: string]: any } = {};

    this.spinner.show();

    this.crudService.getDataFilters(filters).subscribe((response: any[]) => {
      [, this.geosEntity, this.countryEntity, this.countries, this.profileEntity] = response;

      response[0].forEach((item: User) => {
        const lastName = { value: item.last_name };
        const name = { value: item.name };
        if (
          allLastsNames.findIndex(
            obj => obj.value.toUpperCase() === lastName.value.toUpperCase()
          ) === -1
        ) {
          allLastsNames.push(lastName);
        }
        if (
          allNames.findIndex(
            obj => obj.value.toUpperCase() === name.value.toUpperCase()
          ) === -1
        ) {
          allNames.push(name);
        }
      });
      allNames.sort((current: any, next: any) =>
        current.value.localeCompare(next.value)
      );

      this.geosEntity.forEach((item: Geo) => {
        allGeos.push({ value: item.geo, id: item.id });

        if (item.id === this.currentUser.geoId) {
          initialValueGeoFilter.value = item.geo;
          initialValueGeoFilter.id = item.id;
        }
      });

      this.countryEntity.forEach((item: Country) => {
        allCountries.push({ value: item.country, id: item.id });

        if (item.id === this.currentUser.countryId) {
          initialValueCountryFilter.value = item.country;
          initialValueCountryFilter.id = item.id;
        }
      });

      this.profileEntity.forEach((item: Profile) => {
        allProfiles.push({ key: item.id, value: item.profile });
      });

      const selectCountry = new SelectSearchInput({
        key: 'country_id',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        options: allCountries,
        value: initialValueCountryFilter.value,
      });

      const selectGeo = new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        options: allGeos,
        value: initialValueGeoFilter.value,
      });

      this.searchInputs[2] = selectGeo;
      this.searchInputs[3] = selectCountry;
      this.searchInputs = [...this.searchInputs];
    });

    allStatus.push({ value: 'Active' });
    allStatus.push({ value: 'Inactive' });
    salesManager.push({ value: 'No' });
    salesManager.push({ value: 'Yes' });
    orderAdmin.push({ value: 'No' });
    orderAdmin.push({ value: 'Yes' });

    this.searchInputs = this.createSearchInputs();
    this.statusEntity = allStatus;
    this.searchInputs[0].options = allLastsNames;
    this.searchInputs[1].options = allNames;
    this.searchInputs[4].options = allStatus;
    this.searchInputs[5].options = allProfiles;
    this.orderAdminEntity = orderAdmin;
    this.salesManagerEntity = salesManager;
  }

  updateComponents(user: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(user.rows)),
      table: {
        columns: [
          {
            label: 'last name',
            prop: 'last_name',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'name',
            prop: 'name',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'e-mail',
            prop: 'email',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '20%' },
          },
          { label: 'country', sticky: true, style: { width: '15%' } },
          { label: 'status', sticky: true, style: { width: '10%' } },
          { label: 'profile', sticky: true },
        ],
        rows: new MatTableDataSource<User>(
          this.dataTables(JSON.stringify(user.rows))
        ),
        hasStatus: true,
        tooltipMessage: 'Activate this profile to enable actions for the user',
      },
      pageIndex: user.page,
      pageSize: user.totalRows,
      pagesTotal: user.totalPages,
    };

    const actions = this.permissionService.setTablePermissions('user');
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataMapToUser(data: any): User[] {
    return JSON.parse(data).map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        last_name: item.last_name,
        email: item.email,
        country: item.countries.country,
        geo: item.countries.geos.geo,
        profile: item.profiles.profile,
        status: item.status ? 'Active' : 'Inactive',
        editNotActive: !item.profiles.active,
        deleteNotActive: !item.profiles.active,
        active: item.profiles.active,
      };
    });
  }

  dataTables(data: any) {
    const user = this.dataMapToUser(data);
    const dataTables: any[] = [];

    user.forEach((item: User) => {
      dataTables.push(item);
    });
    return [
      ...dataTables.sort((current: any, next: any) =>
        current.last_name.localeCompare(next.last_name)
      ),
    ];
  }

  dataCards(data: any) {
    const user: User[] = this.dataMapToUser(data);
    const dataCards: CardInterface[] = [];
    const actionCards =
      this.permissionService.setActionsCardsPermission('user');
    user.forEach((item: any) => {
      dataCards.push({
        id: item.id,
        title: `${item.name} ${item.last_name}`,
        subtitle: item.email,
        active: item.status === 'Active',
        iconEdit: { visible: actionCards.iconEdit, enabled: item.active },
        iconDelete: { visible: actionCards.iconDelete, enabled: item.active },
        attributes: [
          {
            key: 'Country',
            value: [item.country],
            isTag: true,
          },
          {
            key: 'Profile',
            value: [item.profile],
            isTag: true,
          },
        ],
      });
    });
    return dataCards.sort((current: any, next: any) =>
      current.title.localeCompare(next.title)
    );
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getUsers(paginated, this.filter);
  }

  onEventActions(event: any) {
    event.type === this.event.edit
      ? this.prepareToEdit(event.item.id)
      : this.onDeleteUser(event.item.id);
  }

  resetFormModal() {
    this.userForm.reset();
  }

  prepareToEdit(id: number) {
    this.spinner.show();
    this.isEdit = true;

    this.crudService.fetchEntity(id).subscribe((user: any) => {
      const countryToEdit = this.countries.filter(
        (country: Country) => country.id === user.country_id
      );
      const profileToEdit = this.profileEntity.filter(
        (profile: Profile) => profile.id === user.profile_id
      );

      // setando os valores para userForm
      this.userForm.controls.id.setValue(user.id);
      this.userForm.controls.last_name.setValue(user.last_name);
      this.userForm.controls.name.setValue(user.name);
      this.userForm.controls.email.setValue(user.email);
      this.userForm.controls.country_id.setValue(countryToEdit[0].country);
      this.userForm.controls.status.setValue(
        user.status ? 'Active' : 'Inactive'
      );
      this.userForm.controls.profile_id.setValue(profileToEdit[0].profile);
      this.userForm.controls.sales_manager.setValue(
        user.sales_manager ? 'Yes' : 'No'
      );
      this.userForm.controls.order_admin.setValue(
        user.order_admin ? 'Yes' : 'No'
      );
      this.statusPreviousSalesManager = user.sales_manager;
      this.statusPreviousOrderAdmin = user.order_admin;
      this.actualUser = user;

      const modal = this.modalService.createModal(this.modalCreateEdit);

      modal.afterClosed().subscribe(() => {
        this.resetFormModal();
      });
      this.spinner.hide();
    });
  }

  prepareToCreate() {
    this.spinner.show();
    this.isEdit = false;
    const createModal = this.modalService.createModal(this.modalCreateEdit);

    createModal.afterClosed().subscribe(() => {
      this.resetFormModal();
    });
    this.spinner.hide();
  }

  regexFields() {
    this.userForm.controls.last_name.setValue(
      this.regexCheck
        .alphanumeric(this.userForm.controls.last_name.value || '')
        .trim()
    );
    this.userForm.controls.name.setValue(
      this.regexCheck
        .alphanumeric(this.userForm.controls.name.value || '')
        .trim()
    );
  }

  messageToastSuccessCreated() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS')} User`
    );
  }

  messageToastSuccessUpdated() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} User`
    );
  }

  messageToastSuccessUpdateSalesManager() {
    this.toastr.error(
      `${this.translatorService.getMessage(
        'CRUD_NO_ALLOWED_UPDATED_SALES_MANAGER'
      )}`,
      `${this.translatorService.getTitle(
        'CRUD_NO_ALLOWED_UPDATED_SALES_MANAGER'
      )}`
    );
  }

  messageToastSuccessUpdateOrderAdmin() {
    this.toastr.error(
      `${this.translatorService.getMessage(
        'CRUD_NO_ALLOWED_UPDATED_ORDER_ADMIN'
      )}`,
      `${this.translatorService.getTitle(
        'CRUD_NO_ALLOWED_UPDATED_ORDER_ADMIN'
      )}`
    );
  }

  messageToastRemoved() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} User`
    );
  }

  messageErrorToast(err: any) {
    this.toastr.error(err.error.message, err.error.title);
  }

  onCreateUser() {
    this.regexFields();
    if (
      this.validateFormService.onValidateFields(this.userForm) &&
      this.userForm.valid
    ) {
      this.spinner.show();
      this.retrieveDataFromAutocompleteModal();
      delete this.userForm.value.id;

      this.crudService.createEntity(this.userForm.value).subscribe(
        () => {
          this.messageToastSuccessCreated();
          this.modalService.closeAll();
          this.resetFormModal();
          this.loadData();
          this.spinner.hide();
        },
        (err: any) => {
          this.messageErrorToast(err);
          this.spinner.hide();
        }
      );
    }
  }

  noPreviousOrderAdmin() {
    if (
      ((this.statusPreviousOrderAdmin &&
        this.userForm.value.order_admin === 'false') ||
        this.userForm.value.status === 'false') &&
      this.actualUser?.salesManagers.length > 0
    ) {
      this.messageToastSuccessUpdateSalesManager();
      this.updateAllowed = false;
    }
  }

  noPreviousSalesManager() {
    if (
      ((this.statusPreviousSalesManager &&
        this.userForm.value.sales_manager === 'false') ||
        this.userForm.value.status === 'false') &&
      this.actualUser?.orderAdmins.length > 0
    ) {
      this.messageToastSuccessUpdateOrderAdmin();
      this.updateAllowed = false;
    }
  }

  onUpdateUser() {
    this.isEdit = true;
    this.regexFields();
    if (
      this.validateFormService.onValidateFields(this.userForm) &&
      this.userForm.valid
    ) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          this.retrieveDataFromAutocompleteModal();
          this.noPreviousOrderAdmin();
          this.noPreviousSalesManager();

          if (this.updateAllowed) {
            this.crudService
              .updateEntity(
                this.userForm.controls.id.value,
                this.userForm.value
              )
              .subscribe(
                () => {
                  this.messageToastSuccessUpdated();
                  this.modalService.closeAll();
                  this.resetFormModal();
                  this.loadData();
                  this.spinner.hide();
                },
                (err: any) => {
                  this.messageErrorToast(err);
                  this.spinner.hide();
                }
              );
          } else {
            this.spinner.hide();
          }
        }
      });
    }
  }

  messageErrorToastDeleted(err: any, id: number) {
    this.crudService.fetchEntity(id).subscribe((user: any) => {
      if (user.sales_manager) {
        this.toastr.error(
          err.error.message,
          err.error.title.replace(/_/g, ' ').replace('SALES MANAGER', '')
        );
      } else {
        this.toastr.error(
          err.error.message,
          err.error.title.replace(/_/g, ' ').replace('ORDER ADMIN', '')
        );
      }
      this.spinner.hide();
    });
  }

  onDeleteUser(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.crudService.deleteEntity(id).subscribe(
          () => {
            this.messageToastRemoved();
            this.spinner.hide();
            this.loadData();
          },
          (err: any) => {
            this.messageErrorToastDeleted(err, id);
            this.spinner.hide();
          }
        );
      }
    });
  }

  dataformValuesCountry(value: any) {
    if (value.country_id) {
      const currentCountry = this.countries.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(value.country_id.toLocaleUpperCase())
      );
      value.country_id = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      value.country_id = '';
    }
  }

  dataformValuesGeo(value: any) {
    if (value.geo_id) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo.toLocaleUpperCase().includes(value.geo_id.toLocaleUpperCase())
      );
      value.geo_id = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      value.geo_id = '';
    }
  }

  dataformValuesProfile(value: any) {
    if (value.profile_id) {
      const currentProfile = this.profileEntity.filter((item: Profile) =>
        item.profile
          .toLocaleUpperCase()
          .includes(value.profile_id.toLocaleUpperCase())
      );
      value.profile_id = currentProfile[0] ? currentProfile[0].id : 0;
    } else {
      value.profile_id = '';
    }
  }

  dataformValuesStatus(value: any) {
    value.status
      ? (value.status = value.status === 'Active')
      : (value.status = '');
  }

  dataformValueslastName(value: any) {
    value.last_name = value.last_name ? value.last_name : '';
  }

  dataformValuesName(value: any) {
    value.name = value.name ? value.name : '';
  }

  onSearchEvent(value: any) {
    const formValue = { ...value };

    this.dataformValuesCountry(formValue);
    this.dataformValuesGeo(formValue);
    this.dataformValuesProfile(formValue);
    this.dataformValuesStatus(formValue);
    this.dataformValueslastName(formValue);
    this.dataformValuesName(formValue);

    this.pagination.page = 0;
    this.filter = {
      ...formValue,
      sort: 'last_name',
      pageName: PAGE_NAME_RESOURCE.user,
    };
    this.getUsers(this.pagination, this.filter);
  }

  retrieveDataFromAutocompleteModal() {
    const currentCountry = this.countries.filter(
      (item: Country) =>
        item.country.toLocaleUpperCase() ===
        this.userForm.value.country_id.toLocaleUpperCase()
    );

    const profileCountry = this.profileEntity.filter(
      (item: Profile) =>
        item.profile.toLocaleUpperCase() ===
        this.userForm.value.profile_id.toLocaleUpperCase()
    );

    this.userForm.value.email = this.userForm.value.email.toLocaleLowerCase();
    this.userForm.value.country_id = currentCountry[0]
      ? currentCountry[0].id
      : 0;
    this.userForm.value.status =
      this.userForm.value.status === 'Active' ? 'true' : 'false';
    this.userForm.value.profile_id = profileCountry[0]
      ? profileCountry[0].id
      : 0;
    this.userForm.value.sales_manager =
      this.userForm.value.sales_manager === 'Yes' ? 'true' : 'false';
    this.userForm.value.order_admin =
      this.userForm.value.order_admin === 'Yes' ? 'true' : 'false';
  }

  backButton() {    
    if (this.currentUser.country.toLocaleLowerCase() === 'brazil') {
      this.router.navigate(['home/setup/admin']);
    } else {
      this.router.navigate(['home/admin']);
    }
  }

  updateSelectFields(field: string) {
    if (field === 'sales_manager' && this.selected_sales_manager === 'Yes') {
      this.selected_order_admin = 'No';
      this.userForm.controls.order_admin.setValue('No');
    } else if (field === 'order_admin' && this.selected_order_admin === 'Yes') {
      this.selected_sales_manager = 'No';
      this.userForm.controls.sales_manager.setValue('No');
    }
  }
}
