<table #simpleTable mat-table [dataSource]="dataSource" class="simple-table">
  <ng-container *ngFor="let col of displayedDef">
    <ng-container [matColumnDef]="col.props">
      <th mat-header-cell *matHeaderCellDef [ngStyle]="col.style">
        <div *ngIf="!hideHeader">
          <mat-card
            [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
            [class.product-column]="col.title === 'Product'"
            >{{ col.title }}</mat-card
          >
          <mat-card
            *ngIf="translateYAbsoluteItem > 0"
            [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
            [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
            [class.product-column]="col.title === 'Product'"
            [class.ka-column]="col.title === 'KA'"
            [class.sales-model-column]="col.title === 'Sales Model'"
            class="absoluteItem"
            >{{ col.title }}</mat-card
          >
        </div>
      </th>
      <td
        mat-cell
        *matCellDef="let element; let indexRow = index"
        id="{{ getAddRowId(indexRow) }}"
        (mouseenter)="mouseEnter(indexRow, element)"
        (mouseleave)="mouseLeave(indexRow, element)"
        [class.hidden-retail]="
          filterKaRetail === false && element.ka.value === 'RETAIL_BRA'
        "
        [class.col-sales-model]="col.props === 'salesModel'"
        [class.col-ka]="col.props === 'ka'"
        [ngClass]="element['ka'].value"
        class="column">
        <div
          *ngIf="col.props === 'salesModel' || col.props === 'ka'"
          class="border"
          [ngClass]="element['ka'].value"
          [ngStyle]="{ 'background-color': element['ka'].color }"></div>
        <span>
          <p
            class="p-simple-table"
            [class.is-not-empty]="isNotEmpty(element[col.props].value)">
            {{ element[col.props].value }}
          </p>
        </span>
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
