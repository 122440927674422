import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-dcm-reports',
  templateUrl: './dcm-reports.component.html',
  styleUrls: ['./dcm-reports.component.scss'],
})
export class DcmReportsComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  pages: any[] = [
    {
      name: 'dashboards',
      url: '../../../assets/images/ico-dcm-dashboards.svg',
      path: 'dashboards',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }
  ngOnInit(): void {
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'DCM',
          this.pages
        );
      });
      if (this.itemsList.length === 0) {
        this.router.navigate(['unauthorized']);
      }
    }
  }
  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
