import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nav-card',
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss'],
})
export class NavCardComponent {
  constructor(private router: Router, private route: ActivatedRoute) { }
  @Input() itemsList: any[] = [];

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }
}
