export class TransformText {
  regex = new RegExp(/[^a-z A-Z\u00C0-\u00FF]+/g);

  alphanumeric(value: string): string {
    if (this.regex.test(value)) {
      return value.replace(this.regex, '');
    }
    return value;
  }
}
