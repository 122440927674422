export const responseTableOnePlanVariation: any = {
  table: {
    columns: [
      {
        label: 'PRODUCT',
        prop: 'product',
        sticky: true,
        onePlanVariation: true,
        style: { width: '40%' },
      },
      {
        label: 'SUM OF CURRENT WK+1',
        prop: 'sumwk01',
        sticky: true,
        onePlanVariation: true,
        style: { width: '20%' },
      },
      {
        label: 'SUM OF CURRENT WK+2',
        prop: 'sumwk02',
        sticky: true,
        onePlanVariation: true,
        style: { width: '20%' },
      },
      {
        label: 'SUM OF MONTH ACC',
        prop: 'summonth',
        sticky: true,
        onePlanVariation: true,
        style: { width: '20%' },
      },
    ],
    rows: [
      {
        product: 'XT2321 - Juno23 8/256 - Barberry',
        sumwk01: 2000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2133 - Lisbon 6/28 Aqua Silver',
        sumwk01: 3000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2095 - Malta 2/32 - Ice Flow',
        sumwk01: 3000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2063 - Racer 5G Midnight',
        sumwk01: 2000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2135 - Hanoi Plus - Ti',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2135 - Hanoi Plus 4/128 - Soft Silver',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2063 - Racer 5G Midnight',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2201 - Rogue - Stardust',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2159 - Cyprus 4/64 - Dark Cedar',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2159 - Cyprus 4/64 - Pink Clay',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2169 - Corfu 5G - Neptune Green',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2169 - Corfu 5G - Opal Iris',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
      {
        product: 'XT2167 - Corfu - Meteorite Black',
        sumwk01: 1000,
        sumwk02: 1000,
        summonth: 1000,
      },
    ],
  },
  page: 0,
  totalPages: 1,
};
