import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

import { CustomMatSelectPanel } from 'src/app/helpers/CustomMatSelectPanel';
import { CardInterface } from '../cards/card.interface';
import { TableInterface } from '../table/table.interface';
import { ResultInterface } from './results.interface';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  providers: [{ provide: MAT_SELECT_CONFIG, useValue: CustomMatSelectPanel }],
})
export class ResultsComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() data!: ResultInterface;
  @Output('resultPaginator') resultPaginator = new EventEmitter();
  @Output('eventActions') eventActions = new EventEmitter();
  @Output('setFilterTableSelected') setFilterTableSelected = new EventEmitter();

  resultTable!: TableInterface;
  resultCard!: CardInterface[];
  defaultSizeCards = 0;
  paginationCards = {
    pageIndex: 0,
    pageSize: 0,
    totalRows: 0,
  };
  type = '';
  currentWeekLabel!: any;

  selectedTab = '';
  @Input() isModeTableAccordion: boolean = false;
  sizeOptions = [10, 25, 50];
  modeTable = true;
  dataTable!: TableInterface;
  showFflterTable: boolean = false;
  @Input() isModeTableOrCardsHide: boolean = false;
  @Input() filterTable: any = [];
  @Input() filterTableSelected: any = '';
  @Input() hidePagination: boolean = false;
  @Input() hideResults: boolean = false;
  @Input() hasTotalRow!: boolean;
  @Input() currentWeek!: string;
  @Input() filterAssociatedOrNot: boolean = true;
  public modeTableOrCard = true;

  constructor() {
    this.modeTableOrCard = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.dataTable = {
        columns: this.data.table?.columns,
        rows: this.data.table?.rows,
        hasStatus: this.data.table?.hasStatus,
        tooltipMessage: this.data.table?.tooltipMessage,
        hasComma: this.data.table?.hasComma,
        hasCalculation: this.data.table?.hasCalculation,
        type: this.data.table?.type,
      };
      this.resultCard = this.data.cards || [];
      this.paginationCards.pageSize = this.data.pageSize;
      this.paginationCards.pageIndex = this.data.pageIndex;
      this.paginationCards.totalRows = this.data.pagesTotal;
      this.selectedTab = this.data.selectedTab;
      this.isModeTableAccordion = this.data.isModeTableAccordion || false;
    }
    if (this.filterTable.length > 0) {
      this.showFflterTable = true;
    }
    if (changes.currentWeek?.currentValue) {
      this.currentWeekLabel = changes.currentWeek.currentValue;
    }
    if (changes.filterAssociatedOrNot?.currentValue) {
      this.filterAssociatedOrNot = changes.filterAssociatedOrNot.currentValue;
    }
  }

  handleFilterButton(event: any) {
    this.setFilterTableSelected.emit(event);
  }

  switchMode() {
    this.modeTableOrCard = !this.modeTableOrCard;
  }

  handlePaginator(event: any) {
    this.resultPaginator.emit({ size: event.pageSize, page: event.pageIndex });
  }

  onCallActions(item: any) {
    this.eventActions.emit(item);
  }
}
