import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { CardInterface } from 'src/app/components/cards/card.interface';
import { InputBase, SelectSearchInput } from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { TransformText } from 'src/app/helpers/transformText';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { User } from 'src/app/interfaces/user.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  searchInputs: InputBase<string>[];
  regexCheck = new TransformText();
  dataSource: any;
  displayedColumns: any;
  result!: ResultInterface;
  isEdit: boolean = false;
  countriesEntity: any[] = [];
  geosEntity: any[] = [];
  statusEntity: any[] = [];
  profileEntity: any[] = [];
  salesManagersEntity: any[] = [];
  crudService: CrudService<User>;
  pagination: Pagination;
  filter: any = {
    sales_manager: true,
    sort: 'last_name',
    status: true,
    pageName: PAGE_NAME_RESOURCE.salesManagement,
  };
  currentUser!: any;
  entities: Array<getEntities> = [
    { entity: 'geo', query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.salesManagement } },
    { entity: 'country', query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.salesManagement } },
    {
      entity: 'user',
      query: {
        sales_manager: true, status: true, sort: 'last_name', pageName: PAGE_NAME_RESOURCE.salesManagement,
      },
    },
  ];

  constructor(
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private permissionService: PermissionService,
    private authService: AuthService,
  ) {
    this.pagination = { page: 0, size: 10 };
    this.crudService = new CrudService<User>(this.http, 'user');
    this.searchInputs = [
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'country_id',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'sales_manager',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geo_id: this.currentUser.geoId,
      country_id: this.currentUser.countryId,
    };
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getSalesManagers(this.pagination || undefined);
    this.getDataFilter();
  }

  getSalesManagers(page?: any, params?: any) {
    this.spinner.show();
    this.crudService.getPaged(page, params || this.filter).subscribe((response: any) => {
      this.updateComponents(response);
      this.spinner.hide();
    });
  }

  getDataFilter() {
    const allCountries: any[] = [];
    const salesManager: any[] = [];
    const allGeos: any[] = [];
    this.spinner.show();
    this.crudService.getDataFilters(this.entities).subscribe((response: any) => {
      [this.geosEntity, this.countriesEntity, this.salesManagersEntity] = response;
      const initialValueGeoFilter: { [key: string]: any } = {};
      const initialValueCountryFilter: { [key: string]: any } = {};

      this.geosEntity.forEach((item: any) => {
        allGeos.push({ value: item.geo, id: item.id });
        if (item.id === this.currentUser.geoId) {
          initialValueGeoFilter.value = item.geo;
          initialValueGeoFilter.id = item.id;
        }
      });

      const selectGeo = new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        options: allGeos,
        value: initialValueGeoFilter.value,
      });

      this.countriesEntity.forEach((item: Country) => {
        allCountries.push({ value: item.country, id: item.id });
        if (item.id === this.currentUser.countryId) {
          initialValueCountryFilter.value = item.country;
          initialValueCountryFilter.id = item.id;
        }
      });

      const selectCountry = new SelectSearchInput({
        key: 'country_id',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        options: allCountries,
        value: initialValueCountryFilter.value,
      });

      this.salesManagersEntity.forEach((item: User) => {
        const viewName: string = `${item.last_name}, ${item.name}`;
        if (salesManager.findIndex((obj) => obj.value === viewName) === -1) {
          salesManager.push({ value: `${item.last_name}, ${item.name}`, id: item.id });
        }
      });

      this.searchInputs[0] = selectGeo;
      this.searchInputs[1] = selectCountry;
      this.searchInputs[2].options = salesManager;
      this.searchInputs = [...this.searchInputs];
    });
  }

  updateComponents(user: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(user.rows)),
      table: {
        columns: [
          { label: 'geo', sticky: true, style: { width: '25%' } },
          { label: 'country', sticky: true, style: { width: '25%' } },
          {
            label: 'sales manager', prop: 'sales_manager', sticky: true, style: { width: '25%' },
          },
          {
            label: 'order admin', prop: 'order_admin', sticky: true, edit: true, delete: true, style: { width: '25%' },
          },
        ],
        rows: new MatTableDataSource<User>(
          this.dataTables(JSON.stringify(user.rows)),
        ),
      },
      pageIndex: user.page,
      pageSize: user.totalRows,
      pagesTotal: user.totalPages,
    };
  }

  dataMapToTable(data: any): User[] {
    return JSON.parse(data).map((item: any) => ({
      id: item.id,
      country: item.countries.country,
      geo: item.countries.geos.geo,
      sales_manager: `${item.last_name}, ${item.name} (${item.email})`,
      order_admin: item.orderAdmins.length > 0 ? item.orderAdmins.map((orderAdmins: any) => `${orderAdmins.orderAdmin.last_name}, ${orderAdmins.orderAdmin.name}`).join(' ; ')
        : '',
    }));
  }

  dataMapToCard(data: any): User[] {
    return JSON.parse(data).map((item: any) => ({
      id: item.id,
      country: item.countries.country,
      geo: item.countries.geos.geo,
      email: item.email,
      sales_manager: `${item.last_name}, ${item.name}`,
      order_admin: item.orderAdmins.map((orderAdmins: any) => `${orderAdmins.orderAdmin.last_name}, ${orderAdmins.orderAdmin.name}`),
    }));
  }

  dataTables(data: any) {
    const user = this.dataMapToTable(data);
    const dataTables: any[] = [];
    user.forEach((item: User) => {
      dataTables.push(item);
    });
    return [...dataTables.sort((current: any, next: any) => current.geo.localeCompare(next.geo))];
  }

  dataCards(data: any) {
    const sales: User[] = this.dataMapToCard(data);
    const dataCards: CardInterface[] = [];
    const actionCards = this.permissionService.setActionsCardsPermission('sales-management');
    sales.forEach((item: User) => {
      dataCards.push({
        id: item.id,
        title: item.sales_manager,
        subtitle: item.email,
        iconDelete: { visible: actionCards.iconDelete, enabled: false },
        attributes: [
          {
            key: 'Geo',
            value: [item.geo],
            isTag: true,
          },
          {
            key: 'Country',
            value: [item.country],
            isTag: true,
          },
          {
            key: 'Order Admin',
            value: item.order_admin,
            isTag: true,
          },
        ],
      });
    });
    return dataCards.sort((current: any, next: any) => current.title.localeCompare(next.title));
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getSalesManagers(paginated, this.filter);
  }

  onSearchEvent(value: any) {
    if (value.country_id) {
      const currentCountry = this.countriesEntity.filter((item: Country) => item.country
        .toLocaleUpperCase().includes(value.country_id.toLocaleUpperCase()));
      value.country_id = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      value.country_id = '';
    }

    if (value.geo_id) {
      const currentGeo = this.geosEntity.filter((item: Geo) => item.geo
        .toLocaleUpperCase().includes(value.geo_id.toLocaleUpperCase()));
      value.geo_id = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      value.geo_id = '';
    }

    if (value.sales_manager) {
      const fullNameSalesManager: string[] = value.sales_manager.split(', ');
      value.last_name = fullNameSalesManager[0] || '';
      value.name = fullNameSalesManager[1] || '';
      value.status = fullNameSalesManager[2] || '';
    }

    this.pagination.page = 0;
    this.filter = {
      ...value, sort: 'last_name', sales_manager: true, status: true, pageName: PAGE_NAME_RESOURCE.salesManagement,

    };
    this.getSalesManagers(this.pagination, this.filter);
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }
}
