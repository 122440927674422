import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { mknToBookMockupResponse } from './mkn-to-book-mockup.component';
import { MatTableDataSource } from '@angular/material/table';
import {
  MknToBookResponse,
  TableData,
  TableRow,
} from 'src/app/interfaces/mkn-to-book.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-mkn-to-book',
  templateUrl: './mkn-to-book.component.html',
  styleUrls: ['./mkn-to-book.component.scss'],
})
export class MknToBookComponent implements OnInit {
  @Input() public dataFilter?: any = 0;
  @Input() public applyed?: any;
  @Input() public changeTabs?: any;
  @Output() responseResult = new EventEmitter<any>();
  filter: any;
  mockupResponse = mknToBookMockupResponse;
  data: any;
  dataRow: any[] = [];
  dataTable: any;
  response: any;
  compositeKeyValue: MknToBookResponse[] = [];
  crudService: CrudService<any>;
  showNoResults: boolean;

  constructor(protected http: HttpClient, private spinner: NgxSpinnerService) {
    this.crudService = new CrudService<any>(this.http, 'toBook');
    this.showNoResults = false;
  }

  ngOnInit(): void {}

  expecificProp(prop: string): boolean {
    return (
      prop === 'family' ||
      prop === 'origin' ||
      prop === 'customerShort' ||
      prop === 'tax'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter = {
      ...this.dataFilter,
      pageName: PAGE_NAME_RESOURCE.familyView,
    };

    if (this.applyed || this.changeTabs) {
      this.loadData();
    }
  }

  async loadData() {
    this.checkResponse();
  }

  async checkResponse() {
    let headerResult: MknToBookResponse[] = [];
    let rowResult: MknToBookResponse[] = [];
    this.data = [];
    this.dataRow = [];
    this.crudService
      .getEntity(`allocation-customer/report-ppm/tobook`, this.filter)
      .subscribe((response: any) => {
        if (Object.keys(response).length > 0) {
          this.showNoResults = false;
          this.data = response;
          response.headers.forEach((header: any) => {
            headerResult.push(header);
          });

          response.rows.forEach((row: any) => {
            this.dataRow.push(row);
            rowResult.push(row);
            this.dataTableResult(headerResult, row.data);
          });
          this.changeTabs = false;
          this.responseResult.emit(response);
        } else {
          this.showNoResults = true;
          this.changeTabs = false;
          this.responseResult.emit(response);
        }
      });
  }

  private dataTableResult(headers?: any, rows?: any, response?: any) {
    if (headers !== undefined && rows !== undefined) {
      this.dataTable = {
        table: {
          columns: headers,
          rows: new MatTableDataSource<string>(
            this.convertToDataSource(JSON.stringify(rows))
          ),
        },
      };
    }

    this.addSubtotalValues(headers, this.dataRow);
  }

  private convertToDataSource(data: string): string[] {
    const array = JSON.parse(data);
    const dataTable: string[] = [];

    array.forEach((item: string) => {
      dataTable.push(item);
    });

    return [...dataTable];
  }

  private addSubtotalValues(header?: any, rows?: any) {
    if (header !== undefined && rows !== undefined) {
      const subtotals: { [key: string]: any } = {};

      rows.forEach((item: any) => {
        item.data.forEach((data: any) => {
          const kaValue = data.family;
          const originValue = data.origin;

          const compositeKey = `${kaValue} ${originValue}`;

          if (!subtotals[compositeKey]) {
            subtotals[compositeKey] = {
              family: kaValue,
              origin: originValue,
              subtotalsByColumn: {},
            };
          }

          header.forEach((col: any) => {
            const headerName = col.props;

            if (
              headerName !== 'family' &&
              headerName !== 'origin' &&
              headerName !== 'tax' &&
              !this.expecificProp(col.props)
            ) {
              if (!subtotals[compositeKey].subtotalsByColumn[headerName]) {
                subtotals[compositeKey].subtotalsByColumn[headerName] = 0;
              }

              subtotals[compositeKey].subtotalsByColumn[headerName] +=
                data[headerName];
            }
          });
        });
      });

      this.data.subtotals = subtotals;

      this.calculateTotal(header, rows);
    }
  }

  private calculateTotal(header?: any, rows?: any) {
    if (header !== undefined && rows !== undefined) {
      const totals: { [key: string]: any } = {
        totalsByColumn: {},
      };

      rows.forEach((item: any) => {
        item.data.forEach((data: any) => {
          header.forEach((col: any) => {
            const headerName = col.props;

            if (
              headerName !== 'family' &&
              headerName !== 'origin' &&
              !this.expecificProp(col.props)
            ) {
              if (!totals.totalsByColumn[headerName]) {
                totals.totalsByColumn[headerName] = 0;
              }

              totals.totalsByColumn[headerName] += data[headerName];
            }
          });
        });
      });

      this.data.totals = totals;
    }
  }
}
