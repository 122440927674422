<div
  *ngIf="displayedContent.length > 0"
  #tableDynamic
  class="table-dynamic"
  [style]="dataSource.style">
  <table>
    <thead>
      <tr class="table-header-row">
        <th *ngFor="let headerColumn of displayedColumns; let indexCol = index">
          <mat-card
            [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
            [class.card-column-expandable]="headerColumn.expanded"
            [class.card-ppm-width]="headerColumn.contentPpmWidth"
            [class.card-main-column-expandable]="
              headerColumn.props === headerColumn.otherContent
            "
            [class.first-card-column-expandable]="headerColumn.isFirstCard"
            [class.last-card-column-expandable]="headerColumn.isLastCard"
            [class.card-column-disable]="
              (!headerColumn.enableContent &&
                headerColumn.currentMonth === false) ||
              headerColumn.weekInTime === 'previous'
            "
            [class.card-column-actual-week]="
              headerColumn.weekInTime === 'current' || headerColumn.currentMonth
            "
            [class.card-intro-product]="headerColumn.headerIntroProduct">
            <span>{{ headerColumn.title }}</span>
            <div
              *ngIf="
                headerColumn.props === headerColumn.otherContent &&
                !headerColumn.hideIconExpand
              ">
              <mat-icon
                *ngIf="!headerColumn.expanded"
                (click)="
                  toggleColumnTable(
                    headerColumn,
                    indexCol,
                    headerColumn.typeShowEvent
                  )
                ">
                {{ 'arrow_left' }}
              </mat-icon>
              <mat-icon
                *ngIf="headerColumn.expanded"
                (click)="
                  toggleColumnTable(
                    headerColumn,
                    indexCol,
                    headerColumn.typeHideEvent
                  )
                ">
                {{ 'arrow_right' }}
              </mat-icon>
            </div>
          </mat-card>

          <mat-card
            *ngIf="translateYAbsoluteItem > 0"
            [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
            [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
            class="absoluteItem"
            [class.card-column-expandable]="headerColumn.expanded"
            [class.card-ppm-width]="headerColumn.contentPpmWidth"
            [class.card-main-column-expandable]="
              headerColumn.props === headerColumn.otherContent
            "
            [class.first-card-column-expandable]="headerColumn.isFirstCard"
            [class.last-card-column-expandable]="headerColumn.isLastCard"
            [class.card-column-disable]="
              (!headerColumn.enableContent &&
                headerColumn.currentMonth === false) ||
              headerColumn.weekInTime === 'previous'
            "
            [class.card-column-actual-week]="
              headerColumn.weekInTime === 'current' || headerColumn.currentMonth
            "
            [class.card-intro-product]="headerColumn.headerIntroProduct">
            <span>{{ headerColumn.title }}</span>
            <div
              *ngIf="
                headerColumn.props === headerColumn.otherContent &&
                !headerColumn.hideIconExpand
              ">
              <mat-icon
                *ngIf="!headerColumn.expanded"
                (click)="
                  toggleColumnTable(
                    headerColumn,
                    indexCol,
                    headerColumn.typeShowEvent
                  )
                ">
                {{ 'arrow_left' }}
              </mat-icon>
              <mat-icon
                *ngIf="headerColumn.expanded"
                (click)="
                  toggleColumnTable(
                    headerColumn,
                    indexCol,
                    headerColumn.typeHideEvent
                  )
                ">
                {{ 'arrow_right' }}
              </mat-icon>
            </div>
          </mat-card>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="let content of displayedContent; let indexContent = index">
        <ng-container
          *ngFor="
            let element of content[this.keyContent];
            let indexGroup = index
          ">
          <ng-container *ngIf="!!element.rows[2]">
            <tr
              *ngFor="let row of element.rows; let indexRow = index"
              id="{{ getAddRowId(indexRow, row?.origin) }}"
              (mouseenter)="mouseEnter(indexRow, row?.origin)"
              (mouseleave)="mouseLeave(indexRow, row?.origin)">
              <ng-container *ngFor="let key of row.value | keys">
                <td
                  class="column"
                  [class.bg-blue]="isCurrentWeek(key)"
                  [class.customer-short]="isColumnCustomerShort(key)"
                  [class.enable-input]="
                    !row.value[key].enableInput && isCurrentWeek(key)
                  "
                  [class.bg-net-open-po]="hasNetOpenPo(key, row.value)"
                  (click)="
                    (key === 'netOpenPo');
                    row.value.netOpenPo?.value > 0
                      ? identifyValueNetOpen(row, key)
                      : null
                  "
                  [ngClass]="{
                    'highlight-row':
                      key === 'netOpenPo' && row.value.netOpenPo?.value > 0
                  }">
                  <div
                    [matTooltip]="generateTooltip(row.value[key], key)"
                    class="flexItems"
                    [class.align-center]="row.contentAlignCenter">
                    <span
                      *ngIf="getColorAlert(key)"
                      class="text-solid"
                      [class.color-alert]="row.value[key].value > 0">
                      <app-value-currency [value]="row.value[key].value">
                      </app-value-currency>
                    </span>
                    <div *ngIf="!getColorAlert(key)">
                      <span
                        [class.bold-value]="row.enableATS || row.enableMLS"
                        [class.negative-value]="row.value[key].value < 0"
                        class="text-solid"
                        *ngIf="!row.value[key].enableInput">
                        <app-value-currency
                          [value]="
                            row.value[key].valueSum >= 0 ||
                            row.value[key].valueSum <= 0
                              ? row.value[key].valueSum
                              : row.value[key].value
                          ">
                        </app-value-currency>
                      </span>
                    </div>
                    <div
                      class="input-table"
                      *ngIf="row.value[key].enableInput"
                      [class.disabled]="row.value[key].isDisabled">
                      <input
                        placeholder="0"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        autocomplete="off"
                        (input)="
                          onChangeValueInput(
                            {
                              columnName: key,
                              value: row.value[key],
                              row,
                              indexRow,
                              content,
                              indexContent,
                              keyContent: this.keyContent,
                              indexGroup
                            },
                            $event
                          )
                        "
                        (blur)="onBlurInput({indexContent, indexGroup, indexRow, indexCol: key}, $event)"
                        matInput
                        value="{{
                          row.value[key].value || '0'
                            | convertValue
                            | number : '1.0-0' : 'en-US'
                        }}"
                        [attr.maxlength]="row.value[key].isDisabled ? 10 : 8"
                        id="{{ row.value[key].key }}" />
                    </div>
                  </div>
                  <div
                    class="flexItemsProduct"
                    [class.align-center]="row.contentAlignCenter"
                    *ngIf="showAtsView && showMlsView">
                    <div
                      *ngIf="
                        (key === 'ppm' && row.value['ppm'].value.length > 1) ||
                        (key === 'origin' &&
                          row.value['origin'].value.length > 1)
                      ">
                      <mat-card
                        *ngIf="translateYAbsoluteItem > 0"
                        [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                        [style.transform]="
                          'translateY(' + translateYAbsoluteItem + 'px)'
                        "
                        [class.hiddenHeaderATS]="hiddenHeaderATS"
                        class="absoluteItemProduct">
                        <!--Fazer igual para ATS-->
                        <span *ngIf="key === 'ppm'; else showOrigin"
                          >{{ row.value[key].value }}
                        </span>
                        <ng-template #showOrigin>
                          <span>{{
                            showProductName(key, row.value[key].value)
                          }}</span>
                        </ng-template>
                      </mat-card>
                    </div>
                    <!--JAG-->
                    <div *ngIf="row.enableATS && showValuesATSandMLS(key)">
                      <mat-card
                        *ngIf="translateYAbsoluteItem > 0"
                        [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                        [style.transform]="
                          'translateY(' + translateYAbsoluteItem + 'px)'
                        "
                        class="absoluteItemATS"
                        [class.hiddenHeaderATS]="hiddenHeaderATS"
                        [class.is-column-broke-number]="isBrokeNumber(key)"
                        [class.is-column-week]="key.includes('wk')">
                        <span
                          *ngFor="let item of showDataHeader(key, element.rows)"
                          [matTooltip]="generateTooltip(row.value[key], key)"
                          [class.bold-value]="row.enableATS"
                          [class.negative-subtotal]="item.value < 0"
                          ><app-value-currency [value]="item.value">
                          </app-value-currency>
                        </span>
                      </mat-card>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <tr
            *ngIf="!!element.rows[2] && element.subtotal"
            class="table-row-subtotal"
            id="subtotal1">
            <ng-container *ngFor="let key of columnsNames; let i = index">
              <td
                *ngIf="i === 0 && showSubtotalTitle"
                class="table-row-subtotal-spacer">
                SUBTOTAL
              </td>
              <td
                [class.negative-subtotal]="element.subtotal[key] < 0"
                *ngIf="element.subtotal"
                [class.color-alert]="
                  getColorAlert(key) && element.subtotal[key] > 0
                "
                [class.text-align-center]="element.textAlignCenter"
                [class.text-empity]="
                  !element.textAlignCenter && !element.subtotal[key]
                ">
                {{
                  element.subtotal[key] || true
                    ? (showSubTotalValue(element.subtotal[key], key)
                      | convertValue
                      | number : '' : 'en-US')
                    : ''
                }}
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
