<mat-tab-group
  (click)="tabClick($event)"
  [selectedIndex]="selectedTabIndex"
  *ngIf="secondTab === false || secondTab === undefined">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <mat-tab disabled label="{{ tab.label }}">
      <ng-container [ngTemplateOutlet]="tab.template"></ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>
<span class="line" *ngIf="secondTab === true"></span>
<mat-tab-group
  class="secondTab"
  (click)="tabClick($event)"
  [selectedIndex]="selectedTabIndexOnePlan"
  *ngIf="secondTab === true">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <mat-tab disabled label="{{ tab.label }}">
      <ng-container [ngTemplateOutlet]="tab.template" class="container-tables">
        <div *ngIf="tab.label === 'Awaiting Shipping'">
          <app-simple-table-check
            class="actual-table table-setup-management"
            [data]="dataTabAwaitingShipping"
            [title]="titleAwaitingShipping"
            [viewMonth]="secondTab"
            *ngIf="!noRecordFoundAs">
          </app-simple-table-check>

          <div class="no-filter-selected-session" *ngIf="noRecordFoundAs">
            <p>NO RECORD FOUND</p>
          </div>
        </div>
        <div *ngIf="tab.label === 'Order Dummy'">
          <app-simple-table-check
            class="actual-table table-setup-management"
            [data]="dataTabOrderDummy"
            [title]="titleOrderDummy"
            [viewMonth]="secondTab"
            *ngIf="!noRecordFoundOd">
          </app-simple-table-check>

          <div class="no-filter-selected-session" *ngIf="noRecordFoundOd">
            <p>NO RECORD FOUND</p>
          </div>
        </div>
        <div *ngIf="tab.label === 'Open PO'">
          <h3 class="title-table">{{ tab.label }}</h3>
          <div class="mode-buttons">
            <button (click)="switchMode()" [class.calendar]="modeTableOpenPO">
              CALENDAR
            </button>
            <button (click)="switchMode()" [class.hold-flag]="!modeTableOpenPO">
              HOLD FLAG
            </button>
          </div>
          <div class="fade-in" [hidden]="!modeTableOpenPO">
            <table
              class="calendar-month"
              *ngIf="dataCalendarValue !== 'undefined'">
              <tbody>
                <tr class="line-header">
                  <th>
                    <mat-card class="column date" style="justify-content: left">
                      <span>ORIGIN</span>
                    </mat-card>
                  </th>
                  <th>
                    <mat-card
                      class="column start-date"
                      style="justify-content: left">
                      <span>START DATE</span>
                    </mat-card>
                  </th>
                  <th>
                    <mat-card
                      class="column end-date"
                      style="justify-content: left">
                      <span>END DATE</span>
                    </mat-card>
                  </th>
                  <th>
                    <mat-card
                      class="column initial-day"
                      style="justify-content: left">
                      <span>INITIAL DAY</span>
                    </mat-card>
                  </th>
                  <th>
                    <mat-card
                      class="column final-day"
                      style="justify-content: left">
                      <span>FINAL DAY</span>
                    </mat-card>
                  </th>
                  <th>
                    <mat-card class="column qtd" style="justify-content: left">
                      <span>QTD</span>
                    </mat-card>
                  </th>
                </tr>
                <tr class="line-man">
                  <td>
                    <div>
                      <span>MAN</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <app-input-date
                        (changeDate)="onChangeDay($event, 'MAN', 'start')"
                        [value]="dateManTable.startDate"
                        [tab]="'openPoCalendar'">
                      </app-input-date>
                    </div>
                  </td>

                  <td>
                    <div>
                      <app-input-date
                        (changeDate)="onChangeDay($event, 'MAN', 'end')"
                        [value]="dateManTable.endDate"
                        [tab]="'openPoCalendar'"
                        [disableButton]="disableDateMan">
                      </app-input-date>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span>{{ dateManTable.initialDay }}</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span>{{ dateManTable.finalDay }}</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span>{{ dateManTable.qtd }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="line-jag">
                  <td>
                    <div>
                      <span>JAG</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <app-input-date
                        (changeDate)="onChangeDay($event, 'JAG', 'start')"
                        [value]="dateJagTable.startDate"
                        [tab]="'openPoCalendar'">
                      </app-input-date>
                    </div>
                  </td>

                  <td>
                    <div>
                      <app-input-date
                        (changeDate)="onChangeDay($event, 'JAG', 'end')"
                        [value]="dateJagTable.endDate"
                        [tab]="'openPoCalendar'"
                        [disableButton]="disableDateJag">
                      </app-input-date>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span>{{ dateJagTable.initialDay }}</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span>{{ dateJagTable.finalDay }}</span>
                    </div>
                  </td>

                  <td>
                    <div>
                      <span>{{ dateJagTable.qtd }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="buttons-action-open-po-calendar"
              [class.disabled]="disabledSaveButton">
              <button
                class="button-cancel-open-po-calendar"
                (click)="cancelOpenPoCalendar()">
                CANCEL
              </button>
              <button
                class="button-save-open-po-calendar"
                (click)="saveOpenPoCalendar()">
                SAVE
              </button>
            </div>
          </div>
          <div class="fade-in" [hidden]="modeTableOpenPO">
            <app-simple-table-check
              class="actual-table table-setup-management-open-po"
              [data]="dataTabOpenPoHoldFlag"
              [viewMonth]="secondTab"
              *ngIf="!noRecordFoundOp">
            </app-simple-table-check>

            <div class="no-filter-selected-session" *ngIf="noRecordFoundOp">
              <p>NO RECORD FOUND</p>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>
<ng-content></ng-content>
<div *ngIf="isEditTableMode" class="buttons-actions-table-allocation">
  <button
    class="button modal-cancel-button button-cancel"
    mat-button
    (click)="this.method.loadModalChanges('discard')">
    CANCEL
  </button>
  <button
    #saveChangesButton
    class="button modal-action-button button-save"
    mat-button
    (click)="this.method.loadModalChanges('save')">
    SAVE
  </button>
</div>
<ng-template #dialogConfirmationDiscardSaveChanges>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      *ngIf="isDiscardChanges"
      class="button modal-action-button"
      mat-button
      matDialogClose="discard">
      YES
    </button>
    <button
      *ngIf="!isDiscardChanges"
      class="button modal-action-button"
      mat-button
      matDialogClose="save">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
