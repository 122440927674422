import { HttpClient } from '@angular/common/http';
import {
  Component, ElementRef, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InputBase, SelectSearchInput } from 'src/app/components/input/input-base';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { FiscalCalendar } from 'src/app/interfaces/fiscalCalendar.interface';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';

@Component({
  selector: 'app-crsd',
  templateUrl: './crsd.component.html',
  styleUrls: ['./crsd.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class CrsdComponent implements OnInit {

  @ViewChild('overflowCalendarRef') overflowCalendarRef!: ElementRef<HTMLInputElement>;

  searchInputs: InputBase<string>[];
  quarters: any = []
  calendarAPI: any = []
  dataExporAPI: any = []
  apiCrudService: CrudService<any>
  filter: any = {
    quarter: this.getQuarterFromDate(new Date())
  };
  editButtons: boolean = false
  showNoResults: boolean = false
  quarterSheet: any;

  //modal dialog
  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: | TemplateRef<any> | any;
  messages: { [key: string]: string } = {
    save: 'Do you confirm data updated?',
    discard: 'Are you sure you want to exit before saving your changes?',
  };
  isDiscardChanges = false;
  messageModalChanges!: string;
  tooltipMessage: string = 'Export';
  isValid: any = false;
  currentYear: any;

  constructor(
    private excelSheetsService: ExcelSheetsService,
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private authService: AuthService,
  ) {
    this.apiCrudService = new CrudService<any>(this.http, 'crsd');
    this.filter = {
      ...this.filter,
      year: this.checkCurrentYearAndQuarter()
    };
    this.searchInputs = [
      new SelectSearchInput({
        key: 'year',
        hover: 'Fiscal Year'
      }),
    ];
  }

  ngOnInit(): void {
    let quarterReplace = this.filter.quarter.slice(0, 1)+ 'Y' + this.filter.quarter.slice(1);
    this.quarterSheet = quarterReplace.toLowerCase();
    this.getDataFilter();
    this.getData(this.filter)
  }

  getDataFilter() {
    const fiscalYearFilter: any[] = [];
    const weekFilter: any[] = [];
    const currentYear = this.checkCurrentYearAndQuarter();
    let quarterFilter: any[] = [];
    this.currentYear = currentYear;
    for (let year = currentYear, i = 0; year <= currentYear + 5; year++, i++) {
      fiscalYearFilter.push({
        key: i,
        value: `${year}/${year + 1}`,
      });
    }

    const YearFiscal = (parseInt(this.filter.year)).toString().substring(2,4)+'/'+(parseInt(this.filter.year) + 1).toString().substring(2,4)
    const NextYearFiscal = (parseInt(this.filter.year) + 1).toString().substring(2,4)+'/'+(parseInt(this.filter.year) + 2).toString().substring(2,4)

    quarterFilter = [
      { key: 'FQ1', value: 'FYQ1 ' },
      { key: 'FQ2', value: 'FYQ2 ' },
      { key: 'FQ3', value: 'FYQ3 ' },
      { key: 'FQ4', value: 'FYQ4 ' },
    ];

    this.searchInputs[0].options = fiscalYearFilter;
    this.searchInputs[0].notClearable = true;
    this.searchInputs[0].value = fiscalYearFilter[0].value
    this.quarters = quarterFilter

  }

  getData(filters:any)
  {
    this.spinner.show()

    filters.year = filters.year.toString().split('/')[0]

    this.apiCrudService.getEntity('crsd', {
      year: filters.quarter === 'FQ4' ? parseInt(filters.year) + 1 : this.filter.year,
      quarter: filters.quarter
    }).subscribe((response:any) => {
      this.calendarAPI = response

      if ( response.length === 0 ) {
        this.showNoResults = true
      } else {
        this.showNoResults = false
      }
      this.spinner.hide()

      setTimeout(() => {
        if ( document.getElementsByClassName('activePosition').length > 0 ){
          const elementActive:any = document.getElementsByClassName('activePosition')[0]
          this.overflowCalendarRef.nativeElement.scrollTo( 0, elementActive.offsetTop );
        } else {
          this.overflowCalendarRef.nativeElement.scrollTo( 0, 0 );
        }
      }, 500)
    })
  }

  onSearchEvent(value: any) {

    if ( this.editButtons === true ){
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'save') {
          this.filter = {
            ...value,
            quarter: this.filter.quarter,
            pageName: PAGE_NAME_RESOURCE.crsd,
          };
          this.getData(this.filter);
        }
      })
    } else {
      this.filter = {
        ...value,
        quarter: this.filter.quarter,
        pageName: PAGE_NAME_RESOURCE.crsd,
      };
      this.getData(this.filter);
    }

    const YearFiscal = (parseInt(this.filter.year)).toString().substring(2,4)+'/'+(parseInt(this.filter.year) + 1).toString().substring(2,4)
    const NextYearFiscal = (parseInt(this.filter.year) + 1).toString().substring(2,4)+'/'+(parseInt(this.filter.year) + 2).toString().substring(2,4)

    this.quarters = [
      { key: 'FQ1', value: 'FYQ1 ' },
      { key: 'FQ2', value: 'FYQ2 ' },
      { key: 'FQ3', value: 'FYQ3 ' },
      { key: 'FQ4', value: 'FYQ4 ' },
    ];

  }

  onChangeQuarter(quarter: number, quarterAll: any)
  {
    this.quarterSheet = quarterAll.value.toLowerCase();
    if ( this.editButtons === true ){
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'save') {
          this.filter.quarter = quarter
          this.getData(this.filter);
          this.editButtons = false
        }
      })
    } else {
      this.filter.quarter = quarter
      this.getData(this.filter);
    }
  }

  onChangeDay(item:{keyCalendar:number, keyDays:number, keyDay: number}, event:any)
  {
    this.calendarAPI[item.keyCalendar].calendar[item.keyDays].dates[item.keyDay].value = event.target.value
    this.calendarAPI[item.keyCalendar].calendar[item.keyDays].dates[item.keyDay].value = event.target.value
    this.calendarAPI[item.keyCalendar].calendar[item.keyDays].edit = true
    this.editButtons = true
  }

  onSave(){
    const dialog = this.getDialogModal('save');
    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'save') {
        this.spinner.show()
        const DTOForAPI:any = []
        this.calendarAPI.forEach((element:any) => {
          const edited = element.calendar.filter((obj:any) => obj.edit === true).map((row:any) => {
            DTOForAPI.push(row)
          })
        });
        this.apiCrudService.updateEntity(0, DTOForAPI).subscribe(response => {
          this.spinner.hide()
          this.editButtons = false
        });
      }
    })
  }

  onCancel(){
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'save') {
          this.getData(this.filter);
          this.editButtons = false;
        }
      })
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(this.dialogConfirmationDiscardSaveChanges);
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  checkCurrentYearAndQuarter() {
    if (this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'FQ1';
    } if (month >= 7 && month <= 9) {
      return 'FQ2';
    } if (month >= 10 && month <= 12) {
      return 'FQ3';
    }
    return 'FQ4';
  }

  exportTable(){
    this.spinner.show()
    let filters = this.filter;
    filters.year = filters.year.toString().split('/')[0]

    this.apiCrudService.getEntity('crsd', {
      year: filters.quarter === 'FQ4' ? parseInt(filters.year) + 1 : this.filter.year,
      quarter: filters.quarter
    }).subscribe((response:any) => {
      this.dataExporAPI = response
      this.spinner.hide()
      const dataFinal: any = [];
      const dataUltimate: any = []
      this.dataExporAPI.forEach((element: any) => {
        const columns: any = [];
        const rows: { [key: string]: [] }[] = [];
        columns.push({title: 'DATE', props: 'date'});
        element.weeks.forEach((ele: any)=>{
          columns.push({title: ele.value, props: ele.value});
        });
        element.calendar.forEach((item: any) => {
          const tableRow: { [key: string]: [] } = {};
          columns.forEach((col: any, indexTwo = 0)=>{
            if(indexTwo === 0){
              tableRow[col.props] = item.name;
            }else{
              let count = indexTwo - 1;
              tableRow[col.props] = item.dates[count].value;
            }
            
          });
          
          rows.push(tableRow);
        });
        dataFinal.push({columns: columns, rows: rows});
      });

      const rowFinal: { [key: string]: [] }[] = [];
      const colFinal: any = [];
      dataFinal.forEach((item: any, index = 0)=>{

        if(index == 0){
          item.columns.forEach((col: any)=>{
            colFinal.push(col);
          });
        }

        item.rows.forEach((row: any, indexTwo = 0)=>{
          const tableRow: { [key: string]: [] } = {};
          const tableColTitle: { [key: string]: [] } = {};
          const tableColClear: { [key: string]: [] } = {};
          if(indexTwo == 0 && index > 0){
            item.columns.forEach((col: any, indexCol: any)=>{
              tableColTitle[dataFinal[0].columns[indexCol].props] = col.title == null ? '--' : col.title;
              tableColClear[dataFinal[0].columns[indexCol].props] = [];
            });
            rowFinal.push(tableColClear);
            rowFinal.push(tableColTitle);
          }
          item.columns.forEach((col: any, IndexThree: any)=>{
            tableRow[dataFinal[0].columns[IndexThree].props] = row[col.props];
          });
          rowFinal.push(tableRow);
        });
      });

      dataUltimate.push({columns: colFinal, rows: rowFinal});

      dataUltimate.forEach((prop: any)=>{
        Object.defineProperty(prop, 'sheet', {
          value: `crsd-${this.quarterSheet}`,
        });
        Object.defineProperty(prop, 'headers', {
          value: true,
        });
      });

      this.excelSheetsService.exportJsonAsExcelFile(dataUltimate, 'crsd');
      });
  }

  changeWeek(week: any){
    let insert = 'EEK';
    let result = week.slice(0, 1) + insert + ' ' + week.slice(1);
    return result;
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

}
