<div #overflowTable class="overflow-table">
  <ng-container>
    <table>
      <thead>
        <tr>
          <th *ngFor="let th of tableHead">
            <div
              [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
              [class.last-child]="th.title === 'OVERBOOKED'"
              class="header">
              <span
                [matTooltipPosition]="'above'"
                [class.align]="alignTest(th.title)"
                matTooltip="{{ th.tooltip ? th.tooltip : '' }}"
                >{{ th.title }}</span
              >
            </div>

            <div
              *ngIf="translateYAbsoluteItem > 0"
              [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
              [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
              [class.last-child]="th.title === 'OVERBOOKED'"
              class="absoluteItem">
              <span
                [matTooltipPosition]="'above'"
                matTooltip="{{ th.tooltip ? th.tooltip : '' }}"
                >{{ th.title }}</span
              >
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let jag of tableBody.jag; let indexRow = index"
          id="{{ indexRow + 'jag' }}"
          (mouseenter)="mouseEnter(indexRow + 'jag')"
          (mouseleave)="mouseLeave(indexRow + 'jag')">
          <td
            *ngFor="let head of tableHead"
            [ngClass]="head.current ? 'active' : ''">
            <span *ngIf="!checkNUmber(jag[head.key]) && !checkKey(head.key)">
              {{ jag[head.key] }}
            </span>
            <span
              *ngIf="
                jag[head.key] <= 0 &&
                checkNUmber(jag[head.key]) &&
                checkKey(head.key)
              "
              class="no-overbooked">
              <app-value-currency [value]="jag[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                jag[head.key] > 0 &&
                checkNUmber(jag[head.key]) &&
                checkKey(head.key)
              "
              class="overbooked">
              <app-value-currency [value]="jag[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                jag[head.key] < 0 &&
                checkNUmber(jag[head.key]) &&
                !checkKey(head.key)
              "
              class="negative">
              <app-value-currency [value]="jag[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                jag[head.key] >= 0 &&
                checkNUmber(jag[head.key]) &&
                !checkKey(head.key)
              "
              class="entered">
              <app-value-currency [value]="jag[head.key]"></app-value-currency>
            </span>
          </td>
        </tr>
        <tr *ngFor="let sub_jag of tableBody.sub_jag" class="tr-hover-subtotal">
          <td *ngFor="let head of tableHead" class="subtotal">
            <span
              *ngIf="!checkNUmber(sub_jag[head.key]) && !checkKey(head.key)"
              class="bold">
              {{ sub_jag[head.key] }}
            </span>
            <span
              *ngIf="
                sub_jag[head.key] <= 0 &&
                checkNUmber(sub_jag[head.key]) &&
                checkKey(head.key)
              "
              class="no-overbooked">
              <app-value-currency
                [value]="sub_jag[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                sub_jag[head.key] > 0 &&
                checkNUmber(sub_jag[head.key]) &&
                checkKey(head.key)
              "
              class="overbooked">
              <app-value-currency
                [value]="sub_jag[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                sub_jag[head.key] < 0 &&
                checkNUmber(sub_jag[head.key]) &&
                !checkKey(head.key)
              "
              class="negative">
              <app-value-currency
                [value]="sub_jag[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                sub_jag[head.key] >= 0 &&
                checkNUmber(sub_jag[head.key]) &&
                !checkKey(head.key)
              "
              class="entered">
              <app-value-currency
                [value]="sub_jag[head.key]"></app-value-currency>
            </span>
          </td>
        </tr>
        <tr
          *ngFor="let man of tableBody.man; let indexRow = index"
          id="{{ indexRow + 'man' }}"
          (mouseenter)="mouseEnter(indexRow + 'man')"
          (mouseleave)="mouseLeave(indexRow + 'man')">
          <td
            *ngFor="let head of tableHead"
            [ngClass]="head.current ? 'active' : ''">
            <span *ngIf="!checkNUmber(man[head.key]) && !checkKey(head.key)">
              {{ man[head.key] }}
            </span>
            <span
              *ngIf="
                man[head.key] <= 0 &&
                checkNUmber(man[head.key]) &&
                checkKey(head.key)
              "
              class="no-overbooked">
              <app-value-currency [value]="man[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                man[head.key] > 0 &&
                checkNUmber(man[head.key]) &&
                checkKey(head.key)
              "
              class="overbooked">
              <app-value-currency [value]="man[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                man[head.key] < 0 &&
                checkNUmber(man[head.key]) &&
                !checkKey(head.key)
              "
              class="negative">
              <app-value-currency [value]="man[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                man[head.key] >= 0 &&
                checkNUmber(man[head.key]) &&
                !checkKey(head.key)
              "
              class="entered">
              <app-value-currency [value]="man[head.key]"></app-value-currency>
            </span>
          </td>
        </tr>
        <tr *ngFor="let sub_man of tableBody.sub_man" class="tr-hover-subtotal">
          <td *ngFor="let head of tableHead" class="subtotal">
            <span
              *ngIf="!checkNUmber(sub_man[head.key]) && !checkKey(head.key)"
              class="bold">
              {{ sub_man[head.key] }}
            </span>
            <span
              *ngIf="
                sub_man[head.key] <= 0 &&
                checkNUmber(sub_man[head.key]) &&
                checkKey(head.key)
              "
              class="no-overbooked">
              <app-value-currency
                [value]="sub_man[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                sub_man[head.key] > 0 &&
                checkNUmber(sub_man[head.key]) &&
                checkKey(head.key)
              "
              class="overbooked">
              <app-value-currency
                [value]="sub_man[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                sub_man[head.key] < 0 &&
                checkNUmber(sub_man[head.key]) &&
                !checkKey(head.key)
              "
              class="negative">
              <app-value-currency
                [value]="sub_man[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                sub_man[head.key] >= 0 &&
                checkNUmber(sub_man[head.key]) &&
                !checkKey(head.key)
              "
              class="entered">
              <app-value-currency
                [value]="sub_man[head.key]"></app-value-currency>
            </span>
          </td>
        </tr>
        <tr *ngFor="let total of tableBody.total">
          <td *ngFor="let head of tableHead" class="total">
            <span
              *ngIf="!checkNUmber(total[head.key]) && !checkKey(head.key)"
              class="bold">
              {{ total[head.key] }}
            </span>
            <span
              *ngIf="
                total[head.key] <= 0 &&
                checkNUmber(total[head.key]) &&
                checkKey(head.key)
              "
              class="no-overbooked">
              <app-value-currency
                [value]="total[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                total[head.key] > 0 &&
                checkNUmber(total[head.key]) &&
                checkKey(head.key)
              "
              class="overbooked">
              <app-value-currency
                [value]="total[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                total[head.key] < 0 &&
                checkNUmber(total[head.key]) &&
                !checkKey(head.key)
              "
              class="negative">
              <app-value-currency
                [value]="total[head.key]"></app-value-currency>
            </span>
            <span
              *ngIf="
                total[head.key] >= 0 &&
                checkNUmber(total[head.key]) &&
                !checkKey(head.key)
              "
              class="entered">
              <app-value-currency
                [value]="total[head.key]"></app-value-currency>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
