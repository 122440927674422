export const lomMockupResponse = {
  headers: [
    {
      title: 'PRODUCT',
      props: 'product',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'SALES MODEL',
      props: 'salesModel',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'ORIGIN',
      props: 'origin',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'DEAL CWV',
      props: 'dealCwv',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'INVOICED',
      props: 'invoiced',
      otherContent: 'invoiced',
      hideIconExpand: false,
      alwaysVisible: true,
      expansive: true,
    },
    {
      title: 'JUL',
      props: 'julInvoiced',
      otherContent: 'invoiced',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'AUG',
      props: 'augInvoiced',
      otherContent: 'invoiced',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'SEP',
      props: 'sepInvoiced',
      otherContent: 'invoiced',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'OPEN ORDERS',
      props: 'openOrders',
      otherContent: 'openOrders',
      hideIconExpand: false,
      alwaysVisible: true,
      expansive: true,
    },
    {
      title: 'DUMMY FLEX',
      props: 'dummyFlex',
      otherContent: 'openOrders',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'BUFFER',
      props: 'buffer',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'TOTAL ACTUAL',
      props: 'totalActual',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'LOM ALLOC FQ2',
      quarter: 'FQ2',
      props: 'lomAllocFq2',
      otherContent: 'lomAllocFq2',
      hideIconExpand: false,
      alwaysVisible: true,
      expansive: true,
    },
    {
      title: 'JUL',
      props: 'julLomAlloc',
      otherContent: 'lomAllocFq2',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'AUG',
      props: 'augLomAlloc',
      otherContent: 'lomAllocFq2',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'SEP',
      props: 'sepLomAlloc',
      otherContent: 'lomAllocFq2',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'TO BOOK',
      props: 'toBook',
      otherContent: 'toBook',
      hideIconExpand: false,
      alwaysVisible: true,
      expansive: true,
    },
    {
      title: 'WK 08',
      props: 'wk08',
      otherContent: 'toBook',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'WK 09',
      props: 'wk09',
      otherContent: 'toBook',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'WK 10',
      props: 'wk10',
      otherContent: 'toBook',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    { title: 'WOI', props: 'woi', hideIconExpand: true, alwaysVisible: true },
    {
      title: 'WOI TARGET',
      props: 'woiTarget',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'PREDICTION WOI',
      props: 'predictionWoi',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'ACTIONS',
      props: 'actions',
      hideIconExpand: true,
      alwaysVisible: true,
    },
  ],
  rows: [
    {
      product: { value: 'XT2135 - Hanoi Plus - Haze Gray' },
      salesModel: { value: 'PAJ0010BR' },
      origin: { value: 'JAG' },
      dealCwv: { value: 480 },
      invoiced: { value: 480 },
      julInvoiced: { value: 120 },
      augInvoiced: { value: 160 },
      sepInvoiced: { value: 200 },
      openOrders: { value: 480 },
      dummyFlex: { value: 300 },
      buffer: { value: 480 },
      totalActual: { value: 960 },
      lomAllocFq2: { value: 530 },
      julLomAlloc: { value: 110 },
      augLomAlloc: { value: 210 },
      sepLomAlloc: { value: 210 },
      toBook: { value: 480 },
      wk08: { value: 160 },
      wk09: { value: 160 },
      wk10: { value: 160 },
      woi: { value: 9.5, woiDays: 21 },
      woiTarget: { value: 10.5 },
      predictionWoi: { value: 9.5 },
      actions: { value: '' },
    },
  ],
};
