<div class="results-container">
  <div class="result-control">
    <div class="result-session">
      <span *ngIf="!hideResults" class="result-length">{{
        paginationCards.pageSize === 0
          ? 'No results were found'
          : paginationCards.pageSize === 1
          ? paginationCards.pageSize + ' Result'
          : paginationCards.pageSize + ' Results'
      }}</span>
    </div>

    <div class="button-filter" *ngIf="filterAssociatedOrNot">
      <button
        *ngFor="let item of filterTable"
        (click)="handleFilterButton(item)"
        [class.active]="item.value === filterTableSelected">
        {{ item.label }}
      </button>
    </div>

    <div class="mode-icons" *ngIf="!isModeTableOrCardsHide">
      <img
        class="icon-results svg icon-list"
        [src]="
          modeTableOrCard
            ? '../../../assets/images/mode-table-enable.svg'
            : '../../../assets/images/mode-table-disable.svg'
        "
        (click)="switchMode()"
        matTooltip="List"
        [matTooltipPosition]="'left'" />
      <img
        class="icon-results"
        [src]="
          !modeTableOrCard
            ? '../../../assets/images/mode-cards-enable.svg'
            : '../../../assets/images/mode-cards-disable.svg'
        "
        (click)="switchMode()"
        matTooltip="Grid"
        [matTooltipPosition]="'left'" />
    </div>
  </div>
  <div class="view-results">
    <div class="fade-in" [hidden]="!modeTableOrCard">
      <div *ngIf="isModeTableAccordion; else appTable">
        <app-table-accordion
          [table]="dataTable"
          (callActions)="onCallActions($event)"
          [selectedTab]="selectedTab"></app-table-accordion>
      </div>
      <ng-template #appTable>
        <app-table
          [table]="dataTable"
          [hasTotalRow]="hasTotalRow"
          [currentWeekValue]="currentWeekLabel"
          (callActions)="onCallActions($event)"></app-table>
      </ng-template>
    </div>
    <div class="fade-in" [hidden]="modeTableOrCard">
      <ul class="container-card-grid">
        <div *ngFor="let item of resultCard">
          <app-cards [dataCard]="item" (callActions)="onCallActions($event)">
          </app-cards>
        </div>
      </ul>
    </div>
  </div>
  <mat-paginator
    class="container-pagination"
    *ngIf="!hidePagination"
    [pageSizeOptions]="sizeOptions"
    [pageIndex]="paginationCards.pageIndex"
    [length]="paginationCards.pageSize"
    (page)="handlePaginator($event)"
    showFirstLastButtons>
  </mat-paginator>
</div>
