import { Component, OnInit } from '@angular/core';

import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { AuthService } from '../../services/authorization/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userDatails: any;
  isPermission: any;
  isLogged: boolean = false;
  hasUserPermission: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sharedInfoService: SharedinfoService,
  ) {
    this.isLogged = this.authService.isLoggedIn();
    this.isPermission = this.authService.getUserPermissions();
  }

  ngOnInit(): void {
    this.sharedInfoService.emitChangeSource.subscribe((response) => {
      if (response.isAuthorized) {
        this.userDatails = localStorage.getItem('user_permissions');
        this.userDatails = this.userDatails == null ? 'no-permissions' : JSON.parse(this.userDatails)[0];
        this.hasUserPermission = true;
      }
    });

    if (this.isPermission.length > 0 && this.isLogged) {
      this.userDatails = localStorage.getItem('user_permissions');
      this.userDatails = this.userDatails != null ? JSON.parse(this.userDatails)[0] : '';
      this.hasUserPermission = true;
    }
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/authorization']);
  }
}
