<div #overflowTable class="overflow-table">
  <ng-container>
    <table>
      <thead>
        <tr>
          <th *ngFor="let th of tableHead">
            <div
              [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
              [class.last-child]="th.title === 'OVERBOOKED'"
              [class.text-center]="
                th.title === 'TOTAL ALLOCATION' || th.title.includes('Wk')
              "
              class="header">
              <span>{{ th.title }}</span>
            </div>

            <div
              *ngIf="translateYAbsoluteItem > 0"
              [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
              [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
              [class.last-child]="th.title === 'OVERBOOKED'"
              [class.text-center]="th.title === 'TOTAL ALLOCATION'"
              class="absoluteItem">
              <span>{{ th.title }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let td of tableBody"
          class="tr-hover"
          [class.subtotal]="isSubtotal(td)">
          <td
            *ngFor="let head of tableHead"
            [class.negative]="td[head.prop] < 0"
            [class.bold]="td[head.prop] === 'SUBTOTAL'"
            [class.overbooked]="head.prop === 'overbooked'">
            <span
              *ngIf="head.prop !== 'ean'"
              [class.align-itens]="isNumber(td[head.prop])">
              <app-value-currency [value]="td[head.prop]"></app-value-currency>
            </span>
            <span *ngIf="head.prop === 'ean'">{{ td[head.prop] }}</span>
          </td>
        </tr>
        <tr *ngFor="let td of tableTotal">
          <td
            *ngFor="let head of tableHead"
            class="total"
            [class.negative]="td[head.prop] < 0"
            [class.bold]="!isNumber(td[head.prop])"
            [class.overbooked]="head.prop === 'overbooked'">
            <span [class.align-itens]="isNumber(td[head.prop])">
              <app-value-currency [value]="td[head.prop]"></app-value-currency
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
