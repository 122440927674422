import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import GetEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService<T> {
  baseUrl: String = environment.api.gcaBackEnd;

  constructor(protected readonly http: HttpClient) {}

  async uploadOnePlan(endpoint: string, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.baseUrl}${endpoint}`;
    return this.http.post<T>(url, formData).toPromise();
  }

  async uploadOnePlanKaInfo(endpoint: string, file: any) {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.baseUrl}${endpoint}`;
    return this.http.post<T>(url, formData).toPromise();
  }
}
