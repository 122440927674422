<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="upload-one-plan-content">
    <div class="content">
      <input type="file" class="file-input" (change)="onFileSelected($event)"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        #fileUpload>
      <div class="file-upload">
        {{fileName || "No file uploaded yet."}}
        <button [class.disable-btn]="!isInputPlan" [disabled]="!isInputPlan" mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
          <mat-icon>attach_file</mat-icon>
        </button>
        <!-- <button mat-mini-fab color="primary" class="upload-btn" (click)="clear(fileUpload)">
          <mat-icon>clear</mat-icon>
        </button> -->
      </div>
    </div>
    <ng-template #dialogConfirmation>
      <header class="title-modal" matDialogTitle>Error</header>
      <mat-dialog-content>{{ messageModal }}</mat-dialog-content>
      <mat-dialog-actions>
        <button class="button modal-action-button" mat-button matDialogClose>
          OK
        </button>
      </mat-dialog-actions>
    </ng-template>
    <button class="button back-action-button position-bottom" mat-button (click)="backButton()">BACK</button>
  </div>
</div>
