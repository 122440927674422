import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ResultInterface } from '../results/results.interface';
import { SimpleTableComponent } from '../simple-table/simple-table.component';
import { TableExpandableComponent } from '../table-expandable/table-expandable.component';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCardComponent implements OnInit {
  @Input() typeTable: string = '';
  @Input() dataSource: any = {};
  @Input() filterKaRetail: any = {};
  @Input() allocationForms!: FormGroup[];
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @ViewChild('simpleTable') simpleTable: SimpleTableComponent | undefined;
  @ViewChild('tableExpandable') tableExpandable:
    | TableExpandableComponent
    | undefined;

  simpleTableResult!: ResultInterface;
  expandedTableResult!: ResultInterface;
  dataSourceTableManager: any[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource?.currentValue) {
      this.dataSource = changes.dataSource.currentValue;
      this.loadSimpleExpandedTableData();
    }
  }

  loadSimpleExpandedTableData() {
    if (this.typeTable !== 'allocationByPPM') {
      this.simpleTableResult = {
        table: {
          columns: this.dataSource.simpleTable.headers,
          rows: new MatTableDataSource<any>(
            this.convertToDataSource(
              JSON.stringify(this.dataSource.simpleTable.rows)
            )
          ),
        },
      };

      this.expandedTableResult = {
        table: {
          columns: this.dataSource.expandedTable.headers,
          rows: new MatTableDataSource<any>(
            this.convertToDataSource(
              JSON.stringify(this.dataSource.expandedTable.rows)
            )
          ),
        },
      };
    }
  }

  inputChanged(event: Event) {
    this.inputChangedEvent.emit(event);
    this.updateValueExpandedResult();
    this.cdr.detectChanges();
  }

  updateValueExpandedResult() {
    if (this.expandedTableResult?.table?.rows) {
      this.expandedTableResult.table.rows.filteredData.forEach((row: any) => {
        const matchingData = this.dataSource.expandedTable.rows.find(
          (data: any) =>
            data.fqVariation.idProduct === row.fqVariation.idProduct &&
            row.origin.ka === data.origin.ka
        );

        if (matchingData) {
          Object.assign(row, matchingData);
        }
      });
    }
  }

  convertToDataSource(data: any) {
    const allocationProducts = JSON.parse(data);
    const dataTable: any[] = [];
    allocationProducts.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  getTableFromHtml() {
    const simpleTableRows = this.simpleTable?.dataTable.table.rows.filteredData;

    const simpleTableColumns = this.simpleTable?.dataTable.table.columns.map(
      (item: any) => ({ title: item.title, props: item.props })
    );
    simpleTableColumns.push({
      title: 'ka',
      props: 'ka',
    });
    const tableExpandableRows =
      this.tableExpandable?.dataTable.table.rows.filteredData;
    const tableExpandableColumns =
      this.tableExpandable?.dataTable.table.columns.map((item: any) => ({
        title: item.title,
        props: item.props,
      }));
    const tableColumns = [...simpleTableColumns, ...tableExpandableColumns];
    const tableRows: { [key: string]: any }[] = [];

    for (let i = 0; i < tableExpandableRows.length; i++) {
      const tableRow: { [key: string]: any } = {};
      tableColumns.forEach((column: any) => {
        if (simpleTableRows[i][column.props]) {
          tableRow[column.props] = simpleTableRows[i][column.props].value;
        } else {
          tableRow[column.props] = tableExpandableRows[i][column.props].value;
        }
      });
      tableRows.push(tableRow);
    }

    return { columns: tableColumns, rows: tableRows };
  }
}
