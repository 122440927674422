export const cards: any[] = [
  {
    title: 'Total',
    totQA: 'Total Allocation Quarter',
    valueTot: 12139,
    dealDemand: 'Deal/Demand',
    valueDD: 12000,
  },
  {
    title: 'Retail',
    totQA: 'Total Allocation Quarter',
    valueTot: '76%',
    dealDemand: 'Deal/Demand',
    valueDD: '73%',
  },
  {
    title: 'Carrier',
    totQA: 'Total Allocation Quarter',
    valueTot: '24%',
    dealDemand: 'Deal/Demand',
    valueDD: '27%',
  },
];

export const barChartDataMockup: any = {
  data: {
    source: [
      {
        product: 'Claro',
        totalAllocationQuarter: 43.3,
        dealDemand: 85.8,
      },
      {
        product: 'Vivo',
        totalAllocationQuarter: 83.1,
        dealDemand: 73.4,
      },
      {
        product: 'Tim',
        totalAllocationQuarter: 86.4,
        dealDemand: 65.2,
      },
      {
        product: 'Retail',
        totalAllocationQuarter: 72.4,
        dealDemand: 53.9,
      },
    ],
  },
};

export const lineChartDataMockup: any = {
  data: {
    source: [
      {
        type: 'line',
        dataRetail: [20, 30, 100],
        product: 'Retail',
      },
      {
        type: 'line',
        dataClaro: [25, 50, 30],
        product: 'Claro',
      },
      {
        type: 'line',
        dataTim: [50, 70, 120],
        product: 'Tim',
      },
      {
        type: 'line',
        dataVivo: [70, 85, 110],
        product: 'Vivo',
      },
    ],
  },
};
