import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { responseTableOnePlanVariation } from './one-plan-variation-table.mockup';
import { MatTableDataSource } from '@angular/material/table';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';

@Component({
  selector: 'app-one-plan-variation',
  templateUrl: './one-plan-variation.component.html',
  styleUrls: ['./one-plan-variation.component.scss'],
})
export class OnePlanVariationComponent implements OnInit {
  @ViewChild('tableOnePlanVariation') tableOnePlanVariation: undefined | any;

  isModeTableOrCardsHide: boolean = true;
  hidePagination: boolean = true;
  tableOnePlan = responseTableOnePlanVariation;
  currentWeek: any;
  result: any;
  crudService: CrudService<any>;
  noResultOnePlanVariation: boolean = false;
  translateYAbsoluteItem: number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    protected http: HttpClient,
    private app: ElementRef
  ) {
    this.crudService = new CrudService<any>(this.http, 'onePlan');
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.getDataTable();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPage();
  }

  scrollPage() {
    const tableHeader = this.app.nativeElement.querySelector(
      '.table-body thead tr'
    );

    if (tableHeader) {
      const windowTop = window.scrollY || window.pageYOffset;
      const tableTop =
        this.app.nativeElement.getBoundingClientRect().top + windowTop;
      const offset = 105;
      const height = `${windowTop - offset - tableTop}px`;

      tableHeader.classList.add('fixed-header');
      tableHeader.style.top = `${height}`;
    }
  }

  getCurrentWeek(week: any) {
    let responseWeek = week;
    this.currentWeek = `Current Week: ${responseWeek.slice(3)}`;
  }

  getDataTable() {
    this.crudService
      .getEntity(`onePlan/flex-variation`, {
        pageName: PAGE_NAME_RESOURCE.flexOnePlanVariation,
      })
      .subscribe(
        (response: any) => {
          if (response.table.rows.length > 0) {
            this.getCurrentWeek(response.table.current);
            this.dataTableResponse(response.table);
            this.noResultOnePlanVariation = false;
            this.spinner.hide();
          } else {
            this.noResultOnePlanVariation = true;
            this.spinner.hide();
          }
        },
        (err: any) => {
          this.spinner.hide();
        }
      );
  }

  dataTableResponse(data: any) {
    this.result = {
      table: {
        columns: data.columns,
        rows: new MatTableDataSource<any>(data.rows),
        hasComma: true,
      },
    };
  }

  backButton() {
    this.router.navigate(['home/one-plan']);
  }
}
