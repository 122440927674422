import { Component, Input, OnChanges } from '@angular/core';
import { arrayDetails } from 'src/app/interfaces/location.interface';

@Component({
  selector: 'app-accordion-table',
  templateUrl: './accordion-table.component.html',
  styleUrls: ['./accordion-table.component.scss'],
})
export class AccordionTableComponent implements OnChanges {
  @Input() data!: any;
  @Input() selectedTab!: any;
  @Input() result!: any;
  label: string = '';
  labelCountry: string = '';
  labelIsoCode: string = '';
  arrayDetails: any = [];
  arrayDetailsCountry: arrayDetails[] = [];
  arrayDetailsIsoCode: arrayDetails[] = [];

  constructor() {}

  ngOnChanges(): void {
    if (this.selectedTab) {
      switch (this.selectedTab) {
        case 'customer_short':
          this.label = this.data.customerNumber;
          this.data.customerNumberDetails?.forEach((item: any) => {
            this.arrayDetails.push(item.customer_number);
          });
          break;
        case 'customer_list':
          this.label = this.data.customerShort;
          this.data.customerShortDetails?.forEach((item: any) => {
            this.arrayDetails.push(item.customer_short);
          });
          break;
      }

      if (this.selectedTab === 'emea_location') {
        this.labelCountry = this.data.country;

        this.data.countriesDetails.sort(this.sortByName);

        this.data.countriesDetails?.forEach((item: any) => {
          this.arrayDetailsCountry.push(item.country);
        });

        this.labelIsoCode = this.data.iso_code;

        this.data.iso_codes_details.sort(this.sortByName);

        this.data.iso_codes_details?.forEach((item: any) => {
          this.arrayDetailsIsoCode.push(item.iso_code);
        });
      }

      if (this.selectedTab === 'emea_exception') {
        this.labelCountry = this.data.country;

        this.data.countriesDetails.sort(this.sortByName);

        this.data.countriesDetails?.forEach((item: any) => {
          this.arrayDetailsCountry.push(item.country);
        });

        this.labelIsoCode = this.data.iso_code;

        this.data.iso_codes_details.sort(this.sortByName);

        this.data.iso_codes_details?.forEach((item: any) => {
          this.arrayDetailsIsoCode.push(item.iso_code);
        });
      }
    }
  }

  sortByName(a: arrayDetails, b: arrayDetails) {
    const nameA = a.country.toUpperCase();
    const nameB = b.country.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }

  trackByIndex = (i: number) => i;
}
