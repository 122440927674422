<div class="container-cards">
  <div *ngFor="let card of cardsColor">
    <div class="card-color">
      <div class="card-border" [style.background]="card.borderColor"></div>
      <mat-card class="{{ card.styleClass }}">
        <mat-card-title *ngIf="card.title">{{ card.title }}</mat-card-title>
        <mat-card-subtitle *ngIf="card.subtitle">{{ card.subtitle }}</mat-card-subtitle>
        <mat-card-content *ngFor="let content of card.content">
          <div>
            <span *ngIf="content.label" style="margin-right: 5px">{{ content.label }}</span>
            <span class="{{ content.valueClass}}">{{ content.value }}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
