import { Component, Input, OnInit } from '@angular/core';
import { CardColorInterface } from './card-color.interface';

@Component({
  selector: 'app-card-color',
  templateUrl: './card-color.component.html',
  styleUrls: ['./card-color.component.scss'],
})
export class CardColorComponent implements OnInit {
  @Input() cardsColor?: CardColorInterface[];

  constructor() { }

  ngOnInit(): void {
  }
}
