<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">PROFILE</span>
    <button
      *ngIf="hasPermissionCreate"
      class="button modal-action-button"
      mat-button
      (click)="prepareToCreate()">
      NEW PROFILE
    </button>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results
    class="table-profile"
    [data]="result"
    (eventActions)="onEventActions($event)"
    (resultPaginator)="onChangePaginator($event)">
  </app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>
<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="profileForm" class="form-profile">
    <header class="title-modal" matDialogTitle>
      {{ isEdit ? 'EDIT PROFILE' : 'NEW PROFILE' }}
    </header>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Profile</mat-label>
        <input
          matInput
          formControlName="profile"
          autocomplete="off"
          maxlength="50"
          appInputAlphanumeric />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select
          formControlName="active"
          name="status"
          value="status"
          (selectionChange)="onChangeStatus($event)"
          disableOptionCentering
          panelClass="modal-panel-select-unique-profile">
          <mat-option *ngFor="let status of statusEntity" [value]="status.key">
            {{ status.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <mat-select
          formControlName="countries"
          name="country_id"
          multiple
          disableOptionCentering
          panelClass="modal-panel-select-multiple-material">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <header class="title-modal" matDialogTitle>PERMISSIONS</header>
    <app-tab-permissions
      [editMode]="permissionsEditMode"
      [dataPermissions]="userPermissions"
      (changePermissions)="onChangePermissions($event)"></app-tab-permissions>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        *ngIf="!isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onCreateProfile()">
        SAVE
      </button>
      <button
        *ngIf="isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onUpdateProfile()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    {{
      isEdit
        ? 'Do you confirm to edit Profile?'
        : 'Do you confirm to remove Profile?'
    }}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #modalPermissions class="custom-dialog-container">
  <div class="view-profile">
    <header class="title-modal" matDialogTitle>PERMISSIONS</header>
    <mat-dialog-content>
      <app-tab-permissions
        [viewMode]="permissionsViewSystem"
        [dataPermissions]="userPermissions"
        (changePermissions)="onChangePermissions($event)">
      </app-tab-permissions>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="modal-close-permissions" mat-button matDialogClose>
        CANCEL
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
