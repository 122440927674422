import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-customer-allocation-retail',
  templateUrl: './customer-allocation-retail.component.html',
  styleUrls: ['./customer-allocation-retail.component.scss'],
})
export class CustomerAllocationRetailComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  pages: any[] = [
    {
      name: 'Allocation Retail',
      url: '../../../assets/images/ico-allocation-retail.svg',
      path: 'allocation-retail',
    },
    {
      name: 'Missing',
      url: '../../../assets/images/ico-missing.svg',
      path: 'missing',
    },
    {
      name: 'To Book',
      url: '../../../assets/images/ico-tobook.svg',
      path: 'to-book',
    },
    {
      name: 'Overbooked',
      url: '../../../assets/images/ico-overbooked.svg',
      path: 'overbooked',
    },
    {
      name: 'Customer View',
      url: '../../../assets/images/ico-customer-view.svg',
      path: 'customer-view',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }

  ngOnInit(): void {
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'CUSTOMER ALLOCATION',
          this.pages
        );
      });
      if (this.itemsList.length === 0) {
        this.router.navigate(['unauthorized']);
      }
    }
  }

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
