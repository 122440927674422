<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title" *ngIf="selectedTabIndex === 0">LOCATION</span>
    <span class="title" *ngIf="selectedTabIndex === 1">EXCEPTION</span>
    <button
      *ngIf="hasPermissionCreate && selectedTabIndex === 0"
      class="button modal-action-button"
      mat-button
      (click)="prepareToCreate()">
      NEW TERRITORY
    </button>

    <button
      *ngIf="selectedTabIndex === 1"
      class="button modal-action-button"
      mat-button
      (click)="prepareToCreateException()">
      NEW EXCEPTION
    </button>
  </div>

  <app-tab
    [tabs]="tabs"
    [method]="this"
    [messagesDialog]="messages"
    [isEditTableMode]="isEditTableMode"
    (activeTabIndexChange)="onActiveTabIndexChange($event)"
    #TabComponent>
  </app-tab>

  <ng-template #Location>
    <app-search
      (searchEvent)="onSearchEvent($event, 'location')"
      [filters]="searchInputs"></app-search>

    <span class="line"></span>

    <app-results
      [data]="result"
      (eventActions)="onEventActions($event, 'location')"
      (resultPaginator)="onChangePaginator($event)"
      [isModeTableAccordion]="isModeTableAccordion"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
      *ngIf="!noResultLocation"></app-results>

    <div class="no-filter-selected-session" *ngIf="noResultLocation">
      <p>NO RECORDS FOUND.</p>
    </div>
  </ng-template>

  <ng-template #Exception>
    <app-search
      (searchEvent)="onSearchEvent($event, 'exception')"
      [filters]="searchInputsException"></app-search>

    <span class="line"></span>

    <app-results
      [data]="resultException"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
      (eventActions)="onEventActions($event, 'exception')"
      (resultPaginator)="onChangePaginator($event)"
      *ngIf="!noResultException"></app-results>

    <div class="no-filter-selected-session" *ngIf="noResultException">
      <p>NO RECORDS FOUND.</p>
    </div>
  </ng-template>

  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>

<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="locationForm" class="form">
    <header class="title-modal" matDialogTitle>
      {{ isEdit ? 'EDIT TERRITORY' : 'NEW TERRITORY' }}
    </header>
    <mat-dialog-content>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>GEO</mat-label>
        <mat-select
          [formControl]="geoMultiCtrl"
          [disabled]="true"
          placeholder="GEO"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="geoMultiFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let geo of filteredGeoMulti | async"
            [value]="geo.id">
            {{ geo.geo }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Territory</mat-label>
        <input matInput formControlName="territory" autocomplete="off" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Country</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="countryMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Country"
          [multiple]="true"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="countryMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let country of filteredCountryMulti | async"
            [value]="country.id">
            {{ country.country }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="countryMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.countryMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-content class="mat-content-location">
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Order Date</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="orderDateMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Order Date"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="orderDateMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let order_date of filteredOrderDateMulti | async"
            [value]="order_date.summary_id">
            {{ order_date.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="orderDateMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.orderDateMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Order Type</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="orderTypeMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Order Type"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="orderTypeMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let order_type of filteredOrderTypeMulti | async"
            [value]="order_type.summary_id">
            {{ order_type.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="orderTypeMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.orderTypeMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Plant Location</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="plantLocationMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Plant Location"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="plantLocationMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let plant_location of filteredPlantLocationMulti | async"
            [value]="plant_location.summary_id">
            {{ plant_location.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="plantLocationMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.plantLocationMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-content class="mat-content-location">
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Storage Location</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="storageLocationMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Storage Location"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="storageLocationMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let storage_location of filteredStorageLocationMulti | async
            "
            [value]="storage_location.summary_id">
            {{ storage_location.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="storageLocationMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.storageLocationMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Customer Order</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="customerOrderTypeMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Customer Order"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="customerOrderTypeMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let customer_order of filteredCustomerOrderTypeMulti | async
            "
            [value]="customer_order.summary_id">
            {{ customer_order.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="customerOrderTypeMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.customerOrderTypeMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Shipping Point</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="shippingPointMultiCtrl"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers"
          inputDirective
          placeholder="Shipping Point"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="shippingPointMultiFilterCtrl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let shipping_point of filteredShippingPointMulti | async"
            [value]="shipping_point.summary_id">
            {{ shipping_point.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="shippingPointMultiCtrl.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.shippingPointMultiCtrl)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        *ngIf="!isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onCreate()">
        SAVE
      </button>
      <button
        *ngIf="isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onUpdate()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #modalCreateEditException class="custom-dialog-container">
  <form [formGroup]="exceptionForm" class="form">
    <header class="title-modal" matDialogTitle>
      {{ isEditException ? 'EDIT EXCEPTION' : 'NEW EXCEPTION' }}
    </header>
    <mat-dialog-content>
      <mat-form-field appearance="outline" [class.disabled]="isEditException">
        <mat-label>GEO</mat-label>
        <mat-select
          [formControl]="geoMultiCtrlException"
          [disabled]="true"
          placeholder="GEO"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="
                geoMultiFilterCtrlException
              "></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let geo of filteredGeoMultiException | async"
            [value]="geo.id">
            {{ geo.geo }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="geoMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.geoMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Territory</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="territoryCrtlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers-exception"
          inputDirective
          placeholder="Territory"
          [multiple]="false"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="territoryCrtlFilterException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let territory of filteredTerritoryMultiException | async"
            [value]="territory.id">
            {{ territory.territory }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="territoryCrtlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.territoryCrtlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Country</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="countryMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Country"
          [multiple]="true"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="countryMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let country of filteredCountryMultiException | async"
            [value]="country.id">
            {{ country.country }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="countryMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.countryMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-content class="mat-content-location">
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Data Type</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="dataTypeMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-numbers-exception"
          inputDirective
          placeholder="Data Type"
          [multiple]="false"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="dataTypeMultiCtrlFilterException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let data_type of filteredDataTypeMultiException | async"
            [value]="data_type.value">
            {{ data_type.value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="dataTypeMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.dataTypeMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Order Type</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="orderTypeMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Order Type"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="orderTypeMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let order_type of filteredOrderTypeMultiException | async"
            [value]="order_type.summary_id">
            {{ order_type.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="orderTypeMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.orderTypeMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Plant Location</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="plantLocationMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Plant Location"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="plantLocationMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let plant_location of filteredPlantLocationMultiException | async
            "
            [value]="plant_location.summary_id">
            {{ plant_location.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="plantLocationMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.plantLocationMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-content class="mat-content-location">
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Storage Location</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="storageLocationMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Storage Location"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="storageLocationMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let storage_location of filteredStorageLocationMultiException
                | async
            "
            [value]="storage_location.summary_id">
            {{ storage_location.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="storageLocationMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.storageLocationMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Customer Order</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="customerOrderTypeMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Customer Order"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="customerOrderTypeMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let customer_order of filteredCustomerOrderTypeMultiException
                | async
            "
            [value]="customer_order.summary_id">
            {{ customer_order.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="customerOrderTypeMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.customerOrderTypeMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Order Date</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="orderDateMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Order Date"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="orderDateMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let order_date of filteredOrderDateMultiException | async"
            [value]="order_date.summary_id">
            {{ order_date.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="orderDateMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.orderDateMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-content class="mat-content-location shipping-point">
      <mat-form-field appearance="outline" class="mat-select-search">
        <mat-label>Shipping Point</mat-label>
        <mat-select
          class="mat-select-search"
          [formControl]="shippingPointMultiCtrlException"
          panelClass="multiselect-search"
          panelClass="multiselect-search-exception"
          inputDirective
          placeholder="Shipping Point"
          multiple
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="shippingPointMultiFilterCtrlException"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="
              let shipping_point of filteredShippingPointMultiException | async
            "
            [value]="shipping_point.summary_id">
            {{ shipping_point.summary_value }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="shippingPointMultiCtrlException.value?.length > 0"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearControlValue(this.shippingPointMultiCtrlException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        *ngIf="!isEditException"
        class="button modal-action-button"
        mat-button
        (click)="onCreateExeption()">
        SAVE
      </button>
      <button
        *ngIf="isEditException"
        class="button modal-action-button"
        mat-button
        (click)="onUpdateException()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #modalViewParametrization class="custom-dialog-container">
  <app-view-table
    [dataTable]="dataView"
    [dataTerritory]="territoryView"></app-view-table>

  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      CLOSE
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content *ngIf="selectedTabIndex === 0">
    {{ isEdit === 'create' ? 'Do you confirm to create this Territory?' : '' }}
    {{ isEdit === true ? 'Do you confirm to edit Territory?' : '' }}
    {{ isEdit === false ? 'Do you confirm to remove this Territory?' : '' }}
    {{ isEdit === 'delete' ? messageModalDeleteConfirmation : '' }}
  </mat-dialog-content>
  <mat-dialog-content *ngIf="selectedTabIndex === 1">
    {{
      isEditException === 'create'
        ? 'Do you confirm to create this Territory?'
        : ''
    }}
    {{ isEditException === true ? 'Do you confirm to edit Territory?' : '' }}
    {{
      isEditException === false
        ? 'Do you confirm to remove this Territory?'
        : ''
    }}
    {{ isEditException === 'delete' ? messageModalDeleteConfirmation : '' }}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
