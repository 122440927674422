import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-simple-table-expansive',
  templateUrl: './simple-table-expansive.component.html',
  styleUrls: ['./simple-table-expansive.component.scss'],
})
export class SimpleTableExpansiveComponent implements OnInit {
  @Input() dataTable!: any;
  @Input() nameTable!: any;
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @Output('toggleColumnTable') toggleColumnTableEvent = new EventEmitter();
  @Output() changeTab = new EventEmitter<any>();
  panelOpenState = false;
  headers: any = [];
  content: any = [];
  dataSource = new MatTableDataSource<any>();
  displayedDef: any[] = [];
  displayedDefTest: any = [];
  displayedColumns: any;
  weekTitleNames: any[] = [];
  expandedRows: number[] = [];
  myVar: boolean = true;
  listIdTableOne: any = [];
  listIdTableTwo: any = [];
  tdName: string = 'table-';
  tableOrigin: string = '';
  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private app: ElementRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.dataTable?.currentValue) {
      this.headers = [];
      this.content = [];
      this.displayedDef = [];
      this.displayedColumns = [];
      this.loadTable(changes.dataTable.currentValue);
    }

    if (changes.nameTable?.currentValue) {
      this.tableOrigin = changes?.nameTable.currentValue;
    }

    if (changes.displayedColumns?.currentValue) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }
  }

  toggleSubtitles(rowIndex: number) {
    this.expandedRows = this.expandedRows.includes(rowIndex)
      ? this.expandedRows.filter((row): any => row !== rowIndex)
      : [...this.expandedRows, rowIndex];
  }

  isExpanded(rowIndex: number) {
    return this.expandedRows.includes(rowIndex);
  }

  loadTable(updateDataTable?: any) {
    this.dataTable = updateDataTable || this.dataTable;
    if (this.dataTable?.table) {
      this.dataSource = this.dataTable.table.rows;
      this.displayedDef = this.dataTable?.table.columns;
      this.displayedColumns = this.displayedDef
        .filter((item: any) =>
          item.props && item.secondColumn === false && item.alwaysVisible
            ? item.props && item.secondColumn === false && item.alwaysVisible
            : (item.secondColumn = true) && item.alwaysVisible != false
        )
        .map((item: any) => item.props);
    }

    this.displayedDef.filter((item: any) => {
      if (item.passingButton) {
        item.expanded = false;
      }
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPage();
  }

  scrollPage() {
    const tableHeader = this.app.nativeElement.querySelector('#table thead tr');
    const tableFooter = this.app.nativeElement.querySelector('#footer');

    if (tableHeader && tableFooter) {
      const windowTop = window.scrollY || window.pageYOffset;
      const tableTop =
        this.app.nativeElement.getBoundingClientRect().top + windowTop;
      const footerTop = tableFooter.getBoundingClientRect().top + windowTop;

      const height = `${windowTop - tableTop}px`;

      if (windowTop + tableHeader.offsetHeight <= footerTop) {
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${height}`;
      } else {
        // parar antes do footer
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${
          footerTop - tableTop - tableHeader.offsetHeight
        }px`;
      }
    }
    this.cd.detectChanges();
  }

  toggleColumnTable(col?: any, event?: string) {
    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContent === col.otherContent
    );

    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );

    const indexCol = this.displayedColumns.findIndex(
      (item: any) => item === col?.otherContent
    );

    columsToShowHide.shift();
    if (event === 'show') {
      col.expanded = true;
      groupColumnsToShowHide[0].isFirstCard = true;
      groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
        true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expanded = true;
      });

      this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
    } else {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
    }

    this.toggleColumnTableEvent.emit({
      col,
      event,
    });
    this.cd.detectChanges();
  }

  expandedRow(index: any, table: any) {
    let auxIndex: string = 'table-' + index.toString();
    const myDiv = this.el.nativeElement.querySelectorAll(
      `td div[name="${auxIndex}"]`
    );
    if (this.listIdTableOne.length <= 0) {
      myDiv.forEach((item: any) => {
        item.className = 'content-two';
      });
      this.listIdTableOne.push(index);
    } else {
      if (this.listIdTableOne.length == 1 && this.listIdTableOne[0] == index) {
        myDiv.forEach((item: any) => {
          item.className = 'content-one';
        });
        this.listIdTableOne = [];
      } else {
        if (this.listIdTableOne.includes(index)) {
          myDiv.forEach((item: any) => {
            item.className = 'content-one';
          });
          this.listIdTableOne = this.listIdTableOne.filter(
            (item: any) => item !== index
          );
        } else {
          this.listIdTableOne.push(index);
          myDiv.forEach((item: any) => {
            item.className = 'content-tow';
          });
        }
      }
    }
    this.changeIcon(index, table);
  }

  changeIcon(index: any, table: any) {
    let auxIndex: string = 'table-' + index.toString();
    let auxIconOneWow: string = 'wow-' + auxIndex + '-one';
    let auxIconTwoWow: string = 'wow-' + auxIndex + '-two';
    const myIconOneWow: any = document.getElementById(`${auxIconOneWow}`);
    const myIconTwoWow: any = document.getElementById(`${auxIconTwoWow}`);
    let auxIconOneSupply: string = 'ss-' + auxIndex + '-one';
    let auxIconTwoSupply: string = 'ss-' + auxIndex + '-two';
    const myIconOneSupply: any = document.getElementById(`${auxIconOneSupply}`);
    const myIconTwoSupply: any = document.getElementById(`${auxIconTwoSupply}`);

    if (table == 'wow') {
      if (myIconOneWow?.className === 'show-icon') {
        myIconOneWow.className = 'hide-icon';
        myIconTwoWow.className = 'show-icon';
      } else {
        myIconOneWow.className = 'show-icon';
        myIconTwoWow.className = 'hide-icon';
      }
    } else {
      if (myIconOneSupply?.className === 'show-icon') {
        myIconOneSupply.className = 'hide-icon';
        myIconTwoSupply.className = 'show-icon';
      } else {
        myIconOneSupply.className = 'show-icon';
        myIconTwoSupply.className = 'hide-icon';
      }
    }
  }
}
