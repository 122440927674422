export const BarCharColumnMockup: any = {
  data: {
    dataSource: [
      {
        name: 'Buffer',
        value: 5000,
        label: 'right',
      },
      {
        name: 'LOM Allocation',
        value: 4000,
        label: 'right',
      },
      {
        name: 'To Book',
        value: -3000,
        label: 'right',
      },
    ],
  },
};
