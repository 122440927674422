import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputBase } from '../../components/input/input-base';

@Injectable({ providedIn: 'root' })
export class DinamicFormService {
  constructor() { }

  toFormGroup(inputs: InputBase<string>[]) {
    const group: any = {};

    inputs.forEach((input) => {
      group[input.key] = input.required
        ? new FormControl({ value: input.value || '', disabled: input.disabled }, Validators.required)
        : new FormControl({ value: input.value || '', disabled: input.disabled });
    });
    return new FormGroup(group);
  }
}
