import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noSpecialChars]',
})
export class NoSpecialCharsDirective {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.ngControl.value;
    const sanitizedValue = initialValue.replace(/[^a-zA-Z0-9]/g, '');
    if (sanitizedValue !== initialValue) {
      this.ngControl.control?.patchValue(sanitizedValue);
    }
  }
}
