import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { clustedColumnMockup } from './clusted-column-mockup';

@Component({
  selector: 'app-clusted-column-chart',
  templateUrl: './clusted-column-chart.component.html',
  styleUrls: ['./clusted-column-chart.component.scss'],
})
export class ClustedColumnChartComponent implements OnInit {
  dadosWeek: any[] = [];
  weeks: any = ['June', 'July', 'August'];
  dadosGrafico: any = clustedColumnMockup;
  dadosWeekLegend: any = [];
  dadosWeekRetail: any = [];
  dadosWeekDetails: any = [];

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getWeeks();
    this.getDados(this.dadosGrafico);
  }

  getWeeks() {
    this.weeks.forEach((item: any) => {
      this.dadosWeek.push(item);
    });
  }

  getDados(dados: any) {
    Object.entries(dados).forEach((item: any) => {
      item[1].source.forEach((data: any) => {
        this.dadosWeekLegend.push(data.wk);
        this.dadosWeekRetail.push(data.retail);
        this.dadosWeekDetails.push(data.details);
      });
    });
  }

  option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      formatter: '{b0} <br/> {a0} : {c0}% <br/> {a1} : {c1}%',
      textStyle: {
        color: '#303030',
        fontSize: 12,
        fontFamily: 'Roboto',
      },
    },
    legend: {
      left: 15,
      bottom: 50,
      icon: 'circle',
      selectedMode: false,
      textStyle: {
        color: '#303030',
        fontSize: 10,
        fontFamily: 'Roboto',
      },
    },
    grid: {
      top: 35,
      left: 50,
      right: 50,
      bottom: 110,
    },
    xAxis: [
      {
        type: 'category',
        offset: 2,
        data: this.dadosWeekLegend,
        axisLabel: {
          show: true,
          color: '#303030',
          fontSize: 8,
          fontFamily: 'Roboto',
        },
        axisTick: {
          alignWithLabel: false,
          inside: true,
          length: 100,
          lineStyle: {
            color: '#e4e4e4',
            width: 1,
          },
        },
        axisLine: {
          lineStyle: {
            color: '#e4e4e4',
            width: 1,
          },
        },
      },
      {
        type: 'category',
        axisLabel: {
          show: true,
          color: '#303030',
          fontSize: 10,
          fontFamily: 'Roboto',
        },
        axisTick: {
          show: true,
          length: 25,
          lineStyle: {
            color: '#e4e4e4',
            width: 1,
          },
        },
        axisLine: {
          show: false,
        },
        data: this.dadosWeek,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: function (value: any) {
            return value + ' %';
          },
          show: true,
          color: '#303030',
          fontSize: 8,
          fontFamily: 'Roboto',
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: 'Retail',
        type: 'bar',
        stack: 'Ad',
        color: '#49cbb1',
        data: this.dadosWeekRetail,
      },
      {
        name: 'Details',
        type: 'bar',
        stack: 'Ad',
        color: '#6546cb',
        data: this.dadosWeekDetails,
      },
    ],
  };
}
