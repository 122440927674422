import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import CardModule from '../interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from '../services/authorization/auth.service';
import { SharedinfoService } from '../services/generic/sharedinfo.service';
import { NotificationService } from '../services/generic/notification.service';
import { PermissionService } from '../services/authorization/permission.service';
import { CrudService } from '../services/generic/crud.service';
import { Notifications } from '../interfaces/notifications.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  showNotifications: boolean = true;
  itemList: CardModule[] = [];
  modules: CardModule[] = [
    {
      name: 'ONE PLAN',
      title: 'one plan',
      url: '../../../assets/images/ico-one-plan.svg',
      path: 'one-plan',
    },
    {
      name: 'LOM',
      title: 'lom',
      url: '../../../assets/images/ico-lom.svg',
      path: 'lom',
    },
    {
      name: 'DCM',
      title: 'dcm',
      url: '../../../assets/images/ico-dcm.svg',
      path: 'dcm',
    },
    {
      name: 'KA ALLOCATION',
      title: 'ka allocation',
      url: '../../../assets/images/ico-customer-allocation.svg',
      path: 'ka-allocation',
    },
    {
      name: 'CUSTOMER ALLOCATION',
      title: 'customer allocation retail',
      url: '../../../assets/images/ico-customer-allocation-retail.svg',
      path: 'customer-allocation-retail',
    },
    {
      name: 'SETUP',
      title: 'setup',
      url: '../../../assets/images/ico-setup.svg',
      path: 'setup',
    },
    // {
    //   name: 'ADMIN',
    //   title: 'admin',
    //   url: '../../../assets/images/ico-admin.svg',
    //   path: 'admin',
    // },
  ];
  modulesEmea: CardModule[] = [
    {
      name: 'EMEA ALLOCATION',
      title: 'emea allocation',
      url: '../../../assets/images/ico-emea-allocation.svg',
      path: 'emea-allocation',
    },
    {
      name: 'SETUP',
      title: 'setup',
      url: '../../../assets/images/ico-setup.svg',
      path: 'setup',
    },
    {
      name: 'ADMIN',
      title: 'admin',
      url: '../../../assets/images/ico-admin.svg',
      path: 'admin',
    },
  ];

  notificationsCrudService: CrudService<any>;
  notifications: any[] = [];
  notificationsLength: number = this.notifications.length;
  notificationsVisibile: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    protected readonly http: HttpClient,
    private sharedInfoService: SharedinfoService,
    private notificationService: NotificationService,
    private permissionService: PermissionService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
    this.notificationsCrudService = new CrudService<Notifications>(
      this.http,
      'notifications'
    );
  }

  handleOpenNotification() {
    this.notificationsVisibile = !this.notificationsVisibile;
  }

  handleVisibleNotification(notification: Notifications) {
    this.spinner.show();
    const customerNumberExtract: any = notification.description;
    const regex: any = /\d+/;
    const match = customerNumberExtract.match(regex);
    const result = match ? match[0] : null;
    let link = notification.link;
    let queryParams: any = {};
    if (notification.link.includes('?')) {
      const linkSplit = link.split('?');
      link = linkSplit[0];
      const paramsArray = linkSplit[1].split('&');
      for (let i = 0; i < paramsArray.length; i++) {
        const param = paramsArray[i].split('=');
        queryParams[param[0]] = param[1];
      }
    }
    if (result != null) {
      queryParams = { customerNumber: match[0] };
    }
    this.notificationsCrudService
      .updateEntity(notification.id, '')
      .subscribe(response => {
        this.notificationService.deleteNotificationStorage(notification);
        this.router.navigate([link, { tab: notification.route }], {
          queryParams: queryParams,
        });
        this.spinner.hide();
      });
  }

  public listNotifications(data: any) {
    this.notifications = data;
    this.notificationsLength = data.length;
  }

  public getNotification() {
    const resultNotifications =
      this.notificationService.getNotificationStorage();
    this.listNotifications(resultNotifications);
    return resultNotifications;
  }

  public getPermissionModules() {
    let [user] = this.userPermission;
    if (user.geo === 'EMEA') {
      this.modules = this.modulesEmea;
      this.showNotifications = false;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.getPermissionModules();

      this.sharedInfoService
        .getPermissionStorage()
        .subscribe((permission: any) => {
          this.userPermission = permission;
          this.itemList = this.permissionService
            .enableMudulesByPermissions(this.modules)
            .sort();

          const isNotification = this.getNotification();
          if (!isNotification.length) {
            this.notificationsCrudService
              .getEntity('notifications')
              .subscribe((response: any) => {
                if (response.length > 0) {
                  this.notificationService.setNotificationStorage(response);
                  this.listNotifications(response);
                  this.getNotification();
                }
              });
          }
          this.sharedInfoService.loadLastUpdate();
          this.itemList.sort((a, b) => a.title.localeCompare(b.title));
          this.spinner.hide();
        });
    } else {
      this.spinner.hide();
      this.router.navigate(['/authorization']);
    }
  }
}
