<div [class.section-search]="showButtonApply">
  <form
    [formGroup]="form"
    class="inputs"
    [class.form-hide-show]="showMoreFiltersLink"
    [class.open-modified]="isHeightModified"
    [@openClose]="hidePartialContent ? 'open' : 'closed'">
    <div
      *ngFor="let filter of filters"
      class="form-row"
      [class.col-4]="filters!.length <= 4 && !fullWidthRow">
      <app-input
        (searchEvent)="onSearchEvent($event)"
        [inputs]="filter"
        [typeGEO]="typeGEO"
        [form]="form"></app-input>
    </div>
  </form>
  <div *ngIf="showButtonApply" class="apply">
    <button
      type="button"
      [disabled]="!enableApplyFilter"
      class="button button-apply"
      mat-button
      (click)="applyFilter()">
      APPLY
    </button>
    <div
      *ngIf="showMoreFiltersLink || false"
      [class.show-more-filters-link]="showMoreFiltersLinkWithMinWidth"
      class="align-right">
      <a class="link" (click)="toggleFilters()">{{ textshowOrHideFilters }}</a>
    </div>
  </div>
</div>
<div
  *ngIf="(!showButtonApply && showMoreFiltersLink) || false"
  class="show-more-filters-link align-right">
  <a class="link" (click)="toggleFilters()">{{ textshowOrHideFilters }}</a>
</div>
