<div class="table-container">
  <form *ngIf="allocationForm" [formGroup]="allocationForm" class="form">
    <div #tableHtml>
      <table
        mat-table
        formArrayName="allocationFormArray"
        [dataSource]="dataSource">
        <ng-container *ngFor="let col of displayedDef; let indexCol = index">
          <ng-container [matColumnDef]="col.props">
            <th
              mat-header-cell
              *matHeaderCellDef
              [class.margin-header-column-expandable]="
                col.props === col.otherContent || col.isFirstCard
              ">
              <mat-card
                [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
                [class.card-column-expandable]="col.expanded"
                [class.card-main-column-expandable]="
                  col.props === col.otherContent
                "
                [class.first-card-column-expandable]="col.isFirstCard"
                [class.last-card-column-expandable]="col.isLastCard"
                [class.card-column-disable]="!col.enableContent"
                [class.card-column-actual-week]="col.weekInTime === 'current'">
                <div>
                  <span>{{ col.title }}</span>
                </div>
                <div
                  *ngIf="col.props === col.otherContent && !col.hideIconExpand">
                  <mat-icon
                    *ngIf="!col.expanded"
                    (click)="toggleColumnTable(col, 'show')">
                    {{ 'arrow_left' }}
                  </mat-icon>
                  <mat-icon
                    *ngIf="col.expanded"
                    (click)="toggleColumnTable(col, 'hide')">
                    {{ 'arrow_right' }}
                  </mat-icon>
                </div>
              </mat-card>
              <mat-card
                *ngIf="translateYAbsoluteItem > 0"
                [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
                class="absoluteItem"
                [class.card-column-expandable]="col.expanded"
                [class.card-main-column-expandable]="
                  col.props === col.otherContent
                "
                [class.first-card-column-expandable]="col.isFirstCard"
                [class.last-card-column-expandable]="col.isLastCard"
                [class.card-column-disable]="!col.enableContent"
                [class.card-column-actual-week]="col.weekInTime === 'current'">
                <div>
                  <span>{{ col.title }}</span>
                </div>
                <div
                  *ngIf="col.props === col.otherContent && !col.hideIconExpand">
                  <mat-icon
                    *ngIf="!col.expanded"
                    (click)="toggleColumnTable(col, 'show')">
                    {{ 'arrow_left' }}
                  </mat-icon>
                  <mat-icon
                    *ngIf="col.expanded"
                    (click)="toggleColumnTable(col, 'hide')">
                    {{ 'arrow_right' }}
                  </mat-icon>
                </div>
              </mat-card>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let indexRow = index"
              id="{{ getAddRowId(indexRow) }}"
              (mouseenter)="mouseEnter(indexRow, element)"
              (mouseleave)="mouseLeave(indexRow, element)"
              [class.bg-blue]="
                isCurrentWeek(col.title) && element[col.props].style
              "
              [class.bg-origin]="isOrigin(element[col.props].value)"
              [class.hidden-retail]="
                filterKaRetail === false && element[col.props].ka === 'RETAIL_BRA'
              "
              [formGroupName]="indexRow"
              [class.margin-data-column-expandable]="
                col.props === col.otherContent || col.isFirstCard
              "
              [class.data-column-input]="element[col.props]?.enableInput"
              [class.data-first-column-input]="
                col.isFirstCard && element[col.props]?.enableInput
              "
              [class.enable-input]="
                !element[col.props].enableInput &&
                isCurrentWeek(col.title) &&
                !element[col.props].enableMLS &&
                !isSab(col)
              "
              [ngStyle]="element[col.props].style"
              [ngClass]="element[col.props].ka"
              class="column">
              <span *ngIf="!element[col.props]?.enableInput">
                <app-value-currency
                  [class.is-not-empty]="isNotEmpty(element[col.props].value)"
                  [class.negative-value]="element[col.props].value < 0"
                  [value]="
                    element[col.props].value === '-'
                      ? ''
                      : element[col.props].value
                  "></app-value-currency>
              </span>
              <span *ngIf="element[col.props]?.enableInput && element[col.props].ka === 'RETAIL_BRA'">
                <app-value-currency
                  [class.is-not-empty]="isNotEmpty(element[col.props].value)"
                  [class.negative-value]="element[col.props].value < 0"
                  [class.input-number-disabled]="element[col.props].isDisabled"
                  [value]="
                    element[col.props].value === '-'
                      ? ''
                      : element[col.props].value
                  "></app-value-currency>
              </span>
              <div
                *ngIf="element[col.props]?.enableInput && element[col.props].ka !== 'RETAIL_BRA'"
                class="input-allocation">
                <input
                  autocomplete="off"
                  class="custom-input"
                  (keypress)="validateInput($event)"
                  [id]="col.props + '_' + indexRow + '_' + element[col.props].ka"
                  type="text"
                  [ngClass]="{ 'border-calculate': borderCalc(element[col.props]) }"
                  [class.input-number]="!element[col.props].isDisabled"
                  [class.input-number-disabled]="element[col.props].isDisabled"
                  [formControlName]="
                    this.allFormControlNames[indexRow][col.props]
                  "
                  (blur)="handleInputBlur($event, element, col, {indexRow, columnName: col.props })"
                  (click)="handleInputClick($event)"
                  [class.negative-value]="getCurrentValueFormControl({ indexRow, columnName: col.props }) < 0"
                  [readonly]="element[col.props].isDisabled"
                  [disabled]="element[col.props].isDisabled"
                  (keydown)="handleKeyDownEvent($event, col, indexRow, element[col.props], 0, element[col.props].ka, col.props)"
                  [ngModel]="formatValueOrExpression(element[col.props])"
                  (input)="onInput($event, {indexRow, columnName: col.props })"
                  maxlength="21"
                />
              </div>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </form>
</div>
