<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">Flex One Plan Variation</span>
  </div>
  <mat-divider></mat-divider>
  <div class="one-plan-variation-content">
    <div class="title-week" *ngIf="!noResultOnePlanVariation">
      <span class="current-week">{{ currentWeek }}</span>
    </div>

    <app-results
      #tableOnePlanVariation
      [data]="result"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
      [hidePagination]="hidePagination"
      *ngIf="!noResultOnePlanVariation"></app-results>

    <div class="no-filter-selected-session" *ngIf="noResultOnePlanVariation">
      <p>NO RECORDS FOUND.</p>
    </div>

    <div class="buttons-right">
      <button
        (click)="backButton()"
        class="button back-action-button"
        mat-button>
        BACK
      </button>
    </div>
  </div>
</div>
