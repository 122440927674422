export const executiveOverviewMockup: any = {
  simpleTableExpansive: {
    headers: [
      {
        title: 'FAMILY',
        props: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
        style: { width: '9%' },
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
        style: { width: '9%' },
      },
      {
        title: 'JAN',
        props: 'janCommit',
        commitOrWowName: 'COMMIT', // deve ter apenas no primeiro mês do objeto
        otherContent: 'janCommit',
        firtsTitle: true,
        firstIndex: true,
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'FEB',
        props: 'febCommit',
        otherContent: 'febCommit',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'MAR',
        props: 'marCommit',
        otherContent: 'marCommit',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'TOTAL',
        props: 'totalCommit',
        totalCommit: true,
        secondColumn: true,
        alwaysVisible: true,
        hideIconExpand: true,
      },
      {
        title: 'JAN',
        commitOrWowName: 'WOW', // deve ter apenas no primeiro mês do objeto
        props: 'janWow',
        otherContent: 'janWow',
        firtsTitle: true,
        expansive: true,
        hideIconExpand: true,
        alwaysVisible: true,
        firstIndex: true,
      },
      {
        title: 'FEB',
        props: 'febWow',
        otherContent: 'febWow',
        expansive: true,
        hideIconExpand: true,
        alwaysVisible: true,
      },
      {
        title: 'MAR',
        props: 'marWow',
        otherContent: 'marWow',
        expansive: true,
        hideIconExpand: true,
        alwaysVisible: true,
      },
      {
        title: 'TOTAL',
        props: 'totalWow',
        totalWow: true,
        alwaysVisible: true,
        hideIconExpand: true,
      },

      {
        title: 'BOH TOTAL',
        props: 'bohTotal',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },

      // WK COMMIT
      {
        title: 'WK01',
        props: 'wk01CommitJan',
        otherContent: 'janCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK02',
        props: 'wk02CommitJan',
        otherContent: 'janCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK03',
        props: 'wk03CommitJan',
        otherContent: 'janCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK04',
        props: 'wk04CommitJan',
        otherContent: 'janCommit',
        secondColumn: true,
        alwaysVisible: false,
      },
      {
        title: 'WK05',
        props: 'wk05CommitJan',
        otherContent: 'janCommit',
        alwaysVisible: false,
        secondColumn: true,
      },

      {
        title: 'WK06',
        props: 'wk01CommitFeb',
        otherContent: 'febCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK07',
        props: 'wk02CommitFeb',
        otherContent: 'febCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK08',
        props: 'wk03CommitFeb',
        otherContent: 'febCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK09',
        props: 'wk04CommitFeb',
        otherContent: 'febCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK010',
        props: 'wk05CommitFeb',
        otherContent: 'febCommit',
        alwaysVisible: false,
        secondColumn: true,
      },

      {
        title: 'WK011',
        props: 'wk01CommitMar',
        otherContent: 'marCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK012',
        props: 'wk02CommitMar',
        otherContent: 'marCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK013',
        props: 'wk03CommitMar',
        otherContent: 'marCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK014',
        props: 'wk04CommitMar',
        otherContent: 'marCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK015',
        props: 'wk05CommitMar',
        otherContent: 'marCommit',
        alwaysVisible: false,
        secondColumn: true,
      },
    ],
    rows: [
      {
        family: {
          value: 'Jimmy+ 4 4/64',
        },
        origin: {
          value: 'MAO',
        },
        commit: {
          value: '',
        },
        totalCommit: {
          value: 150,
          summableValue: true,
        },
        totalWow: {
          value: 150,
          summableValue: true,
        },
        wow: {
          value: '',
        },
        bohTotal: {
          value: 600,
          summableValue: true,
        },
        janCommit: {
          value: 50,
          summableValue: true,
        },
        febCommit: {
          value: 0,
          summableValue: true,
        },
        marCommit: {
          value: 100,
          summableValue: true,
        },
        janWow: {
          value: 50,
          summableValue: true,
        },
        febWow: {
          value: 0,
          summableValue: true,
        },
        marWow: {
          value: 100,
          summableValue: true,
        },

        // VALUES WKS COMMIT
        wk01CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk02CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk03CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk04CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk05CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk01CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk02CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk03CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk04CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk05CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk01CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk02CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk03CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk04CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk05CommitMar: {
          value: 500,
          summableValue: true,
        },
        // VALUES WKS COMMIT
        wk01WowJan: {
          value: 500,
          summableValue: true,
        },
        wk02WowJan: {
          value: 500,
          summableValue: true,
        },
        wk03WowJan: {
          value: 500,
          summableValue: true,
        },
        wk04WowJan: {
          value: 500,
          summableValue: true,
        },
        wk05WowJan: {
          value: 500,
          summableValue: true,
        },
        wk01WowFeb: {
          value: 500,
          summableValue: true,
        },
        wk02WowFeb: {
          value: 500,
          summableValue: true,
        },
        wk03WowFeb: {
          value: 500,
          summableValue: true,
        },
        wk04WowFeb: {
          value: 500,
          summableValue: true,
        },
        wk05WowFeb: {
          value: 500,
          summableValue: true,
        },
        wk01WowMar: {
          value: 500,
          summableValue: true,
        },
        wk02WowMar: {
          value: 500,
          summableValue: true,
        },
        wk03WowMar: {
          value: 500,
          summableValue: true,
        },
        wk04WowMar: {
          value: 500,
          summableValue: true,
        },
        wk05WowMar: {
          value: 500,
          summableValue: true,
        },
      },
      {
        family: {
          value: 'Bali 2/32',
        },
        origin: {
          value: 'MAO',
        },
        commit: {
          value: '',
        },
        totalCommit: {
          value: 150,
          summableValue: true,
        },
        totalWow: {
          value: 150,
          summableValue: true,
        },
        wow: {
          value: '',
        },
        bohTotal: {
          value: 600,
          summableValue: true,
        },
        janCommit: {
          value: 50,
          summableValue: true,
        },
        febCommit: {
          value: 0,
          summableValue: true,
        },
        marCommit: {
          value: 100,
          summableValue: true,
        },
        janWow: {
          value: 50,
          summableValue: true,
        },
        febWow: {
          value: 0,
          summableValue: true,
        },
        marWow: {
          value: 100,
          summableValue: true,
        },

        // VALUES WKS COMMIT
        wk01CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk02CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk03CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk04CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk05CommitJan: {
          value: 500,
          summableValue: true,
        },
        wk01CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk02CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk03CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk04CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk05CommitFeb: {
          value: 500,
          summableValue: true,
        },
        wk01CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk02CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk03CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk04CommitMar: {
          value: 500,
          summableValue: true,
        },
        wk05CommitMar: {
          value: 500,
          summableValue: true,
        },
      },
    ],
  },
};

export const executiveOverviewMockupWeekly: any = {
  simpleTableExpansiveWeekly: {
    headers: [
      {
        title: 'FAMILY',
        props: 'family',
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
        style: { width: '8%' },
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: 'WK01',
        props: 'wk01Jan',
        month: 'JAN', // deve ter apenas no primeiro mês do objeto
        firstWeek: true, // deve ter apenas na primeira week do primeiro mes do objeto
        alwaysVisible: true,
        secondColumn: false,
      },
      {
        title: 'WK02',
        props: 'wk02Jan',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK03',
        props: 'wk03Jan',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK04',
        props: 'wk04Jan',
        secondColumn: true,
        alwaysVisible: true,
      },
      {
        title: 'WK05',
        props: 'wk05Jan',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK06',
        props: 'wk01Feb',
        month: 'FEB', // deve ter apenas no primeiro mês do objeto
        alwaysVisible: true,
        secondColumn: false,
      },
      {
        title: 'WK07',
        props: 'wk02Feb',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK08',
        props: 'wk03Feb',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK09',
        props: 'wk04Feb',
        secondColumn: true,
        alwaysVisible: true,
      },
      {
        title: 'WK10',
        props: 'wk05Feb',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK011',
        props: 'wk01Mar',
        month: 'MAR', // deve ter apenas no primeiro mês do objeto
        indexWeek: true,
        alwaysVisible: true,
        secondColumn: false,
      },
      {
        title: 'WK012',
        props: 'wk02Mar',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK013',
        props: 'wk03Mar',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK014',
        props: 'wk04Mar',
        secondColumn: true,
        alwaysVisible: true,
      },
      {
        title: 'WK015',
        props: 'wk05Mar',
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'TOTAL SUPPLY',
        props: 'totalSupply',
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
    ],
    rows: [
      {
        family: {
          value: 'Jimmy+ 4 4/64',
        },
        origin: {
          value: 'MAO',
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        wk01Feb: {
          value: 500,
          summableValue: true,
        },
        wk02Feb: {
          value: 500,
          summableValue: true,
        },
        wk03Feb: {
          value: 500,
          summableValue: true,
        },
        wk04Feb: {
          value: 500,
          summableValue: true,
        },
        wk05Feb: {
          value: 500,
          summableValue: true,
        },
        wk01Mar: {
          value: 500,
          summableValue: true,
        },
        wk02Mar: {
          value: 500,
          summableValue: true,
        },
        wk03Mar: {
          value: 500,
          summableValue: true,
        },
        wk04Mar: {
          value: 500,
          summableValue: true,
        },
        wk05Mar: {
          value: 500,
          summableValue: true,
        },
        totalSupply: {
          value: 500,
          summableValue: true,
        },
      },
      {
        family: {
          value: 'Bali 2/32',
        },
        origin: {
          value: 'MAO',
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        wk01Feb: {
          value: 500,
          summableValue: true,
        },
        wk02Feb: {
          value: 500,
          summableValue: true,
        },
        wk03Feb: {
          value: 500,
          summableValue: true,
        },
        wk04Feb: {
          value: 500,
          summableValue: true,
        },
        wk05Feb: {
          value: 500,
          summableValue: true,
        },
        wk01Mar: {
          value: 500,
          summableValue: true,
        },
        wk02Mar: {
          value: 500,
          summableValue: true,
        },
        wk03Mar: {
          value: 500,
          summableValue: true,
        },
        wk04Mar: {
          value: 500,
          summableValue: true,
        },
        wk05Mar: {
          value: 500,
          summableValue: true,
        },
        totalSupply: {
          value: 500,
          summableValue: true,
        },
      },
    ],
  },
};
