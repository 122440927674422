import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import {
  InputBase,
  SelectSearchInput,
  DatePickerSearchInput,
} from 'src/app/components/input/input-base';
import {
  FiscalCalendar,
  ResultExport,
} from 'src/app/interfaces/fiscalCalendar.interface';
import { ResultInterface } from 'src/app/components/results/results.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';

@Component({
  selector: 'app-fiscal-calendar',
  templateUrl: './fiscal-calendar.component.html',
  styleUrls: ['./fiscal-calendar.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class FiscalCalendarComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  searchInputs: InputBase<string>[];
  displayedColumns: any;
  result!: ResultInterface;
  fiscalCalendarCrudService: CrudService<FiscalCalendar>;
  pagination: Pagination;
  filter: any = {
    quarter: this.getQuarterFromDate(new Date()),
    pageName: PAGE_NAME_RESOURCE.fiscalCalendar,
    sort: 'day',
  };
  buttonSaveDisabled = false;
  isModeTableOrCardsHide: boolean = true;
  fiscalYearEntities: any;
  fullWidthRow: boolean = true;
  hidePagination: boolean = true;

  tooltipMessage: any = 'Export';
  isValid: boolean = true;
  allocateJsonExport: any = [];

  quarterValue!: string;

  formSearchValues!: { [key: string]: any };

  showNoResults: boolean = false;

  constructor(
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelSheetsService: ExcelSheetsService
  ) {
    this.pagination = { page: 0, size: 366 };
    this.fiscalCalendarCrudService = new CrudService<FiscalCalendar>(
      this.http,
      'calendar'
    );
    this.filter = {
      ...this.filter,
      year: this.checkCurrentYearAndQuarter(),
    };
    this.searchInputs = [
      new SelectSearchInput({
        key: 'year',
        hover: 'Fiscal Year',
        classCss: '6',
        type: 'text',
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Quarter',
        classCss: '6',
        type: 'text',
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'week',
        hover: 'Week',
        classCss: '6',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new DatePickerSearchInput({
        key: 'date',
        hover: 'Date',
        classCss: 'date-fiscal-calendar',
        type: 'text',
        hasCheckFlowException: true,
      }),
    ];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getDataFilter();
    this.getFiscalCalendar(this.pagination || undefined);
  }

  getDataFilter() {
    const fiscalYearFilter: any[] = [];
    let quarterFilter: any[] = [];
    const weekFilter: any[] = [];
    const currentYear = this.checkCurrentYearAndQuarter();

    for (
      let year = currentYear - 5, i = 0;
      year <= currentYear + 5;
      year++, i++
    ) {
      fiscalYearFilter.push({
        key: i,
        value: `${year}/${year + 1}`,
      });
    }

    quarterFilter = [
      { key: '1', value: 'FQ1' },
      { key: '2', value: 'FQ2' },
      { key: '3', value: 'FQ3' },
      { key: '4', value: 'FQ4' },
    ];

    this.fiscalCalendarCrudService
      .getPaged({ size: 366, page: 0 }, this.filter)
      .subscribe((response: any) => {
        response.rows.forEach((fiscalYear: any) => {
          if (
            weekFilter.findIndex(
              item => item.value === fiscalYear.week.replace('W', 'WK')
            ) === -1
          ) {
            weekFilter.push({
              key: fiscalYear.week.replace('W', 'WK'),
              value: fiscalYear.week.replace('W', 'WK'),
            });
          }
          weekFilter.sort((current: any, next: any) =>
            current.value.localeCompare(next.value)
          );
        });
      });

    this.searchInputs[0].options = fiscalYearFilter;
    this.searchInputs[0].value = `${currentYear}/${currentYear + 1}`;
    this.searchInputs[0].notClearable = true;
    this.searchInputs[1].options = quarterFilter;
    this.searchInputs[1].value = this.getQuarterFromDate(new Date());
    this.searchInputs[2].options = weekFilter;
  }

  getFiscalCalendar(page?: any, params?: any) {
    this.spinner.show();
    this.fiscalCalendarCrudService
      .getPaged(this.pagination, params || this.filter)
      .subscribe((response: any) => {
        if (response.rows.length > 0) {
          let allQuarterSelect: string[] = [];

          response.rows.forEach((item: any) => {
            allQuarterSelect.push(item.quarter);
          });

          let quarterSelected: any[] = [...new Set(allQuarterSelect)];
          this.quarterValue = quarterSelected.join(', ');

          this.showNoResults = false;
          this.updateComponents(response);
          this.spinner.hide();
        } else {
          this.showNoResults = true;
          this.spinner.hide();
        }
      });
  }

  updateComponents(responseFiscalCalendar: any) {
    this.result = {
      table: {
        columns: [
          {
            label: 'fiscal year',
            prop: 'fiscalYear',
            sticky: false,
            style: { width: '13%', 'word-wrap': 'break-word' },
            group: true,
          },
          {
            label: 'quarter',
            prop: 'quarter',
            sticky: false,
            style: { width: '10%', 'word-wrap': 'break-word' },
            group: true,
          },
          {
            label: 'month',
            prop: 'month',
            sticky: false,
            style: { width: '10%' },
            group: true,
          },
          {
            label: 'week',
            prop: 'week',
            sticky: false,
            style: { width: '10%' },
          },
          {
            label: 'days',
            prop: 'day',
            sticky: false,
            style: { width: '52%' },
          },
        ],
        rows: new MatTableDataSource<any>(
          this.adjustTableFiscalCalendar(responseFiscalCalendar.rows)
        ),
      },
      pageIndex: responseFiscalCalendar.page,
      pageSize: responseFiscalCalendar.totalRows === 0 ? 0 : '',
      pagesTotal: responseFiscalCalendar.totalPages,
    };
  }

  onSearchEvent(value: any) {
    this.pagination.page = 0;
    this.formSearchValues = { ...value };

    this.dataFormSearchQuarter(this.formSearchValues);

    if (value.nameFormControl === 'quarter') {
      value.year = value.year
        ? value.year.split('/')[0]
        : new Date().getFullYear();
      value.sort = value.sort ? value.sort : this.filter.sort;
      value.quarter = value.quarter ? value.quarter : '';
      value.week = '';
      value.date = '';

      if (value.date) {
        const year = value.date.getFullYear();
        const month =
          value.date.getMonth() + 1 > 9
            ? value.date.getMonth() + 1
            : `0${value.date.getMonth() + 1}`;
        const day =
          value.date.getDate() < 10
            ? `0${value.date.getDate()}`
            : value.date.getDate();
        value.day = `${year}-${month}-${day}`;
        delete value.date;
      } else {
        value.day = '';
        delete value.date;
      }
      this.filter = {
        ...value,
        sort: 'day',
        pageName: PAGE_NAME_RESOURCE.fiscalCalendar,
      };

      delete this.filter.action;
      delete this.filter.event;
      delete this.filter.formControl;
      delete this.filter.nameFormControl;
      delete this.filter.panelTrigger;

      this.getFiscalCalendar(this.filter);
    } else {
      value.year = value.year
        ? value.year.split('/')[0]
        : new Date().getFullYear();
      value.sort = value.sort ? value.sort : this.filter.sort;
      value.quarter = value.quarter ? value.quarter : '';
      value.week = value.week ? `W${value.week.substring(2)}` : '';

      if (value.date) {
        const year = value.date.getFullYear();
        const month =
          value.date.getMonth() + 1 > 9
            ? value.date.getMonth() + 1
            : `0${value.date.getMonth() + 1}`;
        const day =
          value.date.getDate() < 10
            ? `0${value.date.getDate()}`
            : value.date.getDate();
        value.day = `${year}-${month}-${day}`;
        delete value.date;
      } else {
        value.day = '';
        delete value.date;
      }
      this.filter = {
        ...value,
        sort: 'day',
        pageName: PAGE_NAME_RESOURCE.fiscalCalendar,
      };

      delete this.filter.action;
      delete this.filter.event;
      delete this.filter.formControl;
      delete this.filter.nameFormControl;
      delete this.filter.panelTrigger;

      this.getFiscalCalendar(this.filter);
    }
  }

  dataFormSearchQuarter(formSearch: any) {
    if (formSearch.nameFormControl === 'quarter') {
      const weekFilter: any[] = [];
      formSearch.date =
        formSearch.date !== undefined || '' ? formSearch.date : '';
      formSearch.week =
        formSearch.week !== undefined || '' ? formSearch.week : '';
      formSearch.year = formSearch.year
        ? formSearch.year.split('/')[0]
        : new Date().getFullYear();

      formSearch.pageName = 'fiscal-calendar';
      formSearch.sort = 'day';

      if (formSearch.quarter) {
        delete formSearch.action;
        delete formSearch.event;
        delete formSearch.formControl;
        delete formSearch.nameFormControl;
        delete formSearch.panelTrigger;

        formSearch.week = '';
        formSearch.date = '';

        this.fiscalCalendarCrudService
          .getPaged({ size: 366, page: 0 }, formSearch)
          .subscribe((response: any) => {
            response.rows.forEach((fiscalYear: any) => {
              if (
                weekFilter.findIndex(
                  item => item.value === fiscalYear.week.replace('W', 'WK')
                ) === -1
              ) {
                weekFilter.push({
                  key: fiscalYear.week.replace('W', 'WK'),
                  value: fiscalYear.week.replace('W', 'WK'),
                });
              }
              weekFilter.sort((current: any, next: any) =>
                current.value.localeCompare(next.value)
              );
            });
          });

        const selectWeek = new SelectSearchInput({
          key: 'week',
          hover: 'Week',
          classCss: '6',
          type: 'text',
          options: weekFilter,
          value: '',
          hasAutocomplete: true,
          hasCheckFlowException: true,
        });

        const selectDate = new DatePickerSearchInput({
          key: 'date',
          hover: 'Date',
          classCss: 'date-fiscal-calendar',
          type: 'text',
          value: '',
          hasCheckFlowException: true,
        });

        this.searchInputs[2] = selectWeek;
        this.searchInputs[3] = selectDate;
        this.searchInputs = [...this.searchInputs];
      } else if (formSearch.quarter == '') {
        delete formSearch.action;
        delete formSearch.event;
        delete formSearch.formControl;
        delete formSearch.nameFormControl;
        delete formSearch.panelTrigger;

        formSearch.week = '';
        formSearch.quarter = '';
        formSearch.date = '';

        this.fiscalCalendarCrudService
          .getPaged({ size: 366, page: 0 }, formSearch)
          .subscribe((response: any) => {
            response.rows.forEach((fiscalYear: any) => {
              if (
                weekFilter.findIndex(
                  item => item.value === fiscalYear.week.replace('W', 'WK')
                ) === -1
              ) {
                weekFilter.push({
                  key: fiscalYear.week.replace('W', 'WK'),
                  value: fiscalYear.week.replace('W', 'WK'),
                });
              }
              weekFilter.sort((current: any, next: any) =>
                current.value.localeCompare(next.value)
              );
            });
          });

        const selectWeek = new SelectSearchInput({
          key: 'week',
          hover: 'Week',
          classCss: '6',
          type: 'text',
          options: weekFilter,
          value: '',
          hasAutocomplete: true,
          hasCheckFlowException: true,
        });

        const selectDate = new DatePickerSearchInput({
          key: 'date',
          hover: 'Date',
          classCss: 'date-fiscal-calendar',
          type: 'text',
          value: '',
          hasCheckFlowException: true,
        });

        this.searchInputs[2] = selectWeek;
        this.searchInputs[3] = selectDate;
        this.searchInputs = [...this.searchInputs];
      }
    }
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

  handleDataToTableGrouping(data: any): any[] {
    const columnsLabel = ['fiscalYear', 'quarter', 'month', 'week', 'day'];
    columnsLabel.forEach(columnLabel => {
      let lastDifferentValue: any = '';
      let colorBrand = 1;
      data.map((row: any) => {
        if (row[columnLabel] !== lastDifferentValue) {
          lastDifferentValue = row[columnLabel];
          colorBrand = colorBrand === 0 ? 1 : 0;
          row[columnLabel] = {
            value: row[columnLabel],
            showValue: true,
            colorBrand,
          };
        } else {
          row[columnLabel] = {
            value: row[columnLabel],
            showValue: false,
            colorBrand,
          };
        }
        return row;
      });
    });
    return data;
  }

  adjustTableFiscalCalendar(data: any): any[] {
    let index = 0;
    let indexDifferentRow = 0;
    let lastDifferentWeek: any = '';

    data.map((row: any) => {
      if (row.splitWeek !== lastDifferentWeek) {
        lastDifferentWeek = row.splitWeek;
        indexDifferentRow = index;
        row.day = row.day.substring(5).replace('-', '/');
        row.week = `WK${row.splitWeek.substring(1)}`;
        row.fiscalYear = `${row.fiscalYear}/${row.fiscalYear + 1}`;
      } else {
        data[indexDifferentRow].day += `, ${row.day
          .substring(5)
          .replace('-', '/')}`;
        delete data[index];
      }
      row.quarter = `${row.quarter}`;
      index++;

      return row;
    });
    data.sort((current: any, next: any) =>
      current.quarter.localeCompare(next.quarter)
    );
    return this.handleDataToTableGrouping(
      data.filter((row: any) => row !== {})
    );
  }

  checkCurrentYearAndQuarter() {
    if (this.filter.quarter === 'FQ4') return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'FQ1';
    }
    if (month >= 7 && month <= 9) {
      return 'FQ2';
    }
    if (month >= 10 && month <= 12) {
      return 'FQ3';
    }
    return 'FQ4';
  }

  prepareToExport(result: ResultExport) {
    let sheet: string = '';
    let columns: any[] = [];
    let rows = result.table.rows.filteredData;

    let allQuarterSelect: string[] = [];

    this.result.table?.rows.filteredData.forEach((item: any) => {
      allQuarterSelect.push(item.quarter.value);
    });

    let quarterSelected: string[] = [...new Set(allQuarterSelect)];

    if (quarterSelected.length === 1) {
      sheet = `fiscal_calendar_${quarterSelected[0]}`;
    } else {
      sheet = 'fiscal_calendar_ALL';
    }

    const tableRows: { [key: string]: string }[] = [];
    columns = result.table.columns.map((item: any) => ({
      title: item.label.toUpperCase(),
      props: item.prop,
    }));

    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: string } = {};
      columns.forEach((column: any) => {
        tableRow[column.props] = rows[i][column.props].value
          ? rows[i][column.props].value
          : '';
      });
      tableRows.push(tableRow);
    }

    return { columns, rows: tableRows, sheet: sheet, headers: true };
  }

  exportTable() {
    this.spinner.show();
    this.allocateJsonExport = [];

    this.allocateJsonExport.push(this.prepareToExport(this.result));

    this.excelSheetsService.exportJsonAsExcelFile(
      this.allocateJsonExport,
      'fiscal-calendar'
    );

    this.spinner.hide();
  }
}
