export const tableMockup: any = {
  table: {
    columns: [
      {
        label: 'PRODUCT',
        prop: 'product',
        sticky: true,
        style: { width: '100px' },
      },
      {
        label: 'BOH',
        prop: 'boh',
        sticky: true,
        style: { width: '100px' },
      },
      {
        label: 'SAB',
        prop: 'sab',
        sticky: true,
        style: { width: '100px' },
      },
      {
        label: '3 oct - 9 oct',
        prop: '3Oct9Oct',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '10 oct - 16 oct',
        prop: '10Oct16Oct',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '17 oct - 23 oct',
        prop: '17Oct23Oct',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '24 oct - 30 oct',
        prop: '24Oct30Oct',
        sticky: true,
        currentWeek: true,
        style: { width: '150px' },
      },
      {
        label: '31 oct - 6 nov',
        prop: '31Oct6Nov',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '7 nov - 13 nov',
        prop: '7Nov13Nov',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '14 nov - 20 nov',
        prop: '14Nov20Nov',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '21 nov - 27 nov',
        prop: '21Nov27Nov',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '28 nov - 4 dec',
        prop: '28Nov4Dec',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: '5 dec - 11 dec',
        prop: '5Dec11Dec',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: 'Supply Guidance',
        prop: 'supplyGuidance',
        sticky: true,
        style: { width: '150px' },
      },
      {
        label: 'FQ3 TOTAL',
        prop: 'fqTotal',
        sticky: true,
        style: { width: '80px' },
      },
    ],
    rows: [
      {
        product: {
          value: 'Aruba',
        },
        boh: {
          value: 500,
          summableValue: true,
        },
        sab: {
          value: 1500,
          summableValue: true,
        },
        '3Oct9Oct': {
          value: 1000,
          summableValue: true,
        },
        '10Oct16Oct': {
          value: 1500,
          summableValue: true,
        },
        '17Oct23Oct': {
          value: 1500,
          summableValue: true,
        },
        '24Oct30Oct': {
          value: 1500,
          summableValue: true,
        },
        '31Oct6Nov': {
          value: 1500,
          summableValue: true,
        },
        '7Nov13Nov': {
          value: 1500,
          summableValue: true,
        },
        '14Nov20Nov': {
          value: 1500,
          summableValue: true,
        },
        '21Nov27Nov': {
          value: 1500,
          summableValue: true,
        },
        '28Nov4Dec': {
          value: 1500,
          summableValue: true,
        },
        '5Dec11Dec': {
          value: 1500,
          summableValue: true,
        },
        supplyGuidance: {
          value: 1000,
          summableValue: true,
        },
        fqTotal: {
          value: 10000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'e-com',
        },
        boh: {
          value: 500,
          summableValue: true,
        },
        sab: {
          value: 1500,
          summableValue: true,
        },
        '3Oct9Oct': {
          value: 1000,
          summableValue: true,
        },
        '10Oct16Oct': {
          value: 1500,
          summableValue: true,
        },
        '17Oct23Oct': {
          value: 1500,
          summableValue: true,
        },
        '24Oct30Oct': {
          value: 1500,
          summableValue: true,
        },
        '31Oct6Nov': {
          value: 1500,
          summableValue: true,
        },
        '7Nov13Nov': {
          value: 1500,
          summableValue: true,
        },
        '14Nov20Nov': {
          value: 1500,
          summableValue: true,
        },
        '21Nov27Nov': {
          value: 1500,
          summableValue: true,
        },
        '28Nov4Dec': {
          value: 1500,
          summableValue: true,
        },
        '5Dec11Dec': {
          value: 1500,
          summableValue: true,
        },
        supplyGuidance: {
          value: 1000,
          summableValue: true,
        },
        fqTotal: {
          value: 10000,
          summableValue: true,
        },
      },
    ],
  },
};
