<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">CUSTOMER MANAGEMENT</span>
  </div>
  <app-search (searchEvent)="onSearchEvent($event)" [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results [data]="result" (eventActions)="onEventActions($event)" (resultPaginator)="onChangePaginator($event)"></app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">BACK</button>
</div>
<ng-template #modalCreateEdit>
  <form [formGroup]="customerForm" class="form">
    <header class="title-modal" matDialogTitle>EDIT CUSTOMER</header>
    <mat-dialog-content>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>GEO</mat-label>
        <mat-select formControlName="geo" name="geo" [disabled]="isEdit">
          <mat-option *ngFor="let geos of geoEntities" [value]="geos.geo">
            {{ geos.geo }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country" name="country" [disabled]="isEdit">
          <mat-option *ngFor="let country of countryEntities" [value]="country.id">
            {{ country.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Customer Number</mat-label>
        <mat-select formControlName="customerNumber" name="customerNumber" [disabled]="isEdit" >
          <mat-option [value] = "customerForm.value['customerNumber']">
            {{ customerForm.value['customerNumber'] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Customer Name</mat-label>
        <mat-select  formControlName="customerName" name="customerName" [disabled]="isEdit">
          <mat-option *ngFor="let customerName of customerNameEntities" [value]="customerName.customerName">
            {{ customerName.customerName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" name="status" [disabled]="isEdit">
          <mat-option [value] = "customerForm.value['status']">
            {{ customerForm.value['status'] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <div class="titulo-sales">
      <header class="title-modal" matDialogTitle>SALES STRUCTURE</header>
      <button type="button" (click)="addItem()" class="button-create"></button>
    </div>
    <div formArrayName="salesStructures" style="height: 250px; overflow-y:auto; overflow-x:hidden; margin-top: -30px; margin-bottom: 20px;">
      <div *ngFor="let item of salesStructures.controls; let i = index;" [formGroupName]="i" style="margin-bottom: -50px;">
        <mat-dialog-content>
          <mat-form-field appearance="outline">
            <mat-label>Order Admin</mat-label>
            <mat-select formControlName="orderAdminId" name="orderAdmin" disableOptionCentering panelClass="modal-panel-select-unique-customer">
              <mat-option *ngFor="let orderAdmin of orderAdminEntities" [value]="orderAdmin.id" matTooltip="{{ orderAdmin.last_name }}, {{ orderAdmin.name }} ({{ orderAdmin.email }})">
                 {{orderAdmin.last_name}}, {{orderAdmin.name}}, ({{orderAdmin.email}})
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Sales Manager</mat-label>
            <mat-select formControlName="salesManagerId" name="salesManager" disableOptionCentering panelClass="modal-panel-select-unique-customer" >
              <div *ngFor="let salesManager of salesManagerEntities">
                <div *ngIf="salesManager.id === item.value.id">
                  <mat-option *ngFor="let sales of salesManager.values" [value]="sales.id" matTooltip="{{ sales.lastName }}, {{ sales.name }} ({{ sales.email }})">
                    {{ sales.lastName }}, {{ sales.name }} ({{ sales.email }})
                  </mat-option>
                </div>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Customer Short</mat-label>
            <input matInput formControlName="customerShort" autocomplete="off" maxlength="20"/>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Customer Po Line Number</mat-label>
            <input matInput formControlName="customerPoLineNumber" autocomplete="off" maxlength="20"/>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Channel</mat-label>
            <mat-select formControlName="channelId" name="channel" disableOptionCentering panelClass="modal-panel-select-unique-customer">
              <mat-option *ngFor="let Channel of channelEntities" [value]="Channel.id">
                {{ Channel.channel }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Taxes Application</mat-label>
            <mat-select formControlName="taxes" name="taxes" disableOptionCentering  multiple
            panelClass="panel-select-multiple">
              <mat-option *ngFor="let tax of taxesApplicableEntities" [value]="tax.id">
                {{ tax.tax }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="action-edit-delete">
            <button type="button" (click)="removeItem(i)" *ngIf="salesStructures.length > 0" class="button-delete" ></button>
          </div>
        </mat-dialog-content>
        <div class="margin-footer"></div>
      </div>
      <div class="margin-footer"></div>
    </div>
    <div class="margin-footer"></div>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>CANCEL</button>
      <button class="button modal-action-button" mat-button (click)="onUpdate()">SAVE</button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    Do you confirm to edit this Costumer?
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>NO</button>
    <button class="button modal-action-button" mat-button matDialogClose="false">YES</button>
  </mat-dialog-actions>
</ng-template>
