<div class="container-mkn-to-book">
  <table id="table">
    <thead>
      <tr>
        <th *ngFor="let header of displayedColumns" class="column">
          <ng-container>
            <div
              class="date"
              [ngClass]="{
                'left-columns': expecificProp(header.props)
              }">
              {{ header.title }}
            </div>
            <div
              [ngClass]="{
                week: header.title !== '',
                'week-sem-data': header.title === ''
              }">
              {{ header.week }}
            </div>
          </ng-container>
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let rowData of dataRow">
        <ng-container *ngFor="let cellData of rowData.data; let i = index">
          <tr>
            <td
              *ngFor="let header of displayedColumns"
              [ngClass]="{
                'centrelize-items': isPropNumber(cellData[header.props]),
                'centrelize-items-right': isPropString(cellData[header.props])
              }">
              <span>
                <app-value-currency
                  [class.negative-value]="cellData[header.props] < 0"
                  [value]="
                    checkAndSetEmptyTitle(cellData[header.props], i) === ''
                      ? ''
                      : checkAndSetEmptyTitle(cellData[header.props], i)
                  ">
                </app-value-currency>
              </span>
            </td>
          </tr>

          <ng-container *ngIf="i === rowData.data.length - 1">
            <tr>
              <td class="subtotal" *ngFor="let header of displayedColumns">
                <span *ngIf="header.props === 'family'" class="title-subtotal"
                  >SUBTOTAL
                </span>
                <ng-container *ngIf="!expecificProp(header.props)">
                  <div class="subtotal-value">
                    <span
                      [class.negative-value]="
                        this.data?.subtotals[
                          this.getSubtotalKeys(rowData.family, rowData.origin)
                        ].subtotalsByColumn[header.props] < 0
                      ">
                      <app-value-currency
                        [class.negative-value]="
                          this.data?.subtotals[
                            this.getSubtotalKeys(rowData.family, rowData.origin)
                          ].subtotalsByColumn[header.props] < 0
                        "
                        [value]="
                          this.data?.subtotals[
                            this.getSubtotalKeys(rowData.family, rowData.origin)
                          ].subtotalsByColumn[header.props] === ''
                            ? ''
                            : this.data?.subtotals[
                                this.getSubtotalKeys(
                                  rowData.family,
                                  rowData.origin
                                )
                              ].subtotalsByColumn[header.props]
                        ">
                      </app-value-currency>
                    </span>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot id="footer">
      <ng-container>
        <tr>
          <ng-container *ngFor="let header of displayedColumns">
            <td class="total">
              <span *ngIf="header.props === 'family'" class="title-total"
                >TOTAL
              </span>
              <ng-container *ngIf="!expecificProp(header.props)">
                <div class="total-value">
                  <span
                    [class.negative-value]="
                      this.data?.totals.totalsByColumn[header.props] < 0
                    ">
                    {{
                      this.data?.totals?.totalsByColumn[header.props]
                        | number : '1.0-0' || 0
                    }}
                  </span>
                </div>
              </ng-container>
            </td>
          </ng-container>
        </tr></ng-container
      >
    </tfoot>
  </table>
</div>
