import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-column-toggle',
  templateUrl: './column-toggle.component.html',
  styleUrls: ['./column-toggle.component.scss'],
})
export class ColumnToggleComponent {
  @Input() thead!: any[];
  @Output() columnsChange = new EventEmitter<any[]>();

  showMenuColumns: boolean = true;
  showSubMenuColumns: boolean = false;
  showColumns: any[] = [];
  visible: boolean = true;

  constructor(private toastr: ToastrService) {
    document.addEventListener(
      'click',
      this.closeMenuColumnsClickingOutside.bind(this)
    );
  }

  filteredProps(prop: string): boolean {
    return (
      prop === 'jan' ||
      prop === 'fev' ||
      prop === 'mar' ||
      prop === 'apr' ||
      prop === 'may' ||
      prop === 'jun' ||
      prop === 'jul' ||
      prop === 'aug' ||
      prop === 'sep' ||
      prop === 'out' ||
      prop === 'dec' ||
      prop === 'family' ||
      prop === 'origin' ||
      prop === 'customerShort' ||
      prop === 'channel'
    );
  }

  closeMenuColumnsClickingOutside(event: MouseEvent) {
    const showColumns = document.getElementById('showColumns');
    if (showColumns && !showColumns.contains(event.target as Node)) {
      this.showSubMenuColumns = false;
    }
  }

  toggleMenuColumns() {
    this.showSubMenuColumns = !this.showSubMenuColumns;
  }

  toggleHideColumns(props: string) {
    if (
      props === 'jan' ||
      props === 'fev' ||
      props === 'mar' ||
      props === 'apr' ||
      props === 'may' ||
      props === 'jun' ||
      props === 'jul' ||
      props === 'aug' ||
      props === 'sep' ||
      props === 'out' ||
      props === 'dec'
    ) {
      const suffix = props.substring(props.length - 3);
      // Encontra o status do mês clicado para passar para os child column.props.endsWith(suffix)
      let monthStatus: any;
      this.thead.forEach(column => {
        if (column.props.endsWith(suffix)) {
          monthStatus = column.status;
        }
      });
      this.thead.forEach(column => {
        if (column.props.endsWith(suffix)) {
          column.status = !monthStatus;
        }
      });
    } else {
      const columnIndex = this.thead.findIndex(
        column => column.props === props
      );

      if (columnIndex !== -1) {
        const visibleColumnsCount = this.thead.filter(
          column => column.status
        ).length;
        try {
          if (
            visibleColumnsCount === 1 &&
            this.thead[columnIndex].status === true
          ) {
            throw 'You must have at least one column.';
          } else {
            this.thead[columnIndex].status = !this.thead[columnIndex].status;
          }
        } catch (err: any) {
          this.toastr.error(err);
        }
      }
    }
  }

  countSelectedColumns(): number {
    return this.thead.filter(column => column.status).length;
  }

  getValidatorHideColumn(props: string): boolean {
    const column = this.thead.find(column => column.props === props);
    return !column || !column.status;
  }
}
