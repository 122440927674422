<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">ORDER ALLOCATION</span>
    <div class="container-export">
      <span class="button-group">
        <span
          class="material-icons"
          (click)="exportTable()"
          matTooltip="{{ tooltipMessage }}">
          <button
            class="export-button"
            [class.disabled]="!enableApplyFilter"
            [disabled]="!enableApplyFilter"></button>
        </span>
        <button
          [class.disabled]="!enableButtonNewOrder"
          [disabled]="!enableButtonNewOrder"
          class="button modal-action-button"
          *ngIf="hasPermissionCreate"
          mat-button
          (click)="prepareToCreate()">
          NEW ORDER
        </button>
      </span>
    </div>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <span class="line"></span>

  <ng-template #actionComments class="custom-dialog-container">
    <form [formGroup]="orderForm" class="form-order-allocation">
      <header class="title-modal" matDialogTitle>COMMENTS</header>
      <mat-dialog-content>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="geoComments"
            class="modal-comments"
            autocomplete="off"
            maxlength="500">
          </textarea>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="modal-close-permissions" mat-button matDialogClose>
          CANCEL
        </button>
        <button
          *ngIf="isEdit"
          class="button modal-action-button"
          mat-button
          (click)="onUpdateComments()">
          SAVE
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <ng-template #viewGeoComments class="custom-dialog-container">
    <form [formGroup]="orderForm" class="form-order-allocation">
      <header class="title-modal" matDialogTitle>COMMENTS</header>
      <mat-dialog-content>
        <div class="modal-comments-container">
          <div mat-dialog-content>
            {{ orderForm.controls.geoComments.value }}
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="modal-close-permissions" mat-button matDialogClose>
          BACK
        </button>
        <button
          *ngIf="isEdit"
          class="button modal-action-button"
          mat-button
          matDialogClose="false"
          (click)="prepareToViewCommentsGeo(EventItemId)">
          EDIT
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <ng-template #actionCommentsSales class="custom-dialog-container">
    <form [formGroup]="orderForm" class="form-order-allocation">
      <header class="title-modal" matDialogTitle>COMMENTS</header>
      <mat-dialog-content>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="salesComments"
            class="modal-comments"
            autocomplete="off"
            maxlength="500">
          </textarea>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="modal-close-permissions" mat-button matDialogClose>
          CANCEL
        </button>
        <button
          *ngIf="isEdit"
          class="button modal-action-button"
          mat-button
          (click)="onUpdateComments()">
          SAVE
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <ng-template #viewSalesComments class="custom-dialog-container">
    <form [formGroup]="orderForm" class="form-order-allocation">
      <header class="title-modal" matDialogTitle>COMMENTS</header>
      <mat-dialog-content>
        <div class="modal-comments-container">
          <div mat-dialog-content>
            {{ orderForm.controls.salesComments.value }}
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="modal-close-permissions" mat-button matDialogClose>
          BACK
        </button>
        <button
          class="button modal-action-button"
          mat-button
          matDialogClose="false"
          (click)="prepareToViewSalesComments(EventItemId)">
          EDIT
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <ng-template #modalCreateEdit>
    <form [formGroup]="userForm" class="form-create-order-allocation">
      <header class="title-modal" matDialogTitle>
        {{ isEdit ? 'Edit Order' : 'New Order' }}
      </header>
      <mat-dialog-content>
        <mat-form-field appearance="outline">
          <mat-label>Territory</mat-label>
          <input matInput formControlName="territory" appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <mat-select
            formControlName="countryId"
            name="country"
            disableOptionCentering
            panelClass="panel-select-material"
            (ngModelChange)="onChangeCountry($event)">
            <mat-option
              *ngFor="let country of countryModalList"
              [value]="country.id">
              {{ country.country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Sales Model</mat-label>
          <mat-select
            class="mat-select-search"
            [formControl]="salesModelMultiCtrl"
            panelClass="panel-select-material"
            panelClass="multiselect-search-numbers"
            inputDirective
            placeholder="Sales Model"
            [multiple]="false"
            (ngModelChange)="onChangeSalesModel($event)"
            disableOptionCentering>
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="{{
                  emptySalesModel ? 'no records found' : 'search...'
                }}"
                noEntriesFoundLabel="no matching found"
                aria-placeholder="Search"
                [formControl]="salesModelMultiFilterCtrl"
                #matSelectSearch>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let salesModel of filteredSalesModelMulti | async"
              [value]="salesModel.sales_model">
              {{ salesModel.sales_model }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-content class="mat-content">
        <mat-form-field appearance="outline">
          <mat-label>Product</mat-label>
          <input matInput formControlName="product" appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Color</mat-label>
          <input
            matInput
            formControlName="color"
            autocomplete="off"
            maxlength="50"
            appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Memory</mat-label>
          <input
            matInput
            formControlName="memory"
            autocomplete="off"
            maxlength="50" />
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-content class="mat-content-sales-order">
        <mat-form-field appearance="outline">
          <mat-label>SIM</mat-label>
          <input
            matInput
            formControlName="sim"
            autocomplete="off"
            maxlength="50"
            appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-select-search">
          <mat-label>Customer</mat-label>
          <mat-select
            class="mat-select-search"
            [formControl]="customerMultiCtrl"
            panelClass="panel-select-material"
            panelClass="multiselect-search-numbers-customer"
            inputDirective
            placeholder="Customer"
            [multiple]="false"
            (ngModelChange)="OnChangeCustomer($event)"
            disableOptionCentering>
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="{{
                  emptyCustomers ? 'no records found' : 'search...'
                }}"
                noEntriesFoundLabel="no matching found"
                aria-placeholder="Search"
                [formControl]="customerMultiFilterCtrl"
                #matSelectSearch>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let customer of filteredCustomerMulti | async"
              [value]="customer.customer_name"
              matTooltip="{{ customer.customer_name }}"
              [matTooltipPosition]="'left'">
              {{ customer.customer_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions class="modal-create">
        <button class="button modal-cancel-button" mat-button matDialogClose>
          CANCEL
        </button>
        <button
          *ngIf="!isEdit"
          class="button modal-action-button"
          mat-button
          (click)="onCreateOrder()">
          SAVE
        </button>
        <button
          *ngIf="isEdit"
          class="button modal-action-button"
          mat-button
          (click)="onUpdateOrder()">
          SAVE
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <div class="allocation-content">
    <div class="header-table-territory-allocation" *ngIf="!noFilterSelected">
      <div class="title-section">
        <h3 class="t-section">Allocation</h3>
        <h4 class="st-section">{{ filterProductSelected || '--' }}</h4>
      </div>
      <div class="button-filter">
        <div>
          <a class="next" (click)="nextQuarter(next)"
            >{{ next ? 'PREVIOUS' : 'NEXT' }} QUARTER</a
          >
        </div>
      </div>
    </div>
    <div
      class="no-filter-selected-session"
      *ngIf="noFilterSelected; else blockFilterSelected">
      <p>PLEASE,<br />START BY FILTERING A PRODUCT</p>
    </div>
    <ng-template #blockFilterSelected>
      <ng-container *ngIf="!showNoResults; else blockNoResult">
        <ngx-syncscroll
          [classSyncScroll]="'drag-timeline'"
          [attributeName]="'drag-scroll-timeline'">
          <div class="table-collapse">
            <div
              class="table-collpase-header"
              (click)="toggleTableCollapse(showTableCollapse)"
              [class.active]="showTableCollapse">
              <h4 class="st-section">Info</h4>
            </div>
            <div
              [@hideShowTableCollapse]="showTableCollapse ? 'show' : 'hide'"
              class="table-collpase-container"
              [class.active]="showTableCollapse">
              <div
                class="table-dynamic-list table-body dragscroll frame romanian"
                [ngClass]="'drag-timeline'"
                [attr.drag-scroll-timeline]="true">
                <app-information-table
                  [dataTable]="dataTableInformation"
                  (pullInChanged)="changedPullInOrIncrease($event)"
                  (valuePullInOrIncrease)="valuePullInOrIncrease($event)"
                  [toggleColumnTableValues]="toggleColumnTableValues"
                  [typeTable]="'order'">
                </app-information-table>
              </div>
            </div>
          </div>

          <div
            class="table-dynamic-list dragscroll frame floss"
            [ngClass]="'drag-timeline'"
            [attr.drag-scroll-timeline]="true">
            <app-table-inputs
              [quarter]="filter.quarter"
              [dataTable]="tableResult"
              (inputChanged)="inputChanged($event)"
              (eventActions)="onEventActions($event)"
              (callActionsComments)="onEventActions($event)"
              (callViewComments)="onEventActions($event)"
              (toggleColumnTable)="toggleColumnTable($event)"
              [typeTable]="'order'">
            </app-table-inputs>
          </div>
        </ngx-syncscroll>
      </ng-container>
      <ng-template #blockNoResult></ng-template>
    </ng-template>

    <div
      class="no-filter-selected-session"
      *ngIf="showNoResults || showNoResultsTableEcc">
      <p>NO RECORDS FOUND.</p>
    </div>

    <ng-template #dialogConfirmationDiscardSaveChanges>
      <header class="title-modal" matDialogTitle>CONFIRMATION</header>
      <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
      <mat-dialog-actions>
        <button class="button modal-cancel-button" mat-button matDialogClose>
          NO
        </button>
        <button
          *ngIf="isDiscardChanges"
          class="button modal-action-button"
          mat-button
          matDialogClose="discard">
          YES
        </button>
        <button
          *ngIf="!isDiscardChanges"
          class="button modal-action-button"
          mat-button
          matDialogClose="save">
          YES
        </button>
      </mat-dialog-actions>
    </ng-template>

    <ng-template #dialogConfirmation>
      <header class="title-modal" matDialogTitle>CONFIRMATION</header>
      <mat-dialog-content>
        {{ isEdit ? 'Do you confirm to edit?' : 'Do you confirm to remove?' }}
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="button modal-cancel-button" mat-button matDialogClose>
          NO
        </button>
        <button
          class="button modal-action-button"
          mat-button
          matDialogClose="false"
          (click)="verifyIfEditToConfirm()">
          YES
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>

  <div class="action-button-order-allocation">
    <div class="display-actions">
      <button
        class="button back-action-button"
        mat-button
        (click)="backButton()">
        BACK
      </button>
      <div
        *ngIf="!showNoResults && isEditTableMode"
        class="buttons-actions-table">
        <div>
          <button
            class="button modal-cancel-button button-cancel"
            mat-button
            (click)="onCancel()">
            CANCEL
          </button>
          <button
            #saveChangesButton
            class="button modal-action-button button-save"
            mat-button
            (click)="onSave()">
            SAVE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
