import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CrudService } from 'src/app/services/generic/crud.service';
@Component({
  selector: 'app-mkn-booked',
  templateUrl: './mkn-booked.component.html',
  styleUrls: ['./mkn-booked.component.scss'],
})
export class MknBookedComponent implements OnChanges {
  @Input() public dataFilter?: any;
  @Input() public changeTabs?: boolean;
  @Input() public selected?: boolean;
  @Input() public applyed?: boolean;
  @Output() dataExport = new EventEmitter<any>();
  @Output() responseResult = new EventEmitter<any>();
  public data?: any;
  public columns?: any;
  showNoResults: boolean;
  crudService: CrudService<any>;

  constructor(
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private app: ElementRef
  ) {
    this.crudService = new CrudService<any>(this.http, 'booked');
    this.showNoResults = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.applyed || this.changeTabs) {
      this.loadData();
    }
  }

  async loadData() {
    await this.checkResponse();
  }

  async checkResponse() {
    this.data = [];
    this.columns = [];

    this.crudService
      .getEntity(`allocation-customer/report-ppm`, this.dataFilter)
      .subscribe((response: any) => {
        if (response.rows.length > 0) {
          this.columns = response.headers;
          this.data = response.rows;
          this.addSubtotalValues(this.columns, this.data);
          this.responseResult.emit(response);
          this.changeTabs = false;
        } else {
          this.showNoResults = true;
          this.changeTabs = false;
          this.responseResult.emit(response);
        }
      });
  }

  compareObjects(o1: any, o2: any) {
    if (o1.title == o2.title && o1.props == o2.props && o1.status == o2.status)
      return true;
    else return false;
  }

  onChange(col: any) {
    if (col.props !== 'family') {
      this.columns.forEach((element: any) => {
        if (col.props == element.props) {
          if (element.status) {
            element.status = false;
          } else {
            element.status = true;
          }
        }
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPage();
  }
  scrollPage() {
    const tableHeader = this.app.nativeElement.querySelector('#table thead tr');
    const tableFooter = this.app.nativeElement.querySelector('#footer');

    if (tableHeader && tableFooter) {
      const windowTop = window.scrollY || window.pageYOffset;
      const tableTop =
        this.app.nativeElement.getBoundingClientRect().top + windowTop;
      const footerTop = tableFooter.getBoundingClientRect().top + windowTop;
      const offset = 0;
      const height = `${windowTop - offset - tableTop}px`;
      if (windowTop + tableHeader.offsetHeight <= footerTop) {
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${height}`;
      } else {
        // parar antes do footer
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${
          footerTop - tableTop - tableHeader.offsetHeight
        }px`;
      }
    }
  }

  checkAndSetEmptyTitle(title: string, i: number): string {
    let firstMatch: string | null = null;
    let foundMatch = false;

    this.data.forEach((dataRow: any, index: number) => {
      dataRow.data.forEach((row: any, i: number) => {
        if (row.family === title) {
          if (!foundMatch) {
            firstMatch = title;
            foundMatch = true;
          } else {
            if (i !== 0) {
              row.family = '';
            }
          }
        }
        if (row.customerShort === title) {
          if (!foundMatch) {
            firstMatch = title;
            foundMatch = true;
          } else {
            if (i !== 0) {
              row.customerShort = '';
            }
          }
        }
        if (row.origin === title) {
          if (!foundMatch) {
            firstMatch = title;
            foundMatch = true;
          } else {
            if (i !== 0) {
              row.origin = '';
            }
          }
        }
      });
    });
    return firstMatch || title;
  }

  expecificProp(prop: string): boolean {
    return (
      prop === 'family' ||
      prop === 'origin' ||
      prop === 'customerShort' ||
      prop === 'channel'
    );
  }

  private addSubtotalValues(header?: any, rows?: any) {
    const subtotals: { [key: string]: any } = {};
    if (header !== undefined && rows !== undefined) {
      rows.forEach((item: any) => {
        item.data.forEach((data: any) => {
          const kaValue = data.family;
          const originValue = data.origin;

          const compositeKey = `${kaValue} ${originValue}`;

          if (!subtotals[compositeKey]) {
            subtotals[compositeKey] = {
              family: kaValue,
              origin: originValue,
              subtotalsByColumn: {},
            };
          }

          header.forEach((col: any) => {
            const headerName = col.props;

            if (
              headerName !== 'family' &&
              headerName !== 'origin' &&
              headerName !== 'channel' &&
              !this.expecificProp(col.props)
            ) {
              if (!subtotals[compositeKey].subtotalsByColumn[headerName]) {
                subtotals[compositeKey].subtotalsByColumn[headerName] = 0;
              }

              subtotals[compositeKey].subtotalsByColumn[headerName] +=
                data[headerName];
            }
          });
        });
      });

      this.data.subtotals = subtotals;
      this.calculateTotal(header, rows);
    }
  }

  private calculateTotal(header?: any, rows?: any) {
    if (header !== undefined && rows !== undefined) {
      const totals: { [key: string]: any } = {
        totalsByColumn: {},
      };

      rows.forEach((item: any) => {
        item.data.forEach((data: any) => {
          header.forEach((col: any) => {
            const headerName = col.props;

            if (
              headerName !== 'family' &&
              headerName !== 'origin' &&
              !this.expecificProp(col.props)
            ) {
              if (!totals.totalsByColumn[headerName]) {
                totals.totalsByColumn[headerName] = 0;
              }

              totals.totalsByColumn[headerName] += data[headerName];
            }
          });
        });
      });

      this.data.totals = totals;
    }
  }

  getSubtotalKeys(family?: string, origin?: string) {
    const value = `${family} ${origin}`;
    return value;
  }

  updateData() {
    this.dataExport.emit(this.data.data);
  }
}
