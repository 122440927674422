<ngx-spinner></ngx-spinner>
<div id="ka-info-upload" class="container-ka-info-upload">
  <div class="header-title">
    <div class="title">KA INFO UPLOAD</div>
    <mat-divider></mat-divider>
    <div class="ka-info-upload-buttons">
      <div class="file-upload">
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          #fileUpload />

        <button
          class="upload-button"
          matTooltip="{{ 'Upload' }}"
          [class.disabled-upload]="!isInputKa"
          [disabled]="!isInputKa"
          (click)="fileUpload.click()"></button>
      </div>
      <span
        class="export-button"
        (click)="onFileDownload()"
        matTooltip="{{ 'Export' }}">
        <img src="../../../assets/images/BTN-Export.svg" alt="download" />
      </span>
    </div>
  </div>
  <div class="ka-info-upload-content">
    <app-results
      [data]="result"
      (resultPaginator)="onChangePaginator($event)"
      [isModeTableAccordion]="isModeTableAccordion"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
      *ngIf="!noResultKaInfUplad">
    </app-results>

    <div class="no-filter-selected-session" *ngIf="noResultKaInfUplad">
      <p>NO FILE UPLOADED YET.</p>
    </div>

    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
  <!-- <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button> -->
</div>
