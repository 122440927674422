<div class="overflow-table">
  <ng-container>
    <table>
      <thead>
        <tr>
          <th style="text-align: left !important;" *ngFor="let th of tableHead">
            <span>{{ th.title }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let td of tableBody">
          <td *ngFor="let head of tableHead; let i = index">
            <div class="card-color">
              <div
                class="card-border"
                [style.background]="i === 0 ? td.borderColor : 'white'"></div>
              <mat-card [ngClass]="detectClass(head.key)">
                <div *ngFor="let item of td[head.key]">
                  <span *ngIf="head.key != 'opCommit'">
                    {{head.key === 'demand' ||  head.key === 'opCommitPer' || head.key === 'delta' ? item.toString()+'%' : item}}
                  </span>
                  <span *ngIf="head.key === 'opCommit'">{{item.toLocaleString('en-US')}}</span>
                </div>
              </mat-card>
            </div>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
