import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { KaAllocationModule } from './ka-allocation/ka-allocation.module';
import { HomeComponent } from './home.component';
import { MaterialsModule } from './materials/materials.module';
import { SetupModule } from './setup/setup.module';
import { ShareComponentsModule } from '../components/share.components.module';
import { CustomerAllocationRetailModule } from './customer-allocation-retail/customer-allocation-retail.module';
import { EmeaAllocationModule } from './emea-allocation/emea-allocation.module';
import { ExcelService } from '../services/generic/excel.service';
import { ExcelSheetsService } from '../services/generic/excelSheets.service';
import { OnePlanModule } from './shipment-plan/one-plan.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MatTabsModule } from '@angular/material/tabs';
import { LomModule } from './lom/lom.module';
import { DcmReportsModule } from './dcm-reports/dcm-reports.module';
@NgModule({
  declarations: [HomeComponent],
  imports: [
    BrowserModule,
    SetupModule,
    MaterialsModule,
    KaAllocationModule,
    OnePlanModule,
    ShareComponentsModule,
    CustomerAllocationRetailModule,
    EmeaAllocationModule,
    CurrencyMaskModule,
    MatTabsModule,
    LomModule,
    DcmReportsModule,
  ],
  providers: [ExcelService, ExcelSheetsService],
  bootstrap: [HomeComponent],
})
export class HomeModule {}
