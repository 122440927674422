export const responseColorMIx: any = {
    fqCommit: {
      headers: [
        { prop: 'PRODUCT', key: 'product', styleClass: 'product' },
        { prop: 'DEMAND', key: 'demand', styleClass: 'demand' },
        { prop: 'OP COMMIT', key: 'opCommit', styleClass: 'opCommit' },
        { prop: 'OP COMMIT %', key: 'opCommitPer', styleClass: 'opCommitPer' },
      ],
      rows: [
        {
          product: ['Sierra Black', 'Sierra White', 'Sierra Teste1', 'Sierra Teste2', 'Sierra Teste3'],
          demand: ['70%', '30%'],
          opCommit: ['700', '300'],
          opCommitPer: ['65%', '35%'],
        },
        {
          product: ['Saipan Grey', 'Saipan Yellow'],
          demand: ['70%', '30%'],
          opCommit: ['23800', '5400'],
          opCommitPer: ['30%', '70%'],
        },
        {
          product: ['Kyoto Lagon', 'Kyoto Black'],
          demand: ['80%', '20%'],
          opCommit: ['23800', '23800'],
          opCommitPer: ['10%', '90%'],
        },
      ],
    },
    delta: {
      headers: [
        { prop: 'PRODUCT', key: 'product', styleClass: 'product' },
        { prop: 'DEMAND', key: 'demand', styleClass: 'demand' },
        { prop: 'OP COMMIT', key: 'opCommit', styleClass: 'opCommit' },
        { prop: 'OP COMMIT %', key: 'opCommitPer', styleClass: 'opCommitPer' },
        { prop: 'DELTA', key: 'delta', styleClass: 'delta' },
      ],
      rows: [
        {
          product: ['Sierra Black', 'Sierra White'],
          demand: ['70%', '30%'],
          opCommit: ['700', '300'],
          opCommitPer: ['65%', '35%'],
          delta: ['-900', '100'],
        },
        {
          product: ['Saipan Grey', 'Saipan Yellow'],
          demand: ['70%', '30%'],
          opCommit: ['23800', '5400'],
          opCommitPer: ['30%', '70%'],
          delta: ['1500', '1300'],
        },
        {
          product: ['Kyoto Lagon', 'Kyoto Black'],
          demand: ['80%', '20%'],
          opCommit: ['23800', '23800'],
          opCommitPer: ['10%', '90%'],
          delta: ['2000', '1000'],
        },
      ],
    },
  };