import { SelectionModel } from '@angular/cdk/collections';
import {
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { TableInterface } from './table.interface';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class TableComponent implements OnChanges, OnInit {
  @ViewChild('tableBody') tableBody!: ElementRef<HTMLInputElement>;
  @Output('callActions') callActions = new EventEmitter();
  @Input() table!: TableInterface;
  @Input() hasTotalRow!: boolean;
  @Input() currentWeekValue!: any;

  selection = new SelectionModel<any>(true, []);
  panelOpenState: boolean;

  dataSource: any;
  displayedColumns: any;
  columnsCustomer: any = [];
  columnsLabelsTableCustomer: any[];
  columnsLabel: any;

  hasStatus: any;
  hasComma: any;
  hasCalculation: any;
  type: any;
  tooltipMessage: any;
  tableGrouping: boolean;

  currentWeek: string;
  currentPeriod: string;
  currentWeekLabel: string;

  offsetTopSubTotal: number;
  translateYAbsoluteItem: number;

  constructor(protected http: HttpClient) {
    this.tableGrouping = false;
    this.panelOpenState = false;

    this.columnsLabelsTableCustomer = [];
    this.columnsLabel = [];

    this.currentWeek = '';
    this.currentPeriod = '';
    this.currentWeekLabel = '';

    this.offsetTopSubTotal = 0;
    this.translateYAbsoluteItem = 0;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.table?.currentValue) {
      this.columnsLabel = this.table.columns;
      this.displayedColumns = this.table.columns?.map(
        (column: any) => column.label
      );
      this.table.columns?.every((column: any) => {
        if (column.innerTable) {
          this.columnsCustomer = column.innerTable;
          return false;
        }
        return true;
      });
      this.columnsLabelsTableCustomer = this.columnsCustomer.map(
        (column: any) => column.idColumnLabel
      );
      this.tableGrouping = this.columnsLabel
        ?.map((column: any) => column.group)
        .find((element: any) => element === true);
      this.dataSource = this.table.rows;
      this.hasStatus = this.table.hasStatus;
      this.hasComma = this.table.hasComma;
      this.hasCalculation = this.table.hasCalculation;
      this.tooltipMessage = this.table.tooltipMessage;
      this.type = this.table.type;
      const weeks = this.table.rows?.filteredData;
      if (weeks) {
        Object.keys(weeks).every(prop => {
          if (weeks[prop] === 0) {
            this.currentWeek = prop;
            return false;
          }
          return true;
        });
      }
      const dataSetup = changes.table.currentValue.columns;
      dataSetup?.forEach((element: any) => {
        if (element.label === this.currentWeek) {
          this.currentPeriod = element.prop;
        }
      });
      this.selection = new SelectionModel<any>(true, []);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0]?.clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0]?.clientHeight;
    const matRipple = 60;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;
    const appFooterSubTotal = document.getElementById('subtotal1')?.offsetTop;
    this.offsetTopSubTotal = appFooterSubTotal || 0;
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const offsetTopElement =
      this.tableBody?.nativeElement.offsetTop + clientHeightTop;
    let elementsProductColumn = document.querySelectorAll(
      'thead tr th.mat-column-PRODUCT div span'
    );
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
      elementsProductColumn.forEach(function (element) {
        let elem = element as HTMLElement;
        elem.style.marginLeft = '20px';
      });
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  wowWeek(data: any) {
    if (data.includes('_')) {
      return true;
    }
    return false;
  }

  isNumberFlexOnePlanVariation(data: string) {
    if (!isNaN(parseInt(data))) {
      let dataNumber = parseInt(data);
      return dataNumber;
    } else {
      return data;
    }
  }

  changeWeek(data: string) {
    if (data.includes('week')) {
      return data.replace('ee', '');
    }
    return data;
  }

  checkType(value: any) {
    return typeof value === 'object';
  }

  isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  isNumber(value: any) {
    return typeof value === 'number';
  }

  editEvent(item: any) {
    this.callActions.emit({ item, type: 'edit' });
  }

  deleteEvent(item: any) {
    this.callActions.emit({ item, type: 'delete' });
  }

  viewEvent(item: any) {
    this.callActions.emit({ item, type: 'view' });
  }

  systemFunction(item: any) {
    this.callActions.emit({ item, type: 'systemFunction' });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.callActions.emit({ item: this.selection, type: 'select' });
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.callActions.emit({ item: this.selection, type: 'select' });
  }

  checkboxToggle(element: any) {
    this.selection.toggle(element);
    this.callActions.emit({ item: this.selection, type: 'select' });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  isCurrentWeek(labelTable: any) {
    const currentYear = new Date().getFullYear();
    const year = currentYear.toString();
    if (
      labelTable === this.currentWeek &&
      this.currentPeriod.includes(`${year}`)
    ) {
      return true;
    }
    return false;
  }

  isCurrentTwo(colDef: any) {
    return (
      (colDef.entered && colDef.currentMonth) ||
      colDef.toBookToAllocation ||
      this.isCurrentWeek(colDef.label)
    );
  }

  currentColumn(label: any) {
    if (label.includes('tot')) {
      return true;
    }
    return false;
  }

  isProduct(label: any) {
    if (label.includes('product')) {
      return true;
    }
    return false;
  }

  isVariation(label: any) {
    if (label.includes('variation')) {
      return true;
    }
    return false;
  }

  isWichMonth(element: any): any {
    let month = element.month.value;

    switch (month) {
      case 'April':
      case 'July':
      case 'October':
      case 'January':
        return 'evenMonth';
      case 'May':
      case 'August':
      case 'November':
      case 'February':
        return 'oddMonth';
      case 'June':
      case 'September':
      case 'December':
      case 'March':
        return 'evenMonth';
    }
  }

  trackByIndex = (i: number) => i;

  rotateSvg(svgElement: any) {
    svgElement.classList.add('rotate');
    setTimeout(() => {
      svgElement.classList.remove('rotate');
    }, 1000);
  }
}
