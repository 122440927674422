export const pagesBrazil: any[] = [
  { name: 'Geo', url: '../../../assets/images/ico-geo.svg', path: 'geo' },
  {
    name: 'Country',
    url: '../../../assets/images/ico-country.svg',
    path: 'country',
  },
  {
    name: 'Channel',
    url: '../../../assets/images/ico-channel.svg',
    path: 'channel',
  },
  {
    name: 'Customer',
    title: 'customer settings',
    url: '../../../assets/images/ico-customer.svg',
    path: 'customer-short',
  },
  {
    name: 'Setup',
    title: 'setup management',
    url: '../../../assets/images/ico-setup-management.svg',
    path: 'setup-management',
  },
  {
    name: 'Fiscal Calendar',
    url: '../../../assets/images/ico-fiscal-calendar.svg',
    path: 'fiscal-calendar',
  },
  { name: 'crsd', url: '../../../assets/images/ico-csrd.svg', path: 'crsd' },
  {
    name: 'charts',
    url: '../../../assets/images/ico-csrd.svg',
    path: 'charts',
  },
  {
    name: 'MATERIALS',
    title: 'materials management',
    url: '../../../assets/images/ico-material.svg',
    path: 'material-management',
  },
  {
    name: 'ADMIN',
    title: 'admin',
    url: '../../../assets/images/ico-admin.svg',
    path: 'admin',
  },
];

export const pagesEmea: any[] = [
  {
    name: 'Location',
    url: '../../../assets/images/ico-location.svg',
    path: 'location',
  },
  {
    name: 'Customer',
    title: 'customer settings',
    url: '../../../assets/images/ico-customer.svg',
    path: 'emea-customer-settings',
  },
  {
    name: 'Fiscal Calendar',
    url: '../../../assets/images/ico-fiscal-calendar.svg',
    path: 'fiscal-calendar',
  },
  {
    name: 'Product Settings',
    url: '../../../assets/images/ico-product-settings.svg',
    path: 'product-settings',
  },
  {
    name: 'ADMIN',
    title: 'admin',
    url: '../../../assets/images/ico-admin.svg',
    path: 'admin',
  },
];
