<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">CUSTOMER VIEW</span>
    <span class="material-icons" (click)="exportTable()">
      <button
        class="export-button"
        [class.disabled]="disabledButtonExport"
        [disabled]="disabledButtonExport"
        matTooltip="{{ tooltipMessage }}"></button>
    </span>
  </div>
  <div class="customer-view-content">
    <mat-tab-group
      (click)="tabClick($event)"
      [selectedIndex]="selectedTabIndex">
      <mat-tab label="ALLOCATION VIEW">
        <app-search
          (searchEvent)="onSearchEvent($event, 'allocationView')"
          [filters]="searchInputsAllocationView"
          (applyFilterEvent)="applyFilter('allocationView')"
          [showButtonApply]="true"
          [enableApplyFilter]="enableApplyFilter"
          [showMoreFiltersLink]="true"></app-search>
        <mat-divider></mat-divider>
        <section class="header-table-allocation">
          <div class="title-section">
            <h3 class="t-section">Allocation By Customer</h3>
            <h4 class="st-section"></h4>
          </div>
          <div class="reference-table-container">
            <span class="reference-table"
              ><b>ENTERED SETUP </b>{{ allocationLabels.entered | uppercase
              }}<b> CURRENT WEEK </b
              >{{ allocationLabels.currentWeek | uppercase }}<b> TO BOOK </b
              >{{ allocationLabels.toBook | uppercase }}<b> TO ALLOCATE </b>
              {{ allocationLabels.toAllocate | uppercase }}
            </span>
            <!-- TBD -->
            <!-- <button
              *ngIf="this.loadedDataTableData"
              (click)="openModalLastWeek()"
              class="button-last-week">
              LAST WEEK
            </button> -->
          </div>
        </section>
        <ng-template #blockFilterSelected>
          <div
            class="table-dynamic-list"
            *ngIf="!showNoResults; else blockNoResult">
            <div *ngFor="let table of dataSourceTable; let key = index;">
              <app-table-customer-view
                *ngIf="showTable"
                [dataSource]="table"
                [eventTable]="eventTable"
                [keyDataSource]="key"
                (toggleTable)="toggleTable($event)"
                [showSubtotalTitle]="table.showSubtotalTitle"
                [origins]="origins"
                (valueIdentified)="openModalNetOpenPo($event)">
              </app-table-customer-view>
            </div>
          </div>

          <!-- TBD -->
          <!-- <app-table-columns-tabs
            [tab]="loadedDataTableTab"
            [thead]="loadedDataTableThead"
            [data]="loadedDataTableData"
            [showMenuColumns]="loadedDataTableShowMenuColumns"
            [visible]="loadedDataTableVisible"
            (changeTab)="changeTabDataTableTab($event)">
          </app-table-columns-tabs> -->
        </ng-template>
        <ng-template #blockNoResult></ng-template>
        <div class="no-filter-selected-session" *ngIf="showNoResults">
          <p>NO RECORDS FOUND.</p>
        </div>
        <div
          class="no-filter-selected-session"
          *ngIf="noFilterSelected; else blockFilterSelected">
          <p>PLEASE,<br />START BY FILTERING A CUSTOMER SHORT</p>
        </div>
      </mat-tab>
    </mat-tab-group>

    <button (click)="backButton()" class="button back-action-button" mat-button>
      BACK
    </button>
  </div>
</div>
