const PAGE_NAME_RESOURCE = {
  profile: 'profile',
  user: 'user',
  country: 'country',
  geo: 'geo',
  channel: 'channel',
  salesManagement: 'sales-management',
  setupManagement: 'setup-management',
  orderAdminManagement: 'order-admin-management',
  fiscalCalendar: 'fiscal-calendar',
  crsd: 'crsd',
  customerShort: 'customer-short',
  allocationRetail: 'allocation-retail',
  materialManagement: 'material-management',
  kaManagement: 'ka-management',
  kaExecution: 'ka-execution',
  kaAllocation: 'ka-allocation',
  kaInfo: 'ka-info',
  EmeaTerritoryAllocation: 'emea-allocation',
  missing: 'missing',
  emeaCustomerSettings: 'emea-customer-settings',
  territoryAllocation: 'territory-allocation',
  Location: 'location',
  orderAllocation: 'order-allocation',
  emeaExecutiveOverview: 'emea-executive-overview',
  emeaTerritoryAndProductsView: 'emea-territory-products-view',
  onePlan: 'one-plan',
  wowAnalysis: 'emea-wow-analysis',
  fairDistribution: 'fair-distribution',
  toBook: 'to-book',
  kaAllocationUpload: 'ka-allocation-upload',
  overBooked: 'overbooked',
  customerView: 'customer-view',
  flexOnePlanVariation: 'flex-one-plan-variation',
  lom: 'lom',
  familyView: 'family-view',
};

export default PAGE_NAME_RESOURCE;
