import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { KaInfoUploadTable } from './ka-info-upload-mockup';
import { DatePipe } from '@angular/common';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import { UploadFileService } from 'src/app/services/generic/upload-file.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { DownloadFileService } from 'src/app/services/generic/download-file.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-ka-info-upload',
  templateUrl: './ka-info-upload.component.html',
  providers: [DatePipe],
  styleUrls: ['./ka-info-upload.component.scss'],
})
export class KaInfoUploadComponent implements OnInit {
  @ViewChild('fileUpload') file!: ElementRef;
  dataTable: any = KaInfoUploadTable;
  isModeTableOrCardsHide: boolean = true;
  hidePagination: boolean = true;
  noResultKaInfUplad: boolean = false;
  isModeTableAccordion: boolean = true;
  isInputKa: boolean = true;
  result: any;
  filter!: any;
  pagination: Pagination;
  crudService: CrudService<any>;
  uploadFileService: UploadFileService<any>;
  downloadFileService: DownloadFileService<any> | undefined;
  fileName: string = '';

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    protected http: HttpClient,
    private toastr: ToastrService,
    private authService: AuthService,
    private sharedInfoService: SharedinfoService
  ) {
    this.pagination = { size: 10, page: 0 };
    this.uploadFileService = new UploadFileService<any>(this.http);
    this.downloadFileService = new DownloadFileService<any>(this.http);
    this.crudService = new CrudService<any>(
      this.http,
      'ka-info-brazil/logs/paged'
    );
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.getDataTable(this.pagination);

    this.isInputKa = this.authService
      .getUserPermissions()
      .some(
        (data: any) =>
          data.resource === 'one-plan-upload' && data.permission === 'CREATE'
      );

    this.sharedInfoService.emitChangeLocalStorage.subscribe(() => {
      this.isInputKa = this.authService
        .getUserPermissions()
        .some(
          (data: any) =>
            data.resource === 'one-plan-upload' && data.permission === 'CREATE'
        );
    });
  }

  getDataTable(page: any) {
    this.spinner.show();

    this.crudService
      .getEntity(
        `ka-info-brazil/logs/paged?size=${page.size}&page=${page.page}`
      )
      .subscribe(
        (response: any) => {
          if (response.rows.length > 0) {
            this.updateComponents(response);
            this.noResultKaInfUplad = false;
            this.spinner.hide();
          } else {
            this.noResultKaInfUplad = true;
            this.spinner.hide();
          }
        },
        (err: any) => {
          this.noResultKaInfUplad = true;
          this.spinner.hide();
        }
      );
  }

  updateComponents(response: any) {
    this.result = {
      cards: [],
      table: {
        columns: [
          {
            label: 'FILE NAME',
            sticky: true,
            prop: 'fileName',
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
          {
            label: 'USER',
            sticky: true,
            prop: 'user',
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
          {
            label: 'DATE',
            sticky: true,
            prop: 'date',
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
          {
            label: 'STATUS',
            sticky: true,
            prop: 'status',
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
        ],
        rows: new MatTableDataSource<any>(
          this.dataTables(JSON.stringify(response.rows))
        ),
      },
      pageIndex: response?.page || 0,
      pageSize: response?.totalRows || 0,
      pagesTotal: response?.totalPages || 0,
    };
  }

  dataTables(data: any) {
    let customer: any = [];

    customer = this.dataMapToTable(data);

    const dataTables: any[] = [];
    customer.forEach((item: any) => {
      dataTables.push(item);
    });

    return dataTables;
  }

  dataMapToTable(data: any): any[] {
    return JSON.parse(data).map((item: any) => ({
      fileName: item.filename,
      user: item.user.name,
      date: this.formatDate(item.date),
      status: item.status,
    }));
  }

  formatDate(date: any) {
    const data = date;
    return this.datePipe.transform(data, 'MM-dd-yyyy HH:mm:ss');
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getDataTable(paginated);
  }

  onFileSelected(event: any): void {
    const file: File = event.target?.files[0];

    if (file) {
      this.spinner.show();
      this.fileName = file.name;

      this.uploadFileService
        .uploadOnePlanKaInfo('ka-info-brazil/upload', file)
        .then((response: any) => {
          this.file.nativeElement.value = null;
          this.fileName = '';
          this.getDataTable(this.pagination);
          this.noResultKaInfUplad = false;
          this.spinner.hide();
          this.toastr.success('File Upload Sucessfully', 'Success');
        })
        .catch((error: any) => {
          this.file.nativeElement.value = null;
          this.fileName = '';
          this.getDataTable(this.pagination);
          this.noResultKaInfUplad = false;
          this.spinner.hide();
          this.toastr.error(error.error.message, 'Error');
        });
    }
  }

  onFileDownload(): void {
    this.spinner.show();
    this.downloadFileService
      ?.downloadFile('ka-info-brazil/export-template', this.filter, 'text')
      .subscribe(
        (response: any) => {
          const file = this.base64ToBlob(response);
          // IE
          const nav = window.navigator as any;
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return;
          }
          FileSaver.saveAs(file, `template_ka_info.xlsx`);
          this.spinner.hide();
          this.toastr.success('File Download Sucessfully', 'Success');
        },
        (err: any) => {
          const subtitleMessage =
            'An error occurred while generating the Excel file!';
          const titleMessage = 'Download Excel';
          this.spinner.hide();
          this.toastr.error(`${subtitleMessage}`, `${titleMessage}`);
        }
      );
  }

  public base64ToBlob(b64Data: string, sliceSize = 512) {
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  backButton() {
    this.router.navigate(['home/ka-allocation/ka-info']);
  }
}
