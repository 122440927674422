import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
  Renderer2
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ColorEvent } from 'ngx-color';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { TableInterface } from 'src/app/components/table/table.interface';
import { validateColorPicker } from 'src/app/helpers/colorpicker-validator';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { validateNoOnlySpaces } from 'src/app/helpers/no-only-space-input-validator';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { Ka } from 'src/app/interfaces/ka.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { salesModelFilter } from 'src/app/interfaces/ka-management';

const STATUS = {
  ACTIVE: { value: 'Active', id: true },
  INACTIVE: { value: 'Inactive', id: false },
  ALL: { value: 'All', id: '' },
};
@Component({
  selector: 'app-ka-management',
  templateUrl: './ka-management.component.html',
  styleUrls: ['./ka-management.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class KaManagementComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('modalEdit', { static: true }) modalEdit: TemplateRef<any> | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  @ViewChildren(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  salesModelProductAutoComplete!: QueryList<MatAutocompleteTrigger>;
  searchInputs: InputBase<string>[];
  searchSalesModelInputs: InputBase<string>[];
  crudService: CrudService<Ka>;
  result!: ResultInterface;
  productsResult!: ResultInterface;
  pagination: Pagination;
  productsPagination: Pagination;
  kaForm: FormGroup;
  filter: any = { sort: 'ka' };
  kaProductsFilter: any = {};
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  geosEntity: Geo[] = [];
  countriesEntity: Country[] = [];
  statusEntities: any[] = [];
  salesModelsEntities: any[] = [];
  salesModelFilter: any[] = [];
  allSalesModels: any[] = [];
  productsEntitities: any[] = [];
  countryFilteredOptions!: Observable<any[]>;
  countryInitial: any = {};
  initialColor: string = '';
  colorPickerVisible: boolean = false;
  isEdit: boolean = false;
  initialProductsSelection = [];
  allowMultiSelect = true;
  productsSelection = new SelectionModel<{
    salesModel: string;
    product: string;
  }>(this.allowMultiSelect, this.initialProductsSelection);
  dataTable!: TableInterface;
  sizeOptions = [10, 25, 50];
  salesModelfilteredOptions!: Observable<any[]>;
  productfilteredOptions!: Observable<any[]>;
  showInactivateLink: boolean = false;
  showActivateLink: boolean = false;
  productsInactivated: any[] = [];
  productsChangedStatus: any[] = [];
  productsSelecteds: any[] = [];
  ka!: string;
  colorSelected: boolean = false;
  showImageNoResults: boolean = false;
  noResults = '../../../assets/images/ico-no-results.png';
  messageConfirmDialog: string = 'Do you confirm the update of the records?';
  previousStatusIsActive!: boolean;
  hasPermissionCreate!: boolean;
  currentUser!: any;

  orderAdminOptions: any[] = [];
  salesManagerOptions: any[] = [];

  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.kaManagement },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.kaManagement },
    },
    {
      entity: 'kaManagement',
      query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.kaManagement },
    },
  ];

  constructor(
    private router: Router,
    private modalService: ModalActionsService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private toastr: ToastrService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private renderer: Renderer2
  ) {
    this.pagination = { page: 0, size: 10 };
    this.productsPagination = { page: 0, size: 10 };
    this.crudService = new CrudService<Ka>(this.http, 'kaManagement');
    this.searchInputs = [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'ka',
        hover: 'KA',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'KA Status',
        classCss: 'ka-status-change'
      }),
    ];

    this.searchSalesModelInputs = [
      new SelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'allocationBz',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];

    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('ka-management');
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
    };

    this.kaForm = this.formBuilder.group({
      id: [''],
      countryId: [{ value: '', disabled: true }, Validators.required],
      ka: ['', [validateNoOnlySpaces, Validators.required]],
      colorHex: ['Select', [validateColorPicker, Validators.required]],
      status: [true],
      salesModel: [''],
      allocationBz: [''],
      products: [''],
      order_admin_id: ['', Validators.required],
      sales_manager_id: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.countryFilteredOptions =
      this.kaForm.controls.countryId.valueChanges.pipe(
        startWith(''),
        map(value => this.filterOptions(value, this.countriesEntity, 'country'))
      );
    this.salesModelfilteredOptions =
      this.kaForm.controls.salesModel.valueChanges.pipe(
        startWith(''),
        map(value =>
          this.filterOptions(value, this.salesModelsEntities, 'value')
        )
      );
    this.productfilteredOptions =
      this.kaForm.controls.allocationBz.valueChanges.pipe(
        startWith(''),
        map(value =>
          this.filterOptions(value, this.productsEntitities, 'value')
        )
      );
  }

  private filterOptions(
    value: string,
    entities: any[],
    property: string
  ): any[] {
    const filterValue = value?.toLocaleUpperCase() || '';
    return entities.filter((option: any) =>
      option[property]?.toLocaleUpperCase().includes(filterValue)
    );
  }

  loadData() {
    this.spinner.show();

    const promises = [
      this.getDataFilter(), 
      this.getKas(this.pagination, this.filter), 
      this.getOrderAdminSalesManager()
    ]
    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  async getDataFilter(): Promise<void> {        
    return new Promise((resolve, reject) => {
      const geoFilter: any[] = [];
      const countryFilter: any[] = [];
      const kaFilter: any[] = [];
      const statusFilter: any[] = [];
      let kasEntity: any[] = [];
      this.statusEntities = [];

      this.crudService
        .getDataFilters(this.entities)
        .subscribe((response: any) => {
          [this.geosEntity, this.countriesEntity, kasEntity] = response;
          const initialValueGeoFilter: { [key: string]: any } = {};
          const initialValueCountryFilter: { [key: string]: any } = {};
  
          this.geosEntity.forEach((item: Geo) => {
            geoFilter.push({ value: item.geo, id: item.id });
            if (item.id === this.currentUser.geoId) {
              initialValueGeoFilter.value = item.geo;
              initialValueGeoFilter.id = item.id;
            }
          });
  
          const selectGeo = new SelectSearchInput({
            key: 'geoId',
            hover: 'GEO',
            type: 'text',
            hasAutocomplete: true,
            options: geoFilter,
            value: initialValueGeoFilter.value,
            disabled: true,
            notClearable: true,
          });
  
          this.countriesEntity.forEach((item: Country) => {
            countryFilter.push({
              value: item.country + ' - ' + item.iso_code,
              id: item.id,
            });
            if (item.id === this.currentUser.countryId) {
              initialValueCountryFilter.value = item.country;
              initialValueCountryFilter.id = item.id;
            }
          });
          this.countryInitial = initialValueCountryFilter;
  
          const selectCountry = new SelectSearchInput({
            key: 'countryId',
            hover: 'Country',
            type: 'text',
            hasAutocomplete: true,
            options: countryFilter,
            value: initialValueCountryFilter.value,
            disabled: true,
            notClearable: true,
          });
  
          kasEntity.forEach((item: Ka) => {
            kaFilter.push({ value: item.ka, id: item.id });
          });
  
          statusFilter.push(STATUS.ACTIVE, STATUS.INACTIVE, STATUS.ALL);
          this.statusEntities.push(STATUS.ACTIVE, STATUS.INACTIVE);
  
          this.searchInputs[0] = selectGeo;
          this.searchInputs[1] = selectCountry;
          this.searchInputs[2].options = kaFilter;
          this.searchInputs[3].options = statusFilter;
          this.searchInputs = [...this.searchInputs];
          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, err.error.title);
          this.spinner.hide();
          reject()
        }
      );
    })

  }

  async getOrderAdminSalesManager(): Promise<void> {  
    return new Promise((resolve, reject) => {
      this.crudService.getEntity('user').subscribe((response: any) => {
        response.forEach((item: any) => {
          if (item.order_admin === true) {
            this.orderAdminOptions.push(
              {
                value: `${item.last_name}, ${item.name}`,
                id: item.id
              }
            )
          }
  
          if (item.sales_manager === true) {
            this.salesManagerOptions.push(
              {
                value: `${item.last_name}, ${item.name}`,
                id: item.id
              }
            )
          }
        })
  
        this.orderAdminOptions = this.hasDuplicates(this.orderAdminOptions);  
        this.orderAdminOptions.sort((a: any, b: any) => a.value.localeCompare(b.value));        
        this.salesManagerOptions = this.hasDuplicates(this.salesManagerOptions);
        this.salesManagerOptions.sort((a: any, b: any) => a.value.localeCompare(b.value));
        resolve();
      },
      (err: any) => {
        this.toastr.error(err.error.message, err.error.title);
        this.spinner.hide();
        reject()
      })
    })

  }

  hasDuplicates(array: any[]): any {
    const seenValues = new Set<any>();
    const uniqueItems: any[] = [];
  
    array.forEach(item => {
      if (!seenValues.has(item.value)) {
        seenValues.add(item.value);
        uniqueItems.push(item);
      }
    });
  
    return uniqueItems;
  }

  async getKas(page: any, params?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService
        .getPaged(page, params || this.filter)
        .subscribe((response: any) => {  
          if (response.totalRows > 0) {
            this.updateComponents(response);
            this.showImageNoResults = false;
          } else {
            this.showImageNoResults = true;
          }
          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, err.error.title);
          this.spinner.hide();
          reject()
        }
      );
    })
  }

  getKaProductsPaged(idKa: number, page?: any, params?: any) {
    this.spinner.show();
    const urlEntity = 'kaManagement/products/summaryPaged';
    this.crudService
      .getEntity([urlEntity, idKa].join('/'), {
        size: page.size,
        page: page.page,
        ...params,
        sort: 'salesModel',
        pageName: PAGE_NAME_RESOURCE.kaManagement,
      })
      .subscribe((response: any) => {
        this.productsResult = {
          table: {
            columns: [
              {
                label: 'selection',
                prop: 'selection',
                allowSelection: true,
                sticky: true,
                style: { width: '5%', padding: '8px' },
              },

              {
                label: 'sales model',
                prop: 'salesModel',
                sticky: true,
                style: { width: '30%', padding: '8px' },
              },
              {
                label: 'product',
                prop: 'product',
                sticky: true,
                style: {
                  width: '65%',
                  'word-wrap': 'break-word',
                  padding: '8px',
                },
              },
            ],
            rows: new MatTableDataSource<Ka>(
              this.dataProductsTable(response.rows)
            ),
          },
          pageIndex: response.page,
          pageSize: response.totalRows,
          pagesTotal: response.totalPages,
        };
        this.dataTable = {
          columns: this.productsResult.table?.columns,
          rows: this.productsResult.table?.rows,
          hasStatus: true,
        };
        this.productsPagination.page = 0;
        response.rows.forEach((item: any) => {
          if (
            !item.status &&
            this.productsInactivated.findIndex(
              (product: any) => product.materialId === item.materialId
            ) === -1
          ) {
            this.productsInactivated.push(item);
          }
        });

        this.spinner.hide();
      });
  }

  getAllKaProducts(): void {
    this.spinner.show();

    const salesModelFilter: any[] = [];
    const productFilter: any[] = [];
    const urlProductsEntity = 'material/products/summary';
    const urlSalesModelsEntity = 'material/salesModels/summary';

    this.crudService
      .getEntity(urlProductsEntity, {
        pageName: PAGE_NAME_RESOURCE.kaManagement,
      })
      .subscribe((response: any) => {
        response.forEach((item: any) => {
          if (
            item.productDesc &&
            productFilter.findIndex(
              product =>
                product.value.toUpperCase() === item.allocationBz?.toUpperCase()
            ) === -1
          ) {
            productFilter.push({ value: item.productDesc });
          }
        });
        this.productsEntitities = productFilter;
        this.kaForm.controls.allocationBz.reset();
      });

    this.crudService
      .getEntity(urlSalesModelsEntity, {
        pageName: PAGE_NAME_RESOURCE.kaManagement,
      })
      .subscribe((response: any) => {
        response.forEach((item: any) => {
          salesModelFilter.push({
            value: item.salesModel,
            id: item.materialId,
          });
        });

        this.allSalesModels = response;
        this.salesModelsEntities = salesModelFilter;
        this.kaForm.controls.salesModel.reset();
        this.spinner.hide();
      });

    this.searchSalesModelInputs[0].filteredOptions =
      this.salesModelfilteredOptions;
    this.searchSalesModelInputs[1].filteredOptions =
      this.productfilteredOptions;
  }

  dataTables(data: any) {
    const dataTables: Ka[] = [];
    data.forEach((item: any) => {
      item.country = item.country.country;
      item.active = item.status ? STATUS.ACTIVE.value : STATUS.INACTIVE.value;
      item.status = item.status ? STATUS.ACTIVE.value : STATUS.INACTIVE.value;
      item.colorPick = item.colorHex ? item.colorHex : '';
      if (item.orderAdminSalesManagers[0] !== undefined) {
        item.orderAdmin = item.orderAdminSalesManagers[0].orderAdmin ? `${item.orderAdminSalesManagers[0].orderAdmin.last_name}, ${item.orderAdminSalesManagers[0].orderAdmin.name}` : '';
        item.salesManager = item.orderAdminSalesManagers[0].salesManager ? `${item.orderAdminSalesManagers[0].salesManager.last_name}, ${item.orderAdminSalesManagers[0].salesManager.name}` : '';
      }
      dataTables.push(item);
    });
    return [...dataTables];
  }

  dataProductsTable(data: any) {
    const dataTable: any[] = [];
    data.forEach((item: any) => {
      item.product = item.allocationBz;
      item.active = item.status ? 'Active' : 'Inactive';
      dataTable.push(item);
    });

    dataTable.forEach((item: any) => {
      const indexProduct = this.productsChangedStatus.findIndex(
        (product: any) => product.materialId === item.materialId
      );
      if (indexProduct !== -1) {
        item.active = this.productsChangedStatus[indexProduct].status
          ? 'Active'
          : 'Inactive';
      }
      return item;
    });
    return [...dataTable];
  }

  updateComponents(responseKA: any) {
    this.result = {
      table: {
        columns: [
          {
            label: 'ka',
            prop: 'ka',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'country',
            prop: 'country',
            sticky: true,
            style: {
              'word-wrap': 'break-word',
              width: '15%',
              padding: '0 10px',
            },
          },
          {
            label: 'color Pick',
            prop: 'colorPick',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'status',
            prop: 'status',
            sticky: true,
            style: {
              'word-wrap': 'break-word',
              width: '15%',
              padding: '0 10px',
            },
          },
          {
            label: 'order admin',
            prop: 'orderAdmin',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'sales manager',
            prop: 'salesManager',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
        ],
        rows: new MatTableDataSource<Ka>(this.dataTables(responseKA.rows)),
        hasStatus: true,
      },
      pageIndex: responseKA.page,
      pageSize: responseKA.totalRows,
      pagesTotal: responseKA.totalPages,
    };

    const actions = this.permissionService.setTablePermissions('ka-management');
    delete actions.delete;

    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }
  onSearchEvent(value: any) {
    if (value.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo.toLocaleUpperCase().includes(value.geoId.toLocaleUpperCase())
      );
      value.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      value.geoId = '';
    }

    if (value.countryId) {
      const splitCountry = value.countryId.replace(/ - .*/, '');
      const currentCountry = this.countriesEntity.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(splitCountry.toLocaleUpperCase())
      );
      value.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      value.countryId = '';
    }

    value.ka = value.ka ? value.ka : '';

    if (
      value.status === STATUS.ACTIVE.value ||
      value.status === STATUS.INACTIVE.value
    ) {
      value.status =
        value.status === STATUS.ACTIVE.value
          ? STATUS.ACTIVE.id
          : STATUS.INACTIVE.id;
    } else {
      value.status = STATUS.ALL.id;
    }

    this.pagination.page = 0;
    this.filter = {
      ...value,
      sort: 'ka',
      pageName: PAGE_NAME_RESOURCE.kaManagement,
    };
    this.getKas(this.pagination, this.filter);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.prepareToEdit(event.item.id);
    }
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getKas(paginated, this.filter);
  }

  onChangeProductsPaginator(paginated: any) {
    if (paginated.length > 10) {
      this.kaProductsFilter = {};

      [this.productsPagination.page, this.productsPagination.size] = [
        paginated.pageIndex,
        paginated.pageSize,
      ];
      const kaId = this.kaForm.controls.id.value;
      this.showActivateLink = false;
      this.showInactivateLink = false;
      this.getKaProductsPaged(
        kaId,
        this.productsPagination,
        this.kaProductsFilter
      );
    }
  }

  onFilterEvent(value: any) {
    value.salesModel = value.salesModel || '';
    value.allocationBz = value.allocationBz || '';
    this.salesModelFilter = [];

    if (value.allocationBz) {
      this.salesModelsEntities = this.allSalesModels.filter(
        item =>
          item.productDesc.toLocaleUpperCase() ===
          value.allocationBz.toLocaleUpperCase()
      );
      if (!this.kaForm.controls.salesModel.value) {
        let salesModelOptions!: Observable<string[]>;

        this.salesModelsEntities.forEach((item: salesModelFilter) => {
          this.salesModelFilter.push({ value: item.salesModel });
        });

        salesModelOptions = this.kaForm.controls.salesModel.valueChanges.pipe(
          startWith(''),
          map(value =>
            this.filterOptions(value, this.salesModelFilter, 'value')
          )
        );

        this.searchSalesModelInputs[0].filteredOptions = salesModelOptions;
      }
    } else {
      this.salesModelsEntities = this.allSalesModels.map(item => ({
        value: item.salesModel,
        id: item.materialId,
      }));
      if (!this.kaForm.controls.salesModel.value) {
        this.kaForm.controls.salesModel.reset();
      }
    }

    this.productsPagination.page = 0;
    this.kaProductsFilter = {
      ...{ salesModel: value.salesModel, allocationBz: value.allocationBz },
      sort: 'salesModel',
      pageName: PAGE_NAME_RESOURCE.kaManagement,
    };
    const kaId = this.kaForm.controls.id.value;
    this.getKaProductsPaged(
      kaId,
      this.productsPagination,
      this.kaProductsFilter
    );

    if (value.salesModel && !value.allocationBz) {
      this.kaForm.controls.allocationBz.disable();
    } else {
      this.kaForm.controls.allocationBz.enable();
    }
  }

  reloadTableProducts(formControl: AbstractControl) {
    if (formControl.value === '') {
      this.clearFilter(formControl);
      this.salesModelProductAutoComplete.forEach((matAutomcomplete: any) =>
        matAutomcomplete.closePanel()
      );
    }
  }

  clearFilter(formControl: AbstractControl) {
    formControl.reset();
    this.onFilterEvent(this.kaForm.value);
    this.showActivateLink = false;
    this.showInactivateLink = false;
  }

  onCallActions(event: any) {
    if (event.type === 'select') {
      this.showActivateLink =
        event.item.selected.every(
          (item: any) =>
            this.productsInactivated.findIndex(
              (product: any) => product.materialId === item.materialId
            ) !== -1
        ) &&
        event.item.selected.length > 0 &&
        this.productsInactivated.length >= event.item.selected.length;
      this.showInactivateLink =
        event.item.selected.length > 0 && !this.showActivateLink;
      this.productsSelecteds = event.item.selected;
    }
  }

  prepareToCreate() {
    this.isEdit = false;
    this.colorSelected = false;
    this.kaForm.controls.countryId.setValue(this.countryInitial.value);
    const modal = this.modalService.createModal(this.modalCreateEdit);
    modal.afterClosed().subscribe(() => {
      this.resetFormModalCreateEdit();
    });
  }

  prepareToEdit(id: number) {
    this.isEdit = true;
    this.colorSelected = true;
    this.spinner.show();
    this.productsInactivated = [];
    this.getAllKaProducts();
    this.getKaProductsPaged(id, this.productsPagination);

    this.crudService.fetchEntity(id).subscribe((ka: any) => {

      if (ka.orderAdminSalesManagers !== undefined) {
        if (ka.orderAdminSalesManagers.length > 0) {
          if (ka.orderAdminSalesManagers[0].orderAdmin.order_admin === true) {
            const orderAdmin = `${ka.orderAdminSalesManagers[0].orderAdmin.last_name}, ${ka.orderAdminSalesManagers[0].orderAdmin.name}`
            this.kaForm.controls.order_admin_id.setValue(orderAdmin)
          }
  
          if (ka.orderAdminSalesManagers[0].salesManager.sales_manager === true) {
            const salesManager = `${ka.orderAdminSalesManagers[0].salesManager.last_name}, ${ka.orderAdminSalesManagers[0].salesManager.name}`
            this.kaForm.controls.sales_manager_id.setValue(salesManager)
          }
  
        }
      }

      this.ka = ka.ka.toUpperCase();
      this.kaForm.controls.id.setValue(ka.id);
      this.kaForm.controls.countryId.setValue(
        this.countriesEntity.filter(
          (country: Country) => country.id === ka.countryId
        )[0].country
      );
      this.kaForm.controls.ka.setValue(ka.ka);
      this.initialColor = ka.colorHex;
      this.kaForm.controls.colorHex.setValue(this.initialColor);
      this.kaForm.controls.status.setValue(ka.status);
      this.previousStatusIsActive = ka.status;

      const overlayContainer = document.querySelector('.cdk-overlay-container');
      if(overlayContainer){
        this.renderer.addClass(overlayContainer, 'modal-open');
      }
      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.renderer.removeClass(overlayContainer, 'modal-open');
        this.resetFormModalCreateEdit();
      });
      this.spinner.hide();
    });
  }

  onCreate() {
    if (
      !this.validateValueSelectedAutocomplete() &&
      this.kaForm.value.countryId
    ) {
      this.toastr.warning(
        'Select the country in the list',
        'Country not found'
      );
    } else {
      const currentCountry = this.countriesEntity.find(
        (item: Country) => item.id === this.currentUser.countryId
      );

      this.kaForm.value.countryId = currentCountry?.id;

      if (!this.kaForm.valid && this.kaForm.value.ka !== '' && this.kaForm.value.colorHex !== 'Select') {
        delete this.kaForm.value.id;
        this.kaForm.value.status = true;
        this.spinner.show();
        this.crudService.createEntity(this.kaForm.value).subscribe(
          () => {
            this.modalService.closeAll();
            this.toastr.success(
              `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
              `${this.translatorService.getTitle('CRUD_SUCCESS')} KA`
            );
            this.filter = { sort: 'ka' };
            this.loadData();
            this.spinner.hide();
            this.resetFormModalCreateEdit();
          },
          (err: any) => {
            this.toastr.error(err.error.message, err.error.title);
            this.spinner.hide();
          }
        );
      } else {
        // this.showInvalidsControlForm();

        this.toastr.warning('Mandatory Field', 'Color Pick')
        this.toastr.warning('Mandatory Field', 'KA')
      }
    }
  }

  putOrderAdminSalesManager() {
      let orderAdmin!: number | string | any;
      let salesManager!: number | string | any;

  
      if (this.kaForm.value.order_admin_id !== null) {
        this.orderAdminOptions.forEach((option: any) => {
          if (option.value === this.kaForm.value.order_admin_id) {
            orderAdmin = option.id;
          }
        })
      } else {
        orderAdmin = '';
      }
  
      if (this.kaForm.value.sales_manager_id !== null) {
        this.salesManagerOptions.forEach((option: any) => {
          if (option.value === this.kaForm.value.sales_manager_id) {
            salesManager = option.id;
          }
        })
      } else {
        salesManager = '';
      }
  
      const kaIds = {
        order_admin_id: orderAdmin ,
        sales_manager_id: salesManager,
      }
  
      if (this.kaForm.valid) {
        this.crudService.putEntity(`order-admin-sales-managers/ka/${this.kaForm.value.id}`, kaIds).subscribe(
          (response: any) => {
            if (response) {
              this.loadData();
            }
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
        })
      }
    }

  onUpdate() {
    const promises = [
      this.onUpdateKaEdit(), 
    ];
    this.hideSpinnerAfterAll(promises);
  }

  async onUpdateKaEdit(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        !this.validateValueSelectedAutocomplete() &&
        this.kaForm.value.countryId
      ) {
        this.toastr.warning(
          'Select the country in the list',
          'Country not found'
        );
      } else {
        const currentCountry = this.countriesEntity.filter((item: any) => {
          if (item.id === this.currentUser.countryId) return item.id;
        });
  
        this.kaForm.value.countryId = currentCountry[0]?.id;

        if (this.kaForm.status === 'INVALID' && this.kaForm.value.ka === 'RETAIL_BRA' && 
          (this.kaForm.value.order_admin_id === '' || this.kaForm.value.order_admin_id === null) && 
          (this.kaForm.value.sales_manager_id === '' || this.kaForm.value.sales_manager_id === null)) {

            if (this.previousStatusIsActive && !this.kaForm.value.status) {
              this.messageConfirmDialog = 'Do you confirm to inactivate this KA?';
            }  
            const dialog = this.modalService.createConfirm(this.dialogConfirmation);
            dialog.afterClosed().subscribe((result: any) => {
              if (result) {
                this.spinner.show();                
                this.kaForm.controls.products.setValue(
                  this.productsChangedStatus.map((item: any) => ({
                    materialId: item.materialId,
                    kaMgmtId: item.kaMgmtId,
                    status: item.status,
                  }))
                );
                this.kaForm.value.countryId = currentCountry[0].id;
                const kaValue = this.kaForm.value;
                delete kaValue.allocationBz;
                delete kaValue.salesModel;
                this.crudService
                  .updateEntity(this.kaForm.controls.id.value, kaValue)
                  .subscribe(
                    () => {
                      this.toastr.success(
                        `${this.translatorService.getMessage(
                          'CRUD_SUCCESS_UPDATED'
                        )}`,
                        `${this.translatorService.getTitle(
                          'CRUD_SUCCESS_UPDATED'
                        )} KA`
                      );

                      this.loadData();
    
                      this.modalService.closeAll();
                      this.resetFormModalCreateEdit();
                      this.productsChangedStatus = [];

                      resolve();
                    },
                    (err: any) => {
                      this.toastr.error(err.error.message, err.error.title);
                      this.spinner.hide();
                      reject()
                    }
                  );
              }
            });
          
        } else if (this.kaForm.status === 'INVALID' && this.kaForm.value.ka === '') {
          this.toastr.warning('Mandatory Field', 'KA')
        } else if (this.kaForm.valid) {
          if (this.previousStatusIsActive && !this.kaForm.value.status) {
            this.messageConfirmDialog = 'Do you confirm to inactivate this KA?';
          }  
          const dialog = this.modalService.createConfirm(this.dialogConfirmation);
          dialog.afterClosed().subscribe((result: any) => {
            if (result) {
              this.kaForm.controls.products.setValue(
                this.productsChangedStatus.map((item: any) => ({
                  materialId: item.materialId,
                  kaMgmtId: item.kaMgmtId,
                  status: item.status,
                }))
              );
              this.kaForm.value.countryId = currentCountry[0].id;
              const kaValue = this.kaForm.value;
              delete kaValue.allocationBz;
              delete kaValue.salesModel;
              this.crudService
                .updateEntity(this.kaForm.controls.id.value, kaValue)
                .subscribe(
                  () => {
                    this.toastr.success(
                      `${this.translatorService.getMessage(
                        'CRUD_SUCCESS_UPDATED'
                      )}`,
                      `${this.translatorService.getTitle(
                        'CRUD_SUCCESS_UPDATED'
                      )} KA`
                    );

                    this.putOrderAdminSalesManager();

                    this.modalService.closeAll();
                    this.resetFormModalCreateEdit();
                    this.productsChangedStatus = [];
                    resolve();
                  },
                  (err: any) => {
                    this.toastr.error(err.error.message, err.error.title);
                    this.spinner.hide();
                    reject()
                  }
                );
            }
          });
        } 
        
        if (this.kaForm.status === 'INVALID' && (this.kaForm.value.order_admin_id === '' || this.kaForm.value.order_admin_id === null) && this.kaForm.value.ka !== 'RETAIL_BRA' ) {
          this.toastr.warning('Mandatory Field', 'Order Admin')
        } 

        if (this.kaForm.status === 'INVALID' && (this.kaForm.value.sales_manager_id === '' || this.kaForm.value.sales_manager_id === null) && this.kaForm.value.ka !== 'RETAIL_BRA' ) {
          this.toastr.warning('Mandatory Field', 'Sales Manager')
        } 
      }
    })
  }

  validateValueSelectedAutocomplete() {
    const selectedId = Number(this.kaForm.value.id);
    return (
      this.countriesEntity.findIndex((item: any) => {
        return Number(item.id) === selectedId;
      }) !== -1
    );
  }

  resetFormModalCreateEdit() {
    this.kaForm.reset();
    this.kaForm.controls.colorHex.setValue('Select');
    this.kaForm.controls.allocationBz.enable();
    this.initialColor = '';
    this.colorPickerVisible = false;
    this.showInactivateLink = false;
    this.showActivateLink = false;
    this.productsPagination.page = 0;
    this.productsPagination.size = 10;
    this.colorSelected = false;
    this.messageConfirmDialog = 'Do you confirm the update of the records?';
  }

  showInvalidsControlForm() {
    Object.keys(this.kaForm.controls).forEach((key: string) =>
      this.kaForm.controls[key].markAsTouched()
    );
  }

  changeColor(event: ColorEvent) {
    this.kaForm.controls.colorHex.setValue(event.color.hex);
    this.initialColor = event.color.hex;
    this.colorPickerVisible = false;
    this.colorSelected = true;
  }

  toggleColorPicker() {
    this.colorPickerVisible = !this.colorPickerVisible;
  }

  closeColorPicker(event: any) {
    const clickTarget = event.relatedTarget?.parentElement.children[0];
    if (
      !`${clickTarget?.localName}.${clickTarget?.className}`.includes(
        'div.swatch'
      )
    ) {
      this.colorPickerVisible = false;
    }
  }

  activateProducts() {
    this.productsSelecteds.forEach((item: any) => {
      const indexProductChanged = this.productsChangedStatus.findIndex(
        (product: any) => product.materialId === item.materialId
      );
      if (indexProductChanged !== -1) {
        this.productsChangedStatus[indexProductChanged].status = true;
      } else {
        item.status = true;
        this.productsChangedStatus.push(item);
      }
    });

    this.productsInactivated = this.productsInactivated.filter(
      (item: any) =>
        this.productsSelecteds.findIndex(
          (product: any) => product.materialId === item.materialId
        ) === -1
    );

    this.dataTable.rows.filteredData
      .filter(
        (item: any) =>
          this.productsSelecteds.findIndex(
            (product: any) => product.materialId === item.materialId
          ) !== -1
      )
      .map((item: any) => {
        item.active = 'Active';
        return item;
      });
    this.dataTable = {
      columns: this.productsResult.table?.columns,
      rows: this.dataTable.rows,
      hasStatus: true,
    };
    this.showActivateLink = false;
  }

  inactivateProducts() {
    this.productsSelecteds.forEach((item: any) => {
      const indexProductChanged = this.productsChangedStatus.findIndex(
        (product: any) => product.materialId === item.materialId
      );
      if (indexProductChanged !== -1) {
        this.productsChangedStatus[indexProductChanged].status = false;
      } else {
        item.status = false;
        this.productsChangedStatus.push(item);
      }
      if (!this.productsInactivated.includes(item)) {
        this.productsInactivated.push(item);
      }
    });

    this.dataTable.rows.filteredData
      .filter(
        (item: any) =>
          this.productsSelecteds.findIndex(
            (product: any) => product.materialId === item.materialId
          ) !== -1
      )
      .map((item: any) => {
        item.active = 'Inactive';
        return item;
      });
    this.dataTable = {
      columns: this.productsResult.table?.columns,
      rows: this.dataTable.rows,
      hasStatus: true,
    };
    this.showInactivateLink = false;
  }

  backButton() {
    this.router.navigate(['home/ka-allocation']);
  }
}
