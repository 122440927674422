<div class="emea-allocation" #tableDynamic [ngClass]="zoomIn ? 'zoomIn' : ''">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let col of displayedDef; let indexCol = index">
      <ng-container [matColumnDef]="col.props">
        <div
          *ngIf="
            typeTable === 'order';
            then contentOrder;
            else contentTerritory
          "></div>
        <ng-template #contentOrder>
          <div>
            <th mat-header-cell *matHeaderCellDef>
              <mat-card
                [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
                [ngStyle]="col.style"
                [class.card-column-disable]="col.weekInTime === 'previous'"
                [class.card-column-actual-week]="
                  !col.title.includes('OPPORT') && col.weekInTime === 'current'
                ">
                <div>
                  <span [class.col-broken]="col.brokenHeaders">{{
                    showTitleHeader(col.title)
                  }}</span>
                </div>
                <ng-container *ngIf="col.typeShowEvent">
                  <div
                    *ngIf="
                      col.props === col.otherContent && !col.hideIconExpand
                    ">
                    <mat-icon
                      *ngIf="!col.expanded"
                      (click)="toggleColumnTable(col, 'show')">
                      {{ 'arrow_left' }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="col.expanded"
                      (click)="toggleColumnTable(col, 'hide')">
                      {{ 'arrow_right' }}
                    </mat-icon>
                  </div>
                </ng-container>
              </mat-card>
              <mat-card
                *ngIf="translateYAbsoluteItem > 0"
                [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                [style.transform]="
                  'translateY(' + translateYAbsoluteItem + 'px)'
                "
                class="absoluteItemOrder"
                [ngStyle]="col.style"
                [class.card-column-disable]="col.weekInTime === 'previous'"
                [class.card-column-actual-week]="
                  !col.title.includes('OPPORT') && col.weekInTime === 'current'
                ">
                <div>
                  <span [class.col-broken]="col.brokenHeaders">{{
                    showTitleHeader(col.title)
                  }}</span>
                </div>
                <ng-container *ngIf="col.typeShowEvent">
                  <div
                    *ngIf="
                      col.props === col.otherContent && !col.hideIconExpand
                    ">
                    <mat-icon
                      *ngIf="!col.expanded"
                      (click)="toggleColumnTable(col, 'show')">
                      {{ 'arrow_left' }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="col.expanded"
                      (click)="toggleColumnTable(col, 'hide')">
                      {{ 'arrow_right' }}
                    </mat-icon>
                  </div>
                </ng-container>
              </mat-card>
            </th>
          </div>
        </ng-template>
        <ng-template #contentTerritory>
          <div *ngIf="!col.props.includes('territory')">
            <th mat-header-cell *matHeaderCellDef>
              <mat-card
                [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
                [class.card-column-is-week]="col.weekInTime"
                [class.card-column-disable]="col.weekInTime === 'previous'"
                [class.card-column-actual-week]="
                  !col.title.includes('OPPORT') && col.weekInTime === 'current'
                "
                [class.col-last-headers]="col.lastHeaders"
                [class.col-is-ordersVsPdp]="col.props.includes('ordersVsPdp')">
                <div>
                  <span [class.col-total-week]="col.totalHeaders">{{
                    showTitleHeader(col.title)
                  }}</span>
                </div>
                <ng-container *ngIf="col.typeShowEvent">
                  <div
                    *ngIf="
                      col.props === col.otherContent && !col.hideIconExpand
                    ">
                    <mat-icon
                      *ngIf="!col.expanded"
                      (click)="toggleColumnTable(col, 'show')">
                      {{ 'arrow_left' }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="col.expanded"
                      (click)="toggleColumnTable(col, 'hide')">
                      {{ 'arrow_right' }}
                    </mat-icon>
                  </div>
                </ng-container>
              </mat-card>
              <mat-card
                *ngIf="translateYAbsoluteItem > 0"
                [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                [style.transform]="
                  'translateY(' + translateYAbsoluteItem + 'px)'
                "
                class="absoluteItem"
                [class.card-column-is-week]="col.weekInTime"
                [class.card-column-disable]="col.weekInTime === 'previous'"
                [class.card-column-actual-week]="
                  !col.title.includes('OPPORT') && col.weekInTime === 'current'
                "
                [class.col-last-headers]="col.lastHeaders"
                [class.col-is-ordersVsPdp]="col.props.includes('ordersVsPdp')">
                <div>
                  <span [class.col-total-week]="col.totalHeaders">{{
                    showTitleHeader(col.title)
                  }}</span>
                </div>
              </mat-card>
            </th>
          </div>
          <div *ngIf="col.props.includes('territory')">
            <th mat-header-cell *matHeaderCellDef>
              <mat-card
                class="card-territory"
                [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
                [class.card-column-disable]="col.weekInTime === 'previous'"
                [class.card-column-actual-week]="col.weekInTime === 'current'">
                <div>
                  <span>{{ col.title }}</span>
                </div>
              </mat-card>
              <mat-card
                class="card-territory absoluteItem"
                *ngIf="translateYAbsoluteItem > 0"
                [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                [style.transform]="
                  'translateY(' + translateYAbsoluteItem + 'px)'
                "
                [class.card-column-disable]="col.weekInTime === 'previous'"
                [class.card-column-actual-week]="
                  !col.title.includes('OPPORT') && col.weekInTime === 'current'
                ">
                <div>
                  <span>{{ col.title }}</span>
                </div>
              </mat-card>
            </th>
          </div>
        </ng-template>
        <td
          mat-cell
          *matCellDef="let element; let indexRow = index"
          [class.color-is-front-order]="element['isFromFront']?.value"
          [class.input-disable]="col.weekInTime === 'previous'">
          <div *ngIf="!col.props.includes('subTotal')">
            <div>
              <div
                *ngIf="
                  !element[col.props]?.enableInput &&
                  !element[col.props]?.enableInputText
                "
                [class.is-column-customer]="col.props.includes('customer')"
                [class.is-column-product]="col.props.includes('product')"
                [class.is-column-color]="col.props.includes('color')"
                [class.is-column-cust-po-number]="
                  col.props.includes('custPoNumber')
                "
                matTooltip="{{ element[col.props]?.value }}"
                [matTooltipPosition]="'right'">
                <app-value-currency
                  *ngIf="
                    col.props !== 'geoComments' && col.props !== 'salesComments'
                  "
                  [value]="
                    element[col.props]?.value === 0
                      ? '-'
                      : element[col.props]?.value
                  "
                  class="p-value-currency"
                  [class.negative-value]="
                    element[col.props]?.value < 0
                  "></app-value-currency>
              </div>
              <div *ngIf="element[col.props]?.enableInput">
                <input
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  class="input-number"
                  maxlength="9"
                  value="{{
                    element[col.props]?.value || '0'
                      | convertValue
                      | number : '1.0-0' : 'en-US'
                  }}"
                  autocomplete="off"
                  matInput
                  [readonly]="col.weekInTime === 'previous'"
                  [disabled]="col.weekInTime === 'previous'"
                  [class.negative-value]="element[col.props]?.value < 0"
                  [class.input-number-actual-week]="
                    !col.title.includes('OPPORT') &&
                    (col.isCurrentMoreTwo || col.weekInTime === 'current')
                  "
                  [class.input-number-disable]="col.weekInTime === 'previous'"
                  [class.input-number-next-month-week]="
                    col.weekInTime === 'next' &&
                    !col.title.includes('OPPORT') &&
                    !col.isCurrentMoreTwo
                  "
                  (keyup)="onChangeEvent({indexRow, columnName: col.props, row:
                    element, col }, $event)" />
              </div>
              <div *ngIf="element[col.props]?.enableInputText">
                <input
                  class="input-text"
                  value="{{ element[col.props]?.value }}"
                  autocomplete="off"
                  matInput
                  [class.input-number-actual-week]="
                    (!col.title.includes('OPPORT') && col.isCurrentMoreTwo) ||
                    col.weekInTime === 'current'
                  "
                  [class.input-number-disable]="col.weekInTime === 'previous'"
                  [class.input-number-next-month-week]="
                    col.weekInTime === 'next' &&
                    !col.props.includes('Opportunity') &&
                    !col.isCurrentMoreTwo
                  "
                  (keyup)="onChangeEvent({indexRow, columnName: col.props, row:
                    element, col }, $event)" />
              </div>
              <div
                class="button-edit"
                (click)="editEvent(element)"
                matTooltip="Edit"
                [matTooltipPosition]="'left'">
                <div
                  class="button-edit-icon"
                  *ngIf="
                    col.props === 'actionEdit' && element[col.props].enable
                  "></div>
              </div>
              <div
                class="button-delete"
                (click)="deleteEvent(element)"
                matTooltip="Delete"
                [matTooltipPosition]="'left'">
                <div
                  class="button-delete-icon"
                  *ngIf="
                    col.props === 'actionDelete' && element[col.props].enable
                  "></div>
              </div>
            </div>
          </div>
        </td>
        <td
          mat-footer-cell
          *matFooterCellDef="let element; let indexRow = index">
          <span
            *ngIf="col.props === 'country' || col.props === 'territory'"
            class="table-row-total-spacer"
            >TOTAL</span
          >
          <span
            [class.margin-values-total]="
              !col.isWeek && !col.actual && !col.totWow
            "
            [class.margin-value-of-actual-colapsed]="
              col.actual && !col.expanded
            "
            [class.margin-value-of-actual-expanded]="col.actual && col.expanded"
            [class.margin-values-of-tot-wow]="col.totWow && col.expanded">
            <app-value-currency
              [class.negative-value]="col.totalAddedValue < 0"
              [value]="
                col.totalAddedValue === '' ? '-' : col.totalAddedValue
              "></app-value-currency>
          </span>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let indexRow = index"
      (click)="handleHighlight(indexRow)"
      [class.brand]="indexRow % 2 !== 0"
      [ngClass]="{
        'row-highlight': rowHighlight.indexOf(indexRow) !== -1
      }"></tr>
    <tr
      mat-footer-row
      class="table-row-total"
      *matFooterRowDef="displayedColumns; sticky: true"></tr>
  </table>
</div>
