import {
  Component,
  OnInit,
  ElementRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { mockup } from './mockup';
import { MatTableDataSource } from '@angular/material/table';
import Pagination from 'src/app/interfaces/pagination.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UploadFileService } from 'src/app/services/generic/upload-file.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { DownloadFileService } from 'src/app/services/generic/download-file.service';
import * as FileSaver from 'file-saver';
import { CrudService } from 'src/app/services/generic/crud.service';

@Component({
  selector: 'app-ka-allocation-upload',
  templateUrl: './ka-allocation-upload.component.html',
  providers: [DatePipe],
  styleUrls: ['./ka-allocation-upload.component.scss'],
})
export class KaAllocationUploadComponent implements OnInit {
  @ViewChild('fileUpload') file!: ElementRef;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  fileName: any = '';
  showNoUpload: boolean = true;
  tableResultMock = mockup;
  tableResult: any = [];
  filter!: any;
  isModeTableAccordion: boolean = true;
  isModeTableOrCardsHide: boolean = true;
  pagination: Pagination;
  isInputKa: boolean = true;
  uploadFileService: UploadFileService<any>;
  downloadFileService: DownloadFileService<any> | undefined;
  isPermission: any;
  messageModal!: string;
  crudService: CrudService<any>;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private sharedInfoService: SharedinfoService,
    private toastr: ToastrService,
    private modalService: ModalActionsService,
    private datePipe: DatePipe
  ) {
    this.pagination = { page: 0, size: 10 };
    this.uploadFileService = new UploadFileService<any>(this.http);
    this.downloadFileService = new DownloadFileService<any>(this.http);
    this.crudService = new CrudService<any>(this.http, 'ka-allocation-upload/execution-logs');
    this.isPermission = this.authService.getUserPermissions();
  }

  ngOnInit(): void {
    this.getDataTable(this.filter);

    this.isInputKa = this.authService
      .getUserPermissions()
      .some(
        (data: any) =>
          data.resource === 'one-plan-upload' && data.permission === 'CREATE'
      );  
    this.sharedInfoService.emitChangeLocalStorage.subscribe(() => {
      this.isInputKa = this.authService
        .getUserPermissions()
        .some(
          (data: any) =>
            data.resource === 'one-plan-upload' && data.permission === 'CREATE'
        );
    });
  }

  getDataTable(page?: any, params?: any) {
    this.spinner.show();
    this.tableResult = [];

    this.crudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        if (response?.rows.length > 0) {
          this.updateComponents(response);
          this.showNoUpload = false;
          this.spinner.hide();
        } else {
          this.showNoUpload = true;
          this.spinner.hide();
        }
      },
      (err: any) => {
        this.showNoUpload = true;
        this.spinner.hide();
      }
    );

  }

  updateComponents(response: any) {
    this.tableResult = {
      cards: [],
      table: {
        columns: [
          {
            label: 'FILE NAME',
            sticky: true,
            prop: 'fileName',
            style: { width: '10%', 'box-sizing': 'border-box', 'text-align': 'center' },
          },
          {
            label: 'USER',
            sticky: true,
            prop: 'user',
            style: { width: '10%', 'box-sizing': 'border-box', 'text-align': 'center' },
          },
          {
            label: 'DATE',
            sticky: true,
            prop: 'date',
            style: { width: '10%', 'box-sizing': 'border-box', 'text-align': 'center' },
          },
          {
            label: 'STATUS',
            sticky: true,
            prop: 'status',
            style: { width: '10%', 'box-sizing': 'border-box', 'text-align': 'center' },
          },
        ],
        rows: new MatTableDataSource<any>(
          this.dataTables(JSON.stringify(response.rows))
        ),
      },
      pageIndex: response?.page || 0,
      pageSize: response?.totalRows || 0,
      pagesTotal: response?.totalPages || 0,
    };
  }

  dataTables(data: any) {
    let customer: any = [];

    customer = this.dataMapToTable(data);

    const dataTables: any[] = [];
    customer.forEach((item: any) => {
      dataTables.push(item);
    });

    return dataTables;
  }

  dataMapToTable(data: any): any[] {
    return JSON.parse(data).map((item: any) => ({
      fileName: item.filename,
      user: item.user.name,
      date: this.formatDate(item.date),
      status: item.status,
    }));
  }

  formatDate(date: any) {
    const data = date;
    return this.datePipe.transform(data, 'MM-dd-yyyy HH:mm:ss');
  }

  getCustomerNumbers(data: any) {
    let customerNumbers =
      data.length + `${data.length > 1 ? ' numbers' : ' number'}`;
    return customerNumbers;
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];

    array.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getDataTable(paginated, this.filter);
  }

  onFileSelected(event: any): void {
    const file: File = event.target?.files[0];
    if (file) {
      this.spinner.show();
      this.fileName = file.name;

      this.uploadFileService
        .uploadOnePlan('ka-allocation-upload/upload-allocations', file)
        .then((response: any) => {
          this.file.nativeElement.value = null;
          this.fileName = '';
          this.getDataTable(this.filter);
          this.showNoUpload = false;
          this.spinner.hide();
          this.toastr.success('File Upload Sucessfully', 'Success');
        })
        .catch((error: any) => {
          this.file.nativeElement.value = null;
          this.fileName = '';
          this.getDataTable(this.filter);
          this.showNoUpload = false;
          this.spinner.hide();
          this.toastr.error(error.error.message, 'Error');
        });
    }
  }

  public base64ToBlob(b64Data: string, sliceSize = 512) {
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  onFileDownload(): void {
    this.spinner.show();
    this.downloadFileService
      ?.downloadFile(
        'ka-allocation-upload/export-template',
        this.filter,
        'text'
      )
      .subscribe(
        (response: any) => {
          const file = this.base64ToBlob(response);

          // IE
          const nav = window.navigator as any;
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return;
          }
          FileSaver.saveAs(file, `template_allocations.xlsx`);
          this.spinner.hide();
          this.toastr.success('File Download Sucessfully', 'Success');
        },
        (err: any) => {
          const subtitleMessage =
            'An error occurred while generating the Excel file!';
          const titleMessage = 'Download Excel';
          this.spinner.hide();
          this.toastr.error(`${subtitleMessage}`, `${titleMessage}`);
        }
      );
  }

  backButton(): void {
    this.router.navigate(['home/ka-allocation/ka-allocation']);
  }
}
