import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  SimpleChanges,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/internal/Observable';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { ToastrService } from 'ngx-toastr';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import {
  InputBase,
  SelectSearchInput,
  MultiSelectSearchInput,
} from 'src/app/components/input/input-base';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { ExcelService } from '../../../services/generic/excel.service';
import { Geo } from 'src/app/interfaces/geo.interface';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { emeaAllocationMockup } from '../territory-allocation/emea-allocation-mockup';
import {
  emeaInformationAllocationMockup,
  emeaInformationAllocationQuarterMockup,
  tableQuarterMockup,
} from '../territory-allocation/table-mockup';
const INDEX_MIDDLE_TABLE = 1;
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { orderAllocationMockup } from './order-allocation-mockup';
import { orderAllocationTableMockup } from './order-allocation-table-mockup';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { orderAllocationCustomerMockup } from './order-allocation-customer-mockup';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { Material } from 'src/app/interfaces/material.interface';
import { ProductInterface } from 'src/app/interfaces/product.interface';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-order-allocation',
  templateUrl: './order-allocation.component.html',
  styleUrls: ['./order-allocation.component.scss'],
  animations: [
    trigger('hideShowTableCollapse', [
      state(
        'hide',
        style({
          height: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'show',
        style({
          'overflow-y': 'hidden',
        })
      ),
      transition('hide <=> show', [animate('0.4s 0.1s ease-out')]),
    ]),
  ],
})
export class OrderAllocationComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;

  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: TemplateRef<any> | any;

  @ViewChild('actionComments', { static: true })
  actionComments: TemplateRef<any> | any;
  @ViewChild('viewGeoComments', { static: true })
  viewGeoComments: TemplateRef<any> | any;

  @ViewChild('actionCommentsSales', { static: true })
  actionCommentsSales: TemplateRef<any> | any;
  @ViewChild('viewSalesComments', { static: true })
  viewSalesComments: TemplateRef<any> | any;

  @ViewChild('dialogConfirmation', { static: true })
  dialogConfirmation: TemplateRef<any> | any;

  hasPermissionCreate!: boolean;
  currentUser!: any;

  searchInputs!: InputBase<string>[];
  showNoResults = false;
  showNoResultsTableEcc = false;
  noFilterSelected = true;
  geosEntity: any[] = [];
  territoryEntity: any[] = [];
  countryEntities: any[] = [];
  customerEntities: any[] = [];
  countryModalList: any[] = [];
  productFilterEntity: ProductInterface[] = [];
  colorFilterEntity: any[] = [];
  memoryFilterEntity: any[] = [];
  simFilterEntity: any[] = [];
  customerFilterEntity: any[] = [];
  salesModelModalList: any[] = [];
  salesModalList: any[] = [];
  salesModelSelected: any;
  customerModalList: any[] = [];
  customersSelected: any;
  material: any[] = [];
  crudService: CrudService<any>;
  putPullInCrudService: any;
  pagination: Pagination;
  paginationMaterials: Pagination;
  filter: any = {};
  territorySelected!: string;
  summedValue: any[] = [];
  emptySalesModel: boolean = false;
  emptyCustomers: boolean = false;

  customerAllocationMockup = orderAllocationCustomerMockup;
  responseEmeaAllocationMockup = emeaAllocationMockup;
  responseOrderAllocationMockup = orderAllocationMockup;
  responseEmeaInformationAllocationMockup = emeaInformationAllocationMockup;
  responseEmeaInformationAllocationQuarterMockup =
    emeaInformationAllocationQuarterMockup;
  responseQuarterTable = tableQuarterMockup;
  responseTableOrderAllocation = orderAllocationTableMockup;
  eventTable!: string;
  pullInOrIncreaseValues: { label: string; mode: boolean; value: number }[] =
    [];
  showTableCollapse: boolean = false;
  dataTableInformation!: any;
  resultOrderAllocation: any;
  newTerritory!: any;
  filterMonthsSelected: string = 'quarter';
  filterMonths: any[] = [];
  next: boolean = false;
  isPullIn: boolean = true;
  isEditTableMode: boolean = false;
  isDiscardChanges: boolean = false;
  confirmEditComments: boolean = false;
  isComment: boolean = false;
  messageModalChanges!: string;

  userForm: FormGroup;
  isEdit: boolean = false;
  filteredOptions!: Observable<any[]>;
  filteredOptionss!: Observable<any[]>;
  productFilter: any[] = [];
  exportResult!: any;

  geos: Geo[] = [];
  geo: any;
  productGroup: any;
  territory: any;
  color: any;
  ram: any;
  sim: any;
  colorFilter: any = [];
  memoryFilter: any = [];
  simFilter: any = [];
  EventItemId: any = [];
  geosCommentsValue: any = [];
  customer: any;
  fullyShipped: { key: string; value: string }[] = [
    { key: 'yes', value: 'Yes' },
    { key: 'no', value: 'No' },
    { key: 'ALL', value: 'All' },
  ];
  tooltipMessage: String = 'Export';
  isValid: any = false;
  fieldsValid: any[] = [];

  dataInfoSourceTable: any[] = [];
  tableResult!: any;
  headerTable: any = [];
  rowsTable: any = [];
  headerInfoTable: any = [];
  rowsInfoTable: any = [];

  valuesSubstraction: { col: string; value: number }[] = [];
  valuesDelta: { col: string; value: number }[] = [];
  productId: any;
  putCrudService: any;
  putCrudServiceEcc: any;
  product_color_id: any;
  enableApplyFilter = false;
  enableButtonNewOrder = false;
  keysFilters = ['territory'];
  filterProductSelected: any = '--';

  formSearchValues!: { [key: string]: any };
  toggleColumnTableValues: any[] = [];
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.orderAllocation },
    },
    {
      entity: 'country',
      query: {
        sort: 'country',
        distinct: 'country',
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      },
    },
    {
      entity: 'location',
      query: {
        status: true,
        sort: 'territory',
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      },
    },
    {
      entity: 'emea-allocation/summary/product',
      query: { pageName: PAGE_NAME_RESOURCE.orderAllocation },
    },
  ];

  filtersEntities: Array<getEntities> = [
    {
      entity: 'emea-allocation/summary/color',
      query: { pageName: PAGE_NAME_RESOURCE.orderAllocation },
    },
    {
      entity: 'emea-allocation/summary/memory',
      query: { pageName: PAGE_NAME_RESOURCE.orderAllocation },
    },
    {
      entity: 'emea-allocation/summary/sim',
      query: { pageName: PAGE_NAME_RESOURCE.orderAllocation },
    },
  ];

  protected onDestroy$ = new Subject();
  public customerMultiCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public customerMultiFilterCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredCustomerMulti: ReplaySubject<any> = new ReplaySubject(1);

  public salesModelMultiCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public salesModelMultiFilterCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );

  public geoCommentsMultiCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public geoCommentsMultiFilterCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredGeoCommentsMulti: ReplaySubject<any> = new ReplaySubject(1);

  public filteredSalesModelMulti: ReplaySubject<any> = new ReplaySubject(1);

  messages: {
    [key: string]: string;
  } = {
    save: 'Do you confirm order allocation data updated?',
    discard: 'Are you sure you want to exit before saving your changes?',
    noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
  };

  // evento da tabela
  event = {
    view: 'view',
    edit: 'edit',
  };
  orderForm: FormGroup;
  rowHighlight: number[] = [];

  constructor(
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private authService: AuthService,
    private modalService: ModalActionsService,
    private validateFormService: ValidateFormService,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private excelService: ExcelService,
    private translatorService: TranslatorService
  ) {
    this.pagination = { page: 0, size: 10 };
    this.paginationMaterials = { page: 0, size: 200 };
    this.crudService = new CrudService<Material>(this.http, 'order-allocation');
    this.putCrudService = new CrudService<any>(
      this.http,
      'emea-order-allocation/save'
    );
    this.putPullInCrudService = new CrudService<any>(
      this.http,
      'emea-order-allocation/one-plan/opportunity'
    );
    this.putCrudServiceEcc = new CrudService<any>(
      this.http,
      'emea-order-allocation'
    );

    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('order-allocation');
    });
    this.userForm = this.formBuilder.group({
      id: [''],
      territory: { value: '', disabled: true },
      countryId: ['', Validators.required],
      salesModel: ['', Validators.required],
      color: { value: '', disabled: true },
      memory: { value: '', disabled: true },
      product: { value: '', disabled: true },
      productId: [''],
      sim: { value: '', disabled: true },
      customer: ['', Validators.required],
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      quarter: this.getQuarterFromDate(new Date()),
    };
    this.filter = {
      ...this.filter,
      fiscalYear: this.checkCurrentYearAndQuarter(),
      geoId: this.currentUser.geoId,
      pageName: PAGE_NAME_RESOURCE.orderAllocation,
    };
    this.searchInputs = this.createSearchInputs();

    this.orderForm = this.formBuilder.group({
      id: [''],
      geoComments: [''],
      salesComments: [''],
    });
  }

  ngOnInit(): void {
    this.loadData(this.filterMonthsSelected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTableInformation?.currentValue) {
      this.dataTableInformation = changes.dataTableInformation.currentValue;
    }
    if (changes.tableResult?.currentValue) {
      this.tableResult = changes.tableResult.currentValue;
      this.exportComponents();
    }
  }

  loadData(filter?: string) {
    this.getDataFilter(this.entities);
    this.getQuarter(new Date());
  }

  loadInitialValues() {
    this.initialTableValues();
    this.initialInfoDeltaValues();
    this.updateInfoTableValues();
  }

  getData(filter?: string) {
    this.getInformationAllocation(this.filterMonthsSelected, filter);
  }

  verifyPermissionEdit(data: any, type?: string) {
    const userPermission = this.authService.getUserPermissions();
    const canEditAllocation = userPermission.some(
      (permission: any) =>
        permission.resource === 'order-allocation' &&
        (permission.permission === 'UPDATE' ||
          permission.permission === 'CREATE')
    );

    if (!canEditAllocation) {
      const currentMonthOrWeek = (header: any) =>
        header.currentMonth || header.weekInTime || header.props === 'sab';
      if (type === 'info') {
        data?.info?.headers.forEach((header: any) => {
          data?.info.rows.forEach((row: any) => {
            if (currentMonthOrWeek(header)) {
              if (row[header.props].enableInput)
                row[header.props].enableInput = false;
            }
          });
        });
      } else {
        data?.expandedTable?.headers.forEach((header: any) => {
          data?.expandedTable.rows.forEach((row: any) => {
            if (currentMonthOrWeek(header)) {
              if (row[header.props].enableInput)
                row[header.props].enableInput = false;
            }
          });
        });
      }
    }
  }

  getInformationAllocation(filter?: string, params?: any) {
    this.spinner.show();
    this.toggleColumnTableValues = [];
    this.dataTableInformation = [];
    this.crudService
      .getEntity(`emea-order-allocation/info`, params)
      .subscribe(response => {
        this.verifyPermissionEdit(response, 'info');
        Object.entries(response).forEach((response: any) => {
          if (filter === 'quarter') {
            this.headerInfoTable = response[1].headers;
          } else {
            this.headerInfoTable = response[1].headers.filter(
              (item: any) => item.props.includes(filter) || !item.weekInTime
            );
          }

          this.rowsInfoTable = response[1]?.rows;

          this.updateComponentsInfo(this.headerInfoTable, this.rowsInfoTable);
          this.showNoResults = false;
          this.dataTableEcc(this.filterMonthsSelected, params);
        }),
          (err: any) => {
            this.toastr.error(err.error.message, 'Error!');
            this.showNoResults = true;
            this.spinner.hide();
          };
      });
  }

  getLastPart = (str: string) => {
    const parts = str.split('/');
    return parts[parts.length - 1].trim();
  };

  dataTableEcc(filter?: string, params?: any) {
    this.spinner.show();
    this.tableResult = [];
    this.crudService.getEntity(`emea-order-allocation/ecc`, params).subscribe(
      async (response: any) => {
        if (response?.expandedTable?.rows?.length > 0) {
          this.verifyPermissionEdit(response);
          if (filter === 'quarter') {
            this.headerTable = response?.expandedTable.headers;
          } else {
            this.headerTable = response?.expandedTable.headers.filter(
              (item: any) => item.props.includes(filter) || !item.weekInTime
            );
          }

          this.rowsTable = response?.expandedTable.rows;

          this.rowsTable.forEach((row: any) => {
            if (row?.custPoNumber.value.includes('/')) {
              const lastString = this.getLastPart(row?.custPoNumber.value);
              if (row.twoTierCustomer.value === '') {
                row.twoTierCustomer.value = lastString;
              }
            }
          });

          this.addTypeShowEvent(response?.expandedTable.headers);
          await this.tableEccColumnsAndRows(this.headerTable, this.rowsTable);
          this.loadInitialValues();
          this.addSubtotalValues(this.tableResult.table);
          this.exportComponents();
          this.enableApplyFilter = true;
          this.showNoResultsTableEcc = false;
          this.spinner.hide();
        } else {
          this.showNoResultsTableEcc = true;
          this.spinner.hide();
        }
        this.showNoResults = false;
      },
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.showNoResults = true;
        this.spinner.hide();
      }
    );
  }

  async tableEccColumnsAndRows(columns: any, rows: any): Promise<void> {
    const actions = this.permissionService.setTablePermissions('user');
    const tableRows = rows;

    tableRows.forEach((row: any) => {
      if (row.actionEdit && row.actionEdit.status)
        row.actionEdit.enable = actions.edit + row.actionEdit.status;
      if (row.actionDelete && row.actionDelete.status)
        row.actionDelete.enable = actions.delete + row.actionEdit.status;
    });

    this.tableResult = {
      table: {
        columns: columns,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(tableRows))
        ),
      },
    };
  }

  updateComponentsInfo(headers: any, rows: any) {
    this.dataInfoSourceTable = [];

    this.territoryEntity.forEach((item: any) => {
      if (item.territory == this.formSearchValues.territory) {
        this.countryModalList = [];
        item.countries.forEach((countries: any) => {
          this.countryModalList.push(countries);
        });
      }
    });

    this.dataTableInformation = {
      table: {
        columns: headers,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(rows))
        ),
      },
    };
  }

  filterOptionsCountries(): void {
    this.countryModalList = [];

    this.crudService
      .getEntity(
        `emea-order-allocation/summary/countries?territory_id=${this.formSearchValues.territory}`,
        { pageName: PAGE_NAME_RESOURCE.orderAllocation }
      )
      .subscribe((response: any) => {
        response.forEach((item: any) => {
          this.countryModalList.push({ country: item.country, id: item.id });
        });
      });
  }

  filterOptionsCustomers(country_id: number): void {
    this.customerModalList = [];

    this.crudService
      .getEntity(
        `emea-order-allocation/summary/customers?sort=customer_name&country_id=${country_id}`,
        { pageName: PAGE_NAME_RESOURCE.orderAllocation }
      )
      .subscribe((response: any) => {
        response.forEach((item: any) => {
          this.customerModalList.push(item);
        });

        this.orderFilter(this.customerModalList);
        this.emptyCustomers = !this.customerModalList.length;
      });
  }

  filterOptionsSalesModel(country_id: number): void {
    this.salesModelModalList = [];

    this.crudService
      .getEntity(
        `emea-order-allocation/summary/salesmodels?country_id=${country_id}`,
        { pageName: PAGE_NAME_RESOURCE.orderAllocation }
      )
      .subscribe((response: any) => {
        response.forEach((item: any) => {
          this.salesModelModalList.push(item);
        });
        this.emptySalesModel = !this.salesModelModalList.length;
      });
  }

  addTypeShowEvent(response: any) {
    this.eventTable = 'hide-columns-rows';

    response.forEach((header: any) => {
      if (header.props === 'country') {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });
  }

  addSubtotalValues(response: any) {
    let headerName: any = [];
    this.summedValue = [];

    response?.columns
      .filter((item: any) => item.hasSubtotal)
      .forEach((header: any) => {
        headerName = header.props;

        response?.rows.filteredData
          .map((item: any) => {
            if (item[headerName]) {
              return item[headerName].value;
            } else {
              return 0;
            }
          })
          .reduce((acc: any, item: any) => {
            if (acc !== undefined && item !== undefined) {
              return (this.summedValue = acc + item);
            }
          }, 0);
        header.totalAddedValue = this.summedValue;
      });
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];
    array.forEach((item: any) => {
      dataTable.push(item);
    });

    return [...dataTable];
  }

  toggleTable(event: string) {
    this.eventTable = event;
  }

  onEventActions(event: any) {
    this.EventItemId = event?.data?.order_id;

    if (event.type === 'editGeoComments') {
      this.isComment = true;
      this.prepareToViewCommentsGeo(this.EventItemId);
    }

    if (event.type === 'editSalesComments') {
      this.isComment = true;
      this.prepareToViewSalesComments(this.EventItemId);
    }

    if (event.type === 'viewGeoComments') {
      this.isComment = true;
      this.viewCommentsGeo(this.EventItemId);
    }

    if (event.type === 'viewSalesComments') {
      this.isComment = true;
      this.viewCommentsSales(this.EventItemId);
    }

    if (event.type === 'delete') {
      this.onDeleteOrder(event.item);
    }

    if (event.type === 'edit') {
      this.prepareToEdit(event.item);
    }
  }

  verifyIfEditToConfirm() {
    if (this.isComment) {
      this.confirmEditComments = true;
    }
  }

  prepareToViewCommentsGeo(id: any) {
    this.isEdit = true;
    this.spinner.show();
    this.crudService
      .getEntity(`emea-order-allocation/${id}`, {
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      })
      .subscribe((item: any) => {
        this.orderForm.controls.id.setValue(item.id);
        this.orderForm.controls.geoComments.setValue(item.geoComments);
        this.orderForm.controls.salesComments.setValue(item.salesComments);

        const modal = this.modalService.createModal(this.actionComments);
        modal.afterClosed().subscribe(() => {
          this.isComment = false;
          this.orderForm.reset();
        });
        this.spinner.hide();
      });
  }

  viewCommentsGeo(id: any) {
    this.isEdit = true;
    this.spinner.show();
    this.crudService
      .getEntity(`emea-order-allocation/${id}`, {
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      })
      .subscribe((item: any) => {
        this.orderForm.controls.id.setValue(item.id);
        this.orderForm.controls.geoComments.setValue(item.geoComments);
        this.orderForm.controls.salesComments.setValue(item.salesComments);

        const modal = this.modalService.createConfirm(this.viewGeoComments);
        modal.afterClosed().subscribe(() => {
          this.orderForm.reset();
        });
        this.spinner.hide();
      });
  }

  prepareToViewSalesComments(id: any) {
    this.isEdit = true;
    this.spinner.show();
    this.crudService
      .getEntity(`emea-order-allocation/${id}`, {
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      })
      .subscribe((item: any) => {
        this.orderForm.controls.id.setValue(item.id);
        this.orderForm.controls.geoComments.setValue(item.geoComments);
        this.orderForm.controls.salesComments.setValue(item.salesComments);

        const modal = this.modalService.createModal(this.actionCommentsSales);
        modal.afterClosed().subscribe(() => {
          this.orderForm.reset();
        });
        this.spinner.hide();
      });
  }

  viewCommentsSales(id: any) {
    this.isEdit = true;
    this.spinner.show();
    this.crudService
      .getEntity(`emea-order-allocation/${id}`, {
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      })
      .subscribe((item: any) => {
        this.orderForm.controls.id.setValue(item.id);
        this.orderForm.controls.salesComments.setValue(item.salesComments);

        const modal = this.modalService.createConfirm(this.viewSalesComments);
        modal.afterClosed().subscribe(() => {
          this.orderForm.reset();
        });
        this.spinner.hide();
      });
  }

  messageToastSuccessUpdate() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Order`
    );
  }

  messageToastErrorUpdate() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS')} Order`
    );
  }

  messageToastRemoved() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Order`
    );
  }

  onUpdateComments() {
    this.isEdit = true;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      this.spinner.show();
      const orderId = this.orderForm.value;
      if (this.confirmEditComments) {
        this.resultUpdateOrder(orderId);
        this.confirmEditComments = false;
      }
      this.spinner.hide();
    });
  }

  onDeleteOrder(data: any) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.putCrudServiceEcc.deleteEntity(data.order_id).subscribe(
          () => {
            this.messageToastRemoved();
            this.spinner.hide();
            this.getData(this.filter);
          },
          (err: any) => {
            this.toastr.error(err.error.message, err.error.title);
            this.spinner.hide();
          }
        );
      }
    });
  }

  prepareToCreate() {
    this.spinner.show();
    this.isEdit = false;
    this.salesModelModalList = [];
    this.customerModalList = [];
    if (this.formSearchValues.territory == '') {
      this.countryModalList = [];
      this.salesModelModalList = [];
      this.customerModalList = [];
      this.userForm.controls.salesModel.setValue('');
      this.userForm.controls.customer.setValue('');
    }
    this.filterOptionsCountries();

    this.customerMultiCtrl.setValue(null);
    this.filteredCustomerMulti.next(this.customerModalList.slice());
    this.customerMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerMulti();
      });

    this.salesModelMultiCtrl.setValue(null);
    this.filteredSalesModelMulti.next(this.salesModelModalList.slice());
    this.salesModelMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterSalesModelMulti();
      });

    this.userForm.controls.territory.setValue(this.territorySelected);
    const createModal = this.modalService.createModal(this.modalCreateEdit);
    createModal.afterClosed().subscribe(() => {
      this.resetFormModal();
    });
    this.spinner.hide();
  }

  prepareToEdit(data: any) {
    this.spinner.show();
    this.isEdit = true;
    this.filterOptionsCountries();
    this.salesModelModalList = [];
    this.customerModalList = [];

    this.salesModelMultiCtrl.setValue(null);
    this.filteredSalesModelMulti.next(this.salesModelModalList.slice());
    this.salesModelMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterSalesModelMulti();
      });

    this.customerMultiCtrl.setValue(null);
    this.filteredCustomerMulti.next(this.customerModalList.slice());
    this.customerMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerMulti();
      });

    this.putCrudServiceEcc.fetchEntity(data.order_id).subscribe(
      (order: any) => {
        this.crudService
          .getEntity(
            `emea-order-allocation/summary/salesmodels?country_id=${order.countryId}`,
            { pageName: PAGE_NAME_RESOURCE.orderAllocation }
          )
          .subscribe(
            (response: any) => {
              response.forEach((item: any) => {
                this.salesModelModalList.push(item);
                this.filteredSalesModelMulti.next(this.salesModelModalList);
              });
              this.crudService
                .getEntity(
                  `emea-order-allocation/summary/customers?sort=customer_name&country_id=${order.countryId}`,
                  { pageName: PAGE_NAME_RESOURCE.orderAllocation }
                )
                .subscribe((response: any) => {
                  response.forEach((item: any) => {
                    this.customerModalList.push(item);
                  });
                  this.orderFilter(this.customerModalList);
                  this.filteredCustomerMulti.next(this.customerModalList);

                  this.customerMultiCtrl.setValue(order.customer);
                  this.userForm.controls.customer.setValue(order.customer);
                  this.userForm.controls.id.setValue(order.id);
                  this.salesModelMultiCtrl.setValue(order.salesModel);
                  this.userForm.controls.salesModel.setValue(order.salesModel);
                  this.userForm.controls.territory.setValue(
                    this.territorySelected
                  );
                  this.userForm.controls.countryId.setValue(order.countryId);
                  this.userForm.controls.product.setValue(order.product_group);
                  this.userForm.controls.productId.setValue(order.productId);
                  this.userForm.controls.color.setValue(order.color_desc);
                  this.userForm.controls.memory.setValue(
                    `${order.ram}/${order.rom}`
                  );
                  this.userForm.controls.sim.setValue(order.sim);

                  const createModal = this.modalService.createModal(
                    this.modalCreateEdit
                  );
                  createModal.afterClosed().subscribe(() => {
                    this.resetFormModal();
                  });
                  this.spinner.hide();
                });
            },
            (err: any) => {
              this.toastr.error(err.error.message, err.error.title);
              this.spinner.hide();
            }
          );
      },
      (err: any) => {
        this.toastr.error(err.error.message, err.error.title);
        this.spinner.hide();
      }
    );
  }

  resultUpdateOrder(idValue: any) {
    if (idValue.id) {
      this.putCrudServiceEcc.updateEntity(idValue.id, idValue).subscribe(
        () => {
          this.messageToastSuccessUpdate();
          this.modalService.closeAll();
          this.isEditTableMode = false;
          this.tableResult;
          this.getData(this.filter);
        },
        (err: any) => {
          this.toastr.error(err.error.message, err.error.title);
          this.spinner.hide();
        }
      );
    } else {
      delete idValue.id;
      this.crudService.createEntity(idValue).subscribe(
        () => {
          this.messageToastErrorUpdate();
          this.modalService.closeAll();
          this.isEditTableMode = false;
          this.orderForm.reset();
          this.getData(this.filter);
        },
        (err: any) => {
          this.toastr.error(err.error.message, err.error.title);
          this.spinner.hide();
        }
      );
    }
    this.spinner.hide();
  }

  updateComponents(response: any) {
    this.dataInfoSourceTable = [];

    Object.entries(response).forEach((response: any) => {
      this.dataInfoSourceTable.push(response[INDEX_MIDDLE_TABLE]);
    });

    this.newTerritory = this.dataInfoSourceTable[0].rows;

    const tableInfo = this.newTerritory.filter((item: any) => {
      return item.columnName.value != 'One Plan';
    });

    this.dataTableInformation = {
      table: {
        columns: this.dataInfoSourceTable[0].headers,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(tableInfo))
        ),
      },
    };
  }

  exportComponents() {
    this.exportResult = {
      tableInfo: {
        columns: this.dataTableInformation?.table?.columns,
        rows: this.dataTableInformation?.table.rows.filteredData,
      },
      tableAllocation: {
        columns: this.tableResult?.table?.columns,
        rows: this.tableResult?.table?.rows.filteredData,
      },
    };
    this.resultOrderAllocation = this.exportResult;
  }

  //create filters
  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'territory',
        hover: 'Territory',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'product_group',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'color',
        hover: 'Color',
        hasAutocomplete: true,
        disabled: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'ram',
        hover: 'Memory',
        hasAutocomplete: true,
        disabled: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'sim',
        hover: 'SIM',
        hasAutocomplete: true,
        disabled: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'customer',
        hover: 'Customer',
        type: 'text',
        classCss: 'order-allocation-filters',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'fully_shipped',
        hover: 'Fully Shipped',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];
  }

  async getDataFilter(filters: any) {
    const geosFilter: any[] = [];
    this.productFilter = [];
    const territoryFilter: any[] = [];
    const initialValueGeoFilter: { [key: string]: any } = {};
    this.spinner.show();

    const selectTerritory = new SelectSearchInput({
      key: 'territory',
      hover: 'Territory',
      type: 'text',
      hasAutocomplete: true,
      options: territoryFilter,
      notClearable: true,
      hasCheckFlowException: true,
    });

    const selectFullyShipped = new SelectSearchInput({
      key: 'fully_shipped',
      hover: 'Fully Shipped',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: this.fullyShipped,
      value: this.fullyShipped[1].value,
      notClearable: true,
    });

    filters.forEach((item: any) => {
      item.query['geo_id'] = [this.currentUser.geoId];
    });
    // quando não for mais necessario o uso dos dados mocados
    this.crudService
      .getDataFilters(this.entities)
      .subscribe((response: any[]) => {
        [
          this.geosEntity,
          this.countryEntities,
          this.territoryEntity,
          this.productFilterEntity,
          /* this.productFilterEntity, this.colorFilterEntity, this.memoryFilterEntity, this.simFilterEntity*/
        ] = response;

        this.geosEntity.forEach((item: Geo) => {
          geosFilter.push({ value: item.geo, id: item.id });

          if (item.id === this.currentUser.geoId) {
            initialValueGeoFilter.value = item.geo;
            initialValueGeoFilter.id = item.id;
          }
        });
        this.territoryEntity.forEach((item: any) => {
          territoryFilter.push({ value: item.territory });
        });
        const selectGeo = new SelectSearchInput({
          key: 'geo_id',
          hover: 'GEO',
          type: 'text',
          hasAutocomplete: false,
          options: geosFilter,
          disabled: true,
          value: initialValueGeoFilter.value,
          notClearable: true,
        });

        this.productFilterEntity.forEach((item: any) => {
          this.productFilter.push({ value: item.product_group, id: item.id });
        });

        const selectProduct = new SelectSearchInput({
          key: 'product_group',
          hover: 'Product',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: this.productFilter,
          notClearable: true,
        });

        this.searchInputs[0] = selectGeo;
        this.searchInputs[1] = selectTerritory;
        this.searchInputs[2] = selectProduct;
        this.searchInputs[7] = selectFullyShipped;
        this.searchInputs = [...this.searchInputs];
        this.spinner.hide();
      });
  }

  prepareExportTable(data: any) {
    this.spinner.show();
    const tableInfoRows = data.tableInfo.rows;

    const tableInfoColumns = data.tableInfo.columns.map((item: any) => ({
      title: item.title,
      props: item.props,
    }));
    tableInfoColumns.splice(1, 0, { title: 'Country', props: 'country' });

    const tableAllocationRows = data.tableAllocation.rows;
    const tableAllocationColumns = data.tableAllocation.columns.map(
      (item: any) => ({
        title: item.title,
        props: item.props,
        totalAddedValue: item.totalAddedValue,
      })
    );

    const tableRows: { [key: string]: any }[] = [];

    for (let i = 0; i < tableInfoRows.length; i++) {
      const tableRow: { [key: string]: any } = {};
      tableInfoColumns.forEach((column: any) => {
        if (column.title.includes('TOTAL'))
          column.title = `${this.filter.quarter} TOTAL`;
        if (column.title.includes('#')) column.title = this.territorySelected;
        if (tableInfoRows[i][column.props]) {
          tableRow[column.props] = tableInfoRows[i][column.props]
            ? tableInfoRows[i][column.props].value
            : '';
        } else {
          tableRow[column.props] = tableInfoRows[i][column.props]
            ? tableInfoRows[i][column.props].value
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    for (let i = 0; i < tableAllocationRows.length; i++) {
      const tableRow: { [key: string]: any } = {};
      tableAllocationColumns.forEach((column: any) => {
        if (column.title.includes('TOTAL'))
          column.title = `${this.filter.quarter} TOTAL`;
        if (tableAllocationRows[i][column.props]) {
          tableRow[column.props] = tableAllocationRows[i][column.props].value;
        } else {
          tableRow[column.props] = tableAllocationRows[i][column.props].value;
        }
      });
      tableRows.push(tableRow);
    }

    const tableRow: { [key: string]: any } = {};
    tableAllocationColumns.forEach((column: any) => {
      if (column.title.includes('COUNTRY')) {
        tableRow[column.props] = 'TOTAL';
      } else {
        tableRow[column.props] = column.totalAddedValue
          ? column.totalAddedValue
          : '-';
      }
    });
    tableRows.push(tableRow);

    const tableColumns = [...tableInfoColumns, ...tableAllocationColumns];

    return { columns: tableColumns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.resultOrderAllocation);
    this.excelService.exportJsonAsExcelFile(fullJsonExport, 'order_allocation');
    this.spinner.hide();
  }

  applyFilter() {
    const formSearchValues = { ...this.formSearchValues };

    delete formSearchValues.geoId;
    delete formSearchValues.action;
    delete formSearchValues.nameFormControl;
    delete formSearchValues.action;
    delete formSearchValues.formControl;
    delete formSearchValues.event;
    delete formSearchValues.panelTrigger;
    delete formSearchValues.geo_id;
    this.formSearchValues.customer = this.formSearchValues.customer
      ? this.formSearchValues.customer
      : delete formSearchValues.customer;
    this.formSearchValues.ram = this.formSearchValues.ram
      ? this.formSearchValues.ram
      : delete formSearchValues.ram;
    this.formSearchValues.sim = this.formSearchValues.sim
      ? this.formSearchValues.sim
      : delete formSearchValues.sim;
    this.formSearchValues.color = this.formSearchValues.color
      ? this.formSearchValues.color
      : delete formSearchValues.color;

    this.noFilterSelected = false;

    this.filter = {
      ...this.filter,
      ...formSearchValues,
    };

    this.checkDiscardAllocation('applyFilter');
  }

  checkDiscardAllocation(sourceMethod?: string) {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.discardChanges();
          if (sourceMethod === 'applyFilter') {
            let idInputTerritory = document.querySelectorAll('input')[0].id;
            let idInputProduct = document.querySelectorAll('input')[1].id;

            let inputElementTerritory: any | HTMLInputElement = '';
            let inputElementProduct: any | HTMLInputElement = '';

            inputElementTerritory = document.getElementById(idInputTerritory);
            inputElementProduct = document.getElementById(idInputProduct);

            inputElementProduct.blur();
            inputElementTerritory.blur();

            this.getData(this.filter);
          }
        }
      });
    } else {
      this.discardChanges();
      if (sourceMethod === 'applyFilter') {
        let idInputTerritory = document.querySelectorAll('input')[0].id;
        let idInputProduct = document.querySelectorAll('input')[1].id;

        let inputElementTerritory: any | HTMLInputElement = '';
        let inputElementProduct: any | HTMLInputElement = '';

        inputElementTerritory = document.getElementById(idInputTerritory);
        inputElementProduct = document.getElementById(idInputProduct);

        inputElementProduct.blur();
        inputElementTerritory.blur();

        this.getData(this.filter);
      }
    }
  }

  dataFilters() {
    function orderFilter(data: any) {
      data.sort(function (x: any, y: any) {
        let a = x.value.toUpperCase(),
          b = y.value.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });
    }

    this.memoryFilter = [];
    this.colorFilter = [];
    this.simFilter = [];
    this.searchInputs[4].disabled = true;
    this.searchInputs[4].options = [];
    this.searchInputs[5].disabled = true;
    this.searchInputs[5].options = [];
    this.searchInputs = [...this.searchInputs];

    const queryParams: any = {
      query: {
        product_group: this.formSearchValues.product_group,
        pageName: PAGE_NAME_RESOURCE.orderAllocation,
      },
    };

    this.filtersEntities.forEach((item: any) => {
      Object.assign(item, queryParams);
    });

    this.crudService
      .getDataFilters(this.filtersEntities)
      .subscribe((response: any[]) => {
        [
          this.colorFilterEntity,
          this.memoryFilterEntity,
          this.simFilterEntity,
        ] = response;

        this.colorFilterEntity.forEach((item: any) => {
          this.colorFilter.push({
            value: item.color,
            id: item.product_color_id,
          });
        });
        orderFilter(this.colorFilter);

        const selectColor = new SelectSearchInput({
          key: 'color',
          hover: 'Color',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: this.colorFilter,
          disabled: false,
        });

        this.memoryFilterEntity.forEach((item: any) => {
          this.memoryFilter.push({
            value: `${item.ram}/${item.rom}`,
            id: item.product_memory_id,
          });
        });

        orderFilter(this.memoryFilter);

        const selectMemory = new SelectSearchInput({
          key: 'ram',
          hover: 'Memory',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: this.memoryFilter,
          disabled: false,
        });

        this.simFilterEntity.forEach((item: any) => {
          this.simFilter.push({
            value: item.sim,
          });
        });

        const selectSim = new SelectSearchInput({
          key: 'sim',
          hover: 'SIM',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: this.simFilter,
          disabled: false,
        });

        this.searchInputs[3] = selectColor;
        this.searchInputs[3].disabled = false;
        this.searchInputs[4] = selectMemory;
        this.searchInputs[4].disabled = false;
        this.searchInputs[5] = selectSim;
        this.searchInputs[5].disabled = false;
        this.searchInputs = [...this.searchInputs];
      });
  }

  clearTablesResult() {
    this.noFilterSelected = true;
    this.tableResult = [];
    this.dataTableInformation = [];
  }

  orderFilter(data: any) {
    data.sort(function (x: any, y: any) {
      let a = x.customer_name.toUpperCase(),
        b = y.customer_name.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    });
  }

  onSearchEvent(value: any) {
    this.formSearchValues = { ...value };

    if (this.formSearchValues.product_group) {
      this.filterProductSelected = this.formSearchValues.product_group;
    }

    if (
      this.formSearchValues.action == 'clear' &&
      !this.formSearchValues.product_group
    ) {
      if (this.isEditTableMode) {
        this.isDiscardChanges = true;
        const dialog = this.getDialogModal('discard');

        dialog.afterClosed().subscribe((result: any) => {
          if (result === 'discard') {
            this.discardChanges();
            this.formSearchValues.product_group = '';
            this.clearTablesResult;
          } else {
            const selectProduct = new SelectSearchInput({
              key: 'product_group',
              hover: 'Product',
              hasAutocomplete: true,
              hasCheckFlowException: true,
              options: this.productFilter,
              value: this.filterProductSelected,
            });
            this.searchInputs[2] = selectProduct;
            this.searchInputs = [...this.searchInputs];
          }
        });
      } else {
        this.discardChanges();
        this.clearTablesResult();
      }
    }

    this.dataFormSearchColor(this.formSearchValues);
    this.dataFormSearchRam(this.formSearchValues);
    this.dataFormSearchGeo(this.formSearchValues);
    this.dataFormSearchTerritory(this.formSearchValues);

    if (
      this.formSearchValues.territory
      && !this.formSearchValues.customer.length
      || this.formSearchValues.nameFormControl == 'territory'
    ) {
      const customerFilter: any[] = [];

      this.crudService
        .getEntity(
          `emea-order-allocation/summary/customers?sort=customer_name&territory_id=${this.formSearchValues.territory}`,
          { pageName: PAGE_NAME_RESOURCE.orderAllocation }
        )
        .subscribe((response: any) => {
          if (response.length > 0) {
            this.customerFilterEntity = response;

            this.orderFilter(this.customerFilterEntity);

            this.customerFilterEntity.forEach((item: any) => {
              customerFilter.push({ value: item.customer_name, id: item.id });
            });

            const selectCustomer = new MultiSelectSearchInput({
              key: 'customer',
              hover: 'Customer',
              type: 'text',
              classCss: 'order-allocation-filters',
              hasAutocomplete: true,
              hasCheckFlowException: true,
              options: customerFilter,
              disabled: false,
            });

            this.searchInputs[6] = selectCustomer;
            this.searchInputs = [...this.searchInputs];
          }
        });
    }

    if (this.formSearchValues.nameFormControl == 'product_group') {
      this.dataFilters();
    }

    this.formSearchValues.product_group = this.formSearchValues.product_group
      ? this.formSearchValues.product_group
      : '';
    this.formSearchValues.fully_shipped = this.formSearchValues.fully_shipped
      ? this.formSearchValues.fully_shipped
      : '';
    this.formSearchValues.color = this.formSearchValues.color
      ? this.formSearchValues.color
      : '';
    this.filter.color = this.filter.color ? this.formSearchValues.color : '';
    this.formSearchValues.ram = this.formSearchValues.ram
      ? this.formSearchValues.ram
      : '';
    this.filter.ram = this.filter.ram ? this.formSearchValues.ram : '';
    this.formSearchValues.sim = this.formSearchValues.sim
      ? this.formSearchValues.sim
      : '';
    this.filter.sim = this.filter.sim ? this.formSearchValues.sim : '';

    this.filter.customer = this.filter.customer
      ? this.formSearchValues.customer
      : '';

    if (this.formSearchValues.customer) {
      let currentCustomer: string = '';
      if (this.formSearchValues.customer.length > 0) {
        this.formSearchValues.customer.forEach((row: any) => {
          currentCustomer = `${row.value}*-*${currentCustomer}`;
        });
        this.formSearchValues.customer = currentCustomer;
      }
    } else {
      this.formSearchValues.customer = '';
    }

    this.pagination.page = 0;

    this.fieldsValid = [
      (this.geo = this.filter.geoId),
      (this.territory = this.formSearchValues.territory),
      (this.productGroup = this.formSearchValues.product_group),
    ];
    this.isValid = this.fieldsValid.every(item => {
      return item != '';
    });

    if (this.isValid) {
      this.applyFilter();
    }
  }

  dataFormSearchColor(formSearchValues: any) {
    if (formSearchValues.color) {
      const currentColor = this.colorFilterEntity.filter((item: any) =>
        item.color
          .toLocaleUpperCase()
          .includes(formSearchValues.color.toLocaleUpperCase())
      );
      formSearchValues.color = currentColor[0]
        ? currentColor[0].product_color_id
        : 0;
    } else {
      formSearchValues.color = '';
    }
  }

  dataFormSearchRam(formSearchValues: any) {
    if (formSearchValues.ram) {
      const currentMemory = this.memoryFilter.filter((item: any) =>
        item.value
          .toLocaleUpperCase()
          .includes(formSearchValues.ram.toLocaleUpperCase())
      );
      formSearchValues.ram = currentMemory[0] ? currentMemory[0].id : 0;
    } else {
      formSearchValues.ram = '';
    }
  }

  dataFormSearchGeo(formSearchValues: any) {
    if (formSearchValues.geo_id) {
      const currentGeo = this.geosEntity.filter((item: any) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchValues.geo_id.toLocaleUpperCase())
      );
      formSearchValues.geo_id = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchValues.geo_id = '';
    }
  }

  dataFormSearchTerritory(formSearchValues: any) {
    if (formSearchValues.territory) {
      this.enableButtonNewOrder = true;

      const currentTerritory = this.territoryEntity.filter((item: any) =>
        item.territory
          .toLocaleUpperCase()
          .includes(formSearchValues.territory.toLocaleUpperCase())
      );

      this.territorySelected = currentTerritory[0]
        ? currentTerritory[0].territory
        : '';
      formSearchValues.territory = currentTerritory[0]
        ? currentTerritory[0].id
        : 0;
    } else {
      formSearchValues.territory = '';
      this.enableButtonNewOrder = false;
    }
  }

  protected filterSalesModelMulti() {
    if (!this.salesModelModalList) {
      return;
    }

    let search = this.salesModelMultiFilterCtrl.value;
    if (!search) {
      this.filteredSalesModelMulti.next(this.salesModelModalList.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredSalesModelMulti.next(
      this.salesModelModalList.filter(item =>
        item.sales_model.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCustomerMulti() {
    if (!this.customerModalList) {
      return;
    }

    let search = this.customerMultiFilterCtrl.value;
    if (!search) {
      this.filteredCustomerMulti.next(this.customerModalList.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredCustomerMulti.next(
      this.customerModalList.filter(item =>
        item.customer_name.toLowerCase().startsWith(search)
      )
    );
  }

  toggleBreadcrumb(toDisable: boolean) {
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/emea-order-allocation', {
      disable: toDisable,
    });
  }

  handleHighlight(indexRow: any) {
    const index = this.rowHighlight.indexOf(indexRow);
    if (index === -1) {
      this.rowHighlight.push(indexRow);
    } else {
      this.rowHighlight.splice(index, 1);
    }
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  nextQuarter(next: boolean) {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.discardChanges();
          this.next = !this.next;
          this.getQuarter(new Date());
          this.filter.fiscalYear = this.checkQuarterAndYear();
          this.resetPullIn();
          this.getData(this.filter);
          this.isEditTableMode = false;
        }
      });
    } else {
      this.next = !this.next;
      this.getQuarter(new Date());
      this.filter.fiscalYear = this.checkQuarterAndYear();
      this.resetPullIn();
      this.getData(this.filter);
      this.isEditTableMode = false;
    }
  }

  getMonthFilter(date: Date) {
    let monthName = date.toLocaleString('en-US', { month: 'long' });
    return monthName.toLowerCase();
  }

  resetPullIn() {
    this.pullInOrIncreaseValues.forEach((key: any) => {
      key.mode = true;
    });
  }

  checkCurrentYearAndQuarter() {
    if (!this.filter.fiscalYear && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  checkQuarterAndYear() {
    if (!this.next && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarterFromDate(date: Date) {
    let month = date.getMonth() + 1;
    this.next === true
      ? (month = date.getMonth() + 4)
      : (month = date.getMonth() + 1);
    if (month >= 4 && month <= 6) return 'FQ1';
    if (month >= 7 && month <= 9) return 'FQ2';
    if (month >= 10 && month <= 12) return 'FQ3';
    return 'FQ4';
  }

  handleFilterButton(event: any) {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.filterMonthsSelected = event.value;
          this.getData(this.filter);
          this.isEditTableMode = false;
        }
      });
    } else {
      this.filterMonthsSelected = event.value;
      this.getData(this.filter);
      this.isEditTableMode = false;
    }
  }

  getQuarter(date: Date) {
    let month = date.getMonth() + 1;
    if (this.next) month = date.getMonth() + 4;
    this.filter.quarter = this.getQuarterFromDate(new Date());

    this.filterMonths = [];

    switch (month) {
      case 4:
      case 5:
      case 6:
        this.filterMonths.push(
          { label: 'April', value: 'april' },
          { label: 'May', value: 'may' },
          { label: 'June', value: 'june' }
        );
        break;
      case 7:
      case 8:
      case 9:
        this.filterMonths.push(
          { label: 'July', value: 'july' },
          { label: 'August', value: 'august' },
          { label: 'September', value: 'september' }
        );
        break;
      case 10:
      case 11:
      case 12:
        this.filterMonths.push(
          { label: 'October', value: 'october' },
          { label: 'November', value: 'november' },
          { label: 'December', value: 'december' }
        );
        break;
      default:
        this.filterMonths.push(
          { label: 'January', value: 'january' },
          { label: 'February', value: 'february' },
          { label: 'March', value: 'march' }
        );
        break;
    }
    this.filterMonths.push({
      label: 'Quarter',
      value: 'quarter',
    });
  }

  inputChanged(item: any) {
    this.isEditTableMode = true;

    if (
      item.columnNameWeek !== 'twoTierCustomer' &&
      item.columnNameWeek !== 'geoComments' &&
      item.columnNameWeek !== 'salesComments'
    ) {
      this.updateTableValues(item);
    }
    this.toggleBreadcrumb(true);
  }

  /* Função que realiza o cálculo inicial dos valores de Delta */
  initialInfoDeltaValues() {
    let acumulativeDelta: any = 0;
    this.valuesSubstraction = [];
    this.valuesDelta = [];

    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    // Adiconando o sub total Delta do quarter na tabela de info
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        const isColumnOpportunity = column.props.includes('Opportunity');
        if (!isColumnOpportunity) {
          if (row[0] === column.props) {
            acumulativeDelta =
              parseFloat(acumulativeDelta) + parseFloat(row[1].value);
            const hasColumnInValuesDelta = this.valuesDelta.filter(
              (v: any) => v.col == row[0]
            );
            if (!hasColumnInValuesDelta.length)
              this.valuesDelta.push({ col: row[0], value: acumulativeDelta });
            this.valuesDelta.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(acumulativeDelta);
              }
            });

            const hasColumnInValuesSubstraction =
              this.valuesSubstraction.filter((v: any) => v.col == row[0]);
            if (!hasColumnInValuesSubstraction.length)
              this.valuesSubstraction.push({ col: row[0], value: 0 });
            this.valuesSubstraction.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(row[1].value);
              }
            });
          }
        } else {
          if (row[0] === column.props) {
            const hasColumnInValuesSubstraction =
              this.valuesSubstraction.filter((v: any) => v.col == row[0]);
            if (!hasColumnInValuesSubstraction.length)
              this.valuesSubstraction.push({ col: row[0], value: 0 });
            this.valuesSubstraction.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(row[1].value);
              }
            });
          }
        }
      });
    });

    // Realizar a subtração do One Plan com Allocation, e adiciona o resultado em um array
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((onePlan: any) => {
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[2]
      ).forEach((allocation: any) => {
        this.valuesSubstraction.forEach((key: any) => {
          if (onePlan[0] === allocation[0]) {
            if (key.col === onePlan[0]) {
              key.value =
                parseFloat(onePlan[1].value) - parseFloat(allocation[1].value);
            }
          }
        });
      });
    });

    let acumulativeDeltaWeek: any = 0;

    // Acumulando os valores de delta
    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        const isColumnOpportunity = column.props.includes('Opportunity');
        if (!isColumnOpportunity) {
          if (row.col === column.props) {
            acumulativeDeltaWeek =
              parseFloat(acumulativeDeltaWeek) + parseFloat(row.value);
            this.valuesDelta.forEach((key: any) => {
              if (key.col === row.col) {
                key.col = row.col;
                key.value = parseFloat(acumulativeDeltaWeek);
              }
            });
          }
        }
      });
    });

    // Adicionando os valores acumulados de delta na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      this.valuesDelta.forEach((key: any) => {
        if (row.columnName.value === 'DELTA')
          row[key.col].value = parseFloat(key.value);
      });
    });

    // Adiciona o valor de PullIn ou Increase no array dos valores subtraídos
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      this.valuesSubstraction.forEach((key: any) => {
        if (key.col === row[0]) {
          const isColumnOpportunity = key.col.includes('Opportunity');
          if (isColumnOpportunity) {
            row[1].value = key.value;
          }
        }
      });
    });

    // Adiciona os valores acumulados de delta e coluna de Opportunity na coluna de TOTAL
    let acumulativeDeltaWeekAndOpportunity: any = 0;

    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row.col === column.props) {
          acumulativeDeltaWeekAndOpportunity =
            parseFloat(acumulativeDeltaWeekAndOpportunity) +
            parseFloat(row.value);
        }
      });
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
      if (totalColumn)
        row[1].value = parseFloat(acumulativeDeltaWeekAndOpportunity);
    });

    const filteredRows = this.dataTableInformation.table.rows.filteredData[0];

    for (const [key, value] of Object.entries(filteredRows)) {
      if (typeof key === 'string' && key.includes('fqTotal')) {
        const rowValue = value as { value: string };
        rowValue.value = '';
      }
    }
  }

  initialTableValues() {
    for (
      let i = 0;
      i < this.tableResult?.table?.rows?.filteredData.length;
      i++
    ) {
      let subTotalQuarter: any = 0;

      this.tableResult?.table?.columns?.forEach((column: any) => {
        Object.entries(this.tableResult.table?.rows.filteredData[i]).forEach(
          (row: any) => {
            if (row[1].enableInput) {
              if (row[0] === column.props) {
                subTotalQuarter += parseFloat(row[1].value);
              }
            }
            const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
            if (totalColumn) row[1].value = subTotalQuarter;
          }
        );
      });

      // Soma de toda a coluna de wk da tabela de inputs
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[2]
      ).forEach((value: any) => {
        Object.entries(this.tableResult?.table?.rows.filteredData[i]).forEach(
          (row: any) => {
            if (value[0] == row[0] && row[1].isWeek) {
              value[1].value += parseFloat(row[1].value);
            }
          }
        );
      });
    }
  }

  updateInfoTableValues(value?: any) {
    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    // Adiconando o sub total One Plan na tabela de info
    let subTotalOnePlan: any = 0;
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row[0] === column.props)
          subTotalOnePlan =
            parseFloat(subTotalOnePlan) + parseFloat(row[1].value);
      });
    });
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((row: any) => {
      const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
      if (totalColumn) row[1].value = parseFloat(subTotalOnePlan);
    });

    // Adiconando o sub total Allocation na tabela de info
    let subTotalAllocation: any = 0;

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[2]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row[0] === column.props) {
          subTotalAllocation =
            parseFloat(subTotalAllocation) + parseFloat(row[1].value);
        }
      });
    });
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[2]
    ).forEach((row: any) => {
      const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
      if (totalColumn) row[1].value = parseFloat(subTotalAllocation);
    });
  }

  updateTableValues(item: any) {
    let subTotalWeek: any = 0;
    let subTotalDelta: any = 0;
    let subTotalQuarter: any = 0;
    let acumulativeDeltaWeek: any = 0;
    let acumulativeDeltaWeekAndOpportunity: any = 0;

    // Soma de toda a coluna de wk da tabela de inputs
    this.tableResult.table?.rows.filteredData.forEach((row: any) => {
      const value = parseFloat(row[item.columnNameWeek].value);
      if (!isNaN(value)) {
        subTotalWeek += value;
      }
    });

    // Adiconando o sub total do quarter na tabela de inputs
    this.tableResult?.table?.columns?.forEach((column: any) => {
      Object.entries(
        this.tableResult.table?.rows.filteredData[item.indexRow]
      ).forEach((row: any) => {
        if (row[1].enableInput) {
          if (row[0] == column.props) {
            subTotalQuarter += parseFloat(row[1].value);
          }
        }
        const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
        if (totalColumn) row[1].value = subTotalQuarter;
      });
    });

    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    // Adicionando sub totais na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value.includes('ORDER ALLOCATION TOTAL')) {
        row[item.columnNameWeek].value = subTotalWeek;
      }
      if (row.columnName.value.includes(this.territorySelected))
        subTotalDelta =
          parseFloat(row[item.columnNameWeek]?.value) -
          parseFloat(subTotalWeek);
      if (row.columnName.value === 'DELTA') {
        row[item.columnNameWeek].value = parseFloat(subTotalDelta);

        // Subtraindo os valores de One Plan e Allocation
        const hasColumn = this.valuesSubstraction.filter(
          (v: any) => v.col == item.columnNameWeek
        );
        if (!hasColumn.length)
          this.valuesSubstraction.push({
            col: item.columnNameWeek,
            value: subTotalDelta,
          });
        this.valuesSubstraction.forEach((key: any) => {
          if (key.col == item.columnNameWeek) {
            key.col = item.columnNameWeek;
            key.value = parseFloat(subTotalDelta);
          }
        });
      }
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((onePlan: any) => {
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[2]
      ).forEach((allocation: any) => {
        this.valuesSubstraction.forEach((key: any) => {
          if (onePlan[0] === allocation[0]) {
            const isColumnOpportunity = key.col.includes('Opportunity');
            if (!isColumnOpportunity) {
              if (key.col === onePlan[0]) {
                let indexArrayWk = onePlan[0].split('wk');
                let indexSelectedWk = item.columnNameWeek.split('wk');
                if (
                  indexArrayWk[1] < indexSelectedWk[1] ||
                  indexArrayWk[1] > indexSelectedWk[1]
                ) {
                  key.value =
                    parseFloat(onePlan[1].value) -
                    parseFloat(allocation[1].value);
                }
              }
            }
          }
        });
      });
    });

    const columnNameWeekIsOpportunity =
      item.columnNameWeek.includes('Opportunity');
    if (!columnNameWeekIsOpportunity) {
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[1]
      ).forEach((row: any) => {
        columnsInfoNamesWeeks?.forEach((column: any) => {
          const isColumnOpportunity = column.props.includes('Opportunity');
          if (!isColumnOpportunity) {
            if (row[0] === column.props) {
              // subTotalDelta = parseFloat(acumulativeDelta) + parseFloat(row[1].value);
              const hasColumn = this.valuesDelta.filter(
                (v: any) => v.col == row[0]
              );
              if (!hasColumn.length)
                this.valuesDelta.push({ col: row[0], value: subTotalDelta });
              this.valuesDelta.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(subTotalDelta);
                }
              });
            }
          }
        });
      });

      // Acumulando os valores de delta
      this.valuesSubstraction.forEach((row: any) => {
        columnsInfoNamesWeeks?.forEach((column: any) => {
          const isColumnOpportunity = column.props.includes('Opportunity');
          if (!isColumnOpportunity) {
            if (row.col === column.props) {
              acumulativeDeltaWeek =
                parseFloat(acumulativeDeltaWeek) + parseFloat(row.value);
              this.valuesDelta.forEach((key: any) => {
                if (key.col === row.col) {
                  key.col = row.col;
                  key.value = parseFloat(acumulativeDeltaWeek);
                }
              });
            }
          }
        });
      });
    }

    // Adicionando os valores acumulados de delta na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      this.valuesDelta.forEach((key: any) => {
        if (row.columnName.value === 'DELTA')
          row[key.col].value = parseFloat(key.value);
      });
    });

    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row.col === column.props) {
          acumulativeDeltaWeekAndOpportunity =
            parseFloat(acumulativeDeltaWeekAndOpportunity) +
            parseFloat(row.value);
        }
      });
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
      if (totalColumn)
        row[1].value = parseFloat(acumulativeDeltaWeekAndOpportunity);
    });

    this.updateInfoTableValues();
  }

  getFiscalYear() {
    if (this.next && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() + 1;
    return new Date().getFullYear();
  }

  onSave() {
    const dialog = this.getDialogModal('save');

    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'save') {
        this.spinner.show();

        const newDataToSave: {
          productGroup: number;
          orderId: number;
          fiscalYear: number;
          fiscalQuarter: string;
          splitWeek: string;
          month: string;
          value: any;
        }[] = [];

        for (
          let i = 0;
          i < this.tableResult.table?.rows.filteredData.length;
          i++
        ) {
          Object.entries(this.tableResult.table?.rows.filteredData[i]).map(
            (row: any) => {
              if (row[0] === 'twoTierCustomer' && row[1].edit) {
                const orderId: number = row[1]?.order_id;
                const twoTierCustomer: string = row[1]?.value;
                const fiscalYear: number = row[1]?.fiscal_year;
                const fiscalQuarter: string = row[1]?.fiscal_quarter;
                const dataSave = {
                  id: orderId,
                  twoTierCustomer: twoTierCustomer,
                  fiscalYear: fiscalYear,
                  fiscalQuarter: fiscalQuarter,
                };
                this.resultUpdateOrder(dataSave);
              } else if (row[0] === 'geoComments' && row[1].edit) {
                const orderId: number = row[1]?.order_id;
                const geoComments: string = row[1]?.value;
                const dataSave = {
                  id: orderId,
                  geoComments,
                };
                this.resultUpdateOrder(dataSave);
              } else if (row[0] === 'salesComments' && row[1].edit) {
                const orderId: number = row[1]?.order_id;
                const salesComments: string = row[1]?.value;
                const dataSave = {
                  id: orderId,
                  salesComments,
                };
                this.resultUpdateOrder(dataSave);
              } else if (row[0] !== 'twoTierCustomer' && row[1].edit) {
                if (row[0]) {
                  let monthName;
                  let weekNumber;
                  const orderId: number = row[1]?.order_id;
                  const fiscalYear: number = row[1]?.fiscal_year;
                  const fiscalQuarter: string = row[1]?.fiscal_quarter;

                  if (row[0].includes('Opportunity')) {
                    weekNumber = 'OPPORTUNITY';
                    const splitName = row[0].split('Opportunity');
                    monthName = splitName[0];
                  } else {
                    const splitName = row[0].split('wk');
                    monthName = splitName[0];
                    weekNumber =
                      splitName[1] === undefined
                        ? monthName
                        : `W${splitName[1]}`;
                  }

                  newDataToSave.push({
                    productGroup: this.filterProductSelected,
                    orderId: orderId,
                    fiscalYear: fiscalYear,
                    fiscalQuarter: fiscalQuarter,
                    splitWeek: weekNumber,
                    month: monthName,
                    value:
                      typeof row[1].value === 'string'
                        ? Number(row[1].value.replaceAll(',', ''))
                        : row[1].value,
                  });
                }
              }
            }
          );
        }

        /* PUT VALUES */
        if (newDataToSave.length > 0) {
          this.putCrudService.updateEntity('', newDataToSave).subscribe(
            () => {
              const subtitleMessage = 'Updated successfully!';
              const titleMessage = 'Order Allocation';

              this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
              this.discardChanges();
              this.modalService.closeAll();
              this.spinner.hide();
              this.getData(this.filter);
            },
            (err: any) => {
              this.spinner.hide();
              this.toastr.error(err.error.message, 'Error!');
            }
          );
        }
      }
    });
  }

  toggleTableCollapse(table: boolean) {
    this.showTableCollapse = !this.showTableCollapse;
  }

  toggleColumnTable({ col, event }: any) {
    this.toggleColumnTableValues = [];
    this.toggleColumnTableValues.push(col, event);
  }

  onSavePullInOrIncreaseValue(item: any, type: string) {
    this.spinner.show();
    let newDataToSave: any = {};

    const splitName = item.props.split('Opportunity');
    const monthName =
      splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1);

    newDataToSave = {
      locationId: this.formSearchValues.territory,
      productGroup: this.filter.product_group,
      fiscalYear: this.filter.fiscalYear,
      month: monthName,
      type: type,
      value:
        typeof item.value === 'string'
          ? Number(item.value.replaceAll(',', ''))
          : item.value,
    };

    /* PUT VALUES */
    this.putPullInCrudService.updateEntity('', newDataToSave).subscribe(
      () => {
        const subtitleMessage = 'Updated successfully!';
        const titleMessage = 'Order Allocation';
        this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
        this.getData(this.filter);
        this.modalService.closeAll();
        this.pullInOrIncreaseValues.forEach((key: any) => {
          key.edit = false;
        });
      },
      (err: any) => {
        this.getData(this.filter);
        this.modalService.closeAll();
        this.pullInOrIncreaseValues.forEach((key: any) => {
          key.edit = false;
        });
        const subtitleMessage =
          'The value reported does not have enough quantity in the next month.';
        const titleMessage = 'Pull In';
        this.toastr.warning(`${subtitleMessage}`, `${titleMessage}`, {
          timeOut: 20000,
        });
      }
    );
  }

  valuePullInOrIncrease({ item, event, values }: any) {
    this.pullInOrIncreaseValues = values;
    this.handleGetCalcules(item);
  }

  changedPullInOrIncrease({ item, event, values }: any) {
    this.pullInOrIncreaseValues = values;
    this.handleGetCalcules(item);
  }

  handleGetCalcules(item: any) {
    this.pullInOrIncreaseValues.forEach((key: any) => {
      if (key.edit)
        key.mode ? this.getCalculePullIn(key) : this.getCalculeIncrease(key);
    });
  }

  getCalculeIncrease(item: any) {
    let increaseValue = parseFloat(item.value);
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value.includes('TERRITORY ALLOCATION')) {
        row[item.props].value = increaseValue;
      }
    });
    this.updateInfoTableValues(increaseValue);
    // this.getUpdateDelta(item);
    this.onSavePullInOrIncreaseValue(item, 'INCREASE');
  }

  getCalculePullIn(item: any) {
    this.onSavePullInOrIncreaseValue(item, 'PULLIN');
  }

  resetCalculeIncrease(item: any) {
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value.includes('TERRITORY ALLOCATION')) {
        row[item.label].value = 0;
      }
    });
    this.updateInfoTableValues();
  }

  /* Função que atualiza os valores de Delta e TOTAL na tabela de Info*/
  getUpdateDelta(item: any) {
    let subTotalWeek: any = 0;
    let subTotalDelta: any = 0;
    let acumulativeDeltaWeekAndOpportunity: any = 0;

    // Soma de toda a coluna de wk da tabela de inputs
    this.tableResult.table?.rows.filteredData.forEach((row: any) => {
      subTotalWeek += parseFloat(row[item.label].value);
    });

    // Adicionando sub totais na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value.includes('TERRITORY ALLOCATION')) {
        subTotalDelta =
          parseFloat(row[item.label].value) - parseFloat(subTotalWeek);
      }
      if (row.columnName.value === 'DELTA') {
        row[item.label].value = parseFloat(subTotalDelta);

        // Subtraindo os valores de One Plan e Allocation
        const hasColumn = this.valuesSubstraction.filter(
          (v: any) => v.col == item.label
        );
        if (!hasColumn.length)
          this.valuesSubstraction.push({
            col: item.label,
            value: subTotalDelta,
          });
        this.valuesSubstraction.forEach((key: any) => {
          if (key.col == item.label) {
            key.col = item.label;
            key.value = parseFloat(subTotalDelta);
          }
        });
      }
    });

    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row.col === column.props) {
          acumulativeDeltaWeekAndOpportunity =
            parseFloat(acumulativeDeltaWeekAndOpportunity) +
            parseFloat(row.value);
        }
      });
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      const totalColumn = row[0].toLocaleUpperCase().includes('TOTAL');
      if (totalColumn)
        row[1].value = parseFloat(acumulativeDeltaWeekAndOpportunity);
    });
  }

  onChangeCountry(value: any) {
    // this.salesModelModalList = [];
    this.salesModelMultiCtrl.setValue(null);
    if (value != null) {
      this.filterOptionsSalesModel(value);
      this.filterOptionsCustomers(value);
      this.filteredSalesModelMulti.next(this.salesModelModalList);
      this.filteredCustomerMulti.next(this.customerModalList);
    }
  }

  onChangeSalesModel(value: any) {
    this.salesModelSelected = '';
    this.salesModelModalList.forEach((item: any) => {
      if (value == item.sales_model) {
        this.salesModelSelected = item.sales_model;
        this.userForm.controls.productId.setValue(item.product_id);
        this.userForm.controls.product.setValue(item.product_group);
        this.userForm.controls.color.setValue(item.color_desc);
        this.userForm.controls.memory.setValue(`${item.ram}/${item.rom}`);
        this.userForm.controls.sim.setValue(item.sim);
      }
    });
    this.userForm.controls.salesModel.setValue(this.salesModelSelected);
  }

  OnChangeCustomer(value: any) {
    this.customersSelected = value;
    this.userForm.controls.customer.setValue(this.customersSelected);
  }

  retrieveDataFromAutocompleteModal() {
    this.userForm.value.countryId = this.userForm.controls.countryId.value;
    this.userForm.value.salesModel = this.userForm.controls.salesModel.value;
    this.userForm.value.customer = this.userForm.controls.customer.value;
  }

  resetFormModal() {
    this.userForm.reset();
  }

  messageToastSuccessUpdated() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Order`
    );
  }

  messageToastSuccessCreated() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS')} Order`
    );
  }

  messageErrorToast(err: any) {
    this.toastr.error(err.error.message, err.error.title);
  }

  onUpdateOrder() {
    this.userForm.value.productId = this.userForm.controls.productId.value;

    this.isEdit = true;
    if (this.validateFormService.onValidateFields(this.userForm)) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();

          this.putCrudServiceEcc
            .updateEntity(this.userForm.controls.id.value, this.userForm.value)
            .subscribe(
              () => {
                this.messageToastSuccessUpdated();
                this.modalService.closeAll();
                this.resetFormModal();
                this.spinner.hide();
                this.getData(this.filter);
              },
              (err: any) => {
                this.messageErrorToast(err);
                this.spinner.hide();
              }
            );
        }
      });
    }
  }

  onCreateOrder() {
    this.userForm.value.locationId = this.formSearchValues.territory;

    if (this.validateFormService.onValidateFields(this.userForm)) {
      this.spinner.show();
      delete this.userForm.value.id;

      this.putCrudServiceEcc.createEntity(this.userForm.value).subscribe(
        () => {
          this.messageToastSuccessCreated();
          this.modalService.closeAll();
          this.resetFormModal();
          this.spinner.hide();
          this.getData(this.filter);
        },
        (err: any) => {
          this.messageErrorToast(err);
          this.spinner.hide();
        }
      );
    }
  }

  onCancel() {
    this.checkDiscardAllocation('applyFilter');
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate(['home/emea-allocation']);
        }
      });
    } else {
      this.router.navigate(['home/emea-allocation']);
    }
  }
}
