export const tableLastMonthMockup: any = {
  lastMonthTable: {
    headers: [
      {
        title: 'PRODUCT',
        props: 'product',
        alwaysVisible: true,
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        alwaysVisible: true,
      },
      // EXPANSÃO BOH TOTAL
      {
        title: 'BOH TOTAL',
        props: 'bohTotal',
        alwaysVisible: true,
      },
      {
        title: 'BUFFER',
        props: 'buffer',
        alwaysVisible: true,
      },
      {
        title: 'BOH FLEX',
        props: 'bohFlex',
        otherContentTotal: true, // para expandir a partir do TOTAL
        alwaysVisible: false,
      },
      {
        title: 'BOH MOTO',
        props: 'bohMoto',
        otherContentTotal: true,
        alwaysVisible: false,
      },
      {
        title: 'SAB',
        props: 'sab',
        otherContentTotal: true,
        alwaysVisible: false,
      },
      {
        title: 'BP',
        props: 'bp',
        otherContentTotal: true,
        alwaysVisible: false,
        bp: true,
      },

      // WEEKS:
      {
        title: 'WK 01',
        props: 'wk1',
        weekInTime: 'previous',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 02',
        props: 'wk2',
        weekInTime: 'previous',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 03',
        props: 'wk3',
        weekInTime: 'previous',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 04',
        props: 'wk4',
        weekInTime: 'previous',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 5',
        props: 'wk5',
        weekInTime: 'previous',
        alwaysVisible: true,
        isWeek: true,
      },
      // ------------------
      {
        title: 'TOT NOVE',
        props: 'totNove',
        alwaysVisible: true,
        totalMonth: true,
      },
      {
        title: 'WOW NOVE',
        props: 'wowNove',
        alwaysVisible: true,
      },
      //EXPANSÃO QX ACTUAL
      {
        title: 'Q2 ACTUAL',
        props: 'q2Actual',
        alwaysVisible: true,
        actual: true,
      },
      {
        title: 'Q2 DEM',
        props: 'Q2DEM',
        otherContentActual: true, // para expandir a partir do ACTUAL
        alwaysVisible: false,
      },
      {
        title: 'TOT ONE PLAN Q2',
        props: 'totOnePlanQ2',
        otherContentActual: true,
        alwaysVisible: false,
      },
      {
        title: 'TOT WOW Q2',
        props: 'totWowQ2',
        otherContentActual: true,
        alwaysVisible: false,
      },
      {
        title: 'TOT WOW Q2 DEM',
        props: 'totWowQ2Dem',
        otherContentActual: true,
        alwaysVisible: false,
        totWow: true,
      },
      //-------------------
    ],
    rows: [
      {
        product: {
          value: 'XT2167 - Cyprus 4/64 - Dark Cedar',
        },
        origin: {
          value: 'MAO',
        },
        // ROW BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true, // para identificar que a linha é SOMATORIA
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2167 - Cyprus 4/64 - Pink Clay',
        },
        origin: {
          value: 'MAO',
        },
        // ROW BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2167 - Corfu 5G - Neptne Green',
        },
        origin: {
          value: 'MAO',
        },
        // ROW BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2167 - Corfu 5G - Opal Iris',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2175 - Yukon - Sea Tea',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2175 - Yukon - Mirage',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2173 - Corfu Lite 128 - Meteorite Grey',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk1: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk2: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk3: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk4: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk5: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totNove: {
          value: 1200,
          summableValue: true,
        },
        wowNove: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
    ],
  },
};
