import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-table-line-input',
  templateUrl: './table-line-input.component.html',
  styleUrls: ['./table-line-input.component.scss']
})
export class TableLineInputComponent implements OnInit {

  @Input() public template: any = 'white-shadow';
  @Input() public header: any = 0;
  @Input() public rows: any = 0;
  @Output('changeRows') changeRows = new EventEmitter();

  @ViewChildren('tableHtml') tableHtmlElement!: QueryList<any>;
  tableHtml!: ElementRef;
  translateYAbsoluteItem: number = 0;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:scroll', [])
  
  onWindowScroll() {
    const appHeaderHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName('xng-breadcrumb-root')[0].clientHeight;
    const headerTitle = document.getElementsByClassName('title')[0].clientHeight;
    const matRipple = document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop = appHeaderHeight + breadCrumbHeight + headerTitle + matRipple + 115;
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop
      || document.body.scrollTop || 0;
    const offsetTopElement = this.tableHtmlElement.first.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  onChangeEvent(item: { row: any, field: string }, event?: any){

    let value = event.target.value.length === 0 ? 0 : event.target.value.replace(',', '')
    
    const newRows:any = []

    this.rows.map((row:any) => {
      if ( row.id === item.row.id ){
        item.row[item.field] = value
        item.row.edit = true
        newRows.push(item.row)
      } else {
        newRows.push(row)
      }
    })
    this.rows = newRows

    this.changeRows.emit({newRows, item, event})
  }

}
