import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-table-expansive',
  templateUrl: './table-expansive.component.html',
  styleUrls: ['./table-expansive.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableExpansiveComponent implements OnInit {
  @Input() dataTable!: any;
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @Output() changeTab = new EventEmitter<any>();
  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;

  content: any = [];
  dataSource: any = [];
  displayedDef: any = [];
  displayedColumns: any = [];

  hidePartialContent: boolean = true;
  expandTrue: boolean = true;
  textshowOrHideColumns: String = 'Expand Columns';
  indexColBhTotal: any;
  columsToShowHideTotal: any;
  columsToShowHideActual: any;
  indexColActual: any;

  translateYAbsoluteItem: number = 0;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.dataSource) {
      this.content = this.dataTable.table.rows;
    }
    if (changes.dataTable?.currentValue) {
      this.dataSource = [];
      this.displayedDef = [];
      this.displayedColumns = [];

      this.loadTable(changes.dataTable.currentValue);
    }
    if (changes.displayedColumns?.currentValue) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0]?.clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0]?.clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0]?.clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0]?.clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement.offsetTop + clientHeightTop;

    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  loadTable(updateDataTable?: any) {
    this.dataTable = updateDataTable || this.dataTable;

    if (this.dataTable?.table) {
      this.dataSource = this.dataTable.table.rows;
      this.displayedDef = this.dataTable?.table.columns;
    }

    this.displayedColumns = this.displayedDef
      .filter((item: any) =>
        // logica para evitar sideEfect em telas que não tem expansão de colunas (alwaysVisible)
        item.props && item.alwaysVisible
          ? item.props && item.alwaysVisible
          : item.alwaysVisible != false
      )
      .map((item: any) => item.props);

    this.displayedDef.filter((item: any) => {
      if (item.passingTabs) {
        this.hidePartialContent = true;
        this.textshowOrHideColumns = 'Expand Columns';
        item.expanded = false;
      }
      if (item.lastMonth) this.textshowOrHideColumns = '';
    });
    if (this.dataTable.length === 0) {
      this.textshowOrHideColumns = '';
    }
  }

  toggleColumnTable() {
    if (this.hidePartialContent) {
      this.textshowOrHideColumns = 'Minimize Columns';
      this.expandTrue = false;
    } else {
      this.textshowOrHideColumns = 'Expand Columns';
      this.expandTrue = true;
    }
    this.hidePartialContent = !this.hidePartialContent;

    this.groupColumnsToExpand();
  }

  toShowhideBhTotal() {
    const groupColumnsToShowHideBhTotal = this.displayedDef.filter(
      (item: any) => item.otherContentTotal
    );
    this.columsToShowHideTotal = groupColumnsToShowHideBhTotal.map(
      (item: any) => item.props
    );
    this.indexColBhTotal = this.displayedColumns.findIndex(
      (item: any) => item.otherContentTotal
    );

    groupColumnsToShowHideBhTotal[1].isFirstCard = true;
    groupColumnsToShowHideBhTotal[
      groupColumnsToShowHideBhTotal.length - 1
    ].isLastCard = true;

    this.displayedColumns.splice(
      this.indexColBhTotal + 4,
      0,
      ...this.columsToShowHideTotal
    );
  }

  toShowhideQActual() {
    const groupColumnsToShowHideActual = this.displayedDef.filter(
      (item: any) => item.otherContentActual
    );
    this.columsToShowHideActual = groupColumnsToShowHideActual.map(
      (item: any) => item.props
    );
    this.indexColActual = this.displayedColumns.findIndex(
      (item: any) => item.otherContentActual
    );

    groupColumnsToShowHideActual[1].isFirstCard = true;
    groupColumnsToShowHideActual[
      groupColumnsToShowHideActual.length - 1
    ].isLastCard = true;

    this.displayedColumns.splice(
      this.indexColActual + 17,
      0,
      ...this.columsToShowHideActual
    );
  }

  groupColumnsToExpand(): void {
    if (this.hidePartialContent === false) {
      this.displayedDef.forEach((item: any) => {
        item.expanded = true;
        if (item.otherContentTotal && item.otherContentActual) {
          item.alwaysVisible = true;
        }
      });

      this.toShowhideBhTotal();
      this.toShowhideQActual();
    } else {
      this.displayedDef.forEach((item: any) => {
        item.expanded = false;
        if (item.otherContentTotal && item.otherContentActual) {
          item.alwaysVisible = false;
        }
      });
      this.displayedColumns.splice(
        this.indexColActual + 17,
        this.columsToShowHideTotal.length
      );
      this.displayedColumns.splice(
        this.indexColBhTotal + 4,
        this.columsToShowHideTotal.length
      );
    }
  }

  onChangeEvent(
    item: { indexRow: number; columnName: string; row: any; col: any },
    event: any
  ) {
    if (event.key === 'Backspace') {
      event.preventDefault();
      event.target.selectionStart;
    }
    const valueInput: any = event.target.value
      ? event.target.value.replaceAll(',', '')
      : 0;
    const dataRowColumn =
      this.dataSource.filteredData[item.indexRow][item.columnName];

    this.dataSource.filteredData[item.indexRow][item.columnName].value =
      valueInput;

    const { fiscalYear, quarter, month, executedAtWeek, salesModel } =
      this.dataSource.filteredData[item.indexRow].product;
    this.dataSource.filteredData[item.indexRow][item.columnName].edit = true;
    this.dataSource.filteredData[item.indexRow][item.columnName].fiscalYear =
      fiscalYear;
    this.dataSource.filteredData[item.indexRow][item.columnName].quarter =
      quarter;
    this.dataSource.filteredData[item.indexRow][item.columnName].month = month;
    this.dataSource.filteredData[item.indexRow][
      item.columnName
    ].executedAtWeek = executedAtWeek;
    this.dataSource.filteredData[item.indexRow][item.columnName].salesModel =
      salesModel;

    const value = {
      indexRow: item.indexRow,
      columnNameWeek: item.columnName,
      oldValue: dataRowColumn.value,
      value: valueInput,
      col: item.col,
    };

    this.inputChangedEvent.emit(value);
    this.cd.detectChanges();
  }
}
