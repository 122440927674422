import { HttpClient } from '@angular/common/http';
import {
  Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { CardInterface } from 'src/app/components/cards/card.interface';
import { InputBase, SelectSearchInput } from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { OrderAdmin } from 'src/app/interfaces/orderAdmin.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { SalesManager } from 'src/app/interfaces/salesManager.interface';
import { User } from 'src/app/interfaces/user.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { TooltipPosition, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { Customer } from 'src/app/interfaces/customer.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { PermissionService } from '../../../services/authorization/permission.service';

@Component({
  selector: 'app-order-admin',
  templateUrl: './order-admin.component.html',
  styleUrls: ['./order-admin.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class OrderAdminComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit: | TemplateRef<any> | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation: | TemplateRef<any> | any;
  searchInputs: InputBase<string>[];
  displayedColumns: any;
  result!: ResultInterface;
  userForm: FormGroup;
  isEdit: boolean = false;
  geosEntity: Geo[] = [];
  countriesEntity: Country[] = [];
  salesManagersEntity: any[] = [];
  salesManagerEntitiesByCountry: any[] = [];
  orderAdminsEntity: any[] = [];
  orderAdminCrudService: CrudService<OrderAdmin>;
  customerCrudService: CrudService<Customer>;
  pagination: Pagination;
  filter: any = {
    order_admin: true,
    sort: 'last_name',
    status: true,
    pageName: PAGE_NAME_RESOURCE.orderAdminManagement,
  };
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  nameFirstSelectedSalesManager: string = '';
  fullNameOrderAdmin: string = '';
  emailOrderAdmin: string = '';
  messageError = '';
  buttonSaveDisabled = false;
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionTooltip = new FormControl(this.positionOptions[0]);
  orderAdminIdEntities: any[] = [];
  salesManagersBeforeEdit: { sales_manager_id: number; salesManager: SalesManager; }[] = [];
  currentUser!: any;
  entities: Array<getEntities> = [
    { entity: 'geo', query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.orderAdminManagement } },
    { entity: 'country', query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.orderAdminManagement } },
    {
      entity: 'user',
      query: {
        order_admin: true, status: true, sort: 'last_name', pageName: PAGE_NAME_RESOURCE.orderAdminManagement,
      },
    },
    {
      entity: 'user',
      query: {
        sales_manager: true, status: true, sort: 'last_name', pageName: PAGE_NAME_RESOURCE.orderAdminManagement,
      },
    },
  ];

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private authService: AuthService,
  ) {
    this.pagination = { page: 0, size: 10 };
    this.orderAdminCrudService = new CrudService<OrderAdmin>(this.http, 'orderadmin');
    this.customerCrudService = new CrudService<Customer>(this.http, 'customer');
    this.searchInputs = [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'orderAdminFullName',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];

    this.userForm = this.formBuilder.group({
      id: [''],
      order_admin_user_id: ['', Validators.required],
      country_id: [''],
      sales_manager_user: [''],
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
    };
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getOrderAdmins(this.pagination || undefined, this.filter);
    this.getDataFilter();
  }

  getOrderAdmins(page?: any, params?: any) {
    this.spinner.show();
    this.orderAdminCrudService.getPaged(page, params || this.filter).subscribe((response: any) => {
      this.updateComponents(response);
      this.spinner.hide();
    });
  }

  getDataFilter() {
    const geosFilter: any[] = [];
    const countriesFilter: any[] = [];
    const orderAdminsFilter: any[] = [];
    this.spinner.show();
    this.orderAdminCrudService.getDataFilters(this.entities).subscribe((response: any) => {
      [this.geosEntity, this.countriesEntity, this.orderAdminsEntity,
      this.salesManagersEntity] = response;
      const initialValueGeoFilter: { [key: string]: any } = {};
      const initialValueCountryFilter: { [key: string]: any } = {};

      this.geosEntity.forEach((item: any) => {
        geosFilter.push({ value: item.geo, id: item.id });
        if (item.id === this.currentUser.geoId) {
          initialValueGeoFilter.value = item.geo;
          initialValueGeoFilter.id = item.id;
        }
      });

      const selectGeo = new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        options: geosFilter,
        value: initialValueGeoFilter.value,
      });

      this.countriesEntity.forEach((item: Country) => {
        countriesFilter.push({ value: item.country, id: item.id });
        if (item.id === this.currentUser.countryId) {
          initialValueCountryFilter.value = item.country;
          initialValueCountryFilter.id = item.id;
        }
      });

      const selectCountry = new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        options: countriesFilter,
        value: initialValueCountryFilter.value,
      });

      this.orderAdminsEntity.forEach((item: User) => {
        const viewName: string = `${item.last_name}, ${item.name}`;
        if (orderAdminsFilter.findIndex((obj) => obj.value
          .toLocaleUpperCase() === viewName.toLocaleUpperCase()) === -1) {
          orderAdminsFilter.push({ value: viewName, id: item.id });
        }
      });

      this.searchInputs[0] = selectGeo;
      this.searchInputs[1] = selectCountry;
      this.searchInputs[2].options = orderAdminsFilter;
      this.searchInputs = [...this.searchInputs];
    });
  }

  updateComponents(responseOrderAdmin: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(responseOrderAdmin.rows)),
      table: {
        columns: [
          {
            label: 'order admin', prop: 'order_admin', sticky: true, style: { width: '40%', 'word-wrap': 'break-word' },
          },
          {
            label: 'sales manager', prop: 'sales_managers', sticky: true, style: { width: '60%', 'padding-right': '10px', 'word-wrap': 'break-word' },
          },
        ],
        rows: new MatTableDataSource<OrderAdmin>(
          this.dataTables(JSON.stringify(responseOrderAdmin.rows)),
        ),
      },
      pageIndex: responseOrderAdmin.page,
      pageSize: responseOrderAdmin.totalRows,
      pagesTotal: responseOrderAdmin.totalPages,
    };
    const actions = this.permissionService.setTablePermissions('order-admin-management');
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataTables(orderAdmin: any) {
    const dataTables: any[] = JSON.parse(orderAdmin).map((item: OrderAdmin) => {
      const hasSalesManagers: boolean = item.salesManagers.length > 0;
      return {
        id: item.id,
        email: item.email,
        order_admin: `${item.last_name}, ${item.name} (${item.email})`,
        sales_managers: hasSalesManagers ? item.salesManagers.map(
          (salesManager: {
            sales_manager_id: number, salesManager: SalesManager
          }) => `${salesManager.salesManager.last_name}, ${salesManager.salesManager.name}`,
        ).join(' ; ') : '',
        deleteNotActive: !hasSalesManagers,
      };
    });
    return [...dataTables.sort((current: any, next: any) => current.order_admin
      .localeCompare(next.order_admin))];
  }

  dataCards(data: any) {
    const dataCards: CardInterface[] = [];
    const actionCards = this.permissionService.setActionsCardsPermission('order-admin-management');
    JSON.parse(data).forEach((item: OrderAdmin) => {
      const hasSalesManagers: boolean = item.salesManagers.length > 0;
      dataCards.push({
        id: item.id,
        title: `${item.last_name}, ${item.name}`,
        iconEdit: { visible: actionCards.iconEdit, enabled: true },
        iconDelete: { visible: actionCards.iconDelete, enabled: hasSalesManagers },
        subtitle: item.email,
        attributes: [
          {
            key: 'Sales Manager',
            value: item.salesManagers.map((salesManager: {
              sales_manager_id: number, salesManager: SalesManager
            }) => `${salesManager.salesManager.last_name}, ${salesManager.salesManager.name} `.toUpperCase()),
            isTag: true,
          },
        ],
      });
    });
    return dataCards.sort((current: any, next: any) => current.title.localeCompare(next.title));
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getOrderAdmins(paginated, this.filter);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.prepareToEdit(event.item.id);
    } else {
      this.onDelete(event.item.id);
    }
  }

  prepareToEdit(id: number) {
    this.spinner.show();
    this.isEdit = true;
    this.buttonSaveDisabled = false;
    this.orderAdminCrudService.fetchEntity(id).subscribe((orderAdmin: OrderAdmin) => {
      this.fullNameOrderAdmin = `${orderAdmin.last_name}, ${orderAdmin.name}`;
      this.emailOrderAdmin = orderAdmin.email;
      this.messageError = '';
      this.salesManagersBeforeEdit = orderAdmin.salesManagers;
      this.salesManagerEntitiesByCountry = this.salesManagersEntity.filter(
        (item: any) => item.country_id === orderAdmin.country_id,
      );
      this.userForm.controls.id.setValue(orderAdmin.id);
      this.userForm.controls.order_admin_user_id.setValue(orderAdmin.id);
      this.userForm.controls.sales_manager_user.setValue(orderAdmin.salesManagers.map(
        (item: any) => item.sales_manager_id,
      ));
      if (orderAdmin.salesManagers.length > 0) {
        const firstSelectedSalesManager: SalesManager = this.salesManagersEntity.filter(
          (item: any) => item.id === orderAdmin.salesManagers[0].sales_manager_id,
        )[0];
        this.nameFirstSelectedSalesManager = `${firstSelectedSalesManager.last_name}, ${firstSelectedSalesManager.name}`;
      }
      if (this.salesManagerEntitiesByCountry.length === 0) {
        this.userForm.controls.sales_manager_user.markAsTouched();
        this.messageError = 'There is no sales manager registered in the same country as this order admin';
        this.buttonSaveDisabled = true;
      }

      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.userForm.reset();
      });
      this.spinner.hide();
    });
  }

  onUpdate() {
    this.isEdit = true;
    if (this.validateFormService.onValidateFields(this.userForm) && this.userForm.valid) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          const salesManagersNoUpdated = this.salesManagersBeforeEdit.filter(
            (salesManager: any) => !this.userForm.controls.sales_manager_user
              .value.includes(salesManager.sales_manager_id),
          );
          if (salesManagersNoUpdated.length) {
            salesManagersNoUpdated.forEach((salesManagerNoUpdated: any) => {
              this.customerCrudService.getPaged(
                0,
                {
                  orderAdminId: this.userForm.controls.id.value,
                  salesManagerId: salesManagerNoUpdated.sales_manager_id,
                },
              ).subscribe((customers: any) => {
                if (customers.rows.length > 0) {
                  this.toastr.error(
                    `${this.translatorService.getMessage('CRUD_NO_ALLOWED_UPDATE_ORDER_ADMIN_WITH_ASSOCIATION_SALES_STRUCTURES')}`,
                    `${this.translatorService.getTitle('CRUD_NO_ALLOWED_UPDATE_ORDER_ADMIN_WITH_ASSOCIATION_SALES_STRUCTURES')}`,
                  );
                  this.spinner.hide();
                } else {
                  this.updateAssociation();
                }
              });
            });
          } else {
            this.updateAssociation();
          }
        }
      });
    }
  }

  onDelete(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.customerCrudService.getPaged(0, { orderAdminId: id }).subscribe((customers: any) => {
          if (customers.rows.length > 0) {
            this.toastr.error(
              `${this.translatorService.getMessage('CRUD_NO_ALLOWED_DELETE_ORDER_ADMIN_WITH_ASSOCIATION_SALES_STRUCTURES')}`,
              `${this.translatorService.getTitle('CRUD_NO_ALLOWED_DELETE_ORDER_ADMIN_WITH_ASSOCIATION_SALES_STRUCTURES')}`,
            );
            this.spinner.hide();
          } else {
            this.orderAdminCrudService.deleteEntity(id).subscribe(() => {
              this.toastr.success(
                `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
                `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Association`,
              );
              this.getOrderAdmins(this.pagination, this.filter);
            }, (err: any) => {
              this.toastr.error(err.error.message, 'Error!');
              this.spinner.hide();
            });
          }
        });
      }
    });
  }

  onSearchEvent(value: any) {
    if (value.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) => item.geo
        .toLocaleUpperCase().includes(value.geoId.toLocaleUpperCase()));
      value.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      value.geoId = '';
    }

    if (value.countryId) {
      const currentCountry = this.countriesEntity.filter((item: Country) => item.country
        .toLocaleUpperCase().includes(value.countryId.toLocaleUpperCase()));
      value.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      value.countryId = '';
    }

    if (value.orderAdminFullName) {
      [value.orderAdmLastName, value.orderAdmName] = [value.orderAdminFullName.split(', ')[0], value.orderAdminFullName.split(', ')[1]];
      delete (value.orderAdminFullName);
    } else {
      value.orderAdminFullName = '';
    }
    this.pagination.page = 0;
    this.filter = {
      ...value, sort: 'last_name', order_admin: true, status: true, pageName: PAGE_NAME_RESOURCE.orderAdminManagement,
    };
    this.getOrderAdmins(this.pagination, this.filter);
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

  onSelectSalesManager(userForm: FormGroup) {
    const salesManagers = this.salesManagersEntity.filter(
      (item) => userForm.value.sales_manager_user.includes(item.id),
    );
    this.nameFirstSelectedSalesManager = salesManagers.length > 0 ? `${salesManagers[0].last_name}, ${salesManagers[0].name}` : '';
  }

  convertValuesToSendForm() {
    const currentOrderAdmin = this.orderAdminsEntity.filter(
      (item) => item.id === this.userForm.value.order_admin_user_id,
    );
    this.userForm.value.country_id = currentOrderAdmin[0].country_id;
    const salesManagers = this.userForm.value.sales_manager_user.map((item: any) => ({ id: item }));
    this.userForm.value.salesManagers = salesManagers;
  }

  updateAssociation() {
    this.convertValuesToSendForm();
    this.spinner.show();
    this.orderAdminCrudService.updateEntity(
      this.userForm.controls.id.value,
      this.userForm.value,
    ).subscribe(() => {
      this.toastr.success(
        `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
        `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Association`,
      );
      this.getOrderAdmins(this.pagination, this.filter);
      this.modalService.closeAll();
    }, (err: any) => {
      this.toastr.error(err.error.message, err.error.title);
      this.spinner.hide();
    });
  }
}
