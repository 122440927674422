export const tableCustomerViewMockup: any = {
  table: {
    columns: [
      {
        title: 'CUSTOMER SHORT',
        props: 'customerShort',
        otherContent: 'customerShort',
        alwaysVisible: true,
        expansive: true,
        hideIconExpand: false,
        style: { width: '10%' },
      },
      {
        title: 'CHANNEL',
        props: 'channel',
        otherContent: 'customerShort',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        otherContent: 'customerShort',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'PPM',
        props: 'ppm',
        style: { width: '10%' },
      },
      {
        title: 'APP TAXES',
        props: 'appTaxes',
        style: { width: '4%' },
      },
      {
        title: 'TOTAL OCT',
        props: 'totalOct',
        otherContent: 'totalOct',
        alwaysVisible: true,
        expansive: true,
        hideIconExpand: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 48.2',
        props: 'wk482',
        otherContent: 'totalOct',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 49',
        props: 'wk49',
        otherContent: 'totalOct',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 50',
        props: 'wk50',
        otherContent: 'totalOct',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 51',
        props: 'wk51',
        otherContent: 'totalOct',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 52',
        props: 'wk52',
        otherContent: 'totalOct',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'TOTAL NOV',
        props: 'totalNov',
        otherContent: 'totalNov',
        alwaysVisible: true,
        expansive: true,
        hideIconExpand: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 53',
        props: 'wk53',
        otherContent: 'totalNov',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 54',
        props: 'wk54',
        otherContent: 'totalNov',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 55',
        props: 'wk55',
        otherContent: 'totalNov',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 56',
        props: 'wk56',
        otherContent: 'totalNov',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 57',
        props: 'wk57',
        otherContent: 'totalNov',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'TOTAL DEC',
        props: 'totalDec',
        otherContent: 'totalDec',
        alwaysVisible: true,
        expansive: true,
        hideIconExpand: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 58',
        props: 'wk58',
        otherContent: 'totalDec',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 59',
        props: 'wk59',
        otherContent: 'totalDec',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 60',
        props: 'wk60',
        otherContent: 'totalDec',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 61',
        props: 'wk61',
        otherContent: 'totalDec',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'WK 62',
        props: 'wk62',
        otherContent: 'totalDec',
        alwaysVisible: false,
        style: { width: '4%' },
      },
      {
        title: 'MISSING',
        props: 'missing',
        style: { width: '4%' },
      },
      {
        title: 'NET OPEN PO',
        props: 'netOpenPo',
        style: { width: '4%' },
      },
      {
        title: 'TO BOOK',
        props: 'toBook',
        style: { width: '4%' },
      },
      {
        title: 'OVERBOOKED',
        props: 'overbooked',
        style: { width: '4%' },
      },
      {
        title: 'BOOKED',
        props: 'booked',
        style: { width: '4%' },
      },
      {
        title: 'SELL IN',
        props: 'sellIn',
        style: { width: '4%' },
      },
    ],
    rows: [
      {
        customerShort: {
          value: 'Via Varejo'
        },
        channel: {
          value: 'Mag'
        },
        origin: {
          value: 'JAG'
        },
        ppm: {
          value: 'XT - Lisbon 2/32 - Aqua Blue'
        },
        appTaxes: {
          value: 'SP'
        },
        totalOct: {
          value: 500
        },
        wk482: {
          value: 100
        },
        wk49: {
          value: 100
        },
        wk50: {
          value: 100
        },
        wk51: {
          value: 100
        },
        wk52: {
          value: 100
        },
        totalNov: {
          value: 500
        },
        wk53: {
          value: 100
        },
        wk54: {
          value: 100
        },
        wk55: {
          value: 100
        },
        wk56: {
          value: 100
        },
        wk57: {
          value: 100
        },
        totalDec: {
          value: 500
        },
        wk58: {
          value: 100
        },
        wk59: {
          value: 100
        },
        wk60: {
          value: 100
        },
        wk61: {
          value: 100
        },
        wk62: {
          value: 100
        },
        missing: {
          value: 500
        },
        netOpenPo: {
          value: 500
        },
        toBook: {
          value: 500
        },
        overbooked: {
          value: 500
        },
        booked: {
          value: 500
        },
        sellIn: {
          value: 500
        },
      },
      {
        customerShort: {
          value: ''
        },
        channel: {
          value: ''
        },
        origin: {
          value: ''
        },
        ppm: {
          value: 'XT - Lisbon 2/32 - Aqua Blue'
        },
        appTaxes: {
          value: 'Fora_7'
        },
        totalOct: {
          value: 500
        },
        wk482: {
          value: 100
        },
        wk49: {
          value: 100
        },
        wk50: {
          value: 100
        },
        wk51: {
          value: 100
        },
        wk52: {
          value: 100
        },
        totalNov: {
          value: 500
        },
        wk53: {
          value: 100
        },
        wk54: {
          value: 100
        },
        wk55: {
          value: 100
        },
        wk56: {
          value: 100
        },
        wk57: {
          value: 100
        },
        totalDec: {
          value: 500
        },
        wk58: {
          value: 100
        },
        wk59: {
          value: 100
        },
        wk60: {
          value: 100
        },
        wk61: {
          value: 100
        },
        wk62: {
          value: 100
        },
        missing: {
          value: 500
        },
        netOpenPo: {
          value: 500
        },
        toBook: {
          value: 500
        },
        overbooked: {
          value: 500
        },
        booked: {
          value: 500
        },
        sellIn: {
          value: 500
        },
      },
      {
        customerShort: {
          value: ''
        },
        channel: {
          value: ''
        },
        origin: {
          value: ''
        },
        ppm: {
          value: 'XT - Lisbon 2/32 - Aqua Blue'
        },
        appTaxes: {
          value: 'Fora_12'
        },
        totalOct: {
          value: 500
        },
        wk482: {
          value: 100
        },
        wk49: {
          value: 100
        },
        wk50: {
          value: 100
        },
        wk51: {
          value: 100
        },
        wk52: {
          value: 100
        },
        totalNov: {
          value: 500
        },
        wk53: {
          value: 100
        },
        wk54: {
          value: 100
        },
        wk55: {
          value: 100
        },
        wk56: {
          value: 100
        },
        wk57: {
          value: 100
        },
        totalDec: {
          value: 500
        },
        wk58: {
          value: 100
        },
        wk59: {
          value: 100
        },
        wk60: {
          value: 100
        },
        wk61: {
          value: 100
        },
        wk62: {
          value: 100
        },
        missing: {
          value: 500
        },
        netOpenPo: {
          value: 500
        },
        toBook: {
          value: 500
        },
        overbooked: {
          value: 500
        },
        booked: {
          value: 500
        },
        sellIn: {
          value: 500
        },
      },
      {
        enableTotal: true,
        customerShort: {
          value: 'SUBTOTAL'
        },
        channel: {
          value: ''
        },
        origin: {
          value: ''
        },
        ppm: {
          value: ''
        },
        appTaxes: {
          value: ''
        },
        totalOct: {
          value: 1500
        },
        wk482: {
          value: 100
        },
        wk49: {
          value: 100
        },
        wk50: {
          value: 100
        },
        wk51: {
          value: 100
        },
        wk52: {
          value: 100
        },
        totalNov: {
          value: 1500
        },
        wk53: {
          value: 100
        },
        wk54: {
          value: 100
        },
        wk55: {
          value: 100
        },
        wk56: {
          value: 100
        },
        wk57: {
          value: 100
        },
        totalDec: {
          value: 1500
        },
        wk58: {
          value: 100
        },
        wk59: {
          value: 100
        },
        wk60: {
          value: 100
        },
        wk61: {
          value: 100
        },
        wk62: {
          value: 100
        },
        missing: {
          value: 500
        },
        netOpenPo: {
          value: 500
        },
        toBook: {
          value: 500
        },
        overbooked: {
          value: 500
        },
        booked: {
          value: 500
        },
        sellIn: {
          value: 500
        },
      }
    ]
  }
}

export const tableCustomerViewToBookMockup: any = {
  table: {
    columns: [
      {
        title: 'PPM',
        props: 'ppm',
        style: { width: '10%' },
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        style: { width: '4%' },
      },
      {
        title: 'CUSTOMER SHORT',
        props: 'customerShort',
        style: { width: '4%' },
      },
      {
        title: 'TAXES',
        props: 'taxes',
        style: { width: '4%' },
      },
      {
        title: 'WK 08',
        subTitle: '22/02',
        props: 'wk08',
        style: { width: '4%' },
      },
      {
        title: 'WK 09',
        subTitle: '27/02',
        props: 'wk09',
        style: { width: '4%' },
      },
      {
        title: 'WK 10',
        subTitle: '07/03',
        props: 'wk10',
        style: { width: '4%' },
      },
      {
        title: 'WK 11',
        props: 'wk11',
        style: { width: '4%' },
      },
      {
        title: 'WK 12',
        props: 'wk12',
        style: { width: '4%' },
      },
      {
        title: 'wk13',
        props: 'wk13',
        style: { width: '4%' },
      },
      {
        title: 'LINE PERCENTAGE',
        props: 'linePercentage',
        style: { width: '4%' },
      },
    ],
    rows: [
      {
        ppm: {
          value: 'XT - Lisbon 2/32 - Aqua Blue'
        },
        origin: {
          value: 'JAG'
        },
        customerShort: {
          value: 'Magalu'
        },
        taxes: {
          value: 'SP'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        ppm: {
          value: ''
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: ''
        },
        taxes: {
          value: 'Fora_7'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        ppm: {
          value: ''
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: ''
        },
        taxes: {
          value: 'Fora_12'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        enableSubTotal: true,
        ppm: {
          value: 'SUBTOTAL'
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: ''
        },
        taxes: {
          value: 'Fora_12'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        ppm: {
          value: 'XT - Lisbon 2/32 - Aqua Blue'
        },
        origin: {
          value: 'MAO'
        },
        customerShort: {
          value: 'Magalu'
        },
        taxes: {
          value: 'SP'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        ppm: {
          value: ''
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: ''
        },
        taxes: {
          value: 'Fora_7'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        ppm: {
          value: ''
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: ''
        },
        taxes: {
          value: 'Fora_12'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
      {
        enableSubTotal: true,
        ppm: {
          value: 'SUBTOTAL'
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: ''
        },
        taxes: {
          value: 'Fora_12'
        },
        wk08: {
          value: 1300
        },
        wk09: {
          value: 1300
        },
        wk10: {
          value: 2700
        },
        wk11: {
          value: 2700
        },
        wk13: {
          value: 4300
        },
        linePercentage: {
          value: '2%'
        },
      },
    ]
  }
}

export const tableCustomerViewBookedMockup: any = {
  table: {
    columns: [
      {
        title: 'PPM',
        props: 'ppm',
        style: { width: '10%' },
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        style: { width: '4%' },
      },
      {
        title: 'CUSTOMER SHORT',
        props: 'customerShort',
        style: { width: '4%' },
      },
      {
        title: 'CHANNEL',
        props: 'channel',
        style: { width: '4%' },
      },
      {
        title: 'JAN',
        props: 'jan',
        style: { width: '4%' },
      },
      {
        title: 'WK 01',
        props: 'wk01',
        style: { width: '4%' },
      },
      {
        title: 'WK 02',
        props: 'wk02',
        style: { width: '4%' },
      },
      {
        title: 'WK 03',
        props: 'wk03',
        style: { width: '4%' },
      },
      {
        title: 'WK 04',
        props: 'wk04',
        style: { width: '4%' },
      },
      {
        title: 'WK 05',
        props: 'wk05',
        style: { width: '4%' },
      },
      {
        title: 'FEB',
        props: 'feb',
        style: { width: '4%' },
      },
      {
        title: 'WK 01',
        props: 'wk01',
        style: { width: '4%' },
      },
      {
        title: 'WK 02',
        props: 'wk02',
        style: { width: '4%' },
      },
      {
        title: 'WK 03',
        props: 'wk03',
        style: { width: '4%' },
      },
      {
        title: 'WK 04',
        props: 'wk04',
        style: { width: '4%' },
      },
      {
        title: 'MAR',
        props: 'mar',
        style: { width: '4%' },
      },
      {
        title: 'WK 01',
        props: 'wk01',
        style: { width: '4%' },
      },
      {
        title: 'WK 02',
        props: 'wk02',
        style: { width: '4%' },
      },
      {
        title: 'WK 03',
        props: 'wk03',
        style: { width: '4%' },
      },
      {
        title: 'WK 04',
        props: 'wk04',
        style: { width: '4%' },
      },
      {
        title: 'WK 05',
        props: 'wk05',
        style: { width: '4%' },
      },
      {
        title: 'TOTAL QUARTER',
        props: 'totalQuarter',
        style: { width: '4%' },
      },
    ],
    rows: [
      {
        ppm: {
          value: 'XT - Lisbon 2/32 - Aqua Blue'
        },
        origin: {
          value: 'JAG'
        },
        customerShort: {
          value: 'Magalu'
        },
        channel: {
          value: 'MAG'
        },
        jan: {
          value: 100
        },
        wk01: {
          value: 100
        },
        wk02: {
          value: 100
        },
        wk03: {
          value: 100
        },
        wk04: {
          value: 100
        },
        wk05: {
          value: 100
        },
        feb: {
          value: 500
        },
        wk06: {
          value: 100
        },
        wk07: {
          value: 100
        },
        wk08: {
          value: 100
        },
        wk09: {
          value: 100
        },
        wk10: {
          value: 100
        },
        mar: {
          value: 500
        },
        wk11: {
          value: 100
        },
        wk12: {
          value: 100
        },
        wk13: {
          value: 100
        },
        wk14: {
          value: 100
        },
        wk15: {
          value: 100
        },
        totalQuarter: {
          value: 500
        },
      },
      {
      ppm: {
        value: ''
      },
      origin: {
        value: ''
      },
      customerShort: {
        value: 'Americanas'
      },
      channel: {
        value: 'B2W'
      },
      jan: {
        value: 100
      },
      wk01: {
        value: 100
      },
      wk02: {
        value: 100
      },
      wk03: {
        value: 100
      },
      wk04: {
        value: 100
      },
      wk05: {
        value: 100
      },
      feb: {
        value: 500
      },
      wk06: {
        value: 100
      },
      wk07: {
        value: 100
      },
      wk08: {
        value: 100
      },
      wk09: {
        value: 100
      },
      wk10: {
        value: 100
      },
      mar: {
        value: 500
      },
      wk11: {
        value: 100
      },
      wk12: {
        value: 100
      },
      wk13: {
        value: 100
      },
      wk14: {
        value: 100
      },
      wk15: {
        value: 100
      },
      totalQuarter: {
        value: 500
      },
      },
      {
        ppm: {
          value: ''
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: 'Fast Shop'
        },
        channel: {
          value: 'FS'
        },
        jan: {
          value: 100
        },
        wk01: {
          value: 100
        },
        wk02: {
          value: 100
        },
        wk03: {
          value: 100
        },
        wk04: {
          value: 100
        },
        wk05: {
          value: 100
        },
        feb: {
          value: 500
        },
        wk06: {
          value: 100
        },
        wk07: {
          value: 100
        },
        wk08: {
          value: 100
        },
        wk09: {
          value: 100
        },
        wk10: {
          value: 100
        },
        mar: {
          value: 500
        },
        wk11: {
          value: 100
        },
        wk12: {
          value: 100
        },
        wk13: {
          value: 100
        },
        wk14: {
          value: 100
        },
        wk15: {
          value: 100
        },
        totalQuarter: {
          value: 500
        },
      },
      {
        enableSubTotal: true,
        ppm: {
          value: 'SUBTOTAL'
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: 'Fast Shop'
        },
        channel: {
          value: 'FS'
        },
        jan: {
          value: 100
        },
        wk01: {
          value: 100
        },
        wk02: {
          value: 100
        },
        wk03: {
          value: 100
        },
        wk04: {
          value: 100
        },
        wk05: {
          value: 100
        },
        feb: {
          value: 500
        },
        wk06: {
          value: 100
        },
        wk07: {
          value: 100
        },
        wk08: {
          value: 100
        },
        wk09: {
          value: 100
        },
        wk10: {
          value: 100
        },
        mar: {
          value: 500
        },
        wk11: {
          value: 100
        },
        wk12: {
          value: 100
        },
        wk13: {
          value: 100
        },
        wk14: {
          value: 100
        },
        wk15: {
          value: 100
        },
        totalQuarter: {
          value: 500
        },
      },
      {
        ppm: {
          value: 'XT - Lisbon 6/128 - Aqua Blue'
        },
        origin: {
          value: 'MAO'
        },
        customerShort: {
          value: 'Magalu'
        },
        channel: {
          value: 'MAG'
        },
        jan: {
          value: 100
        },
        wk01: {
          value: 100
        },
        wk02: {
          value: 100
        },
        wk03: {
          value: 100
        },
        wk04: {
          value: 100
        },
        wk05: {
          value: 100
        },
        feb: {
          value: 500
        },
        wk06: {
          value: 100
        },
        wk07: {
          value: 100
        },
        wk08: {
          value: 100
        },
        wk09: {
          value: 100
        },
        wk10: {
          value: 100
        },
        mar: {
          value: 500
        },
        wk11: {
          value: 100
        },
        wk12: {
          value: 100
        },
        wk13: {
          value: 100
        },
        wk14: {
          value: 100
        },
        wk15: {
          value: 100
        },
        totalQuarter: {
          value: 500
        },
      },
      {
      ppm: {
        value: ''
      },
      origin: {
        value: ''
      },
      customerShort: {
        value: 'Americanas'
      },
      channel: {
        value: 'B2W'
      },
      jan: {
        value: 100
      },
      wk01: {
        value: 100
      },
      wk02: {
        value: 100
      },
      wk03: {
        value: 100
      },
      wk04: {
        value: 100
      },
      wk05: {
        value: 100
      },
      feb: {
        value: 500
      },
      wk06: {
        value: 100
      },
      wk07: {
        value: 100
      },
      wk08: {
        value: 100
      },
      wk09: {
        value: 100
      },
      wk10: {
        value: 100
      },
      mar: {
        value: 500
      },
      wk11: {
        value: 100
      },
      wk12: {
        value: 100
      },
      wk13: {
        value: 100
      },
      wk14: {
        value: 100
      },
      wk15: {
        value: 100
      },
      totalQuarter: {
        value: 500
      },
      },
      {
        ppm: {
          value: ''
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: 'Fast Shop'
        },
        channel: {
          value: 'FS'
        },
        jan: {
          value: 100
        },
        wk01: {
          value: 100
        },
        wk02: {
          value: 100
        },
        wk03: {
          value: 100
        },
        wk04: {
          value: 100
        },
        wk05: {
          value: 100
        },
        feb: {
          value: 500
        },
        wk06: {
          value: 100
        },
        wk07: {
          value: 100
        },
        wk08: {
          value: 100
        },
        wk09: {
          value: 100
        },
        wk10: {
          value: 100
        },
        mar: {
          value: 500
        },
        wk11: {
          value: 100
        },
        wk12: {
          value: 100
        },
        wk13: {
          value: 100
        },
        wk14: {
          value: 100
        },
        wk15: {
          value: 100
        },
        totalQuarter: {
          value: 500
        },
      },
      {
        enableSubTotal: true,
        ppm: {
          value: 'SUBTOTAL'
        },
        origin: {
          value: ''
        },
        customerShort: {
          value: 'Fast Shop'
        },
        channel: {
          value: 'FS'
        },
        jan: {
          value: 100
        },
        wk01: {
          value: 100
        },
        wk02: {
          value: 100
        },
        wk03: {
          value: 100
        },
        wk04: {
          value: 100
        },
        wk05: {
          value: 100
        },
        feb: {
          value: 500
        },
        wk06: {
          value: 100
        },
        wk07: {
          value: 100
        },
        wk08: {
          value: 100
        },
        wk09: {
          value: 100
        },
        wk10: {
          value: 100
        },
        mar: {
          value: 500
        },
        wk11: {
          value: 100
        },
        wk12: {
          value: 100
        },
        wk13: {
          value: 100
        },
        wk14: {
          value: 100
        },
        wk15: {
          value: 100
        },
        totalQuarter: {
          value: 500
        },
      }
    ]
  }
}
