<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="header-title">
    <span class="title">SUPPLY SUMMARY</span>
    <span class="material-icons-supply" (click)="exportTable()">
      <img
        src="../../../assets/images/BTN-Export.svg"
        alt="export"
        matTooltip="{{ 'Export' }}" />
    </span>
  </div>
  <div class="ka-allocation-content">
    <app-search
      (searchEvent)="onSearchEvent($event)"
      (applyFilterEvent)="applyFilter()"
      [filters]="searchInputsSupplySummary"
      [showButtonApply]="true"
      [enableApplyFilter]="enableApplyFilter"
      [showMoreFiltersLink]="false"
      [showMoreFiltersLinkWithMinWidth]="true">
    </app-search>
    <mat-divider></mat-divider>
    <div>
      <div class="container-week-over">
        <p class="title-table-week-over-week" *ngIf="!showNoResultsWow">
          Week Over Week
        </p>

        <div class="spacer"></div>

        <div class="link-container" class="next-supply-summary">
          <a class="next" (click)="nextQuarterSupplySummary(next)">
            {{ next ? 'NEXT' : 'PREVIOUS' }} QUARTER
          </a>
        </div>
      </div>
      <app-simple-table-expansive
        [dataTable]="tableResultWeekOverWeek"
        [nameTable]="nameTableWeekOverWeek"
        *ngIf="!showNoResultsWow">
      </app-simple-table-expansive>
      <div class="no-filter-selected-session" *ngIf="showNoResultsWow">
        <p>NO RECORDS FOUND.</p>
      </div>
    </div>

    <div>
      <p class="title-table-supply-summary" *ngIf="!showNoResultsSupply">
        Supply Summary
      </p>
      <app-simple-table-expansive
        [dataTable]="tableResultSupplySummary"
        [nameTable]="nameTableSupplySummary"
        *ngIf="!showNoResultsSupply">
      </app-simple-table-expansive>
      <div class="no-filter-selected-session" *ngIf="showNoResultsSupply">
        <p>NO RECORDS FOUND.</p>
      </div>
    </div>
  </div>
  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
