import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { Material } from 'src/app/interfaces/material.interface';
import { User } from 'src/app/interfaces/user.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { ExcelSheetsService } from '../../../services/generic/excelSheets.service';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { ToastrService } from 'ngx-toastr';
import { Tab } from 'src/app/interfaces/tab.interface';

@Component({
  selector: 'app-to-book',
  templateUrl: './to-book.component.html',
  styleUrls: ['./to-book.component.scss'],
})
export class ToBookComponent implements OnInit {
  @Output('setFilterTableSelected') setFilterTableSelected = new EventEmitter();
  @ViewChild('OrderAdmin') OrderAdmin!: TemplateRef<any>;
  @ViewChild('SalesManager') SalesManager!: TemplateRef<any>;
  @ViewChild('Customer') Customer!: TemplateRef<any>;
  @ViewChild('Product') Product!: TemplateRef<any>;

  tabs: Tab[] = [];
  entities: Array<getEntities> = [
    {
      entity: 'customer-short',
      query: {
        status: true,
        sort: 'customerShort',
        pageName: PAGE_NAME_RESOURCE.toBook,
      },
    },
    {
      entity: 'material',
      query: { pageName: PAGE_NAME_RESOURCE.toBook },
    },
    {
      entity: 'material/products/summary',
      query: { pageName: PAGE_NAME_RESOURCE.toBook },
    },
    {
      entity: 'user',
      query: {
        status: true,
        order_admin: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.toBook,
      },
    },
    {
      entity: 'user',
      query: {
        status: true,
        sales_manager: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.allocationRetail,
      },
    },
    {
      entity: 'material-family',
      query: { pageName: PAGE_NAME_RESOURCE.toBook },
    },
  ];

  isEditTableMode = false;
  isValid: any = false;
  fieldsValid: any[] = [];
  tooltipMessage: String = 'Export';
  messages: {
    [key: string]: string;
  } = {
    save: 'Do you confirm ka allocation data updated?',
    discard: 'Are you sure you want to exit before saving your changes?',
    negativeValues:
      'Are you sure you want to save the allocation with negative fields?',
    noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
  };

  tabsTeste: { [key: string]: number } = {
    'ORDER ADMIN': 0,
    'SALES MANAGER': 1,
    CUSTOMER: 2,
    PRODUCT: 3,
  };

  dataOrderAdminAllocate: any;
  dataOrderAdminToBook: any;
  dataSalesManagerAllocate: any;
  dataSalesManagerToBook: any;
  dataCustomerShortAllocate: any;
  dataCustomerShortToBook: any;
  dataProductAllocate: any;
  dataProductToBook: any;
  selectedTabIndex = 0;
  storeTab: any = [0];
  formSearchOrderAdmin!: { [key: string]: any };
  formSearchSalesManager!: { [key: string]: any };
  formSearchCustomer!: { [key: string]: any };
  formSearchProduct!: { [key: string]: any };
  countriesEntities: { [key: string]: any } = {};
  crudService: CrudService<any>;
  searchInputsOrderAdmin: InputBase<string>[];
  searchInputsSalesManager: InputBase<string>[];
  searchInputsCustomer: InputBase<string>[];
  searchInputsProduct: any;
  currentUser!: any;
  resultToBook!: any;
  exportAllocateResult!: ResultInterface;
  exportToBookResult!: ResultInterface;
  orderAdminSelected = true;
  noResultOrderAdmin = false;
  salesManagerSelected = true;
  noResultSalesManager = false;
  customerShortSelected = true;
  noResultCustomerShort = false;
  productSelected = true;
  noResultProduct = false;
  enableApplyFilter = true;
  customerShortAllData: any = [];
  ppmAllData: any = [];
  customerShortNoFilter: any = [];
  materialsAllData: any = [];
  familyFullData: any = [];
  filterTable: any = [
    { label: 'Allocate', value: false },
    { label: 'To Book', value: true },
  ];
  allocate = { label: 'Allocate', value: false };
  toBook = { label: 'To Book', value: true };
  dataFinalOrderAdmin: any = [];
  dataFinalSalesManager: any = [];
  dataFinalCustomer: any = [];
  dataFinalProduct: any = [];
  orderAdminFullData: any = [];
  lastUpdate: string;
  salesManagerFullData: any;
  familyfiltered: any;
  constructor(
    private spinner: NgxSpinnerService,
    protected http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private excelSheetsService: ExcelSheetsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.crudService = new CrudService<any>(this.http, '');
    this.searchInputsOrderAdmin = [
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        classCss: 'full-width-panel-options',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];

    this.searchInputsSalesManager = [
      new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        classCss: 'full-width-panel-options',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];

    this.searchInputsCustomer = [
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        classCss: 'full-width-panel-options',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];

    this.searchInputsProduct = [
      new MultiSelectSearchInput({
        key: 'materialId',
        hover: 'Family',
        type: 'text',
        classCss: 'full-width-panel-options',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];
    this.lastUpdate = '';
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    [this.currentUser] = this.authService.getUserPermissions();
    this.handleLastUpdate();
    this.getDataFilter();

    const promises = [this.getDataFilter()];
    this.hideSpinnerAfterAll(promises);
  }

  handleLastUpdate() {
    this.crudService
      .getEntity(`onePlan/last-updates`, {
        pageName: PAGE_NAME_RESOURCE.toBook,
      })
      .subscribe((response: any) => {
        this.lastUpdate = `Order Book Last Update: ${response.eccCustomerUpdate}  |  Allocation Last Update: ${response.allocationCustomerUpdate}`;
      });
  }

  ngAfterViewInit(): void {
    this.allTabs();
    this.cdr.detectChanges();
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  formatDataFront(data: any, tab?: string) {
    const front: any = {
      allocate: data[0].allocate,
      toBook: data[1].toBook,
    };

    front.allocate.rows[0].jag.forEach((item: any) => {
      if (item.SP) {
        item.taxes = 'SP';
        const front = Object.keys(item.SP);
        front.forEach((data: any) => {
          item[data] = item.SP[data];
        });
        delete item.SP;
      } else if (item.FORA_7) {
        item.taxes = 'FORA_7';
        const front = Object.keys(item.FORA_7);
        front.forEach((data: any) => {
          item[data] = item.FORA_7[data];
        });
        delete item.FORA_7;
      } else if (item.FORA_12) {
        item.taxes = 'FORA_12';
        const front = Object.keys(item.FORA_12);
        front.forEach((data: any) => {
          item[data] = item.FORA_12[data];
        });
        delete item.FORA_12;
      }
    });

    front.allocate.rows[1].man.forEach((item: any) => {
      if (item.SP) {
        item.taxes = 'SP';
        const front = Object.keys(item.SP);
        front.forEach((data: any) => {
          item[data] = item.SP[data];
        });
        delete item.SP;
      } else if (item.FORA_7) {
        item.taxes = 'FORA_7';
        const front = Object.keys(item.FORA_7);
        front.forEach((data: any) => {
          item[data] = item.FORA_7[data];
        });
        delete item.FORA_7;
      } else if (item.FORA_12) {
        item.taxes = 'FORA_12';
        const front = Object.keys(item.FORA_12);
        front.forEach((data: any) => {
          item[data] = item.FORA_12[data];
        });
        delete item.FORA_12;
      }
    });

    front.toBook.rows[0].jag.forEach((item: any) => {
      if (item.SP) {
        item.taxes = 'SP';
        const front = Object.keys(item.SP);
        front.forEach((data: any) => {
          item[data] = item.SP[data];
        });
        delete item.SP;
      } else if (item.FORA_7) {
        item.taxes = 'FORA_7';
        const front = Object.keys(item.FORA_7);
        front.forEach((data: any) => {
          item[data] = item.FORA_7[data];
        });
        delete item.FORA_7;
      } else if (item.FORA_12) {
        item.taxes = 'FORA_12';
        const front = Object.keys(item.FORA_12);
        front.forEach((data: any) => {
          item[data] = item.FORA_12[data];
        });
        delete item.FORA_12;
      }
    });

    front.toBook.rows[1].man.forEach((item: any) => {
      if (item.SP) {
        item.taxes = 'SP';
        const front = Object.keys(item.SP);
        front.forEach((data: any) => {
          item[data] = item.SP[data];
        });
        delete item.SP;
      } else if (item.FORA_7) {
        item.taxes = 'FORA_7';
        const front = Object.keys(item.FORA_7);
        front.forEach((data: any) => {
          item[data] = item.FORA_7[data];
        });
        delete item.FORA_7;
      } else if (item.FORA_12) {
        item.taxes = 'FORA_12';
        const front = Object.keys(item.FORA_12);
        front.forEach((data: any) => {
          item[data] = item.FORA_12[data];
        });
        delete item.FORA_12;
      }
    });

    const allocateJagRows: any = front.allocate.rows[0].jag;
    const allocateManRows: any = front.allocate.rows[1].man;
    front.allocate.rows = {};

    const toBookJagRows: any = front.toBook.rows[0].jag;
    const toBookManRows: any = front.toBook.rows[1].man;
    front.toBook.rows = {};

    front.allocate.rows.jag = allocateJagRows;
    front.allocate.rows.man = allocateManRows;

    front.toBook.rows.jag = toBookJagRows;
    front.toBook.rows.man = toBookManRows;

    this.calcSubAndTotal(front.allocate, tab);
    this.calcSubAndTotal(front.toBook, tab);
    return front;
  }

  calcSubAndTotal(data: any, tab?: string) {
    const allocateTotalJag: any = {};
    const allocateTotalMan: any = {};
    const totalJagMan: any = {};
    data.rows.jag.forEach((item: any) => {
      Object.keys(item).forEach((value: any) => {
        allocateTotalJag[value] = '';
        allocateTotalMan[value] = '';
        totalJagMan[value] = '';
      });
    });

    data.rows.man.forEach((item: any) => {
      Object.keys(item).forEach((value: any) => {
        allocateTotalJag[value] = '';
        allocateTotalMan[value] = '';
        totalJagMan[value] = '';
      });
    });

    if (data.rows.jag.length > 0) {
      data.rows.jag.forEach((item: any) => {
        Object.keys(item).forEach((value: any) => {
          if (
            value.includes('WK') ||
            value.includes('Week') ||
            value.includes('totalToBook') ||
            value.includes('entered') ||
            value.includes('overBooked')
          ) {
            let result1 = Number(allocateTotalJag[value]);
            let result2 = Number(item[value]);
            allocateTotalJag[value] = result1 + result2;
          } else {
            allocateTotalJag[value] = '';
          }
        });
      });
    }

    if (data.rows.man.length > 0) {
      data.rows.man.forEach((item: any) => {
        Object.keys(item).forEach((value: any) => {
          if (
            value.includes('WK') ||
            value.includes('Week') ||
            value.includes('totalToBook') ||
            value.includes('entered') ||
            value.includes('overBooked')
          ) {
            let result1 = Number(allocateTotalMan[value]);
            let result2 = Number(item[value]);
            allocateTotalMan[value] = result1 + result2;
          } else {
            allocateTotalMan[value] = '';
          }
        });
      });
    }

    const auxTotal: any = [allocateTotalJag, allocateTotalMan];
    auxTotal.forEach((item: any) => {
      Object.keys(item).forEach((value: any) => {
        if (
          value.includes('WK') ||
          value.includes('Week') ||
          value.includes('totalToBook') ||
          value.includes('entered') ||
          value.includes('overBooked')
        ) {
          let result1 = Number(allocateTotalMan[value]);
          let result2 = Number(allocateTotalJag[value]);
          totalJagMan[value] = result1 + result2;
        } else {
          totalJagMan[value] = '';
        }
      });
    });

    if (data.rows.jag.length > 0) {
      if (tab === 'orderAdmin') {
        allocateTotalJag.orderAdmin = 'SUBTOTAL';
      }
      if (tab === 'salesManager') {
        allocateTotalJag.salesManager = 'SUBTOTAL';
      }
      if (tab === 'customer') {
        allocateTotalJag.customerShort = 'SUBTOTAL';
      }
      if (tab === 'product') {
        allocateTotalJag.ppm = 'SUBTOTAL';
      }
      data.rows.sub_jag = [allocateTotalJag];
    }
    if (data.rows.man.length > 0) {
      if (tab === 'orderAdmin') {
        allocateTotalMan.orderAdmin = 'SUBTOTAL';
      }
      if (tab === 'salesManager') {
        allocateTotalMan.salesManager = 'SUBTOTAL';
      }
      if (tab === 'customer') {
        allocateTotalMan.customerShort = 'SUBTOTAL';
      }
      if (tab === 'product') {
        allocateTotalMan.ppm = 'SUBTOTAL';
      }
      data.rows.sub_man = [allocateTotalMan];
    }

    if (tab === 'orderAdmin') {
      totalJagMan.orderAdmin = 'TOTAL';
    }
    if (tab === 'salesManager') {
      totalJagMan.salesManager = 'TOTAL';
    }
    if (tab === 'customer') {
      totalJagMan.customerShort = 'TOTAL';
    }
    if (tab === 'product') {
      totalJagMan.ppm = 'TOTAL';
    }
    data.rows.total = [totalJagMan];
    return data;
  }

  async getDataFilter(): Promise<void> {
    return new Promise((resolve, reject) => {
      const ppmFilter: any[] = [];
      const marketNameFilter: any[] = [];
      const salesModelFilter: any[] = [];

      let orderAdminsEntities;
      let customerShortEntities;
      let materialsEntities;
      let kaManagementPPMEntities;
      let salesManagerEntities;
      let familyEntities;

      this.spinner.show();

      this.crudService.getDataFilters(this.entities).subscribe(
        (response: any) => {
          [
            customerShortEntities,
            materialsEntities,
            kaManagementPPMEntities,
            orderAdminsEntities,
            salesManagerEntities,
            familyEntities,
          ] = response;

          this.dataFilterOrderAdmin(
            orderAdminsEntities,
            kaManagementPPMEntities,
            customerShortEntities
          );

          this.dataFilterSalesManager(
            kaManagementPPMEntities,
            customerShortEntities,
            salesManagerEntities
          );

          this.dataFilterCustomerShort(
            orderAdminsEntities,
            kaManagementPPMEntities,
            customerShortEntities,
            salesManagerEntities
          );

          this.dataFilterProduct(
            orderAdminsEntities,
            familyEntities,
            customerShortEntities,
            materialsEntities,
            salesManagerEntities
          );

          this.orderAdminFullData = orderAdminsEntities;
          this.salesManagerFullData = salesManagerEntities;
          this.customerShortAllData = customerShortEntities;
          this.familyfiltered = familyEntities;
          this.ppmAllData = ppmFilter;
          this.materialsAllData = materialsEntities;
          this.familyFullData = familyEntities;

          materialsEntities.forEach((item: any) => {
            salesModelFilter.push({ value: item.salesModel, id: item.id });
            marketNameFilter.push({
              value: item.marketName.toUpperCase(),
              id: item.id,
            });
          });

          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        }
      );
    });
  }

  dataFilterOrderAdmin(...response: any) {
    const orderAdminsFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const ppmFilter: any[] = [];
    const origin: any[] = [];
    this.customerShortNoFilter = [];

    let orderAdminsEntities = response[0];
    let customerShortEntities = response[2];
    let kaManagementPPMEntities = response[1];

    const initialValueOrderAdmin: { [key: string]: any } = {};
    orderAdminsEntities.forEach((item: User) => {
      const viewName: string = `${item.last_name}, ${item.name}`;
      if (
        orderAdminsFilter.findIndex(
          obj => obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
        ) === -1
      ) {
        orderAdminsFilter.push({ value: viewName, id: item.id });
        if (item.email.includes(this.currentUser.email)) {
          initialValueOrderAdmin.value = viewName;
          initialValueOrderAdmin.id = item.id;
        }
      }
    });

    kaManagementPPMEntities.forEach((item: any) => {
      if (
        item.productDesc &&
        ppmFilter.findIndex(
          obj => obj.value.toUpperCase() === item.allocationBz?.toUpperCase()
        ) === -1
      ) {
        ppmFilter.push({
          key: item.productDesc,
          value: item.productDesc,
          id: item.productId,
        });
      }
    });

    customerShortEntities.forEach((item: any) => {
      this.customerShortNoFilter.push({
        value: item.customerShort,
        id: item.id,
      });
    });

    origin.push({ value: 'ALL' }, { value: 'JAG' }, { value: 'MAN' });

    const selectMultiOrderAdmin: any = new MultiSelectSearchInput({
      key: 'orderAdminId',
      hover: 'Order Admin',
      type: 'text',
      hasAutocomplete: true,
      classCss: 'first-filter-to-book',
      hasCheckFlowException: true,
      options: orderAdminsFilter,
      value: [initialValueOrderAdmin],
    });

    let orderAdminsFilterOption: any;

    if (initialValueOrderAdmin.id || initialValueOrderAdmin.value) {
      const matchingItems = customerShortEntities.filter(
        (item: any) => item.orderAdminId == initialValueOrderAdmin.id
      );
      const firstMatchingItem = matchingItems[0];

      customerShortEntities.filter((item: any) => {
        if (item.orderAdminId == initialValueOrderAdmin.id) {
          customerShortFilter.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });

      this.formSearchOrderAdmin = {
        orderAdminId: [initialValueOrderAdmin.id],
        customerShortId: firstMatchingItem ? firstMatchingItem.id : [],
        internalName: [],
        marketName: [],
        materialId: [],
        salesManagerId: [],
        origin: 'ALL',
        ppm: [],
        report: 'orderAdmin',
        salesModel: [],
      };
    } else {
      this.formSearchOrderAdmin = {
        orderAdminId: [],
        customerShortId: [],
        internalName: [],
        marketName: [],
        materialId: [],
        salesManagerId: [],
        origin: 'ALL',
        ppm: [],
        report: 'orderAdmin',
        salesModel: [],
      };
    }

    const selectPpmFilter = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'PPM',
      type: 'text',
      classCss: 'full-width-panel-options',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      largeInput: true,
      options: ppmFilter,
    });

    const selectMarketName = new MultiSelectSearchInput({
      key: 'marketName',
      hover: 'Market Name',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectSalesModel = new MultiSelectSearchInput({
      key: 'salesModel',
      hover: 'Sales Model',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectOrigin = new SelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: origin,
      value: origin[0].value,
      disabled: true,
    });

    this.searchInputsOrderAdmin[0] = selectMultiOrderAdmin;
    this.searchInputsOrderAdmin[1] =
      this.createAndAssignCustomerShortSearchInput(customerShortFilter);
    this.searchInputsOrderAdmin[1].disabled =
      selectMultiOrderAdmin.value.length > 0 && customerShortFilter.length > 0
        ? false
        : true;
    this.searchInputsOrderAdmin[2] = selectPpmFilter;
    this.searchInputsOrderAdmin[3] = selectMarketName;
    this.searchInputsOrderAdmin[4] = selectSalesModel;
    this.searchInputsOrderAdmin[5] = selectOrigin;
    this.searchInputsOrderAdmin[5].notClearable = true;

    this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
    this.cdr.detectChanges();
  }

  dataFilterSalesManager(...response: any) {
    const salesManagerFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const ppmFilter: any[] = [];
    const origin: any[] = [];

    let salesManagerEntities = response[2];
    let customerShortEntities = response[1];
    let kaManagementPPMEntities = response[0];

    const initialValueSalesManager: { [key: string]: any } = {};
    let salesManagerFilterOption: any;
    salesManagerEntities.forEach((item: any) => {
      const viewSalesManager: string = `${item.last_name}, ${item.name}`;
      if (
        salesManagerFilter.findIndex(
          obj =>
            obj.value.toLocaleUpperCase() ===
            viewSalesManager.toLocaleUpperCase()
        ) === -1
      ) {
        salesManagerFilter.push({ value: viewSalesManager, id: item.id });
        if (item.email.includes(this.currentUser.email)) {
          initialValueSalesManager.value = viewSalesManager;
          initialValueSalesManager.id = item.id;
        }
      }
    });

    kaManagementPPMEntities.forEach((item: any) => {
      if (
        item.productDesc &&
        ppmFilter.findIndex(
          obj => obj.value.toUpperCase() === item.allocationBz?.toUpperCase()
        ) === -1
      ) {
        ppmFilter.push({
          key: item.productDesc,
          value: item.productDesc,
          id: item.productId,
        });
      }
    });

    origin.push({ value: 'ALL' }, { value: 'JAG' }, { value: 'MAN' });

    if (initialValueSalesManager.id || initialValueSalesManager.value) {
      const matchingItems = customerShortEntities.filter(
        (item: any) => item.salesManagerId == initialValueSalesManager.id
      );
      const firstMatchingItem = matchingItems[0];

      customerShortEntities.filter((item: any) => {
        if (item.salesManagerId == initialValueSalesManager.id) {
          customerShortFilter.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });

      this.formSearchSalesManager = {
        salesManagerId: [initialValueSalesManager.id],
        orderAdminId: [],
        customerShortId: firstMatchingItem ? [firstMatchingItem.id] : [],
        internalName: [],
        marketName: [],
        materialId: [],
        origin: 'ALL',
        ppm: [],
        report: 'salesManager',
        salesModel: [],
      };
    } else {
      this.formSearchSalesManager = {
        salesManagerId: [],
        orderAdminId: [],
        customerShortId: [],
        internalName: [],
        marketName: [],
        materialId: [],
        origin: 'ALL',
        ppm: [],
        report: 'salesManager',
        salesModel: [],
      };
    }

    const selectSalesManager = new MultiSelectSearchInput({
      key: 'salesManagerId',
      hover: 'Sales Manager',
      type: 'text',
      classCss: 'first-filter-to-book',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: salesManagerFilter,
      value: [initialValueSalesManager],
    });

    const selectPpm = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'PPM',
      type: 'text',
      classCss: 'full-width-panel-options',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      largeInput: true,
      options: ppmFilter,
    });

    const selectMarketName = new MultiSelectSearchInput({
      key: 'marketName',
      hover: 'Market Name',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectSalesModel = new MultiSelectSearchInput({
      key: 'salesModel',
      hover: 'Sales Model',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectOrigin = new SelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: origin,
      value: origin[0].value,
      disabled: true,
    });

    this.searchInputsSalesManager[0] = selectSalesManager;
    this.searchInputsSalesManager[1] =
      this.createAndAssignCustomerShortSearchInput(customerShortFilter);
    this.searchInputsSalesManager[1].disabled =
      Object.keys(selectSalesManager).length > 0 &&
      Object.keys(customerShortFilter).length > 0
        ? false
        : true;
    this.searchInputsSalesManager[1].notClearable = this
      .searchInputsSalesManager[1].disabled
      ? true
      : false;
    this.searchInputsSalesManager[2] = selectPpm;
    this.searchInputsSalesManager[3] = selectMarketName;
    this.searchInputsSalesManager[4] = selectSalesModel;
    this.searchInputsSalesManager[5] = selectOrigin;
    this.searchInputsSalesManager[5].notClearable = true;

    this.searchInputsSalesManager = [...this.searchInputsSalesManager];
  }

  dataFilterCustomerShort(...response: any) {
    const orderAdminsFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const ppmFilter: any[] = [];
    const origin: any[] = [];
    this.customerShortNoFilter = [];

    let orderAdminsEntities = response[0];
    let customerShortEntities = response[2];
    let kaManagementPPMEntities = response[1];

    customerShortEntities.forEach((item: any) => {
      customerShortFilter.push({
        value: item.customerShort,
        id: item.id,
      });
    });

    const initialValueOrderAdmin: { [key: string]: any } = {};
    orderAdminsEntities.forEach((item: User) => {
      const viewName: string = `${item.last_name}, ${item.name}`;
      if (
        orderAdminsFilter.findIndex(
          obj => obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
        ) === -1
      ) {
        orderAdminsFilter.push({ value: viewName, id: item.id });
        if (item.email.includes(this.currentUser.email)) {
          initialValueOrderAdmin.value = viewName;
          initialValueOrderAdmin.id = item.id;
        }
      }
    });

    const customerShortIds: Set<number> = new Set();
    if (initialValueOrderAdmin.id || initialValueOrderAdmin.value) {
      const matchingItems = customerShortEntities.filter(
        (item: any) => item.orderAdminId == initialValueOrderAdmin.id
      );
      const firstMatchingItem = matchingItems[0];

      customerShortEntities.filter((item: any) => {
        if (
          item.orderAdminId == initialValueOrderAdmin.id &&
          customerShortIds.has(item.id)
        ) {
          customerShortFilter.push({
            value: item.customerShort,
            id: item.id,
          });
        }
        customerShortIds.add(item.id);
      });

      this.formSearchCustomer = {
        orderAdminId: [initialValueOrderAdmin.id],
        customerShortId: firstMatchingItem ? [firstMatchingItem.id] : [],
        internalName: [],
        marketName: [],
        materialId: [],
        salesManagerId: [],
        origin: 'ALL',
        ppm: [],
        report: 'customer',
        salesModel: [],
      };
    } else {
      this.formSearchCustomer = {
        orderAdminId: [],
        customerShortId: [],
        internalName: [],
        marketName: [],
        materialId: [],
        salesManagerId: [],
        origin: 'ALL',
        ppm: [],
        report: 'customer',
        salesModel: [],
      };
    }

    kaManagementPPMEntities.forEach((item: any) => {
      if (
        item.productDesc &&
        ppmFilter.findIndex(
          obj => obj.value.toUpperCase() === item.allocationBz?.toUpperCase()
        ) === -1
      ) {
        ppmFilter.push({
          key: item.productDesc,
          value: item.productDesc,
          id: item.productId,
        });
      }
    });

    origin.push({ value: 'ALL' }, { value: 'JAG' }, { value: 'MAN' });

    const selectCustomerShort = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      type: 'text',
      classCss: 'first-filter-to-book',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: customerShortFilter,
    });

    const selectMultiOrderAdmin: any = new MultiSelectSearchInput({
      key: 'orderAdminId',
      hover: 'Order Admin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: orderAdminsFilter,
      value: [initialValueOrderAdmin],
    });

    const selectPpm = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'PPM',
      type: 'text',
      classCss: 'full-width-panel-options',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      largeInput: true,
      options: ppmFilter,
    });

    const selectMarketName = new MultiSelectSearchInput({
      key: 'marketName',
      hover: 'Market Name',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectSalesModel = new MultiSelectSearchInput({
      key: 'salesModel',
      hover: 'Sales Model',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectOrigin = new SelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: origin,
      value: origin[0].value,
      disabled: true,
    });

    this.searchInputsCustomer[0] = selectCustomerShort;
    this.searchInputsCustomer[1] = selectMultiOrderAdmin;
    this.searchInputsCustomer[1].disabled = selectCustomerShort.value
      ? false
      : true;
    this.searchInputsCustomer[1].notClearable = true;
    this.searchInputsCustomer[2] = selectPpm;
    this.searchInputsCustomer[3] = selectMarketName;
    this.searchInputsCustomer[4] = selectSalesModel;
    this.searchInputsCustomer[4].notClearable = true;
    this.searchInputsCustomer[5] = selectOrigin;
    this.searchInputsCustomer[5].notClearable = true;

    this.searchInputsCustomer = [...this.searchInputsCustomer];
  }

  dataFilterProduct(...response: any) {
    const orderAdminsFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const customerShortFilterValue: any[] = [];
    const ppmFamily: any[] = [];
    const salesManagerFilter: any[] = [];
    const origin: any[] = [];
    this.customerShortNoFilter = [];

    let orderAdminsEntities = response[0];
    let customerShortEntities = response[2];
    let familyEntities = response[1];
    let salesManagerEntities = response[4];

    familyEntities.forEach((item: any) => {
      ppmFamily.push({
        id: item.ids,
        value: item.family,
      });
    });

    customerShortEntities.forEach((item: any) => {
      customerShortFilter.push({
        value: item.customerShort,
        id: item.id,
      });
    });

    origin.push({ value: 'ALL' }, { value: 'JAG' }, { value: 'MAN' });

    const initialValueSalesManager: { [key: string]: any } = {};
    salesManagerEntities.forEach((item: any) => {
      const viewSalesManager: string = `${item.last_name}, ${item.name}`;
      if (
        salesManagerFilter.findIndex(
          obj =>
            obj.value.toLocaleUpperCase() ===
            viewSalesManager.toLocaleUpperCase()
        ) === -1
      ) {
        salesManagerFilter.push({ value: viewSalesManager, id: item.id });
        if (
          item.email.includes(this.currentUser.email) &&
          this.currentUser.profileType.includes('salesManager')
        ) {
          initialValueSalesManager.value = viewSalesManager;
          initialValueSalesManager.id = item.id;
        }
      }
    });

    const initialValueOrderAdmin: { [key: string]: any } = {};
    orderAdminsEntities.forEach((item: User) => {
      const viewName: string = `${item.last_name}, ${item.name}`;
      if (
        orderAdminsFilter.findIndex(
          obj => obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
        ) === -1
      ) {
        orderAdminsFilter.push({ value: viewName, id: item.id });

        if (
          item.email.includes(this.currentUser.email) &&
          this.currentUser.profileType.includes('orderAdmin')
        ) {
          initialValueOrderAdmin.value = viewName;
          initialValueOrderAdmin.id = item.id;
        }
      }
    });

    const isOrderAdminDefined =
      initialValueOrderAdmin.id || initialValueOrderAdmin.value;
    const isSalesManagerDefined =
      initialValueSalesManager.id || initialValueSalesManager.value;

    let customerShortId;

    if (isSalesManagerDefined) {
      customerShortEntities.filter((item: any) => {
        if (item.salesManagerId == initialValueSalesManager.id) {
          customerShortFilterValue.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });
    }

    if (isOrderAdminDefined) {
      const matchingItems = customerShortEntities.filter(
        (item: any) => item.orderAdminId == initialValueOrderAdmin.id
      );
      const firstMatchingItem = matchingItems[0];
      customerShortEntities.filter((item: any) => {
        if (item.orderAdminId == initialValueOrderAdmin.id) {
          customerShortFilterValue.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });
      customerShortId = firstMatchingItem ? [firstMatchingItem.id] : [];
    } else if (isSalesManagerDefined) {
      const matchingItems = customerShortEntities.filter(
        (item: any) => item.salesManagerId == initialValueSalesManager.id
      );
      const firstMatchingItem = matchingItems[0];
      customerShortEntities.filter((item: any) => {
        if (item.salesManagerId == initialValueSalesManager.id) {
          customerShortFilterValue.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });
      customerShortId = firstMatchingItem ? [firstMatchingItem.id] : [];
    }

    this.formSearchProduct = {
      orderAdminId: isOrderAdminDefined ? [initialValueOrderAdmin.id] : [],
      salesManagerId: isSalesManagerDefined
        ? [initialValueSalesManager.id]
        : [],
      customerShortId: customerShortId ? customerShortId : [],
      internalName: [],
      marketName: [],
      materialId: [],
      origin: 'ALL',
      ppm: [],
      report: 'product',
      salesModel: [],
    };

    const selectPpm = new MultiSelectSearchInput({
      key: 'materialId',
      hover: 'Family',
      type: 'text',
      classCss: 'first-filter-to-book',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: ppmFamily,
    });

    const selectCustomerShort = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: customerShortFilter,
      disabled: true,
      value: customerShortFilterValue,
    });

    const selectMarketName = new MultiSelectSearchInput({
      key: 'marketName',
      hover: 'Market Name',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: [],
      disabled: true,
    });

    const selectMultiOrderAdmin = new MultiSelectSearchInput({
      key: 'orderAdminId',
      hover: 'Order Admin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: orderAdminsFilter,
      disabled: true,
      value: [initialValueOrderAdmin],
    });

    const selectSalesManager = new MultiSelectSearchInput({
      key: 'salesManagerId',
      hover: 'Sales Manager',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: salesManagerFilter,
      disabled: true,
      value: [initialValueSalesManager],
    });

    const selectOrigin = new SelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: origin,
      value: origin[0].value,
      disabled: true,
    });

    this.searchInputsProduct[0] = selectPpm;
    this.searchInputsProduct[1] = selectCustomerShort;
    this.searchInputsProduct[1].notClearable = true;
    this.searchInputsProduct[2] = selectMarketName;
    this.searchInputsProduct[3] = selectMultiOrderAdmin;
    this.searchInputsProduct[3].notClearable = true;
    this.searchInputsProduct[4] = selectSalesManager;
    this.searchInputsProduct[4].notClearable = true;
    this.searchInputsProduct[5] = selectOrigin;
    this.searchInputsProduct[5].notClearable = true;

    this.searchInputsProduct = [...this.searchInputsProduct];
  }

  applyFilter(tab?: any) {
    if (tab === 'orderAdmin') {
      this.applyFilterOrderAdmin(tab);
    }
    if (tab === 'salesManager') {
      this.applyFilterSalesManager(tab);
    }
    if (tab === 'customer') {
      this.applyFilterCustomer(tab);
    }
    if (tab === 'product') {
      this.applyFilterProduct(tab);
    }
  }

  private applyFilterOrderAdmin(tab: any) {
    this.spinner.show();
    let formSearchValues = this.formSearchOrderAdmin;
    let formOrderAdmin = this.deleteProperty(formSearchValues);
    this.orderAdminSelected = false;
    this.isValid = true;

    const dataOrderAdmin = this.getDataTableOrderAdmin(tab, formOrderAdmin);
    this.hideSpinnerAfterAll([dataOrderAdmin]);
  }

  private applyFilterSalesManager(tab: any) {
    this.spinner.show();
    let formSearchValues = this.formSearchSalesManager;
    let formSalesManager = this.deleteProperty(formSearchValues);
    this.salesManagerSelected = false;

    const dataSalesManager = this.getDataTableSalesManager(
      tab,
      formSalesManager
    );
    this.hideSpinnerAfterAll([dataSalesManager]);
  }

  private applyFilterCustomer(tab: any) {
    this.spinner.show();
    let formSearchValues = this.formSearchCustomer;
    let formCustomer = this.deleteProperty(formSearchValues);
    this.customerShortSelected = false;
    const dataCustomer = this.getDataTableCustomer(tab, formCustomer);
    this.hideSpinnerAfterAll([dataCustomer]);
  }

  private applyFilterProduct(tab: any) {
    this.spinner.show();
    let formSearchValues = this.formSearchProduct;

    let formProduct = this.deleteProperty(formSearchValues);
    this.productSelected = false;
    const dataProduct = this.getDataTableProduct(tab, formProduct);
    this.hideSpinnerAfterAll([dataProduct]);
  }

  prepareToGetExport(data: any, report: any) {
    let dict: any = {};
    const products: any = [];

    products.push(...data.rows.jag, ...data.rows.man);

    for (let i = 0; i < products.length; i += 1) {
      const {
        orderAdminId,
        salesManagerId,
        customerShort,
        customerShortId,
        ppm,
      } = products[i];
      const key = `${customerShort}_${orderAdminId}_${salesManagerId}`;
      if (dict[key]) {
        if (!dict[key].ppms.includes(ppm))
          dict[key].ppms = `${dict[key].ppms},${ppm}`;
      } else {
        dict[key] = {
          customerShort,
          customerShortId,
          orderAdminId: orderAdminId,
          salesManagerId: salesManagerId,
          ppms: ppm,
        };
      }
    }
    this.getExport(dict, report);
  }

  getDataTableOrderAdmin(report?: any, params?: any): Promise<void> {
    this.dataFinalOrderAdmin = '';
    return new Promise((resolve, reject) => {
      this.crudService
        .postEntity(`tobook/allocation-to-book-report`, params)
        .subscribe(
          (response: any) => {
            if (response == '') {
              this.noResultOrderAdmin = true;
            } else {
              this.noResultOrderAdmin = false;
              this.dataFinalOrderAdmin = this.formatDataFront(response, report);
              this.dataOrderAdminAllocate = this.dataFinalOrderAdmin.allocate;
              this.dataOrderAdminToBook = this.dataFinalOrderAdmin.toBook;
              this.prepareToGetExport(this.dataOrderAdminToBook, report);
            }
            resolve();
          },
          error => {
            reject(error);
            this.noResultOrderAdmin = true;
          }
        );
    });
  }

  getDataTableSalesManager(report?: any, params?: any): Promise<void> {
    this.dataFinalSalesManager = '';
    return new Promise((resolve, reject) => {
      this.crudService
        .postEntity(`tobook/allocation-to-book-report`, params)
        .subscribe(
          (response: any) => {
            if (response == '') {
              this.noResultSalesManager = true;
            } else {
              this.noResultSalesManager = false;
              this.dataFinalSalesManager = this.formatDataFront(
                response,
                report
              );
              this.dataSalesManagerAllocate =
                this.dataFinalSalesManager.allocate;
              this.dataSalesManagerToBook = this.dataFinalSalesManager.toBook;
              this.prepareToGetExport(this.dataSalesManagerToBook, report);
            }
            resolve();
          },
          error => {
            reject(error);
            this.noResultSalesManager = true;
          }
        );
    });
  }

  getDataTableCustomer(report?: any, params?: any): Promise<void> {
    this.dataFinalCustomer = '';
    return new Promise((resolve, reject) => {
      this.crudService
        .postEntity(`tobook/allocation-to-book-report`, params)
        .subscribe(
          (response: any) => {
            if (response == '') {
              this.noResultCustomerShort = true;
            } else {
              this.noResultCustomerShort = false;
              this.dataFinalCustomer = this.formatDataFront(response, report);
              this.dataCustomerShortAllocate = this.dataFinalCustomer.allocate;
              this.dataCustomerShortToBook = this.dataFinalCustomer.toBook;
              this.prepareToGetExport(this.dataCustomerShortToBook, report);
            }
            resolve();
          },
          error => {
            reject(error);
            this.noResultCustomerShort = true;
          }
        );
    });
  }

  getDataTableProduct(report?: any, params?: any): Promise<void> {
    this.dataFinalProduct = '';
    return new Promise((resolve, reject) => {
      this.crudService
        .postEntity(`tobook/allocation-to-book-report`, params)
        .subscribe(
          (response: any) => {
            if (response == '') {
              this.noResultProduct = true;
            } else {
              this.noResultProduct = false;
              this.dataFinalProduct = this.formatDataFront(response, report);
              this.dataProductAllocate = this.dataFinalProduct.allocate;
              this.dataProductToBook = this.dataFinalProduct.toBook;
              this.prepareToGetExport(this.dataProductToBook, report);
            }
            resolve();
          },
          error => {
            reject(error);
            this.noResultProduct = true;
          }
        );
    });
  }

  tabClick(event: any) {
    this.changeTab(event.target.innerText);
  }

  changeTab(label: any) {
    if (this.tabsTeste[label] || this.tabsTeste[label] === 0) {
      this.selectedTabIndex = this.tabsTeste[label];
      this.controlExportButton();
    }
  }

  controlExportButton() {
    if (this.selectedTabIndex == 0 && this.dataFinalOrderAdmin != '') {
      this.isValid = true;
    } else if (this.selectedTabIndex == 1 && this.dataFinalSalesManager != '') {
      this.isValid = true;
    } else if (this.selectedTabIndex == 2 && this.dataFinalCustomer != '') {
      this.isValid = true;
    } else if (this.selectedTabIndex == 3 && this.dataFinalProduct != '') {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  getExport(dict: any, report?: any) {
    let TypeHeaderExport: any;

    if (report === 'orderAdmin') {
      TypeHeaderExport = this.dataFinalOrderAdmin;
    } else if (report === 'salesManager') {
      TypeHeaderExport = this.dataFinalSalesManager;
    } else if (report === 'customer') {
      TypeHeaderExport = this.dataFinalCustomer;
    } else if (report === 'product') {
      TypeHeaderExport = this.dataFinalProduct;
    }

    this.crudService
      .postEntity('tobook/allocation-to-book/export', {
        products: dict,
      })
      .subscribe((response: any) => {
        const fullExport = Object.assign(response, TypeHeaderExport);
        this.exportComponents(fullExport);
        this.isValid = this.fieldsValid.every(item => {
          return item != '';
        });
      });
  }

  allTabs() {
    this.tabs = [
      {
        label: 'Order Admin',
        tab: 'ORDER ADMIN',
        template: this.OrderAdmin,
      },
      {
        label: 'Sales Manager',
        tab: 'SALES MANAGER',
        template: this.SalesManager,
      },
      {
        label: 'Customer',
        tab: 'CUSTOMER',
        template: this.Customer,
      },
      {
        label: 'Product',
        tab: 'PRODUCT',
        template: this.Product,
      },
    ];
  }

  deleteProperty(item: any) {
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  tableReport(report: any, search: any) {
    return this.crudService.getEntity(
      `tobook/allocation-to-book-report`,
      search
    );
  }

  dataFormSearchSalesManager(formSearchName: any, tab: any) {
    if (formSearchName.nameFormControl === 'salesManagerId') {
      if (formSearchName.salesManagerId) {
        const customerOptions: any = [];
        this.customerShortAllData.forEach((item: any) => {
          formSearchName.salesManagerId.forEach((data: any) => {
            if (data.id == item.salesManagerId) {
              customerOptions.push({
                value: item.customerShort,
                id: item.id,
              });
            }
          });
        });

        const currentSalesManager = formSearchName.salesManagerId.map(
          (item: any) => item.id
        );
        formSearchName.salesManagerId = currentSalesManager
          ? currentSalesManager
          : [];

        this.searchInputsSalesManager[1] =
          this.createAndAssignCustomerShortSearchInput(customerOptions);

        if (formSearchName.orderAdminId?.length > 0) {
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          formSearchName.orderAdminId = currentOrderAdmin;
          formSearchName.orderAdminId = currentOrderAdmin;
        } else {
          formSearchName.orderAdminId = formSearchName.orderAdminId;
        }
        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );
        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];

        if (tab === 'product') {
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          formSearchName.materialId = currentFamily ? currentFamily : [];
        }
      } else {
        formSearchName.salesManagerId = [];
        const currentOrderAdmin = formSearchName.orderAdminId?.map(
          (item: any) => item.id
        );

        formSearchName.orderAdminId = currentOrderAdmin
          ? currentOrderAdmin
          : [];
        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );

        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
        this.searchInputsSalesManager[1] =
          this.createAndAssignCustomerShortSearchInput();
        this.searchInputsSalesManager = [...this.searchInputsSalesManager];

        if (tab === 'product') {
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          formSearchName.materialId = currentFamily ? currentFamily : [];
        }
      }
    } else {
      if (formSearchName.salesManagerId) {
        let orderFinal = formSearchName.salesManagerId.map(
          (item: any) => item.id
        );
        formSearchName.salesManagerId = orderFinal;
      } else {
        formSearchName.salesManagerId = [];
      }
    }
  }

  dataFormSearchSalesManagerSales(formSearchName: any) {
    if (formSearchName.nameFormControl === 'salesManagerId') {
      if (formSearchName.salesManagerId) {
        const customerOptions: any = [];
        this.customerShortAllData.forEach((item: any) => {
          formSearchName.salesManagerId.forEach((data: any) => {
            if (data.id == item.salesManagerId) {
              customerOptions.push({
                value: item.customerShort,
                id: item.id,
              });
            }
          });
        });

        const currentSalesManager = formSearchName.salesManagerId.map(
          (item: any) => item.id
        );
        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );
        const currentSalesModel = formSearchName.salesModel?.map(
          (item: any) => item.value
        );

        formSearchName.salesManagerId = currentSalesManager;
        this.searchInputsSalesManager[1] =
          this.createAndAssignCustomerShortSearchInput(customerOptions);

        if (formSearchName.orderAdminId?.length > 0) {
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          formSearchName.orderAdminId = currentOrderAdmin;
        } else {
          formSearchName.orderAdminId = formSearchName.orderAdminId;
        }

        const salesM = this.searchInputsSalesManager[1].options?.map(
          (item: any) => {
            return item.id;
          }
        );
        formSearchName.customerShortId = salesM;
        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.salesModel = currentSalesModel ? currentSalesModel : [];
      } else {
        const tabFilter = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        this.searchInputsSalesManager[1] = tabFilter;
        formSearchName.salesManagerId = [];
        formSearchName.customerShortId = [];
        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );
        const currentSalesModel = formSearchName.salesModel?.map(
          (item: any) => item.value
        );

        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.salesModel = currentSalesModel ? currentSalesModel : [];
      }
    } else {
      if (formSearchName.salesManagerId) {
        let orderFinal = formSearchName.salesManagerId.map(
          (item: any) => item.id
        );
        formSearchName.salesManagerId = orderFinal;
      } else {
        formSearchName.salesManagerId = [];
      }
    }
  }

  dataformSearchOrderAdmin(formSearchName: any, tab?: any) {
    const customerOptions: any = [];
    if (formSearchName.nameFormControl === 'orderAdminId') {
      let viewName: string = '';

      if (formSearchName.orderAdminId) {
        this.customerShortAllData.forEach((item: any) => {
          if (item.orderAdmin !== null) {
            viewName = `${item.orderAdmin.last_name}, ${item.orderAdmin.name}`;
            formSearchName.orderAdminId.forEach((data: any) => {
              if (data.value == viewName) {
                customerOptions.push({
                  value: item.customerShort,
                  id: item.id,
                });
              }
            });
          }
        });

        const customerShort = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: customerOptions,
          value: customerOptions,
        });

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[1] = customerShort;
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );

          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          const currentCustomerShort =
            this.searchInputsOrderAdmin[1].options?.map((item: any) => item.id);

          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          formSearchName.materialId = currentFamily ? currentFamily : [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];
          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];

          if (
            customerShort.value.length === 0 ||
            formSearchName.orderAdminId.length === 0
          ) {
            this.searchInputsOrderAdmin[1].disabled = true;
          }
        }
        if (tab === 'product') {
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );

          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          formSearchName.materialId = currentFamily ? currentFamily : [];
          formSearchName.salesModel = currentSalesModel ? currentFamily : [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];
          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
        }
      } else {
        formSearchName.orderAdminId = [];

        if (tab === 'orderAdmin') {
          const customerShort = new MultiSelectSearchInput({
            key: 'customerShortId',
            hover: 'Customer Short',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: [],
            disabled: true,
          });

          const selectOrigin = new SelectSearchInput({
            key: 'origin',
            hover: 'Origin',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: [],
            value: 'ALL',
            disabled: true,
          });

          this.searchInputsOrderAdmin[1] = customerShort;
          this.searchInputsOrderAdmin[5] = selectOrigin;

          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );

          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          formSearchName.materialId = currentFamily ? currentFamily : [];
          formSearchName.customerShortId = customerShort.options;
          formSearchName.origin = selectOrigin.value;
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
        }

        if (tab === 'product') {
          const selectOrigin = new SelectSearchInput({
            key: 'origin',
            hover: 'Origin',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: [],
            value: 'ALL',
            disabled: true,
          });
          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );
          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );
          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          this.searchInputsOrderAdmin[5] = selectOrigin;

          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          formSearchName.materialId = currentFamily ? currentFamily : [];
          formSearchName.customerShortId = currentCustomerShort;
          formSearchName.origin = selectOrigin.value;
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
        }
      }
    }
  }

  dataformSearchOrderAdminProduct(formSearchName: any) {
    const customerOptions: any = [];
    if (formSearchName.nameFormControl === 'orderAdminId') {
      let viewName: string = '';

      if (formSearchName.orderAdminId) {
        this.customerShortAllData.forEach((item: any) => {
          if (item.orderAdmin !== null) {
            viewName = `${item.orderAdmin.last_name}, ${item.orderAdmin.name}`;
            formSearchName.orderAdminId.forEach((data: any) => {
              if (data.value == viewName) {
                customerOptions.push({
                  value: item.customerShort,
                  id: item.id,
                });
              }
            });
          }
        });

        const tabFilter =
          this.createAndAssignCustomerShortSearchInput(customerOptions);
        this.searchInputsOrderAdmin[1] = tabFilter;

        const currentOrderAdmin = formSearchName.orderAdminId?.map(
          (item: any) => item.id
        );

        const currentMarketName = this.searchInputsCustomer[3].options?.map(
          (item: any) => {
            return item.value;
          }
        );

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );

        const currentSalesModel = formSearchName.salesModel?.map(
          (item: any) => item.value
        );

        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.salesModel = currentSalesModel ? currentSalesModel : [];
        formSearchName.orderAdminId = currentOrderAdmin
          ? currentOrderAdmin
          : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
      } else {
        this.searchInputsOrderAdmin[1].options = [];
        const selectOrigin = new SelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          value: 'ALL',
          disabled: true,
        });

        this.searchInputsOrderAdmin[5] = selectOrigin;

        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );

        const currentSalesModel = formSearchName.salesModel?.map(
          (item: any) => item.value
        );

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );

        formSearchName.orderAdminId = [];
        formSearchName.origin = selectOrigin.value;
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.salesModel = currentSalesModel ? currentSalesModel : [];
      }
    }
  }

  createAndAssignCustomerShortSearchInput(customerOptions?: any) {
    let tabFilter;

    if (customerOptions && customerOptions.length > 0) {
      tabFilter = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        options: customerOptions,
        value: customerOptions,
      });
    } else {
      tabFilter = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        options: [],
        disabled: true,
      });
    }
    return tabFilter;
  }

  removeDuplicatas(array: any[]): any[] {
    const valoresUnicos = new Set<string>();
    const resultado: any[] = [];

    for (const objeto of array) {
      const chave = JSON.stringify(objeto);

      if (!valoresUnicos.has(chave)) {
        valoresUnicos.add(chave);
        resultado.push(objeto);
      }
    }

    return resultado;
  }

  dataformSearchCustomerShort(formSearchName: any, tab?: string) {
    if (formSearchName.nameFormControl === 'customerShortId') {
      let viewName: string = '';
      let viewNameSales: string = '';

      const orderAdminsFilter: any[] = [];
      const SalesManagerFilter: any[] = [];
      const orderAdminsFilterList: any[] = [];
      const SalesManagerFilterList: any[] = [];

      if (formSearchName.customerShortId.length > 0) {
        this.customerShortAllData.forEach((item: any) => {
          if (item.orderAdmin !== null) {
            formSearchName.customerShortId.forEach((data: any) => {
              if (data.value == item.customerShort) {
                viewName = `${item.orderAdmin?.last_name}, ${item.orderAdmin?.name}`;
                viewNameSales = `${item.salesManager?.last_name}, ${item.salesManager?.name}`;
                orderAdminsFilter.push({
                  value: viewName,
                  id: item.orderAdminId,
                });
              } else {
                orderAdminsFilterList.push({
                  value: viewName,
                  id: item.orderAdminId,
                });
              }
            });
          }
          if (item.salesManager !== null) {
            formSearchName.customerShortId.forEach((data: any) => {
              if (data.value == item.customerShort) {
                viewName = `${item.orderAdmin?.last_name}, ${item.orderAdmin?.name}`;
                viewNameSales = `${item.salesManager?.last_name}, ${item.salesManager?.name}`;
                SalesManagerFilter.push({
                  value: viewNameSales,
                  id: item.salesManagerId,
                });
              }
            });
          }
        });

        const resultNoDuplicatas = this.removeDuplicatas(orderAdminsFilter);

        const resultNoDuplicatasSales =
          this.removeDuplicatas(SalesManagerFilter);

        const actualOrderAdmins = new MultiSelectSearchInput({
          key: 'orderAdminId',
          hover: 'Order Admin',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: resultNoDuplicatas,
          value: resultNoDuplicatas,
        });

        const actualSalesManager = new MultiSelectSearchInput({
          key: 'salesManagerId',
          hover: 'Sales Manager',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: resultNoDuplicatasSales,
          value: resultNoDuplicatasSales,
        });

        if (tab === 'customer') {
          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );
          this.searchInputsCustomer[1] = actualOrderAdmins;
          const currentOrderAdmin = this.searchInputsCustomer[1].options?.map(
            (item: any) => item.id
          );

          const currentMarketName = this.searchInputsCustomer[3].options?.map(
            (item: any) => {
              return item.value;
            }
          );
          const currentSalesModel = this.searchInputsCustomer[4].options?.map(
            (item: any) => item.value
          );

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];

          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
        }

        if (tab === 'product') {
          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );
          const currentMarketName = this.searchInputsProduct[2].options?.map(
            (item: any) => item.value
          );
          const currentSalesManager = actualSalesManager.options?.map(
            (item: any) => {
              return item;
            }
          );

          this.searchInputsProduct[3] = actualOrderAdmins;
          this.searchInputsProduct[4] = actualSalesManager;

          formSearchName.materialId = currentFamily ? currentFamily : [];
          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];

          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];

          formSearchName.salesManagerId = currentSalesManager
            ? currentSalesManager
            : [];
        }

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[0] = actualOrderAdmins;
          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );
          const currentMarketName = this.searchInputsOrderAdmin[3].options?.map(
            (item: any) => item.value
          );
          const currentSalesModel = this.searchInputsOrderAdmin[4].options?.map(
            (item: any) => item.value
          );

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];

          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
        }

        if (tab === 'salesManager') {
          const currentCustomerShort =
            this.searchInputsSalesManager[1].options?.map(
              (item: any) => item.id
            );

          const currentSalesManager = formSearchName.salesManagerId?.map(
            (item: any) => item
          );

          const currentMarketName =
            this.searchInputsSalesManager[3].options?.map(
              (item: any) => item.value
            );
          const currentSalesModel =
            this.searchInputsSalesManager[4].options?.map(
              (item: any) => item.value
            );

          formSearchName.salesManagerId = currentSalesManager
            ? currentSalesManager
            : [];

          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];

          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
        }
      } else {
        const actualSalesManager = new MultiSelectSearchInput({
          key: 'salesManagerId',
          hover: 'Sales Manager',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
        });

        const productActualOrderAdmins = new MultiSelectSearchInput({
          key: 'orderAdminId',
          hover: 'Order Admin',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: false,
        });

        const selectOrigin = new SelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          value: 'ALL',
          disabled: true,
        });

        if (tab === 'product') {
          this.orderAdminFullData.forEach((item: any) => {
            if (item.orderAdmin !== null) {
              const viewName = `${item.last_name}, ${item.name}`;
              orderAdminsFilterList.push({
                value: viewName,
                id: item.id,
              });
            }
          });

          this.salesManagerFullData.forEach((item: any) => {
            if (item.salesManagerId !== null) {
              const viewName = `${item.last_name}, ${item.name}`;
              SalesManagerFilterList.push({
                value: viewName,
                id: item.id,
              });
            }
          });

          const resultNoDuplicatasList = this.removeDuplicatas(
            orderAdminsFilterList
          );
          const resultNoDuplicatasListSales = this.removeDuplicatas(
            SalesManagerFilterList
          );

          let idSales: any[] = [];
          SalesManagerFilterList.forEach((item: any) => {
            idSales.push(item);
          });

          let idOrderAdmin: any[] = [];
          orderAdminsFilterList.forEach((item: any) => {
            idOrderAdmin.push(item.id);
          });

          const actualOrderAdminsList = new MultiSelectSearchInput({
            key: 'orderAdminId',
            hover: 'Order Admin',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: resultNoDuplicatasList,
          });
          const actualSalesManagerList = new MultiSelectSearchInput({
            key: 'salesManagerId',
            hover: 'Sales Manager',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: resultNoDuplicatasListSales,
          });

          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });

          formSearchName.customerShortId = [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          this.searchInputsProduct[3] = actualOrderAdminsList;
          this.searchInputsProduct[4] = actualSalesManagerList;

          formSearchName.materialId = currentFamily;
          formSearchName.salesManagerId = idSales;
          formSearchName.orderAdminId = idOrderAdmin;
        }

        if (tab === 'salesManager') {
          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );

          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          formSearchName.customerShortId = [];
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];

          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
        }

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[5] = selectOrigin;
          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );

          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];

          formSearchName.customerShortId = [];
          formSearchName.origin = selectOrigin.value;
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
        }

        if (tab === 'customer') {
          const productActualOrderAdmins = new MultiSelectSearchInput({
            key: 'orderAdminId',
            hover: 'Order Admin',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: [],
            disabled: true,
          });
          this.searchInputsCustomer[1] = productActualOrderAdmins;
          this.searchInputsCustomer[5] = selectOrigin;

          const currentMarketName = formSearchName.marketName?.map(
            (item: any) => item.value
          );
          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );

          formSearchName.customerShortId = [];
          formSearchName.origin = selectOrigin.value;
          formSearchName.marketName = currentMarketName
            ? currentMarketName
            : [];
          formSearchName.salesModel = currentSalesModel
            ? currentSalesModel
            : [];
          formSearchName.orderAdminId = [];
          this.searchInputsCustomer = [...this.searchInputsCustomer];
        }
      }
    }
  }

  dataformSearchPpm(formSearchName: any, tab: string) {
    if (formSearchName.nameFormControl === 'ppm') {
      if (formSearchName.ppm.length > 0 || formSearchName.ppm) {
        const currentPPM = formSearchName.ppm.map((item: any) => item.value);
        formSearchName.ppm = currentPPM;
        const marketNameOptions: any = [];
        const salesModelOptions: any = [];
        const internalNameOptions: any = [];
        const auxMkt: any = [];
        const mktFinal: any = [];
        this.materialsAllData.forEach((item: any) => {
          formSearchName.ppm.forEach((data: any) => {
            if (item.allocationBz == data) {
              marketNameOptions.push({
                id: item.id,
                value: item.marketName.toUpperCase(),
              });
              salesModelOptions.push({
                id: item.id,
                value: item.salesModel,
              });
              internalNameOptions.push({
                id: item.id,
                value: item.internalName,
              });
            }
          });
        });

        marketNameOptions.forEach((item: any) => {
          auxMkt.push(item.value);
        });
        const aux = new Set(auxMkt);
        aux.forEach((item: any) => {
          let count = 0;
          mktFinal.push({
            id: (count += 1),
            value: item,
          });
        });
        const sales = salesModelOptions.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );
        const marketNameOrder = mktFinal.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );
        const internalNameOrder = internalNameOptions.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: marketNameOrder,
        });

        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: sales,
        });

        const actualMarketNameNotValue = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
          value: [],
        });
        const actualSalesModelNotValue = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
          value: [],
        });

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[5].disabled = false;
          this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );
          const currenCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];
          formSearchName.customerShortId = currenCustomerShort
            ? currenCustomerShort
            : [];

          const currentMarketName = this.searchInputsOrderAdmin[3].options?.map(
            (item: any) => item.value
          );
          const currentSalesModel = this.searchInputsOrderAdmin[4].options?.map(
            (item: any) => item.value
          );

          if (formSearchName.ppm.length > 0) {
            this.searchInputsOrderAdmin[3] = actualMarketName;
            this.searchInputsOrderAdmin[4] = actualSalesModel;
            formSearchName.marketName = currentMarketName;
            formSearchName.salesModel = currentSalesModel;
          } else {
            this.searchInputsOrderAdmin[3] = actualMarketNameNotValue;
            this.searchInputsOrderAdmin[4] = actualSalesModelNotValue;
            formSearchName.marketName = [];
            formSearchName.salesModel = [];
          }

          this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
        }
        if (tab === 'salesManager') {
          this.searchInputsSalesManager[5].disabled = false;
          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
          const currenCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          const currentMarketName =
            this.searchInputsSalesManager[3].options?.map(
              (item: any) => item.value
            );

          const currentSalesModel =
            this.searchInputsSalesManager[4].options?.map(
              (item: any) => item.value
            );

          if (formSearchName.ppm.length > 0) {
            this.searchInputsSalesManager[3] = actualMarketName;
            this.searchInputsSalesManager[4] = actualSalesModel;
            formSearchName.marketName = currentMarketName;
            formSearchName.salesModel = currentSalesModel;
          } else {
            this.searchInputsSalesManager[3] = actualMarketNameNotValue;
            this.searchInputsSalesManager[4] = actualSalesModelNotValue;
            formSearchName.marketName = [];
            formSearchName.salesModel = [];
          }

          formSearchName.customerShortId = currenCustomerShort
            ? currenCustomerShort
            : [];
        }
        if (tab === 'customer') {
          this.searchInputsCustomer[5].disabled = false;
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          const currenCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          formSearchName.customerShortId = currenCustomerShort
            ? currenCustomerShort
            : [];

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];

          const currentMarketName = this.searchInputsCustomer[3].options?.map(
            (item: any) => item.value
          );

          const currentSalesModel = this.searchInputsCustomer[4].options?.map(
            (item: any) => item.value
          );

          if (formSearchName.ppm.length > 0) {
            this.searchInputsCustomer[3] = actualMarketName;
            this.searchInputsCustomer[4] = actualSalesModel;
            formSearchName.marketName = currentMarketName;
            formSearchName.salesModel = currentSalesModel;
          } else {
            this.searchInputsCustomer[3] = actualMarketNameNotValue;
            this.searchInputsCustomer[4] = actualSalesModelNotValue;
            formSearchName.marketName = [];
            formSearchName.salesModel = [];
          }
          this.searchInputsCustomer = [...this.searchInputsCustomer];
        }
        if (tab === 'product') {
          this.searchInputsProduct[2] = actualMarketName;
          this.searchInputsProduct[1].disabled = false;
          this.searchInputsProduct[3].disabled = false;
          this.searchInputsProduct = [...this.searchInputsProduct];

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          const currenCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          formSearchName.customerShortId = currenCustomerShort;

          formSearchName.orderAdminId = currentOrderAdmin;
        }
      } else {
        formSearchName.ppm = [];
        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
          value: [],
        });
        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
          value: [],
        });

        if (tab === 'orderAdmin') {
          const selectOrigin = new SelectSearchInput({
            key: 'origin',
            hover: 'Origin',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            options: [],
            value: 'ALL',
            disabled: true,
          });

          this.searchInputsOrderAdmin[5] = selectOrigin;
          this.searchInputsOrderAdmin[3] = actualMarketName;
          this.searchInputsOrderAdmin[4] = actualSalesModel;

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          const currenCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          formSearchName.customerShortId = currenCustomerShort
            ? currenCustomerShort
            : [];

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];

          formSearchName.ppm = [];
          formSearchName.marketName = [];
          formSearchName.salesModel = [];
          formSearchName.origin = selectOrigin.value;
          this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
        }
        if (tab === 'salesManager') {
          this.searchInputsSalesManager[3] = actualMarketName;
          this.searchInputsSalesManager[4] = actualSalesModel;
          this.searchInputsSalesManager[5].disabled = true;

          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
          formSearchName.salesModel = [];
          formSearchName.marketName = [];
          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
        }
        if (tab === 'customer') {
          this.searchInputsCustomer[3] = actualMarketName;
          this.searchInputsCustomer[4] = actualSalesModel;
          this.searchInputsCustomer[5].disabled = true;

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          const currentCustomerShort = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];
          formSearchName.customerShortId = currentCustomerShort
            ? currentCustomerShort
            : [];
          formSearchName.marketName = [];
          formSearchName.salesModel = [];
          this.searchInputsCustomer = [...this.searchInputsCustomer];
        }
        if (tab === 'product') {
          this.searchInputsProduct[2] = actualMarketName;
          this.searchInputsProduct[2] = actualMarketName;
          this.searchInputsProduct[1].disabled = true;
          this.searchInputsProduct[4].disabled = true;
          this.searchInputsProduct = [...this.searchInputsProduct];

          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );

          formSearchName.orderAdminId = currentOrderAdmin
            ? currentOrderAdmin
            : [];
          formSearchName.customerShortId = formSearchName.customerShortId;
          formSearchName.marketName = [];
        }
      }
    } else {
      const currentPPM = formSearchName.ppm?.map((item: any) => item.value);
      formSearchName.ppm = formSearchName.ppm ? currentPPM : [];
    }
  }

  dataFormSearchFamily(formSearchName: any) {
    if (formSearchName.nameFormControl === 'materialId') {
      if (formSearchName.materialId) {
        const selectCustomerShort = new SelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        const selectSalesManager = new SelectSearchInput({
          key: 'salesManagerId',
          hover: 'Sales Manager',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        const selectOrderAdmin = new SelectSearchInput({
          key: 'orderAdminId',
          hover: 'OrderAdminId',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });

        const actualOrigin = new MultiSelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          value: 'ALL',
          disabled: true,
        });
        const marketNameOptions: any = [];
        const salesModelOptions: any = [];
        const internalNameValues: any = [];
        const internalNameOptions: any = [];
        const auxInternal: any = [];
        const auxMkt: any = [];
        const mktFinal: any = [];
        const currentIdsFamily: any = [];

        this.familyFullData.forEach((item: any) => {
          formSearchName.materialId.forEach((data: any) => {
            if (item.family == data.value) {
              item.ids.forEach((value: any) => {
                currentIdsFamily.push(value);
              });
            }
          });
        });

        this.materialsAllData.forEach((item: any) => {
          formSearchName.materialId.forEach((data: any) => {
            if (data.id.includes(item.id)) {
              marketNameOptions.push({
                id: item.id,
                value: item.marketName.toUpperCase(),
              });
              salesModelOptions.push({
                id: item.id,
                value: item.salesModel,
              });
              internalNameValues.push({
                id: item.id,
                value: item.internalName,
              });
            }
          });
        });

        marketNameOptions.forEach((item: any) => {
          auxMkt.push(item.value);
        });
        const aux = new Set(auxMkt);
        aux.forEach((item: any) => {
          let count = 0;
          mktFinal.push({
            id: (count += 1),
            value: item,
          });
        });

        internalNameValues.forEach((item: any) => {
          auxInternal.push(item.value);
        });
        let internalNameArray = new Set(auxInternal);
        internalNameArray.forEach((item: any) => {
          internalNameOptions.push({ value: item });
        });

        const marketNameOrder = mktFinal.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: marketNameOrder,
        });
        if (marketNameOrder.length > 0) {
          this.searchInputsProduct[2] = actualMarketName;
        }

        this.searchInputsProduct[1].disabled = false;
        this.searchInputsProduct[3].disabled = false;
        this.searchInputsProduct[4].disabled = false;
        this.searchInputsProduct[5].disabled = false;
        // this.searchInputsProduct = [...this.searchInputsProduct];

        let currentFamily: any[] = [];
        formSearchName.materialId?.forEach((item: any) => {
          item.id.map((item: any) => currentFamily.push(item));
        });

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );
        const currentMarketName = this.searchInputsProduct[2].options?.map(
          (item: any) => item.value
        );
        const currentOrderAdmin = formSearchName.orderAdminId?.map(
          (item: any) => item.id
        );
        const currentSalesManager = formSearchName.salesManagerId?.map(
          (item: any) => item
        );

        formSearchName.materialId = currentFamily ? currentFamily : [];
        formSearchName.marketName = currentMarketName ? currentMarketName : [];
        formSearchName.orderAdminId = currentOrderAdmin
          ? currentOrderAdmin
          : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
        formSearchName.salesManagerId = currentSalesManager
          ? currentSalesManager
          : [];

        if (formSearchName.materialId.length === 0) {
          this.searchInputsProduct[1].disabled = true;
          this.searchInputsProduct[3].disabled = true;
          this.searchInputsProduct[4].disabled = true;
          this.searchInputsProduct[5].disabled = true;
        }
      } else {
        formSearchName.ppm = [];
        formSearchName.materialId = [];
        const marketNameOptions: any = [];
        const auxMkt: any = [];
        const mktFinal: any = [];

        this.materialsAllData.forEach((item: any) => {
          marketNameOptions.push({
            id: item.id,
            value: item.marketName.toUpperCase(),
          });
        });

        marketNameOptions.forEach((item: any) => {
          auxMkt.push(item.value);
        });
        const aux = new Set(auxMkt);
        aux.forEach((item: any) => {
          let count = 0;
          mktFinal.push({
            id: (count += 1),
            value: item,
          });
        });

        const marketNameOrder = mktFinal.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: marketNameOrder,
          value: [],
        });

        this.searchInputsProduct[2] = actualMarketName;
        formSearchName.marketName = [];

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );
        const currentOrderAdmin = formSearchName.orderAdminId.options?.map(
          (item: any) => item.id
        );
        const currentSalesManager = formSearchName.salesManagerId?.map(
          (item: any) => item
        );

        formSearchName.orderAdminId = currentOrderAdmin
          ? currentOrderAdmin
          : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
        formSearchName.salesManagerId = currentSalesManager
          ? currentSalesManager
          : [];
        this.searchInputsProduct[1].disabled = true;
        this.searchInputsProduct[3].disabled = true;
        this.searchInputsProduct[4].disabled = true;
        this.searchInputsProduct[5].disabled = true;
        this.searchInputsProduct = [...this.searchInputsProduct];
      }
    }
  }

  dataformSearchMarketName(formSearchName: any) {
    if (formSearchName.nameFormControl === 'marketName') {
      if (Array.isArray(formSearchName.marketName)) {
        const currentMKT = formSearchName.marketName.map(
          (item: any) => item.value
        );
        formSearchName.marketName = currentMKT;

        if (
          Array.isArray(formSearchName.orderAdminId) &&
          formSearchName.orderAdminId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );
          formSearchName.orderAdminId = currentOrderAdmin;
        } else if (
          formSearchName.orderAdminId === null ||
          formSearchName.orderAdminId === undefined
        ) {
          formSearchName.orderAdminId = [];
        } else {
          formSearchName.orderAdminId = formSearchName.orderAdminId;
        }

        if (
          Array.isArray(formSearchName.salesManagerId) &&
          formSearchName.salesManagerId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.salesManagerId?.map(
            (item: any) => item
          );
          formSearchName.salesManagerId = currentOrderAdmin;
        } else if (
          formSearchName.salesManagerId === null ||
          formSearchName.salesManagerId === undefined
        ) {
          formSearchName.salesManagerId = [];
        } else {
          formSearchName.salesManagerId = formSearchName.salesManagerId;
        }

        if (
          Array.isArray(formSearchName.salesModel) &&
          formSearchName.salesModel.length > 0
        ) {
          const currentSalesModel = formSearchName.salesModel?.map(
            (item: any) => item.value
          );
          formSearchName.salesModel = currentSalesModel;
        } else if (formSearchName.salesModel === null) {
          formSearchName.salesModel = [];
        } else {
          formSearchName.salesModel = formSearchName.salesModel;
        }

        if (
          Array.isArray(formSearchName.customerShortId) &&
          formSearchName.customerShortId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );
          formSearchName.customerShortId = currentOrderAdmin;
        } else if (
          formSearchName.customerShortId === null ||
          formSearchName.customerShortId === undefined
        ) {
          formSearchName.customerShortId = [];
        } else {
          formSearchName.customerShortId = formSearchName.customerShortId;
        }

        if (
          Array.isArray(formSearchName.materialId) &&
          formSearchName.materialId.length > 0
        ) {
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });
          formSearchName.materialId = currentFamily;
        } else if (
          formSearchName.materialId === null ||
          formSearchName.materialId === undefined
        ) {
          formSearchName.materialId = [];
        } else {
          formSearchName.materialId = formSearchName.materialId;
        }
      } else {
        formSearchName.marketName = [];
        if (
          Array.isArray(formSearchName.orderAdminId) &&
          formSearchName.orderAdminId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );
          formSearchName.orderAdminId = currentOrderAdmin;
        } else if (
          formSearchName.orderAdminId === null ||
          formSearchName.orderAdminId === undefined
        ) {
          formSearchName.orderAdminId = [];
        } else {
          formSearchName.orderAdminId = formSearchName.orderAdminId;
        }

        if (
          Array.isArray(formSearchName.salesManagerId) &&
          formSearchName.salesManagerId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.salesManagerId?.map(
            (item: any) => item
          );
          formSearchName.salesManagerId = currentOrderAdmin;
        } else if (
          formSearchName.salesManagerId === null ||
          formSearchName.salesManagerId === undefined
        ) {
          formSearchName.salesManagerId = [];
        } else {
          formSearchName.salesManagerId = formSearchName.salesManagerId;
        }

        if (
          Array.isArray(formSearchName.salesModel) &&
          formSearchName.salesModel.length > 0
        ) {
          const currentOrderAdmin = formSearchName.salesModel?.map(
            (item: any) => item.value
          );
          formSearchName.salesModel = currentOrderAdmin;
        } else if (formSearchName.salesModel === null) {
          formSearchName.salesModel = [];
        } else {
          formSearchName.salesModel = formSearchName.salesModel;
        }

        if (
          Array.isArray(formSearchName.customerShortId) &&
          formSearchName.customerShortId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );
          formSearchName.customerShortId = currentOrderAdmin;
        } else if (
          formSearchName.customerShortId === null ||
          formSearchName.customerShortId === undefined
        ) {
          formSearchName.customerShortId = [];
        } else {
          formSearchName.customerShortId = formSearchName.customerShortId;
        }

        if (
          Array.isArray(formSearchName.materialId) &&
          formSearchName.materialId.length > 0
        ) {
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });
          formSearchName.materialId = currentFamily;
        } else if (
          formSearchName.materialId === null ||
          formSearchName.materialId === undefined
        ) {
          formSearchName.materialId = [];
        } else {
          formSearchName.materialId = formSearchName.materialId;
        }
      }
    }
  }

  dataFormSearchSalesModel(formSearchName: any) {
    if (formSearchName.nameFormControl === 'salesModel') {
      if (formSearchName.salesModel) {
        if (Array.isArray(formSearchName.salesModel)) {
          const currentSalesModel = formSearchName.salesModel.map(
            (item: any) => item.value
          );
          formSearchName.salesModel = currentSalesModel;

          if (
            Array.isArray(formSearchName.orderAdminId) &&
            formSearchName.orderAdminId.length > 0
          ) {
            const currentOrderAdmin = formSearchName.orderAdminId?.map(
              (item: any) => item.id
            );
            formSearchName.orderAdminId = currentOrderAdmin;
          } else if (
            formSearchName.orderAdminId === null ||
            formSearchName.orderAdminId === undefined
          ) {
            formSearchName.orderAdminId = [];
          } else {
            formSearchName.orderAdminId = formSearchName.orderAdminId;
          }
          if (
            Array.isArray(formSearchName.marketName) &&
            formSearchName.marketName.length > 0
          ) {
            const currentOrderAdmin = formSearchName.marketName?.map(
              (item: any) => item.value
            );
            formSearchName.marketName = currentOrderAdmin;
          } else if (
            formSearchName.marketName === null ||
            formSearchName.marketName === undefined
          ) {
            formSearchName.marketName = [];
          } else {
            formSearchName.marketName = formSearchName.marketName;
          }

          if (
            Array.isArray(formSearchName.salesManagerId) &&
            formSearchName.salesManagerId.length > 0
          ) {
            const currentOrderAdmin = formSearchName.salesManagerId?.map(
              (item: any) => item
            );
            formSearchName.salesManagerId = currentOrderAdmin;
          } else if (
            formSearchName.salesManagerId === null ||
            formSearchName.salesManagerId === undefined
          ) {
            formSearchName.salesManagerId = [];
          } else {
            formSearchName.salesManagerId = formSearchName.salesManagerId;
          }

          if (
            Array.isArray(formSearchName.customerShortId) &&
            formSearchName.customerShortId.length > 0
          ) {
            const currentOrderAdmin = formSearchName.customerShortId?.map(
              (item: any) => item.id
            );
            formSearchName.customerShortId = currentOrderAdmin;
          } else if (
            formSearchName.customerShortId === null ||
            formSearchName.customerShortId === undefined
          ) {
            formSearchName.customerShortId = [];
          } else {
            formSearchName.customerShortId = formSearchName.customerShortId;
          }

          if (
            Array.isArray(formSearchName.materialId) &&
            formSearchName.materialId.length > 0
          ) {
            let currentFamily: any[] = [];
            formSearchName.materialId?.forEach((item: any) => {
              item.id.map((item: any) => currentFamily.push(item));
            });
            formSearchName.materialId = currentFamily;
          } else if (
            formSearchName.materialId === null ||
            formSearchName.materialId === undefined
          ) {
            formSearchName.materialId = [];
          } else {
            formSearchName.materialId = formSearchName.materialId;
          }
        }
      } else {
        formSearchName.salesModel = [];
        const currentSalesModel = formSearchName.salesModel.map(
          (item: any) => item.value
        );

        if (
          Array.isArray(formSearchName.orderAdminId) &&
          formSearchName.orderAdminId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.orderAdminId?.map(
            (item: any) => item.id
          );
          formSearchName.orderAdminId = currentOrderAdmin;
        } else if (
          formSearchName.orderAdminId === null ||
          formSearchName.orderAdminId === undefined
        ) {
          formSearchName.orderAdminId = [];
        } else {
          formSearchName.orderAdminId = formSearchName.orderAdminId;
        }

        if (
          Array.isArray(formSearchName.salesManagerId) &&
          formSearchName.salesManagerId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.salesManagerId?.map(
            (item: any) => item
          );
          formSearchName.salesManagerId = currentOrderAdmin;
        } else if (
          formSearchName.salesManagerId === null ||
          formSearchName.salesManagerId === undefined
        ) {
          formSearchName.salesManagerId = [];
        } else {
          formSearchName.salesManagerId = formSearchName.salesManagerId;
        }

        if (
          Array.isArray(formSearchName.marketName) &&
          formSearchName.marketName.length > 0
        ) {
          const currentOrderAdmin = formSearchName.marketName?.map(
            (item: any) => item.value
          );
          formSearchName.marketName = currentOrderAdmin;
        } else if (
          formSearchName.marketName === null ||
          formSearchName.marketName === undefined
        ) {
          formSearchName.marketName = [];
        } else {
          formSearchName.marketName = formSearchName.marketName;
        }

        if (
          Array.isArray(formSearchName.customerShortId) &&
          formSearchName.customerShortId.length > 0
        ) {
          const currentOrderAdmin = formSearchName.customerShortId?.map(
            (item: any) => item.id
          );
          formSearchName.customerShortId = currentOrderAdmin;
        } else if (
          formSearchName.customerShortId === null ||
          formSearchName.customerShortId === undefined
        ) {
          formSearchName.customerShortId = [];
        } else {
          formSearchName.customerShortId = formSearchName.customerShortId;
        }

        if (
          Array.isArray(formSearchName.materialId) &&
          formSearchName.materialId.length > 0
        ) {
          let currentFamily: any[] = [];
          formSearchName.materialId?.forEach((item: any) => {
            item.id.map((item: any) => currentFamily.push(item));
          });
          formSearchName.materialId = currentFamily;
        } else if (
          formSearchName.materialId === null ||
          formSearchName.materialId === undefined
        ) {
          formSearchName.materialId = [];
        } else {
          formSearchName.materialId = formSearchName.materialId;
        }
      }
    }
  }

  dataformSearchMarketNameProduct(formSearchName: any) {
    if (formSearchName.nameFormControl === 'marketName') {
      if (formSearchName.marketName) {
        const currentMKT = formSearchName.marketName.map(
          (item: any) => item.value
        );
        formSearchName.marketName = currentMKT;
      } else {
        formSearchName.marketName = [];
        formSearchName.customerShortId = formSearchName.customerShortId;

        const currentOrderAdmin = formSearchName.orderAdminId?.map(
          (item: any) => item.id
        );

        formSearchName.orderAdminId = currentOrderAdmin;
      }
    }
  }

  dataformSearchOrigin(formSearchName: any) {
    if (formSearchName.nameFormControl === 'origin') {
      if (formSearchName.origin) {
        const currentOrigin = formSearchName.origin;
        const currentOrderAdmin = formSearchName.orderAdminId?.map(
          (item: any) => item.id
        );

        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );

        const currentSalesModel = formSearchName.salesModel?.map(
          (item: any) => item.value
        );

        formSearchName.origin = currentOrigin;
        formSearchName.marketName = currentMarketName
          ? currentMarketName
          : [];
        formSearchName.orderAdminId = currentOrderAdmin
          ? currentOrderAdmin
          : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
        formSearchName.salesModel = currentSalesModel
        ? currentSalesModel
        : [];
      } else {
        const currentOrderAdmin = formSearchName.orderAdminId?.map(
          (item: any) => item.id
        );

        const currentMarketName = formSearchName.marketName?.map(
          (item: any) => item.value
        );

        const currentCustomerShort = formSearchName.customerShortId?.map(
          (item: any) => item.id
        );

        const currentSalesModel = formSearchName.salesModel?.map(
          (item: any) => item.value
        );

        formSearchName.origin = '';
        formSearchName.marketName = currentMarketName
          ? currentMarketName
          : [];
        formSearchName.orderAdminId = currentOrderAdmin
          ? currentOrderAdmin
          : [];
        formSearchName.customerShortId = currentCustomerShort
          ? currentCustomerShort
          : [];
        formSearchName.salesModel = currentSalesModel
        ? currentSalesModel
        : [];
      }
    }
  }

  onSearchEvent(form: any, tab?: any) {
    if (tab === 'orderAdmin') {
      this.formSearchOrderAdmin = { ...form };
      this.dataformSearchOrderAdmin(this.formSearchOrderAdmin, tab);
      this.dataformSearchCustomerShort(this.formSearchOrderAdmin, tab);
      this.dataformSearchPpm(this.formSearchOrderAdmin, tab);
      this.dataformSearchMarketName(this.formSearchOrderAdmin);
      this.dataFormSearchSalesModel(this.formSearchOrderAdmin);
      this.dataformSearchOrigin(this.formSearchOrderAdmin);
      this.formSearchOrderAdmin.report = tab;
      this.formSearchOrderAdmin.materialId = [];
      this.formSearchOrderAdmin.salesManagerId = [];
      this.formSearchOrderAdmin.internalName = [];

      if (this.searchInputsOrderAdmin[3].disabled === false) {
        this.searchInputsOrderAdmin[5].notClearable = false;
      } else {
        this.searchInputsOrderAdmin[5].notClearable = true;
      }
    }

    if (tab === 'salesManager') {
      this.formSearchSalesManager = { ...form };
      this.dataFormSearchSalesManagerSales(this.formSearchSalesManager);
      this.dataformSearchCustomerShort(this.formSearchSalesManager, tab);
      this.dataformSearchPpm(this.formSearchSalesManager, tab);
      this.dataformSearchMarketName(this.formSearchSalesManager);
      this.dataFormSearchSalesModel(this.formSearchSalesManager);
      this.dataformSearchOrigin(this.formSearchSalesManager);
      this.formSearchSalesManager.report = tab;
      this.formSearchSalesManager.materialId = [];
      this.formSearchSalesManager.orderAdminId = [];
      this.formSearchSalesManager.internalName = [];

      if (this.searchInputsSalesManager[3].disabled === false) {
        this.searchInputsSalesManager[5].notClearable = false;
      } else {
        this.searchInputsSalesManager[5].notClearable = true;
      }
      if (this.searchInputsSalesManager[1].disabled === false) {
        this.searchInputsSalesManager[1].notClearable = false;
      } else {
        this.searchInputsSalesManager[1].notClearable = true;
      }
      this.searchInputsSalesManager = [...this.searchInputsSalesManager];
    }
    if (tab === 'customer') {
      this.formSearchCustomer = { ...form };
      this.dataformSearchCustomerShort(this.formSearchCustomer, tab);
      this.dataformSearchOrderAdminProduct(this.formSearchCustomer);
      this.dataformSearchPpm(this.formSearchCustomer, tab);
      this.dataformSearchMarketName(this.formSearchCustomer);
      this.dataFormSearchSalesModel(this.formSearchCustomer);
      this.dataformSearchOrigin(this.formSearchCustomer);
      this.formSearchCustomer.report = tab;
      this.formSearchCustomer.materialId = [];
      this.formSearchCustomer.salesManagerId = [];
      this.formSearchProduct.internalName = [];

      if (this.searchInputsCustomer[2].disabled === false) {
        this.searchInputsCustomer[4].notClearable = false;
      } else {
        this.searchInputsCustomer[4].notClearable = true;
      }
      if (this.searchInputsCustomer[5].disabled === false) {
        this.searchInputsCustomer[5].notClearable = false;
      } else {
        this.searchInputsCustomer[5].notClearable = true;
      }
      this.searchInputsCustomer = [...this.searchInputsCustomer];
    }
    if (tab === 'product') {
      this.formSearchProduct = { ...form };
      this.dataFormSearchFamily(this.formSearchProduct);
      this.dataformSearchMarketName(this.formSearchProduct);
      this.dataformSearchCustomerShort(this.formSearchProduct, tab);
      this.dataformSearchOrderAdmin(this.formSearchProduct, tab);
      this.dataFormSearchSalesManager(this.formSearchProduct, tab);
      this.formSearchProduct.report = tab;
      this.formSearchProduct.ppm = [];
      this.formSearchProduct.salesModel = [];

      if (this.searchInputsProduct[1].disabled === false) {
        this.searchInputsProduct[1].notClearable = false;
        this.searchInputsProduct[3].notClearable = false;
        this.searchInputsProduct[4].notClearable = false;
        this.searchInputsProduct[5].notClearable = false;
      } else {
        this.searchInputsProduct[1].notClearable = true;
        this.searchInputsProduct[3].notClearable = true;
        this.searchInputsProduct[4].notClearable = true;
        this.searchInputsProduct[5].notClearable = true;
      }
      this.searchInputsProduct = [...this.searchInputsProduct];
    }

    this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
  }

  handleFilterButton(item: any) {
    this.filterTable.forEach((data: any) => {
      if (data.label == item.label) {
        data.value = true;
      } else {
        data.value = false;
      }
    });

    if (item.label == this.allocate.label) {
      this.allocate.value = true;
    } else {
      this.allocate.value = false;
    }

    if (item.label == this.toBook.label) {
      this.toBook.value = true;
    } else {
      this.toBook.value = false;
    }
  }

  exportComponents(data: any) {
    this.exportAllocateResult = {
      table: {
        columns: [...data.allocate.headers],
        rows: data.allocate.rows,
      },
    };

    this.exportToBookResult = {
      table: {
        columns: [...data.toBook.headers],
        rows: data.toBook.rows,
      },
    };

    this.resultToBook = Object.assign([
      this.exportAllocateResult,
      this.exportToBookResult,
      data.toBookExport,
    ]);
  }

  prepareExportTable(data: any, tabName: any): any {
    if (tabName === 'toAllocate') {
      const rows: any = [];
      let rowsJag: any;
      let rowsSubJag: any;
      let rowsMan: any;
      let rowsSubMan: any;
      let rowTotal: any;

      if (data[0].table.rows.jag && data[0].table.rows.man.length == 0) {
        rowsJag = data[0].table.rows.jag;
        rowsSubJag = data[0].table.rows.sub_jag;
        rowTotal = data[0].table.rows.total;

        rows.push(...rowsJag, ...rowsSubJag, ...rowTotal);
      }
      if (data[0].table.rows.man && data[0].table.rows.jag.length == 0) {
        rowsMan = data[0].table.rows.man;
        rowsSubMan = data[0].table.rows.sub_man;
        rowTotal = data[0].table.rows.total;

        rows.push(...rowsMan, ...rowsSubMan, ...rowTotal);
      }
      if (
        data[0].table.rows.jag &&
        data[0].table.rows.jag.length > 0 &&
        data[0].table.rows.man &&
        data[0].table.rows.man.length > 0
      ) {
        rowsJag = data[0].table.rows.jag;
        rowsSubJag = data[0].table.rows.sub_jag;
        rowsMan = data[0].table.rows.man;
        rowsSubMan = data[0].table.rows.sub_man;
        rowTotal = data[0].table.rows.total;

        rows.push(
          ...rowsJag,
          ...rowsSubJag,
          ...rowsMan,
          ...rowsSubMan,
          ...rowTotal
        );
      }

      const columns = data[0].table.columns.map((data: any) =>
        data.tooltip !== undefined
          ? { title: data.title + ' - CRSD ' + data.tooltip, props: data.props }
          : { title: data.title, props: data.props }
      );
      const tableRows: { [key: string]: [] }[] = [];

      for (let i = 0; i < rows.length; i++) {
        const tableRow: { [key: string]: [] } = {};

        columns.forEach((column: any) => {
          if (rows[i][column.props]) {
            tableRow[column.props] = rows[i][column.props];
          } else {
            tableRow[column.props] = rows[i][column.props];
          }
        });

        tableRows.push(tableRow);
      }

      return { columns: columns, rows: tableRows };
    } else if (tabName === 'toBook') {
      const rows: any = [];
      let rowsJag: any;
      let rowsSubJag: any;
      let rowsMan: any;
      let rowsSubMan: any;
      let rowTotal: any;

      if (data[1].table.rows.jag && data[1].table.rows.man.length == 0) {
        rowsJag = data[1].table.rows.jag;
        rowsSubJag = data[1].table.rows.sub_jag;
        rowTotal = data[1].table.rows.total;

        rows.push(...rowsJag, ...rowsSubJag, ...rowTotal);
      }
      if (data[1].table.rows.man && data[1].table.rows.jag.length == 0) {
        rowsMan = data[1].table.rows.man;
        rowsSubMan = data[1].table.rows.sub_man;
        rowTotal = data[1].table.rows.total;

        rows.push(...rowsMan, ...rowsSubMan, ...rowTotal);
      }
      if (
        data[1].table.rows.jag &&
        data[1].table.rows.jag.length > 0 &&
        data[1].table.rows.man &&
        data[1].table.rows.man.length > 0
      ) {
        rowsJag = data[1].table.rows.jag;
        rowsSubJag = data[1].table.rows.sub_jag;
        rowsMan = data[1].table.rows.man;
        rowsSubMan = data[1].table.rows.sub_man;
        rowTotal = data[1].table.rows.total;

        rows.push(
          ...rowsJag,
          ...rowsSubJag,
          ...rowsMan,
          ...rowsSubMan,
          ...rowTotal
        );
      }
      const columns = data[1].table.columns.map((data: any) =>
        data.tooltip !== undefined
          ? { title: data.title + ' - CRSD ' + data.tooltip, props: data.props }
          : { title: data.title, props: data.props }
      );
      const tableRows: { [key: string]: [] }[] = [];

      for (let i = 0; i < rows.length; i++) {
        const tableRow: { [key: string]: [] } = {};

        columns.forEach((column: any) => {
          if (rows[i][column.props]) {
            tableRow[column.props] = rows[i][column.props];
          } else {
            tableRow[column.props] = rows[i][column.props];
          }
        });

        tableRows.push(tableRow);
      }

      return { columns: columns, rows: tableRows };
    }
  }

  exportTable() {
    const exportData = [];
    let allocateJsonExport = '';
    let toBookJsonExport = '';

    allocateJsonExport = this.prepareExportTable(
      this.resultToBook,
      'toAllocate'
    );
    toBookJsonExport = this.prepareExportTable(this.resultToBook, 'toBook');

    Object.defineProperty(allocateJsonExport, 'sheet', {
      value: 'Allocation',
    });
    Object.defineProperty(allocateJsonExport, 'headers', {
      value: true,
    });
    Object.defineProperty(toBookJsonExport, 'sheet', {
      value: 'To_Book',
    });
    Object.defineProperty(toBookJsonExport, 'headers', {
      value: true,
    });
    Object.defineProperty(this.resultToBook[2], 'sheet', {
      value: 'Order_Book',
    });
    Object.defineProperty(this.resultToBook[2], 'headers', {
      value: false,
    });


    exportData.push(allocateJsonExport, toBookJsonExport, this.resultToBook[2]);

    this.excelSheetsService.exportJsonAsExcelFile(exportData, 'export-to-book');
  }

  backButton() {
    this.router.navigate(['home/customer-allocation-retail']);
  }
}
