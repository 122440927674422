import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../authorization/auth.service';
import { PermissionRouter } from '../../helpers/authorization/permission-router';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  resourceRouter: any = {
    allocationRetail: 'ALLOCATION RETAIL',
    dcmDashboards: 'DASHBOARDS',
    dcmNotifications: 'NOTIFICATIONS',
  };

  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let canRoute: boolean = false;
    let isAuthorized: any = this.authService.getToken();
    let userPermissions: any = this.authService.getUserPermissions();
    let routerMap: string = route.url[0].path.toUpperCase().replace(/-/g, ' ');
    if (isAuthorized && userPermissions.length > 0) {
      let entityPermissions = userPermissions;
      entityPermissions.forEach((permission: any) => {
        if (
          permission.category === routerMap ||
          routerMap === 'ADMIN' ||
          routerMap == 'ALLOCATION RETAIL' ||
          routerMap == 'DASHBOARDS'
        ) {
          switch (permission.category && routerMap) {
            case PermissionRouter.admin:
              canRoute = true;
              break;
            case PermissionRouter.setup:
              canRoute = true;
              break;
            case PermissionRouter.onePlan:
              canRoute = true;
              break;
            case PermissionRouter.materials:
              canRoute = true;
              break;
            case PermissionRouter.kaAllocation:
              canRoute = true;
              break;
            case this.resourceRouter.allocationRetail:
              canRoute = true;
              break;
            case PermissionRouter.customerAllocation:
              canRoute = true;
              break;
            case PermissionRouter.emeaAllocation:
              canRoute = true;
              break;
            case PermissionRouter.lom:
              canRoute = true;
              break;
            case this.resourceRouter.dcmDashboards:
              canRoute = true;
              break;
            default:
              canRoute = false;
              break;
          }
        }
      });
      if (!canRoute) {
        this.router.navigate(['/unauthorized']);
      }
    } else {
      this.router.navigate(['/authorization']);
    }
    return canRoute;
  }
}
