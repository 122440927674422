import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { ModalService } from 'src/app/services/generic/modal-status.service';
import { ModalData } from 'src/app/interfaces/modal.interface';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-modal',
  templateUrl: './modal-table.component.html',
  styleUrls: ['./modal-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() isOpen: boolean;
  @Output() confirmed;
  @Output() cancelled;

  originalHtmlBodyHeight: string | null;
  private hoveredRows: Set<any> = new Set();
  clickOutsideListener: any;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private focusMonitor: FocusMonitor,
    private modalService: ModalService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.confirmed = new EventEmitter<void>();
    this.cancelled = new EventEmitter<void>();

    this.isVisible = false;
    this.isOpen = false;
    this.originalHtmlBodyHeight = null;
  }

  ngOnInit(): void {
    this.focusMonitor.stopMonitoring(document.body);

    // Salva a altura original do html e body e define para "auto".
    this.originalHtmlBodyHeight = document.documentElement.style.height;
    document.documentElement.style.height = 'auto';
    document.body.style.height = 'auto';
    this.modalService.openModal();
    this.getTableStringAndNumber();
  }

  ngOnDestroy(): void {
    this.focusMonitor.monitor(document.body);

    // Restaura a altura original do html e body.
    if (this.originalHtmlBodyHeight !== null) {
      document.documentElement.style.height = this.originalHtmlBodyHeight;
      document.body.style.height = this.originalHtmlBodyHeight;
    }

    // Remove o ouvinte de eventos de clique fora do modal
    if (this.modalService.isModalOpenSubject) {
      this.modalService.closeModal();
    }
  }

  closeModal(): void {
    this.dialogRef.close();
    this.modalService.closeModal();
    this.isOpen = false;
  }

  exportData(): void {
    const uniqueMonths = this.getUniqueMonths();
    const dataSourceWithSubtotals = [];
    const columnTitles: any = {};
    this.data.displayedColumns.forEach((column: any) => {
      columnTitles[column.prop] = column.label;
    });

    dataSourceWithSubtotals.push({
      [this.data.displayedColumns[0].prop]: this.data.subtitle,
    });
    dataSourceWithSubtotals.push(columnTitles);

    uniqueMonths.forEach(month => {
      const rowsForMonth = this.getRowsForMonth(month);
      const subtotal = this.calculateSubtotalByMonth(month);

      dataSourceWithSubtotals.push(...rowsForMonth, {
        [this.data.displayedColumns[0].prop]: `SUBTOTAL`,
        QTY: subtotal,
      });
    });

    const total = this.calculateTotalOfSubtotals();
    dataSourceWithSubtotals.push({
      [this.data.displayedColumns[0].prop]: 'TOTAL',
      QTY: total,
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      dataSourceWithSubtotals,
      { skipHeader: true }
    );
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.data.titleExport}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getUniqueMonths(): string[] {
    const uniqueMonths: string[] = [];

    for (const item of this.data.dataSource) {
      if (!uniqueMonths.includes(item.month)) {
        uniqueMonths.push(item.month);
      }
    }

    return uniqueMonths;
  }

  getUniqueSubtotal(): string[] {
    return this.data.dynamicPropertyWithSameValue;
  }

  getRowsWithSameValue(): any[] {
    return this.data.dataSource;
  }

  getRowsForMonth(month: string): any[] {
    return this.data.dataSource.filter((item: any) => item.month === month);
  }

  calculateSubtotalByMonth(month: string): number {
    return this.data.dataSource
      .filter((item: any) => item.month === month)
      .reduce((acc: any, item: any) => acc + item.QTY, 0);
  }

  getTableStringAndNumber(column?: any): string {
    const columnType = this.getColumnType(column?.prop);
    return columnType === 'string' ? 'row-string' : 'row-number';
  }

  getColumnType(prop: string): string {
    let isString = true;
    this.data.dataSource?.forEach((row: any) => {
      if (typeof row[prop] !== 'string' && !isNaN(Number(row[prop]))) {
        isString = false;
      }
    });

    return isString ? 'string' : 'number';
  }

  getRowColorClass(row: any, column: any): string {
    const rowDelta = column.prop === 'delta';
    if (rowDelta) {
      return 'color-red-row';
    }

    return 'app-value-currency';
  }

  getDynamicallySubtotalCalc() {
    return this.data.dynamicCalcSubtotal;
  }

  shouldShowRow(row?: any, value?: any) {
    if (row.origin === value.origin && row.family === value.family) {
      return row.origin && row.family;
    }
  }

  calculateTotalOfSubtotals() {
    return this.getUniqueSubtotal()
      .map(value => this.calculateSubtotalByMonth(value))
      .reduce((acc, subtotal) => acc + subtotal, 0);
  }

  isRowHovered(row: any): boolean {
    return this.hoveredRows.has(row);
  }

  setHoveredRow(row: any, isHovered: boolean): void {
    if (isHovered) {
      this.hoveredRows.add(row);
    } else {
      this.hoveredRows.delete(row);
    }
    this.cdr.detectChanges();
  }
}
