import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { CardInterface } from 'src/app/components/cards/card.interface';
import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { Country } from 'src/app/interfaces/country.interface';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PermissionsInterface from 'src/app/interfaces/genericInterfaces/permission.interface';
import PermissionsByModuleInterface, {
  ResourcesInterface,
} from 'src/app/interfaces/genericInterfaces/permissionsByModuleInterface';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { Profile } from 'src/app/interfaces/profile.iterface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import CRUD_EVENTS from 'src/assets/constants/crud-events';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import STATUS from 'src/assets/constants/status';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  @ViewChild('modalPermissions', { static: true }) modalPermissions:
    | TemplateRef<any>
    | any;

  searchInputs!: InputBase<string>[];
  dataSource: any;
  displayedColumns: any;
  result!: ResultInterface;
  profileForm: FormGroup;
  isEdit: boolean = false;
  hasPermissionCreate!: boolean;
  crudService: CrudService<Profile>;
  permissionsModules: CrudService<PermissionsByModuleInterface>;
  pagination: Pagination;
  filter: any = { sort: 'profile', pageName: PAGE_NAME_RESOURCE.profile };
  countries: any = [];
  geos: any = [];
  geosEntity?: any[] = [];
  permissions: any = [];
  permissionsEditMode: boolean = true;
  permissionsViewSystem: boolean = false;
  statusEntity = [
    { key: 'active', value: STATUS.active },
    { key: 'inactive', value: STATUS.inactive },
  ];
  countryEntity: any[] = [];
  allProfiles: Profile[] = [];
  allCountries: Country[] = [];
  allGeos: Geo[] = [];
  modalSize = {
    with: '500px',
    height: '300px',
  };
  entities: Array<getEntities> = [
    { entity: 'profile', query: { sort: 'profile' } },
    {
      entity: 'country',
      query: {
        sort: 'country',
        distinct: 'country',
        pageName: PAGE_NAME_RESOURCE.profile,
      },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.profile },
    },
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.profile },
    },
  ];

  // valores pré-carregados/iniciais dos filtros
  initialValueCountryFilter: { [key: string]: any } = {};
  initialValueGeoFilter: { [key: string]: any } = {};

  actionCards = this.permissionService.setActionsCardsPermission('profile');

  statusActiveOrInactiveTable = {
    active: 'Active',
    inactive: 'Inactive',
  };

  crud_events = CRUD_EVENTS;
  userPermissions!: Array<PermissionsByModuleInterface>;
  currentUser!: any;

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.crudService = new CrudService<Profile>(this.http, 'profile');
    this.permissionsModules = new CrudService<PermissionsByModuleInterface>(
      this.http,
      ''
    );
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('profile');
    });
    this.pagination = { page: 0, size: 10 };
    this.profileForm = this.formBuilder.group({
      id: [''],
      profile: ['', Validators.required],
      active: ['', Validators.required],
      countries: ['', Validators.required],
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geo_id: this.currentUser.geoId,
      country_id: this.currentUser.countryId,
    };
    this.searchInputs = this.createSearchInputs();
  }

  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'profile',
        hover: 'Profile',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        options: this.statusEntity,
        classCss: 'status-profile',
      }),
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'country_id',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getProfiles(this.pagination || undefined, this.filter);
    this.getDataFilter(this.entities);
  }

  getProfiles(page?: any, params?: any) {
    this.spinner.show();
    this.crudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        this.updateComponents(response);
        this.spinner.hide();
      });
  }

  dataFilterProfile(responseProfile: any) {
    const profilesFilter: any[] = [];

    responseProfile[0].forEach((item: any) => {
      profilesFilter.push({ key: item.id, value: item.profile });
    });

    this.searchInputs[0].options = profilesFilter;
  }

  dataFilterCountry(responseCountry: any) {
    const countryFilter: any[] = [];
    this.countryEntity = [];

    responseCountry[1].forEach((country: any) => {
      this.countryEntity.push(country);
      countryFilter.push({
        key: country.id,
        value: country.country,
        id: country.id,
      });
      // const countryKey = country.id;
      const countryValue = country.country;
      const countryId = country.id;

      if (countryId === this.currentUser.countryId) {
        this.initialValueCountryFilter.value = countryValue;
        this.initialValueCountryFilter.id = countryId;
      }
    });

    const selectCountry = new SelectSearchInput({
      key: 'country_id',
      hover: 'Country',
      type: 'text',
      hasAutocomplete: true,
      options: countryFilter,
      value: this.initialValueCountryFilter.value,
    });

    this.searchInputs[3] = selectCountry;
  }

  dataFilterAllCountry(response: any) {
    this.countries = [];

    response.forEach((country: any) => {
      this.countries.push({
        key: country.id,
        id: country.id,
        country: country.country + ' - ' + country.iso_code,
      });
    });
  }

  dataFilterGeo(responseGeo: any) {
    const geosFilter: any[] = [];
    this.geos = [];

    responseGeo[3].forEach((geo: any) => {
      this.geos.push(geo);
      geosFilter.push({ key: geo.id, value: geo.geo, id: geo.id });

      // const geoKey = geo.id;
      const geoValue = geo.geo;
      const geoId = geo.id;

      if (geoId === this.currentUser.geoId) {
        this.initialValueGeoFilter.value = geoValue;
        this.initialValueGeoFilter.id = geoId;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geo_id',
      hover: 'GEO',
      type: 'text',
      hasAutocomplete: true,
      options: geosFilter,
      value: this.initialValueGeoFilter.value,
    });

    this.searchInputs[2] = selectGeo;
  }

  getDataFilter(filters: any) {
    this.spinner.show();

    this.crudService.getDataFilters(filters).subscribe((response: any[]) => {
      this.dataFilterProfile(response);
      this.dataFilterCountry(response);
      this.dataFilterAllCountry(response[2]);
      this.dataFilterGeo(response);

      this.searchInputs = [...this.searchInputs];
    });
  }

  updateComponents(profile: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(profile.rows)),
      table: {
        columns: [
          { label: 'profile', sticky: true, style: { width: '30%' } },
          {
            label: 'country',
            prop: 'countries',
            sticky: true,
            style: { width: '30%' },
          },
          {
            label: 'status',
            prop: 'active',
            sticky: true,
            style: { width: '15%', 'padding-right': '10px' },
          },
          {
            label: 'permissions',
            prop: 'systemFunction',
            sticky: true,
            style: {
              width: '15%',
              'padding-right': '100px',
            },
          },
        ],
        rows: new MatTableDataSource<Profile>(
          this.dataTables(JSON.stringify(profile.rows))
        ),
        hasStatus: true,
      },
      pageIndex: profile.page,
      pageSize: profile.totalRows,
      pagesTotal: profile.totalPages,
    };
    const actions = this.permissionService.setTablePermissions('profile');
    if (actions !== undefined) this.result.table?.columns?.push(actions);
  }

  dataTables(data: any) {
    const profile = JSON.parse(data);
    const dataTables: any[] = [];

    profile.forEach((item: any) => {
      let countriesLabel: string = '';
      let itemCountries = item.countries;

      itemCountries
        ? itemCountries.forEach((country: any, index: number) => {
            const countryNamesWithCommas = `${country.country}${
              index + 1 === itemCountries.length ? '' : ', '
            }`;
            countriesLabel += countryNamesWithCommas;
          })
        : (countriesLabel = '');

      item.active = item.active
        ? this.statusActiveOrInactiveTable.active
        : this.statusActiveOrInactiveTable.inactive;
      item.countries = countriesLabel;
      dataTables.push(item);
    });

    return [
      ...dataTables.sort((current: any, next: any) =>
        current.countries.localeCompare(next.countries)
      ),
    ];
  }

  dataCards(data: string) {
    const profile = JSON.parse(data);
    const dataCards: CardInterface[] = [];

    profile.forEach((item: Profile) => {
      const countries: any[] = [];
      const itemCountries = item.countries;

      itemCountries?.forEach((value: any) => {
        countries.push(value.country);
      });
      this.createCardItemProfileCountry(dataCards, item, countries, 'Country');
    });
    return dataCards;
  }

  createCardItemProfileCountry(
    dataCardsInterface: any,
    dataCardsItem: any,
    value: any,
    key: string
  ) {
    dataCardsInterface.push({
      id: dataCardsItem.id,
      title: dataCardsItem.profile,
      active: dataCardsItem.active,
      systemFunction: true,
      iconEdit: {
        visible: this.actionCards.iconEdit,
        enabled: true,
      },
      iconDelete: { visible: this.actionCards.iconDelete, enabled: true },
      attributes: [
        {
          key: key,
          value: value,
          isTag: true,
        },
      ],
    });
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getProfiles(paginated, this.filter);
  }

  onEventActions(event: any) {
    const EventItemId = event?.item?.id;

    if (event?.type === this.crud_events.system_function) {
      this.viewSystemFuncitons(EventItemId);
    } else if (event?.type === this.crud_events.edit) {
      this.prepareToEditProfile(EventItemId);
    } else {
      this.onDeleteProfile(EventItemId);
    }
  }

  viewSystemFuncitons(id: number) {
    this.spinner.show();
    this.permissionsModules
      .getEntity(`permissions/${id}`)
      .subscribe((response: any) => {
        this.permissionsEditMode = false;
        this.permissionsViewSystem = true;
        this.userPermissions = response;
        this.modalService.createModal(this.modalPermissions);
        this.spinner.hide();
      });
  }

  modalCloseAfterChange(
    syncRequestsPermissions: any,
    syncRequestsPermissionsProfile: any
  ) {
    if (syncRequestsPermissions && syncRequestsPermissionsProfile) {
      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.profileForm.reset();
      });
      this.spinner.hide();
    }
  }

  prepareToEditProfile(id: number) {
    const syncRequests = { profile: false, permissions: false };
    const statusActive = this.profileForm.controls.active;
    const listCountry: any = [];
    const profileCountry: any = [];
    const countriesList: any = [];

    this.spinner.show();
    this.permissionsEditMode = true;
    this.isEdit = true;

    this.crudService.fetchEntity(id).subscribe((profile: any) => {
      this.profileForm.controls.id.setValue(profile.id);
      this.profileForm.controls.profile.setValue(profile.profile);

      profile.active
        ? statusActive.setValue('active')
        : statusActive.setValue('inactive');

      if (profile.countries.length) {
        profile.countries.forEach((country: string) => {
          profileCountry.push(country);
        });
        this.countries.forEach((item: string) => countriesList.push(item));

        profileCountry.filter((profileCountry: any) =>
          listCountry.push(
            countriesList.find(
              (countriesList: any) => countriesList.id === profileCountry.id
            )
          )
        );
      }
      this.profileForm.controls.countries.setValue(listCountry);
      syncRequests.profile = true;

      this.modalCloseAfterChange(
        syncRequests.permissions,
        syncRequests.profile
      );
    });

    this.permissionsModules
      .getEntity(`permissions/${id}`)
      .subscribe((response: any) => {
        syncRequests.permissions = true;
        this.userPermissions = response;
        this.permissions = response;
        this.modalCloseAfterChange(
          syncRequests.permissions,
          syncRequests.profile
        );
      });
  }

  prepareToCreate() {
    this.isEdit = false;
    this.spinner.show();
    this.permissionsEditMode = true;
    this.permissionsModules
      .getEntity('permissions')
      .subscribe((response: any) => {
        this.userPermissions = response;
        const createModal = this.modalService.createModal(
          this.modalCreateEdit,
          this.modalSize
        );
        createModal.afterClosed().subscribe(() => {
          this.profileForm.reset();
        });
        this.spinner.hide();
      });
  }

  getActivePermissions(): Array<any> {
    const data: any[] = [];
    this.permissions.forEach((category: PermissionsByModuleInterface) => {
      category?.resources?.forEach((resources: ResourcesInterface) => {
        const currentPermissions = resources?.permissions?.filter(
          (permission: PermissionsInterface) => permission.active === true
        );

        if (currentPermissions?.length) {
          currentPermissions.forEach((permission: PermissionsInterface) => {
            data.push({ id: permission.id });
          });
        }
      });
    });
    return data;
  }

  checkPermissions() {
    const permissions = this.getActivePermissions();
    if (permissions.length <= 0) {
      this.toastr.warning(
        'No action (Create, Delete, Edit, View) checked. Please informed regarding the profile/function',
        'Permissions'
      );
      return false;
    }
    return true;
  }

  idOfcountry(countries: any) {
    const countriesForm = this.profileForm.controls.countries.value; // pega o id country

    countriesForm.forEach((country: any) => {
      countries.push({ id: `${country.id}` }); // passa o id selecionado para o parâmetro
    });
  }

  createNewProfileEntity(countries: number, currentPermissions: any) {
    const profile: Profile = {
      id: '',
      profile: this.profileForm.controls.profile.value,
      active: this.profileForm.controls.active.value === 'active',
      countries,
      permissions: currentPermissions,
    };
    return profile;
  }

  messageAfterCreateNewProfile(profile: any) {
    this.crudService.createEntity(profile).subscribe(
      () => {
        this.messageToastCreated();
        this.loadData();
        this.modalService.closeAll();
      },
      (err: any) => {
        this.messgeErrorToast(err);
        this.spinner.hide();
      }
    );
  }
  onCreateProfile() {
    if (
      this.validateFormService.onValidateFields(this.profileForm) &&
      this.checkPermissions()
    ) {
      this.spinner.show();
      const countries: any = [];
      const currentPermissions = this.getActivePermissions();

      this.idOfcountry(countries);

      const profile = this.createNewProfileEntity(
        countries,
        currentPermissions
      );
      this.messageAfterCreateNewProfile(profile);
    }
  }

  profileUpdatedObject(countries: string, permissions: any) {
    const { id, profile } = this.profileForm.value;
    const active = this.profileForm.controls.active.value === 'active';

    const profileToSave: Profile = {
      id,
      profile,
      active,
      countries,
      permissions,
    };
    return profileToSave;
  }

  messageToastCreated() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS')} Profile`
    );
  }

  messageToastUpdated() {
    return this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Profile`
    );
  }

  messageToastRemoved() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Profile`
    );
  }

  messgeErrorToast(err: any) {
    return this.toastr.error(err.error.message, err.error.title);
  }

  messageAfterUpdateProfile(profileId: number, messageProfileToSabe: any) {
    this.crudService.updateEntity(profileId, messageProfileToSabe).subscribe(
      () => {
        this.messageToastUpdated();
        this.loadData();
        this.modalService.closeAll();
      },
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
      }
    );
  }

  onUpdateProfile() {
    this.isEdit = true;
    if (
      this.validateFormService.onValidateFields(this.profileForm) &&
      this.checkPermissions()
    ) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);

      dialog.afterClosed().subscribe((result: string) => {
        if (result) {
          this.spinner.show();

          const permissions = this.getActivePermissions();
          const countries: any = [];
          const profileToSave = this.profileUpdatedObject(
            countries,
            permissions
          );
          const profileId = this.profileForm.controls.id.value;

          this.idOfcountry(countries);
          this.messageAfterUpdateProfile(profileId, profileToSave);
        }
      });
    }
  }

  onDeleteProfile(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.crudService.deleteEntity(id).subscribe(
          () => {
            this.messageToastRemoved();
            this.loadData();
            this.modalService.closeAll();
          },
          (err: any) => {
            this.messgeErrorToast(err);
            this.spinner.hide();
          }
        );
      }
    });
  }

  formValuesCountry(formValueCountry: any) {
    if (formValueCountry.country_id) {
      const currentCountry = this.countries.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(formValueCountry.country_id.toLocaleUpperCase())
      );
      formValueCountry.country_id = currentCountry[0]
        ? currentCountry[0].id
        : 0;
    } else {
      formValueCountry.country_id = '';
    }
  }

  formValuesProfile(formValueProfile: any) {
    if (!formValueProfile.profile) {
      formValueProfile.profile = '';
    }
  }

  formValuesGeo(formValueGeo: any) {
    if (formValueGeo.geo_id) {
      const currentGeo = this.geos.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formValueGeo.geo_id.toLocaleUpperCase())
      );
      formValueGeo.geo_id = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formValueGeo.geo_id = '';
    }
  }

  formValuesStatus(formValueStatus: any) {
    if (formValueStatus.status) {
      formValueStatus.status = formValueStatus.status === 'Active';
    } else {
      formValueStatus.status = '';
    }
  }

  onSearchEvent(value: any) {
    const formValue = { ...value };

    this.formValuesCountry(formValue);
    this.formValuesProfile(formValue);
    this.formValuesGeo(formValue);
    this.formValuesStatus(formValue);

    this.pagination.page = 0;
    this.filter = {
      ...formValue,
      sort: 'profile',
      pageName: PAGE_NAME_RESOURCE.profile,
    };
    this.getProfiles(this.pagination, this.filter);
  }

  eventInactiveValue(event: any) {
    return event.value === 'inactive';
  }

  warmingAssociationUser() {
    return this.toastr.warning('Profile has association with Users', 'Status');
  }

  onChangeStatus(event: any) {
    if (this.profileForm.controls.id.value) {
      if (this.eventInactiveValue(event)) {
        const id = this.profileForm.controls.id.value;
        this.spinner.show();

        this.crudService
          .getEntity(`user/paged?size=1&page=0&profile_id=${id}`)
          .subscribe((response: any) => {
            const userAssociated = response;
            this.spinner.hide();

            if (userAssociated.totalRows > 0) {
              this.warmingAssociationUser();
            }
          });
      }
    }
  }

  onChangePermissions(data: PermissionsByModuleInterface) {
    this.permissions = data;
  }

  backButton() {
    if (this.currentUser.country.toLocaleLowerCase() === 'brazil') {
      this.router.navigate(['home/setup/admin']);
    } else {
      this.router.navigate(['home/admin']);
    }
  }
}
