import { Component, OnInit } from '@angular/core';
import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { NgxSpinnerService } from 'ngx-spinner';
import { filtersExecutiveOverviewMockup } from './filters-mockup';
import { tableExecutiveOverviewMockup } from './table-executive-overview-mockup';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-executive-overview',
  templateUrl: './executive-overview.component.html',
  styleUrls: ['./executive-overview.component.scss'],
})
export class ExecutiveOverviewComponent implements OnInit {
  searchInputsExecutiveOverview: InputBase<string>[];
  isModeTableOrCardsHide: boolean = true;
  hidePagination: boolean = true;

  filtersMockup = filtersExecutiveOverviewMockup;
  tableMockup = tableExecutiveOverviewMockup;
  dataSourceTable: any[] = [];
  summedValue: any[] = [];
  month: any;
  result: any;

  viewEntity: any[] = [];

  constructor(private spinner: NgxSpinnerService, private router: Router) {
    this.searchInputsExecutiveOverview = [
      new SelectSearchInput({
        key: 'view',
        hover: 'View',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getDataFilter();
    this.getDataTable(this.tableMockup);
    this.getQuarterFromDate(new Date());
  }

  dataFilterView(response: string): void {
    this.viewEntity.push({ value: response });

    const selectView = new SelectSearchInput({
      key: 'view',
      hover: 'View',
      type: 'text',
      classCss: 'filter-one-plan',
      hasAutocomplete: true,
      options: this.viewEntity,
    });
    this.searchInputsExecutiveOverview[0] = selectView;
  }

  getDataFilter() {
    Object.entries(this.filtersMockup).forEach((response: any) => {
      this.dataFilterView(response[1].view);
    });
  }

  getDataTable(data: any) {
    this.result = {
      table: {
        columns: data.table.columns,
        rows: new MatTableDataSource<any>(data.table.rows),
        hasCalculation: true,
      },
      pageIndex: data.page,
      pageSize: data.totalRows === 0 ? 0 : '',
      pagesTotal: data.totalPages,
    };
    this.addSubtotalValues();
  }

  addSubtotalValues() {
    let headerName: any = [];

    Object.entries(this.tableMockup).forEach((response: any) => {
      response[1].columns.forEach((header: any) => {
        headerName = header.prop;

        response[1].rows
          .map((item: any) => {
            if (item[headerName].summableValue) {
              return item[headerName].value;
            }
          })
          .reduce((acc: any, item: any) => {
            if (acc !== undefined && item !== undefined) {
              return (this.summedValue = acc + item);
            }
          }, 0);
        header.totalAddedValue = this.summedValue;
      });
    });
  }

  getQuarterFromDate(date: Date) {
    this.month = date.getMonth() + 1;

    if (this.month >= 4 && this.month <= 6) return 'FQ1';
    if (this.month >= 7 && this.month <= 9) return 'FQ2';
    if (this.month >= 10 && this.month <= 12) return 'FQ3';
    console.log(this.month);
    return 'FQ4';
  }

  backButton() {
    this.router.navigate(['home/emea-allocation']);
  }
}
