import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DcmReportsComponent } from './dcm-reports.component';
import { DashboardsComponent } from './dashboards/dashboards/dashboards.component';
import { MatTableModule } from '@angular/material/table';
import { NotificationsComponent } from './dashboards/notifications/notifications.component';

@NgModule({
  declarations: [DcmReportsComponent, DashboardsComponent, NotificationsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    ShareComponentsModule,
  ],
  providers: [],
  bootstrap: [],
})
export class DcmReportsModule {}
