import { NgModule } from '@angular/core';

import { NgxEchartsModule } from 'ngx-echarts';
import { StackedColumnComponent } from './stacked-column.component-chart';

@NgModule({
  declarations: [StackedColumnComponent],
  exports: [StackedColumnComponent],
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [],
  bootstrap: [StackedColumnComponent],
})
export class AppEchart {}
