<ngx-spinner></ngx-spinner>
<div class="container-ka-execution">
  <div class="header-title">
    <div class="title">KA EXECUTION</div>
    <div class="container-export" >
      <button
        matTooltip="{{ tooltipMessage }}"
        matTooltipPosition="left"
        class="export-button"
        (click)="exportToExcelData()"
        [class.disabled]="disableElement"
        [disabled]="disableElement">
      </button>
    </div>
  </div>

  <app-search
    [filters]="searchInputs"
    (searchEvent)="onSearchEvent($event)"
    (applyFilterEvent)="applyFilter()"
    [showButtonApply]="true"
    [enableApplyFilter]="disableApply">
  </app-search>

  <mat-divider></mat-divider>

  <div class="quarter" *ngIf="showTable === true">
    <h4>{{ this.current.quarter }}</h4>
    <p>{{ this.currentWeekSubtitle }}</p>
  </div>

  <div class="no-filter-selected-session" *ngIf="showNoResults">
    <p>NO RECORDS FOUND.</p>
  </div>

  <app-ka-execution-table
    [data]="this.dataTable"
    [subtotal]="this.response.subtotals"
    [total]="this.response.totals"
    [response]="this.tableWatch"
    [tabMonth]="this.tabMonth"
    [tabSelected]="this.selectedTab"
    (changeTab)="changeTabData($event)"
    *ngIf="showTable">
  </app-ka-execution-table>

  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
