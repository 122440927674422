<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="wow-analysis">
    <div class="header-title">
      <span class="title">WOW ANALYSIS</span>
      <div class="container-export">
        <span
          class="material-icons"
          (click)="exportTable()"
          matTooltip="{{ tooltipMessage }}">
          <button
            class="export-button"
            [class.disabled]="isValid"
            [disabled]="isValid"></button>
        </span>
      </div>
    </div>

    <div class="executive-overview-content">
      <div class="filters">
        <app-search
          (searchEvent)="onSearchEvent($event)"
          [filters]="searchInputsWowVariation">
        </app-search>
      </div>

      <mat-divider></mat-divider>

      <!-- <div class="title-quarter">
        <span class="current-quarter">FQ{{ month }}</span>
      </div> -->

      <div class="header-table-wow-analysis" *ngIf="!noResultWowAnalysis">
        <div class="wow-territories">
          <span class="title-territories">Territories</span>
          <span class="body-territories">{{ territories }}</span>
        </div>
        <div class="button-filter">
          <div>
            <a class="next" (click)="nextQuarter(next)"
              >{{ next ? 'PREVIOUS' : 'NEXT' }} QUARTER</a
            >
          </div>
        </div>
      </div>

      <div class="no-filter-selected-session" *ngIf="noResultWowAnalysis">
        <p>NO RECORDS FOUND.</p>
      </div>

      <app-simple-table-header-fixed
        [data]="result"
        [currentWeek]="currentWeekLabel"
        *ngIf="!noResultWowAnalysis">
      </app-simple-table-header-fixed>
    </div>

    <div class="buttons-right">
      <button (click)="backButton()" class="button back-action-button" mat-button>
        BACK
      </button>
    </div>
  </div>
</div>
