import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { filtersMockup } from './filters-mockup';
import { tableMockup } from './table-mockup';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { CrudService } from 'src/app/services/generic/crud.service';
import { Material } from 'src/app/interfaces/material.interface';
import { ExcelService } from '../../../services/generic/excel.service';

@Component({
  selector: 'app-territory-and-products-view',
  templateUrl: './territory-and-products-view.component.html',
  styleUrls: ['./territory-and-products-view.component.scss'],
})
export class TerritoryAndProductsViewComponent implements OnInit {
  searchInput: InputBase<string>[];

  filterMockup = filtersMockup;
  tableMockup = tableMockup;
  showTable: boolean = false;
  result: any;
  summedValue: any[] = [];
  month: any;
  territorySelect: any;
  nextQuarterFilter: boolean = false;
  territoryEntity: any[] = [];
  territoryFilter: any[] = [];
  productEntity: any[] = [];
  productFilter: any[] = [];
  dataTypeFilter: any[] = [];
  dataTypeEntity: any[] = [];
  filter: any = {};
  next: boolean = false;
  enableApplyFilter: boolean = false;
  crudService: CrudService<any>;
  formSearchValues!: { [key: string]: any };
  isValid: boolean = true;
  tooltipMessage: string = 'Export';
  filterTable: any = [
    { label: 'Current Quarter', value: true },
    { label: 'Next Quarter', value: false },
  ];
  keysFilters = ['location_id', 'product_group', 'data_type'];
  productGroup: any;
  territory: any;
  dataType: any;
  fieldsValid: any[] = [];
  entities: Array<getEntities> = [
    {
      entity: 'location',
      query: {
        status: true,
        sort: 'territory',
        pageName: PAGE_NAME_RESOURCE.emeaTerritoryAndProductsView,
      },
    },
    {
      entity: 'emea-allocation/summary/product',
      query: { pageName: PAGE_NAME_RESOURCE.emeaTerritoryAndProductsView },
    },
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    protected http: HttpClient,
    private excelService: ExcelService
  ) {
    this.filter = {
      fiscal_quarter: this.getQuarterFromDate(new Date()),
    };
    this.filter = {
      ...this.filter,
      fiscal_year: this.checkCurrentYearAndQuarter(),
    };
    this.crudService = new CrudService<Material>(
      this.http,
      'emea-territory-products-view'
    );
    this.searchInput = [
      new MultiSelectSearchInput({
        key: 'location_id',
        hover: 'Territory',
        type: 'text',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'product_group',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'data_type',
        hover: 'Data Type',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
    this.dataTypeEntity = [
      {
        value: 'Demand',
      },
      {
        value: 'Supply',
      },
      {
        value: 'Orders',
      },
      {
        value: 'Orders Vs Supply',
      },
      {
        value: 'Orders Vs Demand',
      },
      {
        value: 'Supply Vs Demand',
      },
    ];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.getDataFilter();
    // this.getDataTable(this.filter);
    this.getQuarterFromDate(new Date());
  }

  getDataFilter(): void {
    this.spinner.show();
    this.crudService
      .getDataFilters(this.entities)
      .subscribe((response: any) => {
        if (response[0].length > 0 && response[1].length > 0) {
          this.enableApplyFilter = true;
          this.territoryEntity = response[0];
          this.productEntity = response[1];

          this.dataFilterTerritory(this.territoryEntity);
          this.dataFilterProduct(this.productEntity);
          this.dataFilterDataType(this.dataTypeEntity);
          this.applyFilter();
        } else {
          this.spinner.hide();
        }
      });
  }

  dataFilterTerritory(response: any): void {
    this.territoryFilter = [];

    response.forEach((item: any) => {
      this.territoryFilter.push({ value: item.territory });
    });

    const selectTerritory = new MultiSelectSearchInput({
      key: 'location_id',
      hover: 'Territory',
      type: 'text',
      classCss: 'territory-product-filters',
      hasAutocomplete: true,
      options: this.territoryFilter,
      value: this.territoryFilter,
    });
    this.searchInput[0] = selectTerritory;
    this.searchInput = [...this.searchInput];
  }

  dataFilterProduct(response: any): void {
    this.productFilter = [];

    response.forEach((item: any) => {
      this.productFilter.push({ value: item.product_group });
    });

    const selectProduct = new MultiSelectSearchInput({
      key: 'product_group',
      hover: 'Product',
      type: 'text',
      classCss: 'territory-product-others-filters',
      hasAutocomplete: true,
      options: this.productFilter,
      value: this.productFilter,
    });
    this.searchInput[1] = selectProduct;
    this.searchInput = [...this.searchInput];
  }

  dataFilterDataType(response: any): void {
    this.dataTypeFilter = [];

    response.forEach((item: any) => {
      this.dataTypeFilter.push({ value: item.value });
    });

    const selectDataType = new MultiSelectSearchInput({
      key: 'data_type',
      hover: 'Data Type',
      type: 'text',
      classCss: 'territory-product-others-filters',
      hasAutocomplete: true,
      options: this.dataTypeFilter,
      value: this.dataTypeFilter,
    });
    this.searchInput[2] = selectDataType;
    this.searchInput = [...this.searchInput];
  }

  getDataTable(params?: any): void {
    this.spinner.show();
    this.crudService.getEntity(`emea-territory-product-view?pageName=${PAGE_NAME_RESOURCE.emeaTerritoryAndProductsView}`, params).subscribe(
      (response: any) => {
        if (response?.table?.rows.length > 0) {
          this.updateComponents(response.table);
          this.isValid = false;
          this.showTable = true;
          this.spinner.hide();
        } else {
          this.isValid = true;
          this.showTable = false;
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.isValid = true;
        this.showTable = false;
        this.spinner.hide();
      }
    );
  }

  updateComponents(data: any) {

    data.rows.forEach((row: any) => {
      let acumulativeValue: number = 0;
      data.columns.forEach((column: any) => {
        if(!['supplyguidance', 'product', 'datatype', 'fqTotal'].includes(column.prop)) {
          acumulativeValue += parseFloat(row[column.prop].value);
        };
         row['fqTotal'].value = acumulativeValue;
      });
    });

    this.addSubtotalValues(data);

    data.columns.forEach((header: any) => {
      if (['boh', 'sab', 'supplyguidance', 'fqTotal'].includes(header.prop)) {
        data.rows.forEach((row: any) => {
          if (row[header.prop].value === 0) {
            row[header.prop].value = '-';
          }
        });
      }
    });

    this.result = {
      table: {
        columns: data.columns,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(data.rows))
        ),
        hasCalculation: true,
      },
    };
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];
    array.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  addSubtotalValues(data: any) {
    let headerName: any = [];
    this.summedValue = [];

    data.columns.map((header: any) => {
      headerName = header.prop;

      data.rows
        .map((item: any) => {
          if (item[headerName].summableValue) {
            return item[headerName]?.value;
          }
        })
        .reduce((acc: number, item: any) => {
          if (acc !== undefined && item !== undefined) {
            return (this.summedValue = acc + item);
          }
        }, 0);
      header.totalAddedValue = this.summedValue;
    });
  }

  applyFilter(): void {
    if (this.formSearchValues === undefined) {
      const location_id = this.dataFormSearchTerritoryIds(this.territoryFilter);
      const product_group = this.dataFormSearchProductIds(this.productFilter);
      const data_type = this.dataFormSearchDataTypeIds(this.dataTypeFilter);

      let formSearchValues = {};
      formSearchValues = {
        location_id,
        product_group,
        data_type,
      };

      this.filter = {
        ...this.filter,
        ...formSearchValues,
      };
    } else {
      const formSearchValues = { ...this.formSearchValues };

      this.filter = {
        ...this.filter,
        ...formSearchValues,
      };
    }
    this.getDataTable(this.filter);
  }

  editTitles(
    stringAtual: string,
    titulosSelecionados: string[],
    todosTitulos: string[]
  ): string {
    // Adicionar títulos selecionados
    for (const titulo of titulosSelecionados) {
      if (!stringAtual.includes(titulo)) {
        stringAtual += titulo + ', ';
      }
    }

    // Remover títulos não selecionados
    for (const titulo of todosTitulos) {
      if (!titulosSelecionados.includes(titulo)) {
        stringAtual = stringAtual.replace(titulo + ', ', '');
      }
    }

    return stringAtual;
  }

  dataFormSearchTerritoryIds(formSearchName: any) {
    const locationIds: any = [];
    this.territoryEntity.forEach((item: any) => {
      if (
        formSearchName.filter(
          (location_id: any) => location_id.value === item.territory
        ).length > 0
      ) {
        locationIds.push(item.id);
      }
    });
    return locationIds;
  }

  dataFormSearchProductIds(formSearchName: any) {
    const productIds: any = [];
    this.productEntity.forEach((item: any) => {
      if (
        formSearchName.filter(
          (product: any) => product.value === item.product_group
        ).length > 0
      ) {
        productIds.push(item.product_group);
      }
    });
    return productIds;
  }

  dataFormSearchDataTypeIds(formSearchName: any) {
    const dataTypeIds: any = [];
    this.dataTypeEntity.forEach((item: any) => {
      if (
        formSearchName.filter((dataType: any) => dataType.value === item.value)
          .length > 0
      ) {
        dataTypeIds.push(item.value);
      }
    });
    return dataTypeIds;
  }

  onSearchEvent(value: any): void {
    this.formSearchValues = { ...value };
    const territories: any[] = [];
    this.territorySelect = '';

    if (this.formSearchValues.location_id) {
      this.formSearchValues.location_id.forEach((row: any) => {
        territories.push(row.value);
      });

      if (this.territoryFilter.length === territories.length) {
        this.territorySelect = 'ALL';
      } else {
        this.territorySelect = this.editTitles(
          this.territorySelect,
          territories,
          this.territoryFilter
        );
      }

      this.formSearchValues.location_id = this.dataFormSearchTerritoryIds(
        this.formSearchValues.location_id
      );
    }

    if (this.formSearchValues.product_group) {
      this.formSearchValues.product_group = this.dataFormSearchProductIds(
        this.formSearchValues.product_group
      );
    }

    if (this.formSearchValues.data_type) {
      this.formSearchValues.data_type = this.dataFormSearchDataTypeIds(
        this.formSearchValues.data_type
      );
    }

    this.fieldsValid = [
      (this.territory = this.formSearchValues.location_id),
      (this.productGroup = this.formSearchValues.product_group),
      (this.dataType = this.formSearchValues.data_type),
    ];
    this.enableApplyFilter = this.fieldsValid.every(item => {
      return item.length > 0;
    });
    if (this.enableApplyFilter) {
      this.applyFilter();
    }
  }

  nextQuarter() {
    this.next = !this.next;
    this.filter.fiscal_quarter = this.getQuarterFromDate(new Date());
    this.filter.fiscal_year = this.checkQuarterAndYear();
    this.getDataTable(this.filter);
  }

  prepareExportTable(data: any) {
    this.spinner.show();

    // Função para adicionar valores subtotalizados
    function addSubtotalValues() {
      let headerName: any = [];
      let summedValue: any = [];
      columns
        .filter((item: any) => item.hasSubtotal)
        .forEach((header: any) => {
          headerName = header.props;

          rows
            .map((item: any) => {
              return item[headerName]?.value;
            })
            .reduce((acc: any, item: any) => {
              if (acc !== undefined && item !== undefined) {
                return (summedValue = acc + item);
              }
            }, 0);
          header.totalAddedValue = summedValue;
        });
    }

    const rows = data.table.rows.filteredData;
    const columns = data.table.columns.map((item: any) => ({
      title: item.label,
      props: item.prop,
      totalAddedValue: item.totalAddedValue,
    }));

    const tableRows: { [key: string]: any }[] = [];

    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: [] } = {};
      columns.forEach((column: any) => {
        if (rows[i][column.props]) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        } else {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    addSubtotalValues();

    // Cria a última linha da tabela
    const tableRow: { [key: string]: any } = {};
    columns.forEach((column: any) => {
      if (column.title.includes('PRODUCT')) {
        tableRow[column.props] = 'TOTAL';
      } else {
        tableRow[column.props] = column.totalAddedValue
          ? column.totalAddedValue
          : '-';
      }
    });
    tableRows.push(tableRow);

    return { columns: columns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.result);
    Object.defineProperty(fullJsonExport, 'sheet', {
      value: 'Confidential',
    });
    this.excelService.exportJsonAsExcelFile(
      fullJsonExport,
      'territory_and_products_view_export'
    );
    this.spinner.hide();
  }

  checkCurrentYearAndQuarter() {
    if (!this.filter.fiscal_year && this.filter.fiscal_quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  checkQuarterAndYear() {
    if (!this.next && this.filter.fiscal_quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarterFromDate(date: Date) {
    let month = date.getMonth() + 1;
    this.next === true
      ? (month = date.getMonth() + 4)
      : (month = date.getMonth() + 1);
    if (month >= 4 && month <= 6) return 'FQ1';
    if (month >= 7 && month <= 9) return 'FQ2';
    if (month >= 10 && month <= 12) return 'FQ3';
    return 'FQ4';
  }

  backButton() {
    this.router.navigate(['home/emea-allocation']);
  }
}
