import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-table-missing',
  templateUrl: './table-missing.component.html',
  styleUrls: ['./table-missing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMissingComponent implements OnInit {
  @Input() dataTable: any = {};
  tableHead: any = [];
  tableBody: any = [];
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataTable.currentValue) {
      this.dataTable = changes.dataTable.currentValue;
      this.loadDataTable();
    }
  }

  ngOnInit(): void {}

  loadDataTable() {
    this.tableHead = [];
    this.tableBody = [];
    this.dataTable.forEach((item: any) => {
      if (item.headers) {
        item.headers.forEach((value: any) => {
          this.tableHead.push(value);
        });
      } else if (item.rows) {
        item.rows.forEach((value: any) => {
          this.tableBody.push(value);
        });
      }
    });
  }

  checkString(td: any) {
    return isNaN(td);
  }

  checkPerc(string: any) {
    return string === 'tot';
  }
}
