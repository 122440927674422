import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InputBase, MultiSelectSearchInput, SelectSearchInput } from 'src/app/components/input/input-base';
import { KeysFilters } from 'src/app/interfaces/filter.interface';
import GetEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { Material } from 'src/app/interfaces/material.interface';
import { SupplySummary } from 'src/app/interfaces/supply.interface';
import { CurrentUser } from 'src/app/interfaces/user.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';

@Component({
  selector: 'app-supply-summary',
  templateUrl: './supply-summary.component.html',
  styleUrls: ['./supply-summary.component.scss']
})
export class SupplySummaryComponent implements OnInit {

  searchInputsSupplySummary: InputBase<string>[];
  enableApplyFilter: boolean;
  showNoResultsWow: boolean;
  tableResultWeekOverWeek!: any;
  nameTableWeekOverWeek: string;
  showNoResultsSupply: boolean;
  tableResultSupplySummary!: any;
  nameTableSupplySummary: string;
  originFilter: any[];
  currentUser!: CurrentUser;
  crudService: CrudService<Material>;
  entities: Array<GetEntities>; // verificar maiscula
  salesModelEntity: string[] = [];
  productFamilyEntity: string[] = [];
  colorEntity: string[] = [];
  salesModelFilter: any[];
  colorFilter: any;
  productFamilyFullData: any[] = [];
  salesModelsEntities: any[];
  dataSourceTableSupplySummary: any[];
  dataSourceTableWeekOverWeek: any[];
  dataSupplySumaryExport!: any;
  dataWeekOverWeekExport!: any;
  summedValue: any[];
  exportMontlyResult!: any;
  exportWeeklyResult!: any;
  exportResult!: any;
  formSearchValuesSupplySummary!: { [key: string]: any };
  keysFiltersSupplySummary: KeysFilters;
  next: boolean = true;
  filterQuarter: string = '';

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    protected http: HttpClient,
    private toastr: ToastrService,
    private excelSheetsService: ExcelSheetsService,
  ) {

    this.crudService = new CrudService<Material>(this.http, 'material');
    this.enableApplyFilter = false;
    this.showNoResultsWow = false;
    this.nameTableWeekOverWeek = 'wow';
    this.nameTableSupplySummary = 'ss';
    this.showNoResultsSupply = false;
    this.originFilter = [];
    this.salesModelFilter = [];
    this.salesModelsEntities = [];
    this.dataSourceTableSupplySummary = [];
    this.dataSourceTableWeekOverWeek = [];
    this.summedValue = [];

    this.keysFiltersSupplySummary = ['ppm', 'salesModelId', 'color', 'origin'];

    this.entities = [
      {
        entity: 'material-family',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'material/salesModels/summary',
        query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'material-colors',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
    ];

    this.searchInputsSupplySummary = [
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'Family',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'salesModelId',
        hover: 'Sales Model',
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'color',
        hover: 'Color',
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
      }),
    ];

    const selectOriginSupplySummary = new MultiSelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'filter-origin-supply-summary',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: this.originFilter,
    });

    this.searchInputsSupplySummary[3] = selectOriginSupplySummary;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];
   }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    [this.currentUser] = this.authService.getUserPermissions();
    
    const promises = [this.getDataFilter(), this.getDataTableSupplySummary()];

    this.hideSpinnerAfterAll(promises);
  }

  async getDataFilter(): Promise<void>{
    return new Promise((resolve, reject) => {

      this.spinner.show();
      
      this.crudService.getDataFilters(this.entities).subscribe(
        (response: any) => {
          this.productFamilyEntity = response[0];
          this.salesModelEntity = response[1];
          this.colorEntity = response[2];

          this.dataFilterOrigin();
          this.dataFilterSalesModel(this.salesModelEntity);
          this.dataFilterFamily(this.productFamilyEntity);
          this.dataFilterColor(this.colorEntity);
          this.productFamilyFullData = this.productFamilyEntity;
          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        }
      ); 
    });
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  dataFilterFamily(response: any) {
    const ppmFamily: any[] = [];

    response.forEach((item: any, index = 0) => {
      ppmFamily.push({
        id: index,
        materialId: item.ids,
        value: item.family,
      });
    });

    const selectPpmFamily = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'Family',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: ppmFamily,
      classCss: 'filter-family-ka-allocation',
      value: ppmFamily,
    });

    this.searchInputsSupplySummary[0] = selectPpmFamily;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];
  }

  dataFilterSalesModel(response: any){
    this.salesModelFilter = [];

    response.forEach((item: any) => {
      this.salesModelFilter.push({
        value: item.salesModel,
        id: item.materialId,
      });
    });

    const selectSalesModelSupplySummary = new MultiSelectSearchInput({
      key: 'salesModelId',
      hover: 'Sales Model',
      classCss: 'filter-supply-summary-allocation',
      hasAutocomplete: true,
      options: this.salesModelFilter,
      value: this.salesModelFilter,
      hasCheckFlowException: true,
    });

    this.searchInputsSupplySummary[1] = selectSalesModelSupplySummary;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];
  }

  dataFilterColor(response?: any) {
    this.colorFilter = [];

    response.forEach((item: any, index = 0) => {
      this.colorFilter.push({
        id: item.materialIds,
        value: item.colorDesc,
      });
    });

    const orderFilters = this.colorFilter.sort(this.alphabeticalOrder);

    const selectColor = new MultiSelectSearchInput({
      key: 'color',
      hover: 'Color',
      hasAutocomplete: true,
      classCss: 'filter-supply-summary-allocation',
      options: orderFilters,
      disabled: false,
      hasCheckFlowException: true,
    });

    this.searchInputsSupplySummary[2] = selectColor;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];
  }

  dataFilterOrigin(){
    this.originFilter.push({ value: 'JAG' }, { value: 'MAN' });
    const selectOriginSupplySummary = new MultiSelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'filter-origin-supply-summary',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: this.originFilter,
    });

    this.searchInputsSupplySummary[3] = selectOriginSupplySummary;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];
  }

  private resetValuesTableSupplySummaryAndWow() {
    this.tableResultSupplySummary = [];
    this.tableResultWeekOverWeek = [];
    this.dataSourceTableSupplySummary = [];
    this.dataSourceTableWeekOverWeek = [];
    this.showNoResultsSupply = false;
    this.showNoResultsWow = false;
  }

  private applySupplySummary(tab: any) {
    let formSearchValues = {};

    formSearchValues = {
      ...this.formSearchValuesSupplySummary,
    };
    let params = this.deleteProperty(formSearchValues, tab);
    params.quarter = this.filterQuarter;
    const dataSupplySummary = this.getDataTableSupplySummary(params);
    this.hideSpinnerAfterAll([dataSupplySummary]);
  }

  deleteProperty(item: any, tab?: string) {
    if (tab === 'supplySummary') {
      delete item.ppm;
    }
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  getDataTableSupplySummary(params?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.resetValuesTableSupplySummaryAndWow();
      this.crudService
        .getEntity(
          `kaAllocation/supplySumary?pageName=${PAGE_NAME_RESOURCE.kaAllocation}`,
          params
        )
        .subscribe(
          (response: any) => {
            this.processResponse(response);
            resolve();
          },
          error => {
            this.spinner.hide();
            reject(error);
          }
        );
    });
  }

  private processResponse(response: any) {
    if (response.supplySummary && response.supplySummary.rows.length > 0) {
      this.dataSupplySumaryExport = response.supplySummary;
      this.dataSourceTableSupplySummary.push(response);
      this.dataTableResulSupplySummary(
        this.dataSourceTableSupplySummary[0].supplySummary.headers,
        this.dataSourceTableSupplySummary[0].supplySummary.rows
      );
      this.addSubtotalValues(response);
      this.showNoResultsSupply = false;
    } else {
      this.showNoResultsSupply = true;
    }

    if (response.wow && response.wow.rows.length > 0) {
      this.dataWeekOverWeekExport = response.wow;
      this.dataSourceTableWeekOverWeek.push(response);
      this.dataTableResulSupplySummaryWeekly(
        this.dataSourceTableWeekOverWeek[0].wow.headers,
        this.dataSourceTableWeekOverWeek[0].wow.rows
      );
      this.addSubtotalValuesWow(response);
      this.showNoResultsWow = false;
    } else {
      this.showNoResultsWow = true;
    }
  }

  private dataTableResulSupplySummary(headers?: string, rows?: string) {
    this.tableResultSupplySummary = {
      table: {
        columns: headers,
        rows: new MatTableDataSource<string>(
          this.convertToDataSource(JSON.stringify(rows))
        ),
      },
    };
    this.exportComponents(this.tableResultSupplySummary, 'supplySumary');
  }

  private addSubtotalValues(response: any) {
    let headerName: any = [];
    response.supplySummary.headers.map((header: any) => {
      headerName = header.props;

      response.supplySummary.rows
        .map((item: any) => {
          if (item[headerName]?.summableValue) {
            return item[headerName].value;
          } else {
            return 0;
          }
        })
        .reduce((acc: any, item: any) => {
          if (acc !== undefined && item !== undefined) {
            return (this.summedValue = acc + item);
          }
        }, 0);
      header.totalAddedValue = this.summedValue;
    });
  }

  private dataTableResulSupplySummaryWeekly(headers?: string, rows?: string) {
    this.tableResultWeekOverWeek = {
      table: {
        columns: headers,
        rows: new MatTableDataSource<string>(
          this.convertToDataSource(JSON.stringify(rows))
        ),
      },
    };

    this.exportComponents(this.tableResultWeekOverWeek, 'weekly');
  }

  private addSubtotalValuesWow(response: any) {
    let headerName: any = [];
    response.wow.headers.map((header: any) => {
      headerName = header.props;

      response.wow.rows
        .map((item: any) => {
          if (item[headerName]?.summableValue) {
            return item[headerName].value;
          } else {
            return 0;
          }
        })
        .reduce((acc: any, item: any) => {
          if (acc !== undefined && item !== undefined) {
            return (this.summedValue = acc + item);
          }
        }, 0);
      header.totalAddedValue = this.summedValue;
    });
  }

  private convertToDataSource(data: string) {
    const array = JSON.parse(data);
    const dataTable: string[] = [];

    array.forEach((item: string) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  exportComponents(data: any, tabName: string) {
    if (tabName === 'supplySummary') {
      this.exportMontlyResult = {
        table: {
          columns: data?.table?.columns,
          rows: data?.table?.rows?.filteredData,
        },
      };
    }
    if (tabName === 'weekly') {
      this.exportWeeklyResult = {
        table: {
          columns: data?.table?.columns,
          rows: data?.table?.rows?.filteredData,
        },
      };
    }

    this.exportResult = Object.assign([
      this.exportMontlyResult,
      this.exportWeeklyResult,
    ]);
  }

  alphabeticalOrder(a: any, b: any) {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  }

  applyFilter(){
    this.applySupplySummary('supplySummary');
  }

  exportTable(){
      const exportData = [];

      const weeklyJsonExport = this.prepareExportTable(
        this.dataWeekOverWeekExport
      );
      const exportDataSupplySumary = this.prepareExportTable(
        this.dataSupplySumaryExport
      );

      Object.defineProperty(weeklyJsonExport, 'sheet', {
        value: 'Week_Over_Week',
      });
      Object.defineProperty(weeklyJsonExport, 'headers', {
        value: true,
      });

      Object.defineProperty(exportDataSupplySumary, 'sheet', {
        value: 'Supply_Summary',
      });
      Object.defineProperty(exportDataSupplySumary, 'headers', {
        value: true,
      });

      exportData.push(weeklyJsonExport, exportDataSupplySumary);

      this.excelSheetsService.exportJsonAsExcelFile(
        exportData,
        'SupplySummary'
      );
  }

  prepareExportTable(data: any){
      const columns = data.headers.map((item: any) => ({
        title: item.title,
        props: item.props,
      }));
      const auxColumns: any = [];
      data.headers.forEach((item: any) => {
        if (item.subtitle) {
          auxColumns.push({ title: item.title, subtitle: item.subtitle });
        }
      });
      auxColumns.forEach((one: any) => {
        columns.forEach((two: any) => {
          let auxOne: string = one.subtitle.toLowerCase();
          let auxTwo: string = two.props.toLowerCase();
          if (auxTwo.includes(auxOne)) {
            two.title = `${one.subtitle} - ${two.title}`;
          }
        });
      });
      const dataRowsInitial: any = [];
      data.rows.forEach((first: any) => {
        let familyAndColor = first.family.color;
        let firstNameFamily = first.family.value;
        dataRowsInitial.push({ family: firstNameFamily });
        familyAndColor.forEach((item: any) => {
          dataRowsInitial.push({ family: item.colorFamily });
        });
        columns.forEach((third: any) => {
          let propertyName: string = `${third.props}`;
          if (propertyName != 'family') {
            dataRowsInitial.forEach((fifth: any) => {
              if (fifth.family == firstNameFamily) {
                fifth[propertyName] = first[propertyName].value;
              }
            });
          }
        });
      });
      const familyAndProperty: any = [];
      columns.forEach((one: any) => {
        if (one.props != 'family') {
          data.rows.forEach((two: any) => {
            let getProperty: string = one.props;
            two[getProperty].color.forEach((three: any) => {
              familyAndProperty.push({
                family: three.colorFamily,
                prop: getProperty,
                value: three.value,
              });
            });
          });
        }
      });
      familyAndProperty.forEach((one: any) => {
        dataRowsInitial.forEach((two: any) => {
          let auxOne: string = one.family.replace(/\s/g, '');
          let auxTwo: string = two.family.replace(/\s/g, '');
          if (auxOne === auxTwo) {
            two[one.prop] = one.value;
          }
        });
      });
      const totalSupplySummary: any = [];
      totalSupplySummary.push({ family: 'TOTAL' });
      data.rows.forEach((one: any) => {
        columns.forEach((two: any) => {
          if (two.props != 'family') {
            if (!totalSupplySummary[0][two.props]) {
              totalSupplySummary[0][two.props] = one[two.props].value;
            } else {
              totalSupplySummary[0][two.props] =
                totalSupplySummary[0][two.props] + one[two.props].value;
            }
          }
        });
      });
      totalSupplySummary.forEach((item: any) => {
        dataRowsInitial.push(item);
      });
      return { columns: columns, rows: dataRowsInitial };
  }

  backButton(){
    this.router.navigate(['home/ka-allocation']);
  }

  dataFormSearchFamily(formSearchName: any) {
    const currentIdsFamily: any = [];

    this.productFamilyFullData?.forEach((item: any) => {
      if (formSearchName.ppm) {
        formSearchName.ppm?.forEach((data: any) => {
          if (item.family == data.value) {
            item.ids.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
        formSearchName.materialId = currentIdsFamily.map((item: any) => item);
      }
    });

    formSearchName.materialId = formSearchName.materialId
      ? formSearchName.materialId
      : '';
  }

  dataFormSearchSalesModelFiltered() {
    const salesModelFiltered: any = this.salesModelFilter.filter((item: any) =>
      this.formSearchValuesSupplySummary.materialId.includes(item.id)
    );

    const salesModelIds = salesModelFiltered.map((objeto: any) => objeto.id);

    this.formSearchValuesSupplySummary.salesModelId = salesModelFiltered;

    const selectSalesModelSupplySummary = new MultiSelectSearchInput({
      key: 'salesModelId',
      hover: 'Sales Model',
      classCss: 'filter-supply-summary-allocation',
      hasAutocomplete: true,
      options: salesModelFiltered,
      value: salesModelFiltered,
      hasCheckFlowException: true,
    });

    this.searchInputsSupplySummary[1] = selectSalesModelSupplySummary;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];

    return salesModelIds;
  }

  dataFormSearchColorsFiltered(salesModelIds: any) {
    const colorsIdsFiltered = salesModelIds.flat().filter((id: any) => {
      return this.colorFilter.some((color: any) => color.id.includes(id));
    });

    const colorsFiltered = this.colorFilter.filter((objeto: any) => {
      return objeto.id.some((id: any) => colorsIdsFiltered.includes(id));
    });

    const orderFilters = colorsFiltered.sort(this.alphabeticalOrder);

    const selectColor = new MultiSelectSearchInput({
      key: 'color',
      hover: 'Color',
      hasAutocomplete: true,
      classCss: 'filter-supply-summary-allocation',
      options: orderFilters,
      disabled: false,
      hasCheckFlowException: true,
    });

    this.searchInputsSupplySummary[2] = selectColor;
    this.searchInputsSupplySummary = [...this.searchInputsSupplySummary];
  }

  dataFormSearchColor(formSearchName: any) {
    if (formSearchName.color) {
      const currentColor = formSearchName.color.map((item: any) => item.value);
      formSearchName.color = currentColor;
    } else {
      formSearchName.color = '';
    }
  }

  dataFormSearchOrigin(formSearchName: any) {
    if (formSearchName.origin) {
      const currentOrigin = formSearchName.origin.map(
        (item: any) => item.value
      );
      formSearchName.origin = currentOrigin;
    } else {
      formSearchName.origin = '';
    }
  }

  dataFormSearchSalesModelIds(formSearchName: any) {
    if (formSearchName.salesModelId) {
      const currentSalesModel = formSearchName.salesModelId.map(
        (item: any) => item.id
      );
      formSearchName.salesModelId = currentSalesModel;
    } else {
      formSearchName.salesModelId = '';
    }
  }

  onSearchEvent(form: any){
    this.formSearchValuesSupplySummary = { ...form };

    const { nameFormControl, ppm, action, color, salesModelId, origin } =
      this.formSearchValuesSupplySummary;

    if (nameFormControl === 'ppm' && action !== 'clear' && ppm?.length > 0) {
      this.dataFormSearchFamily(this.formSearchValuesSupplySummary);
      const salesModelIds = this.dataFormSearchSalesModelFiltered();
      this.dataFormSearchColorsFiltered(salesModelIds);
    }
    if (
      (nameFormControl === 'ppm' && action === 'clear') ||
      (nameFormControl === 'ppm' && !ppm?.length)
    ) {
      this.dataFilterSalesModel(this.salesModelEntity);
      delete this.formSearchValuesSupplySummary.color;

      const currentSalesModel: { value: any; id: any }[] = [];
      this.salesModelEntity.forEach((item: any) => {
        currentSalesModel.push({
          value: item.salesModel,
          id: item.materialId,
        });
      });

      this.formSearchValuesSupplySummary.salesModelId = currentSalesModel;

      this.dataFilterColor(this.colorEntity);
      this.enableApplyFilter = true;
    }

    if (nameFormControl === 'color') {
      if (color?.length > 0) {
        this.dataFormSearchColor(this.formSearchValuesSupplySummary);
        this.enableApplyFilter = true;
      } else if ((!color?.length || action === 'clear') && !ppm?.length) {
        this.dataFilterColor(this.colorEntity);
      } else if (action === 'clear') {
        this.dataFormSearchColor(this.formSearchValuesSupplySummary);
      }
    }

    if (nameFormControl === 'salesModelId') {
      this.enableApplyFilter = true;
      this.dataFormSearchColor(this.formSearchValuesSupplySummary);
    }

    if (nameFormControl === 'origin') {
      this.dataFormSearchColor(this.formSearchValuesSupplySummary);
    }

    this.dataFormSearchFamily(this.formSearchValuesSupplySummary);

    this.enableApplyFilter = Boolean(
      this.formSearchValuesSupplySummary[this.keysFiltersSupplySummary[0]]
        ?.length > 0 ||
        this.formSearchValuesSupplySummary[this.keysFiltersSupplySummary[1]]
          ?.length > 0 ||
        this.formSearchValuesSupplySummary[this.keysFiltersSupplySummary[2]]
          ?.length > 0 ||
        this.formSearchValuesSupplySummary[this.keysFiltersSupplySummary[3]]
          ?.length > 0
    );

    this.dataFormSearchOrigin(this.formSearchValuesSupplySummary);
    this.dataFormSearchSalesModelIds(this.formSearchValuesSupplySummary);
  }

  nextQuarterSupplySummary(next: boolean) {
    this.spinner.show();
    this.filterQuarter = next ? 'next' : '';

    if(this.enableApplyFilter){
      this.applySupplySummary('supplySummary');
    }
    this.hideSpinnerAfterAll([this.getDataTableSupplySummary({quarter: this.filterQuarter})]);
    this.next = !this.next;
  }
}
