import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-color-mix',
  templateUrl: './table-color-mix.component.html',
  styleUrls: ['./table-color-mix.component.scss'],
})
export class TableColorMixComponent implements OnInit {
  @Input() dataTable: any = {};
  tableHead: any = [];
  tableBody: any = {};

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataTable.currentValue) {
      this.dataTable = changes.dataTable.currentValue;
      this.loadDataTable();
    }
  }

  loadDataTable() {
    this.tableHead = [];
    this.tableBody = {};
    this.dataTable.headers.forEach((item: any) => {
      this.tableHead.push(item);
    });
    this.tableBody = this.returnColor(this.dataTable.rows); 
  }
  
  returnColor(body: any) {
    body.forEach((item: any) => {
      let maxDemand: number = item.demand.reduce((a: any, b: any)=> Math.max(a,b), -Infinity);
      let positionDemand: number = 0;
      item.demand.forEach((data: any, index = 0)=>{
        if(data == maxDemand){
          positionDemand = index;
        }
      });
      let opCommitFromPosition: number = 0;
      if(maxDemand == 0){
        opCommitFromPosition = item.opCommitPer.reduce((a: any, b: any)=> Math.max(a,b), -Infinity);
      }else{
        opCommitFromPosition = item.opCommitPer[positionDemand];
      }
      let resultFinal = Math.abs(maxDemand - opCommitFromPosition);
      if (resultFinal <= 20) {
        item.borderColor = '#2fb23c';
      } else if (resultFinal <= 60) {
        item.borderColor = 'yellow';
      } else if(resultFinal >=61){
        item.borderColor = '#cb0c0c';
      }
    });

    return body;
  }

  detectClass(classCard: string){
    if(classCard === 'product'){
      return 'first-card-style';
    }
    return 'card-style';
  }
}
