import {
  SimpleChanges, Component, EventEmitter, Input, Output,
} from '@angular/core';
import PermissionsByModuleInterface from 'src/app/interfaces/genericInterfaces/permissionsByModuleInterface';

@Component({
  selector: 'app-tab-permissions',
  templateUrl: './tabPermissions.component.html',
  styleUrls: ['./tabPermissions.component.scss'],
})

export class PermissionsComponent {
  @Input() dataPermissions!: Array<PermissionsByModuleInterface>;
  @Input('editMode') editMode!: boolean;
  @Input('viewMode') viewMode!: boolean;
  @Output('changePermissions') changePermissions = new EventEmitter<any>();

  permissions: any = { active: false, permission: 'ALL' };

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    this.dataPermissions.forEach(this.addSelectAllPermission);

    if (changes.dataPermissions.currentValue) {
      this.dataPermissions = changes.dataPermissions.currentValue;
    }
  }

  addSelectAllPermission(permission: any) {
    permission.resources.forEach((resource: any) => {
      let {id, path, active} = resource.permissions[0];
      resource.permissions.push({
        id,
        permission: 'ALL',
        method: 'All',
        path,
        active
      });
    });
  }

  onChangeAll(event: any, permission: any, permissionsForModule: any) {
    const clickAll: boolean = event.ripple._isInitialized;
    if (permission.permission === 'ALL') {
      permissionsForModule.permissions.forEach((itemPermission: any) => {
          itemPermission.active = !itemPermission.active;
          this.permissions = { active: clickAll, permission: 'ALL' };
      });
  }
    if (this.editMode) {
        permission.active = event.ripple._isInitialized;
        this.changePermissions.emit(this.dataPermissions);
    }
  }

  onChangePermissions(value: any, permission: any, permissionsForModule: any) {
    switch (permission.permission) {
      case 'VIEW':
        if (!value.checked) {
          permissionsForModule.permissions.forEach(
            (itemPermission: any) => { itemPermission.active = false; },
          );
        }
        break;
      default:
        if (value.checked) {
          permissionsForModule.permissions.find((itemPermission: any) => {
            if (itemPermission.path == '/setupManagement') {
              itemPermission.active = true;
            }
          });
        } else {
          permissionsForModule.permissions.find((itemPermission: any) => {
            if (itemPermission.path == '/setupManagement') {
              itemPermission.active = false;
            }
          });
        }
    }

    const permissionView = permissionsForModule.permissions.find((itemPermission: any) => itemPermission.permission == 'VIEW');
    permissionView.active = true;

    if (this.editMode) {
      permission.active = value.checked;
      this.changePermissions.emit(this.dataPermissions);
    }
  }
}
