import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from './translator.service';

@Injectable({
  providedIn: 'root',
})
export class ValidateFormService {
  constructor(
    private toastr: ToastrService,
    private translatorService: TranslatorService
  ) {}

  onValidateFields(formData: any) {
    const fields: { key: string; errors: any; value: any }[] = [];
    const { controls } = formData;
    let isValidate = true;
    let length = 0;

    Object.keys(controls).forEach(key => {
      if (!key.includes('id')) {
        length++;
      }
      if (controls[key].errors) {
        fields.push({
          key: key.toString().charAt(0).toUpperCase() + key.toString().slice(1),
          errors: controls[key].errors,
          value: controls[key].value,
        });
      }
    });
    fields.forEach(item => {
      item.value =
        item.value && !Array.isArray(item.value) ? item.value.trim() : '';
      if (!item.value) {
        const itemKey: string = item.key
          .toLowerCase()
          .replace('_id', '')
          .replace('id', '')
          .replaceAll('_', ' ')
          .replace(/(?:^|\s)\S/g, key => key.toUpperCase());
        if (itemKey === 'Active')
          this.toastr.warning(
            `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
            'Status'
          );
        else if (itemKey === 'Countries')
          this.toastr.warning(
            `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
            'Country'
          );
        else if (itemKey === 'Localmarketname')
          this.toastr.warning(
            `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
            'Local Market Name'
          );
        else if (itemKey === 'Localcolordesc')
          this.toastr.warning(
            `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
            'Local Color Desc'
          );
        else if (itemKey === 'Salesmodel')
          this.toastr.warning(
            `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
            'Sales Model'
          );
        else {
          const itemKeyTitle =
            itemKey.length < 4 ? itemKey.toLocaleUpperCase() : itemKey;
          this.toastr.warning(
            `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
            itemKeyTitle === 'Sales Manager' || itemKeyTitle === 'Order Admin'
              ? `This user is a ${itemKeyTitle}?`
              : itemKeyTitle.replace('_user', '')
          );
        }
        isValidate = false;
      } else if (Object.keys(item.errors).includes('maxlength')) {
        this.toastr.warning(
          `${this.translatorService.getMessage('CRUD_FIELD_MAX_LENGTH')} ${
            item.errors.maxlength.requiredLength
          } characters`,
          `New ${item.key} invalid!`
        );
        isValidate = false;
      } else if (Object.keys(item.errors).includes('pattern')) {
        this.toastr.warning(
          `${this.translatorService.getMessage('CRUD_EMAIL_INVALID')}`,
          `${this.translatorService.getTitle('CRUD_EMAIL_INVALID')}`
        );
        isValidate = false;
      } else if (Object.keys(item.errors).includes('selected')) {
        this.toastr.warning(
          `${this.translatorService.getMessage('CRUD_FIELD_EMPTY')}`,
          'Color pick'
        );
        isValidate = false;
      }
    });
    return isValidate;
  }
}
