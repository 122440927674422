export const emeaAllocationMockup: any = [{
  "subseries_desc": "XT2301-4",
  "product_group": "CANYON23",
  "ram": "12GB",
  "rom": "256GB",
  "color": "GLASS BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2255-1",
  "product_group": "VICTORIA22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "METEORITE BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2255-1",
  "product_group": "VICTORIA22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "METEORITE BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2255-1",
  "product_group": "VICTORIA22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "NIAGARA BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2251-1",
  "product_group": "MAVEN22",
  "ram": "8GB",
  "rom": "256GB",
  "color": "QUARTZ BLACK",
  "sim": "Single sim unlocked"
}, {
  "subseries_desc": "XT2245-1",
  "product_group": "MIAMI22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "OPAL SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2245-1",
  "product_group": "MIAMI22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "MOONLESS NIGHT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2245-1",
  "product_group": "MIAMI22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "VERY PERI",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2243-1",
  "product_group": "TUNDRA22",
  "ram": "12GB",
  "rom": "256GB",
  "color": "LAZULI BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2243-1",
  "product_group": "TUNDRA22",
  "ram": "12GB",
  "rom": "256GB",
  "color": "QUARTZ BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2243-1",
  "product_group": "TUNDRA22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "QUARTZ BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2243-1",
  "product_group": "TUNDRA22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "OPAL WHITE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2241-2",
  "product_group": "FRONTIER22",
  "ram": "12GB",
  "rom": "256GB",
  "color": "ASH GRAY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2241-2",
  "product_group": "FRONTIER22",
  "ram": "12GB",
  "rom": "256GB",
  "color": "CLARK WHITE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-3",
  "product_group": "DEVON22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "SOFT SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-3",
  "product_group": "DEVON22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "DOVE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-3",
  "product_group": "DEVON22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "FLEECE GOLD",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "4GB",
  "rom": "64GB",
  "color": "DOVE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "DOVE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "SOFT SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "FLEECE GOLD",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "4GB",
  "rom": "128GB",
  "color": "DOVE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "4GB",
  "rom": "64GB",
  "color": "SOFT SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2235-2",
  "product_group": "DEVON22",
  "ram": "4GB",
  "rom": "128GB",
  "color": "SOFT SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2225-1",
  "product_group": "RHODE5GPLUS22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "INK BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2225-1",
  "product_group": "RHODE5GPLUS22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "ELECTRO-OPTIC WHITE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2223-1",
  "product_group": "RHODE5G22",
  "ram": "4GB",
  "rom": "64GB",
  "color": "TRANQUIL MIDNIGHT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2223-1",
  "product_group": "RHODE5G22",
  "ram": "4GB",
  "rom": "128GB",
  "color": "TRANQUIL MIDNIGHT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2223-1",
  "product_group": "RHODE5G22",
  "ram": "4GB",
  "rom": "64GB",
  "color": "CALAMINE BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2223-1",
  "product_group": "RHODE5G22",
  "ram": "4GB",
  "rom": "128GB",
  "color": "CALAMINE BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2223-1",
  "product_group": "RHODE5G22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "TRANQUIL MIDNIGHT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2221-1",
  "product_group": "RHODE4G22",
  "ram": "4GB",
  "rom": "128GB",
  "color": "TWILIGHT BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2221-1",
  "product_group": "RHODE4G22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "PEAK BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2221-1",
  "product_group": "RHODE4G22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "TWILIGHT BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2221-1",
  "product_group": "RHODE4G22",
  "ram": "6GB",
  "rom": "128GB",
  "color": "GARDENIA",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2221-1",
  "product_group": "RHODE4G22",
  "ram": "4GB",
  "rom": "128GB",
  "color": "GARDENIA",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2203-1",
  "product_group": "DUBAI22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "MOJITO",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2203-1",
  "product_group": "DUBAI22",
  "ram": "8GB",
  "rom": "256GB",
  "color": "SILENCE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2203-1",
  "product_group": "DUBAI22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "SILENCE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2203-1",
  "product_group": "DUBAI22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "OPAL SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2203-1",
  "product_group": "DUBAI22",
  "ram": "8GB",
  "rom": "256GB",
  "color": "MOJITO",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2201-1",
  "product_group": "ROGUE22",
  "ram": "12GB",
  "rom": "256GB",
  "color": "COSMOS",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2201-1",
  "product_group": "ROGUE22",
  "ram": "12GB",
  "rom": "256GB",
  "color": "STARDUST",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2175-1",
  "product_group": "YUKON22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "MIRAGE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2175-1",
  "product_group": "YUKON22",
  "ram": "8GB",
  "rom": "128GB",
  "color": "SEA ICE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-4",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "STERLING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-4",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "GRAPHITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-4",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "64GB",
  "color": "METEORITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-4",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "METEORITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-4",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "64GB",
  "color": "STERLING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-4",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "64GB",
  "color": "GRAPHITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "64GB",
  "color": "GRAPHITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "DARK GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "64GB",
  "color": "STERLING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "64GB",
  "color": "DARK GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "GRAPHITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "STERLING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2173-3",
  "product_group": "CORFULITE21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "METEORITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2169-1",
  "product_group": "CORFU5G21",
  "ram": "6GB",
  "rom": "128GB",
  "color": "NEPTUNE GREEN",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2169-1",
  "product_group": "CORFU5G21",
  "ram": "6GB",
  "rom": "128GB",
  "color": "OPAL IRIS",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2169-1",
  "product_group": "CORFU5G21",
  "ram": "6GB",
  "rom": "128GB",
  "color": "IRON BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2167-2",
  "product_group": "CORFU21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "METEORITE BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2167-2",
  "product_group": "CORFU21",
  "ram": "4GB",
  "rom": "128GB",
  "color": "ZINC SILK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2167-2",
  "product_group": "CORFU21",
  "ram": "6GB",
  "rom": "128GB",
  "color": "METEORITE BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2167-2",
  "product_group": "CORFU21",
  "ram": "6GB",
  "rom": "128GB",
  "color": "ZINC SILK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2165-5",
  "product_group": "TONGA22",
  "ram": "4GB",
  "rom": "64GB",
  "color": "Dark Grove",
  "sim": "Single sim unlocked"
}, {
  "subseries_desc": "XT2153-1",
  "product_group": "SIERRA",
  "ram": "12GB",
  "rom": "256GB",
  "color": "ROYAL INDIGO",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2153-1",
  "product_group": "SIERRA",
  "ram": "12GB",
  "rom": "256GB",
  "color": "THUNDER BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2153-1",
  "product_group": "SIERRA",
  "ram": "12GB",
  "rom": "256GB",
  "color": "OPTIC WHITE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2149-1",
  "product_group": "SAIPAN",
  "ram": "4GB",
  "rom": "128GB",
  "color": "METEORITE GREY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2149-1",
  "product_group": "SAIPAN",
  "ram": "4GB",
  "rom": "128GB",
  "color": "TENDER GREEN",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2145-1",
  "product_group": "IBIZA PREMIUM",
  "ram": "4GB",
  "rom": "128GB",
  "color": "SUPER GRAY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2143-1",
  "product_group": "BERLIN",
  "ram": "8GB",
  "rom": "128GB",
  "color": "DREAMY WHITE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2143-1",
  "product_group": "BERLIN",
  "ram": "8GB",
  "rom": "128GB",
  "color": "STOUT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2143-1",
  "product_group": "BERLIN",
  "ram": "8GB",
  "rom": "256GB",
  "color": "STOUT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2143-1",
  "product_group": "BERLIN",
  "ram": "8GB",
  "rom": "128GB",
  "color": "EMERALD",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2143-1",
  "product_group": "BERLIN",
  "ram": "6GB",
  "rom": "128GB",
  "color": "STOUT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2139-1",
  "product_group": "KYOTO",
  "ram": "6GB",
  "rom": "128GB",
  "color": "MYSTIC LAGOON",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2139-1",
  "product_group": "KYOTO",
  "ram": "6GB",
  "rom": "128GB",
  "color": "ELECTRIC GUNMETAL",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2139-1",
  "product_group": "KYOTO",
  "ram": "8GB",
  "rom": "128GB",
  "color": "ELECTRIC GUNMETAL",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2139-1",
  "product_group": "KYOTO",
  "ram": "8GB",
  "rom": "128GB",
  "color": "MYSTIC LAGOON",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2137-1",
  "product_group": "IBIZA",
  "ram": "4GB",
  "rom": "64GB",
  "color": "A I AQUA",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2137-1",
  "product_group": "IBIZA",
  "ram": "4GB",
  "rom": "128GB",
  "color": "A I AQUA",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2137-1",
  "product_group": "IBIZA",
  "ram": "4GB",
  "rom": "128GB",
  "color": "SUPER GRAY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2137-1",
  "product_group": "IBIZA",
  "ram": "4GB",
  "rom": "64GB",
  "color": "SUPER GRAY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2135-2",
  "product_group": "HANOI+",
  "ram": "6GB",
  "rom": "128GB",
  "color": "MOONLESS",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2135-2",
  "product_group": "HANOI+",
  "ram": "6GB",
  "rom": "128GB",
  "color": "HAZE GRAY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2135-2",
  "product_group": "HANOI+",
  "ram": "6GB",
  "rom": "128GB",
  "color": "SOFT SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2133-2",
  "product_group": "LISBON",
  "ram": "6GB",
  "rom": "128GB",
  "color": "MIDNIGHT BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2133-2",
  "product_group": "LISBON",
  "ram": "4GB",
  "rom": "128GB",
  "color": "AQUA SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2133-2",
  "product_group": "LISBON",
  "ram": "6GB",
  "rom": "128GB",
  "color": "AQUA SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2133-2",
  "product_group": "LISBON",
  "ram": "4GB",
  "rom": "128GB",
  "color": "MIDNIGHT BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2125-4",
  "product_group": "TAHOE",
  "ram": "8GB",
  "rom": "128GB",
  "color": "IRIDESCENT BERYL",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2125-4",
  "product_group": "TAHOE",
  "ram": "8GB",
  "rom": "128GB",
  "color": "SLATE LUSTRE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2125-4",
  "product_group": "TAHOE",
  "ram": "8GB",
  "rom": "128GB",
  "color": "IRIDESCENT SKY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2117-4",
  "product_group": "BORNEO NA",
  "ram": "4GB",
  "rom": "64GB",
  "color": "FLASH GRAY",
  "sim": "Single sim unlocked"
}, {
  "subseries_desc": "XT2113-3",
  "product_group": "KIEV ROW",
  "ram": "6GB",
  "rom": "128GB",
  "color": "HAZY SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2113-3",
  "product_group": "KIEV ROW",
  "ram": "4GB",
  "rom": "64GB",
  "color": "HAZY SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2113-3",
  "product_group": "KIEV ROW",
  "ram": "6GB",
  "rom": "128GB",
  "color": "MOLTEN LAVA",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2113-3",
  "product_group": "KIEV ROW",
  "ram": "4GB",
  "rom": "64GB",
  "color": "MOLTEN LAVA",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2091-8",
  "product_group": "CEBU",
  "ram": "4GB",
  "rom": "128GB",
  "color": "JASPER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2091-8",
  "product_group": "CEBU",
  "ram": "4GB",
  "rom": "128GB",
  "color": "JAZZ BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2091-3",
  "product_group": "CEBU",
  "ram": "4GB",
  "rom": "128GB",
  "color": "JASPER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2091-3",
  "product_group": "CEBU",
  "ram": "4GB",
  "rom": "128GB",
  "color": "JAZZ BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2087-2",
  "product_group": "ODESSA",
  "ram": "6GB",
  "rom": "128GB",
  "color": "DEEP DIVE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2087-2",
  "product_group": "ODESSA",
  "ram": "4GB",
  "rom": "128GB",
  "color": "COPPER ROSE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2087-2",
  "product_group": "ODESSA",
  "ram": "6GB",
  "rom": "128GB",
  "color": "COPPER ROSE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2087-2",
  "product_group": "ODESSA",
  "ram": "4GB",
  "rom": "128GB",
  "color": "DEEP DIVE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2083-9",
  "product_group": "ATHENA",
  "ram": "4GB",
  "rom": "64GB",
  "color": "GREEN",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2083-9",
  "product_group": "ATHENA",
  "ram": "4GB",
  "rom": "64GB",
  "color": "BLACK",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2075-3",
  "product_group": "NAIROBI",
  "ram": "8GB",
  "rom": "128GB",
  "color": "AZURY",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2075-3",
  "product_group": "NAIROBI",
  "ram": "4GB",
  "rom": "64GB",
  "color": "SURFING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2075-3",
  "product_group": "NAIROBI",
  "ram": "6GB",
  "rom": "128GB",
  "color": "SURFING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2075-3",
  "product_group": "NAIROBI",
  "ram": "4GB",
  "rom": "64GB",
  "color": "SURFING BLUE",
  "sim": "Single sim unlocked"
}, {
  "subseries_desc": "XT2075-3",
  "product_group": "NAIROBI",
  "ram": "8GB",
  "rom": "128GB",
  "color": "SURFING BLUE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2071-4",
  "product_group": "ODYSSEY",
  "ram": "8GB",
  "rom": "256GB",
  "color": "TIN SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2071-4",
  "product_group": "ODYSSEY",
  "ram": "8GB",
  "rom": "256GB",
  "color": "MERCURY SILVER",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2071-4",
  "product_group": "ODYSSEY",
  "ram": "8GB",
  "rom": "256GB",
  "color": "BLUSH GOLD",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2067-1",
  "product_group": "LIBERTY",
  "ram": "6GB",
  "rom": "128GB",
  "color": "WHITE",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2067-1",
  "product_group": "LIBERTY",
  "ram": "6GB",
  "rom": "128GB",
  "color": "CLOUD",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2063-4",
  "product_group": "RACER 5G",
  "ram": "6GB",
  "rom": "128GB",
  "color": "MIDNIGHT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2063-3",
  "product_group": "RACER 5G",
  "ram": "6GB",
  "rom": "128GB",
  "color": "MIDNIGHT",
  "sim": "Dual SIM unlocked"
}, {
  "subseries_desc": "XT2061-3",
  "product_group": "RACER TURBO",
  "ram": "12GB",
  "rom": "256GB",
  "color": "BALTIC GRAY",
  "sim": "Single sim unlocked"
}, {
  "subseries_desc": "XT2043-7",
  "product_group": "SOFIA+ B2B",
  "ram": "4GB",
  "rom": "128GB",
  "color": "GRAPHENE BLUE",
  "sim": "Dual SIM unlocked"
}]
