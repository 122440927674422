<div class="permission-content">
  <mat-dialog-content>
    <mat-tab-group mat-align-tabs="start" class="tab-label" animationDuration="0ms">
      <mat-tab *ngFor="let category of dataPermissions" label="{{ category.category }}">
        <mat-list>
          <mat-list-item *ngFor="let resources of category.resources; let permissionAll = index;">
            <div class="list-path" mat-line>{{ resources.description }}</div>
            <mat-action-list id="actions-profile" class="permissions-actions">
              <ng-container *ngFor="let type of resources.permissions">
                <div *ngIf="type.permission === 'VIEW'" class="check-image view"
                  matTooltip="{{ type.permission | titlecase }}">
                  <label for="{{
                      editMode ? resources.resource + '-' + type.permission : ''
                    }}">
                    <input type="checkbox" [checked]="type.active"
                      name="{{ resources.resource + '-' + type.permission }}"
                      id="{{ resources.resource + '-' + type.permission }}"
                      (change)="onChangePermissions($event.target, type, resources)" />
                    <i class="icon-all" [class.label-icon-disabled]="!editMode"></i>
                  </label>
                </div>
                <div *ngIf="type.permission === 'CREATE'" class="check-image create"
                  matTooltip="{{ type.permission | titlecase }}">
                  <label for="{{
                      editMode ? resources.resource + '-' + type.permission : ''
                    }}">
                    <input type="checkbox" [checked]="type.active"
                      name="{{ resources.resource + '-' + type.permission }}"
                      id="{{ resources.resource + '-' + type.permission }}"
                      (change)="onChangePermissions($event.target, type, resources)" />
                    <i class="icon-all" [class.label-icon-disabled]="!editMode"></i>
                  </label>
                </div>
                <div *ngIf="type.permission === 'UPDATE'" class="check-image update"
                  matTooltip="{{ 'edit' | titlecase }}">
                  <label for="{{
                      editMode ? resources.resource + '-' + type.permission : ''
                    }}">
                    <input type="checkbox" [checked]="type.active"
                      name="{{ resources.resource + '-' + type.permission }}"
                      id="{{ resources.resource + '-' + type.permission }}"
                      (change)="onChangePermissions($event.target, type, resources)" />
                    <i class="icon-all" [class.label-icon-disabled]="!editMode"></i>
                  </label>
                </div>
                <div *ngIf="resources.resource != 'material-management'">
                  <div *ngIf="type.permission === 'DELETE'" class="check-image delete"
                    matTooltip="{{ type.permission | titlecase }}">
                    <label for="{{
                        editMode ? resources.resource + '-' + type.permission : ''
                      }}">
                      <input type="checkbox" [checked]="type.active"
                        name="{{ resources.resource + '-' + type.permission }}"
                        id="{{ resources.resource + '-' + type.permission }}"
                        (change)="onChangePermissions($event.target, type, resources)" />
                      <i class="icon-all" [class.label-icon-disabled]="!editMode"></i>
                    </label>
                  </div>
                </div>
                <div *ngIf="!viewMode">
                  <div *ngIf="type.permission === 'ALL'" class="icon-all-permission"
                    [class.icon-all-permission-enable]="type.active" matTooltip="{{ 'select all' | titlecase }}">
                    <button mat-icon-button #event name="{{ permissions.permissions }}" id="{{ permissions.active }}"
                    (click)="onChangeAll(event, permissions, resources)">
                      <mat-icon>touch_app</mat-icon>
                    </button>
                  </div>
                </div>
              </ng-container>
            </mat-action-list>
          </mat-list-item>
        </mat-list>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</div>
