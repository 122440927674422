<div *ngIf="typeTable === ''" class="grid-table">
  <app-simple-table
    #simpleTable
    [filterKaRetail]="filterKaRetail"
    [dataTable]="simpleTableResult">
  </app-simple-table>
  <app-table-expandable
    class="expandableTable"
    #tableExpandable
    [filterKaRetail]="filterKaRetail"
    [dataTable]="expandedTableResult"
    (inputChanged)="inputChanged($event)"
    [allocationForm]="allocationForms[0]"></app-table-expandable>
</div>
<div *ngIf="typeTable === 'allocationByPPM'">
  <app-table-expandable-manager
    #tableExpandable
    [filterKaRetail]="filterKaRetail"
    [dataSourceTableManager]="dataSourceTableManager"
    [allocationForms]="allocationForms">
  </app-table-expandable-manager>
</div>
