<div class="container">
  <div class="title">KA ALLOCATION</div>
  <div class="container-menu">
    <nav-card [itemsList]="itemsList"></nav-card>
    <button
      class="button back-action-button position-back-button"
      mat-button
      (click)="backButton()">
      BACK
    </button>
  </div>
</div>
