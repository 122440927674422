<div class="table-columns-tabs-booked" *ngIf="!showNoResults">
  <app-column-toggle [thead]="this.columns"></app-column-toggle>

  <div class="content-tables">
    <table id="table" class="overflow-table">
      <thead>
        <tr>
          <th
            *ngFor="let header of columns"
            [attr.name]="header.props"
            [hidden]="!header.status"
            class="column">
            <ng-container>
              <div
                [ngClass]="{
                  'left-columns': expecificProp(header.props)
                }">
                <span>
                  {{ header.title }}
                </span>
              </div>
            </ng-container>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let rowData of data">
          <ng-container *ngFor="let cellData of rowData.data; let i = index">
            <tr>
              <td
                [ngClass]="{
                  'centrelize-items': !expecificProp(header.props)
                }"
                *ngFor="let header of columns"
                [attr.name]="header.props"
                [hidden]="!header.status">
                <span>
                  <app-value-currency
                    [class.negative-value]="cellData[header.props] < 0"
                    [value]="checkAndSetEmptyTitle(cellData[header.props], i)">
                  </app-value-currency
                ></span>
              </td>
            </tr>

            <ng-container *ngIf="i === rowData.data.length - 1">
              <tr>
                <td
                  class="subtotal"
                  *ngFor="let header of columns"
                  [hidden]="!header.status">
                  <span *ngIf="header.props === 'family'" class="title-subtotal"
                    >SUBTOTAL
                  </span>
                  <ng-container *ngIf="!expecificProp(header.props)">
                    <div class="subtotal-value">
                      <span [class.negative-value]="cellData[header.props] < 0">
                        {{
                          data.subtotals[
                            this.getSubtotalKeys(rowData.family, rowData.origin)
                          ].subtotalsByColumn[header.props]
                        }}
                      </span>
                    </div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot id="footer">
        <ng-container>
          <tr>
            <ng-container *ngFor="let header of columns">
              <td class="total" [hidden]="!header.status">
                <span *ngIf="header.props === 'family'" class="title-total"
                  >TOTAL
                </span>
                <ng-container *ngIf="!expecificProp(header.props)">
                  <div class="total-value">
                    <span
                      [class.negative-value]="
                        data.totals.totalsByColumn[header.props] < 0
                      ">
                      {{
                        data.totals.totalsByColumn[header.props]
                          | number : '1.0-0' || 0
                      }}
                    </span>
                  </div>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tfoot>
    </table>
  </div>
</div>

<div class="no-filter-selected-session" *ngIf="showNoResults">
  <p>NO RECORDS FOUND.</p>
</div>
