import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputAlphanumericDirective } from './inputAlphanumeric/input-alphanumeric.directive';
import { OnlyNumberDirective } from './onlyNumberDirective/only-number.directive';
import { NoSpecialCharsDirective } from './no-special-chars/no-special-chars.directive';
import { OnlyNumberAndOperatorsDirective } from './only-number-and-operators/only-number-and-operators.directive';
import { RemoveAriaOwnsDirective } from './appRemoveAriaOwns/app-remove-aria-owns.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    InputAlphanumericDirective,
    OnlyNumberDirective,
    NoSpecialCharsDirective,
    OnlyNumberAndOperatorsDirective,
    RemoveAriaOwnsDirective,
  ],
  exports: [
    OnlyNumberDirective,
    NoSpecialCharsDirective,
    OnlyNumberAndOperatorsDirective,
    RemoveAriaOwnsDirective,
  ],
})
export class ShareDirectiviesModule {}
