import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  SimpleChanges,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DcmRollover } from 'src/app/interfaces/dcm-rollover.interface';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent implements OnChanges, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() dataSource!: MatTableDataSource<DcmRollover>;
  @Input() displayedColumns: { title: string; props: string }[] = [];
  @Input() onSearch!: boolean;
  @Output('resultPaginator') resultPaginator = new EventEmitter();

  noRecordFoundRolloverReport: boolean = false;

  columnProps: string[] = [];
  isCentered: boolean = true

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.columnProps = changes.displayedColumns.currentValue.map((column: any) => column.props);
    if (this.dataSource.filteredData[0]?.product === 'true') {
      this.noRecordFoundRolloverReport = this.dataSource.filteredData[0]?.product === 'true' ? true : false;
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  columnWithNumberValues(value: any): boolean {
    return typeof value === 'number';
  }

  columnWithNullValues(value: any): boolean {
    return value === null;
  }

  hasNumberValues(columnProp: string): boolean {
    return this.dataSource.data.some((row: any) =>
      this.columnWithNumberValues(row[columnProp])
    );
  }

  columnStatusExecuted(column: string, value: string) {
    return column === 'status' && value === 'EXECUTED';
  }
  columnStatusNotExecuted(column: string, value: string) {
    return column === 'status' && value === 'NOT EXECUTED';
  }

  handlePaginator(event: any) {
    this.resultPaginator.emit({ limit: event.pageSize, page: event.pageIndex });
  }
}
