import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ModalAlertService } from 'src/app/services/generic/modal.alert.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { ResultInterface } from '../results/results.interface';
import { ExcelService } from 'src/app/services/generic/excel.service';
import { dcmDataTables, dcmExportComponent } from 'src/app/interfaces/dcm-alert.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-table-filtering',
  templateUrl: './table-filtering.component.html',
  styleUrls: ['./table-filtering.component.scss'],
})
export class TableFilteringComponent implements OnChanges {
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation!:
  | TemplateRef<any>
  | any;

  @Output() customEvent = new EventEmitter<string>();
  @Output() readEvent = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() dataSourceFilterTable!: MatTableDataSource<any>;
  @ViewChild('secondModal') secondModal!: ElementRef;
  @Output('resultPaginator') resultPaginator = new EventEmitter();

  crudService: CrudService<any>;

  tableData!: any;

  displayedColumnsFilterTable: string[] = ['alert'];

  isFirstModalOpen$!: Observable<boolean>;
  isSecondModalOpen$!: Observable<boolean>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  modalNotificationViewDetails!: any;

  exportResult!: ResultInterface;

  paginationValues = {
    pageIndex: 0,
    pageSize: 0,
    totalRows: 0,
  };

  constructor(
    private modalService: ModalAlertService,
    private modalNotification: ModalActionsService,
    private toastr: ToastrService,
    private translatorService: TranslatorService,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService, 
    private http: HttpClient, 
  ) {
    this.crudService = new CrudService<any>(this.http, 'dcm');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isFirstModalOpen$ = this.modalService.firstModalOpen$;
    this.isSecondModalOpen$ = this.modalService.secondModalOpen$;  

    if (changes.dataSourceFilterTable.currentValue) {
      this.paginationValues.pageIndex = changes.dataSourceFilterTable.currentValue.page - 1;
      this.paginationValues.totalRows = changes.dataSourceFilterTable.currentValue.totalRows;

      this.tableData = changes.dataSourceFilterTable.currentValue.notifications
    }
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    const notificationTable = this.tableData
    const notificationData: any = this.dataSourceFilterTable

    let dataNotificationFilter: any = []

    notificationTable.forEach((item: any) => {
      if (item.title.trim().toLowerCase().includes(filterValue.trim().toLowerCase())) {
        dataNotificationFilter.push(item)
      }
    })

    if (filterValue.length == 0) {
      this.tableData = notificationData.notifications
    } else {
      this.tableData = dataNotificationFilter
    }
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    if (event.target instanceof HTMLElement) {  
      if (event.target.className.includes('cdk-overlay-backdrop')) {
        this.modalNotification.closeAll()

        if (this.modalNotificationViewDetails !== undefined) {
          this.elementRead(this.modalNotificationViewDetails)
        } 
      }
    }
  }

  openFirstModal() {
    this.modalService.openFirstModal();
  }

  closeFirstModal() {
    this.modalService.closeFirstModal();
  }

  openConfirmation() {
    this.modalService.closeFirstModal();
    this.modalService.openSecondModal();
  }

  closeSecondModal() {
    this.modalService.closeSecondModal();
  }
  closeSecondModalWithConfirmation() {
    this.modalService.closeSecondModal();
    this.customEvent.emit('dataReduction');
  }

  preventClose(event: Event): void {
    event.stopPropagation();
  }

  viewDetails(data: any) {
    this.modalNotification.createConfirm(this.dialogConfirmation);
    this.modalNotificationViewDetails = data;
    
    if (typeof this.modalNotificationViewDetails.subtitleComplete == 'object') {
      this.modalNotificationViewDetails.subtitleComplete.forEach((item: any) => {
        
        if (Array.isArray(item.content)) {
          return item.content = item.content.join(', ')
        }
      })

      let dataExport = [
        this.modalNotificationViewDetails.subtitleComplete[4],
        this.modalNotificationViewDetails.subtitleComplete[5],
        this.modalNotificationViewDetails.subtitleComplete[9]
      ]

      this.exportComponents(dataExport);
    }
  }

  elementRead(element: any) {
    this.transformToRead(element)
  }

  transformToRead(element: any) { 
    if (element.read === false) {
      this.spinner.show();  

      const dataNotificationId = {notificationId: element.id}
  
        this.crudService.putEntity(`dcm-notifications/read/${element.id}`, dataNotificationId)
          .subscribe((response: any) => {
            if (response.message) {
              this.readEvent.emit('readed');
              this.toastr.success('Successfully!', 'Notification marked as readed')
              this.spinner.hide()
            }
        })      
    }
  }

  exportComponents(dataMaterials: dcmExportComponent[]) {
    this.exportResult = {
      table: {
        columns: [
          { label: 'diverging so numbers in bigquery', prop: 'divergigSoBigQuery' },
          { label: 'diverging so numbers in local database', prop: 'divergigSoLocalDatabase' },
          { label: 'canceled so numbers in bigquery', prop: 'canceledSoBigQuery' },
        ],
        rows: this.dataTables(dataMaterials)
      },
    };  
  }

  dataTables(rows: dcmExportComponent[]) {
    const dataTables: any[] = [];
    let arrayNumbersDivergigBQ: dcmDataTables[] = [];
    let arrayNumbersDivergigDB: dcmDataTables[] = [];
    let arrayNumbersCanceledBQ: dcmDataTables[] = [];

    rows.forEach((item: any) => {
      if (item.title === 'Diverging SO numbers in BigQuery') {
        if (item.content.length === 0) {
          arrayNumbersDivergigBQ = [{ divergigSoBigQuery: '' }];
        } else {
          arrayNumbersDivergigBQ = item.content.split(', ').map((number: string) => ({ divergigSoBigQuery: parseInt(number, 10) }));
        }
      }
      
      if (item.title === 'Diverging SO numbers in Local Database') {
        if (item.content.length === 0) {
          arrayNumbersDivergigDB = [{ divergigSoLocalDatabase: '' }];
        } else {
          arrayNumbersDivergigDB = item.content.split(', ').map((number: string) => ({ divergigSoLocalDatabase: parseInt(number, 10) }));
        }
      }
      
      if (item.title === 'Canceled SO numbers in BigQuery') {
        if (item.content.length === 0) {
          arrayNumbersCanceledBQ = [{ canceledSoBigQuery: '' }];
        } else {
          arrayNumbersCanceledBQ = item.content.split(', ').map((number: string) => ({ canceledSoBigQuery: parseInt(number, 10) }));
        }
      }
    })

    const arrayUnique = this.joinArrays(arrayNumbersDivergigBQ, arrayNumbersDivergigDB, arrayNumbersCanceledBQ);

    dataTables.push(arrayUnique);

    return [...dataTables]
  }

  joinArrays(arrayDivergigBQ: any[], arrayDivergigDB: any[], arrayCanceledBQ: any[]): dcmDataTables[] {
    const resultado: dcmDataTables[] = [];
    const tamanhoMaximo = Math.max(arrayDivergigBQ.length, arrayDivergigDB.length, arrayCanceledBQ.length);

    for (let i = 0; i < tamanhoMaximo; i++) {
      const obj: any = {};

      // // Verifica e adiciona do array1
      if (i < arrayDivergigBQ.length) {
          const keyArrayDivergigBQ = Object.keys(arrayDivergigBQ[i])[0];
          obj[keyArrayDivergigBQ] = arrayDivergigBQ[i][keyArrayDivergigBQ];
      } else {
          obj.divergigSoBigQuery = '';
      }

      // Verifica e adiciona do array2
      if (i < arrayDivergigDB.length) {
          const keyArrayDivergigDB = Object.keys(arrayDivergigDB[i])[0];
          obj[keyArrayDivergigDB] = arrayDivergigDB[i][keyArrayDivergigDB];
      } else {
          obj.divergigSoLocalDatabase = '';
      }

      // Verifica e adiciona do array3
      if (i < arrayCanceledBQ.length) {
        const keyArrayCanceledBQ = Object.keys(arrayCanceledBQ[i])[0];
        obj[keyArrayCanceledBQ] = arrayCanceledBQ[i][keyArrayCanceledBQ];
    } else {
        obj.canceledSoBigQuery = '';
    }

      resultado.push(obj);
    }

    return resultado;
  }

  removeDuplicatedObj(array: dcmDataTables[]): dcmDataTables[] {
    const keySet = new Set<string>();
    const result: dcmDataTables[] = [];

    array.forEach(obj => {
        const key = `${obj.divergigSoBigQuery}-${obj.divergigSoLocalDatabase}-${obj.canceledSoBigQuery}`;
        if (!keySet.has(key)) {
            keySet.add(key);
            result.push(obj);
        }
    });

    return result;
  }


  isSubtitleArray(): boolean {
    return Array.isArray(this.modalNotificationViewDetails.subtitleComplete);
  }

  isSubtitleArrayLarge(value: any): boolean {
    return value.length > 65;
  }

  handlePaginator(event: any) {
    this.resultPaginator.emit({ limit: event.pageSize, page: event.pageIndex + 1 });
  }

  prepareExportTable(data: any) {
    const rows = data.table.rows;
    const columns = data.table.columns.map((item: any) => ({
      title: item.label,
      props: item.prop,
    }));

    const tableRows: { [key: string]: any }[] = [];

    rows[0].forEach((rows: string) => {
      const tableRow: { [key: string]: any } = {};

      columns.forEach((column: any) => {
        tableRow[column.props] = rows[column.props]        
        tableRows.push(tableRow);
      });
    });

    return { columns: columns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.exportResult);
    const arrayNoRep = this.removeDuplicatedObj(fullJsonExport.rows);
    fullJsonExport.rows = arrayNoRep;
    this.excelService.exportJsonAsExcelFile(fullJsonExport, 'order_book_fail_reduction');
  }
}

