import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import {
  customerShortEntities,
  familyEntities,
  orderAdminEntities,
  salesManagerEntities,
  currentData,
} from 'src/app/interfaces/overbooked.interface';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { Material } from 'src/app/interfaces/material.interface';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { CrudService } from 'src/app/services/generic/crud.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { tableCustomerViewMockup } from './customer-view-mockup';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/interfaces/user.interface';
import { OrderAdmin } from 'src/app/interfaces/orderAdmin.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { DownloadFileService } from 'src/app/services/generic/download-file.service';
import * as FileSaver from 'file-saver';
import { SalesManager } from 'src/app/interfaces/salesManager.interface';
import { catchError, finalize } from 'rxjs/operators';
import { columnsStyle } from 'src/app/components/table/table.interface';
import { ModalComponent } from 'src/app/components/modal-table/modal-table.component';
import { MatDialog } from '@angular/material/dialog';
import {
  DynamicData,
  NetOpenPoData,
} from 'src/app/interfaces/netOpenPo.interface';

const INDEX_INTRO_TABLE = 0;
const INDEX_MIDDLE_TABLE = 1;
const INDEX_END_TABLE = 2;

interface Filter {
  pageName: string;
  fiscalYear: number;
  quarter: string;
}

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss'],
})
export class CustomerViewComponent implements OnInit {
  selectedTabIndex = 0;
  dataExport: any;
  formSearchAllocationView!: { [key: string]: any };
  searchInputsAllocationView: InputBase<string>[];
  tabs: { [key: string]: number } = {
    'ALLOCATION VIEW': 0,
  };
  enableApplyFilter = false;
  customerShortEntities: customerShortEntities[];
  salesManagerEntities: salesManagerEntities[];
  familyEntities: familyEntities[];
  orderAdminsEntities: orderAdminEntities[];
  originFilter: any[] = [];
  countriesEntities: { [key: string]: any } = {};
  geosEntities: { [key: string]: any } = {};
  setupEntity: any[] = [];
  entities: Array<getEntities>;
  crudService: CrudService<Material>;
  currentUser!: any;
  initialValueSalesManager: { [key: string]: any };
  simpleViewOrNot: boolean = true;
  tableMockup = tableCustomerViewMockup;
  formValue!: { [key: string]: any };
  filter: Filter = {
    pageName: PAGE_NAME_RESOURCE.customerView,
    fiscalYear: 0,
    quarter: '',
  };
  currentQuarter!: string;
  currentYear!: number;
  tableResultCustomerView: any;
  showNoResults: boolean = false;
  noFilterSelected: boolean = true;
  disabledButtonExport: any;
  eventTable!: string;
  loadedDataTableData: any;
  loadedDataTableVisible: boolean = false;
  loadedDataTableShowMenuColumns: boolean = false;
  loadedDataTableTab: string = 'To Book';
  loadedDataTableThead: any = [
    {
      label: 'To Book',
      thead: this.getTheadFromToBook([]),
    },
    {
      label: 'Booked',
      thead: this.getTheadFromDataBooked(this.filter),
    },
    {
      label: 'Sell In',
      thead: this.getTheadFromDataSellIn(this.filter),
    },
  ];
  dataSourceTable: any[] = [];
  currentData!: currentData;
  allocationLabels: { [key: string]: any } = {};
  tooltipMessage: String = 'Export';
  downloadFileService: DownloadFileService<any> | undefined;
  translateYAbsoluteItem: number = 0;
  offsetTopSubTotal: number = 0;
  origins: any[] = [];
  totalToBook: any = 0;
  showTable = false;

  quarterEntities = [
    { key: '1', value: 'FQ1' },
    { key: '2', value: 'FQ2' },
    { key: '3', value: 'FQ3' },
    { key: '4', value: 'FQ4' },
  ];

  constructor(
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    [this.currentUser] = this.authService.getUserPermissions();
    this.crudService = new CrudService<any>(this.http, 'customer-view');
    this.downloadFileService = new DownloadFileService<any>(this.http);
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;
    this.filter = {
      ...this.filter,
      fiscalYear: this.currentYear,
      quarter: `F${this.currentQuarter}`,
    };
    this.customerShortEntities = [];
    this.salesManagerEntities = [];
    this.orderAdminsEntities = [];
    this.familyEntities = [];
    this.initialValueSalesManager = {};
    this.searchInputsAllocationView = [
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'full-width-panel-customer-short',
        limitedValue: true,
        module: 'customer-view',
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'materialId',
        hover: 'Family',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        // classCss: 'full-width-panel-customer-short',
        disabled: true,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        // classCss: 'full-width-panel-customer-short',
        disabled: true,
        largeInput: true,
      }),
      new SelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'filters-position',
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Fiscal Quarter',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];
    this.entities = [
      {
        entity: 'customer-short',
        query: {
          status: true,
          sort: 'customerShort',
          pageName: PAGE_NAME_RESOURCE.customerView,
        },
      },
      {
        entity: 'material-family',
        query: { pageName: PAGE_NAME_RESOURCE.customerView },
      },
      {
        entity: 'user',
        query: {
          status: true,
          sales_manager: true,
          sort: 'last_name',
          pageName: PAGE_NAME_RESOURCE.customerView,
        },
      },
      {
        entity: 'user',
        query: {
          status: true,
          order_admin: true,
          sort: 'last_name',
          pageName: PAGE_NAME_RESOURCE.customerView,
        },
      },
      {
        entity: 'geo',
        query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.customerView },
      },
      {
        entity: 'country',
        query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.customerView },
      },
      {
        entity: 'setupManagement/currentSetup',
        query: { pageName: PAGE_NAME_RESOURCE.customerView },
      },
    ];
  }

  ngOnInit(): void {
    [this.currentUser] = this.authService.getUserPermissions();

    const promises = [this.getDataFilter()];

    this.hideSpinnerAfterAll(promises);

    this.disabledButtonExport =
      this.getExportStatus() && this.getExportStatus()
        ? this.getExportStatus()
        : false;
  }

  private resetValues() {
    this.loadedDataTableData = [];
    this.tableResultCustomerView = [];
    this.dataSourceTable = [];
    this.noFilterSelected = true;
    this.showNoResults = false;
    this.showTable = false;
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  async getEndTable(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.getTableEndPage(this.filter, this.loadedDataTableTab);
    });
  }

  async getDataFilter(): Promise<void> {
    this.spinner.show();

    return new Promise((resolve, reject) => {
      this.crudService.getDataFilters(this.entities).subscribe(
        (response: any) => {
          this.customerShortEntities = response[0];
          this.familyEntities = response[1];
          this.salesManagerEntities = response[2];
          this.orderAdminsEntities = response[3];
          this.geosEntities = response[4];
          this.countriesEntities = response[5];
          this.setupEntity = response[6];

          this.dataFilterCustomerShort(this.customerShortEntities);
          this.dataFilterFamily(this.familyEntities);
          this.dataFilterSalesManager(this.salesManagerEntities);
          this.dataFilterOrderAdmin(this.orderAdminsEntities);
          this.dataFilterOrigin();
          this.dataFilterFiscalYear();
          this.dataFilterFiscalQuarter();
          this.dataFilterGeo(this.geosEntities);
          this.dataFilterCountry(this.countriesEntities);
          this.allocationLabels = this.removePastWeek(this.setupEntity);

          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        }
      );
    });
  }

  dataFilterCustomerShort(response: any) {
    const customerShortFilter: any[] = [];

    response.forEach((item: any) => {
      customerShortFilter.push({
        id: item.id,
        value: item.customerShort,
      });
    });

    const selectCustomerShort = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: customerShortFilter,
      classCss: 'full-width-panel-customer-short',
      limitedValue: true,
      module: 'customer-view',
      largeInput: true,
    });

    this.searchInputsAllocationView[0] = selectCustomerShort;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterSalesManager(response: salesManagerEntities[]) {
    const salesManagerFilter: any[] = [];
    const nameSalesManager: string = `${this.currentUser.lastName}, ${this.currentUser.name}`;

    response.forEach((item: SalesManager) => {
      const viewName: string = `${item.last_name}, ${item.name}`;

      if (
        this.currentUser.profileType === 'salesManager' &&
        nameSalesManager === viewName
      ) {
        this.initialValueSalesManager.value = viewName;
        this.initialValueSalesManager.id = item.id;
      }
      salesManagerFilter.push({ value: viewName, id: item.id });
    });

    if (this.initialValueSalesManager.value) {
      this.spinner.show();
      const customerOptions: any = [];

      this.customerShortEntities.forEach((item: any) => {
        if (item.orderAdmin !== null) {
          let salesManagerSelected = `${item.orderAdmin.last_name}, ${item.orderAdmin.name}`;
          if (salesManagerSelected === this.initialValueSalesManager.viewName) {
            customerOptions.push({
              id: item.id,
              value: item.customerShort,
            });
          }
        }
      });

      const selectSalesManager: any = new SelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'full-width-panel-customer-short',
        options: salesManagerFilter,
        value: this.initialValueSalesManager.value,
        disabled: true,
      });

      this.searchInputsAllocationView[4] = selectSalesManager;
      this.searchInputsAllocationView = [...this.searchInputsAllocationView];
    } else {
      const selectSalesManager: any = new SelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'filters-position',
        options: salesManagerFilter,
        disabled: true,
      });

      this.searchInputsAllocationView[4] = selectSalesManager;
      this.searchInputsAllocationView = [...this.searchInputsAllocationView];
    }
  }

  dataFilterFamily(response: any) {
    const familyFilter: any[] = [];

    response.forEach((item: any, index = 0) => {
      familyFilter.push({
        id: index,
        materialId: item.ids,
        value: item.family,
      });
    });

    const selectMultiFamily = new MultiSelectSearchInput({
      key: 'materialId',
      hover: 'Family',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: familyFilter,
      classCss: 'full-width-panel-customer-short',
      disabled: true,
      largeInput: true,
    });

    this.searchInputsAllocationView[1] = selectMultiFamily;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterOrderAdmin(response: any) {
    const orderAdminFilter: any[] = [];
    const nameOrderAdmin: string = `${this.currentUser.lastName}, ${this.currentUser.name}`;

    const initialValue: { [key: string]: any } = {};
    response.forEach((order: OrderAdmin) => {
      const viewName: string = `${order.last_name}, ${order.name}`;

      if (
        this.currentUser.profileType === 'orderAdmin' &&
        nameOrderAdmin === viewName
      ) {
        initialValue.value = viewName;
        initialValue.id = order.id;
      }

      orderAdminFilter.push({
        value: viewName,
        id: order.id,
      });
    });

    const selectOrders = new SelectSearchInput({
      key: 'orderAdminId',
      hover: 'Order Admin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: orderAdminFilter,
      value: initialValue.value,
      disabled: true,
    });
    this.searchInputsAllocationView[3] = selectOrders;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterOrigin() {
    this.originFilter.push({ key: 1, value: 'JAG' }, { key: 2, value: 'MAN' });

    this.originFilter.forEach((item: any) => {
      this.origins.push({ value: item.value });
    });

    const selectFilter = new MultiSelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'full-width-panel-customer-short',
      options: this.originFilter,
      disabled: true,
      notClearable: true,
      largeInput: true,
    });

    this.searchInputsAllocationView[2] = selectFilter;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterFiscalYear() {
    const fiscalYearFilter: any[] = [];

    for (
      let year = this.currentYear - 6, i = 0;
      year <= this.currentYear + 6;
      year++, i++
    ) {
      fiscalYearFilter.push({
        key: i,
        value: `${year}/${year + 1}`,
      });
    }

    const selectFiscalYear = new SelectSearchInput({
      key: 'fiscalYear',
      hover: 'Fiscal Year',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: fiscalYearFilter,
      value: `${this.currentYear}/${this.currentYear + 1}`,
      disabled: true,
      notClearable: true,
    });

    this.searchInputsAllocationView[5] = selectFiscalYear;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterFiscalQuarter() {
    const selectQuarter = new SelectSearchInput({
      key: 'quarter',
      hover: 'Fiscal Quarter',
      options: this.quarterEntities,
      value: `F${this.currentQuarter}`,
      classCss: 'select-ka-filter-ka-info',
      disabled: true,
      notClearable: true,
    });

    this.searchInputsAllocationView[6] = selectQuarter;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterGeo(response: any) {
    const geoFilter: any[] = [];
    const initialValueGeoFilter: { [key: string]: any } = {};

    response.forEach((item: Geo) => {
      geoFilter.push({ value: item.geo, id: item.id });
      if (item.id === this.currentUser.geoId) {
        initialValueGeoFilter.value = item.geo;
        initialValueGeoFilter.id = item.id;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: geoFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.searchInputsAllocationView[7] = selectGeo;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  dataFilterCountry(response: any) {
    const countryFilter: any[] = [];
    const initialValueCountryFilter: { [key: string]: any } = {};

    response.forEach((item: Country) => {
      countryFilter.push({ value: item.country, id: item.id });
      if (item.id === this.currentUser.countryId) {
        initialValueCountryFilter.value = item.country;
        initialValueCountryFilter.id = item.id;
      }
    });

    const selectMultiCountry = new SelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: countryFilter,
      value: initialValueCountryFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.searchInputsAllocationView[8] = selectMultiCountry;
    this.searchInputsAllocationView = [...this.searchInputsAllocationView];
  }

  async getDataCustomerView(params?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.resetValues();
      this.eventTable = 'hide-columns-rows';
      this.crudService.getEntity('allocation-customer-view', params).subscribe(
        (response: any) => {
          if (response.introTable.content.length > 0) {
            this.tableResultCustomerView = response;
            this.tableResultCustomerView = JSON.parse(
              JSON.stringify(this.tableResultCustomerView)
            );
            this.getUpdateTable();
            this.disabledButtonExport = false;
            this.noFilterSelected = false;
          } else {
            this.showNoResults = true;
            this.disabledButtonExport = true;
            this.noFilterSelected = false;
          }
          resolve();
        },
        error => {
          reject(error.statusText);
          this.showNoResults = true;
          this.disabledButtonExport = true;
        }
      );
    });
  }

  updateComponents(data: any) {
    this.tableResultCustomerView = {
      table: {
        columns: data.table.columns,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(data.table.rows))
        ),
      },
    };
  }

  getUpdateTable(): void {
    this.dataSourceTable = [];

    Object.keys(this.tableResultCustomerView).forEach((keyTable: string) => {
      const editableColumns: any[] = [];
      const columnsNamesHasSubtotal: any[] = [];
      this.tableResultCustomerView[keyTable].headers.forEach((header: any) => {
        if (header.enableContent && header.props !== header.otherContent) {
          editableColumns.push(header.props);
        }
        if (header.hasSubtotal || header.title?.includes('Total'))
          columnsNamesHasSubtotal.push(header.props);
        if (header.props === 'customerShort') {
          header.typeShowEvent = 'show-columns-rows';
          header.typeHideEvent = 'hide-columns-rows';
          header.contentPpmWidth = true;
        } else {
          header.typeShowEvent = 'show-columns';
          header.typeHideEvent = 'hide-columns';
        }
        return header;
      });

      this.tableResultCustomerView[keyTable].content.map(
        (content: any, indexContent: number) => {
          content.summarized?.forEach((summary: any) => {
            summary.subtotal = {};

            columnsNamesHasSubtotal.forEach((columnName: string) => {
              summary.subtotal[columnName] = 0;
              summary.rows.forEach((row: any) => {
                if (!row.enableATS && !row.enableMLS) {
                  summary.subtotal[columnName] += row.value[columnName].value
                    ? Number(row.value[columnName].value)
                    : 0;
                  summary.textAlignCenter = true;
                }
                row.contentAlignCenter = keyTable !== 'introTable';
              });
            });
          });

          content.expanded?.forEach((expand: any) => {
            expand.subtotal = {};
            columnsNamesHasSubtotal.forEach((columnName: string) => {
              expand.subtotal[columnName] = 0;
              expand.rows.forEach((row: any) => {
                if (!row.enableATS && !row.enableMLS) {
                  expand.subtotal[columnName] += Number(
                    row.value[columnName].value
                  );
                  expand.textAlignCenter = true;
                }
                row.contentAlignCenter = keyTable !== 'introTable';
              });
            });
          });

          return content;
        }
      );
      if (keyTable !== 'lastWeekModal')
        this.dataSourceTable.push(this.tableResultCustomerView[keyTable]);
    });
    this.dataSourceTable[INDEX_MIDDLE_TABLE].hasScroll = true;
    this.dataSourceTable[INDEX_MIDDLE_TABLE].style =
      'overflow-x: auto; overflow-y: hidden; min-width: 290px;';
    this.dataSourceTable[INDEX_INTRO_TABLE].showSubtotalTitle = true;
    this.dataSourceTable[INDEX_MIDDLE_TABLE].showSubtotalTitle = false;
    this.dataSourceTable[INDEX_END_TABLE].showSubtotalTitle = false;

    this.configMiddleTable(this.dataSourceTable[INDEX_MIDDLE_TABLE]);
  }

  configMiddleTable(middleTable: any) {
    const editableColumns: any[] = [];
    const columnsNamesHasSubtotal: any[] = [];
    middleTable.headers.forEach((header: any) => {
      if (header.enableContent && header.props !== header.otherContent) {
        editableColumns.push(header.props);
      }
      if (header.hasSubtotal) {
        columnsNamesHasSubtotal.push(header.props);
      }
      if (header.props === 'customerShort') {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });

    middleTable.content.map((content: any) => {
      const columnsWeeks = Object.keys(
        content.expanded[0].rows[0].value
      ).filter((column: string) => column.includes('wk'));
      const columnsMonth = Object.keys(
        content.expanded[0].rows[0].value
      ).filter((column: string) => !column.includes('wk'));

      content.expanded.map((expanded: any) => {
        columnsMonth.forEach((columnMonth: string) => {
          expanded.rows.map((row: any) => {
            if (!row.enableMLS) {
              row.value[columnMonth].value = 0;
            }
            return row;
          });
        });

        columnsWeeks.forEach((columnWeek: string) => {
          const columnTotalMonth = columnWeek.split('wk')[0];
          expanded.subtotal[columnWeek] = 0;
          expanded.rows.map((row: any) => {
            if (!row.enableATS && !row.enableMLS) {
              expanded.subtotal[columnWeek] += Number(
                row.value[columnWeek].value
              );
              row.value[columnTotalMonth].value += Number(
                row.value[columnWeek].value
              );
            }
            return row;
          });
        });

        return expanded;
      });

      return content;
    });

    middleTable.hasScroll = true;
    middleTable.style =
      'overflow-x: auto; overflow-y: hidden; min-width: 290px;';
  }

  removePastWeek(setup: any) {
    let toAllocate: string = '';
    let toBook: string = '';
    const allocate: any = [];
    const book: any = [];
    const currentWeek = setup.currentWeek.replace('week', '');
    setup.toAllocate.split(',').forEach((item: any) => {
      allocate.push(item.trim());
    });
    allocate.sort().forEach((item: any) => {
      let allocate = item.replace('Wk', '').trim();
      if (allocate >= currentWeek) {
        toAllocate += `${item},`;
      }
    });
    setup.toBook.split(',').forEach((item: any) => {
      book.push(item.trim());
    });
    book.sort().forEach((item: any) => {
      let book = item.replace('Wk', '').trim();
      if (book >= currentWeek) {
        toBook += `${item},`;
      }
    });

    setup.toAllocate = toAllocate.slice(0, -1);
    setup.toBook = toBook.slice(0, -1);
    return setup;
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];

    array.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  private dataFormSearchFamily(formSearchName: any) {
    const currentIdsFamily: any = [];

    this.familyEntities?.forEach((item: any) => {
      if (formSearchName.materialId) {
        formSearchName.materialId?.forEach((data: any) => {
          if (item.family === data.value) {
            item.ids.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
      }
    });
    formSearchName.materialId = currentIdsFamily.map((item: any) => item);
    formSearchName.materialId = formSearchName.materialId
      ? formSearchName.materialId
      : '';
  }

  private dataformSearchCustomerShort(formSearchName: any) {
    if (formSearchName.customerShortId) {
      const currentCustomerShortId = formSearchName.customerShortId.map(
        (item: any) => item.id
      );
      formSearchName.customerShortId = currentCustomerShortId;
    } else {
      formSearchName.customerShortId = '';
    }
  }

  private dataformSearchSalesManager(formSearchName: any) {
    if (formSearchName.salesManagerId) {
      this.salesManagerEntities.forEach((item: SalesManager) => {
        const viewName: string = `${item.last_name}, ${item.name}`;
        if (viewName === formSearchName.salesManagerId) {
          formSearchName.salesManagerId = item.id;
        }
      });
    } else {
      formSearchName.salesManagerId = '';
    }
  }

  private dataFormValuesOrigin(formSearchName: any) {
    if (formSearchName.origin) {
      const currentOrigin = formSearchName.origin.map(
        (item: any) => item.value
      );
      formSearchName.origin = currentOrigin;
    } else {
      formSearchName.origin = '';
    }
  }

  private dataFormValuesGeo(formSearchName: any) {
    if (formSearchName.geoId) {
      const currentGeo = this.geosEntities.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchName.geoId.toLocaleUpperCase())
      );
      formSearchName.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchName.geoId = '';
    }
  }

  private dataFormValuesCountry(formSearchName: any) {
    if (formSearchName.countryId) {
      const currentCountry = this.countriesEntities.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(formSearchName.countryId.toLocaleUpperCase())
      );
      formSearchName.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      formSearchName.countryId = '';
    }
  }

  private dataFormValuesFiscalYear(formSearchName: any) {
    formSearchName.fiscalYear = formSearchName.fiscalYear?.split('/')[0] || '';
  }

  private dataFormValuesOrderAdmin(formSearchName: any) {
    if (formSearchName.orderAdminId) {
      this.orderAdminsEntities.forEach((order: any) => {
        const viewName: string = `${order.last_name}, ${order.name}`;
        if (viewName === formSearchName.orderAdminId) {
          formSearchName.orderAdminId = order.id;
        }
      });
    } else {
      formSearchName.orderAdminId = '';
    }
  }

  onSearchEvent(form: any, tab?: string): void {
    if (tab === 'allocationView') {
      this.formSearchAllocationView = { ...form };

      this.dataFormSearchFamily(this.formSearchAllocationView);
      this.dataformSearchCustomerShort(this.formSearchAllocationView);
      this.dataFormValuesGeo(this.formSearchAllocationView);
      this.dataFormValuesCountry(this.formSearchAllocationView);
      this.dataFormValuesOrigin(this.formSearchAllocationView);

      const customerShortIdExists =
        this.formSearchAllocationView.customerShortId;

      this.enableApplyFilter = customerShortIdExists;

      for (let i = 1; i <= 6; i++) {
        this.searchInputsAllocationView[i].disabled = !customerShortIdExists;
        this.searchInputsAllocationView[i].notClearable =
          !customerShortIdExists;
      }

      this.searchInputsAllocationView = [...this.searchInputsAllocationView];
    }
  }

  setExportStatus(status: string) {
    localStorage.setItem('disabled_button_export_customer_view', status);
  }

  destroyExportStatus() {
    localStorage.removeItem('disabled_button_export_customer_view');
    this.disabledButtonExport = false;
  }

  getExportStatus() {
    const status = localStorage.getItem('disabled_button_export_customer_view');
    return !!status;
  }

  public base64ToBlob(b64Data: string, sliceSize = 512) {
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  exportTable() {
    this.setExportStatus('true');
    this.disabledButtonExport = true;
    this.tooltipMessage = 'Waiting...';
    const subtitleMessage = 'Generating Excel file for background download!';
    const titleMessage = 'Download Excel';
    this.toastr.success(`${subtitleMessage}`, `${titleMessage}`, {
      timeOut: 10000,
    });
    this.downloadFileService
      ?.downloadFile(
        'allocation-customer/export-report-ppm',
        this.filter,
        'text'
      )
      .subscribe(
        (response: any) => {
          const file = this.base64ToBlob(response);

          // IE
          const nav = window.navigator as any;
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(file);
            return;
          }
          FileSaver.saveAs(file, `CUSTOMER_VIEW_REPORT.xlsx`);
          const subtitleMessage = 'Successfully generated file!';
          const titleMessage = 'Download Excel';
          this.toastr.success(`${subtitleMessage}`, `${titleMessage}`, {
            timeOut: 10000,
          });
          this.destroyExportStatus();
          this.tooltipMessage = 'Export';
        },
        (err: any) => {
          const subtitleMessage =
            'An error occurred while generating the Excel file!';
          const titleMessage = 'Download Excel';
          this.toastr.error(`${subtitleMessage}`, `${titleMessage}`, {
            timeOut: 10000,
          });
          this.destroyExportStatus();
          this.tooltipMessage = 'Export';
        }
      );
  }

  toggleTable(event: string) {
    this.eventTable = event;
  }

  addTypeShowEvent(response: any) {
    this.eventTable = 'hide-columns';

    response.forEach((header: any) => {
      if (header.expansive === true) {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });
  }

  checkSimpleOrNot(event: any) {
    if (event.srcElement.checked) {
      this.simpleViewOrNot = event.srcElement.checked;
      if (this.enableApplyFilter) {
        this.applyFilter();
      }
    } else {
      this.simpleViewOrNot = event.srcElement.checked;
      if (this.enableApplyFilter) {
        this.applyFilter();
      }
    }
  }

  controlExportButton() {}

  tabClick(event: any) {
    this.changeTab(event.target.innerText);
  }

  deleteProperty(item: any) {
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    delete item.family;

    return item;
  }

  applyFilter(tab?: string) {
    if (tab === 'allocationView') {
      this.applyFilterAllocationView();
    }
  }

  applyFilterAllocationView() {
    this.spinner.show();

    this.dataformSearchSalesManager(this.formSearchAllocationView);
    this.dataFormValuesOrderAdmin(this.formSearchAllocationView);
    this.dataFormValuesFiscalYear(this.formSearchAllocationView);

    const formSearchValues = { ...this.formSearchAllocationView };

    formSearchValues.simpleView = this.simpleViewOrNot;
    if (formSearchValues.salesManagerId === undefined)
      formSearchValues.salesManagerId = '';
    if (formSearchValues.orderAdminId === undefined)
      formSearchValues.orderAdminId = '';
    let formAllocationView = this.deleteProperty(formSearchValues);

    this.filter = { ...this.filter, ...formAllocationView };

    const dataTable = this.getDataCustomerView(this.filter);

    this.hideSpinnerAfterAll([dataTable]);
    this.getEndTable([dataTable]);
  }

  private getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  changeTab(label: any) {
    if (this.tabs[label] || this.tabs[label] === 0) {
      this.selectedTabIndex = this.tabs[label];
      this.controlExportButton();
    }
  }

  backButton() {
    this.router.navigate(['home/customer-allocation-retail']);
  }

  changeTabDataTableTab(tab: any) {
    this.getTableEndPage(this.filter, tab);
    this.loadedDataTableTab = tab;
  }

  getTableEndPage(filter: any, tab: any) {
    this.loadedDataTableVisible = false;
    this.loadedDataTableData = [];
    this.totalToBook = 0;
    const newHead: any = [];

    this.loadedDataTableThead.map((row: any) => {
      newHead.push(row);
    });

    if (tab === 'To Book') {
      this.spinner.show();
      this.crudService
        .getEntity('allocation-customer/report-ppm/tobook', {
          ...this.filter,
        })
        .pipe(
          catchError((error: any) => {
            this.toastr.error('', 'Error!');
            this.loadedDataTableVisible = false;
            return [];
          }),
          finalize(() => {
            this.showTable = true;
            this.spinner.hide();
          })
        )
        .subscribe((response: any) => {
          if (response.rows) {
            newHead[0].thead = this.getTheadFromToBook(response.headers);
            this.loadedDataTableData = response.rows;
            this.loadedDataTableTab = tab;
            // this.loadedDataTableVisible = true;
            this.loadedDataTableThead = newHead;
            this.loadedDataTableShowMenuColumns = false;
            this.totalToBook = response.totalToBook;
            this.getValuesToBook(response.customerTaxToBooks);
          } else {
            this.loadedDataTableVisible = false;
          }
        });
    } else if (tab === 'Booked') {
      this.spinner.show();
      newHead[1].thead = this.getTheadFromDataBooked(filter);
      this.crudService
        .getEntity('allocation-customer/report-ppm', {
          ...this.filter,
          type: 'booked',
        })
        .pipe(
          catchError((error: any) => {
            this.toastr.error('', 'Error!');
            this.loadedDataTableVisible = false;
            return [];
          }),
          finalize(() => {
            this.showTable = true;
            this.spinner.hide();
          })
        )
        .subscribe((response: any) => {
          if (response.length > 0) {
            this.loadedDataTableThead = newHead;
            this.loadedDataTableData = response;
            this.loadedDataTableTab = tab;
            // this.loadedDataTableVisible = true;
          } else {
            this.loadedDataTableVisible = false;
          }
        });
    } else if (tab === 'Sell In') {
      this.spinner.show();
      newHead[2].thead = this.getTheadFromDataSellIn(filter);
      this.crudService
        .getEntity('allocation-customer/report-ppm', {
          ...this.filter,
          type: 'sell_in',
        })
        .pipe(
          catchError((error: any) => {
            this.toastr.error('', 'Error!');
            this.loadedDataTableVisible = false;
            return [];
          }),
          finalize(() => {
            this.showTable = true;
            this.spinner.hide();
          })
        )
        .subscribe((response: any) => {
          if (response.length > 0) {
            this.loadedDataTableThead = newHead;
            this.loadedDataTableData = response;
            this.loadedDataTableTab = tab;
            // this.loadedDataTableVisible = true;
            this.loadedDataTableShowMenuColumns = true;
          } else {
            this.loadedDataTableVisible = false;
          }
        });
    } else {
      this.loadedDataTableShowMenuColumns = true;
    }
  }

  getValuesToBook(values: any) {
    const contentRows = this.dataSourceTable[INDEX_END_TABLE].content.length;

    for (let i = 0; i < contentRows; i++) {
      const content = this.dataSourceTable[INDEX_END_TABLE].content[i];
      for (const key of Object.keys(values)) {
        for (const expanded of content.expanded) {
          for (const product of expanded.rows) {
            const onlyOrigin = values[key].key.match(/[a-zA-Z]+/g)[0];
            if (
              onlyOrigin === product.origin &&
              values[key].appTaxes === product.appTaxes &&
              values[key].ppm === product.ppm &&
              values[key].customerShort === product.customerShort
            ) {
              product.value.toBook.value = parseFloat(values[key].value);
            }
          }
        }
      }
    }

    for (let i = 0; i < contentRows; i++) {
      for (const key of Object.keys(values)) {
        const summarized =
          this.dataSourceTable[INDEX_END_TABLE].content[i].summarized;
        for (const item of summarized) {
          for (const product of item.rows) {
            if (
              values[key].ppm === product.ppm &&
              values[key].customerShort === product.customerShort
            ) {
              product.value.toBook.value += parseFloat(values[key].value);
            }
          }
        }
      }
    }

    this.getUpdateTable();
  }

  getTheadFromToBook(weeks: any) {
    const dataSplit: any = [];

    const data = [
      {
        label: 'CUSTOMER SHORT',
        key: 'ppm',
        description: ' ',
      },
      {
        label: 'Origin',
        key: 'origin',
        description: ' ',
      },
      {
        label: 'PPM',
        key: 'ppm',
        description: ' ',
      },
      {
        label: 'Taxes',
        key: 'tax',
        description: '',
      },
    ];

    dataSplit.push(data);

    const weeksAll: any = [];
    if (weeks !== undefined) {
      weeks.map((row: any) => {
        weeksAll.push(row);
      });
    }

    dataSplit.push(weeksAll);

    return dataSplit;
  }

  getTheadFromDataBooked(filters: any) {
    const dataSplit: any = [];
    const data = [
      {
        label: 'Customer Short',
        key: 'ppm',
      },
      {
        label: 'Origin',
        key: 'origin',
      },
      {
        label: 'PPM',
        key: 'ppm',
      },
      {
        label: 'Channel',
        key: 'channel',
      },
    ];
    dataSplit.push(data);

    const weeks: any = [];
    this.getMonthsWeeksForQuarter(filters.quarter).map((row: any) => {
      weeks.push(row);
    });
    weeks.push({
      label: 'Total Quarter',
      key: 'totalQuarter',
    });
    dataSplit.push(weeks);

    return dataSplit;
  }

  getTheadFromDataSellIn(filters: any) {
    const dataSplit: any = [];
    const data = [
      {
        label: 'Customer Short',
        key: 'ppm',
      },
      {
        label: 'Origin',
        key: 'origin',
      },
      {
        label: 'PPM',
        key: 'ppm',
      },
      {
        label: 'Channel',
        key: 'channel',
      },
    ];
    dataSplit.push(data);

    const weeks: any = [];
    this.getMonthsWeeksForQuarter(filters.quarter).map((row: any) => {
      weeks.push(row);
    });
    weeks.push({
      label: 'Total Quarter',
      key: 'totalQuarter',
    });
    dataSplit.push(weeks);

    return dataSplit;
  }

  getMonthsWeeksForQuarter(quarter: any) {
    const data: any = [];
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];

    if (quarter === 'FQ1') {
      data.push({
        label: months[3],
        key: months[3],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[3] + '_WK0' + i,
          parent: months[3],
        });
      }
      data.push({
        label: months[4],
        key: months[4],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[4] + '_WK0' + i,
          parent: months[4],
        });
      }
      data.push({
        label: months[5],
        key: months[5],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[5] + '_WK0' + i,
          parent: months[5],
        });
      }
    } else if (quarter === 'FQ2') {
      data.push({
        label: months[6],
        key: months[6],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[6] + '_WK0' + i,
          parent: months[6],
        });
      }
      data.push({
        label: months[7],
        key: months[7],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[7] + '_WK0' + i,
          parent: months[7],
        });
      }
      data.push({
        label: months[8],
        key: months[8],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[8] + '_WK0' + i,
          parent: months[8],
        });
      }
    } else if (quarter === 'FQ3') {
      data.push({
        label: months[9],
        key: months[9],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[9] + '_WK0' + i,
          parent: months[9],
        });
      }
      data.push({
        label: months[10],
        key: months[10],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[10] + '_WK0' + i,
          parent: months[10],
        });
      }
      data.push({
        label: months[11],
        key: months[11],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[11] + '_WK0' + i,
          parent: months[11],
        });
      }
    } else if (quarter === 'FQ4') {
      data.push({
        label: months[0],
        key: months[0],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[0] + '_WK0' + i,
        });
      }
      data.push({
        label: months[1],
        key: months[1],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[1] + '_WK0' + i,
        });
      }
      data.push({
        label: months[2],
        key: months[2],
      });
      for (var i = 1; i < 6; i++) {
        data.push({
          label: 'WK0' + i,
          key: months[2] + '_WK0' + i,
        });
      }
    }

    return data;
  }

  openModalNetOpenPo(rowValue: any): void {
    const dynamicData = this.dynamicDataSource(rowValue);
    const uniqueMonths = Array.from(
      new Set(dynamicData.map((item: any) => item.month))
    );

    // Calculando o subtotal
    const calcValue = dynamicData
      .filter((item: any) => item.month === uniqueMonths[0])
      .reduce((acc: any, item: any) => acc + item.QTY, 0);

    const modalData = {
      title: 'NET OPEN PO',
      subtitle: rowValue.rowValue.ppm,
      titleExport: 'netOpenPo',
      nameModal: 'netOpenPo',
      dataSource: dynamicData,
      dynamicPropertyWithSameValue: uniqueMonths,
      dynamicCalcSubtotal: calcValue,
      displayedColumns: this.dynamicColumns(),
      hasRecords: dynamicData.length > 0,
      messageNoRecords: 'THERE ARE NO ASSOCIATED SO LINE NUMBERS',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog-container',
    });
  }

  dynamicDataSource(event?: any): DynamicData[] {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dynamicData: DynamicData[] = [];

    months.forEach((month: any) => {
      if (event.rowValue.value.netOpenPo.modal.hasOwnProperty(month)) {
        const dataMonth = event.rowValue.value.netOpenPo.modal[month];

        if (dataMonth.length > 0) {
          dataMonth.forEach((data: NetOpenPoData) => {
            dynamicData.push({
              customerShort: event.rowValue.customerShort,
              month: data.month,
              taxed: data.tax,
              soLineNumber: data.so_line_number,
              customerPoLineNumber: data.cust_po_line_num,
              QTY: data.qty,
            });
          });
        }
      }
    });

    return dynamicData;
  }

  dynamicColumns(): columnsStyle[] {
    return [
      { label: 'CUSTOMER SHORT', prop: 'customerShort' },
      { label: 'MONTH', prop: 'month' },
      { label: 'TAXES', prop: 'taxed' },
      { label: 'SO LINE NUMBER', prop: 'soLineNumber' },
      { label: 'CUSTOMER PO LINE NUMBER', prop: 'customerPoLineNumber' },
      { label: 'QTY', prop: 'QTY' },
    ];
  }

  openModalLastWeek(): void {
    const dynamicData: any = this.dynamicDataSourceLast(
      this.tableResultCustomerView.lastWeekModal?.content,
      this.tableResultCustomerView.lastWeekModal?.headers[0]
    );

    const resultTotal: any = this.tableResultCustomerView.lastWeekModal;
    const columns = this.dynamicColumnsLast();
    const combinedUnique: any[] = [];

    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const family = item.family;
      const found = combinedUnique.find(
        elem => elem.origin === origin && elem.family === family
      );

      if (!found) {
        combinedUnique.push({ origin, family });
      }
    });

    const originSubtotals: {
      [key: string]: { allocated: number; invoiced: number; delta: number };
    } = {};
    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const allocated = item.allocated;
      const invoiced = item.invoiced;
      const delta = item.delta;

      if (!originSubtotals[origin]) {
        originSubtotals[origin] = { allocated: 0, invoiced: 0, delta: 0 };
      }

      originSubtotals[origin].allocated += allocated;
      originSubtotals[origin].invoiced += invoiced;
      originSubtotals[origin].delta += delta;
    });

    const totalSubtotals: any = {};
    const processedCombinations = new Map();
    const newData = [];

    dynamicData.forEach((item: any) => {
      const origin = item.origin;
      const customerShort = item.customerShort;
      const combinationKey = origin;

      if (!processedCombinations.has(combinationKey)) {
        item.subtotals = {};

        columns.forEach((header: any) => {
          if (header.summable) {
            const subtotal = dynamicData
              .filter((dataItem: any) => {
                return dataItem.origin === origin;
              })
              .reduce(
                (sum: number, dataItem: any) => sum + dataItem[header.prop],
                0
              );

            item.subtotals[header.prop] = subtotal;

            if (!totalSubtotals[header.prop]) {
              totalSubtotals[header.prop] = subtotal;
            } else {
              totalSubtotals[header.prop] += subtotal;
            }
            resultTotal.total = totalSubtotals;
          }
        });

        processedCombinations.set(combinationKey, true);
      }
    });

    let modalData = {
      title: 'LAST WEEK',
      nameModal: 'lastWeek',
      dataSource: dynamicData,
      displayedColumns: columns,
      total: resultTotal?.total,
      dynamicPropertyWithSameValue: combinedUnique,
      hasRecords: dynamicData.length > 0,
      messageNoRecords: 'THERE ARE NO LAST WEEK DATA',
    };

    const dialogRef = this.dialog.open(ModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog-container',
    });
  }

  dynamicDataSourceLast(data?: any, headers?: any): any[] {
    const dynamicData: any[] = [];

    if (data) {
      data.forEach((row: any) => {
        const dynamicRow: any = {};
        for (const key in headers) {
          if (headers.hasOwnProperty(key) && row.hasOwnProperty(key)) {
            dynamicRow[key] = row[key];
          }
        }
        dynamicData.push(dynamicRow);
      });

      dynamicData.forEach((row, index) => {
        if (index > 0) {
          const previousRow = dynamicData[index - 1];
          if (
            row.customerShort === previousRow.customerShort &&
            row.origin === previousRow.origin
          ) {
            const currentCustomerShort = row.customerShort;
            const currentOrigin = row.origin;

            let firstMatchingIndex = index - 1;
            while (firstMatchingIndex >= 0) {
              const prevCustomerShort =
                dynamicData[firstMatchingIndex].customerShort;
              const prevOrigin = dynamicData[firstMatchingIndex].origin;

              if (
                prevCustomerShort === currentCustomerShort &&
                prevOrigin === currentOrigin
              ) {
                break;
              }
              firstMatchingIndex--;
            }

            if (firstMatchingIndex >= 0) {
              for (let i = index; i > firstMatchingIndex; i--) {
                const item = dynamicData[i];
                if (
                  item.customerShort === currentCustomerShort &&
                  item.origin === currentOrigin
                ) {
                  item.customerShort = '';
                }
              }
            }
          }
        }
      });
    }
    return dynamicData;
  }

  dynamicColumnsLast(): columnsStyle[] {
    return [
      { label: 'CUSTOMER SHORT', prop: 'customerShort' },
      { label: 'PPM', prop: 'family' },
      { label: 'CHANNEL', prop: 'channel' },
      { label: 'ORIGIN', prop: 'origin' },
      { label: 'TAXES', prop: 'taxes' },
      {
        label: 'ALLOCATED',
        prop: 'allocated',
        summable: true,
        total: 0,
      },
      {
        label: 'INVOICED',
        prop: 'invoiced',
        summable: true,
        total: 0,
      },
      { label: 'DELTA', prop: 'delta', summable: true, total: 0 },
    ];
  }
}
