import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
  NgZone,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnAttributes } from '../table/table.interface';
import { Propertys } from 'src/app/interfaces/ka-info.interface';
import { KaProductListAndKaSubtotal } from 'src/app/interfaces/ka-info.interface';

@Component({
  selector: 'app-table-subtotal',
  templateUrl: './table-subtotal.component.html',
  styleUrls: ['./table-subtotal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSubtotalComponent implements OnInit {
  @Input() dataTable!: any;
  @Input() disableTableInput!: boolean;
  @Output('toggleColumnTable') toggleColumnTableEvent = new EventEmitter();
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @Output() onChange = new EventEmitter<any>();

  displayedColumns?: any;
  dataSource = new MatTableDataSource<any>();
  displayedDef?: any;
  rowsSubtotal: any;
  displayedDefWithAlwaysVisibleFalses?: any;

  deal: any;
  totalPlan: any;
  totalRequest: any;
  subtotalDeal: any;
  quantityMaterialIds: any;

  headers!: HTMLElement[];
  previousProductValue = null;
  firstDealIndex: number | undefined;

  clickCount: number = 0;

  @ViewChild('firstTable') firstTable!: ElementRef;
  @ViewChild('secondTable') secondTable!: ElementRef;
  initDeal!: number;
  numFixedColumns: number;

  currentRowIndex: number = 0;
  currentColIndex: number = 0;
  formattedValue!: string;
  value: any;

  currentFocusedInput: HTMLInputElement | null = null;
  secondClickInput!: boolean;
  enterIntoOrOut: any;
  backgroundYellow!: boolean;
  isTypingNewValue: any;
  maxlength: number = 21;

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone
  ) {
    this.numFixedColumns = 3;
  }

  ngOnInit(): void {
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.dataTable?.currentValue) {
      this.displayedDef = [];
      this.displayedColumns = [];
      this.loadTable(changes.dataTable.currentValue);
    }
    if (changes.displayedColumns?.currentValue) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }
  }

  ngAfterViewInit() {
    this.headers = Array.from(
      this.elementRef.nativeElement.querySelectorAll('#table th')
    );
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.handleScroll);
    });

    const firstTableRows =
      this.firstTable.nativeElement.querySelectorAll('tr[data-row-index]');
    const secondTableRows =
      this.secondTable.nativeElement.querySelectorAll('tr[data-row-index]');

    firstTableRows.forEach((row: any) => {
      row.addEventListener('mouseover', () => {
        const kaRowIndex = row.getAttribute('data-ka-row-index');
        const correspondingRow = this.getCorrespondingRow(
          secondTableRows,
          kaRowIndex
        );
        this.highlightRows(row, correspondingRow);
      });

      row.addEventListener('mouseout', () => {
        const kaRowIndex = row.getAttribute('data-ka-row-index');
        const correspondingRow = this.getCorrespondingRow(
          secondTableRows,
          kaRowIndex
        );

        this.removeHighlightRows(row, correspondingRow);
      });
    });

    secondTableRows.forEach((row: any) => {
      row.addEventListener('mouseover', () => {
        const kaRowIndex = row.getAttribute('data-ka-row-index');
        const correspondingRow = this.getCorrespondingRow(
          firstTableRows,
          kaRowIndex
        );
        this.highlightRows(correspondingRow, row);
      });

      row.addEventListener('mouseout', () => {
        const kaRowIndex = row.getAttribute('data-ka-row-index');
        const correspondingRow = this.getCorrespondingRow(
          firstTableRows,
          kaRowIndex
        );

        this.removeHighlightRows(correspondingRow, row);
      });
    });
  }

  handleInputClick(event: any) {
    const target = event.target as HTMLInputElement;

    if (target && target.tagName === 'INPUT') {
        if (this.currentFocusedInput && this.currentFocusedInput !== target) {
            this.currentFocusedInput.classList.remove('input-highlight');
            this.currentFocusedInput.classList.remove('color-click');
            this.clickCount = 0;
            this.backgroundYellow = false;
            this.enterIntoOrOut = true;
        }

        this.currentFocusedInput = target;
        this.clickCount++;

        if (this.clickCount === 1) {
            this.secondClickInput = false;
            target.classList.add('color-click');
            target.classList.add('custom-input');
            target.classList.remove('input-highlight');
            this.backgroundYellow = false;
            this.isTypingNewValue = false;
            document.querySelectorAll('input').forEach(input => {
              input.addEventListener('focus', () => {
                this.isTypingNewValue = false;
              });
            });
        } else if (this.clickCount === 2) {
            this.secondClickInput = true;
            target.classList.add('input-highlight');
            target.classList.remove('custom-input');
            target.classList.remove('color-click');
            target.focus();
            this.backgroundYellow = true;
            document.querySelectorAll('input').forEach(input => {
              input.addEventListener('focus', () => {
                this.isTypingNewValue = true;
              });
            });
        }
    }
  }

  handleInputBlur(event: any, kaProduct: any, col: any) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value.trim();

    if (inputValue.includes('=')) {
      this.calculateAndSetValue(target, kaProduct, col);
      const enterEvent = new KeyboardEvent('keyup', { key: 'Enter' });
      target.dispatchEvent(enterEvent);
    }
  }

  handleEnter(event: KeyboardEvent, target: HTMLInputElement, kaProduct: any, col: any) {
    if (event.key === 'Enter') {
      this.calculateAndSetValue(target, kaProduct, col);
    }
  }

  calculateAndSetValue(target: HTMLInputElement, kaProduct: any, col: any) {
    try {
      const inputValue = target.value.trim();

      if (inputValue === '=') {
        target.value = kaProduct[col.props].value.toLocaleString('en');
        return;
      }
      
      const expression = inputValue.split('=')[1].trim();
      const sanitizedExpression = expression.replace(/,/g, '');
      let adjustedExpression = sanitizedExpression.replace(/\b0+(\d+)/g, '$1');
      adjustedExpression = adjustedExpression.replace(/([\+\-])(\s*)$/, '$10$2');
      const result = eval(adjustedExpression);
      const formattedResult = result.toLocaleString('en');
      target.value = formattedResult;

      kaProduct[col.props].value = formattedResult;

      this.onChange.emit({
        value: formattedResult,
      });

      this.cdr.detectChanges();

    } catch (error) {
      console.error('Invalid expression', error);
    }
  }


  handleDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    // Verifica se o clique foi fora do input atual
    if (this.currentFocusedInput && !this.currentFocusedInput.contains(target)) {
        this.clickCount = 0;
        this.currentFocusedInput.classList.remove('color-click');
        this.currentFocusedInput.classList.remove('input-highlight');
        this.enterIntoOrOut = true;
        this.backgroundYellow = false;
        this.currentFocusedInput = null;
        this.isTypingNewValue = true;
    }
  }

  handleKeyDownEvent(
    event: KeyboardEvent,
    col: any,
    indexRow: any,
    row?: any,
    indexRowList?: any,
    ka?: any,
    idRowSpan?: any,
    columnName?: any,
  ) {
    const tableContainer = document.querySelector('.table-container');
    if (tableContainer instanceof HTMLElement) {
      tableContainer.addEventListener('click', () => {
        const highlightedCell = document.querySelector('.highlighted-cell');
        if (highlightedCell instanceof HTMLElement) {
          highlightedCell.style.backgroundColor = '';
          highlightedCell.classList.remove('highlighted-cell');
        }
      });
    }

    const target = event.target as HTMLInputElement;
    this.backgroundYellow = false;
    if (!this.secondClickInput) {
      switch (event.key) {
        case 'ArrowUp':
          this.moveCursorUp(col, indexRow, row, indexRowList, ka, idRowSpan);
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        case 'ArrowDown':
          this.moveCursorDown(col, indexRow, row, indexRowList, ka, idRowSpan);
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        case 'ArrowLeft':
          this.moveCursorLeft(col, indexRow, ka, indexRowList, idRowSpan);
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        case 'ArrowRight':
          this.moveCursorRight(col, indexRow, ka, indexRowList, idRowSpan);
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        }
      // this.backgroundYellow = true;
      this.secondClick(event, indexRow, indexRowList, columnName, row, col)
    } else if(this.secondClickInput) {
      this.backgroundYellow = true;
      this.secondClick(event, indexRow, indexRowList, columnName, row, col)
    }

    this.handleEnterBackground(event)
  }

  secondClick(
    event: any,
    indexRow: number,
    indexRowList: number,
    columnName: string,
    row: any,
    col: any) {
      const inputValue: string = (event.target as HTMLInputElement).value.trim();
      const equalsCount = (inputValue.match(/=/g) || []).length;
      const inputElement = event.target as HTMLInputElement;
      const inputLength = inputElement.value.length;

      const allowedKeys = [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Enter',
        '=',
        '+',
        '-'
      ];

      if ((event.ctrlKey || event.metaKey) && ['c', 'v', 'x'].includes(event.key)) {
        return;
      }

      if (/[a-zA-Z.]/.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
        return;
      }

      if (event.key === 'Backspace' && !this.secondClickInput) {
        (event.target as HTMLInputElement).value = '0';
        return;
      }

      // no segundo click não apaga todos valores do input ao digitar
      if (this.clickCount >= 2) {
        this.isTypingNewValue = true;
      }

      if (event.key === 'Enter' && this.clickCount >= 2 ) {
        this.isTypingNewValue = false;
      }

      if (this.handleEqualsKey(event, inputValue)) return;

      if (event.key === 'Tab') {
        event.preventDefault();
        return;
      }

      if (event.key === '=' && equalsCount >= 1) {
        event.preventDefault();
        return;
      }

      if (event.ctrlKey) {
        switch (event.key) {
          case 'c':
          case 'v':
          case 'x':
            return;
        }
      }

      if ((event.key === '+' || event.key === '-') && !inputValue.includes('=')) {
        event.preventDefault();
        return;
      }

      if (event.key === 'Enter' && inputValue.includes('=')) {
        event.preventDefault();
        this.backgroundYellow = true;

        if (inputValue === '=') {
          (event.target as HTMLInputElement).value = '0';
          return;
        }
        try {
          const expression = inputValue.split('=')[1].trim();
          const sanitizedExpression = expression.replace(/,/g, '');
          let adjustedExpression = sanitizedExpression.replace(/\b0+(\d+)/g, '$1');
          adjustedExpression = adjustedExpression.replace(/([\+\-])(\s*)$/, '$10$2');
          const result = eval(adjustedExpression);
          const formattedResult = result.toLocaleString('en');
          (event.target as HTMLInputElement).value = formattedResult;

          let item: any = {
            indexRow: indexRow,
            indexRowList: indexRowList,
            columnName: columnName,
            row: row,
            col: col,
          };
          this.onChangeEvent(item, { target: event.target, key: 'Enter' });
        } catch (error) {
          console.error('Invalid expression', error);
        }
        this.isTypingNewValue = false;

        return;
      }
  }

  limitInput(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const maxLength = 21;

    const newValue = event.data;
    if (!this.isTypingNewValue && this.clickCount === 1) {
      inputElement.value = newValue;
      this.isTypingNewValue = true;
    }

    if (inputElement.value.length > maxLength) {
      inputElement.value = inputElement.value.slice(0, maxLength);
    }
  }

  handleEqualsKey(event: any, inputValue: string): boolean {
    if (event.key === '=' && inputValue.indexOf('=') !== 0) {
      event.preventDefault();
      const newValue = `=${inputValue}`;
      const newValueOneClick = `=`;
      (event.target as HTMLInputElement).value = this.clickCount >= 2 ? newValue : newValueOneClick;
      event.target.selectionStart = event.target.selectionEnd = newValue.length;
      this.isTypingNewValue = true;
      return true;
    }
    return false;
  }

  handleEnterBackground(event: any) {
    const target = event.target as HTMLInputElement;
    this.currentFocusedInput = target;

    if (event.key !== undefined) {
      if (event.key === 'Enter' && this.backgroundYellow) {
          // Alternar para fundo amarelo
          this.secondClickInput = false;
          this.currentFocusedInput.classList.add('color-click');
          this.currentFocusedInput.classList.add('custom-input');
          this.currentFocusedInput.classList.remove('input-highlight');
          this.clickCount = 1;
      } else if (event.key === 'Enter' && !this.backgroundYellow) {
          // Alternar para borda amarela
          this.secondClickInput = true;
          this.currentFocusedInput.classList.add('input-highlight');
          this.currentFocusedInput.classList.remove('custom-input');
          this.currentFocusedInput.classList.remove('color-click');
          this.clickCount = 2;
      }
    }
  }

  moveCursorUp(
    col: any,
    indexRow: any,
    row: any,
    indexRowList: any,
    ka: any,
    idRowSpan?: any
  ) {
    const targetIndex = indexRow - 1;
    const currentRow: any =
      this.dataSource.filteredData[indexRowList].kaProductList[indexRow];
    const previousRow: any =
      this.dataSource.filteredData[indexRowList].kaProductList[targetIndex];

    if (currentRow[col.props].hasOwnProperty('rowSpan')) {
      if (
        previousRow &&
        previousRow[col.props] &&
        previousRow[col.props].enableInput
      ) {
        const targetIndex = idRowSpan - 1;
        const rowId: string = col.props + '_' + targetIndex;

        const previousHighlightedCell: HTMLInputElement =
          this.elementRef.nativeElement.querySelector('.highlighted-cell');
        if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
        }

        const inputElement: HTMLInputElement =
          this.elementRef.nativeElement.querySelector(`#${rowId}`);
        if (inputElement) {
          inputElement.focus();
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
          return;
        }
      }
      if (previousRow === undefined) {
        const targetIndex = idRowSpan - 1;
        const rowId: string = col.props + '_' + targetIndex;

        const previousHighlightedCell: HTMLInputElement =
          this.elementRef.nativeElement.querySelector('.highlighted-cell');
        if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
        }

        const inputElement: HTMLInputElement =
          this.elementRef.nativeElement.querySelector(`#${rowId}`);
        if (inputElement) {
          inputElement.focus();
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
          return;
        }
      }
    } else {
      if (previousRow && previousRow[col.props]) {
        if (previousRow[col.props].enableInput) {
          const rowId: string = col.props + '_' + targetIndex + '_' + ka;
          const previousHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
          }

          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowId}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
            return;
          }
        }
      } else {
        if (previousRow === undefined) {
          const lastIndexPreviousTable =
            this.dataSource.filteredData[indexRowList - 1].kaProductList
              .length - 1;
          const rowId: string =
            col.props +
            '_' +
            lastIndexPreviousTable +
            '_' +
            this.dataSource.filteredData[indexRowList - 1].kaProductList[
              lastIndexPreviousTable
            ].ka.secondValue;
          const previousHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
          }
          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowId}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
            return;
          }
        }
      }
    }
  }

  moveCursorDown(
    col: any,
    indexRow: any,
    row: any,
    indexRowList: any,
    ka: any,
    idRowSpan?: any
  ) {
    const targetIndex = indexRow + 1;
    const currentRow: any =
      this.dataSource.filteredData[indexRowList].kaProductList[indexRow];
    const nextRow: any =
      this.dataSource.filteredData[indexRowList].kaProductList[targetIndex];
    if (currentRow[col.props].hasOwnProperty('rowSpan')) {
      const targetIndex = idRowSpan + 1;
      const rowId: string = col.props + '_' + targetIndex;

      const previousHighlightedCell: HTMLInputElement =
        this.elementRef.nativeElement.querySelector('.highlighted-cell');
      if (previousHighlightedCell) {
        previousHighlightedCell.style.backgroundColor = '';
        previousHighlightedCell.classList.remove('highlighted-cell');
      }

      const inputElement: HTMLInputElement =
        this.elementRef.nativeElement.querySelector(`#${rowId}`);
      if (inputElement) {
        inputElement.focus();
        inputElement.style.backgroundColor = '#fdf1c5';
        inputElement.classList.add('highlighted-cell');
        return;
      }
    } else {
      if (nextRow && nextRow[col.props]) {
        if (nextRow[col.props].enableInput) {
          const rowId: string = col.props + '_' + targetIndex + '_' + ka;
          const previousHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
          }

          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowId}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
            return;
          }
        }
      } else {
        if (nextRow === undefined) {
          const firstIndexRow: number = 0;
          const rowId: string =
            col.props +
            '_' +
            firstIndexRow +
            '_' +
            this.dataSource.filteredData[indexRowList + 1].kaProductList[0].ka
              .secondValue;
          const previousHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
          }

          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowId}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
            return;
          }
        }
      }
    }
  }

  moveCursorLeft(
    col: any,
    indexRow: any,
    ka: any,
    indexRowList: any,
    idRowSpan?: any
  ) {
    const currentRow: any =
      this.dataSource.filteredData[indexRowList].kaProductList[indexRow];

    let indexColumnActual: number | null = null;
    for (let i = 0; i < this.displayedDefWithAlwaysVisibleFalses.length; i++) {
      if (this.displayedDefWithAlwaysVisibleFalses[i].props === col.props) {
        indexColumnActual = i;
        break;
      }
    }

    if (currentRow[col.props].hasOwnProperty('rowSpan')) {
      if (indexColumnActual !== null && indexColumnActual > 0) {
        const previousColumnIndex = indexColumnActual - 1;
        const previousColumn =
          this.displayedDefWithAlwaysVisibleFalses[previousColumnIndex];
        if (previousColumn.enableInput) {
          const rowIdColumn = previousColumn.props + '_' + idRowSpan;
          const previousHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
          }

          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
          }
        } else {
          const previousColumn =
            this.displayedDefWithAlwaysVisibleFalses[previousColumnIndex - 1];
          const rowIdColumn = previousColumn.props + '_' + idRowSpan;
          const previousHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
          }

          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
          }
        }
      }
      return;
    }

    if (indexColumnActual !== null && indexColumnActual > 0) {
      const previousColumnIndex = indexColumnActual - 1;
      const previousColumn =
        this.displayedDefWithAlwaysVisibleFalses[previousColumnIndex];
      if (previousColumn.enableInput) {
        const rowIdColumn = previousColumn.props + '_' + indexRow + '_' + ka;
        const previousHighlightedCell: HTMLInputElement =
          this.elementRef.nativeElement.querySelector('.highlighted-cell');
        if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
        }

        const inputElement: HTMLInputElement =
          this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
        if (inputElement) {
          inputElement.focus();
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
        }
      } else {
        for (
          let i = indexColumnActual - 1;
          i < this.displayedDefWithAlwaysVisibleFalses.length;
          i--
        ) {
          if (this.displayedDefWithAlwaysVisibleFalses[i].enableInput) {
            const previousColumn = this.displayedDefWithAlwaysVisibleFalses[i];
            let rowIdColumn: any = '';
            if (currentRow[previousColumn.props]?.hasOwnProperty('rowSpan')) {
              rowIdColumn =
                previousColumn.props +
                '_' +
                currentRow[previousColumn.props]?.id;
            } else {
              rowIdColumn = previousColumn.props + '_' + indexRow + '_' + ka;
            }
            const previousHighlightedCell: HTMLInputElement =
              this.elementRef.nativeElement.querySelector('.highlighted-cell');
            if (previousHighlightedCell) {
              previousHighlightedCell.style.backgroundColor = '';
              previousHighlightedCell.classList.remove('highlighted-cell');
            }

            const inputElement: HTMLInputElement =
              this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
            if (inputElement) {
              inputElement.focus();
              inputElement.style.backgroundColor = '#fdf1c5';
              inputElement.classList.add('highlighted-cell');
            }
            break;
          }
        }
      }
    }
  }

  moveCursorRight(
    col: any,
    indexRow: any,
    ka: any,
    indexRowList: any,
    idRowSpan?: any
  ) {
    const currentRow: any =
      this.dataSource.filteredData[indexRowList].kaProductList[indexRow];

    let indexColumnActual: number | null = null;
    for (let i = 0; i < this.displayedDefWithAlwaysVisibleFalses.length; i++) {
      if (this.displayedDefWithAlwaysVisibleFalses[i].props === col.props) {
        indexColumnActual = i;
        break;
      }
    }

    if (currentRow[col.props].hasOwnProperty('rowSpan')) {
      if (indexColumnActual !== null && indexColumnActual > 0) {
        const nextColumnIndex = indexColumnActual + 1;
        const nextColumn =
          this.displayedDefWithAlwaysVisibleFalses[nextColumnIndex];
        if (nextColumn.enableInput) {
          const rowIdColumn = nextColumn.props + '_' + idRowSpan;
          const nextHighlightedCell: HTMLInputElement =
            this.elementRef.nativeElement.querySelector('.highlighted-cell');
          if (nextHighlightedCell) {
            nextHighlightedCell.style.backgroundColor = '';
            nextHighlightedCell.classList.remove('highlighted-cell');
          }

          const inputElement: HTMLInputElement =
            this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
          if (inputElement) {
            inputElement.focus();
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
          }
        } else {
          for (
            let i = indexColumnActual + 1;
            i < this.displayedDefWithAlwaysVisibleFalses.length;
            i++
          ) {
            if (this.displayedDefWithAlwaysVisibleFalses[i].enableInput) {
              const nextColumn = this.displayedDefWithAlwaysVisibleFalses[i];
              const rowIdColumn = nextColumn.props + '_' + indexRow + '_' + ka;
              const nextHighlightedCell: HTMLInputElement =
                this.elementRef.nativeElement.querySelector(
                  '.highlighted-cell'
                );
              if (nextHighlightedCell) {
                nextHighlightedCell.style.backgroundColor = '';
                nextHighlightedCell.classList.remove('highlighted-cell');
              }

              const inputElement: HTMLInputElement =
                this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
              if (inputElement) {
                inputElement.focus();
                inputElement.style.backgroundColor = '#fdf1c5';
                inputElement.classList.add('highlighted-cell');
              }
              break;
            }
          }
        }
      }
      return;
    }

    if (indexColumnActual !== null && indexColumnActual > 0) {
      const nextColumnIndex = indexColumnActual + 1;
      const nextColumn =
        this.displayedDefWithAlwaysVisibleFalses[nextColumnIndex];
      if (nextColumn.enableInput) {
        const rowIdColumn = nextColumn.props + '_' + indexRow + '_' + ka;
        const nextHighlightedCell: HTMLInputElement =
          this.elementRef.nativeElement.querySelector('.highlighted-cell');
        if (nextHighlightedCell) {
          nextHighlightedCell.style.backgroundColor = '';
          nextHighlightedCell.classList.remove('highlighted-cell');
        }

        const inputElement: HTMLInputElement =
          this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
        if (inputElement) {
          inputElement.focus();
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
        }
      } else {
        for (
          let i = indexColumnActual + 1;
          i < this.displayedDefWithAlwaysVisibleFalses.length;
          i++
        ) {
          if (this.displayedDefWithAlwaysVisibleFalses[i].enableInput) {
            const nextColumn = this.displayedDefWithAlwaysVisibleFalses[i];
            let rowIdColumn: any = '';
            if (currentRow[nextColumn.props]?.hasOwnProperty('rowSpan')) {
              rowIdColumn =
                nextColumn.props + '_' + currentRow[nextColumn.props]?.id;
            } else {
              rowIdColumn = nextColumn.props + '_' + indexRow + '_' + ka;
            }
            const nextHighlightedCell: HTMLInputElement =
              this.elementRef.nativeElement.querySelector('.highlighted-cell');
            if (nextHighlightedCell) {
              nextHighlightedCell.style.backgroundColor = '';
              nextHighlightedCell.classList.remove('highlighted-cell');
            }

            const inputElement: HTMLInputElement =
              this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
            if (inputElement) {
              inputElement.focus();
              inputElement.style.backgroundColor = '#fdf1c5';
              inputElement.classList.add('highlighted-cell');
            }
            break;
          }
        }
      }
    }
  }

  addRowId(col: any, rowId: any, ka: any, id: any) {
    if (col === 'initDeal' || col === 'deal') {
      return col + '_' + id;
    }
    return col + '_' + rowId + '_' + ka['ka']?.secondValue;
  }

  private getCorrespondingRow(
    rows: NodeListOf<Element>,
    kaRowIndex: string
  ): any {
    return (
      Array.from(rows).find(
        row => row.getAttribute('data-ka-row-index') === kaRowIndex
      ) || null
    );
  }

  private highlightRows(row1: Element, row2: Element) {
    row1.classList.add('hover');
    row2.classList.add('hover');

    const tdElements = row2.querySelectorAll('.column-color');

    for (let i = 0; i < tdElements.length; i++) {
      const tdElement = tdElements[i];
      tdElement.classList.remove('column-color');
    }
  }

  private removeHighlightRows(row1: Element, row2: Element) {
    row1.classList.remove('hover');
    row2.classList.remove('hover');

    const tdElements = row2.querySelectorAll('[data-ka-row-blue]');

    for (let i = 0; i < tdElements.length; i++) {
      const tdElement = tdElements[i];
      tdElement.classList.add('column-color');
    }

    const dealElements = row2.querySelectorAll('[data-ka-titles]');

    for (let i = 0; i < dealElements.length; i++) {
      const tdElement = dealElements[i];
      if (dealElements) {
        tdElement.classList.remove('column-color');
      }
    }
  }

  @HostListener('window:resize')
  handleResize() {
    this.stickyHeaders();
  }

  handleScroll = () => {
    this.ngZone.runOutsideAngular(() => {
      this.stickyHeaders();
    });
  };

  stickyHeaders() {
    const windowTop = window.scrollY || window.pageYOffset;
    const tableTop =
      this.elementRef.nativeElement
        .querySelector('#table')
        .getBoundingClientRect().top + windowTop;

    this.headers.forEach((header: HTMLElement) => {
      header.style.position = 'sticky';
      if (windowTop > tableTop) {
        header.classList.add('fixed-header');
        header.style.top = `${windowTop - tableTop}px`;
      } else {
        header.style.top = '0';
      }
    });
  }

  loadTable(updateDataTable?: any) {
    this.dataTable = updateDataTable || this.dataTable;

    if (this.dataTable?.table) {
      this.dataSource = this.dataTable.table.rows;
      this.displayedDef = this.dataTable?.table.columns;

      this.displayedColumns = this.displayedDef
        .filter((item: any) =>
          // logica para evitar sideEfect em telas que não tem expansão de colunas (alwaysVisible)
          item.props && item.alwaysVisible
            ? item.props && item.alwaysVisible
            : item.alwaysVisible != false
        )
        .map((item: any) => item.props);
      this.dataSource.filteredData.forEach((item: Propertys) => {
        item.kaProductList?.forEach((item: any) => {
          item.quantity = item.deal.materialIds.length;
        });
      });
    }

    this.displayedDefWithAlwaysVisibleFalses =
      this.dataTable?.table.columns.filter(
        (item: any) =>
          this.displayedColumns.includes(item.props) && item.alwaysVisible
      );
  }

  getAddRowId(indexRow: number) {
    return indexRow;
  }

  mouseEnter(indexRow: number, row: any) {
    const kaValue: string = row['ka'].secondValue;
    const tableRows = document.querySelectorAll('.column');
    const rowId = indexRow + row['ka'].secondValue;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.add(`hover-row${kaValue}`);
      }
    });
  }

  mouseLeave(indexRow: number, row: any) {
    const kaValue: string = row['ka'].secondValue;
    const tableRows = document.querySelectorAll('.column');
    const rowId = indexRow + row['ka'].secondValue;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.remove(`hover-row${kaValue}`);
      }
    });
  }

  updateDisplayedDefWithNotAlwaysVisible() {
    this.displayedDefWithAlwaysVisibleFalses = [];
    this.displayedDef.forEach((column: Propertys) => {
      if (column.alwaysVisible) {
        this.displayedDefWithAlwaysVisibleFalses.push(column);
      } else if (this.displayedColumns.includes(column.props)) {
        this.displayedDefWithAlwaysVisibleFalses.push(column);
      }
    });
  }

  toggleColumnTable(col?: any, event?: string) {
    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContent === col.otherContent
    );
    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );
    const indexCol = this.displayedColumns.findIndex(
      (item: any) => item === col?.otherContent
    );
    columsToShowHide.shift();
    if (event === 'show') {
      col.expanded = true;
      groupColumnsToShowHide[0].isFirstCard = true;
      groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
        true;
      groupColumnsToShowHide.forEach((column: Propertys) => {
        column.expanded = true;
      });

      this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
      this.updateDisplayedDefWithNotAlwaysVisible();
    } else {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
      groupColumnsToShowHide.forEach((column: Propertys) => {
        if (!column.isFirstCard) {
          this.updateDisplayedDefWithNotAlwaysVisible();
        }
      });
    }

    this.toggleColumnTableEvent.emit({
      col,
      event,
    });

    if (col.props === 'product') {
      this.numFixedColumns = col.expanded ? 4 : 3;
    }
    this.cdr.detectChanges();

    this.headers = Array.from(
      this.elementRef.nativeElement.querySelectorAll('#table th')
    );
    this.stickyHeaders();
  }

  updateProperty(
    column: Propertys,
    event: KeyboardEvent,
    calculatedValue: any,
    property: string,
    col: ColumnAttributes,
    subtotal: KaProductListAndKaSubtotal
  ) {
    if (isNaN(calculatedValue)) calculatedValue = this.formattedValue;

    // if ((event.code === 'Backspace' || event.key === 'Delete')  && !calculatedValue) {
    //   column[property].value = '0';
    // }

    if (col.props === property) {
      if (property === 'deal') {
        this.deal = calculatedValue;
      } else if (property === 'initDeal') {
        this.initDeal = calculatedValue;
      }
    }

    if(!isNaN(calculatedValue)) {
      this.columnsWithSameMaterialId(subtotal, column, calculatedValue, col);
    }
  }

  updateValues(
    column: any,
    subtotal: KaProductListAndKaSubtotal,
    event: KeyboardEvent,
    col: ColumnAttributes
  ) {
    let calculated = Number(
      (event.target as HTMLInputElement).value.replace(/,/g, '')
    );

    if (col.props === 'deal') {
      this.updateProperty(column, event, calculated, 'deal', col, subtotal);
    } else {
      this.deal = column.deal.value;
    }

    if (col.props === 'initDeal') {
      this.updateProperty(column, event, calculated, 'initDeal', col, subtotal);
    } else {
      this.initDeal = column.initDeal.value;
    }

    this.displayedDef.forEach((item: any) => {
      let sum = 0;
      if (
        item.props === 'deal' ||
        item.props === 'initDeal' ||
        item.props === 'gapDealDemand' ||
        item.props === 'gapDealPlan' ||
        item.props === 'gapDealRequest' ||
        item.props === 'gapDealAllocation'
      ) {
        this.dataSource.filteredData.forEach((rows: any) => {
          sum += rows.kaSubtotal[item.props].value;
        });
        item.totalAddedValue = sum;
      }
    });
    this.cdr.markForCheck();
  }

  subtotalValue(col: ColumnAttributes) {
    let sum = 0;
    this.dataSource.filteredData.forEach((rows: any) => {
      if (col.props) sum += rows.kaSubtotal[col.props].value;
    });

    this.displayedDef.forEach((item: any) => {
      if (item.props === col.props) {
        item.totalAddedValue = sum;
      }
    });
  }

  columnsWithSameMaterialId(
    subtotal: any,
    column: any,
    dealValue: number,
    col: ColumnAttributes
  ) {
    const productList = subtotal.kaProductList;
    let dealValueSum = 0;
    let initDealValueSum = 0;
    let gapDealDemandValueSum = 0;
    let gapDealPlanValueSum = 0;
    let gapDealRequestValueSum = 0;
    let gapDealAllocationValueSum = 0;

    // Itera sobre a productList para atribuir o dealValue apenas ao item correspondente
    productList.forEach((item: any) => {
      if (
        item.deal.materialIds &&
        column.deal.materialIds &&
        JSON.stringify(item.deal.materialIds) ===
          JSON.stringify(column.deal.materialIds)
      ) {
        if (col.props === 'deal') {
          item.deal.value = dealValue;
        }
        if (col.props === 'initDeal') {
          item.initDeal.value = dealValue;
        }
      }
    });

    // Realiza os cálculos e atualizações para o subtotal
    const {
      deal,
      initDeal,
      gapDealDemand,
      gapDealPlan,
      gapDealRequest,
      gapDealAllocation,
    } = subtotal.kaSubtotal;

    productList.forEach((item: any) => {
      if (col.props === 'deal') {
        if (item.deal.firstValue) {
          dealValueSum += item.deal.value;
          deal.value = dealValueSum;
        }
      }

      if (col.props === 'initDeal') {
        if (item.initDeal.firstValue) {
          initDealValueSum += parseFloat(item.initDeal.value);
          initDeal.value = initDealValueSum;
        }
      }

      if (
        item.deal.materialIds &&
        column.deal.materialIds &&
        col.props === 'deal' &&
        JSON.stringify(item.deal.materialIds) ===
          JSON.stringify(column.deal.materialIds)
      ) {
        this.updateGapDealDemand(item, dealValue, item.demandByFamily.value);
        this.updateGapDealPlan(item, dealValue, item.planByFamily.value);
        this.updateGapDealRequest(item, dealValue, item.requestByFamily.value);
        this.updateGapDealAllocation(
          item,
          dealValue,
          item.allocationByFamily.value
        );
      }
      if (item.deal.firstValue) {
        gapDealDemandValueSum += item.gapDealDemand.value;
        gapDealPlanValueSum += item.gapDealPlan.value;
        gapDealRequestValueSum += item.gapDealRequest.value;
        gapDealAllocationValueSum += item.gapDealAllocation.value;
      }
    });

    gapDealDemand.value = gapDealDemandValueSum;
    gapDealPlan.value = gapDealPlanValueSum;
    gapDealRequest.value = gapDealRequestValueSum;
    gapDealAllocation.value = gapDealAllocationValueSum;
  }

  updateTotalPlan(
    column: Propertys,
    subtotal: KaProductListAndKaSubtotal,
    event: any,
    col: ColumnAttributes
  ) {
    let plan: any = (event.target as HTMLInputElement).value.replace(/,/g, '')

    if ((event.code === 'Backspace' || event.key === 'Delete') && !plan) {
      plan = '0';
    }

    if (isNaN(plan)) plan  = this.formattedValue;
    const identifierPlan = event.target.dataset.identifier;

    if(!isNaN(plan) && !plan.includes('+') && plan !== '') {
      this.updatePlanMonth(identifierPlan, column, subtotal, plan, col);
    }
    this.cdr.markForCheck();
  }

  updateTotalRequest(
    column: Propertys,
    subtotal: KaProductListAndKaSubtotal,
    event: any,
    col: any
  ) {
    let request: any = (event.target as HTMLInputElement).value.replace(/,/g, '');

    if ((event.code === 'Backspace' || event.key === 'Delete') && !request) {
      request = '0';
    }

    if (isNaN(request)) request = this.formattedValue;
    const identifierRequest = event.target.dataset.identifier;

    if(!isNaN(request) && !request.includes('+') && request !== '') {
      this.updateRequest(identifierRequest, column, subtotal, request, col);
    }
    this.cdr.markForCheck();
  }

  updatePlanMonth(
    identifierPlan: string,
    column: any,
    subtotal: KaProductListAndKaSubtotal,
    updatePlan: number,
    col: ColumnAttributes
  ) {
    const row: any = Object.values(column).find((obj: any) => {
      return obj.identifier === identifierPlan;
    });
    const subtotalPlan = {
      totalPlan: 0,
    };
    const subtotalPlanMonth = {
      [row.identifier]: 0,
    };
    const propertiesWithPlanMonth = [];

    for (const key in column) {
      if (column.hasOwnProperty(key) && column[key].planMonth) {
        if (key === row.identifier) {
          row.value = updatePlan;
          column[key].value = row.value;
        }
        propertiesWithPlanMonth.push(key);
      }
    }

    let totalPlan = 0;
    for (const prop of propertiesWithPlanMonth) {
      totalPlan += parseFloat(column[prop].value);
    }

    column.totalPlan = {
      value: totalPlan,
      summableValue: true,
    };

    subtotal.kaProductList.forEach((column: any) => {
      subtotalPlan.totalPlan += parseFloat(column.totalPlan.value);
      subtotalPlanMonth[row.identifier] += parseFloat(
        column[row.identifier].value
      );
    });

    Object.entries(subtotalPlan).forEach(([key, value]) => {
      subtotal.kaSubtotal[key] = {
        value,
        summableValue: true,
      };
    });

    Object.entries(subtotalPlanMonth).forEach(([key, value]) => {
      subtotal.kaSubtotal[key] = {
        value,
        summableValue: true,
      };
    });

    this.totalPlan = subtotalPlan.totalPlan;

    this.displayedDef.forEach((item: any) => {
      let sum = 0;
      if (item.props === 'gapDealPlan' || item.props === 'totalPlan') {
        this.dataSource.filteredData.forEach((rows: any) => {
          sum += rows.kaSubtotal[item.props].value;
        });
        item.totalAddedValue = sum;
      }
    });

    this.updatePlanVsAllocation(column, subtotal);
    this.subtotalValue(col);
  }

  updateRequest(
    identifierRequest: string,
    column: any,
    subtotal: KaProductListAndKaSubtotal,
    updateRequest: number,
    col: ColumnAttributes
  ) {
    const row: any = Object.values(column).find((obj: any) => {
      return obj.identifier === identifierRequest;
    });
    const subtotalRequest = {
      totalRequest: 0,
    };
    const subtotalRequestMonth = {
      [row.identifier]: 0,
    };
    const propertiesWithRequestMonth = [];

    for (const key in column) {
      if (column.hasOwnProperty(key) && column[key].requestMonth) {
        if (key === row.identifier) {
          row.value = updateRequest;
          column[key].value = row.value;
        }
        propertiesWithRequestMonth.push(key);
      }
    }

    let totalRequest = 0;
    for (const prop of propertiesWithRequestMonth) {
      totalRequest += parseFloat(column[prop].value);
    }

    column.totalRequest = {
      value: totalRequest,
      summableValue: true,
    };

    subtotal.kaProductList.forEach((column: any) => {
      subtotalRequest.totalRequest += parseFloat(column.totalRequest.value);
      subtotalRequestMonth[row.identifier] += parseFloat(
        column[row.identifier].value
      );
    });

    Object.entries(subtotalRequest).forEach(([key, value]) => {
      subtotal.kaSubtotal[key] = {
        value,
        summableValue: true,
      };
    });

    Object.entries(subtotalRequestMonth).forEach(([key, value]) => {
      subtotal.kaSubtotal[key] = {
        value,
        summableValue: true,
      };
    });

    this.totalRequest = subtotalRequest.totalRequest;

    let sum = 0;
    this.displayedDef.forEach((item: any) => {
      if (item.props === 'totalRequest') {
        this.dataSource.filteredData.forEach((rows: Propertys) => {
          sum += rows.kaSubtotal[item.props].value;
        });
        item.totalAddedValue = sum;
      }
    });

    this.updateRequestVsAllocation(column, subtotal);
    this.subtotalValue(col);
  }

  hundleCalculationTotal(value: string) {
    let sum = 0;
    this.displayedDef.forEach((item: Propertys) => {
      if (item.props === value) {
        this.dataSource.filteredData.forEach((rows: Propertys) => {
          sum += rows.kaSubtotal[item.props].value;
        });
        item.totalAddedValue = sum;
      }
    });
  }

  updateRequestVsAllocation(
    column: Propertys,
    subtotal: KaProductListAndKaSubtotal
  ) {
    for (const key in column) {
      if (column.hasOwnProperty(key) && key.includes('reqVsAlloc')) {
        const requestMonth = key.slice(-3);
        const requestProp = `request${requestMonth}`;
        const allocProp = `alloc${requestMonth}`;
        const requestValue = parseFloat(column[requestProp].value || 0);
        const allocValue = parseFloat(column[allocProp].value || 0);
        const diffValue = allocValue - requestValue;
        let diffProp = `reqVsAlloc${requestMonth}`;
        column[diffProp] = {
          value: diffValue,
          summableValue: true,
          requestAllocMonth: true,
          planVsAllocMonth: false,
          identifier: diffProp,
          columnColor: true,
        };
      }
    }

    const reqVsAllocTotal: any = {};
    subtotal.kaProductList.forEach((row: Propertys) => {
      Object.values(row).forEach((col: any) => {
        if (col.requestAllocMonth) {
          const propertyName = col.identifier;
          if (!reqVsAllocTotal[propertyName]) {
            let value = 0;
            reqVsAllocTotal[propertyName] = {
              value: value,
              summableValue: true,
              requestAllocMonth: true,
              planVsAllocMonth: false,
              identifier: propertyName,
              columnColor: true,
            };
          }
          reqVsAllocTotal[propertyName].value += parseFloat(col.value);
        }
      });
    });

    Object.entries(reqVsAllocTotal).forEach(([key, value]) => {
      subtotal.kaSubtotal[key] = value;
    });

    for (const key in column) {
      if (column.hasOwnProperty(key) && key.includes('reqVsAlloc')) {
        const planMonth = key.slice(-3);
        let diffProp = `reqVsAlloc${planMonth}`;
        this.hundleCalculationTotal(diffProp);
      }
    }

    this.calcTotalRequestVsAlloc(column, subtotal);
  }

  calcTotalRequestVsAlloc(column: any, subtotal: KaProductListAndKaSubtotal) {
    const propertiesWithRequestMonth = [];

    for (const key in column) {
      if (column.hasOwnProperty(key) && column[key].requestAllocMonth) {
        propertiesWithRequestMonth.push(key);
      }
    }

    let totalRequestVsAlloc = 0;
    for (const prop of propertiesWithRequestMonth) {
      totalRequestVsAlloc += parseFloat(column[prop].value);
    }

    column.totalReqVsAlloc = {
      value: totalRequestVsAlloc,
      summableValue: true,
      columnColor: true,
    };

    let total = 0;
    subtotal.kaProductList.forEach((row: any) => {
      if (row.totalReqVsAlloc) {
        total += parseFloat(row.totalReqVsAlloc.value);
      }
    });

    subtotal.kaSubtotal.totalReqVsAlloc = {
      value: total,
      summableValue: true,
      columnColor: true,
    };

    this.hundleCalculationTotal('totalReqVsAlloc');
    this.updateGapAlloc(column, subtotal);
  }

  updateGapAlloc(column: any, subtotal: KaProductListAndKaSubtotal) {
    let gapReqValue = column.demand.value - column.totalRequest.value;
    column.gapReq = {
      value: gapReqValue,
      columnColor: true,
    };

    let totalGapReq = 0;
    subtotal.kaProductList.forEach((row: any) => {
      if (row.gapReq) {
        totalGapReq += parseFloat(row.gapReq.value);
      }
    });

    subtotal.kaSubtotal.gapReq = {
      value: totalGapReq,
      columnColor: true,
    };

    this.hundleCalculationTotal('gapReq');
  }

  updatePlanVsAllocation(
    column: Propertys,
    subtotal: KaProductListAndKaSubtotal
  ) {
    for (const key in column) {
      if (column.hasOwnProperty(key) && key.includes('planVsAlloc')) {
        const planMonth = key.slice(-3);
        const requestProp = `plan${planMonth}`;
        const allocProp = `alloc${planMonth}`;
        const planValue = parseFloat(column[requestProp].value || 0);
        const allocValue = parseFloat(column[allocProp].value || 0);
        const diffValue = allocValue - planValue;
        let diffProp = `planVsAlloc${planMonth}`;
        column[diffProp] = {
          value: diffValue,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: diffProp,
          columnColor: true,
          requestAllocMonth: false,
        };
      }
    }

    const planVsAllocTotal: any = {};
    subtotal.kaProductList.forEach((row: Propertys) => {
      Object.values(row).forEach((col: any) => {
        if (col.planVsAllocMonth) {
          const propertyName = col.identifier;
          if (!planVsAllocTotal[propertyName]) {
            let value = 0;
            planVsAllocTotal[propertyName] = {
              value: value,
              summableValue: true,
              planVsAllocMonth: true,
              identifier: propertyName,
              columnColor: true,
            };
          }
          planVsAllocTotal[propertyName].value += parseFloat(col.value);
        }
      });
    });

    Object.entries(planVsAllocTotal).forEach(([key, value]) => {
      subtotal.kaSubtotal[key] = value;
    });

    for (const key in column) {
      if (column.hasOwnProperty(key) && key.includes('planVsAlloc')) {
        const planMonth = key.slice(-3);
        let diffProp = `planVsAlloc${planMonth}`;
        this.hundleCalculationTotal(diffProp);
      }
    }

    this.calcTotaPlantVsAlloc(column, subtotal);
  }

  calcTotaPlantVsAlloc(column: any, subtotal: KaProductListAndKaSubtotal) {
    const propertiesWithPlanMonth = [];

    for (const key in column) {
      if (column.hasOwnProperty(key) && column[key].planVsAllocMonth) {
        propertiesWithPlanMonth.push(key);
      }
    }

    let totalPlanVsAlloc = 0;
    for (const prop of propertiesWithPlanMonth) {
      totalPlanVsAlloc += parseFloat(column[prop].value);
    }

    column.totalPlanVsAlloc = {
      value: totalPlanVsAlloc,
      summableValue: true,
      columnColor: true,
    };

    let total = 0;
    subtotal.kaProductList.forEach((row: any) => {
      if (row.totalPlanVsAlloc) {
        total += parseFloat(row.totalPlanVsAlloc.value);
      }
    });

    subtotal.kaSubtotal.totalPlanVsAlloc = {
      value: total,
      summableValue: true,
      columnColor: true,
    };

    this.hundleCalculationTotal('totalPlanVsAlloc');
  }

  updateGapDealDemand(columnGap: any, dealChange: number, demand: number) {
    const gap = dealChange - demand;
    columnGap.gapDealDemand.value = gap;
    columnGap.gapDealDemand.summableValue = true;
    columnGap.gapDealDemand.columnColor = gap < 0 ? 'red' : 'black';
    return columnGap.gapDealDemand.value;
  }

  updateGapDealPlan(columnGap: any, dealChange: number, totalDealPlan: number) {
    const gap = dealChange - totalDealPlan;
    columnGap.gapDealPlan.value = gap;
    columnGap.gapDealPlan.summableValue = true;
    columnGap.gapDealPlan.columnColor = gap < 0 ? 'red' : 'black';
  }

  updateGapDealRequest(
    columnGap: any,
    dealChange: number,
    totalRequest: number
  ) {
    const gap = dealChange - totalRequest;
    columnGap.gapDealRequest.value = gap;
    columnGap.gapDealRequest.summableValue = true;
    columnGap.gapDealRequest.columnColor = gap < 0 ? 'red' : 'black';
  }

  updateGapDealAllocation(
    columnGap: any,
    dealChange: number,
    totalAllocation: number
  ) {
    const gap = dealChange - totalAllocation;
    columnGap.gapDealAllocation.value = gap;
    columnGap.gapDealAllocation.summableValue = true;
    columnGap.gapDealAllocation.columnColor = gap < 0 ? 'red' : 'black';
  }

  formatNumberWithCommas(numberString: string): string {
    numberString = numberString.replace(/,/g, '');
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formattingValuesInputWithComma(event: Event, valueInput: string, comma: any) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value.trim();

    const parts = inputValue.split('+');
    const formattedParts = parts.map(part => {
      const trimmedPart = part.trim();
      if (!isNaN(Number(trimmedPart))) {
        return this.formatNumberWithCommas(trimmedPart);
      }
      return trimmedPart;
    });

    let formattedValue = formattedParts.join('+');

    if (inputValue.startsWith(`${comma}=`)) {
      formattedValue = '=';
    }

    this.value = formattedValue;

    inputElement.value = this.formatNumberWithCommas(this.value);
    console.log(this.value)

    this.cdr.markForCheck();
  }

  onChangeEvent(
    item: {
      indexRow: number;
      indexRowList: number;
      columnName: string;
      row: any;
      col: any;
    },
    event: any
  ) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      event.target.selectionStart;
    }

    let valueInput: any = event.target.value
      ? event.target.value.replaceAll(',', '')
      : 0;

    const cleanValue = valueInput ? valueInput?.replace(/=/, '') : '';

    if (!isNaN(valueInput)) {
      valueInput = valueInput.toString();
    } else {
      valueInput = '0';
    }

    this.formattingValuesInputWithComma(event, valueInput, cleanValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    this.limitInput(event)
    const dataRowColumnPlanAndReq =
      this.dataSource.filteredData[item.indexRowList]?.kaProductList[
        item.indexRow
      ][item.columnName];
    const dataRowColumnDeal =
      this.dataSource.filteredData[item.indexRowList]?.kaProductList[
        item.indexRow
      ][item.col.props];
    const dataRowColumnInitDeal =
      this.dataSource.filteredData[item.indexRowList]?.kaProductList[
        item.indexRow
      ].initDeal;

    if (dataRowColumnPlanAndReq) {
      const { month, identifier, type, value } = dataRowColumnPlanAndReq;
      const { fiscalQuarter, fiscalYear, kaId, materialId } =
        this.dataSource.filteredData[item.indexRowList].kaProductList[
          item.indexRow
        ].product;

      dataRowColumnPlanAndReq.fiscalYear = fiscalYear;
      dataRowColumnPlanAndReq.quarter = fiscalQuarter;
      dataRowColumnPlanAndReq.identifier = identifier;
      dataRowColumnPlanAndReq.materialId = materialId;
      dataRowColumnPlanAndReq.kaId = kaId;
      dataRowColumnPlanAndReq.month = month;
      dataRowColumnPlanAndReq.type = type;
      dataRowColumnPlanAndReq.value = value;
      dataRowColumnPlanAndReq.edit = true;

      const valueTable = {
        indexRow: item.indexRow,
        oldValue: dataRowColumnPlanAndReq.value,
        value: valueInput,
        col: item.col,
      };

      this.inputChangedEvent.emit(valueTable);
      this.cdr.markForCheck();
    }

    if (item.col.props === 'initDeal') {
      const { materialIds, value } = dataRowColumnInitDeal;
      const { fiscalQuarter, fiscalYear, kaId } =
        this.dataSource.filteredData[item.indexRowList].kaProductList[
          item.indexRow
        ].product;

      dataRowColumnInitDeal.fiscalYear = fiscalYear;
      dataRowColumnInitDeal.quarter = fiscalQuarter;
      dataRowColumnInitDeal.materialIds = materialIds;
      dataRowColumnInitDeal.kaId = kaId;
      dataRowColumnInitDeal.initDeal = value;
      dataRowColumnInitDeal.editInitDeal = true;

      const valueTable = {
        indexRow: item.indexRow,
        oldValue: dataRowColumnInitDeal.value,
        initDeal: valueInput,
        col: item.col,
      };

      this.inputChangedEvent.emit(valueTable);
      this.cdr.markForCheck();
    }

    if (item.col.props === 'deal') {
      const { materialIds, value } = dataRowColumnDeal;
      const { fiscalQuarter, fiscalYear, kaId } =
        this.dataSource.filteredData[item.indexRowList].kaProductList[
          item.indexRow
        ].product;
      dataRowColumnDeal.fiscalYear = fiscalYear;
      dataRowColumnDeal.quarter = fiscalQuarter;
      dataRowColumnDeal.materialIds = materialIds;
      dataRowColumnDeal.kaId = kaId;
      dataRowColumnDeal.value = value;
      dataRowColumnDeal.editDeal = true;

      const valueTable = {
        indexRow: item.indexRow,
        oldValue: dataRowColumnDeal.value,
        value: valueInput,
        col: item.col,
      };

      this.inputChangedEvent.emit(valueTable);
      this.cdr.markForCheck();
    }
  }
}
