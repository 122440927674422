export const emeaInformationAllocationMockup: any = {
  table: {
    headers: [
      {
        title: '#',
        props: 'columnName',
        style: { width: '180px', color: '#000' },
      },
      {
        title: 'SALES MODEL',
        props: 'salesModel',
        otherContent: 'country',
        alwaysVisible: true,
        style: { width: '80px', 'text-align': 'center' },
      },
      {
        title: 'CUSTOMER',
        props: 'customer',
        alwaysVisible: true,
        style: { width: '80px', 'text-align': 'center' },
      },
      {
        title: '2 TIER CUSTOMER',
        props: 'twoTierCustomer',
        alwaysVisible: true,
        style: { width: '80px', 'text-align': 'center' },
      },
      {
        title: 'OPEN QTY',
        props: 'openQTY',
        alwaysVisible: true,
        style: { width: '100px', 'text-align': 'center' },
      },
      {
        title: 'UNREALEASED',
        props: 'unrealeased',
        alwaysVisible: true,
        style: { width: '180px', 'text-align': 'center' },
      },
      {
        title: 'SHIPPED',
        props: 'shipped',
        alwaysVisible: true,
        style: { width: '180px', 'text-align': 'center' },
      },
      {
        title: 'WK 01',
        props: 'januarywk01',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'previous',
      },
      {
        title: 'WK 02',
        props: 'januarywk02',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'current',
      },
      {
        title: 'WK 03',
        props: 'januarywk03',
        style: { width: '90px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'WK 04',
        props: 'januarywk04',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'OPPORTUNITY',
        props: 'januaryOpportunity',
        style: { width: '50px', 'text-align': 'center' },
        weekInTime: 'januaryOpportunity',
      },
      {
        title: 'FQ4 TOTAL+SAB',
        props: 'fq4total',
        style: { width: '100px', 'text-align': 'center' },
      },
      {
        title: 'GEO COMMENTS',
        props: 'geoComments',
        style: { width: '180px', 'text-align': 'center' },
      },
      {
        title: 'SALES COMMENTS',
        props: 'salesComments',
        style: { width: '180px', 'text-align': 'center' },
      },
    ],
    rows: [
      {
        columnName: {
          value: 'One Plan',
        },
        salesModel: {
          value: '',
        },
        customer: {
          value: '',
        },
        twoTierCustomer: {
          value: '',
        },
        openQTY: {
          value: '',
        },
        unrealeased: {
          value: '',
        },
        shipped: {
          value: '',
        },
        januarywk01: {
          value: 50,
        },
        januarywk02: {
          value: 100,
        },
        januarywk03: {
          value: 150,
        },
        januarywk04: {
          value: 200,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4total: {
          value: 500,
        },
        geoComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
      {
        columnName: {
          value: 'Allocation',
        },
        salesModel: {
          value: '',
        },
        customer: {
          value: '',
        },
        twoTierCustomer: {
          value: '',
        },
        openQTY: {
          value: '',
        },
        unrealeased: {
          value: '',
        },
        shipped: {
          value: '',
        },
        januarywk01: {
          value: 0,
        },
        januarywk02: {
          value: 0,
        },
        januarywk03: {
          value: 0,
        },
        januarywk04: {
          value: 0,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: false,
        },
        fq4total: {
          value: 0,
        },
        geoComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
      {
        columnName: {
          value: 'Delta',
        },
        salesModel: {
          value: '',
        },
        customer: {
          value: '',
        },
        twoTierCustomer: {
          value: '',
        },
        openQTY: {
          value: '',
        },
        unrealeased: {
          value: '',
        },
        shipped: {
          value: '',
        },
        januarywk01: {
          value: 0,
        },
        januarywk02: {
          value: 0,
        },
        januarywk03: {
          value: 0,
        },
        januarywk04: {
          value: 0,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: false,
        },
        fq4total: {
          value: 0,
        },
        geoComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
    ],
  },
};

export const emeaInformationAllocationQuarterMockup: any = {
  table: {
    headers: [
      {
        title: '#',
        props: 'columnName',
        style: { width: '180px', color: '#000' },
        columnDefaut: true,
      },
      {
        title: 'WK 01',
        props: 'julywk01',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'previous',
      },
      {
        title: 'WK 02',
        props: 'julywk02',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'current',
      },
      {
        title: 'WK 03',
        props: 'julywk03',
        style: { width: '90px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'WK 04',
        props: 'julywk04',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'OPPORTUNITY',
        props: 'julyOpportunity',
        style: { width: '50px', 'text-align': 'center' },
        weekInTime: 'julyOpportunity',
      },
      {
        title: 'WK 05',
        props: 'augustwk05',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'previous',
      },
      {
        title: 'WK 06',
        props: 'augustwk06',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'WK 07',
        props: 'augustwk07',
        style: { width: '90px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'WK 08',
        props: 'augustwk08',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'OPPORTUNITY',
        props: 'augustOpportunity',
        style: { width: '50px', 'text-align': 'center' },
        weekInTime: 'augustOpportunity',
      },
      {
        title: 'WK 09',
        props: 'septemberwk09',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'previous',
      },
      {
        title: 'WK 10',
        props: 'septemberwk10',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'WK 11',
        props: 'septemberwk11',
        style: { width: '90px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'WK 12',
        props: 'septemberwk12',
        style: { width: '80px', 'text-align': 'center' },
        weekInTime: 'next',
      },
      {
        title: 'OPPORTUNITY',
        props: 'septemberOpportunity',
        style: { width: '50px', 'text-align': 'center' },
        weekInTime: 'septemberOpportunity',
      },
      {
        title: 'FQ4 TOTAL+SAB',
        props: 'fq4total',
        style: { width: '140px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'SIC',
        props: 'sic',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'WOI',
        props: 'woi',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'OUTLOOK',
        props: 'outlook',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'GP',
        props: 'gp',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'ORDERS',
        props: 'orders',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'SUPPLY',
        props: 'supply',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'PDP',
        props: 'pdp',
        style: { width: '80px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'ORDERS VS ALLOCATION',
        props: 'ordersVsAllocation',
        style: { width: '165px', 'text-align': 'center' },
        columnDefaut: true,
      },
      {
        title: 'ORDERS VS OUTLOOK',
        props: 'ordersVsOutlook',
        style: { width: '165px', 'text-align': 'center' },
        columnDefaut: true,
      },
    ],
    rows: [
      {
        columnName: {
          value: 'One Plan',
        },
        julywk01: {
          value: 20,
        },
        julywk02: {
          value: 100,
        },
        julywk03: {
          value: 150,
        },
        julywk04: {
          value: 200,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 50,
        },
        augustwk06: {
          value: 100,
        },
        augustwk07: {
          value: 150,
        },
        augustwk08: {
          value: 200,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 80,
        },
        septemberwk10: {
          value: 100,
        },
        septemberwk11: {
          value: 150,
        },
        septemberwk12: {
          value: 200,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4total: {
          value: 1500,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        columnName: {
          value: 'Allocation',
        },
        julywk01: {
          value: 0,
        },
        julywk02: {
          value: 0,
        },
        julywk03: {
          value: 0,
        },
        julywk04: {
          value: 0,
        },
        julyOpportunity: {
          value: 0,
          enableInput: false,
        },
        augustwk05: {
          value: 0,
        },
        augustwk06: {
          value: 0,
        },
        augustwk07: {
          value: 0,
        },
        augustwk08: {
          value: 0,
        },
        augustOpportunity: {
          value: 0,
          enableInput: false,
        },
        septemberwk09: {
          value: 0,
        },
        septemberwk10: {
          value: 0,
        },
        septemberwk11: {
          value: 0,
        },
        septemberwk12: {
          value: 0,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: false,
        },
        fq4total: {
          value: 0,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        columnName: {
          value: 'Delta',
        },
        julywk01: {
          value: 0,
        },
        julywk02: {
          value: 0,
        },
        julywk03: {
          value: 0,
        },
        julywk04: {
          value: 0,
        },
        julyOpportunity: {
          value: 0,
          enableInput: false,
        },
        augustwk05: {
          value: 0,
        },
        augustwk06: {
          value: 0,
        },
        augustwk07: {
          value: 0,
        },
        augustwk08: {
          value: 0,
        },
        augustOpportunity: {
          value: 0,
          enableInput: false,
        },
        septemberwk09: {
          value: 0,
        },
        septemberwk10: {
          value: 0,
        },
        septemberwk11: {
          value: 0,
        },
        septemberwk12: {
          value: 0,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: false,
        },
        fq4total: {
          value: 0,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
    ],
  },
};

export const tableMockup: any = {
  table: {
    headers: [
      {
        title: 'SAB',
        props: 'sab',
        enableContent: true,
        weekInTime: 'sab',
        style: { width: '100px' },
      },
      {
        title: 'TERRITORY',
        props: 'territory',
        style: { width: '125px' },
      },
      {
        title: 'WK 01',
        props: 'januarywk01',
        enableContent: false,
        weekInTime: 'previous',
        style: { width: '100px' },
      },
      {
        title: 'WK 02',
        props: 'januarywk02',
        enableContent: true,
        weekInTime: 'current',
        style: { width: '100px' },
      },
      {
        title: 'WK 03',
        props: 'januarywk03',
        enableContent: true,
        weekInTime: 'next',
        style: { width: '100px' },
      },
      {
        title: 'WK 04',
        props: 'januarywk04',
        enableContent: true,
        weekInTime: 'next',
        style: { width: '100px' },
      },
      {
        title: 'OPPORTUNITY',
        props: 'januaryOpportunity',
        enableContent: true,
        weekInTime: 'januaryOpportunity',
        style: { width: '160px' },
      },
      {
        title: 'FQ4 TOTAL+SAB',
        props: 'fq4TotalSab',
        enableContent: true,
        style: { width: '150px' },
      },
      {
        title: 'SIC',
        props: 'sic',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'WOI',
        props: 'woi',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'OUTLOOK',
        props: 'outlook',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'GP',
        props: 'gp',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'ORDERS',
        props: 'orders',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'SUPPLY',
        props: 'supply',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'PDP',
        props: 'pdp',
        enableContent: false,
        style: { width: '100px' },
      },
      {
        title: 'ORDERS VS ALLOCATION',
        props: 'ordersVsAllocation',
        enableContent: false,
        style: { width: '225px' },
      },
      {
        title: 'ORDERS VS OUTLOOK',
        props: 'ordersVsOutlook',
        enableContent: false,
        style: { width: '100px' },
      },
    ],
    rows: [
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 1,
          value: 'Benelux',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 2,
          value: 'CEE',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 3,
          value: 'Central Asia',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 4,
          value: 'France',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 5,
          value: 'Germany',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 6,
          value: 'Italy',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 7,
          value: 'Iberia',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 8,
          value: 'Jordan',
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        januaryOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
    ],
  },
};

export const tableQuarterMockup: any = {
  table: {
    headers: [
      {
        title: 'SAB',
        props: 'sab',
        otherContent: 'sab',
        enableContent: true,
        columnDefaut: true,
      },
      {
        title: 'TERRITORY',
        props: 'territory',
        otherContent: 'territory',
        columnDefaut: true,
      },
      {
        title: 'WK 01',
        props: 'julywk01',
        otherContent: 'july',
        enableContent: false,
        weekInTime: 'previous',
      },
      {
        title: 'WK 02',
        props: 'julywk02',
        otherContent: 'july',
        enableContent: true,
        weekInTime: 'current',
      },
      {
        title: 'WK 03',
        props: 'julywk03',
        otherContent: 'july',
        enableContent: true,
        weekInTime: 'next',
      },
      {
        title: 'WK 04',
        props: 'julywk04',
        otherContent: 'july',
        enableContent: true,
        weekInTime: 'next',
      },
      {
        title: 'OPPORTUNITY',
        props: 'julyOpportunity',
        otherContent: 'julyOpportunity',
        enableContent: true,
        weekInTime: 'julyOpportunity',
      },
      {
        title: 'WK 05',
        props: 'augustwk05',
        otherContent: 'august',
        enableContent: false,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'WK 06',
        props: 'augustwk06',
        otherContent: 'august',
        enableContent: true,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'WK 07',
        props: 'augustwk07',
        otherContent: 'august',
        enableContent: true,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'WK 08',
        props: 'augustwk08',
        otherContent: 'august',
        enableContent: true,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'OPPORTUNITY',
        props: 'augustOpportunity',
        otherContent: 'augustOpportunity',
        enableContent: true,
        weekInTime: 'augustOpportunity',
      },
      {
        title: 'WK 09',
        props: 'septemberwk09',
        otherContent: 'september',
        enableContent: false,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'WK 10',
        props: 'septemberwk10',
        otherContent: 'september',
        enableContent: true,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'WK 11',
        props: 'septemberwk11',
        otherContent: 'september',
        enableContent: true,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'WK 12',
        props: 'septemberwk12',
        otherContent: 'september',
        enableContent: true,
        weekInTime: 'next',
        nextMonth: true,
      },
      {
        title: 'OPPORTUNITY',
        props: 'septemberOpportunity',
        otherContent: 'septemberOpportunity',
        enableContent: true,
        weekInTime: 'septemberOpportunity',
      },
      {
        title: 'FQ4 TOTAL+SAB',
        props: 'fq4TotalSab',
        otherContent: 'fq4TotalSab',
        enableContent: true,
        columnDefaut: true,
      },
      {
        title: 'SIC',
        props: 'sic',
        otherContent: 'sic',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'WOI',
        props: 'woi',
        otherContent: 'woi',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'OUTLOOK',
        props: 'outlook',
        otherContent: 'outlook',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'GP',
        props: 'gp',
        otherContent: 'gp',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'ORDERS',
        props: 'orders',
        otherContent: 'orders',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'SUPPLY',
        props: 'supply',
        otherContent: 'supply',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'PDP',
        props: 'pdp',
        otherContent: 'pdp',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'ORDERS VS ALLOCATION',
        props: 'ordersVsAllocation',
        otherContent: 'ordersVsAllocation',
        enableContent: false,
        columnDefaut: true,
      },
      {
        title: 'ORDERS VS OUTLOOK',
        props: 'ordersVsOutlook',
        otherContent: 'ordersVsOutlook',
        enableContent: false,
        columnDefaut: true,
      },
    ],
    rows: [
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 1,
          value: 'Benelux',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 2,
          value: 'CEE',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 3,
          value: 'Central Asia',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 4,
          value: 'France',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 5,
          value: 'Germany',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 6,
          value: 'Italy',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 7,
          value: 'Iberia',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
      {
        sab: {
          value: 0,
          enableInput: true,
        },
        territory: {
          id: 8,
          value: 'Jordan',
        },
        julywk01: {
          value: 0,
          enableInput: true,
        },
        julywk02: {
          value: 0,
          enableInput: true,
        },
        julywk03: {
          value: 0,
          enableInput: true,
        },
        julywk04: {
          value: 0,
          enableInput: true,
        },
        julyOpportunity: {
          value: 0,
          enableInput: true,
        },
        augustwk05: {
          value: 0,
          enableInput: true,
        },
        augustwk06: {
          value: 0,
          enableInput: true,
        },
        augustwk07: {
          value: 0,
          enableInput: true,
        },
        augustwk08: {
          value: 0,
          enableInput: true,
        },
        augustOpportunity: {
          value: 0,
          enableInput: true,
        },
        septemberwk09: {
          value: 0,
          enableInput: true,
        },
        septemberwk10: {
          value: 0,
          enableInput: true,
        },
        septemberwk11: {
          value: 0,
          enableInput: true,
        },
        septemberwk12: {
          value: 0,
          enableInput: true,
        },
        septemberOpportunity: {
          value: 0,
          enableInput: true,
        },
        fq4TotalSab: {
          value: 0,
          enableInput: false,
        },
        sic: {
          value: '-',
          enableInput: false,
        },
        woi: {
          value: '-',
          enableInput: false,
        },
        outlook: {
          value: '-',
          enableInput: false,
        },
        gp: {
          value: '-',
          enableInput: false,
        },
        orders: {
          value: '-',
          enableInput: false,
        },
        supply: {
          value: '-',
          enableInput: false,
        },
        pdp: {
          value: '-',
          enableInput: false,
        },
        ordersVsAllocation: {
          value: '',
          enableInput: false,
        },
        ordersVsOutlook: {
          value: '',
          enableInput: false,
        },
      },
    ],
  },
};
