import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  rolloverMockup,
  cardsColor,
  lastAllertsMockup,
  logHistoryMockup,
  notificationsMockup,
} from '../../dcm-mockups';
import { DcmRollover } from 'src/app/interfaces/dcm-rollover.interface';
import { CardData } from 'src/app/interfaces/card-color.interface';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/services/generic/crud.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Pagination from 'src/app/interfaces/pagination.interface';
import { DcmAlert } from 'src/app/interfaces/dcm-alert.interface';
interface RolloverData {
  headers: { props: string }[];
  rows: any[];
}

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
})
export class DashboardsComponent implements OnInit {
  actualWeek: string;
  crudService: CrudService<any>;
  pagination: Pagination;
  paginationAlert: Pagination;

  displayedColumns: any;
  displayedColumnsLogHistory: any;

  dataSource = new MatTableDataSource<DcmRollover>();

  dataSourceFilterTable = new MatTableDataSource<DcmAlert>();
  dataSourceLogHistory = new MatTableDataSource<DcmRollover>();

  dataSourceLogHistoryActual = new MatTableDataSource<DcmRollover>();
  dataSourceLogHistoryAllocation = new MatTableDataSource<DcmRollover>();
  dataSourceLogHistoryRollover = new MatTableDataSource<DcmRollover>();
  dataSourceLogHistoryOrderBook = new MatTableDataSource<DcmRollover>();
  dataSourceLogHistoryOnePlan = new MatTableDataSource<DcmRollover>();
  dataSourceLogHistoryMls = new MatTableDataSource<DcmRollover>();

  dataSourceNotifications = new MatTableDataSource();

  rolloverData: RolloverData = rolloverMockup;
  cardDataArr: CardData[];
  logHistoryData: RolloverData = logHistoryMockup;

  asyncTabs!: any[];
  showFiller = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);
  isSidenavOpened: boolean = false;

  noRecordFoundRolloverReport: boolean = false;

  constructor(
    private router: Router,
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private http: HttpClient
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.actualWeek = '';
    this.crudService = new CrudService<any>(this.http, 'dcm-notifications');
    this.cardDataArr = [];
    this.pagination = { page: 1, limit: 50 };
    this.paginationAlert = { page: 1, limit: 10000 };
  }

  ngOnInit(): void {
    this.displayedColumns = [];
    this.dataSource.data = [];
    this.dataSourceFilterTable.data = [];
    this.displayedColumnsLogHistory = this.logHistoryData.headers;
    this.dataSourceLogHistory.data = this.logHistoryData.rows;

    this.tabs();

    const promises = [
      this.getSupplyAllocation(), 
      this.getLogHistory(this.paginationAlert), 
      this.getCurrentQuarter(), 
      this.getRollover(this.paginationAlert),
      this.getLastAlert(this.pagination),
      this.getNotificationsSidebar()
    ];

    this.hideSpinnerAfterAll(promises);
  }
  
  async getCurrentQuarter(): Promise<void> {
    this.spinner.show()
  
      return new Promise((resolve, reject) => {
        this.crudService.getEntity('current-fiscal-quarter-actual').subscribe((response: any) => {
          const week: string = response[0].week_previous.slice(-2);
          this.actualWeek = week;
          resolve();
        },  
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        })
      })
  }

  async getRollover(page: any): Promise<void> {
    this.spinner.show()

    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`rollover-report`, { limit: page.limit, page: page.page }).subscribe((response: any) => {   
        const headers = response.headers.map((header: any) => {
          const { title, prop, hideIconExpand, alwaysVisible } = header;
          return {
              title: title,
              props: prop,
              hideIconExpand: hideIconExpand,
              alwaysVisible: alwaysVisible
          };
        });

        this.displayedColumns = headers;

        if (response.rows.length === 0) {
          this.dataSource.data = [{
            product: 'true'
          }];
        } else {
          this.dataSource.data = response.rows;
        }

        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getSupplyAllocation(): Promise<void> {
    this.spinner.show()

    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`supply-allocation`).subscribe((response: any) => {
        this.cardDataArr = response;
        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getLogHistory(page: any): Promise<void> {
    this.spinner.show()
    let columnsLogHistory: any = []
    let logHistoryActual: any = []
    let logHistoryAllocation: any = []
    let logHistoryRollover: any = []
    let logHistoryOrderBook: any = []
    let logHistoryOnePlan: any = []
    let logHistoryMls: any = []
  

    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`audit-logs`, { limit: page.limit, page: page.page }).subscribe((response: any) => {
        const updatedData = {
          headers: this.renamePropKey(response.headers),
          rows: response.rows
        };

        updatedData.headers.forEach((data: any) => {
          if (data.title === 'Ingestion Name' || data.title === 'Last Date' || data.title === 'Log Details') {
            columnsLogHistory.push(data)
          }
        })

        updatedData.rows.forEach((data: any) => {
          if (data.category === 'ka actual') {
            logHistoryActual.push(data)
          }
          if (data.category === 'allocation') {
            logHistoryAllocation.push(data)
          }
          if (data.category === 'ka rollover') {
            logHistoryRollover.push(data)
          }
          if (data.category === 'order book') {
            logHistoryOrderBook.push(data)
          }
          if (data.category === 'one plan') {
            logHistoryOnePlan.push(data)
          }
          if (data.category === 'retail') {
            logHistoryMls.push(data)
          }
        })

        this.validadeRecordFound(logHistoryActual);   
        this.validadeRecordFound(logHistoryAllocation);   
        this.validadeRecordFound(logHistoryRollover);   
        this.validadeRecordFound(logHistoryOrderBook);   
        this.validadeRecordFound(logHistoryOnePlan);   
        this.validadeRecordFound(logHistoryMls);      

        logHistoryActual = this.validadeNotDate(logHistoryActual);
        logHistoryAllocation = this.validadeNotDate(logHistoryAllocation);
        logHistoryRollover = this.validadeNotDate(logHistoryRollover);
        logHistoryOrderBook = this.validadeNotDate(logHistoryOrderBook);
        logHistoryOnePlan = this.validadeNotDate(logHistoryOnePlan);
        logHistoryMls = this.validadeNotDate(logHistoryMls);

        this.displayedColumnsLogHistory = columnsLogHistory;
        this.dataSourceLogHistory.data = updatedData.rows;

        this.dataSourceLogHistoryActual.data = logHistoryActual;
        this.dataSourceLogHistoryAllocation.data = logHistoryAllocation;
        this.dataSourceLogHistoryRollover.data = logHistoryRollover;
        this.dataSourceLogHistoryOrderBook.data = logHistoryOrderBook;
        this.dataSourceLogHistoryOnePlan.data = logHistoryOnePlan;
        this.dataSourceLogHistoryMls.data = logHistoryMls;
        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  validadeRecordFound(log: any) {
    if (log.length === 0) {
      log.push({
        ingestion_name: 'No Record Found',
        lastDate: 'No Record Found',
        logDetails: 'No Record Found',
        route: 'No Record Found',
        status: 'No Record Found'
      })
    }
  }

 validadeNotDate(
  array: any[], 
  filterVale: string = '31/12/69 at 20:00', 
  newValue: string = 'No Execution Date'
): any[] {
  const filterObj = array.filter(obj => obj.lastDate === filterVale);

  filterObj.forEach(obj => {
    obj.lastDate = newValue;
  });
  const newArray = array.filter(obj => obj.lastDate !== filterVale);

  return newArray
}

  async getLastAlert(page: any): Promise<void> {
    this.spinner.show()
    
    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`dcm-notifications`, { limit: page.limit, page: page.page }).subscribe((response: any) => {
        if (response.notifications.length > 0) {
          this.dataSourceFilterTable = response;
        }
        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getNotificationsSidebar(): Promise<void> {
    this.spinner.show()
    
    return new Promise((resolve, reject) => {
      this.crudService.getEntity('dcmFeedNotifications').subscribe((response: any) => {
        if (response.notifications.length > 0) {
          this.dataSourceNotifications = new MatTableDataSource(response.notifications.slice(0, 10));
        }
        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getUpdateLastAlert(page: any): Promise<void> {
    this.spinner.show()

    return new Promise((resolve, reject) => {
      this.crudService.postEntity(`update-data-reduction-status`, page).subscribe((response: any) => {
        if (response.message) {     
          this.toastr.success('Successfully!', 'Data Reduction Done');
        }

        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  renamePropKey(headers: any[]): any[] {
    return headers.map(({ title, prop }) => ({
      title,
      props: prop,
    }));
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  tabs() {
    // fazer o loop e adicionar de forma dinamica
    return (this.asyncTabs = [
      {
        label: 'Actual',
        dataSource: this.dataSourceLogHistoryActual,
        displayedColumns: this.displayedColumnsLogHistory,
      },
      {
        label: 'Allocation',
        dataSource: this.dataSourceLogHistoryAllocation,
        displayedColumns: this.displayedColumnsLogHistory,
      },
      {
        label: 'Rollover',
        dataSource: this.dataSourceLogHistoryRollover,
        displayedColumns: this.displayedColumnsLogHistory,
      },
      {
        label: 'Order Book',
        dataSource: this.dataSourceLogHistoryOrderBook,
        displayedColumns: this.displayedColumnsLogHistory,
      },
      {
        label: 'One Plan',
        dataSource: this.dataSourceLogHistoryOnePlan,
        displayedColumns: this.displayedColumnsLogHistory,
      },
      {
        label: 'Retail',
        dataSource: this.dataSourceLogHistoryMls,
        displayedColumns: this.displayedColumnsLogHistory,
      },
    ]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase()

    if (this.dataSource.filteredData.length === 0) {
      this.noRecordFoundRolloverReport = true;
    } else {
      this.noRecordFoundRolloverReport = false;
    }

    if (this.dataSourceFilterTable.paginator) {
      this.dataSourceFilterTable.paginator.firstPage();
    }
  }

  updateNotifications(data: string) {
    if (data === 'dataReduction') {
      const promises = [this.getUpdateLastAlert(this.pagination), this.getLastAlert(this.pagination), this.getNotificationsSidebar()];
      this.hideSpinnerAfterAll(promises)
    }
  }

  updateReadNotifications(data: string) {
    if (data === 'readed') {
      const promises = [this.getLastAlert(this.pagination), this.getNotificationsSidebar()];
      this.hideSpinnerAfterAll(promises)
    }
  }

  onChangePaginator(paginated: any, table: string) {
    if (table === 'table-last-alert') {
      const promises = [this.getLastAlert(paginated)]
      this.hideSpinnerAfterAll(promises)
    }
  }
  

  backButton() {
    this.router.navigate(['home/dcm']);
  }

  viewAll() {
    this.router.navigate(['home/dcm/dashboards/notifications']);
  }
}
