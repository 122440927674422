import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { ClustedColumnChartComponent } from './clusted-column-chart.component';

@NgModule({
  declarations: [ClustedColumnChartComponent],
  exports: [ClustedColumnChartComponent],
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
})
export class ClustedColumnChartModule {}
