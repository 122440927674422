import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mkn-sell-in',
  templateUrl: './mkn-sell-in.component.html',
  styleUrls: ['./mkn-sell-in.component.scss'],
})
export class MknSellInComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
