import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  constructor() { }

  formatNumberWithCommas(numberString: string): string {
    numberString = numberString.replace(/,/g, '');
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formattingValuesInputWithComma(event: Event, valueInput: string, comma: any): string {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value.trim();

    const parts = inputValue.split('+');
    const formattedParts = parts.map(part => {
      const trimmedPart = part.trim();
      if (!isNaN(Number(trimmedPart))) {
        return this.formatNumberWithCommas(trimmedPart);
      }
      return trimmedPart;
    });

    let formattedValue = formattedParts.join('+');

    if (inputValue.startsWith(`${comma}=`)) {
      formattedValue = '=';
    }

    return formattedValue;
  }
}
