import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { CustomHttpParamEncoder } from 'src/app/helpers/customHttpParamEncoder';
import GetEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CrudService<T> {
  protected readonly apiUrl = `${this.baseUrl}${this.entityName}`;

  constructor(
    protected readonly http: HttpClient,
    protected readonly entityName: String,
    protected readonly baseUrl: String = environment.api.gcaBackEnd
  ) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  getEntity(entity?: string, query?: any) {
    const params = this.convertObjToHttpParams(query);
    return this.http.get(`${this.baseUrl}${entity}`, { params });
  }

  getDataFilters(entities: Array<GetEntities>) {
    const requests: any[] = [];
    entities.forEach(item => {
      const params = this.convertObjToHttpParams(item.query);
      requests.push(this.http.get(`${this.baseUrl}${item.entity}`, { params }));
    });
    return forkJoin(requests);
  }

  getPaged(args?: any, query?: any) {
    const params = this.convertObjToHttpParams(query);
    const pagination = args || { size: 10, page: 0 };
    return this.http.get(
      `${this.apiUrl}/paged?size=${pagination.size}&page=${pagination.page}`,
      { params }
    );
  }

  getSummaryPaged(args?: any, query?: any, paramUrl?: string) {
    const params = this.convertObjToHttpParams(query);
    const pagination = args || { size: 10, page: 0 };
    const parameterUrl = paramUrl ? `/${paramUrl}` : '';
    return this.http.get(
      `${this.apiUrl}${parameterUrl}/summaryPaged?size=${pagination.size}&page=${pagination.page}`,
      { params }
    );
  }

  getGenericObjectFromUrl(paramUrl: string, query?: any) {
    const params = this.convertObjToHttpParams(query);
    return this.http.get(`${this.baseUrl}${paramUrl}`, { params });
  }

  createEntity(body: T): Observable<T> {
    return this.http.post<T>(this.apiUrl, body);
  }

  postEntity(paramUrl: string, body: T): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}${paramUrl}`, body);
  }

  putEntity(paramUrl: string, body: T): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}${paramUrl}`, body);
  }

  fetchEntity(id: number): Observable<T> {
    const url = this.entityUrl(id);
    return this.http.get<T>(url);
  }

  updateEntity(id: number, body: T): Observable<T> {
    const url = this.entityUrl(id);
    return this.http.put<T>(url, body);
  }

  deleteEntity(id: number): Observable<T> {
    const url = this.entityUrl(id.toString());
    return this.http.delete<T>(url);
  }

  protected entityUrl(id: number | string): string {
    return [this.apiUrl, id].join('/');
  }

  private convertObjToHttpParams(args?: any) {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (args) {
      Object.keys(args).forEach(key => {
        params = params.append(key, args[key]);
      });
    }
    return params;
  }

  updateKa(data: any): Observable<T> {
    return this.http.put<T>(this.apiUrl, data);
  }
}
