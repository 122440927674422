<div class="table-container">
  <div
    class="content-table"
    *ngIf="displayedDefWithAlwaysVisibleFalses.length > 0">
    <!-- primeira parte da tabela - fixa -->
    <table class="fixed-Columns" id="table" #firstTable>
      <thead>
        <tr>
          <th
            *ngFor="
              let col of displayedDefWithAlwaysVisibleFalses.slice(
                0,
                numFixedColumns
              )
            ">
            <mat-card>
              <span>{{ col.title }} </span>

              <ng-container *ngIf="col.typeShowEvent">
                <div
                  *ngIf="col.props === col.otherContent && !col.hideIconExpand"
                  class="cell-icon-arrow">
                  <mat-icon
                    *ngIf="!col.expanded"
                    (click)="toggleColumnTable(col, 'show')">
                    {{ 'add_circle_outline' }}
                  </mat-icon>

                  <mat-icon
                    *ngIf="col.expanded"
                    id="table"
                    (click)="toggleColumnTable(col, 'hide')">
                    {{ 'remove_circle_outline' }}
                  </mat-icon>
                </div>
              </ng-container>
            </mat-card>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container
          *ngFor="let element of dataSource.filteredData; let rowIndex = index">
          <ng-container
            *ngFor="
              let kaProduct of element?.kaProductList;
              let kaRowIndex = index
            ">
            <tr
              class="row-color"
              [attr.data-row-index]="
                rowIndex + '_' + kaProduct['ka']?.secondValue
              "
              [attr.data-ka-row-index]="
                kaRowIndex + '_' + kaProduct['ka']?.secondValue
              ">
              <td
                *ngFor="
                  let col of displayedDefWithAlwaysVisibleFalses.slice(
                    0,
                    numFixedColumns
                  )
                "
                class="column"
                id="{{ getAddRowId(kaRowIndex + kaProduct['ka'].secondValue) }}"
                (mouseenter)="mouseEnter(kaRowIndex, kaProduct)"
                (mouseleave)="mouseLeave(kaRowIndex, kaProduct)"
                [class.negative-value]="kaProduct[col.props]?.value < 0"
                [class.centrelize-items]="col.props !== 'product'"
                [class.left-items]="col.props === 'ka'"
                [class.centrelize-items-product]="col.props === 'product'">
                <app-value-currency
                  *ngIf="!kaProduct[col.props]?.enableInput"
                  [value]="
                    kaProduct[col.props]?.value === ''
                      ? kaProduct[col.props]?.secondValue
                      : kaProduct[col.props]?.value
                  ">
                </app-value-currency>
              </td>
            </tr>
          </ng-container>

          <tr>
            <td
              *ngFor="
                let col of displayedDefWithAlwaysVisibleFalses.slice(
                  0,
                  numFixedColumns
                )
              "
              class="subtotal">
              <p class="subtotal-value" *ngIf="col.props === 'ka'">SUBTOTAL</p>
              <span
                *ngIf="
                  col.props !== 'product' &&
                  col.props !== 'ka' &&
                  col.props !== 'origin' &&
                  col.props !== 'salesModel'
                ">
                <app-value-currency
                  [value]="
                    element.kaSubtotal[col.props]?.value === ''
                      ? '-'
                      : element.kaSubtotal[col.props]?.value
                  ">
                </app-value-currency>
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>

      <tfoot class="fixed-columns">
        <tr>
          <td
            *ngFor="
              let col of displayedDefWithAlwaysVisibleFalses.slice(
                0,
                numFixedColumns
              )
            "
            class="table-row-total">
            <p class="total-value" *ngIf="col.props === 'ka'">TOTAL</p>
            <span
              class="values-total"
              [class.negative-value]="col.totalAddedValue < 0">
              <app-value-currency
                [value]="
                  col.totalAddedValue === '' ? '-' : col.totalAddedValue
                "></app-value-currency>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>

    <!-- segunda parte da tabela - não fixo, contendo scroll -->
    <table class="second-table" id="table" #secondTable>
      <thead>
        <tr>
          <th
            *ngFor="
              let col of displayedDefWithAlwaysVisibleFalses.slice(
                numFixedColumns
              )
            ">
            <mat-card
              [ngClass]="{
                'req-plan-columns':
                  col.title.includes('REQ VS ALLOC') ||
                  col.title.includes('PLAN VS ALLOC'),
                'gap-columns': col.title.includes('GAP DEAL')
              }">
              <span
                *ngIf="
                  !col.title.includes('GAP DEAL') &&
                  !col.title.includes('REQ VS ALLOC') &&
                  !col.title.includes('PLAN VS ALLOC')
                "
                >{{ col.title }}
              </span>

              <app-header-breaker
                *ngIf="
                  col.title.includes('GAP DEAL') ||
                  col.title.includes('REQ VS ALLOC') ||
                  col.title.includes('PLAN VS ALLOC')
                "
                [header]="col.title"></app-header-breaker>

              <ng-container *ngIf="col.typeShowEvent">
                <div
                  *ngIf="col.props === col.otherContent && !col.hideIconExpand"
                  class="cell-icon-arrow">
                  <mat-icon
                    *ngIf="!col.expanded"
                    (click)="toggleColumnTable(col, 'show')">
                    {{ 'add_circle_outline' }}
                  </mat-icon>

                  <mat-icon
                    *ngIf="col.expanded"
                    id="table"
                    (click)="toggleColumnTable(col, 'hide')">
                    {{ 'remove_circle_outline' }}
                  </mat-icon>
                </div>
              </ng-container>
            </mat-card>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container
          *ngFor="
            let element of dataSource.filteredData;
            let indexRowList = index
          ">
          <ng-container
            *ngFor="
              let kaProduct of element?.kaProductList;
              let indexRow = index
            ">
            <ng-container>
              <tr
                class="row-color"
                [attr.data-row-index]="
                  indexRowList + '_' + kaProduct['ka']?.secondValue
                "
                [attr.data-ka-row-index]="
                  indexRow + '_' + kaProduct['ka']?.secondValue
                ">
                <td
                  id="{{ getAddRowId(indexRow + kaProduct['ka'].secondValue) }}"
                  (mouseenter)="mouseEnter(indexRow, kaProduct)"
                  (mouseleave)="mouseLeave(indexRow, kaProduct)"
                  *ngFor="
                    let col of displayedDefWithAlwaysVisibleFalses.slice(
                      numFixedColumns
                    )
                  "
                  [attr.data-ka-row-blue]="
                    col.expanded
                      ? indexRow + '_' + kaProduct['ka']?.secondValue
                      : null
                  "
                  [attr.data-ka-titles]="
                    col.props === 'deal' ||
                    col.props === 'demand' ||
                    col.props === 'totalPlan' ||
                    col.props === 'totalRequest'
                      ? 'true'
                      : null
                  "
                  class="column"
                  [class.column-color]="kaProduct[col.props]?.columnColor"
                  [class.negative-value]="kaProduct[col.props]?.value < 0"
                  [ngClass]="kaProduct['ka'].secondValue"
                  [class.centrelize-items]="col.props !== 'product'"
                  [class.centrelize-items-product]="col.props === 'product'"
                  [class.materialId]="kaProduct[col.props]?.invisible"
                  [attr.rowspan]="
                    kaProduct[col.props]?.rowSpan || kaProduct.rowSpan
                      ? kaProduct.quantity
                      : 1
                  "
                  [attr.colspan]="
                    kaProduct[col.props]?.materialId === 1 ? 1 : null
                  ">
                  <app-value-currency
                    *ngIf="!kaProduct[col.props]?.enableInput"
                    [value]="
                      kaProduct[col.props]?.value === ''
                        ? ''
                        : kaProduct[col.props]?.value
                    ">
                  </app-value-currency>
                  <div
                    *ngIf="
                      !kaProduct[col.props]?.invisible &&
                      kaProduct[col.props]?.enableInput
                    "
                    class="input-table">
                    <input
                      id="{{
                        addRowId(
                          col.props,
                          indexRow,
                          kaProduct['ka']?.secondValue,
                          kaProduct[col.props]?.id
                        )
                      }}"
                      *ngIf="
                        !kaProduct[col.props].planMonth &&
                        !kaProduct[col.props].requestMonth
                      "
                      placeholder="0"
                      type="text"
                      maxlength="21"
                      class="custom-input"
                      (keyup)="updateValues(kaProduct, element, $event, col)"
                      (click)="handleInputClick($event)"
                      (keydown)="handleKeyDownEvent(
                        $event, col, indexRow, kaProduct[col.props],
                        indexRowList, kaProduct['ka']?.secondValue, kaProduct[col.props]?.id, kaProduct.deal)"
                      (input)="onChangeEvent({indexRow, indexRowList, columnName: kaProduct.deal, row:
                        element, col }, $event)"
                      autocomplete="off"
                      (blur)="handleInputBlur($event, kaProduct, col)"
                      matInput
                      [disabled]="this.disableTableInput"
                      [class.disabled]="this.disableTableInput"
                      [value]="
                        kaProduct[col.props].value.toString()
                          | thousandSeparator
                      " />
                    <input
                      [id]="
                        col.props +
                        '_' +
                        indexRow +
                        '_' +
                        kaProduct['ka']?.secondValue
                      "
                      *ngIf="
                        kaProduct[col.props].planMonth &&
                        !kaProduct[col.props].requestMonth
                      "
                      placeholder="0"
                      type="text"
                      maxlength="21"
                      class="custom-input"
                      [attr.data-identifier]="kaProduct[col.props].identifier"
                      (keyup)="updateTotalPlan(kaProduct, element, $event, col)"
                      (click)="handleInputClick($event)"
                      (keydown)="handleKeyDownEvent(
                        $event, col, indexRow, kaProduct[col.props],
                        indexRowList, kaProduct['ka']?.secondValue, kaProduct[col.props]?.id, kaProduct.deal)"
                      (input)="onChangeEvent({indexRow, indexRowList, columnName: kaProduct[col.props].identifier
                        , row:
                        element, col }, $event)"
                      autocomplete="off"
                      matInput
                      (blur)="handleInputBlur($event, kaProduct, col)"
                      [value]="
                        kaProduct[col.props].value.toString()
                          | thousandSeparator
                      " />
                    <input
                      [id]="
                        col.props +
                        '_' +
                        indexRow +
                        '_' +
                        kaProduct['ka']?.secondValue
                      "
                      *ngIf="
                        kaProduct[col.props].requestMonth &&
                        !kaProduct[col.props].planMonth
                      "
                      placeholder="0"
                      type="text"
                      [attr.data-identifier]="kaProduct[col.props].identifier"
                      (keyup)="
                        updateTotalRequest(kaProduct, element, $event, col)
                      "
                       (blur)="handleInputBlur($event, kaProduct, col)"
                    (click)="handleInputClick($event)"
                    class="custom-input"
                    (keydown)="handleKeyDownEvent(
                      $event, col, indexRow, kaProduct[col.props],
                      indexRowList, kaProduct['ka']?.secondValue, kaProduct[col.props]?.id, kaProduct.deal)"
                      (input)="onChangeEvent({indexRow, indexRowList, columnName: kaProduct[col.props].identifier, row:
                        element, col }, $event)"
                      autocomplete="off"
                      matInput
                      maxlength="21"
                      [value]="
                        kaProduct[col.props].value.toString()
                          | thousandSeparator
                      "/>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>

          <tr>
            <td
              *ngFor="
                let col of displayedDefWithAlwaysVisibleFalses.slice(
                  numFixedColumns
                )
              "
              class="subtotal">
              <p class="subtotal-value" *ngIf="col.props === 'ka'">SUBTOTAL</p>
              <span
                *ngIf="
                  col.props !== 'product' &&
                  col.props !== 'ka' &&
                  col.props !== 'origin' &&
                  col.props !== 'salesModel'
                ">
                <app-value-currency
                  [class.negative-value]="
                    element.kaSubtotal[col.props]?.value < 0
                  "
                  [value]="
                    element.kaSubtotal[col.props]?.value === ''
                      ? '-'
                      : element.kaSubtotal[col.props]?.value
                  ">
                </app-value-currency>
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>

      <tfoot class="fixed-columns">
        <tr>
          <td
            *ngFor="
              let col of displayedDefWithAlwaysVisibleFalses.slice(
                numFixedColumns
              )
            "
            class="table-row-total">
            <p class="subtotal-value" *ngIf="col.props === 'ka'">TOTAL</p>
            <span
              class="values-total"
              [class.negative-value]="col.totalAddedValue < 0">
              <app-value-currency
                [value]="
                  col.totalAddedValue === '' ? '-' : col.totalAddedValue
                "></app-value-currency>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
