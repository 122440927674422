import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carriers-info',
  templateUrl: './carriers-info.component.html',
  styleUrls: ['./carriers-info.component.scss'],
})
export class CarriersInfoComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
