import { HttpClient } from '@angular/common/http';
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Observable, ReplaySubject, Subject, empty } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { OrderAdmin } from 'src/app/interfaces/orderAdmin.interface';
import { SalesManager } from 'src/app/interfaces/salesManager.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { User } from 'src/app/interfaces/user.interface';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { Channel } from 'src/app/interfaces/channel.interface';
import { taxesApplicable } from 'src/app/interfaces/taxesApplicable.interface';
import STATUS from 'src/assets/constants/status';
import { CustomerShort } from 'src/app/interfaces/customer-short.interface';
import { Customers } from 'src/app/interfaces/customers.interface';
import { CardInterface } from 'src/app/components/cards/card.interface';
import { MatSelect } from '@angular/material/select';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { MatOption } from '@angular/material/core';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer-short.component.html',
  styleUrls: ['./customer-short.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class CustomerShortComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogAssociation', { static: true }) dialogAssociation:
    | TemplateRef<any>
    | any;
  searchInputs!: InputBase<string>[];
  searchInputsList!: any[];
  searchAssociated: any = 'not';
  displayedColumns: any;
  resultCustomerShort!: ResultInterface;
  resultCustomerPage!: number;
  resultCustomerShortExport!: ResultInterface;
  resultCustomerShortExportRows!: any;

  noResultCustomerShort: boolean = false;
  noResultCustomerList: boolean = false;

  resultCustomerList!: ResultInterface;
  resultCustomerListPage!: number;
  resultCustomerListExport!: ResultInterface;
  resultCustomerListExportRows!: any;

  allocateJsonExport: any = [];

  customerForm: FormGroup;
  filterTable: any = [
    {
      label: 'Not associated',
      value: 'not',
    },
    {
      label: 'Associated',
      value: 'true',
    },
    {
      label: 'All',
      value: 'false',
    },
  ];
  isModeTableAccordion: boolean = true;
  isEdit: any = false;
  geosEntity: Geo[] = [];
  countriesEntity: Country[] = [];
  statusEntity: any[] = [
    { name: 'Active', value: true },
    { name: 'Inactive', value: false },
  ];
  hasPermissionCreate!: boolean;
  salesManagersEntity: any[] = [];
  salesManagerEntitiesByCountry: any[] = [];
  orderAdminsEntity: any[] = [];
  customerShortCrudService: CrudService<CustomerShort>;
  customerShortCrudServiceList: CrudService<CustomerShort>;
  customersCrudService: CrudService<Customers>;
  userCrudService: CrudService<User>;
  pagination: Pagination;
  filter: any = {
    sort: 'customerShort',
    pageName: PAGE_NAME_RESOURCE.customerShort,
  };
  filterCustomerShort: any;
  filterCustomerList: any;
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  isValid: boolean = true;
  tooltipMessage: any;

  customerShortTable: any;

  nameFirstSelectedSalesManager: string = '';
  messageError = '';
  customerNamesEntity: any[] = [];
  customerNameEntitiesFiltered: any[] = [];
  customerNumberEntities: any[] = [];
  customerShortsEntity: any[] = [];
  taxesApplicablesEntity: any[] = [];
  channelsEntity: any[] = [];
  orderAdminIdEntities: any[] = [];
  orderAdminCrudService: any;
  channelCrudService: CrudService<Channel>;
  buttonSaveDisabled = false;
  taxesApplicableCrudService: CrudService<taxesApplicable>;
  filteredOptionsOrderAdmin!: Observable<any[]>;
  customersMultiSelectSearch: any;
  currentUser!: any;
  tabs: { [key: string]: number } = {
    'CUSTOMER SHORT': 0,
    'CUSTOMER LIST': 1,
  };
  selectedTabIndex = 0;
  isModeTableOrCardsHide: boolean = true;
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.customerShort },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.customerShort },
    },
    {
      entity: 'customers',
      query: {
        sort: 'customer_name',
        pageName: PAGE_NAME_RESOURCE.customerShort,
      },
    },
    {
      entity: 'customer-short',
      query: {
        sort: 'customer_short',
        pageName: PAGE_NAME_RESOURCE.customerShort,
      },
    },
    {
      entity: 'user',
      query: {
        order_admin: true,
        status: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.customerShort,
      },
    },
    {
      entity: 'user',
      query: {
        sales_manager: true,
        status: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.customerShort,
      },
    },
    {
      entity: 'channel',
      query: { sort: 'channel', pageName: PAGE_NAME_RESOURCE.customerShort },
    },
    {
      entity: 'taxesApplicable',
      query: { sort: 'tax', pageName: PAGE_NAME_RESOURCE.customerShort },
    },
  ];

  filterTableSelected: any = 'not';

  messageModalAssociation!: string;
  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private excelSheetsService: ExcelSheetsService,
    private authService: AuthService,
    private sharedInfoService: SharedinfoService,
    private route: ActivatedRoute
  ) {
    this.pagination = { page: 0, size: 10 };
    this.customerShortCrudService = new CrudService<CustomerShort>(
      this.http,
      'customer-short'
    );
    this.customerShortCrudServiceList = new CrudService<CustomerShort>(
      this.http,
      'customers'
    );
    this.customersCrudService = new CrudService<Customers>(
      this.http,
      'customers'
    );
    this.userCrudService = new CrudService<User>(this.http, 'user');
    this.channelCrudService = new CrudService<Channel>(this.http, 'channel');
    this.taxesApplicableCrudService = new CrudService<taxesApplicable>(
      this.http,
      'taxesApplicable'
    );
    this.customerForm = this.formBuilder.group({
      id: [''],
      customerShort: ['', Validators.required],
      customerPoLineNumber: [''],
      salesManagerId: [''],
      orderAdminId: [''],
      channelId: [''],
      countryId: [''],
      status: ['', Validators.required],
      taxes: ['', Validators.required],
      customers: [],
    });

    this.customersMultiSelectSearch = {
      key: 'customers',
      hover: 'Customers Numbers',
      required: false,
      controlType: 'multiselect-search',
      options: [],
      hasAutocomplete: false,
      hasMultiple: true,
      subscription: false,
      disabled: false,
      value: [],
    };
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('customer-short');
    });
    [this.currentUser] = this.authService.getUserPermissions();
    let customerNumberInitial: string = '';
    this.route.queryParams.subscribe((item: any) => {
      customerNumberInitial = item.customerNumber;
    });
    this.filter = {
      ...this.filter,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
      customerNumber: customerNumberInitial ? customerNumberInitial : '',
    };

    this.filterCustomerShort = {
      ...this.filterCustomerShort,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
      customerNumber: customerNumberInitial ? customerNumberInitial : '',
      status: true,
    };
    this.searchInputs = this.createSearchInputs();
    this.searchInputsList = this.createSearchInputsList();
  }

  public customerNameMultiCtrl: FormControl = new FormControl();
  public customerNameMultiFilterCtrl: FormControl = new FormControl();
  public filteredCustomerNameMulti: ReplaySubject<any> = new ReplaySubject(1);

  public salesManagerMultiCtrl: FormControl = new FormControl();
  public salesManagerMultiFilterCtrl: FormControl = new FormControl();
  public filteredSalesManagerMulti: ReplaySubject<any> = new ReplaySubject(1);

  public countryMultiCtrl: FormControl = new FormControl();
  public countryMultiFilterCtrl: FormControl = new FormControl();
  public filteredCountryMulti: ReplaySubject<any> = new ReplaySubject(1);

  public orderAdminMultiCtrl: FormControl = new FormControl();
  public orderAdminMultiFilterCtrl: FormControl = new FormControl();
  public filteredOrderAdminMulti: ReplaySubject<any> = new ReplaySubject(1);

  public channelMultiCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public channelMultiFilterCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredChannelMulti: ReplaySubject<any> = new ReplaySubject(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect | any;

  protected onDestroy$ = new Subject();

  ngOnInit(): void {
    this.router.navigate([], { queryParams: null });
    this.loadData();
    this.customerService();
    this.filterOptionsOrderAdmin();
    this.tabRoute();
  }

  customerService() {
    this.customersCrudService
      .getEntity('customers', { pageName: PAGE_NAME_RESOURCE.customerShort })
      .subscribe((response: any) => {
        this.customerNamesEntity = response;
      });
  }

  filterOptionsOrderAdmin() {
    this.filteredOptionsOrderAdmin = this.customerForm.valueChanges.pipe(
      startWith(''),
      map(value => this.filterOptions(value))
    );
  }

  tabRoute() {
    const tabRoute = this.route.snapshot.paramMap.get('tab');
    this.changeTab(tabRoute);
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  clearCustomerNumber(event: any) {
    this.customerNameMultiCtrl.setValue([]);
  }

  clearCountry(event: any) {
    this.countryMultiCtrl.setValue(null);
  }

  clearOrderAdmin(event: any) {
    this.orderAdminMultiCtrl.setValue(null);
  }

  clearChannel(event: any) {
    this.channelMultiCtrl.setValue(null);
  }

  clearSalesManager(event: any) {
    this.salesManagerMultiCtrl.setValue(null);
  }

  clearTaxes(event: any) {
    this.customerForm.controls.taxes.setValue([]);
  }

  clearStatus(event: any) {
    this.customerForm.controls.status.setValue(null);
  }

  protected setInitialValue() {
    this.customerNameMultiCtrl.setValue([]);
    this.salesManagerMultiCtrl.setValue(null);
    this.countryMultiCtrl.setValue(this.currentUser.countryId);
    this.orderAdminMultiCtrl.setValue(null);
    this.channelMultiCtrl.setValue(null);
  }

  protected filterCustomerNameMulti() {
    if (!this.customerNamesEntity) {
      return;
    }

    let search = this.customerNameMultiFilterCtrl.value;
    if (!search) {
      this.filteredCustomerNameMulti.next(this.customerNamesEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredCustomerNameMulti.next(
      this.customerNamesEntity.filter(item =>
        item.customerNumber.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterSalesManagerSingle() {
    if (!this.salesManagersEntity) {
      return;
    }

    let search = this.salesManagerMultiFilterCtrl.value;
    if (!search) {
      this.filteredSalesManagerMulti.next(this.salesManagersEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredSalesManagerMulti.next(
      this.salesManagersEntity.filter(item =>
        item.name.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCountrySingle() {
    if (!this.countriesEntity) {
      return;
    }

    let search = this.countryMultiFilterCtrl.value;
    if (!search) {
      this.filteredCountryMulti.next(this.countriesEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredCountryMulti.next(
      this.countriesEntity.filter(item =>
        item.country.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterOrderAdminSingle() {
    if (!this.orderAdminsEntity) {
      return;
    }

    let search = this.orderAdminMultiFilterCtrl.value;
    if (!search) {
      this.filteredOrderAdminMulti.next(this.orderAdminsEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredOrderAdminMulti.next(
      this.orderAdminsEntity.filter(item =>
        item.name.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterChannelSingle() {
    if (!this.channelsEntity) {
      return;
    }

    let search = this.channelMultiFilterCtrl.value;
    if (!search) {
      this.filteredChannelMulti.next(this.channelsEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredChannelMulti.next(
      this.channelsEntity.filter(item =>
        item.channel.toLowerCase().startsWith(search)
      )
    );
  }

  public customerNameEntitiesFilterOptions(filter: string): void {
    if (filter) {
      this.customerNameEntitiesFiltered = this.customerNamesEntity.filter(x =>
        x.customerNumber.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }

  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        type: 'text',
        classCss: 'status-customer-short',
      }),
      new SelectSearchInput({
        key: 'customerShort',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
      }),
      new SelectSearchInput({
        key: 'customerNumber',
        hover: 'Customer Number',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  createSearchInputsList(): any[] {
    return [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'customerNumber',
        hover: 'Customer Number',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'customerShort',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'customerName',
        hover: 'Customer Name',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  customerShortInvalid(customerShortValid: any) {
    if (customerShortValid.customerShort.status === 'INVALID') {
      this.toastr.warning('Mandatory field!', 'Customer Short');
    }
  }

  statusInvalid(customerShortValid: any) {
    if (customerShortValid.status.status === 'INVALID') {
      this.toastr.warning('Mandatory field!', 'Status');
    }
  }

  taxesInvalid(customerShortValid: any) {
    if (customerShortValid.taxes.status === 'INVALID') {
      this.toastr.warning('Mandatory field!', 'Taxes');
    }
  }

  channelInvalid() {
    if (this.channelMultiCtrl.status === 'INVALID') {
      this.toastr.warning('Mandatory field!', 'Channel');
    }
  }

  checkValid() {
    const customerShortValid = this.customerForm.controls;

    this.customerShortInvalid(customerShortValid);
    this.statusInvalid(customerShortValid);
    this.taxesInvalid(customerShortValid);
    this.channelInvalid();
  }

  private filterOptions(value: string): any[] {
    const filterValue = value ? value.toLocaleUpperCase() : '';

    return this.orderAdminsEntity.filter((option: any) =>
      option.geo.toLocaleUpperCase().includes(filterValue)
    );
  }

  loadData() {
    this.getCustomersWithSalesStructures(this.pagination || undefined);
    this.getDataFilter();
  }

  getCustomersWithSalesStructures(page?: any, params?: any) {
    this.spinner.show();

    if (this.selectedTabIndex === 0) {
      this.filter = {
        sort: 'customerShort',
        ...this.filter,
      };
      this.customerShortCrudService
        .getPaged(page, params || this.filter)
        .subscribe((response: any) => {
          if (response.rows.length == 0) {
            this.isValid = false;
            this.noResultCustomerShort = true;
            this.spinner.hide();
          } else {
            this.isValid = true;
            this.updateComponents(response);
            this.noResultCustomerShort = false;
            this.spinner.hide();
          }
        });

      delete this.filter.sort;

      this.customerShortCrudServiceList
        .getPaged(page, {
          ...this.filter,
          associated: true,
        })
        .subscribe((response: any) => {
          this.resultCustomerListPage = response.totalRows;
          this.spinner.hide();
        });
    } else if (this.selectedTabIndex === 1) {
      delete this.filter.sort;

      this.customerShortCrudServiceList
        .getPaged(page, {
          ...this.filter,
          associated: this.searchAssociated,
        })
        .subscribe((response: any) => {
          if (response.rows.length == 0) {
            this.isValid = false;
            this.noResultCustomerList = true;
            this.spinner.hide();
          } else {
            this.isValid = true;
            this.updateComponentsList(response);
            this.noResultCustomerList = false;
            this.spinner.hide();
          }
        });
    }
  }

  // dados dos filtros a partir deste ponto
  dataFilterGeo(geosFilter: any) {
    this.customerShortCrudService
      .getEntity('geo', { sort: 'geo', pageName: 'customer-short' })
      .subscribe((response: any) => {
        const initialValue: { [key: string]: any } = {};

        this.geosEntity = response;
        response.forEach((geo: Geo) => {
          geosFilter.push({ value: geo.geo, id: geo.id });
          if (geo.id === this.currentUser.geoId) {
            initialValue.value = geo.geo;
            initialValue.id = geo.id;
          }
        });

        const selectGeo = new SelectSearchInput({
          key: 'geoId',
          hover: 'GEO',
          type: 'text',
          hasAutocomplete: true,
          options: geosFilter,
          value: initialValue.value,
          disabled: true,
          notClearable: true,
        });

        this.searchInputs[0] = selectGeo;
        this.searchInputsList[0] = selectGeo;
        this.searchInputs = [...this.searchInputs];
        this.searchInputsList = [...this.searchInputsList];
      });
  }

  dataFilterCountry(countriesFilter: any) {
    this.customerShortCrudService
      .getEntity('country', { sort: 'country', pageName: 'customer-short' })
      .subscribe((response: any) => {
        this.countriesEntity = response;

        const initialValue: { [key: string]: any } = {};
        response.forEach((country: Country) => {
          countriesFilter.push({ value: country.country, id: country.id });
          if (country.id === this.currentUser.countryId) {
            initialValue.value = country.country;
            initialValue.id = country.id;
          }
        });

        const selectCountry = new SelectSearchInput({
          key: 'countryId',
          hover: 'Country',
          type: 'text',
          hasAutocomplete: true,
          options: countriesFilter,
          value: initialValue.value,
          disabled: true,
          notClearable: true,
        });

        this.searchInputs[1] = selectCountry;
        this.searchInputsList[1] = selectCountry;
        this.searchInputs = [...this.searchInputs];
        this.searchInputsList = [...this.searchInputsList];
      });
  }

  dataFilterCustomerName(customerNameFilter: any) {
    this.customersCrudService
      .getEntity('customers', {
        sort: 'customerName',
        pageName: PAGE_NAME_RESOURCE.customerShort,
      })
      .subscribe((response: any) => {
        response.forEach((item: CustomerShort) => {
          if (
            customerNameFilter.findIndex(
              (obj: { value: string }) =>
                obj.value.toUpperCase() === item.customerName.toUpperCase()
            ) === -1
          ) {
            customerNameFilter.push({ value: item.customerName, id: item.id });
          }
        });
        this.customerNamesEntity = response;
        this.customerNameEntitiesFiltered = response;
      });
  }

  dataFilterCustomerNumber(customerNumberFilter: any) {
    this.customersCrudService
      .getEntity('customers', {
        sort: 'customer_number',
        pageName: PAGE_NAME_RESOURCE.customerShort,
      })
      .subscribe((response: any) => {
        response.forEach((item: CustomerShort) => {
          customerNumberFilter.push({
            value: item.customerNumber,
            id: item.id,
          });
        });
      });
  }

  dataFilterCustomerShort(customerShortFilter: any) {
    this.customerShortCrudService
      .getEntity('customer-short', { sort: 'customer_short' })
      .subscribe((response: any) => {
        response.forEach((item: CustomerShort) => {
          customerShortFilter.push({ value: item.customerShort, id: item.id });
        });
      });
  }

  dataFilterOrderAdmin(orderAdminFilter: any) {
    this.customerShortCrudService
      .getEntity('user', {
        status: true,
        order_admin: true,
        sort: 'last_name',
        pageName: 'customer-short',
      })
      .subscribe((response: any) => {
        this.orderAdminsEntity = response;

        const initialValue: { [key: string]: any } = {};
        response.forEach((order: OrderAdmin) => {
          orderAdminFilter.push({
            value: `${order.last_name}, ${order.name}`,
            id: order.id,
          });
        });

        const selectOrders = new SelectSearchInput({
          key: 'orderAdminId',
          hover: 'Order Admin',
          type: 'text',
          hasAutocomplete: true,
          options: orderAdminFilter,
          value: initialValue.value,
        });

        this.searchInputs[4] = selectOrders;
        this.searchInputs = [...this.searchInputs];
      });
  }

  dataFilterSalesManager(salesManagerFilter: any) {
    this.customerShortCrudService
      .getEntity('user', {
        status: true,
        sales_manager: true,
        sort: 'last_name',
        pageName: 'customer-short',
      })
      .subscribe((response: any) => {
        this.salesManagersEntity = response;

        const initialValue: { [key: string]: any } = {};
        response.forEach((sales: SalesManager) => {
          salesManagerFilter.push({
            value: `${sales.last_name}, ${sales.name}`,
            id: sales.id,
          });
        });

        const selectSales = new SelectSearchInput({
          key: 'salesManagerId',
          hover: 'Sales Manager',
          type: 'text',
          hasAutocomplete: true,
          options: salesManagerFilter,
          value: initialValue.value,
        });

        this.searchInputs[5] = selectSales;
        this.searchInputs = [...this.searchInputs];
      });
  }

  dataFilterChannel() {
    this.customerShortCrudService
      .getEntity('channel', { sort: 'channel', pageName: 'customer-short' })
      .subscribe((response: any) => {
        this.channelsEntity = response;
      });
  }

  dataFilterTaxesApplicable() {
    this.customerShortCrudService
      .getEntity('taxesApplicable', { sort: 'tax', pageName: 'customer-short' })
      .subscribe((response: any) => {
        this.taxesApplicablesEntity = response;
      });
  }

  getDataFilter() {
    const geosFilter: any[] = [];
    const countriesFilter: any[] = [];
    const allStatus: any[] = [];
    const customerNameFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const customerNumberFilter: any[] = [];
    const orderAdminFilter: any[] = [];
    const salesManagerFilter: any[] = [];

    this.spinner.show();

    this.dataFilterGeo(geosFilter);
    this.dataFilterCountry(countriesFilter);
    this.dataFilterCustomerName(customerNameFilter);
    this.dataFilterCustomerNumber(customerNumberFilter);
    this.dataFilterCustomerShort(customerShortFilter);
    this.dataFilterOrderAdmin(orderAdminFilter);
    this.dataFilterSalesManager(salesManagerFilter);
    this.dataFilterChannel();
    this.dataFilterTaxesApplicable();

    allStatus.push({ value: 'Active' });
    allStatus.push({ value: 'Inactive' });

    this.searchInputs = this.createSearchInputs();
    this.searchInputs[0].options = geosFilter;
    this.searchInputs[1].options = countriesFilter;
    this.searchInputs[2].options = allStatus;
    this.searchInputs[3].options = customerShortFilter;
    this.searchInputs[6].options = customerNumberFilter;
    this.searchInputsList = this.createSearchInputsList();
    this.searchInputsList[0].options = geosFilter;
    this.searchInputsList[1].options = countriesFilter;
    this.searchInputsList[2].options = customerNumberFilter;
    this.searchInputsList[3].options = customerShortFilter;
    this.searchInputsList[4].options = customerNameFilter;
  }

  updateComponents(responseCustomer: any, exportBoolean?: boolean) {
    if (!exportBoolean) {
      this.resultCustomerShort = {
        table: {
          columns: [
            {
              label: 'geo',
              prop: 'geo',
              sticky: true,
              style: { width: '10%', 'box-sizing': 'border-box' },
            },
            {
              label: 'country',
              prop: 'country',
              sticky: true,
              style: { width: '10%', 'box-sizing': 'border-box' },
            },
            {
              label: 'customer short',
              prop: 'customerShort',
              sticky: true,
              style: { width: '15%' },
            },
            {
              label: 'channel',
              prop: 'channel',
              sticky: true,
              style: { width: '10%' },
            },
            {
              label: 'order admin',
              prop: 'orderAdmin',
              sticky: true,
              style: { width: '10%' },
            },
            {
              label: 'sales manager',
              prop: 'salesManager',
              sticky: true,
              style: { width: '10%' },
            },
            {
              label: 'app taxes',
              prop: 'appTaxes',
              sticky: true,
              style: { width: '10%' },
            },
            {
              label: 'customer number',
              prop: 'customerNumber',
              sticky: true,
              style: { width: '15%', 'box-sizing': 'border-box' },
              accordion: true,
            },
            {
              label: 'status',
              prop: 'status',
              sticky: true,
              style: { width: '5%', 'box-sizing': 'border-box' },
            },
          ],
          rows: new MatTableDataSource<CustomerShort>(
            this.dataTables(JSON.stringify(responseCustomer.rows))
          ),
        },
        pageIndex: responseCustomer.page,
        pageSize: responseCustomer.totalRows,
        pagesTotal: responseCustomer.totalPages,
        isModeTableAccordion: true,
        selectedTab: 'customer_short',
      };
      const actions =
        this.permissionService.setTablePermissions('customer-short');
      if (actions.label === 'actions') {
        this.resultCustomerShort.table?.columns?.push(actions);
      }

      this.resultCustomerPage = responseCustomer.totalRows;
    } else if (exportBoolean == true) {
      this.resultCustomerShortExport = this.resultCustomerShort;
    }
  }

  updateComponentsList(responseCustomer: any, exportBoolean?: boolean) {
    this.resultCustomerList = {
      cards: [],
      table: {
        columns: [
          {
            label: 'geo',
            prop: 'geo',
            sticky: true,
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
          {
            label: 'country',
            prop: 'country',
            sticky: true,
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
          {
            label: 'customer short',
            prop: 'customerShort',
            sticky: true,
            style: { width: '15%' },
            accordion: true,
          },
          {
            label: 'customer name',
            prop: 'customerName',
            sticky: true,
            style: { width: '15%' },
          },
          {
            label: 'customer number',
            prop: 'customerNumber',
            sticky: true,
            style: { width: '10%', 'box-sizing': 'border-box' },
          },
        ],
        rows: new MatTableDataSource<CustomerShort>(
          this.dataTables(JSON.stringify(responseCustomer.rows))
        ),
      },
      pageIndex: responseCustomer.page,
      pageSize: responseCustomer.totalRows,
      pagesTotal: responseCustomer.totalPages,
      selectedTab: 'customer_list',
      isModeTableAccordion: true,
    };

    this.resultCustomerListPage = responseCustomer.totalRows;

    if (exportBoolean == true) {
      this.resultCustomerListExport = this.resultCustomerList;
    }
  }

  getTaxes(data: any) {
    let taxes: any[] = [];
    data.forEach((item: any) => {
      taxes.push(item.tax);
      taxes.sort();
    });
    return taxes.join(', ');
  }

  getCustomerNumbers(data: any) {
    let customerNumbers =
      data.length + `${data.length > 1 ? ' numbers' : ' number'}`;
    return customerNumbers;
  }

  getCustomerCountry(data: any) {
    const listCountries = data.map((item: any) => item.country.country);
    return listCountries.find((element: any) => element);
  }

  getCustomerGeo(data: any) {
    const listGeo = data.map((item: any) => item.country.geos.geo);
    return listGeo.find((element: any) => element);
  }

  getCustomerShort(data: any) {
    let customerShorts =
      data.length +
      `${data.length > 1 ? ' customer shorts' : ' customer short'}`;
    return customerShorts;
  }

  getCustomerShortExport(data: any) {
    let customerShorts = data.map((item: any) => ({
      value: item.customer_short,
    }));

    return customerShorts;
  }

  dataMapToTable(data: any): CustomerShort[] {
    return JSON.parse(data).map((item: any) => ({
      id: item.id,
      geo: item.country ? item.country.geos.geo : '',
      country: item.country ? item.country.country : '',
      customerShort: item.customerShort,
      channel: item.channel ? item.channel.channel : '',
      orderAdmin: item.orderAdmin
        ? `${item.orderAdmin.last_name} ${item.orderAdmin.name}`
        : '',
      salesManager: item.salesManager
        ? `${item.salesManager.last_name} ${item.salesManager.name}`
        : '',
      appTaxes: item.taxes ? this.getTaxes(item.taxes) : '',
      customerNumber: item.customers
        ? this.getCustomerNumbers(item.customers)
        : '',
      customerNumberDetails: item.customers ? item.customers : '',
      status: item.status ? 'Active' : 'Inactive',
    }));
  }

  dataMapToTableList(data: any, exportBoolean?: boolean): CustomerShort[] {
    if (!exportBoolean) {
      return JSON.parse(data).map((item: any) => ({
        id: item.id,
        geo: item.customerShort ? this.getCustomerGeo(item.customerShort) : '',
        country: item.customerShort
          ? this.getCustomerCountry(item.customerShort)
          : '',
        customerShort: item.customerShort
          ? this.getCustomerShort(item.customerShort)
          : '',
        customerShortDetails: item.customerShort ? item.customerShort : '',
        customerName: item.customerName,
        customerNumber: item.customerNumber,
      }));
    } else {
      return JSON.parse(data).map((item: any) => ({
        id: item.id,
        geo: item.customerShort ? this.getCustomerGeo(item.customerShort) : '',
        country: item.customerShort
          ? this.getCustomerCountry(item.customerShort)
          : '',
        customerShort: item.customerShort
          ? this.getCustomerShortExport(item.customerShort)
          : '',
        customerName: item.customerName,
        customerNumber: item.customerNumber,
      }));
    }
  }

  dataTables(data: any) {
    let customer: any = [];

    if (this.selectedTabIndex === 0) {
      customer = this.dataMapToTable(data);
    } else if (this.selectedTabIndex === 1) {
      customer = this.dataMapToTableList(data);
    }

    const dataTables: any[] = [];
    customer.forEach((item: CustomerShort) => {
      dataTables.push(item);
    });

    return [
      ...dataTables.sort(
        (current: any, next: any) =>
          current.geo && current.geo.localeCompare(next.geo)
      ),
    ];
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getCustomersWithSalesStructures(paginated, this.filter);
  }

  onEventActions(event: any) {
    event.type === this.event.edit
      ? this.prepareToEdit(event.item.id)
      : this.onDelete(event.item.id);
  }

  messageToastSuccessDelete() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Association`
    );
  }

  messageToastUpdate() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Association`
    );
  }

  messageToastSuccess() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS')} Association`
    );
  }

  onDelete(id: number) {
    this.isEdit = 'delete';
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.customerShortCrudService.deleteEntity(id).subscribe(
          () => {
            this.messageToastSuccessDelete();
            this.getCustomersWithSalesStructures();
          },
          (err: any) => {
            this.toastr.error(err.error.title, 'Error!');
            this.spinner.hide();
          }
        );
      }
    });
  }

  setFilterTableSelected(event: any) {
    this.filterTableSelected = event.value;

    this.searchAssociated = event.value;
    this.filter.associated = event.value;
    this.getCustomersWithSalesStructures(this.pagination || this.filter);
  }

  changeCustomerNameMultiCtrl() {
    this.customerNameMultiCtrl.setValue(this.customerNamesEntity[1]);
    this.filteredCustomerNameMulti.next(this.customerNamesEntity.slice());
    this.customerNameMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerNameMulti();
      });
  }

  changeSalesManagerMultiFilterCtrl() {
    this.salesManagerMultiCtrl.setValue(this.salesManagersEntity[1]);
    this.filteredSalesManagerMulti.next(this.salesManagersEntity.slice());
    this.salesManagerMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterSalesManagerSingle();
      });
  }

  changeCountryMultiFilterCtrl() {
    this.countryMultiCtrl.setValue(this.countriesEntity[1]);
    this.filteredCountryMulti.next(this.countriesEntity.slice());
    this.countryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountrySingle();
      });
  }

  changeOrderAdminMultiFilterCtrl() {
    this.orderAdminMultiCtrl.setValue(this.orderAdminsEntity[1]);
    this.filteredOrderAdminMulti.next(this.orderAdminsEntity.slice());
    this.orderAdminMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderAdminSingle();
      });
  }

  changeChannelMultiFilterCtrl() {
    this.channelMultiCtrl.setValue(this.channelsEntity[1]);
    this.filteredChannelMulti.next(this.channelsEntity.slice());
    this.channelMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterChannelSingle();
      });
  }

  prepareToCreate() {
    this.spinner.show();

    this.changeCustomerNameMultiCtrl();
    this.changeSalesManagerMultiFilterCtrl();
    this.changeCountryMultiFilterCtrl();
    this.changeOrderAdminMultiFilterCtrl();
    this.changeChannelMultiFilterCtrl();

    this.setInitialValue();
    this.isEdit = false;

    const modal = this.modalService.createModal(this.modalCreateEdit);
    modal.afterClosed().subscribe(() => {
      this.customerForm.reset();
    });
    this.spinner.hide();
  }

  prepareToEdit(id: number) {
    this.setInitialValue();

    this.changeCustomerNameMultiCtrl();
    this.changeSalesManagerMultiFilterCtrl();
    this.changeCountryMultiFilterCtrl();
    this.changeOrderAdminMultiFilterCtrl();
    this.changeChannelMultiFilterCtrl();

    this.spinner.show();
    this.isEdit = true;
    this.buttonSaveDisabled = false;
    this.customerShortCrudService.fetchEntity(id).subscribe((customer: any) => {
      const taxesSelected: any = [];
      customer.taxes?.map((tax: any) => taxesSelected.push(tax.id));

      const customersSelected: any = [];
      customer.customers?.map((customerSel: any) =>
        customersSelected.push(customerSel.id)
      );

      this.customerForm.controls.id.setValue(customer.id);

      this.customerNameMultiCtrl.setValue(customersSelected);
      this.salesManagerMultiCtrl.setValue(customer.salesManagerId);
      this.countryMultiCtrl.setValue(customer.countryId);
      this.orderAdminMultiCtrl.setValue(customer.orderAdminId);
      this.channelMultiCtrl.setValue(customer.channelId);

      this.customerForm.controls.orderAdminId.setValue(customer.orderAdminId);
      this.customerForm.controls.channelId.setValue(customer.channelId);
      this.customerForm.controls.taxes.setValue(taxesSelected);
      this.customerForm.controls.customerShort.setValue(customer.customerShort);
      this.customerForm.controls.customerPoLineNumber.setValue(
        customer.customerPoLineNumber
      );
      this.customerForm.controls.status.setValue(!!customer.status);

      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.customerForm.reset();
      });
      this.spinner.hide();
    });
  }

  checkIfAssociated(customerNumber: any, matOption: MatOption) {
    const isSelected = matOption.selected;

    if (isSelected) {
      if (customerNumber.customerShort.length > 0) {
        const customerShortValues = customerNumber.customerShort.map(
          (customerShort: any) => {
            return customerShort.customer_short;
          }
        );

        this.messageModalAssociation = `The customer ${
          customerNumber.customerNumber
        } is associated with ${customerShortValues.join(', ')}.
        Keep the new association?`;

        const dialog = this.modalService.createConfirm(this.dialogAssociation);
        dialog.afterClosed().subscribe((result: any) => {
          if (result) {
            this.spinner.show();
            matOption.select();
            this.spinner.hide();
          } else {
            matOption.deselect();
          }
        });
      }
    } else {
      matOption.deselect();
    }
  }

  onUpdate() {
    this.checkValid();
    if (this.customerForm.valid && this.channelMultiCtrl.status === 'VALID') {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();

          const customerShort: any = this.customerForm.value;

          if (this.customerForm.value.taxes) {
            customerShort.taxes = this.customerForm.value.taxes.map(
              (tax: any) => ({ id: tax })
            );
          }
          if (this.customerNameMultiCtrl.value) {
            customerShort.customers = this.customerNameMultiCtrl.value.map(
              (customer: any) => ({ id: customer })
            );
          }
          customerShort.salesManagerId = this.salesManagerMultiCtrl.value;
          customerShort.countryId = this.countryMultiCtrl.value;
          customerShort.orderAdminId = this.orderAdminMultiCtrl.value;
          customerShort.channelId = this.channelMultiCtrl.value;

          if (customerShort.id) {
            this.customerShortCrudService
              .updateEntity(customerShort.id, customerShort)
              .subscribe(
                () => {
                  this.messageToastUpdate();
                  this.modalService.closeAll();
                  this.loadData();
                  this.customerForm.reset();
                },
                (err: any) => {
                  this.toastr.error(err.error.message, err.error.title);
                  this.spinner.hide();
                }
              );
          } else {
            delete customerShort.id;

            this.customerShortCrudService.createEntity(customerShort).subscribe(
              () => {
                this.messageToastSuccess();
                this.modalService.closeAll();
                this.loadData();
                this.customerForm.reset();
              },
              (err: any) => {
                this.toastr.error(err.error.message, err.error.title);
                this.spinner.hide();
              }
            );
          }
        }
      });
    }
  }

  dataformSearchGeo(formSearchValues: any) {
    if (formSearchValues.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchValues.geoId.toLocaleUpperCase())
      );
      formSearchValues.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchValues.geoId = '';
    }
  }

  dataformSearchCountry(formSearchValues: any) {
    if (formSearchValues.countryId) {
      const currentCountry = this.countriesEntity.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(formSearchValues.countryId.toLocaleUpperCase())
      );
      formSearchValues.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      formSearchValues.countryId = '';
    }
  }

  dataformSearchStatus(formSearchValues: any) {
    if (formSearchValues.status === STATUS.active) {
      formSearchValues.status = true;
    } else if (formSearchValues.status === STATUS.inactive) {
      formSearchValues.status = false;
    } else {
      formSearchValues.status = '';
    }
  }
  dataformSearchCustomerName(formSearchValues: any) {
    formSearchValues.customerName = formSearchValues.customerName
      ? formSearchValues.customerName
      : '';
  }

  dataformSearchCustomerShort(formSearchValues: any) {
    formSearchValues.customerShort = formSearchValues.customerShort
      ? formSearchValues.customerShort
      : '';
  }

  dataformSearchOrderAdmin(formSearchValues: any) {
    if (formSearchValues.orderAdminId) {
      const nameOrderAdmin = this.orderAdminsEntity.find(
        item =>
          `${item.last_name}, ${item.name}` === formSearchValues.orderAdminId
      );
      formSearchValues.orderAdminId =
        nameOrderAdmin !== undefined ? nameOrderAdmin.id : '';
    } else {
      formSearchValues.orderAdminId = '';
    }
  }

  dataformSearchSalesManager(formSearchValues: any) {
    if (formSearchValues.salesManagerId) {
      const nameSalesManager = this.salesManagersEntity.find(
        item =>
          `${item.last_name}, ${item.name}` === formSearchValues.salesManagerId
      );
      formSearchValues.salesManagerId =
        nameSalesManager !== undefined ? nameSalesManager.id : '';
    } else {
      formSearchValues.salesManagerId = '';
    }
  }

  onSearchEvent(value: any) {
    const formSearchValues = { ...value };

    this.dataformSearchGeo(formSearchValues);
    this.dataformSearchCountry(formSearchValues);
    this.dataformSearchStatus(formSearchValues);
    this.dataformSearchCustomerName(formSearchValues);
    this.dataformSearchCustomerShort(formSearchValues);
    this.dataformSearchOrderAdmin(formSearchValues);
    this.dataformSearchSalesManager(formSearchValues);

    this.pagination.page = 0;

    if (this.selectedTabIndex === 0) {
      this.filter = {
        ...formSearchValues,
        sort: 'customerShort',
        pageName: PAGE_NAME_RESOURCE.customerShort,
        geo: formSearchValues.geo,
        country: formSearchValues.country,
        status: formSearchValues.status,
        customerShort: formSearchValues.customerShort,
        customerNumber: formSearchValues.customerNumber
          ? formSearchValues.customerNumber
          : '',
      };
    } else if (this.selectedTabIndex === 1) {
      this.filter = {
        associated: this.searchAssociated,
        customerNumber: formSearchValues.customerNumber
          ? formSearchValues.customerNumber
          : '',
        customerName: formSearchValues.customerName,
        customerShort: formSearchValues.customerShort,
        pageName: PAGE_NAME_RESOURCE.customerShort,
      };
    }

    this.filterCustomerShort = {
      ...formSearchValues,
      sort: 'customerShort',
      pageName: PAGE_NAME_RESOURCE.customerShort,
      geo: formSearchValues.geo,
      country: formSearchValues.country,
      status: formSearchValues.status,
      customerName: '',
      customerShort: formSearchValues.customerShort,
      customerNumber: formSearchValues.customerNumber
        ? formSearchValues.customerNumber
        : '',
    };

    if (formSearchValues.status == false) {
      this.filterCustomerShort.status = false;
    }
    if (formSearchValues.status == true) {
      this.filterCustomerShort.status = true;
    }
    if (typeof formSearchValues.status == 'string') {
      this.filterCustomerShort.status = true;
    }

    this.filterCustomerList = {
      associated: this.searchAssociated,
      customerNumber: formSearchValues.customerNumber
        ? formSearchValues.customerNumber
        : '',
      customerName: formSearchValues.customerName,
      customerShort: formSearchValues.customerShort,
      pageName: PAGE_NAME_RESOURCE.customerShort,
    };

    this.getCustomersWithSalesStructures(this.pagination, this.filter);
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

  getSalesManagersFromOrderAdmin(orderAdmin: any) {
    const result: any = [];

    if (orderAdmin.salesManagers.length > 0) {
      const partial = orderAdmin.salesManagers;
      partial.forEach((value: any) => {
        result.push({
          id: value.salesManager.id,
          lastName: value.salesManager.last_name,
          name: value.salesManager.name,
          email: value.salesManager.email,
        });
      });
    } else {
      this.toastr.warning(
        'There is no sales manager associated with this order admin',
        'Sales manager'
      );
    }
    return result;
  }

  clearFilters() {
    this.filter.geoId = this.currentUser.geoId;
    this.filter.countryId = this.currentUser.countryId;
    delete this.filter.customerName;
    delete this.filter.associated;
  }

  tabClick(event: any) {
    this.changeTab(event.target.innerText);
  }
  changeTab(label: any) {
    if (this.tabs[label] || this.tabs[label] === 0) {
      this.clearFilters();
      this.selectedTabIndex = this.tabs[label];
      this.getCustomersWithSalesStructures(this.pagination || this.filter);
    }
  }

  async exportCustomerShort(
    page?: any,
    params?: any,
    filter?: any
  ): Promise<void> {
    page = { page: 0, size: this.resultCustomerPage };

    return new Promise((resolve, reject) => {
      this.customerShortCrudService
        .getPaged(page, params || this.filterCustomerShort)
        .subscribe(
          (response: any) => {
            let exportBoolean = true;

            this.resultCustomerShortExportRows = this.dataMapToTable(
              JSON.stringify(response.rows)
            );

            this.updateComponents(response, exportBoolean);
            let sheet: string = 'Customer Shorts';

            this.resultCustomerShortExport?.table?.columns?.splice(9, 1);

            const columns = this.resultCustomerShortExport?.table?.columns;
            const rows = this.resultCustomerShortExportRows?.map(
              (row: any) => row
            );

            this.allocateJsonExport.push(
              this.prepareToExport(rows, columns, sheet)
            );

            resolve();
          },
          (err: any) => {
            reject();
          }
        );
    });
  }

  async exportCustomerList(
    page?: any,
    params?: any,
    filter?: any
  ): Promise<void> {
    page = { page: 0, size: this.resultCustomerListPage };

    return new Promise((resolve, reject) => {
      this.customerShortCrudServiceList
        .getPaged(page, {
          ...this.filter,
          associated: true,
        })
        .subscribe(
          (response: any) => {
            let exportBoolean = true;

            this.resultCustomerListExportRows = this.dataMapToTableList(
              JSON.stringify(response.rows),
              exportBoolean
            );

            this.updateComponentsList(response, exportBoolean);
            let sheet: string = 'Customer List';

            const columns = this.resultCustomerListExport?.table?.columns;
            const rows = this.resultCustomerListExportRows?.map(
              (row: any) => row
            );

            this.allocateJsonExport.push(
              this.prepareToExport(rows, columns, sheet)
            );

            resolve();
          },
          (err: any) => {
            reject();
          }
        );
    });
  }

  prepareToExport(rows: any, data: any, sheet: any) {
    let columns: any[] = [];
    const tableRows: { [key: string]: string }[] = [];
    columns = data.map((item: any) => ({
      title: item.label.toUpperCase(),
      props: item.prop,
    }));

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].customerNumberDetails) {
        if (rows[i].customerNumberDetails.length > 0) {
          for (const customer of rows[i].customerNumberDetails) {
            if (
              this.filterCustomerShort &&
              this.filterCustomerShort.customerNumber != ''
            ) {
              if (
                this.filterCustomerShort.customerNumber ==
                customer.customer_number
              ) {
                const tableRow: { [key: string]: string } = {};
                columns.forEach((column: any) => {
                  if (
                    rows[i].customerNumberDetails &&
                    rows[i][column.props].includes('number')
                  ) {
                    tableRow[column.props] = customer.customer_number
                      ? customer.customer_number
                      : '';
                  } else {
                    tableRow[column.props] = rows[i][column.props]
                      ? rows[i][column.props]
                      : '';
                  }
                });
                tableRows.push(tableRow);
              }
            } else {
              const tableRow: { [key: string]: string } = {};
              columns.forEach((column: any) => {
                if (
                  rows[i].customerNumberDetails &&
                  rows[i][column.props].includes('number')
                ) {
                  tableRow[column.props] = customer.customer_number
                    ? customer.customer_number
                    : '';
                } else {
                  tableRow[column.props] = rows[i][column.props]
                    ? rows[i][column.props]
                    : '';
                }
              });
              tableRows.push(tableRow);
            }
          }
        } else {
          const tableRow: { [key: string]: string } = {};
          columns.forEach((column: any) => {
            if (
              rows[i].customerNumberDetails.length == 0 &&
              rows[i][column.props].includes('number')
            ) {
              tableRow[column.props] = '' ? '' : '';
            } else {
              tableRow[column.props] = rows[i][column.props]
                ? rows[i][column.props]
                : '';
            }
          });
          tableRows.push(tableRow);
        }
      }

      if (typeof rows[i].customerShort == 'object') {
        for (const customer of rows[i].customerShort) {
          if (
            this.filterCustomerList &&
            this.filterCustomerList.customerShort != ''
          ) {
            if (this.filterCustomerList.customerShort == customer.value) {
              const tableRow: { [key: string]: string } = {};
              columns.forEach((column: any) => {
                if (typeof rows[i][column.props] == 'object') {
                  tableRow[column.props] = customer.value ? customer.value : '';
                } else {
                  tableRow[column.props] = rows[i][column.props]
                    ? rows[i][column.props]
                    : '';
                }
              });
              tableRows.push(tableRow);
            }
          } else {
            const tableRow: { [key: string]: string } = {};
            columns.forEach((column: any) => {
              if (typeof rows[i][column.props] == 'object') {
                tableRow[column.props] = customer.value ? customer.value : '';
              } else {
                tableRow[column.props] = rows[i][column.props]
                  ? rows[i][column.props]
                  : '';
              }
            });
            tableRows.push(tableRow);
          }
        }
      }
    }

    return { columns, rows: tableRows, sheet: sheet, headers: true };
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      if (this.allocateJsonExport[0].sheet == 'Customer Shorts') {
        this.excelSheetsService.exportJsonAsExcelFile(
          this.allocateJsonExport,
          'customer-settings'
        );
      } else {
        this.excelSheetsService.exportJsonAsExcelFile(
          this.allocateJsonExport.reverse(),
          'customer-settings'
        );
      }

      this.spinner.hide();
    });
  }

  exportTable(filter: any = undefined) {
    this.spinner.show();
    this.allocateJsonExport = [];

    const promises = [
      this.exportCustomerShort(this.pagination || this.filterCustomerShort),
      this.exportCustomerList(this.pagination || this.filterCustomerList),
    ];

    this.hideSpinnerAfterAll(promises);
  }
}
