<ngx-spinner></ngx-spinner>
<div class="container-material">
  <div class="line-title-btn">
    <span class="title">MATERIALS</span>
    <div class="container-export">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="!isValid"
          matTooltip="Export"
          matTooltipPosition="left"
          [disabled]="!isValid"></button>
      </span>
      <div class="material-button">
        <button class="material-button-new-device" (click)="newDevice()">
          NEW ACCESSORY
        </button>
      </div>
    </div>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"
    [showMoreFiltersLink]="true"></app-search>
  <span class="line"></span>
  <app-results
    [data]="result"
    (eventActions)="onEventActions($event)"
    (resultPaginator)="onChangePaginator($event)"
    *ngIf="!noResultMaterial">
  </app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
  <div class="margin-footer"></div>
</div>

<ng-template #modalEdit class="custom-dialog-container">
  <form [formGroup]="materialsForm" class="form-materials">
    <header class="title-modal" matDialogTitle>EDIT MATERIAL</header>
    <div class="scroll-bar">
      <mat-dialog-content>
        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            materialsForm.controls['countries'].value.length === 0
          ">
          <mat-label>Country</mat-label>
          <input matInput formControlName="countries" appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            materialsForm.controls['salesModel'].value.length === 0
          ">
          <mat-label>Sales Model</mat-label>
          <input matInput formControlName="salesModel" appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            materialsForm.controls['status'].value.length === 0
          ">
          <mat-label>Status</mat-label>
          <mat-select
            formControlName="status"
            name="status"
            disableOptionCentering
            panelClass="panel-select-status-material">
            <mat-option
              *ngFor="let status of statusEntityEdit"
              [value]="status.value">
              {{ status.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>

      <mat-dialog-content class="mat-content">
        <mat-form-field
          appearance="outline"
          class="mat-form-field-full-width-productLongDesc"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['productLongDesc'].value
          ">
          <mat-label>Product Long Desc</mat-label>
          <div
            matTooltip="{{
              materialsForm.controls['productLongDesc'].value &&
              materialsForm.controls['productLongDesc'].value.length > 50
                ? materialsForm.controls['productLongDesc'].value
                : ''
            }}">
            <input
              matInput
              formControlName="productLongDesc"
              appInputAlphanumeric />
          </div>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="mat-form-field-flex-width"
          [class.mat-form-field-disabled-with-no-values]="
            materialsForm.controls['marketName'].value.length === 0
          ">
          <mat-label>Market Name</mat-label>
          <input matInput formControlName="marketName" appInputAlphanumeric />
        </mat-form-field>
      </mat-dialog-content>

      <mat-dialog-content class="mat-content">
        <mat-form-field appearance="outline">
          <mat-label>Local Market Name</mat-label>
          <input
            matInput
            formControlName="localMarketName"
            autocomplete="off" />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="mat-form-field-full-width"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['allocationBz'].value
          ">
          <mat-label>Allocation BZ</mat-label>
          <div
            matTooltip="{{
              materialsForm.controls['allocationBz'].value &&
              materialsForm.controls['allocationBz'].value.length > 25
                ? materialsForm.controls['allocationBz'].value
                : ''
            }}">
            <input matInput formControlName="allocationBz" />
          </div>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="mat-form-field-full-width"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['suffix'].value
          ">
          <mat-label>Suffix</mat-label>
          <div
            matTooltip="{{
              materialsForm.controls['suffix'].value &&
              materialsForm.controls['suffix'].value.length > 50
                ? materialsForm.controls['suffix'].value
                : ''
            }}">
            <input matInput formControlName="suffix" noSpecialChars />
          </div>
        </mat-form-field>
      </mat-dialog-content>

      <mat-dialog-content class="mat-content">
        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['segment'].value
          ">
          <mat-label>Segment</mat-label>
          <input matInput formControlName="segment" appInputAlphanumeric />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['ram'].value
          ">
          <mat-label>RAM</mat-label>
          <input matInput formControlName="ram" appInputAlphanumeric />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['rom'].value
          ">
          <mat-label>ROM</mat-label>
          <input matInput formControlName="rom" appInputAlphanumeric />
        </mat-form-field>
      </mat-dialog-content>

      <mat-dialog-content class="mat-content">
        <mat-form-field appearance="outline">
          <mat-label>Local Color Desc</mat-label>
          <input
            matInput
            formControlName="localColorDesc"
            autocomplete="off"
            appInputAlphanumeric />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['colorDesc'].value
          ">
          <mat-label>Color Desc</mat-label>
          <input matInput formControlName="colorDesc" appInputAlphanumeric />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['internalName'].value
          ">
          <mat-label>Internal Name</mat-label>
          <input matInput formControlName="internalName" appInputAlphanumeric />
        </mat-form-field>
      </mat-dialog-content>

      <mat-dialog-content class="mat-content">
        <mat-form-field
          appearance="outline"
          class="mat-form-field-full-width"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['origin'].value
          ">
          <mat-label>Origin</mat-label>
          <input matInput formControlName="origin" appInputAlphanumeric />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="mat-form-field-full-width"
          [class.mat-form-field-disabled-with-no-values]="
            !materialsForm.controls['upcCode'].value
          ">
          <mat-label>UPC_Code</mat-label>
          <input matInput formControlName="upcCode" appInputAlphanumeric />
        </mat-form-field>

        <mat-form-field appearance="outline" class="mat-form-field-full-width">
          <mat-label>This Product is NPI?</mat-label>
          <mat-select
            formControlName="isNpi"
            name="isNpi"
            disableOptionCentering
            panelClass="panel-select-npi-material">
            <mat-option *ngFor="let isNPI of isNPIEntity" [value]="isNPI.value">
              {{ isNPI.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        class="button modal-action-button"
        mat-button
        (click)="onUpdate()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #modalDevice class="modal-material-device">
  <form [formGroup]="deviceForm" class="form-material-device">
    <header class="title-modal" matDialogTitle>CREATE NEW ACCESSORY</header>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Sales Model</mat-label>
        <input
          matInput
          formControlName="salesModel"
          maxlength="20"
          pattern="[A-Za-z0-9]+"
          class="device-input-uppercase" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Origin</mat-label>
        <mat-select
          formControlName="origin"
          name="origin"
          panelClass="device-input-origin">
          <mat-option *ngFor="let to of originNewDevice" [value]="to.value">
            {{ to.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        class="button modal-action-button"
        mat-button
        (click)="onCreateDevice()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<div class="no-filter-selected-session" *ngIf="noResultMaterial">
  <p>NO RECORDS FOUND.</p>
</div>

<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content
    >Do you confirm the update of the records?</mat-dialog-content
  >
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
