<div class="custom-dialog" *ngIf="data.hasRecords">
  <div class="title-container">
    <div class="title-text">
      <h2 class="title-table">{{ data.title }}</h2>
      <p class="subtitle-table">{{ data.subtitle }}</p>
    </div>
    <button
      class="export-button-modal"
      [ngClass]="{ 'no-export-button': !data.titleExport }"
      matTooltip="Export"
      matTooltipPosition="left"
      (click)="exportData()"></button>
  </div>
  <div class="container-body">
    <div class="table-body-modal">
      <table *ngIf="data.hasRecords">
        <thead>
          <tr>
            <th
              class="header"
              *ngFor="let column of data.displayedColumns; let i = index"
              [ngClass]="getTableStringAndNumber(column)">
              <span>{{ column.label | titlecase }}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let value of getUniqueSubtotal()">
            <ng-container
              *ngFor="let row of getRowsWithSameValue(); let index = index">
              <!-- shouldShowRow() Verifica se a origem da linha corresponde à origem atual no loop -->
              <tr
                *ngIf="
                  this.data.nameModal === 'lastWeek' &&
                  shouldShowRow(row, value)
                "
                [ngClass]="{ 'hovered-row': isRowHovered(row) }"
                (mouseenter)="setHoveredRow(row, true)"
                (mouseleave)="setHoveredRow(row, false)">
                <td
                  *ngFor="let column of data.displayedColumns; let i = index"
                  [ngClass]="getRowColorClass(row, column)">
                  <app-value-currency
                    [ngClass]="getTableStringAndNumber(column)"
                    [value]="
                      row.secondTitle === '' && column.prop === 'family'
                        ? row.secondTitle
                        : row[column.prop]
                    "></app-value-currency>
                </td>
              </tr>
            </ng-container>

            <ng-container
              *ngFor="
                let rowMonth of getRowsForMonth(value);
                let index = index
              ">
              <tr
                *ngIf="this.data.nameModal === 'netOpenPo'"
                [ngClass]="{ 'hovered-row': isRowHovered(rowMonth) }"
                (mouseenter)="setHoveredRow(rowMonth, true)"
                (mouseleave)="setHoveredRow(rowMonth, false)">
                <td *ngFor="let column of data.displayedColumns; let i = index">
                  <app-value-currency
                    [ngClass]="getTableStringAndNumber(column)"
                    [value]="rowMonth[column.prop]"></app-value-currency>
                </td>
              </tr>
            </ng-container>

            <ng-container
              *ngFor="let row of getRowsWithSameValue(); let index = index">
              <tr
                class="subtotal"
                *ngIf="
                  this.data.nameModal === 'lastWeek' &&
                  shouldShowRow(row, value) &&
                  (getDynamicallySubtotalCalc() || row.subtotals)
                ">
                <td
                  *ngFor="let column of data.displayedColumns; let i = index"
                  [ngClass]="getRowColorClass(row, column)">
                  <p *ngIf="i === 0" class="title-subtotal">SUBTOTAL</p>
                  <app-value-currency
                    *ngIf="column.prop === 'QTY' || column.summable"
                    [value]="
                      getDynamicallySubtotalCalc() ||
                      (row.subtotals && row.subtotals[column.prop])
                    ">
                  </app-value-currency>
                </td>
              </tr>
            </ng-container>

            <ng-container>
              <tr class="subtotal" *ngIf="this.data.nameModal === 'netOpenPo'">
                <td *ngFor="let column of data.displayedColumns; let i = index">
                  <p *ngIf="i === 0" class="title-subtotal">SUBTOTAL</p>
                  <app-value-currency
                    *ngIf="column.prop === 'QTY'"
                    [value]="calculateSubtotalByMonth(value)">
                  </app-value-currency>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>

        <tfoot>
          <ng-container
            *ngFor="let row of getRowsWithSameValue(); let index = index">
            <tr class="total" *ngIf="index === 0">
              <td
                *ngFor="let column of data.displayedColumns; let i = index"
                [ngClass]="getRowColorClass(row, column)">
                <p *ngIf="i === 0" class="title-total">TOTAL</p>
                <app-value-currency
                  *ngIf="column.prop === 'QTY' || column.summable"
                  [value]="
                    calculateTotalOfSubtotals() || this.data.total[column.prop]
                  ">
                </app-value-currency>
              </td>
            </tr>
          </ng-container>
        </tfoot>
      </table>
    </div>
    <div *ngIf="data.hasRecords">
      <button (click)="closeModal()" class="button-close-modal">CLOSE</button>
    </div>
  </div>
</div>

<div class="custom-dialog-no-records-found" *ngIf="!data.hasRecords">
  <div class="warning-icon">
    <img src="../../../assets/images/ico-warning.svg" alt="warning-icon" />
  </div>
  <p class="warning">WARNING</p>
  <p class="message">{{ data.messageNoRecords }}</p>
  <div class="close-message">
    <button (click)="closeModal()" class="button-close-modal-warning">
      CLOSE
    </button>
  </div>
</div>
