import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TableComponent } from '../table/table.component';
import { Paginator, columnsStyle } from '../table/table.interface';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-simple-table-check',
  templateUrl: './simple-table-check.component.html',
  styleUrls: ['./simple-table-check.component.scss'],
})
export class SimpleTableCheckComponent extends TableComponent {
  @Input() data: any;
  @Input() title: string;
  @Input() viewMonth!: boolean;
  @Input() dataMonth!: any;
  @Input() tabSelectedMonth!: string;
  @Output() changeTab = new EventEmitter<any>();

  selectdMonth!: any

  constructor(http: HttpClient) {
    super(http);

    this.data = {} as any;
    this.title = '';
  }

  ngOnChanges(): void {
    if (this.data?.table !== undefined) {
      this.table = this.data.table;
      this.columnsLabel = this.table.columns;
      this.dataSource = this.table.rows;
      this.displayedColumns = this.table.columns?.map(
        (column: columnsStyle) => column.label
      );
    }
  }

  isTrue(label: any): any {
    if (label === true) {
      return true
    }
  }

  isNext(label: any): any {
    if (label === 'next' || label === 'current') {
      return true
    }
  }

  isPrevious(label: any): any {
    if (label === 'previous') {
      return true
    }
  }

  isStatus(label: any): any {    
    if (label === 'previous' || label === 'current' || label === 'next') {
      return true
    }
  }

  onChangeTab(tab: string) {
    this.changeTab.emit(tab);
  }
}
