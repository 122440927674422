export const environment = {
  production: true,
  name: 'PROD',
  api: {
    gcaBackEnd: 'https://prod-dot-gca-back-dot-dataservices-non-prod.appspot.com/'
  },
  urlAuth: 'https://prod-dot-gca-back-dot-dataservices-non-prod.appspot.com/auth',
  gapiClientId: '712306587835-tgf227aslg2dd5lp1m9esr6dm8ailvlk.apps.googleusercontent.com',
  idleDetection: 30,
  idleOnTimeOut: 30,
};
