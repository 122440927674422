import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './modules/home.component';
import { ChannelComponent } from './modules/setup/channel/channel.component';
import { CountryComponent } from './modules/setup/country/country.component';
import { GeoComponent } from './modules/setup/geo/geo.component';
import { SalesComponent } from './modules/setup/sales/sales.component';
import { OrderAdminComponent } from './modules/setup/order-admin/order-admin.component';
import { SetupComponent } from './modules/setup/setup.component';
import { CustomerManagementComponent } from './modules/setup/customer-management/customer-management.component';
import { CustomerShortComponent } from './modules/setup/customer-short/customer-short.component';
import { FiscalCalendarComponent } from './modules/setup/fiscal-calendar/fiscal-calendar.component';
import { SetupManagementComponent } from './modules/setup/setup-management/setup-management.component';
import { MaterialsComponent } from './modules/materials/materials.component';
import { AuthorizationComponent } from './modules/authorization/authorization.component';
import { KaAllocationComponent } from './modules/ka-allocation/ka-allocation.component';
import { KaManagementComponent } from './modules/ka-allocation/ka-management/ka-management.component';
import { CarriersInfoComponent } from './modules/ka-allocation/carriers-info/carriers-info.component';
import { BrazilDemandsComponent } from './modules/ka-allocation/brazil-demands/brazil-demands.component';
import { AllocationKaAllocationComponent } from './modules/ka-allocation/allocation-ka-allocation/allocation-ka-allocation.component';
import { OnePlanPrincipalComponent } from './modules/shipment-plan/one-plan.component';
import { UploadOnePlanComponent } from './modules/shipment-plan/upload-one-plan/upload-one-plan.component';
import { CustomerAllocationRetailComponent } from './modules/customer-allocation-retail/customer-allocation-retail.component';
import { AllocationRetailComponent } from './modules/customer-allocation-retail/allocation-retail/allocation-retail.component';
import { PpmComponent } from './modules/customer-allocation-retail/allocation-retail/ppm/ppm.component';
import { AuthGuard } from './services/guards/auth.guard';
import { UnauthorizedComponent } from './modules/authorization/unauthorized/unauthorized.component';
import { PermissionGuard } from './services/guards/permission.guard';
import { OnePlanVariationComponent } from './modules/shipment-plan/one-plan-variation/one-plan-variation.component';
import { CrsdComponent } from './modules/setup/crsd/crsd.component';
import { LocationComponent } from './modules/setup/location/location.component';
import { KaInfoComponent } from './modules/ka-allocation/ka-info/ka-info.component';
import { EmeaAllocationComponent } from './modules/emea-allocation/emea-allocation.component';
import { TerritoryAllocationComponent } from './modules/emea-allocation/territory-allocation/territory-allocation.component';
import { MissingComponent } from './modules/customer-allocation-retail/missing/missing.component';
import { EmeaCustomerSettingsComponent } from './modules/setup/emea-customer-settings/emea-customer-settings.component';
import { OrderAllocationComponent } from './modules/emea-allocation/order-allocation/order-allocation.component';
import { ToBookComponent } from './modules/customer-allocation-retail/to-book/to-book.component';
import { ChartsComponent } from './modules/setup/charts/charts.component';
import { OnePlanComponent } from './modules/shipment-plan/one-plan/one-plan.component';
import { ExecutiveOverviewComponent } from './modules/emea-allocation/executive-overview/executive-overview.component';
import { WowAnalysisComponent } from './modules/emea-allocation/wow-analysis/wow-analysis.component';
import { TerritoryAndProductsViewComponent } from './modules/emea-allocation/territory-and-products-view/territory-and-products-view.component';
import { FairDistributionComponent } from './modules/ka-allocation/fair-distribution/fair-distribution.component';
import { OverbookedComponent } from './modules/customer-allocation-retail/overbooked/overbooked.component';
import { KaAllocationUploadComponent } from './modules/ka-allocation/ka-allocation-upload/ka-allocation-upload.component';
import { CustomerViewComponent } from './modules/customer-allocation-retail/customer-view/customer-view.component';
import { SupplySummaryComponent } from './modules/ka-allocation/supply-summary/supply-summary.component';
import { KaExecutionComponent } from './modules/ka-allocation/ka-execution/ka-execution.component';
import { FamilyNameComponent } from './modules/customer-allocation-retail/allocation-retail/family-name/family-name.component';
import { KaInfoUploadComponent } from './modules/ka-allocation/ka-info/ka-info-upload/ka-info-upload.component';
import { LomComponent } from './modules/lom/lom.component';
import { DcmReportsComponent } from './modules/dcm-reports/dcm-reports.component';
import { DashboardsComponent } from './modules/dcm-reports/dashboards/dashboards/dashboards.component';
import { NotificationsComponent } from './modules/dcm-reports/dashboards/notifications/notifications.component';
import { AdminComponent } from './modules/setup/admin/admin.component';
import { ProfileComponent } from './modules/setup/admin/profile/profile.component';
import { UserComponent } from './modules/setup/admin/user/user.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'authorization',
    pathMatch: 'full',
  },
  {
    path: 'authorization',
    component: AuthorizationComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { breadcrumb: 'Home' },
      },
      {
        path: 'lom',
        children: [
          {
            component: LomComponent,
            path: '',
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'dcm',
        canActivate: [AuthGuard],
        children: [
          {
            component: DcmReportsComponent,
            path: '',
          },
          {
            path: 'dashboards',
            children: [
              {
                path: '',
                children: [
                  {
                    path: '',
                    component: DashboardsComponent,
                    data: { breadcrumb: 'Dcm Reports' },
                  },
                  {
                    component: NotificationsComponent,
                    path: 'notifications',
                    data: { breadcrumb: 'Notifications' },
                  },
                ],
              },
            ],
            canActivate: [PermissionGuard],
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            component: AdminComponent,
            path: '',
          },
          {
            component: ProfileComponent,
            path: 'profile',
          },
          {
            component: UserComponent,
            path: 'user',
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'emea-allocation',
        children: [
          {
            path: '',
            component: EmeaAllocationComponent,
            data: { breadcrumb: 'Emea Allocation' },
          },
          {
            path: 'territory-allocation',
            component: TerritoryAllocationComponent,
            data: { breadcrumb: 'Territory Allocation' },
          },
          {
            path: 'order-allocation',
            component: OrderAllocationComponent,
            data: { breadcrumb: 'Order Allocation' },
          },
          {
            path: 'emea-executive-overview',
            component: ExecutiveOverviewComponent,
            data: { breadcrumb: 'Executive Overview' },
          },
          {
            path: 'emea-wow-analysis',
            component: WowAnalysisComponent,
            data: { breadcrumb: 'Wow Analysis' },
          },
          {
            path: 'emea-territory-products-view',
            component: TerritoryAndProductsViewComponent,
            data: { breadcrumb: 'Territory and Products View' },
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'setup',
        children: [
          {
            component: SetupComponent,
            path: '',
          },
          {
            path: 'admin',
            children: [
              {
                component: AdminComponent,
                path: '',
              },
              {
                component: ProfileComponent,
                path: 'profile',
              },
              {
                component: UserComponent,
                path: 'user',
              },
            ],
            canActivate: [PermissionGuard],
          },
          {
            path: 'location',
            component: LocationComponent,
            data: { breadcrumb: 'Location' },
          },
          {
            path: 'geo',
            component: GeoComponent,
          },
          {
            path: 'country',
            component: CountryComponent,
          },
          {
            path: 'channel',
            component: ChannelComponent,
          },
          {
            path: 'sales-management',
            component: SalesComponent,
            data: { breadcrumb: 'sales management' },
          },
          {
            path: 'order-admin-management',
            component: OrderAdminComponent,
            data: { breadcrumb: 'order admin management' },
          },
          {
            path: 'customer-management',
            component: CustomerManagementComponent,
            data: { breadcrumb: 'customer management' },
          },
          {
            path: 'customer-short',
            component: CustomerShortComponent,
            data: { breadcrumb: 'customer settings' },
          },
          {
            path: 'emea-customer-settings',
            component: EmeaCustomerSettingsComponent,
            data: { breadcrumb: 'customer settings' },
          },
          {
            path: 'setup-management',
            component: SetupManagementComponent,
            data: { breadcrumb: 'setup management' },
          },
          {
            path: 'fiscal-calendar',
            component: FiscalCalendarComponent,
            data: { breadcrumb: 'fiscal calendar' },
          },
          {
            path: 'crsd',
            component: CrsdComponent,
            data: { breadcrumb: 'crsd' },
          },
          // temporario - para visualização dos charts
          {
            path: 'charts',
            component: ChartsComponent,
            data: { breadcrumb: 'charts' },
          },
          {
            path: 'material-management',
            component: MaterialsComponent,
            data: { breadcrumb: 'Materials' },
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'materials',
        children: [
          {
            component: MaterialsComponent,
            path: '',
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'ka-allocation',
        children: [
          {
            component: KaAllocationComponent,
            path: '',
            data: { breadcrumb: 'KA allocation' },
          },
          {
            path: 'ka-management',
            component: KaManagementComponent,
            data: { breadcrumb: 'KA Management' },
          },
          {
            path: 'ka-allocation',
            component: AllocationKaAllocationComponent,
            data: { breadcrumb: 'KA Allocation' },
          },
          {
            path: 'ka-info',
            children: [
              {
                component: KaInfoComponent,
                path: '',
                data: { breadcrumb: 'KA Info' },
              },
              {
                component: KaInfoUploadComponent,
                path: 'ka-info-upload',
                data: { breadcrumb: 'KA Info Upload' },
              },
            ],
          },
          {
            path: 'carries-info',
            component: CarriersInfoComponent,
            data: { breadcrumb: 'Carriers Info' },
          },
          {
            path: 'brazil-demand',
            component: BrazilDemandsComponent,
            data: { breadcrumb: 'Brazil Demands' },
          },
          {
            path: 'ka-execution',
            component: KaExecutionComponent,
            data: { breadcrumb: 'Ka execution' },
          },
          {
            path: 'fair-distribution',
            component: FairDistributionComponent,
            data: { breadcrumb: 'Fair distribution' },
          },
          {
            path: 'ka-allocation-upload',
            component: KaAllocationUploadComponent,
            data: { breadcrumb: 'Ka Allocation Upload' },
          },
          {
            path: 'supply-summary',
            component: SupplySummaryComponent,
            data: { breadcrumb: 'supply summary' },
          },
          {
            path: 'ka-execution',
            component: KaExecutionComponent,
            data: { breadcrumb: 'Ka Execution' },
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'customer-allocation-retail',
        canActivate: [AuthGuard],
        children: [
          {
            component: CustomerAllocationRetailComponent,
            path: '',
            data: { breadcrumb: 'Customer Allocation Retail' },
          },
          {
            path: 'allocation-retail',
            children: [
              {
                component: AllocationRetailComponent,
                path: '',
                data: { breadcrumb: 'Allocation Retail' },
              },
              {
                component: PpmComponent,
                path: 'allocation-ppm',
                data: { breadcrumb: 'PPM' },
              },
              {
                component: FamilyNameComponent,
                path: 'allocation-family-name',
                data: { breadcrumb: 'FAMILY NAME' },
              },
            ],
            canActivate: [PermissionGuard],
          },
          {
            path: 'missing',
            component: MissingComponent,
            data: { breadcrumb: 'Missing' },
          },
          {
            path: 'to-book',
            component: ToBookComponent,
            data: { breadcrumb: 'To Book' },
          },
          {
            path: 'overbooked',
            component: OverbookedComponent,
            data: { breadcrumb: 'Overbooked' },
          },
          {
            path: 'customer-view',
            component: CustomerViewComponent,
            data: { breadcrumb: 'Customer View' },
          },
        ],
      },
      {
        path: 'one-plan',
        children: [
          {
            component: OnePlanPrincipalComponent,
            path: '',
            data: { breadcrumb: 'One Plan' },
          },
          {
            path: 'one-plan',
            component: OnePlanComponent,
            data: { breadcrumb: 'One Plan' },
          },
          {
            path: 'one-plan-upload',
            component: UploadOnePlanComponent,
            data: { breadcrumb: 'One Plan Upload' },
          },
          {
            path: 'flex-one-plan-variation',
            component: OnePlanVariationComponent,
            data: { breadcrumb: 'Flex One Plan Variation' },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: UnauthorizedComponent,
    canActivate: [PermissionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, PermissionGuard],
})
export class AppRoutingModule {}
