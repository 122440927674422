<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="header-title">
    <span class="title">One Plan</span>
    <div class="container-export">
      <span *ngIf="selectedTabIndex === 0" class="one-plan-last-update"
        >One Plan Flex Last Update {{ onePlanLastUpdate }} | Order Book Last
        Update {{ onePlanLastRefresh }}</span
      >
      <span *ngIf="selectedTabIndex === 3" class="one-plan-last-update"
        >One Plan Brazil Last Update {{ onePlanBrColorMix }} | One Plan Last
        Demand {{ onePlanDemandColorMix }}</span
      >
      <div *ngIf="showExportButton">
        <span class="one-plan-icons">
          <button
            (click)="exportTable()"
            class="export-button"
            matTooltip="{{ tooltipMessage }}"
            [class.disabled]="isValid"
            [disabled]="isValid"></button>
          <button
            *ngIf="
              (hasPermissionCreate || hasPermissionEdit) &&
              selectedTabIndex === 0
            "
            class="button-start modal-action-button"
            [class.blink-button-start]="blinkButton"
            (click)="prepareToEdit()"
            mat-button>
            START ONE PLAN
          </button>
        </span>
      </div>
    </div>
  </div>

  <ng-template #modalCreateEdit>
    <form [formGroup]="holdFlagForm" class="form modalHoldFlag">
      <header class="title-modal" matDialogTitle>HOLD FLAG SETUP</header>
      <mat-dialog-content>
        <mat-form-field appearance="outline">
          <mat-label>Hold Flag</mat-label>
          <mat-select
            formControlName="holdFlag"
            name="holdFlag"
            disableOptionCentering
            panelClass="hold-flag-input">
            <mat-option
              *ngFor="let value of holdFlagOptions"
              [value]="value"
              (focusout)="onChangeValueHoldFlag(value)">
              {{ value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button class="button modal-cancel-button" mat-button matDialogClose>
          CANCEL
        </button>
        <button
          class="button modal-action-button"
          [class.disabled]="enableButtonHoldFlag"
          [disabled]="enableButtonHoldFlag"
          mat-button
          (click)="checkDiscardStart()">
          CONFIRM
        </button>
      </mat-dialog-actions>
    </form>
  </ng-template>

  <div class="one-plan-content">
    <mat-tab-group
      (click)="tabClick($event)"
      [selectedIndex]="selectedTabIndex">
      <mat-tab label="One Plan">
        <div
          class="filters"
          [class.form-hide-show]="hidePartialContent"
          [@openClose]="hidePartialContent ? 'open' : 'closed'">
          <app-search
            (searchEvent)="onSearchEvent($event, 'onePlan')"
            (applyFilterEvent)="applyFilter('onePlan')"
            [showButtonApply]="true"
            [enableApplyFilter]="enableApplyFilter"
            [filters]="searchInputsOnePlan"
            [showMoreFiltersLink]="true">
          </app-search>
        </div>
        <div class="hide-filters">
          <a class="link-hide" (click)="toggleHideFilters()">{{
            textshowOrHideFilters
          }}</a>
        </div>
        <mat-divider></mat-divider>

        <div
          class="no-filter-selected-session"
          *ngIf="!tableOnePlan && !tableStart && !tableCheck">
          <p>PLEASE,<br />START BY FILTERING ONE PLAN FIRST</p>
        </div>

        <div
          class="dynamic-table"
          *ngIf="tableOnePlan || tableStart || tableCheck">
          <section class="header-table-allocation" *ngIf="!tableCheck">
            <div class="title-section">
              <h3 class="t-section">{{ fyTable }}</h3>
            </div>
          </section>

          <section class="header-table-allocation" *ngIf="tableCheck">
            <div class="title-section">
              <h3 class="t-section">{{ filterMonthsSelectedCheck }}</h3>
            </div>
            <div>
              <a class="right-link" (click)="tableCheck = false">ONE PLAN</a>
              <a
                class="right-link"
                (click)="tableCheck = true"
                [class.select-button-active-check]="tableCheck"
                >CHECK</a
              >
            </div>
          </section>

          <div class="button-filter" *ngIf="!tableCheck">
            <div *ngIf="(tableOnePlan || tableStart) && !tableCheck">
              <button
                *ngFor="let item of nameMonth; let last = last"
                [class.active]="item === filterMonthsSelected"
                [class.last-item]="last"
                (click)="handleFilterButton(item)">
                {{ item }}
              </button>
            </div>
            <div>
              <a
                class="right-link"
                [class.select-button-active]="!tableCheck"
                (click)="tableCheck = false">
                ONE PLAN
              </a>
              <a class="right-link" (click)="tableCheck = true"> CHECK </a>
            </div>
          </div>
          <app-table-expansive
            (inputChanged)="inputChange()"
            *ngIf="(tableOnePlan || tableStart) && !tableCheck"
            [dataTable]="tableResult">
          </app-table-expansive>

          <app-results
            *ngIf="tableCheck && (!tableOnePlan || !tableStart) && showTable"
            [data]="tableResultCheck"
            [isModeTableOrCardsHide]="isModeTableOrCardsHide"
            [hidePagination]="hidePagination"
            [hideResults]="true">
          </app-results>

          <div
            class="no-results"
            *ngIf="
              showNoResultsCheck && tableCheck && (!tableOnePlan || !tableStart)
            ">
            <p>{{ this.messageNoRecordFound }}</p>
          </div>
          <div class="no-results" *ngIf="showNoResults && !tableCheck">
            <p>{{ this.messageNoRecordFound }}</p>
          </div>
        </div>
        <div class="buttons-right">
          <button
            (click)="backButton()"
            class="button back-action-button"
            mat-button>
            BACK
          </button>
          <div
            [class.show]="isValid"
            *ngIf="!showNoResults && isEditTableMode && !tableCheck">
            <button
              class="button cancel-action-button"
              mat-button
              (click)="onCancel()">
              CANCEL
            </button>
            <button
              *ngIf="hasPermissionCreate || hasPermissionEdit"
              class="button save-action-button"
              mat-button
              (click)="handleSave()">
              SAVE
            </button>
            <button
              *ngIf="
                !showNoResults && (hasPermissionCreate || hasPermissionEdit)
              "
              [class.publish-action-button]="!isValidPublish"
              mat-button
              (click)="handlePublish()"
              [class.disabled]="isValidPublish"
              [disabled]="isValidPublish">
              PUBLISH
            </button>
          </div>
          <ng-container *ngIf="!isEditTableMode">
            <button
              *ngIf="
                !showNoResults &&
                !tableCheck &&
                !isEditTableMode &&
                hasPermissionCreate
              "
              class="button button-start-one-plan publish-action-button"
              mat-button
              (click)="handlePublish()">
              PUBLISH
            </button>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab label="Executive Overview">
        <div
          class="filters"
          [class.form-hide-show]="hidePartialContentExecutiveOverview"
          [@openCloseExecutiveOverview]="
            hidePartialContentExecutiveOverview ? 'open' : 'closed'
          ">
          <app-search
            (searchEvent)="onSearchEvent($event, 'executiveOverview')"
            [filters]="searchInputsExecutiveOverview"
            (applyFilterEvent)="applyFilter('executiveOverview')"
            [showButtonApply]="true"
            [enableApplyFilter]="enableApplyFilter"
            [showMoreFiltersLink]="true">
          </app-search>
        </div>
        <div class="hide-filters">
          <a class="link-hide" (click)="toggleHideFiltersExecutiveOverview()">{{
            textshowOrHideFiltersExecutiveOverview
          }}</a>
        </div>
        <mat-divider></mat-divider>

        <div>
          <div class="container-button">
            <section class="header-table-allocation">
              <div class="title-section">
                <h3 class="t-section">{{ this.currentUser.country }}</h3>
              </div>
            </section>
            <div class="button-filter-executive-overview">
              <a
                class="right-link"
                [class.select-button-active]="tableMontly"
                (click)="getMontlyTable()">
                MONTLY
              </a>
              <a
                class="right-link"
                (click)="getWeeklyTable()"
                [class.select-button-active]="tableWeekly">
                WEEKLY
              </a>
            </div>
          </div>

          <app-simple-table-expansive
            *ngIf="
              this.tableMontly && !this.tableWeekly && !showNoExecutiveOverview
            "
            [dataTable]="tableResultExecutiveOverviewMontly"
            (toggleColumnTable)="
              toggleColumnTable($event)
            "></app-simple-table-expansive>
          <app-simple-table-expansive
            *ngIf="
              this.tableWeekly && !this.tableMontly && !showNoExecutiveOverview
            "
            [dataTable]="tableResultExecutiveOverviewWeekly"
            (toggleColumnTable)="
              toggleColumnTable($event)
            "></app-simple-table-expansive>
        </div>
        <div class="no-filter-selected-session" *ngIf="showNoExecutiveOverview">
          <p>{{ this.messageNoRecordFound }}</p>
        </div>
      </mat-tab>

      <mat-tab label="Wow Variation">
        <div
          [class.form-hide-show]="hideFilterWow"
          [@openCloseWow]="hideFilterWow ? 'open' : 'closed'">
          <app-search
            (searchEvent)="onSearchEvent($event, 'wowVariation')"
            [filters]="searchInputsWowVariation"
            (applyFilterEvent)="applyFilter('wowVariation')"
            [enableApplyFilter]="enableApplyFilter"
            [showButtonApply]="true"
            [showMoreFiltersLink]="true"
            [showMoreFiltersLinkWithMinWidth]="true">
          </app-search>
        </div>
        <div class="hide-filters">
          <a class="link-hide" (click)="toggleWowFilter()">{{
            textShowOrHideWow
          }}</a>
        </div>
        <mat-divider></mat-divider>
        <div [hidden]="!showTableWowVariation">
          <section class="header-table-allocation">
            <div class="title-section">
              <h3 class="t-section">{{ selectedQuarter }}</h3>
              <h4 class="st-section"></h4>
            </div>
          </section>
          <div class="wow-variation">
            <app-results
              [data]="dataWowVariation"
              [hidePagination]="true"
              [hideResults]="true"
              [hasTotalRow]="true"
              [isModeTableOrCardsHide]="true">
            </app-results>
          </div>
        </div>
        <div class="no-filter-selected-session" *ngIf="showNoWowVariation">
          <p>{{ this.messageNoRecordFound }}</p>
        </div>
      </mat-tab>
      <mat-tab label="Color Mix">
        <div
          [class.form-hide-show]="hideFilterColorMix"
          [@openCloseColorMix]="hideFilterColorMix ? 'open' : 'closed'">
          <app-search
            (searchEvent)="onSearchEvent($event, 'colorMix')"
            [filters]="searchInputsColorMix"
            [showMoreFiltersLink]="false"
            (applyFilterEvent)="applyFilter('colorMix')"
            [showButtonApply]="true"
            [enableApplyFilter]="true">
          </app-search>
        </div>
        <div class="hide-filters">
          <a class="link-hide" (click)="toggleColorMixFilter()">{{
            textShowOrHideColorMix
          }}</a>
        </div>
        <mat-divider></mat-divider>
        <div>
          <span style="color: #303030; font-size: 16px; font-weight: bold">{{
            colorMixFY
          }}</span>
        </div>
        <div class="container-color-mix" *ngIf="!noResultColorMix">
          <div class="child-fq2">
            <div>
              <section class="header-table-allocation">
                <div class="title-section">
                  <h3 class="t-section-2" style="color: #515151">
                    {{ colorMixFQ }}
                  </h3>
                </div>
              </section>
            </div>
            <app-table-color-mix
              [dataTable]="dataColorMix.fqCommit"></app-table-color-mix>
          </div>
          <div class="child-month">
            <div>
              <section class="header-table-allocation">
                <div class="title-section">
                  <h3 class="t-section-2" style="color: #515151">
                    MONTH COMMIT
                  </h3>
                </div>
                <div class="button-filter">
                  <button
                    *ngFor="let item of monthButton"
                    (click)="handleButtonMonthCommit(item)"
                    [class.active]="item.value">
                    {{ item.label }}
                  </button>
                </div>
              </section>
            </div>
            <app-table-color-mix
              [dataTable]="monthCommit"></app-table-color-mix>
          </div>
          <div class="child-variation">
            <div>
              <section class="header-table-allocation">
                <div class="title-section">
                  <h3 class="t-section-2" style="color: #515151">
                    VARIATION PROPOSAL - {{ buttonQM ? 'MONTH' : 'QUARTER' }}
                  </h3>
                </div>
                <div class="title-section">
                  <div class="quarters">
                    <button
                      *ngIf="buttonQM"
                      [class.active]="false"
                      (click)="changeButtonQM('quarter')">
                      QUARTER
                    </button>
                    <button
                      *ngIf="!buttonQM"
                      [class.active]="false"
                      (click)="changeButtonQM('month')">
                      MONTH
                    </button>
                  </div>
                </div>
              </section>
            </div>
            <app-table-color-mix
              class="space-variation"
              [dataTable]="varitationProposal">
            </app-table-color-mix>
          </div>
        </div>
        <div class="no-filter-selected-session" *ngIf="noResultColorMix">
          <p>{{ this.messageNoRecordFound }}</p>
        </div>
      </mat-tab>
    </mat-tab-group>
    <button
      class="button back-action-button"
      mat-button
      *ngIf="selectedTabIndex !== 0"
      (click)="backButton()">
      BACK
    </button>
    <ng-template #dialogConfirmationDiscardSaveChanges>
      <header class="title-modal" matDialogTitle>CONFIRMATION</header>
      <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
      <mat-dialog-actions>
        <button class="button modal-cancel-button" mat-button matDialogClose>
          NO
        </button>
        <button
          *ngIf="isDiscardChanges"
          class="button modal-action-button"
          mat-button
          matDialogClose="discard">
          YES
        </button>
        <button
          *ngIf="!isDiscardChanges"
          class="button modal-action-button"
          mat-button
          matDialogClose="save">
          YES
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>
</div>
