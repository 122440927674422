import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
})
export class KeysTablePipePipe implements PipeTransform {
  transform(value: any): string[] {
    return Object.keys(value);
  }
}
