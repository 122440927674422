<table mat-table [dataSource]="dataSource" [ngClass]="zoomIn ? 'zoomIn' : ''">
  <ng-container
    *ngFor="let col of displayedDef; let i = index; trackBy: trackByIndex">
    <ng-container [matColumnDef]="col.props">
      <div *ngIf="col">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="col.style"></th>
        <td
          mat-cell
          *matCellDef="let element; let indexRow = index"
          [class.zoomIn-first-column]="col.props.includes('pdp')"
          [class.zoomIn-column-columnName]="col.props.includes('columnName')"
          [ngStyle]="indexRow !== 0 && col.style">
          <div *ngIf="!col.props.includes('increase')" class="flexItems">
            <span
              *ngIf="!element[col.props]?.enableInput"
              [class.negative-value]="element[col.props]?.value < 0"
              [class.increase-highlight]="checkHighlightOpportunity({indexRow, columnName: col.props})
              ">
              <app-value-currency [value]="element[col.props].value">
              </app-value-currency>
            </span>
            <div
              *ngIf="
                element[col.props]?.enableInput &&
                typeTable === 'order' &&
                indexRow === 1
              "
              class="input-container">
              <input
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                class="input-number"
                placeholder="---"
                maxlength="9"
                value="{{
                  element[col.props].value || '0'
                    | convertValue
                    | number : '1.0-0' : 'en-US'
                }}"
                autocomplete="off"
                (focusout)="onChangeValueInput({indexRow, columnName: col.props }, $event)"
                (keyup.enter)="onChangeValueInput({indexRow, columnName: col.props }, $event)" />
            </div>
            <div
              *ngIf="
                element[col.props]?.enableInput && typeTable === 'territory'
              "
              class="input-container">
              <input
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                class="input-number"
                placeholder="---"
                maxlength="9"
                value="{{
                  element[col.props].value || '0'
                    | convertValue
                    | number : '1.0-0' : 'en-US'
                }}"
                autocomplete="off"
                (focusout)="onChangeValueInput({indexRow, columnName: col.props }, $event)"
                (keyup.enter)="onChangeValueInput({indexRow, columnName: col.props }, $event)" />
            </div>
          </div>
          <div
            *ngIf="
              typeTable === 'order';
              then contentOrder;
              else contentTerritory
            "></div>
          <ng-template #contentOrder>
            <div *ngIf="col.props.includes('Opportunity') && indexRow === 0">
              <span>
                <ng-container *ngFor="let row of pullInOrIncreaseValues">
                  <span *ngIf="row.props === col.props">
                    <button
                      (click)="handlePullInOrIncrease({indexRow, columnName: col.props }, row.mode)"
                      [class.active]="!row.mode"
                      class="button-increase button-order">
                      Increase
                    </button>
                    <button
                      (click)="handlePullInOrIncrease({indexRow, columnName: col.props }, row.mode)"
                      [class.active]="row.mode"
                      class="button-pullIn button-order">
                      Pull In
                    </button>
                  </span>
                </ng-container>
              </span>
            </div>
          </ng-template>
          <ng-template #contentTerritory>
            <div *ngIf="col.props.includes('Opportunity') && indexRow === 0">
              <span>
                <ng-container *ngFor="let row of pullInOrIncreaseValues">
                  <span *ngIf="row.props === col.props">
                    <button
                      (click)="handlePullInOrIncrease({indexRow, columnName: col.props }, row.mode)"
                      [class.active]="!row.mode"
                      class="button-increase">
                      Increase
                    </button>
                    <button
                      (click)="handlePullInOrIncrease({indexRow, columnName: col.props }, row.mode)"
                      [class.active]="row.mode"
                      class="button-pullIn">
                      Pull In
                    </button>
                  </span>
                </ng-container>
              </span>
            </div>
          </ng-template>
        </td>
      </div>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
</table>
<ng-template #dialogConfirmationDiscardSaveChanges>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      *ngIf="isDiscardChanges"
      class="button modal-action-button"
      mat-button
      matDialogClose="discard">
      YES
    </button>
    <button
      *ngIf="!isDiscardChanges"
      class="button modal-action-button"
      mat-button
      matDialogClose="save">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
