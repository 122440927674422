import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';
import { ToastrService } from 'ngx-toastr';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  take,
  takeUntil,
} from 'rxjs/operators';

import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { InputBase } from './input-base';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class InputComponent {
  @Input() inputs!: InputBase<string>;
  @Input() form!: FormGroup;
  @Input() typeGEO!: string;
  @Output() searchEvent = new EventEmitter<any>();
  @Output() selectSearch = new EventEmitter<number>();
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild(MatSelect) matSelectTrigger!: MatSelect;
  @ViewChild('matSelectSearch') matSelectSearch!: MatSelectSearchComponent;
  selectedCountry = '';
  textBoxInputValue = new Subject<string>();
  DELAY_TIME = 2000;
  trigger = this.textBoxInputValue.pipe(
    debounceTime(this.DELAY_TIME),
    distinctUntilChanged()
  );
  subscriptions: Subscription[] = [];
  onDestroyComponent = new Subject<void>();
  inputFilterFormControl = new FormControl();
  filteredOptionsMultiSelect: ReplaySubject<any[]> = new ReplaySubject<any[]>(
    1
  );
  filteredOptionsMultiSelectCache: any[] = [];
  filteredOptions?: Observable<{ key: string; value: string }[]>;
  showClearFilterDate: boolean = false;
  isIndeterminate = false;
  isChecked = false;
  quantOptionsSelected = 0;
  showArrowDropDown = false;
  maxSelection: boolean = false;
  countSelection: number = 0;
  optionSelected: any = [];
  countSelectionCustomerName: number = 0;
  optionSelectedCustomerName: any = [];
  countSelectionCustomerNumber: number = 0;
  optionSelectedCustomerNumber: any = [];
  countSelectionFamily: number = 0;
  optionSelectedFamily: any = [];
  countSelectionCustomerShort: number = 0;
  optionSelectedCustomerShort: any = [];
  countSelectionCustomerShortId: number = 0;
  optionSelectedCustomerShortId: any = [];
  isFormFieldFocused = false;
  panelClass!: string;

  get isValid() {
    return this.form.controls[this.inputs.key]?.valid;
  }

  constructor(private toastr: ToastrService) {}

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputs?.currentValue) {
      if (this.inputs.controlType !== 'multiselect-search') {
        if (this.inputs.key !== undefined) {
          this.form.controls[this.inputs.key]?.setValue(this.inputs.value);
        }
      }
    }

    this.panelClass = this.inputs.largeInput
      ? 'multiselect-search-large-input'
      : 'multiselect-search-normal-input';
  }

  ngOnInit() {
    this.inputWithAutocomplete();
    this.inputSubscription();
    this.inputMultiselectSearch();
  }

  // tipo com autocomplete
  inputWithAutocomplete() {
    if (this.inputs.hasAutocomplete) {
      this.filteredOptions = this.form.controls[
        this.inputs.key
      ]?.valueChanges.pipe(
        startWith(this.inputs.value || ''),
        map(val => this.filterValues(val))
      );
    }
  }

  inputSubscription() {
    if (this.inputs.subscription) {
      const subscription = this.trigger.subscribe(value => {
        this.searchEvent.emit(this.form.value);
      });
      this.subscriptions.push(subscription);
    }
  }

  isControlTypeMultiselectSearch() {
    return this.inputs.controlType === 'multiselect-search';
  }

  // tipo multiselect
  inputMultiselectSearch() {
    if (this.isControlTypeMultiselectSearch()) {
      this.filteredOptionsMultiSelectCache = this.inputs.options.slice();
      this.filteredOptionsMultiSelect.next(
        this.filteredOptionsMultiSelectCache
      );

      this.inputFilterFormControl.valueChanges
        .pipe(takeUntil(this.onDestroyComponent))
        .subscribe(() => {
          this.filterOptionsMultiSelect();
          this.setToggleAllCheckboxState();
        });

      this.form.controls[this.inputs.key]?.valueChanges
        .pipe(takeUntil(this.onDestroyComponent))
        .subscribe(() => {
          this.setToggleAllCheckboxState();
        });

      this.setInitialValue();
    }
  }

  ngAfterViewInit() {
    if (
      this.isControlTypeMultiselectSearch() &&
      this.inputs.value?.length > 0
    ) {
      this.setCustomTrigger();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.onDestroyComponent.next();
    this.onDestroyComponent.complete();
  }

  // valores dos inputs
  filterValues(value: any): { key: string; value: string }[] {
    if (value && value.length > 0) {
      value = `${value}`;
      return this.inputs.options.filter(
        option => option.value.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    }
    return this.inputs.options;
  }

  // evento de pesquisa
  onSearchFilter(
    controlType: string,
    formControl: any,
    event: any,
    hasCheckFlowException: boolean | undefined
  ) {
    const formValues: { [key: string]: any } = {};

    this.inputFormValues(hasCheckFlowException, event, formValues, formControl);
    this.controlTypeTextBox(controlType, formControl, formValues);
    this.showClearFilterDate = true;
  }

  inputFormValues(
    hasCheckFlowException: boolean | undefined,
    event: any,
    formValues: any,
    formControl: any
  ) {
    Object.keys(this.form.controls).forEach((keyFormControl: string) => {
      formValues[keyFormControl] = this.form.controls[keyFormControl].value;
    });
    this.countSelection = formValues.productDesc?.length;
    this.optionSelected = formValues.productDesc;
    this.countSelectionCustomerName = formValues.customerName?.length;
    this.optionSelectedCustomerName = formValues.customerName;
    this.countSelectionCustomerNumber = formValues.customerNumber?.length;
    this.optionSelectedCustomerNumber = formValues.customerNumber;
    this.countSelectionFamily = formValues.family?.length;
    this.optionSelectedFamily = formValues.family;
    this.countSelectionCustomerShort = formValues.customerShortId?.length;
    this.optionSelectedCustomerShort = formValues.customerShortId;
    this.countSelectionCustomerShortId = formValues.customerShortId?.length;
    this.optionSelectedCustomerShortId = formValues.customerShortId;
    this.checkSelectedFiveValues(formValues);
    this.checkSelectedHundredValuesCustomerName(formValues);
    this.checkSelectedHundredValuesCustomerNumber(formValues);
    this.checkSelectedHundredValuesCustomerShortId(formValues);
    this.checkSelectedFilterWithLimit(formValues);

    if (hasCheckFlowException) {
      formValues.formControl = formControl;
      formValues.event = event;
      formValues.panelTrigger =
        this.autocompleteTrigger || this.matSelectTrigger;
      formValues.nameFormControl = this.inputs.key;
      formValues.action = 'search';
      this.showArrowDropDown = true;
    }
  }

  // caso o tipo do input seja textBox
  controlTypeTextBox(controlType: string, formControl: any, formValues: any) {
    switch (controlType) {
      case 'textbox':
        if (!formControl.value?.length) {
          this.searchEvent.emit(formValues);
        } else {
          this.textBoxInputValue.next(formControl.value);
        }
        break;
      default:
        this.searchEvent.emit(formValues);
    }
  }

  messageToastMaximumSelected() {
    return this.toastr.warning('Maximum selection 5 items', 'Products');
  }

  messageToastMaximumSelectedCustomerNameAndCustomerNumber() {
    return this.toastr.warning('Maximum selection 100 items');
  }

  checkSelectedFiveValues(data: any) {
    if (this.inputs.key === 'productDesc' && data.productDesc != null) {
      if (data.productDesc.length >= 5) this.messageToastMaximumSelected();
    }
  }
  checkSelectedHundredValuesCustomerName(data: any) {
    if (this.inputs.key === 'customerName' && data.customerName != null) {
      if (data.customerName.length >= 100) this.messageToastMaximumSelected();
    }
  }

  checkSelectedHundredValuesCustomerNumber(data: any) {
    if (this.inputs.key === 'customerNumber' && data.customerNumber != null) {
      if (data.customerNumber.length >= 100)
        this.messageToastMaximumSelectedCustomerNameAndCustomerNumber();
    }
  }

  checkSelectedHundredValuesCustomerShortId(data: any) {
    if (
      this.inputs.key === 'customerShortId' &&
      this.inputs.module === 'customer-view' &&
      !this.inputs.limit &&
      data.customerShortId != null
    ) {
      if (data.customerShortId.length >= 3)
        this.toastr.warning('Maximum selection 3 items');
    }
  }
  checkSelectedFilterWithLimit(data: any) {
    if (this.inputs.valueForFilterLimit) {
      if (data.customerShortId.length >= this.inputs.valueForFilterLimit)
        this.toastr.warning(
          `Maximum selection ${this.inputs.valueForFilterLimit} items`
        );
    }
  }

  disabledOption(data: any): boolean {
    if (this.countSelection > 4 && !this.optionSelected.includes(data))
      return true;
    if (this.countSelection === 0 && this.maxSelection) return false;
    return false;
  }

  disabledOptionCustomerName(data: any): boolean {
    if (
      this.countSelectionCustomerName > 99 &&
      !this.optionSelectedCustomerName.includes(data)
    )
      return true;
    if (this.countSelectionCustomerName === 0 && this.maxSelection)
      return false;
    return false;
  }

  disabledOptionCustomerNumber(data: any): boolean {
    if (
      this.countSelectionCustomerNumber > 99 &&
      !this.optionSelectedCustomerNumber.includes(data)
    )
      return true;
    if (this.countSelectionCustomerNumber === 0 && this.maxSelection)
      return false;
    return false;
  }

  disabledOptionFamily(data: any): boolean {
    if (this.inputs.module === 'family-name') {
      if (
        this.countSelectionFamily > 0 &&
        !this.optionSelectedFamily.includes(data)
      ) {
        return true;
      }
      if (this.countSelectionFamily === 0 && this.maxSelection) return false;
      return false;
    } else {
      if (
        this.countSelectionFamily > 2 &&
        !this.optionSelectedFamily.includes(data)
      ) {
        return true;
      }
      if (this.countSelectionFamily === 0 && this.maxSelection) return false;
    }
    return false;
  }

  disabledOptionCustomerShort(data: any): boolean {
    if (
      this.countSelectionCustomerShort > 2 &&
      !this.optionSelectedCustomerShort.includes(data)
    )
      return true;
    if (this.countSelectionCustomerShort === 0 && this.maxSelection)
      return false;
    return false;
  }

  disabledOptionWithlimitedValue(data: any) {
    if (this.inputs.valueForFilterLimit) {
      if (
        this.countSelectionCustomerShort === this.inputs.valueForFilterLimit &&
        !this.optionSelectedCustomerShort.includes(data)
      )
        return true;
    }
    if (this.countSelectionCustomerShort === 0 && this.maxSelection) {
      return false;
    }
    return false;
  }

  clearSearch(event: any, hasCheckFlowException: boolean | undefined) {
    this.maxSelection = true;
    this.isChecked = false;
    this.isIndeterminate = false;
    this.countSelection = 0;
    this.countSelectionCustomerName = 0;
    this.countSelectionCustomerNumber = 0;
    this.countSelectionCustomerShortId = 0;
    this.countSelectionCustomerShort = 0;
    this.countSelectionFamily = 0;
    const formValues: { [key: string]: any } = { ...this.form.value };

    if (hasCheckFlowException) {
      formValues.formControl = this.form.controls[this.inputs.key];
      formValues.nameFormControl = this.inputs.key;
      formValues.action = 'clear';
      event.stopPropagation();
    }

    this.form.controls[this.inputs.key]?.reset();
    formValues[this.inputs.key] = '';
    this.searchEvent.emit(formValues);

    if (this.isControlTypeMultiselectSearch()) {
      this.setCustomTrigger();
      this.showArrowDropDown = true;
    }
  }

  clearFilterDate(event: any) {
    event.stopPropagation();
    this.form.controls.date.setValue(null);
    this.showClearFilterDate = false;
  }

  setInitialValue() {
    this.filteredOptionsMultiSelect
      .pipe(take(1), takeUntil(this.onDestroyComponent))
      .subscribe((options: any) => {
        const inputValuesId = this.inputs.value
          ? this.inputs.value.map((item: any) => item.id)
          : [];
        const defaultOption = options.filter(
          (option: any) =>
            this.inputs.value && inputValuesId.includes(option.id)
        );
        this.form.controls[this.inputs.key]?.patchValue(defaultOption);
      });
  }

  toggleSelectAll(isSelectAllValues: boolean) {
    this.filteredOptionsMultiSelect
      .pipe(take(1), takeUntil(this.onDestroyComponent))
      .subscribe((val: any) => {
        if (isSelectAllValues) {
          this.form.controls[this.inputs.key]?.patchValue(val);
        } else {
          this.form.controls[this.inputs.key]?.patchValue([]);
        }
      });

    this.onSearchFilter(
      'multiselect-search',
      this.form.controls[this.inputs.key],
      isSelectAllValues,
      this.inputs.hasCheckFlowException
    );
  }

  filterOptionsMultiSelect() {
    if (!this.inputs.options) return;

    let search = this.inputFilterFormControl.value;

    if (!search) {
      this.filteredOptionsMultiSelectCache = this.inputs.options.slice();
      this.filteredOptionsMultiSelect.next(
        this.filteredOptionsMultiSelectCache
      );
      return;
    }
    search = search.toLowerCase();

    this.filteredOptionsMultiSelectCache = this.inputs.options.filter(
      option => option.value.toLowerCase().indexOf(search) > -1
    );
    this.filteredOptionsMultiSelect.next(this.filteredOptionsMultiSelectCache);
  }

  setToggleAllCheckboxState() {
    let filteredLength = 0;

    if (
      this.form.controls[this.inputs.key] &&
      this.form.controls[this.inputs.key]?.value
    ) {
      this.filteredOptionsMultiSelectCache.forEach(item => {
        if (this.form.controls[this.inputs.key]?.value.indexOf(item) > -1) {
          filteredLength++;
        }
      });

      this.isIndeterminate =
        filteredLength > 0 &&
        filteredLength < this.filteredOptionsMultiSelectCache.length;

      this.isChecked =
        filteredLength > 0 &&
        filteredLength === this.filteredOptionsMultiSelectCache.length;

      if (this.matSelectSearch) {
        this.setCustomTrigger();
      }
    }
  }

  setCustomTrigger() {
    if (this.typeGEO === 'EMEA') {
      let qtdOptionsSelected = this.form.get(this.inputs.key)?.value?.length;
      let qtdOptions = this.inputs.options.length;

      if (qtdOptionsSelected == qtdOptions) {
        this.matSelectSearch.matSelect.trigger.nativeElement.children[0].innerText =
          'All';
        return;
      }
    }

    this.quantOptionsSelected =
      this.form.get(this.inputs.key)?.value?.length || 0;

    this.matSelectSearch.matSelect.trigger.nativeElement.children[0].innerText =
      this.quantOptionsSelected > 0
        ? `${this.quantOptionsSelected} Selected`
        : '';
  }

  onKeyPress(event: any) {
    if (
      this.inputs.key === 'product_group' ||
      this.inputs.key === 'territory' ||
      this.inputs.key === 'color' ||
      this.inputs.key === 'ram' ||
      this.inputs.key === 'sim' ||
      this.inputs.key === 'fully_shipped'
    ) {
      if (
        event.relatedTarget === null &&
        this.form.controls[this.inputs.key]?.value !== undefined
      ) {
        this.form.controls[this.inputs.key]?.reset();
      }
    }
  }
}
