<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">CUSTOMER SETTINGS</span>
    <div class="container-export-customer-short">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="!isValid"
          [disabled]="!isValid"></button>
      </span>
      <button
        class="button modal-action-button"
        *ngIf="hasPermissionCreate && selectedTabIndex === 0"
        mat-button
        (click)="prepareToCreate()">
        NEW CUSTOMER SHORT
      </button>
    </div>
  </div>
  <mat-tab-group (click)="tabClick($event)" [selectedIndex]="selectedTabIndex">
    <mat-tab label="CUSTOMER SHORT">
      <app-search
        (searchEvent)="onSearchEvent($event)"
        [filters]="searchInputs"></app-search>
      <span class="line"></span>
      <app-results
        [data]="resultCustomerShort"
        (eventActions)="onEventActions($event)"
        (resultPaginator)="onChangePaginator($event)"
        [isModeTableAccordion]="isModeTableAccordion"
        [isModeTableOrCardsHide]="isModeTableOrCardsHide"
        *ngIf="!noResultCustomerShort"></app-results>
      <div class="no-filter-selected-session" *ngIf="noResultCustomerShort">
        <p>NO RECORDS FOUND.</p>
      </div>
      <button
        class="button back-action-button"
        mat-button
        (click)="backButton()">
        BACK
      </button>

      <ng-template #modalCreateEdit>
        <form [formGroup]="customerForm" class="form-customer-short">
          <header class="title-modal" matDialogTitle>
            {{ isEdit ? 'EDIT CUSTOMER SHORT' : 'NEW CUSTOMER SHORT' }}
          </header>
          <mat-dialog-content>
            <mat-form-field appearance="outline">
              <mat-label>Customer Short</mat-label>
              <input matInput formControlName="customerShort" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Customer PO Line Number</mat-label>
              <input matInput formControlName="customerPoLineNumber" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="mat-select-search">
              <mat-label>Channel</mat-label>
              <mat-select
                [formControl]="channelMultiCtrl"
                panelClass="multiselect-search-customer-short"
                placeholder="Channel"
                disableOptionCentering>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search..."
                    noEntriesFoundLabel="no matching found"
                    aria-placeholder="Search"
                    [formControl]="
                      channelMultiFilterCtrl
                    "></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let channel of filteredChannelMulti | async"
                  [value]="channel.id"
                  matTooltip="{{ channel.channel }}">
                  {{ channel.channel }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="channelMultiCtrl.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="clearChannel($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-dialog-content>

          <mat-dialog-content>
            <mat-form-field appearance="outline" class="mat-select-search">
              <mat-label>Country</mat-label>
              <mat-select
                [formControl]="countryMultiCtrl"
                panelClass="multiselect-search-customer-short"
                placeholder="Country"
                [disabled]="true"
                disableOptionCentering>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search..."
                    noEntriesFoundLabel="no matching found"
                    aria-placeholder="Search"
                    [formControl]="
                      countryMultiFilterCtrl
                    "></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let country of filteredCountryMulti | async"
                  [value]="country.id"
                  matTooltip="{{ country.country }}">
                  {{ country.country }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="mat-select-search">
              <mat-label>Order Admin</mat-label>
              <mat-select
                [formControl]="orderAdminMultiCtrl"
                panelClass="multiselect-search-customer-short"
                placeholder="Order Admin"
                disableOptionCentering>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search..."
                    noEntriesFoundLabel="no matching found"
                    aria-placeholder="Search"
                    [formControl]="
                      orderAdminMultiFilterCtrl
                    "></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let orderAdmin of filteredOrderAdminMulti | async"
                  [value]="orderAdmin.id"
                  matTooltip="{{ orderAdmin.last_name }}, {{
                    orderAdmin.name
                  }}, {{ orderAdmin.email }}">
                  {{ orderAdmin.last_name }}, {{ orderAdmin.name }}
                  <span class="additional-selection"
                    >({{ orderAdmin.email }})</span
                  >
                </mat-option>
              </mat-select>
              <button
                *ngIf="orderAdminMultiCtrl.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="clearOrderAdmin($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="outline" class="mat-select-search">
              <mat-label>Sales Manager</mat-label>
              <mat-select
                [formControl]="salesManagerMultiCtrl"
                panelClass="multiselect-search-customer-short"
                placeholder="Sales Manager"
                disableOptionCentering>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search..."
                    noEntriesFoundLabel="no matching found"
                    aria-placeholder="Search"
                    [formControl]="
                      salesManagerMultiFilterCtrl
                    "></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let salesManager of filteredSalesManagerMulti | async"
                  [value]="salesManager.id"
                  matTooltip="{{ salesManager.last_name }}, {{
                    salesManager.name
                  }}, {{ salesManager.email }}">
                  {{ salesManager.last_name }}, {{ salesManager.name }}
                  <span class="additional-selection"
                    >({{ salesManager.email }})</span
                  >
                </mat-option>
              </mat-select>
              <button
                *ngIf="salesManagerMultiCtrl.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="clearSalesManager($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-dialog-content>
          <mat-dialog-content>
            <mat-form-field appearance="outline" class="mat-select-search">
              <mat-label>Status</mat-label>
              <mat-select
                formControlName="status"
                panelClass="panel-select-multiple-status"
                name="status"
                disableOptionCentering>
                <mat-option
                  *ngFor="let status of statusEntity"
                  [value]="status.value">
                  {{ status.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Taxes Application</mat-label>
              <mat-select
                formControlName="taxes"
                name="taxes"
                disableOptionCentering
                multiple
                panelClass="panel-select-multiple">
                <mat-option
                  *ngFor="let tax of taxesApplicablesEntity"
                  [value]="tax.id"
                  matTooltip="{{ tax.tax }}">
                  {{ tax.tax }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="customerForm.controls.taxes.value?.length > 0"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="clearTaxes($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Customer Numbers</mat-label>
              <mat-select
                class="mat-select-search"
                [formControl]="customerNameMultiCtrl"
                panelClass="multiselect-search-number-customer-short"
                inputDirective
                placeholder="Customer Number"
                [multiple]="true"
                disableOptionCentering>
                <mat-option>
                  <ngx-mat-select-search
                    placeholderLabel="Search..."
                    noEntriesFoundLabel="no matching found"
                    aria-placeholder="Search"
                    [formControl]="customerNameMultiFilterCtrl"
                    #matSelectSearch>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let customer of filteredCustomerNameMulti | async"
                  [value]="customer.id"
                  (click)="checkIfAssociated(customer, matOption)"
                  matTooltip="{{ customer.customerNumber }}"
                  #matOption>
                  {{ customer.customerNumber }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="customerNameMultiCtrl.value?.length > 0"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="clearCustomerNumber($event)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </mat-dialog-content>
          <div class="margin-footer"></div>
          <mat-dialog-actions>
            <button
              class="button modal-cancel-button"
              mat-button
              matDialogClose>
              CANCEL
            </button>
            <button
              class="button modal-action-button"
              mat-button
              (click)="onUpdate()">
              SAVE
            </button>
          </mat-dialog-actions>
        </form>
      </ng-template>
      <ng-template #dialogConfirmation>
        <header class="title-modal" matDialogTitle>CONFIRMATION</header>
        <mat-dialog-content>
          {{
            isEdit === 'delete'
              ? 'Do you confirm to delete this Customer Short?'
              : ''
          }}
          {{
            isEdit === true ? 'Do you confirm to edit this Customer Short?' : ''
          }}
          {{
            isEdit === false
              ? 'Do you confirm to create this Customer Short?'
              : ''
          }}
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="button modal-cancel-button" mat-button matDialogClose>
            NO
          </button>
          <button
            class="button modal-action-button"
            mat-button
            matDialogClose="false">
            YES
          </button>
        </mat-dialog-actions>
      </ng-template>
      <ng-template #dialogAssociation>
        <header class="title-modal" matDialogTitle>CONFIRMATION</header>
        <mat-dialog-content>
          {{ messageModalAssociation }}
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="button modal-cancel-button" mat-button matDialogClose>
            NO
          </button>
          <button
            class="button modal-action-button"
            mat-button
            matDialogClose="false">
            YES
          </button>
        </mat-dialog-actions>
      </ng-template>
    </mat-tab>
    <mat-tab label="CUSTOMER LIST">
      <app-search
        (searchEvent)="onSearchEvent($event)"
        [filters]="searchInputsList"></app-search>
      <span class="line"></span>
      <div class="button-filter">
        <button
          *ngFor="let item of filterTable"
          (click)="setFilterTableSelected(item)"
          [class.active]="item.value === filterTableSelected">
          {{ item.label }}
        </button>
      </div>
      <app-results
        class="table-customer-settings"
        [filterTable]="filterTable"
        [filterTableSelected]="searchAssociated"
        [filterAssociatedOrNot]="false"
        (setFilterTableSelected)="setFilterTableSelected($event)"
        [isModeTableOrCardsHide]="true"
        [data]="resultCustomerList"
        (eventActions)="onEventActions($event)"
        (resultPaginator)="onChangePaginator($event)"
        [isModeTableAccordion]="isModeTableAccordion"
        *ngIf="!noResultCustomerList">
      </app-results>

      <div class="no-filter-selected-session" *ngIf="noResultCustomerList">
        <p>NO RECORDS FOUND.</p>
      </div>

      <button
        class="button back-action-button"
        mat-button
        (click)="backButton()">
        BACK
      </button>
    </mat-tab>
  </mat-tab-group>
</div>
