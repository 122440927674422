<ngx-spinner></ngx-spinner>
<div id="crsd" class="container">
    <div class="header-title">
        <span class="title">CRSD</span>
        <span
          class="material-icons"
          (click)="exportTable()"
          matTooltip="{{ tooltipMessage }}">
          <button
            class="export-button"
            [class.disabled]="showNoResults"
            [disabled]="showNoResults"></button>
        </span>
    </div>
    <app-search (searchEvent)="onSearchEvent($event)" [filters]="searchInputs"></app-search>
    <span class="line"></span>
    <div class="calendar-crsd">

        <div class="no-filter-selected-session" *ngIf="showNoResults;">
            <p>NO RECORDS FOUND.</p>
        </div>
        <div class="fiscal-year" *ngIf="!showNoResults;">
            <span>Fiscal Year {{currentYear}}/{{currentYear+1}}</span>
        </div>
        <div class="quarters" *ngIf="!showNoResults;">
            <button *ngFor="let row of quarters" [class.active]="row.key === filter.quarter" (click)="onChangeQuarter(row.key, row)">
                {{row.value}}
            </button>
        </div>
        <div class="calendar-overflow" #overflowCalendarRef>
            <div class="table-container" *ngFor="let row of calendarAPI; let keyCalendar = index;">
                <table class="calendar-month">
                    <tbody>
                        <tr>
                            <th>
                                <mat-card style="justify-content: left;">
                                    <span>DATE</span>
                                </mat-card>
                            </th>
                            <th *ngFor="let week of row.weeks" [class.activePosition]="week.active && week.value != null">
                                <mat-card [style.background]="week.active && week.value != null ? '#ff554d' : '#001428'">
                                    <span>{{week.value ? changeWeek(week.value) : '--'}}</span>
                                </mat-card>
                            </th>
                        </tr>
                        <tr *ngFor="let days of row.calendar; let keyDays = index" >
                            <td>
                                <div class="box">
                                    <span class="upper">{{days.weekday}} </span>
                                    <span>{{days.date}}</span>
                                </div>
                            </td>
                            <td *ngFor="let day of days.dates; let keyDay = index;">
                                <div class="box">
                                    <app-input-date (changeDate)="onChangeDay({keyCalendar, keyDays, keyDay}, $event)" [value]="day.value"></app-input-date>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="line-break" *ngIf="(keyCalendar + 1) % 3 === 0"></div>
            </div>
        </div>
        
        <div *ngIf="editButtons" class="buttons">
            <button class="button back-action-button" mat-button (click)="onCancel()">CANCEL</button>
            <button class="button modal-action-button" mat-button (click)="onSave()">SAVE</button>
        </div>
    </div>

    <button class="button back-action-button" mat-button (click)="backButton()">BACK</button>

</div>

<ng-template #dialogConfirmationDiscardSaveChanges>
    <header class="title-modal" matDialogTitle>CONFIRMATION</header>
    <mat-dialog-content>{{messageModalChanges}}</mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>NO</button>
      <button *ngIf="editButtons" class="button modal-action-button" mat-button matDialogClose="save">YES</button>
    </mat-dialog-actions>
</ng-template>