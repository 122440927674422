import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RemovePastWeekService {
  removePastWeek(setup: any): any {
    let toAllocate: string = '';
    let toBook: string = '';
    const allocate: any = [];
    const book: any = [];
    const currentWeek = setup.currentWeek.replace('week', '');

    setup.toAllocate.split(',').forEach((item: any) => {
      allocate.push(item.trim());
    });

    allocate.sort().forEach((item: any) => {
      let allocate = item.replace('Wk', '').trim();
      if (allocate >= currentWeek) {
        toAllocate += `${item},`;
      }
    });

    setup.toBook.split(',').forEach((item: any) => {
      book.push(item.trim());
    });

    book.sort().forEach((item: any) => {
      let book = item.replace('Wk', '').trim();
      if (book >= currentWeek) {
        toBook += `${item},`;
      }
    });

    setup.toAllocate = toAllocate.slice(0, -1);
    setup.toBook = toBook.slice(0, -1);

    return setup;
  }
}
