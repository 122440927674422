import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/authorization/auth.service';

@Component({
  selector: 'app-allocation-retail',
  templateUrl: './allocation-retail.component.html',
  styleUrls: ['./allocation-retail.component.scss'],
})
export class AllocationRetailComponent implements OnInit {
  isAuthorized: boolean;
  itemsList: any[] = [
    {
      name: 'PPM',
      url: '../../../assets/images/ico-ppm.svg',
      path: 'allocation-ppm',
    },
    /* TBD */
    {
      name: 'FAMILY NAME',
      url: '../../../assets/images/ico-market-name.svg',
      path: 'allocation-family-name',
    },
  ];

  constructor(private authService: AuthService, private router: Router) {
    this.isAuthorized = this.authService.isLoggedIn();
  }

  ngOnInit(): void {
    if (this.isAuthorized) {
      const permissionResource = this.authService
        .getUserPermissions()
        .some((data: any) => data.resource === 'allocation-retail');
      if (!permissionResource) {
        this.router.navigate(['unauthorized']);
      }
    }
  }

  backButton() {
    this.router.navigate(['home/customer-allocation-retail']);
  }
}
