import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-value-currency',
  templateUrl: './value-currency.component.html',
  styleUrls: ['./value-currency.component.scss'],
})
export class ValueCurrencyComponent implements OnInit {
  @Input() public value: any = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: any) {
    this.value = changes.value.currentValue
      ? changes.value.currentValue.toLocaleString('en-GB')
      : changes.value.currentValue;
  }
}
