<!-- <div class="stacked-column">
  <app-stacked-column></app-stacked-column>
</div> -->

<div class="column-marker">
  <app-column-marker-chart></app-column-marker-chart>
</div>

<!-- <div class="column-marker">
  <app-line-marker-chart></app-line-marker-chart>
</div> -->

<div class="clusted-column">
  <app-clusted-column-chart></app-clusted-column-chart>
</div>

<div class="bar-chart">
  <app-bar-chart></app-bar-chart>
</div>
