import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  InputBase,
  MultiSelectSearchInput,
} from 'src/app/components/input/input-base';
import GetEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { CrudService } from 'src/app/services/generic/crud.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { Ka } from 'src/app/interfaces/ka.interface';
import { tableFairDistributionMockup } from './fair-distribution-mockup';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from 'src/app/services/generic/excel.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fair-distribution',
  templateUrl: './fair-distribution.component.html',
  styleUrls: ['./fair-distribution.component.scss'],
})
export class FairDistributionComponent implements OnInit {
  crudService: CrudService<any>;
  searchInputs!: InputBase<string>[];
  filter!: any;
  enableApplyFilter: boolean = true;
  tooltipMessage: string = 'Export';
  tableResultMock = tableFairDistributionMockup;
  tableResult: any = [];
  exportResult!: any;
  eventTable!: string;
  showNoResults: boolean = false;
  formSearchValues!: any;
  showTable: boolean = false;
  noFilterSelected = true;
  currentUser!: any;
  currentQuarter!: string;
  currentYear!: number;
  toggleColumnTableValues: string[] = [];
  toggleRowTableValues: string[] = [];
  productFamilyEntity: string[] = [];
  kaIdsEntity: any[] = [];
  fieldsValid: any[] = [];
  isValid: boolean = true;
  kaFilter: any[] = [];
  entities: Array<GetEntities> = [
    {
      entity: 'material-family',
      query: { pageName: PAGE_NAME_RESOURCE.fairDistribution },
    },
    {
      entity: 'kaManagement',
      query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.fairDistribution },
    },
  ];

  constructor(
    private router: Router,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private excelService: ExcelService,
    private toastr: ToastrService
  ) {
    this.crudService = new CrudService<any>(
      this.http,
      PAGE_NAME_RESOURCE.fairDistribution
    );
    [this.currentUser] = this.authService.getUserPermissions();
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;
    this.filter = {
      ...this.filter,
      fiscalYear: this.currentYear,
      quarter: `F${this.currentQuarter}`,
    };
    this.searchInputs = this.createSearchInputs();
  }

  ngOnInit(): void {
    this.spinner.show();
    const promises = [this.getDataFilter(this.entities)];
    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  createSearchInputs(): any[] {
    return [
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'Family',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'filter-family-ka-allocation',
        disabled: false,
      }),
      new MultiSelectSearchInput({
        key: 'kaId',
        hover: 'KA',
        type: 'text',
        hasCheckFlowException: true,
        hasAutocomplete: true,
        disabled: false,
      }),
    ];
  }

  private async getDataFilter(filters: any): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.spinner.show();

      this.crudService.getDataFilters(filters).subscribe(
        (response: any) => {
          this.productFamilyEntity = response[0];
          this.kaIdsEntity = response[1];

          this.dataFilterFamily(response[0]);
          this.dataFilterKa(response[1]);
          this.spinner.hide();
          resolve();
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  dataFilterFamily(response: any): void {
    const ppmFamily: any[] = [];

    response.forEach((item: any, index = 0) => {
      ppmFamily.push({
        id: index,
        value: item.family,
      });
    });

    const selectPpmFamily = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'Family',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: ppmFamily,
      classCss: 'filter-family-ka-allocation',
    });

    this.searchInputs[0] = selectPpmFamily;
    this.searchInputs = [...this.searchInputs];
  }

  dataFilterKa(response: any): void {
    this.kaFilter = [];

    response.forEach((item: Ka) => {
      if (item.status && item.ka !== 'RETAIL_BRA' && item.ka !== 'MMICOM_BR') {
        this.kaFilter.push({ value: item.ka, key: item.id });
      }
    });

    this.kaFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );

    const selectMultiKa = new MultiSelectSearchInput({
      key: 'kaId',
      hover: 'KA',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      classCss: 'multiselect-filter-allocation',
      disabled: false,
      options: this.kaFilter,
      value: this.kaFilter,
    });

    this.searchInputs[1] = selectMultiKa;
    this.searchInputs = [...this.searchInputs];
  }

  getDataTable(params?: any) {
    this.spinner.show();
    this.tableResult = [];
    return new Promise<void>((resolve, reject) => {
      this.resetValues();
      this.crudService.getEntity(`fair-distribution/get?`, params).subscribe(
        (response: any) => {
          if (response?.rows.length > 0) {
            this.addTypeShowEvent(response.headers);
            this.updateComponents(response);
            this.exportComponents(response);
            this.isValid = false;
            resolve();
          } else {
            this.isValid = true;
            this.showNoResults = true;
            this.spinner.hide();
          }
        },
        (err: any) => {
          this.isValid = true;
          this.showNoResults = true;
          this.spinner.hide();
          reject(err);
        }
      );
    });
  }

  updateComponents(data: any) {
    this.tableResult = {
      table: {
        columns: data.headers,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(data.rows))
        ),
      },
    };
  }

  private resetValues() {
    this.tableResult = [];
    this.showNoResults = false;
    this.showTable = false;
    this.toggleColumnTableValues = [];
    this.toggleRowTableValues = [];
  }

  addTypeShowEvent(response: any) {
    this.eventTable = 'hide-columns-rows';

    response.forEach((header: any) => {
      if (header.expansive === true) {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });
  }

  dataFormSearchKaIds(formSearchKaIds: any) {
    const kaIds = formSearchKaIds.map((ka: any) => ka.key);
    return kaIds;
  }

  onSearchEvent(value: any) {
    this.dataFormValuesKa(value);
    this.dataFormValuesFamily(value);
    this.deleteProperty(value);

    this.formSearchValues = { ...value };
    this.filter = {
      ...this.filter,
      ...this.formSearchValues,
    };

    this.fieldsValid = [this.formSearchValues.kaId];
    this.enableApplyFilter = this.fieldsValid.every(item => {
      return item.length > 0;
    });
  }

  applyFilter(): void {
    let formSearchValues: any = {};

    const filterKaRetail = this.kaIdsEntity.filter(
      item => item.ka === 'MMICOM_BR' || item.ka === 'RETAIL_BRA'
    ).map((item: any) => item.id);

    if (this.formSearchValues === undefined) {
      const kaId = this.dataFormSearchKaIds(this.kaFilter);

      const concatFilterKaRetail = kaId.concat(filterKaRetail);

      formSearchValues = { kaId: concatFilterKaRetail };
    } else {
      formSearchValues = { ...this.formSearchValues };

      const concatFilterKaRetail = formSearchValues.kaId.concat(filterKaRetail);

      formSearchValues.kaId = concatFilterKaRetail;
    }

    let params = this.deleteProperty(formSearchValues);
    this.noFilterSelected = false;

    this.hideSpinnerAfterAll([this.getDataTable(params)]);
  }

  private deleteProperty(item: any) {
    delete item.ppm;
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];

    array.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  private dataFormValuesKa(formSearch: any) {
    if (formSearch.kaId) {
      const currentKa = formSearch.kaId.map((item: any) => item.key);
      formSearch.kaId = currentKa;
    } else {
      formSearch.kaId = '';
    }
  }

  private dataFormValuesFamily(formSearch: any) {
    const currentIdsFamily: any = [];

    if (formSearch.ppm) {
      this.productFamilyEntity.forEach((item: any) => {
        formSearch.ppm.forEach((data: any) => {
          if (item.family === data.value) {
            item.ids.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
      });
      formSearch.materialId = currentIdsFamily.map((item: any) => item);
    } else {
      formSearch.materialId = formSearch.materialId
        ? formSearch.materialId
        : '';
    }
  }

  toggleColumnTable({ col, event }: any) {
    this.toggleColumnTableValues = [];
    this.toggleColumnTableValues.push(col, event);
  }

  toggleRowTable({ col, event }: any) {
    this.toggleRowTableValues = [];
    this.toggleRowTableValues.push(col, event);
  }

  private getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  private exportComponents(data: any): void {
    this.exportResult = {
      table: {
        columns: data.headers,
        rows: data.rows,
      },
    };
  }

  prepareExportTable(data: any) {
    this.spinner.show();

    const tableRows: { [key: string]: any }[] = [];

    const rows = data.rows;
    const columns: any[] = data.columns.map((item: any) => ({
      title: item.title,
      props: item.props,
      otherContent: item.otherContent || false,
    }));

    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: [] } = {};
      columns.forEach((column: any) => {
        if (rows[i][column.props]) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        } else {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    return { columns: columns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.exportResult.table);
    this.excelService.exportJsonAsExcelFile(
      fullJsonExport,
      'fair_distribution'
    );
    this.spinner.hide();
  }

  backButton(): void {
    this.router.navigate(['home/ka-allocation']);
  }
}
