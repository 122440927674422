import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { AuthService } from '../../services/authorization/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedinfoService } from '../../services/generic/sharedinfo.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
})
export class AuthorizationComponent implements OnInit {
  @Output('isAuthorized') isAuthorized = new EventEmitter();
  initStateLogin!: Boolean;
  isLoggedIn!: Boolean;
  userPermission!: any;
  googleClientId: string = environment.gapiClientId;
  loginRedirect: string= environment.urlAuth;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private sharedInfoService: SharedinfoService,
    private socialAuthService: SocialAuthService,
    private dataService: AuthService,
  ) {}

  ngOnInit(): void {
    this.socialAuthService?.initState.subscribe(
      (response: Boolean) => (this.initStateLogin = response)
    );
  }

  isTokenAuthorized(token: any) {
    if (!!token.access_token && !!token.user_permissions) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/authorization']);
    }
  }

  async authetication() {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userAuth: any) => {
        this.authService.storeToken(userAuth?.idToken);
        this.authService.signIn(userAuth)?.subscribe(
          (user: any) => {
            if (user) {
              this.authService.storeUserPermissions(user);
              this.sharedInfoService.emitChange({ isAuthorized: true });
              this.router.navigate(['/home']);

              this.dataService.setInputData(true);
            } else {
              this.router.navigate(['/authorization']);

              this.dataService.setInputData(false);
            }
          },
          error => {
            throw this.handlerError(error);
          }
        );
      })
      .catch((error: any) => {
        throw this.handlerError(error);
      });
  }

  private handlerError(error: any) {
    if (
      error instanceof HttpErrorResponse &&
      (error.status === 401 || error.status === 403)
    ) {
      const message = 'Access denied. Please contact your administrator.';
      const action = 'OK';
      this.snackBar.open(message, action, {
        duration: 10000,
        verticalPosition: 'top',
      });
    } else if (error !== null || error === 'popup_closed_by_user') {
      const message = 'Access denied. Please contact your administrator.';
      const action = 'OK';
      this.snackBar.open(message, action, {
        duration: 10000,
        verticalPosition: 'top',
      });
    }
    return error;
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: any) {
    this.isTokenAuthorized(event.view.localStorage);
  }
}
