export const clustedColumnMockup: any = {
  data: {
    source: [
      {
        wk: 'WK1',
        retail: 50,
        details: 50,
      },
      {
        wk: 'WK2',
        retail: 40,
        details: 60,
      },
      {
        wk: 'WK3',
        retail: 60,
        details: 40,
      },
      {
        wk: 'WK4',
        retail: 50,
        details: 50,
      },
      {
        wk: 'WK5',
        retail: 20,
        details: 80,
      },
      {
        wk: 'WK6',
        retail: 80,
        details: 20,
      },
      {
        wk: 'WK7',
        retail: 30,
        details: 70,
      },
      {
        wk: 'WK8',
        retail: 40,
        details: 60,
      },
      {
        wk: 'WK9',
        retail: 30,
        details: 70,
      },
      {
        wk: 'WK10',
        retail: 40,
        details: 60,
      },
      {
        wk: 'WK11',
        retail: 20,
        details: 80,
      },
      {
        wk: 'WK12',
        retail: 10,
        details: 90,
      },
      {
        wk: 'WK13',
        retail: 80,
        details: 20,
      },
      {
        wk: 'WK14',
        retail: 70,
        details: 30,
      },
      {
        wk: 'WK15',
        retail: 60,
        details: 40,
      },
    ],
  },
};
