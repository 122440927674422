import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogEditComponent } from '../dialog-edit/dialog-edit.component';

export interface DialogData {
  message: string;
}
@Component({
  selector: 'app-dialog-table',
  templateUrl: './dialog-table.component.html',
  styleUrls: ['./dialog-table.component.scss'],
})
export class DialogTableComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    console.log('mensagem do dialog', this.data);
    console.log(this.dialog);
  }

  openDialogEdit(): void {
    // abrir o dialog de edit
    const dialogRef = this.dialog.open(DialogEditComponent, {
      width: '349px',
      data: this.data,
    });

    // tratamento para valor editado
    dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        console.log('The dialog was closed');
        this.data = result;
        console.log((this.data = result));
      }
    });
  }
}
