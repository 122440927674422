<ngx-spinner></ngx-spinner>
<div class="dcm-container" [class.example-is-mobile]="mobileQuery.matches">
  <!-- Title and Toggle Button -->
  <div class="title toggle" (click)="isSidenavOpened && snav.close()">
    <div>DCM REPORTS</div>
    <div mat-icon-button class="notification-DCM">
      <button (click)="snav.toggle()">
        <b>Notifications</b>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 1024 1024">
          <path
            fill="currentColor"
            d="M512 64a64 64 0 0 1 64 64v64H448v-64a64 64 0 0 1 64-64z" />
          <path
            fill="currentColor"
            d="M256 768h512V448a256 256 0 1 0-512 0v320zm256-640a320 320 0 0 1 320 320v384H192V448a320 320 0 0 1 320-320z" />
          <path
            fill="currentColor"
            d="M96 768h832q32 0 32 32t-32 32H96q-32 0-32-32t32-32zm352 128h128a64 64 0 0 1-128 0z" />
        </svg>
      </button>
    </div>
  </div>

  <!-- Sidenav Container -->
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <!-- Sidenav -->
    <mat-sidenav
      #snav
      (openedChange)="isSidenavOpened = $event"
      position="end"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56">
      <div class="feed-notifications">
        <p class="title">Feed Notifications</p>
        <button
          matTooltip="Close"
          matTooltipPosition="left"
          matTooltipClass="custom-tooltip"
          (click)="snav.close()"
          class="close-button"></button>
      </div>

      <mat-nav-list>
        <app-table-notifications
          [dataNotifications]="dataSourceNotifications"
          (readEvent)="updateReadNotifications($event)"
          [dashboardNotification]="true"></app-table-notifications>
      </mat-nav-list>

      <div class="view-all" (click)="viewAll()">
        <p>VIEW ALL</p>
      </div>
    </mat-sidenav>

    <!-- Main Content -->
    <mat-sidenav-content>
      <mat-divider></mat-divider>

      <div class="container-rollover-supply-alerts">
        <!-- Main Content -->
        <div class="main-content">
          <div class="header-rollover">
            <p class="title">Rollover Report - Week {{ actualWeek }}</p>
            <mat-form-field appearance="outline" class="search-rollover">
              <mat-label>Search</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Type Your Search Here"
                #input />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <app-table-pagination
            class="table-rollover"
            [dataSource]="dataSource"
            [displayedColumns]="displayedColumns"
            [onSearch]="true"
            *ngIf="!noRecordFoundRolloverReport"
            (resultPaginator)="onChangePaginator($event, 'table-rollover')">
          </app-table-pagination>

          <div *ngIf="noRecordFoundRolloverReport">
            <div
              class="mat-elevation-z6 table-pagination no-filter-selected-session">
              <p>NO RECORD FOUND</p>
            </div>
          </div>

          <p class="title">Supply Allocation</p>
          <app-cards-color
            class="table-supply"
            [cardDataArr]="cardDataArr"></app-cards-color>
        </div>

        <!-- Alerts Section -->
        <div class="alerts alerts-section">
          <div class="title-INT">
            <p class="title">Last Alerts</p>
            <div
              matTooltip="You can view your last 100 updates. For more details you can access the notifications panel."
              matTooltipPosition="left"
              matTooltipClass="custom-tooltip"
              class="img-INT custom-tooltip"></div>
          </div>
          <app-table-filtering
            [dataSourceFilterTable]="dataSourceFilterTable"
            (customEvent)="updateNotifications($event)"
            (readEvent)="updateReadNotifications($event)"
            (resultPaginator)="
              onChangePaginator($event, 'table-last-alert')
            "></app-table-filtering>
        </div>

        <!-- Log History Section -->
        <div class="log-history dcm-tabs">
          <p class="title">Log History</p>
          <mat-tab-group>
            <mat-tab *ngFor="let tab of asyncTabs; let i = index">
              <ng-template mat-tab-label>{{ tab.label }}</ng-template>
              <ng-template matTabContent>
                <div>
                  <app-table-pagination
                    class="table-log-history"
                    [dataSource]="tab.dataSource"
                    [displayedColumns]="tab.displayedColumns"
                    (resultPaginator)="
                      onChangePaginator($event, 'table-log-history')
                    ">
                  </app-table-pagination>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>
