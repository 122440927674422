<div class="show-columns" id="showColumns">
  <div
    (click)="toggleMenuColumns()"
    class="select-columns"
    [class.active]="showSubMenuColumns">
    <span>Columns</span>
  </div>
  <ul class="submenu" [class.active]="showSubMenuColumns">
    <li
      *ngFor="let row of thead"
      [ngClass]="{ filteredMenu: !filteredProps(row.props) }">
      <button (click)="toggleHideColumns(row.props)">
        <div
          class="checkbox"
          [class.active]="!getValidatorHideColumn(row.props)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 16 16">
            <path
              fill="currentColor"
              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06a.733.733 0 0 1 1.047 0l3.052 3.093l5.4-6.425a.247.247 0 0 1 .02-.022Z" />
          </svg>
        </div>
        <span>{{ row.title }}</span>
      </button>
    </li>
  </ul>
</div>
