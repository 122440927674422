export const mknToBookMockupResponse: any = {
  customerTaxToBooks: {
    key: '2198055MAN2',
    ppm: 'XT2347 - BANGKOK5G23 8/256 - OUTER SPACE',
    customerShort: 'CMR',
    value: -30,
  },
  headers: [
    {
      title: 'FAMILY',
      props: 'family',
    },
    {
      title: 'ORIGIN',
      props: 'origin',
    },
    {
      title: 'CUSTOMER SHORT',
      props: 'customerShort',
    },
    {
      title: 'TAXES',
      props: 'taxes',
    },
    {
      title: '22/02',
      week: 'WK 08',
      props: 'week08',
    },
    {
      title: '27/02',
      week: 'WK 09',
      props: 'week09',
    },
    {
      title: '07/03',
      week: 'WK 10',
      props: 'week10',
    },
    {
      title: '',
      week: 'WK 11',
      props: 'week11',
    },
    {
      title: '',
      week: 'WK 12',
      props: 'week12',
    },
    {
      title: '',
      week: 'WK 13',
      props: 'week13',
    },
  ],
  rows: [
    {
      family: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE',
      origin: 'JAG',
      data: [
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE', id: 0 },
          origin: { value: 'JAG', id: 0 },
          customerShort: { value: 'Magalu' },
          taxes: { value: 'SP' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE', id: 0 },
          origin: { value: 'JAG', id: 0 },
          customerShort: { value: 'Magalubssss' },
          taxes: { value: 'Fora_7' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE', id: 0 },
          origin: { value: 'JAG', id: 0 },
          customerShort: { value: 'Magalubssss' },
          taxes: { value: 'Fora_12' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
      ],
    },
    {
      family: 'HAWAIIPLUSSOLED22 4/128 - DEEP LAGOON',
      origin: 'MAN',
      data: [
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - DEEP LAGOON', id: 0 },
          origin: { value: 'MAN', id: 0 },
          customerShort: { value: 'Magalu' },
          taxes: { value: 'SP' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - DEEP LAGOON', id: 0 },
          origin: { value: 'MAN', id: 0 },
          customerShort: { value: 'Magalu' },
          taxes: { value: 'Fora_7' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
      ],
    },
    {
      family: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE 22',
      origin: 'MAN',
      data: [
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE 22', id: 0 },
          origin: { value: 'MAN', id: 0 },
          customerShort: { value: 'Magalu' },
          taxes: { value: 'SP' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE 22', id: 0 },
          origin: { value: 'MAN', id: 0 },
          customerShort: { value: 'Magalu' },
          taxes: { value: 'Fora_7' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
        {
          family: { value: 'HAWAIIPLUSSOLED22 4/128 - SOFT BEIGE 22', id: 0 },
          origin: { value: 'MAN', id: 0 },
          customerShort: { value: 'Magalu' },
          taxes: { value: 'Fora_12' },
          week08: { value: 1000 },
          week09: { value: 1000 },
          week10: { value: 1000 },
          week11: { value: 1000 },
          week12: { value: 1000 },
          week13: { value: 1000 },
        },
      ],
    },
  ],
};
