import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../authorization/auth.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';

@Injectable({
  providedIn: 'root',
})
export class SharedinfoService {
  authService = new AuthService();
  emitChangeSource = new Subject<any>();
  userPermissionsBehavior = new BehaviorSubject<any>([]);
  lastUpdateBehavior = new BehaviorSubject<any>([]);
  emitChangeLocalStorage = new Subject<any>();

  newPermission = this.userPermissionsBehavior.asObservable();
  newLastUpdate = this.lastUpdateBehavior.asObservable();
  sharedInfo = this.emitChangeSource.asObservable();

  constructor(private http?: HttpClient) {}

  refreshPermission() {
    return this.http?.get<any>(
      environment.api.gcaBackEnd + 'refresh-permission'
    );
  }

  setPermissionStorage(permissions: any) {
    localStorage.setItem('user_permissions', JSON.stringify(permissions));
    this.userPermissionsBehavior.next(permissions);
  }

  getPermissionStorage() {
    return this.newPermission;
  }

  loadLastUpdate() {
    const [currentUser] = JSON.parse(
      localStorage.getItem('user_permissions') || 'null'
    );
    if (currentUser.country.toLowerCase() === 'brazil') {
      this.getLastUpdate()?.subscribe((res: any) =>
        this.setLastUpdate(res.eccCustomerUpdate)
      );
    }
  }
  getLastUpdate() {
    return this.http?.get<any>(
      environment.api.gcaBackEnd + 'onePlan/last-updates?pageName=ka-allocation'
    );
  }
  setLastUpdate(data: any) {
    localStorage.setItem('last_update', JSON.stringify(data));
    this.lastUpdateBehavior.next(data);
  }
  getLastUpdateStorage(): Observable<any> {
    return this.newLastUpdate;
  }

  emitChangeStorage(newStorage: any) {
    this.emitChangeLocalStorage.next(newStorage);
  }

  emitChange(inf: any) {
    this.emitChangeSource.next(inf);
  }
}
