<div
  class="card-container"
  [class.disabled]="dataCard.active != null && !dataCard.active">
  <div class="card-header">
    <div class="card-title">{{ dataCard.title }}</div>
    <div class="card-icons">
      <img
        *ngIf="dataCard.systemFunction"
        class="system-function-icon"
        (click)="systemFunction({dataCard})"
        src="../../../assets/images/ico-permission.svg" />
      <img
        *ngIf="dataCard.iconEdit?.visible && dataCard.iconEdit?.enabled"
        class="delete-edit-icon"
        (click)="editEvent({dataCard})"
        src="../../../assets/images/ico-edit.svg"
        matTooltip="Edit"
        [matTooltipPosition]="'left'" />
      <img
        *ngIf="dataCard.iconEdit?.visible && !dataCard.iconEdit?.enabled"
        class="delete-edit-icon delete-edit-icon-disabled"
        src="../../../assets/images/ico-edit-disable.svg" />
      <img
        *ngIf="dataCard.iconDelete?.visible && dataCard.iconDelete?.enabled"
        class="delete-edit-icon"
        (click)="deleteEvent({dataCard})"
        src="../../../assets/images/icon-delete.svg"
        matTooltip="Delete"
        [matTooltipPosition]="'left'" />
      <img
        *ngIf="!dataCard.iconDelete?.visible && !dataCard.iconDelete?.enabled"
        class="delete-edit-icon delete-edit-icon-disabled"
        src="../../../assets/images/icon-delete-disabled.svg" />
    </div>
  </div>
  <div *ngIf="dataCard.subtitle" class="card-subtitle">
    <p>{{ dataCard.subtitle }}</p>
  </div>
  <content
    *ngFor="let item of dataCard.attributes"
    class="card-content-atributes">
    <div class="card-atributes" [class.labelExpanded]="item.keyExpanded">
      {{ item.key }}
    </div>
    <div *ngIf="item.value.length > 0">
      <div
        class="card-atributes"
        [class.isTag]="item.isTag"
        *ngFor="let value of item.value">
        {{ value }}
      </div>
    </div>
  </content>
</div>
