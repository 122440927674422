import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-table-book',
  templateUrl: './table-book.component.html',
  styleUrls: ['./table-book.component.scss'],
})
export class TableBookComponent implements OnInit {
  @ViewChild('overflowTable') overflowTable!: ElementRef<HTMLInputElement>;
  @Input() dataTable: any = {};
  tableHead: any = [];
  tableBody: any = {};
  offsetTopSubTotal: number = 0;
  translateYAbsoluteItem: number = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataTable.currentValue) {
      this.dataTable = changes.dataTable.currentValue;
      this.loadDataTable();
    }
  }

  loadDataTable() {
    this.tableHead = [];
    this.tableBody = {};
    this.dataTable.headers.forEach((item: any) => {
      this.tableHead.push(item);
    });
    this.tableBody = this.dataTable.rows;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;
    const appFooterSubTotal = document.getElementById('subtotal1')?.offsetTop;
    this.offsetTopSubTotal = appFooterSubTotal || 0;
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const offsetTopElement =
      this.overflowTable.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  checkNUmber(n: any) {
    if (typeof n === 'number') {
      return true;
    }
    return false;
  }

  // isCurrentWeek(week: any) {
  //   // if (week.indexOf('WK') > -1) {
  //   //   if (week.slice(2) < week.slice(2)) {
  //   //     console.log(week);
  //   //   }
  //   // }
  // }

  getAddRowId(indexRow: number) {
    return indexRow;
  }

  mouseEnter(indexRow: any, row?: any) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.add('hover-row');
      }
    });
  }

  mouseLeave(indexRow: any, row?: any) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.remove('hover-row');
      }
    });
  }

  checkKey(chave: any) {
    if (chave == 'overBooked') {
      return true;
    }
    return false;
  }

  alignTest(title: string) {
    const week = 'WK';
    if (
      title == 'TOTAL' ||
      title == 'ENTERED' ||
      title == 'OVERBOOKED' ||
      title.includes(week)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
