import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardInterface } from './card.interface';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
  @Output('callActions') callActions = new EventEmitter();

  @Input() dataCard!: CardInterface;

  constructor() {}

  ngOnInit(): void {}

  editEvent(item: any) {
    this.callActions.emit({ item: item.dataCard, type: 'edit' });
  }

  deleteEvent(item: any) {
    this.callActions.emit({ item: item.dataCard, type: 'delete' });
  }

  systemFunction(item: any) {
    this.callActions.emit({ item: item.dataCard, type: 'systemFunction' });
  }
}
