import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SetupComponent } from './setup.component';
import { GeoComponent } from './geo/geo.component';
import { CountryComponent } from './country/country.component';
import { ChannelComponent } from './channel/channel.component';
import { SalesComponent } from './sales/sales.component';
import { OrderAdminComponent } from './order-admin/order-admin.component';
import { CustomerManagementComponent } from './customer-management/customer-management.component';
import { CustomerShortComponent } from './customer-short/customer-short.component';
import { FiscalCalendarComponent } from './fiscal-calendar/fiscal-calendar.component';
import { SetupManagementComponent } from './setup-management/setup-management.component';
import { CrsdComponent } from './crsd/crsd.component';
import { LocationComponent } from './location/location.component';
import { EmeaCustomerSettingsComponent } from './emea-customer-settings/emea-customer-settings.component';

// temporario - para visualização dos charts
import { ChartsComponent } from './charts/charts.component';
import { MatCardModule } from '@angular/material/card';
import { AdminModule } from './admin/admin.module';

@NgModule({
  declarations: [
    ChannelComponent,
    CountryComponent,
    GeoComponent,
    SetupComponent,
    SalesComponent,
    OrderAdminComponent,
    CustomerManagementComponent,
    CustomerShortComponent,
    FiscalCalendarComponent,
    SetupManagementComponent,
    CrsdComponent,
    LocationComponent,
    EmeaCustomerSettingsComponent,
    ChartsComponent,
  ],
  imports: [
    BrowserModule,
    ShareDirectiviesModule,
    ShareComponentsModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCardModule,
    AdminModule
  ],
  providers: [],
  bootstrap: [],
})
export class SetupModule {}
