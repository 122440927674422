import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  KaExecutionMockup,
  KaExecutionMockupPlus1,
} from './ka-execution-mockup';
import { MatTableDataSource } from '@angular/material/table';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/services/generic/crud.service';
import {
  KaExecutionMockupInterface,
  DataRow,
  KaExecutionRow,
  Header,
  TabMonthItem,
  ActionEventData,
  EntityItem,
  CustomTableStructure,
} from 'src/app/interfaces/ka-execution-interface';
import { Router } from '@angular/router';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ka-execution',
  templateUrl: './ka-execution.component.html',
  styleUrls: ['./ka-execution.component.scss'],
})
export class KaExecutionComponent implements OnInit {
  @ViewChild('CurrentWeek') currentWeek!: TemplateRef<any>;
  @ViewChild('CurrentWeek1') currentWeek1!: TemplateRef<any>;
  @ViewChild('CurrentWeek2') currentWeek2!: TemplateRef<any>;
  @ViewChild('CurrentQuarter') currentQuarter!: TemplateRef<any>;
  @ViewChild('CurrentMonth') currentMonth!: TemplateRef<any>;

  crudService: CrudService<any>;

  responseFilters: any[];
  salesModelFilter: any[];

  responseMockup = KaExecutionMockup;
  responseMockupPlus1 = KaExecutionMockupPlus1;
  summedValue!: number;
  eventTable!: string;

  dataTable!: CustomTableStructure;
  tabMonth: TabMonthItem[];
  tableWatch: KaExecutionMockupInterface;

  selectedTab: string;

  searchInputs!: InputBase<string>[];
  entities: EntityItem[];
  formSearchKaExecution!: { [key: string]: any };
  formSearchKaExecutionExport!: any;
  filter!: ActionEventData;
  response: any = {};

  disableElement: boolean;
  disableApply: boolean;

  fiscalQuarter: string;
  currentWeekSubtitle!: string;
  headerResult: any[] = [];
  rowWithTabs: any;

  showNoResults = false;
  showTable = false;
  current: any;

  tooltipMessage: string;

  constructor(
    protected http: HttpClient,
    private router: Router,
    private excelSheetsService: ExcelSheetsService,
    private spinner: NgxSpinnerService
  ) {
    this.crudService = new CrudService<any>(this.http, 'kaAllocation');
    this.disableElement = true;
    this.disableApply = false;
    this.selectedTab = 'Current Week';
    this.fiscalQuarter = 'FQ2';
    this.tabMonth = [];
    this.entities = this.getEntities();
    this.searchInputs = this.createSearchInputs();
    this.tableWatch = this.rowWithTabs;
    this.responseFilters = [];
    this.salesModelFilter = [];
    this.tooltipMessage = 'Export';
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.spinner.show();
  }

  loadData() {
    this.spinner.show();
    const promises = [this.getDataTable(), this.getDataFilter()];
    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  applyFilter() {
    this.spinner.show();
    let formSearchValues: any = {};
    formSearchValues = { ...this.formSearchKaExecution };
    this.deleteProperty(this.formSearchKaExecution);
    const promises = [this.getDataTable(formSearchValues)];
    this.hideSpinnerAfterAll(promises);
  }

  getEntities() {
    return (this.entities = [
      {
        entity: 'kaManagement',
        query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.kaExecution },
      },
      {
        entity: 'material/products/summary',
        query: { status: true, pageName: PAGE_NAME_RESOURCE.kaExecution },
      },
      {
        entity: 'material',
        query: { status: true, pageName: PAGE_NAME_RESOURCE.kaExecution },
      },
    ]);
  }

  createSearchInputs(
    kaFilter?: InputBase<string>[] | undefined,
    ppmFilter?: InputBase<string>[],
    salesModelFilter?: InputBase<string>[]
  ) {
    const originFilter: any = [
      { id: 1, value: 'JAG' },
      { id: 2, value: 'MAN' },
    ];

    return (this.searchInputs = [
      new MultiSelectSearchInput({
        key: 'kaId',
        hover: 'KA',
        hasCheckFlowException: true,
        disabled: false,
        options: kaFilter,
        // classCss: 'multi-select-filter-kaExecution-ka',
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        hasCheckFlowException: true,
        disabled: false,
        options: ppmFilter,
        // classCss: 'multi-select-filter-kaExecution-ppm',
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        hasCheckFlowException: true,
        disabled: false,
        options: salesModelFilter,
        // classCss: 'multi-select-filter-kaExecution-salesModel',
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: false,
        options: originFilter,
        // classCss: 'multi-select-filter-kaExecution-origin',
      }),
    ]);
  }

  createSearchInputSalesModel(salesModelFilter?: InputBase<string>[]) {
    const currentSalesModel = new MultiSelectSearchInput({
      key: 'salesModel',
      hover: 'Sales Model',
      hasCheckFlowException: true,
      disabled: false,
      options: salesModelFilter,
      classCss: 'multi-select-filter-kaExecution-salesModel',
    });

    this.searchInputs[2] = currentSalesModel;
    this.searchInputs = [...this.searchInputs];
  }

  async getDataFilter(): Promise<void> {
    try {
      const kaFilter: any[] = [];
      const ppmFilter: any[] = [];
      const salesModelFilter: any[] = [];

      this.responseFilters = await this.crudService
        .getDataFilters(this.entities)
        .toPromise();

      this.responseFilters.forEach((item: any) => {
        item.forEach((data: DataRow) => {
          if (data.ka) {
            kaFilter.push({ value: data.ka, key: data.id, ka: data.ka });
          }
          if (data.productDesc) {
            ppmFilter.push({ value: data.productDesc, id: data.id });
          }
          if (data.salesModel) {
            salesModelFilter.push({ value: data.salesModel, id: data.id });
            this.salesModelFilter.push({ value: data.salesModel, id: data.id });
          }
        });
      });
      this.createSearchInputs(kaFilter, ppmFilter, salesModelFilter);
    } catch (error) {
      console.error(error);
    }
  }

  dataFormSearchPpm(formSearchName: any) {
    if (formSearchName.nameFormControl === 'ppm') {
      if (Array.isArray(formSearchName.ppm)) {
        this.salesModelFilter = [];
        const currentPPM = formSearchName.ppm.map((item: any) => item.value);

        this.responseFilters[2].forEach((item: any) => {
          formSearchName.ppm.forEach((data: any) => {
            if (item.allocationBz == data.value) {
              this.salesModelFilter.push({
                value: item.salesModel,
                id: item.id,
              });
            }
          });
        });

        this.createSearchInputSalesModel(this.salesModelFilter);

        formSearchName.ppm = currentPPM;
      } else {
        this.salesModelFilter = [];

        this.responseFilters[2].forEach((item: DataRow) => {
          this.salesModelFilter.push({ value: item.salesModel, id: item.id });
        });

        this.createSearchInputSalesModel(this.salesModelFilter);

        formSearchName.ppm = [formSearchName.ppm];
        formSearchName.salesModel = '';
      }
    } else {
      if (Array.isArray(formSearchName.ppm)) {
        const currentPPM = formSearchName.ppm.map((item: any) => item.value);

        formSearchName.ppm = currentPPM;
      } else {
        formSearchName.ppm = [formSearchName.ppm];
      }
    }
  }

  dataFormSearchKaId(formSearchName: any, exportForm: any) {
    if (Array.isArray(formSearchName.kaId)) {
      const currentKaId = formSearchName.kaId.map((item: any) => item.key);
      const currentKa = formSearchName.kaId.map((item: any) => item.ka);
      formSearchName.kaId = currentKaId;
      exportForm.ka = currentKa;
    } else {
      formSearchName.kaId = '';
    }
  }

  dataFormSearchSalesModelKa(formSearchName: any) {
    if (Array.isArray(formSearchName.salesModel)) {
      const currentSalesModel = formSearchName.salesModel.map(
        (item: any) => item.value
      );
      formSearchName.salesModel = currentSalesModel;
    } else {
      formSearchName.salesModel = '';
    }
  }

  dataFormSearchOrigin(formSearchName: any) {
    if (Array.isArray(formSearchName.origin)) {
      const currentOrigin = formSearchName.origin.map(
        (item: any) => item.value
      );
      formSearchName.origin = currentOrigin;
    } else {
      formSearchName.origin = '';
    }
  }

  disableExport(response: any) {
    if (response?.rows?.length > 0) {
      this.disableElement = false;
      this.disableApply = true;
    } else {
      this.disableElement = true;
      this.disableApply = false;
    }
  }

  private deleteProperty(item: any) {
    if (item) {
      if (item.origin == 'ALL' || item.origin == undefined) {
        item.origin = '';
      }
      if (item.ka) {
        delete item.ka;
      }
      delete item.action;
      delete item.nameFormControl;
      delete item.action;
      delete item.formControl;
      delete item.event;
      delete item.panelTrigger;
      return item;
    }
  }

  async onSearchEvent(form: any) {
    this.formSearchKaExecution = { ...form };
    this.formSearchKaExecutionExport = { ...form };
    this.dataFormSearchKaId(
      this.formSearchKaExecution,
      this.formSearchKaExecutionExport
    );
    this.dataFormSearchPpm(this.formSearchKaExecution);
    this.dataFormSearchSalesModelKa(this.formSearchKaExecution);
    this.dataFormSearchOrigin(this.formSearchKaExecution);
    // this.deleteProperty(this.formSearchKaExecution);

    this.filter = {
      ...this.filter,
      ...this.formSearchKaExecution,
      ...this.formSearchKaExecutionExport,
    };
  }

  tabs(tabValues: string[]) {
    const uniqueTabs = tabValues.filter(
      label => !this.tabMonth.some(tab => tab.label === label)
    );
    this.tabMonth = this.tabMonth.concat(
      uniqueTabs.map((label: string) => ({ label }))
    );
  }

  changeTabData(tab: string) {
    if (this.selectedTab !== tab) {
      this.selectedTab = tab;
      this.changeTableWithClickTab(tab);
    }
  }

  changeTableWithClickTab(tab: string) {
    this.dataTableResult(tab, this.headerResult, this.response.rows);
  }

  private resetValues() {
    this.headerResult = [];
    this.showNoResults = false;
    this.showTable = false;
  }

  async getDataTable(params?: KaExecutionMockupInterface) {
    return new Promise<void>((resolve, reject) => {
      this.resetValues();
      this.crudService.getEntity(`ka-execution-report?`, params).subscribe(
        (response: any) => {
          if (response?.rows?.length > 0) {
            response.headers.forEach((header: Header) => {
              this.headerResult.push(header);
            });
            this.response.rows = response.rows;
            this.current = response.current;

            this.dataTableResult(
              this.selectedTab,
              this.headerResult,
              response.rows,
              response
            );
            this.showTable = true;
            this.showNoResults = false;
            this.disableElement = false;
            this.disableApply = true;
            resolve();
          } else {
            this.showNoResults = true;
            this.showTable = false;
            this.disableElement = true;
            this.hideSpinnerAfterAll(resolve());
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  changeTable(table: KaExecutionMockupInterface) {
    this.tableWatch = table;
  }

  private dataTableResult(
    tab: string,
    headers?: any,
    rowsValue?: any,
    response?: any
  ) {
    let rowResult: DataRow[] = [];

    rowsValue.forEach((nameRow: any) => {
      for (let key in nameRow) {
        this.tabs([key]);
        this.current.period.forEach((period: any) => {
          for (let keyPeriod in period) {
            if (this.selectedTab === keyPeriod) {
              this.currentWeekSubtitle = period[this.selectedTab];
            }
          }
        });
      }
      if (nameRow[tab]) {
        this.rowWithTabs = nameRow;

        this.changeTable(nameRow[tab]);
        nameRow[tab].rows.forEach((row: KaExecutionRow) => {
          row.data.forEach((rowData: DataRow) => {
            rowResult.push(rowData);
          });
        });
      }
    });
    if (headers !== undefined && rowsValue !== undefined) {
      this.dataTable = {
        table: {
          columns: headers,
          rows: new MatTableDataSource<string>(
            this.convertToDataSource(JSON.stringify(rowResult))
          ),
        },
      };
    }
    this.addSubtotalValues(headers, rowResult, response);
  }

  private convertToDataSource(data: string): string[] {
    const array = JSON.parse(data);
    const dataTable: string[] = [];

    array.forEach((item: string) => {
      dataTable.push(item);
    });

    return [...dataTable];
  }

  private addSubtotalValues(
    header?: Header[],
    rows?: DataRow[],
    response?: any
  ) {
    if (header !== undefined && rows !== undefined) {
      const subtotals: { [key: string]: any } = {};

      rows?.forEach((item: any) => {
        const kaValue = item.ka.value;

        if (!subtotals[kaValue]) {
          subtotals[kaValue] = {
            ka: kaValue,
            subtotalsByColumn: {},
          };
        }

        header.forEach((col: Header) => {
          const headerName = col.props;

          if (headerName !== 'ka' && item[headerName]?.summableValue) {
            if (!subtotals[kaValue].subtotalsByColumn[headerName]) {
              subtotals[kaValue].subtotalsByColumn[headerName] = 0;
            }

            subtotals[kaValue].subtotalsByColumn[headerName] +=
              item[headerName].value;
          }
        });
      });
      response = subtotals;
      this.response.subtotals = response;
      const totalSubtotal = this.calculateTotalSubtotal(subtotals);
    }
  }

  private calculateTotalSubtotal(subtotals: any) {
    const totalSubtotal: any = { totalsByColumn: {} };

    for (const kaValue in subtotals) {
      if (Object.prototype.hasOwnProperty.call(subtotals, kaValue)) {
        const kaSubtotal = subtotals[kaValue];

        for (const columnName in kaSubtotal.subtotalsByColumn) {
          if (
            Object.prototype.hasOwnProperty.call(
              kaSubtotal.subtotalsByColumn,
              columnName
            )
          ) {
            if (!totalSubtotal.totalsByColumn[columnName]) {
              totalSubtotal.totalsByColumn[columnName] = 0;
            }

            totalSubtotal.totalsByColumn[columnName] +=
              kaSubtotal.subtotalsByColumn[columnName];
          }
        }
      }
      this.response.totals = totalSubtotal;
    }

    return totalSubtotal;
  }

  private extractData(response: any): { headers: Header[]; rows: DataRow[] } {
    const headers: Header[] = [];
    const rows: DataRow[] = [];

    response.headers.forEach((header: Header) => headers.push(header));

    response.rows.forEach((row: KaExecutionRow) =>
      row.data.forEach((rowData: DataRow) => rows.push(rowData))
    );

    return { headers, rows };
  }

  private calculateSubtotalsForSheet(
    headers: Header[],
    rows: any
  ): { [key: string]: any } {
    const subtotals: { [key: string]: any } = {};

    rows.rows.forEach((item: any) => {
      item.data.forEach((data: any) => {
        const kaValue = data.ka.value;

        if (!subtotals[kaValue]) {
          subtotals[kaValue] = { ka: kaValue, subtotalsByColumn: {} };
        }

        headers.forEach((col: Header) => {
          const headerName = col.props;

          if (headerName !== 'ka' && data[headerName]?.summableValue) {
            if (!subtotals[kaValue].subtotalsByColumn[headerName]) {
              subtotals[kaValue].subtotalsByColumn[headerName] = 0;
            }

            subtotals[kaValue].subtotalsByColumn[headerName] +=
              data[headerName].value;
          }
        });
      });
    });

    return subtotals;
  }

  exportToExcelData(): void {
    const dataToExport: any[] = [];

    this.tabMonth.forEach((tab: any) => {
      this.response.rows.forEach((row: any) => {
        if (row[tab.label]) {
          const subtotalsForSheet1 = this.calculateSubtotalsForSheet(
            this.headerResult,
            row[tab.label]
          );

          dataToExport.push({
            sheet: tab.label,
            headers: this.headerResult,
            rows: this.formattedDataTableToExport(
              row[tab.label],
              subtotalsForSheet1
            ),
          });
        }
      });
    });

    this.excelSheetsService.exportJsonAsExcelFile(
      dataToExport,
      `export -ka-execution`
    );
  }

  formattedDataTableToExport(response: any, subtotalsForSheet1?: any) {
    const currentWeekData = response.rows.filter((row: any) => {
      return row.data;
    });

    const formattedCurrentWeekData = currentWeekData.map((data: any) =>
      data.data.map((row: any) => this.formatRows(row))
    );

    const subtotalMap: { [ka: string]: any } = {};

    response.rows.forEach((selectedKa: any) => {
      if (subtotalsForSheet1) {
        const subtotal =
          subtotalsForSheet1[selectedKa.ka]?.subtotalsByColumn || {};
        subtotalMap[selectedKa.ka] = { ka: 'SUBTOTAL', ...subtotal };
      }
    });

    const formattedDataWithSubtotal = formattedCurrentWeekData.map(
      (data: any) => {
        const ka = data[0]?.ka;

        return [...data, subtotalMap[ka] || {}];
      }
    );

    const filteredSubtotals: { [key: string]: any } = {};

    response.rows.forEach((ka: any) => {
      if (subtotalsForSheet1[ka.ka]) {
        for (const header in subtotalsForSheet1[ka.ka].subtotalsByColumn) {
          if (!filteredSubtotals[header]) {
            filteredSubtotals[header] = 0;
          }
          filteredSubtotals[header] +=
            subtotalsForSheet1[ka.ka].subtotalsByColumn[header];
        }
      }
    });

    const totalRow: { [key: string]: any } = { ka: 'TOTAL' };
    for (const header in filteredSubtotals) {
      totalRow[header] = filteredSubtotals[header];
    }
    formattedDataWithSubtotal.push([totalRow]);

    return formattedDataWithSubtotal.flat();
  }

  formatRows(rowData: any) {
    const formattedRow: any = {};

    for (const key of Object.keys(rowData)) {
      formattedRow[key] = rowData[key].value;
    }

    return formattedRow;
  }

  backButton() {
    this.router.navigate(['home/ka-allocation']);
  }
}
