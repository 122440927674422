import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import * as packageJSON from '../../../../package.json';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  environmentName = environment.name;
  versionApp: any = (packageJSON as any).default.version;

  teste: any = environment;

  crudService: CrudService<any>;
  getStringUnic: string = '';
  getStringMultiple: any;
  htmlVersion: any;
  filter: any;
  hideRelease!: Boolean;
  currentUser: any;

  lastUpdateOrderBook: string | null = '';
  showLastUpdateOrderBook: boolean = false;
  showReleaseNotes: boolean = false;

  private subscriptionLastUpdate!: Subscription;

  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private modalService: ModalActionsService,
    private spinner: NgxSpinnerService,
    private sharedInfoService: SharedinfoService,
    private dataService: AuthService,
  ) {
    this.crudService = new CrudService<any>(this.http, '');
  }

  ngOnInit() {  
    this.getLoading();
  }

  ngOnDestroy() {
    this.subscriptionLastUpdate.unsubscribe();
  }

  getLoading() {
    this.dataService.currentInputData.subscribe((data: any) => {
      if (data === true) {
        this.getUser();
        this.getLastUpdateOrderBook();
      }
    });
  }

  getUser() {
    const token = this.authService.getInstance().localStorage;
    if (!!token.access_token && !!token.user_permissions.length) {
      [this.currentUser] = JSON.parse(token.user_permissions);
      this.showReleaseNotes = this.currentUser.geo.toUpperCase() === 'LATAM';
      this.showLastUpdateOrderBook =
        this.currentUser.geo.toUpperCase() === 'LATAM';
    }
  }

  getLastUpdateOrderBook() {
    this.subscriptionLastUpdate = this.sharedInfoService
      .getLastUpdateStorage()
      .subscribe(data => {
        if (data.length > 0) {
          this.lastUpdateOrderBook = data;
        } else {
          this.lastUpdateOrderBook = JSON.parse(
            localStorage.getItem('last_update') || 'null'
          );
        }
      });
  }

  prepareToReadNotes() {
    this.spinner.show();
    if (this.currentUser) {
      this.crudService
        .getEntity('release-notes', { country: this.currentUser.country })
        .subscribe((response: any) => {
          if (!!response.length) {
            this.prepareToCreate();
            this.getStringUnic = response[0].notes;
            this.htmlVersion = `${this.getStringUnic}`;
            this.spinner.hide();
            this.hideRelease = true;
          } else {
            this.spinner.hide();
            this.hideRelease = false;
          }
        });
      this.crudService
        .getEntity('release-notes', {
          notes: 'all',
          country: this.currentUser.country,
        })
        .subscribe((response: any) => {
          if (!!response.length) {
            this.getStringMultiple = response;
            this.spinner.hide();
            this.hideRelease = true;
          } else {
            this.spinner.hide();
            this.hideRelease = false;
          }
        });
    }
  }

  prepareToCreate() {
    const createModal = this.modalService.createModal(this.modalCreateEdit);
    createModal.afterClosed().subscribe(() => {});
  }
}
