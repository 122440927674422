<div class="tabs">
  <button
    *ngFor="let tab of tabMonth"
    (click)="onChangeTab(tab.label)"
    [class.active]="tab.label === tabSelected">
    {{ tab.label }}
  </button>
</div>

<div class="container-ka-execution-table">
  <table id="table">
    <thead>
      <tr>
        <th *ngFor="let header of displayedColumns">
          <ng-container>
            <mat-card>
              <span
                [ngClass]="{
                  'left-columns': expecificProp(header.props)
                }">
                {{ header.title }}
              </span>

              <ng-container *ngIf="header.typeShowEvent">
                <div
                  *ngIf="
                    header.props === header.otherContent &&
                    !header.hideIconExpand
                  "
                  class="cell-icon-arrow">
                  <mat-icon
                    *ngIf="!header.expanded"
                    (click)="toggleColumnTable(header, 'show')">
                    {{ 'arrow_left' }}
                  </mat-icon>

                  <mat-icon
                    *ngIf="header.expanded"
                    id="table"
                    (click)="toggleColumnTable(header, 'hide')">
                    {{ 'arrow_right' }}
                  </mat-icon>
                </div>
              </ng-container>
            </mat-card>
          </ng-container>
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let kaData of response.rows">
        <ng-container *ngFor="let rowData of kaData.data; let i = index">
          <tr
            class="column"
            [attr.data-rowId]="getRowId(i, rowData['ka'].value)">
            <td
              *ngFor="let header of displayedColumns"
              [ngClass]="{
                'centrelize-items': isPropNumber(rowData[header.props]?.value),
                'centrelize-items-right': isPropString(
                  rowData[header.props]?.value
                )
              }"
              [class.orange-value]="
                orangeValueForSpecificColumn(
                  header.props,
                  rowData[header.props]?.value
                )
              "
              [class.row-color]="colorRow(header.props)"
              (mouseenter)="mouseEnter(i, rowData)"
              (mouseleave)="mouseLeave(i, rowData)">
              <app-value-currency
                [class.negative-value]="rowData[header.props]?.value < 0"
                [value]="
                  rowData[header.props]?.value === ''
                    ? ''
                    : rowData[header.props]?.value
                ">
              </app-value-currency>
            </td>
          </tr>
          <!-- Verifica se é a última linha de data para este ka -->
          <ng-container *ngIf="i === kaData.data.length - 1">
            <tr>
              <td class="subtotal" *ngFor="let header of displayedColumns">
                <span *ngIf="header.props === 'ka'" class="title-subtotal"
                  >SUBTOTAL
                </span>
                <ng-container *ngIf="!expecificProp(header.props)">
                  <div
                    class="subtotal-value"
                    [class.orange-value]="
                      orangeValueForSpecificColumn(
                        header.props,
                        subtotal[kaData.ka]?.subtotalsByColumn[header.props]
                      )
                    ">
                    <span
                      [class.negative-value]="
                        subtotal[kaData.ka]?.subtotalsByColumn[header.props] < 0
                      ">
                      {{
                        subtotal[kaData.ka]?.subtotalsByColumn[header.props]
                          | number : '1.0-0' || 0
                      }}
                    </span>
                  </div>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>

    <tfoot id="footer">
      <ng-container>
        <tr>
          <ng-container *ngFor="let header of displayedColumns">
            <td class="total">
              <span *ngIf="header.props === 'ka'" class="title-total"
                >TOTAL
              </span>
              <ng-container *ngIf="!expecificProp(header.props)">
                <div
                  class="total-value"
                  [class.orange-value]="
                    orangeValueForSpecificColumn(
                      header.props,
                      total.totalsByColumn[header.props]
                    )
                  ">
                  <span
                    [class.negative-value]="
                      total.totalsByColumn[header.props] < 0
                    ">
                    {{
                      total.totalsByColumn[header.props] | number : '1.0-0' || 0
                    }}
                  </span>
                </div>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tfoot>
  </table>
</div>
