<ngx-spinner></ngx-spinner>
<div class="container-notifications">
  <div class="line-title">
    <span class="title">NOTIFICATIONS</span>
  </div>

  <div class="body-awaiting-notifications" *ngIf="!awaitingNotifications">
    <h4>Awaiting Notifications...</h4>
  </div>

  <div class="body-notifications" *ngIf="awaitingNotifications">
    <mat-tab-group>
      <mat-tab *ngFor="let tab of asyncTabs; let i = index">
        <ng-template mat-tab-label>
          <span class="tab-title">{{ tab.label }}</span>
          <div class="tab-view">
            <span>{{ tab.totalRows }}</span>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <span class="tab-subtitle">Recent Updates</span>
          <div>
            <app-table-notifications
              [dataNotifications]="tab.dataSource"
              [pageNotification]="tab.page"
              [totalRowsNotification]="tab.totalRows"
              [limitNotification]="tab.limit"
              [sidebarNotifications]="false"
              (customEvent)="updateTableNotifications($event)"
              (resultPaginator)="onChangePaginator($event, tab)"
              [dashboardNotification]="false"></app-table-notifications>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>
