<div [formGroup]="form">
  <label appRemoveAriaOwns [attr.for]="inputs.key">{{ inputs.label }}</label>
  <div [ngSwitch]="inputs.controlType">
    <div *ngSwitchCase="'textbox'">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns>{{ inputs.hover }}</mat-label>
        <input
          matInput
          autocomplete="off"
          [formControlName]="inputs.key"
          (keyup)="
            onSearchFilter(
              'textbox',
              this.form.controls[inputs.key],
              $event,
              inputs.hasCheckFlowException
            )
          " />
        <button
          *ngIf="form.controls[inputs.key].value && !inputs.notClearable"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="
            form.controls[inputs.key].reset();
            clearSearch($event, inputs.hasCheckFlowException)
          ">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'select'">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns>{{ inputs.label }}</mat-label>
        <input matInput type="text" />
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'search'">
      <mat-form-field
        appRemoveAriaOwns
        appearance="outline"
        class="my-form-field"
        [ngClass]="{
          'large-input': inputs.largeInput
        }"
        [class.warn]="
          (!form.controls[inputs.key].value && inputs.warn) ||
          isFormFieldFocused
        "
        [color]="
          !form.controls[inputs.key].value && inputs.warn ? 'warn' : 'primary'
        ">
        <mat-label appRemoveAriaOwns>{{ inputs.hover || '' }}</mat-label>
        <div *ngIf="!inputs.hasAutocomplete && !inputs.hasMultiple">
          <mat-select
            appRemoveAriaOwns
            name="inputs.key"
            [formControlName]="inputs.key"
            [value]="inputs.value"
            (selectionChange)="
              onSearchFilter(
                'search',
                this.form.controls[inputs.key],
                $event,
                inputs.hasCheckFlowException
              )
            "
            disableOptionCentering
            inputDirective
            panelClass="inputs-select">
            <mat-option
              *ngFor="let opt of inputs.options"
              [value]="opt.value"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </mat-select>
        </div>
        <div *ngIf="inputs.hasAutocomplete">
          <mat-icon class="material-icons align-arrow-right"
            >arrow_drop_down</mat-icon
          >
          <input
            #trigger="matAutocompleteTrigger"
            type="{{ inputs.type }}"
            matInput
            [formControlName]="inputs.key"
            [matAutocomplete]="auto"
            (focus)="onKeyPress($event)"
            #matInput
            (keydown.enter)="
              onSearchFilter(
                'search',
                this.form.controls[inputs.key],
                $event,
                inputs.hasCheckFlowException
              )
            "
            (keyup)="
              onSearchFilter(
                'textbox',
                this.form.controls[inputs.key],
                $event,
                inputs.hasCheckFlowException
              )
            " />
          <mat-autocomplete
            isOpen
            #auto="matAutocomplete"
            dropdown-arrow="true"
            class="{{ inputs.classCss }}"
            [classList]="[inputs.classCss]">
            <div
              (click)="
                onSearchFilter(
                  'search',
                  this.form.controls[inputs.key],
                  $event,
                  inputs.hasCheckFlowException
                )
              ">
              <mat-option
                *ngFor="let opt of filteredOptions | async"
                [value]="opt.value"
                matTooltip="{{ opt.value }}">
                {{ opt.value || '' }}
              </mat-option>
            </div>
          </mat-autocomplete>
        </div>
        <div *ngIf="inputs.hasMultiple">
          <mat-select
            appRemoveAriaOwns
            multiple
            name="inputs.key"
            [formControlName]="inputs.key"
            [value]="inputs.value"
            (selectionChange)="
              onSearchFilter(
                'search',
                this.form.controls[inputs.key],
                $event,
                inputs.hasCheckFlowException
              )
            "
            disableOptionCentering
            inputDirective
            panelClass="multiple-panel">
            <mat-option
              *ngFor="let opt of inputs.options"
              [value]="opt.value"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </mat-select>
        </div>
        <button
          *ngIf="
            this.form.controls[inputs.key].value?.length > 0 &&
            !inputs.notClearable
          "
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearSearch($event, inputs.hasCheckFlowException)">
          <mat-icon>close</mat-icon>
        </button>
        <div class="mat-select-arrow-wrapper ng-tns-c146-16"></div>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'datePicker'">
      <mat-form-field appearance="outline">
        <mat-label appRemoveAriaOwns>Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          class="mat-datepicker-input"
          name="inputs.key"
          [formControlName]="inputs.key"
          (dateChange)="
            onSearchFilter(
              'datepicker',
              this.form.controls[inputs.key],
              $event,
              inputs.hasCheckFlowException
            )
          " />
        <mat-icon
          class="mat-icon-position-close"
          [class.hidden-clear-icon]="!showClearFilterDate"
          matDatepickerToggleIcon
          (click)="
            clearFilterDate($event);
            clearSearch($event, inputs.hasCheckFlowException)
          ">
          close
        </mat-icon>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="'multiselect-search'">
      <mat-form-field
        appearance="outline"
        [ngClass]="{
          'large-input': inputs.largeInput
        }">
        <mat-label>{{ inputs.hover }}</mat-label>
        <mat-select
          appRemoveAriaOwns
          class="mat-select-search"
          [formControlName]="inputs.key"
          multiple
          disableOptionCentering
          [panelClass]="panelClass"
          (selectionChange)="
            onSearchFilter(
              'multiselect-search',
              this.form.controls[inputs.key],
              $event,
              inputs.hasCheckFlowException
            )
          ">
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              [toggleAllCheckboxChecked]="isChecked"
              (toggleAll)="toggleSelectAll($event)"
              placeholderLabel="Search"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="inputs.key === 'productDesc'"
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              placeholderLabel="Select max. 5"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="inputs.key === 'customerName'"
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              placeholderLabel="Select max. 100"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="inputs.key === 'customerNumber'"
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              placeholderLabel="Select max. 100"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="inputs.key === 'family' && inputs.module === 'family-name'"
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              placeholderLabel="Select max. 1"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="
                inputs.key === 'family' &&
                inputs.module !== 'family-name' &&
                inputs.module !== 'one-plan'
              "
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              placeholderLabel="Select max. 3"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="
                inputs.key === 'customerShortId' &&
                !inputs.limit &&
                inputs.module === 'customer-view'
              "
              [toggleAllCheckboxIndeterminate]="isIndeterminate"
              placeholderLabel="Select max. 3"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
            <ngx-mat-select-search
              *ngIf="inputs.limit"
              placeholderLabel="Select max. {{ inputs.valueForFilterLimit }}"
              [disableScrollToActiveOnOptionsChanged]="true"
              [alwaysRestoreSelectedOptionsMulti]="true"
              noEntriesFoundLabel="No entry matches"
              [formControl]="inputFilterFormControl"
              #matSelectSearch>
            </ngx-mat-select-search>
          </mat-option>
          <div *ngIf="inputs.key === 'productDesc'" class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              [disabled]="opt.disabled || disabledOption(opt)"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
          <div *ngIf="inputs.key === 'customerName'" class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              [disabled]="opt.disabled || disabledOptionCustomerName(opt)"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
          <div *ngIf="inputs.key === 'customerNumber'" class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              [disabled]="opt.disabled || disabledOptionCustomerNumber(opt)"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
          <div
            *ngIf="inputs.key === 'family' && inputs.module !== 'one-plan'"
            class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              [disabled]="opt.disabled || disabledOptionFamily(opt)"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
          <div
            *ngIf="
              inputs.key === 'customerShortId' &&
              !inputs.limit &&
              inputs.module === 'customer-view'
            "
            class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              [disabled]="opt.disabled || disabledOptionCustomerShort(opt)"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
          <div *ngIf="inputs.limit" class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              [disabled]="opt.disabled || disabledOptionWithlimitedValue(opt)"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
          <div *ngIf="!inputs.limitedValue" class="overflow-select">
            <mat-option
              *ngFor="let opt of filteredOptionsMultiSelect | async"
              [value]="opt"
              matTooltip="{{ opt.value }}">
              {{ opt.value || '' }}
            </mat-option>
          </div>
        </mat-select>
        <button
          *ngIf="
            this.form.controls[inputs.key]?.value?.length > 0 &&
            !inputs.notClearable
          "
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="clearSearch($event, inputs.hasCheckFlowException)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
