import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
  SimpleChanges,
} from '@angular/core';
import {
  Header,
  CustomTableStructure,
} from 'src/app/interfaces/ka-execution-interface';
@Component({
  selector: 'app-ka-execution-table',
  templateUrl: './ka-execution-table.component.html',
  styleUrls: ['./ka-execution-table.component.scss'],
})
export class KaExecutionTableComponent implements OnInit {
  @Input() data!: CustomTableStructure;
  @Input() subtotal!: any[];
  @Input() total: any;
  @Input() response!: any;
  @Input() tabMonth!: any;
  @Input() tabSelected!: string;
  @Output() changeTab = new EventEmitter<any>();
  headers!: Header[];
  rows!: any[];
  displayedColumns!: any[];

  constructor(private app: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.headers = changes.data.currentValue?.table.columns;
      this.rows = changes.data.currentValue?.table.rows.filteredData;

      this.filterAwaysVisibleColumns();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPage();
  }
  scrollPage() {
    const tableHeader = this.app.nativeElement.querySelector('#table thead tr');
    const tableFooter = this.app.nativeElement.querySelector('#footer');

    if (tableHeader && tableFooter) {
      const windowTop = window.scrollY || window.pageYOffset;
      const tableTop =
        this.app.nativeElement.getBoundingClientRect().top + windowTop;
      const footerTop = tableFooter.getBoundingClientRect().top + windowTop;
      const offset = 25;
      const height = `${windowTop - offset - tableTop}px`;
      if (windowTop + tableHeader.offsetHeight <= footerTop) {
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${height}`;
      } else {
        // parar antes do footer
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${
          footerTop - tableTop - tableHeader.offsetHeight
        }px`;
      }
    }
    this.cd.detectChanges();
  }

  getRowId(kaId: any, index: number): string {
    return `${kaId + index}`;
  }
  mouseEnter(indexRow: number, row: any) {
    const kaValue: string = row['ka'].value;
    const rowId = indexRow + row['ka'].value;
    const tableRow = document.querySelector(`tr[data-rowId="${rowId}"]`);
    const rows = indexRow + row['ka'].value;

    if (rows === rowId) {
      tableRow?.classList.add(`hover-row${kaValue}`);
    }
  }

  mouseLeave(indexRow: number, row: any) {
    const kaValue: string = row['ka'].value;
    const rowId = indexRow + row['ka'].value;
    const tableRow = document.querySelector(`tr[data-rowId="${rowId}"]`);
    const rows = indexRow + row['ka'].value;

    if (rows === rowId) {
      tableRow?.classList.remove(`hover-row${kaValue}`);
    }
  }

  onChangeTab(tab: string) {
    this.changeTab.emit(tab);
  }

  filterAwaysVisibleColumns() {
    return (this.displayedColumns = this.headers.filter((item: Header) =>
      item.props && item.alwaysVisible
        ? item.props && item.alwaysVisible
        : item.alwaysVisible != false
    ));
  }

  isPropNumber(prop: number): boolean {
    return typeof prop === 'number';
  }

  isPropString(prop: string): boolean {
    return typeof prop === 'string';
  }
  colorRow(prop: string) {
    return prop === 'actual' || prop === 'alloc' || prop === 'gap';
  }

  expecificProp(prop: string): boolean {
    return (
      prop === 'ka' ||
      prop === 'ppm' ||
      prop === 'origin' ||
      prop === 'salesModel'
    );
  }

  toggleColumnTable(header?: any, event?: string) {
    if (event === 'show') {
      header.expanded = true;
      const clickedOtherContent = header.otherContent;

      this.headers.filter((item: Header) => {
        if (item.otherContent === clickedOtherContent) {
          item.alwaysVisible = true;
        }
      });

      // this.filterAwaysVisibleColumns();
    } else {
      header.expanded = false;
      const clickedOtherContent = header.otherContent;

      this.headers.forEach((item: Header) => {
        if (
          header.props !== item.props &&
          item.otherContent === clickedOtherContent
        ) {
          item.alwaysVisible = false;
        }
      });

      // this.filterAwaysVisibleColumns();
    }
  }

  orangeValueForSpecificColumn(column: any, row?: any) {
    return column === 'overBooking' && row > 0 ? 'overBooking' : '';
  }
}
