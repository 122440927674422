<ngx-spinner></ngx-spinner>
<div class="table-notifications mat-elevation-z4">
  <div class="table-container">
    <table mat-table [dataSource]="dataNotifications" *ngIf="hasData">
      <ng-container matColumnDef="notifications">
        <th mat-header-cell *matHeaderCellDef>Notifications</th>
        <td mat-cell *matCellDef="let element">
          <div class="title-and-button">
            <div class="notifications-title">
              {{ element.alert }}
              <button class="button-subtitle">{{ element.alert }}</button>
            </div>
            <div class="warnning-and-unread-pulse">
              <div
                *ngIf="element.warning"
                mat-raised-button
                matBadge="3"
                matBadgePosition="before"
                matBadgeColor="accent"
                class="img-button"></div>
              <div class="ring-container" *ngIf="element.isRecent">
                <div class="ringring"></div>
                <div class="circle"></div>
              </div>
            </div>
          </div>
          <div class="minutes-or-date-subtitle" *ngIf="element.isRecent">
            {{ element.minutesAgo }}
          </div>
          <div class="minutes-or-date-subtitle" *ngIf="!element.isRecent">
            {{ element.createdAt }}
          </div>
          <div class="notifications-subtitle">
            {{ element.subtitleResume }}
            <div class="container-actions-notifications">
              <div
                mat-raised-button
                matBadge="3"
                matBadgePosition="before"
                matBadgeColor="accent"
                class="img-details"
                matTooltip="Details"
                matTooltipPosition="left"
                #tooltip="matTooltip"
                matTooltipClass="custom-tooltip"
                (click)="viewDetails(element)"></div>
              <div
                mat-raised-button
                matBadge="3"
                matBadgePosition="before"
                matBadgeColor="accent"
                [class.img-view-page]="element.tag !== 'home/dcm/dashboards'"
                matTooltip="View Page"
                matTooltipPosition="left"
                #tooltip="matTooltip"
                matTooltipClass="custom-tooltip"
                *ngIf="element.url !== 'home/dcm/dashboards'"
                (click)="viewPage(element)"></div>
              <div
                mat-raised-button
                matBadge="3"
                matBadgePosition="before"
                matBadgeColor="accent"
                class="img-archived"
                matTooltip="Archived"
                matTooltipPosition="left"
                #tooltip="matTooltip"
                matTooltipClass="custom-tooltip"
                *ngIf="!element.archived"
                (click)="archived(element)"></div>
              <div
                mat-raised-button
                matBadge="3"
                matBadgePosition="before"
                matBadgeColor="accent"
                class="img-unarchived"
                matTooltip="Unarchived"
                matTooltipPosition="left"
                #tooltip="matTooltip"
                matTooltipClass="custom-tooltip"
                *ngIf="element.archived"
                (click)="unarchived(element)"></div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Hidden header row -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsFilterTable"
        class="hidden-header"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsFilterTable"></tr>
    </table>
    <div *ngIf="!hasData" class="message-not-notifications">
      <span>{{ messageNot }}</span>
    </div>
  </div>

  <mat-paginator
    [pageSize]="limit"
    [pageIndex]="page"
    [length]="totalRows"
    showFirstLastButtons
    [hidePageSize]="true"
    class="pagination-alerts"
    *ngIf="sidebarNotifications === false && totalRows > 0"
    (page)="handlePaginator($event)">
  </mat-paginator>
</div>

<ng-template #dialogConfirmation>
  <div class="container-modal-notifications">
    <div class="container-export">
      <h4 class="header-modal-notifications">
        {{ modalNotificationViewDetails.alert }}
      </h4>

      <span
        class="export-details-icon"
        (click)="exportTable()"
        *ngIf="isSubtitleArray()">
        <button
          class="export-button"
          matTooltip="Export"
          matTooltipPosition="left"></button>
      </span>
    </div>

    <span
      class="date-modal-notifications"
      *ngIf="modalNotificationViewDetails.isRecent">
      {{ modalNotificationViewDetails.minutesAgo }}
    </span>
    <span
      class="date-modal-notifications"
      *ngIf="!modalNotificationViewDetails.isRecent">
      {{ modalNotificationViewDetails.createdAt }}
    </span>
    <div *ngIf="isSubtitleArray()">
      <div
        *ngFor="let section of modalNotificationViewDetails.subtitleComplete"
        class="message-order-book"
        [class.large-content]="isSubtitleArrayLarge(section.content)">
        <h4>{{ section.title }} :</h4>
        <div class="container-p">
          <p>{{ section.content }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="!isSubtitleArray()">
      <p class="value-modal-notifications">
        {{ modalNotificationViewDetails.subtitleComplete }}
      </p>
    </div>
    <mat-dialog-actions>
      <button
        class="button modal-cancel-button"
        (click)="elementRead(modalNotificationViewDetails)"
        mat-button
        matDialogClose>
        CLOSE
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
