<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">CUSTOMER SETTINGS</span>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results
    [data]="result"
    [isModeTableOrCardsHide]="isModeTableOrCardsHide"
    (eventActions)="onEventActions($event)"
    (resultPaginator)="onChangePaginator($event)"
    *ngIf="!noResultCustomerSettings"></app-results>

  <div class="no-filter-selected-session" *ngIf="noResultCustomerSettings">
    <p>NO RECORDS FOUND.</p>
  </div>

  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>

<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="customerSettingsForm" class="form-emea-customer-settings">
    <header class="title-modal" matDialogTitle>
      {{ isEdit ? 'EDIT CUSTOMER' : 'NEW CUSTOMER' }}
    </header>

    <mat-dialog-content>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>GEO</mat-label>
        <mat-select
          [formControl]="geoMultiCtrl"
          [disabled]="true"
          placeholder="GEO"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Searc
              h"
              [formControl]="geoMultiFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option
            [attr.disabled]="true"
            *ngFor="let geo of filteredGeoMulti | async"
            [value]="geo.id">
            {{ geo.geo }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Country</mat-label>
        <mat-select
          panelClass="multiselect-search-country"
          [formControl]="countryMultiCtrl"
          [disabled]="true"
          placeholder="Country"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"
              [formControl]="countryMultiFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let country of filteredCountryMulti | async"
            [value]="country.id">
            {{ country.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Customer Number</mat-label>
        <mat-select
          [disabled]="true"
          placeholder="Customer Number"
          [formControl]="customerNumberMultiCtrl"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let customerNumber of filteredCustomerNumberMulti | async"
            [value]="customerNumber.customerNumber">
            {{ customerNumber.customerNumber }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-content class="mat-content-sales-order">
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Customer Name</mat-label>
        <mat-select
          [disabled]="true"
          placeholder="Customer Name"
          [formControl]="customerNameMultiCtrl"
          disableOptionCentering>
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              noEntriesFoundLabel="no matching found"
              aria-placeholder="Search"></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let customerName of filteredCustomerNameMulti | async"
            [value]="customerName.customerName || ''">
            {{ customerName.customerName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select
          panelClass="multiselect-search-status"
          formControlName="status"
          placeholder="Status"
          disableOptionCentering>
          <mat-option
            *ngFor="let status of statusEntity"
            [value]="status.value">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <!-- <button *ngIf="!isEdit" class="button modal-action-button" mat-button (click)="onCreate()">SAVE</button> -->
      <button
        *ngIf="isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onUpdate()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    {{ isEdit === true ? 'Do you confirm to edit Status?' : '' }}
    {{ isEdit === false ? 'Do you confirm to remove this Status?' : '' }}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
