export const tableExecutiveOverviewMockup: any = {
  table: {
    columns: [
      {
        label: 'MARKET NAME',
        prop: 'marketName',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'PRODUCT',
        prop: 'product',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'BENELUX',
        prop: 'benelux',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'FRANCE',
        prop: 'france',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'GERMANY',
        prop: 'germany',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'IBERIA',
        prop: 'iberia',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'ITALY',
        prop: 'italy',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'MEA',
        prop: 'mea',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'NORDICS',
        prop: 'nordics',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'POLAND',
        prop: 'poland',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'RUSSIA',
        prop: 'russia',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'SEE',
        prop: 'see',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'UKI',
        prop: 'uki',
        sticky: true,
        style: { width: '7,14%' },
      },
      {
        label: 'TOTAL',
        prop: 'total',
        sticky: true,
        style: { width: '7,14%' },
      },
    ],
    rows: [
      {
        marketName: {
          value: 'Moto E20',
        },
        product: {
          value: 'Aruba',
        },
        benelux: {
          value: 0,
          summableValue: true,
        },
        france: {
          value: 0,
          summableValue: true,
        },
        germany: {
          value: 0,
          summableValue: true,
        },
        iberia: {
          value: 0,
          summableValue: true,
        },
        italy: {
          value: 0,
          summableValue: true,
        },
        mea: {
          value: 0,
          summableValue: true,
        },
        nordics: {
          value: 0,
          summableValue: true,
        },
        poland: {
          value: 0,
          summableValue: true,
        },
        russia: {
          value: 0,
          summableValue: true,
        },
        see: {
          value: 0,
          summableValue: true,
        },
        uki: {
          value: 0,
          summableValue: true,
        },
        total: {
          value: 0,
          summableValue: true,
        },
      },
      {
        marketName: {
          value: 'Moto G30',
        },
        product: {
          value: 'Capri+',
        },
        benelux: {
          value: 0,
          summableValue: true,
        },
        france: {
          value: 0,
          summableValue: true,
        },
        germany: {
          value: 0,
          summableValue: true,
        },
        iberia: {
          value: 0,
          summableValue: true,
        },
        italy: {
          value: 0,
          summableValue: true,
        },
        mea: {
          value: 0,
          summableValue: true,
        },
        nordics: {
          value: 0,
          summableValue: true,
        },
        poland: {
          value: 0,
          summableValue: true,
        },
        russia: {
          value: 0,
          summableValue: true,
        },
        see: {
          value: 0,
          summableValue: true,
        },
        uki: {
          value: 0,
          summableValue: true,
        },
        total: {
          value: 0,
          summableValue: true,
        },
      },
      {
        marketName: {
          value: 'Moto Edge E20',
        },
        product: {
          value: 'Berlin',
        },
        benelux: {
          value: 0,
          summableValue: true,
        },
        france: {
          value: 0,
          summableValue: true,
        },
        germany: {
          value: 0,
          summableValue: true,
        },
        iberia: {
          value: 0,
          summableValue: true,
        },
        italy: {
          value: 0,
          summableValue: true,
        },
        mea: {
          value: 0,
          summableValue: true,
        },
        nordics: {
          value: 0,
          summableValue: true,
        },
        poland: {
          value: 0,
          summableValue: true,
        },
        russia: {
          value: 0,
          summableValue: true,
        },
        see: {
          value: 0,
          summableValue: true,
        },
        uki: {
          value: 0,
          summableValue: true,
        },
        total: {
          value: 0,
          summableValue: true,
        },
      },
      {
        marketName: {
          value: 'Moto Edge E20',
        },
        product: {
          value: 'Corfu',
        },
        benelux: {
          value: 0,
          summableValue: true,
        },
        france: {
          value: 0,
          summableValue: true,
        },
        germany: {
          value: 0,
          summableValue: true,
        },
        iberia: {
          value: 0,
          summableValue: true,
        },
        italy: {
          value: 0,
          summableValue: true,
        },
        mea: {
          value: 0,
          summableValue: true,
        },
        nordics: {
          value: 0,
          summableValue: true,
        },
        poland: {
          value: 0,
          summableValue: true,
        },
        russia: {
          value: 0,
          summableValue: true,
        },
        see: {
          value: 0,
          summableValue: true,
        },
        uki: {
          value: 0,
          summableValue: true,
        },
        total: {
          value: 0,
          summableValue: true,
        },
      },
    ],
  },
};
