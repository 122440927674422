<div>
  <a
    class="textshowOrHideColumns"
    href="javascript:void(0)"
    (click)="toggleColumnTable()"
    >{{ textshowOrHideColumns }}</a
  >
</div>
<div #tableDynamic class="table-dynamic">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let col of displayedDef; let indexCol = index">
      <ng-container [matColumnDef]="col.props">
        <th
          [ngStyle]="col.style"
          mat-header-cell
          *matHeaderCellDef
          [class.mat-cell-head]="col.props !== 'product'"
          [class.expand-true]="!expandTrue"
          [class.mat-cell-actual]="col.actual">
          <mat-card
            [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
            [class.test]="col.enableContent"
            [class.card-column-actual-week]="col.weekInTime === 'current'"
            [class.card-column-disable]="col.weekInTime === 'previous'">
            <div>
              <span>{{ col.title }}</span>
            </div>
          </mat-card>
          <mat-card
            *ngIf="translateYAbsoluteItem > 0"
            [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
            [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
            class="absoluteItem"
            [class.test]="col.enableContent"
            [class.card-column-actual-week]="col.weekInTime === 'current'"
            [class.card-column-disable]="col.weekInTime === 'previous'">
            <div>
              <span>{{ col.title }}</span>
            </div>
          </mat-card>
        </th>

        <td
          mat-cell
          [class.mat-cell-product]="col.props === 'product'"
          [class.mat-cell-header]="col.props === 'lastMonth'"
          *matCellDef="let element; let indexRow = index">
          <div [class.flexItems-bp]="col.props === 'bp'">
            <div *ngIf="!element[col.props]?.enableInput">
              <app-value-currency
                [value]="
                  element[col.props]?.value === ''
                    ? '-'
                    : element[col.props]?.value
                "></app-value-currency>
            </div>
          </div>

          <div *ngIf="element[col.props]?.enableInput">
            <input
              oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
              class="input-number"
              maxlength="9"
              value="{{
                element[col.props]?.value || '0'
                  | convertValue
                  | number: '1.0-0':'en-US'
              }}"
              autocomplete="off"
              matInput
              [class.input-number-disable]="col.weekInTime === 'previous'"
              [disabled]="col.weekInTime === 'previous'"
              (keyup)="onChangeEvent({indexRow, columnName: col.props, row:
            element, col }, $event)" />
          </div>
        </td>

        <td
          mat-footer-cell
          *matFooterCellDef="let element; let indexRow = index">
          <span *ngIf="col.props === 'product'" class="table-row-total-spacer"
            >TOTAL</span
          >
          <span>
            <app-value-currency
              *ngIf="col.props !== 'product' && col.props !== 'origin'"
              [value]="
                col.totalAddedValue === '' ? '-' : col.totalAddedValue
              "></app-value-currency>
          </span>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="example-element-row"></tr>
    <tr
      mat-footer-row
      class="table-row-total"
      *matFooterRowDef="displayedColumns; sticky: true"></tr>
  </table>
</div>
