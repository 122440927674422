<div class="content-table" #tableDynamic>
  <table class="fixed-columns" mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let col of displayedDef; let indexCol = index">
      <ng-container [matColumnDef]="col.props">
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.border-left]="col.month && !col.firstWeek"
          [class.border-horizontal]="col.styleBorderHorizontal"
          [ngStyle]="col.style"
          [class.is-ka-column]="col.props === 'ka'">
          <div
            class="content-header"
            [class.is-column-family]="col.props === 'family'">
            <div
              class="title"
              [class.padding-firt-title]="
                col.props !== 'bohTotal' && col.props !== 'totalSupply'
              ">
              <div
                class="columns-wrap"
                *ngIf="col.hideIconExpand; else otherColumns">
                <mat-card [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1">
                  <span>
                    {{ col.title }}
                  </span>
                </mat-card>
                <mat-card
                  *ngIf="translateYAbsoluteItem > 0"
                  [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                  [style.transform]="
                    'translateY(' + translateYAbsoluteItem + 'px)'
                  "
                  class="absoluteItem">
                  <span>
                    {{ col.title }}
                  </span>
                </mat-card>
              </div>
              <ng-template #otherColumns>
                <div class="columns-wrap is-icon-expanded-column">
                  <mat-card
                    [style.margin-top]="(col.props.includes('wk') || (col.otherContent.includes('gap') && !col.alwaysVisible)) ? '2px': '-6px'"
                    [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1">
                    <span>
                      {{ col.title }}
                    </span>
                    <ng-container *ngIf="col.typeShowEvent">
                      <div
                        *ngIf="
                          col.props === col.otherContent &&
                          !col.hideIconExpand &&
                          col.secondColumn
                        "
                        class="cell-icon-arrow">
                        <mat-icon
                          *ngIf="!col.expanded"
                          (click)="toggleColumnTable(col, 'show')">
                          {{ 'add_circle_outline' }}
                        </mat-icon>
                        <mat-icon
                          *ngIf="col.expanded"
                          (click)="toggleColumnTable(col, 'hide')">
                          {{ 'remove_circle_outline' }}
                        </mat-icon>
                      </div>
                    </ng-container>
                  </mat-card>
                  <mat-card
                    *ngIf="translateYAbsoluteItem > 0"
                    [style.margin-top]="(col.props.includes('wk') || (col.otherContent.includes('gap') && !col.alwaysVisible)) ? '2px': '-6px'"
                    [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                    [style.transform]="
                      'translateY(' + translateYAbsoluteItem + 'px)'
                    "
                    class="absoluteIconItem">
                    <span>
                      {{ col.title }}
                    </span>
                    <ng-container *ngIf="col.typeShowEvent">
                      <div
                        *ngIf="
                          col.props === col.otherContent &&
                          !col.hideIconExpand &&
                          col.secondColumn
                        "
                        class="cell-icon-arrow">
                        <mat-icon
                          *ngIf="!col.expanded"
                          (click)="toggleColumnTable(col, 'show')">
                          {{ 'add_circle_outline' }}
                        </mat-icon>
                        <mat-icon
                          *ngIf="col.expanded"
                          (click)="toggleColumnTable(col, 'hide')">
                          {{ 'remove_circle_outline' }}
                        </mat-icon>
                      </div>
                    </ng-container>
                  </mat-card>
                </div>
              </ng-template>
            </div>
          </div>
        </th>

        <td
          mat-cell
          *matCellDef="let element; let indexRow = index"
          id="{{ getAddRowId(indexRow) }}"
          (mouseenter)="mouseEnter(indexRow, element)"
          (mouseleave)="mouseLeave(indexRow, element)"
          [class.bg-blue]="col.isPercentage"
          [class.border-horizontal]="col.styleBorderHorizontal"
          [class.negative-value]="element[col.props]?.value < 0"
          [class.table-row-total]="element?.enableTotal"
          [class.table-row-total-retail]="element?.enableRetail"
          [class.not-family]="col.props !== 'family' && col.props !== 'ka'"
          [class.is-ka-row]="col.props === 'ka'">
          <div
            *ngIf="!element[col.props]?.expandedIcon; else showIconAccordion">
            <div
              *ngIf="
                (col.props === 'family' && element?.enableTotal) ||
                  (col.props === 'family' && element?.enableRetail);
                else valueCurrency
              ">
              <span class="table-row-total-spacer">
                {{ element[col.props]?.value }}
              </span>
            </div>
            <ng-template #valueCurrency>
              <div
                *ngIf="
                  element[col.props]?.isPercentage;
                  else otherValueCurrency
                "
                style="display: inline-flex; align-items: center">
                <app-value-currency
                  [value]="
                    element[col.props]?.value === ''
                      ? ''
                      : element[col.props]?.value
                  "></app-value-currency
                ><span *ngIf="element[col.props]?.value !== ''">%</span>
              </div>
              <ng-template #otherValueCurrency>
                <div
                  *ngIf="col.props === 'ka'"
                  class="border"
                  [ngClass]="element['ka'].value"
                  [ngStyle]="{ 'background-color': element['ka'].color }"></div>
                <app-value-currency
                  [value]="
                    element[col.props]?.value === ''
                      ? ''
                      : element[col.props]?.value
                  "></app-value-currency>
              </ng-template>
            </ng-template>
          </div>
          <ng-template #showIconAccordion>
            <div
              class="cell-column-expanded-icon"
              *ngIf="col.props === 'family' && element[col.props].expandedIcon">
              <span>
                {{
                  element[col.props]?.value === ''
                    ? ''
                    : element[col.props]?.value
                }}
              </span>
              <div
                class="show-icon"
                *ngIf="!element.expandedVertical"
                (click)="expandedRow(col, element, 'show')">
                <span>
                  <mat-icon class="icon-color">expand_more</mat-icon>
                </span>
              </div>
              <div
                class="show-icon"
                *ngIf="element.expandedVertical"
                (click)="expandedRow(col, element, 'hide')">
                <span>
                  <mat-icon class="icon-color">expand_less</mat-icon>
                </span>
              </div>
            </div>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="row-color"
      *matRowDef="let row; columns: displayedColumns; let i = index"
      [ngStyle]="{
        display: row?.rowFamily || row?.expandedVertical ? '' : 'none'
      }"></tr>
  </table>
</div>
