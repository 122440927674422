<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="header-title">
    <span class="title">TO BOOK</span>
    <div class="container-export">
      <span class="last-update">{{ lastUpdate }}</span>
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="!isValid"
          [disabled]="!isValid"></button>
      </span>
    </div>
  </div>
  <div class="to-book-content">
    <app-tab
      [tabs]="tabs"
      [method]="this"
      [messagesDialog]="messages"
      [isEditTableMode]="isEditTableMode"
      (click)="tabClick($event)"
      #TabComponent>
    </app-tab>

    <ng-template #OrderAdmin>
      <app-search
        (searchEvent)="onSearchEvent($event, 'orderAdmin')"
        [filters]="searchInputsOrderAdmin"
        (applyFilterEvent)="applyFilter('orderAdmin')"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="true"
        class="order-admin">
      </app-search>
      <mat-divider></mat-divider>
      <section class="header-table-allocation">
        <div class="title-section">
          <h3 class="t-section">Order Admin</h3>
          <h4 class="st-section"></h4>
        </div>
        <div class="button-filter">
          <button
            *ngFor="let item of filterTable"
            (click)="handleFilterButton(item)"
            [class.active]="item.value">
            {{ item.label }}
          </button>
        </div>
      </section>
      <div class="no-filter-selected-session" *ngIf="orderAdminSelected">
        <p>PLEASE,<br />START BY FILTERING ORDER ADMIN FIRST</p>
      </div>
      <div class="no-filter-selected-session" *ngIf="noResultOrderAdmin">
        <p>NO RECORDS FOUND.</p>
      </div>
      <app-table-book
        [dataTable]="dataOrderAdminToBook"
        *ngIf="!noResultOrderAdmin && !allocate.value">
      </app-table-book>
      <app-table-book
        [dataTable]="dataOrderAdminAllocate"
        *ngIf="!noResultOrderAdmin && !toBook.value">
      </app-table-book>
    </ng-template>

    <ng-template #SalesManager>
      <app-search
        (searchEvent)="onSearchEvent($event, 'salesManager')"
        [filters]="searchInputsSalesManager"
        (applyFilterEvent)="applyFilter('salesManager')"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="true"
        class="sales-manager">
      </app-search>
      <mat-divider></mat-divider>
      <section class="header-table-allocation">
        <div class="title-section">
          <h3 class="t-section">Sales Manager</h3>
          <h4 class="st-section"></h4>
        </div>
        <div class="button-filter">
          <button
            *ngFor="let item of filterTable"
            (click)="handleFilterButton(item)"
            [class.active]="item.value">
            {{ item.label }}
          </button>
        </div>
      </section>
      <div class="no-filter-selected-session" *ngIf="salesManagerSelected">
        <p>PLEASE,<br />START BY FILTERING SALES MANAGERS FIRST</p>
      </div>
      <div class="no-filter-selected-session" *ngIf="noResultSalesManager">
        <p>NO RECORDS FOUND.</p>
      </div>
      <app-table-book
        [dataTable]="dataSalesManagerToBook"
        *ngIf="!noResultSalesManager && !allocate.value">
      </app-table-book>
      <app-table-book
        [dataTable]="dataSalesManagerAllocate"
        *ngIf="!noResultSalesManager && !toBook.value">
      </app-table-book>
    </ng-template>

    <ng-template #Customer class="customer">
      <app-search
        (searchEvent)="onSearchEvent($event, 'customer')"
        [filters]="searchInputsCustomer"
        (applyFilterEvent)="applyFilter('customer')"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="true"
        class="customer">
      </app-search>
      <mat-divider></mat-divider>
      <section class="header-table-allocation">
        <div class="title-section">
          <h3 class="t-section">Customer Short</h3>
          <h4 class="st-section"></h4>
        </div>
        <div class="button-filter">
          <button
            *ngFor="let item of filterTable"
            (click)="handleFilterButton(item)"
            [class.active]="item.value">
            {{ item.label }}
          </button>
        </div>
      </section>
      <div class="no-filter-selected-session" *ngIf="customerShortSelected">
        <p>PLEASE,<br />START BY FILTERING CUSTOMER SHORT FIRST</p>
      </div>
      <div class="no-filter-selected-session" *ngIf="noResultCustomerShort">
        <p>NO RECORDS FOUND.</p>
      </div>
      <app-table-book
        [dataTable]="dataCustomerShortToBook"
        *ngIf="!noResultCustomerShort && !allocate.value">
      </app-table-book>
      <app-table-book
        [dataTable]="dataCustomerShortAllocate"
        *ngIf="!noResultCustomerShort && !toBook.value">
      </app-table-book>
    </ng-template>

    <ng-template #Product>
      <app-search
        (searchEvent)="onSearchEvent($event, 'product')"
        [filters]="searchInputsProduct"
        (applyFilterEvent)="applyFilter('product')"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="true"
        class="product">
      </app-search>
      <mat-divider></mat-divider>
      <section class="header-table-allocation">
        <div class="title-section">
          <h3 class="t-section">Product</h3>
          <h4 class="st-section"></h4>
        </div>
        <div class="button-filter">
          <button
            *ngFor="let item of filterTable"
            (click)="handleFilterButton(item)"
            [class.active]="item.value">
            {{ item.label }}
          </button>
        </div>
      </section>
      <div class="no-filter-selected-session" *ngIf="productSelected">
        <p>PLEASE,<br />START BY FILTERING PRODUCT FIRST</p>
      </div>
      <div class="no-filter-selected-session" *ngIf="noResultProduct">
        <p>NO RECORDS FOUND.</p>
      </div>
      <app-table-book
        [dataTable]="dataProductToBook"
        *ngIf="!noResultProduct && !allocate.value">
      </app-table-book>
      <app-table-book
        [dataTable]="dataProductAllocate"
        *ngIf="!noResultProduct && !toBook.value">
      </app-table-book>
    </ng-template>

    <button (click)="backButton()" class="button back-action-button" mat-button>
      BACK
    </button>
  </div>
</div>
