export const KAInfoResponse: any = {
  headers: [
    {
      title: 'KA',
      props: 'ka',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'PRODUCT',
      props: 'product',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'ORIGIN',
      props: 'origin',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'DEAL',
      props: 'deal',
      otherContent: 'deal',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'GAP DEAL DEMAND',
      props: 'gapDealDemand',
      otherContent: 'deal',
      alwaysVisible: false,
    },
    {
      title: 'GAP DEAL PLAN',
      props: 'gapDealPlan',
      otherContent: 'deal',
      alwaysVisible: false,
    },
    {
      title: 'GAP DEAL REQUEST',
      props: 'gapDealRequest',
      otherContent: 'deal',
      alwaysVisible: false,
    },
    {
      title: 'GAP DEAL ALLOCATION',
      props: 'gapDealAllocation',
      otherContent: 'deal',
      alwaysVisible: false,
    },
    {
      title: 'DEMAND',
      props: 'demand',
      otherContent: 'demand',
      alwaysVisible: true,
    },
    {
      title: 'GAP ALLOC',
      props: 'gapAlloc',
      otherContent: 'demand',
      alwaysVisible: false,
    },
    {
      title: 'GAP REQ',
      props: 'gapReq',
      otherContent: 'demand',
      alwaysVisible: false,
    },
    {
      title: 'PLAN OCT',
      props: 'planOct',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'PLAN NOV',
      props: 'planNov',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'PLAN DEC',
      props: 'planDec',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'TOTAL PLAN',
      props: 'totalPlan',
      otherContent: 'totalPlan',
      alwaysVisible: true,
    },
    {
      title: 'PLAN VS ALLOC OCT',
      props: 'planVsAllocOct',
      otherContent: 'totalPlan',
      alwaysVisible: false,
    },
    {
      title: 'PLAN VS ALLOC NOV',
      props: 'planVsAllocNov',
      otherContent: 'totalPlan',
      alwaysVisible: false,
    },
    {
      title: 'PLAN VS ALLOC DEC',
      props: 'planVsAllocDec',
      otherContent: 'totalPlan',
      alwaysVisible: false,
    },
    {
      title: 'TOTAL',
      props: 'totalPlanVsAlloc',
      otherContent: 'totalPlan',
      alwaysVisible: false,
    },
    {
      title: 'REQ OCT',
      props: 'requestOct',
      alwaysVisible: true,
    },
    {
      title: 'REQ NOV',
      props: 'requestNov',
      alwaysVisible: true,
    },
    {
      title: 'REQ DEC',
      props: 'requestDec',
      alwaysVisible: true,
    },
    {
      title: 'TOTAL REQ',
      props: 'totalRequest',
      otherContent: 'totalRequest',
      alwaysVisible: true,
    },
    {
      title: 'REQ VS ALLOC OCT',
      props: 'reqVsAllocOct',
      otherContent: 'totalRequest',
      alwaysVisible: false,
    },
    {
      title: 'REQ VS ALLOC NOV',
      props: 'reqVsAllocNov',
      otherContent: 'totalRequest',
      alwaysVisible: false,
    },
    {
      title: 'REQ VS ALLOC DEC',
      props: 'reqVsAllocDec',
      otherContent: 'totalRequest',
      alwaysVisible: false,
    },
    {
      title: 'TOTAL',
      props: 'totalReqVsAlloc',
      otherContent: 'totalRequest',
      alwaysVisible: false,
    },
    {
      title: 'ALLOC OCT',
      props: 'allocOct',
      alwaysVisible: true,
    },
    {
      title: 'ALLOC NOV',
      props: 'allocNov',
      alwaysVisible: true,
    },
    {
      title: 'ALLOC DEC',
      props: 'allocDec',
      alwaysVisible: true,
    },
    {
      title: 'TOTAL ALLOC',
      props: 'totalAlloc',
      alwaysVisible: true,
    },
  ],
  rows: [
    {
      kaProductList: [
        {
          ka: {
            value: 'Claro BR',
          },
          product: {
            value: 'XT2133 - Lisbon 6/28 Aqua Silver',
          },
          origin: {
            value: 'MAN',
          },
          deal: {
            value: 1000,
            enableInput: true,

            materialIds: {
              value: [1, 2],
            },
            rowSpan: true,
          },

          materialId: {
            value: 1,
          },
          demand: {
            value: 1000,
          },
          gapAlloc: {
            value: 10000,
            columnColor: true,
          },
          gapReq: {
            value: 10000,
            columnColor: true,
          },
          gapDealDemand: {
            value: 1000,
            columnColor: true,
            rowSpan: true,
          },
          gapDealPlan: {
            value: 1000,
            columnColor: true,
            rowSpan: true,
          },
          gapDealRequest: {
            value: 1000,
            columnColor: true,
            rowSpan: true,
          },
          gapDealAllocation: {
            value: 1000,
            columnColor: true,
            rowSpan: true,
          },
          planOct: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planOct',
          },
          planNov: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planNov',
          },
          planDec: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planDec',
          },
          totalPlan: {
            value: 1000,
          },
          planVsAllocOct: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocOct',
          },
          planVsAllocNov: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocNov',
          },
          planVsAllocDec: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocDec',
          },
          totalPlanVsAlloc: {
            value: 3000,
            columnColor: true,
            totalPlanVsAlloc: true,
          },
          requestOct: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestOct',
          },
          requestNov: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestNov',
          },
          requestDec: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestDec',
          },
          totalRequest: {
            value: 1000,
          },
          reqVsAllocOct: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocOct',
          },
          reqVsAllocNov: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocNov',
          },
          reqVsAllocDec: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocDec',
          },
          totalReqVsAlloc: {
            value: 3000,
            columnColor: true,
            totalReqVsAlloc: true,
          },
          allocOct: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocOct',
          },
          allocNov: {
            value: 2000,
            allocMonth: true,
            identifier: 'allocNov',
          },
          allocDec: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocDec',
          },
          totalAlloc: {
            value: 4000,
          },
        },
        {
          ka: {
            value: '',
          },
          product: {
            value: 'XT2133 - Lisbon 6/28 Aqua Silver',
          },
          origin: {
            value: 'JAG',
          },
          deal: {
            value: 1000,
            enableInput: true,
            invisible: true,

            materialIds: {
              value: [1, 2],
            },
            rowSpan: false,
          },
          materialId: {
            value: 2,
          },
          demand: {
            value: 1000,
          },
          gapAlloc: {
            value: 10000,
            columnColor: true,
          },
          gapReq: {
            value: 10000,
            columnColor: true,
          },
          gapDealDemand: {
            value: 1000,
            columnColor: true,
            rowSpan: false,
            invisible: true,
          },
          gapDealPlan: {
            value: 1000,
            columnColor: true,
            rowSpan: false,
            invisible: true,
          },
          gapDealRequest: {
            value: 1000,
            columnColor: true,
            rowSpan: false,
            invisible: true,
          },
          gapDealAllocation: {
            value: 1000,
            columnColor: true,
            rowSpan: false,
            invisible: true,
          },
          planOct: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planOct',
          },
          planNov: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planNov',
          },
          planDec: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planDec',
          },
          totalPlan: {
            value: 1000,
          },
          planVsAllocOct: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocOct',
          },
          planVsAllocNov: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocNov',
          },
          planVsAllocDec: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocDec',
          },
          totalPlanVsAlloc: {
            value: 3000,
            columnColor: true,
            totalPlanVsAlloc: true,
          },
          requestOct: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestOct',
          },
          requestNov: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestNov',
          },
          requestDec: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestDec',
          },
          totalRequest: {
            value: 1000,
          },
          reqVsAllocOct: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocOct',
          },
          reqVsAllocNov: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocNov',
          },
          reqVsAllocDec: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocDec',
          },
          totalReqVsAlloc: {
            value: 3000,
            columnColor: true,
            totalReqVsAlloc: true,
          },
          allocOct: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocOct',
          },
          allocNov: {
            value: 2000,
            allocMonth: true,
            identifier: 'allocNov',
          },
          allocDec: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocDec',
          },
          totalAlloc: {
            value: 4000,
          },
        },
      ],
      kaSubtotal: {
        deal: {
          value: 3000,
          total: 6000,
          summableValue: true,
        },
        demand: {
          value: 2000,
          total: 6000,
        },
        gapAlloc: {
          value: 30000,
          total: 6000,
          summableValue: true,
        },
        gapReq: {
          value: 30000,
          total: 6000,
          summableValue: true,
        },
        gapDealDemand: {
          value: 1500,
          total: 6000,
          summableValue: true,
        },
        gapDealPlan: {
          value: 1500,
          total: 6000,
          summableValue: true,
        },
        gapDealRequest: {
          value: 3000,
          total: 6000,
          summableValue: true,
        },
        gapDealAllocation: {
          value: 3000,
          total: 6000,
          summableValue: true,
        },
        planOct: {
          value: 3000,
          total: 6000,
          summableValue: true,
          planMonth: true,
          identifier: 'planOct',
        },
        planNov: {
          value: 3000,
          total: 6000,
          summableValue: true,
          planMonth: true,
          identifier: 'planNov',
        },
        planDec: {
          value: 3000,
          total: 6000,
          summableValue: true,
          planMonth: true,
          identifier: 'planDec',
        },
        totalPlan: {
          value: 4000,
          total: 6000,
          summableValue: true,
        },
        planVsAllocOct: {
          value: 3000,
          total: 6000,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: 'planVsAllocOct',
        },
        planVsAllocNov: {
          value: 3000,
          total: 6000,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: 'planVsAllocNov',
        },
        planVsAllocDec: {
          value: 3000,
          total: 6000,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: 'planVsAllocDec',
        },
        totalPlanVsAlloc: {
          value: 9000,
          total: 6000,
          summableValue: true,
          totalPlanVsAlloc: true,
        },
        requestOct: {
          value: 3000,
          total: 6000,
          enableInput: true,
          requestMonth: true,
          identifier: 'requestOct',
        },
        requestNov: {
          value: 3000,
          enableInput: true,
          requestMonth: true,
          identifier: 'requestNov',
        },
        requestDec: {
          value: 3000,
          total: 6000,
          enableInput: true,
          requestMonth: true,
          identifier: 'requestDec',
        },
        totalRequest: {
          value: 3000,
          total: 6000,
        },
        reqVsAllocOct: {
          value: 3000,
          total: 6000,
          summableValue: true,
          requestAllocMonth: true,
          identifier: 'reqVsAllocOct',
        },
        reqVsAllocNov: {
          value: 3000,
          total: 6000,
          summableValue: true,
          requestAllocMonth: true,
          identifier: 'reqVsAllocNov',
        },
        reqVsAllocDec: {
          value: 3000,
          total: 6000,
          summableValue: true,
          requestAllocMonth: true,
          identifier: 'reqVsAllocDec',
        },
        totalReqVsAlloc: {
          value: 9000,
          total: 6000,
          summableValue: true,
          totalReqVsAlloc: true,
        },
        allocOct: {
          value: 3000,
          total: 6000,
          summableValue: true,
          allocMonth: true,
          identifier: 'allocOct',
        },
        allocNov: {
          value: 3000,
          total: 6000,
          summableValue: true,
          allocMonth: true,
          identifier: 'allocNov',
        },
        allocDec: {
          value: 3000,
          total: 6000,
          summableValue: true,
          allocMonth: true,
          identifier: 'allocDec',
        },
        totalAlloc: {
          value: 6000,
          total: 6000,
          summableValue: true,
        },
      },
    },
    {
      kaProductList: [
        {
          ka: {
            value: 'Tim BRA',
          },
          product: {
            value: 'XT2208 - Dubai - Mojito',
          },
          origin: {
            value: 'MAN',
          },
          deal: {
            value: 1000,
            enableInput: true,
          },
          demand: {
            value: 1000,
          },
          gapAlloc: {
            value: 10000,
            columnColor: true,
          },
          gapReq: {
            value: 10000,
            columnColor: true,
          },
          gapDealDemand: {
            value: 1000,
            columnColor: true,
          },
          gapDealPlan: {
            value: 1000,
            columnColor: true,
          },
          gapDealRequest: {
            value: 1000,
            columnColor: true,
          },
          gapDealAllocation: {
            value: 1000,
            columnColor: true,
          },
          planOct: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planOct',
          },
          planNov: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planNov',
          },
          planDec: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planDec',
          },
          totalPlan: {
            value: 1000,
          },
          planVsAllocOct: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocOct',
          },
          planVsAllocNov: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocNov',
          },
          planVsAllocDec: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocDec',
          },
          totalPlanVsAlloc: {
            value: 3000,
            columnColor: true,
            totalPlanVsAlloc: true,
          },
          requestOct: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestOct',
          },
          requestNov: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestNov',
          },
          requestDec: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestDec',
          },
          totalRequest: {
            value: 3000,
          },
          reqVsAllocOct: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocOct',
          },
          reqVsAllocNov: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocNov',
          },
          reqVsAllocDec: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocDec',
          },
          totalReqVsAlloc: {
            value: 3000,
            columnColor: true,
            totalReqVsAlloc: true,
          },
          allocOct: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocOct',
          },
          allocNov: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocNov',
          },
          allocDec: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocDec',
          },
          totalAlloc: {
            value: 3000,
          },
        },
        {
          ka: {
            value: '',
          },
          product: {
            value: 'XT2235 - Devon - Dove Gray',
          },
          origin: {
            value: 'MAN',
          },
          deal: {
            value: 1000,
            enableInput: true,
          },
          demand: {
            value: 1000,
          },
          gapAlloc: {
            value: 10000,
            columnColor: true,
          },
          gapReq: {
            value: 10000,
            columnColor: true,
          },
          gapDealDemand: {
            value: 1000,
            columnColor: true,
          },
          gapDealPlan: {
            value: 1000,
            columnColor: true,
          },
          gapDealRequest: {
            value: 1000,
            columnColor: true,
          },
          gapDealAllocation: {
            value: 1000,
            columnColor: true,
          },
          planOct: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planOct',
          },
          planNov: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planNov',
          },
          planDec: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planDec',
          },
          totalPlan: {
            value: 1000,
          },
          planVsAllocOct: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocOct',
          },
          planVsAllocNov: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocNov',
          },
          planVsAllocDec: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocDec',
          },
          totalPlanVsAlloc: {
            value: 3000,
            columnColor: true,
            totalPlanVsAlloc: true,
          },
          requestOct: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestOct',
          },
          requestNov: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestNov',
          },
          requestDec: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestDec',
          },
          totalRequest: {
            value: 3000,
          },
          reqVsAllocOct: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocOct',
          },
          reqVsAllocNov: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocNov',
          },
          reqVsAllocDec: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocDec',
          },
          totalReqVsAlloc: {
            value: 3000,
            columnColor: true,
            totalReqVsAlloc: true,
          },
          allocOct: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocOct',
          },
          allocNov: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocNov',
          },
          allocDec: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocDec',
          },
          totalAlloc: {
            value: 3000,
          },
        },
        {
          ka: {
            value: '',
          },
          product: {
            value: 'XT2239 - Bora G - Niagara',
          },
          origin: {
            value: 'MAN',
          },
          deal: {
            value: 1000,
            enableInput: true,
          },
          demand: {
            value: -500,
          },
          gapAlloc: {
            value: 10000,
            columnColor: true,
          },
          gapReq: {
            value: 10000,
            columnColor: true,
          },
          gapDealDemand: {
            value: 1000,
            columnColor: true,
          },
          gapDealPlan: {
            value: 1000,
            columnColor: true,
          },
          gapDealRequest: {
            value: 1000,
            columnColor: true,
          },
          gapDealAllocation: {
            value: 1000,
            columnColor: true,
          },
          planOct: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planOct',
          },
          planNov: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planNov',
          },
          planDec: {
            value: 1000,
            enableInput: true,
            planMonth: true,
            identifier: 'planDec',
          },
          totalPlan: {
            value: 2000,
          },
          planVsAllocOct: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocOct',
          },
          planVsAllocNov: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocNov',
          },
          planVsAllocDec: {
            value: 1000,
            columnColor: true,
            planVsAllocMonth: true,
            identifier: 'planVsAllocDec',
          },
          totalPlanVsAlloc: {
            value: 3000,
            columnColor: true,
            totalPlanVsAlloc: true,
          },
          requestOct: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestOct',
          },
          requestNov: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestNov',
          },
          requestDec: {
            value: 1000,
            enableInput: true,
            requestMonth: true,
            identifier: 'requestDec',
          },
          totalRequest: {
            value: 3000,
          },
          reqVsAllocOct: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocOct',
          },
          reqVsAllocNov: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocNov',
          },
          reqVsAllocDec: {
            value: 1000,
            columnColor: true,
            requestAllocMonth: true,
            identifier: 'reqVsAllocDec',
          },
          totalReqVsAlloc: {
            value: 3000,
            columnColor: true,
            totalReqVsAlloc: true,
          },
          allocOct: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocOct',
          },
          allocNov: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocNov',
          },
          allocDec: {
            value: 1000,
            allocMonth: true,
            identifier: 'allocDec',
          },
          totalAlloc: {
            value: 3000,
          },
        },
      ],

      kaSubtotal: {
        deal: {
          value: 3000,
          summableValue: true,
        },
        demand: {
          value: 1500,
          summableValue: true,
        },
        gapAlloc: {
          value: 30000,
          summableValue: true,
        },
        gapReq: {
          value: 30000,
          summableValue: true,
        },
        gapDealDemand: {
          value: 3000,
          summableValue: true,
        },
        gapDealPlan: {
          value: 3000,
          summableValue: true,
        },
        gapDealRequest: {
          value: 3000,
          summableValue: true,
        },
        gapDealAllocation: {
          value: 3000,
          summableValue: true,
        },
        planOct: {
          value: 3000,
          summableValue: true,
        },
        planNov: {
          value: 3000,
          summableValue: true,
        },
        planDec: {
          value: 3000,
          summableValue: true,
        },
        totalPlan: {
          value: 4000,
          summableValue: true,
        },
        planVsAllocOct: {
          value: 3000,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: 'planVsAllocOct',
        },
        planVsAllocNov: {
          value: 3000,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: 'planVsAllocNov',
        },
        planVsAllocDec: {
          value: 3000,
          summableValue: true,
          planVsAllocMonth: true,
          identifier: 'planVsAllocDec',
        },
        totalPlanVsAlloc: {
          value: 9000,
          summableValue: true,
          totalPlanVsAlloc: true,
        },
        requestOct: {
          value: 3000,
          summableValue: true,
        },
        requestNov: {
          value: 3000,
          summableValue: true,
        },
        requestDec: {
          value: 3000,
          summableValue: true,
        },
        totalRequest: {
          value: 3000,
        },
        reqVsAllocOct: {
          value: 3000,
          summableValue: true,
        },
        reqVsAllocNov: {
          value: 3000,
          summableValue: true,
        },
        reqVsAllocDec: {
          value: 3000,
          summableValue: true,
        },
        totalReqVsAlloc: {
          value: 9000,
          summableValue: true,
          totalReqVsAlloc: true,
        },
        allocOct: {
          value: 3000,
          summableValue: true,
        },
        allocNov: {
          value: 3000,
          summableValue: true,
        },
        allocDec: {
          value: 3000,
          summableValue: true,
        },
        totalAlloc: {
          value: 9000,
          summableValue: true,
        },
      },
    },
  ],
};
