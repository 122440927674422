<h1 mat-dialog-title class="mat-dialog-title title-modal">COMMENTS</h1>
<div class="test">
  <div mat-dialog-content>
    {{ data.message }}
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>CANCEL</button>
    <button mat-button class="button-save" *ngIf="data.message !== ''" (click)="openDialogEdit()">
      EDIT
    </button>
  </div>
</div>
