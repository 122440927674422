import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import {
  SetupManagement,
  QuarterEntity,
} from 'src/app/interfaces/setup-management.interface';
import { CurrentUser } from 'src/app/interfaces/user.interface';
import { EditAndDelete } from 'src/app/interfaces/edit-delete.interface';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { Tab } from 'src/app/interfaces/tab.interface';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-setup-management',
  templateUrl: './setup-management.component.html',
  styleUrls: ['./setup-management.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class SetupManagementComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<void>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<void>
    | any;
  @ViewChild('modalCreateHoldFlag', { static: true }) modalCreateHoldFlag:
    | TemplateRef<void>
    | any;

  @ViewChild('ToBook') ToBook!: TemplateRef<any>;
  @ViewChild('OnePlan') OnePlan!: TemplateRef<any>;
  @ViewChild('AwaitingShipping') AwaitingShipping!: TemplateRef<any>;
  @ViewChild('OrderDummy') OrderDummy!: TemplateRef<any>;
  @ViewChild('OpenPO') OpenPO!: TemplateRef<any>;

  result!: ResultInterface;
  resultNullOrnot: any;
  resultEnteredSetup!: ResultInterface;
  resultEnteredSetupKa!: ResultInterface;
  showImageNoResults: boolean;
  hideResults: boolean;
  hidePagination: boolean;

  toBookAllocate: any;
  awaitingShipping: any;
  searchInputs!: InputBase<string>[];
  searchInputsAwaitingShipping!: InputBase<string>[];
  setupForm: FormGroup;
  setupFormHoldFlag: FormGroup;

  enteredSetupTable: any;
  enteredSetupTableKa: any;
  showTable: boolean;
  isModeTableOrCardsHide: boolean;

  enteredSetupEdit: any;
  enteredSetupEditKa: any;
  hasPermissionEdit!: boolean;
  isEdit: boolean;

  setupCrudService: CrudService<SetupManagement>;
  filter: any;
  event: EditAndDelete;

  currentWeek: any;
  currentWeekOnePlan: any;
  currentUser: CurrentUser;
  currentYear: number;
  currentQuarter!: string;

  quarterEntities: QuarterEntity[];
  countriesEntity: Country[];
  setupEntities!: FormGroup;
  fiscalYearEntities: any;

  messageNoRecordsFound: string;
  titleToBookToAllocate: string;
  titleEnteredSetup: string;
  titleEnteredSetupKa: string = 'Entered Setup - KA';

  isEditTableMode: boolean;
  messages!: { [key: string]: string };
  selectedTabIndex: number;
  selectedTabIndexOnePlan: number;
  selectedTabIndexOpenPo: number;
  tabs: Tab[] = [];
  tabsOnePlan: Tab[] = [];
  storeTab: number[];
  storeTabOnePlan: number[];
  tabsIndex: { [key: string]: any };
  tabsIndexOnePlan: { [key: string]: any };

  resultOnePlan!: any;
  resultOnePlanCalendar!: any;
  monthsArray: string[];
  manCalendarArray: any[];
  jagCalendarArray: any[];

  dataYear: any;

  dataMonths: any;
  dataCurrentMonth: any;

  noRecordsFound: any;

  noRecordsFoundAs!: boolean;
  noRecordsFoundOd!: boolean;
  noRecordsFoundOp!: boolean;

  noRecordsFoundAsArray: any[] = [];
  noRecordsFoundOdArray: any[] = [];
  noRecordsFoundOpArray: any[] = [];
  editValue!: string;
  editValueOrderDummy!: string;
  editValueOpenPo!: string;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private translatorService: TranslatorService,
    private sharedInfoService: SharedinfoService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.setupCrudService = new CrudService<SetupManagement>(
      this.http,
      'setupManagement'
    );
    this.setupForm = this.formBuilder.group({
      countryId: ['', Validators.required],
      fiscalYear: ['', Validators.required],
      toBook: ['', Validators.required],
      toAllocate: ['', Validators.required],
      enteredSetupRetail: ['', Validators.required],
      enteredSetupKa: ['', Validators.required],
    });

    this.setupFormHoldFlag = this.formBuilder.group({
      awaitingShipping: new FormControl('', Validators.required),
    });

    this.currentQuarter = this.getQuarterFromDate(new Date());
    [this.currentUser] = this.authService.getUserPermissions();
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;

    this.filter = {
      ...this.filter,
      country_id: this.currentUser.countryId,
      fiscalYear: this.currentYear,
      quarter: `F${this.currentQuarter}`,
    };
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionEdit =
        this.permissionService.setButtonEditPermission('setup-management');
    });
    this.searchInputs = this.createSearchInputs();
    this.searchInputsAwaitingShipping =
      this.createSearchInputsAwaitingShipping();

    this.isModeTableOrCardsHide = true;
    this.hidePagination = true;
    this.hideResults = true;
    this.resultNullOrnot = null;
    this.isEdit = false;
    this.showImageNoResults = false;
    this.showTable = true;

    this.countriesEntity = [];
    this.enteredSetupTable = [];
    this.enteredSetupEdit = [];
    this.enteredSetupEditKa = [];
    this.toBookAllocate = [];
    this.awaitingShipping = [];

    this.messageNoRecordsFound = 'NO RECORDS FOUND.';
    this.titleToBookToAllocate = 'To Book + To Allocate Setup';
    this.titleEnteredSetup = 'Entered Setup - Retail';
    this.filter = { sort: 'week', pageName: 'setup-management' };
    this.quarterEntities = [
      { key: '1', value: 'FQ1' },
      { key: '2', value: 'FQ2' },
      { key: '3', value: 'FQ3' },
      { key: '4', value: 'FQ4' },
    ];
    this.event = {
      delete: 'delete',
      edit: 'edit',
    };

    this.isEditTableMode = false;
    this.tabsIndex = {
      'TO BOOK': 0,
      'ONE PLAN': 2,
    };
    this.tabsIndexOnePlan = {
      'AWAITING SHPPING': 0,
      'ORDER DUMMY': 1,
      'OPEN PO': 2,
    };
    this.selectedTabIndex = 0;
    this.selectedTabIndexOnePlan = 0;
    this.storeTab = [0];
    this.storeTabOnePlan = [0];

    this.monthsArray = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    this.manCalendarArray = [];
    this.jagCalendarArray = [];
    this.dataMonths = this.monthData();
    this.selectedTabIndexOpenPo = 2;
    this.noRecordsFound = [];
  }

  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Quarter',
      }),
    ];
  }

  createSearchInputsAwaitingShipping(): any[] {
    return [
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
      }),
    ];
  }

  monthData() {
    const months: string[] = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    months.push(monthNames[currentMonth].substring(0, 3));

    for (let i = 1; i <= 5; i++) {
      const nextMonthIndex = (currentMonth + i) % 12;
      months.push(monthNames[nextMonthIndex].substring(0, 3));
    }

    return months;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    this.dataYear = currentYear;
    this.dataCurrentMonth = this.monthsArray[currentMonth].substring(0, 3);

    const promises = [
      this.getCurrentWeek(),
      this.getSetupManagement(),
      this.getSetupOnePlan(currentYear, this.dataCurrentMonth),
      this.getDataFilter(),
    ];

    this.hideSpinnerAfterAll(promises);
  }

  ngAfterViewInit(): void {
    this.allTabs();
    this.allTabsOnePlan();
    this.cdr.detectChanges();
  }

  async hideSpinnerAfterAll(promises: Promise<any>[]): Promise<void> {
    await Promise.all(promises)
      .then(() => {
        this.spinner.hide();
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }

  onWoiDaysSelectionChange(event: any) {
    const newWoiDaysValue = event.value;
    this.setupFormHoldFlag.patchValue({
      awaitingShipping: newWoiDaysValue,
    });
  }

  allTabs() {
    this.tabs = [
      { label: 'To Book', tab: 'TO BOOK', template: this.ToBook },
      { label: 'One Plan', tab: 'ONE PLAN', template: this.OnePlan },
    ];
  }

  allTabsOnePlan() {
    this.tabsOnePlan = [
      {
        label: 'Awaiting Shipping',
        tab: 'AWAITING SHIPPING',
        template: this.resultOnePlan,
      },
      {
        label: 'Order Dummy',
        tab: 'ORDER DUMMY',
        template: this.resultOnePlan,
      },
      { label: 'Open PO', tab: 'OPEN PO', template: this.resultOnePlan },
    ];
  }

  onActiveTabIndexChange(index: number) {
    this.selectedTabIndex = index; // atualiza a variável com o índice selecionado
    this.checkTabClick(index, 'toBook');
  }

  onActiveTabIndexChangeOnePlan(index: number) {
    this.selectedTabIndexOnePlan = index; // atualiza a variável com o índice selecionado
    this.checkTabClick(index, 'onePlan');
  }

  checkTabClick(index: number, tab?: string) {
    let result = this.storeTab.find((element: any) => element === index);

    if (tab === 'onePlan') {
      this.selectedTabIndexOnePlan = index;
    }
  }

  getCurrentWeek(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setupCrudService
        .getEntity('setupManagement/currentSetup')
        .subscribe((response: any) => {
          this.currentWeek = [];
          this.currentWeek = response;
        });
      // edit retail
      this.setupCrudService
        .getEntity('setupManagement/enteredSetup/:entered=true')
        .subscribe((response: any) => {
          this.enteredSetupTable = [];
          this.enteredSetupTable = response;
        });
      this.setupCrudService
        .getEntity('setupManagement/enteredSetup')
        .subscribe((response: any) => {
          this.enteredSetupEdit = [];
          this.enteredSetupEdit = response;
        });
      // edit ka
      this.setupCrudService
        .getEntity('setupManagement/enteredkaSetup/:entered=true')
        .subscribe((response: any) => {
          this.enteredSetupTableKa = [];
          this.enteredSetupTableKa = response;
        });

      this.setupCrudService
        .getEntity('setupManagement/enteredkaSetup')
        .subscribe((response: any) => {
          this.enteredSetupEditKa = [];
          this.enteredSetupEditKa = response;
        });
      resolve();
    });
  }

  onTabOpenPoChange(tab: any) {
    this.selectedTabIndexOpenPo = 2;

    if (tab === true) {
      this.selectedTabIndexOpenPo = 2;
    }

    if (tab === false) {
      this.selectedTabIndexOpenPo = 3;
    }
  }

  async onParamsCalendar(params: any[]) {
    this.spinner.show();
    let toastShown = false;
    const results = [];

    for (const param of params) {
      try {
        await this.putOpenPoCalendar(param, param.id);
        if (!toastShown) {
          this.toastr.success('Calendar updated successfully!', 'Success');
          toastShown = true;
        }
        results.push(true);
        const promises = [
          this.getSetupOnePlan(this.dataYear, this.dataCurrentMonth),
        ];
        this.hideSpinnerAfterAll(promises);
      } catch (error) {
        this.toastr.error(
          'Start date cannot be greater than end date!',
          'Error'
        );
        this.spinner.hide();
        results.push(false);
        break;
      }
    }
    const allSuccessful = results.every(result => result === true);

    return allSuccessful;
  }

  async onCancel(params: any[]) {
    this.spinner.show();
    let toastShown = false;
    const results = [];

    for (const param of params) {
      try {
        await this.putOpenPoCalendar(param, param.id);
        if (!toastShown) {
          this.toastr.warning('Calendar update canceled!', 'Canceled update');
          toastShown = true;
        }
        results.push(true);
        const promises = [
          this.getSetupOnePlan(this.dataYear, this.dataCurrentMonth),
        ];
        this.hideSpinnerAfterAll(promises);
      } catch (error) {
        this.toastr.error(
          'Start date cannot be greater than end date!',
          'Error'
        );
        this.spinner.hide();
        results.push(false);
        break;
      }
    }

    const allSuccessful = results.every(result => result === true);

    return allSuccessful;
  }

  async putOpenPoCalendar(params: any, id: any) {
    return this.setupCrudService
      .putEntity(`openPOCalendar/${id}`, params)
      .toPromise();
  }

  onChangeMonth(month: any) {
    this.dataCurrentMonth = month;
    const promises = [
      this.getSetupOnePlan(this.dataYear, this.dataCurrentMonth),
    ];
    this.hideSpinnerAfterAll(promises);
  }

  getSetupManagement(filter?: any): Promise<void> {
    this.spinner.show();

    return new Promise((resolve, reject) => {
      this.getCurrentWeek();
      this.toBookAllocate = [];
      this.setupCrudService
        .getEntity('setupManagement/currentSetup')
        .subscribe((response: any) => {
          this.currentWeek = response;

          this.setupCrudService
            .getEntity('setupManagement', filter || this.filter)
            .subscribe((response: any) => {
              let countWeeks = 0;
              let currentWeek: string = this.currentWeek.currentWeek.replace(
                'week',
                ''
              );
              if (response !== null) {
                this.showTable = true;
                for (let i = 0; i < response.headers.length; i++) {
                  const nameWeek = response.headers[i].week.replace('week', '');
                  if (nameWeek >= currentWeek) {
                    this.toBookAllocate.push({
                      name: 'Week',
                      value: countWeeks++,
                    });
                  }
                }

                const data = JSON.stringify(response);
                this.setupEntities = JSON.parse(data);
                this.updateComponentsToBookToAllocate(this.setupEntities);
              } else {
                this.resultNullOrnot = null;
                this.updateComponentsToBookToAllocate(null);
                this.showTable = false;
              }

              this.setupCrudService
                .getEntity('setupManagement/enteredSetup')
                .subscribe((response: any) => {
                  this.enteredSetupEdit = [];
                  this.enteredSetupEdit = response;
                  this.updateComponentsEnteredSetup(this.enteredSetupEdit);
                });

              this.setupCrudService
                .getEntity('setupManagement/enteredkaSetup')
                .subscribe((response: any) => {
                  this.enteredSetupEditKa = [];
                  this.enteredSetupEditKa = response;
                  this.updateComponentsEnteredSetupKa(this.enteredSetupEditKa);
                });
            });
          resolve();
        });
    });
  }

  async getSetupOnePlan(year: number, month: string): Promise<void> {
    this.spinner.show();
    this.awaitingShipping = [];

    return new Promise((resolve, reject) => {
      this.awaitingShipping.push(
        { value: 'All' },
        { value: 'Yes' },
        { value: 'No' }
      );
      this.editValue = 'Yes';

      this.setupCrudService
        .getEntity('setupManagement/currentSetup')
        .subscribe((response: any) => {
          this.currentWeekOnePlan = response;

          this.setupCrudService
            .getEntity('holdFlagOnePlan')
            .subscribe((response: any) => {
              if (response.length > 0) {
                const responseMonth: any[] = [];

                response.forEach((data: any) => {
                  if (data.year == year) {
                    data.rows.forEach((row: any) => {
                      if (row.month.substring(0, 3) === month) {
                        responseMonth.push(row);

                        if (row.category_type === 'AS') {
                          this.noRecordsFoundAsArray.push(false);
                        }

                        if (row.category_type === 'OD') {
                          this.noRecordsFoundOdArray.push(false);
                        }

                        if (row.category_type === 'OP') {
                          this.noRecordsFoundOpArray.push(false);
                        }
                      }
                    });
                  }
                });

                this.updateComponentsOnePlan(responseMonth);
              } else {
                this.noRecordsFoundAsArray = [];
                this.noRecordsFoundOdArray = [];
                this.noRecordsFoundOpArray = [];
              }

              this.noRecordsFoundAs =
                this.noRecordsFoundAsArray.length > 0 ? false : true;
              this.noRecordsFoundOd =
                this.noRecordsFoundOdArray.length > 0 ? false : true;
              this.noRecordsFoundOp =
                this.noRecordsFoundOpArray.length > 0 ? false : true;

              this.noRecordsFound = [
                {
                  noRecordFoundAs: this.noRecordsFoundAs,
                  noRecordFoundOd: this.noRecordsFoundOd,
                  noRecordFoundOp: this.noRecordsFoundOp,
                },
              ];
              resolve();
            });

          this.setupCrudService
            .getEntity('openPOCalendar')
            .subscribe((response: any) => {
              this.updateComponentsOnePlanCalendar(response);
            });
        });
    });
  }

  async putHoldFlag(params: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.setupCrudService
        .postEntity(`populateFromCalendar`, params)
        .subscribe(
          () => {
            this.modalService.closeAll();
            resolve();
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getDataFilter(): Promise<void> {
    const countriesFilter: any[] = [];
    const fiscalYearFilter: any[] = [];
    const fiscalYearFilterAwaitingShipping: any[] = [];
    let quarterFilter: any[] = [];
    this.spinner.show();

    return new Promise((resolve, reject) => {
      this.setupCrudService
        .getEntity('country', {
          sort: 'country',
          pageName: PAGE_NAME_RESOURCE.setupManagement,
        })
        .subscribe((response: any) => {
          this.countriesEntity = response;
          const initialValue: { [key: string]: any } = {};
          response.forEach((item: Country) => {
            if (item.id === this.currentUser.countryId) {
              initialValue.value = item.country;
              initialValue.id = item.id;
            }
          });

          const selectCountry = new SelectSearchInput({
            key: 'countryId',
            hover: 'Country',
            type: 'text',
            hasAutocomplete: true,
            options: countriesFilter,
            value: initialValue.value,
            disabled: true,
            notClearable: true,
          });

          this.searchInputs[0] = selectCountry;
          this.searchInputs = [...this.searchInputs];

          this.setupCrudService
            .getEntity('holdFlagOnePlan')
            .subscribe((response: any) => {
              if (response.length > 0) {
                response.forEach((data: any) => {
                  fiscalYearFilterAwaitingShipping.push({
                    value: `${data.year}/${data.year + 1}`,
                  });
                });

                const selectFiscalYear = new SelectSearchInput({
                  key: 'fiscalYear',
                  hover: 'Fiscal Year',
                  options: fiscalYearFilterAwaitingShipping,
                  classCss: 'fiscalYearOnePlan',
                  value: `${this.currentYear}/${this.currentYear + 1}`,
                  notClearable: true,
                });

                this.searchInputsAwaitingShipping[0] = selectFiscalYear;
                this.searchInputsAwaitingShipping = [
                  ...this.searchInputsAwaitingShipping,
                ];
              } else {
                for (
                  let year = this.currentYear - 1, i = 0;
                  year <= this.currentYear + 1;
                  year++, i++
                ) {
                  fiscalYearFilterAwaitingShipping.push({
                    key: i,
                    value: `${year}/${year + 1}`,
                  });
                }

                const selectFiscalYear = new SelectSearchInput({
                  key: 'fiscalYear',
                  hover: 'FiscalYear',
                  options: fiscalYearFilterAwaitingShipping,
                  classCss: 'fiscalYearOnePlan',
                  value: `${this.currentYear}/${this.currentYear + 1}`,
                  notClearable: true,
                });

                this.searchInputsAwaitingShipping[0] = selectFiscalYear;
                this.searchInputsAwaitingShipping = [
                  ...this.searchInputsAwaitingShipping,
                ];
              }
            });
        });

      for (
        let year = this.currentYear - 6, i = 0;
        year <= this.currentYear + 6;
        year++, i++
      ) {
        fiscalYearFilter.push({
          key: i,
          value: `${year}/${year + 1}`,
        });
      }

      quarterFilter = this.quarterEntities;

      this.searchInputs[1].options = fiscalYearFilter;
      this.searchInputs[1].value = `${this.currentYear}/${
        this.currentYear + 1
      }`;
      this.searchInputs[1].notClearable = true;
      this.searchInputs[1].classCss = 'fiscal-year-setup';
      this.searchInputs[2].options = quarterFilter;
      this.searchInputs[2].notClearable = true;
      this.searchInputs[2].value = `F${this.currentQuarter}`;
      this.searchInputs[2].classCss = 'current-quarter-setup';
      resolve();
    });
  }

  updateComponentsToBookToAllocate(responSetup: any) {
    if (responSetup == null) {
      this.clearTable();
    } else {
      this.prepareTableData(responSetup);
      this.showTable = true;
      this.showImageNoResults = false;
    }
  }

  updateComponentsOnePlan(OnePlan: any) {
    if (OnePlan == null) {
      this.clearTable();
    } else {
      this.prepareTableDataOnePlan(OnePlan, false);
      this.showTable = true;
      this.showImageNoResults = false;
    }
  }

  updateComponentsOnePlanCalendar(OnePlan: any) {
    if (OnePlan == null) {
      this.clearTable();
    } else {
      this.prepareTableDataOnePlanCalendar(OnePlan, true);
      this.showTable = true;
      this.showImageNoResults = false;
    }
  }

  clearTable() {
    this.resultNullOrnot = null;
    this.showTable = false;
  }

  prepareTableData(responSetup: any) {
    const headers = this.prepareTableHeaders(responSetup.headers);
    const rows = this.dataTables(responSetup.rows);

    this.resultNullOrnot = {
      table: {
        columns: headers,
        rows: new MatTableDataSource<any>(rows),
      },
      pageIndex: '',
      pageSize: '',
      pagesTotal: '',
    };
  }

  orderHeaderColumns(array: any[], label: string) {
    const objSelected = array.find(obj => obj.label === label);
    const filterArray = array.filter(obj => obj.label !== label);

    filterArray.sort((a, b) => {
      if (a.prop < b.prop) {
        return -1;
      }
      if (a.prop > b.prop) {
        return 1;
      }
      return 0;
    });

    return [objSelected, ...filterArray];
  }

  prepareTableDataOnePlan(OnePlan: any, calendar: boolean) {
    let onePlanNoCalendar = OnePlan.filter((item: any) => !item.origin);

    let headersAwaitingShipping =
      this.prepareTableHeadersOnePlanAwaitingShipping(onePlanNoCalendar);
    let rowsAwaitingShipping: any =
      this.dataTablesOnePlanAwaitingShipping(onePlanNoCalendar);

    headersAwaitingShipping = headersAwaitingShipping.filter(
      item => item !== undefined
    );
    headersAwaitingShipping = this.orderHeaderColumns(
      headersAwaitingShipping,
      'hold flag'
    );

    let headersOrderDummy =
      this.prepareTableHeadersOnePlanOrderDummy(onePlanNoCalendar);
    let rowsOrderDummy = this.dataTablesOnePlanOrderDummy(onePlanNoCalendar);

    headersOrderDummy = headersOrderDummy.filter(item => item !== undefined);
    headersOrderDummy = this.orderHeaderColumns(headersOrderDummy, 'status');

    let headersOpenPoHoldFlag =
      this.prepareTableHeadersOnePlanOpenPo(onePlanNoCalendar);
    let rowsOpenPoHoldFlag = this.dataTablesOnePlanOpenPo(onePlanNoCalendar);

    headersOpenPoHoldFlag = headersOpenPoHoldFlag.filter(
      item => item !== undefined
    );
    headersOpenPoHoldFlag = this.orderHeaderColumns(
      headersOpenPoHoldFlag,
      'status'
    );

    this.resultOnePlan = [
      {
        table: {
          columns: headersAwaitingShipping,
          rows: new MatTableDataSource<any>(rowsAwaitingShipping),
        },
        pageIndex: '',
        pageSize: '',
        pagesTotal: '',
      },
      {
        table: {
          columns: headersOrderDummy,
          rows: new MatTableDataSource<any>(rowsOrderDummy),
        },
        pageIndex: '',
        pageSize: '',
        pagesTotal: '',
      },
      {
        table: {
          columns: headersOpenPoHoldFlag,
          rows: new MatTableDataSource<any>(rowsOpenPoHoldFlag),
        },
        pageIndex: '',
        pageSize: '',
        pagesTotal: '',
      },
    ];
  }

  prepareTableDataOnePlanCalendar(OnePlan: any, calendar: boolean) {
    let onePlanCalendar = OnePlan.filter((item: any) => item.origin);
    let rowsOpenPoCalendar: any[] = [];
    this.manCalendarArray = [];
    this.jagCalendarArray = [];

    this.dataTablesOnePlanCalendar(onePlanCalendar);

    rowsOpenPoCalendar.push(this.manCalendarArray[0], this.jagCalendarArray[0]);

    this.resultOnePlanCalendar = [...rowsOpenPoCalendar];
  }

  prepareTableHeaders(headers: any[]) {
    const headersWithWeekColumn = [{ week: 'options', period: '' }, ...headers];
    const totalHeaders = headersWithWeekColumn.length;
    const headerWidth = 100 / totalHeaders;

    return headersWithWeekColumn.map((headerLabel: any) => {
      const width = `${headerWidth}%`;
      const isCurrentWeek = headerLabel.week === this.currentWeek.currentWeek;

      return {
        label: headerLabel.week,
        prop: headerLabel.period,
        sticky: false,
        style: {
          width: isCurrentWeek
            ? `calc(${width} + 1%)`
            : headerLabel.week === 'options'
            ? '15%'
            : width,
        },
        toBookToAllocation: isCurrentWeek,
        tooltipInfo: {
          title: headerLabel.week.replace('week', 'WEEK '),
          subtitle: this.handleDataWeekPeriod(headerLabel.period),
        },
      };
    });
  }

  prepareTableHeadersOnePlanAwaitingShipping(headers: any[]) {
    const headersWithWeekColumn = [
      { wk: 'holdFlag', period: '', category_type: 'AS' },
      ...headers,
    ];
    const totalHeaders = headersWithWeekColumn.length;
    const headerWidth = 100 / totalHeaders;

    return headersWithWeekColumn.map((headerLabel: any): any => {
      if (!headerLabel.origin) {
        if (headerLabel.category_type === 'AS') {
          const width = `${headerWidth}%`;
          const isCurrentWeek = headerLabel.week_status === 'current';

          return {
            label: headerLabel.wk === 'holdFlag' ? 'hold flag' : headerLabel.wk,
            prop: headerLabel.wk,
            sticky: false,
            style: {
              width: isCurrentWeek
                ? `calc(${width} + 1%)`
                : headerLabel.wk === 'holdFlag'
                ? '1%'
                : width,
            },
            currentWeek: isCurrentWeek === true ? 'isCurrent' : '',
            setup: true,
          };
        }
      }
    });
  }

  prepareTableHeadersOnePlanOrderDummy(headers: any[]) {
    const headersWithWeekColumn = [
      { wk: 'status', period: '', category_type: 'OD' },
      ...headers,
    ];
    const totalHeaders = headersWithWeekColumn.length;
    const headerWidth = 100 / totalHeaders;

    return headersWithWeekColumn.map((headerLabel: any): any => {
      if (!headerLabel.origin) {
        if (headerLabel.category_type === 'OD') {
          const width = `${headerWidth}%`;
          const isCurrentWeek = headerLabel.week_status === 'current';

          return {
            label: headerLabel.wk,
            prop: headerLabel.wk,
            sticky: false,
            style: {
              width: isCurrentWeek
                ? `calc(${width} + 1%)`
                : headerLabel.wk === 'status'
                ? '1%'
                : width,
            },
            currentWeek: isCurrentWeek === true ? 'isCurrent' : '',
            setup: true,
          };
        }
      }
    });
  }

  prepareTableHeadersOnePlanOpenPo(headers: any[]) {
    const headersWithWeekColumn = [
      { wk: 'status', period: '', category_type: 'OP' },
      ...headers,
    ];
    const totalHeaders = headersWithWeekColumn.length;
    const headerWidth = 100 / totalHeaders;

    return headersWithWeekColumn.map((headerLabel: any): any => {
      if (!headerLabel.origin) {
        if (headerLabel.category_type === 'OP') {
          const width = `${headerWidth}%`;
          const isCurrentWeek = headerLabel.week_status === 'current';

          return {
            label: headerLabel.wk,
            prop: headerLabel.wk,
            sticky: false,
            style: {
              width: isCurrentWeek
                ? `calc(${width} + 1%)`
                : headerLabel.wk === 'status'
                ? '1%'
                : width,
            },
            currentWeek: isCurrentWeek === true ? 'isCurrent' : '',
            setup: true,
          };
        }
      }
    });
  }

  updateComponentsEnteredSetup(responSetup: any) {
    if (responSetup === null) {
      //this.showTable = false;
      //this.showImageNoResults = true;
    } else {
      this.resultEnteredSetup = {
        table: {
          columns: responSetup.headers.map((headerLabel: any) => {
            let count = responSetup.headers.indexOf(headerLabel);
            const headers: any = 100 / responSetup.headers.length;
            const width = parseInt(headers, 10);
            return {
              label: headerLabel.key,
              prop: headerLabel.key,
              entered: `${headerLabel.key}/${headerLabel.year}`.toUpperCase(),
              sticky: false,
              style: { width: `${width}%` },
              position: count,
              currentMonth: headerLabel.currentMonth,
            };
          }),
          rows: new MatTableDataSource<any>(responSetup.rows),
        },
        pageIndex: '',
        pageSize: '',
        pagesTotal: '',
      };
      // this.showTable = true;
      this.showImageNoResults = false;
    }
  }

  updateComponentsEnteredSetupKa(responSetup: any) {
    if (responSetup === null) {
      //this.showTable = false;
      //this.showImageNoResults = true;
    } else {
      this.resultEnteredSetupKa = {
        table: {
          columns: responSetup.headers.map((headerLabel: any) => {
            let count = responSetup.headers.indexOf(headerLabel);
            const headers: any = 100 / responSetup.headers.length;
            const width = parseInt(headers, 10);
            return {
              label: headerLabel.key,
              prop: headerLabel.key,
              entered: `${headerLabel.key}/${headerLabel.year}`.toUpperCase(),
              sticky: false,
              style: { width: `${width}%` },
              position: count,
              currentMonth: headerLabel.currentMonth,
            };
          }),
          rows: new MatTableDataSource<any>(responSetup.rows),
        },
        pageIndex: '',
        pageSize: '',
        pagesTotal: '',
      };
      // this.showTable = true;
      this.showImageNoResults = false;
    }
  }

  dataTables(data: any) {
    const setup = [];
    setup.push(data[0].weeksForNow[0]);
    setup.push(data[0].toBook[0]);
    setup.push(data[0].toAllocate[0]);
    setup.push(data[0].mls[0]);
    setup.push(data[0].sellIn[0]);
    setup.push(data[0].execution[0]);
    setup.forEach((item: any, index) => {
      let countIndex: number = index;

      switch (countIndex) {
        case 0:
          item.options = 'Weeks From Now';
          break;
        case 1:
          item.options = 'To Book';
          break;
        case 2:
          item.options = 'To Allocate';
          break;
        case 3:
          item.options = 'MLS';
          break;
        case 4:
          item.options = 'Sell In';
          break;
        case 5:
          item.options = 'Execution';
          break;
      }
    });
    return setup;
  }

  dataTablesOnePlanAwaitingShipping(data: any) {
    let rowsAwaitingShipping: any[] = [];
    let dataNo: any[] = [];
    let dataYes: any[] = [];
    let dataAll: any[] = [];
    let awaitingShippingObjNo: any = [];
    let awaitingShippingObjYes: any = [];
    let awaitingShippingObjAll: any = [];

    data.forEach((row: any) => {
      if (!row.origin) {
        if (row.category_type === 'AS') {
          if (row.flag === 'NO') {
            dataNo.push(row);
            this.editValue = 'No';
          }
          if (row.flag === 'YES') {
            dataYes.push(row);
            this.editValue = 'Yes';
          }
          if (row.flag === 'ALL') {
            dataAll.push(row);
            this.editValue = 'All';
          }
        }
      }
    });

    if (dataNo.length > 0) {
      dataNo.forEach(obj => {
        awaitingShippingObjNo.push({
          'hold flag': 'No',
          [obj.wk]: obj.week_status,
        });
      });
    } else {
      awaitingShippingObjNo.push({ 'hold flag': 'No' });
    }

    if (dataYes.length > 0) {
      dataYes.forEach((obj: any) => {
        awaitingShippingObjYes.push({
          'hold flag': 'Yes',
          [obj.wk]: obj.week_status,
        });
      });
    } else {
      awaitingShippingObjYes.push({ 'hold flag': 'Yes' });
    }

    if (dataAll.length > 0) {
      dataAll.forEach(obj => {
        awaitingShippingObjAll.push({
          'hold flag': 'All',
          [obj.wk]: obj.week_status,
        });
      });
    } else {
      awaitingShippingObjAll.push({ 'hold flag': 'All' });
    }

    let rowsNo: any = awaitingShippingObjNo.reduce(
      (acumulador: any, obj: any) => {
        return { ...acumulador, ...obj };
      },
      {}
    );

    let rowsYes: any = awaitingShippingObjYes.reduce(
      (acumulador: any, obj: any) => {
        return { ...acumulador, ...obj };
      },
      {}
    );

    let rowsAll: any = awaitingShippingObjAll.reduce(
      (acumulador: any, obj: any) => {
        return { ...acumulador, ...obj };
      },
      {}
    );

    rowsAwaitingShipping.push(rowsAll, rowsYes, rowsNo);
    this.setupFormHoldFlag.patchValue({
      awaitingShipping: this.editValue,
    });

    return rowsAwaitingShipping;
  }

  dataTablesOnePlanOrderDummy(data: any) {
    let rowsOrderDummy: any[] = [];
    let dataNo: any[] = [];
    let dataYes: any[] = [];
    let dataAll: any[] = [];
    let orderDummyObjNo: any = [];
    let orderDummyYes: any = [];
    let orderDummyObjAll: any = [];

    data.forEach((row: any) => {
      if (!row.origin) {
        if (row.category_type === 'OD') {
          if (row.flag === 'NO') {
            dataNo.push(row);
            this.editValueOrderDummy = 'No';
          }
          if (row.flag === 'YES') {
            dataYes.push(row);
            this.editValueOrderDummy = 'Yes';
          }
          if (row.flag === 'ALL') {
            dataAll.push(row);
            this.editValueOrderDummy = 'All';
          }
        }
      }
    });

    if (dataNo.length > 0) {
      dataNo.forEach(obj => {
        orderDummyObjNo.push({ status: 'No', [obj.wk]: obj.week_status });
      });
    } else {
      orderDummyObjNo.push({ status: 'No' });
    }

    if (dataYes.length > 0) {
      dataYes.forEach(obj => {
        orderDummyYes.push({ status: 'Yes', [obj.wk]: obj.week_status });
      });
    } else {
      orderDummyYes.push({ status: 'Yes' });
    }

    if (dataAll.length > 0) {
      dataAll.forEach(obj => {
        orderDummyObjAll.push({ status: 'All', [obj.wk]: obj.week_status });
      });
    } else {
      orderDummyObjAll.push({ status: 'All' });
    }

    let rowsNo: any = orderDummyObjNo.reduce((acumulador: any, obj: any) => {
      return { ...acumulador, ...obj };
    }, {});

    let rowsYes: any = orderDummyYes.reduce((acumulador: any, obj: any) => {
      return { ...acumulador, ...obj };
    }, {});

    let rowsAll: any = orderDummyObjAll.reduce((acumulador: any, obj: any) => {
      return { ...acumulador, ...obj };
    }, {});

    rowsOrderDummy.push(rowsAll, rowsYes, rowsNo);

    return rowsOrderDummy;
  }

  dataTablesOnePlanOpenPo(data: any) {
    let rowsOpenPoHoldFlag: any[] = [];
    let dataNo: any[] = [];
    let dataYes: any[] = [];
    let dataAll: any[] = [];
    let openPoHoldFlagObjNo: any = [];
    let openPoHoldFlagYes: any = [];
    let openPoHoldFlagObjAll: any = [];

    data.forEach((row: any) => {
      if (row.category_type === 'OP') {
        if (!row.origin) {
          if (row.flag === 'NO') {
            dataNo.push(row);
            this.editValueOpenPo = 'No';
          }
          if (row.flag === 'YES') {
            dataYes.push(row);
            this.editValueOpenPo = 'Yes';
          }
          if (row.flag === 'ALL') {
            dataAll.push(row);
            this.editValueOpenPo = 'All';
          }
        }
      }
    });

    if (dataNo.length > 0) {
      dataNo.forEach(obj => {
        openPoHoldFlagObjNo.push({ status: 'No', [obj.wk]: obj.week_status });
      });
    } else {
      openPoHoldFlagObjNo.push({ status: 'No' });
    }

    if (dataYes.length > 0) {
      dataYes.forEach(obj => {
        openPoHoldFlagYes.push({ status: 'Yes', [obj.wk]: obj.week_status });
      });
    } else {
      openPoHoldFlagYes.push({ status: 'Yes' });
    }

    if (dataAll.length > 0) {
      dataAll.forEach(obj => {
        openPoHoldFlagObjAll.push({ status: 'All', [obj.wk]: obj.week_status });
      });
    } else {
      openPoHoldFlagObjAll.push({ status: 'All' });
    }

    let rowsNo: any = openPoHoldFlagObjNo.reduce(
      (acumulador: any, obj: any) => {
        return { ...acumulador, ...obj };
      },
      {}
    );

    let rowsYes: any = openPoHoldFlagYes.reduce((acumulador: any, obj: any) => {
      return { ...acumulador, ...obj };
    }, {});

    let rowsAll: any = openPoHoldFlagObjAll.reduce(
      (acumulador: any, obj: any) => {
        return { ...acumulador, ...obj };
      },
      {}
    );

    rowsOpenPoHoldFlag.push(rowsAll, rowsYes, rowsNo);

    return rowsOpenPoHoldFlag;
  }

  dataTablesOnePlanCalendar(data: any) {
    data.forEach((row: any) => {
      if (row.origin == 'MAN') {
        this.manCalendarArray.push({
          startDate: row.start_date,
          endDate: row.end_date,
          finalDay: row.final_day,
          initialDay: row.initial_day,
          qtd: row.qtd,
          id: row.id,
        });
      } else if (row.origin == 'JAG') {
        this.jagCalendarArray.push({
          startDate: row.start_date,
          endDate: row.end_date,
          finalDay: row.final_day,
          initialDay: row.initial_day,
          qtd: row.qtd,
          id: row.id,
        });
      }
    });
  }

  countToBookToAllocate(data: any) {
    let countTA: number = 0;
    let countTB: number = 0;
    let currentWeek: number = Number(data.currentWeek.replace('week', ''));
    const toAllocate = data.toAllocate.split(',');
    const toBook = data.toBook.split(',');

    toAllocate.forEach((element: string) => {
      let week: string = element.replace(/\s/g, '');
      let weekNumber: number = Number(week.replace('Wk', ''));
      if (weekNumber > currentWeek) {
        countTA = countTA + 1;
      }
    });

    toBook.forEach((element: string) => {
      let week: string = element.replace(/\s/g, '');
      let weekNumber: number = Number(week.replace('Wk', ''));
      if (weekNumber > currentWeek) {
        countTB = countTB + 1;
      }
    });

    return { countTA, countTB };
  }

  prepareToEdit() {
    this.spinner.show();
    this.isEdit = true;

    this.setupCrudService
      .getEntity('setupManagement', this.filter)
      .subscribe((response: any) => {
        const inputValues = this.countToBookToAllocate(this.currentWeek);
        this.setupForm.controls.countryId.setValue(response.countryId);
        this.setupForm.controls.fiscalYear.setValue(response.fiscalYear);
        this.setupForm.controls.toBook.setValue(inputValues.countTB);
        this.setupForm.controls.toAllocate.setValue(inputValues.countTA);
        this.setupForm.controls.enteredSetupRetail.setValue(
          this.enteredSetupTable.headers
        );
        this.setupForm.controls.enteredSetupKa.setValue(
          this.enteredSetupTableKa.headers
        );
        const modal = this.modalService.createModal(this.modalCreateEdit, {
          width: '25%',
          height: '40%',
        });
        modal.afterClosed().subscribe(() => {
          this.setupForm.reset();
        });
        this.spinner.hide();
      });
  }

  initialValueAwaitingShipping() {
    if (this.selectedTabIndexOnePlan === 0) {
      this.setupFormHoldFlag.patchValue({
        awaitingShipping: this.editValue,
      });
    } else if (this.selectedTabIndexOnePlan === 1) {
      this.setupFormHoldFlag.patchValue({
        awaitingShipping: this.editValueOrderDummy,
      });
    } else if (this.selectedTabIndexOnePlan === 2) {
      this.setupFormHoldFlag.patchValue({
        awaitingShipping: this.editValueOpenPo,
      });
    }
  }

  prepareToEditHoldFlag() {
    const modal = this.modalService.createModal(this.modalCreateHoldFlag, {
      width: '460px',
      height: '185px',
    });

    this.initialValueAwaitingShipping();

    modal.afterClosed().subscribe(() => {
      this.setupFormHoldFlag.reset();
      this.dialog.closeAll();
    });
  }

  compare(c1: { month: string }, c2: { month: string }) {
    return c1 && c2 && c1.month === c2.month;
  }

  onUpdate() {
    this.isEdit = true;
    if (this.setupForm.valid) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          this.setupCrudService
            .getEntity('setupManagement')
            .subscribe((response: any) => {
              const retultEnteredRetail = this.checkEnteredValues(
                this.setupForm.controls.enteredSetupRetail.value
              );
              const retultEnteredKa = this.checkEnteredValuesKa(
                this.setupForm.controls.enteredSetupKa.value
              );
              const setup: any = {
                countryId: response.countryId,
                toBook: this.setupForm.controls.toBook.value,
                toAllocate: this.setupForm.controls.toAllocate.value,
                enteredSetupsRetail: retultEnteredRetail,
                enteredSetupsKa: retultEnteredKa,
              };
              this.setupCrudService.createEntity(setup).subscribe(
                () => {
                  this.toastr.success(
                    `${this.translatorService.getMessage(
                      'CRUD_SUCCESS_UPDATED'
                    )}`,
                    `${this.translatorService.getTitle(
                      'CRUD_SUCCESS_UPDATED'
                    )} Setup`
                  );
                  this.dialog.closeAll();
                  this.loadData();
                },
                (err: any) => {
                  this.toastr.error(err.error.message, err.error.title);
                  this.spinner.hide();
                }
              );
            });
        }
      });
    } else {
      this.toastr.warning('Mandatory Field', 'To Book & To Allocate');
    }
  }

  async onUpdateHoldFlag() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    this.dataCurrentMonth = this.monthsArray[currentMonth].substring(0, 3);

    if (this.setupFormHoldFlag.valid) {
      const params: any[] = [];

      if (this.selectedTabIndexOnePlan === 0) {
        params.push({
          fiscal_year: this.dataYear,
          flag: this.setupFormHoldFlag.controls.awaitingShipping.value.toUpperCase(),
          category_type: 'AS',
        });
      }
      if (this.selectedTabIndexOnePlan === 1) {
        params.push({
          fiscal_year: this.dataYear,
          flag: this.setupFormHoldFlag.controls.awaitingShipping.value.toUpperCase(),
          category_type: 'OD',
        });
      }
      if (this.selectedTabIndexOnePlan === 2) {
        params.push({
          fiscal_year: this.dataYear,
          flag: this.setupFormHoldFlag.controls.awaitingShipping.value.toUpperCase(),
          category_type: 'OP',
        });
      }

      this.saveEditHoldFlagAwaiting(params);
    }
  }

  saveEditHoldFlagAwaiting(params: any) {
    if (params.length > 0) {
      this.spinner.show();

      this.putHoldFlag(params[0])
        .then(() => {
          const promises = [
            this.getSetupOnePlan(this.dataYear, this.dataCurrentMonth),
          ];
          return this.hideSpinnerAfterAll(promises);
        })
        .catch(error => {
          console.error('Erro:', error);
        });
    }
  }

  checkEnteredValues(entered: any) {
    const result: any = [];
    if (entered != null) {
      this.enteredSetupEdit.headers.forEach((item: any) => {
        let exist = entered.find((element: any) => element.key === item.key);
        if (exist) {
          item.entered = true;
        } else {
          item.entered = false;
        }
      });
      this.enteredSetupEdit.headers.forEach((item: any) => {
        result.push({
          id: item.id,
          yearMonth: item.yearMonth,
          fiscalYear: item.fiscalYear,
          entered: item.entered,
        });
      });
      return result;
    } else {
      this.enteredSetupEdit.headers.forEach((item: any) => {
        result.push({
          id: item.id,
          yearMonth: item.yearMonth,
          fiscalYear: item.fiscalYear,
          entered: item.entered,
        });
      });

      return result;
    }
  }

  checkEnteredValuesKa(entered: any) {
    const result: any = [];
    if (entered != null) {
      this.enteredSetupEditKa.headers.forEach((item: any) => {
        let exist = entered.find((element: any) => element.key === item.key);
        if (exist) {
          item.entered = true;
        } else {
          item.entered = false;
        }
      });
      this.enteredSetupEditKa.headers.forEach((item: any) => {
        result.push({
          id: item.id,
          yearMonth: item.yearMonth,
          fiscalYear: item.fiscalYear,
          entered: item.entered,
        });
      });
      return result;
    } else {
      this.enteredSetupEditKa.headers.forEach((item: any) => {
        result.push({
          id: item.id,
          yearMonth: item.yearMonth,
          fiscalYear: item.fiscalYear,
          entered: item.entered,
        });
      });

      return result;
    }
  }

  onSearchEvent(value: any, tab?: string) {
    if (tab === 'toBook') {
      if (value.countryId) {
        const currentCountry = this.countriesEntity.filter((item: Country) =>
          item.country
            .toLocaleUpperCase()
            .includes(value.countryId.toLocaleUpperCase())
        );
        value.countryId = currentCountry[0] ? currentCountry[0].id : 0;
      } else {
        value.countryId = '';
      }

      value.fiscalYear = value.fiscalYear ? value.fiscalYear.split('/')[0] : '';

      this.filter = {
        ...value,
        sort: 'week',
        pageName: PAGE_NAME_RESOURCE.setupManagement,
      };
      const promises = [this.getSetupManagement(this.filter)];
      this.hideSpinnerAfterAll(promises);
    }

    if (tab === 'onePlan') {
      this.dataYear = '';
      this.dataYear = value.fiscalYear ? value.fiscalYear.split('/')[0] : '';
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      this.dataCurrentMonth = this.monthsArray[currentMonth].substring(0, 3);

      const promises = [
        this.getSetupOnePlan(this.dataYear, this.dataCurrentMonth),
      ];
      this.hideSpinnerAfterAll(promises);
    }
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

  handleDataWeekPeriod(weekPeriod: string) {
    return weekPeriod
      .replace('\n', '')
      .replaceAll(' ', '')
      .replace('-', ' To ');
  }
}
