export const tableExpansiveMockup: any = {
  expansiveTable: {
    headers: [
      {
        title: 'PRODUCT',
        props: 'product',
        alwaysVisible: true,
      },
      {
        title: 'ORIGIN',
        props: 'origin',
        alwaysVisible: true,
      },
      // EXPANSÃO BOH TOTAL
      {
        title: 'BOH TOTAL',
        props: 'bohTotal',
        alwaysVisible: true,
      },
      {
        title: 'BUFFER',
        props: 'buffer',
        alwaysVisible: true,
      },
      {
        title: 'BOH FLEX',
        props: 'bohFlex',
        otherContentTotal: true, // para expandir a partir do TOTAL
        alwaysVisible: false,
      },
      {
        title: 'BOH MOTO',
        props: 'bohMoto',
        otherContentTotal: true,
        alwaysVisible: false,
      },
      {
        title: 'SAB',
        props: 'sab',
        otherContentTotal: true,
        alwaysVisible: false,
      },
      {
        title: 'BP',
        props: 'bp',
        otherContentTotal: true,
        alwaysVisible: false,
        bp: true,
      },

      // WEEKS:
      {
        title: 'WK 06',
        props: 'wk6',
        weekInTime: 'previous',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 07',
        props: 'wk7',
        weekInTime: 'current',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 08',
        props: 'wk8',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 09',
        props: 'wk9',
        alwaysVisible: true,
        isWeek: true,
      },
      {
        title: 'WK 10',
        props: 'wk10',
        alwaysVisible: true,
        isWeek: true,
      },
      // ------------------
      {
        title: 'TOT DEC',
        props: 'totDec',
        alwaysVisible: true,
        totalMonth: true,
      },
      {
        title: 'WOW DEC',
        props: 'wowDec',
        alwaysVisible: true,
      },
      //EXPANSÃO QX ACTUAL
      {
        title: 'Q2 ACTUAL',
        props: 'q2Actual',
        alwaysVisible: true,
        actual: true,
      },
      {
        title: 'Q2 DEM',
        props: 'Q2DEM',
        otherContentActual: true, // para expandir a partir do ACTUAL
        alwaysVisible: false,
      },
      {
        title: 'TOT ONE PLAN Q2',
        props: 'totOnePlanQ2',
        otherContentActual: true,
        alwaysVisible: false,
      },
      {
        title: 'TOT WOW Q2',
        props: 'totWowQ2',
        otherContentActual: true,
        alwaysVisible: false,
      },
      {
        title: 'TOT WOW Q2 DEM',
        props: 'totWowQ2Dem',
        otherContentActual: true,
        alwaysVisible: false,
        totWow: true,
      },
      //-------------------
    ],
    rows: [
      {
        product: {
          value: 'XT2167 - Cyprus 4/64 - Dark Cedar',
        },
        origin: {
          value: 'MAO',
        },
        // ROW BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true, // para identificar que a linha é SOMATORIA
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2167 - Cyprus 4/64 - Pink Clay',
        },
        origin: {
          value: 'MAO',
        },
        // ROW BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2167 - Corfu 5G - Neptne Green',
        },
        origin: {
          value: 'MAO',
        },
        // ROW BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2167 - Corfu 5G - Opal Iris',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2175 - Yukon - Sea Tea',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2175 - Yukon - Mirage',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
      {
        product: {
          value: 'XT2173 - Corfu Lite 128 - Meteorite Grey',
        },
        origin: {
          value: 'MAO',
        },
        // row BH TOTAL EXPANSIVO
        bohTotal: {
          value: 1000,
          summableValue: true,
        },
        bohFlex: {
          value: 700,
          summableValue: true,
        },
        bohMoto: {
          value: 700,
          summableValue: true,
        },
        sab: {
          value: 0,
          summableValue: true,
        },
        bp: {
          value: 1000,
          summableValue: true,
        },
        buffer: {
          value: 1200,
          summableValue: true,
        },
        // WEEKS
        wk6: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk7: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk8: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk9: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        wk10: {
          value: 200000,
          summableValue: true,
          enableInput: true,
        },
        // -------------
        totDec: {
          value: 1200,
          summableValue: true,
        },
        wowDec: {
          value: 1200,
          summableValue: true,
        },
        // EXPANSÃO Q2 ACTUAL
        q2Actual: {
          value: 1000000,
          summableValue: true,
        },
        Q2DEM: {
          value: 700,
          summableValue: true,
        },
        totOnePlanQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2: {
          value: 1000000,
          summableValue: true,
        },
        totWowQ2Dem: {
          value: 1000000,
          summableValue: true,
        },
      },
    ],
  },
};

export const tableExpansiveShipmentAndCheck: any = {
    "shipment": [
    {
      "january": {
        "headers": [
          {
            "title": "PRODUCT",
            "props": "product",
            "alwaysVisible": true
          },
          {
            "title": "ORIGIN",
            "props": "origin",
            "alwaysVisible": true
          },
          {
            "title": "BOH TOTAL",
            "props": "bohTotal",
            "alwaysVisible": true
          },
          {
            "title": "BUFFER",
            "props": "buffer",
            "alwaysVisible": true
          },
          {
            "title": "BOH FLEX",
            "props": "bohFlex",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BOH MOTO",
            "props": "bohMoto",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "SAB",
            "props": "sab",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BP",
            "props": "bp",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "WK 01",
            "props": "WK01",
            "weekInTime": "previous",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 02",
            "props": "WK02",
            "weekInTime": "previous",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 03",
            "props": "WK03",
            "weekInTime": "previous",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 04",
            "props": "WK04",
            "weekInTime": "current",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 05.1",
            "props": "WK05.1",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "TOT JAN",
            "props": "totJan",
            "alwaysVisible": true,
            "totalMonth": true
          },
          {
            "title": "WOW JAN",
            "props": "wowJan",
            "alwaysVisible": true
          },
          {
            "title": "Q4 ACTUAL",
            "props": "q4Actual",
            "alwaysVisible": true,
            "actual": true
          },
          {
            "title": "Q4 DEM",
            "props": "Q4DEM",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT ONE PLAN Q4",
            "props": "totOnePlanQ4",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q4",
            "props": "totWowQ4",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q4 DEM",
            "props": "totWowQ4Dem",
            "otherContentActual": true,
            "alwaysVisible": false,
            "totWow": true
          }
        ],
        "rows": [
          {
            "product": {
              "value": "XT2155 - ARUBA 2/32 - GRAPHITE",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "01",
              "executedAtWeek": "WK04",
              "salesModel": "PARG0033BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 262,
              "summableValue": true
            },
            "bohFlex": {
              "value": 8,
              "summableValue": true
            },
            "bohMoto": {
              "value": 254,
              "summableValue": true
            },
            "sab": {
              "value": 60,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 254,
              "summableValue": true
            },
            "WK01": {
              "value": 64,
              "summableValue": true,
              "enableInput": true
            },
            "WK02": {
              "value": 65,
              "summableValue": true,
              "enableInput": true
            },
            "WK03": {
              "value": 68,
              "summableValue": true,
              "enableInput": true
            },
            "WK04": {
              "value": 70,
              "summableValue": true,
              "enableInput": true
            },
            "WK05.1": {
              "value": -5,
              "summableValue": true,
              "enableInput": true
            },
            "totJan": {
              "value": 262,
              "summableValue": true
            },
            "wowJan": {
              "value": -1038,
              "summableValue": true
            },
            "q4Actual": {
              "value": 1267,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 63,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 262,
              "summableValue": true
            },
            "totWowQ4": {
              "value": -938,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 63,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "01",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0008BR"
            },
            "origin": {
              "value": "JAG"
            },
            "bohTotal": {
              "value": 85,
              "summableValue": true
            },
            "bohFlex": {
              "value": 59,
              "summableValue": true
            },
            "bohMoto": {
              "value": 26,
              "summableValue": true
            },
            "sab": {
              "value": 108,
              "summableValue": true
            },
            "bp": {
              "value": 7943,
              "summableValue": true
            },
            "buffer": {
              "value": 26,
              "summableValue": true
            },
            "WK01": {
              "value": 60,
              "summableValue": true,
              "enableInput": true
            },
            "WK02": {
              "value": 39,
              "summableValue": true,
              "enableInput": true
            },
            "WK03": {
              "value": 246,
              "summableValue": true,
              "enableInput": true
            },
            "WK04": {
              "value": 760,
              "summableValue": true,
              "enableInput": true
            },
            "WK05.1": {
              "value": 3775,
              "summableValue": true,
              "enableInput": true
            },
            "totJan": {
              "value": 4880,
              "summableValue": true
            },
            "wowJan": {
              "value": 4880,
              "summableValue": true
            },
            "q4Actual": {
              "value": 0,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 45133,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 41521,
              "summableValue": true
            },
            "totWowQ4": {
              "value": 41521,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "01",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0009BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 2496,
              "summableValue": true
            },
            "bohFlex": {
              "value": 1946,
              "summableValue": true
            },
            "bohMoto": {
              "value": 550,
              "summableValue": true
            },
            "sab": {
              "value": 72,
              "summableValue": true
            },
            "bp": {
              "value": 6688,
              "summableValue": true
            },
            "buffer": {
              "value": 550,
              "summableValue": true
            },
            "WK01": {
              "value": 249,
              "summableValue": true,
              "enableInput": true
            },
            "WK02": {
              "value": 1660,
              "summableValue": true,
              "enableInput": true
            },
            "WK03": {
              "value": 4622,
              "summableValue": true,
              "enableInput": true
            },
            "WK04": {
              "value": 1778,
              "summableValue": true,
              "enableInput": true
            },
            "WK05.1": {
              "value": 875,
              "summableValue": true,
              "enableInput": true
            },
            "totJan": {
              "value": 9184,
              "summableValue": true
            },
            "wowJan": {
              "value": 9184,
              "summableValue": true
            },
            "q4Actual": {
              "value": 0,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 36040,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 33883,
              "summableValue": true
            },
            "totWowQ4": {
              "value": 33883,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 0,
              "summableValue": true
            }
          }
        ]
      },
      "february": {
        "headers": [
          {
            "title": "PRODUCT",
            "props": "product",
            "alwaysVisible": true
          },
          {
            "title": "ORIGIN",
            "props": "origin",
            "alwaysVisible": true
          },
          {
            "title": "BOH TOTAL",
            "props": "bohTotal",
            "alwaysVisible": true
          },
          {
            "title": "BUFFER",
            "props": "buffer",
            "alwaysVisible": true
          },
          {
            "title": "BOH FLEX",
            "props": "bohFlex",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BOH MOTO",
            "props": "bohMoto",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "SAB",
            "props": "sab",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BP",
            "props": "bp",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "WK 05.2",
            "props": "WK05.2",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 06",
            "props": "WK06",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 07",
            "props": "WK07",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 08",
            "props": "WK08",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 09.1",
            "props": "WK09.1",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "TOT FEB",
            "props": "totFeb",
            "alwaysVisible": true,
            "totalMonth": true
          },
          {
            "title": "WOW FEB",
            "props": "wowFeb",
            "alwaysVisible": true
          },
          {
            "title": "Q4 ACTUAL",
            "props": "q4Actual",
            "alwaysVisible": true,
            "actual": true
          },
          {
            "title": "Q4 DEM",
            "props": "Q4DEM",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT ONE PLAN Q4",
            "props": "totOnePlanQ4",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q4",
            "props": "totWowQ4",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q4 DEM",
            "props": "totWowQ4Dem",
            "otherContentActual": true,
            "alwaysVisible": false,
            "totWow": true
          }
        ],
        "rows": [
          {
            "product": {
              "value": "XT2155 - ARUBA 2/32 - GRAPHITE",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "02",
              "executedAtWeek": "WK04",
              "salesModel": "PARG0033BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 254,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 254,
              "summableValue": true
            },
            "sab": {
              "value": 60,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 254,
              "summableValue": true
            },
            "WK05.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK06": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK07": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK08": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK09.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totFeb": {
              "value": 0,
              "summableValue": true
            },
            "wowFeb": {
              "value": 0,
              "summableValue": true
            },
            "q4Actual": {
              "value": 1267,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 63,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 262,
              "summableValue": true
            },
            "totWowQ4": {
              "value": -938,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 63,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "02",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0008BR"
            },
            "origin": {
              "value": "JAG"
            },
            "bohTotal": {
              "value": 3174,
              "summableValue": true
            },
            "bohFlex": {
              "value": 3148,
              "summableValue": true
            },
            "bohMoto": {
              "value": 26,
              "summableValue": true
            },
            "sab": {
              "value": 108,
              "summableValue": true
            },
            "bp": {
              "value": 14573,
              "summableValue": true
            },
            "buffer": {
              "value": 26,
              "summableValue": true
            },
            "WK05.2": {
              "value": 3148,
              "summableValue": true,
              "enableInput": true
            },
            "WK06": {
              "value": 3724,
              "summableValue": true,
              "enableInput": true
            },
            "WK07": {
              "value": 5513,
              "summableValue": true,
              "enableInput": true
            },
            "WK08": {
              "value": 2856,
              "summableValue": true,
              "enableInput": true
            },
            "WK09.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totFeb": {
              "value": 15241,
              "summableValue": true
            },
            "wowFeb": {
              "value": 15241,
              "summableValue": true
            },
            "q4Actual": {
              "value": 0,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 45133,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 41521,
              "summableValue": true
            },
            "totWowQ4": {
              "value": 41521,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "02",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0009BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 550,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 550,
              "summableValue": true
            },
            "sab": {
              "value": 72,
              "summableValue": true
            },
            "bp": {
              "value": 14003,
              "summableValue": true
            },
            "buffer": {
              "value": 550,
              "summableValue": true
            },
            "WK05.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK06": {
              "value": 4109,
              "summableValue": true,
              "enableInput": true
            },
            "WK07": {
              "value": 6670,
              "summableValue": true,
              "enableInput": true
            },
            "WK08": {
              "value": 2624,
              "summableValue": true,
              "enableInput": true
            },
            "WK09.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totFeb": {
              "value": 13403,
              "summableValue": true
            },
            "wowFeb": {
              "value": 13403,
              "summableValue": true
            },
            "q4Actual": {
              "value": 0,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 36040,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 33883,
              "summableValue": true
            },
            "totWowQ4": {
              "value": 33883,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 0,
              "summableValue": true
            }
          }
        ]
      },
      "march": {
        "headers": [
          {
            "title": "PRODUCT",
            "props": "product",
            "alwaysVisible": true
          },
          {
            "title": "ORIGIN",
            "props": "origin",
            "alwaysVisible": true
          },
          {
            "title": "BOH TOTAL",
            "props": "bohTotal",
            "alwaysVisible": true
          },
          {
            "title": "BUFFER",
            "props": "buffer",
            "alwaysVisible": true
          },
          {
            "title": "BOH FLEX",
            "props": "bohFlex",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BOH MOTO",
            "props": "bohMoto",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "SAB",
            "props": "sab",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BP",
            "props": "bp",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "WK 09.2",
            "props": "WK09.2",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 10",
            "props": "WK10",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 11",
            "props": "WK11",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 12",
            "props": "WK12",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 13.1",
            "props": "WK13.1",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "TOT MAR",
            "props": "totMar",
            "alwaysVisible": true,
            "totalMonth": true
          },
          {
            "title": "WOW MAR",
            "props": "wowMar",
            "alwaysVisible": true
          },
          {
            "title": "Q4 ACTUAL",
            "props": "q4Actual",
            "alwaysVisible": true,
            "actual": true
          },
          {
            "title": "Q4 DEM",
            "props": "Q4DEM",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT ONE PLAN Q4",
            "props": "totOnePlanQ4",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q4",
            "props": "totWowQ4",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q4 DEM",
            "props": "totWowQ4Dem",
            "otherContentActual": true,
            "alwaysVisible": false,
            "totWow": true
          }
        ],
        "rows": [
          {
            "product": {
              "value": "XT2155 - ARUBA 2/32 - GRAPHITE",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "03",
              "executedAtWeek": "WK04",
              "salesModel": "PARG0033BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 254,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 254,
              "summableValue": true
            },
            "sab": {
              "value": 60,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 254,
              "summableValue": true
            },
            "WK09.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK10": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK11": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK12": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK13.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totMar": {
              "value": 0,
              "summableValue": true
            },
            "wowMar": {
              "value": 0,
              "summableValue": true
            },
            "q4Actual": {
              "value": 1267,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 63,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 262,
              "summableValue": true
            },
            "totWowQ4": {
              "value": -938,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 63,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "03",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0008BR"
            },
            "origin": {
              "value": "JAG"
            },
            "bohTotal": {
              "value": 2506,
              "summableValue": true
            },
            "bohFlex": {
              "value": 2480,
              "summableValue": true
            },
            "bohMoto": {
              "value": 26,
              "summableValue": true
            },
            "sab": {
              "value": 108,
              "summableValue": true
            },
            "bp": {
              "value": 20852,
              "summableValue": true
            },
            "buffer": {
              "value": 26,
              "summableValue": true
            },
            "WK09.2": {
              "value": 2480,
              "summableValue": true,
              "enableInput": true
            },
            "WK10": {
              "value": 5280,
              "summableValue": true,
              "enableInput": true
            },
            "WK11": {
              "value": 3900,
              "summableValue": true,
              "enableInput": true
            },
            "WK12": {
              "value": 3900,
              "summableValue": true,
              "enableInput": true
            },
            "WK13.1": {
              "value": 5840,
              "summableValue": true,
              "enableInput": true
            },
            "totMar": {
              "value": 21400,
              "summableValue": true
            },
            "wowMar": {
              "value": 21400,
              "summableValue": true
            },
            "q4Actual": {
              "value": 0,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 45133,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 41521,
              "summableValue": true
            },
            "totWowQ4": {
              "value": 41521,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2022,
              "quarter": "FQ4",
              "month": "03",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0009BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 1150,
              "summableValue": true
            },
            "bohFlex": {
              "value": 600,
              "summableValue": true
            },
            "bohMoto": {
              "value": 550,
              "summableValue": true
            },
            "sab": {
              "value": 72,
              "summableValue": true
            },
            "bp": {
              "value": 12408,
              "summableValue": true
            },
            "buffer": {
              "value": 550,
              "summableValue": true
            },
            "WK09.2": {
              "value": 600,
              "summableValue": true,
              "enableInput": true
            },
            "WK10": {
              "value": 2668,
              "summableValue": true,
              "enableInput": true
            },
            "WK11": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK12": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK13.1": {
              "value": 8028,
              "summableValue": true,
              "enableInput": true
            },
            "totMar": {
              "value": 11296,
              "summableValue": true
            },
            "wowMar": {
              "value": 11296,
              "summableValue": true
            },
            "q4Actual": {
              "value": 0,
              "summableValue": true
            },
            "Q4DEM": {
              "value": 36040,
              "summableValue": true
            },
            "totOnePlanQ4": {
              "value": 33883,
              "summableValue": true
            },
            "totWowQ4": {
              "value": 33883,
              "summableValue": true
            },
            "totWowQ4Dem": {
              "value": 0,
              "summableValue": true
            }
          }
        ]
      },
      "april": {
        "headers": [
          {
            "title": "PRODUCT",
            "props": "product",
            "alwaysVisible": true
          },
          {
            "title": "ORIGIN",
            "props": "origin",
            "alwaysVisible": true
          },
          {
            "title": "BOH TOTAL",
            "props": "bohTotal",
            "alwaysVisible": true
          },
          {
            "title": "BUFFER",
            "props": "buffer",
            "alwaysVisible": true
          },
          {
            "title": "BOH FLEX",
            "props": "bohFlex",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BOH MOTO",
            "props": "bohMoto",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "SAB",
            "props": "sab",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BP",
            "props": "bp",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "WK 13.2",
            "props": "WK13.2",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 14",
            "props": "WK14",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 15",
            "props": "WK15",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 16",
            "props": "WK16",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 17",
            "props": "WK17",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "TOT APR",
            "props": "totApr",
            "alwaysVisible": true,
            "totalMonth": true
          },
          {
            "title": "WOW APR",
            "props": "wowApr",
            "alwaysVisible": true
          },
          {
            "title": "Q1 ACTUAL",
            "props": "q1Actual",
            "alwaysVisible": true,
            "actual": true
          },
          {
            "title": "Q1 DEM",
            "props": "Q1DEM",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT ONE PLAN Q1",
            "props": "totOnePlanQ1",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q1",
            "props": "totWowQ1",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q1 DEM",
            "props": "totWowQ1Dem",
            "otherContentActual": true,
            "alwaysVisible": false,
            "totWow": true
          }
        ],
        "rows": [
          {
            "product": {
              "value": "XT2155 - ARUBA 2/32 - GRAPHITE",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "04",
              "executedAtWeek": "WK04",
              "salesModel": "PARG0033BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 0,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK13.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK14": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK15": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK16": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK17": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totApr": {
              "value": 0,
              "summableValue": true
            },
            "wowApr": {
              "value": 0,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 0,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 0,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "04",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0008BR"
            },
            "origin": {
              "value": "JAG"
            },
            "bohTotal": {
              "value": 1932,
              "summableValue": true
            },
            "bohFlex": {
              "value": 1932,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 1358,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK13.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK14": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK15": {
              "value": 2690,
              "summableValue": true,
              "enableInput": true
            },
            "WK16": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK17": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totApr": {
              "value": 2690,
              "summableValue": true
            },
            "wowApr": {
              "value": 2690,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 3290,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 3290,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "04",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0009BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 1712,
              "summableValue": true
            },
            "bohFlex": {
              "value": 1712,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 662,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK13.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK14": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK15": {
              "value": 374,
              "summableValue": true,
              "enableInput": true
            },
            "WK16": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK17": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totApr": {
              "value": 374,
              "summableValue": true
            },
            "wowApr": {
              "value": 374,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 2374,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 2374,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          }
        ]
      },
      "may": {
        "headers": [
          {
            "title": "PRODUCT",
            "props": "product",
            "alwaysVisible": true
          },
          {
            "title": "ORIGIN",
            "props": "origin",
            "alwaysVisible": true
          },
          {
            "title": "BOH TOTAL",
            "props": "bohTotal",
            "alwaysVisible": true
          },
          {
            "title": "BUFFER",
            "props": "buffer",
            "alwaysVisible": true
          },
          {
            "title": "BOH FLEX",
            "props": "bohFlex",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BOH MOTO",
            "props": "bohMoto",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "SAB",
            "props": "sab",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BP",
            "props": "bp",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "WK 18",
            "props": "WK18",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 19",
            "props": "WK19",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 20",
            "props": "WK20",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 21",
            "props": "WK21",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 22.1",
            "props": "WK22.1",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "TOT MAY",
            "props": "totMay",
            "alwaysVisible": true,
            "totalMonth": true
          },
          {
            "title": "WOW MAY",
            "props": "wowMay",
            "alwaysVisible": true
          },
          {
            "title": "Q1 ACTUAL",
            "props": "q1Actual",
            "alwaysVisible": true,
            "actual": true
          },
          {
            "title": "Q1 DEM",
            "props": "Q1DEM",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT ONE PLAN Q1",
            "props": "totOnePlanQ1",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q1",
            "props": "totWowQ1",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q1 DEM",
            "props": "totWowQ1Dem",
            "otherContentActual": true,
            "alwaysVisible": false,
            "totWow": true
          }
        ],
        "rows": [
          {
            "product": {
              "value": "XT2155 - ARUBA 2/32 - GRAPHITE",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "05",
              "executedAtWeek": "WK04",
              "salesModel": "PARG0033BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 0,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK18": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK19": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK20": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK21": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK22.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totMay": {
              "value": 0,
              "summableValue": true
            },
            "wowMay": {
              "value": 0,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 0,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 0,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "05",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0008BR"
            },
            "origin": {
              "value": "JAG"
            },
            "bohTotal": {
              "value": 600,
              "summableValue": true
            },
            "bohFlex": {
              "value": 600,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK18": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK19": {
              "value": 600,
              "summableValue": true,
              "enableInput": true
            },
            "WK20": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK21": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK22.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totMay": {
              "value": 600,
              "summableValue": true
            },
            "wowMay": {
              "value": 600,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 3290,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 3290,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "05",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0009BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 2000,
              "summableValue": true
            },
            "bohFlex": {
              "value": 2000,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK18": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK19": {
              "value": 2000,
              "summableValue": true,
              "enableInput": true
            },
            "WK20": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK21": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK22.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totMay": {
              "value": 2000,
              "summableValue": true
            },
            "wowMay": {
              "value": 2000,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 2374,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 2374,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          }
        ]
      },
      "june": {
        "headers": [
          {
            "title": "PRODUCT",
            "props": "product",
            "alwaysVisible": true
          },
          {
            "title": "ORIGIN",
            "props": "origin",
            "alwaysVisible": true
          },
          {
            "title": "BOH TOTAL",
            "props": "bohTotal",
            "alwaysVisible": true
          },
          {
            "title": "BUFFER",
            "props": "buffer",
            "alwaysVisible": true
          },
          {
            "title": "BOH FLEX",
            "props": "bohFlex",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BOH MOTO",
            "props": "bohMoto",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "SAB",
            "props": "sab",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "BP",
            "props": "bp",
            "otherContentTotal": true,
            "alwaysVisible": false
          },
          {
            "title": "WK 22.2",
            "props": "WK22.2",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 23",
            "props": "WK23",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 24",
            "props": "WK24",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 25",
            "props": "WK25",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "WK 26.1",
            "props": "WK26.1",
            "weekInTime": "next",
            "alwaysVisible": true,
            "isWeek": true
          },
          {
            "title": "TOT JUN",
            "props": "totJun",
            "alwaysVisible": true,
            "totalMonth": true
          },
          {
            "title": "WOW JUN",
            "props": "wowJun",
            "alwaysVisible": true
          },
          {
            "title": "Q1 ACTUAL",
            "props": "q1Actual",
            "alwaysVisible": true,
            "actual": true
          },
          {
            "title": "Q1 DEM",
            "props": "Q1DEM",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT ONE PLAN Q1",
            "props": "totOnePlanQ1",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q1",
            "props": "totWowQ1",
            "otherContentActual": true,
            "alwaysVisible": false
          },
          {
            "title": "TOT WOW Q1 DEM",
            "props": "totWowQ1Dem",
            "otherContentActual": true,
            "alwaysVisible": false,
            "totWow": true
          }
        ],
        "rows": [
          {
            "product": {
              "value": "XT2155 - ARUBA 2/32 - GRAPHITE",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "06",
              "executedAtWeek": "WK04",
              "salesModel": "PARG0033BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 0,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK22.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK23": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK24": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK25": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK26.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totJun": {
              "value": 0,
              "summableValue": true
            },
            "wowJun": {
              "value": 0,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 0,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 0,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "06",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0008BR"
            },
            "origin": {
              "value": "JAG"
            },
            "bohTotal": {
              "value": 0,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK22.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK23": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK24": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK25": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK26.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totJun": {
              "value": 0,
              "summableValue": true
            },
            "wowJun": {
              "value": 0,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 3290,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 3290,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          },
          {
            "product": {
              "value": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
              "fiscalYear": 2023,
              "quarter": "FQ1",
              "month": "06",
              "executedAtWeek": "WK04",
              "salesModel": "PATU0009BR"
            },
            "origin": {
              "value": "MAN"
            },
            "bohTotal": {
              "value": 0,
              "summableValue": true
            },
            "bohFlex": {
              "value": 0,
              "summableValue": true
            },
            "bohMoto": {
              "value": 0,
              "summableValue": true
            },
            "sab": {
              "value": 0,
              "summableValue": true
            },
            "bp": {
              "value": 0,
              "summableValue": true
            },
            "buffer": {
              "value": 0,
              "summableValue": true
            },
            "WK22.2": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK23": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK24": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK25": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "WK26.1": {
              "value": 0,
              "summableValue": true,
              "enableInput": true
            },
            "totJun": {
              "value": 0,
              "summableValue": true
            },
            "wowJun": {
              "value": 0,
              "summableValue": true
            },
            "q1Actual": {
              "value": null,
              "summableValue": true
            },
            "Q1DEM": {
              "value": 0,
              "summableValue": true
            },
            "totOnePlanQ1": {
              "value": 2374,
              "summableValue": true
            },
            "totWowQ1": {
              "value": 2374,
              "summableValue": true
            },
            "totWowQ1Dem": {
              "value": 0,
              "summableValue": true
            }
          }
        ]
      }
    }
  ],
  "check": [
    {
      "january": {
        "headers": [
          {
            "label": "PRODUCT",
            "prop": "product",
            "sticky": true,
            "style": { width: '7,14%' },
          },
          {
            "label": "TOTAL MLS FLEX",
            "prop": "totalMlsFlex",
            "sticky": true,
            "style": { width: '7,14%' },
          },
          {
            "label": "BOH MOTO",
            "prop": "bohMoto",
            "sticky": true,
            "style": { width: '7,14%' },
          },
          {
            "label": "ONE PLAN MOTO",
            "prop": "onePlanMoto",
            "sticky": true,
            "style": { width: '7,14%' },
          },
          {
            "label": "CHECK",
            "prop": "check",
            "sticky": true,
            "style": { width: '7,14%' },
          }
        ],
        "rows": [
          {
            "product": "XT2155 - ARUBA 2/32 - GRAPHITE",
            "totalMlsFlex": 8,
            "bohMoto": 254,
            "onePlanMoto": 262,
            "check": 0
          },
          {
            "product": "XT2231 - HAWAIIPLUS22 4/128 - FRAGRANCE GREEN",
            "totalMlsFlex": 13488,
            "bohMoto": 576,
            "onePlanMoto": 14064,
            "check": 0
          }
        ]
      }
    }
  ]
}

export const wowVariationMockup: any = {
  headers: [
    { prop: 'product', label: 'product', sticky: true, wowVariation: true },
    { prop: 'origin', label: 'origin', sticky: true, wowVariation: true },
    { prop: 'WK01', label: 'wk01_jan', sticky: true, wowVariation: true },
    { prop: 'WK02', label: 'wk02_jan', sticky: true, wowVariation: true },
    { prop: 'WK03', label: 'wk03_jan', sticky: true, wowVariation: true },
    { prop: 'WK04', label: 'wk04_jan', sticky: true, wowVariation: true },
    { prop: 'WK05', label: 'wk05_jan', sticky: true, wowVariation: true },
    { prop: 'TOT JAN', label: 'tot_jan', sticky: true, wowVariation: true },
    { prop: 'WK01', label: 'wk01_fev', sticky: true, wowVariation: true },
    { prop: 'WK02', label: 'wk02_fev', sticky: true, wowVariation: true },
    { prop: 'WK03', label: 'wk03_fev', sticky: true, wowVariation: true },
    { prop: 'WK04', label: 'wk04_fev', sticky: true, wowVariation: true },
    { prop: 'WK05', label: 'wk05_fev', sticky: true, wowVariation: true },
    { prop: 'TOT FEV', label: 'tot_fev', sticky: true, wowVariation: true },
    { prop: 'WK01', label: 'wk01_mar', sticky: true, wowVariation: true },
    { prop: 'WK02', label: 'wk02_mar', sticky: true, wowVariation: true },
    { prop: 'WK03', label: 'wk03_mar', sticky: true, wowVariation: true },
    { prop: 'WK04', label: 'wk04_mar', sticky: true, wowVariation: true },
    { prop: 'WK05', label: 'wk05_mar', sticky: true, wowVariation: true },
    { prop: 'TOT MAR', label: 'tot_mar', sticky: true, wowVariation: true },
    { prop: 'VARIATION', label: 'variation', sticky: true, wowVariation: true },
  ],
  rows: [
    {
      product: 'XT2095 - Malta 2/32 Ice Flow',
      origin: 'JAG',
      wk01_jan: 0.1,
      wk02_jan: 0.5,
      wk03_jan: 0.5,
      wk04_jan: 1.1,
      wk05_jan: 0.5,
      tot_jan: 1.5,
      wk01_fev: 1.1,
      wk02_fev: 0.5,
      wk03_fev: 0.5,
      wk04_fev: 1.1,
      wk05_fev: 0.5,
      tot_fev: 1.5,
      wk01_mar: 1.1,
      wk02_mar: 0.5,
      wk03_mar: 0.5,
      wk04_mar: 1.1,
      wk05_mar: 0.5,
      tot_mar: 1.5,
      variation: 6.0,
    },
    {
      product: 'XT2095 - Malta 2/32 Ice Flow',
      origin: 'JAG',
      wk01_jan: 1.1,
      wk02_jan: 0.5,
      wk03_jan: 0.5,
      wk04_jan: 1.1,
      wk05_jan: 0.5,
      tot_jan: 1.5,
      wk01_fev: 1.1,
      wk02_fev: 0.5,
      wk03_fev: 0.5,
      wk04_fev: 1.1,
      wk05_fev: 0.5,
      tot_fev: 1.5,
      wk01_mar: 1.1,
      wk02_mar: 0.5,
      wk03_mar: 0.5,
      wk04_mar: 1.1,
      wk05_mar: 0.5,
      tot_mar: 1.5,
      variation: 6.0,
    },
  ],
};
