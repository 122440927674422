import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-allocation',
  templateUrl: './card-allocation.component.html',
  styleUrls: ['./card-allocation.component.scss'],
})
export class CardAllocationComponent implements OnInit {
  @Input() cardsInformation?: any[];
  getDatas: any[] = [];
  objectLength: any;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardsInformation.currentValue) {
      this.getDataInformation(changes.cardsInformation.currentValue);
    }
  }

  getDataInformation(data: any) {
    this.getDatas = [];

    data?.forEach((item: any) => {
      if (typeof item.value01 && typeof item.value02 === 'number') {
        this.getDatas.push({
          title: item.title,
          subtitle01: item.subtitle01,
          value01: this.valueCard(item.value01),
          subtitle02: item.subtitle02,
          value02: this.valueCard(item.value02),
        });
      } else {
        this.getDatas.push({
          title: item.title,
          subtitle01: item.subtitle01,
          value01: item.value01,
          subtitle02: item.subtitle02,
          value02: item.value02,
        });
      }
    });

    this.objectLength = Object.keys(this.getDatas[0]).length;
  }

  valueCard(item: number) {
    return item.toLocaleString('en-GB');
  }
}
