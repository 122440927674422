import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { AuthService } from '../../services/authorization/auth.service';

@Component({
  selector: 'app-one-plan-principal',
  templateUrl: './one-plan.component.html',
  styleUrls: ['./one-plan.component.scss'],
})
export class OnePlanPrincipalComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  pages: any[] = [
    // TODO: Hidden for homologation
    // Ronaldo Reis - 19-05-2023
    {
      name: 'One Plan',
      url: '../../../../assets/images/ico-one-plan-principal.svg',
      path: 'one-plan',
    },
    {
      name: 'Flex One Plan Variation',
      url: '../../../../assets/images/ico-variation-analysis.svg',
      path: 'flex-one-plan-variation',
    },
    {
      name: 'One Plan Upload',
      url: '../../../../assets/images/ico-one-plan-upload.svg',
      path: 'one-plan-upload',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }

  ngOnInit(): void {
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'ONE PLAN',
          this.pages
        );
      });
    } else {
      this.router.navigate(['authorization']);
    }
  }

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
