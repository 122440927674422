import { SelectionModel } from '@angular/cdk/collections';
import {
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  Component,
  Input,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { TableInterface } from '../table/table.interface';

@Component({
  selector: 'app-table-accordion',
  templateUrl: './table-accordion.component.html',
  styleUrls: ['./table-accordion.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class TableAccordionComponent implements OnChanges {
  @Output('callActions') callActions = new EventEmitter();
  @Input() table!: TableInterface;
  @Input() selectedTab!: any;
  selection = new SelectionModel<any>(true, []);
  panelOpenState = false;
  dataSource: any;
  displayedColumns: any;
  columnsCustomer: any = [];
  columnsLabelsTableCustomer: any[] = [];
  columnsLabel: any = [];
  hasStatus: any;
  tooltipMessage: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.table?.currentValue) {
      this.columnsLabel = this.table.columns;
      this.displayedColumns = this.table.columns.map(
        (column: any) => column.label
      );
      this.table.columns.every((column: any) => {
        if (column.innerTable) {
          this.columnsCustomer = column.innerTable;
          return false;
        }
        return true;
      });
      this.columnsLabelsTableCustomer = this.columnsCustomer.map(
        (column: any) => column.idColumnLabel
      );

      this.dataSource = this.table.rows;
      this.hasStatus = this.table.hasStatus;
      this.tooltipMessage = this.table.tooltipMessage;

      this.selection = new SelectionModel<any>(true, []);
    }
  }

  checkType(value: any) {
    return typeof value === 'object';
  }

  isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  isNumber(value: any) {
    return typeof value === 'number';
  }

  viewEvent(item: any) {
    this.callActions.emit({ item, type: 'view' });
  }

  editEvent(item: any) {
    this.callActions.emit({ item, type: 'edit' });
  }

  deleteEvent(item: any) {
    this.callActions.emit({ item, type: 'delete' });
  }

  systemFunction(item: any) {
    this.callActions.emit({ item, type: 'systemFunction' });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.callActions.emit({ item: this.selection, type: 'select' });
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.callActions.emit({ item: this.selection, type: 'select' });
  }

  checkboxToggle(element: any) {
    this.selection.toggle(element);
    this.callActions.emit({ item: this.selection, type: 'select' });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  trackByIndex = (i: number) => i;
}
