<ngx-spinner></ngx-spinner>
<div id="ka-allocation-upload" class="container">
  <div class="header-title">
    <span class="title">KA ALLOCATION UPLOAD</span>
    <div class="ka-allocation-upload-buttons">
      <div class="file-upload">
        <input type="file" class="file-input" (change)="onFileSelected($event)"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          #fileUpload>
        <button
          matTooltip="{{ 'Upload Allocation' }}"
          class="upload-button"
          [class.disabled-upload]="!isInputKa"
          [disabled]="!isInputKa"
          (click)="fileUpload.click()">
        </button>
      </div>
      <span
          class="style-export"
          (click)="onFileDownload()"
          matTooltip="{{ 'Export' }}">
          <img src="../../../assets/images/BTN-Export.svg" alt="download" />
      </span>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <ng-template #showTable>
    <app-results
      [data]="tableResult"
      (resultPaginator)="onChangePaginator($event)"
      [isModeTableAccordion]="isModeTableAccordion"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
    >
    </app-results>
  </ng-template>
  <div class="no-filter-selected-session" *ngIf="showNoUpload; else showTable">
    <p>NO FILE UPLOADED YET.</p>
  </div>
  <ng-template #dialogConfirmation>
    <header class="title-modal" matDialogTitle>Error</header>
    <mat-dialog-content>{{ messageModal }}</mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-action-button" mat-button matDialogClose>
        OK
      </button>
    </mat-dialog-actions>
  </ng-template>
  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
