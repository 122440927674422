import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { MatTableModule } from '@angular/material/table';
import { EmeaAllocationComponent } from './emea-allocation.component';
import { TerritoryAllocationComponent } from './territory-allocation/territory-allocation.component';
import { OrderAllocationComponent } from './order-allocation/order-allocation.component';
import { DialogEditComponent } from 'src/app/components/emea/table-inputs/dialog-edit/dialog-edit.component';
import { DialogTableComponent } from 'src/app/components/emea/table-inputs/dialog-table/dialog-table.component';
import { DialogCreateComponent } from 'src/app/components/emea/table-inputs/dialog-create/dialog-create.component';
import { ExecutiveOverviewComponent } from './executive-overview/executive-overview.component';
import { TerritoryAndProductsViewComponent } from './territory-and-products-view/territory-and-products-view.component';
import { WowAnalysisComponent } from './wow-analysis/wow-analysis.component';

@NgModule({
  declarations: [
    EmeaAllocationComponent,
    TerritoryAllocationComponent,
    OrderAllocationComponent,
    DialogTableComponent,
    DialogEditComponent,
    DialogCreateComponent,
    ExecutiveOverviewComponent,
    TerritoryAndProductsViewComponent,
    WowAnalysisComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ShareDirectiviesModule,
    ShareComponentsModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
})
export class EmeaAllocationModule {}
