import { CardColorInterface } from 'src/app/components/card-color/card-color.interface';

export const cardsColor: CardColorInterface[] = [
  {
    title: 'PAJA0024BR',
    content: [
      { value: 'BRXT2063-FIG5G' },
      { value: 'moto g5' },
      { value: 'CLARO BR' },
      { value: 'Manaus' },
    ],
    borderColor: '#f99c9c',
    styleClass: 'medium-width',
  },
  {
    title: 'TIM_BRA',
    subtitle: '17 Products',
    content: [{ value: 'BR' }],
    borderColor: '#a3e9f3',
    styleClass: 'medium-width',
  },
  {
    title: 'Vivo_BR',
    subtitle: '17 Products',
    content: [{ value: 'BR' }],
    borderColor: '#c9acfc',
    styleClass: 'medium-width',
  },
  {
    title: 'Retail_BR',
    subtitle: '17 Products',
    content: [{ value: 'BR' }],
    borderColor: '#a9f39b',
    styleClass: 'medium-width',
  },
  {
    title: 'MMICOM_BRA',
    subtitle: '17 Products',
    content: [{ value: 'BR' }],
    borderColor: '#fce198',
    styleClass: 'medium-width',
  },
  {
    title: 'Claro_BR',
    subtitle: '17 Products',
    content: [{ value: 'BR' }],
    borderColor: '#f99c9c',
    styleClass: 'medium-width',
  },
  {
    title: 'TIM_BRA',
    subtitle: '17 Products',
    content: [{ value: 'BR' }],
    borderColor: '#a3e9f3',
    styleClass: 'medium-width',
  },
];

export const cardsFairDistribution: CardColorInterface[] = [
  {
    content: [
      { label: 'April:', value: -545, valueClass: 'negative-value' },
      { label: 'May:', value: 185, valueClass: 'positive-value' },
      { label: 'June:', value: -41, valueClass: 'negative-value' },
    ],
    borderColor: '#f99c9c',
    styleClass: 'small-width',
  },
  {
    content: [
      { label: 'April:', value: 545, valueClass: 'positive-value' },
      { label: 'May:', value: -185, valueClass: 'negative-value' },
      { label: 'June:', value: 41, valueClass: 'positive-value' },
    ],
    borderColor: '#a3e9f3',
    styleClass: 'small-width',
  },
  {
    content: [
      { label: 'April:', value: -545, valueClass: 'negative-value' },
      { label: 'May:', value: 185, valueClass: 'positive-value' },
      { label: 'June:', value: -41, valueClass: 'negative-value' },
    ],
    borderColor: '#c9acfc',
    styleClass: 'small-width',
  },
  {
    content: [
      { label: 'April:', value: 545, valueClass: 'positive-value' },
      { label: 'May:', value: -185, valueClass: 'negative-value' },
      { label: 'June:', value: 41, valueClass: 'positive-value' },
    ],
    borderColor: '#a9f39b',
    styleClass: 'small-width',
  },
  {
    content: [
      { label: 'April:', value: -545, valueClass: 'negative-value' },
      { label: 'May:', value: 185, valueClass: 'positive-value' },
      { label: 'June:', value: -41, valueClass: 'negative-value' },
    ],
    borderColor: '#fce198',
    styleClass: 'small-width',
  },
];

export const responseAllocation: { [key: string]: any } = {
  simpleTable: {
    // Objeto para renderizar dados para component de simple table
    headers: [
      {
        title: 'Product',
        props: 'product',
        subTitle: '',
        style: { width: '63.5%' },
      },
      {
        title: 'Sales Model',
        props: 'salesModel', // Atrituto para referenciar a row
        subTitle: '',
        style: { width: '36.5%' },
      },
    ],
    rows: [
      {
        product: {
          value: 'XT2063 - Capri 5G - Aurora Gray',
          id: 123,
        },
        salesModel: {
          // props do header
          value: '-',
        },
        ka: {
          value: 'MLS',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        product: {
          value: '',
          id: 123,
        },
        salesModel: {
          // props do header
          value: 'PAJA0029BR',
          color: '#f99c9c', // cor ao lado do sales model
        },
        ka: {
          value: 'CLARO_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        salesModel: {
          value: 'PAJA0030BR',
          color: '#a9f39b',
        },
        product: {
          value: '',
          id: 123,
        },
        ka: {
          value: 'RETAIL_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        salesModel: {
          value: 'PAJA0031BR',
          color: '#c9acfc',
        },
        product: {
          value: '',
          id: 123,
        },
        ka: {
          value: 'VIVO_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        // 2º product
        product: {
          value: 'XT2076 - Capri 5G - Red Water',
          id: 456,
        },
        salesModel: {
          // props do header
          value: '-',
        },
        ka: {
          value: 'MLS',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        product: {
          value: '',
          id: 456,
        },
        salesModel: {
          // props do header
          value: 'PAJA0033BR',
          color: '#fce198', // cor ao lado do sales model
        },
        ka: {
          value: 'MMICOM_BRA',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        salesModel: {
          value: 'PAJA0034BR',
          color: '#a9f39b',
        },
        product: {
          value: '',
          id: 456,
        },
        ka: {
          value: 'RETAIL_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        // 3º product
        product: {
          value: 'XT2079 - Doha - Phantom Black',
          id: 789,
        },
        salesModel: {
          // props do header
          value: '-',
        },
        ka: {
          value: 'MLS',
        },
        origin: {
          value: 'MAO',
        },
      },
      {
        product: {
          value: '',
          id: 789,
        },
        salesModel: {
          // props do header
          value: 'PAJA0041BR',
          color: '#a9f39b', // cor ao lado do sales model
        },
        ka: {
          value: 'RETAIL_BR',
        },
        origin: {
          value: 'MAO',
        },
      },
      {
        salesModel: {
          value: 'PAJA0042BR',
          color: '#a3e9f3',
        },
        product: {
          value: '',
          id: 789,
        },
        ka: {
          value: 'TIM_BRA',
        },
        origin: {
          value: 'MAO',
        },
      },
    ],
  },
  expandedTable: {
    // expandedTabel::: Objeto para renderizar dados para component de simple table
    headers: [
      {
        title: 'Origin',
        props: 'origin', // props::: Atrituto para referenciar a row
        otherContent: 'origin', // otherContent::: Atrituto para marcar colunas que pertencem a content
        enableContent: true, // enableConent::: Atributo para settar o backgound da coluna (w/g)
      },
      {
        title: 'SAB',
        props: 'sab',
        otherContent: 'origin',
        enableContent: true,
      },
      {
        title: 'Total Apr',
        props: 'april',
        otherContent: 'april',
        enableContent: false,
      },
      {
        title: 'WK 01',
        props: 'aprilwk01',
        otherContent: 'april',
        enableContent: false,
      },
      {
        title: 'WK 02',
        props: 'aprilwk02',
        otherContent: 'april',
        enableContent: false,
      },
      {
        title: 'WK 03',
        props: 'aprilwk03',
        otherContent: 'april',
        enableContent: false,
      },
      {
        title: 'WK 04',
        props: 'aprilwk04',
        otherContent: 'april',
        enableContent: false,
      },
      {
        title: 'WK 05',
        props: 'aprilwk05',
        otherContent: 'april',
        enableContent: false,
      },
      {
        title: 'Total May', // Observe que adicionei abril e may. Abril sendo passado (tudo disable) e may como mes corrente habilitando somente a segunda semana.
        props: 'may',
        otherContent: 'may',
        enableContent: false,
      },
      {
        title: 'WK 01',
        props: 'maywk01',
        otherContent: 'may',
        enableContent: false,
      },
      {
        title: 'WK 02',
        props: 'maywk02',
        otherContent: 'may',
        enableContent: false,
      },
      {
        title: 'WK 03',
        props: 'maywk03',
        otherContent: 'may',
        enableContent: false,
      },
      {
        title: 'WK 04',
        props: 'maywk04',
        otherContent: 'may',
        enableContent: false,
      },
      {
        title: 'WK 05',
        props: 'maywk05',
        otherContent: 'may',
        enableContent: false,
      },
      {
        title: 'Total Jun',
        props: 'june',
        otherContent: 'june',
        enableContent: true,
      },
      {
        title: 'WK 01',
        props: 'junewk01',
        otherContent: 'june',
        enableContent: false,
      },
      {
        title: 'WK 02',
        props: 'junewk02',
        otherContent: 'june',
        enableContent: false,
      },
      {
        title: 'WK 03',
        props: 'junewk03',
        otherContent: 'june',
        enableContent: true,
        actualWeek: true,
      },
      {
        title: 'WK 04',
        props: 'junewk04',
        otherContent: 'june',
        enableContent: true,
      },
      {
        title: 'WK 05',
        props: 'junewk05',
        otherContent: 'june',
        enableContent: true,
      },
      {
        title: 'FQ1 Total',
        props: 'fq1Total',
        otherContent: 'fq1Total',
        enableContent: true,
        hideIconExpand: true,
      },
      {
        title: 'FQ1 Actual',
        props: 'fq1Actual',
        otherContent: 'fq1Actual',
        enableContent: true,
        hideIconExpand: true,
      },
    ],
    rows: [
      {
        // 1º product
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 123,
          salesModel: '-',
        },
        sab: {
          value: '250',
          idProduct: 123,
          salesModel: '-',
        },
        april: {
          value: 1500,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        aprilwk01: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        aprilwk02: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        aprilwk03: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        aprilwk04: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        aprilwk05: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        may: {
          value: 1500,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        maywk01: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        maywk02: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        maywk03: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        maywk04: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        maywk05: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        june: {
          value: 1500,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        junewk01: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        junewk02: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        junewk03: {
          value: 300,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          idProduct: 123,
          salesModel: '-',
        },
        junewk04: {
          value: 300,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          idProduct: 123,
          salesModel: '-',
        },
        junewk05: {
          value: 300,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          idProduct: 123,
          salesModel: '-',
        },
        fq1Total: {
          value: 4500,
          idProduct: 123,
        },
        fq1Actual: {
          value: 3000,
          idProduct: 123,
        },
      },
      {
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        sab: {
          value: '250',
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        april: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        aprilwk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        aprilwk02: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        aprilwk03: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        aprilwk04: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        aprilwk05: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        may: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        maywk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          salesModel: 'PAJA0029BR',
          ka: 'CLARO_BR',
        },
        maywk02: {
          value: 100,
          idProduct: 123,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        maywk03: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        maywk04: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        maywk05: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        june: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        junewk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        junewk02: {
          value: 100,
          idProduct: 123,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        junewk03: {
          value: 0,
          idProduct: 123,
          enableInput: true,
          salesModel: 'PAJA0029BR',
          ka: 'CLARO_BR',
        },
        junewk04: {
          value: 0,
          idProduct: 123,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        junewk05: {
          value: 0,
          idProduct: 123,
          enableInput: true,
          salesModel: 'PAJA0029BR',
          ka: 'CLARO_BR',
        },
        fq1Total: {
          value: 1500,
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
      },
      {
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 123,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        sab: {
          value: '250',
          idProduct: 123,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        april: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        aprilwk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        aprilwk02: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        aprilwk03: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        aprilwk04: {
          value: 100,
          idProduct: 123,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0030BR',
        },
        aprilwk05: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        may: {
          value: 500,
          idProduct: 123,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0030BR',
        },
        maywk01: {
          value: 100,
          idProduct: 123,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0030BR',
        },
        maywk02: {
          value: 100,
          idProduct: 123,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        maywk03: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        maywk04: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        maywk05: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        june: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        junewk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        junewk02: {
          value: 100,
          idProduct: 123,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        junewk03: {
          value: 300,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        junewk04: {
          value: 300,
          idProduct: 123,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        junewk05: {
          value: 300,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        fq1Total: {
          value: 1500,
          idProduct: 123,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 123,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
      },
      {
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        sab: {
          value: '250',
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        april: {
          value: 500,
          idProduct: 123,
          ka: 'VIVO_BR',
          enableInput: false,
          salesModel: 'PAJA0031BR',
        },
        aprilwk01: {
          value: 100,
          ka: 'VIVO_BR',
          idProduct: 123,
          enableInput: false,
          salesModel: 'PAJA0031BR',
        },
        aprilwk02: {
          value: 100,
          idProduct: 123,
          ka: 'VIVO_BR',
          enableInput: false,
          salesModel: 'PAJA0031BR',
        },
        aprilwk03: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        aprilwk04: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        aprilwk05: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        may: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        maywk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        maywk02: {
          value: 100,
          idProduct: 123,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        maywk03: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        maywk04: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        maywk05: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        june: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        junewk01: {
          value: 100,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        junewk02: {
          value: 100,
          idProduct: 123,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        junewk03: {
          value: 0,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        junewk04: {
          value: 0,
          idProduct: 123,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        junewk05: {
          value: 0,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        fq1Total: {
          value: 1500,
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
      },
      {
        // 2º product
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 456,
          salesModel: '-',
        },
        sab: {
          value: '250',
          idProduct: 456,
          salesModel: '-',
        },
        april: {
          value: 1000,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        aprilwk01: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        aprilwk02: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        aprilwk03: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        aprilwk04: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        aprilwk05: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        may: {
          value: 1000,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        maywk01: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        maywk02: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        maywk03: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        maywk04: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        maywk05: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        june: {
          value: 1000,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        junewk01: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        junewk02: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        junewk03: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          salesModel: '-',
        },
        junewk04: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          salesModel: '-',
        },
        junewk05: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          salesModel: '-',
        },
        fq1Total: {
          value: 1000,
          idProduct: 456,
          salesModel: '-',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 456,
          salesModel: '-',
        },
      },
      {
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        sab: {
          value: '250',
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        april: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        aprilwk01: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        aprilwk02: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        aprilwk03: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        aprilwk04: {
          value: 100,
          ka: 'MMICOM_BRA',
          idProduct: 456,
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        aprilwk05: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        may: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        maywk01: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          salesModel: 'PAJA0033BR',
          ka: 'MMICOM_BRA',
        },
        maywk02: {
          value: 100,
          idProduct: 456,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        maywk03: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        maywk04: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: false,
          salesModel: 'PAJA0033BR',
        },
        maywk05: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        june: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        junewk01: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        junewk02: {
          value: 100,
          idProduct: 456,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        junewk03: {
          value: 0,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        junewk04: {
          value: 0,
          idProduct: 456,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        junewk05: {
          value: 0,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        fq1Total: {
          value: 750,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        fq1Actual: {
          value: 500,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
      },
      {
        origin: {
          // props do header
          value: 'JAG',
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        sab: {
          value: '250',
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        april: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        aprilwk01: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        aprilwk02: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0034BR',
        },
        aprilwk03: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0034BR',
        },
        aprilwk04: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0034BR',
        },
        aprilwk05: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0034BR',
        },
        may: {
          value: 500,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0034BR',
        },
        maywk01: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        maywk02: {
          value: 100,
          idProduct: 456,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        maywk03: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        maywk04: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        maywk05: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        june: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        junewk01: {
          value: 100,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        junewk02: {
          value: 100,
          idProduct: 456,
          enableInput: false, // habilitar input para semana ativa de maio
          salesModel: 'PAJA0034BR',
          ka: 'RETAIL_BR',
        },
        junewk03: {
          value: 200,
          idProduct: 456,
          enableInput: true,
          salesModel: 'PAJA0034BR',
          ka: 'RETAIL_BR',
          isDisabled: true,
        },
        junewk04: {
          value: 200,
          idProduct: 456,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
          isDisabled: true,
        },
        junewk05: {
          value: 200,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        fq1Total: {
          value: 750,
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        fq1Actual: {
          value: 500,
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
      },
      {
        // 3º product
        origin: {
          // props do header
          value: 'MAO',
          idProduct: 789,
          salesModel: '-',
        },
        sab: {
          value: 250,
          idProduct: 789,
          salesModel: '-',
        },
        april: {
          value: 1000,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        aprilwk01: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        aprilwk02: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        aprilwk03: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        aprilwk04: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        aprilwk05: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        may: {
          value: 1000,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        maywk01: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        maywk02: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        maywk03: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        maywk04: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        maywk05: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        june: {
          value: 1000,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        junewk01: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        junewk02: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        junewk03: {
          value: 200,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          idProduct: 789,
          salesModel: '-',
        },
        junewk04: {
          value: 200,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          idProduct: 789,
          salesModel: '-',
        },
        junewk05: {
          value: 200,
          style: { 'font-weight': 'bold' },
          enableMLS: true,
          idProduct: 789,
          salesModel: '-',
        },
        fq1Total: {
          value: 3600,
          idProduct: 789,
          salesModel: '-',
        },
        fq1Actual: {
          value: 2400,
          idProduct: 789,
          salesModel: '-',
        },
      },
      {
        origin: {
          // props do header
          value: 'MAO',
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        sab: {
          value: '250',
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        april: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        aprilwk01: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        aprilwk02: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        aprilwk03: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        aprilwk04: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        aprilwk05: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        may: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        maywk01: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        maywk02: {
          value: 100,
          idProduct: 789,
          enableInput: false, // habilitar input para semana ativa de maio
          salesModel: 'PAJA0041BR',
          ka: 'RETAIL_BR',
        },
        maywk03: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0041BR',
          ka: 'RETAIL_BR',
        },
        maywk04: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0041BR',
          ka: 'RETAIL_BR',
        },
        maywk05: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0041BR',
          ka: 'RETAIL_BR',
        },
        june: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        junewk01: {
          value: 100,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        junewk02: {
          value: 100,
          idProduct: 789,
          enableInput: false, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        junewk03: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
          isDisabled: true,
        },
        junewk04: {
          value: 100,
          idProduct: 789,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
          isDisabled: true,
        },
        junewk05: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0041BR',
          ka: 'RETAIL_BR',
        },
        fq1Total: {
          value: 1800,
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        fq1Actual: {
          value: 1200,
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
      },
      {
        origin: {
          // props do header
          value: 'MAO',
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        sab: {
          value: '250',
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        april: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        aprilwk01: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        aprilwk02: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        aprilwk03: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        aprilwk04: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        aprilwk05: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        may: {
          value: 500,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        maywk01: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        maywk02: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false, // habilitar input para semana ativa de maio
          salesModel: 'PAJA0042BR',
        },
        maywk03: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        maywk04: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        maywk05: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          salesModel: 'PAJA0042BR',
          enableInput: false,
        },
        june: {
          value: 500,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        junewk01: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        junewk02: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false, // habilitar input para semana ativa de maio
          salesModel: 'PAJA0042BR',
        },
        junewk03: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        junewk04: {
          value: 100,
          idProduct: 789,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        junewk05: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
          enableInput: true,
        },
        fq1Total: {
          value: 1800,
          idProduct: 789,
          salesModel: 'PAJA0042BR',
          ka: 'TIM_BRA',
        },
        fq1Actual: {
          value: 1200,
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
      },
    ],
  },
};

export const responseAllocation2: { [key: string]: any } = {
  simpleTable: {
    headers: [
      {
        title: 'Product',
        props: 'product',
        subTitle: '',
        style: { width: '63.5%' },
      },
      {
        title: 'Sales Model',
        props: 'salesModel',
        subTitle: '',
        style: { width: '36.5%' },
      },
    ],
    rows: [
      {
        product: {
          value: 'XT2063 - GUAM+ - Aurora Gray',
          id: 123,
        },
        salesModel: {
          value: '-',
        },
        ka: {
          value: 'MLS',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        product: {
          value: '',
          id: 123,
        },
        salesModel: {
          value: 'PAJA0029BR',
          color: '#f99c9c',
        },
        ka: {
          value: 'CLARO_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        salesModel: {
          value: 'PAJA0030BR',
          color: '#a9f39b',
        },
        product: {
          value: '',
          id: 123,
        },
        ka: {
          value: 'RETAIL_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        salesModel: {
          value: 'PAJA0031BR',
          color: '#c9acfc',
        },
        product: {
          value: '',
          id: 123,
        },
        ka: {
          value: 'VIVO_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        product: {
          value: ' XT1710 - HOUDINI 4/64 - FINE GOLD',
          id: 456,
        },
        salesModel: {
          value: '-',
        },
        ka: {
          value: 'MLS',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        product: {
          value: '',
          id: 456,
        },
        salesModel: {
          value: 'PAJA0033BR',
          color: '#fce198',
        },
        ka: {
          value: 'MMICOM_BRA',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        salesModel: {
          value: 'PAJA0034BR',
          color: '#a9f39b',
        },
        product: {
          value: '',
          id: 456,
        },
        ka: {
          value: 'RETAIL_BR',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        // 3º product
        product: {
          value: ' XT1925 - BLAINE 4/64 - DEEP INDIGO ',
          id: 789,
        },
        salesModel: {
          value: '-',
        },
        ka: {
          value: 'MLS',
        },
        origin: {
          value: 'MAO',
        },
      },
      {
        product: {
          value: '',
          id: 789,
        },
        salesModel: {
          value: 'PAJA0041BR',
          color: '#a9f39b',
        },
        ka: {
          value: 'RETAIL_BR',
        },
        origin: {
          value: 'MAO',
        },
      },
      {
        salesModel: {
          value: 'PAJA0042BR',
          color: '#a3e9f3',
        },
        product: {
          value: '',
          id: 789,
        },
        ka: {
          value: 'TIM_BRA',
        },
        origin: {
          value: 'MAO',
        },
      },
    ],
  },
  expandedTable: {
    headers: [
      {
        title: 'Origin',
        props: 'origin',
        otherContent: 'origin',
        enableContent: true,
      },
      {
        title: 'SAB',
        props: 'sab',
        otherContent: 'origin',
        enableContent: true,
      },
      {
        title: 'Total Jul',
        props: 'july',
        otherContent: 'july',
        enableContent: true,
      },
      {
        title: 'WK 01',
        props: 'julywk01',
        otherContent: 'july',
        enableContent: true,
      },
      {
        title: 'WK 02',
        props: 'julywk02',
        otherContent: 'july',
        enableContent: true,
      },
      {
        title: 'WK 03',
        props: 'julywk03',
        otherContent: 'july',
        enableContent: true,
      },
      {
        title: 'WK 04',
        props: 'julywk04',
        otherContent: 'july',
        enableContent: true,
      },
      {
        title: 'WK 05',
        props: 'julywk05',
        otherContent: 'july',
        enableContent: true,
      },
      {
        title: 'Total Aug',
        props: 'august',
        otherContent: 'august',
        enableContent: true,
      },
      {
        title: 'WK 01',
        props: 'augustwk01',
        otherContent: 'august',
        enableContent: true,
      },
      {
        title: 'WK 02',
        props: 'augustwk02',
        otherContent: 'august',
        enableContent: true,
      },
      {
        title: 'WK 03',
        props: 'augustwk03',
        otherContent: 'august',
        enableContent: true,
      },
      {
        title: 'WK 04',
        props: 'augustwk04',
        otherContent: 'august',
        enableContent: true,
      },
      {
        title: 'WK 05',
        props: 'augustwk05',
        otherContent: 'august',
        enableContent: true,
      },
      {
        title: 'Total Sep',
        props: 'september',
        otherContent: 'september',
        enableContent: true,
      },
      {
        title: 'WK 01',
        props: 'septemberwk01',
        otherContent: 'september',
        enableContent: true,
      },
      {
        title: 'WK 02',
        props: 'septemberwk02',
        otherContent: 'september',
        enableContent: true,
      },
      {
        title: 'WK 03',
        props: 'septemberwk03',
        otherContent: 'september',
        enableContent: true,
      },
      {
        title: 'WK 04',
        props: 'septemberwk04',
        otherContent: 'september',
        enableContent: true,
      },
      {
        title: 'WK 05',
        props: 'septemberwk05',
        otherContent: 'september',
        enableContent: true,
      },
      {
        title: 'FQ1 Total',
        props: 'fq1Total',
        otherContent: 'fq1Total',
        enableContent: true,
        hideIconExpand: true,
      },
      {
        title: 'FQ1 Actual',
        props: 'fq1Actual',
        otherContent: 'fq1Actual',
        enableContent: true,
        hideIconExpand: true,
      },
    ],
    rows: [
      {
        // 1º product
        origin: {
          value: 'JAG',
          idProduct: 123,
          ka: '',
        },
        sab: {
          value: '250',
          idProduct: 123,
          salesModel: '-',
        },
        july: {
          value: 1500,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        julywk01: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        julywk02: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        julywk03: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        julywk04: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        julywk05: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        august: {
          value: 3000,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        augustwk01: {
          value: 600,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk02: {
          value: 600,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk03: {
          value: 600,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk04: {
          value: 600,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk05: {
          value: 600,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        september: {
          value: 1500,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
        },
        septemberwk01: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk02: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk03: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk04: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk05: {
          value: 300,
          style: { 'font-weight': 'bold' },
          idProduct: 123,
          salesModel: '-',
          enableMLS: true,
        },
        fq1Total: {
          value: 4500,
          idProduct: 123,
        },
        fq1Actual: {
          value: 3000,
          idProduct: 123,
        },
      },
      {
        origin: {
          value: 'JAG',
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        sab: {
          value: '250',
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        july: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        julywk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
          enableMLS: true,
        },
        julywk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        julywk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        julywk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        julywk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          salesModel: 'PAJA0029BR',
          ka: 'CLARO_BR',
        },
        august: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        augustwk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        augustwk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        augustwk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        augustwk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        september: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        septemberwk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        septemberwk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        septemberwk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        septemberwk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        fq1Total: {
          value: 1500,
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 123,
          ka: 'CLARO_BR',
          salesModel: 'PAJA0029BR',
        },
      },
      {
        origin: {
          value: 'JAG',
          idProduct: 123,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        sab: {
          value: '250',
          idProduct: 123,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        july: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        julywk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        julywk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        julywk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        julywk04: {
          value: 100,
          idProduct: 123,
          ka: 'RETAIL_BR',
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        julywk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        august: {
          value: 500,
          idProduct: 123,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0030BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 123,
          ka: 'RETAIL_BR',
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        augustwk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        augustwk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        augustwk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        augustwk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        september: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
        },
        septemberwk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        septemberwk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0030BR',
          isDisabled: true,
        },
        septemberwk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        septemberwk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        fq1Total: {
          value: 1500,
          idProduct: 123,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 123,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0030BR',
        },
      },
      {
        origin: {
          value: 'JAG',
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        sab: {
          value: '250',
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        july: {
          value: 500,
          idProduct: 123,
          ka: 'VIVO_BR',
          enableInput: false,
          salesModel: 'PAJA0031BR',
        },
        julywk01: {
          value: 100,
          ka: 'VIVO_BR',
          idProduct: 123,
          enableInput: true,
          salesModel: 'PAJA0031BR',
        },
        julywk02: {
          value: 100,
          idProduct: 123,
          ka: 'VIVO_BR',
          enableInput: true,
          salesModel: 'PAJA0031BR',
        },
        julywk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        julywk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        julywk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        august: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        augustwk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        augustwk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        augustwk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        augustwk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        september: {
          value: 500,
          idProduct: 123,
          enableInput: false,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        septemberwk01: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        septemberwk02: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        septemberwk03: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        septemberwk05: {
          value: 100,
          idProduct: 123,
          enableInput: true,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        fq1Total: {
          value: 1500,
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 123,
          ka: 'VIVO_BR',
          salesModel: 'PAJA0031BR',
        },
      },
      {
        // 2º product
        origin: {
          value: 'JAG',
          idProduct: 456,
          salesModel: '-',
        },
        sab: {
          value: '250',
          idProduct: 456,
          salesModel: '-',
        },
        july: {
          value: 1000,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        julywk01: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        julywk02: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        julywk03: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        julywk04: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        julywk05: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        august: {
          value: 1000,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        augustwk01: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        augustwk02: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        augustwk03: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        augustwk04: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        augustwk05: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        september: {
          value: 1000,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
        },
        septemberwk01: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk02: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk03: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk04: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk05: {
          value: 200,
          idProduct: 456,
          style: { 'font-weight': 'bold' },
          salesModel: '-',
          enableMLS: true,
        },
        fq1Total: {
          value: 1000,
          idProduct: 456,
          salesModel: '-',
        },
        fq1Actual: {
          value: 1000,
          idProduct: 456,
          salesModel: '-',
        },
      },
      {
        origin: {
          value: 'JAG',
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        sab: {
          value: '250',
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        july: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        julywk01: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        julywk02: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        julywk03: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        julywk04: {
          value: 100,
          ka: 'MMICOM_BRA',
          idProduct: 456,
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        julywk05: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        august: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        augustwk02: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        augustwk03: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        augustwk04: {
          value: 100,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          enableInput: true,
          salesModel: 'PAJA0033BR',
        },
        augustwk05: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        september: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        septemberwk01: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        septemberwk02: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        septemberwk03: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        septemberwk05: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        fq1Total: {
          value: 750,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
        fq1Actual: {
          value: 500,
          idProduct: 456,
          ka: 'MMICOM_BRA',
          salesModel: 'PAJA0033BR',
        },
      },
      {
        origin: {
          value: 'JAG',
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        sab: {
          value: '250',
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        july: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        julywk01: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        julywk02: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        julywk03: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        julywk04: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        julywk05: {
          value: 100,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: true,
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        august: {
          value: 500,
          idProduct: 456,
          ka: 'RETAIL_BR',
          enableInput: false,
          salesModel: 'PAJA0034BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        augustwk02: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        augustwk03: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        augustwk04: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        augustwk05: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        september: {
          value: 500,
          idProduct: 456,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        septemberwk01: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        septemberwk02: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        septemberwk03: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        septemberwk05: {
          value: 100,
          idProduct: 456,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0034BR',
        },
        fq1Total: {
          value: 750,
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
        fq1Actual: {
          value: 500,
          idProduct: 456,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0034BR',
        },
      },
      {
        // 3º product
        origin: {
          value: 'MAO',
          idProduct: 789,
          salesModel: '-',
        },
        sab: {
          value: 250,
          idProduct: 789,
          salesModel: '-',
        },
        july: {
          value: 1000,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        julywk01: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        julywk02: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        julywk03: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        julywk04: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        julywk05: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        august: {
          value: 1000,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        augustwk01: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk02: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk03: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk04: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        augustwk05: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        september: {
          value: 1000,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
        },
        septemberwk01: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk02: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk03: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk04: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        septemberwk05: {
          value: 200,
          style: { 'font-weight': 'bold' },
          idProduct: 789,
          salesModel: '-',
          enableMLS: true,
        },
        fq1Total: {
          value: 3600,
          idProduct: 789,
          salesModel: '-',
        },
        fq1Actual: {
          value: 2400,
          idProduct: 789,
          salesModel: '-',
        },
      },
      {
        origin: {
          value: 'MAO',
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        sab: {
          value: '250',
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        july: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        julywk01: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          isDisabled: true,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        julywk02: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          isDisabled: true,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        julywk03: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          isDisabled: true,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        julywk04: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        julywk05: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        august: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        augustwk02: {
          value: 100,
          idProduct: 789,
          enableInput: true, // habilitar input para semana ativa de maio
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        augustwk03: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        augustwk04: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        augustwk05: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        september: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        septemberwk01: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        septemberwk02: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        septemberwk03: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          ka: 'RETAIL_BR',
          isDisabled: true,
          salesModel: 'PAJA0041BR',
        },
        septemberwk05: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          isDisabled: true,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        fq1Total: {
          value: 1800,
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
        fq1Actual: {
          value: 1200,
          idProduct: 789,
          ka: 'RETAIL_BR',
          salesModel: 'PAJA0041BR',
        },
      },
      {
        origin: {
          value: 'MAO',
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        sab: {
          value: '250',
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        july: {
          value: 500,
          idProduct: 789,
          enableInput: false,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        julywk01: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        julywk02: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        julywk03: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        julywk04: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        julywk05: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        august: {
          value: 500,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        augustwk01: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        augustwk02: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        augustwk03: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        augustwk04: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        augustwk05: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        september: {
          value: 500,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: false,
          salesModel: 'PAJA0042BR',
        },
        septemberwk01: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        septemberwk02: {
          value: 100,
          ka: 'TIM_BRA',
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        septemberwk03: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        septemberwk04: {
          value: 100,
          idProduct: 789,
          enableInput: true,
          salesModel: 'PAJA0042BR',
          ka: 'TIM_BRA',
        },
        septemberwk05: {
          value: 100,
          idProduct: 789,
          ka: 'TIM_BRA',
          enableInput: true,
          salesModel: 'PAJA0042BR',
        },
        fq1Total: {
          value: 1800,
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
        fq1Actual: {
          value: 1200,
          idProduct: 789,
          ka: 'TIM_BRA',
          salesModel: 'PAJA0042BR',
        },
      },
    ],
  },
};

export const responseAllocationFinal: { [key: string]: any } = {
  simpleTable: {
    headers: [
      {
        title: 'Product',
        props: 'product',
        subtitle: '',
      },
      {
        title: 'Sales Model',
        props: 'salesModel',
        subtitle: '',
      },
    ],
    rows: [
      {
        product: {
          value: 'XT2053 - FIJI SC 2/32 - ELECTRIC PINK',
          idProduct: 25754,
        },
        salesModel: {
          value: '-',
        },
        ka: {
          value: '-',
        },
        origin: {
          value: 'MAN',
        },
      },
      {
        product: {
          value: '',
          idProduct: 25754,
        },
        salesModel: {
          value: 'PANC0030BR',
        },
        ka: {
          value: 'MMICOM_BR',
          color: '#808080',
        },
        origin: {
          value: '-',
        },
      },
      {
        product: {
          value: '',
          idProduct: 25754,
        },
        salesModel: {
          value: 'PANC0030BR',
        },
        ka: {
          value: 'RETAIL_BRA',
          color: '#68bc00',
        },
        origin: {
          value: '-',
        },
      },
      {
        product: {
          value: 'XT2053 - FIJI SC 2/32 - ELECTRIC PINK',
          idProduct: 25755,
        },
        salesModel: {
          value: '-',
        },
        ka: {
          value: '-',
        },
        origin: {
          value: 'JAG',
        },
      },
      {
        product: {
          value: '',
          idProduct: 25755,
        },
        salesModel: {
          value: 'PANC0028BR',
        },
        ka: {
          value: 'CLARO_BR',
          color: '#d33115',
        },
        origin: {
          value: '-',
        },
      },
      {
        product: {
          value: '',
          idProduct: 25755,
        },
        salesModel: {
          value: 'PANC0001BR',
        },
        ka: {
          value: 'MMICOM_BR',
          color: '#808080',
        },
        origin: {
          value: '-',
        },
      },
      {
        product: {
          value: '',
          idProduct: 25755,
        },
        salesModel: {
          value: 'PANC0001BR',
        },
        ka: {
          value: 'RETAIL_BRA',
          color: '#68bc00',
        },
        origin: {
          value: '-',
        },
      },
      {
        product: {
          value: '',
          idProduct: 25755,
        },
        salesModel: {
          value: 'PANC0001BR',
        },
        ka: {
          value: 'VIVO_BRA',
          color: '#653294',
        },
        origin: {
          value: '-',
        },
      },
    ],
    expandedTable: {
      headers: [
        {
          title: 'Origin',
          props: 'origin',
          otherContent: 'origin',
          enableContent: false,
        },
        {
          title: 'SAB',
          props: 'sab',
          otherContent: 'origin',
          enableContent: false,
        },
        {
          title: 'Total Jan',
          props: 'january',
          otherContent: 'january',
          enableContent: false,
          currentMonth: false,
        },
        {
          title: 'WK 01',
          props: 'januarywk01',
          otherContent: 'january',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 02',
          props: 'januarywk02',
          otherContent: 'january',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 03',
          props: 'januarywk03',
          otherContent: 'january',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 04',
          props: 'januarywk04',
          otherContent: 'january',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 05',
          props: 'januarywk05',
          otherContent: 'january',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'Total Feb',
          props: 'february',
          otherContent: 'february',
          enableContent: false,
          currentMonth: false,
        },
        {
          title: 'WK 01',
          props: 'februarywk01',
          otherContent: 'february',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 02',
          props: 'februarywk02',
          otherContent: 'february',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 03',
          props: 'februarywk03',
          otherContent: 'february',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 04',
          props: 'februarywk04',
          otherContent: 'february',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 05',
          props: 'februarywk05',
          otherContent: 'february',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'Total Mar',
          props: 'march',
          otherContent: 'march',
          enableContent: false,
          currentMonth: true,
        },
        {
          title: 'WK 01',
          props: 'marchwk01',
          otherContent: 'march',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 02',
          props: 'marchwk02',
          otherContent: 'march',
          enableContent: false,
          weekInTime: 'previous',
        },
        {
          title: 'WK 03',
          props: 'marchwk03',
          otherContent: 'march',
          enableContent: true,
          weekInTime: 'current',
        },
        {
          title: 'WK 04',
          props: 'marchwk04',
          otherContent: 'march',
          enableContent: true,
          weekInTime: 'next',
        },
        {
          title: 'WK 05',
          props: 'marchwk05',
          otherContent: 'march',
          enableContent: true,
          weekInTime: 'next',
        },
        {
          title: 'FQ4 Total',
          props: 'fqTotal',
          otherContent: 'fqTotal',
          enableContent: true,
          hideIconExpand: true,
        },
        {
          title: 'FQ4 Actual',
          props: 'fqActual',
          otherContent: 'fqActual',
          enableContent: true,
          hideIconExpand: true,
        },
      ],
      rows: [
        {
          origin: {
            value: 'MAN',
            ka: '-',
            idProduct: 25754,
            salesModel: '-',
          },
          sab: {
            value: 0,
            ka: '-',
            idProduct: 25754,
            salesModel: '-',
          },
          january: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
          },
          januarywk01: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk02: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk03: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk04: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk05: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          fqTotal: {
            value: 0,
            idProduct: 25754,
          },
          fqActual: {
            value: 0,
            idProduct: 25754,
          },
          february: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
          },
          februarywk01: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk02: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk03: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk04: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk05: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          march: {
            value: 1,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
          },
          marchwk01: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          marchwk02: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          marchwk03: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: true,
          },
          marchwk04: {
            value: 0,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: true,
          },
          marchwk05: {
            value: 1,
            idProduct: 25754,
            ka: '-',
            salesModel: '-',
            enableInput: true,
          },
        },
        {
          origin: {
            value: '-',
            ka: 'MMICOM_BR',
            idProduct: 25754,
            materialId: 793,
            salesModel: 'PANC0030BR',
          },
          sab: {
            value: '-',
            ka: 'MMICOM_BR',
            idProduct: 25754,
            materialId: 793,
            salesModel: 'PANC0030BR',
          },
          january: {
            id: 77588,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
          },
          januarywk01: {
            id: 77588,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk02: {
            id: 77588,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk03: {
            id: 77588,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk04: {
            id: 77588,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk05: {
            id: 77588,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          fqTotal: {
            value: 1,
            idProduct: 25754,
          },
          fqActual: {
            value: 0,
            idProduct: 25754,
          },
          february: {
            id: 77909,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
          },
          februarywk01: {
            id: 77909,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk02: {
            id: 77909,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk03: {
            id: 77909,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk04: {
            id: 77909,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk05: {
            id: 77909,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          march: {
            id: 78365,
            value: 1,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
          },
          marchwk01: {
            id: 78365,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          marchwk02: {
            id: 78365,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          marchwk03: {
            id: 78365,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: true,
          },
          marchwk04: {
            id: 78365,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: true,
          },
          marchwk05: {
            id: 78365,
            value: 1,
            materialId: 793,
            idProduct: 25754,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0030BR',
            enableInput: true,
          },
        },
        {
          origin: {
            value: '-',
            ka: 'RETAIL_BRA',
            idProduct: 25754,
            materialId: 793,
            salesModel: 'PANC0030BR',
          },
          sab: {
            value: '-',
            ka: 'RETAIL_BRA',
            idProduct: 25754,
            materialId: 793,
            salesModel: 'PANC0030BR',
          },
          january: {
            id: 77543,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
          },
          januarywk01: {
            id: 77543,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk02: {
            id: 77543,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk03: {
            id: 77543,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk04: {
            id: 77543,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          januarywk05: {
            id: 77543,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          fqTotal: {
            value: 0,
            idProduct: 25754,
          },
          fqActual: {
            value: 0,
            idProduct: 25754,
          },
          february: {
            id: 78207,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
          },
          februarywk01: {
            id: 78207,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk02: {
            id: 78207,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk03: {
            id: 78207,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk04: {
            id: 78207,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          februarywk05: {
            id: 78207,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          march: {
            id: 78400,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
          },
          marchwk01: {
            id: 78400,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          marchwk02: {
            id: 78400,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: false,
          },
          marchwk03: {
            id: 78400,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: true,
          },
          marchwk04: {
            id: 78400,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: true,
          },
          marchwk05: {
            id: 78400,
            value: 0,
            materialId: 793,
            idProduct: 25754,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0030BR',
            enableInput: true,
          },
        },
        {
          origin: {
            value: 'JAG',
            ka: '-',
            idProduct: 25755,
            salesModel: '-',
          },
          sab: {
            value: 0,
            ka: '-',
            idProduct: 25755,
            salesModel: '-',
          },
          january: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
          },
          januarywk01: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk02: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk03: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk04: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          januarywk05: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          fqTotal: {
            value: 2099,
            idProduct: 25755,
          },
          fqActual: {
            value: 5,
            idProduct: 25755,
          },
          february: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
          },
          februarywk01: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk02: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk03: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk04: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          februarywk05: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          march: {
            value: 2099,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
          },
          marchwk01: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          marchwk02: {
            value: 5,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: false,
          },
          marchwk03: {
            value: 0,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: true,
          },
          marchwk04: {
            value: 2000,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: true,
          },
          marchwk05: {
            value: 94,
            idProduct: 25755,
            ka: '-',
            salesModel: '-',
            enableInput: true,
          },
        },
        {
          origin: {
            value: '-',
            ka: 'CLARO_BR',
            idProduct: 25755,
            materialId: 791,
            salesModel: 'PANC0028BR',
          },
          sab: {
            value: '-',
            ka: 'CLARO_BR',
            idProduct: 25755,
            materialId: 791,
            salesModel: 'PANC0028BR',
          },
          january: {
            id: 77829,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
          },
          januarywk01: {
            id: 77829,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          januarywk02: {
            id: 77829,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          januarywk03: {
            id: 77829,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          januarywk04: {
            id: 77829,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          januarywk05: {
            id: 77829,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          fqTotal: {
            value: 0,
            idProduct: 25755,
          },
          fqActual: {
            value: 0,
            idProduct: 25755,
          },
          february: {
            id: 77964,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
          },
          februarywk01: {
            id: 77964,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          februarywk02: {
            id: 77964,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          februarywk03: {
            id: 77964,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          februarywk04: {
            id: 77964,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          februarywk05: {
            id: 77964,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          march: {
            id: 78216,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
          },
          marchwk01: {
            id: 78216,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          marchwk02: {
            id: 78216,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: false,
          },
          marchwk03: {
            id: 78216,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: true,
          },
          marchwk04: {
            id: 78216,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: true,
          },
          marchwk05: {
            id: 78216,
            value: 0,
            materialId: 791,
            idProduct: 25755,
            ka: 'CLARO_BR',
            salesModel: 'PANC0028BR',
            enableInput: true,
          },
        },
        {
          origin: {
            value: '-',
            ka: 'MMICOM_BR',
            idProduct: 25755,
            materialId: 784,
            salesModel: 'PANC0001BR',
          },
          sab: {
            value: '-',
            ka: 'MMICOM_BR',
            idProduct: 25755,
            materialId: 784,
            salesModel: 'PANC0001BR',
          },
          january: {
            id: 77793,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
          },
          januarywk01: {
            id: 77793,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk02: {
            id: 77793,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk03: {
            id: 77793,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk04: {
            id: 77793,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk05: {
            id: 77793,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          fqTotal: {
            value: 99,
            idProduct: 25755,
          },
          fqActual: {
            value: 5,
            idProduct: 25755,
          },
          february: {
            id: 77952,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
          },
          februarywk01: {
            id: 77952,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk02: {
            id: 77952,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk03: {
            id: 77952,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk04: {
            id: 77952,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk05: {
            id: 77952,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          march: {
            id: 78252,
            value: 99,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
          },
          marchwk01: {
            id: 78252,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          marchwk02: {
            id: 78252,
            value: 5,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          marchwk03: {
            id: 78252,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
          marchwk04: {
            id: 78252,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
          marchwk05: {
            id: 78252,
            value: 94,
            materialId: 784,
            idProduct: 25755,
            ka: 'MMICOM_BR',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
        },
        {
          origin: {
            value: '-',
            ka: 'RETAIL_BRA',
            idProduct: 25755,
            materialId: 784,
            salesModel: 'PANC0001BR',
          },
          sab: {
            value: '-',
            ka: 'RETAIL_BRA',
            idProduct: 25755,
            materialId: 784,
            salesModel: 'PANC0001BR',
          },
          january: {
            id: 77757,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
          },
          januarywk01: {
            id: 77757,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk02: {
            id: 77757,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk03: {
            id: 77757,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk04: {
            id: 77757,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk05: {
            id: 77757,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          fqTotal: {
            value: 2000,
            idProduct: 25755,
          },
          fqActual: {
            value: 0,
            idProduct: 25755,
          },
          february: {
            id: 77940,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
          },
          februarywk01: {
            id: 77940,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk02: {
            id: 77940,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk03: {
            id: 77940,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk04: {
            id: 77940,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk05: {
            id: 77940,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          march: {
            id: 78288,
            value: 2000,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
          },
          marchwk01: {
            id: 78288,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          marchwk02: {
            id: 78288,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          marchwk03: {
            id: 78288,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
          marchwk04: {
            id: 78288,
            value: 2000,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
          marchwk05: {
            id: 78288,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'RETAIL_BRA',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
        },
        {
          origin: {
            value: '-',
            ka: 'VIVO_BRA',
            idProduct: 25755,
            materialId: 784,
            salesModel: 'PANC0001BR',
          },
          sab: {
            value: '-',
            ka: 'VIVO_BRA',
            idProduct: 25755,
            materialId: 784,
            salesModel: 'PANC0001BR',
          },
          january: {
            id: 77721,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
          },
          januarywk01: {
            id: 77721,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk02: {
            id: 77721,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk03: {
            id: 77721,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk04: {
            id: 77721,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          januarywk05: {
            id: 77721,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          fqTotal: {
            value: 0,
            idProduct: 25755,
          },
          fqActual: {
            value: 0,
            idProduct: 25755,
          },
          february: {
            id: 77929,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
          },
          februarywk01: {
            id: 77929,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk02: {
            id: 77929,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk03: {
            id: 77929,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk04: {
            id: 77929,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          februarywk05: {
            id: 77929,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          march: {
            id: 78327,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
          },
          marchwk01: {
            id: 78327,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          marchwk02: {
            id: 78327,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: false,
          },
          marchwk03: {
            id: 78327,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
          marchwk04: {
            id: 78327,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
          marchwk05: {
            id: 78327,
            value: 0,
            materialId: 784,
            idProduct: 25755,
            ka: 'VIVO_BRA',
            salesModel: 'PANC0001BR',
            enableInput: true,
          },
        },
      ],
    },
  },
  generalInformations: [
    {
      title: 'MMICOM_BR',
      subtitle: '1 Products',
      content: [
        {
          value: 'BR',
        },
      ],
      borderColor: '#808080',
    },
    {
      title: 'RETAIL_BRA',
      subtitle: '1 Products',
      content: [
        {
          value: 'BR',
        },
      ],
      borderColor: '#68bc00',
    },
    {
      title: 'CLARO_BR',
      subtitle: '1 Products',
      content: [
        {
          value: 'BR',
        },
      ],
      borderColor: '#d33115',
    },
    {
      title: 'VIVO_BRA',
      subtitle: '1 Products',
      content: [
        {
          value: 'BR',
        },
      ],
      borderColor: '#653294',
    },
  ],
  mlsLastUpdate: '03/15/2022 14:21',
};

export const responseCardTable: any = {
  fqCommit: {
    headers: [
      { prop: 'PRODUCT', key: 'product', styleClass: 'product' },
      { prop: 'DEMAND', key: 'demand', styleClass: 'demand' },
      { prop: 'MLS COMMIT', key: 'mlsCommit', styleClass: 'mlsCommit' },
      { prop: 'MLS COMMIT %', key: 'mlsCommitPer', styleClass: 'mlsCommitPer' },
    ],
    rows: [
      {
        product: 'Sierra Black',
        demand: '70%',
        mlsCommit: '7800',
        mlsCommitPer: '74%',
      },
      {
        product: 'Saipan Grey',
        demand: '70%',
        mlsCommit: '23800',
        mlsCommitPer: '30%',
      },
      {
        product: 'Kyoto Lagon',
        demand: '80%',
        mlsCommit: '23800',
        mlsCommitPer: '10%',
      },
    ],
  },
  delta: {
    headers: [
      { prop: 'PRODUCT', key: 'product', styleClass: 'product' },
      { prop: 'DEMAND', key: 'demand', styleClass: 'demand' },
      { prop: 'MLS COMMIT', key: 'mlsCommit', styleClass: 'mlsCommit' },
      { prop: 'MLS COMMIT %', key: 'mlsCommitPer', styleClass: 'mlsCommitPer' },
      { prop: 'DELTA', key: 'delta', styleClass: 'delta' },
    ],
    rows: [
      {
        product: 'Sierra Black',
        demand: '70%',
        mlsCommit: '7800',
        mlsCommitPer: '74%',
        delta: '-900',
      },
      {
        product: 'Saipan Grey',
        demand: '70%',
        mlsCommit: '23800',
        mlsCommitPer: '71%',
        delta: '1500',
      },
      {
        product: 'Kyoto Lagon',
        demand: '80%',
        mlsCommit: '23800',
        mlsCommitPer: '10%',
        delta: '2000',
      },
    ],
  },
};

export const expandedTable: any = {
  headers: [
    {
      title: 'Jan Vol Variation',
      props: 'janVolVariation',
      otherContent: 'janVolVariation',
      enableContent: true,
    },
    {
      title: 'Feb Vol Variation',
      props: 'febVolVariation',
      otherContent: 'febVolVariation',
      enableContent: true,
    },
    {
      title: 'Mar Vol Variation',
      props: 'marVolVariation',
      otherContent: 'marVolVariation',
      enableContent: true,
    },
    {
      title: 'Fq1 Vol Variation',
      props: 'fq1VolVariation',
      otherContent: 'fq1VolVariation',
      enableContent: true,
    },
  ],
  rows: [
    {
      janVolVariation: {
        value: 0,
      },
      febVolVariation: {
        value: 0,
      },
      marVolVariation: {
        value: 0,
      },
      fq1VolVariation: {
        value: 0,
      },
    },
    {
      janVolVariation: {
        value: 900,
      },
      febVolVariation: {
        value: 300,
      },
      marVolVariation: {
        value: 300,
      },
      fq1VolVariation: {
        value: 300,
      },
    },
    {
      janVolVariation: {
        value: 900,
      },
      febVolVariation: {
        value: 300,
      },
      marVolVariation: {
        value: 300,
      },
      fq1VolVariation: {
        value: 300,
      },
    },
    {
      janVolVariation: {
        value: 900,
      },
      febVolVariation: {
        value: 300,
      },
      marVolVariation: {
        value: 300,
      },
      fq1VolVariation: {
        value: 300,
      },
    },
    {
      janVolVariation: {
        value: 900,
      },
      febVolVariation: {
        value: 300,
      },
      marVolVariation: {
        value: 300,
      },
      fq1VolVariation: {
        value: 300,
      },
    },
    {
      janVolVariation: {
        value: 900,
      },
      febVolVariation: {
        value: 300,
      },
      marVolVariation: {
        value: 300,
      },
      fq1VolVariation: {
        value: 300,
      },
    },
  ],
};

export const responseColumnMarkerChart: any = {
  data: {
    source: [
      {
        month: 'June',
        values: [
          {
            product: 'GUAM+',
            color: 'Eletric Blue',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'GUAM+',
            color: 'Evergreen',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'GUAM',
            color: 'Misty Blue',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'GUAM',
            color: 'Twilight',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Malta 4/64',
            color: 'Ice Flow',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Malta 4/64',
            color: 'Water Red',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Cebu',
            color: 'Jasper',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Cebu',
            color: 'Jazz Blue',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Kiev',
            color: 'Molten Lava',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Malta Lite',
            color: 'Digital Blue',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Malta Lite',
            color: 'Oxy Red',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Capri',
            color: 'Aurora Gray',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Capri',
            color: 'Pearl',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Capri Plus',
            color: 'Phantom Black',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Capri Plus',
            color: 'Pastel Sky',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Taboe',
            color: 'Beryl',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            product: 'Taboe',
            color: 'Sky',
            brDemand: 8,
            mlsJune: 2,
          },
          {
            totalBrDemand: 'Total BR Demand',
            valueBrDemand: 240000,
          },
          {
            totalMls: 'Total MLS June',
            valueMls: 60000,
          },
        ],
      },
      {
        month: 'July',
        values: [
          {
            product: 'GUAM+',
            color: 'Eletric Blue',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'GUAM+',
            color: 'Evergreen',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'GUAM',
            color: 'Misty Blue',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'GUAM',
            color: 'Twilight',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Malta 4/64',
            color: 'Ice Flow',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Malta 4/64',
            color: 'Water Red',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Cebu',
            color: 'Jasper',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Cebu',
            color: 'Jazz Blue',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Kiev',
            color: 'Molten Lava',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Malta Lite',
            color: 'Digital Blue',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Malta Lite',
            color: 'Oxy Red',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Capri',
            color: 'Aurora Gray',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Capri',
            color: 'Pearl',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Capri Plus',
            color: 'Phantom Black',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Capri Plus',
            color: 'Pastel Sky',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Taboe',
            color: 'Beryl',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            product: 'Taboe',
            color: 'Sky',
            brDemand: 8,
            mlsJune: 4,
          },
          {
            totalBrDemand: 'Total BR Demand',
            valueBrDemand: 240000,
          },
          {
            totalMls: 'Total MLS June',
            valueMls: 80000,
          },
        ],
      },
      {
        month: 'August',
        values: [
          {
            product: 'GUAM+',
            color: 'Eletric Blue',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'GUAM+',
            color: 'Evergreen',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'GUAM',
            color: 'Misty Blue',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'GUAM',
            color: 'Twilight',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Malta 4/64',
            color: 'Ice Flow',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Malta 4/64',
            color: 'Water Red',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Cebu',
            color: 'Jasper',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Cebu',
            color: 'Jazz Blue',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Kiev',
            color: 'Molten Lava',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Malta Lite',
            color: 'Digital Blue',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Malta Lite',
            color: 'Oxy Red',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Capri',
            color: 'Aurora Gray',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Capri',
            color: 'Pearl',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Capri Plus',
            color: 'Phantom Black',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Capri Plus',
            color: 'Pastel Sky',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Taboe',
            color: 'Beryl',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            product: 'Taboe',
            color: 'Sky',
            brDemand: 8,
            mlsJune: 6,
          },
          {
            totalBrDemand: 'Total BR Demand',
            valueBrDemand: 240000,
          },
          {
            totalMls: 'Total MLS June',
            valueMls: 80000,
          },
        ],
      },
      {
        quarter: 'FQ1',
        values: [
          {
            product: 'GUAM+',
            color: 'Eletric Blue',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'GUAM+',
            color: 'Spring Pink',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'GUAM+',
            color: 'Evergreen',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'GUAM',
            color: 'Misty Blue',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'GUAM',
            color: 'Twilight',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Malta 4/64',
            color: 'Ice Flow',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Malta 4/64',
            color: 'Water Red',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Cebu',
            color: 'Jasper',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Cebu',
            color: 'Jazz Blue',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Kiev',
            color: 'Molten Lava',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Malta Lite',
            color: 'Digital Blue',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Malta Lite',
            color: 'Oxy Red',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Capri',
            color: 'Aurora Gray',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Capri',
            color: 'Pearl',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Capri Plus',
            color: 'Phantom Black',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Capri Plus',
            color: 'Pastel Sky',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Taboe',
            color: 'Beryl',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            product: 'Taboe',
            color: 'Sky',
            brDemand: 8,
            mlsJune: 8,
          },
          {
            totalBrDemand: 'Total BR Demand',
            valueBrDemand: 240000,
          },
          {
            totalMls: 'Total MLS June',
            valueMls: 24000,
          },
        ],
      },
    ],
  },
};
