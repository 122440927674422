<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">SALES MANAGEMENT</span>
  </div>
  <app-search (searchEvent)="onSearchEvent($event)" [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results [data]="result" (resultPaginator)="onChangePaginator($event)"></app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">BACK</button>
</div>
