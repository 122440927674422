import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef, Directive, ElementRef, HostListener, Input, OnInit,
} from '@angular/core';

import { CustomTooltipTableComponent } from '../components/custom-tooltip-table/custom-tooltip-table.component';

@Directive({
  selector: '[customTooltipTable]',
})
export class CustomTooltipTableDirective implements OnInit {
  @Input('customTooltipTable') tooltipTitle!: string;
  @Input('customTooltipTableSubtitle') tooltipSubtitle!: string;
  overlayRef!: OverlayRef;

  constructor(
private elementRef: ElementRef,
              private overlay: Overlay,
              private overlayPositionBuilder: OverlayPositionBuilder,
  ) { }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseenter') onMouseEnter() {
    const tooltipPortal = new ComponentPortal(CustomTooltipTableComponent);
    const tooltipRef: ComponentRef<CustomTooltipTableComponent> = this.overlayRef
      .attach(tooltipPortal);
    tooltipRef.instance.title = this.tooltipTitle;
    tooltipRef.instance.subtitle = this.tooltipSubtitle;
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }
}
