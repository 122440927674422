import { Component, OnInit } from '@angular/core';

import { BarCharColumnMockup } from './bar-chart-mockup';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  chartOption: any;
  barChartMockup = BarCharColumnMockup;
  dataSource: any[] = [];
  positive: any[] = [];
  negative: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.createChart();
    this.getdataSource();
  }

  getdataSource() {
    Object.entries(this.barChartMockup).forEach((data: any) => {
      data[1].dataSource.forEach((data: any) => {
        if (data.value > 0) {
          this.dataSource.push(data.name);
          this.positive.push(data.value);
          this.negative.push('');
        } else {
          this.dataSource.push(data.name);
          this.negative.push(data.value);
          this.positive.push('');
        }
      });
    });
  }

  createChart() {
    this.chartOption = {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        left: '25%',
        right: '7%',
        top: 12,
        height: '184px',
      },
      xAxis: {
        type: 'value',
        position: 'bottom',
        axisLine: { show: false },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },

      yAxis: {
        type: 'category',
        axisTick: { show: false },
        axisLabel: { color: '#303030' },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#e3e3e3',
          },
        },
        data: this.dataSource,
        inverse: true,
      },
      series: [
        {
          itemStyle: { color: '#60ccb2' },
          type: 'bar',
          barWidth: 30,
          stack: 'Total',
          label: {
            show: true,
            position: 'right',
            formatter: function (value: any, index: any) {
              return value.value.toLocaleString();
            },
          },
          data: this.positive,
        },
        {
          itemStyle: { color: '#d9332b' },
          type: 'bar',
          barWidth: 30,
          stack: 'Total',
          label: {
            show: true,
            position: 'right',
            formatter: function (value: any, index: any) {
              return value.value.toLocaleString();
            },
          },
          data: this.negative,
        },
      ],
    };
  }
}
