<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">KA MANAGEMENT</span>
    <button
      *ngIf="hasPermissionCreate"
      class="button modal-action-button"
      mat-button
      (click)="prepareToCreate()">
      NEW KA
    </button>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <mat-divider class="divider"></mat-divider>
  <app-results
    *ngIf="!showImageNoResults"
    [data]="result"
    [isModeTableOrCardsHide]="true"
    (eventActions)="onEventActions($event)"
    (resultPaginator)="onChangePaginator($event)">
  </app-results>
  <div class="no-filter-selected-session" *ngIf="showImageNoResults">
    <p>NO RECORDS FOUND</p>
  </div>
  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>
<ng-template #modalCreateEdit class="custom-dialog-container">
  <header class="title-modal" matDialogTitle>
    {{ isEdit ? 'EDIT' : 'NEW' }} KEY ACCOUNT
  </header>
  <mat-dialog-content class="mat-dialog-content-ka-management">
    <ng-template [ngIf]="isEdit" [ngIfElse]="templateKACreate">
      <mat-tab-group animationDuration="0ms" class="mat-tab-group-ka">
        <mat-tab label="KA">
          <ng-container *ngTemplateOutlet="templateKACreate"></ng-container>
        </mat-tab>
        <mat-tab *ngIf="ka !== 'RETAIL_BRA'" label="{{ ka }} PRODUCTS">
          <form [formGroup]="kaForm" class="ka-products-form">
            <div class="inputs-ka-management-claro-products">
              <mat-form-field
                *ngFor="let input of searchSalesModelInputs"
                class="edit-products-ka-management"
                appearance="outline">
                <mat-label>{{ input.hover }}</mat-label>
                <mat-icon class="material-icons align-arrow-right"
                  >arrow_drop_down</mat-icon
                >
                <input
                  type="text"
                  matInput
                  [formControlName]="input.key"
                  [name]="input.key"
                  [matAutocomplete]="templateInputAutocomplete"
                  (keyup)="reloadTableProducts(kaForm.controls[input.key])"
                  (keydown.enter)="onFilterEvent(this.kaForm.value)" />
                <mat-autocomplete
                  #autoCompleteProduct
                  autoActiveFirstOption
                  #templateInputAutocomplete="matAutocomplete">
                  <div (click)="onFilterEvent(this.kaForm.value)">
                    <mat-option
                      *ngFor="let opt of input.filteredOptions | async"
                      [value]="opt.value"
                      matTooltip="{{ opt.value }}">
                      {{ opt.value || '' }}
                    </mat-option>
                  </div>
                </mat-autocomplete>
                <button
                  *ngIf="kaForm.controls[input.key].value"
                  matSuffix
                  mat-icon-button
                  type="button"
                  aria-label="Clear"
                  (click)="clearFilter(kaForm.controls[input.key])">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <app-table
              class="change-table"
              [table]="dataTable"
              (callActions)="onCallActions($event)">
            </app-table>
            <footer class="footer-table">
              <div class="in-activate-link">
                <button
                  class="button modal-action-button"
                  mat-button
                  *ngIf="showInactivateLink"
                  (click)="inactivateProducts()">
                  INACTIVATE
                </button>
                <button
                  class="button modal-action-button"
                  mat-button
                  *ngIf="showActivateLink"
                  (click)="activateProducts()">
                  ACTIVATE
                </button>
              </div>
              <mat-paginator
                class="container-pagination-ka-management"
                [pageSizeOptions]="sizeOptions"
                [pageIndex]="productsResult?.pageIndex"
                [length]="productsResult?.pageSize"
                (page)="onChangeProductsPaginator($event)"
                showFirstLastButtons>
              </mat-paginator>
            </footer>
          </form>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      CANCEL
    </button>
    <button
      *ngIf="!isEdit"
      class="button modal-action-button"
      mat-button
      (click)="onCreate()">
      SAVE
    </button>
    <button
      *ngIf="isEdit"
      class="button modal-action-button"
      mat-button
      (click)="onUpdate()">
      SAVE
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #templateKACreate>
  <form [formGroup]="kaForm" class="ka-form">
    <mat-form-field appearance="outline">
      <mat-label>Country</mat-label>
      <mat-icon class="material-icons align-arrow-right"
        >arrow_drop_down</mat-icon
      >
      <input
        type="text"
        formControlName="countryId"
        matInput
        isDisabled
        [matAutocomplete]="countryAutocomplete" />
      <mat-autocomplete
        isDisabled
        #countryAutocomplete="matAutocomplete"
        autoActiveFirstOption
        disableOptionCentering
        [disableRipple]="true"
        panelClass="panel-select-country">
        <mat-option
          disabled
          *ngFor="let country of countryFilteredOptions | async"
          [value]="country.country"
          matTooltip="{{ country.country }}">
          {{ country.country }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>KA</mat-label>
      <input matInput formControlName="ka" maxlength="70" autocomplete="off" />
    </mat-form-field>
    <div class="input-color-picker">
      <mat-form-field appearance="outline" (click)="toggleColorPicker()">
        <mat-label>Color Pick</mat-label>
        <input
          matInput
          readonly
          value="Select"
          formControlName="colorHex"
          class="color-picker"
          [style.background]="initialColor"
          [class.color-transparent]="colorSelected"
          (blur)="closeColorPicker($event)" />
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>
      <color-compact
        *ngIf="colorPickerVisible"
        [color]="initialColor"
        (onChange)="changeColor($event)">
      </color-compact>
    </div>
    <mat-form-field appearance="outline" *ngIf="isEdit">
      <mat-label>Status</mat-label>
      <mat-select
        formControlName="status"
        name="status"
        disableOptionCentering
        panelClass="panel-select-ka-management-edit-status">
        <mat-option *ngFor="let status of statusEntities" [value]="status.id">
          {{ status.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="isEdit && ka !== 'RETAIL_BRA'">
      <mat-label>Order Admin</mat-label>
      <mat-select
        formControlName="order_admin_id"
        name="order_admin_id"
        disableOptionCentering
        panelClass="panel-select-ka-management-edit-order-admin"
        [disabled]="ka === 'RETAIL_BRA'">
        <mat-option
          *ngFor="let orderAdmin of orderAdminOptions"
          [value]="orderAdmin.value">
          {{ orderAdmin.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="isEdit && ka !== 'RETAIL_BRA'">
      <mat-label>Sales Manager</mat-label>
      <mat-select
        formControlName="sales_manager_id"
        name="sales_manager_id"
        disableOptionCentering
        panelClass="panel-select-ka-management-edit-sales-manager"
        [disabled]="ka === 'RETAIL_BRA'">
        <mat-option
          *ngFor="let salesManager of salesManagerOptions"
          [value]="salesManager.value">
          {{ salesManager.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>{{ messageConfirmDialog }}</mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
