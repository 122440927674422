import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from '../../services/generic/sharedinfo.service';
import { pagesBrazil, pagesEmea } from './pages';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  cardsList: CardModule[] = [];
  pages: any[] = pagesBrazil;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }

  ngOnInit() {
    if (this.isAuthorized && this.userPermission.length > 0) {
      let [user] = this.userPermission;

      if (user.geo === 'EMEA') {
        this.pages = pagesEmea;
      }

      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'SETUP',
          this.pages
        );
      });

      if (user.country === 'BRAZIL') {
        this.sharedInfoService.getPermissionStorage().subscribe((permission: any) => {
          this.userPermission = permission;
          if (this.permissionService.enableMudulesByPermissions(this.pages).sort()) {
            this.itemsList.push(...this.permissionService.enableMudulesByPermissions(this.pages).sort());
          }
        })
      }

      this.cardsList = this.itemsList.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      this.router.navigate(['authorization']);
    }
  }

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
