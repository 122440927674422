<div class="table-body">
  <table #simpleTable mat-table [dataSource]="dataSource" class="simple-table">
    <ng-container
      *ngFor="let colDef of columnsLabel; let i = index; trackBy: trackByIndex">
      <ng-container
        *ngIf="colDef.sticky && !colDef.wowVariation"
        [matColumnDef]="colDef.label">
        <div
          *ngIf="colDef.label !== 'actions' && colDef.label !== 'permissions'">
          ''
          <div *ngIf="colDef.allowSelection; else columnTitleBlock">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
          </div>
          <ng-template #columnTitleBlock>
            <th
              mat-header-cell
              [class.align-column-and-row]="
                !hasStatus &&
                !hasComma &&
                hasCalculation &&
                colDef.prop !== 'marketName' &&
                colDef.prop !== 'product'
              "
              [class.align-columns]="
                !hasStatus &&
                !hasComma &&
                !hasCalculation &&
                colDef.prop !== 'product' &&
                colDef.prop !== 'dataType'
              "
              *matHeaderCellDef
              [ngStyle]="colDef.style">
              <span [innerHTML]="breakTitleColumns(colDef.label)"></span>
            </th>
          </ng-template>
          <div *ngIf="colDef.allowSelection; else columnDataBlock">
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? checkboxToggle(element) : null"
                [checked]="selection.isSelected(element)"
                [aria-label]="checkboxLabel(element)">
              </mat-checkbox>
            </td>
          </div>
          <ng-template #columnDataBlock>
            <td
              mat-cell
              *matCellDef="let element"
              [ngStyle]="colDef.style"
              [class.product-line]="colDef.prop === 'product'">
              <label
                *ngIf="hasStatus"
                [class.status-disable]="
                  element['active'] === 'Inactive' || !element['active']
                ">
                {{ colDef.prop ? element[colDef.prop] : element[colDef.label] }}
              </label>
              <label *ngIf="!hasStatus && !hasComma && hasCalculation">
                <app-value-currency
                  [class.negative-value]="element[colDef.prop]?.value < 0"
                  [value]="
                    colDef.prop
                      ? element[colDef.prop].value
                      : element[colDef.label].value
                  "></app-value-currency>
              </label>
              <label *ngIf="!hasStatus && hasComma">
                <app-value-currency
                  [class.negative-value]="element[colDef.prop] < 0"
                  [value]="element[colDef.prop]"></app-value-currency>
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef="let element">
              <span
                class="table-row-total-spacer"
                *ngIf="
                  colDef.prop === 'marketName' || colDef.prop === 'product'
                "
                >TOTAL</span
              >
              <span>
                <app-value-currency
                  [class.negative-value]="colDef.totalAddedValue < 0"
                  [value]="
                    colDef.totalAddedValue === '' ? '-' : colDef.totalAddedValue
                  "></app-value-currency>
              </span>
            </td>
          </ng-template>
        </div>
        <div *ngIf="colDef.label === 'permissions'">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <img
              class="icon-permission"
              src="../../../assets/images/ico-permission.svg"
              (click)="systemFunction(element)"
              matTooltip="Permissions"
              matTooltipPosition="left" />
          </td>
        </div>
        <div *ngIf="colDef.delete || colDef.edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <img
              *ngIf="colDef.edit && !element.editNotActive"
              class="icon-action"
              src="../../../assets/images/ico-edit.svg"
              (click)="editEvent(element)"
              matTooltip="Edit"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.edit && element.editNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/ico-edit-disable.svg"
              matTooltip="{{ tooltipMessage }}"
              matTooltipPosition="left" />
            <img
              *ngIf="colDef.delete && !element.deleteNotActive"
              class="icon-action"
              src="../../../assets/images/icon-delete.svg"
              (click)="deleteEvent(element)"
              matTooltip="Delete"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.delete && element.deleteNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/icon-delete-disabled.svg" />
          </td>
        </div>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <div *ngIf="!tableGrouping">
      <tr
        mat-row
        class="row-color"
        [class.brand]="i % 2 !== 0"
        *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </div>
    <div *ngIf="tableGrouping">
      <tr
        mat-row
        class="row-color"
        class="example-element-row"
        *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </div>
    <div *ngIf="(!hasStatus && !hasComma && hasCalculation) || hasTotalRow">
      <tr
        mat-footer-row
        class="table-row-total"
        *matFooterRowDef="displayedColumns"></tr>
    </div>
  </table>
</div>
