import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Notifications } from 'src/app/interfaces/notifications.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { ExcelService } from 'src/app/services/generic/excel.service';
import { NotificationService } from 'src/app/services/generic/notification.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { ResultInterface } from '../results/results.interface';
import { dcmDataTables, dcmExportComponent } from 'src/app/interfaces/dcm-alert.interface';

@Component({
  selector: 'app-table-notifications',
  templateUrl: './table-notifications.component.html',
  styleUrls: ['./table-notifications.component.scss'],
})

export class TableNotificationsComponent implements OnChanges {
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
  | TemplateRef<any>
  | any;

  @Output() readEvent = new EventEmitter<string>();

  @Input() dashboardNotification!: any;
  @Input() sidebarNotifications!: boolean;
  @Output() customEvent = new EventEmitter<boolean>();
  @Input() dataNotifications!: MatTableDataSource<any>;
  @Input() pageNotification!: MatTableDataSource<any>;
  @Input() totalRowsNotification!: MatTableDataSource<any>;
  @Input() limitNotification!: MatTableDataSource<any>;
  @Output('resultPaginator') resultPaginator = new EventEmitter();
  displayedColumnsFilterTable: string[] = ['notifications'];
  crudService: CrudService<any>;
  notificationsCrudService: CrudService<any>;
  hasData!: boolean;
  messageNot!: string;
  modalNotificationViewDetails!: any;

  exportResult!: ResultInterface;

  page!: number;
  totalRows!: number;
  limit!: number;

  constructor(
    private router: Router,
    private http: HttpClient, 
    private spinner: NgxSpinnerService, 
    private notificationService: NotificationService,
    private modalNotification: ModalActionsService,
    private toastr: ToastrService,
    private excelService: ExcelService
  ) {
    this.crudService = new CrudService<any>(this.http, 'dcm');
    this.notificationsCrudService = new CrudService<Notifications>(
      this.http,
      'notifications'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageNotification) {
      this.page = changes.pageNotification.currentValue - 1;
      this.totalRows = changes.totalRowsNotification.currentValue;
      this.limit = changes.limitNotification.currentValue;
    }
    
    if (changes.dataNotifications.currentValue.filteredData.length > 0) {
      if (changes.dataNotifications.currentValue.filteredData[0].message === undefined) {
        this.hasData = true;

      } else {
        this.messageNot = changes.dataNotifications.currentValue.filteredData[0].message;
      }
    }
  }

  ngAfterViewInit() {}

  viewDetails(data: any) {
    this.modalNotification.createConfirm(this.dialogConfirmation);
    this.modalNotificationViewDetails = data;

    if (typeof this.modalNotificationViewDetails.subtitleComplete == 'object') {
      this.modalNotificationViewDetails.subtitleComplete.forEach((item: any) => {
        
        if (Array.isArray(item.content)) {
          return item.content = item.content.join(', ')
        }
      })

      let dataExport = [
        this.modalNotificationViewDetails.subtitleComplete[4],
        this.modalNotificationViewDetails.subtitleComplete[5],
        this.modalNotificationViewDetails.subtitleComplete[9]
      ]

      this.exportComponents(dataExport);
    }
  }

  elementRead(element: any) {
    this.transformToRead(element)
  }

  exportComponents(dataMaterials: dcmExportComponent[]) {
    this.exportResult = {
      table: {
        columns: [
          { label: 'diverging so numbers in bigquery', prop: 'divergigSoBigQuery' },
          { label: 'diverging so numbers in local database', prop: 'divergigSoLocalDatabase' },
          { label: 'canceled so numbers in bigquery', prop: 'canceledSoBigQuery' },
        ],
        rows: this.dataTables(dataMaterials)
      },
    };
  }

  dataTables(rows: dcmExportComponent[]) {
    const dataTables: any[] = [];
    let arrayNumbersDivergigBQ: dcmDataTables[] = [];
    let arrayNumbersDivergigDB: dcmDataTables[] = [];
    let arrayNumbersCanceledBQ: dcmDataTables[] = [];


    rows.forEach((item: any) => {
      if (item.title === 'Diverging SO numbers in BigQuery') {
        if (item.content.length === 0) {
          arrayNumbersDivergigBQ = [{ divergigSoBigQuery: '' }]
        } else {
          arrayNumbersDivergigBQ = item.content.split(', ').map((number: any) => ({ divergigSoBigQuery: parseInt(number, 10) }));
        }
      }
      
      if (item.title === 'Diverging SO numbers in Local Database') {
        if (item.content.length === 0) {
          arrayNumbersDivergigDB = [{ divergigSoLocalDatabase: '' }]
        } else {
          arrayNumbersDivergigDB = item.content.split(', ').map((number: any) => ({ divergigSoLocalDatabase: parseInt(number, 10) }));    
        }
      }

      if (item.title === 'Canceled SO numbers in BigQuery') {
        if (item.content.length === 0) {
          arrayNumbersCanceledBQ = [{ canceledSoBigQuery: '' }];
        } else {
          arrayNumbersCanceledBQ = item.content.split(', ').map((number: string) => ({ canceledSoBigQuery: parseInt(number, 10) }));
        }
      }
    })

    const arrayUnique = this.joinArrays(arrayNumbersDivergigBQ, arrayNumbersDivergigDB,arrayNumbersCanceledBQ);

    dataTables.push(arrayUnique);

    return [...dataTables]
  }

  joinArrays(arrayDivergigBQ: any[], arrayDivergigDB: any[], arrayCanceledBQ: any[]): any[] {
    const resultado: dcmDataTables[] = [];
    const tamanhoMaximo = Math.max(arrayDivergigBQ.length, arrayDivergigDB.length, arrayCanceledBQ.length);

    for (let i = 0; i < tamanhoMaximo; i++) {
      const obj: any = {};

      if (i < arrayDivergigBQ.length) {
          const keyArrayDivergigBQ = Object.keys(arrayDivergigBQ[i])[0];
          obj[keyArrayDivergigBQ] = arrayDivergigBQ[i][keyArrayDivergigBQ];
      } else {
          obj.divergigSoBigQuery = '';
      }

      if (i < arrayDivergigDB.length) {
          const keyArrayDivergigDB = Object.keys(arrayDivergigDB[i])[0];
          obj[keyArrayDivergigDB] = arrayDivergigDB[i][keyArrayDivergigDB];
      } else {
          obj.divergigSoLocalDatabase = '';
      }

      if (i < arrayCanceledBQ.length) {
          const keyArrayCanceledBQ = Object.keys(arrayCanceledBQ[i])[0];
          obj[keyArrayCanceledBQ] = arrayCanceledBQ[i][keyArrayCanceledBQ];
      } else {
          obj.canceledSoBigQuery = '';
      }
      

      resultado.push(obj);
    }
    return resultado;
  }
  
  viewPage(data: any) {
    const dataNotificationId = {notificationId: data.id}

    if (data.tag !== 'ingestion') {
      const promises = [this.putRead(data.id, dataNotificationId), this.goToPage(data)];
      this.hideSpinnerAfterAll(promises);
    } 
  }

  archived(data: any) {
    const dataNotificationId = {notificationId: data.id}

    const promises = [this.putArchived(data.id, dataNotificationId)];
    this.hideSpinnerAfterAll(promises);
  }

  unarchived(data: any) {
    const dataNotificationId = {notificationId: data.id}

    const promises = [this.putUnarchived(data.id, dataNotificationId)];
    this.hideSpinnerAfterAll(promises);
  }

  transformToRead(element: any) { 
    if (element.read === false) {
      if (this.dashboardNotification === true) {
        this.spinner.show();
      }

      const dataNotificationId = {notificationId: element.id}
  
      this.crudService.putEntity(`dcm-notifications/read/${element.id}`, dataNotificationId).subscribe((response: any) => {
        if (response.message) {
          this.readEvent.emit('readed');
          this.toastr.success('Successfully!', 'Notification marked as readed')
          this.sendEmit();
        }
      })
    }
  }

  async putRead(id: number, formData: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.putEntity(`dcm-notifications/read/${id}`, formData).subscribe((response: any) => {
        if (response.message) {
          this.sendEmit();
        }
      })
      resolve();
    })
  }

  async putArchived(id: number, formData: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.putEntity(`dcm-notifications/archive/${id}`, formData).subscribe((response: any) => {
        if (response.message) {
          this.toastr.success('Successfully!', 'Notification marked as archived')
          this.sendEmit();
        }
      })
      resolve();
    })
  }

  async putUnarchived(id: number, formData: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.putEntity(`dcm-notifications/unarchive/${id}`, formData).subscribe((response: any) => {
        if (response.message) {
          this.toastr.success('Successfully!', 'Notification marked as unarchived')
          this.sendEmit();
        }
      })
      resolve();
    })
  }

  async goToPage(notification: any): Promise<void> {
    const customerNumberExtract: any = notification.subtitleResume;
    const regex: any = /\d+/;
    const match = customerNumberExtract.match(regex);
    const result = match ? match[0] : null;
    let link = notification.url;
    let queryParams: any = {};
    if (notification.url.includes('?')) {
      const linkSplit = link.split('?');
      link = linkSplit[0];
      const paramsArray = linkSplit[1].split('&');
      for (let i = 0; i < paramsArray.length; i++) {
        const param = paramsArray[i].split('=');
        queryParams[param[0]] = param[1];
      }
    }

    if (result != null) {
      queryParams = { customerNumber: match[0] };
    }

    this.notificationsCrudService
    .updateEntity(notification.id, '')
    .subscribe(response => {
      this.notificationService.deleteNotificationStorage(notification);
      this.router.navigate([link, { tab: notification.route }], {
        queryParams: queryParams,
      });
      this.spinner.hide();
    });
  }

  async sendEmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customEvent.emit(true)
      resolve()
    })
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {    
    if (event.target instanceof HTMLElement) {
      if (event.target.className.includes('cdk-overlay-backdrop')) {
        this.modalNotification.closeAll()

        if (this.modalNotificationViewDetails !== undefined) {
          this.elementRead(this.modalNotificationViewDetails);
        } 
      }
    }
  }

  isSubtitleArray(): boolean {
    return Array.isArray(this.modalNotificationViewDetails.subtitleComplete);
  }

  isSubtitleArrayLarge(value: any): boolean {
    return value.length > 65;
  }

  removeDuplicatedObj(array: dcmDataTables[]): dcmDataTables[] {
    const keySet = new Set<string>();
    const result: dcmDataTables[] = [];

    array.forEach(obj => {
        const key = `${obj.divergigSoBigQuery}-${obj.divergigSoLocalDatabase}-${obj.canceledSoBigQuery}`;
        if (!keySet.has(key)) {
            keySet.add(key);
            result.push(obj);
        }
    });

    return result;
  }

  prepareExportTable(data: any) {
    const rows = data.table.rows;
    const columns = data.table.columns.map((item: any) => ({
      title: item.label,
      props: item.prop,
    }));

    const tableRows: { [key: string]: any }[] = [];

    rows[0].forEach((rows: string) => {
      const tableRow: { [key: string]: any } = {};

      columns.forEach((column: any) => {
        tableRow[column.props] = rows[column.props]        
        tableRows.push(tableRow);
      });
    });

    return { columns: columns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.exportResult);
    const arrayNoRep = this.removeDuplicatedObj(fullJsonExport.rows);
    fullJsonExport.rows = arrayNoRep;
    this.excelService.exportJsonAsExcelFile(fullJsonExport, 'order_book_fail_reduction');
  }

  handlePaginator(event: any) {
    this.resultPaginator.emit({ 
      limit: event.pageSize, 
      page: event.pageIndex + 1,  
      archived: this.dataNotifications.filteredData[0].archived,
      read: this.dataNotifications.filteredData[0].read,
      all: this.dataNotifications.filteredData[0].viewAll
    });
  }

}
