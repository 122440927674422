import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { TerritoryAllocation } from 'src/app/interfaces/territory-allocation.interface';
import { ProductInterface } from 'src/app/interfaces/product.interface';
import { ExcelService } from '../../../services/generic/excel.service';
import { UtilitiesService } from 'src/app/services/generic/utilities.service';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-territory-allocation',
  templateUrl: './territory-allocation.component.html',
  styleUrls: ['./territory-allocation.component.scss'],
  animations: [
    trigger('hideShowTableCollapse', [
      state(
        'hide',
        style({
          height: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'show',
        style({
          'overflow-y': 'hidden',
        })
      ),
      transition('hide <=> show', [animate('0.4s 0.1s ease-out')]),
    ]),
  ],
})
export class TerritoryAllocationComponent implements OnInit {
  scrollableList: any = [];
  elHover = null;

  @HostListener('mouseover', ['$event'])
  onMouseOver(evt: any) {
    this.elHover =
      this.scrollableList.find(
        (scrollable: any) => scrollable === evt.target
      ) || null;
  }
  @HostListener('mouseout', ['$event'])
  onMouseOut(evt: any) {
    this.elHover = null;
  }

  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: TemplateRef<any> | any;
  searchInputs!: InputBase<string>[];
  showNoResults = false;
  showInfoTable = false;
  noFilterSelected = true;
  hasPermissionCreate!: boolean;
  hasPermissionEdit!: boolean;
  geosEntity: any[] = [];
  productFilterEntity: ProductInterface[] = [];
  productFilter: any[] = [];

  currentColorId: any[] = [];
  product_color_id: any;
  product_memory_id: any;
  currentMemoryId: any[] = [];
  simFilterEntity: any[] = [];
  summedValue: any[] = [];

  crudService: CrudService<any>;
  putCrudService: any;
  putPullInCrudService: any;
  putSabCrudService: any;
  productCrudService: CrudService<ProductInterface>;

  pagination: Pagination;
  filter: any = {};
  keysFilters = ['product', 'product_color_id', 'product_memory_id', 'sim'];
  enableApplyFilter = false;
  exportResult!: any;
  resultTerritoryAllocation: any;
  originTerritory: any;

  tooltipMessage: String = 'Export';
  geo: any;
  productGroup: any;
  colorFilter: any[] = [];
  memoryFilter: any[] = [];
  simFilter: any[] = [];
  isValid: any = false;
  fieldsValid: any[] = [];

  zoomIn: boolean = false;
  eventTable!: string;
  toggleColumnTableValues: any[] = [];

  productId: any;

  dataSourceTable: any[] = [];
  tableResult!: any;

  valuesSubstraction: { col: string; value: number }[] = [];
  valuesDelta: { col: string; value: number }[] = [];
  pullInOrIncreaseValue: any = 0;

  filteredOptionsGeo!: Observable<any[]>;
  filterProductSelected: any = '--';
  currentUser!: any;
  formSearchValues!: { [key: string]: any };
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.territoryAllocation },
    },
    {
      entity: 'emea-allocation/summary/product',
      query: {
        sort: 'product_group',
        pageName: PAGE_NAME_RESOURCE.territoryAllocation,
      },
    },
  ];
  pullInOrIncreaseValues: { label: string; mode: boolean; value: number }[] =
    [];
  showTableCollapse: boolean = false;
  next: boolean = false;
  isPullIn: boolean = true;
  isEditTableMode: boolean = false;
  messageModalChanges!: string;
  isDiscardChanges: boolean = false;
  filterMonthsSelected: string = 'quarter';
  filterMonths: any[] = [];
  messages: {
    [key: string]: string;
  } = {
    save: 'Do you confirm new changes on territory allocation?',
    confirm:
      'There are allocations associated with the Territory. If you continue, the allocations of orders will be reset. Do you want to continue with the update?',
    discard: 'Are you sure you want to exit before saving your changes?',
    noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
  };

  dataTableInformation!: any;
  headerInfoTable: any = [];
  rowsInfoTable: any = [];
  headerTable: any = [];
  rowsTable: any = [];
  clickScreenSub!: Subscription;

  constructor(
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private authService: AuthService,
    private modalService: ModalActionsService,
    private excelService: ExcelService,
    private elRef: ElementRef,
    private utilitiesService: UtilitiesService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.pagination = { page: 0, size: 10 };
    this.productCrudService = new CrudService<ProductInterface>(
      this.http,
      'product'
    );
    this.crudService = new CrudService<any>(this.http, 'location');
    this.putCrudService = new CrudService<TerritoryAllocation>(
      this.http,
      'emea-allocation'
    );
    this.putSabCrudService = new CrudService<TerritoryAllocation>(
      this.http,
      'emea-allocation/sab'
    );
    this.putPullInCrudService = new CrudService<TerritoryAllocation>(
      this.http,
      'emea-allocation/one-plan/opportunity'
    );

    this.sharedInfoService
      .getPermissionStorage()
      .subscribe(
        () =>
          (this.hasPermissionCreate =
            this.permissionService.setButtonCreatePermission(
              'territory-allocation'
            ))
      );
    this.sharedInfoService
      .getPermissionStorage()
      .subscribe(
        () =>
          (this.hasPermissionEdit =
            this.permissionService.setButtonEditPermission(
              'territory-allocation'
            ))
      );

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      quarter: this.getQuarterFromDate(new Date()),
    };
    this.filter = {
      ...this.filter,
      fiscalYear: this.checkCurrentYearAndQuarter(),
      geoId: this.currentUser.geoId,
    };

    this.searchInputs = [
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'product_group',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];
  }

  ngOnInit(): void {
    this.loadData(this.filterMonthsSelected);
    this.scrollableList = Array.from(
      (this.elRef.nativeElement as HTMLElement).getElementsByClassName(
        'scrollable'
      )
    );
    this.clickScreenSub = this.utilitiesService.documentClickedTarget.subscribe(
      target => this.verifyClickChangeRoute(target)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTableInformation?.currentValue) {
      this.dataTableInformation = changes.dataTableInformation.currentValue;
    }
    if (changes.tableResult?.currentValue) {
      this.tableResult = changes.tableResult.currentValue;
      this.exportComponents();
    }
  }

  ngAfterViewInit() {
    this.toggleBreadcrumb(false);
    this.clickScreenSub = this.utilitiesService.documentClickedTarget.subscribe(
      (target: any) => this.verifyClickChangeRoute(target)
    );
  }

  ngOnDestroy() {
    this.toggleBreadcrumb(false);
    this.clickScreenSub.unsubscribe();
  }

  updateScroll(evt: any) {
    const elHover = evt.target;
    const percentage =
      elHover.scrollTop / (elHover.scrollHeight - elHover.offsetHeight);
    this.scrollableList
      .filter((scrollable: any) => scrollable !== this.elHover)
      .forEach((scrollable: any) => {
        scrollable.scrollTop =
          percentage * (scrollable.scrollHeight - scrollable.offsetHeight);
      });
  }

  loadData(filter?: string) {
    this.getDataFilter(this.entities);
    this.getQuarter(new Date());
  }

  loadInitialValues() {
    this.initialTableValues();
    this.AddingAllWeeksValues();
    this.initialInfoDeltaValues();
    this.updateInfoTableValues();
  }

  getData(filter?: string) {
    this.getTerritoryAllocation(this.filterMonthsSelected, filter);
  }

  verifyPermissionEdit(data: any) {
    const userPermission = this.authService.getUserPermissions();
    const canEditAllocation = userPermission.some(
      (permission: any) =>
        permission.resource === 'territory-allocation' &&
        (permission.permission === 'UPDATE' ||
          permission.permission === 'CREATE')
    );

    if (!canEditAllocation) {
      const currentMonthOrWeek = (header: any) =>
        header.currentMonth || header.weekInTime || header.props === 'sab';
      data?.table?.headers.forEach((header: any) => {
        data?.table.rows.forEach((row: any) => {
          if (currentMonthOrWeek(header)) {
            if (row[header.props]?.enableInput)
              row[header.props].enableInput = false;
          }
        });
      });
    }
  }

  getTerritoryAllocation(filter?: any, params?: any) {
    this.tableResult = [];
    this.dataTableInformation = [];
    this.rowsInfoTable = [];
    this.spinner.show();
    this.crudService
      .getEntity(
        `emea-allocation?pageName=${PAGE_NAME_RESOURCE.territoryAllocation}`,
        params
      )
      .subscribe(
        (response: any) => {
          this.verifyPermissionEdit(response);
          if (filter === 'quarter') {
            this.headerTable = response?.table.headers;
          } else {
            this.headerTable = response?.table.headers.filter(
              (item: any) => item.props.includes(filter) || !item.weekInTime
            );
          }

          this.rowsTable = response?.table.rows;
          this.addTypeShowEvent(response?.table.headers);

          this.tableResult = {
            table: {
              columns: this.headerTable,
              rows: new MatTableDataSource<any>(
                this.convertToDataSource(JSON.stringify(this.rowsTable))
              ),
            },
          };
          this.initialTableValues();
          this.addSubtotalValues(
            this.tableResult?.table,
            response?.parametrizations
          );
          this.spinner.hide();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
        }
      );
  }

  getInformationAllocation(filter?: any, params?: any): Promise<void> {
    this.spinner.show();
    this.dataSourceTable = [];
    this.toggleColumnTableValues = [];
    return new Promise((resolve, reject) => {
      this.crudService
        .getEntity(
          `emea-allocation/one-plan?pageName=${PAGE_NAME_RESOURCE.territoryAllocation}`,
          params
        )
        .subscribe(
          (response: any) => {
            this.verifyPermissionEdit(response);
            if (filter === 'quarter') {
              this.headerInfoTable = response?.table.headers;
            } else {
              this.headerInfoTable = response?.table.headers.filter(
                (item: any) => item.props.includes(filter) || !item.weekInTime
              );
            }

            this.productId = response?.product_id;

            this.rowsInfoTable = response?.table.rows;

            this.updateComponents(this.headerInfoTable, this.rowsInfoTable);
            this.exportComponents();
            this.loadInitialValues();
            this.showInfoTable = true;
            this.spinner.hide();
            resolve();
          },
          (err: any) => {
            reject(err);
            this.showInfoTable = false;
            this.toastr.error(err.error.message, 'Error!');
            this.spinner.hide();
          }
        );
    });
  }

  updateComponents(headers: any, rows: any) {
    this.dataTableInformation = {
      table: {
        columns: headers,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(rows))
        ),
      },
    };
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];
    array.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  async getDataFilter(filters: any) {
    const geosFilter: any[] = [];
    this.productFilter = [];
    const initialValueGeoFilter: { [key: string]: any } = {};
    this.spinner.show();

    filters.forEach((item: any) => {
      item.query['geo_id'] = [this.currentUser.geoId];
    });

    this.crudService.getDataFilters(filters).subscribe((response: any[]) => {
      [this.geosEntity, this.productFilterEntity] = response;

      this.geosEntity.forEach((item: Geo) => {
        geosFilter.push({ value: item.geo, id: item.id });

        if (item.id === this.currentUser.geoId) {
          initialValueGeoFilter.value = item.geo;
          initialValueGeoFilter.id = item.id;
        }
      });
      const selectGeo = new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: false,
        options: geosFilter,
        disabled: true,
        value: initialValueGeoFilter.value,
        notClearable: true,
      });

      this.productFilterEntity.forEach((item: any) => {
        this.productFilter.push({ value: item.product_group, id: item.id });
      });

      const selectProduct = new SelectSearchInput({
        key: 'product_group',
        hover: 'Product',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        options: this.productFilter,
      });

      this.searchInputs[0] = selectGeo;
      this.searchInputs[1] = selectProduct;

      this.searchInputs = [...this.searchInputs];
      this.spinner.hide();
    });
  }

  toggleTable(event: string) {
    this.eventTable = event;
  }

  addTypeShowEvent(response: any) {
    this.eventTable = 'hide-columns';

    response.forEach((header: any) => {
      if (header.expansive === true) {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });
  }

  checkOpenInfoTable(): boolean {
    return this.rowsInfoTable.length > 0;
  }

  addSubtotalValues(table: any, parametrizations: any) {
    let headerName: any = [];
    this.summedValue = [];
    const woiTotal: any = parametrizations.totals?.woiTotal?.value || '';

    table?.columns
      .filter((item: any) => item.hasSubtotal)
      .forEach((header: any) => {
        headerName = header.props;

        table?.rows?.filteredData
          .map((item: any) => {
            if (item[headerName] && item[headerName].summableValue) {
              return item[headerName].value;
            } else {
              return 0;
            }
          })
          .reduce((acc: any, item: any) => {
            if (acc !== undefined && item !== undefined) {
              return (this.summedValue = acc + item);
            }
          }, 0);
        header.totalAddedValue = this.summedValue;
      });

    table?.columns.forEach((header: any) => {
      if (header.props === 'woi') {
        header.totalAddedValue = woiTotal;
      }
    });
  }

  exportComponents() {
    this.exportResult = {
      tableInfo: {
        columns: this.dataTableInformation?.table?.columns,
        rows: this.dataTableInformation?.table.rows.filteredData,
      },
      tableAllocation: {
        columns: this.tableResult?.table?.columns,
        rows: this.tableResult?.table?.rows.filteredData,
      },
    };
    this.resultTerritoryAllocation = this.exportResult;
  }

  prepareExportTable(data: any) {
    this.spinner.show();
    const tableInfoRows = data.tableInfo.rows;

    const tableInfoColumns = data.tableInfo.columns
      .map((item: any) => ({
        title: item.title,
        props: item.props,
      }))
      .filter((item: any) => !item.title.includes('increase'));

    tableInfoColumns.splice(0, 0, { title: 'Territory', props: 'territory' });
    tableInfoColumns.splice(1, 0, { title: 'Sab', props: 'sab' });

    const tableAllocationRows = data.tableAllocation.rows;
    const tableAllocationColumns = data.tableAllocation.columns
      .filter((col: any) => col.title !== 'SUBTOTAL')
      .map((col: any) => ({
        title: col.title,
        props: col.props,
        totalAddedValue: col.totalAddedValue,
      }));

    const tableRows: { [key: string]: any }[] = [];

    for (let i = 0; i < tableInfoRows.length; i++) {
      const tableRow: { [key: string]: any } = {};
      tableInfoColumns.forEach((column: any) => {
        if (column.title.includes('TOTAL'))
          column.title = `${this.filter.quarter} TOTAL`;
        if (column.title.includes('#'))
          column.title = this.filterProductSelected;
        if (tableInfoRows[i][column.props]) {
          tableRow[column.props] = tableInfoRows[i][column.props]
            ? tableInfoRows[i][column.props].value
            : '';
        } else {
          tableRow[column.props] = tableInfoRows[i][column.props]
            ? tableInfoRows[i][column.props].value
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    for (let i = 0; i < tableAllocationRows.length; i++) {
      const tableRow: { [key: string]: any } = {};
      tableAllocationColumns.forEach((column: any) => {
        if (column.props.includes('fq4TotalSab'))
          column.title = `${this.filter.quarter} TOTAL+SAB`;
        if (tableAllocationRows[i][column.props]) {
          tableRow[column.props] = tableAllocationRows[i][column.props].value;
        }
      });
      tableRows.push(tableRow);
    }

    const tableRow: { [key: string]: any } = {};
    tableAllocationColumns.forEach((column: any) => {
      if (column.title.includes('TERRITORY')) {
        tableRow[column.props] = 'TOTAL';
      } else {
        tableRow[column.props] = column.totalAddedValue
          ? column.totalAddedValue
          : '-';
      }
    });
    tableRows.push(tableRow);

    const tableColumns = [...tableInfoColumns, ...tableAllocationColumns];

    return { columns: tableColumns, rows: tableRows };
  }

  async exportTable(): Promise<any> {
    if (!this.checkOpenInfoTable()) {
      await this.getInformationAllocation(
        this.filterMonthsSelected,
        this.filter
      );
    }

    const fullJsonExport = this.prepareExportTable(
      this.resultTerritoryAllocation
    );
    this.excelService.exportJsonAsExcelFile(
      fullJsonExport,
      'territoy_allocation'
    );
    this.spinner.hide();
  }

  clearTablesResult() {
    this.noFilterSelected = true;
    this.tableResult = [];
    this.dataTableInformation = [];
  }

  clearSearchInput() {
    this.filterProductSelected = '';
    this.filter = { ...this.filter, ...this.formSearchValues };

    for (let i = 2; i < this.searchInputs.length; i++) {
      this.searchInputs[i].options = [];
      this.searchInputs[i].disabled = true;
    }
    this.searchInputs = [...this.searchInputs];
    this.clearTablesResult();
  }

  applyFilter() {
    const formSearchValues = { ...this.formSearchValues };
    delete formSearchValues.geoId;
    delete formSearchValues.action;
    delete formSearchValues.nameFormControl;
    delete formSearchValues.action;
    delete formSearchValues.formControl;
    delete formSearchValues.event;
    delete formSearchValues.panelTrigger;
    delete formSearchValues.geo_id;
    this.noFilterSelected = false;
    this.filter = {
      ...this.filter,
      ...formSearchValues,
    };
    this.checkDiscardAllocation('applyFilter');
  }

  checkDiscardAllocation(sourceMethod?: string) {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.discardChanges();
          if (sourceMethod === 'applyFilter') {
            let idInput = document.querySelectorAll('input')[0].id;

            let inputElementProduct: any | HTMLInputElement = '';

            inputElementProduct = document.getElementById(idInput);
            inputElementProduct.blur();

            this.getData(this.filter);
          }
        }
      });
    } else {
      this.discardChanges();
      if (sourceMethod === 'applyFilter') {
        let idInput = document.querySelectorAll('input')[0].id;

        let inputElementProduct: any | HTMLInputElement = '';

        inputElementProduct = document.getElementById(idInput);
        inputElementProduct.blur();

        this.getData(this.filter);
      }
    }
  }

  onSearchEvent(value: any) {
    this.formSearchValues = { ...value };

    if (this.formSearchValues.geo_id) {
      const currentGeo = this.geosEntity.filter((item: any) =>
        item.geo
          .toLocaleUpperCase()
          .includes(this.formSearchValues.geo_id.toLocaleUpperCase())
      );
      this.formSearchValues.geo_id = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      this.formSearchValues.geo_id = '';
    }

    if (this.formSearchValues.action == 'clear') {
      if (this.isEditTableMode) {
        this.isDiscardChanges = true;
        const dialog = this.getDialogModal('discard');

        dialog.afterClosed().subscribe((result: any) => {
          if (result === 'discard') {
            this.discardChanges();
            this.formSearchValues.product_group = '';
            this.clearSearchInput();
          } else {
            const selectProduct = new SelectSearchInput({
              key: 'product_group',
              hover: 'Product',
              hasAutocomplete: true,
              hasCheckFlowException: true,
              options: this.productFilter,
              value: this.filterProductSelected,
            });
            this.searchInputs[1] = selectProduct;
            this.searchInputs = [...this.searchInputs];
          }
        });
      } else {
        this.discardChanges();
        this.clearSearchInput();
      }
    }

    if (this.formSearchValues.product_group) {
      this.filterProductSelected = this.formSearchValues.product_group;
      this.enableApplyFilter = true;
      this.applyFilter();
    } else if (!this.formSearchValues.product_group) {
      if (this.isEditTableMode) {
        this.isDiscardChanges = true;
        const dialog = this.getDialogModal('discard');

        dialog.afterClosed().subscribe((result: any) => {
          if (result === 'discard') {
            this.discardChanges();
            this.formSearchValues.product_group = '';
            this.clearSearchInput();
          }
        });
      } else {
        this.discardChanges();
        this.clearSearchInput();
      }
    }

    this.pagination.page = 0;
    this.searchInputs = [...this.searchInputs];
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  verifyClickChangeRoute(event: any) {
    const classElementsChangesRoutes = ['xng-breadcrumb-item ng-star-inserted'];

    const isElementChangeRoute =
      classElementsChangesRoutes.filter((className: string) =>
        event.target.className.includes(className)
      ).length > 0;

    if (isElementChangeRoute && this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          const originUrl = event.target.children[0].origin;
          const routeToNavigate = event.target.children[0].href.replace(
            originUrl,
            ''
          );
          this.router.navigate([routeToNavigate]);
        }
      });
    }
  }

  toggleBreadcrumb(toDisable: boolean) {
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/emea-territory-allocation', {
      disable: toDisable,
    });
  }

  handleFilterButton(event: any) {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.filterMonthsSelected = event.value;
          this.getData(this.filter);
          this.isEditTableMode = false;
        }
      });
    } else {
      this.filterMonthsSelected = event.value;
      this.getData(this.filter);
      this.isEditTableMode = false;
    }
  }

  getMonthFilter(date: Date) {
    let monthName = date.toLocaleString('en-US', { month: 'long' });
    return monthName.toLowerCase();
  }

  resetPullIn() {
    this.pullInOrIncreaseValues.forEach((key: any) => {
      key.mode = true;
    });
  }

  checkCurrentYearAndQuarter() {
    if (!this.filter.fiscalYear && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  checkQuarterAndYear() {
    if (!this.next && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarterFromDate(date: Date) {
    let month = date.getMonth() + 1;
    this.next === true
      ? (month = date.getMonth() + 4)
      : (month = date.getMonth() + 1);
    if (month >= 4 && month <= 6) return 'FQ1';
    if (month >= 7 && month <= 9) return 'FQ2';
    if (month >= 10 && month <= 12) return 'FQ3';
    return 'FQ4';
  }

  getQuarter(date: Date) {
    let month = date.getMonth() + 1;
    if (this.next) month = date.getMonth() + 4;
    this.filter.quarter = this.getQuarterFromDate(new Date());

    this.filterMonths = [];

    switch (month) {
      case 4:
      case 5:
      case 6:
        this.filterMonths.push(
          { label: 'April', value: 'april' },
          { label: 'May', value: 'may' },
          { label: 'June', value: 'june' }
        );
        break;
      case 7:
      case 8:
      case 9:
        this.filterMonths.push(
          { label: 'July', value: 'july' },
          { label: 'August', value: 'august' },
          { label: 'September', value: 'september' }
        );
        break;
      case 10:
      case 11:
      case 12:
        this.filterMonths.push(
          { label: 'October', value: 'october' },
          { label: 'November', value: 'november' },
          { label: 'December', value: 'december' }
        );
        break;
      default:
        this.filterMonths.push(
          { label: 'January', value: 'january' },
          { label: 'February', value: 'february' },
          { label: 'March', value: 'march' }
        );
        break;
    }
    this.filterMonths.push({
      label: 'Quarter',
      value: 'quarter',
    });
  }

  nextQuarter(next: boolean) {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.discardChanges();
          this.next = !this.next;
          this.getQuarter(new Date());
          this.filter.fiscalYear = this.checkQuarterAndYear();
          this.resetPullIn();
          this.getData(this.filter);
          this.isEditTableMode = false;
        }
      });
    } else {
      this.next = !this.next;
      this.getQuarter(new Date());
      this.filter.fiscalYear = this.checkQuarterAndYear();
      this.resetPullIn();
      this.getData(this.filter);
      this.isEditTableMode = false;
    }
  }

  async toggleTableCollapse(): Promise<any> {
    if (!this.checkOpenInfoTable()) {
      await this.getInformationAllocation(
        this.filterMonthsSelected,
        this.filter
      );
    }
    this.showTableCollapse = !this.showTableCollapse;
  }

  toggleColumnTable({ col, event }: any) {
    this.toggleColumnTableValues = [];
    this.toggleColumnTableValues.push(col, event);
  }

  onSavePullInOrIncreaseValue(item: any, type: string) {
    this.spinner.show();
    let newDataToSave: any = {};
    const splitName = item.props.split('Opportunity');
    const monthName =
      splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1);

    newDataToSave = {
      product_group: this.filterProductSelected,
      fiscal_year: this.filter.fiscalYear,
      month: monthName,
      type: type,
      value:
        typeof item.value === 'string'
          ? Number(item.value.replaceAll(',', ''))
          : item.value,
    };
    /* PUT VALUES */
    this.putPullInCrudService.updateEntity('', newDataToSave).subscribe(
      () => {
        const subtitleMessage = 'Updated successfully!';
        const titleMessage = 'Territory Allocation';
        this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
        this.getInformationAllocation(this.filterMonthsSelected, this.filter);
        this.modalService.closeAll();
        this.pullInOrIncreaseValues.forEach((key: any) => {
          key.edit = false;
        });
      },
      (err: any) => {
        this.getInformationAllocation(this.filterMonthsSelected, this.filter);
        this.modalService.closeAll();
        this.pullInOrIncreaseValues.forEach((key: any) => {
          key.edit = false;
        });
        const subtitleMessage =
          'The value reported does not have enough quantity in the next month.';
        const titleMessage = 'Pull In';
        this.toastr.warning(`${subtitleMessage}`, `${titleMessage}`, {
          timeOut: 20000,
        });
      }
    );
  }

  valuePullInOrIncrease({ item, event, values }: any) {
    this.pullInOrIncreaseValues = values;
    this.handleGetCalcules(item);
  }

  changedPullInOrIncrease({ item, event, values }: any) {
    this.pullInOrIncreaseValues = values;
    this.handleGetCalcules(item);
  }

  handleGetCalcules(item: any) {
    this.pullInOrIncreaseValues.forEach((key: any) => {
      if (key.edit)
        key.mode ? this.getCalculePullIn(key) : this.getCalculeIncrease(key);
    });
  }

  getCalculeIncrease(item: any) {
    let increaseValue = parseFloat(item.value);
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value === 'ONE PLAN') {
        if (!isNaN(increaseValue)) {
          row[item.props].value = increaseValue;
        }
      }
    });
    this.updateInfoTableValues();
    this.onSavePullInOrIncreaseValue(item, 'INCREASE');
  }

  getCalculePullIn(item: any) {
    this.onSavePullInOrIncreaseValue(item, 'PULLIN');
  }

  resetCalculeIncrease(item: any) {
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value === 'ONE PLAN') {
        row[item.props].value = 0;
      }
    });
    this.updateInfoTableValues();
  }

  /* Função que atualiza os valores de Delta e TOTAL na tabela de Info*/
  getUpdateDelta(item: any) {
    let subTotalWeek: any = 0;
    let subTotalDelta: any = 0;
    let acumulativeDeltaWeekAndOpportunity: any = 0;

    // Soma de toda a coluna de wk da tabela de inputs
    this.tableResult.table?.rows.filteredData.forEach((row: any) => {
      subTotalWeek += parseFloat(row[item.props].value);
    });

    // Adicionando sub totais na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (row.columnName.value === 'ONE PLAN') {
        subTotalDelta =
          parseFloat(row[item.props].value) - parseFloat(subTotalWeek);
      }
      if (row.columnName.value === 'DELTA') {
        row[item.props].value = parseFloat(subTotalDelta);

        // Subtraindo os valores de One Plan e Allocation
        const hasColumn = this.valuesSubstraction.filter(
          (v: any) => v.col == item.props
        );
        if (!hasColumn.length)
          this.valuesSubstraction.push({
            col: item.props,
            value: subTotalDelta,
          });
        this.valuesSubstraction.forEach((key: any) => {
          if (key.col == item.props) {
            key.col = item.props;
            key.value = parseFloat(subTotalDelta);
          }
        });
      }
    });

    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row.col === column.props) {
          acumulativeDeltaWeekAndOpportunity =
            parseFloat(acumulativeDeltaWeekAndOpportunity) +
            parseFloat(row.value);
        }
      });
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      const totalColumn = row[0] === 'fq4Total';
      if (totalColumn)
        row[1].value = parseFloat(acumulativeDeltaWeekAndOpportunity);
    });
  }

  getFiscalYear() {
    if (this.next && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() + 1;
    return new Date().getFullYear();
  }

  handleZoomIn() {
    // this.resetExpandedColumns();
    this.zoomIn = !this.zoomIn;
  }

  resetExpandedColumns() {
    this.tableResult?.table?.columns?.forEach((header: any) => {
      if (header?.expanded) {
        delete header.expanded;
      }
    });
    this.dataTableInformation?.table?.columns?.forEach((header: any) => {
      if (header?.expanded) {
        delete header.expanded;
      }
    });
  }

  onSave() {
    const dialog = this.getDialogModal('save');
    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'save') {
        this.spinner.show();

        const newDataToSave: {
          productGroup: number;
          locationId: number;
          fiscalYear: number;
          splitWeek: string;
          month: string;
          value: number;
        }[] = [];

        const newDataToSaveSab: {
          product_group: number;
          location_id: number;
          fiscal_year: number;
          fiscal_quarter: string;
          value: number;
        }[] = [];

        for (
          let i = 0;
          i < this.tableResult.table?.rows.filteredData.length;
          i++
        ) {
          Object.entries(this.tableResult.table?.rows.filteredData[i]).forEach(
            (row: any) => {
              if (row[1].edit) {
                if (row[0]) {
                  let monthName;
                  let weekNumber;
                  const territoryId: number = row[1]?.territoryId;

                  if (row[0].includes('Opportunity')) {
                    weekNumber = 'OPPORTUNITY';
                    const splitName = row[0].split('Opportunity');
                    monthName = splitName[0];
                  } else {
                    const splitName = row[0].split('wk');
                    monthName = splitName[0];
                    weekNumber =
                      splitName[1] === undefined
                        ? monthName
                        : `W${splitName[1]}`;
                  }

                  if (monthName.toUpperCase() == 'SAB') {
                    newDataToSaveSab.push({
                      product_group: this.filterProductSelected,
                      location_id: territoryId,
                      fiscal_year: this.filter.fiscalYear,
                      fiscal_quarter: this.filter.quarter,
                      value:
                        typeof row[1].value === 'string'
                          ? Number(row[1].value.replaceAll(',', ''))
                          : row[1].value,
                    });
                  } else {
                    newDataToSave.push({
                      productGroup: this.filterProductSelected,
                      locationId: territoryId,
                      fiscalYear: this.filter.fiscalYear,
                      splitWeek: weekNumber,
                      month: monthName,
                      value:
                        typeof row[1].value === 'string'
                          ? Number(row[1].value.replaceAll(',', ''))
                          : row[1].value,
                    });
                  }
                }
              }
            }
          );
        }
        /* PUT VALUES */
        this.putCrudService.updateEntity('', newDataToSave).subscribe(
          () => {
            if (newDataToSaveSab.length > 0) this.onSaveSab(newDataToSaveSab);

            this.spinner.hide();
            const subtitleMessage = 'Updated successfully!';
            const titleMessage = 'Territory Allocation';
            this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
            this.isEditTableMode = false;
            this.modalService.closeAll();
            this.applyFilter();
          },
          (err: any) => {
            this.spinner.hide();
            this.onSaveConfirm(newDataToSave);
            this.toastr.error(err.error.message, 'Error!');
          }
        );
      }
    });
  }

  onSaveSab(data: any) {
    /* PUT VALUES SAB */
    this.putSabCrudService.updateEntity('', data).subscribe(
      () => {},
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'Error!');
      }
    );
  }

  onSaveConfirm(data: any) {
    const newDataToSave: any = {
      resetOpportunityValue: true,
      payloadParameters: [...data],
    };

    const dialog = this.getDialogModal('confirm');
    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'save') {
        this.spinner.show();
        this.putCrudService.updateEntity('', newDataToSave).subscribe(
          () => {
            this.spinner.hide();
            const subtitleMessage = 'Updated successfully!';
            const titleMessage = 'Territory Allocation';
            this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
            this.isEditTableMode = false;
            this.modalService.closeAll();
            this.applyFilter();
          },
          (err: any) => {
            this.spinner.hide();
            this.toastr.error(err.error.message, 'Error!');
          }
        );
      }
    });
  }

  onCancel() {
    this.checkDiscardAllocation('applyFilter');
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate(['home/emea-allocation']);
        }
      });
    } else {
      this.router.navigate(['home/emea-allocation']);
    }
  }

  inputChanged(item: any) {
    this.updateTableValues(item);
    this.toggleBreadcrumb(true);
  }

  /* Função que realiza o cálculo inicial dos valores de Delta */
  initialInfoDeltaValues() {
    let acumulativeDelta: any = 0;
    this.valuesSubstraction = [];
    this.valuesDelta = [];

    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    // Adiconando o sub total Delta do quarter na tabela de info
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        const isColumnOpportunity = column.props.includes('Opportunity');
        if (!isColumnOpportunity) {
          if (row[0] === column.props) {
            acumulativeDelta =
              parseFloat(acumulativeDelta) + parseFloat(row[1].value);
            const hasColumnInValuesDelta = this.valuesDelta.filter(
              (v: any) => v.col == row[0]
            );
            if (!hasColumnInValuesDelta.length)
              this.valuesDelta.push({ col: row[0], value: acumulativeDelta });
            this.valuesDelta.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(acumulativeDelta);
              }
            });

            const hasColumnInValuesSubstraction =
              this.valuesSubstraction.filter((v: any) => v.col == row[0]);
            if (!hasColumnInValuesSubstraction.length)
              this.valuesSubstraction.push({ col: row[0], value: 0 });
            this.valuesSubstraction.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(row[1].value);
              }
            });
          }
        } else {
          if (row[0] === column.props) {
            const hasColumnInValuesSubstraction =
              this.valuesSubstraction.filter((v: any) => v.col == row[0]);
            if (!hasColumnInValuesSubstraction.length)
              this.valuesSubstraction.push({ col: row[0], value: 0 });
            this.valuesSubstraction.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(row[1].value);
              }
            });
          }
        }
      });
    });

    // Realizar a subtração do One Plan com Allocation, e adiciona o resultado em um array
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((onePlan: any) => {
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[2]
      ).forEach((allocation: any) => {
        this.valuesSubstraction.forEach((key: any) => {
          if (onePlan[0] === allocation[0]) {
            if (key.col === onePlan[0]) {
              key.value =
                parseFloat(onePlan[1].value) - parseFloat(allocation[1].value);
            }
          }
        });
      });
    });

    let acumulativeDeltaWeek: any = 0;

    // Acumulando os valores de delta
    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        const isColumnOpportunity = column.props.includes('Opportunity');
        if (!isColumnOpportunity) {
          if (row.col === column.props) {
            acumulativeDeltaWeek =
              parseFloat(acumulativeDeltaWeek) + parseFloat(row.value);
            this.valuesDelta.forEach((key: any) => {
              if (key.col === row.col) {
                key.col = row.col;
                key.value = parseFloat(acumulativeDeltaWeek);
              }
            });
          }
        }
      });
    });

    // Adicionando os valores acumulados de delta na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      this.valuesDelta.forEach((key: any) => {
        if (row.columnName.value === 'DELTA')
          row[key.col].value = parseFloat(key.value);
      });
    });

    // Adiciona o valor de PullIn ou Increase no array dos valores subtraídos
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      this.valuesSubstraction.forEach((key: any) => {
        if (key.col === row[0]) {
          const isColumnOpportunity = key.col.includes('Opportunity');
          if (isColumnOpportunity) {
            row[1].value = key.value;
          }
        }
      });
    });

    // Adiciona os valores acumulados de delta e coluna de Opportunity na coluna de TOTAL
    let acumulativeDeltaWeekAndOpportunity: any = 0;

    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row.col === column.props) {
          acumulativeDeltaWeekAndOpportunity =
            parseFloat(acumulativeDeltaWeekAndOpportunity) +
            parseFloat(row.value);
        }
      });
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      const totalColumn = row[0] === 'fq4Total';
      if (totalColumn)
        row[1].value = parseFloat(acumulativeDeltaWeekAndOpportunity);
    });

    const filteredRows = this.dataTableInformation.table.rows.filteredData[0];

    for (const [key, value] of Object.entries(filteredRows)) {
      if (typeof key === 'string' && key.includes('fq4Total')) {
        const rowValue = value as { value: string };
        rowValue.value = '';
      }
    }
  }

  initialTableValues() {
    for (
      let i = 0;
      i < this.tableResult?.table?.rows?.filteredData.length;
      i++
    ) {
      let subTotalQuarter: any = 0;
      let subTotalQuarterPDP: any = 0;
      let calculeOrdersVsAllocation: any = 0;
      let calculeOrdersVsMx: any = 0;
      let calculeOrdersVsPdp: any = 0;
      let sabValue: any = 0;
      let mxValue: any = 0;

      this.tableResult?.table?.columns?.forEach((column: any) => {
        Object.entries(this.tableResult.table?.rows.filteredData[i]).forEach(
          (row: any) => {
            if (row[1].enableInput) {
              if (row[0] === column.props) {
                subTotalQuarter += parseFloat(row[1].value);
              }
            } else if (row[1].isPDP && typeof row[1].value == 'number') {
              if (row[0] === column.props) {
                subTotalQuarterPDP += parseFloat(row[1].value);
              }
            }

            const sabColumn = row[0].toLocaleUpperCase() === 'SAB';

            if (column.props.toLocaleUpperCase() === 'SAB' && sabColumn) {
              sabValue = row[1].value;
            }

            const totalColumn = row[0].toLocaleUpperCase() === 'FQ4TOTALSAB';
            if (totalColumn) {
              if (row[1].isTotalPDP) {
                row[1].value = subTotalQuarterPDP;
              } else {
                row[1].value = subTotalQuarter;
              }
            }

            const mxColumn = row[0].toLocaleUpperCase() === 'MX';
            if (mxColumn) mxValue = row[1].value;

            const ordersColumn = row[0].toLocaleUpperCase() === 'ORDERS';
            if (ordersColumn) {
              calculeOrdersVsAllocation = this.calculateOrdersVsAllocation(
                row[1].value,
                parseFloat(sabValue),
                subTotalQuarter
              );
              calculeOrdersVsMx = this.calculateOrdersVsMx(
                row[1].value,
                parseFloat(sabValue),
                parseFloat(mxValue)
              );
              calculeOrdersVsPdp = row[1].value;
            }

            const pdpColumn = row[0].toLocaleUpperCase() === 'PDP';
            if (pdpColumn)
              calculeOrdersVsPdp = this.calculateOrdersVsPdp(
                parseFloat(calculeOrdersVsPdp),
                parseFloat(row[1].value)
              );

            const ordersVsPdp = row[0].toLocaleUpperCase() === 'ORDERSVSPDP';
            if (ordersVsPdp) row[1].value = calculeOrdersVsPdp;

            const ordersVsAllocation =
              row[0].toLocaleUpperCase() === 'ORDERSVSALLOCATION';
            if (ordersVsAllocation) {
              row[1].value = calculeOrdersVsAllocation;
            }

            const ordersVsOutlook = row[0].toLocaleUpperCase() === 'ORDERSVSMX';
            if (ordersVsOutlook) row[1].value = calculeOrdersVsMx;
          }
        );
      });
    }
  }

  calculateOrdersVsAllocation(
    ordersValue: any,
    sabValue: number,
    subTotalQuarter: number
  ): number {
    return subTotalQuarter > 0
      ? ordersValue + sabValue - subTotalQuarter
      : ordersValue;
  }

  calculateOrdersVsMx(
    ordersValue: any,
    sabValue: number,
    mxValue: number
  ): number {
    return ordersValue + sabValue - mxValue;
  }
  calculateOrdersVsPdp(ordersValue: any, pdpValue: number): number {
    return ordersValue - pdpValue;
  }

  AddingAllWeeksValues(): void {
    for (
      let i = 0;
      i < this.tableResult?.table?.rows?.filteredData.length;
      i++
    ) {
      // Soma de toda a coluna de wk da tabela de inputs
      Object.entries(
        this.dataTableInformation.table?.rows.filteredData[2]
      ).forEach((value: any) => {
        Object.entries(this.tableResult?.table?.rows.filteredData[i]).forEach(
          (row: any) => {
            if (value[0] == row[0] && row[1].isWeek && !row[1].isPDP) {
              if (row[1].value > 0) value[1].value += parseFloat(row[1].value);
            }
          }
        );
      });
    }
  }

  updateInfoTableValues(): void {
    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    // Adiconando o sub total One Plan na tabela de info
    let subTotalOnePlan: any = 0;
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row[0] === column.props)
          subTotalOnePlan =
            parseFloat(subTotalOnePlan) + parseFloat(row[1].value);
      });
    });
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((row: any) => {
      const totalColumn = row[0] === 'fq4Total';
      if (totalColumn) row[1].value = parseFloat(subTotalOnePlan);
    });

    // Adiconando o sub total Allocation na tabela de info
    let subTotalAllocation: any = 0;

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[2]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row[0] === column.props) {
          subTotalAllocation =
            parseFloat(subTotalAllocation) + parseFloat(row[1].value);
        }
      });
    });
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[2]
    ).forEach((row: any) => {
      const totalColumn = row[0] === 'fq4Total';
      if (totalColumn) row[1].value = parseFloat(subTotalAllocation);
    });

    // Adiconando o sub total Allocation na tabela de info
    let subTotalPDP: any = 0;

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[4]
    ).forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        const isColumnOpportunity = column.props.includes('Opportunity');
        if (!isColumnOpportunity) {
          if (row[0] === column.props) {
            subTotalPDP = parseFloat(subTotalPDP) + parseFloat(row[1].value);
          }
        }
      });
    });
    Object.entries(
      this.dataTableInformation.table.rows.filteredData[4]
    ).forEach((row: any) => {
      const totalColumn = row[0] === 'fq4Total';
      if (totalColumn) row[1].value = parseFloat(subTotalPDP);
    });
  }

  updateTableValues(item: any) {
    let subTotalWeek: any = 0;
    let subTotalDelta: any = 0;
    let subTotalQuarter: any = 0;
    let sabValue: any = 0;
    let mxValue: any = 0;
    let calculeOrdersVsAllocation: any = 0;
    let calculeOrdersVsMx: any = 0;
    let calculeOrdersVsPdp: any = 0;
    let acumulativeDeltaWeek: any = 0;
    let acumulativeDeltaWeekAndOpportunity: any = 0;
    this.isEditTableMode = true;

    // Soma de toda a coluna de wk da tabela de inputs
    this.tableResult.table?.rows.filteredData.forEach((row: any) => {
      if (row[item.columnNameWeek]?.value > 0 && !row.territory.isPDP) {
        subTotalWeek += parseFloat(row[item.columnNameWeek]?.value);
      }
    });

    // Adiconando o sub total do quarter na tabela de inputs
    this.tableResult?.table?.columns?.forEach((column: any) => {
      Object.entries(
        this.tableResult.table?.rows.filteredData[item.indexRow]
      ).forEach((row: any) => {
        if (row[1].enableInput) {
          if (row[0] == column.props) {
            subTotalQuarter += parseFloat(row[1].value);
          }
        }

        const sabColumn = row[0].toLocaleUpperCase() === 'SAB';
        if (sabColumn) sabValue = row[1].value;

        const totalColumn = row[0].toLocaleUpperCase() === 'FQ4TOTALSAB';
        if (totalColumn) row[1].value = subTotalQuarter;

        const mxColumn = row[0].toLocaleUpperCase() === 'MX';
        if (mxColumn) mxValue = row[1].value;

        const ordersColumn = row[0].toLocaleUpperCase() === 'ORDERS';
        if (ordersColumn) {
          calculeOrdersVsAllocation = this.calculateOrdersVsAllocation(
            row[1].value,
            parseFloat(sabValue),
            subTotalQuarter
          );
          calculeOrdersVsMx = this.calculateOrdersVsMx(
            row[1].value,
            parseFloat(sabValue),
            parseFloat(mxValue)
          );
          calculeOrdersVsPdp = row[1].value;
        }

        const pdpColumn = row[0].toLocaleUpperCase() === 'PDP';
        if (pdpColumn)
          calculeOrdersVsPdp = this.calculateOrdersVsPdp(
            parseFloat(calculeOrdersVsPdp),
            parseFloat(row[1].value)
          );

        const ordersVsPdp = row[0].toLocaleUpperCase() === 'ORDERSVSPDP';
        if (ordersVsPdp) row[1].value = calculeOrdersVsPdp;

        const ordersVsAllocation =
          row[0].toLocaleUpperCase() === 'ORDERSVSALLOCATION';
        if (ordersVsAllocation) {
          row[1].value = calculeOrdersVsAllocation;
        }

        const ordersVsOutlook = row[0].toLocaleUpperCase() === 'ORDERSVSMX';
        if (ordersVsOutlook) row[1].value = calculeOrdersVsMx;
      });
    });

    const columnsInfoNamesWeeks =
      this.dataTableInformation.table?.columns?.filter(
        (header: any) => header.weekInTime
      );

    // Adicionando sub totais na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      if (item.columnNameWeek !== 'sab') {
        if (row.columnName.value === 'ALLOCATION')
          row[item.columnNameWeek].value = subTotalWeek;
        if (row.columnName.value === 'ONE PLAN')
          subTotalDelta =
            parseFloat(row[item.columnNameWeek]?.value) -
            parseFloat(subTotalWeek);
        if (row.columnName.value === 'DELTA') {
          row[item.columnNameWeek].value = parseFloat(subTotalDelta);

          // Subtraindo os valores de One Plan e Allocation
          const hasColumn = this.valuesSubstraction.filter(
            (v: any) => v.col == item.columnNameWeek
          );
          if (!hasColumn.length)
            this.valuesSubstraction.push({
              col: item.columnNameWeek,
              value: subTotalDelta,
            });
          this.valuesSubstraction.forEach((key: any) => {
            if (key.col == item.columnNameWeek) {
              key.col = item.columnNameWeek;
              key.value = parseFloat(subTotalDelta);
            }
          });
        }
      }
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[1]
    ).forEach((onePlan: any) => {
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[2]
      ).forEach((allocation: any) => {
        this.valuesSubstraction.forEach((key: any) => {
          if (onePlan[0] === allocation[0]) {
            const isColumnOpportunity = key.col.includes('Opportunity');
            if (!isColumnOpportunity) {
              if (key.col === onePlan[0]) {
                let indexArrayWk = onePlan[0].split('wk');
                let indexSelectedWk = item.columnNameWeek.split('wk');
                if (
                  indexArrayWk[1] < indexSelectedWk[1] ||
                  indexArrayWk[1] > indexSelectedWk[1]
                ) {
                  key.value =
                    parseFloat(onePlan[1].value) -
                    parseFloat(allocation[1].value);
                }
              }
            }
          }
        });
      });
    });

    const columnNameWeekIsOpportunity =
      item.columnNameWeek.includes('Opportunity');
    if (!columnNameWeekIsOpportunity) {
      Object.entries(
        this.dataTableInformation.table.rows.filteredData[1]
      ).forEach((row: any) => {
        columnsInfoNamesWeeks?.forEach((column: any) => {
          const isColumnOpportunity = column.props.includes('Opportunity');
          if (!isColumnOpportunity) {
            if (row[0] === column.props) {
              // subTotalDelta = parseFloat(acumulativeDelta) + parseFloat(row[1].value);
              const hasColumn = this.valuesDelta.filter(
                (v: any) => v.col == row[0]
              );
              if (!hasColumn.length)
                this.valuesDelta.push({ col: row[0], value: subTotalDelta });
              this.valuesDelta.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(subTotalDelta);
                }
              });
            }
          }
        });
      });

      // Acumulando os valores de delta
      this.valuesSubstraction.forEach((row: any) => {
        columnsInfoNamesWeeks?.forEach((column: any) => {
          const isColumnOpportunity = column.props.includes('Opportunity');
          if (!isColumnOpportunity) {
            if (row.col === column.props) {
              acumulativeDeltaWeek =
                parseFloat(acumulativeDeltaWeek) + parseFloat(row.value);
              this.valuesDelta.forEach((key: any) => {
                if (key.col === row.col) {
                  key.col = row.col;
                  key.value = parseFloat(acumulativeDeltaWeek);
                }
              });
            }
          }
        });
      });
    }

    // Adicionando os valores acumulados de delta na tabela de info
    this.dataTableInformation.table.rows.filteredData.forEach((row: any) => {
      this.valuesDelta.forEach((key: any) => {
        if (row.columnName.value === 'DELTA')
          row[key.col].value = parseFloat(key.value);
      });
    });

    this.valuesSubstraction.forEach((row: any) => {
      columnsInfoNamesWeeks?.forEach((column: any) => {
        if (row.col === column.props) {
          acumulativeDeltaWeekAndOpportunity =
            parseFloat(acumulativeDeltaWeekAndOpportunity) +
            parseFloat(row.value);
        }
      });
    });

    Object.entries(
      this.dataTableInformation.table.rows.filteredData[3]
    ).forEach((row: any) => {
      const totalColumn = row[0] === 'fq4Total';
      if (totalColumn)
        row[1].value = parseFloat(acumulativeDeltaWeekAndOpportunity);
    });

    this.updateInfoTableValues();
  }
}
