<ngx-spinner></ngx-spinner>
<div class="container-market-name">
  <div class="header-title">
    <div class="title">FAMILY NAME</div>
    <div class="container-export">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="disabledButtonExport"
          [disabled]="disabledButtonExport"></button>
      </span>
    </div>
  </div>

  <app-search
    [filters]="searchInputs"
    (searchEvent)="onSearchEvent($event)"
    (applyFilterEvent)="applyFilter()"
    [showButtonApply]="true"
    [enableApplyFilter]="disableApply"
    [showMoreFiltersLink]="true">
  </app-search>
  <label class="switch">
    <input (change)="checkSimpleOrNot($event)" type="checkbox" />
    <span class="slider round"></span>
  </label>
  <label>
    <span style="margin-left: 10px">Simple View</span>
  </label>
  <mat-divider></mat-divider>
  <section class="header-table-market-name">
    <div class="title-section">
      <h3 class="t-section">Allocation By Family</h3>
      <h4 class="st-section"></h4>
    </div>
    <div class="reference-table-container">
      <span class="reference-table"
        ><b>ENTERED SETUP </b>{{ allocationLabels.entered | uppercase
        }}<b> CURRENT WEEK </b>{{ allocationLabels.currentWeek | uppercase
        }}<b> TO BOOK </b>{{ allocationLabels.toBook | uppercase
        }}<b> TO ALLOCATE </b>
        {{ allocationLabels.toAllocate | uppercase }}
      </span>
      <button
        *ngIf="this.loadedDataTableData"
        (click)="openModalLastWeek()"
        class="button-last-week">
        LAST WEEK
      </button>
    </div>
  </section>
  <ng-template #blockFilterSelected>
    <div class="table-dynamic-list" *ngIf="!showNoResults; else blockNoResult">
      <div *ngFor="let table of dataSourceTable; let key = index" [style]="table.style">
        <app-table-manager-market-name
          #tableManagerMarketName
          *ngIf="showTable"
          [dataSource]="table"
          [eventTable]="eventTable"
          [keyDataSource]="key"
          (toggleTable)="toggleTable($event)"
          [showSubtotalTitle]="table.showSubtotalTitle"
          (inputValueChangedEvent)="updateTableValues($event)"
          (valueIdentified)="openModalNetOpenPo($event)"
          [valuesSubTotal]="valuesSubTotal"
          (onBlurInput)="updateSummarizedTable($event)">
        </app-table-manager-market-name>
      </div>
    </div>
    <div
      *ngIf="!viewButtonSave && isEditTableMode"
      class="buttons-actions-table-allocation">
      <button
        class="button modal-cancel-button"
        mat-button
        (click)="checkDiscardAllocation('applyFilter')">
        CANCEL
      </button>
      <button
        #saveChangesButton
        class="button modal-action-button disabled"
        mat-button
        (click)="saveAllocation()"
        [class.disabled]="disabledSaveButton"
        [disabled]="disabledSaveButton">
        SAVE
      </button>
    </div>
    <div
      *ngIf="viewButtonSave && isEditTableMode"
      class="buttons-actions-table-allocation">
      <button
        class="button modal-cancel-button button-cancel"
        mat-button
        (click)="checkDiscardAllocation('applyFilter')">
        CANCEL
      </button>
      <button
        #saveChangesButton
        class="button modal-action-button button-save disabled"
        mat-button
        (click)="saveAllocation()"
        [class.disabled]="disabledSaveButton"
        [disabled]="disabledSaveButton">
        SAVE
      </button>
    </div>
  </ng-template>
  <ng-template #blockNoResult></ng-template>
  <div class="no-filter-selected-session" *ngIf="showNoResults">
    <p>NO RECORDS FOUND.</p>
  </div>
  <div
    class="no-filter-selected-session"
    *ngIf="noFilterSelected; else blockFilterSelected">
    <p>PLEASE,<br />START BY FILTERING A CUSTOMER SHORT</p>
  </div>
  <div class="tabs" *ngIf="this.applyTTo">
    <button
      *ngFor="let row of thead"
      (click)="onChangeTab(row.label, row)"
      [class.active]="row.status">
      {{ row.label }}
    </button>
  </div>
  <br />
  <div *ngFor="let row of thead">
    <app-mkn-to-book
      *ngIf="row.label === 'To Book' && row.status && this.applyTTo"
      [dataFilter]="this.filter"
      [changeTabs]="changeTab"
      [applyed]="noFilterSelected"
      (responseResult)="responseResultBookedAndSellIn($event)">
    </app-mkn-to-book>
    <app-mkn-booked
      *ngIf="row.label === 'Booked' && row.status"
      [dataFilter]="this.filterBooked"
      [selected]="columnsBooked"
      [changeTabs]="changeTab"
      [applyed]="noFilterSelected"
      (responseResult)="responseResultBookedAndSellIn($event)"
      (dataExport)="receiveData($event)">
    </app-mkn-booked>
    <app-mkn-booked
      *ngIf="row.label === 'Sell In' && row.status && this.applyTTo"
      [changeTabs]="changeTab"
      [dataFilter]="this.filterSellIn"
      [selected]="columnsSellIn"
      [applyed]="noFilterSelected"
      (responseResult)="responseResultBookedAndSellIn($event)"
      (dataExport)="receiveData($event)">
    </app-mkn-booked>
  </div>
  <ng-template #dialogConfirmationDiscardSaveChanges>
    <header class="title-modal" matDialogTitle>CONFIRMATION</header>
    <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        NO
      </button>
      <button
        *ngIf="isDiscardChanges"
        class="button modal-action-button"
        mat-button
        matDialogClose="discard">
        YES
      </button>
      <button
        *ngIf="!isDiscardChanges"
        class="button modal-action-button"
        mat-button
        matDialogClose="save">
        YES
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
<div class="container-menu-family">
  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>
