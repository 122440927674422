import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
@Component({
  selector: 'app-information-table',
  templateUrl: './information-table.component.html',
  styleUrls: ['./information-table.component.scss'],
})
export class InformationTableComponent {
  @Input() dataTable: any = {};
  @Input() hideHeader: boolean = false;
  @Input() pullInOrIncreaseValues: {
    label: string;
    props: string;
    mode: boolean;
    value: number;
  }[] = [];
  @Input() typeTable!: any;
  @Input() toggleColumnTableValues!: any;
  @Input() zoomIn: boolean = false;
  @Output('pullInChanged') optionChangedEvent = new EventEmitter();
  @Output('valuePullInOrIncrease') inputChangedEvent = new EventEmitter();

  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: TemplateRef<any> | any;

  dataSource: any = [];
  displayedDef: any[] = [];
  displayedColumns: any[] = [];
  isIncrease: boolean = false;
  messageModalChanges!: string;
  messages: {
    [key: string]: string;
  } = {
    discard: 'Are you sure you want to switch the opportunity type?',
  };
  isDiscardChanges: boolean = false;
  indexRowDELTA: number = 2;
  indexRowPDP: number = 3;

  constructor(
    private cd: ChangeDetectorRef,
    private modalService: ModalActionsService
  ) {
    this.pullInOrIncreaseValues.push(
      {
        label: 'januaryincrease',
        props: 'januaryOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'februaryincrease',
        props: 'februaryOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'marchincrease',
        props: 'marchOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'aprilincrease',
        props: 'aprilOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'mayincrease',
        props: 'mayOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'juneincrease',
        props: 'juneOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'julyincrease',
        props: 'julyOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'augustincrease',
        props: 'augustOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'septemberincrease',
        props: 'septemberOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'octoberincrease',
        props: 'octoberOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'novemberincrease',
        props: 'novemberOpportunity',
        mode: true,
        value: 0,
      },
      {
        label: 'decemberincrease',
        props: 'decemberOpportunity',
        mode: true,
        value: 0,
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTable && changes.dataTable.currentValue) {
      this.dataTable = {
        columns: this.dataTable.table?.columns,
        rows: this.dataTable.table?.rows,
      };
      this.loadSimpleTable();
    }
    if (changes.zoomIn && changes.zoomIn.currentValue) {
      this.dataTable.columns.forEach((header: any) => {
        if (header?.expanded) {
          delete header.expanded;
        }
      });
    }
    this.toggleColumnTable();
  }

  loadSimpleTable() {
    this.dataSource = this.dataTable.rows;
    this.displayedDef = this.dataTable.columns;
    this.displayedColumns = this.dataTable.columns?.map(
      (item: any) => item.props
    );

    if (this.dataSource?.filteredData[1]) {
      Object.entries(this.dataSource?.filteredData[1])?.forEach((row: any) => {
        this.pullInOrIncreaseValues.forEach((key: any) => {
          if (key.props === row[0]) {
            key.value = row[1].value;
            key.mode = row[1].type === 'PULLIN' ? true : false;
          }
        });
      });

      this.displayedColumns = this.displayedDef
        .filter((item: any) =>
          // logica para evitar sideEfect em telas que não tem expansão de colunas (alwaysVisible)
          item.props && item.alwaysVisible
            ? item.props && item.alwaysVisible
            : item.alwaysVisible != false
        )
        .map((item: any) => item.props);
    }
    this.onChangeIncreaseHighlight();
  }

  // expansão da tabela conforme o evento
  toggleColumnTable() {
    const col = this.toggleColumnTableValues[0]
      ? this.toggleColumnTableValues[0]
      : { otherContent: '', expanded: '' };
    const event = this.toggleColumnTableValues[1]
      ? this.toggleColumnTableValues[1]
      : 'hide';

    if (this.displayedDef) {
      const groupColumnsToShowHide = this.displayedDef.filter(
        (item: any) => item.otherContent === col.otherContent
      );
      const columsToShowHide = groupColumnsToShowHide.map(
        (item: any) => item.props
      );
      const indexCol = this.displayedColumns.findIndex(
        (item: any) => item === col?.otherContent
      );
      columsToShowHide.shift();
      if (event === 'show') {
        col.expanded = true;
        groupColumnsToShowHide[0].isFirstCard = true;
        groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
          true;
        groupColumnsToShowHide.forEach((column: any) => {
          column.expanded = true;
        });

        this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
      } else {
        col.expanded = false;
        this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
      }
    }
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  onChangeIncreaseHighlight() {
    let hasIncrease = this.pullInOrIncreaseValues.filter(
      (obj: any) => !obj.mode && obj.value != 0
    );
    this.isIncrease = !!hasIncrease.length;
  }

  updateValuesInTable(
    item: { indexRow: number; columnName: string },
    value: number
  ) {
    this.dataSource.filteredData[item.indexRow][item.columnName].value = value;
  }

  onChangeValueInput(
    item: { indexRow: number; columnName: string },
    value?: any
  ) {
    const valueInput: any = value?.target.value
      ? parseFloat(value?.target.value.replaceAll(',', ''))
      : 0;
    this.updateValuesInTable(item, valueInput);
    this.pullInOrIncreaseValues
      .filter((obj: any) => obj.props === item.columnName)
      .forEach((row: any) => {
        row.value = valueInput;
        row.edit = true;
      });
    this.inputChangedEvent.emit({
      item,
      valueInput,
      values: this.pullInOrIncreaseValues,
    });
    this.cd.detectChanges();
    this.onChangeIncreaseHighlight();
  }

  handlePullInOrIncrease(
    item: { indexRow: number; columnName: string },
    event: any
  ) {
    this.isDiscardChanges = true;
    const dialog = this.getDialogModal('discard');
    dialog.afterClosed().subscribe((result: any) => {
      if (result === 'discard') {
        this.pullInOrIncreaseValues
          .filter((obj: any) => obj.props === item.columnName)
          .forEach((row: any) => {
            row.mode = !event;
            row.edit = true;
            row.value = '';
          });
        this.optionChangedEvent.emit({
          item,
          event: this.isIncrease,
          values: this.pullInOrIncreaseValues,
        });
        this.onChangeIncreaseHighlight();
      }
    });
  }

  checkHighlightOpportunity(item: { indexRow: number; columnName: string }) {
    return (
      item.indexRow === 1 &&
      item.columnName.toLocaleUpperCase().includes('TOTAL') &&
      this.isIncrease
    );
  }

  trackByIndex = (i: number) => i;
}
