import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ThousandSeparatorPipe } from 'src/app/helpers/thousand-separator-pipe.pipe';
import { FormatService } from 'src/app/services/generic/format.service';


@Component({
  selector: 'app-table-expandable',
  templateUrl: './table-expandable.component.html',
  styleUrls: ['./table-expandable.component.scss'],
  providers: [ThousandSeparatorPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpandableComponent implements OnInit {
  @Input() dataTable: any = {};
  @Input() allocationForm!: FormGroup;
  @Input() indexGroup: number = 0;
  @Input() hideHeader: boolean = false;
  @Input() displayedColumns: any[] = [];
  @Input() filterKaRetail: any = false;
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @Output() onChange = new EventEmitter<any>();
  @ViewChild('tableHtml') tableHtml!: ElementRef<HTMLInputElement>;

  translateYAbsoluteItem: number = 0;
  dataSource: any = [];
  displayedDef: any[] = [];
  allocationFormArray!: FormArray;
  allFormControlNames: { [key: string]: string }[] = [];
  isInputChanged = false;
  nameAllocationFormArray!: string;
  hasRowsExpanded: boolean = false;
  col: any;
  indexRow: number = 0;

  currentFocusedInput: HTMLInputElement | null = null;
  clickCount: number = 0;
  secondClickInput!: boolean;
  enterIntoOrOut: any;
  backgroundYellow!: boolean;
  isTypingNewValue: any;
  value!: string;

  constructor(
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private formatService: FormatService,
    private thousandSeparatorPipe: ThousandSeparatorPipe
    ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataTable?.currentValue) {
      this.loadTable(changes.dataTable.currentValue);
    }
    if (changes.displayedColumns?.currentValue) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.tableHtml?.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer?.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0]?.clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0]?.clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0]?.clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0]?.clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple + 245;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableHtml.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  loadTable(updateDataTable?: any) {
    [this.nameAllocationFormArray] = Object.keys(this.allocationForm.controls);
    this.dataTable = updateDataTable || this.dataTable;
    this.dataSource = this.dataTable.table.rows;
    this.displayedDef = this.dataTable.table.columns;
    this.displayedColumns = this.displayedDef
      .filter((item: any) => item.props === item.otherContent)
      .map((item: any) => item.props);
    this.allocationFormArray = this.allocationForm.get(
      this.nameAllocationFormArray
    ) as FormArray;
    this.createFormControlNames();
  }

  toggleColumnTable(col: any, event: string) {
    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContent === col.otherContent
    );
    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );
    const indexCol = this.displayedColumns.findIndex(
      (item: any) => item === col?.otherContent
    );

    columsToShowHide.shift();

    if (event === 'show') {
      col.expanded = true;
      groupColumnsToShowHide[1].isFirstCard = true;
      groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
        true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expanded = true;
      });

      this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
      this.hasRowsExpanded = true;
    } else {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
      this.hasRowsExpanded = false;
    }
  }

  isCalculation(value: any) {
    return value && value.includes('=');
  }


  formatValueOrExpression(columnData: any) {
    if (columnData.expression) {
      return this.formatExpression(columnData.expression );
    } else {
      return this.thousandSeparatorPipe.transform(columnData.value?.toString());
    }
  }

  private formatExpression(expression: string): string {
    if (!expression) return '';

    let formattedExpression = expression;

    return formattedExpression;
  }

  onInput(event: any, item: { indexRow: number; columnName: string, value?: any }) {
    let valueInput: any = event.target.value
      ? event.target.value.replaceAll(',', '')
      : '0';

    if(valueInput.startsWith('=0') && event.inputType === 'deleteContentBackward') {
      valueInput = '='
    }

    if (valueInput === '=' && !this.secondClickInput) {
      (event.target as HTMLInputElement).value = '0';
      return;
    }

    this.newValueInput(event, this.dataSource.filteredData[item.indexRow][item.columnName], item, valueInput);

    const cleanValue = valueInput ? valueInput.replace(/=/, '') : '';
    this.value = this.formatService.formattingValuesInputWithComma(event, valueInput, cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = this.formatService.formatNumberWithCommas(this.value);
    this.cdr.markForCheck();

    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault();
      event.target.selectionStart;
    }
  }

  validateInput(event: any){
    const allowedKeys = [
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Backspace',
      'Delete',
      'Enter',
      '=',
      '+',
      '-'
    ];

    if (/[a-zA-Z.]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }
  }

  onChangeEvent(item: { indexRow: number; columnName: string }, event?: any) {
    const dataRowColumn = this.dataSource.filteredData[item.indexRow][item.columnName];
    const inputValue: string = event.target.value.trim();
    switch (event.key) {
      case 'ArrowUp':
        return;
      case 'ArrowDown':
        return;
      case 'ArrowLeft':
        return;
      case 'ArrowRight':
        return;
    }

    this.cd.detectChanges();
  }



  handleFocusEvent(event: FocusEvent): void {
    this.cd.detectChanges();
  }

  createFormControlNames() {
    this.allocationFormArray?.controls.forEach(
      (formGroup: any, indexRow: number) => {
        Object.keys(formGroup.controls).forEach((formControlName: string) => {
          const columnName: string = formControlName.split('-')[4];
          if (!this.allFormControlNames[indexRow]) {
            this.allFormControlNames[indexRow] = {};
          }
          this.allFormControlNames[indexRow][columnName] = formControlName;
        });
      }
    );
  }

  getFormControlName(item: { indexRow: number; columnName: string }) {
    const formControlName =
      this.allFormControlNames[item.indexRow][item.columnName];
    return formControlName;
  }

  getCurrentValueFormControl(item: { indexRow: number; columnName: string }) {
    const formGroup = this.allocationFormArray.get(`${item.indexRow}`);
    const formControlName =
      Object.keys(formGroup?.value).find((key: string) =>
        key.includes(item.columnName)
      ) || '';
    let returnNumber = parseFloat(formGroup?.get(formControlName)?.value);
    return returnNumber;
  }

  isCurrentWeek(week: any) {
    if (week.indexOf('WK') > -1) {
      return true;
    }
    return false;
  }

  getAddRowId(indexRow: number) {
    if (this.hasRowsExpanded) {
      return indexRow;
    }
    return indexRow;
  }

  mouseEnter(indexRow: number, row: any) {
    let kaValue: string = row['sab'].ka;
    kaValue = kaValue == '-' ? '' : kaValue;
    const tableRows = document.querySelectorAll('td');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.add(`hover-row${kaValue}`);
      }
    });
  }

  mouseLeave(indexRow: number, row: any) {
    let kaValue: string = row['sab'].ka;
    kaValue = kaValue == '-' ? '' : kaValue;
    const tableRows = document.querySelectorAll('td');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.remove(`hover-row${kaValue}`);
      }
    });
  }

  isSab(sab: any) {
    if (sab.title === 'SAB') {
      return true;
    }
    return false;
  }

  isNotEmpty(value: number) {
    if (value >= 0 || value <= 0) return true;
    return false;
  }

  isOrigin(value: any) {
    if (value === 'JAG' || value === 'MAN') return true;
    return false;
  }

  handleInputClick(event: any) {
    const target = event.target as HTMLInputElement;

    if (target && target.tagName === 'INPUT') {
        if (this.currentFocusedInput && this.currentFocusedInput !== target) {
            this.currentFocusedInput.classList.remove('input-highlight');
            this.currentFocusedInput.classList.remove('color-click');
            this.clickCount = 0;
            this.backgroundYellow = false;
            this.enterIntoOrOut = true;
        }

        this.currentFocusedInput = target;
        this.clickCount++;

        if (this.clickCount === 1) {
            this.secondClickInput = false;
            target.classList.add('color-click');
            target.classList.add('custom-input');
            target.classList.remove('input-highlight');
            this.backgroundYellow = false;
            document.querySelectorAll('input').forEach(input => {
              input.addEventListener('focus', () => {
                this.isTypingNewValue = false;
              });
            });

          } else if (this.clickCount === 2) {
            this.secondClickInput = true;
            target.classList.add('input-highlight');
            target.classList.remove('custom-input');
            target.classList.remove('color-click');
            target.focus();
            this.backgroundYellow = true;
            document.querySelectorAll('input').forEach(input => {
              input.addEventListener('focus', () => {
                this.isTypingNewValue = true;
              });
            });
        }
    }
  }

  handleInputBlur(event: any, kaProduct: any, col: any, item: { indexRow: number; columnName: string, value?: any }) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value.trim();

    target.classList.add('custom-input');
    if (inputValue.includes('=')) {
      this.calculateAndSetValue(target, kaProduct, col, item, event);
      const enterEvent = new KeyboardEvent('keyup', { key: 'Enter' });
      target.dispatchEvent(enterEvent);
    }
  }

  handleEnter(event: KeyboardEvent, target: HTMLInputElement, kaProduct: any, col: any) {
    if (event.key === 'Enter') {
      this.calculateAndSetValue(target, kaProduct, col);
    }
  }

  calculateAndSetValue(target: HTMLInputElement, kaProduct: any, col: any, item?: any, event?: any) {
    try {
      const inputValue = target.value.trim();

      if (inputValue === '=') {
        target.value = kaProduct[col.props].value.toLocaleString('en');
        return;
      }

      const expression = inputValue.split('=')[1]?.trim() || '';
      const sanitizedExpression = expression.replace(/,/g, '');
      let adjustedExpression = sanitizedExpression.replace(/\b0+(\d+)/g, '$1');
      adjustedExpression = adjustedExpression.replace(/([\+\-])(\s*)$/, '$10$2');

      // Definir adjustedExpression como 0 se sanitizedExpression estiver vazio
      if (!sanitizedExpression) {
        adjustedExpression = '0';
      }

      const result = eval(adjustedExpression);
      const formattedResult = result.toLocaleString('en');
      target.value = formattedResult;

      kaProduct[col.props].value = Number(formattedResult);

      this.onChange.emit({
        value: formattedResult,
      });

      const dataRowColumn = this.dataSource.filteredData[item.indexRow][item.columnName];
      const value = {
        idProduct: dataRowColumn.idProduct,
        salesModel: dataRowColumn.salesModel,
        origin: this.dataSource.filteredData[item.indexRow].origin.value,
        col: item.columnName,
        ka: dataRowColumn.ka,
        oldValue: formattedResult,
        value: formattedResult
      };

      this.dataSource.filteredData[item.indexRow][item.columnName].value = result;
      this.inputChangedEvent.emit(value);
      this.onInput(event, { indexRow: item.indexRow, columnName: item.columnName, value: formattedResult });
      this.cdr.detectChanges();

    } catch (error) {
      console.error('Invalid expression', error);
    }
  }

  handleDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    // Verifica se o clique foi fora do input atual
    if (this.currentFocusedInput && !this.currentFocusedInput.contains(target)) {
        this.clickCount = 0;
        this.currentFocusedInput.classList.remove('color-click');
        this.currentFocusedInput.classList.remove('input-highlight');
        this.enterIntoOrOut = true;
        this.backgroundYellow = false;
        this.currentFocusedInput = null;
        this.isTypingNewValue = true;
    }
  }

  restrictCharacters(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    const allowedKeys = [
      '=', '+', '-', '/', '*', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete',
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];

    const equalsCount = (inputValue.match(/=/g) || []).length;

    // Verificar se o caractere atual é um operador
    const operators = ['+', '-', '/', '*'];
    const lastChar = inputValue.slice(-1);
    const isLastCharOperator = operators.includes(lastChar);

    if ((event.key === '=' && equalsCount >= 1) ||
        (isLastCharOperator && operators.includes(event.key)) || // Impedir operadores consecutivos
        (equalsCount === 0 && (event.key === '/' || event.key === '*'))) {
      event.preventDefault();
      return;
    }

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    if (inputValue === '0' && event.key === 'Backspace') {
      event.preventDefault();
      return;
    }
  }

  handleKeyDownEvent(
    event: KeyboardEvent,
    col: any,
    indexRow: any,
    row?: any,
    indexRowList?: any,
    ka?: any,
    columnName?: any) {
    const tableContainer = document.querySelector('.table-container');
    if (tableContainer instanceof HTMLElement) {
      tableContainer.addEventListener('click', () => {
        const highlightedCell = document.querySelector('.highlighted-cell');
        if (highlightedCell instanceof HTMLElement) {
          highlightedCell.style.backgroundColor = '';
          highlightedCell.classList.remove('highlighted-cell');
        }
      });
    }

    const inputElement = event.target as HTMLInputElement;
    const inputLength = inputElement.value;

    if (inputLength === '0' && event.key === 'Backspace') {
      event.preventDefault();
      return;
    }

    const target = event.target as HTMLInputElement;
    this.backgroundYellow = false;
    if (!this.secondClickInput) {
      switch (event.key) {
        case 'ArrowUp':
          this.moveCursorUp(col, indexRow, row, indexRowList, ka);
          event.preventDefault();
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        case 'ArrowDown':
          this.moveCursorDown(col, indexRow, row, indexRowList, ka);
          event.preventDefault();
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        case 'ArrowLeft':
          this.moveCursorLeft(col, indexRow, row, indexRowList, ka);
          event.preventDefault();
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
        case 'ArrowRight':
          this.moveCursorRight(col, indexRow, row, indexRowList, ka);
          event.preventDefault();
          target.classList.remove('color-click');
          this.isTypingNewValue = false;
          break;
      }
      this.secondClick(event, indexRow, indexRowList, columnName, row, col)
    } else if(this.secondClickInput) {
      this.backgroundYellow = true;
      this.secondClick(event, indexRow, indexRowList, columnName, row, col)
    }

    this.handleEnterBackground(event)
  }

  secondClick(
    event: any,
    indexRow: number,
    indexRowList: number,
    columnName: string,
    row: any,
    col: any) {
      const inputValue: string = (event.target as HTMLInputElement).value.trim();
      const inputElement = event.target as HTMLInputElement;
      const inputLength = inputElement.value.length;

      const allowedKeys = [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Enter',
        '=',
        '+',
        '-'
      ];

      if ((event.ctrlKey || event.metaKey) && ['c', 'v', 'x'].includes(event.key)) {
        return;
      }

      if (/[a-zA-Z.]/.test(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
        return;
      }

      if (event.key === 'Backspace' && !this.secondClickInput) {
        (event.target as HTMLInputElement).value = '0';
        return;
      }

      if (inputValue === '0' && event.key === 'Backspace') {
        event.preventDefault();
        return;
      }

      console.log(inputValue)

      // no segundo click não apaga todos valores do input ao digitar
      if (this.clickCount >= 2) {
        this.isTypingNewValue = true;
      }

      if (event.key === 'Enter' && this.clickCount >= 2 ) {
        this.isTypingNewValue = false;
      }

      if (this.handleEqualsKey(event, inputValue)) return;

      if (event.key === 'Tab') {
        event.preventDefault();
        return;
      }

      this.restrictCharacters(event);

      if (event.ctrlKey) {
        switch (event.key) {
          case 'c':
          case 'v':
          case 'x':
            return;
        }
      }

      if ((event.key === '+' || event.key === '-') && !inputValue.includes('=')) {
        event.preventDefault();
        return;
      }

      if (event.key === 'Enter' && inputValue.includes('=')) {
        event.preventDefault();
        this.backgroundYellow = true;

        let lastValue = this.dataSource.filteredData[indexRow][columnName].value;

        if (inputValue === '=') {
          (event.target as HTMLInputElement).value = lastValue.toLocaleString('en')
          return;
        }

        try {
          const expression = inputValue.split('=')[1].trim();
          const sanitizedExpression = expression.replace(/,/g, '');
          let adjustedExpression = sanitizedExpression.replace(/\b0+(\d+)/g, '$1');
          adjustedExpression = adjustedExpression.replace(/([\+\-])(\s*)$/, '$10$2');
          const result = eval(adjustedExpression);
          const formattedResult = result.toLocaleString('en');
          (event.target as HTMLInputElement).value = formattedResult;
          const dataRowColumn = this.dataSource.filteredData[indexRow][columnName];
          this.dataSource.filteredData[indexRow][columnName].value = formattedResult;

          const value = {
            idProduct: dataRowColumn.idProduct,
            salesModel: dataRowColumn.salesModel,
            origin: this.dataSource.filteredData[indexRow].origin.value,
            col: columnName,
            ka: dataRowColumn.ka,
            oldValue: formattedResult,
            value: formattedResult,
          };

          this.onChange.emit({
            value: formattedResult,
          });

          this.zone.run(() => {
            this.cd.detectChanges();
          });

          this.inputChangedEvent.emit(value);
          this.onInput(event, { indexRow: indexRow, columnName: columnName, value: formattedResult });
          this.cd.detectChanges();
        } catch (error) {
          console.error('Invalid expression', error);
        }
        this.isTypingNewValue = false;

        return;
      }
  }

  newValueInput(event: any, dataRowColumn: any, item: any, valueInput: any) {
    const inputElement = event.target as HTMLInputElement;
    const maxLength = 21;
    let newValue = event.data;

    if (newValue === null) {
      newValue = '0';
    }

    let value: any;
    if (!this.isTypingNewValue && this.clickCount === 1 && !valueInput.includes('=')) {
      inputElement.value = newValue;
      valueInput = newValue;
      this.isTypingNewValue = true;
      value = {
        idProduct: dataRowColumn.idProduct,
        salesModel: dataRowColumn.salesModel,
        origin: this.dataSource.filteredData[item.indexRow].origin.value,
        col: item.columnName,
        ka: dataRowColumn.ka,
        oldValue: valueInput,
        value: valueInput,
        click: 1,
      };
    } else {
      value = {
        idProduct: dataRowColumn.idProduct,
        salesModel: dataRowColumn.salesModel,
        origin: this.dataSource.filteredData[item.indexRow].origin.value,
        col: item.columnName,
        ka: dataRowColumn.ka,
        oldValue: valueInput,
        value: valueInput,
        click: 2,
      };
    }

    this.dataSource.filteredData[item.indexRow][item.columnName].value = valueInput

    if (inputElement.value.length > maxLength) {
      inputElement.value = inputElement.value.slice(0, maxLength);
    }

    this.inputChangedEvent.emit(value);
    this.cd.detectChanges();
  }

  handleEqualsKey(event: any, inputValue: string): boolean {
    if (event.key === '=' && inputValue.indexOf('=') !== 0) {
      event.preventDefault();
      const newValue = `=${inputValue}`;
      const newValueOneClick = `=`;
      (event.target as HTMLInputElement).value = this.clickCount >= 2 ? newValue : newValueOneClick;
      event.target.selectionStart = event.target.selectionEnd = newValue.length;
      this.isTypingNewValue = true;
      return true;
    }
    return false;
  }

  handleEnterBackground(event: any) {
    const target = event.target as HTMLInputElement;
    this.currentFocusedInput = target;

    if (event.key !== undefined) {
      if (event.key === 'Enter' && this.backgroundYellow) {
          // Alternar para fundo amarelo
          this.secondClickInput = false;
          this.currentFocusedInput.classList.add('color-click');
          this.currentFocusedInput.classList.add('custom-input');
          this.currentFocusedInput.classList.remove('input-highlight');
          this.clickCount = 1;
      } else if (event.key === 'Enter' && !this.backgroundYellow) {
          // Alternar para borda amarela
          this.secondClickInput = true;
          this.currentFocusedInput.classList.add('input-highlight');
          this.currentFocusedInput.classList.remove('custom-input');
          this.currentFocusedInput.classList.remove('color-click');
          this.clickCount = 2;
      }
    }
  }

  moveCursorUp(col: any, indexRow: any, row: any, indexRowList: any, ka: any) {
    const targetIndex = indexRow - 1;
    const currentRow: any = this.dataSource.filteredData[indexRow];
    const previousRow: any = this.dataSource.filteredData[targetIndex];
    if (previousRow[col.props].isDisabled || previousRow[col.props].enableMLS || previousRow[col.props].enableInput) {
      let count = 0;
      for(let i = targetIndex; i > 0; i--){
        if(this.dataSource.filteredData[i][col.props].enableMLS || this.dataSource.filteredData[i][col.props].isDisabled){
          count += 1;
        } else {
          break;
        }
      }
      let result = targetIndex - count;
      if(result > 0){
        const rowId: string = col.props + '_' + result + '_' + this.dataSource.filteredData[result][col.props].ka;
        const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
        if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
        }

        const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector(`#${rowId}`);
        if (inputElement) {
          inputElement.focus();
          setTimeout(() => {
            inputElement.setSelectionRange(0, 0);
            if (inputElement.value === '0') {
              inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
            }
          }, 0);
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
          return;
        }
      }
    } else {
      const rowId: string = col.props + '_' + targetIndex + '_' + previousRow[col.props].ka;
      const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
      if (previousHighlightedCell) {
        previousHighlightedCell.style.backgroundColor = '';
        previousHighlightedCell.classList.remove('highlighted-cell');
      }

      const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector(`#${rowId}`);
      if (inputElement) {
        inputElement.focus();
        setTimeout(() => {
          inputElement.setSelectionRange(0, 0);
          if (inputElement.value === '0') {
            inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
          }
        }, 0);
        inputElement.style.backgroundColor = '#fdf1c5';
        inputElement.classList.add('highlighted-cell');
        return;
      }
    }
  }
  moveCursorDown(col: any, indexRow: any, row: any, indexRowList: any, ka: any) {
    const targetIndex = indexRow + 1;
    const currentRow: any = this.dataSource.filteredData[indexRow];
    const nextRow: any = this.dataSource.filteredData[targetIndex];
    if (nextRow[col.props].isDisabled || nextRow[col.props].enableMLS || nextRow[col.props].enableInput) {
      let count = 0;
      for(let i = targetIndex; i < this.dataSource.filteredData.length; i++){
        if(this.dataSource.filteredData[i][col.props].enableMLS || this.dataSource.filteredData[i][col.props].isDisabled){
          count += 1;
        }else{
          break;
        }
      }
      let result = targetIndex + count;
      if(result <= this.dataSource.filteredData.length - 1){
        const rowId: string = col.props + '_' + result + '_' + this.dataSource.filteredData[result][col.props].ka;
        const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
        if (previousHighlightedCell) {
            previousHighlightedCell.style.backgroundColor = '';
            previousHighlightedCell.classList.remove('highlighted-cell');
        }

        const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector(`#${rowId}`);
        if (inputElement) {
            inputElement.focus();
            setTimeout(() => {
              inputElement.setSelectionRange(0,0);
            }, 0);
            inputElement.style.backgroundColor = '#fdf1c5';
            inputElement.classList.add('highlighted-cell');
            return;
        }
      }
    }else{
      const rowId: string = col.props + '_' + targetIndex + '_' + nextRow[col.props].ka;
      const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
      if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
      }

      const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector(`#${rowId}`);
      if (inputElement) {
          inputElement.focus();
          setTimeout(() => {
            inputElement.setSelectionRange(0,0);
          }, 0);
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
          return;
      }
    }
  }
  moveCursorLeft(col: any, indexRow: any, row: any, indexRowList: any, ka: any) {
    const currentRow: any = this.dataSource.filteredData[indexRow];
    let indexColumnActual: number | null = null;
    this.displayedColumns.forEach((item: any, index = 0)=>{
      if(item == col.props){
        indexColumnActual = index;
      }
    });
    if (indexColumnActual !== null && indexColumnActual > 0) {
      const previousColumnIndex = indexColumnActual - 1;
      const previousColumn = currentRow[this.displayedColumns[previousColumnIndex]];
      if(previousColumn.enableInput) {
      const rowIdColumn = previousColumn.indiceKey + '_' + indexRow + '_' + ka;
      const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
      if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
      }

      const inputElement: HTMLInputElement =
          this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
      if (inputElement) {
          inputElement.focus();
          setTimeout(() => {
            inputElement.setSelectionRange(0,0);
          }, 0);
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
      }
      } else {
        for (let i = indexColumnActual - 1; i < this.displayedColumns.length; i--) {
          if (currentRow[this.displayedColumns[i]].enableInput) {

            const previousColumn = currentRow[this.displayedColumns[i]];
              let rowIdColumn: any = '';
              if(currentRow[previousColumn.indiceKey]?.hasOwnProperty('rowSpan')) {
                rowIdColumn = previousColumn.indiceKey + '_' + currentRow[previousColumn.indiceKey]?.id;
              } else {
                rowIdColumn = previousColumn.indiceKey + '_' + indexRow + '_' + ka;
              }
              const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
              if (previousHighlightedCell) {
                  previousHighlightedCell.style.backgroundColor = '';
                  previousHighlightedCell.classList.remove('highlighted-cell');
              }

              const inputElement: HTMLInputElement =
                  this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
              if (inputElement) {
                  inputElement.focus();
                  setTimeout(() => {
                    inputElement.setSelectionRange(0,0);
                  }, 0);
                  inputElement.style.backgroundColor = '#fdf1c5';
                  inputElement.classList.add('highlighted-cell');
              }
            break;
          }
        }
      }
    }
  }
  moveCursorRight(col: any, indexRow: any, row: any, indexRowList: any, ka: any) {
    const currentRow: any = this.dataSource.filteredData[indexRow];
    let indexColumnActual: number | null = null;
    this.displayedColumns.forEach((item: any, index = 0)=>{
      if(item == col.props){
        indexColumnActual = index;
      }
    });
    if (indexColumnActual !== null && indexColumnActual > 0) {
      const previousColumnIndex = indexColumnActual + 1;
      const previousColumn = currentRow[this.displayedColumns[previousColumnIndex]];
      if(previousColumn.enableInput) {
      const rowIdColumn = previousColumn.indiceKey + '_' + indexRow + '_' + ka;
      const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
      if (previousHighlightedCell) {
          previousHighlightedCell.style.backgroundColor = '';
          previousHighlightedCell.classList.remove('highlighted-cell');
      }

      const inputElement: HTMLInputElement =
          this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
      if (inputElement) {
          inputElement.focus();
          setTimeout(() => {
            inputElement.setSelectionRange(0,0);
          }, 0);
          inputElement.style.backgroundColor = '#fdf1c5';
          inputElement.classList.add('highlighted-cell');
      }
      } else {
        for (let i = indexColumnActual + 1; i < this.displayedColumns.length; i++) {
          if (currentRow[this.displayedColumns[i]].enableInput) {

            const previousColumn = currentRow[this.displayedColumns[i]];
              let rowIdColumn: any = '';
              if(currentRow[previousColumn.indiceKey]?.hasOwnProperty('rowSpan')) {
                rowIdColumn = previousColumn.indiceKey + '_' + currentRow[previousColumn.indiceKey]?.id;
              } else {
                rowIdColumn = previousColumn.indiceKey + '_' + indexRow + '_' + ka;
              }
              const previousHighlightedCell: HTMLInputElement = this.elementRef.nativeElement.querySelector('.highlighted-cell');
              if (previousHighlightedCell) {
                  previousHighlightedCell.style.backgroundColor = '';
                  previousHighlightedCell.classList.remove('highlighted-cell');
              }

              const inputElement: HTMLInputElement =
                  this.elementRef.nativeElement.querySelector(`#${rowIdColumn}`);
              if (inputElement) {
                  inputElement.focus();
                  setTimeout(() => {
                    inputElement.setSelectionRange(0,0);
                  }, 0);
                  inputElement.style.backgroundColor = '#fdf1c5';
                  inputElement.classList.add('highlighted-cell');
              }
            break;
          }
        }
      }
    }
  }

  borderCalc(ele: any){
    let convert: number = parseFloat(ele.value);
    if(convert === 0){
      ele.isCalculationEnabled = false;
    }
    return ele.isCalculationEnabled;
  }
}

