import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public isModalOpenSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isModalOpen$ = this.isModalOpenSubject.asObservable();

  openModal() {
    this.isModalOpenSubject.next(true);
  }

  closeModal() {
    this.isModalOpenSubject.next(false);
  }

  isModalOpen(): boolean {
    return this.isModalOpenSubject.value;
  }
}
