<div class="container-table-filtering">
  <mat-form-field appearance="outline">
    <mat-label>Search</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Type Your Search Here"
      #input />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <div class="table-filtering mat-elevation-z4">
    <div class="table-container">
      <table mat-table [dataSource]="tableData">
        <ng-container matColumnDef="alert">
          <th mat-header-cell *matHeaderCellDef>Alerts</th>
          <td mat-cell *matCellDef="let element">
            <div class="alert-title">
              <p class="alert-title-cursor" (click)="viewDetails(element)">
                {{ element.alert }}
              </p>
              <div
                *ngIf="element.warning === true"
                mat-raised-button
                matBadge="3"
                matBadgePosition="before"
                matBadgeColor="accent"
                class="img-button"
                (click)="openFirstModal()"></div>
              <div
                class="check-container"
                *ngIf="
                  !element.warning &&
                  element.title === 'ORDER BOOK FAIL REDUCTION'
                "></div>
            </div>
            <div class="alert-subtitle">
              {{ element.subtitleResume }}
            </div>
          </td>
        </ng-container>

        <!-- hidden-header para esconder a header  -->
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsFilterTable"
          class="hidden-header"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsFilterTable"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>

    <mat-paginator
      [pageSize]="50"
      [pageIndex]="paginationValues.pageIndex"
      [length]="paginationValues.totalRows"
      showFirstLastButtons
      [hidePageSize]="true"
      class="pagination-alerts"
      (page)="handlePaginator($event)">
    </mat-paginator>
  </div>
</div>

<div class="container-modal-table-filtering">
  <!-- Primeiro Modal -->
  <div
    class="modal-backdrop cdk-overlay-dark-backdrop"
    *ngIf="isFirstModalOpen$ | async"
    (click)="closeFirstModal()">
    <div class="modal" (click)="preventClose($event)">
      <div class="modal-content-container">
        <p class="modal-title">WARNING</p>
        <p class="modal-content">
          There are a order book Data reduction. Do you want confirm this
          reduction?
        </p>
      </div>
      <div class="modal-actions">
        <button class="button-no" (click)="closeFirstModal()">NO</button>
        <button class="button-yes" (click)="openConfirmation()">YES</button>
      </div>
    </div>
  </div>

  <!-- Segundo Modal -->
  <div
    class="modal-backdrop"
    *ngIf="isSecondModalOpen$ | async"
    (click)="closeSecondModal()">
    <div class="second-modal" (click)="preventClose($event)">
      <div class="modal-content-container">
        <p class="modal-title">WARNING</p>
        <p class="modal-content">
          This action can be undone. Are you sure do you want proceed and
          confirm?
        </p>
      </div>
      <div class="modal-actions">
        <button class="button-cancel" (click)="closeSecondModal()">
          CANCEL
        </button>
        <button
          class="button-confirm"
          (click)="closeSecondModalWithConfirmation()"
          aria-label="Show an example snack-bar">
          CONFIRM
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogConfirmation>
  <div class="container-modal-notifications">
    <div class="container-export">
      <h4 class="header-modal-notifications">
        {{ modalNotificationViewDetails.alert }}
      </h4>

      <span
        class="export-details-icon"
        (click)="exportTable()"
        *ngIf="isSubtitleArray()">
        <button
          class="export-button"
          matTooltip="Export"
          matTooltipPosition="left"></button>
      </span>
    </div>

    <span
      class="date-modal-notifications"
      *ngIf="modalNotificationViewDetails.isRecent"
      >{{ modalNotificationViewDetails.minutesAgo }}</span
    >
    <span
      class="date-modal-notifications"
      *ngIf="!modalNotificationViewDetails.isRecent"
      >{{ modalNotificationViewDetails.createdAt }}</span
    >

    <div *ngIf="isSubtitleArray()">
      <div
        *ngFor="let section of modalNotificationViewDetails.subtitleComplete"
        class="message-order-book"
        [class.large-content]="isSubtitleArrayLarge(section.content)">
        <h4>{{ section.title }}:</h4>
        <div class="container-p">
          <p>{{ section.content }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="!isSubtitleArray()">
      <p class="value-modal-notifications">
        {{ modalNotificationViewDetails.subtitleComplete }}
      </p>
    </div>

    <mat-dialog-actions>
      <button
        class="button modal-cancel-button"
        (click)="elementRead(modalNotificationViewDetails)"
        mat-button
        matDialogClose>
        CLOSE
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
