import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CardInterface } from 'src/app/components/cards/card.interface';
import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { TransformText } from 'src/app/helpers/transformText';
import { Channel } from 'src/app/interfaces/channel.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class ChannelComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  searchInputs!: InputBase<string>[];
  regexCheck = new TransformText();
  dataSource: any;
  displayedColumns: any;
  result!: ResultInterface;
  channelForm: FormGroup;
  isEdit: boolean = false;
  hasPermissionCreate: boolean = false;
  channelsEntities: any[] = [];
  pagination: Pagination;
  filter: any = { sort: 'channel', pageName: PAGE_NAME_RESOURCE.channel };
  crudService: CrudService<Channel>;
  geosEntities: any[] = [];
  countriesEntities: any[] = [];
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  filteredOptions!: Observable<any[]>;
  userPermissions: Permissions[] = [];
  currentUser!: any;
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.channel },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.channel },
    },
    {
      entity: 'channel',
      query: { sort: 'channel', pageName: PAGE_NAME_RESOURCE.channel },
    },
  ];
  messageModalDelete!: string;

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private authService: AuthService
  ) {
    this.pagination = { page: 0, size: 10 };
    this.crudService = new CrudService<Channel>(this.http, 'channel');
    this.channelForm = this.formBuilder.group({
      id: [''],
      channel: ['', Validators.required],
      description: ['', Validators.required],
      country_id: ['', Validators.required],
    });
    this.searchInputs = [
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'country_id',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'channel',
        hover: 'Channel',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];

    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('channel');
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geo_id: this.currentUser.geoId,
      country_id: this.currentUser.countryId,
    };
  }

  ngOnInit(): void {
    this.loadData();
    this.filteredOptions =
      this.channelForm.controls.country_id.valueChanges.pipe(
        startWith(''),
        map(value => this.filterOptions(value))
      );
  }

  private filterOptions(value: string): any[] {
    const filterValue = value ? value.toLocaleUpperCase() : '';
    return this.countriesEntities.filter((option: any) =>
      option.country.toLocaleUpperCase().includes(filterValue)
    );
  }

  loadData() {
    [this.currentUser] = this.authService.getUserPermissions();
    this.getChannels(this.pagination || undefined);
    this.getDataFilter();
  }

  getChannels(page?: any, params?: any) {
    this.spinner.show();
    this.crudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        this.updateComponents(response);
        this.spinner.hide();
      });
  }

  getDataFilter() {
    const countriesFilter: any[] = [];
    const channelsFilter: any[] = [];
    const geosFilter: any[] = [];

    this.spinner.show();
    this.crudService
      .getDataFilters(this.entities)
      .subscribe((response: any) => {
        [this.geosEntities, this.countriesEntities, this.channelsEntities] =
          response;
        const initialValueGeoFilter: { [key: string]: any } = {};
        const initialValueCountryFilter: { [key: string]: any } = {};

        this.geosEntities.forEach((geo: any) => {
          geosFilter.push({ key: geo.geo, value: geo.geo, id: geo.id });
          if (geo.id === this.currentUser.geoId) {
            initialValueGeoFilter.value = geo.geo;
            initialValueGeoFilter.id = geo.id;
          }
        });

        const selectGeo = new SelectSearchInput({
          key: 'geo_id',
          hover: 'GEO',
          type: 'text',
          hasAutocomplete: true,
          options: geosFilter,
          value: initialValueGeoFilter.value,
          disabled: true,
          notClearable: true,
        });

        this.countriesEntities.forEach((country: any) => {
          countriesFilter.push({
            key: country.country,
            value: country.country + ' - ' + country.iso_code,
          });
          if (country.id === this.currentUser.countryId) {
            initialValueCountryFilter.value = country.country;
            initialValueCountryFilter.id = country.id;
          }
        });

        const selectCountry = new SelectSearchInput({
          key: 'country_id',
          hover: 'Country',
          type: 'text',
          hasAutocomplete: true,
          options: countriesFilter,
          value: initialValueCountryFilter.value,
          disabled: true,
          notClearable: true,
        });

        this.channelsEntities.forEach((channel: any) => {
          channelsFilter.push({
            key: channel.id,
            value: channel.channel,
            id: channel.id,
          });
        });

        this.searchInputs[0] = selectGeo;
        this.searchInputs[1] = selectCountry;
        this.searchInputs[2].options = channelsFilter;
        this.searchInputs = [...this.searchInputs];
      });
  }

  updateComponents(channel: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(channel.rows)),
      table: {
        columns: [
          {
            label: 'geo',
            prop: 'geos',
            sticky: true,
            style: { width: '15%' },
          },
          {
            label: 'country',
            prop: 'countries',
            sticky: true,
            style: { width: '15%', padding: '8px' },
          },
          {
            label: 'channel',
            prop: 'channel',
            sticky: true,
            style: { width: '25%', 'word-wrap': 'break-word', padding: '8px' },
          },
          {
            label: 'description',
            prop: 'description',
            sticky: true,
            style: { width: '40%', padding: '8px' },
          },
        ],
        rows: new MatTableDataSource<Channel>(
          this.dataTables(JSON.stringify(channel.rows))
        ),
      },
      pageIndex: channel.page,
      pageSize: channel.totalRows,
      pagesTotal: channel.totalPages,
    };
    const actions = this.permissionService.setTablePermissions('channel');
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataTables(data: any) {
    const channel = JSON.parse(data);
    const dataTables: any[] = [];
    channel.forEach((item: any) => {
      let countCountry: string = '';
      let countGeo: string = '';
      const countries = [item.countries];
      countries.forEach((value: any, index: number) => {
        countCountry += `${value.country}${
          index + 1 === countries.length ? '' : ', '
        }`;
        const geos = [value.geos];
        geos.forEach((valueGeo: any, indexGeo: number) => {
          countGeo += `${valueGeo.geo}${
            indexGeo + 1 === geos.length ? '' : ', '
          }`;
        });
      });
      item.geos = countGeo;
      item.countries = countCountry;
      item.active = true;
      dataTables.push(item);
    });
    return [
      ...dataTables.sort((current: any, next: any) =>
        current.geos.localeCompare(next.geos)
      ),
    ];
  }

  dataCards(data: any) {
    const channel = JSON.parse(data);
    const dataCards: CardInterface[] = [];
    const actionCards =
      this.permissionService.setActionsCardsPermission('channel');
    channel.forEach((item: Channel) => {
      dataCards.push({
        id: item.id,
        title: item.channel,
        subtitle: item.description,
        iconEdit: { visible: actionCards.iconEdit, enabled: true },
        iconDelete: { visible: actionCards.iconDelete, enabled: true },
        attributes: [
          {
            key: 'Country',
            value: [item.countries.country],
            isTag: true,
          },
          {
            key: 'GEO',
            value: [item.countries.geos.geo],
            isTag: true,
          },
        ],
      });
    });
    return dataCards.sort((current: any, next: any) =>
      current.title.localeCompare(next.title)
    );
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getChannels(paginated, this.filter);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.spinner.show();
      this.crudService
        .getEntity('country', { sort: 'country', pageName: 'channel' })
        .subscribe((response: any) => {
          this.prepareToEdit(event.item.id);
        });
    } else {
      this.onDelete(event.item.id);
      this.messageModalDelete = `Do you confirm to remove CHANNEL: ${event.item.channel}?`;
    }
  }

  prepareToEdit(id: number) {
    this.isEdit = true;
    this.crudService.fetchEntity(id).subscribe((channel: any) => {
      this.channelForm.controls.id.setValue(channel.id);
      this.channelForm.controls.channel.setValue(channel.channel);
      this.channelForm.controls.description.setValue(channel.description);
      this.channelForm.controls.country_id.setValue(channel.countries.country);
      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.channelForm.reset();
      });
      this.spinner.hide();
    });
  }

  prepareToCreate() {
    this.spinner.show();
    this.isEdit = false;
    this.crudService
      .getEntity('country', { sort: 'country', pageName: 'channel' })
      .subscribe((response: any) => {
        this.countriesEntities = response;
        const createModal = this.modalService.createModal(this.modalCreateEdit);
        createModal.afterClosed().subscribe(() => {
          this.channelForm.reset();
        });
        this.spinner.hide();
      });
  }

  regexFields() {
    this.channelForm.controls.channel.setValue(
      this.regexCheck
        .alphanumeric(this.channelForm.controls.channel.value || '')
        .trim()
    );
    this.channelForm.controls.description.setValue(
      this.regexCheck
        .alphanumeric(this.channelForm.controls.description.value || '')
        .trim()
    );
  }

  onCreate() {
    this.regexFields();
    if (this.validateFormService.onValidateFields(this.channelForm)) {
      this.retrieveDataFromAutocompleteModal();
      this.spinner.show();
      delete this.channelForm.value.id;
      this.crudService.createEntity(this.channelForm.value).subscribe(
        () => {
          this.modalService.closeAll();
          this.toastr.success(
            `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
            `${this.translatorService.getTitle('CRUD_SUCCESS')} Channel`
          );
          this.loadData();
        },
        (err: any) => {
          this.toastr.error(err.error.message, err.error.title);
          this.spinner.hide();
        }
      );
    }
  }

  onUpdate() {
    this.isEdit = true;
    this.regexFields();
    if (this.validateFormService.onValidateFields(this.channelForm)) {
      this.retrieveDataFromAutocompleteModal();
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          this.crudService
            .updateEntity(
              this.channelForm.controls.id.value,
              this.channelForm.value
            )
            .subscribe(
              () => {
                this.toastr.success(
                  `${this.translatorService.getMessage(
                    'CRUD_SUCCESS_UPDATED'
                  )}`,
                  `${this.translatorService.getTitle(
                    'CRUD_SUCCESS_UPDATED'
                  )} Channel`
                );
                this.loadData();
                this.modalService.closeAll();
              },
              (err: any) => {
                this.toastr.error(err.error.message, err.error.title);
                this.spinner.hide();
              }
            );
        }
      });
    }
  }

  onDelete(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.crudService.deleteEntity(id).subscribe(
          () => {
            this.toastr.success(
              `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
              `${this.translatorService.getTitle(
                'CRUD_SUCCESS_REMOVED'
              )} Channel`
            );
            this.loadData();
          },
          (err: any) => {
            this.toastr.error(err.error.message, err.error.title);
            this.spinner.hide();
          }
        );
      }
    });
  }

  onSearchEvent(value: any) {
    if (value.country_id) {
      const splitCountry = value.country_id.replace(/ - .*/, '');
      const currentCountry = this.countriesEntities.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(splitCountry.toLocaleUpperCase())
      );
      value.country_id = currentCountry[0]?.id || '';
    } else {
      value.country_id = '';
    }

    if (value.geo_id) {
      const currentGeo = this.geosEntities.filter((item: Geo) =>
        item.geo.toLocaleUpperCase().includes(value.geo_id.toLocaleUpperCase())
      );
      value.geo_id = currentGeo[0]?.id || '';
    } else {
      value.geo_id = '';
    }

    value.channel = value.channel ? value.channel : '';

    this.pagination.page = 0;
    this.filter = { ...value, sort: 'channel' };
    this.getChannels(this.pagination, this.filter);
  }

  retrieveDataFromAutocompleteModal() {
    const currentCountry = this.countriesEntities.filter((item: Country) =>
      item.country
        .toLocaleUpperCase()
        .includes(this.channelForm.value.country_id.toLocaleUpperCase())
    );
    this.channelForm.value.country_id = currentCountry[0]
      ? currentCountry[0].id
      : 0;
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }
}
