import { Directive, HostListener } from '@angular/core';
import { TransformText } from 'src/app/helpers/transformText';

@Directive({
  selector: '[appInputAlphanumeric]',
})
export class InputAlphanumericDirective {
  regexCheck = new TransformText();

  @HostListener('input', ['$event']) onInputChange(event: any) {
    event.target.value = this.regexCheck.alphanumeric(event.target.value);
    event.stopPropagation();
    return false;
  }
}
