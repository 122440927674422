<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="header-title">
    <span class="title">PPM</span>
    <div class="container-export">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="disabledButtonExport"
          [disabled]="disabledButtonExport"></button>
      </span>
    </div>
  </div>
  <div class="ppm-allocation-content">
    <mat-tab-group
      (click)="tabClick($event)"
      [selectedIndex]="selectedTabIndex">
      <mat-tab label="Allocation">
        <app-search
          (searchEvent)="onSearchEvent($event)"
          [filters]="searchInputs"
          (applyFilterEvent)="applyFilter()"
          [showButtonApply]="true"
          [enableApplyFilter]="enableApplyFilter"
          [showMoreFiltersLink]="true">
        </app-search>
        <mat-divider></mat-divider>
        <label class="switch">
          <input (change)="checkSimpleOrNot($event)" type="checkbox" checked />
          <span class="slider round"></span>
        </label>
        <label>
          <span style="margin-left: 10px">Simple View</span>
        </label>
        <section class="header-table-allocation">
          <div class="title-section">
            <h3 class="t-section">Allocation By PPM</h3>
            <h4 class="st-section"></h4>
          </div>
          <div class="container-reference-and-button">
            <span class="reference-table"
              ><b>ENTERED SETUP </b>{{ allocationLabels.entered | uppercase
              }}<b> CURRENT WEEK </b>{{ allocationLabels.currentWeek | uppercase
              }}<b> TO BOOK </b>{{ allocationLabels.toBook | uppercase
              }}<b> TO ALLOCATE</b>
              {{ allocationLabels.toAllocate | uppercase }}
            </span>
            <button
              *ngIf="this.loadedDataPPM"
              (click)="openModalLastWeek()"
              class="button-last-week">
              LAST WEEK
            </button>
            <!-- <a class="last-week-button">LAST WEEK</a> -->
          </div>
        </section>
        <div class="no-filter-selected-session" *ngIf="showNoResults">
          <p>NO RECORDS FOUND.</p>
        </div>
        <div
          class="no-filter-selected-session"
          *ngIf="noFilterSelected; else blockFilterSelected">
          <p>PLEASE,<br />START BY FILTERING PPM FIRST</p>
        </div>
        <ng-template #blockFilterSelected>
          <div
            class="table-dynamic-list"
            *ngIf="!showNoResults; else blockNoResult">
            <div
              *ngFor="let table of dataSourceTable; let key = index"
              [style]="table.style">
              <app-table-manager
                *ngIf="showTable"
                #tableManager
                [dataSource]="table"
                [keyDataSource]="key"
                (inputValueChanged)="updateTableValues($event)"
                [showSubtotalTitle]="table.showSubtotalTitle"
                [eventTable]="eventTable"
                [valuesSubTotal]="valuesSubTotal"
                [valuesSubMlsAndTotalAllocationExpanded]="
                  valuesSubMlsAndTotalAllocationExpanded
                "
                (toggleTable)="toggleTable($event)"
                (onBlurInput)="updateSummarizedTable($event)"
                [origins]="origins"
                [totalToBook]="totalToBook"
                (valueIdentified)="
                  openModalNetOpenPo($event)
                "></app-table-manager>
            </div>
          </div>
          <!-- <div
            *ngIf="!showNoResults && isEditTableMode"
            class="buttons-actions-table-allocation">
            <button
              class="button modal-cancel-button"
              mat-button
              (click)="checkDiscardAllocation('applyFilter')">
              CANCEL
            </button>
            <button
              #saveChangesButton
              class="button modal-action-button button-save disabled"
              mat-button
              (click)="saveAllocation()"
              [class.disabled]="disabledSaveButton"
              [disabled]="disabledSaveButton">
              SAVE
            </button>
          </div> -->
          <div
            *ngIf="!viewButtonSave && isEditTableMode"
            class="buttons-actions-table-allocation">
            <button
              class="button modal-cancel-button"
              mat-button
              (click)="checkDiscardAllocation('applyFilter')">
              CANCEL
            </button>
            <button
              #saveChangesButton
              class="button modal-action-button disabled"
              mat-button
              (click)="saveAllocation()"
              [class.disabled]="disabledSaveButton"
              [disabled]="disabledSaveButton">
              SAVE
            </button>
          </div>
          <div
            *ngIf="viewButtonSave && isEditTableMode"
            class="buttons-actions-table-allocation">
            <button
              class="button modal-cancel-button button-cancel"
              mat-button
              (click)="checkDiscardAllocation('applyFilter')">
              CANCEL
            </button>
            <button
              #saveChangesButton
              class="button modal-action-button button-save disabled"
              mat-button
              (click)="saveAllocation()"
              [class.disabled]="disabledSaveButton"
              [disabled]="disabledSaveButton">
              SAVE
            </button>
          </div>

          <ng-template #blockNoResult></ng-template>

          <app-table-columns-tabs
            [tab]="loadedDataPPMTableTab"
            [thead]="loadedDataPPMTableThead"
            [data]="loadedDataPPMTableData"
            [showMenuColumns]="loadedDataPPMTableshowMenuColumns"
            [visible]="loadedDataPPMTableVisible"
            (changeTab)="changeTabDataPPMTableTab($event)">
          </app-table-columns-tabs>
        </ng-template>

        <ng-template #dialogConfirmationDiscardSaveChanges>
          <header class="title-modal" matDialogTitle>CONFIRMATION</header>
          <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
          <mat-dialog-actions>
            <button
              class="button modal-cancel-button"
              mat-button
              matDialogClose>
              NO
            </button>
            <button
              *ngIf="isDiscardChanges"
              class="button modal-action-button"
              mat-button
              matDialogClose="discard">
              YES
            </button>
            <button
              *ngIf="!isDiscardChanges"
              class="button modal-action-button"
              mat-button
              matDialogClose="save">
              YES
            </button>
          </mat-dialog-actions>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab label="Customer Analysis" disabled></mat-tab>
      <mat-tab label="Wow Analysis" disabled></mat-tab>
      <mat-tab label="Wow target" disabled></mat-tab>
      <mat-tab label="Margin Rankings" disabled></mat-tab> -->
    </mat-tab-group>
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
