import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelSheetsService {
  constructor() {}

  public exportJsonAsExcelFile(data: any[], excelFileName: string) {
    const length = data.length;
    const headersArray = [];
    const noHeadersArray = [];
    let counter = 1;

    for (let json of data) {
      if (json.headers) {
        const headers: { [key: string]: any } = {};
        if (json.columns) {
          json.columns.forEach((column: any) => {
            headers[column.props] = column.title;
          });
        } else {
          json.headers.forEach((column: any) => {
            headers[column.props] = column.title;
          });
        }
        json.rows.unshift(headers);

        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.rows, {
          skipHeader: true,
        });
        Object.defineProperty(workSheet, 'sheet', {
          value: json.sheet,
        });
        headersArray.push(workSheet);
      } else {
        if (json.rows) {
          const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json.rows);
          Object.defineProperty(workSheet, 'sheet', {
            value: json.sheet,
          });
          noHeadersArray.push(workSheet);
        } else {
          const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
          Object.defineProperty(workSheet, 'sheet', {
            value: json.sheet,
          });
          noHeadersArray.push(workSheet);
        }
      }

      if (counter === length) {
        const sheetsData = [];
        const sheetNames = [];

        for (let json of headersArray) {
          sheetsData.push({ [`${json.sheet}`]: json });
          sheetNames.push(json.sheet);
        }

        for (let json of noHeadersArray) {
          sheetsData.push({ [`${json.sheet}`]: json });
          sheetNames.push(json.sheet);
        }

        const formatedData = Object.assign({}, ...sheetsData);
        const workBook: XLSX.WorkBook = {
          Sheets: formatedData,
          SheetNames: sheetNames,
        };
        const excelBuffer: any = XLSX.write(workBook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, excelFileName);
      }

      counter++;
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, `${fileName}_exported${EXCEL_EXTENSION}`);
  }
}
