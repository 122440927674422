import { Component, Input, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TableComponent } from '../table/table.component';
import { columnsStyle } from '../table/table.interface';
import { Paginator } from '../table/table.interface';

@Component({
  selector: 'app-simple-table-header-fixed',
  templateUrl: './simple-table-header-fixed.component.html',
  styleUrls: ['./simple-table-header-fixed.component.scss'],
})
export class SimpleTableHeaderFixedComponent extends TableComponent {
  @Input() data: Paginator;
  @Input() currentWeek: string;

  privatecolumnsLabel: columnsStyle;
  displayedColumns!: string;
  dataSource!: [];

  constructor(http: HttpClient) {
    super(http);

    this.data = {} as Paginator;
    this.privatecolumnsLabel = {} as columnsStyle;
    this.currentWeek = '';
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data?.table !== undefined) {
      this.table = this.data.table;
      this.columnsLabel = this.table.columns;
      this.dataSource = this.table.rows;

      this.displayedColumns = this.table.columns?.map(
        (column: columnsStyle) => column.label
      );
    }

    this.colorLabelCurrentWeek(changes);
  }

  colorLabelCurrentWeek(changes: SimpleChanges): void {
    if (changes.currentWeek?.currentValue) {
      this.currentWeekLabel = changes.currentWeek.currentValue;
    }
  }
}
