export const sypplySummary: any = {
	"weekly": {
		"headers": [
			{
				"title": "FAMILY",
				"props": "family",
				"firtsTitle": true,
				"alwaysVisible": true,
				"hideIconExpand": true,
				"secondColumn": false
			},
			{
				"title": "JAN",
				"props": "januaryBrazil",
				"style": {
					"border-left": "2px solid #aea1ff"
				},
				"subtitle": "BRAZIL",
				"styleBorderHorizontal": true,
				"firtsTitle": true,
				"firstIndex": true,
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FEB",
				"props": "februaryBrazil",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "MAR",
				"props": "marchBrazil",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FQ4",
				"props": "fq4Brazil",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "JAN",
				"props": "januaryClaro_br",
				"style": {
					"border-left": "2px solid #d33115"
				},
				"subtitle": "CLARO_BR",
				"styleBorderHorizontal": true,
				"firtsTitle": true,
				"firstIndex": true,
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FEB",
				"props": "februaryClaro_br",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "MAR",
				"props": "marchClaro_br",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FQ4",
				"props": "fq4Claro_br",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "JAN",
				"props": "januaryMmicom_br",
				"style": {
					"border-left": "2px solid #73d8ff"
				},
				"subtitle": "MMICOM_BR",
				"styleBorderHorizontal": true,
				"firtsTitle": true,
				"firstIndex": true,
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FEB",
				"props": "februaryMmicom_br",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "MAR",
				"props": "marchMmicom_br",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FQ4",
				"props": "fq4Mmicom_br",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "JAN",
				"props": "januaryRetail_bra",
				"style": {
					"border-left": "2px solid #68bc00"
				},
				"subtitle": "RETAIL_BRA",
				"styleBorderHorizontal": true,
				"firtsTitle": true,
				"firstIndex": true,
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FEB",
				"props": "februaryRetail_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "MAR",
				"props": "marchRetail_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FQ4",
				"props": "fq4Retail_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "JAN",
				"props": "januaryTim_bra",
				"style": {
					"border-left": "2px solid #009ce0"
				},
				"subtitle": "TIM_BRA",
				"styleBorderHorizontal": true,
				"firtsTitle": true,
				"firstIndex": true,
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FEB",
				"props": "februaryTim_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "MAR",
				"props": "marchTim_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FQ4",
				"props": "fq4Tim_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "JAN",
				"props": "januaryVivo_bra",
				"style": {
					"border-left": "2px solid #fa28ff"
				},
				"subtitle": "VIVO_BRA",
				"styleBorderHorizontal": true,
				"firtsTitle": true,
				"firstIndex": true,
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FEB",
				"props": "februaryVivo_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "MAR",
				"props": "marchVivo_bra",
				"alwaysVisible": true,
				"secondColumn": true
			},
			{
				"title": "FQ4",
				"props": "fq4Vivo_bra",
				"alwaysVisible": true,
				"secondColumn": true
			}
		],
		"rows": [
			{
				"family": {
					"value": "ARUBA 2/32",
					"color": [
						{
							"family": "ARUBA 2/32",
							"colorFamily": "ARUBA 2/32-AQUARIUS",
							"name": "AQUARIUS"
						},
						{
							"family": "ARUBA 2/32",
							"colorFamily": "ARUBA 2/32-GRAPHITE",
							"name": "GRAPHITE"
						}
					],
					"showAccordion": true
				},
				"januaryBrazil": {
					"value": 785,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 335
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 450
						}
					],
					"showAccordion": true
				},
				"februaryBrazil": {
					"value": 299,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 198
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 101
						}
					],
					"showAccordion": true
				},
				"marchBrazil": {
					"value": 463,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 450
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 13
						}
					],
					"showAccordion": true
				},
				"januaryClaro_br": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"februaryClaro_br": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"marchClaro_br": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"januaryMmicom_br": {
					"value": 709,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 312
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 397
						}
					],
					"showAccordion": true
				},
				"februaryMmicom_br": {
					"value": 278,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 192
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 86
						}
					],
					"showAccordion": true
				},
				"marchMmicom_br": {
					"value": 39,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 31
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 8
						}
					],
					"showAccordion": true
				},
				"januaryRetail_bra": {
					"value": 96,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 96
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"februaryRetail_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"marchRetail_bra": {
					"value": -619,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 387
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": -1006
						}
					],
					"showAccordion": true
				},
				"januaryTim_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"februaryTim_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"marchTim_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"januaryVivo_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"februaryVivo_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"marchVivo_bra": {
					"value": 1000,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 1000
						}
					],
					"showAccordion": true
				},
				"fq4Brazil": {
					"value": 1547,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 983
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 564
						}
					],
					"showAccordion": true
				},
				"fq4Claro_br": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"fq4Mmicom_br": {
					"value": 1026,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 535
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 491
						}
					],
					"showAccordion": true
				},
				"fq4Retail_bra": {
					"value": -523,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 483
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": -1006
						}
					],
					"showAccordion": true
				},
				"fq4Tim_bra": {
					"value": 0,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 0
						}
					],
					"showAccordion": true
				},
				"fq4Vivo_bra": {
					"value": 1000,
					"summableValue": true,
					"color": [
						{
							"colorFamily": "ARUBA 2/32 - AQUARIUS",
							"value": 0
						},
						{
							"colorFamily": "ARUBA 2/32 - GRAPHITE",
							"value": 1000
						}
					],
					"showAccordion": true
				}
			}
		]
	}
}

