import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-breaker',
  templateUrl: './header-breaker.component.html',
  styleUrls: ['./header-breaker.component.scss'],
})
export class HeaderBreakerComponent implements OnInit {
  @Input() header!: string;
  newHeader!: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.header) {
      const headers: string[] = this.header.split(' ');
      if (headers.length === 3) {
        this.newHeader = `${headers.slice(0, 2).join(' ')}\n${headers[2]}`;
      } else if (headers.length > 3) {
        this.newHeader = `${headers.slice(0, 3).join(' ')}\n${headers
          .slice(3)
          .join(' ')}`;
      } else {
        this.newHeader = this.header;
      }
    }
  }
}
