<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">Territory and Products View</span>
    <div class="container-export">
        <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="isValid"
          [disabled]="isValid"></button>
      </span>
    </div>
  </div>

  <div class="territory-product-content">
    <div class="filters">
      <app-search
        (searchEvent)="onSearchEvent($event)"
        [filters]="searchInput"
        [typeGEO]="'EMEA'"></app-search>
    </div>
    <mat-divider></mat-divider>

    <div *ngIf="showTable">
      <section class="header-territory-product-content">
        <div class="title-quarter">
          <div class="title-quarter-container">
            <span class="current-quarter">Territories:</span>
            <span>
              {{ territorySelect || 'ALL' }}
            </span>
          </div>
        </div>
        <div class="button-filter">
          <a class="next" (click)="nextQuarter()"
            >{{ next ? 'PREVIOUS' : 'NEXT' }} QUARTER</a
          >
        </div>
      </section>
      <app-simple-table-emea
        [data]="result"
        [hasTotalRow]="true"
      ></app-simple-table-emea>
    </div>

    <div class="buttons-right">
      <button
        (click)="backButton()"
        class="button back-action-button"
        mat-button>
        BACK
      </button>
    </div>
  </div>
</div>
