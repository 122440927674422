import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertValue',
})
export class ConvertValuePipe implements PipeTransform {
  transform(value: any): any {
    return typeof value === 'undefined' ? '' : Number(value);
  }
}
