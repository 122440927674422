import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { Material } from 'src/app/interfaces/material.interface';
import { User } from 'src/app/interfaces/user.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { ExcelSheetsService } from '../../../services/generic/excelSheets.service';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { ToastrService } from 'ngx-toastr';
import { Tab } from 'src/app/interfaces/tab.interface';
import { Subscription } from 'rxjs';
import { UtilitiesService } from 'src/app/services/generic/utilities.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { TabComponent } from 'src/app/components/tab/tab.component';
import { dataOverbookedMockup } from './overbooked.mockup';
import { ExcelService } from 'src/app/services/generic/excel.service';
import {
  currentData,
  customerShortEntities,
  familyEntities,
  orderAdminEntities,
  ppmEntities,
  salesManagerEntities,
} from 'src/app/interfaces/overbooked.interface';

@Component({
  selector: 'app-overbooked',
  templateUrl: './overbooked.component.html',
  styleUrls: ['./overbooked.component.scss'],
})
export class OverbookedComponent implements OnInit {
  @ViewChild('OrderAdmin') OrderAdmin!: TemplateRef<any>;
  @ViewChild('SalesManager') SalesManager!: TemplateRef<any>;
  @ViewChild('Customer') Customer!: TemplateRef<any>;
  @ViewChild('Product') Product!: TemplateRef<any>;

  @ViewChild('TabComponent') TabComponent!: TabComponent;

  orderAdminsEntities: orderAdminEntities[];
  ppmEntities: ppmEntities[];
  customerShortEntities: customerShortEntities[];
  materialEntities: any[];
  salesManagerEntities: salesManagerEntities[];
  familyEntities: familyEntities[];

  tabs: Tab[];

  isValid: boolean;
  tooltipMessage: String;

  exportResult!: ResultInterface;
  resultExportOrderAdmin: any;
  resultExportSalesManager: any;
  resultExportCustomer: any;
  resultExportProduct: any;

  currentUser!: any;
  currentData!: currentData;
  mlsLastUpdate: string;

  clickScreenSub!: Subscription;
  filterTable: any;
  allocate: { label: string; value: boolean };
  toBook: { label: string; value: boolean };

  dataOrderAdmin: any;
  dataSalesManager: any;
  dataCustomer: any;
  dataProduct: any;

  noResultOrderAdmin: boolean;
  orderAdminSelected: boolean;
  noResultSalesManager: boolean;
  salesManagerSelected: boolean;
  noResultCustomer: boolean;
  customerSelected: boolean;
  noResultProduct: boolean;
  productSelected: boolean;

  tabsTeste: { [key: string]: number };

  messages: {
    [key: string]: string;
  } = {
    save: 'Do you confirm ka allocation data updated?',
    discard: 'Are you sure you want to exit before saving your changes?',
    negativeValues:
      'Are you sure you want to save the allocation with negative fields?',
    noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
  };

  isEditTableMode: boolean;
  isDiscardChanges: boolean;

  selectedTabIndex: number;

  formSearchValuesOrderAdmin!: { [key: string]: any };
  formSearchValuesSalesManager!: { [key: string]: any };
  formSearchValuesCustomer!: { [key: string]: any };
  formSearchValuesProduct!: { [key: string]: any };

  enableApplyFilterOrderAdmin: boolean;
  enableApplyFilterSalesManager: boolean;
  enableApplyFilterCustomer: boolean;
  enableApplyFilterProduct: boolean;

  searchInputsOrderAdmin: InputBase<string>[];
  searchInputsSalesManager: InputBase<string>[];
  searchInputsCustomer: InputBase<string>[];
  searchInputsProduct: InputBase<string>[];

  keyFilterOrderAdmin: Array<string>;
  keyFilterSalesManager: Array<string>;
  keyFilterCustomer: Array<string>;
  keyFilterProduct: Array<string>;

  crudService: CrudService<Material>;

  entities: Array<getEntities>;

  initialValueOrderAdmin: { [key: string]: any };
  initialValueOrderAdminToCustomerId: any;
  initialValueSalesManager: { [key: string]: any };
  initialValueSalesManagerToCustomerId: any;

  constructor(
    private spinner: NgxSpinnerService,
    protected http: HttpClient,
    private authService: AuthService,
    private modalService: ModalActionsService,
    private router: Router,
    private excelService: ExcelService,
    private utilitiesService: UtilitiesService,
    private excelSheetsService: ExcelSheetsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    [this.currentUser] = this.authService.getUserPermissions();
    this.crudService = new CrudService<any>(this.http, 'overbooked');

    this.orderAdminsEntities = [];
    this.ppmEntities = [];
    this.materialEntities = [];
    this.customerShortEntities = [];
    this.salesManagerEntities = [];
    this.familyEntities = [];

    this.tabs = [];

    this.isValid = false;
    this.tooltipMessage = 'Export';

    this.mlsLastUpdate = '';

    this.filterTable = [
      { label: 'Allocate', value: false },
      { label: 'To Book', value: true },
    ];
    this.allocate = { label: 'Allocate', value: false };
    this.toBook = { label: 'To Book', value: true };

    this.dataOrderAdmin = '';
    this.dataSalesManager = '';
    this.dataCustomer = '';
    this.dataProduct = '';

    this.noResultOrderAdmin = false;
    this.orderAdminSelected = true;
    this.noResultSalesManager = false;
    this.salesManagerSelected = true;
    this.noResultCustomer = false;
    this.customerSelected = true;
    this.noResultProduct = false;
    this.productSelected = true;

    this.tabsTeste = {
      'ORDER ADMIN': 0,
      'SALES MANAGER': 1,
      CUSTOMER: 2,
      PRODUCT: 3,
    };

    // messages: {
    //   [key: string]: string;
    // } = {
    //   save: 'Do you confirm ka allocation data updated?',
    //   discard: 'Are you sure you want to exit before saving your changes?',
    //   negativeValues:
    //     'Are you sure you want to save the allocation with negative fields?',
    //   noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
    // };

    this.isEditTableMode = false;
    this.isDiscardChanges = false;

    this.selectedTabIndex = 0;

    this.enableApplyFilterOrderAdmin = true;
    this.enableApplyFilterSalesManager = true;
    this.enableApplyFilterCustomer = true;
    this.enableApplyFilterProduct = true;

    this.keyFilterOrderAdmin = [
      'orderAdminId',
      'product',
      'customerShortId',
      'origin',
    ];

    this.keyFilterSalesManager = [
      'salesManagerId',
      'product',
      'customerShortId',
      'origin',
    ];

    this.keyFilterCustomer = ['customerShortId', 'product', 'origin'];

    this.keyFilterProduct = ['materialId', 'customerShortId', 'origin'];

    this.entities = [
      {
        entity: 'customer-short',
        query: {
          status: true,
          sort: 'customerShort',
          pageName: PAGE_NAME_RESOURCE.overBooked,
        },
      },
      {
        entity: 'material/products/summary',
        query: { pageName: PAGE_NAME_RESOURCE.overBooked },
      },
      {
        entity: 'material',
        query: { pageName: PAGE_NAME_RESOURCE.overBooked },
      },
      {
        entity: 'user',
        query: {
          status: true,
          order_admin: true,
          sort: 'last_name',
          pageName: PAGE_NAME_RESOURCE.overBooked,
        },
      },
      {
        entity: 'setupManagement/currentSetup',
        query: { pageName: PAGE_NAME_RESOURCE.overBooked },
      },
      {
        entity: 'user',
        query: {
          status: true,
          sales_manager: true,
          sort: 'last_name',
          pageName: PAGE_NAME_RESOURCE.overBooked,
        },
      },
      {
        entity: 'material-family',
        query: { pageName: PAGE_NAME_RESOURCE.overBooked },
      },
    ];

    this.initialValueOrderAdmin = {};
    this.initialValueOrderAdminToCustomerId = [];
    this.initialValueSalesManager = {};
    this.initialValueSalesManagerToCustomerId = [];

    this.searchInputsOrderAdmin = [
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];

    this.searchInputsSalesManager = [
      new SelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];

    this.searchInputsCustomer = [
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];

    this.searchInputsProduct = [
      new MultiSelectSearchInput({
        key: 'materialId',
        hover: 'Family',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    [this.currentUser] = this.authService.getUserPermissions();
    this.clickScreenSub = this.utilitiesService.documentClickedTarget.subscribe(
      target => this.verifyClickChangeRoute(target)
    );

    const promises = [this.getDataFilter()];

    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  ngAfterViewInit(): void {
    this.allTabs();
    this.cdr.detectChanges();
  }

  verifyClickChangeRoute(event: any) {
    const classElementsChangesRoutes = ['xng-breadcrumb-item ng-star-inserted'];

    const isElementChangeRoute =
      classElementsChangesRoutes.filter((className: string) =>
        event.target.className.includes(className)
      ).length > 0;

    if (isElementChangeRoute && this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.modalService.createConfirm(
        this.TabComponent.dialogConfirmationDiscardSaveChanges
      );
      this.TabComponent.messageModalChanges = this.messages.discard;
      const originUrl = event.target.children[0].origin;
      const routeToNavigate = event.target.children[0].href.replace(
        originUrl,
        ''
      );
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.router.navigate([routeToNavigate]);
        }
      });
    }
  }

  allTabs() {
    this.tabs = [
      { label: 'Order Admin', tab: 'ORDER ADMIN', template: this.OrderAdmin },
      {
        label: 'Sales Manager',
        tab: 'SALES MANAGER',
        template: this.SalesManager,
      },
      {
        label: 'Customer',
        tab: 'CUSTOMER',
        template: this.Customer,
      },
      {
        label: 'Product',
        tab: 'PRODUCT',
        template: this.Product,
      },
    ];
  }

  tabClick(event: any) {
    this.changeTab(event.target.innerText);
  }

  changeTab(label: any) {
    if (this.tabsTeste[label] || this.tabsTeste[label] === 0) {
      this.selectedTabIndex = this.tabsTeste[label];
      this.controlExportButton();
    }
  }

  controlExportButton() {
    if (this.selectedTabIndex == 0 && this.dataOrderAdmin != '') {
      this.isValid = true;
    } else if (this.selectedTabIndex == 1 && this.dataSalesManager != '') {
      this.isValid = true;
    } else if (this.selectedTabIndex == 2 && this.dataCustomer != '') {
      this.isValid = true;
    } else if (this.selectedTabIndex == 3 && this.dataProduct != '') {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  handleFilterButton(item: any) {
    this.filterTable.forEach((data: any) => {
      if (data.label == item.label) {
        data.value = true;
      } else {
        data.value = false;
      }
    });

    if (item.label == this.allocate.label) {
      this.allocate.value = true;
    } else {
      this.allocate.value = false;
    }

    if (item.label == this.toBook.label) {
      this.toBook.value = true;
    } else {
      this.toBook.value = false;
    }
  }

  async getDataFilter(): Promise<void> {
    this.spinner.show();

    return new Promise((resolve, reject) => {
      this.crudService.getDataFilters(this.entities).subscribe(
        (response: any) => {
          this.customerShortEntities = response[0];
          this.ppmEntities = response[1];
          this.materialEntities = response[2];
          this.orderAdminsEntities = response[3];
          this.salesManagerEntities = response[5];
          this.familyEntities = response[6];

          this.currentData = {
            fiscalYear: response[4].fiscalYear,
            quarter: response[4].quarter,
            geoId: this.currentUser.geoId,
            countryId: this.currentUser.countryId,
          };

          this.dataFilterOrderAdmin(this.orderAdminsEntities);
          this.dataFilterSalesManager(this.salesManagerEntities);
          this.dataFilterCustomerShort(this.customerShortEntities);
          this.dataFilterFamily(this.familyEntities);

          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        }
      );
    });
  }

  dataFilterOrderAdmin(response: any) {
    const orderAdminsFilter: any[] = [];
    const nameOrderAdmin: string = `${this.currentUser.lastName}, ${this.currentUser.name}`;

    response.forEach((item: any) => {
      const viewName: string = `${item.last_name}, ${item.name}`;

      if (
        this.currentUser.profileType === 'orderAdmin' &&
        nameOrderAdmin === viewName
      ) {
        orderAdminsFilter.push({ value: viewName, id: item.id });
        this.initialValueOrderAdmin.value = viewName;
        this.initialValueOrderAdmin.id = item.id;
      } else if (this.currentUser.profileType !== 'orderAdmin') {
        orderAdminsFilter.push({ value: viewName, id: item.id });
      }
    });

    if (this.initialValueOrderAdmin.value) {
      this.spinner.show();
      const customerOptions: any = [];
      this.enableApplyFilterOrderAdmin = true;

      this.customerShortEntities.forEach((item: any) => {
        if (item.orderAdmin !== null) {
          let orderAdminSelected = `${item.orderAdmin.last_name}, ${item.orderAdmin.name}`;
          if (orderAdminSelected === this.initialValueOrderAdmin.value) {
            customerOptions.push({
              id: item.id,
              value: item.customerShort,
            });
          }
        }
      });

      customerOptions.forEach((item: any) => {
        this.initialValueOrderAdminToCustomerId.push(item.id);
      });

      this.createAndAssignCustomerShortSearchInput(
        customerOptions,
        'orderAdmin',
        true
      );

      this.dataFilterPpm(
        this.ppmEntities,
        this.searchInputsOrderAdmin,
        'filters-order-admin-overbooked'
      );
      this.dataFilterOrigin(this.searchInputsOrderAdmin);

      const selectMultiOrderAdmin: any = new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'first-filter-order-admin',

        options: orderAdminsFilter,
        value: [this.initialValueOrderAdmin],
      });

      this.searchInputsOrderAdmin[0] = selectMultiOrderAdmin;
      this.searchInputsOrderAdmin[2].disabled = false;
      this.searchInputsOrderAdmin[5].disabled = false;
      this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
    } else {
      this.initialValueOrderAdmin.value = '';
      this.initialValueOrderAdmin.id = 0;
      this.initialValueOrderAdminToCustomerId = [];

      this.dataFilterOrigin(undefined, undefined, 'orderAdmin');

      const selectMultiOrderAdmin: any = new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'first-filter-order-admin',

        options: orderAdminsFilter,
      });

      const selectMultiPpm = new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'filters-order-admin-overbooked',
        largeInput: true,
        disabled: true,
      });

      const selectMultiCustomerShort = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'filters-order-admin-overbooked',
        disabled: true,
      });

      this.searchInputsOrderAdmin[0] = selectMultiOrderAdmin;
      this.searchInputsOrderAdmin[1] = selectMultiCustomerShort;
      this.searchInputsOrderAdmin[2] = selectMultiPpm;
      this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
    }
  }

  dataFilterSalesManager(response: salesManagerEntities[]) {
    const salesManagerFilter: any[] = [];
    const nameSalesManager: string = `${this.currentUser.lastName}, ${this.currentUser.name}`;

    response.forEach((item: any) => {
      const viewName: string = `${item.last_name}, ${item.name}`;

      if (
        this.currentUser.profileType === 'salesManager' &&
        nameSalesManager === viewName
      ) {
        salesManagerFilter.push({ value: viewName, id: item.id });
        this.initialValueSalesManager.value = viewName;
        this.initialValueSalesManager.id = item.id;
      } else if (this.currentUser.profileType !== 'salesManager') {
        salesManagerFilter.push({ value: viewName, id: item.id });
      }
    });

    if (this.initialValueSalesManager.value) {
      this.spinner.show();
      const customerOptions: any = [];
      this.enableApplyFilterSalesManager = true;

      this.customerShortEntities.forEach((item: any) => {
        if (item.salesManager !== null) {
          let salesManagerSelected = `${item.salesManager.last_name}, ${item.salesManager.name}`;

          if (salesManagerSelected === this.initialValueSalesManager.value) {
            customerOptions.push({
              id: item.id,
              value: item.customerShort,
            });
          }
        }
      });

      customerOptions.forEach((item: any) => {
        this.initialValueSalesManagerToCustomerId.push(item.id);
      });

      this.createAndAssignCustomerShortSearchInput(
        customerOptions,
        'salesManager',
        true
      );
      this.dataFilterPpm(
        this.ppmEntities,
        this.searchInputsSalesManager,
        'filters-order-admin-overbooked'
      );
      this.dataFilterOrigin(this.searchInputsSalesManager);

      const selectMultiSalesManager: any = new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        classCss: 'first-filter-order-admin',
        hasCheckFlowException: true,

        options: salesManagerFilter,
        value: [this.initialValueSalesManager],
      });

      this.searchInputsSalesManager[0] = selectMultiSalesManager;
      this.searchInputsSalesManager[1].disabled = false;
      this.searchInputsSalesManager[5].disabled = false;
      this.searchInputsSalesManager = [...this.searchInputsSalesManager];
    } else {
      this.initialValueSalesManager.value = '';
      this.initialValueSalesManager.id = 0;
      this.initialValueSalesManagerToCustomerId = [];

      this.dataFilterOrigin(undefined, undefined, 'salesManager');

      const selectMultiSalesManager: any = new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        classCss: 'first-filter-order-admin',
        hasCheckFlowException: true,

        options: salesManagerFilter,
      });

      this.searchInputsSalesManager[0] = selectMultiSalesManager;
      this.searchInputsSalesManager = [...this.searchInputsSalesManager];
    }
  }

  dataFilterFamily(response: familyEntities[]) {
    const familyFilter: any[] = [];

    response.forEach((item: any, index = 0) => {
      familyFilter.push({ value: item.family, id: item.ids });
    });

    const selectFamily = new MultiSelectSearchInput({
      key: 'materialId',
      hover: 'Family',
      type: 'text',
      classCss: 'first-filter-family',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: familyFilter,
    });

    this.searchInputsProduct[0] = selectFamily;
    this.searchInputsProduct = [...this.searchInputsProduct];
  }

  dataFilterPpm(response: any, searchInputs?: any, classCssValue?: string) {
    const ppmFilter: any[] = [];
    const auxPpm: any = [];
    const ppmFinal: any = [];

    response.forEach((item: any) => {
      ppmFilter.push({
        value: item.productDesc,
      });
    });

    ppmFilter.forEach((item: any) => {
      auxPpm.push(item.value);
    });
    const aux = new Set(auxPpm);
    aux.forEach((item: any) => {
      ppmFinal.push({
        value: item,
      });
    });

    const selectMultiPpm = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'PPM',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: ppmFinal,
      classCss: classCssValue,
      largeInput: true,
      disabled: true,
    });

    if (searchInputs == this.searchInputsOrderAdmin) {
      searchInputs[2] = selectMultiPpm;
      searchInputs = [...searchInputs];
    }

    if (searchInputs == this.searchInputsSalesManager) {
      searchInputs[1] = selectMultiPpm;
      searchInputs = [...searchInputs];
    }

    if (searchInputs == this.searchInputsCustomer) {
      searchInputs[1] = selectMultiPpm;
      searchInputs = [...searchInputs];
    }
  }

  dataFilterCustomerShort(response: any) {
    const customerShortFilter: any[] = [];

    response.forEach((item: any) => {
      customerShortFilter.push({
        id: item.id,
        value: item.customerShort,
      });
    });

    const selectMultiCustomerShort = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: customerShortFilter,
      classCss: 'first-filter-order-admin',
      disabled: false,
    });

    this.searchInputsCustomer[0] = selectMultiCustomerShort;
    this.searchInputsCustomer = [...this.searchInputsCustomer];
  }

  dataFilterOrigin(
    searchInputs?: Array<any>,
    classCss: string = 'origin-filter',
    tab?: string
  ) {
    const originFilter: any[] = [
      { id: 0, value: 'ALL' },
      { id: 1, value: 'JAG' },
      { id: 2, value: 'MAN' },
    ];

    if (searchInputs) {
      const selectOrigin = new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        options: originFilter,
        classCss: classCss,
      });

      if (searchInputs == this.searchInputsOrderAdmin) {
        searchInputs[5] = selectOrigin;
        searchInputs = [...searchInputs];
      }

      if (searchInputs == this.searchInputsSalesManager) {
        searchInputs[5] = selectOrigin;
        searchInputs = [...searchInputs];
      }

      if (searchInputs == this.searchInputsCustomer) {
        searchInputs[4] = selectOrigin;
        searchInputs = [...searchInputs];
      }

      if (searchInputs == this.searchInputsProduct) {
        searchInputs[2] = selectOrigin;
        searchInputs = [...searchInputs];
      }
    } else {
      const selectOrigin = new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        options: originFilter,
        disabled: true,
        value: originFilter[0].value,
        notClearable: true,
      });

      if (tab == 'orderAdmin') {
        this.searchInputsOrderAdmin[5] = selectOrigin;
        this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
      }

      if (tab === 'salesManager') {
        this.searchInputsSalesManager[5] = selectOrigin;
        this.searchInputsSalesManager = [...this.searchInputsSalesManager];
      }

      this.searchInputsCustomer[4] = selectOrigin;
      this.searchInputsProduct[2] = selectOrigin;

      this.searchInputsCustomer = [...this.searchInputsCustomer];
      this.searchInputsProduct = [...this.searchInputsProduct];
    }
  }

  onSearchEvent(form: any, tab?: string) {
    this.initialValueOrderAdmin.value = '';
    this.initialValueOrderAdmin.id = 0;
    this.initialValueOrderAdminToCustomerId = [];
    this.initialValueSalesManager.value = '';
    this.initialValueSalesManager.id = 0;
    this.initialValueSalesManagerToCustomerId = [];

    if (tab === 'orderAdmin') {
      this.formSearchValuesOrderAdmin = { ...form };
      this.dataFormSearchOrderAdmin(this.formSearchValuesOrderAdmin);
      this.dataFormSearchProduct(
        this.formSearchValuesOrderAdmin,
        tab,
        'filters-order-admin-overbooked'
      );
      this.dataFormSearchCustomerShort(this.formSearchValuesOrderAdmin);
      this.dataFormSearchMarketName(this.formSearchValuesOrderAdmin);
      this.dataFormSearchSalesModel(this.formSearchValuesOrderAdmin);
      this.dataFormSearchOrigin(this.formSearchValuesOrderAdmin);

      this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
    }
    if (tab === 'salesManager') {
      this.formSearchValuesSalesManager = { ...form };
      this.dataFormSearchSalesManager(this.formSearchValuesSalesManager);
      this.dataFormSearchProduct(
        this.formSearchValuesSalesManager,
        tab,
        'filters-sales-manager-overbooked'
      );
      this.dataFormSearchCustomerShort(this.formSearchValuesSalesManager);
      this.dataFormSearchMarketName(this.formSearchValuesSalesManager);
      this.dataFormSearchSalesModel(this.formSearchValuesSalesManager);
      this.dataFormSearchOrigin(this.formSearchValuesSalesManager);
    }

    if (tab === 'customer') {
      this.formSearchValuesCustomer = { ...form };

      this.dataFormSearchCustomerShort(this.formSearchValuesCustomer, tab);
      this.dataFormSearchProduct(
        this.formSearchValuesCustomer,
        tab,
        'filters-customer-overbooked'
      );
      this.dataFormSearchMarketName(this.formSearchValuesCustomer);
      this.dataFormSearchSalesModel(this.formSearchValuesCustomer);
      this.dataFormSearchOrigin(this.formSearchValuesCustomer);
    }

    if (tab === 'product') {
      this.formSearchValuesProduct = { ...form };

      this.dataFormSearchFamily(this.formSearchValuesProduct);
      this.dataFormSearchCustomerShort(this.formSearchValuesProduct);
      this.dataFormSearchOrigin(this.formSearchValuesProduct);
    }
  }

  createAndAssignCustomerShortSearchInput(
    customerOptions: any = '',
    tab?: string,
    initialValue?: boolean
  ) {
    if (customerOptions?.length > 0) {
      if (initialValue == true) {
        const actualCustomer = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: customerOptions,
          value: customerOptions,
          classCss: 'filters-order-admin-overbooked',
        });

        if (tab == 'orderAdmin') {
          this.searchInputsOrderAdmin[1] = actualCustomer;
        }
        if (tab == 'salesManager') {
          this.searchInputsSalesManager[2] = actualCustomer;
          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
        }
      } else {
        const actualCustomer = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: customerOptions,
          classCss: 'filters-order-admin-overbooked',
          value: customerOptions,
        });

        if (tab == 'orderAdmin') {
          this.searchInputsOrderAdmin[1] = actualCustomer;
        }
        if (tab == 'salesManager') {
          this.searchInputsSalesManager[2] = actualCustomer;
          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
        }
      }
    } else {
      const actualCustomer = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      });
      if (tab == 'orderAdmin') {
        this.searchInputsOrderAdmin[1] = actualCustomer;
        this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
      }
      if (tab == 'salesManager') {
        this.searchInputsSalesManager[2] = actualCustomer;
        this.searchInputsSalesManager = [...this.searchInputsSalesManager];
      }
    }
  }

  dataFormSearchOrderAdmin(formSearch: any) {
    if (formSearch.nameFormControl === 'orderAdminId') {
      if (formSearch.orderAdminId) {
        const customerOptions: any = [];
        this.customerShortEntities.forEach((item: any) => {
          if (item.orderAdmin) {
            let orderAdminSelected = `${item.orderAdmin.last_name}, ${item.orderAdmin.name}`;
            formSearch.orderAdminId.forEach((data: any) => {
              if (orderAdminSelected === data.value) {
                customerOptions.push({
                  id: item.id,
                  value: item.customerShort,
                });
              }
            });
          }
        });

        this.createAndAssignCustomerShortSearchInput(
          customerOptions,
          'orderAdmin',
          false
        );

        this.dataFilterPpm(
          this.ppmEntities,
          this.searchInputsOrderAdmin,
          'filters-order-admin-overbooked'
        );
        this.dataFilterOrigin(this.searchInputsOrderAdmin);

        this.searchInputsOrderAdmin[2].disabled = false;
        this.searchInputsOrderAdmin[5].disabled = false;
        this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];

        const currentOrderAdmin = formSearch.orderAdminId.map(
          (item: any) => item.id
        );
        formSearch.orderAdminId = currentOrderAdmin;

        if (customerOptions) {
          formSearch.customerShortId = customerOptions;
          const currentCustomer = formSearch.customerShortId.map(
            (item: any) => item
          );

          formSearch.customerShortId = currentCustomer;
        }
      } else {
        const orderAdminsFilter: any[] = [];
        formSearch.orderAdminId = '';
        formSearch.customerShortId = '';
        formSearch.ppm = '';
        formSearch.marketName = '';
        formSearch.salesModel = '';
        formSearch.origin = '';
        this.createAndAssignCustomerShortSearchInput([], 'orderAdmin');

        this.orderAdminsEntities.forEach((item: any) => {
          const viewName: string = `${item.last_name}, ${item.name}`;
          orderAdminsFilter.push({ value: viewName, id: item.id });
        });

        const selectMultiOrderAdmin: any = new MultiSelectSearchInput({
          key: 'orderAdminId',
          hover: 'Order Admin',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          classCss: 'first-filter-order-admin',

          options: orderAdminsFilter,
        });

        const selectMultiPpm = new MultiSelectSearchInput({
          key: 'ppm',
          hover: 'PPM',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          largeInput: true,
          disabled: true,
        });

        const selectOrigin = new SelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          hasCheckFlowException: true,
          disabled: true,
        });

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
        });

        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
        });

        this.searchInputsOrderAdmin[0] = selectMultiOrderAdmin;
        this.searchInputsOrderAdmin[2] = selectMultiPpm;
        this.searchInputsOrderAdmin[3] = actualMarketName;
        this.searchInputsOrderAdmin[4] = actualSalesModel;
        this.searchInputsOrderAdmin[5] = selectOrigin;
        this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
      }
    } else {
      if (formSearch.orderAdminId) {
        const currentOrderAdmin = formSearch.orderAdminId.map(
          (item: any) => item.id
        );
        formSearch.orderAdminId = currentOrderAdmin;
      } else {
        formSearch.orderAdminId = '';
      }
    }
  }

  dataFormSearchSalesManager(formSearch: any) {
    if (formSearch.nameFormControl === 'salesManagerId') {
      if (formSearch.salesManagerId) {
        const customerOptions: any = [];
        this.customerShortEntities.forEach((item: any) => {
          if (item.salesManager) {
            let salesManagerOptions = `${item.salesManager.last_name}, ${item.salesManager.name}`;
            formSearch.salesManagerId.forEach((salesManagerSelected: any) => {
              if (salesManagerSelected.value === salesManagerOptions) {
                customerOptions.push({
                  value: item.customerShort,
                  id: item.id,
                });
              }
            });
          }
        });

        this.createAndAssignCustomerShortSearchInput(
          customerOptions,
          'salesManager',
          false
        );

        this.dataFilterPpm(
          this.ppmEntities,
          this.searchInputsSalesManager,
          'filters-sales-manager-overbooked'
        );
        this.dataFilterOrigin(this.searchInputsSalesManager);

        this.searchInputsSalesManager[1].disabled = false;
        this.searchInputsSalesManager[5].disabled = false;
        this.searchInputsSalesManager = [...this.searchInputsSalesManager];

        const currentSalesManager = formSearch.salesManagerId.map(
          (item: any) => item.id
        );
        formSearch.salesManagerId = currentSalesManager;

        if (customerOptions) {
          formSearch.customerShortId = customerOptions;
          const currentCustomer = formSearch.customerShortId.map(
            (item: any) => item
          );

          formSearch.customerShortId = currentCustomer;
        }
      } else {
        const salesManagerFilter: any[] = [];
        formSearch.salesManagerId = '';
        formSearch.ppm = '';
        formSearch.customerShortId = '';
        formSearch.marketName = '';
        formSearch.salesModel = '';
        formSearch.origin = '';
        this.createAndAssignCustomerShortSearchInput([], 'salesManager');

        this.salesManagerEntities.forEach((item: any) => {
          const viewName: string = `${item.last_name}, ${item.name}`;
          salesManagerFilter.push({ value: viewName, id: item.id });
        });

        const selectMultiSalesManager: any = new MultiSelectSearchInput({
          key: 'salesManagerId',
          hover: 'Sales Manager',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          classCss: 'first-filter-order-admin',

          options: salesManagerFilter,
        });

        const selectMultiPpm = new MultiSelectSearchInput({
          key: 'ppm',
          hover: 'PPM',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          largeInput: true,
          disabled: true,
        });

        const selectOrigin = new MultiSelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          hasCheckFlowException: true,
          disabled: true,
        });

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });

        this.searchInputsSalesManager[0] = selectMultiSalesManager;
        this.searchInputsSalesManager[1] = selectMultiPpm;
        this.searchInputsSalesManager[3] = actualMarketName;
        this.searchInputsSalesManager[4] = actualSalesModel;
        this.searchInputsSalesManager[5] = selectOrigin;
        this.searchInputsSalesManager = [...this.searchInputsSalesManager];
      }
    } else {
      if (formSearch.salesManagerId) {
        const currentSalesManager = formSearch.salesManagerId.map(
          (item: any) => item.id
        );
        formSearch.salesManagerId = currentSalesManager;
      } else {
        formSearch.salesManagerId = '';
      }
    }
  }

  dataFormSearchProduct(formSearch: any, tab?: string, claasCssValue?: string) {
    let ppmFinal: any = [];
    let ppmFilter: any[] = [];
    let auxPpm: any = [];

    if (formSearch.nameFormControl == 'ppm') {
      if (formSearch.ppm) {
        const marketNameOptions: any = [];
        const salesModelOptions: any = [];
        const auxMkt: any = [];
        const mktFinal: any = [];

        this.ppmEntities.forEach((item: any) => {
          ppmFilter.push({
            value: item.productDesc,
          });
        });
        ppmFilter.forEach((item: any) => {
          auxPpm.push(item.value);
        });
        const ppm = new Set(auxPpm);
        ppm.forEach((item: any) => {
          ppmFinal.push({
            value: item,
          });
        });

        formSearch.ppm.forEach((data: any) => {
          this.materialEntities.forEach((item: any) => {
            if (data.value === item.allocationBz) {
              marketNameOptions.push({
                value: item.marketName.toUpperCase(),
                id: item.id,
              });

              salesModelOptions.push({ value: item.salesModel, id: item.id });
            }
          });
        });

        marketNameOptions.forEach((item: any) => {
          auxMkt.push(item.value);
        });
        const aux = new Set(auxMkt);
        aux.forEach((item: any) => {
          let count = 0;
          mktFinal.push({
            id: (count += 1),
            value: item,
          });
        });

        const marketNameOrder = mktFinal.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );
        const sales = salesModelOptions.sort((a: any, b: any) =>
          a.value.localeCompare(b.value)
        );

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: false,
          classCss: claasCssValue,
          options: marketNameOrder,
        });

        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: false,
          classCss: claasCssValue,
          options: sales,
        });

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[3] = actualMarketName;
          this.searchInputsOrderAdmin[4] = actualSalesModel;
          this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
        }
        if (tab === 'salesManager') {
          this.searchInputsSalesManager[3] = actualMarketName;
          this.searchInputsSalesManager[4] = actualSalesModel;
          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
        }
        if (tab === 'customer') {
          this.searchInputsCustomer[2] = actualMarketName;
          this.searchInputsCustomer[3] = actualSalesModel;
          this.searchInputsCustomer = [...this.searchInputsCustomer];
        }

        if (formSearch.ppm.length == ppmFinal.length) {
          formSearch.ppm = [];
        } else if (formSearch.ppm.length != ppmFinal.length) {
          const currentPPM = formSearch.ppm.map((item: any) => item.value);
          formSearch.ppm = currentPPM;
        }
      } else {
        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[3] = actualMarketName;
          this.searchInputsOrderAdmin[4] = actualSalesModel;
          this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
        }
        if (tab === 'salesManager') {
          this.searchInputsSalesManager[3] = actualMarketName;
          this.searchInputsSalesManager[4] = actualSalesModel;
          this.searchInputsSalesManager = [...this.searchInputsSalesManager];
        }
        if (tab === 'customer') {
          this.searchInputsCustomer[2] = actualMarketName;
          this.searchInputsCustomer[3] = actualSalesModel;
          this.searchInputsCustomer = [...this.searchInputsCustomer];
        }

        formSearch.ppm = '';
        formSearch.marketName = '';
        formSearch.salesModel = '';
      }
    } else {
      this.ppmEntities.forEach((item: any) => {
        ppmFilter.push({
          value: item.productDesc,
        });
      });
      ppmFilter.forEach((item: any) => {
        auxPpm.push(item.value);
      });
      const ppm = new Set(auxPpm);
      ppm.forEach((item: any) => {
        ppmFinal.push({
          value: item,
        });
      });

      if (formSearch.ppm) {
        if (formSearch.ppm.length == ppmFinal.length) {
          formSearch.ppm = [];
        } else if (formSearch.ppm.length != ppmFinal.length) {
          const currentPPM = formSearch.ppm.map((item: any) => item.value);
          formSearch.ppm = currentPPM;
        }
      } else {
        formSearch.ppm = '';
      }
    }
  }

  dataFormSearchFamily(formSearch: any) {
    if (formSearch.nameFormControl === 'materialId') {
      if (formSearch.materialId) {
        const customerOptions: any = [];
        const currentFamily: any = [];
        const currentFamilyIds: any = [];

        this.customerShortEntities.forEach((item: any) => {
          customerOptions.push({ value: item.customerShort, id: item.id });
        });

        formSearch.materialId.forEach((item: any) => {
          currentFamily.push(item.id);
        });

        for (const array of currentFamily) {
          currentFamilyIds.push(...array);
        }

        this.dataFilterOrigin(
          this.searchInputsProduct,
          'filters-family-origin-overbooked'
        );

        const selectMultiCustomerShort = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: customerOptions,
          classCss: 'filters-family-overbooked',
          disabled: false,
        });

        this.searchInputsProduct[1] = selectMultiCustomerShort;
        this.searchInputsProduct[2].disabled = false;
        this.searchInputsProduct = [...this.searchInputsProduct];

        formSearch.materialId = currentFamilyIds;
      } else {
        formSearch.materialId = '';
        formSearch.customerShortId = '';
        formSearch.origin = '';

        const selectMultiCustomerShort = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          disabled: true,
        });

        const selectOrigin = new SelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          hasCheckFlowException: true,
          disabled: true,
        });

        this.searchInputsProduct[1] = selectMultiCustomerShort;
        this.searchInputsProduct[2] = selectOrigin;
        this.searchInputsProduct = [...this.searchInputsProduct];
      }
    } else {
      if (formSearch.materialId) {
        const currentFamily: any = [];
        const currentFamilyIds: any = [];

        formSearch.materialId.forEach((item: any) => {
          currentFamily.push(item.id);
        });

        for (const array of currentFamily) {
          currentFamilyIds.push(...array);
        }

        formSearch.materialId = currentFamilyIds;
      } else {
        formSearch.materialId = '';
      }
    }
  }

  dataFormSearchCustomerShort(formSearch: any, tab?: string) {
    if (formSearch.nameFormControl === 'customerShortId') {
      this.dataFilterPpm(
        this.ppmEntities,
        this.searchInputsCustomer,
        'filters-customer-overbooked'
      );
      this.dataFilterOrigin(this.searchInputsCustomer);

      this.searchInputsCustomer[1].disabled = false;
      this.searchInputsCustomer[4].disabled = false;
      this.searchInputsCustomer = [...this.searchInputsCustomer];

      if (formSearch.customerShortId) {
        const currentCustomer = formSearch.customerShortId.map(
          (item: any) => item.id
        );
        formSearch.customerShortId = currentCustomer;
      } else {
        formSearch.customerShortId = '';
        if (tab === 'customer') {
          formSearch.origin = '';
          formSearch.ppm = '';
          formSearch.marketName = '';
          formSearch.salesModel = '';
        }

        const selectMultiPpm = new MultiSelectSearchInput({
          key: 'ppm',
          hover: 'PPM',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          largeInput: true,
          disabled: true,
        });

        const selectOrigin = new SelectSearchInput({
          key: 'origin',
          hover: 'Origin',
          hasCheckFlowException: true,
          disabled: true,
        });

        const actualMarketName = new MultiSelectSearchInput({
          key: 'marketName',
          hover: 'Market Name',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        const actualSalesModel = new MultiSelectSearchInput({
          key: 'salesModel',
          hover: 'Sales Model',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });

        this.searchInputsCustomer[1] = selectMultiPpm;
        this.searchInputsCustomer[2] = actualMarketName;
        this.searchInputsCustomer[3] = actualSalesModel;
        this.searchInputsCustomer[4] = selectOrigin;
        this.searchInputsCustomer = [...this.searchInputsCustomer];
      }
    } else {
      if (formSearch.customerShortId) {
        const currentCustomer = formSearch.customerShortId.map(
          (item: any) => item.id
        );
        formSearch.customerShortId = currentCustomer;
      } else {
        formSearch.customerShortId = '';
      }
    }
  }

  dataFormSearchMarketName(formSearch: any) {
    if (formSearch.marketName) {
      let ppmFinal: any = [];
      let ppmFilter: any[] = [];
      let auxPpm: any = [];

      const marketNameOptions: any = [];
      const auxMkt: any = [];
      const mktFinal: any = [];

      this.ppmEntities.forEach((item: any) => {
        ppmFilter.push({
          value: item.productDesc,
        });
      });
      ppmFilter.forEach((item: any) => {
        auxPpm.push(item.value);
      });
      const ppm = new Set(auxPpm);
      ppm.forEach((item: any) => {
        ppmFinal.push({
          value: item,
        });
      });

      ppmFinal.forEach((data: any) => {
        this.materialEntities.forEach((item: any) => {
          if (data.value === item.allocationBz) {
            marketNameOptions.push({
              value: item.marketName.toUpperCase(),
              id: item.id,
            });
          }
        });
      });

      marketNameOptions.forEach((item: any) => {
        auxMkt.push(item.value);
      });
      const aux = new Set(auxMkt);
      aux.forEach((item: any) => {
        let count = 0;
        mktFinal.push({
          id: (count += 1),
          value: item,
        });
      });

      if (mktFinal.length == formSearch.marketName.length) {
        formSearch.marketName = [];
      } else if (mktFinal.length != formSearch.marketName.length) {
        const currentMkt = formSearch.marketName.map((item: any) => item.value);
        formSearch.marketName = currentMkt;
      }
    } else {
      formSearch.marketName = '';
    }
  }

  dataFormSearchSalesModel(formSearch: any) {
    if (formSearch.salesModel) {
      const salesModelOptions: any = [];

      let ppmFinal: any = [];
      let ppmFilter: any[] = [];
      let auxPpm: any = [];

      this.ppmEntities.forEach((item: any) => {
        ppmFilter.push({
          value: item.productDesc,
        });
      });
      ppmFilter.forEach((item: any) => {
        auxPpm.push(item.value);
      });
      const ppm = new Set(auxPpm);
      ppm.forEach((item: any) => {
        ppmFinal.push({
          value: item,
        });
      });

      ppmFinal.forEach((data: any) => {
        this.materialEntities.forEach((item: any) => {
          if (data.value === item.allocationBz) {
            salesModelOptions.push({ value: item.salesModel, id: item.id });
          }
        });
      });

      if (salesModelOptions.length == formSearch.salesModel.length) {
        formSearch.salesModel = [];
      } else if (salesModelOptions.length != formSearch.salesModel.length) {
        const currentSalesModel = formSearch.salesModel.map(
          (item: any) => item.value
        );
        formSearch.salesModel = currentSalesModel;
      }
    } else {
      formSearch.salesModel = '';
    }
  }

  dataFormSearchOrigin(formSearch: any) {
    if (formSearch.origin) {
      const currentOrderAdmin = formSearch.origin;
      if (currentOrderAdmin === 'ALL') {
        formSearch.origin = '';
      } else {
        formSearch.origin = currentOrderAdmin;
      }
    } else {
      formSearch.origin = '';
    }
  }

  applyFilter(tab: string) {
    if (tab === 'orderAdmin') {
      this.applyFilterOrderAdmin();
    }
    if (tab === 'salesManager') {
      this.applyFilterSalesManager();
    }
    if (tab === 'customer') {
      this.applyFilterCustomer();
    }
    if (tab === 'product') {
      this.applyFilterProduct();
    }
  }

  applyFilterOrderAdmin() {
    this.spinner.show();
    let formSearchValues = {};

    if (this.initialValueOrderAdmin.id !== 0) {
      const orderAdmin = {
        orderAdminId: [this.initialValueOrderAdmin.id],
        customerShortId: this.initialValueOrderAdminToCustomerId,
      };
      formSearchValues = { ...orderAdmin, ...this.currentData };

      const dataOrderAdmin = this.getDataTable(formSearchValues, 'orderAdmin');
      this.hideSpinnerAfterAll([dataOrderAdmin]);
    } else {
      formSearchValues = {
        ...this.formSearchValuesOrderAdmin,
        ...this.currentData,
      };

      let params = this.deleteProperty(formSearchValues);
      const dataOrderAdmin = this.getDataTable(params, 'orderAdmin');
      this.hideSpinnerAfterAll([dataOrderAdmin]);
    }
  }

  applyFilterSalesManager() {
    this.spinner.show();
    let formSearchValues = {};

    if (this.initialValueSalesManager.id !== 0) {
      const salesManager = {
        salesManagerId: [this.initialValueSalesManager.id],
        customerShortId: this.initialValueSalesManagerToCustomerId,
      };
      formSearchValues = { ...salesManager, ...this.currentData };

      const dataSalesManager = this.getDataTable(
        formSearchValues,
        'salesManager'
      );
      this.hideSpinnerAfterAll([dataSalesManager]);
    } else {
      formSearchValues = {
        ...this.formSearchValuesSalesManager,
        ...this.currentData,
      };

      let params = this.deleteProperty(formSearchValues);

      const dataSalesManager = this.getDataTable(params, 'salesManager');
      this.hideSpinnerAfterAll([dataSalesManager]);
    }
  }

  applyFilterCustomer() {
    this.spinner.show();
    let formSearchValues = {};

    formSearchValues = {
      ...this.formSearchValuesCustomer,
      ...this.currentData,
    };

    let params = this.deleteProperty(formSearchValues);
    const dataCustomer = this.getDataTable(params, 'customer');
    this.hideSpinnerAfterAll([dataCustomer]);
  }

  applyFilterProduct() {
    this.spinner.show();
    let formSearchValues = {};

    formSearchValues = {
      ...this.formSearchValuesProduct,
      ...this.currentData,
    };

    let params = this.deleteProperty(formSearchValues);
    const dataProduct = this.getDataTable(params, 'product');
    this.hideSpinnerAfterAll([dataProduct]);
  }

  async getDataTable(params?: any, tab?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService
        .postEntity(
          `allocation-customer/report-ppm/overbooked?report=${tab}`,
          params
        )
        .subscribe(
          (response: any) => {
            if (response?.rows.length > 0) {
              if (tab === 'orderAdmin') {
                this.dataOrderAdmin = response;
                this.exportComponents(response, tab);
                this.noResultOrderAdmin = false;
                this.orderAdminSelected = false;
                this.isValid = true;
              } else if (tab === 'salesManager') {
                this.dataSalesManager = response;
                this.exportComponents(response, tab);
                this.noResultSalesManager = false;
                this.salesManagerSelected = false;
                this.isValid = true;
              } else if (tab === 'customer') {
                this.dataCustomer = response;
                this.exportComponents(response, tab);
                this.noResultCustomer = false;
                this.customerSelected = false;
                this.isValid = true;
              } else if (tab === 'product') {
                this.dataProduct = response;
                this.exportComponents(response, tab);
                this.noResultProduct = false;
                this.productSelected = false;
                this.isValid = true;
              }
              resolve();
            } else {
              if (tab === 'orderAdmin') {
                this.noResultOrderAdmin = true;
                this.orderAdminSelected = false;
                this.isValid = false;
              } else if (tab === 'salesManager') {
                this.noResultSalesManager = true;
                this.salesManagerSelected = false;
                this.isValid = false;
              } else if (tab === 'customer') {
                this.noResultCustomer = true;
                this.customerSelected = false;
                this.isValid = false;
              } else if (tab === 'product') {
                this.noResultProduct = true;
                this.productSelected = false;
                this.isValid = false;
              }
              resolve();
            }
          },
          error => {
            reject(error.statusText);
            if (tab === 'orderAdmin') {
              this.noResultOrderAdmin = true;
              this.orderAdminSelected = false;
              this.isValid = false;
            } else if (tab === 'salesManager') {
              this.noResultSalesManager = true;
              this.salesManagerSelected = false;
              this.isValid = false;
            } else if (tab === 'customer') {
              this.noResultCustomer = true;
              this.customerSelected = false;
              this.isValid = false;
            } else if (tab === 'product') {
              this.noResultProduct = true;
              this.productSelected = false;
              this.isValid = false;
            }
          }
        );
    });
  }

  exportComponents(data: any, report?: string) {
    this.exportResult = {
      table: {
        columns: data.headers,
        rows: [...data.rows, ...data.total],
      },
    };

    if (report == 'orderAdmin') {
      this.resultExportOrderAdmin = this.exportResult;
    }
    if (report == 'salesManager') {
      this.resultExportSalesManager = this.exportResult;
    }
    if (report == 'customer') {
      this.resultExportCustomer = this.exportResult;
    }
    if (report == 'product') {
      this.resultExportProduct = this.exportResult;
    }
  }

  prepareExportTable(data: any) {
    const rows: any = data.table.rows.map((row: any) => row);
    const columns = data.table.columns.map((item: any) => ({
      title: item.title,
      props: item.prop,
    }));
    const tableRows: { [key: string]: [] }[] = [];

    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: [] } = {};
      columns.forEach((column: any) => {
        if (rows[i][column.props]) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props]
            : '';
        } else if (rows[i][column.props] === 0) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props]
            : 0;
        } else if (rows[i][column.props] === '') {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props]
            : '';
        } else if (rows[i][column.props] == null) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props]
            : '';
        } else {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props]
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    return { columns: columns, rows: tableRows };
  }

  exportTable(tabs?: any) {
    if (this.selectedTabIndex == 0 && this.dataOrderAdmin != '') {
      const fullJsonExport = this.prepareExportTable(
        this.resultExportOrderAdmin
      );
      this.excelService.exportJsonAsExcelFile(fullJsonExport, 'overbooked');
    }
    if (this.selectedTabIndex == 1 && this.dataSalesManager != '') {
      const fullJsonExport = this.prepareExportTable(
        this.resultExportSalesManager
      );
      this.excelService.exportJsonAsExcelFile(fullJsonExport, 'overbooked');
    }
    if (this.selectedTabIndex == 2 && this.dataCustomer != '') {
      const fullJsonExport = this.prepareExportTable(this.resultExportCustomer);
      this.excelService.exportJsonAsExcelFile(fullJsonExport, 'overbooked');
    }
    if (this.selectedTabIndex == 3 && this.dataProduct != '') {
      const fullJsonExport = this.prepareExportTable(this.resultExportProduct);
      this.excelService.exportJsonAsExcelFile(fullJsonExport, 'overbooked');
    }
  }

  deleteProperty(item: any) {
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  backButton() {
    this.router.navigate(['home/customer-allocation-retail']);
  }
}
