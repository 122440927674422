<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">TERRITORY ALLOCATION</span>
    <div class="container-export">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="!enableApplyFilter"
          [disabled]="!enableApplyFilter"></button>
      </span>
    </div>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <div class="allocation-content">
    <div class="header-table-territory-allocation" *ngIf="!noFilterSelected">
      <div class="title-section">
        <h3 class="t-section">Allocation</h3>
        <h4 class="st-section">{{ filterProductSelected || '--' }}</h4>
      </div>
      <div class="button-filter">
        <div class="icon-zoom">
          <span
            *ngIf="!zoomIn"
            matTooltip="Increase"
            class="icon-zoom-in"
            (click)="handleZoomIn()"></span>
          <span
            *ngIf="zoomIn"
            matTooltip="Decrease"
            class="icon-zoom-out"
            (click)="handleZoomIn()"></span>
        </div>
        <div>
          <a class="next" (click)="nextQuarter(next)"
            >{{ next ? 'PREVIOUS' : 'NEXT' }} QUARTER</a
          >
        </div>
      </div>
    </div>
    <div class="no-filter-selected-session" *ngIf="showNoResults">
      <p>NO RECORDS FOUND.</p>
    </div>
    <div
      class="no-filter-selected-session"
      *ngIf="noFilterSelected; else blockFilterSelected">
      <p>PLEASE,<br />START BY FILTERING A PRODUCT</p>
    </div>
    <ng-template #blockFilterSelected>
      <ngx-syncscroll
        [classSyncScroll]="'drag-timeline'"
        [attributeName]="'drag-scroll-timeline'">
        <div class="table-collapse" [ngClass]="zoomIn ? 'zoomIn' : ''">
          <div
            class="table-collpase-header"
            (click)="toggleTableCollapse()"
            [class.active]="showTableCollapse">
            <h4 class="st-section">Info</h4>
          </div>
          <div
            [@hideShowTableCollapse]="showTableCollapse ? 'show' : 'hide'"
            class="table-collpase-container"
            [class.active]="showTableCollapse">
            <div
              class="table-dynamic-list table-body dragscroll frame romanian"
              [ngClass]="'drag-timeline'"
              [attr.drag-scroll-timeline]="true">
              <app-information-table
                [dataTable]="dataTableInformation"
                (pullInChanged)="changedPullInOrIncrease($event)"
                (valuePullInOrIncrease)="valuePullInOrIncrease($event)"
                [toggleColumnTableValues]="toggleColumnTableValues"
                [zoomIn]="zoomIn"
                [typeTable]="'territory'">
              </app-information-table>
            </div>
          </div>
        </div>

        <div
          class="table-dynamic-list table-body dragscroll frame floss"
          *ngIf="!showNoResults; else blockNoResult"
          [ngClass]="'drag-timeline'"
          [attr.drag-scroll-timeline]="true">
          <app-table-inputs
            [quarter]="filter.quarter"
            [dataTable]="tableResult"
            (inputChanged)="inputChanged($event)"
            (toggleColumnTable)="toggleColumnTable($event)"
            [zoomIn]="zoomIn"
            [typeTable]="'territory'">
          </app-table-inputs>
        </div>
        <ng-template #blockNoResult></ng-template>
      </ngx-syncscroll>
    </ng-template>
    <ng-template #dialogConfirmationDiscardSaveChanges>
      <header class="title-modal" matDialogTitle>CONFIRMATION</header>
      <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
      <mat-dialog-actions>
        <button class="button modal-cancel-button" mat-button matDialogClose>
          NO
        </button>
        <button
          *ngIf="isDiscardChanges"
          class="button modal-action-button"
          mat-button
          matDialogClose="discard">
          YES
        </button>
        <button
          *ngIf="!isDiscardChanges"
          class="button modal-action-button"
          mat-button
          matDialogClose="save">
          YES
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>
  <div class="action-button">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>

    <div
      *ngIf="!showNoResults && isEditTableMode"
      class="buttons-actions-table">
      <div>
        <button
          class="button modal-cancel-button button-cancel"
          mat-button
          (click)="onCancel()">
          CANCEL
        </button>
        <button
          #saveChangesButton
          class="button modal-action-button button-save"
          mat-button
          (click)="onSave()">
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>
