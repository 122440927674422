<ngx-spinner></ngx-spinner>
<div class="container-setup">
  <div class="line-title-btn">
    <span class="title">SETUP MANAGEMENT</span>
    <button
      *ngIf="hasPermissionEdit && selectedTabIndex === 0"
      class="button modal-action-button"
      (click)="prepareToEdit()"
      mat-button>
      EDIT CURRENT SETUP
    </button>

    <button
      *ngIf="hasPermissionEdit && selectedTabIndex === 1"
      class="button modal-action-button"
      [class.display-calendar]="
        selectedTabIndexOnePlan === 2 && selectedTabIndexOpenPo === 2
      "
      (click)="prepareToEditHoldFlag()"
      mat-button>
      EDIT HOLD FLAG
    </button>
  </div>

  <div class="container-setup">
    <app-tab
      [tabs]="tabs"
      [method]="this"
      [messagesDialog]="messages"
      [isEditTableMode]="isEditTableMode"
      [secondTab]="false"
      (activeTabIndexChange)="onActiveTabIndexChange($event)"
      #TabComponent>
    </app-tab>

    <ng-template #ToBook>
      <app-search
        (searchEvent)="onSearchEvent($event, 'toBook')"
        [filters]="searchInputs">
      </app-search>

      <span class="line"></span>

      <div
        *ngIf="showTable && resultNullOrnot !== null"
        class="container-tables">
        <app-simple-table-check
          class="actual-table table-setup-management"
          [data]="resultNullOrnot"
          [title]="titleToBookToAllocate">
        </app-simple-table-check>
      </div>

      <div
        *ngIf="showTable && resultNullOrnot !== null"
        class="container-tables">
        <app-simple-table-check
          class="actual-table table-setup-management"
          [data]="resultEnteredSetup"
          [title]="titleEnteredSetup">
        </app-simple-table-check>
      </div>

      <div
        *ngIf="showTable && resultNullOrnot !== null"
        class="container-tables">
        <app-simple-table-check
          class="actual-table table-setup-management"
          [data]="resultEnteredSetupKa"
          [title]="titleEnteredSetupKa">
        </app-simple-table-check>
      </div>

      <div
        class="no-filter-selected-session"
        *ngIf="!showTable && resultNullOrnot === null">
        <p>{{ messageNoRecordsFound }}</p>
      </div>
      <div class="div-button">
        <button
          class="button back-action-button"
          mat-button
          (click)="backButton()">
          BACK
        </button>
      </div>
    </ng-template>

    <ng-template #OnePlan>
      <app-search
        (searchEvent)="onSearchEvent($event, 'onePlan')"
        [filters]="searchInputsAwaitingShipping"
        *ngIf="selectedTabIndexOnePlan === 0">
      </app-search>

      <div
        [class.button-month-as]="
          selectedTabIndexOnePlan === 0 && noRecordsFoundAs === false
        "
        [class.button-month-od]="
          selectedTabIndexOnePlan === 1 && noRecordsFoundOd === false
        "
        [class.button-month-calendar]="selectedTabIndexOnePlan === 2"
        [class.button-month-op]="
          selectedTabIndexOpenPo === 3 && noRecordsFoundOp === false
        "
        class="button-month">
        <button
          *ngFor="let tab of dataMonths"
          (click)="onChangeMonth(tab)"
          [class.active]="tab === dataCurrentMonth">
          {{ tab }}
        </button>
      </div>

      <div class="app-tab">
        <app-tab
          [tabs]="tabsOnePlan"
          [method]="this"
          [messagesDialog]="messages"
          [isEditTableMode]="isEditTableMode"
          [secondTab]="true"
          [resultTabs]="resultOnePlan"
          [resultTabsCalendar]="resultOnePlanCalendar"
          [noRecordFound]="noRecordsFound"
          (activeTabIndexChange)="onActiveTabIndexChangeOnePlan($event)"
          (onTabOpenPo)="onTabOpenPoChange($event)"
          (paramsCalendar)="onParamsCalendar($event)"
          (cancel)="onCancel($event)"
          #TabComponent>
        </app-tab>
      </div>

      <ng-template #AwaitingShipping></ng-template>

      <ng-template #OrderDummy></ng-template>

      <ng-template #OpenPO></ng-template>

      <div class="div-button">
        <button
          class="button back-action-button"
          mat-button
          (click)="backButton()">
          BACK
        </button>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #modalCreateEdit>
  <form [formGroup]="setupForm" class="form-to-book">
    <header class="title-modal" matDialogTitle>EDIT CURRENT SETUP</header>
    <p style="font-weight: bold; font-size: 12px">TO BOOK + TO ALLOCATE</p>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>To Book</mat-label>
        <mat-select
          formControlName="toBook"
          name="To Book"
          disableOptionCentering
          panelClass="panel-select-unique-setup">
          <mat-option *ngFor="let to of toBookAllocate" [value]="to.value">
            {{ to.name }} +{{ to.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>To Allocate</mat-label>
        <mat-select
          formControlName="toAllocate"
          name="To Allocate"
          disableOptionCentering
          panelClass="panel-select-unique-setup">
          <mat-option *ngFor="let to of toBookAllocate" [value]="to.value">
            {{ to.name }} +{{ to.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <div class="entered">
      <span class="title-entered-one">ENTERED SETUP - RETAIL</span>
      <span class="title-entered-two">ENTERED SETUP - KA</span>
    </div>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Retail</mat-label>
        <mat-select
          formControlName="enteredSetupRetail"
          name="enteredSetupRetail"
          [multiple]="true"
          [compareWith]="compare"
          panelClass="panel-select-unique-entered">
          <mat-option
            *ngFor="let entered of enteredSetupEdit.headers"
            [value]="entered"
            [disabled]="entered.blockEdit">
            {{ entered.month }}/{{ entered.year }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>KA</mat-label>
        <mat-select
          formControlName="enteredSetupKa"
          name="enteredSetupKa"
          [multiple]="true"
          [compareWith]="compare"
          panelClass="panel-select-unique-entered">
          <mat-option
            *ngFor="let entered of enteredSetupEditKa.headers"
            [value]="entered"
            [disabled]="entered.blockEdit">
            {{ entered.month }}/{{ entered.year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="false">
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label>Country</mat-label>
        <mat-select
          formControlName="countryId"
          name="country"
          [disabled]="true">
          <mat-option
            *ngFor="let country of countriesEntity"
            [value]="country.id">
            {{ country.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        class="button modal-action-button"
        mat-button
        (click)="onUpdate()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #modalCreateHoldFlag>
  <form [formGroup]="setupFormHoldFlag" class="form-hold-flag">
    <header class="title-modal" matDialogTitle>EDIT HOLD FLAG</header>

    <mat-dialog-content>
      <mat-form-field
        appearance="outline"
        *ngIf="editValue || editValueOrderDummy">
        <mat-label *ngIf="selectedTabIndexOnePlan === 0"
          >Awaiting Shipping</mat-label
        >
        <mat-select
          formControlName="awaitingShipping"
          name="awaitingShipping"
          panelClass="panel-select-as"
          [value]="
            selectedTabIndexOnePlan ? this.editValue : this.editValueOrderDummy
          ">
          <mat-option *ngFor="let to of awaitingShipping" [value]="to.value">
            {{ to.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        class="button modal-action-button"
        mat-button
        (click)="onUpdateHoldFlag()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content class="content-setup">
    Do you confirm the update of the records?
  </mat-dialog-content>

  <mat-dialog-actions class="confirmation">
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
