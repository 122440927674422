

import { AdminComponent } from './admin.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user/user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    AdminComponent,
    ProfileComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    ShareComponentsModule,
    CommonModule,
    MatTooltipModule,
    FormsModule,
    ShareDirectiviesModule,
    MatTableModule,
    ReactiveFormsModule,
  ],
  exports: [
    AdminComponent,
    ProfileComponent,
    UserComponent
  ],
  providers: [],
  bootstrap: [],
})
export class AdminModule { }
