import { Country } from './../../../interfaces/country.interface';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import {
  tableExpansiveMockup,
  wowVariationMockup,
} from './table-expansive-mockup';
import {
  executiveOverviewMockup,
  executiveOverviewMockupWeekly,
} from './executive-overview-mockup';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { Geo } from 'src/app/interfaces/geo.interface';
import { ToastrService } from 'ngx-toastr';
import { tableLastMonthMockup } from './last-month-table-mockup';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { responseColorMIx } from './color-mix-mockup';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import { OnePlan } from 'src/app/interfaces/onePlan.interface';

import { ExecutiveOverview } from 'src/app/interfaces/executive-overview.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-one-plan',
  templateUrl: './one-plan.component.html',
  styleUrls: ['./one-plan.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          height: '67px',
          overflow: 'hidden',
        })
      ),
      transition('closed <=> open', [animate('0.4s linear')]),
    ]),
    trigger('openCloseExecutiveOverview', [
      state(
        'open',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          height: '67px',
          overflow: 'hidden',
        })
      ),
      transition('closed <=> open', [animate('0.4s linear')]),
    ]),
    trigger('openCloseWow', [
      state(
        'open',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          height: '100px',
          overflow: 'hidden',
        })
      ),
      transition('closed <=> open', [animate('0.4s linear')]),
    ]),
    trigger('openCloseColorMix', [
      state(
        'open',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          height: '100px',
          overflow: 'hidden',
        })
      ),
      transition('closed <=> open', [animate('0.4s linear')]),
    ]),
  ],
})
export class OnePlanComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: TemplateRef<any> | any;
  tableExpansiveMockup = tableExpansiveMockup;
  tableLastMonthMockup = tableLastMonthMockup;
  tableExecutiveOverview = executiveOverviewMockup;
  tableExecutiveOverviewWeekly = executiveOverviewMockupWeekly;

  tableResult!: any;
  tableResultCheck!: any;
  tableResultExecutiveOverview!: any;
  tableResultExecutiveOverviewWeekly!: any;
  tableResultExecutiveOverviewMontly!: any;
  dataSourceTable: any[] = [];
  dataSourceTableExecutiveOverviewMontly: any[] = [];
  dataSourceTableExecutiveOverviewWeekly: any[] = [];
  tableMontly: boolean = true;
  tableWeekly: boolean = false;
  dataSourceTableLastMonth: any[] = [];
  headerTable: any = [];
  rowsTable: any = [];
  eventTable!: string;
  varitationProposal: any = [];
  monthCommit: any = [];
  summedValue: any[] = [];
  fqCommit = responseColorMIx.fqCommit;
  delta = responseColorMIx.delta;
  filterMonths: any[] = [];
  filterMontlyAndWeekly = 'montly';
  toggleColumnTableValues: string[] = [];
  filterMonthsSelected: string = this.getMonthFilter(new Date(Date.now()));
  filterMonthsSelectedCheck: string = this.getMonthFilter(new Date(Date.now()));
  next: boolean = false;
  isEditTableMode: boolean = false;
  isEditModal: boolean = false;
  timeOut: any;
  isSave: boolean = false;
  handleSaveCicked: boolean = false;
  activeOnePlan: boolean = false;
  isDiscardChanges: boolean = false;
  messageModalChanges!: string;
  fyTable: any;
  selectedTabIndex = 0;
  tableOnePlan = false;
  tableCheck = false;
  buttonPublishHability = false;
  tableStart = false;
  blinkButton = false;
  startOrApply = 'get';
  isModeTableOrCardsHide: boolean = true;
  hidePagination: boolean = true;
  showNoResults: boolean = false;
  showNoResultsCheck: boolean = false;
  showTable: boolean = false;
  nameMonth: string[] = [];
  isCurrentMonth: boolean = true;
  notCurrentMonth: boolean = true;
  monthName: string[] = [];
  responseTable: any;
  month = '';
  ppmFamily: any[] = [];
  monthsColorMix = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  monthButton: any = [];
  buttonQM: boolean = true;
  noResultVariationProposal: boolean = false;
  months: any = [
    { label: 'January', value: true },
    { label: 'February', value: false },
    { label: 'March', value: false },
    { label: 'April', value: false },
    { label: 'May', value: false },
    { label: 'June', value: false },
    { label: 'July', value: false },
    { label: 'August', value: false },
    { label: 'September', value: false },
    { label: 'October', value: false },
    { label: 'November', value: false },
    { label: 'December', value: false },
    { label: 'LastMonth', value: false },
  ];

  tabs: { [key: string]: number } = {
    'ONE PLAN': 0,
    'EXECUTIVE OVERVIEW': 1,
    'WOW VARIATION': 2,
    'COLOR MIX': 3,
  };
  keyFiltersOnePlan = [
    'ppm',
    'origin',
    'quarter',
    'fiscalYear',
    'geoId',
    'countryId',
  ];

  keyFiltersExecutive = [
    'family',
    'origin',
    'quarter',
    'fiscalYear',
    'geoId',
    'countryId',
  ];

  keyFiltersWow = [
    'ppm',
    'origin',
    'quarter',
    'fiscalYear',
    'geoId',
    'countryId',
  ];

  holdFlagForm: FormGroup;
  holdFlagOptions: Array<string> = ['All', 'Y', 'N'];
  enableButtonHoldFlag: boolean = true;
  holdFlagValue!: string;

  showExportButton: boolean = true;
  hidePartialContent: boolean = false;
  textshowOrHideFilters: String = 'Hide Filters';
  hideFilterWow: boolean = true;
  hideFilterColorMix: boolean = true;
  textShowOrHideWow: string = 'Show Filters';
  textShowOrHideColorMix: string = 'Show Filters';
  hidePartialContentExecutiveOverview: boolean = true;
  textshowOrHideFiltersExecutiveOverview: string = 'Show Filters';
  putCrudService: any;

  messages: {
    [key: string]: string;
  } = {
    save: 'Do you confirm material data updated?',
    discard: 'Are you sure you want to exit before saving your changes?',
    noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
    publish: 'Do you confirm to publish this version of One Plan?',
  };
  messageNoRecordFound: string;

  dataOnePlan: any;
  dataWowVariation: any;
  currentUser!: any;
  noResultOnePlan: boolean = false;
  showNoWowVariation: boolean = false;
  showNoExecutiveOverview: boolean = false;
  showTableWowVariation: boolean = false;
  noResultColorMix: boolean = true;
  dataColorMix: any;
  getCrudServiceStartOnePlan: any;

  formSearchOnePlan!: { [key: string]: string };
  formSearchExecutiveOverview!: { [key: string]: string };
  formSearchWowVariation!: { [key: string]: string };
  formSearchValuesColorMix!: { [key: string]: string };
  productFamilyFullData: any = [];
  tooltipMessage: String = 'Export';
  isValid: boolean = true;
  isValidPublish: boolean = true;
  isValidSave: boolean = true;
  enableApplyFilter = true;

  searchInputsOnePlan: InputBase<string>[];
  searchInputsExecutiveOverview: InputBase<string>[];
  searchInputsWowVariation: InputBase<string>[];
  searchInputsColorMix: InputBase<string>[];

  responseCountry: any;
  countryEntity: any[] = [];
  countryFilters: any[] = [];
  geosEntity: any[] = [];
  salesModelEntity: any[] = [];
  geosFilter: any[] = [];
  ppmEntity: any[] = [];
  productFamily: any[] = [];
  lastUpdateEntity: any[] = [];
  onePlanLastUpdate: any;
  onePlanBrColorMix: any;
  onePlanDemandColorMix: any;
  onePlanLastUpdateVisible: boolean = false;
  // dados de last refresh mockado
  onePlanLastRefresh: any;
  onePlanLastRefreshVisible: boolean = false;
  currentQuarter!: string;
  selectedQuarter!: string;
  resultExecutiveOverview: any[] = [];
  quarterEntity = [
    { key: '1', value: 'FQ1' },
    { key: '2', value: 'FQ2' },
    { key: '3', value: 'FQ3' },
    { key: '4', value: 'FQ4' },
  ];

  filter: any = {
    status: true,
    pageName: PAGE_NAME_RESOURCE.onePlan,
  };
  originFilter: any[] = [];
  filterExecutiveOverview: any;
  hasPermissionCreate!: boolean;
  hasPermissionEdit!: boolean;
  crudService: CrudService<any>;
  colorMixService: CrudService<any>;
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.onePlan },
    },
    {
      entity: 'country',
      query: {
        sort: 'country',
        pageName: PAGE_NAME_RESOURCE.onePlan,
      },
    },
    {
      entity: 'material/products/summary',
      query: { status: true, pageName: PAGE_NAME_RESOURCE.onePlan },
    },
    {
      entity: 'onePlan/last-updates',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.onePlan },
    },
    {
      entity: 'material-family',
      query: { pageName: PAGE_NAME_RESOURCE.onePlan },
    },
    {
      entity: 'material/salesModels/summary',
      query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.onePlan },
    },
  ];

  dataWeekly: any[] = [];

  colorMixFY: string = '';
  colorMixFQ: string = '';
  applyClicked!: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    protected http: HttpClient,
    private authService: AuthService,
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private excelSheetsService: ExcelSheetsService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private formBuilder: FormBuilder
  ) {
    this.crudService = new CrudService<any>(this.http, 'onePlan');
    this.putCrudService = new CrudService<any>(this.http, 'onePlan/start');
    this.colorMixService = new CrudService<any>(this.http, 'onePlan/color-mix');
    this.searchInputsOnePlan = [
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Fiscal Quarter',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];

    this.searchInputsExecutiveOverview = this.searchInputsOnePlan;
    this.searchInputsWowVariation = this.searchInputsOnePlan;

    this.searchInputsColorMix = [
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'Family',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];

    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('one-plan');
      this.hasPermissionEdit =
        this.permissionService.setButtonEditPermission('one-plan');
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
    };
    this.filter = {
      quarter: this.getQuarterFromDate(new Date()),
    };
    this.filter = {
      ...this.filter,
      fiscalYear: this.checkCurrentYearAndQuarter(),
      geoId: this.currentUser.geoId,
      pageName: PAGE_NAME_RESOURCE.onePlan,
    };
    this.filterExecutiveOverview = {
      fiscalYear: this.checkCurrentYearAndQuarter(),
      geoId: this.currentUser.geoId,
      origin: this.originFilter,
    };
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.selectedQuarter = this.currentQuarter;

    this.holdFlagForm = this.formBuilder.group({
      holdFlag: ['', Validators.required],
    });

    this.messageNoRecordFound = 'NO RECORD FOUND.';
  }

  ngOnInit(): void {
    this.loadData(this.filterMonthsSelected, this.filterMontlyAndWeekly);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableResultExecutiveOverview?.currentValue) {
      this.tableResultExecutiveOverview =
        changes.tableResultExecutiveOverview.currentValue;
      this.dataTableResultExecutiveOverviewMontly();
      this.dataTableResultExecutiveOverviewWeekly();
    }
    this.getMontlyAndWeeklyFilter();
    // this.getTableOnePlan(this.filter);
  }

  loadData(filter?: string, filterOverview?: any) {
    this.getMontlyAndWeeklyFilter();
    this.getQuarter(new Date());

    const promises = [
      this.getDataFilter(),
      this.getDataTableWow(this.filter),
      this.getDataFilter(),
      this.getDataTableExecutiveOverview(this.filter),
      this.getTableOnePlan(this.filter),
      this.getDataTableColorMix(),
    ];

    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  toggleHideFilters() {
    if (this.hidePartialContent) {
      this.textshowOrHideFilters = 'Hide Filters';
    } else {
      this.textshowOrHideFilters = 'Show Filters';
    }
    this.hidePartialContent = !this.hidePartialContent;
  }

  toggleHideFiltersExecutiveOverview() {
    if (this.hidePartialContentExecutiveOverview) {
      this.textshowOrHideFiltersExecutiveOverview = 'Hide Filters';
    } else {
      this.textshowOrHideFiltersExecutiveOverview = 'Show Filters';
    }
    this.hidePartialContentExecutiveOverview =
      !this.hidePartialContentExecutiveOverview;
  }

  toggleWowFilter() {
    if (this.hideFilterWow) {
      this.textShowOrHideWow = 'Hide Filters';
    } else {
      this.textShowOrHideWow = 'Show Filters';
    }
    this.hideFilterWow = !this.hideFilterWow;
  }

  toggleColorMixFilter() {
    if (this.hideFilterColorMix) {
      this.textShowOrHideColorMix = 'Hide Filters';
    } else {
      this.textShowOrHideColorMix = 'Show Filters';
    }
    this.hideFilterColorMix = !this.hideFilterColorMix;
  }

  getDataExecutiveOverview(filter?: string) {
    this.getDataTableExecutiveOverview(filter);
  }

  private capitalizeFirstLetter(letter: string): string {
    return letter.charAt(0).toUpperCase() + letter.slice(1);
  }

  startOnePlan(holdFlag: string) {
    this.spinner.show();
    this.dataFilterPpm(this.ppmEntity, this.productFamily);
    this.dataFilterFiscalYear();
    this.dataFilterFiscalQuarter();
    this.tableResult = [];
    this.summedValue = [];
    this.nameMonth = [];
    this.tableCheck = false;
    this.crudService.getEntity(`onePlan/start?holdFlag=${holdFlag}`).subscribe(
      (response: any) => {
        if (response.shipment) {
          this.months.forEach((month: any) => {
            this.tableStart = true;
            this.tableOnePlan = false;
            this.blinkButton = false;
            this.responseTable = response;
            if (response.shipment[0][month.label] !== undefined) {
              this.month = month.label;
              this.handleMonthsFilter(response.shipment[0])

              if (response.shipment) {
                this.dataTableResult(response, this.month);
              }
            }
            this.spinner.hide();
          });
        }

        if (response.check) {
          this.showTable = true;
          this.tableOnePlan = false;
          this.filterMonthsSelectedCheck = this.capitalizeFirstLetter(
            this.filterMonthsSelectedCheck
          );

          if (response.check[0][this.filterMonthsSelectedCheck] !== undefined) {
            let headers =
              response.check[0][this.filterMonthsSelectedCheck].headers;
            let rows = response.check[0][this.filterMonthsSelectedCheck].rows;

            this.dataTableResultCheck(headers, rows);
          } else {
            this.showTable = false;
          }
        }
        this.spinner.hide();
      },
      (err: any) => {
        this.showNoResults = true;
        this.modalService.closeAll();
        this.toastr.warning(err.error.response, 'Error');
        this.showTable = false;
        this.blinkButton = false;
        this.spinner.hide();
      }
    );
  }

  getTableOnePlan(params?: OnePlan) {
    this.clearTimeout();
    this.spinner.show();
    this.resetValues();
    this.crudService.getEntity(`onePlan/get`, params).subscribe(
      (response: any) => {
        if (this.applyClicked) {
          const promise = [this.handleSuccessResponse(response)];
          this.hideSpinnerAfterAll(promise);
        } else {
          this.handleSuccessResponse(response)
        }
      },
      (err: any) => {
        this.handleErrorResponse(err);
      }
    );
  }

  private resetValues() {
    this.tableResult = [];
    this.summedValue = [];
    this.nameMonth = [];
    this.tableStart = false;
    this.showNoResults = false;
    this.showNoResultsCheck = false;
    this.blinkButton = false;
    this.showTable = false;
  }

  private handleSuccessResponse(response: any) {
    if (response.shipment) {
      this.handleShipmentResponse(response);
    }
  }

  private handleMonthsFilter(response: any) {
    this.nameMonth = Object.keys(response);
  }

  private handleShipmentResponse(response: any) {
    let count: number = 0;

    function currentMonth(response: boolean) {
      if (response) return 1;
      return 0;
    }

    return new Promise<void>((resolve, reject) => {
      this.months.forEach((month: any) => {
        if (response.shipment[0][month.label] !== undefined) {
          const userPermission = this.authService.getUserPermissions();
          const canEditAllocation = userPermission.some(
            (permission: any) =>
              permission.resource === 'one-plan' &&
              (permission.permission === 'UPDATE' ||
                permission.permission === 'CREATE')
          );

          if (!canEditAllocation) {
            response.shipment[0][month.label].headers.forEach((header: any) => {
              if (header.isWeek) {
                response.shipment[0][month.label].rows.forEach((row: any) => {
                  Object.keys(row).forEach((columnName: string) => {
                    if (header.props === columnName) {
                      row[columnName].enableInput = false;
                    }
                  });
                });
              }
            });
          }

          this.tableOnePlan = true;
          this.month = month.label;
          this.handleMonthsFilter(response.shipment[0])
          this.responseTable = response;
          this.dataTableResult(response, this.month);
          count =
            currentMonth(response.shipment[0][this.month].currentMonth) + count;

          if (count === 0) {
            this.showNoResultsCheck = true;
            this.showTable = false;
            this.filterMonthsSelectedCheck = '';
          }

          if (response.shipment[0][this.month].currentMonth) {
            if (response.check) {
              this.filterMonthsSelectedCheck = this.capitalizeFirstLetter(
                this.getMonthFilter(new Date(Date.now()))
              );
              this.handleCheckResponse(
                response,
                this.filterMonthsSelectedCheck
              );
            }
          }
        }
        resolve();
      });
    });
  }

  private handleCheckResponse(response: any, month: string) {
    return new Promise<void>((resolve, reject) => {
      if (response.check[0][month] !== undefined) {
        let headers = response.check[0][month].headers;
        let rows = response.check[0][month].rows;
        this.showTable = true;

        this.dataTableResultCheck(headers, rows);
        this.tableStart = false;
        resolve();
      } else {
        this.showTable = false;
        this.filterMonthsSelectedCheck = '';
        this.showNoResultsCheck = true;
      }
    });
  }

  private handleErrorResponse(err: any) {
    if (err.error.response) {
      this.toastr.warning(err.error.response, 'Error');
      this.blinkButton = true;
    }
    this.showNoResults = true;
    this.showNoResultsCheck = true;
  }

  dataTableResult(response: any, month: string) {
    this.clearTimeout();
    this.filterMonthsSelected = this.capitalizeFirstLetter(
      this.filterMonthsSelected
    );

    if (response.shipment[0][month]?.firstMonth === true) {
      this.filterMonthsSelected = response.shipment[0][month].monthName;
    }

    if (month === this.filterMonthsSelected) {
      let headers = response.shipment[0][month].headers;
      let rows = response.shipment[0][month].rows;

      this.tableResult = {
        table: {
          columns: headers,
          rows: new MatTableDataSource<string>(
            this.convertToDataSource(JSON.stringify(rows))
          ),
        },
      };

      this.tableResult.table.columns.forEach((column: any) => {
        column.passingTabs = true;
        if (this.filterMonthsSelected === 'LastMonth') {
          column.lastMonth = true;
        }
      });
      this.addSubtotalValuesOnePlan(this.tableResult.table);
      this.isValid = false;
    }
  }

  dataTableResultCheck(headers: any, rows: any) {
    this.tableResultCheck = {
      table: {
        columns: headers,
        rows: new MatTableDataSource<any>(
          this.convertToDataSource(JSON.stringify(rows))
        ),
      },
    };
    this.isValid = false;
  }

  handleFilterButton(event: string) {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.isEditTableMode = false;
          this.filterMonthsSelected = event;
          this.dataTableResult(this.responseTable, event);
          this.tableResult.table.columns.forEach((column: any) => {
            column.passingTabs = true;
          });
        }
      });
    } else {
      this.isEditTableMode = false;
      this.filterMonthsSelected = event;
      this.dataTableResult(this.responseTable, event);
      this.tableResult.table.columns.forEach((column: any) => {
        column.passingTabs = true;
      });
    }
  }

  getDataTableExecutiveOverview(params?: any) {
    // this.spinner.show();
    return new Promise<void>((resolve, reject) => {
      this.summedValue = [];
      this.dataSourceTableExecutiveOverviewMontly = [];
      this.dataSourceTableExecutiveOverviewWeekly = [];
      this.tableResultExecutiveOverviewWeekly = [];
      this.tableResultExecutiveOverviewMontly = [];
      this.resultExecutiveOverview = [];
      const search = Object.keys(params).length === 0 ? this.filter : params;

      this.crudService
        .getEntity(`onePlan/executive-overview`, search)
        .subscribe(
          (response: any) => {
            if (response.montly) {
              if (response.montly.rows.length > 0) {
                this.showNoExecutiveOverview = false;
                this.addTypeShowEvent(response.montly.headers);
                this.dataSourceTableExecutiveOverviewMontly.push(
                  response.montly
                );
                this.resultExecutiveOverview.push(response.montly);
                this.dataTableResultExecutiveOverviewMontly(
                  this.dataSourceTableExecutiveOverviewMontly[0].headers,
                  this.dataSourceTableExecutiveOverviewMontly[0].rows
                );
                this.addSubtotalValuesExecutiveOverview(response.montly);
                this.isValid = false;
              } else {
                this.showNoExecutiveOverview = true;
                this.isValid = true;
              }
            }

            if (response.weekly) {
              if (response.weekly.rows.length > 0) {
                this.showNoExecutiveOverview = false;
                this.addTypeShowEvent(response.weekly.headers);
                this.dataSourceTableExecutiveOverviewWeekly.push(
                  response.weekly
                );
                this.resultExecutiveOverview.push(response.weekly);
                this.dataTableResultExecutiveOverviewWeekly(
                  this.dataSourceTableExecutiveOverviewWeekly[0].headers,
                  this.dataSourceTableExecutiveOverviewWeekly[0].rows
                );
                this.addSubtotalValuesExecutiveOverview(response.weekly);
                this.isValid = false;
              } else {
                this.showNoExecutiveOverview = true;
              }
            }
            resolve();
          },
          (err: any) => {
            reject(err);
            this.showNoExecutiveOverview = true;
            this.isValid = true;
          }
        );
    });
  }

  getWeeklyTable(): void {
    this.tableMontly = false;
    this.tableWeekly = true;
    this.filterMontlyAndWeekly = 'weekly';
    this.dataTableResultExecutiveOverviewWeekly(this.filterMontlyAndWeekly);
  }

  getMontlyTable(): void {
    this.tableMontly = true;
    this.tableWeekly = false;
    this.filterMontlyAndWeekly = 'montly';
    this.dataSourceTableExecutiveOverviewMontly[0].headers.forEach(
      (header: any) => {
        if (header?.expanded) {
          delete header.expanded;
        }
      }
    );
    this.dataTableResultExecutiveOverviewMontly(
      this.dataSourceTableExecutiveOverviewMontly[0].headers,
      this.dataSourceTableExecutiveOverviewMontly[0].rows
    );
  }

  dataTableResultExecutiveOverviewMontly(headers?: string, rows?: string) {
    if (headers !== undefined && rows !== undefined) {
      this.tableResultExecutiveOverviewMontly = {
        table: {
          columns: headers,
          rows: new MatTableDataSource<any>(
            this.convertToDataSource(JSON.stringify(rows))
          ),
        },
      };
    }
  }

  dataTableResultExecutiveOverviewWeekly(headers?: string, rows?: string) {
    if (headers !== undefined && rows !== undefined) {
      this.tableResultExecutiveOverviewWeekly = {
        table: {
          columns: headers,
          rows: new MatTableDataSource<any>(
            this.convertToDataSource(JSON.stringify(rows))
          ),
        },
      };
    }
  }

  toggleColumnTable({ col, event }: any) {
    this.toggleColumnTableValues = [];
    this.toggleColumnTableValues.push(col, event);
  }

  inputChange() {
    this.isEditTableMode = true;
    this.isValidSave = false;
    this.toggleBreadcrumb(true);
    this.setTimeountOnSave();
  }

  toggleBreadcrumb(toDisable: boolean) {
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/onePlan', {
      disable: toDisable,
    });
  }

  addTypeShowEvent(response: any) {
    this.eventTable = 'hide-columns-rows';

    response.forEach((header: any) => {
      if (header.expansive === true) {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });
  }

  getDataTableColorMix(search?: any) {
    this.spinner.show();
    const result = search ? search : '';
    return new Promise<void>((resolve, reject) => {
      this.crudService.getEntity('onePlan/color-mix', result).subscribe(
        (response: any) => {
          if (response == '') {
            this.noResultColorMix = true;
          } else {
            this.noResultColorMix = false;
            this.dataColorMix = response;
            if (this.buttonQM) {
              this.varitationProposal = response.varialProposal.month;
            } else {
              this.varitationProposal = response.varialProposal.quarter;
            }
            const orderButton: any = [];
            response.monthCommit.rows.forEach((item: any) => {
              let dataString: any = Object.keys(item);
              let typeDate = new Date(dataString);
              orderButton.push((typeDate.getMonth() + 1) % 12);
            });
            this.monthButton = [];
            orderButton.forEach((item: any, index = 0) => {
              if (index == 0) {
                this.monthButton.push({
                  label: this.monthsColorMix[item],
                  value: true,
                });
              } else {
                this.monthButton.push({
                  label: this.monthsColorMix[item],
                  value: false,
                });
              }
            });
            response.monthCommit.rows.forEach((item: any) => {
              let dataString: any = Object.keys(item);
              let typeDate = new Date(dataString);
              let month: string =
                this.monthsColorMix[(typeDate.getMonth() + 1) % 12];
              this.monthButton.forEach((value: any) => {
                if (value.label == month && value.value) {
                  this.monthCommit = [];
                  this.monthCommit = {
                    headers: response.monthCommit.headers,
                    rows: item[dataString],
                  };
                }
              });
            });
          }
          resolve();
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  changeButtonQM(button: string) {
    if (button == 'month') {
      this.varitationProposal = this.dataColorMix.varialProposal.month;
      this.buttonQM = true;
    } else if (button == 'quarter') {
      this.varitationProposal = this.dataColorMix.varialProposal.quarter;
      this.buttonQM = false;
    }
  }

  getDataTableWow(params: { [key: string]: any } = {}): Promise<any> {
    // this.spinner.show();
    return new Promise<void>((resolve, reject) => {
      this.summedValue = [];
      this.showTableWowVariation = false;
      this.dataWowVariation = [];
      const search = Object.keys(params).length === 0 ? this.filter : params;

      this.crudService.getEntity(`onePlan/variation`, search).subscribe(
        (response: any) => {
          if (response?.expandedTable?.rows.length > 0) {
            this.showTableWowVariation = true;
            this.showNoWowVariation = false;
            this.updateComponents(response.expandedTable);
            this.addSubtotalValuesWow(response.expandedTable);
          } else {
            this.showNoWowVariation = true;
          }
          resolve();
        },
        (err: any) => {
          reject(err);
          this.showNoWowVariation = true;
        }
      );
    });
  }

  updateComponents(data: any) {
    this.dataWowVariation = {
      table: {
        columns: data.headers,
        rows: new MatTableDataSource<any>(data.rows),
        hasCalculation: true,
      },
    };
  }

  addSubtotalValuesWow(response: any) {
    response.headers.forEach((header: any, headerIndex: any) => {
      this.summedValue = [];
      response.rows
        .map((row: any) => {
          const item = row[header.label];

          if (item && item.summbleValue) {
            return item.value;
          }
        })
        .reduce((acc: any, item: any) => {
          if (acc !== undefined && item !== undefined) {
            return (this.summedValue = acc + item);
          }
        }, 0);
      header.totalAddedValue = this.summedValue;
    });
  }

  applyFilter(tab?: string) {
    let formSearchValues = {};
    this.applyClicked = true;
    if (tab === 'wowVariation') {
      formSearchValues = { ...this.formSearchWowVariation };
      let params = this.deleteProperty(formSearchValues);
      this.getDataTableWow(params);
    }

    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          if (tab === 'onePlan') {
            this.getTableOnePlan(this.filter);
            this.discardChanges();
          }
        }
      });
    } else {
      if (tab === 'onePlan') {
        this.getTableOnePlan(this.filter);
        this.discardChanges();
        this.clearTimeout();
        this.changeLabelFiscalYear();
      }
    }
    if (tab === 'colorMix') {
      formSearchValues = { ...this.formSearchValuesColorMix };
      let formColorMix = this.deleteProperty(formSearchValues, tab);
      const promise = [this.getDataTableColorMix(formColorMix)];
      this.hideSpinnerAfterAll(promise);
    }
    if (tab === 'executiveOverview') {
      formSearchValues = { ...this.formSearchExecutiveOverview };
      let formExecutiveOverview = this.deleteProperty(formSearchValues, tab);
      this.getDataTableExecutiveOverview(formExecutiveOverview);
    }
  }

  changeLabelFiscalYear() {
    if (this.formSearchOnePlan?.fiscalYear !== undefined) {
      const fyCurrent = this.formSearchOnePlan?.fiscalYear
        .toString()
        .substring(2, 4);
      const fyNext = this.formSearchOnePlan.fiscalYear.toString().substring(7);
      const fyTable = `FY${fyCurrent}/${fyNext}`;
      this.fyTable = fyTable;
    }
  }

  deleteProperty(item: any, tab?: string) {
    if (item.origin == 'All') {
      item.origin = '';
    }
    if (tab === 'colorMix' || tab === 'executiveOverview') {
      delete item.ppm;
    }
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  addSubtotalValuesOnePlan(response: any) {
    let headerName: any = [];
    this.summedValue = [];
    response.columns
      .filter((item: any) => item.hasSubtotal)
      .forEach((header: any) => {
        headerName = header.props;

        response?.rows.filteredData
          .map((item: any) => {
            return item[headerName]?.value;
          })
          .reduce((acc: any, item: any) => {
            if (acc !== undefined && item !== undefined) {
              return (this.summedValue = acc + item);
            }
          }, 0);
        header.totalAddedValue = this.summedValue;
      });
  }

  addSubtotalValues(response: any) {
    let headerName: any = [];

    response[1].headers.map((header: any) => {
      headerName = header.props;

      response[1].rows
        .map((item: any) => {
          if (item[headerName].summableValue) {
            return item[headerName].value;
          }
        })
        .reduce((acc: any, item: any) => {
          if (acc !== undefined && item !== undefined) {
            return (this.summedValue = acc + item);
          }
        }, 0);
      header.totalAddedValue = this.summedValue;
    });
  }

  addSubtotalValuesExecutiveOverview(response: any) {
    let headerName: any = [];

    response?.headers.map((header: any) => {
      headerName = header.props;

      response?.rows
        .map((item: any) => {
          if (item[headerName]?.summableValue) {
            return item[headerName]?.value;
          }
        })
        .reduce((acc: any, item: any) => {
          if (acc !== undefined && item !== undefined) {
            return (this.summedValue = acc + item);
          }
        }, 0);
      header.totalAddedValue = this.summedValue;
    });
  }

  convertToDataSource(data: any) {
    const array = JSON.parse(data);
    const dataTable: any[] = [];

    array.forEach((item: any) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  getMonthFilter(date: Date) {
    let monthName = date.toLocaleString('en-US', { month: 'long' });
    return monthName.toLowerCase();
  }

  getMontlyAndWeeklyFilter() {
    this.dataWeekly.push(
      {
        label: 'MONTLY',
        value: 'montly',
      },
      {
        label: 'WEEKLY',
        value: 'weekly',
      }
    );

    return this.dataWeekly;
  }

  getQuarterFromDate(date: Date) {
    let month = date.getMonth() + 1;
    this.next === true
      ? (month = date.getMonth() + 4)
      : (month = date.getMonth() + 1);
    if (month >= 4 && month <= 6) return 'FQ1';
    if (month >= 7 && month <= 9) return 'FQ2';
    if (month >= 10 && month <= 12) return 'FQ3';
    return 'FQ4';
  }

  checkCurrentYearAndQuarter() {
    if (this.filter.quarter === 'FQ4') return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  checkQuarterAndYear() {
    if (!this.next && this.filter.quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarter(date: Date) {
    let month = date.getMonth() + 1;

    if (this.next) month = date.getMonth() + 4;
    this.filter.quarter = this.getQuarterFromDate(new Date());
    this.filterMonths = [];

    switch (month) {
      case 4:
      case 5:
      case 6:
        this.filterMonths.push(
          { label: 'April', value: 'april' },
          { label: 'May', value: 'may' },
          { label: 'June', value: 'june' }
        );
        break;
      case 7:
      case 8:
      case 9:
        this.filterMonths.push(
          { label: 'July', value: 'july' },
          { label: 'August', value: 'august' },
          { label: 'September', value: 'september' }
        );
        break;
      case 10:
      case 11:
      case 12:
        this.filterMonths.push(
          { label: 'October', value: 'october' },
          { label: 'November', value: 'november' },
          { label: 'December', value: 'december' }
        );
        break;
      default:
        this.filterMonths.push(
          { label: 'January', value: 'january' },
          { label: 'February', value: 'february' },
          { label: 'March', value: 'march' }
        );
        break;
    }

    this.filterMonths.push({
      label: 'Last Month',
      value: 'lastMonth',
    });

    if (this.next) {
      this.filterMonthsSelected = this.filterMonths[0].value;
      this.filterMonthsSelectedCheck = this.filterMonths[0].value;
    } else {
      this.filterMonthsSelected = this.getMonthFilter(new Date(Date.now()));
      this.filterMonthsSelectedCheck = this.getMonthFilter(
        new Date(Date.now())
      );
    }
  }

  handleSave() {
    const dialog = this.getDialogModal('save');
    dialog.afterClosed().subscribe((result: string) => {
      if (result === 'save') {
        this.clearTimeout();
        this.spinner.show();
        this.onSave();
      }
    });
  }

  onSave(typeSave?: string) {
    // this.spinner.show();
    const newDataToSave: {
      fiscalYear: number;
      quarter: string;
      month: string;
      week: string;
      executedAtWeek: string;
      salesModel: string;
      orderQty: number;
    }[] = [];

    for (let i = 0; i < this.tableResult.table?.rows.filteredData.length; i++) {
      Object.entries(this.tableResult.table?.rows.filteredData[i]).forEach(
        (row: any) => {
          if (row[1].edit) {
            newDataToSave.push({
              fiscalYear: row[1].fiscalYear,
              quarter: row[1].quarter,
              month: row[1].month,
              week: row[0],
              executedAtWeek: row[1].executedAtWeek,
              salesModel: row[1].salesModel,
              orderQty:
                typeof row[1].value === 'string'
                  ? Number(row[1].value.replaceAll(',', ''))
                  : row[1].value,
            });
          }
        }
      );
    }
    /* PUT VALUES */
    this.putCrudService.updateEntity('', newDataToSave).subscribe(
      () => {
        if (newDataToSave !== undefined) {
          this.isEditTableMode = false;
          this.isSave = true;
          this.modalService.closeAll();
          this.spinner.hide();
          if (!typeSave) {
            const subtitleMessage = 'Updated successfully!';
            const titleMessage = 'One Plan';
            this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
            if (this.tableStart) {
              this.checkDiscardStart();
            } else {
              this.getTableOnePlan(this.filter);
            }
          }
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'Error!');
      }
    );
    this.isValidPublish = false;
  }

  setTimeountOnSave() {
    this.clearTimeout();
    this.timeOut = setTimeout(() => {
      this.onSave('autoSave');
    }, 300000);
  }

  clearTimeout() {
    clearTimeout(this.timeOut);
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  checkDiscardAllocation() {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          this.discardChanges();
          if (this.tableStart) {
            this.checkDiscardStart();
          } else {
            this.getTableOnePlan(this.filter);
          }
        }
      });
    } else {
      this.discardChanges();
      if (this.tableStart) {
        this.checkDiscardStart();
      } else {
        this.getTableOnePlan(this.filter);
      }
    }
  }

  checkDiscardStart() {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          this.discardChanges();
          this.modalService.closeAll();
          this.startOnePlan(this.holdFlagValue);
        }
      });
    } else {
      this.discardChanges();
      this.modalService.closeAll();
      this.startOnePlan(this.holdFlagValue);
    }
  }

  onCancel() {
    this.checkDiscardAllocation();
  }

  handlePublish() {
    const dialog = this.getDialogModal('publish');
    dialog.afterClosed().subscribe((result: string) => {
      if (result === 'save') {
        this.spinner.show();
        this.crudService.getEntity(`onePlan/publish`).subscribe(
          () => {
            const subtitleMessage = 'Updated successfully!';
            const titleMessage = 'One Plan successfully published!!!';
            this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
            this.isEditTableMode = false;
            this.isSave = false;
            this.modalService.closeAll();
            this.applyFilter('onePlan');
            this.isValidPublish = true;
          },
          (err: any) => {
            this.spinner.hide();
            this.modalService.closeAll();
            this.toastr.error(err.error.message, 'Error!');
          }
        );
      }
    });
  }

  getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  dataFilterGeo(response: any) {
    const initialValueGeoFilter: { [key: string]: any } = {};

    response.forEach((item: any) => {
      this.geosEntity[item.geo] = item.id;
      this.geosFilter.push({ value: item.geo, id: item.id });
      if (item.id === this.currentUser.geoId) {
        initialValueGeoFilter.value = item.geo;
        initialValueGeoFilter.id = item.id;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      classCss: 'filter-one-plan',
      hasAutocomplete: true,
      options: this.geosFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
      hasCheckFlowException: true,
    });

    this.searchInputsOnePlan[4] = selectGeo;
    this.searchInputsOnePlan[4].notClearable = true;
    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.searchInputsWowVariation[4] = selectGeo;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    this.searchInputsExecutiveOverview[4] = selectGeo;
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];

    this.searchInputsColorMix[2] = selectGeo;
    this.searchInputsColorMix = [...this.searchInputsColorMix];
  }

  dataFilterCountry(response: any) {
    const initialValueCountryFilter: { [key: string]: any } = {};

    response.forEach((item: any) => {
      this.countryEntity[item.country] = item.id;
      this.countryFilters.push({ value: item.country, id: item.id });
      if (item.id === this.currentUser.countryId) {
        initialValueCountryFilter.value = item.country;
        initialValueCountryFilter.id = item.id;
      }
    });

    const orderFilters = this.countryFilters.sort(this.ordemAlfabetica);

    const selectCountry = new SelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'text',
      classCss: 'filter-one-plan',
      hasAutocomplete: true,
      options: orderFilters,
      value: initialValueCountryFilter.value,
      disabled: true,
    });

    this.searchInputsOnePlan[5] = selectCountry;
    this.searchInputsOnePlan[5].notClearable = true;

    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.searchInputsWowVariation[5] = selectCountry;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    this.searchInputsExecutiveOverview[5] = selectCountry;
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];

    this.searchInputsColorMix[3] = selectCountry;
    this.searchInputsColorMix = [...this.searchInputsColorMix];
  }

  ordemAlfabetica(a: any, b: any) {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  }

  dataFilterPpm(response: any, family: any) {
    const ppmFilter: any[] = [];

    response.forEach((item: any) => {
      ppmFilter.push({ value: item.productDesc, id: item.id });
    });

    family.forEach((item: any, index = 0) => {
      const existingItem = this.ppmFamily.find(
        ppmItem => ppmItem.value === item.family
      );

      if (!existingItem) {
        this.ppmFamily.push({
          id: index,
          value: item.family,
        });
      }
    });

    const selectProduct = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'PPM',
      type: 'text',
      classCss: 'ppm-filter-one-plan',
      hasAutocomplete: true,
      options: ppmFilter,
      value: ppmFilter,
      hasCheckFlowException: true,
    });

    const selectPpmFamily = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'Family',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: this.ppmFamily,
      value: this.ppmFamily,
      classCss: 'ppm-filter-one-plan',
    });

    this.searchInputsOnePlan[0] = selectProduct;
    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.searchInputsWowVariation[0] = selectProduct;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    this.searchInputsExecutiveOverview[0] = selectPpmFamily;
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];

    this.searchInputsColorMix[0] = selectPpmFamily;
    this.searchInputsColorMix = [...this.searchInputsColorMix];
  }

  dataFilterFiscalYear() {
    const fiscalYearFilter: any[] = [];
    const currentYear = this.checkCurrentYearAndQuarter();
    const initialValueFiscalYearFilter: { [key: string]: any } = {};

    for (let year = currentYear, i = 0; year <= currentYear + 1; year++, i++) {
      fiscalYearFilter.push({
        key: i,
        value: `${year}/${year + 1}`,
      });
    }
    const fyCurrentYear = currentYear.toString().substring(2, 4);
    const nextYear = currentYear;
    const nextYearPlusOne = nextYear + 1;
    const fyNextYear = nextYearPlusOne.toString().substring(2, 4);
    const fyTable = `FY${fyCurrentYear}/${fyNextYear}`;
    this.fyTable = fyTable;

    initialValueFiscalYearFilter.value = fiscalYearFilter[0].value;

    const selectFiscalYear = new SelectSearchInput({
      key: 'fiscalYear',
      hover: 'Fiscal Year',
      type: 'text',
      hasAutocomplete: true,
      options: fiscalYearFilter,
      value: `${currentYear}/${currentYear + 1}`,
    });

    this.searchInputsOnePlan[3] = selectFiscalYear;
    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.searchInputsWowVariation[3] = selectFiscalYear;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    this.searchInputsExecutiveOverview[3] = selectFiscalYear;
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];

    let auxColorMixFYOne: string = currentYear.toString().substring(2, 4);
    let auxColorMixFYTwo: string = (currentYear + 1).toString().substring(2, 4);
    this.colorMixFY = `FY${auxColorMixFYOne}/${auxColorMixFYTwo}`;
  }

  dataFilterFiscalQuarter() {
    const fiscalQuarterFilter = this.quarterEntity;
    const initialValueFiscalYearQuarter: { [key: string]: string } = {};

    initialValueFiscalYearQuarter.value = this.currentQuarter;
    const selectFiscalQuarter = new SelectSearchInput({
      key: 'quarter',
      hover: 'Fiscal Quarter',
      type: 'text',
      hasAutocomplete: true,
      options: fiscalQuarterFilter,
      value: initialValueFiscalYearQuarter.value,
      hasCheckFlowException: true,
    });

    this.searchInputsOnePlan[2] = selectFiscalQuarter;
    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.searchInputsWowVariation[2] = selectFiscalQuarter;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    this.searchInputsExecutiveOverview[2] = selectFiscalQuarter;
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];

    this.colorMixFQ = `${this.currentQuarter} COMMIT`;
  }

  dataFilterOrigin() {
    if (this.originFilter.length === 0) {
      this.originFilter.push(
        { value: 'All' },
        { value: 'JAG' },
        { value: 'MAN' }
      );
    }

    const selectOrigin = new SelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      type: 'text',
      hasAutocomplete: true,
      options: this.originFilter,
      value: this.originFilter[0].value,
      hasCheckFlowException: true,
    });

    this.searchInputsOnePlan[1] = selectOrigin;
    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.searchInputsWowVariation[1] = selectOrigin;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    this.searchInputsExecutiveOverview[1] = selectOrigin;
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];

    this.searchInputsColorMix[1] = selectOrigin;
    this.searchInputsColorMix = [...this.searchInputsColorMix];
  }

  getLastUpdate(response: any) {
    this.onePlanLastUpdate = response.onePlanFlexLastUpdate;
    this.onePlanLastRefresh = response.enteredLastRefresh;
    this.onePlanBrColorMix = response.onePlanBrUpdate;
    this.onePlanDemandColorMix = response.onePlanDemandUpdate;
  }

  async getDataFilter(): Promise<any> {
    // this.spinner.show();
    return new Promise<void>((resolve, reject) => {
      this.crudService
        .getDataFilters(this.entities)
        .subscribe((response: any) => {
          [
            this.geosEntity,
            this.countryEntity,
            this.ppmEntity,
            this.lastUpdateEntity,
            this.productFamily,
            this.salesModelEntity,
          ] = response;
          this.productFamilyFullData = this.productFamily;
          this.dataFilterGeo(this.geosEntity);
          this.dataFilterPpm(this.ppmEntity, this.productFamily);
          this.dataFilterOrigin();
          this.dataFilterFiscalYear();
          this.dataFilterFiscalQuarter();
          this.getLastUpdate(this.lastUpdateEntity);
          this.searchInputsOnePlan = [...this.searchInputsOnePlan];
          this.searchInputsExecutiveOverview = [
            ...this.searchInputsExecutiveOverview,
          ];
          this.searchInputsWowVariation = [...this.searchInputsWowVariation];
          this.searchInputsColorMix = [...this.searchInputsColorMix];
          this.crudService
            .getEntity(
              `country?geo_id=${this.filter.geoId}&pageName=${PAGE_NAME_RESOURCE.onePlan}`
            )
            .subscribe((response: any) => {
              this.countryEntity = response;
              this.dataFilterCountry(this.countryEntity);
            });
          resolve();
        }),
        (err: any) => {
          reject(err);
        };
      this.searchInputsOnePlan = [...this.searchInputsOnePlan];
      this.searchInputsExecutiveOverview = [
        ...this.searchInputsExecutiveOverview,
      ];
      this.searchInputsWowVariation = [...this.searchInputsWowVariation];
      this.searchInputsColorMix = [...this.searchInputsColorMix];
    });
  }

  dataFormSearchGeo(formSearchName: any) {
    if (formSearchName.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchName.geoId.toLocaleUpperCase())
      );
      formSearchName.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchName.geoId = '';
    }
  }

  prepareToEdit() {
    this.isEditModal = true;
    this.enableButtonHoldFlag = true;
    const modal = this.modalService.createModal(this.modalCreateEdit);
    modal.afterClosed().subscribe(() => {
      this.holdFlagForm.reset();
    });
  }

  onChangeValueHoldFlag(value: string) {
    if (value) {
      this.holdFlagValue = value;
      this.enableButtonHoldFlag = false;
    } else {
      this.enableButtonHoldFlag = true;
    }
  }

  dataFormSearchCountry(formSearchName: any) {
    if (formSearchName.countryId) {
      const currentCountry = this.countryEntity.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(formSearchName.countryId.toLocaleUpperCase())
      );
      formSearchName.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      formSearchName.countryId = '';
    }
  }

  dataFormSearchPpm(formSearchName: any) {
    if (formSearchName.ppm) {
      const currentPPM = formSearchName.ppm.map((item: any) => item.value);
      formSearchName.ppm = currentPPM;
    } else {
      formSearchName.ppm = '';
    }
  }

  dataFormSearchFamily(formSearchName: any) {
    const currentIdsFamily: any = [];

    this.productFamilyFullData?.forEach((item: any) => {
      if (formSearchName.ppm) {
        formSearchName.ppm?.forEach((data: any) => {
          if (item.family == data.value) {
            item.ids.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
        formSearchName.materialId = currentIdsFamily.map((item: any) => item);
      }
    });

    formSearchName.materialId = formSearchName.materialId
      ? formSearchName.materialId
      : '';
  }

  dataFormSearchOrigin(formSearchName: any) {
    formSearchName.origin = formSearchName.origin ? formSearchName.origin : '';
  }

  dataFormSearchOriginOnePlan(formSearchName: any) {
    formSearchName.origin = formSearchName.origin ? formSearchName.origin : '';
    this.deleteProperty(formSearchName);
  }

  dataFormFiscalYear(formSearchName: any) {
    formSearchName.fiscalYear = formSearchName.fiscalYear
      ? formSearchName.fiscalYear
      : '';
  }

  dataFormFiscalQuarter(formSearchName: any) {
    formSearchName.quarter = formSearchName.quarter
      ? formSearchName.quarter
      : '';
  }

  dataFormStatus(formSearchName: any) {
    if (formSearchName.status) {
      const currentStatus = formSearchName.status.map(
        (item: any) => item.value
      );
      formSearchName.status = currentStatus[0];
    } else {
      formSearchName.status = '';
    }
  }

  checkValidFilterValid(filter: any) {
    if (filter.geoId == null || filter.countryId == '') {
      this.enableApplyFilter = false;
    } else if (filter.geoId == '' || filter.countryId == null) {
      this.enableApplyFilter = false;
    } else {
      this.enableApplyFilter = true;
    }
  }

  onSearchEvent(form: any, tab?: string) {
    // this.checkValidFilterValid(form);

    if (tab === 'onePlan') {
      this.formSearchOnePlan = { ...form };
      this.dataFormSearchGeo(this.formSearchOnePlan);
      this.dataFormSearchCountry(this.formSearchOnePlan);
      this.dataFormSearchPpm(this.formSearchOnePlan);
      this.dataFormSearchOriginOnePlan(this.formSearchOnePlan);
      this.dataFormFiscalYear(this.formSearchOnePlan);
      this.dataFormFiscalQuarter(this.formSearchOnePlan);

      this.enableApplyFilter = Boolean(
        this.formSearchOnePlan[this.keyFiltersOnePlan[2]] &&
          this.formSearchOnePlan[this.keyFiltersOnePlan[3]]
      );
    }

    if (tab === 'executiveOverview') {
      this.formSearchExecutiveOverview = { ...form };
      this.dataFormSearchGeo(this.formSearchExecutiveOverview);
      this.dataFormSearchCountry(this.formSearchExecutiveOverview);
      this.dataFormSearchFamily(this.formSearchExecutiveOverview);
      this.dataFormSearchOrigin(this.formSearchExecutiveOverview);
      this.dataFormFiscalYear(this.formSearchExecutiveOverview);
      this.dataFormFiscalQuarter(this.formSearchExecutiveOverview);

      this.enableApplyFilter = Boolean(
        this.formSearchExecutiveOverview[this.keyFiltersExecutive[2]] &&
          this.formSearchExecutiveOverview[this.keyFiltersExecutive[3]]
      );
    }

    if (tab === 'wowVariation') {
      this.formSearchWowVariation = { ...form };
      this.dataFormSearchGeo(this.formSearchWowVariation);
      this.dataFormSearchCountry(this.formSearchWowVariation);
      this.dataFormSearchPpm(this.formSearchWowVariation);
      this.dataFormSearchOrigin(this.formSearchWowVariation);
      this.dataFormFiscalYear(this.formSearchWowVariation);
      this.dataFormFiscalQuarter(this.formSearchWowVariation);

      this.selectedQuarter = this.formSearchWowVariation.quarter
        ? this.formSearchWowVariation.quarter
        : '';

      this.enableApplyFilter = Boolean(
        this.formSearchWowVariation[this.keyFiltersWow[2]] &&
          this.formSearchWowVariation[this.keyFiltersWow[3]]
      );
    }

    if (tab === 'colorMix') {
      this.formSearchValuesColorMix = { ...form };
      this.dataFormSearchFamily(this.formSearchValuesColorMix);
      this.dataFormSearchOrigin(this.formSearchValuesColorMix);
      this.dataFormSearchGeo(this.formSearchValuesColorMix);
      this.dataFormSearchCountry(this.formSearchValuesColorMix);
    }
    this.searchInputsExecutiveOverview = [
      ...this.searchInputsExecutiveOverview,
    ];
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];
    this.searchInputsColorMix = [...this.searchInputsColorMix];
    this.searchInputsOnePlan = [...this.searchInputsOnePlan];

    this.filter = {
      ...this.filter,
      ...this.formSearchOnePlan,
    };
  }

  handleButtonMonthCommit(item: any) {
    this.monthButton.forEach((data: any) => {
      if (data.label === item.label) {
        data.value = true;
        this.dataColorMix.monthCommit.rows.forEach((value: any) => {
          let dataString: any = Object.keys(value);
          let typeDate = new Date(dataString);
          let month: string =
            this.monthsColorMix[(typeDate.getMonth() + 1) % 12];
          if (data.label == month) {
            this.monthCommit = [];
            this.monthCommit = {
              headers: this.dataColorMix.monthCommit.headers,
              rows: value[dataString],
            };
          }
        });
      } else {
        data.value = false;
      }
    });
  }

  tabClick(event: any) {
    this.changeTab(event.target.innerText);
  }

  changeTab(label: string) {
    if (this.tabs[label] || this.tabs[label] === 0) {
      this.selectedTabIndex = this.tabs[label];
      this.enableExportButton(label);
    }
  }

  enableExportButton(tab: string): void {
    if (tab === 'WOW VARIATION' || tab === 'COLOR MIX') {
      this.showExportButton = false;
    } else {
      this.showExportButton = true;
    }
  }

  prepareExportTableOnePlan(data: any, month?: any): any {
    this.spinner.show();

    // Função para adicionar valores subtotalizados
    function addSubtotalValues() {
      let headerName: any = [];
      let summedValue: any = [];
      columns
        .filter((item: any) => item.hasSubtotal)
        .forEach((header: any) => {
          headerName = header.props;

          rows
            .map((item: any) => {
              return item[headerName]?.value;
            })
            .reduce((acc: any, item: any) => {
              if (acc !== undefined && item !== undefined) {
                return (summedValue = acc + item);
              }
            }, 0);
          header.totalAddedValue = summedValue;
        });
    }

    // Obtém as linhas da tabela
    const rows: any[] = data?.rows.map((item: any) => item);

    const tableRows: { [key: string]: [] }[] = [];

    // Obtém as colunas da tabela
    const columns: any[] = data?.headers.map((item: any) => ({
      title: item.title,
      props: item.props,
      hasSubtotal: item.hasSubtotal || false,
      otherContent: item.otherContent || false,
    }));

    // Converte as linhas em formato de tabela
    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: [] } = {};
      columns.forEach((column: any) => {
        if (rows[i][column.props]) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        } else {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    if (this.selectedTabIndex === this.tabs['EXECUTIVE OVERVIEW']) {
      // Adiciona valores subtotalizados
      let headerName: any = [];
      let summedValue: any = [];

      columns.map((header: any) => {
        headerName = header.props;

        rows
          .map((item: any) => {
            if (item[headerName].summableValue) {
              return item[headerName].value;
            }
          })
          .reduce((acc: any, item: any) => {
            if (acc !== undefined && item !== undefined) {
              return (summedValue = acc + item);
            }
          }, 0);
        header.totalAddedValue = summedValue;
      });

      columns.forEach((column: any) => {
        if (month === 'weekly') {
          if (column.props.includes('wk')) {
            let month: string = column.props.match(/[A-Za-z]+$/);
            column.title = `${month[0].toUpperCase()} - ${column.title}`;
          }
        }
      });

      // Cria a última linha da tabela
      const tableRow: { [key: string]: any } = {};
      columns.forEach((column: any) => {
        if (column.title.includes('FAMILY')) {
          tableRow[column.props] = 'TOTAL';
        } else {
          tableRow[column.props] = column.totalAddedValue
            ? column.totalAddedValue
            : '-';
        }
      });
      tableRows.push(tableRow);

      return {
        columns,
        rows: tableRows,
        sheet: month,
        headers: true,
      };
    }

    // Adiciona valores subtotalizados
    addSubtotalValues();

    // Cria a última linha da tabela
    const tableRow: { [key: string]: any } = {};
    columns.forEach((column: any) => {
      if (column.title.includes('PRODUCT')) {
        tableRow[column.props] = 'TOTAL';
      } else {
        tableRow[column.props] = column.totalAddedValue
          ? column.totalAddedValue
          : '-';
      }
    });
    tableRows.push(tableRow);

    return {
      columns,
      rows: tableRows,
      sheet: month,
      headers: true,
    };
  }

  exportTableOnePlan() {
    let allocateJsonExport: any = [];

    this.months.forEach((month: any) => {
      this.responseTable.shipment.forEach((data: string) => {
        if (data[month.label] !== undefined) {
          allocateJsonExport.push(
            this.prepareExportTableOnePlan(data[month.label], month.label)
          );
        }
      });
    });

    this.excelSheetsService.exportJsonAsExcelFile(
      allocateJsonExport,
      'one-plan'
    );
  }

  exportTableExecutiveOverview() {
    const sheets = ['monthly', 'weekly'];

    const allocateJsonExport = this.resultExecutiveOverview.map(data => {
      const sheet = sheets.shift();
      return this.prepareExportTableOnePlan(data, sheet);
    });

    this.excelSheetsService.exportJsonAsExcelFile(
      allocateJsonExport,
      'one-plan-executive-overview'
    );
  }

  exportTable(): void {
    if (this.selectedTabIndex === this.tabs['ONE PLAN']) {
      this.exportTableOnePlan();
    } else if (this.selectedTabIndex === this.tabs['EXECUTIVE OVERVIEW']) {
      this.exportTableExecutiveOverview();
    }
    this.spinner.hide();
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          this.router.navigate(['home/one-plan']);
        }
      });
    } else {
      this.router.navigate(['home/one-plan']);
    }
  }
}
