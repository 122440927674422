<div class="container">
  <div class="access-denied-center">
    <div class="content-main-logo-denied">
      <span class="content-img-denied">
        <img src="../../../assets/images/ico-denied.svg">
      </span>
    </div>
    <div class="content-main-access">
      <span class="access-denied">
        ACCESS DENIED
      </span>
    </div>
    <div class="content-access">
      <span class="content-bd-access">
        You do not have permission for this content.<br>
        Please contact your admin.
      </span>
    </div>
    <div class="content-main-btn">
      <button mat-button class="button action-button" (click)="redirectHome()" >HOME</button>
    </div>
  </div>
</div>