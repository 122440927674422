import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ToastrService } from 'ngx-toastr';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { Material } from 'src/app/interfaces/material.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { UtilitiesService } from 'src/app/services/generic/utilities.service';
import { Subscription } from 'rxjs';
import { CardColorInterface } from 'src/app/components/card-color/card-color.interface';
import { TableCardComponent } from 'src/app/components/table-card/table-card.component';
import { ExcelService } from 'src/app/services/generic/excel.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { Ka } from 'src/app/interfaces/ka.interface';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import {
  responseAllocation,
  cardsFairDistribution,
  responseCardTable,
  expandedTable,
  responseColumnMarkerChart,
} from './ka-allocation-mockup';
import { CardInterface } from 'src/app/components/cards/card.interface';
import {
  barChartDataMockup,
  cards,
  lineChartDataMockup,
} from './ka-allocation-deal-allocation-mockup';
import { sypplySummary } from './supply-summary-mockup';
import { MatTableDataSource } from '@angular/material/table';
import { Tab } from 'src/app/interfaces/tab.interface';
import { TabComponent } from 'src/app/components/tab/tab.component';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import { CurrentUser } from 'src/app/interfaces/user.interface';
import { KeysFilters } from 'src/app/interfaces/filter.interface';

@Component({
  selector: 'app-ka-allocation',
  templateUrl: './allocation-ka-allocation.component.html',
  styleUrls: ['./allocation-ka-allocation.component.scss'],
  animations: [
    trigger('hideShowGeneralInformations', [
      state(
        'hide',
        style({
          height: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'show',
        style({
          height: '92px',
          'overflow-y': 'hidden',
        })
      ),
      transition('hide <=> show', [animate('0.4s 0.1s ease-out')]),
    ]),
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class AllocationKaAllocationComponent implements OnInit {
  @ViewChild('tableCard') tableCard: TableCardComponent | undefined;
  @ViewChild('Allocation') Allocation!: TemplateRef<any>;
  @ViewChild('DealVsAllocation') DealVsAllocation!: TemplateRef<any>;
  @ViewChild('DemandVsMls') DemandVsMls!: TemplateRef<any>;
  @ViewChild('TabComponent') TabComponent!: TabComponent;

  tabs: Tab[] = [];
  indexAllocationLastUpdate = 0;
  tabsIndex: { [key: string]: any };

  searchInputs: InputBase<string>[];
  searchInputsColorMix: InputBase<string>[];
  searchInputsDealAllocation: InputBase<string>[];
  searchInputsDemandVsMls: InputBase<string>[];
  searchSalesModel: any = [];

  crudService: CrudService<Material>;
  crudServiceKa: CrudService<any>;

  variationForm!: FormGroup;
  allocationForm!: FormGroup;
  allocationForms: { [key: string]: any };
  columnsFormGroup: { [key: string]: any };
  columnsFormGroupRetail: { [key: string]: any };
  columnsFormGroupVariation: { [key: string]: any };
  formGroupToUpdate: FormGroup[];
  allAllocationFormControls: any[];
  allVariationFormControls: any[];

  formSearchValues!: { [key: string]: any };
  formSearchValuesColorMix!: { [key: string]: any };
  formSearchValuesDealAllocation!: { [key: string]: any };
  formSearchValuesSupplySummary!: { [key: string]: any };
  formSearchValuesDemandVsMls!: { [key: string]: any };

  sypplySummaryMockup = sypplySummary;
  summedValue: any[];
  exportResult!: any;
  exportMontly!: any;
  exportWeekly!: any;
  exportMontlyResult!: any;
  exportWeeklyResult!: any;
  resultSupplySumary: any;

  cards!: any;
  cardsMockup = cards;
  cardsColor: any[];
  cardsFairDistribution = cardsFairDistribution;

  dataChartDemandVsMls: any;
  barChart!: any;
  barChartMockup = barChartDataMockup;
  lineChart!: any;
  lineChartMockup = lineChartDataMockup;
  responseChartMockup = responseColumnMarkerChart;

  productFilterDealAllocations: any[];
  productsIDSForSaleModels: any;
  geosFilter: any[];
  countryFilter: any[];
  countryFilterAll: any[];
  statusFilter: any[];
  statusFilterDealVsAllocation: any[];
  originFilter: any[];
  originFilterDealAllocation: any[];
  originFilterDemandVsMls: any[];
  productFilter: any[];
  kaFilter: any[];
  salesModelFilter: any[];
  colorFilter: any;
  initialGeo: any[];
  filterKaRetail: boolean;
  filter: any;
  colorFilterMockup: any;
  noFilterSelected: boolean;
  oldValuesFilters: KeysFilters;
  keysFilters: KeysFilters;
  keysFiltersDealAllocation: KeysFilters;
  keysFiltersDemandVsMls: KeysFilters;
  enableApplyFilter: boolean;

  geosEntity: any[] = [];
  countryEntity: any[] = [];
  responseCountry: any;
  productEntity: string[] = [];
  salesModelEntity: string[] = [];
  productFamilyEntity: string[] = [];
  colorEntity: string[] = [];
  geosEntities: { [key: string]: any };
  countriesEntities: { [key: string]: any };
  salesModelsEntities: any[];
  productEntities: any[];
  kaEntities: any[];
  entities: Array<getEntities>;
  filtersEntities: Array<getEntities>;

  showGeneralInformationsArrow: boolean;
  showGeneralInformations: boolean;
  showAllocationSection: boolean;
  showFairDistribution: boolean;
  showImageNoResults: boolean;
  showNoResults: boolean;
  showNoResultsDealVsAllocation: boolean;
  showNoResultsDemandVsMls: boolean;

  isEditTableMode: boolean;
  isDiscardChanges: boolean;

  next = true;
  response = responseAllocation;
  fqCommit = responseCardTable.fqCommit;
  delta = responseCardTable.delta;

  monthCommitButton: { label: string; value: boolean }[];
  tooltipMessage: any;
  tooltipUpload: any = 'Upload Allocation';
  messageModalChanges: string;
  messages: { [key: string]: string };

  selectedTabIndex: number;
  storeTab: number[];
  clickScreenSub: Subscription;
  textProducts: string;
  mlsLastUpdate: any;
  mlsLastUpdateVisible: boolean;
  kaAllocationLastUpdate: string;
  kaAllocationLastUpdateVisible: boolean;
  dictWeeks: { [key: string]: string };
  productFamilyFullData: any[] = [];

  currentUser!: CurrentUser;
  currentQuarter!: string;
  currentYear!: number;

  exportKaActual: any;
  exportKaNext: any;
  exportActualOrNext: any;

  editWeekColumn: any = [];
  addColumnsToVariation: any = [];
  arrayVariationMonth: any = [];
  arrayRetailMonthVariation: any = [];
  arrayRetailQuarterVariation: any = [];

  loadedDataProducts: any;

  constructor(
    private validateFormService: ValidateFormService,
    private modalService: ModalActionsService,
    private utilitiesService: UtilitiesService,
    private breadcrumbService: BreadcrumbService,
    private excelService: ExcelService,
    private excelSheetsService: ExcelSheetsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.crudService = new CrudService<Material>(this.http, 'material');
    this.crudServiceKa = new CrudService<any>(this.http, 'kaAllocation');
    this.searchInputs = [
      new MultiSelectSearchInput({
        key: 'productDesc',
        hover: 'Product',
        hasCheckFlowException: true,
        limitedValue: true,
        largeInput: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        notClearable: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModelId',
        hover: 'Sales Model',
        hasCheckFlowException: true,
        disabled: false,
      }),
      new MultiSelectSearchInput({
        key: 'kaId',
        hover: 'KA',
        hasCheckFlowException: true,
        disabled: false,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];

    this.searchInputsColorMix = [
      new MultiSelectSearchInput({
        key: 'productDesc',
        hover: 'Product',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModelId',
        hover: 'Sales Model',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];

    this.searchInputsDealAllocation = [
      new MultiSelectSearchInput({
        key: 'product',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModelId',
        hover: 'Sales Model',
        hasCheckFlowException: true,
        disabled: false,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        notClearable: true,
      }),
    ];

    this.searchInputsDemandVsMls = [
      new MultiSelectSearchInput({
        key: 'product',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesModelId',
        hover: 'Sales Model',
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        notClearable: true,
      }),
      new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasCheckFlowException: true,
        disabled: true,
        notClearable: true,
      }),
    ];

    [this.currentUser] = this.authService.getUserPermissions();
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;

    this.filter = {
      ...this.filter,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
    };

    this.breadcrumbService.set('/home', { disable: false });
    this.breadcrumbService.set('/home/ka-allocation', { disable: false });
    this.tabsIndex = {
      ALLOCATION: 0,
      'DEAL VS ALLOCATION': 1,
      'DEMAND VS MLS': 2,
    };

    this.allocationForms = {};
    this.columnsFormGroup = {};
    this.columnsFormGroupRetail = {};
    this.columnsFormGroupVariation = {};
    this.formGroupToUpdate = [];
    this.allAllocationFormControls = [];
    this.allVariationFormControls = [];

    this.geosEntities = {};
    this.countriesEntities = {};
    this.salesModelsEntities = [];
    this.productEntities = [];
    this.kaEntities = [];

    this.summedValue = [];

    this.cardsColor = [];

    this.productFilterDealAllocations = [];
    this.productsIDSForSaleModels = [];
    this.geosFilter = [];
    this.countryFilter = [];
    this.countryFilterAll = [];
    this.statusFilter = [];
    this.statusFilterDealVsAllocation = [];
    this.originFilter = [];
    this.originFilterDealAllocation = [];
    this.originFilterDemandVsMls = [];
    this.productFilter = [];
    this.kaFilter = [];
    this.salesModelFilter = [];
    this.colorFilter = [];
    this.initialGeo = [];
    this.filterKaRetail = true;

    this.enableApplyFilter = false;
    this.showGeneralInformationsArrow = false;
    this.showGeneralInformations = false;
    this.showAllocationSection = false;
    this.showFairDistribution = false;
    this.showImageNoResults = false;
    this.showNoResults = false;
    this.showNoResultsDealVsAllocation = false;
    this.showNoResultsDemandVsMls = false;

    this.isEditTableMode = false;
    this.isDiscardChanges = false;

    this.filter = {
      sort: 'product',
      pageName: PAGE_NAME_RESOURCE.kaAllocation,
    };
    this.colorFilterMockup = [
      { id: 0, value: 'Haze Gray' },
      { id: 1, value: 'Soft Silver' },
    ];
    this.noFilterSelected = true;
    this.oldValuesFilters = [
      'geoId',
      'countryId',
      'salesModelId',
      'productId',
      'kaId',
      'origin',
    ];
    this.keysFilters = [
      'geoId',
      'countryId',
      'productDesc',
      'salesModelId',
      'kaId',
      'origin',
      'status',
    ];
    this.keysFiltersDealAllocation = [
      'geoId',
      'countryId',
      'product',
      'salesModelId',
      'kaId',
      'origin',
      'status',
    ];
    this.keysFiltersDemandVsMls = [
      'product',
      'salesModelId',
      'origin',
      'geoId',
      'countryId',
    ];

    this.monthCommitButton = [
      { label: 'June', value: true },
      { label: 'July', value: false },
      { label: 'August', value: false },
    ];
    this.tooltipMessage = null;
    this.messageModalChanges = '';
    this.messages = {
      save: 'Do you confirm ka allocation data updated?',
      discard: 'Are you sure you want to exit before saving your changes?',
      negativeValues:
        'Are you sure you want to save the allocation with negative fields?',
      noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
    };
    this.selectedTabIndex = 0;
    this.storeTab = [0];
    this.clickScreenSub = new Subscription();
    this.textProducts = '';
    this.mlsLastUpdate = null;
    this.mlsLastUpdateVisible = false;
    this.kaAllocationLastUpdate = '08/17/2021 16:30';
    this.kaAllocationLastUpdateVisible = false;
    this.dictWeeks = {
      wk01: 'week1Allocated',
      wk02: 'week2Allocated',
      wk03: 'week3Allocated',
      wk04: 'week4Allocated',
      wk05: 'week5Allocated',
      sab: 'sab',
    };

    this.entities = [
      {
        entity: 'geo',
        query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'country',
        query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'kaManagement',
        query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'material/products/summary',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'material/salesModels/summary',
        query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'material-family',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'material-colors',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
    ];

    this.filtersEntities = [
      {
        entity: 'emea-allocation/summary/color',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'emea-allocation/summary/memory',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
      {
        entity: 'emea-allocation/summary/sim',
        query: { pageName: PAGE_NAME_RESOURCE.kaAllocation },
      },
    ];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.tooltipMessage = 'Export';
    [this.currentUser] = this.authService.getUserPermissions();
    this.clickScreenSub = this.utilitiesService.documentClickedTarget.subscribe(
      target => this.verifyClickChangeRoute(target)
    );

    const promises = [this.getDataFilter(), this.getDataMlsLastUpdate()];

    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  ngAfterViewInit(): void {
    this.allTabs();
    this.cdr.detectChanges();
  }

  async getDataMlsLastUpdate(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.crudService
        .getEntity('onePlan/last-updates', {
          pageName: PAGE_NAME_RESOURCE.kaAllocation,
        })
        .subscribe(
          (response: any) => {
            this.mlsLastUpdate = response.mlsLastUpdate;
            this.mlsLastUpdateVisible = true;
            resolve();
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  allTabs() {
    this.tabs = [
      { label: 'Allocation', tab: 'ALLOCATION', template: this.Allocation },
      {
        label: 'Deal VS Allocation',
        tab: 'DEAL VS ALLOCATION',
        template: this.DealVsAllocation,
      },
      {
        label: 'Demand Vs MLS',
        tab: 'DEMAND VS MLS',
        template: this.DemandVsMls,
      },
    ];
  }

  onActiveTabIndexChange(index: number) {
    this.selectedTabIndex = index; // atualiza a variável com o índice selecionado

    this.checkTabClick(index);
  }

  checkTabClick(index: number) {
    let result = this.storeTab.find((element: any) => element === index);
    if (!result) {
      this.storeTab.push(index);
      if (index == 1) {
        this.applyDealAllocation();
      } else if (index == 2) {
        this.applyDemandVsMls('demandVsMls');
      }
    }
  }

  resetExpandedAllocationTable() {
    this.loadedDataProducts.expandedTable.headers.forEach((header: any) => {
      if (header?.expanded) header.expanded = false;
    });
  }

  ngOnDestroy() {
    this.verifyStatusBreadcrumb(false);
    this.clickScreenSub.unsubscribe();
  }

  verifyStatusBreadcrumb(status: boolean) {
    this.breadcrumbService.set('/home', { disable: status });
    this.breadcrumbService.set('/home/ka-allocation', {
      disable: status,
    });
  }

  alphabeticalOrder(a: any, b: any) {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  }

  dataFilterGeo(response: any) {
    const initialValueGeoFilter: { [key: string]: any } = {};

    response.forEach((item: any) => {
      this.geosFilter.push({ value: item.geo, id: item.id });
      if (item.id === this.currentUser.geoId) {
        initialValueGeoFilter.value = item.geo;
        initialValueGeoFilter.id = item.id;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      classCss: 'filter-color-mix',
      hasAutocomplete: true,
      options: this.geosFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
    });

    const selectGeoDealAllocation = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      classCss: 'filter-deal-allocation',
      hasAutocomplete: true,
      options: this.geosFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
    });

    const selectGeoDemandVsMls = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      classCss: 'filter-demand-vs-mls',
      hasAutocomplete: true,
      options: this.geosFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.initialGeo.push(initialValueGeoFilter.id);

    this.searchInputsColorMix[4] = selectGeo;
    this.searchInputsColorMix = [...this.searchInputsColorMix];

    this.searchInputsDealAllocation[4] = selectGeoDealAllocation;
    this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

    this.searchInputsDemandVsMls[3] = selectGeoDemandVsMls;
    this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
  }

  dataFilterCountry(response: any) {
    const initialValueCountryFilter: { [key: string]: any } = {};

    response.forEach((item: any) => {
      this.countryFilter.push({ value: item.country, id: item.id });
      if (item.id === this.currentUser.countryId) {
        initialValueCountryFilter.value = item.country;
        initialValueCountryFilter.id = item.id;
      }
    });

    const orderFilters = this.countryFilter.sort(this.alphabeticalOrder);

    const selectCountry = new SelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'text',
      classCss: 'filter-color-mix',
      hasAutocomplete: true,
      options: orderFilters,
      value: initialValueCountryFilter.value,
    });

    const selectCountryDealAllocation = new SelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'text',
      classCss: 'filter-deal-allocation',
      hasAutocomplete: true,
      options: orderFilters,
      value: initialValueCountryFilter.value,
      disabled: true,
      notClearable: true,
    });

    const selectCountryDemandVsMls = new MultiSelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'text',
      classCss: 'filter-demand-vs-mls',
      hasAutocomplete: true,
      options: orderFilters,
      value: [initialValueCountryFilter],
      disabled: true,
      notClearable: true,
    });

    this.searchInputsColorMix[5] = selectCountry;
    this.searchInputsColorMix = [...this.searchInputsColorMix];

    this.searchInputsDealAllocation[5] = selectCountryDealAllocation;
    this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

    this.searchInputsDemandVsMls[4] = selectCountryDemandVsMls;
    this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
  }

  dataFilterStatus() {
    this.statusFilter = [
      { value: 'Active' },
      { value: 'Inactive' },
      { value: 'All' },
    ];

    this.statusFilterDealVsAllocation = [
      { value: 'Active' },
      { value: 'Inactive' },
    ];

    const selectStatus = new SelectSearchInput({
      key: 'status',
      hover: 'Status',
      classCss: 'filter-color-mix',
      hasAutocomplete: true,
      options: this.statusFilter,
      value: this.statusFilter[0].value,
    });

    const selectStatusDealAllocation = new SelectSearchInput({
      key: 'status',
      hover: 'Status',
      classCss: 'filter-deal-allocation',
      hasAutocomplete: true,
      options: this.statusFilterDealVsAllocation,
      value: this.statusFilter[0].value,
    });

    this.searchInputsColorMix[2] = selectStatus;
    this.searchInputsColorMix = [...this.searchInputsColorMix];

    this.searchInputsDealAllocation[2] = selectStatusDealAllocation;
    this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];
  }

  dataFilterOrigin() {
    this.originFilter.push({ value: 'JAG' }, { value: 'MAN' });
    this.originFilterDealAllocation.push({ value: 'JAG' }, { value: 'MAN' });

    const selectOrigin = new MultiSelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'filter-color-mix',
      hasAutocomplete: true,
      options: this.originFilter,
    });

    const selectOriginDealAllocation = new MultiSelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'filter-deal-allocation',
      hasAutocomplete: true,
      options: this.originFilterDealAllocation,
      value: [this.originFilterDealAllocation],
    });

    const selectOriginDemandVsMls = new MultiSelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'filter-demand-vs-mls',
      hasAutocomplete: true,
      options: this.originFilter,
      value: [this.originFilter],
    });

    this.searchInputsColorMix[3] = selectOrigin;
    this.searchInputsColorMix = [...this.searchInputsColorMix];

    this.searchInputsDealAllocation[3] = selectOriginDealAllocation;
    this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

    this.searchInputsDemandVsMls[2] = selectOriginDemandVsMls;
    this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
  }

  dataFilterProduct(response: any) {
    response.forEach((item: any) => {
      this.productFilter.push({ value: item.productDesc });
      this.productFilterDealAllocations.push({
        value: item.productDesc,
      });
    });

    const selectProduct = new MultiSelectSearchInput({
      key: 'productDesc',
      hover: 'Product',
      classCss: 'product-filter-color-mix',
      hasCheckFlowException: true,
      disabled: false,
      options: this.productFilter,
      limitedValue: true,
    });

    const selectProductDealAllocation = new MultiSelectSearchInput({
      key: 'product',
      hover: 'Product',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      largeInput: true,
      classCss: 'product-filter-deal-allocation',
      options: this.productFilterDealAllocations,
      value: this.productFilterDealAllocations,
    });

    const selectProductDemandVsMls = new MultiSelectSearchInput({
      key: 'product',
      hover: 'Product',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      largeInput: true,
      classCss: 'product-filter-demand-vs-mls',
      options: this.productFilterDealAllocations,
      value: this.productFilterDealAllocations,
    });

    this.searchInputsColorMix[0] = selectProduct;
    this.searchInputsColorMix = [...this.searchInputsColorMix];

    this.searchInputsDealAllocation[0] = selectProductDealAllocation;
    this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

    this.searchInputsDemandVsMls[0] = selectProductDemandVsMls;
    this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
  }

  dataFilterSalesModel(response: any) {
    this.salesModelFilter = [];

    response.forEach((item: any) => {
      this.salesModelFilter.push({
        value: item.salesModel,
        id: item.materialId,
      });
    });

    const selectSalesModel = new MultiSelectSearchInput({
      key: 'salesModelId',
      hover: 'Sales Model',
      classCss: 'filter-color-mix',
      hasAutocomplete: true,
      options: this.salesModelFilter,
    });

    const selectSalesModelDealAllocation = new MultiSelectSearchInput({
      key: 'salesModelId',
      hover: 'Sales Model',
      classCss: 'filter-deal-allocation',
      hasAutocomplete: true,
      options: this.salesModelFilter,
      value: this.salesModelFilter,
    });

    const selectSalesModelDemandVsMls = new MultiSelectSearchInput({
      key: 'salesModelId',
      hover: 'Sales Model',
      hasAutocomplete: true,
      classCss: 'filter-demand-vs-mls',
      options: this.salesModelFilter,
      value: this.salesModelFilter,
    });

    this.searchInputsColorMix[1] = selectSalesModel;
    this.searchInputsColorMix = [...this.searchInputsColorMix];

    this.searchInputsDealAllocation[1] = selectSalesModelDealAllocation;
    this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

    this.searchInputsDemandVsMls[1] = selectSalesModelDemandVsMls;
    this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
  }

  orderFilter(data: any) {
    data.sort(function (x: any, y: any) {
      let a = x.value.toUpperCase(),
        b = y.value.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    });
  }

  dataFilters() {
    function orderFilter(data: any) {
      data.sort(function (x: any, y: any) {
        let a = x.value.toUpperCase(),
          b = y.value.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });
    }

    this.filtersEntities.forEach((item: any) => {
      Object.assign(item);
    });

    this.crudService
      .getDataFilters(this.filtersEntities)
      .subscribe((response: any[]) => {
        [
          this.colorEntity,
          // this.memoryFilterEntity,
          // this.simFilterEntity,
        ] = response;

        this.colorEntity.forEach((item: any) => {
          this.colorFilter.push({
            value: item.color,
            id: item.product_color_id,
          });
        });
        orderFilter(this.colorFilter);

        const selectColor = new SelectSearchInput({
          key: 'color',
          hover: 'Color',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: this.colorFilter,
          disabled: false,
        });
      });
  }

  async getDataFilter(): Promise<void> {
    return new Promise((resolve, reject) => {
      const geoFilter: any[] = [];
      const countryFilter: any[] = [];
      const salesModelFilter: any[] = [];
      const productFilter: any[] = [];
      this.kaFilter = [];
      const originFilter: any[] = [];

      this.spinner.show();

      this.crudService.getDataFilters(this.entities).subscribe(
        (response: any) => {
          this.geosEntity = response[0];
          this.countryEntity = response[1];
          this.productEntity = response[3];
          this.salesModelEntity = response[4];
          this.productFamilyEntity = response[5];
          this.colorEntity = response[6];

          this.dataFilterGeo(this.geosEntity);
          this.dataFilterStatus();
          this.dataFilterOrigin();
          this.dataFilterProduct(this.productEntity);
          this.dataFilterSalesModel(this.salesModelEntity);
          this.productFamilyFullData = this.productFamilyEntity;

          this.crudService
            .getEntity(
              `country?geo_id=${this.currentUser.geoId}&pageName=${PAGE_NAME_RESOURCE.kaAllocation}`
            )
            .subscribe((response: any) => {
              this.dataFilterCountry(response);
            });

          const initialValueGeoFilter: { [key: string]: any } = {};
          response[0].forEach((item: any) => {
            this.geosEntities[item.geo] = item.id;
            geoFilter.push({ value: item.geo, id: item.id });
            if (item.id === this.currentUser.geoId) {
              initialValueGeoFilter.value = item.geo;
              initialValueGeoFilter.id = item.id;
            }
          });

          const initialValueCountryFilter: { [key: string]: any } = {};
          response[1].forEach((item: Country) => {
            this.countryFilterAll.push({ value: item.country, id: item.id });
            countryFilter.push({ value: item.country, id: item.id });
            if (item.id === this.currentUser.countryId) {
              initialValueCountryFilter.value = item.country;
              initialValueCountryFilter.id = item.id;
            }
            this.countriesEntities[item.country] = item.id;
          });

          response[2].forEach((item: Ka) => {
            if (item.status) {
              this.kaFilter.push({ value: item.ka, key: item.id });
            }
          });

          this.kaFilter.sort((current: any, next: any) =>
            current.value.localeCompare(next.value)
          );

          response[4].forEach((item: any) => {
            salesModelFilter.push({
              value: item.salesModel,
              key: item.materialId,
            });
          });
          this.salesModelsEntities = salesModelFilter;

          response[3].forEach((item: any) => {
            if (
              item.productDesc &&
              productFilter.findIndex(
                product =>
                  product.value.toUpperCase() ===
                  item.allocationBz?.toUpperCase()
              ) === -1
            ) {
              productFilter.push({
                value: item.productDesc,
                key: item.productDesc,
                id: item.productId,
              });
            }
          });
          this.productEntities = productFilter;

          originFilter.push({ value: 'JAG' }, { value: 'MAN' });

          const selectCountry = new SelectSearchInput({
            key: 'countryId',
            hover: 'Country',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            classCss: 'filter-allocation',
            options: countryFilter,
            value: initialValueCountryFilter.value,
            disabled: true,
            notClearable: true,
          });

          const selectGeo = new SelectSearchInput({
            key: 'geoId',
            hover: 'GEO',
            type: 'text',
            hasAutocomplete: true,
            hasCheckFlowException: true,
            classCss: 'filter-allocation',
            options: geoFilter,
            value: initialValueGeoFilter.value,
            disabled: true,
            notClearable: true,
          });

          const selectMultiProduct = new MultiSelectSearchInput({
            key: 'productDesc',
            hover: 'Product',
            hasCheckFlowException: true,
            classCss: 'product-filter-allocation',
            disabled: false,
            options: productFilter,
            limitedValue: true,
            largeInput: true,
          });

          const selectMultiSales = new MultiSelectSearchInput({
            key: 'salesModelId',
            hover: 'Sales Model',
            hasCheckFlowException: true,
            classCss: 'multiselect-filter-allocation',
            disabled: false,
            options: salesModelFilter,
          });

          const selectMultiKa = new MultiSelectSearchInput({
            key: 'kaId',
            hover: 'KA',
            hasCheckFlowException: true,
            classCss: 'multiselect-filter-allocation',
            disabled: false,
            options: this.kaFilter,
            value: [],
          });

          this.searchInputs[0] = selectMultiProduct;
          this.searchInputs[1] = selectMultiSales;
          this.searchInputs[2] = selectMultiKa;
          this.searchInputs[3] = selectGeo;
          this.searchInputs[4] = selectCountry;
          this.searchInputs[5].options = originFilter;
          this.searchInputs[5].classCss = 'multiselect-filter-allocation';
          this.searchInputs[5] = { ...this.searchInputs[5] };

          this.searchInputs = [...this.searchInputs];
          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        }
      );
    });
  }

  getDataTableDealVsAllocation(params?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService
        .getEntity(
          `kaAllocation/dealVsAllocation?pageName=${PAGE_NAME_RESOURCE.kaAllocation}`,
          params
        )
        .subscribe(
          (response: any) => {
            this.getDataCards(response.cards);
            this.barChart = response.barChartData;
            this.lineChart = response.lineChartData;
            this.showNoResultsDealVsAllocation = false;
            resolve();
          },
          error => {
            this.showNoResultsDealVsAllocation = true;
            reject(error);
            this.spinner.hide();
          }
        );
    });
  }

  getDataTableDemandVsMls(params?: any): Promise<void> {
    this.dataChartDemandVsMls = '';
    return new Promise((resolve, reject) => {
      this.crudService
        .getEntity(
          `kaAllocation/demandVsMls?pageName=${PAGE_NAME_RESOURCE.kaAllocation}`,
          params
        )
        .subscribe(
          (response: any) => {
            this.dataChartDemandVsMls = response;
            this.showNoResultsDemandVsMls = false;
            resolve();
          },
          error => {
            this.showNoResultsDemandVsMls = true;
            reject(error);
            this.spinner.hide();
          }
        );
    });
  }

  getDataCards(data: any) {
    this.cards = this.dataCards(data);
  }

  dataCards(data: any) {
    const card = data;
    const dataCards: CardInterface[] = [];

    card.forEach((item: any) => {
      this.createCard(dataCards, item);
    });

    return dataCards;
  }

  createCard(dataCardInterface: any, dataCardItem: any) {
    dataCardInterface.push({
      title: dataCardItem.title,
      subtitle01: dataCardItem.totQA,
      value01: dataCardItem.valueTot,
      subtitle02: dataCardItem.dealDemand,
      value02: dataCardItem.valueDD,
    });
  }

  getDataChart() {
    // this.barChart = this.barChartMockup;
    // this.lineChart = this.lineChartMockup;
  }

  getTotalValueRetail(filter?: any) {
    this.columnsFormGroupRetail = {};
    const filterRetail = { ...filter };
    delete filterRetail.kaId;
    this.crudService
      .getEntity('kaAllocation', filterRetail)
      .subscribe((response: any) => {
        if (response.simpleTable) {
          const editableColumns = response.simpleTable.expandedTable.headers
            .filter(
              (item: any) =>
                item.enableContent && item.props !== item.otherContent
            )
            .splice(0)
            .map((item: any) => item.props);
          response.simpleTable.expandedTable.rows.map(
            (row: any, indexRow: number) => {
              Object.keys(row).forEach((columnName: string) => {
                if (row.origin.salesModel === '-') {
                  row[columnName].enableMLS = true;
                  delete row[columnName].enableInput;
                } else if (
                  row.origin.ka.toLocaleUpperCase().includes('RETAIL') &&
                  row[columnName].enableInput
                ) {
                  row[columnName].isDisabled = true;
                }
              });
              this.createFormControlAllocationRetail(
                row,
                indexRow,
                editableColumns
              );
              return row;
            }
          );
        }
      });
  }

  getProducts(filter?: any) {
    this.getTotalValueRetail(filter);
    this.spinner.show();
    this.editWeekColumn = [];
    this.noFilterSelected = false;
    this.allAllocationFormControls = [];
    this.allVariationFormControls = [];
    this.columnsFormGroup = {};
    this.columnsFormGroupVariation = {};
    this.addColumnsToVariation = [];
    this.arrayVariationMonth = [];
    this.arrayRetailMonthVariation = [];
    this.arrayRetailQuarterVariation = [];

    this.filterKaRetail =
      filter.kaId && filter.kaId.length > 0
        ? filter.kaId.filter((value: number) => value === 87).length > 0
        : true;

    const responseTable: { [key: string]: any } = {};
    this.crudService
      .getEntity('kaAllocation', filter)
      .subscribe((response: any) => {
        this.mlsLastUpdate = response.mlsLastUpdate;
        if (this.mlsLastUpdate) {
          this.mlsLastUpdateVisible = true;
        } else {
          this.mlsLastUpdateVisible = false;
        }
        const generalInformations: any[] = response.generalInformations || [];
        this.cardsColor =
          generalInformations.map((item: any) => {
            item.styleClass = 'medium-width';
            return item;
          }) || [];

        if (!response.simpleTable) {
          this.showNoResults = true;
          this.loadedDataProducts = [];
          // this.tableResult = [];
          this.showGeneralInformations = false;
        } else {
          this.showNoResults = false;
          this.showGeneralInformations = true;
          responseTable.simpleTable = {
            headers: response.simpleTable.headers.map((item: any) => {
              if (item.props === 'product') {
                item.style = { width: '63.5%' };
              } else {
                item.style = { width: '36.5%' };
              }
              return item;
            }),
            rows: response.simpleTable.rows,
          };

          this.createAllocationForm();
          this.createVariationForm();

          response.simpleTable.expandedTable.rows.forEach((value: any) => {
            value.sab.enableInput = true;
          });
          response.simpleTable.expandedTable.headers.forEach((item: any) => {
            if (item.props === 'sab') {
              item.enableContent = true;
            }
          });
          const editableColumns = response.simpleTable.expandedTable.headers
            .filter(
              (item: any) =>
                item.enableContent && item.props !== item.otherContent
            )
            .splice(0)
            .map((item: any) => item.props);
          editableColumns.push('fqVariation');
          this.editWeekColumn = editableColumns;

          const variationColumns = response.simpleTable.expandedTable.headers
            .filter((item: any) => item.props == 'fqVariation')
            .slice(0)
            .map((item: any) => item.props);

          response.simpleTable.rows.map((row: any) => {
            row.salesModel.value =
              row.salesModel.value === '-' ? '' : row.salesModel.value;
          });
          response.simpleTable.expandedTable.rows.map(
            (row: any, indexRow: number) => {
              row['fqVariation'].enableMLS = true;
              Object.keys(row).forEach((columnName: string) => {
                if (row.origin.salesModel === '-') {
                  row[columnName].style = {
                    'font-weight': 'bold',
                    'background-color': '#FFFFFF',
                  };
                  row[columnName].enableMLS = true;
                  delete row[columnName].enableInput;
                } else if (
                  row.origin.ka.toLocaleUpperCase().includes('RETAIL') &&
                  row[columnName].enableInput
                ) {
                  row[columnName].isDisabled = true;
                } else {
                  let permissionEditAllocation: boolean = false;
                  const userPermission = this.authService.getUserPermissions();
                  userPermission.forEach((item: any) => {
                    if (
                      item.resource === 'ka-allocation' &&
                      item.permission === 'UPDATE'
                    ) {
                      permissionEditAllocation = true;
                    }
                  });
                  if (!permissionEditAllocation) {
                    row[columnName].enableInput = false;
                  }
                }
              });

              this.createFormControlAllocation(row, indexRow, editableColumns);
              this.createFormControlVariation(row, indexRow, variationColumns);
              // create array line retail col variation
              Object.keys(row).forEach((item: any) => {
                let checkRetail = !row['buffer'].ka.includes('-');
                let checkInput = row[item].enableInput;
                let checkWeek = item.toLocaleUpperCase().includes('WK');
                if (checkWeek && checkRetail && checkInput) {
                  let getId = row[item].idProduct;
                  let getValue = row[item].value;
                  let getKa = row[item].ka;
                  this.arrayRetailMonthVariation.push({
                    id: getId,
                    col: item,
                    value: getValue,
                    ka: getKa,
                  });
                }
              });
              // create array lina ka, col month variation
              Object.keys(row).forEach((item: any) => {
                let checkRetail = !row['buffer'].ka.includes('-');
                let checkWeek =
                  item.toLocaleUpperCase().includes('VARIATION') &&
                  !item.toLocaleUpperCase().includes('FQVARIATION');
                if (checkWeek && checkRetail) {
                  let getId = row[item].idProduct;
                  let getValue = row[item].value;
                  let getka = row['buffer'].ka;
                  this.arrayVariationMonth.push({
                    id: getId,
                    col: item,
                    value: getValue,
                    ka: getka,
                  });
                }

                if (
                  checkRetail &&
                  item.toLocaleUpperCase().includes('FQVARIATION')
                ) {
                  let getId = row[item].idProduct;
                  let getValue = row[item].value;
                  let getka = row['buffer'].ka;
                  this.arrayRetailQuarterVariation.push({
                    id: getId,
                    col: item,
                    value: getValue,
                    ka: getka,
                  });
                }
              });
              return row;
            }
          );

          responseTable.expandedTable = response.simpleTable.expandedTable;
          responseTable.expandedTable.rows.forEach((row: any, key: any) => {
            if (key > 0) {
              row.fqTotal.ka = this.valuesToArray(row)[0].ka;
              row.fqActual.ka = this.valuesToArray(row)[0].ka;
            }
          });

          this.loadedDataProducts = JSON.parse(JSON.stringify(responseTable));
        }
        this.spinner.hide();
      });
  }

  valuesToArray(obj: any) {
    const result: any[] = [];
    Object.keys(obj).forEach((key: any) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key].indiceKey = key;
        result.push(obj[key]);
      }
    });
    return result;
  }

  nextQuarter(next: boolean) {
    this.filter.quarter = next ? 'next' : '';

    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.modalService.createConfirm(
        this.TabComponent.dialogConfirmationDiscardSaveChanges
      );
      this.TabComponent.messageModalChanges = this.messages.discard;

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.discardChanges();
          this.next = !this.next;
        }
      });
    } else {
      this.discardChanges();
      this.next = !this.next;
    }
  }

  get variationFormArray(): FormArray {
    return this.variationForm.get('variationFormArray') as FormArray;
  }

  get allocationFormArray(): FormArray {
    return this.allocationForm.get('allocationFormArray') as FormArray;
  }

  createAllocationForm() {
    this.allocationForm = this.formBuilder.group({
      allocationFormArray: this.formBuilder.array([]),
    });
  }

  createVariationForm() {
    this.variationForm = this.formBuilder.group({
      variationFormArray: this.formBuilder.array([]),
    });
  }

  createFormControlAllocation(
    row: any,
    indexRow: number,
    editableColumns: string[]
  ) {
    const rowFormGroup = new FormGroup({});

    editableColumns.forEach((columnName: string) => {
      const { idProduct } = row.origin;
      const { salesModel } = row.origin;
      const origin = row.origin.value;
      const ka = row.origin.ka === '-' ? 'MLS' : row.origin.ka;

      if (row[columnName].enableInput || row[columnName].enableMLS) {
        const idAllocation =
          row[columnName].id || row[columnName].idProduct || 'noIdAllocation';
        const formControlName = `${idProduct}-${salesModel}-${origin}-${columnName}-${ka}`;
        const formControl = new FormControl(row[columnName].value || '');
        const formGroupColumnName = `${idProduct}-${columnName}`;
        this.allAllocationFormControls.push({
          formControlName,
          formControl,
          idAllocation,
          indexRow,
        });

        rowFormGroup.addControl(formControlName, formControl);
        if (this.columnsFormGroup[formGroupColumnName]) {
          this.columnsFormGroup[formGroupColumnName].addControl(
            formControlName,
            formControl
          );
        } else {
          const columnFormGroup = this.formBuilder.group({});
          columnFormGroup.addControl(formControlName, formControl);
          this.columnsFormGroup[formGroupColumnName] = columnFormGroup;
        }
      }
    });
    this.allocationFormArray.push(rowFormGroup);
  }

  createFormControlAllocationRetail(
    row: any,
    indexRow: number,
    editableColumns: string[]
  ) {
    const rowFormGroup = new FormGroup({});
    editableColumns.forEach((columnName: string) => {
      const { idProduct } = row.origin;
      const { salesModel } = row.origin;
      const origin = row.origin.value;
      const ka = row.origin.ka === '-' ? 'MLS' : row.origin.ka;
      if (row[columnName].enableInput || row[columnName].enableMLS) {
        const formControlName = `${idProduct}-${salesModel}-${origin}-${columnName}-${ka}`;
        const formControl = new FormControl(row[columnName].value || '');
        const formGroupColumnName = `${idProduct}-${columnName}`;
        rowFormGroup.addControl(formControlName, formControl);
        if (this.columnsFormGroupRetail[formGroupColumnName]) {
          this.columnsFormGroupRetail[formGroupColumnName].addControl(
            formControlName,
            formControl
          );
        } else {
          const columnFormGroup = this.formBuilder.group({});
          columnFormGroup.addControl(formControlName, formControl);
          this.columnsFormGroupRetail[formGroupColumnName] = columnFormGroup;
        }
      }
    });
  }
  // modificar e criar form control somente para variation
  createFormControlVariation(
    row: any,
    indexRow: number,
    editableColumns: string[]
  ) {
    const rowFormGroup = new FormGroup({});

    editableColumns.forEach((columnName: string) => {
      const { idProduct } = row.origin;
      const { salesModel } = row.origin;
      const origin = row.origin.value;
      const ka = row.origin.ka === '-' ? 'MLS' : row.origin.ka;

      if (row[columnName]) {
        const idAllocation =
          row[columnName].id || row[columnName].idProduct || 'noIdAllocation';
        const formControlName = `${idProduct}-${salesModel}-${origin}-${columnName}-${ka}`;
        const formControl = new FormControl(row[columnName].value || '');
        const formGroupColumnName = `${idProduct}-${columnName}`;
        this.allVariationFormControls.push({
          formControlName,
          formControl,
          idAllocation,
          indexRow,
        });

        rowFormGroup.addControl(formControlName, formControl);
        if (this.columnsFormGroupVariation[formGroupColumnName]) {
          this.columnsFormGroupVariation[formGroupColumnName].addControl(
            formControlName,
            formControl
          );
        } else {
          const columnFormGroup = this.formBuilder.group({});
          columnFormGroup.addControl(formControlName, formControl);
          this.columnsFormGroupVariation[formGroupColumnName] = columnFormGroup;
        }
      }
    });
    this.variationFormArray.push(rowFormGroup);
  }

  dataFormSearchGeo(formSearchName: any) {
    if (formSearchName.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchName.geoId.toLocaleUpperCase())
      );
      formSearchName.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchName.geoId = '';
    }
  }

  dataFormSearchCountry(formSearchName: any, tab: any) {
    const valueContry = formSearchName.countryId;

    if (formSearchName.geoId) {
      this.countryFilter = [];

      this.crudService
        .getEntity(`country?geo_id=${formSearchName.geoId}`)
        .subscribe((response: any) => {
          this.responseCountry = response;

          if (formSearchName.countryId) {
            const currentCountry = this.responseCountry.filter(
              (item: Country) =>
                item.country
                  .toLocaleUpperCase()
                  .includes(formSearchName.countryId.toLocaleUpperCase())
            );
            formSearchName.countryId = currentCountry[0]
              ? currentCountry[0].id
              : 0;
          } else {
            formSearchName.countryId = '';
          }

          response.forEach((item: any) => {
            this.countryFilter.push({ value: item.country, id: item.id });
          });

          const orderFilters = this.countryFilter.sort(this.alphabeticalOrder);

          const selectCountry = new SelectSearchInput({
            key: 'countryId',
            hover: 'Country',
            type: 'text',
            classCss: 'filter-color-mix',
            hasAutocomplete: true,
            options: orderFilters,
            value: valueContry,
          });

          if (tab === 'colorMix') {
            this.searchInputsColorMix[5] = selectCountry;
            this.searchInputsColorMix = [...this.searchInputsColorMix];
          }
        });
    } else {
      this.countryFilter = [];

      const selectCountry = new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        classCss: 'filter-color-mix',
        hasAutocomplete: true,
        options: [],
        value: '',
      });

      if (tab === 'colorMix') {
        this.searchInputsColorMix[5] = selectCountry;
        this.searchInputsColorMix = [...this.searchInputsColorMix];
      }
    }
  }

  dataFormSearchCountryDealAllocation(formSearchName: any, tab: any) {
    if (formSearchName.geoId) {
      if (this.initialGeo[0] !== formSearchName.geoId) {
        this.countryFilter = [];
        this.initialGeo = [];
        this.initialGeo.push(formSearchName.geoId);

        this.crudService
          .getEntity(`country?geo_id=${this.initialGeo[0]}`)
          .subscribe((response: any) => {
            this.responseCountry = response;

            response.forEach((item: any) => {
              this.countryFilter.push({ value: item.country, id: item.id });
            });

            const orderFilters = this.countryFilter.sort(
              this.alphabeticalOrder
            );

            const selectCountry = new MultiSelectSearchInput({
              key: 'countryId',
              hover: 'Country',
              type: 'text',
              classCss: 'filter-deal-allocation',
              hasAutocomplete: true,
              options: orderFilters,
            });

            const selectCountryDemandVsMls = new MultiSelectSearchInput({
              key: 'countryId',
              hover: 'Country',
              type: 'text',
              classCss: 'filter-demand-vs-mls',
              hasAutocomplete: true,
              options: orderFilters,
            });

            this.searchInputsDealAllocation[5] = selectCountry;
            this.searchInputsDealAllocation = [
              ...this.searchInputsDealAllocation,
            ];

            // this.searchInputsDemandVsMls[4] = selectCountryDemandVsMls;
            // this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
          });
      }
    }
    if (formSearchName.geoId === '' && formSearchName.countryId === '') {
      const selectCountry = new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        classCss: 'filter-deal-allocation',
        hasAutocomplete: true,
        options: this.countryFilterAll,
      });

      const selectCountryDemandVsMls = new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        classCss: 'filter-demand-vs-mls',
        hasAutocomplete: true,
        disabled: true,
        options: this.countryFilterAll,
      });

      this.searchInputsDealAllocation[5] = selectCountry;
      this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

      this.searchInputsDemandVsMls[4] = selectCountryDemandVsMls;
      this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
    }
    if (formSearchName.geoId === '' && formSearchName.countryId === null) {
      const selectCountry = new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        classCss: 'filter-deal-allocation',
        hasAutocomplete: true,
        options: this.countryFilterAll,
      });

      const selectCountryDemandVsMls = new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        classCss: 'filter-demand-vs-mls',
        hasAutocomplete: true,
        options: this.countryFilterAll,
      });

      this.searchInputsDealAllocation[5] = selectCountry;
      this.searchInputsDealAllocation = [...this.searchInputsDealAllocation];

      this.searchInputsDemandVsMls[4] = selectCountryDemandVsMls;
      this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
    }

    if (formSearchName.countryId) {
      const currentCountry = formSearchName.countryId.map(
        (item: any) => item.id
      );
      formSearchName.countryId = currentCountry;
    } else {
      formSearchName.countryId = '';
    }
  }

  dataFormSearchOrigin(formSearchName: any) {
    if (formSearchName.origin) {
      const currentOrigin = formSearchName.origin.map(
        (item: any) => item.value
      );
      formSearchName.origin = currentOrigin;
    } else {
      formSearchName.origin = '';
    }
  }

  dataFormSearchProduct(formSearchName: any, tab: any) {
    if (tab === 'colorMix') {
      if (formSearchName.productDesc) {
        const currentProduct = formSearchName.productDesc.map(
          (item: any) => item.value
        );
        formSearchName.productDesc = currentProduct;
      } else {
        formSearchName.productDesc = '';
      }
    }
    if (tab === 'dealAllocation') {
      if (formSearchName.product) {
        const currentProduct = formSearchName.product.map(
          (item: any) => item.value
        );
        formSearchName.product = currentProduct;
      } else {
        formSearchName.product = '';
      }
    }
    if (tab === 'demandVsMls') {
      if (formSearchName.product) {
        const currentProduct = formSearchName.product.map(
          (item: any) => item.value
        );
        formSearchName.product = currentProduct;
      } else {
        formSearchName.product = '';
      }
    }

    this.deleteProperty(formSearchName);
  }

  dataFormSearchSalesModel(formSearchName: any, tab: any) {
    const urlSalesModelsEntity = 'material/salesModels/summary';
    const productsIDSForSaleModels: any = [];
    const productsIDSForSaleModelsDealAllocation: any = [];
    const productsIDSForSaleModelsSupplySummary: any = [];
    const productsIDSForSaleModelsDemandVsMls: any = [];
    this.salesModelFilter = [];

    if (tab === 'colorMix') {
      if (formSearchName.productDesc) {
        this.productFilter.forEach((item: any) => {
          if (
            formSearchName.productDesc.filter((obj: any) => obj === item.value)
              .length > 0
          ) {
            productsIDSForSaleModels.push(item.value);
          }
        });
      }

      this.crudService
        .getEntity(urlSalesModelsEntity, {
          pageName: 'ka-allocation',
          product_desc: productsIDSForSaleModels,
        })
        .subscribe((response: any) => {
          this.salesModelFilter = [];

          response.forEach((item: any) => {
            this.salesModelFilter.push({
              value: item.salesModel,
              id: item.materialId,
            });
          });

          if (
            this.salesModelFilter.length !==
            this.searchInputsColorMix[1].options.length
          ) {
            const selectSalesModel = new MultiSelectSearchInput({
              key: 'salesModelId',
              hover: 'Sales Model',
              classCss: 'filter-color-mix',
              hasCheckFlowException: true,
              options: this.salesModelFilter,
            });
            this.searchInputsColorMix[1] = selectSalesModel;
          }
          this.searchInputsColorMix = [...this.searchInputsColorMix];
        });
    }

    if (tab === 'dealAllocation') {
      if (formSearchName.product) {
        this.productFilter.forEach((item: any) => {
          if (
            formSearchName.product.filter((obj: any) => obj === item.value)
              .length > 0
          ) {
            productsIDSForSaleModelsDealAllocation.push(item.value);
          }
        });
      }

      this.crudService
        .getEntity(urlSalesModelsEntity, {
          pageName: 'ka-allocation',
          product_desc: productsIDSForSaleModelsDealAllocation,
        })
        .subscribe((response: any) => {
          this.salesModelFilter = [];

          response.forEach((item: any) => {
            this.salesModelFilter.push({
              value: item.salesModel,
              id: item.materialId,
            });
          });

          if (
            this.salesModelFilter.length !==
            this.searchInputsDealAllocation[1].options.length
          ) {
            const selectSalesModelDealAllocation = new MultiSelectSearchInput({
              key: 'salesModelId',
              hover: 'Sales Model',
              classCss: 'filter-deal-allocation',
              hasAutocomplete: true,
              options: this.salesModelFilter,
            });
            this.searchInputsDealAllocation[1] = selectSalesModelDealAllocation;
          }
          this.searchInputsDealAllocation = [
            ...this.searchInputsDealAllocation,
          ];
        });

      if (formSearchName.salesModelId) {
        const currentSalesModel = formSearchName.salesModelId.map(
          (item: any) => item.value
        );
        formSearchName.salesModelId = currentSalesModel;
      } else {
        formSearchName.salesModelId = '';
      }
    }

    if (tab === 'demandVsMls') {
      if (formSearchName.product) {
        this.productFilter.forEach((item: any) => {
          if (
            formSearchName.product.filter((obj: any) => obj === item.value)
              .length > 0
          ) {
            productsIDSForSaleModelsDemandVsMls.push(item.value);
          }
        });
      }

      this.crudService
        .getEntity(urlSalesModelsEntity, {
          pageName: 'ka-allocation',
          product_desc: productsIDSForSaleModelsDemandVsMls,
        })
        .subscribe((response: any) => {
          this.salesModelFilter = [];

          response.forEach((item: any) => {
            this.salesModelFilter.push({
              value: item.salesModel,
              id: item.materialId,
            });
          });

          if (
            this.salesModelFilter.length !==
            this.searchInputsDemandVsMls[1].options.length
          ) {
            const selectSalesModelDemandVsMls = new MultiSelectSearchInput({
              key: 'salesModelId',
              hover: 'Sales Model',
              hasAutocomplete: true,
              classCss: 'filter-demand-vs-mls',
              options: this.salesModelFilter,
            });

            this.searchInputsDemandVsMls[1] = selectSalesModelDemandVsMls;
          }
          this.searchInputsDemandVsMls = [...this.searchInputsDemandVsMls];
        });

      if (formSearchName.salesModelId) {
        const currentSalesModel = formSearchName.salesModelId.map(
          (item: any) => item.value
        );
        formSearchName.salesModelId = currentSalesModel;
      } else {
        formSearchName.salesModelId = '';
      }
    }
  }

  // dataFormSearchFamily(formSearchName: any) {
  //   const currentIdsFamily: any = [];

  //   this.productFamilyFullData.forEach((item: any) => {
  //     formSearchName.ppm.forEach((data: any) => {
  //       if (item.family == data.value) {
  //         item.ids.forEach((value: any) => {
  //           currentIdsFamily.push(value);
  //         });
  //       }
  //     });
  //     formSearchName.materialId = currentIdsFamily.map((item: any) => item);
  //   });
  // }

  dataFormSearchProductDesc(formSearchName: any) {
    if (formSearchName.productDesc) {
      const currentproduct = formSearchName.productDesc.map(
        (item: any) => item.value
      );
      formSearchName.productDesc = currentproduct;

      this.searchInputs[0].options.forEach((row: any) => {
        if (
          formSearchName.productDesc.filter((obj: any) => obj === row.value)
            .length > 0
        ) {
          this.productsIDSForSaleModels.push(row.value);
        }
      });
    } else {
      this.productsIDSForSaleModels = [];
      formSearchName.productDesc = '';
    }
  }

  dataFormSearchGeoKa(formSearchName: any) {
    if (formSearchName.geoId) {
      formSearchName.geoId = this.geosEntities[formSearchName.geoId];
    } else {
      formSearchName.geoId = '';
    }
  }

  dataFormSearchSalesModelKa(formSearchName: any) {
    if (formSearchName.salesModelId) {
      const currentSalesModel = formSearchName.salesModelId.map(
        (item: any) => item.key
      );
      formSearchName.salesModelId = currentSalesModel;
    } else {
      formSearchName.salesModelId = '';
    }
  }

  dataFormSearchKaId(formSearchName: any) {
    if (formSearchName.kaId) {
      const currentKa = formSearchName.kaId.map((item: any) => item.key);
      formSearchName.kaId = currentKa;
    } else {
      formSearchName.kaId = '';
    }
  }

  dataFormSearchCountryKa(formSearchName: any) {
    if (formSearchName.countryId) {
      formSearchName.countryId =
        this.countriesEntities[formSearchName.countryId];
    } else {
      formSearchName.countryId = '';
    }
  }

  dataFormSearchFamily(formSearchName: any) {
    const currentIdsFamily: any = [];

    this.productFamilyFullData?.forEach((item: any) => {
      if (formSearchName.ppm) {
        formSearchName.ppm?.forEach((data: any) => {
          if (item.family == data.value) {
            item.ids.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
        formSearchName.materialId = currentIdsFamily.map((item: any) => item);
      }
    });

    formSearchName.materialId = formSearchName.materialId
      ? formSearchName.materialId
      : '';
  }

  dataFormSearchSalesModelIds(formSearchName: any) {
    if (formSearchName.salesModelId) {
      const currentSalesModel = formSearchName.salesModelId.map(
        (item: any) => item.id
      );
      formSearchName.salesModelId = currentSalesModel;
    } else {
      formSearchName.salesModelId = '';
    }
  }

  dataFormSearchColor(formSearchName: any) {
    if (formSearchName.color) {
      const currentColor = formSearchName.color.map((item: any) => item.value);
      formSearchName.color = currentColor;
    } else {
      formSearchName.color = '';
    }
  }

  dataFormSearchSalesModelFiltered() {
    const salesModelFiltered: any = this.salesModelFilter.filter((item: any) =>
      this.formSearchValuesSupplySummary.materialId.includes(item.id)
    );

    const salesModelIds = salesModelFiltered.map((objeto: any) => objeto.id);

    this.formSearchValuesSupplySummary.salesModelId = salesModelFiltered;

    const selectSalesModelSupplySummary = new MultiSelectSearchInput({
      key: 'salesModelId',
      hover: 'Sales Model',
      classCss: 'filter-supply-summary-allocation',
      hasAutocomplete: true,
      options: salesModelFiltered,
      value: salesModelFiltered,
      hasCheckFlowException: true,
    });

    return salesModelIds;
  }

  onSearchEvent(form: any, tab?: string) {
    if (tab === 'allocation') {
      this.formSearchValues = { ...form };

      const urlSalesModelsEntity = 'material/salesModels/summary';
      const salesModelFilter: any = [];
      const productsIDSForSaleModels: any = [];

      this.dataFormSearchProductDesc(this.formSearchValues);
      this.dataFormSearchGeoKa(this.formSearchValues);
      this.dataFormSearchSalesModelKa(this.formSearchValues);
      this.dataFormSearchKaId(this.formSearchValues);
      this.dataFormSearchOrigin(this.formSearchValues);
      this.dataFormSearchCountryKa(this.formSearchValues);

      this.enableApplyFilter = Boolean(
        this.formSearchValues[this.keysFilters[2]]?.length > 0 ||
          this.formSearchValues[this.keysFilters[3]]?.length > 0 ||
          this.formSearchValues[this.keysFilters[4]]?.length > 0
      );

      const keyOptionSelected = String(
        this.formSearchValues[this.keysFilters[2]][0]
      );
      if (
        !keyOptionSelected.toLocaleUpperCase().includes('RETAIL') &&
        this.formSearchValues[this.keysFilters[2]]?.length > 0
      ) {
        this.searchInputs[2].options.map((option: any) => {
          const keyOption = String(option.key);
          if (
            keyOption !== keyOptionSelected &&
            !keyOption.toLocaleUpperCase().includes('RETAIL')
          ) {
            option.disabled = true;
          }
          return option;
        });
      } else {
        this.searchInputs[2].options.map((option: any) => {
          option.disabled = false;
          return option;
        });
      }

      if (this.enableApplyFilter) {
        this.searchInputs[5].disabled = false;
        //this.textProducts = `${form.geoId.toLocaleUpperCase()} - ${form.countryId.toLocaleUpperCase()}`;
      } else {
        this.searchInputs[5].disabled = true;
        this.searchInputs = [...this.searchInputs];
      }

      this.crudService
        .getEntity(urlSalesModelsEntity, {
          pageName: 'ka-allocation',
          product_desc: this.productsIDSForSaleModels,
        })
        .subscribe((response: any) => {
          response.forEach((item: any) => {
            salesModelFilter.push({
              value: item.salesModel,
              key: item.materialId,
            });
          });

          if (salesModelFilter.length !== this.searchInputs[1].options.length) {
            const selectMultiSales = new MultiSelectSearchInput({
              key: 'salesModelId',
              hover: 'Sales Model',
              hasCheckFlowException: true,
              classCss: 'multiselect-filter-allocation',
              disabled: false,
              options: salesModelFilter,
            });
            this.searchInputs[1] = selectMultiSales;
          }
          this.searchInputs = [...this.searchInputs];
        });
    }

    if (tab === 'colorMix') {
      this.formSearchValuesColorMix = { ...form };
      this.dataFormSearchGeo(this.formSearchValuesColorMix);
      this.dataFormSearchCountry(this.formSearchValuesColorMix, tab);
      this.dataFormSearchOrigin(this.formSearchValuesColorMix);
      this.dataFormSearchProduct(this.formSearchValuesColorMix, tab);
      this.dataFormSearchSalesModel(this.formSearchValuesColorMix, tab);
      this.searchInputsColorMix = [...this.searchInputsColorMix];
    }

    if (tab === 'dealAllocation') {
      this.formSearchValuesDealAllocation = { ...form };
      this.dataFormSearchGeo(this.formSearchValuesDealAllocation);
      this.dataFormSearchOrigin(this.formSearchValuesDealAllocation);
      this.dataFormSearchProduct(this.formSearchValuesDealAllocation, tab);
      this.dataFormSearchSalesModel(this.formSearchValuesDealAllocation, tab);

      this.enableApplyFilter = Boolean(
        this.formSearchValuesDealAllocation[this.keysFilters[0]] &&
          (this.formSearchValuesDealAllocation[
            this.keysFiltersDealAllocation[2]
          ]?.length > 0 ||
            this.formSearchValuesDealAllocation[
              this.keysFiltersDealAllocation[3]
            ]?.length > 0 ||
            this.formSearchValuesDealAllocation[
              this.keysFiltersDealAllocation[5]
            ]?.length > 0 ||
            this.formSearchValuesDealAllocation[
              this.keysFiltersDealAllocation[6]
            ]?.length > 0)
      );
    }

    if (tab === 'demandVsMls') {
      this.formSearchValuesDemandVsMls = { ...form };
      this.dataFormSearchProduct(this.formSearchValuesDemandVsMls, tab);
      this.dataFormSearchSalesModel(this.formSearchValuesDemandVsMls, tab);
      this.dataFormSearchOrigin(this.formSearchValuesDemandVsMls);

      this.enableApplyFilter = Boolean(
        this.formSearchValuesDemandVsMls[this.keysFiltersDemandVsMls[0]]
          ?.length > 0 ||
          this.formSearchValuesDemandVsMls[this.keysFiltersDemandVsMls[1]]
            ?.length > 0 ||
          this.formSearchValuesDemandVsMls[this.keysFiltersDemandVsMls[2]]
            ?.length > 0
      );
    }

    this.filter = {
      ...this.filter,
      ...this.formSearchValuesSupplySummary,
    };
    this.cdr.detectChanges();
  }

  deleteProperty(item: any, tab?: string) {
    if (tab === 'demandVsMls') {
      delete item.geoId;
      delete item.countryId;
    }
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  applyFilter(tab: string) {
    if (tab === 'allocation') {
      this.applyFilterAllocation();
    }

    if (tab === 'dealAllocation') {
      this.applyDealAllocation();
    }

    if (tab === 'demandVsMls') {
      this.deleteProperty(this.formSearchValuesDemandVsMls, tab);
      this.applyDemandVsMls(tab);
    }
  }

  private applyDealAllocation() {
    this.spinner.show();

    const formSearchValuesDealAllocation = {
      ...this.formSearchValuesDealAllocation,
    };

    this.hideSpinnerAfterAll([
      this.getDataTableDealVsAllocation(formSearchValuesDealAllocation),
    ]);
  }

  private applyDemandVsMls(tab?: string) {
    this.spinner.show();

    const formSearchValuesDemandVsMls = {
      ...this.formSearchValuesDemandVsMls,
    };

    this.hideSpinnerAfterAll([
      this.getDataTableDemandVsMls(formSearchValuesDemandVsMls),
    ]);
  }

  private applyFilterAllocation() {
    const formSearchValues = { ...this.formSearchValues };

    this.filterKaRetail =
      formSearchValues.kaId && formSearchValues.kaId.length > 0
        ? formSearchValues.kaId.filter(
            (value: number) => value === 87 || value === 3
          ).length > 0
        : true;

    if (!this.filterKaRetail) {
      const subtitleMessage =
        'To make allocations, you must also select the KA - RETAIL_BRA';
      const titleMessage = 'WARNNING';
      this.toastr.warning(`${subtitleMessage}`, `${titleMessage}`, {
        timeOut: 15000,
      });
      return;
    }

    this.showAllocationSection = true;

    if (
      this.keysFilters.some(
        (searchValue: string) => formSearchValues[searchValue]?.length > 0
      )
    ) {
      if (this.isEditTableMode) {
        this.isDiscardChanges = true;
        const dialog = this.modalService.createConfirm(
          this.TabComponent.dialogConfirmationDiscardSaveChanges
        );
        this.TabComponent.messageModalChanges = this.messages.discard;
        dialog.afterClosed().subscribe((result: any) => {
          const panelTrigger = formSearchValues.panelTrigger;

          if (
            panelTrigger &&
            Object.prototype.hasOwnProperty.call(panelTrigger, 'autocomplete')
          ) {
            panelTrigger.closePanel();
          } else if (panelTrigger) {
            panelTrigger.close();
          }

          if (result) {
            this.oldValuesFilters[formSearchValues.nameFormControl] =
              formSearchValues.formControl.value;
            delete formSearchValues.action;
            delete formSearchValues.nameFormControl;
            delete formSearchValues.action;
            delete formSearchValues.formControl;

            const currentFilter: any = {};
            Object.keys(formSearchValues).forEach(item => {
              if (
                formSearchValues[item] &&
                String(formSearchValues[item]).length
              ) {
                currentFilter[item] = formSearchValues[item];
              }
            });

            currentFilter.quarter = this.next ? '' : 'next';
            this.filter = {
              ...currentFilter,
              sort: 'product',
              pageName: 'ka-allocation',
            };
            this.discardChanges();
          } else {
            const formControl = formSearchValues.formControl as AbstractControl;
            this.TabComponent.saveChangesButton.focus();
            formControl?.setValue(
              this.oldValuesFilters[formSearchValues.nameFormControl]
            );
          }
        });
      } else {
        this.oldValuesFilters[formSearchValues.nameFormControl] =
          formSearchValues.formControl.value;
        delete formSearchValues.formControl;
        delete formSearchValues.event;
        delete formSearchValues.panelTrigger;

        const searchOnlyProduct = Object.keys(formSearchValues).every(key => {
          if (key === 'productId') {
            return formSearchValues[key]?.length > 0;
          }
          return !formSearchValues[key];
        });
        this.showFairDistribution = searchOnlyProduct;

        if (searchOnlyProduct) {
          this.calcFairDistribution();
        }
        delete formSearchValues.action;
        delete formSearchValues.nameFormControl;
        delete formSearchValues.formControl;
        const currentFilter: any = {};

        Object.keys(formSearchValues).forEach(item => {
          if (formSearchValues[item] && String(formSearchValues[item]).length) {
            currentFilter[item] = formSearchValues[item];
          }
        });

        currentFilter.quarter = this.next ? '' : 'next';
        this.filter = {
          ...currentFilter,
          sort: 'product',
          pageName: PAGE_NAME_RESOURCE.kaAllocation,
        };
        this.getProducts(this.filter);
      }
    } else {
      this.noFilterSelected = true;
    }
  }

  calcFairDistribution() {
    const distributionAllocationKAs: any[] = [];
    const fairDistributionKAs: any[] = [];
    this.loadedDataProducts.simpleTable.rows.forEach((row: any) => {
      if (
        distributionAllocationKAs.findIndex(
          item => item.ka === row.ka.value
        ) === -1 &&
        !row.ka.value.toLocaleUpperCase().includes('MLS')
      ) {
        const ka: { [key: string]: any[] } = {};
        ka.ka = row.ka.value;
        ka.color = row.salesModel.color;
        ka.values = [];
        distributionAllocationKAs.push(ka);
      }
    });

    this.loadedDataProducts.expandedTable.rows.forEach((row: any) => {
      const headerWeeks = Object.keys(row).filter((key: string) =>
        key.includes('wk')
      );
      headerWeeks.forEach((header: string) => {
        if (row[header].ka) {
          const ka = distributionAllocationKAs.find(
            item => item.ka === row[header].ka
          );
          const month = header.split('wk')[0];
          const allocationMonthKA = ka.values.filter(
            (item: any) => item.month === month
          );
          let values: { [key: string]: any } = {};

          if (allocationMonthKA.length > 0) {
            [values] = allocationMonthKA;
            values.totalAllocation += Number(row[header].value);
          } else {
            values = {};
            [values.month] = header.split('wk');
            values.totalAllocation = 0;
            values.totalAllocation += Number(row[header].value);
            ka.values.push(values);
          }
        }
      });
    });

    const KARetail = distributionAllocationKAs.find((item: any) =>
      item.ka.toLocaleUpperCase().includes('RETAIL')
    );
    const indexKAMmicom = distributionAllocationKAs.findIndex((item: any) =>
      item.ka.toLocaleUpperCase().includes('MMICOM')
    );
    distributionAllocationKAs[indexKAMmicom].values.forEach(
      (value: any, index: number) => {
        KARetail.values[index].totalAllocation += value.totalAllocation;
      }
    );
    distributionAllocationKAs.splice(indexKAMmicom, 1);

    let totalAllocationFirstMonthAllKAs = 0;
    let totalAllocationSecondMonthAllKAs = 0;
    let totalAllocationThirdMonthAllKAs = 0;
    let demandAllKAs = 0;
    distributionAllocationKAs.forEach(allocationKA => {
      totalAllocationFirstMonthAllKAs += allocationKA.values[0].totalAllocation;
      totalAllocationSecondMonthAllKAs +=
        allocationKA.values[1].totalAllocation;
      totalAllocationThirdMonthAllKAs += allocationKA.values[2].totalAllocation;
      allocationKA.values.forEach((value: any) => {
        demandAllKAs += value.totalAllocation;
      });
    });

    distributionAllocationKAs.forEach(allocationKA => {
      let demandKA = 0;
      allocationKA.values.forEach((value: any) => {
        demandKA += value.totalAllocation;
      });

      const totalAllocationFirstMonthKA =
        allocationKA.values[0].totalAllocation;
      const totalAllocationSecondMonthKA =
        allocationKA.values[1].totalAllocation;
      const totalAllocationThirdMonthKA =
        allocationKA.values[2].totalAllocation;

      const fdFirstMonth: { [key: string]: any } = {};
      fdFirstMonth.month = allocationKA.values[0].month;
      fdFirstMonth.valueAllocation = Math.round(
        totalAllocationFirstMonthKA -
          totalAllocationFirstMonthAllKAs * (demandKA / demandAllKAs)
      );

      const fdSecondMonth: { [key: string]: any } = {};
      fdSecondMonth.month = allocationKA.values[1].month;
      fdSecondMonth.valueAllocation = Math.round(
        totalAllocationFirstMonthKA +
          totalAllocationSecondMonthKA -
          (totalAllocationFirstMonthAllKAs + totalAllocationSecondMonthAllKAs) *
            (demandKA / demandAllKAs)
      );

      const fdThirdMonth: { [key: string]: any } = {};
      fdThirdMonth.month = allocationKA.values[2].month;
      fdThirdMonth.valueAllocation = Math.round(
        totalAllocationFirstMonthKA +
          totalAllocationSecondMonthKA +
          totalAllocationThirdMonthKA -
          (totalAllocationFirstMonthAllKAs +
            totalAllocationSecondMonthAllKAs +
            totalAllocationThirdMonthAllKAs) *
            (demandKA / demandAllKAs)
      );

      fairDistributionKAs.push({
        ka: allocationKA.ka,
        color: allocationKA.color,
        firstMonth: fdFirstMonth,
        secondMonth: fdSecondMonth,
        thirdMonth: fdThirdMonth,
      });
    });

    this.cardsFairDistribution = [];
    fairDistributionKAs.forEach((item: any) => {
      const cardFairKADistribution: CardColorInterface = {
        title: item.ka,
        content: [
          {
            label: `${item.firstMonth.month}:`,
            value: item.firstMonth.valueAllocation,
            valueClass:
              item.firstMonth.valueAllocation >= 0
                ? 'positive-value'
                : 'negative-value',
          },
          {
            label: `${item.secondMonth.month}:`,
            value: item.secondMonth.valueAllocation,
            valueClass:
              item.secondMonth.valueAllocation >= 0
                ? 'positive-value'
                : 'negative-value',
          },
          {
            label: `${item.thirdMonth.month}:`,
            value: item.thirdMonth.valueAllocation,
            valueClass:
              item.thirdMonth.valueAllocation >= 0
                ? 'positive-value'
                : 'negative-value',
          },
        ],
        borderColor: item.color,
        styleClass: 'small-width',
      };
      this.cardsFairDistribution.push(cardFairKADistribution);
    });
  }

  toggleContentVisibility() {
    this.showGeneralInformationsArrow = !this.showGeneralInformationsArrow;
  }

  inputChanged(value?: any) {
    this.updateValuesFormGroup(value);
    this.verifyStatusBreadcrumb(this.isEditTableMode);
  }

  setNewValueVariation(valueEvent: any, valueToUpdateVariation: any) {
    this.loadedDataProducts.expandedTable.headers.forEach((element: any) => {
      const foundRow = this.loadedDataProducts.expandedTable.rows.find(
        (row: any) => {
          return (
            row[element.props].idProduct === valueEvent.idProduct &&
            row[element.props].ka === valueEvent.ka
          );
        }
      );

      if (foundRow) {
        foundRow.fqVariation.value = valueToUpdateVariation;
      }
    });
  }

  setNewValueRetailVariation(valueEvent: any, valueToUpdateVariation: any) {
    this.loadedDataProducts.expandedTable.headers.forEach((element: any) => {
      const foundRow = this.loadedDataProducts.expandedTable.rows.find(
        (row: any) => {
          return (
            row[element.props].idProduct === valueEvent.id &&
            row[element.props].ka?.toLocaleUpperCase().includes('RETAIL')
          );
        }
      );
      if (foundRow) {
        foundRow.fqVariation.value = valueToUpdateVariation;
      }
    });
  }

  setNewValueMonthCalc(valueEvent: any, valueToUpdateVariation: any) {
    let keyReplace = valueEvent.col.replace('Variation', '');
    this.loadedDataProducts.expandedTable.headers.forEach((element: any) => {
      const foundRow = this.loadedDataProducts.expandedTable.rows.find(
        (row: any) => {
          return (
            row[element.props].idProduct === valueEvent.id &&
            row['buffer'].ka?.includes(valueEvent.ka) &&
            row[keyReplace].indiceKey === keyReplace
          );
        }
      );
      if (foundRow) {
        foundRow[keyReplace].value = valueToUpdateVariation;
      }
    });
  }

  setNewValueMonthCalcRetail(valueEvent: any, valueToUpdateCalcRetail: any) {
    let keyReplace = valueEvent.col.replace('Variation', '');
    this.loadedDataProducts.expandedTable.headers.forEach((element: any) => {
      const foundRow = this.loadedDataProducts.expandedTable.rows.find(
        (row: any) => {
          return (
            row[element.props].idProduct === valueEvent.id &&
            row[element.props].ka?.toLocaleUpperCase().includes('RETAIL') &&
            row[keyReplace].indiceKey === keyReplace
          );
        }
      );
      if (foundRow) {
        foundRow[keyReplace].value = valueToUpdateCalcRetail;
      }
    });
  }

  setNewValueMonthVariation(valueEvent: any, valueToUpdateVariation: any) {
    this.loadedDataProducts.expandedTable.headers.forEach((element: any) => {
      const foundRow = this.loadedDataProducts.expandedTable.rows.find(
        (row: any) => {
          return (
            row[element.props].idProduct === valueEvent.id &&
            row['buffer'].ka?.includes(valueEvent.ka) &&
            row[valueEvent.col].indiceKey === valueEvent.col
          );
        }
      );
      if (foundRow) {
        foundRow[valueEvent.col].value = valueToUpdateVariation;
      }
    });
  }

  setNewValueMonthRetail(valueEvent: any, valueToUpdateVariation: any) {
    this.loadedDataProducts.expandedTable.headers.forEach((element: any) => {
      const foundRow = this.loadedDataProducts.expandedTable.rows.find(
        (row: any) => {
          return (
            row[element.props].idProduct === valueEvent.id &&
            row[element.props].ka?.includes('RETAIL') &&
            row[valueEvent.col].indiceKey === valueEvent.col
          );
        }
      );

      if (foundRow) {
        foundRow[valueEvent.col].value = valueToUpdateVariation;
      }
    });
  }

  updateValuesFormGroup(value?: any) {
    const kaName = value.ka;
    const nameFormGroup = `${value.idProduct}-${value.col}`;
    const nameFormGroupVariation = `${value.idProduct}-fqVariation`;
    const formGroup = this.columnsFormGroup[nameFormGroup];
    const formGroupRetail = this.columnsFormGroupRetail[nameFormGroup];
    const formGroupVariation =
      this.columnsFormGroupVariation[nameFormGroupVariation];
    let nameFormControlVariation = '';
    let nameFormControlRetail = '';
    let nameFormControlMLS = '';
    let totalValueOthersKa = 0;
    let totalValuesKa = 0;
    let actualInput = `${value.idProduct}-${value.salesModel}---${value.col}-${value.ka}`;
    let currentValue: any = 0;

    if (!formGroup.controls[actualInput]) {
      formGroup.controls[actualInput].value = '0';
      currentValue = '0';
    }

    let newValueInput: any;
    if (value.click === 1) {
      newValueInput = value.value;
      formGroup.controls[actualInput].value = newValueInput;
      formGroup.value[actualInput] = newValueInput;
    } else {
      newValueInput = formGroup.controls[actualInput].value;
    }

    if (newValueInput === '-') {
      newValueInput = '0';
    }

    if (newValueInput.startsWith('=')) {
      const expression = String(newValueInput).replaceAll(',', '');
      if (expression.startsWith('=')) {
        currentValue = Number(eval(expression));
      } else {
        currentValue = Number(expression);
      }
    } else {
      currentValue = Number(newValueInput.replace(/[\s,]/g, ''));
    }

    let valueMLS: number = 0;
    let valueToupdateRetail: number = 0;
    let valueToUpdateRetailMonth: number = 0;
    let valueRetailVariation = {};
    let valueMonthVariation = {};
    let updateMonthVariation: number = 0;
    let updateMonthCalc: number = 0;
    let updateMonthRetail: number = 0;
    let updateNewFqVariation: number = 0;
    let updateNewFqVariationRetail: number = 0;
    const filteredKaValues = this.kaFilter
      .filter(item => this.formSearchValues.kaId.includes(item.key))
      .map(item => item.value);
    if (filteredKaValues.length > 0) {
      Object.keys(formGroupRetail.controls).forEach((key: string) => {
        if (key.toLocaleUpperCase().includes('RETAIL')) {
          nameFormControlRetail = key;
        } else if (key.toLocaleUpperCase().includes('MLS')) {
          nameFormControlMLS = key;
        } else {
          const lastDashIndex = key.lastIndexOf('-');
          const stringKey = key.substring(lastDashIndex + 1);
          if (!filteredKaValues.includes(stringKey)) {
            totalValuesKa += Number(formGroupRetail.value[key] || 0);
          }
        }
      });
      Object.keys(formGroup.controls).forEach((key: string) => {
        if (key.toLocaleUpperCase().includes('RETAIL')) {
          nameFormControlRetail = key;
        } else if (key.toLocaleUpperCase().includes('MLS')) {
          nameFormControlMLS = key;
        } else {
          if (
            formGroup.value[key] !== undefined &&
            formGroup.value[key] !== null
          ) {
            if (
              typeof formGroup.value[key] === 'string' &&
              formGroup.value[key].startsWith('=')
            ) {
              const expression = formGroup.value[key].replace(/,/g, '');
              try {
                totalValueOthersKa += eval(expression);
              } catch (error) {
                console.error('Erro ao avaliar a expressão:', error);
              }
            } else {
              const numericValue = Number(
                formGroup.value[key].replace(/,/g, '')
              );
              if (isNaN(numericValue)) {
                totalValueOthersKa += 0;
              } else {
                totalValueOthersKa += numericValue;
              }
            }
          }
        }
      });
      valueMLS = Number(formGroup.value[nameFormControlMLS]);
      valueToupdateRetail = valueMLS - totalValueOthersKa - totalValuesKa;

      this.arrayRetailMonthVariation.forEach((item: any) => {
        if (item.id == value.idProduct) {
          if (item.col == value.col) {
            //item.value = valueToupdateRetail;
            valueRetailVariation = { id: item.id };
          }
        }
      });

      let findWeek = value.col.indexOf('w');
      let getColName = value.col.substring(0, findWeek);
      this.arrayVariationMonth.forEach((item: any) => {
        if (
          item.id == value.idProduct &&
          item.col.includes(`${getColName}`) &&
          item.ka == value.ka
        ) {
          valueMonthVariation = item;
          updateMonthVariation = item.value;
        }
        if (
          item.id == value.idProduct &&
          item.ka.includes('RETAIL') &&
          item.col.includes(`${getColName}`)
        ) {
          updateMonthRetail = item.value;
        }
      });

      this.arrayRetailQuarterVariation.forEach((item: any) => {
        if (item.id == value.idProduct && item.ka == value.ka) {
          updateNewFqVariation = item.value;
        }

        if (item.id == value.idProduct && item.ka.includes('RETAIL')) {
          updateNewFqVariationRetail = item.value;
        }
      });

      //primeiro subtrair dos valores de retail antigo depois somar com os valores de retail atual
      this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
        if (
          item[value.col] &&
          item[value.col].idProduct == value.idProduct &&
          item[value.col].ka.includes('RETAIL')
        ) {
          item[value.col].value = valueToupdateRetail;
        }

        if (
          item[value.col] &&
          item[value.col].idProduct == value.idProduct &&
          item[value.col].ka.includes(value.ka)
        ) {
          item[value.col].value = currentValue;
          let someWeek = 0;
          for (let i = 1; i < 6; i++) {
            let colAndWeek: string = `${getColName}wk0${i}`;
            let result: number;
            let checkNumber = String(item[colAndWeek].value);

            if (checkNumber.startsWith('=')) {
              result = eval(checkNumber.slice(1));
            } else {
              result = parseFloat(checkNumber);
            }

            if (!isNaN(result)) {
              someWeek += result;
            } else {
              console.log(`Error evaluating expression ${checkNumber}`);
            }
          }
          updateMonthCalc = someWeek;
        }

        for (let i = 1; i < 6; i++) {
          let colAndWeek: string = `${getColName}wk0${i}`;
          if (
            item[colAndWeek] &&
            item[colAndWeek].idProduct == value.idProduct &&
            item[colAndWeek].ka.includes('RETAIL')
          ) {
            valueToUpdateRetailMonth += item[colAndWeek].value;
          }
        }
      });
      this.arrayRetailMonthVariation.forEach((item: any) => {
        if (
          item.id == value.idProduct &&
          item.col.includes(getColName) &&
          item.ka.includes('RETAIL')
        ) {
          updateMonthRetail = updateMonthRetail - item.value;
        }
        if (
          item.id == value.idProduct &&
          item.col.includes(getColName) &&
          item.ka == value.ka
        ) {
          updateMonthVariation = updateMonthVariation - item.value;
        }

        if (item.id == value.idProduct && item.ka == value.ka) {
          updateNewFqVariation = updateNewFqVariation - item.value;
        }

        if (item.id == value.idProduct && item.ka.includes('RETAIL')) {
          updateNewFqVariationRetail = updateNewFqVariationRetail - item.value;
        }
      });

      if (!isNaN(currentValue)) {
        updateNewFqVariation = updateNewFqVariation + currentValue;
      } else {
        console.warn(`Invalid currentValue: ${currentValue}`);
      }
      updateNewFqVariationRetail =
        updateNewFqVariationRetail + valueToupdateRetail;

      updateMonthRetail = updateMonthRetail + valueToupdateRetail;
      if (!isNaN(currentValue)) {
        updateMonthVariation = updateMonthVariation + currentValue;
      } else {
        console.warn(`Invalid currentValue: ${currentValue}`);
      }
      // check initial
      const checkOtherWeek = Object.keys(this.columnsFormGroup).filter(
        (item: any) => {
          if (
            item.includes('wk') &&
            item.includes(`${value.idProduct}`) &&
            item.includes(getColName) &&
            item !== nameFormGroup
          )
            return item;
        }
      );
      const checkOtherVariation = Object.keys(this.columnsFormGroup).filter(
        (item: any) => {
          if (
            item.includes('wk') &&
            item.includes(`${value.idProduct}`) &&
            item !== nameFormGroup
          )
            return item;
        }
      );
      // calc month col variation and month col retail line
      if (checkOtherWeek.length > 0) {
        checkOtherWeek.forEach((item: any) => {
          let sepatorIdAndWeek = item.split('-');
          let auxIdProduct: number = sepatorIdAndWeek[0];
          let auxMonthAndWeek = sepatorIdAndWeek[1];

          let result = 0;
          let resultRetail: number = 0;
          this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes('RETAIL')
            ) {
              resultRetail = item[auxMonthAndWeek].value;
            }

            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes(value.ka)
            ) {
              result = item[auxMonthAndWeek].value;
            }
          });
          let resultString = result.toString().replaceAll(',', '');
          updateMonthVariation += parseFloat(resultString);
          updateMonthRetail += resultRetail;
        });
      }
      // variação quarter
      if (checkOtherVariation.length > 0) {
        checkOtherVariation.forEach((item: any) => {
          let sepatorIdAndWeek = item.split('-');
          let auxIdProduct: number = sepatorIdAndWeek[0];
          let auxMonthAndWeek = sepatorIdAndWeek[1];
          let result = 0;
          let resultRetail: number = 0;
          this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes(value.ka)
            ) {
              result = item[auxMonthAndWeek].value;
            }

            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes('RETAIL')
            ) {
              resultRetail = item[auxMonthAndWeek].value;
            }
          });
          let resultString = result.toString().replaceAll(',', '');
          updateNewFqVariation += parseFloat(resultString);
          updateNewFqVariationRetail += resultRetail;
        });
      }
    } else {
      Object.keys(formGroup.controls).forEach((key: string) => {
        if (key.toLocaleUpperCase().includes('RETAIL')) {
          nameFormControlRetail = key;
        } else if (key.toLocaleUpperCase().includes('MLS')) {
          nameFormControlMLS = key;
        } else {
          if (
            formGroup.value[key] !== undefined &&
            formGroup.value[key] !== null
          ) {
            if (
              typeof formGroup.value[key] === 'string' &&
              formGroup.value[key].startsWith('=')
            ) {
              const expression = formGroup.value[key].replace(/,/g, '');
              try {
                totalValueOthersKa += eval(expression);
              } catch (error) {
                console.error('Erro ao avaliar a expressão:', error);
              }
            } else {
              const numericValue = Number(
                formGroup.value[key].replace(/,/g, '')
              );
              if (isNaN(numericValue)) {
                totalValueOthersKa += 0;
              } else {
                totalValueOthersKa += numericValue;
              }
            }
          }
        }
      });
      valueMLS = Number(formGroup.value[nameFormControlMLS]);
      valueToupdateRetail = valueMLS - totalValueOthersKa;
      // calc line retail, col variation
      this.arrayRetailMonthVariation.forEach((item: any) => {
        if (item.id == value.idProduct) {
          if (item.col == value.col) {
            //item.value = valueToupdateRetail;
            valueRetailVariation = { id: item.id };
          }
        }
      });
      let findWeek = value.col.indexOf('w');
      let getColName = value.col.substring(0, findWeek);
      this.arrayVariationMonth.forEach((item: any) => {
        if (
          item.id == value.idProduct &&
          item.col.includes(`${getColName}`) &&
          item.ka == value.ka
        ) {
          valueMonthVariation = item;
          updateMonthVariation = item.value;
        }
        if (
          item.id == value.idProduct &&
          item.ka.includes('RETAIL') &&
          item.col.includes(`${getColName}`)
        ) {
          updateMonthRetail = item.value;
        }
      });
      this.arrayRetailQuarterVariation.forEach((item: any) => {
        if (item.id == value.idProduct && item.ka == value.ka) {
          updateNewFqVariation = item.value;
        }

        if (item.id == value.idProduct && item.ka.includes('RETAIL')) {
          updateNewFqVariationRetail = item.value;
        }
      });

      //primeiro subtrair dos valores de retail antigo depois somar com os valores de retail atual
      if (!nameFormGroup.includes('sab')) {
        this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
          if (
            item[value.col] &&
            item[value.col].idProduct == value.idProduct &&
            item[value.col].ka.includes('RETAIL')
          ) {
            item[value.col].value = valueToupdateRetail;
          }
          if (
            item[value.col] &&
            item[value.col].idProduct == value.idProduct &&
            item[value.col].ka.includes(value.ka)
          ) {
            item[value.col].value = currentValue;
            let someWeek = 0;
            for (let i = 1; i < 6; i++) {
              let colAndWeek: string = `${getColName}wk0${i}`;
              let result: number;
              let checkNumber = String(item[colAndWeek].value);

              if (checkNumber.startsWith('=')) {
                result = eval(checkNumber.slice(1));
              } else {
                result = parseFloat(checkNumber);
              }

              if (!isNaN(result)) {
                someWeek += result;
              } else {
                console.log(`Error evaluating expression ${checkNumber}`);
              }
            }
            updateMonthCalc = someWeek;
          }
          for (let i = 1; i < 6; i++) {
            let colAndWeek: string = `${getColName}wk0${i}`;
            if (
              item[colAndWeek] &&
              item[colAndWeek].idProduct == value.idProduct &&
              item[colAndWeek].ka.includes('RETAIL')
            ) {
              valueToUpdateRetailMonth += item[colAndWeek].value;
            }
          }
        });
      } else {
        this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
          if (
            item[value.col] &&
            item[value.col].idProduct == value.idProduct &&
            item[value.col].ka.includes('RETAIL')
          ) {
            let actualLessTotal = totalValueOthersKa - currentValue;
            let result = valueMLS - actualLessTotal;
            item[value.col].value =
              valueToupdateRetail < 0 ? result : valueToupdateRetail;
          }
        });
      }

      this.arrayRetailMonthVariation.forEach((item: any) => {
        if (
          item.id == value.idProduct &&
          item.col.includes(getColName) &&
          item.ka.includes('RETAIL')
        ) {
          updateMonthRetail = updateMonthRetail - item.value;
        }
        if (
          item.id == value.idProduct &&
          item.col.includes(getColName) &&
          item.ka == value.ka
        ) {
          updateMonthVariation = updateMonthVariation - item.value;
        }

        if (item.id == value.idProduct && item.ka == value.ka) {
          updateNewFqVariation = updateNewFqVariation - item.value;
        }

        if (item.id == value.idProduct && item.ka.includes('RETAIL')) {
          updateNewFqVariationRetail = updateNewFqVariationRetail - item.value;
        }
      });
      if (!isNaN(currentValue)) {
        updateNewFqVariation = updateNewFqVariation + currentValue;
      } else {
        console.warn(`Invalid currentValue: ${currentValue}`);
      }
      updateNewFqVariationRetail =
        updateNewFqVariationRetail + valueToupdateRetail;

      updateMonthRetail = updateMonthRetail + valueToupdateRetail;
      if (!isNaN(currentValue)) {
        updateMonthVariation = updateMonthVariation + currentValue;
      } else {
        console.warn(`Invalid currentValue: ${currentValue}`);
      }
      // check initial
      const checkOtherWeek = Object.keys(this.columnsFormGroup).filter(
        (item: any) => {
          if (
            item.includes('wk') &&
            item.includes(`${value.idProduct}`) &&
            item.includes(getColName) &&
            item !== nameFormGroup
          )
            return item;
        }
      );
      const checkOtherVariation = Object.keys(this.columnsFormGroup).filter(
        (item: any) => {
          if (
            item.includes('wk') &&
            item.includes(`${value.idProduct}`) &&
            item !== nameFormGroup
          )
            return item;
        }
      );
      // calc month col variation and month col retail line
      if (checkOtherWeek.length > 0) {
        checkOtherWeek.forEach((item: any) => {
          let sepatorIdAndWeek = item.split('-');
          let auxIdProduct: number = sepatorIdAndWeek[0];
          let auxMonthAndWeek = sepatorIdAndWeek[1];

          let result = 0;
          let resultRetail: number = 0;
          this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes('RETAIL')
            ) {
              resultRetail = item[auxMonthAndWeek].value;
            }

            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes(value.ka)
            ) {
              result = item[auxMonthAndWeek].value;
            }
          });
          let resultString = result.toString().replaceAll(',', '');
          updateMonthVariation += parseFloat(resultString);
          updateMonthRetail += resultRetail;
        });
      }
      // variação quarter
      if (checkOtherVariation.length > 0) {
        checkOtherVariation.forEach((item: any) => {
          let sepatorIdAndWeek = item.split('-');
          let auxIdProduct: number = sepatorIdAndWeek[0];
          let auxMonthAndWeek = sepatorIdAndWeek[1];
          let result = 0;
          let resultRetail: number = 0;
          this.loadedDataProducts.expandedTable.rows.forEach((item: any) => {
            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes(value.ka)
            ) {
              result = item[auxMonthAndWeek].value;
            }

            if (
              item[auxMonthAndWeek] &&
              item[auxMonthAndWeek].idProduct == auxIdProduct &&
              item[auxMonthAndWeek].ka.includes('RETAIL')
            ) {
              resultRetail = item[auxMonthAndWeek].value;
            }
          });
          let resultString = result.toString().replaceAll(',', '');
          updateNewFqVariation += parseFloat(resultString);
          updateNewFqVariationRetail += resultRetail;
        });
      }
      Object.keys(formGroupVariation.controls).forEach((key: string) => {
        if (key.toLocaleUpperCase().includes(kaName)) {
          nameFormControlVariation = key;
        }
      });
    }
    const formControlRetail = formGroup.controls[nameFormControlRetail];

    const zeroRegex = /0+$/;
    const formActual = formGroup.controls[actualInput];
    if (
      nameFormGroup.includes('sab') &&
      (valueMLS <= 0 || totalValueOthersKa > valueMLS)
    ) {
      const subtitleMessage = 'Retail SAB cannot be negative';
      const titleMessage = 'SAB exception';
      formActual.setValue(0, { onlySelf: true, emitEvent: false });
      this.toastr.warning(`${subtitleMessage}`, `${titleMessage}`, {
        timeOut: 10000,
      });
    } else if (!nameFormGroup.includes('sab')) {
      this.setNewValueVariation(value, updateNewFqVariation);
      this.setNewValueRetailVariation(
        valueRetailVariation,
        updateNewFqVariationRetail
      );
      this.setNewValueMonthCalc(valueMonthVariation, updateMonthCalc);
      this.setNewValueMonthCalcRetail(
        valueMonthVariation,
        valueToUpdateRetailMonth
      );
      this.setNewValueMonthVariation(valueMonthVariation, updateMonthVariation);
      this.setNewValueMonthRetail(valueMonthVariation, updateMonthRetail);
      formControlRetail.setValue(`${valueToupdateRetail}`, {
        onlySelf: true,
        emitEvent: false,
      });
      const formGroupIsChanged = Object.keys(formGroup.value).some(
        (key: string) => {
          if (!key.toLocaleUpperCase().includes('MLS')) {
            const ctrlName = key.split('-');
            const idProduct = ctrlName[0];
            const salesModel = ctrlName[1];
            const monthWeek = ctrlName[4];
            const ka = ctrlName[5];
            const rowTable = this.loadedDataProducts.expandedTable.rows.find(
              (row: any) =>
                row[monthWeek].idProduct === Number(idProduct) &&
                row[monthWeek].salesModel === salesModel &&
                row[monthWeek].ka === ka
            );
            const currentValue =
              String(formGroup.value[key]).search(zeroRegex) !== 0
                ? Number(formGroup.value[key])
                : '0';
            return rowTable[monthWeek].value !== currentValue;
          }
          return false;
        }
      );
      if (formGroupIsChanged) {
        if (!this.formGroupToUpdate.includes(formGroup)) {
          this.formGroupToUpdate.push(formGroup);
        }
      } else if (this.formGroupToUpdate.includes(formGroup)) {
        const indexFormGroup = this.formGroupToUpdate.findIndex((item: any) =>
          Object.keys(item.controls).every((key: string) =>
            Object.prototype.hasOwnProperty.call(formGroup.controls, key)
          )
        );
        this.formGroupToUpdate.splice(indexFormGroup, 1);
      }

      this.isEditTableMode = this.formGroupToUpdate.length >= 0;
      this.cdr.detectChanges();
    }
  }

  loadModalChanges(value: string) {
    this.isDiscardChanges = value === 'discard';

    const dialog = this.modalService.createConfirm(
      this.TabComponent.dialogConfirmationDiscardSaveChanges
    );
    const hasNegativesValues = this.formGroupToUpdate
      .map((formValue: any) => formValue.controls)
      .some((allocationValue: any) =>
        Object.keys(allocationValue).some(
          key => Math.sign(allocationValue[key].value) === -1
        )
      );
    this.TabComponent.messageModalChanges =
      hasNegativesValues && !this.isDiscardChanges
        ? this.messages.negativeValues
        : this.messages[value];

    dialog.afterClosed().subscribe((result: any) => {
      if (result && !this.isDiscardChanges) {
        if (
          this.formGroupToUpdate.every(formGroup =>
            this.validateFormService.onValidateFields(formGroup)
          )
        ) {
          const dataToSave: any[] = [];
          const sab: any = [];
          let nameFormControlsChanged: Set<string> = new Set<string>();

          this.formGroupToUpdate.forEach((formGroup: FormGroup) => {
            nameFormControlsChanged = new Set([
              ...Object.keys(formGroup.controls),
              ...nameFormControlsChanged,
            ]);
            if (
              Object.keys(formGroup.controls).every(elem =>
                elem.includes('sab')
              )
            ) {
              sab.push(formGroup);
            }
          });

          //dados na vertical
          const formControlsSab: any[] = [];
          nameFormControlsChanged.forEach((key: string) => {
            const formControlsRow: any[] = [];

            if (!key.includes('MLS')) {
              const itemFormControl = this.allAllocationFormControls.find(
                (iFormControl: any) => iFormControl.formControlName === key
              );

              const month = key.split('-')[4];
              const namedWk = month.substring(month.length - 4);
              //dados na horizontal
              const rowTable =
                this.loadedDataProducts.expandedTable.rows[
                  itemFormControl.indexRow
                ];

              if (namedWk !== 'sab') {
                for (let i = 1; i <= 5; i++) {
                  formControlsRow.push(
                    rowTable[`${month.substring(0, month.length - 1)}${i}`]
                  );
                }
                if (
                  dataToSave.findIndex(
                    (item: any) =>
                      item.id === itemFormControl.idAllocation &&
                      !item.allocationMonthIds
                  ) === -1
                ) {
                  const dataRowAllocation: { [key: string]: any } = {
                    id: itemFormControl.idAllocation,
                  };

                  if (formControlsRow[0].enableInput) {
                    const week1Value = Number(formControlsRow[0].value);
                    dataRowAllocation.week1Allocated = isNaN(week1Value)
                      ? 0
                      : week1Value;
                  }

                  if (formControlsRow[1].enableInput) {
                    const week2Value = Number(formControlsRow[1].value);
                    dataRowAllocation.week2Allocated = isNaN(week2Value)
                      ? 0
                      : week2Value;
                  }

                  if (formControlsRow[2].enableInput) {
                    const week3Value = Number(formControlsRow[2].value);
                    dataRowAllocation.week3Allocated = isNaN(week3Value)
                      ? 0
                      : week3Value;
                  }

                  if (formControlsRow[3].enableInput) {
                    const week4Value = Number(formControlsRow[3].value);
                    dataRowAllocation.week4Allocated = isNaN(week4Value)
                      ? 0
                      : week4Value;
                  }

                  if (formControlsRow[4].enableInput) {
                    const week5Value = Number(formControlsRow[4].value);
                    dataRowAllocation.week5Allocated = isNaN(week5Value)
                      ? 0
                      : week5Value;
                  }

                  dataRowAllocation[this.dictWeeks[namedWk]] = Number(
                    itemFormControl.formControl.value.replaceAll(',', '')
                  );

                  dataToSave.push(dataRowAllocation);
                } else {
                  const indexDataRow = dataToSave.findIndex(
                    (item: any) => item.id === itemFormControl.idAllocation
                  );
                  dataToSave[indexDataRow][this.dictWeeks[namedWk]] = !Number(
                    itemFormControl.formControl.value
                  )
                    ? Number(
                        itemFormControl.formControl.value.replaceAll(',', '')
                      )
                    : Number(itemFormControl.formControl.value);
                }        

                //SAB
              } else {
                const rowSab = rowTable['sab'];
                rowSab.value = itemFormControl.formControl.value;
                formControlsSab.push(rowSab);
              }
            }
          });
          const newDataToSave: any = [];
          if (dataToSave.length > 0) {
            dataToSave.map((row, key) => {
              formControlsSab.map((sab: any) => {
                sab.allocationMonthIds.map((monthId: any) => {
                  if (monthId === row.id) {
                    const newValue = !Number(sab.value) ? 0 : Number(sab.value);
                    row.sab = newValue;
                  }
                });
              });
              newDataToSave.push(row);
            });
          } else {
            formControlsSab.map((value: any) => {
              value.allocationMonthIds.map((item: any) => {
                newDataToSave.push({
                  id: item,
                  sab: !Number(value.value) ? 0 : Number(value.value),
                });
              });
            });
          }
          if (formControlsSab.length * 3 !== newDataToSave.length) {
            formControlsSab.map((value: any) => {
              value.allocationMonthIds.map((item: any) => {
                if (
                  !newDataToSave.find((element: any) => element.id === item)
                ) {
                  newDataToSave.push({
                    id: item,
                    sab: !Number(value.value) ? 0 : Number(value.value),
                  });
                }
              });
            });
          }
          this.crudServiceKa.updateKa(newDataToSave).subscribe(() => {
            this.spinner.show();
            const subtitleMessage = 'Updated successfully!';
            const titleMessage = 'KA allocation';
            this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
            this.discardChanges();
          });
        }
      } else if (result && this.isDiscardChanges) {
        this.discardChanges();
      }
    });
  }

  discardChanges() {
    this.formGroupToUpdate = [];
    this.isEditTableMode = false;
    this.getProducts(this.filter);
    this.verifyStatusBreadcrumb(this.isEditTableMode);
  }

  @HostListener('window:click', ['$event'])
  verifyClickChangeRoute(event: any) {
    const classElementsChangesRoutes = ['xng-breadcrumb-item ng-star-inserted'];
    const isElementChangeRoute =
      classElementsChangesRoutes.filter((className: string) =>
        event.target.className.includes(className)
      ).length > 0;
    if (isElementChangeRoute && this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.modalService.createConfirm(
        this.TabComponent.dialogConfirmationDiscardSaveChanges
      );
      this.TabComponent.messageModalChanges = this.messages.discard;
      const originUrl = event.target.children[0].origin;
      const routeToNavigate = event.target.children[0].href.replace(
        originUrl,
        ''
      );
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.router.navigate([routeToNavigate]);
        }
      });
    }
    if (isElementChangeRoute && !this.isEditTableMode) {
      const originUrl = event.target.children[0].origin;
      const routeToNavigate = event.target.children[0].href.replace(
        originUrl,
        ''
      );
      this.router.navigate([routeToNavigate]);
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key?.includes('F5')) {
      event.preventDefault();
      if (this.isEditTableMode) {
        this.isDiscardChanges = true;
        const dialog = this.modalService.createConfirm(
          this.TabComponent.dialogConfirmationDiscardSaveChanges
        );
        this.TabComponent.messageModalChanges = this.messages.discard;

        dialog.afterClosed().subscribe((result: any) => {
          if (result === 'discard') {
            window.location.reload();
          }
        });
      } else {
        window.location.reload();
      }
    }
  }

  prepareExportTable(data: any, tabName: any): any {
    if (tabName === 'future') {
      const rows: any = data[1].table.rows.map((row: any) => row);
      const columns = data[1].table.columns.map((item: any) => ({
        title: item.title,
        props: item.props,
      }));

      const tableRows: { [key: string]: [] }[] = [];

      for (let i = 0; i < rows.length; i++) {
        const tableRow: { [key: string]: [] } = {};
        columns.forEach((column: any) => {
          if (rows[i][column.props]) {
            tableRow[column.props] = rows[i][column.props]
              ? rows[i][column.props].value
              : '';
          } else {
            tableRow[column.props] = rows[i][column.props]
              ? rows[i][column.props].value
              : '';
          }
        });
        tableRows.push(tableRow);
      }

      return { columns: columns, rows: tableRows };
    }
  }

  async exportTable(tabIndex: number) {
    if (tabIndex === 0) {
      const jsonTable = this.tableCard?.getTableFromHtml();
      if (jsonTable !== undefined) {
        const exportData = [];
        // check base dev if problem
        let prop = this.next ? 'next' : '';
        let name = this.next ? 'actual-quarter' : 'next-quarter';
        await this.hideSpinnerAfterAll([
          this.getDataTableEspecificKa(prop, 'actual-next'),
        ]);
        this.fillGapExport(jsonTable);
        Object.defineProperty(jsonTable, 'sheet', {
          value: `${name}`,
        });
        Object.defineProperty(jsonTable, 'headers', {
          value: true,
        });
        if (this.next) {
          exportData.push(
            jsonTable,
            this.getResultResponse(this.exportActualOrNext, 'next-quarter')
          );
        } else {
          exportData.push(
            jsonTable,
            this.getResultResponse(this.exportActualOrNext, 'actual-quarter')
          );
        }

        this.excelSheetsService.exportJsonAsExcelFile(
          exportData,
          'ka-allocation'
        );
      } else {
        await this.hideSpinnerAfterAll([
          this.getDataTableKaAllocation('', 'actual'),
          this.getDataTableKaAllocation('next', 'next'),
        ]);
        const exportData = [];
        exportData.push(
          this.getResultResponse(this.exportKaActual, 'actual-quarter'),
          this.getResultResponse(this.exportKaNext, 'next-quarter')
        );

        this.excelSheetsService.exportJsonAsExcelFile(
          exportData,
          'ka-allocation'
        );
      }
    }
  }

  getDataTableKaAllocation(params?: any, name?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.crudService.getEntity('kaAllocation', { quarter: params }).subscribe(
        (response: any) => {
          if (name == 'actual') {
            this.exportKaActual = response;
          }
          if (name == 'next') {
            this.exportKaNext = response;
          }
          resolve();
        },
        error => {
          this.spinner.hide();
          reject(error);
        }
      );
    });
  }

  getDataTableEspecificKa(params?: any, name?: any): Promise<void> {
    this.formSearchValues.quarter = params;
    const formNow = { ...this.formSearchValues };
    this.deleteProperty(formNow);
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.crudService.getEntity('kaAllocation', formNow).subscribe(
        (response: any) => {
          if (name == 'actual-next') {
            this.exportActualOrNext = response;
          }
          resolve();
        },
        error => {
          this.spinner.hide();
          reject(error);
        }
      );
    });
  }

  getResultResponse(data: any, name: string) {
    const responseTable: { [key: string]: any } = {};
    responseTable.simpleTable = {
      headers: data.simpleTable.headers.map((item: any) => {
        return item;
      }),
      rows: data.simpleTable.rows,
    };
    data.simpleTable.expandedTable.rows.map((row: any) => {
      return row;
    });
    responseTable.expandedTable = data.simpleTable.expandedTable;
    responseTable.expandedTable.rows.forEach((row: any, key: any) => {
      if (key > 0) {
        row.fqTotal.ka = this.valuesToArray(row)[0].ka;
        row.fqActual.ka = this.valuesToArray(row)[0].ka;
      }
    });
    const dataExport = JSON.parse(JSON.stringify(responseTable));
    const fullJsonExport = this.excelService.exportTableExcel(dataExport);
    this.fillGapExport(fullJsonExport);
    Object.defineProperty(fullJsonExport, 'sheet', {
      value: `${name}`,
    });
    Object.defineProperty(fullJsonExport, 'headers', {
      value: true,
    });
    return fullJsonExport;
  }

  fillGapExport(data: any) {
    data.rows.forEach((element: any, index = 0) => {
      if (index > 0) {
        if (element.product == '') {
          let change = data.rows[index - 1].product;
          element.product = change;
        }

        if (element.salesModel == '') {
          let change = data.rows[index + 1].salesModel;
          element.salesModel = change;
        }

        if (element.origin == '-') {
          let change = data.rows[index - 1].origin;
          element.origin = change;
        }
      }
    });
    return data;
  }

  handleFilterButton(item: any) {
    this.monthCommitButton.forEach((data: any) => {
      if (data.label === item.label) {
        data.value = true;
      } else {
        data.value = false;
      }
    });
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.modalService.createConfirm(
        this.TabComponent.dialogConfirmationDiscardSaveChanges
      );
      this.TabComponent.messageModalChanges = this.messages.discard;

      dialog.afterClosed().subscribe((result: any) => {
        if (result === 'discard') {
          this.router.navigate(['home/ka-allocation']);
        }
      });
    } else {
      this.router.navigate(['home/ka-allocation']);
    }
  }

  toUpload() {
    this.router.navigate(['home/ka-allocation/ka-allocation-upload']);
  }
}
