<h1 mat-dialog-title class="mat-dialog-title title-modal">COMMENTS</h1>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <input matInput [(ngModel)]="data.message" />
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>CANCEL</button>
  <button mat-button class="button-save" [mat-dialog-close]="data.message" cdkFocusInitial>
    SAVE
  </button>
</div>
