export const TableMarketName: any = {
  introTable: {
    headers: [
      // expansível
      {
        title: 'Family',
        props: 'family',
        otherContent: 'family',
        enableContent: false,
      },
      // fica dentro de family
      {
        title: 'Origin',
        props: 'origin',
        otherContent: 'family',
        enableContent: false,
      },
      // fica dentro de family
      {
        title: 'Customer Short',
        props: 'customerShort',
        otherContent: 'family',
        enableContent: false,
        alwaysVisible: true,
      },
      // fica dentro de family
      {
        title: 'Channel',
        props: 'channel',
        otherContent: 'family',
        enableContent: false,
      },
      // fica dentro de family
      {
        title: 'App Taxes',
        props: 'appTaxes',
        otherContent: 'family',
        enableContent: false,
      },
    ],
    content: [
      {
        // renderiza o 1o produto
        idFamily: 12456,
        summarized: [
          // responsável por renderizar o produto quando a tabela estiver colapsada
          {
            rows: [
              {
                enableATS: true,
                value: {
                  // sem valores pra essas propriedades linha 1
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                enableMLS: true,
                value: {
                  // linha 2 -> MLS
                  family: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  origin: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  customerShort: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  channel: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  appTaxes: {
                    value: ' ',
                    // enableMLS: true,
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - sumarizado
                  family: {
                    value: 'HAWAUUPLUS0LED22 4/128 - SOFT BEIGE',
                  },
                  origin: {
                    value: '',
                  },
                  customerShort: {
                    value: 'Móveis Brasilia',
                  },
                  channel: {
                    value: '',
                  },
                  appTaxes: {
                    value: '',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
        expanded: [
          // renderizar linhas do produto quando a tabela estiver expandida (linhas)
          {
            // renderiza para o origin JAG
            rows: [
              {
                enableATS: true,
                value: {
                  // sem valores pra essas propriedades linha 1
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                enableMLS: true,
                value: {
                  // linha 2 -> MLS
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  family: {
                    value: 'HAWAUUPLUS0LED22 4/128 - SOFT BEIGE',
                  },
                  origin: {
                    value: 'JAG',
                  },
                  customerShort: {
                    value: 'Móveis Brasilia',
                  },
                  channel: {
                    value: 'Distributor',
                  },
                  appTaxes: {
                    value: 'Fora_12',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
          {
            // renderiza para o origin MAO
            rows: [
              {
                enableATS: true,
                value: {
                  // sem valores pra essas propriedades linha 1
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                enableMLS: true,
                value: {
                  // linha 2 -> MLS
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                id: 12845, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  family: {
                    value: 'HAWAUUPLUS0LED22 4/128 - SOFT BEIGE',
                  },
                  origin: {
                    value: 'MAO',
                  },
                  customerShort: {
                    value: 'Móveis Brasilia',
                  },
                  channel: {
                    value: 'Distributor',
                  },
                  appTaxes: {
                    value: 'SP',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
      },
      {
        // renderiza o 2o produto
        idFamily: 789,
        summarized: [
          {
            // responsável por renderizar o produto quando a tabela estiver colapsada
            rows: [
              {
                enableATS: true,
                value: {
                  // sem valores pra essas propriedades linha 1
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                enableMLS: true,
                value: {
                  // linha 2 -> MLS
                  family: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  origin: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  customerShort: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  channel: {
                    value: ' ',
                    // enableMLS: true,
                  },
                  appTaxes: {
                    value: ' ',
                    // enableMLS: true,
                  },
                },
              },
              {
                id: 54321, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - sumarizado
                  family: {
                    value: 'HAWAUUPLUS0LED22 4/128 - DEEP LAGOON BEIGE',
                  },
                  origin: {
                    value: '',
                  },
                  customerShort: {
                    value: 'Móveis Brasilia',
                  },
                  channel: {
                    value: '',
                  },
                  appTaxes: {
                    value: '',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
        expanded: [
          // renderizar linhas do produto quando a tabela estiver expandida (linhas)
          {
            // renderiza para o origin JAG
            rows: [
              {
                enableATS: true,
                value: {
                  // sem valores pra essas propriedades linha 1
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                enableMLS: true,
                value: {
                  // linha 2 -> MLS
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                id: 54321, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  family: {
                    value: 'HAWAUUPLUS0LED22 4/128 - DEEP LAGOON BEIGE',
                  },
                  origin: {
                    value: 'JAG',
                  },
                  customerShort: {
                    value: 'Móveis Brasilia',
                  },
                  channel: {
                    value: 'Distributor',
                  },
                  appTaxes: {
                    value: 'SP',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
          {
            // renderiza para o origin MAO
            rows: [
              {
                enableATS: true,
                value: {
                  // sem valores pra essas propriedades linha 1
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                enableMLS: true,
                value: {
                  // linha 2 -> MLS
                  family: {
                    value: ' ',
                  },
                  origin: {
                    value: ' ',
                  },
                  customerShort: {
                    value: ' ',
                  },
                  channel: {
                    value: ' ',
                  },
                  appTaxes: {
                    value: ' ',
                  },
                },
              },
              {
                id: 12845, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  family: {
                    value: 'HAWAUUPLUS0LED22 4/128 - DEEP LAGOON BEIGE',
                  },
                  origin: {
                    value: 'MAO',
                  },
                  customerShort: {
                    value: 'Móveis Brasilia',
                  },
                  channel: {
                    value: 'Distributor',
                  },
                  appTaxes: {
                    value: 'Fora_7',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
      },
    ],
  },
  middleTable: {
    headers: [
      // expansível, assim como os outros possíves meses e suas respectivas semanas
      {
        title: 'Total Jan',
        props: 'january',
        otherContent: 'january',
        enableContent: false,
        currentMonth: false,
      },
      // *** inicio das colunas que ficam dentro de january
      {
        title: 'WK 01',
        props: 'januarywk01', // nome da coluna
        otherContent: 'january', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true, // a coluna possiu valor exibido na linha de subtotal
      },
      {
        title: 'WK 02',
        props: 'januarywk02', // nome da coluna
        otherContent: 'january', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 03',
        props: 'januarywk03', // nome da coluna
        otherContent: 'january', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 04',
        props: 'januarywk04', // nome da coluna
        otherContent: 'january', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header
        hasSubtotal: true,
      },
      {
        title: 'WK 05',
        props: 'januarywk05', // nome da coluna
        otherContent: 'january', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      // *** termina as colunas que ficam dentro de january
      {
        title: 'Total Fev',
        props: 'february', // nome da coluna
        otherContent: 'february',
        enableContent: false,
        currentMonth: false,
      },
      // *** inicio das colunas que ficam dentro de february
      {
        title: 'WK 01',
        props: 'februarywk01', // nome da coluna
        otherContent: 'february', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 02',
        props: 'februarywk02', // nome da coluna
        otherContent: 'february', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header
        hasSubtotal: true,
      },
      {
        title: 'WK 03',
        props: 'februarywk03', // nome da coluna
        otherContent: 'february', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 04',
        props: 'februarywk04', // nome da coluna
        otherContent: 'february', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 05',
        props: 'februarywk05', // nome da coluna
        otherContent: 'february', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      // *** termina as colunas que ficam dentro de february
      {
        title: 'Total Mar',
        props: 'march', // nome da coluna
        otherContent: 'march',
        enableContent: false,
        currentMonth: true, // configura como laranja
      },
      // *** inicio das colunas que ficam dentro de march
      {
        title: 'WK 01',
        props: 'marchwk01', // nome da coluna
        otherContent: 'march', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 02',
        props: 'marchwk02', // nome da coluna
        otherContent: 'march', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false,
        weekInTime: 'previous', // configurar header cinza
        hasSubtotal: true,
      },
      {
        title: 'WK 03',
        props: 'marchwk03', // nome da coluna
        otherContent: 'march', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: true, // adicionar um input de texto
        weekInTime: 'current', // configurar header laranja
        hasSubtotal: true,
      },
      {
        title: 'WK 04',
        props: 'marchwk04', // nome da coluna
        otherContent: 'march', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: true, // adicionar um input de texto
        weekInTime: 'next', // configurar header branco
        hasSubtotal: true,
      },
      {
        title: 'WK 05',
        props: 'marchwk05', // nome da coluna
        otherContent: 'march', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: true, // adicionar um input de texto
        weekInTime: 'next', // configurar header
        hasSubtotal: true,
      },
    ],
    content: [
      {
        idFamily: 12456,
        summarized: [
          {
            rows: [
              {
                enableATS: true, // define que esse é um campo que contém valor de ATS
                value: {
                  // total linha 1: Os valores da 1ª linha refere-se a quantidade ATS
                  january: {
                    value: 9500,
                    customerShort: 'ATS', // nome do customer short que está na
                  },
                  januarywk01: {
                    value: 1000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk02: {
                    value: 2500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk03: {
                    value: 4500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk04: {
                    value: 7500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk05: {
                    value: 9500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  february: {
                    value: 7500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk01: {
                    value: 1000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk02: {
                    value: 2500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk03: {
                    value: 3500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 6000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk05: {
                    value: 7500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  march: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk01: {
                    value: 500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk02: {
                    value: 1000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk03: {
                    value: 2000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk04: {
                    value: 3500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk05: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                },
              },
              {
                enableMLS: true, // define que esse é um campo que contém valor de MLS
                value: {
                  // linha 2 -> MLS
                  /* valores linha 2:  É a soma do subtotal disposto na RN15, para cada semana */
                  january: {
                    value: 9500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk01: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk02: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk03: {
                    value: 2000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk04: {
                    value: 3000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk05: {
                    value: 2000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  february: {
                    value: 7500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk01: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk02: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk03: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 2500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk05: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  march: {
                    value: 5000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk01: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk02: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk03: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk04: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk05: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  january: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk01: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk02: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk03: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk04: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk05: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  february: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk01: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk02: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk03: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk05: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  march: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  marchwk01: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  marchwk02: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  marchwk03: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
        expanded: [
          {
            // renderiza para o origin JAG
            rows: [
              {
                enableATS: true, // define que esse é um campo que contém valor de ATS
                value: {
                  // total linha 1: Os valores da 1ª linha refere-se a quantidade ATS
                  january: {
                    enableATS: true, // define que esse é um campo que contém valor de ATS
                    value: 4500,
                    customerShort: 'ATS', // nome do customer short que está na
                    origin: 'JAG',
                  },
                  januarywk01: {
                    value: 1000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk02: {
                    value: 1900,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk03: {
                    value: 2700,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk04: {
                    value: 3400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk05: {
                    value: 4500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  february: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk01: {
                    value: 1250,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk02: {
                    value: 2000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk03: {
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk04: {
                    value: 4500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk05: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  march: {
                    value: 2000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk01: {
                    value: 400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk02: {
                    value: 900,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk03: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk04: {
                    value: 1700,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk05: {
                    value: 2000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                },
              },
              {
                enableMLS: true, // define que esse é um campo que contém valor de MLS
                value: {
                  // linha 2 -> MLS
                  /* valores linha 2:  É a soma do subtotal disposto na RN15, para cada semana */
                  january: {
                    value: 4500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk01: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk02: {
                    value: 900,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk03: {
                    value: 800,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk04: {
                    value: 700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk05: {
                    value: 1100,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  february: {
                    value: 5000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk01: {
                    value: 1250,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk02: {
                    value: 750,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk03: {
                    value: 1000,
                    origin: 'JAG',
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk05: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  march: {
                    value: 2000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk01: {
                    value: 400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk02: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk03: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk04: {
                    value: 200,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk05: {
                    value: 300,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  january: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk01: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk02: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk03: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk04: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk05: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  february: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk01: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk02: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk03: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk04: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk05: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  march: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  marchwk01: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  marchwk02: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  marchwk03: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 2a Linha com os valores sobre o produto - 1o customer short - app tax Fora_7
                  january: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk01: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk02: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk03: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk04: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk05: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  february: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk01: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk02: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk03: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk04: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk05: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  march: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  marchwk01: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  marchwk02: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  marchwk03: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 3a Linha com os valores sobre o produto - 1o customer short - app tax Fora_12
                  january: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  february: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  march: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  marchwk01: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  marchwk02: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  marchwk03: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
          {
            // renderiza para o origin MAO
            rows: [
              {
                enableATS: true, // define que esse é um campo que contém valor de ATS
                value: {
                  // total linha 1: Os valores da 1ª linha refere-se a quantidade ATS
                  january: {
                    enableATS: true, // define que esse é um campo que contém valor de ATS
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na
                    origin: 'MAO',
                  },
                  januarywk01: {
                    value: 450,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk02: {
                    value: 1175,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk03: {
                    value: 2150,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk04: {
                    value: 3850,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk05: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  february: {
                    value: 2500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk01: {
                    value: 250,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk02: {
                    value: 1000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk03: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk04: {
                    value: 1900,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk05: {
                    value: 2500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  march: {
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk01: {
                    value: 600,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk02: {
                    value: 1200,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk03: {
                    value: 1800,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk04: {
                    value: 2400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk05: {
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                },
              },
              {
                enableMLS: true, // define que esse é um campo que contém valor de MLS
                value: {
                  // linha 2 -> MLS
                  /* valores linha 2:  É a soma do subtotal disposto na RN15, para cada semana */
                  january: {
                    value: 5000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk01: {
                    value: 450,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk02: {
                    value: 725,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk03: {
                    value: 975,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk04: {
                    value: 1700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk05: {
                    value: 1150,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  february: {
                    value: 2500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk01: {
                    value: 250,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk02: {
                    value: 750,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk03: {
                    value: 500,
                    origin: 'MAO',
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk05: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  march: {
                    value: 3000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk01: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk02: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk03: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk04: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk05: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 2a Linha com os valores sobre o produto - 1o customer short - app tax Fora_7
                  january: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk01: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk02: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk03: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk04: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk05: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  february: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk01: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk02: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk03: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk04: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk05: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  march: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  marchwk01: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  marchwk02: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  marchwk03: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 3a Linha com os valores sobre o produto - 1o customer short - app tax Fora_12
                  january: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  february: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  march: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  marchwk01: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  marchwk02: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  marchwk03: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
      },
      {
        idFamily: 789,
        summarized: [
          {
            rows: [
              {
                enableATS: true, // define que esse é um campo que contém valor de ATS
                value: {
                  // total linha 1: Os valores da 1ª linha refere-se a quantidade ATS
                  january: {
                    value: 4400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk01: {
                    value: 700,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk02: {
                    value: 1700,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk03: {
                    value: 2700,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk04: {
                    value: 3400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  januarywk05: {
                    value: 4400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  february: {
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk01: {
                    value: 400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk02: {
                    value: 900,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk03: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 2200,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  februarywk05: {
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  march: {
                    value: 10000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk01: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk02: {
                    value: 2000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk03: {
                    value: 5750,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk04: {
                    value: 7000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                  marchwk05: {
                    value: 10000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                  },
                },
              },
              {
                enableMLS: true, // define que esse é um campo que contém valor de MLS
                value: {
                  // linha 2 -> MLS
                  /* valores linha 2:  É a soma do subtotal disposto na RN15, para cada semana */
                  january: {
                    value: 4400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk01: {
                    value: 700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk02: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk03: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk04: {
                    value: 700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  januarywk05: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  february: {
                    value: 3000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk01: {
                    value: 400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk02: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk03: {
                    value: 600,
                    origin: 'JAG',
                  },
                  februarywk04: {
                    value: 700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk05: {
                    value: 800,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  march: {
                    value: 10000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk01: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk02: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk03: {
                    value: 3750,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk04: {
                    value: 1250,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  marchwk05: {
                    value: 3000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  january: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk01: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk02: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk03: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk04: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  januarywk05: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  february: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk01: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk02: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk03: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  februarywk05: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  march: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  marchwk01: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  marchwk02: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                  },
                  marchwk03: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
        expanded: [
          {
            // renderiza para o origin JAG
            rows: [
              {
                enableATS: true, // define que esse é um campo que contém valor de ATS
                value: {
                  // total linha 1: Os valores da 1ª linha refere-se a quantidade ATS
                  january: {
                    enableATS: true, // define que esse é um campo que contém valor de ATS
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na
                    origin: 'JAG',
                  },
                  januarywk01: {
                    value: 400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk02: {
                    value: 900,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk03: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk04: {
                    value: 2200,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk05: {
                    value: 3000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  february: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk01: {
                    value: 100,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk02: {
                    value: 300,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk03: {
                    value: 600,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk04: {
                    value: 1000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk05: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  march: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk01: {
                    value: 700,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk02: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk03: {
                    value: 2400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk04: {
                    value: 3400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk05: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                },
              },
              {
                enableMLS: true, // define que esse é um campo que contém valor de MLS
                value: {
                  // linha 2 -> MLS
                  /* valores linha 2:  É a soma do subtotal disposto na RN15, para cada semana */
                  january: {
                    value: 3000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk01: {
                    value: 400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk02: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk03: {
                    value: 600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk04: {
                    value: 700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  januarywk05: {
                    value: 800,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  february: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk01: {
                    value: 100,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk02: {
                    value: 200,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk03: {
                    value: 300,
                    origin: 'JAG',
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  februarywk05: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  march: {
                    value: 5000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk01: {
                    value: 700,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk02: {
                    value: 800,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk03: {
                    value: 900,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk04: {
                    value: 1000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                  marchwk05: {
                    value: 1600,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'JAG',
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                  january: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk01: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk02: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk03: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk04: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  januarywk05: {
                    id: 77588, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  february: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk01: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk02: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk03: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk04: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  februarywk05: {
                    id: 77909, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  march: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  marchwk01: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  marchwk02: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                  },
                  marchwk03: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78365, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'SP',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 2a Linha com os valores sobre o produto - 1o customer short - app tax Fora_7
                  january: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk01: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk02: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk03: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk04: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  januarywk05: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  february: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk01: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk02: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk03: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk04: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  februarywk05: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  march: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  marchwk01: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  marchwk02: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                  },
                  marchwk03: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 3a Linha com os valores sobre o produto - 1o customer short - app tax Fora_12
                  january: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  januarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  february: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  februarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  march: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  marchwk01: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  marchwk02: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                  },
                  marchwk03: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'JAG',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
          {
            // renderiza para o origin MAO
            rows: [
              {
                enableATS: true, // define que esse é um campo que contém valor de ATS
                value: {
                  // total linha 1: Os valores da 1ª linha refere-se a quantidade ATS
                  january: {
                    enableATS: true, // define que esse é um campo que contém valor de ATS
                    value: 1400,
                    customerShort: 'ATS', // nome do customer short que está na
                    origin: 'MAO',
                  },
                  januarywk01: {
                    value: 150,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk02: {
                    value: 400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk03: {
                    value: 900,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk04: {
                    value: 1200,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk05: {
                    value: 1400,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  february: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk01: {
                    value: 200,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk02: {
                    value: 750,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk03: {
                    value: 1225,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk04: {
                    value: 1375,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk05: {
                    value: 1500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  march: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk01: {
                    value: 750,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk02: {
                    value: 2000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk03: {
                    value: 2500,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk04: {
                    value: 3250,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk05: {
                    value: 5000,
                    customerShort: 'ATS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                },
              },
              {
                enableMLS: true, // define que esse é um campo que contém valor de MLS
                value: {
                  // linha 2 -> MLS
                  /* valores linha 2:  É a soma do subtotal disposto na RN15, para cada semana */
                  january: {
                    value: 1400,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk01: {
                    value: 150,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk02: {
                    value: 250,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk03: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk04: {
                    value: 300,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  januarywk05: {
                    value: 200,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  february: {
                    value: 1500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk01: {
                    value: 200,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk02: {
                    value: 550,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk03: {
                    value: 475,
                    origin: 'MAO',
                    customerShort: 'MLS', // nome do customer short que está na linha
                  },
                  februarywk04: {
                    value: 150,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  februarywk05: {
                    value: 125,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  march: {
                    value: 5000,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk01: {
                    value: 750,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk02: {
                    value: 1250,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk03: {
                    value: 500,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk04: {
                    value: 750,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                  marchwk05: {
                    value: 1750,
                    customerShort: 'MLS', // nome do customer short que está na linha
                    origin: 'MAO',
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 2a Linha com os valores sobre o produto - 1o customer short - app tax Fora_7
                  january: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk01: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk02: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk03: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk04: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  januarywk05: {
                    id: 77543, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  february: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk01: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk02: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk03: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk04: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  februarywk05: {
                    id: 78207, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  march: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  marchwk01: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  marchwk02: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                  },
                  marchwk03: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 78400, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_7',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
              {
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  // 3a Linha com os valores sobre o produto - 1o customer short - app tax Fora_12
                  january: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  januarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  february: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk01: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk02: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk03: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk04: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  februarywk05: {
                    id: 25755, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  march: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  marchwk01: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  marchwk02: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    enableInput: true,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                  },
                  marchwk03: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk04: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                  marchwk05: {
                    id: 77964, // id utilizado para atualizar as informações de alocação dessa linha
                    value: 0,
                    customerShort: 'Móveis Brasilia', // nome do customer short que está na linha
                    appTaxes: 'Fora_12',
                    origin: 'MAO',
                    enableInput: true, // habilita o input para esse campo
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
      },
    ],
  },
  endTable: {
    headers: [
      {
        title: 'Missing',
        props: 'missing', // nome da coluna
        otherContent: 'missing', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false, // adicionar um input de texto
        hasSubtotal: true,
        hideIconExpand: true,
      },
      {
        title: 'Net Open PO',
        props: 'netOpenPo', // nome da coluna
        otherContent: 'netOpenPo', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false, // adicionar um input de texto
        hasSubtotal: true,
        hideIconExpand: true,
      },
      {
        title: 'To Book',
        props: 'toBook', // nome da coluna
        otherContent: 'toBook', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false, // adicionar um input de texto
        hasSubtotal: true,
        hideIconExpand: true,
      },
      {
        title: 'Overbooked',
        props: 'overbooked', // nome da coluna
        otherContent: 'overbooked', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false, // adicionar um input de
        hasSubtotal: true,
        hideIconExpand: true,
      },
      {
        title: 'Booked',
        props: 'booked', // nome da coluna
        otherContent: 'booked', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false, // adicionar um input de texto
        hasSubtotal: true,
        hideIconExpand: true,
      },
      {
        title: 'Sell In',
        props: 'sellIn', // nome da coluna
        otherContent: 'sellIn', // indica a qual mês essa coluna pertence. obs: se for diferente, indica que essa coluna fica dentro da coluna que tem esse nome (props)
        enableContent: false, // adicionar um input de texto
        hasSubtotal: true,
        hideIconExpand: true,
      },
    ],
    content: [
      {
        // informações do 1o produto
        idFamily: 12456,
        summarized: [
          {
            rows: [
              /* linha 1 ainda não vai ser colocada pois a tabela ainda não está
             preparada para receber ATS */
              {
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // linha 2 -> MLS
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // 1a Linha com os valores sobre o produto - 1o customer short - sumarizado
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  missing: {
                    value: '0',
                  },
                  netOpenPo: {
                    value: '1000',
                  },
                  toBook: {
                    value: '0',
                  },
                  overbooked: {
                    value: '2400',
                  },
                  booked: {
                    value: '300',
                  },
                  sellIn: {
                    value: '500',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
        expanded: [
          {
            // renderiza para o origin JAG
            rows: [
              /* linha 1 ainda não vai ser colocada pois a tabela ainda não está
             preparada para receber ATS */
              {
                enableATS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // linha 2 -> MLS
                enableMLS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  missing: {
                    value: '0',
                  },
                  netOpenPo: {
                    value: '1000',
                  },
                  toBook: {
                    value: '0',
                  },
                  overbooked: {
                    value: '2400',
                  },
                  booked: {
                    value: '300',
                  },
                  sellIn: {
                    value: '500',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
          {
            // renderiza para o origin MAO
            rows: [
              {
                enableATS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // linha 2 -> MLS
                enableMLS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // 1a Linha com os valores sobre o produto - 1o customer short - app tax Fora_7
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  missing: {
                    value: '0',
                  },
                  netOpenPo: {
                    value: '1500',
                  },
                  toBook: {
                    value: '0',
                  },
                  overbooked: {
                    value: '100',
                  },
                  booked: {
                    value: '100',
                  },
                  sellIn: {
                    value: '500',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
      },
      {
        // informações do 2o produto
        idFamily: 789,
        summarized: [
          {
            rows: [
              {
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // linha 2 -> MLS
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // 1a Linha com os valores sobre o produto - 1o customer short - sumarizado
                id: 12345, // idfamily + origin + idcustomer
                value: {
                  missing: {
                    value: '0',
                  },
                  netOpenPo: {
                    value: '1000',
                  },
                  toBook: {
                    value: '0',
                  },
                  overbooked: {
                    value: '2400',
                  },
                  booked: {
                    value: '300',
                  },
                  sellIn: {
                    value: '500',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
        expanded: [
          {
            // renderiza para o origin JAG
            rows: [
              /* linha 1 ainda não vai ser colocada pois a tabela ainda não está
             preparada para receber ATS */
              {
                enableATS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              // linha 2 -> MLS
              {
                enableMLS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // 1a Linha com os valores sobre o produto - 1o customer short - app tax SP
                id: 54321, // idfamily + origin + idcustomer
                value: {
                  missing: {
                    value: '0',
                  },
                  netOpenPo: {
                    value: '1000',
                  },
                  toBook: {
                    value: '0',
                  },
                  overbooked: {
                    value: '2400',
                  },
                  booked: {
                    value: '300',
                  },
                  sellIn: {
                    value: '500',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
          {
            // renderiza para o origin MAO
            rows: [
              {
                enableATS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              // linha 2 -> MLS
              {
                enableMLS: true,
                value: {
                  missing: {
                    value: ' ',
                  },
                  netOpenPo: {
                    value: ' ',
                  },
                  toBook: {
                    value: ' ',
                  },
                  overbooked: {
                    value: ' ',
                  },
                  booked: {
                    value: ' ',
                  },
                  sellIn: {
                    value: ' ',
                  },
                },
              },
              {
                // 1a Linha com os valores sobre o produto - 1o customer short - app tax Fora_7
                id: 54321, // idfamily + origin + idcustomer
                value: {
                  missing: {
                    value: '0',
                  },
                  netOpenPo: {
                    value: '1000',
                  },
                  toBook: {
                    value: '0',
                  },
                  overbooked: {
                    value: '2400',
                  },
                  booked: {
                    value: '300',
                  },
                  sellIn: {
                    value: '500',
                  },
                },
              },
            ],
            hasRowSubtotal: true,
          },
        ],
      },
    ],
  },
};
