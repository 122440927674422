import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { UploadFileService } from 'src/app/services/generic/upload-file.service';

@Component({
  selector: 'app-upload-one-plan',
  templateUrl: './upload-one-plan.component.html',
  styleUrls: ['./upload-one-plan.component.scss'],
})

export class UploadOnePlanComponent {
  @ViewChild('fileUpload') file!: ElementRef;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation: | TemplateRef<any> | any;
  fileName: any = '';
  isInputPlan: boolean = true;
  isPermission: any;
  hasPermissionCreate: any;
  uploadFileService: UploadFileService<any>;
  messageModal!: string;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private toastr: ToastrService,
    private modalService: ModalActionsService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private sharedInfoService: SharedinfoService,
  ) {
    this.uploadFileService = new UploadFileService<any>(this.http);
    this.isPermission = this.authService.getUserPermissions();
  }

  ngOnInit(): void {
    this.isInputPlan = this.authService.getUserPermissions().some((data: any) => (data.resource === 'one-plan-upload' && data.permission === 'CREATE'));
    this.sharedInfoService.emitChangeLocalStorage.subscribe(() => {
      this.isInputPlan = this.authService.getUserPermissions().some((data: any) => (data.resource === 'one-plan-upload' && data.permission === 'CREATE'));
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target?.files[0];
    if (file) {
      this.spinner.show();
      this.fileName = file.name;

      this.uploadFileService.uploadOnePlan('onePlan/upload', file).then((response: any) => {
        this.file.nativeElement.value = null;
        this.fileName = '';
        this.spinner.hide();
        this.toastr.success('File Uploaded!', 'Success');
      }).catch((error: any) => {
        this.modalService.createConfirm(this.dialogConfirmation);
        this.messageModal = error.error.message.toString();
        this.file.nativeElement.value = null;
        this.fileName = '';
        this.spinner.hide();
      });
    }
  }

  backButton() {
    this.router.navigate(['home/one-plan']);
  }
}
