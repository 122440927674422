<footer class="footer" flex>
  <mat-divider></mat-divider>
  <div class="footer-content">
    <div class="version">
      <img src="../../../assets/images/logo-moto-gray.svg" />
      <p>
        {{ environmentName }} | Version {{ versionApp }}
        <a
          class="version-link"
          (click)="prepareToReadNotes()"
          *ngIf="showReleaseNotes">
          | Release Notes</a
        >
      </p>
    </div>
    <div class="last-update" *ngIf="lastUpdateOrderBook">
      <h1 class="order-book">
        Order Book Last Update: {{ lastUpdateOrderBook }}
      </h1>
    </div>
  </div>
</footer>
<ng-template #modalCreateEdit>
  <header class="title-modal" matDialogTitle>RELEASE NOTES</header>
  <mat-tab-group>
    <mat-tab label="CURRENT">
      <mat-dialog-content style="max-height: 55vh">
        <div [innerHTML]="htmlVersion"></div>
      </mat-dialog-content>
    </mat-tab>
    <mat-tab label="HISTORY">
      <mat-dialog-content class="custom-modal">
        <div *ngFor="let option of getStringMultiple">
          <div [innerHTML]="option.notes"></div>
          <mat-divider></mat-divider>
        </div>
      </mat-dialog-content>
    </mat-tab>
  </mat-tab-group>
  <mat-dialog-actions>
    <button
      style="background-color: #fb544c; color: white"
      mat-button
      matDialogClose>
      CLOSE
    </button>
  </mat-dialog-actions>
</ng-template>
