import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-mkn-to-book-table',
  templateUrl: './mkn-to-book-table.component.html',
  styleUrls: ['./mkn-to-book-table.component.scss'],
})
export class MknToBookTableComponent implements OnInit {
  @Input() data!: any;
  @Input() dataRow!: any;
  @Input() response!: any;
  @Input() total: any;
  displayedColumns: any;
  headers: any;
  rows: any;

  constructor(private app: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.headers = this.data?.headers;
    this.rows = this.data?.rows;

    this.displayedColumns = this.headers;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPage();
  }
  scrollPage() {
    const tableHeader = this.app.nativeElement.querySelector('#table thead tr');
    const tableFooter = this.app.nativeElement.querySelector('#footer');

    if (tableHeader && tableFooter) {
      const windowTop = window.scrollY || window.pageYOffset;
      const tableTop = tableHeader.getBoundingClientRect().top + windowTop;
      const footerTop = tableFooter.getBoundingClientRect().top + windowTop;
      const headerHeight = tableHeader.offsetHeight;
      const offset = 0;

      if (windowTop + headerHeight <= footerTop) {
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${offset}px`;
      } else {
        // Parar antes do footer
        tableHeader.classList.add('fixed-header');
        tableHeader.style.top = `${footerTop - tableTop - headerHeight}px`;
      }
    }
  }

  checkAndSetEmptyTitle(title: string, i: number): string {
    let firstMatch: string | null = null;
    let foundMatch = false;

    this.dataRow.forEach((dataRow: any, index: number) => {
      dataRow.data.forEach((row: any, i: number) => {
        if (row.family === title) {
          if (!foundMatch) {
            firstMatch = title;
            foundMatch = true;
          } else {
            if (i !== 0) {
              row.family = '';
            }
          }
        }
        if (row.customerShort === title) {
          if (!foundMatch) {
            firstMatch = title;
            foundMatch = true;
          } else {
            if (i !== 0) {
              row.customerShort = '';
            }
          }
        }
        if (row.origin === title) {
          if (!foundMatch) {
            firstMatch = title;
            foundMatch = true;
          } else {
            if (i !== 0) {
              row.origin = '';
            }
          }
        }
      });
    });

    return firstMatch || title;
  }

  isPropNumber(prop: number): boolean {
    return typeof prop === 'number';
  }

  isPropString(prop: string): boolean {
    return typeof prop === 'string';
  }

  expecificProp(prop: string): boolean {
    return (
      prop === 'family' ||
      prop === 'origin' ||
      prop === 'customerShort' ||
      prop === 'tax'
    );
  }

  getSubtotalKeys(family?: string, origin?: string) {
    const valor = `${family} ${origin}`;
    return valor;
  }
}
