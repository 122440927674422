export const rolloverMockup = {
  headers: [
    {
      title: 'Product',
      props: 'product',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'KA',
      props: 'ka',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'Sales Model',
      props: 'salesModel',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'Alloc',
      props: 'alloc',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'Actual',
      props: 'actual',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'Rollover',
      props: 'rollover',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'Date',
      props: 'date',
      hideIconExpand: true,
      alwaysVisible: true,
    },
  ],
  rows: [
    {
      product: 'XT2133 - Lisbon 6/28 aqua Silverrrrrrrr',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: -1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
    {
      product: 'XT2133 - Lisbon 6/28 aqua silver',
      ka: 'Claro_BR',
      salesModel: 'PAKH002BR',
      alloc: 1000,
      actual: 1000,
      rollover: 1000,
      date: '00/00/00 at 00:00',
    },
  ],
};

export const logHistoryMockup = {
  headers: [
    {
      title: 'LAST DATE',
      props: 'lastDate',
    },
    {
      title: 'LOG DETAILS',
      props: 'logDetails',
    },
    {
      title: 'STATUS',
      props: 'status',
    },
  ],
  rows: [
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'NOT EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'NOT EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
    {
      lastDate: '00/00/00 at 00:00',
      logDetails: 'Lorem ipsum dolor sit amet consecte...',
      status: 'EXECUTED',
    },
  ],
};

export const cardsColor = [
  {
    title: 'BRAZIL',
    subtitles: ['Allocation', 'Actual', 'Rollover'],
    values: [19523, 19523, 19523],
    color: '#BCCEFB',
  },
  {
    title: 'CLARO BRA',
    subtitles: ['Allocation', 'Actual', 'Rollover'],
    values: [12345, 12345, 12345],
    color: '#D33115',
  },
  {
    title: 'MMICOM BR',
    subtitles: ['Allocation', 'Actual', 'Rollover'],
    values: [12345, 12345, 12345],
    color: '#73D8FF',
  },
  {
    title: 'RETAIL',
    subtitles: ['Allocation', 'Actual', 'Rollover'],
    values: [19523, 19523, 19523],
    color: '#68BC00',
  },
  {
    title: 'TIM',
    subtitles: ['Allocation', 'Actual', 'Rollover'],
    values: [12345, 12345, 12345],
    color: '#009CE0',
  },
  {
    title: 'VIVO BRA',
    subtitles: ['Allocation', 'Actual', 'Rollover'],
    values: [12345, 12345, 12345],
    color: '#FA28FF',
  },
];

export const lastAllertsMockup = [
  {
    alert: 'DATA PRODUCTION',
    subtitle: 'There are data reduction. You Need to confirm first.',
    warnning: true,
  },
  {
    alert: 'ORDER BOOK FAIL REDUCTIOIN',
    subtitle: 'Data reduction denied.',
  },
  {
    alert: 'NEW CUSTOMER CREATED',
    subtitle: 'New Data has been update.',
  },
  {
    alert: 'NEW MATERIAL CREATED',
    subtitle: 'New Data has been Created.',
  },
  {
    alert: 'MATERIAL EDITED',
    subtitle: 'New Data has been updated.',
  },
  {
    alert: 'MATERIAL DELETED',
    subtitle: 'New Material has been Deleted.',
  },
  {
    alert: 'MATERIAL DELETED',
    subtitle: 'New Material has been Deleted.',
  },
  {
    alert: 'MATERIAL DELETED',
    subtitle: 'New Material has been Deleted.',
  },
];

export const notificationsMockup = [
  {
    notifications: 'TITLE OF NOTIFICATIONS',
    minutes: '4 MINUTES AGO',
    subtitle: 'Details of Notification.',
    tag: 'SCREEN NAME',
    read: true,
  },
  {
    notifications: 'NEW ORDER FOR CUSTOMER',
    minutes: '4 MINUTES AGO',
    subtitle: 'Order 2313213 Whithout TAX FORA_12 Associated',
    tag: 'CUSTOMER SETTINGS',
  },
  {
    notifications: 'NEW ORDER DIVERGENT',
    date: '00/00/0000 AT 00:00',
    subtitle: 'New Data has been update.',
    tag: 'ORDER BOOK',
    warnning: true,
    read: true,
  },
  {
    notifications: 'DATA REDUCTION FAILED',
    date: '00/00/0000 AT 00:00',
    subtitle: 'New Data has been update.',
    tag: 'ORDER BOOK',
  },
  {
    notifications: 'TITLE OF NOTIFICATIONS',
    minutes: '4 MINUTES AGO',
    subtitle: 'Details of Notification.',
    tag: 'SCREEN NAME',
  },
  {
    notifications: 'NEW ORDER FOR CUSTOMER',
    minutes: '4 MINUTES AGO',
    subtitle: 'Order 2313213 Whithout TAX FORA_12 Associated',
    tag: 'CUSTOMER SETTINGS',
  },
  {
    notifications: 'NEW ORDER DIVERGENT',
    date: '00/00/0000 AT 00:00',
    subtitle: 'New Data has been update.',
    tag: 'ORDER BOOK',
    warnning: true,
  },
  {
    notifications: 'DATA REDUCTION FAILED',
    date: '00/00/0000 AT 00:00',
    subtitle: 'New Data has been update.',
    tag: 'ORDER BOOK',
  },
  {
    notifications: 'DATA REDUCTION FAILED',
    date: '00/00/0000 AT 00:00',
    subtitle: 'New Data has been update.',
    tag: 'ORDER BOOK',
  },
  {
    notifications: 'DATA REDUCTION FAILED',
    date: '00/00/0000 AT 00:00',
    subtitle: 'New Data has been update.',
    tag: 'ORDER BOOK',
  },
];
