<mat-accordion
  *ngIf="selectedTab !== 'emea_location' && selectedTab !== 'emea_exception'">
  <mat-expansion-panel class="mat-expansion-panel">
    <mat-expansion-panel-header
      [ngClass]="
        selectedTab === 'customer_short'
          ? 'mat-expansion-panel-header-cs'
          : 'mat-expansion-panel-header-cl'
      ">
      <mat-panel-title>
        {{ label }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-container
        *ngFor="let item of arrayDetails; let i = index; trackBy: trackByIndex"
        class="mat-expansion-panel-row">
        <label>
          {{ item }}
        </label>
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="selectedTab === 'emea_location'" class="emea_location">
  <mat-expansion-panel class="mat-expansion-panel">
    <mat-expansion-panel-header
      [ngClass]="
        selectedTab === 'customer_short'
          ? 'mat-expansion-panel-header-cs'
          : 'mat-expansion-panel-header-cl'
      ">
      <mat-panel-title class="title-accordion-country">
        {{ labelCountry }}
      </mat-panel-title>

      <mat-panel-title class="title-accordion-iso-code">
        {{ labelIsoCode }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="body-accordion-country">
        <ng-container
          *ngFor="
            let item of arrayDetailsCountry;
            let i = index;
            trackBy: trackByIndex
          "
          class="mat-expansion-panel-row">
          <label>
            {{ item }}
          </label>
        </ng-container>
      </div>

      <div class="body-accordion-iso-code">
        <ng-container
          *ngFor="
            let item of arrayDetailsIsoCode;
            let i = index;
            trackBy: trackByIndex
          "
          class="mat-expansion-panel-row">
          <label>
            {{ item }}
          </label>
        </ng-container>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="selectedTab === 'emea_exception'" class="emea_exception">
  <mat-expansion-panel class="mat-expansion-panel">
    <mat-expansion-panel-header
      [ngClass]="
        selectedTab === 'customer_short'
          ? 'mat-expansion-panel-header-cs'
          : 'mat-expansion-panel-header-cl'
      ">
      <mat-panel-title class="title-accordion-country">
        {{ labelCountry }}
      </mat-panel-title>

      <mat-panel-title class="title-accordion-iso-code">
        {{ labelIsoCode }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="body-accordion-country">
        <ng-container
          *ngFor="
            let item of arrayDetailsCountry;
            let i = index;
            trackBy: trackByIndex
          "
          class="mat-expansion-panel-row">
          <label>
            {{ item }}
          </label>
        </ng-container>
      </div>

      <div class="body-accordion-iso-code">
        <ng-container
          *ngFor="
            let item of arrayDetailsIsoCode;
            let i = index;
            trackBy: trackByIndex
          "
          class="mat-expansion-panel-row">
          <label>
            {{ item }}
          </label>
        </ng-container>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
