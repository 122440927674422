import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  InputBase,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { Customer } from 'src/app/interfaces/customer.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { CardInterface } from 'src/app/components/cards/card.interface';
import { User } from 'src/app/interfaces/user.interface';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Channel } from 'src/app/interfaces/channel.interface';
import { taxesApplicable } from 'src/app/interfaces/taxesApplicable.interface';
import STATUS from 'src/assets/constants/status';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PermissionService } from '../../../services/authorization/permission.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer-management.component.html',
  styleUrls: ['./customer-management.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class CustomerManagementComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  searchInputs!: InputBase<string>[];
  displayedColumns: any;
  result!: ResultInterface;
  customerForm: FormGroup;
  isEdit: boolean = false;
  geoEntities: Geo[] = [];
  countryEntities: Country[] = [];
  statusEntity: any[] = [];
  salesManagerEntities: any[] = [];
  salesManagerEntitiesByCountry: any[] = [];
  orderAdminEntities: any[] = [];

  custumerCrudService: CrudService<Customer>;
  userCrudService: CrudService<User>;
  pagination: Pagination;
  filter: any = { sort: 'customerName' };
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  nameFirstSelectedSalesManager: string = '';
  messageError = '';
  customerNameEntities: any[] = [];
  customerNumberEntities: any[] = [];
  customerShortEntities: any[] = [];
  taxesApplicableEntities: any[] = [];
  channelEntities: any[] = [];
  orderAdminIdEntities: any[] = [];
  orderAdminCrudService: any;
  channelCrudService: CrudService<Channel>;
  buttonSaveDisabled = false;
  taxesApplicableCrudService: CrudService<taxesApplicable>;
  filteredOptionsOrderAdmin!: Observable<any[]>;

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private permissionService: PermissionService
  ) {
    this.pagination = { page: 0, size: 10 };
    this.custumerCrudService = new CrudService<Customer>(this.http, 'customer');
    this.userCrudService = new CrudService<User>(this.http, 'user');
    this.channelCrudService = new CrudService<Channel>(this.http, 'channel');
    this.taxesApplicableCrudService = new CrudService<taxesApplicable>(
      this.http,
      'taxesApplicable'
    );
    this.customerForm = this.formBuilder.group({
      id: [''],
      geo: ['', Validators.required],
      country: ['', Validators.required],
      customerNumber: ['', Validators.required],
      customerPoLineNumber: ['', Validators.required],
      customerName: ['', Validators.required],
      status: ['', Validators.required],
      salesStructures: new FormArray([]),
    });
  }

  createSearchInputs(): any[] {
    return [
      new SelectSearchInput({
        key: 'geo',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        type: 'text',
      }),
      new SelectSearchInput({
        key: 'customerName',
        hover: 'Customer Name',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'customerShort',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'customerPoLineNumber',
        hover: 'Customer Line Number',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  createSalesStructureFormGroup(value?: any): FormGroup {
    const salesStructureFormGroup: any = {
      id: [value ? value.id : ''],
      orderAdminId: [value ? value.orderAdminId : '', Validators.required],
      salesManagerId: [value ? value.salesManagerId : '', Validators.required],
      customerShort: [
        value ? value.customerShort : '',
        [this.validateNoOlynSpaces, Validators.required],
      ],
      customerPoLineNumber: [
        value ? value.customerPoLineNumber : '',
        [this.validateNoOlynSpaces, Validators.required],
      ],
      channelId: [value ? value.channelId : '', Validators.required],
      taxes: [
        value ? value.taxes.map((tax: any) => tax.id) : '',
        Validators.required,
      ],
    };
    if (value?.id) {
      salesStructureFormGroup.id = value.id;
    }
    return this.formBuilder.group(salesStructureFormGroup);
  }

  get salesStructures(): FormArray {
    return this.customerForm.get('salesStructures') as FormArray;
  }

  public addItem(): void {
    const salesStructureFormGroup: FormGroup =
      this.createSalesStructureFormGroup();
    this.configSalesStructureFormGroup(
      salesStructureFormGroup,
      this.salesStructures
    );
  }

  public removeItem(i: number): void {
    this.salesStructures.removeAt(i);
  }

  checkRepeat(): boolean {
    const sales = this.customerForm.get('salesStructures') as FormArray;
    const uniqueObject = sales.value;
    const set = new Set(
      uniqueObject
        .map((item: any) => ({
          channelId: item.channelId,
          customerShort: item.customerShort,
          orderAdminId: item.orderAdminId,
          salesManagerId: item.salesManagerId,
          taxes: item.taxes,
        }))
        .map(JSON.stringify)
    );
    const hasDuplicates = set.size < uniqueObject.length;

    if (hasDuplicates) {
      this.toastr.warning('Has sales duplicates', 'Sales Structure');
      return false;
    }
    return true;
  }

  checkValid() {
    const sales = this.salesStructures.controls;
    sales.forEach((value: any) => {
      if (value.controls.channelId.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Channel');
      }
      if (value.controls.orderAdminId.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Order Admin');
      }
      if (value.controls.salesManagerId.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Sales Manager');
      }
      if (value.controls.taxes.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Taxes Application');
      }
      if (value.controls.customerShort.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Customer Short');
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.filteredOptionsOrderAdmin =
      this.customerForm.controls.salesStructures.valueChanges.pipe(
        startWith(''),
        map(value => this.filterOptions(value))
      );
  }

  private filterOptions(value: string): any[] {
    const filterValue = value ? value.toLocaleUpperCase() : '';
    return this.orderAdminEntities.filter((option: any) =>
      option.geo.toLocaleUpperCase().includes(filterValue)
    );
  }

  loadData() {
    this.getCustomersWithSalesStructures(this.pagination || undefined);
    this.getDataFilter();
  }

  getCustomersWithSalesStructures(page?: any, params?: any) {
    this.spinner.show();
    this.custumerCrudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        this.updateComponents(response);
        this.spinner.hide();
      });
  }

  getDataFilter() {
    const geosFilter: any[] = [];
    const countriesFilter: any[] = [];
    const allStatus: any[] = [];
    const customerNameFilter: any[] = [];
    const customerShortFilter: any[] = [];
    const salesManagerFilter: any[] = [];
    const orderAdminsFilter: any[] = [];
    this.spinner.show();

    this.custumerCrudService
      .getEntity('geo', { sort: 'geo', pageName: 'customer-management' })
      .subscribe((response: any) => {
        const filter = { geo: false };
        this.geoEntities = response;
        filter.geo = true;
        response.forEach((item: Geo) => {
          geosFilter.push({ value: item.geo, id: item.id });
        });
      });

    this.custumerCrudService
      .getEntity('country', {
        sort: 'country',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.countryEntities = response;
        response.forEach((item: Country) => {
          countriesFilter.push({ value: item.country, id: item.id });
        });
      });

    this.custumerCrudService
      .getEntity('customer', { sort: 'customerName' })
      .subscribe((response: any) => {
        this.customerNameEntities = response;
        response.forEach((item: Customer) => {
          customerNameFilter.push({ value: item.customerName, id: item.id });
        });
      });

    this.custumerCrudService
      .getEntity('customer', { sort: 'customerShort' })
      .subscribe((response: any) => {
        const sales = response;
        sales.forEach((item: any) => {
          if (item.salesStructures.length > 0) {
            item.salesStructures.forEach((salesStructures: any) => {
              if (
                customerShortFilter.findIndex(
                  obj => obj.value === salesStructures.customerShort
                ) === -1
              ) {
                customerShortFilter.push({
                  value: salesStructures.customerShort,
                  id: salesStructures.id,
                });
              }
            });
          }
        });
        customerShortFilter.sort((current: any, next: any) =>
          current.value.localeCompare(next.value)
        );
      });

    this.custumerCrudService
      .getEntity('user', {
        sales_manager: true,
        status: true,
        sort: 'last_name',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.salesManagerEntities = response;
        response.forEach((item: User) => {
          const viewName: string = `${item.last_name}, ${item.name} (${item.email})`;
          if (
            salesManagerFilter.findIndex(obj => obj.value === viewName) === -1
          ) {
            salesManagerFilter.push({
              value: `${item.last_name}, ${item.name} (${item.email})`,
              id: item.id,
            });
          }
        });
      });

    this.custumerCrudService
      .getEntity('user', {
        status: true,
        order_admin: true,
        sort: 'last_name',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.orderAdminEntities = response;
        response.forEach((item: User) => {
          const viewName: string = `${item.last_name}, ${item.name} (${item.email})`;
          if (
            orderAdminsFilter.findIndex(
              obj =>
                obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
            ) === -1
          ) {
            orderAdminsFilter.push({ value: viewName, id: item.id });
          }
        });
      });

    this.custumerCrudService
      .getEntity('user', {
        status: true,
        sales_manager: true,
        sort: 'last_name',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.salesManagerEntities = response;
      });

    this.custumerCrudService
      .getEntity('user', {
        status: true,
        order_admin: true,
        sort: 'last_name',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.orderAdminEntities = response;
      });

    this.custumerCrudService
      .getEntity('channel', {
        sort: 'channel',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.channelEntities = response;
      });

    this.custumerCrudService
      .getEntity('taxesApplicable', {
        sort: 'tax',
        pageName: 'customer-management',
      })
      .subscribe((response: any) => {
        this.taxesApplicableEntities = response;
      });

    allStatus.push({ value: 'Active' });
    allStatus.push({ value: 'Inactive' });

    this.searchInputs = this.createSearchInputs();
    this.searchInputs[0].options = geosFilter;
    this.searchInputs[1].options = countriesFilter;
    this.searchInputs[2].options = allStatus;
    this.searchInputs[3].options = customerNameFilter;
    this.searchInputs[4].options = customerShortFilter;
    this.searchInputs[5].options = salesManagerFilter;
    this.searchInputs[6].options = orderAdminsFilter;
  }

  updateComponents(responseCustomer: any) {
    this.result = {
      cards: this.dataCards(JSON.stringify(responseCustomer.rows)),
      table: {
        columns: [
          {
            label: 'geo',
            sticky: true,
            style: { width: '15%', 'box-sizing': 'border-box' },
          },
          {
            label: 'country',
            sticky: true,
            style: { width: '15%', 'box-sizing': 'border-box' },
          },
          {
            label: 'customer name',
            prop: 'customerName',
            sticky: true,
            style: { width: '45%' },
          },
          {
            label: 'customer number',
            prop: 'customerNumber',
            sticky: true,
            style: { width: '15%', 'box-sizing': 'border-box' },
          },
          {
            label: 'status',
            sticky: true,
            style: { width: '5%', 'box-sizing': 'border-box' },
          },
        ],
        rows: new MatTableDataSource<Customer>(
          this.dataTables(JSON.stringify(responseCustomer.rows))
        ),
      },
      pageIndex: responseCustomer.page,
      pageSize: responseCustomer.totalRows,
      pagesTotal: responseCustomer.totalPages,
    };
    const actions = this.permissionService.setTablePermissions(
      'customer-management'
    );
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataMapToTable(data: any): Customer[] {
    return JSON.parse(data).map((item: any) => ({
      id: item.id,
      geo: item.country.geos.geo,
      customerNumber: item.customerNumber,
      customerName:
        item.salesStructures.length > 0
          ? item.salesStructures
          : item.customerName,
      country: item.country.country,
      status: item.status ? 'Active' : 'Inactive',
    }));
  }

  dataTables(data: any) {
    const customer = this.dataMapToTable(data);
    const dataTables: any[] = [];
    customer.forEach((item: Customer) => {
      dataTables.push(item);
    });

    return [
      ...dataTables.sort((current: any, next: any) =>
        current.geo.localeCompare(next.geo)
      ),
    ];
  }

  dataCards(data: any) {
    const dataCards: CardInterface[] = [];
    const actionCards = this.permissionService.setActionsCardsPermission(
      'customer-management'
    );
    JSON.parse(data).forEach((item: Customer) => {
      dataCards.push({
        id: item.id,
        title: item.customerName,
        subtitle: item.customerNumber,
        iconEdit: { visible: actionCards.iconEdit, enabled: true },
        iconDelete: { visible: actionCards.iconDelete, enabled: true },
        attributes: [
          {
            key: 'GEO',
            value: [item.country.geos.geo],
            isTag: true,
          },
          {
            key: 'Country',
            value: [item.country.country],
            isTag: true,
          },
          {
            key: 'Customer Short',
            value: item.salesStructures.map(
              (sales: any) => sales.customerShort
            ),
            isTag: true,
          },
        ],
      });
    });
    return dataCards.sort((current: any, next: any) =>
      current.title.localeCompare(next.title)
    );
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getCustomersWithSalesStructures(paginated, this.filter);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.prepareToEdit(event.item.id);
    } else {
      this.onDelete(event.item.id);
    }
  }

  prepareToEdit(id: number) {
    this.spinner.show();
    this.isEdit = true;
    this.buttonSaveDisabled = false;
    this.salesManagerEntities = [];
    this.custumerCrudService.fetchEntity(id).subscribe((customer: any) => {
      this.customerForm.controls.id.setValue(customer.id);
      this.customerForm.controls.geo.setValue(customer.country.geos.geo);
      this.customerForm.controls.country.setValue(customer.countryId);
      this.customerForm.controls.customerNumber.setValue(
        customer.customerNumber
      );
      this.customerForm.controls.customerName.setValue(customer.customerName);
      this.customerForm.controls.status.setValue(
        customer.status ? 'Active' : 'Inactive'
      );
      const salesStructures = this.customerForm.get(
        'salesStructures'
      ) as FormArray;

      while (salesStructures.value.length !== 0) {
        salesStructures.removeAt(0);
      }
      if (customer.salesStructures.length > 0) {
        customer.salesStructures.forEach((value: any) => {
          this.userCrudService
            .fetchEntity(value.orderAdminId)
            .subscribe((orderAdmin: any) => {
              const salesStructureFormGroup: FormGroup =
                this.createSalesStructureFormGroup(value);

              this.salesManagerEntities.push({
                id: salesStructureFormGroup.controls.id.value,
                values: this.getSalesManagersFromOrderAdmin(orderAdmin),
              });
              this.configSalesStructureFormGroup(
                salesStructureFormGroup,
                salesStructures
              );
            });
        });
      } else {
        this.addItem();
      }
      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.customerForm.reset();
      });
      this.spinner.hide();
    });
  }

  onUpdate() {
    this.isEdit = true;
    this.checkValid();

    if (this.salesStructures.valid && this.checkRepeat()) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          const salesStructures =
            this.customerForm.controls.salesStructures.value.map(
              (value: any) => {
                const itemSalesStructure: any = {
                  orderAdminId: value.orderAdminId,
                  salesManagerId: value.salesManagerId,
                  customerShort: value.customerShort.trim(),
                  channelId: value.channelId,
                  status:
                    this.customerForm.controls.status.value === STATUS.active,
                  countryId: this.customerForm.controls.country.value,
                  customerNumber:
                    this.customerForm.controls.customerNumber.value,
                  customerPoLineNumber: value.customerPoLineNumber.trim(),
                  customerName: this.customerForm.controls.customerName.value,
                  taxes: value.taxes.map((tax: any) => ({ id: tax })),
                };
                if (value.id !== '') {
                  itemSalesStructure.id = value.id;
                }
                return itemSalesStructure;
              }
            );

          const customerAll: any = {
            parentId: this.customerForm.controls.id.value,
            salesStructures,
          };
          this.custumerCrudService
            .updateEntity(this.customerForm.controls.id.value, customerAll)
            .subscribe(
              () => {
                this.toastr.success(
                  `${this.translatorService.getMessage(
                    'CRUD_SUCCESS_UPDATED'
                  )}`,
                  `${this.translatorService.getTitle(
                    'CRUD_SUCCESS_UPDATED'
                  )} Association`
                );
                this.loadData();
                this.modalService.closeAll();
              },
              (err: any) => {
                this.toastr.error(err.error.message, err.error.title);
                this.spinner.hide();
              }
            );
        }
      });
    }
  }

  onDelete(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.custumerCrudService.deleteEntity(id).subscribe(
          (data: any) => {
            this.toastr.success(
              `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
              `${this.translatorService.getTitle(
                'CRUD_SUCCESS_REMOVED'
              )} Association`
            );
            this.getCustomersWithSalesStructures();
          },
          (err: any) => {
            this.toastr.error(err.error.message, 'Error!');
            this.spinner.hide();
          }
        );
      }
    });
  }

  onSearchEvent(value: any) {
    if (value.geo) {
      const currentGeo = this.geoEntities.filter((item: Geo) =>
        item.geo.toLocaleUpperCase().includes(value.geo.toLocaleUpperCase())
      );
      value.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      value.geoId = '';
    }
    delete value.geo;

    if (value.countryId) {
      const currentCountry = this.countryEntities.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(value.countryId.toLocaleUpperCase())
      );
      value.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      value.countryId = '';
    }

    if (value.status === STATUS.active) {
      value.status = true;
    } else if (value.status === STATUS.inactive) {
      value.status = false;
    } else {
      value.status = '';
    }
    value.customerName = value.customerName ? value.customerName : '';
    value.customerShort = value.customerShort ? value.customerShort : '';

    if (value.salesManagerId) {
      const currentSalesManager = this.salesManagerEntities.filter(
        (item: any) =>
          value.salesManagerId ===
          `${item.last_name}, ${item.name} (${item.email})`
      );
      value.salesManagerId = currentSalesManager[0]
        ? currentSalesManager[0].id
        : 0;
    } else {
      value.salesManagerId = '';
    }

    if (value.orderAdminId) {
      const currentOrderAdmin = this.orderAdminEntities.filter(
        (item: any) =>
          value.orderAdminId ===
          `${item.last_name}, ${item.name} (${item.email})`
      );
      value.orderAdminId = currentOrderAdmin[0] ? currentOrderAdmin[0].id : 0;
    } else {
      value.orderAdminId = '';
    }

    this.pagination.page = 0;
    this.filter = { ...value, sort: 'customerName' };
    this.getCustomersWithSalesStructures(this.pagination, this.filter);
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

  getSalesManagersFromOrderAdmin(orderAdmin: any) {
    const result: any = [];

    if (orderAdmin.salesManagers.length > 0) {
      const partial = orderAdmin.salesManagers;
      partial.forEach((value: any) => {
        result.push({
          id: value.salesManager.id,
          lastName: value.salesManager.last_name,
          name: value.salesManager.name,
          email: value.salesManager.email,
        });
      });
    } else {
      this.toastr.warning(
        'There is no sales manager associated with this order admin',
        'Sales manager'
      );
    }
    return result;
  }

  generateRandomIdToSalesStructure() {
    const salesStructures = this.customerForm.get(
      'salesStructures'
    ) as FormArray;
    const currentIds = salesStructures.controls.map(
      (formGroup: any) => formGroup.controls.id.value
    );

    const randomId = (randId: number) => {
      do {
        randId = Math.floor(Math.random() * 9999);
      } while (currentIds.includes(randId));
      return randId;
    };

    return randomId(0);
  }

  updateInputSalesManager(orderAdmin: any, salesStructureFormGroup: FormGroup) {
    const result = this.getSalesManagersFromOrderAdmin(orderAdmin);

    if (
      this.salesManagerEntities.findIndex(
        obj => obj.id === salesStructureFormGroup.controls.id?.value
      ) === -1
    ) {
      this.salesManagerEntities.push({
        id: salesStructureFormGroup.controls.id.value,
        values: result,
      });
    } else {
      this.salesManagerEntities.forEach(obj => {
        if (obj.id === salesStructureFormGroup.controls.id.value) {
          obj.values = result;
        }
      });
    }
  }

  configSalesStructureFormGroup(
    salesStructureFormGroup: FormGroup,
    salesStructures: FormArray
  ) {
    salesStructureFormGroup.controls.orderAdminId.valueChanges.subscribe(
      (valueChange: any) => {
        if (valueChange) {
          this.spinner.show();
          this.userCrudService
            .fetchEntity(valueChange)
            .subscribe((orderAdmin: any) => {
              this.updateInputSalesManager(orderAdmin, salesStructureFormGroup);
              this.spinner.hide();
            });
        }
      }
    );
    salesStructures.push(salesStructureFormGroup);
  }

  validateNoOlynSpaces(control: FormControl): { whitespace: boolean } | null {
    const valueNoWhiteSpace = control.value?.trim();
    const isValid = valueNoWhiteSpace?.length > 0;
    return isValid ? null : { whitespace: true };
  }
}
