<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">GEO</span>
    <button *ngIf="hasPermissionCreate" class="button modal-action-button" mat-button (click)="prepareToCreate()">NEW GEO</button>
  </div>
  <app-search (searchEvent)="onSearchEvent($event)" [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results [data]="result" (eventActions)="onEventActions($event)" (resultPaginator)="onChangePaginator($event)">
  </app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">BACK</button>
</div>
<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="geoForm">
    <header class="title-modal" matDialogTitle>{{isEdit ? 'EDIT GEO': 'NEW GEO'}}</header>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>GEO</mat-label>
        <input matInput placeholder="GEO" formControlName="geo" autocomplete="off" maxlength="20"
          appInputAlphanumeric />
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>CANCEL</button>
      <button *ngIf="!isEdit" class="button modal-action-button" mat-button (click)="onCreate()">SAVE</button>
      <button *ngIf="isEdit" class="button modal-action-button" mat-button (click)="onUpdate()">SAVE</button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    {{isEdit ? 'Do you confirm to edit GEO?': 'Do you confirm to remove GEO?'}}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>NO</button>
    <button class="button modal-action-button" mat-button matDialogClose="false">YES</button>
  </mat-dialog-actions>
</ng-template>
