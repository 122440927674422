<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">ORDER ADMIN MANAGEMENT</span>
  </div>
  <app-search (searchEvent)="onSearchEvent($event)" [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results [data]="result" (eventActions)="onEventActions($event)" (resultPaginator)="onChangePaginator($event)">
  </app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">BACK</button>
</div>
<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="userForm" class="form">
    <header class="title-modal" matDialogTitle>EDIT ASSOCIATION ORDER ADMIN</header>
    <mat-dialog-content>
      <mat-form-field appearance="outline" [class.disabled]="isEdit">
        <mat-label> Order Admin </mat-label>
        <mat-select formControlName="order_admin_user_id" name="order_admin_user_id" [disabled]="isEdit">
          <mat-select-trigger>
            {{ fullNameOrderAdmin }} <span class="add-info-select">({{ emailOrderAdmin }})</span>
          </mat-select-trigger>
          <mat-option *ngFor="let orderAdmin of orderAdminsEntity" [value]="orderAdmin.id">
            {{ orderAdmin.last_name }}, {{ orderAdmin.name }} <span class="additional-selection">({{
              orderAdmin.email}})</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Sales Manager</mat-label>
        <mat-select formControlName="sales_manager_user" name="sales_manager_user" multiple disableOptionCentering
          panelClass="panel-select-multiple" (selectionChange)="onSelectSalesManager(this.userForm)">
          <mat-select-trigger>
            {{ userForm.value['sales_manager_user'] ? nameFirstSelectedSalesManager : '' }}
            <span *ngIf="userForm.value['sales_manager_user']?.length > 1" class="additional-selection">
              (+{{userForm.value['sales_manager_user'].length - 1}} {{userForm.value['sales_manager_user']?.length === 2
              ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let salesManager of salesManagerEntitiesByCountry" [value]="salesManager.id"
            matTooltip="{{ salesManager.last_name }}, {{ salesManager.name }} ({{ salesManager.email }})">
            {{ salesManager.last_name }}, {{ salesManager.name }} <span class="add-info-select">({{ salesManager.email
              }})</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.controls.sales_manager_user.hasError('required')"> {{messageError }}</mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>CANCEL</button>
      <button class="button modal-action-button" [disabled]="buttonSaveDisabled" mat-button
        (click)="onUpdate()">SAVE</button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    {{isEdit ? 'Do you confirm to edit this association?': 'Are you sure you want to delete this association?'}}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>NO</button>
    <button class="button modal-action-button" mat-button matDialogClose="false">YES</button>
  </mat-dialog-actions>
</ng-template>
