import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialsComponent } from './materials.component';



@NgModule({
  declarations: [
    MaterialsComponent
  ],
  imports: [
    BrowserModule,
    ShareComponentsModule,
    ShareDirectiviesModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [MaterialsComponent]

})
export class MaterialsModule { }
