import { Injectable } from '@angular/core';

export interface TranslatorInterface {
  key?: string;
  title?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {
  text: Array<TranslatorInterface> = [
    { key: 'CRUD_SUCCESS', title: 'New', message: 'Registered successfully' },
    { key: 'CRUD_SUCCESS_UPDATED', title: 'Updated', message: 'Successfully!' },
    { key: 'CRUD_SUCCESS_REMOVED', title: '', message: 'Successfully removed!' },
    { key: 'CRUD_FIELD_EMPTY', title: '', message: 'Mandatory field' },
    { key: 'CRUD_FIELD_MAX_LENGTH', title: '', message: 'The maximum field length must be' },
    { key: 'CRUD_DUPLICATED', title: 'already exists', message: 'Please inform another description!' },
    { key: 'CRUD_SPECIAL_CHARACTERS', title: 'Invalid', message: 'Special characters are not valid!' },
    { key: 'CRUD_FIELD_MIN_LENGTH', title: '', message: 'The min field length must be' },
    { key: 'CRUD_GEO_COUNTRY_ASSOCIATION', title: 'field associated', message: 'Please remove it before delete to complete this operation!' },
    { key: 'CRUD_DATA_NOT_FOUND', title: '', message: 'Data not found' },
    { key: 'CRUD_EMAIL_INVALID', title: 'Invalid e-mail address', message: 'Please check if the domain is @motorola.com or @lenovo.com!' },
    { key: 'CRUD_NO_ALLOWED_UPDATED_SALES_MANAGER', title: 'Update not allowed', message: 'This user is associated with some Sales Manager. Please, remove this association to complete this action!' },
    { key: 'CRUD_NO_ALLOWED_UPDATED_ORDER_ADMIN', title: 'Update not allowed', message: 'This user is associated with some Order Admin. Please, remove this association to complete this action!' },
    { key: 'CRUD_NO_ALLOWED_DELETE_ORDER_ADMIN_WITH_ASSOCIATION_SALES_STRUCTURES', title: 'Delete not allowed', message: 'These sales managers are associated with a sales structure of some customer. Please, remove this association to complete this action!' },
    { key: 'CRUD_NO_ALLOWED_UPDATE_ORDER_ADMIN_WITH_ASSOCIATION_SALES_STRUCTURES', title: 'Update not allowed', message: 'Some sales managers are associated with a sales structure of some customer. Please, remove this association to complete this action!' },
  ];

  getTitle(key: string) {
    const value: any = this.text.find((item) => item.key === key);
    return value?.title;
  }

  getMessage(key: string) {
    const value: any = this.text.find((s) => s.key === key);
    return value?.message;
  }

  getTitleAndMessage(key: string) {
    return this.text.find((s) => s.key === key);
  }
}
