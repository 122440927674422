<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="header-title">
    <span class="title">KA ALLOCATION</span>
    <div class="container-export">
      <span
        *ngIf="
          (mlsLastUpdateVisible &&
            selectedTabIndex === indexAllocationLastUpdate) ||
          selectedTabIndex === 2
        "
        class="mls-last-update"
        >One Plan Last Update: {{ mlsLastUpdate }}</span
      >

      <span
        *ngIf="mlsLastUpdateVisible && selectedTabIndex === 1"
        class="mls-last-update"
        >One Plan Last Update: {{ mlsLastUpdate }}
      </span>

      <div>
        <span
          *ngIf="selectedTabIndex !== 1 && selectedTabIndex !== 2"
          class="material-icons"
          (click)="toUpload()"
          matTooltip="{{ tooltipUpload }}">
          <img
            style="border-radius: 4px"
            src="../../../assets/images/BTN-upload.svg"
            alt="upload" />
        </span>

        <span
          *ngIf="selectedTabIndex !== 1 && selectedTabIndex !== 2"
          class="material-icons"
          (click)="exportTable(selectedTabIndex)"
          matTooltip="{{ tooltipMessage }}">
          <img src="../../../assets/images/BTN-Export.svg" alt="download" />
        </span>
      </div>
    </div>
  </div>

  <div class="ka-allocation-content">
    <app-tab
      [tabs]="tabs"
      [method]="this"
      [messagesDialog]="messages"
      [isEditTableMode]="isEditTableMode"
      (activeTabIndexChange)="onActiveTabIndexChange($event)"
      #TabComponent>
    </app-tab>

    <ng-template #Allocation class="ka-allocation-content">
      <app-search
        (searchEvent)="onSearchEvent($event, 'allocation')"
        (applyFilterEvent)="applyFilter('allocation')"
        [filters]="searchInputs"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="false">
      </app-search>
      <mat-divider></mat-divider>
      <section class="general-informations">
        <div>
          <section class="title-section flex" *ngIf="showGeneralInformations">
            <h3 class="t-section">General Informations</h3>
            <mat-icon
              *ngIf="showGeneralInformationsArrow; else elseBlock"
              class="arrow-hide-show"
              (click)="toggleContentVisibility()">
              arrow_drop_up
            </mat-icon>
            <ng-template #elseBlock>
              <mat-icon
                class="arrow-hide-show"
                (click)="toggleContentVisibility()">
                arrow_drop_down
              </mat-icon>
            </ng-template>
          </section>
          <div
            [@hideShowGeneralInformations]="
              showGeneralInformationsArrow ? 'show' : 'hide'
            ">
            <app-card-color [cardsColor]="cardsColor"></app-card-color>
          </div>
        </div>
        <div *ngIf="showGeneralInformations">
          <a class="next" (click)="nextQuarter(next)"
            >{{ next ? 'NEXT' : 'PREVIOUS' }} QUARTER</a
          >
        </div>
      </section>
      <div
        class="no-filter-selected-session"
        *ngIf="noFilterSelected; else blockFilterSelected">
        <p>PLEASE,<br />START BY FILTERING GEO, COUNTRY AND PRODUCT FIRST</p>
      </div>
      <ng-template #blockFilterSelected>
        <app-table-card
          #tableCard
          *ngIf="!showNoResults; else blockNoResult"
          [dataSource]="loadedDataProducts"
          [allocationForms]="[allocationForm]"
          (inputChanged)="inputChanged($event)"
          class="allocation">
        </app-table-card>
        <ng-template #blockNoResult>
          <div class="not-found">
            <p class="no-result-session">
              Record not found. Please try another filters selection!
            </p>
            <a class="next" (click)="nextQuarter(next)"
              >{{ next ? 'NEXT' : 'PREVIOUS' }} QUARTER</a
            >
          </div>
        </ng-template>
      </ng-template>

      <section class="section-fair-distribution" *ngIf="showFairDistribution">
        <div class="title-section">
          <h3 class="t-section">Fair Distribution</h3>
        </div>
        <app-card-color [cardsColor]="cardsFairDistribution"></app-card-color>
      </section>
    </ng-template>

    <ng-template #DealVsAllocation>
      <app-search
        (searchEvent)="onSearchEvent($event, 'dealAllocation')"
        (applyFilterEvent)="applyFilter('dealAllocation')"
        [filters]="searchInputsDealAllocation"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="false">
      </app-search>
      <mat-divider></mat-divider>

      <div class="layout-screen">
        <div class="left">
          <div class="layout-cards">
            <app-card-allocation
              [cardsInformation]="cards"
              *ngIf="
                !showNoResultsDealVsAllocation;
                else blockNoResult
              "></app-card-allocation>
          </div>
          <div class="layout-bar-chart">
            <app-stacked-column
              [dataChart]="barChart"
              *ngIf="!showNoResultsDealVsAllocation"></app-stacked-column>
          </div>
        </div>
        <div class="right">
          <div class="layout-line-chart">
            <app-line-marker-chart
              [dataSource]="lineChart"
              *ngIf="!showNoResultsDealVsAllocation"></app-line-marker-chart>
          </div>
        </div>
      </div>
      <ng-template #blockNoResult>
        <div class="not-found">
          <p class="no-result-session">
            Record not found. Please try another filters selection!
          </p>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #DemandVsMls>
      <app-search
        (searchEvent)="onSearchEvent($event, 'demandVsMls')"
        (applyFilterEvent)="applyFilter('demandVsMls')"
        [filters]="searchInputsDemandVsMls"
        [showButtonApply]="true"
        [enableApplyFilter]="enableApplyFilter"
        [showMoreFiltersLink]="true"
        [showMoreFiltersLinkWithMinWidth]="true">
      </app-search>
      <mat-divider></mat-divider>
      <app-column-marker-chart
        [dataColumn]="dataChartDemandVsMls"
        *ngIf="
          !showNoResultsDemandVsMls;
          else blockNoResult
        "></app-column-marker-chart>
      <ng-template #blockNoResult>
        <div class="not-found">
          <p class="no-result-session">
            Record not found. Please try another filters selection!
          </p>
        </div>
      </ng-template>
    </ng-template>
  </div>

  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
