import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class ModalActionsService {
  constructor(public matDialog: MatDialog, public overlay: Overlay) { }

  createModal(modal:any, data?: any) {
    return this.matDialog.open(modal, {
      minWidth: data ? data.width : '50%',
      minHeight: data ? data.height : '60%',
      id: 'myModal',
      autoFocus: false,
      disableClose: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
  }

  destroyModal(id:string) {
    this.matDialog.getDialogById(id)?.close();
  }

  closeAll() {
    this.matDialog.closeAll();
  }

  createConfirm(confirm: any) {
    return this.matDialog.open(confirm, {
      minWidth: '341px',
      minHeight: '109px',
      id: 'myDialog',
      autoFocus: false,
      disableClose: true,
      restoreFocus: false,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
  }
}
