import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) return value;
    if (value === 'NaN') return '0';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
