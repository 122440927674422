import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { CrudService } from 'src/app/services/generic/crud.service';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { tableExecutiveOverviewMockup } from './mockup-wow-analysis';
import { MatTableDataSource } from '@angular/material/table';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { ExcelService } from 'src/app/services/generic/excel.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-wow-analysis',
  templateUrl: './wow-analysis.component.html',
  styleUrls: ['./wow-analysis.component.scss'],
})
export class WowAnalysisComponent implements OnInit {
  searchInputsWowVariation: InputBase<string>[];
  tooltipMessage: String;
  exportResult!: ResultInterface;
  resultExport: any;

  tableMockup = tableExecutiveOverviewMockup;

  pullInOrIncreaseValues: { label: string; mode: boolean; value: number }[];

  territoryEntity: any[];
  productFilterEntity: any[];

  territories: any[];

  formValue!: { [key: string]: any };

  territoryFilter: any[];
  productFilter: any[];
  fiscalYearFilter: any[];
  filter: any;
  currentQuarter!: string;
  result: any;
  isModeTableOrCardsHide: boolean;
  hidePagination: boolean;
  isValid: boolean;

  isDiscardChanges: boolean;

  currentWeekLabel!: string;

  next: boolean;
  isEditTableMode: boolean;

  filterMonths: any[];

  noResultWowAnalysis: boolean;

  crudService: CrudService<any>;
  entities: Array<getEntities>;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.tooltipMessage = '';

    this.pullInOrIncreaseValues = [];

    this.territoryEntity = [];
    this.productFilterEntity = [];

    this.territories = ['ALL'];

    this.territoryFilter = [];
    this.productFilter = [];
    this.fiscalYearFilter = [];

    this.filter = {
      fiscal_quarter: this.getQuarterFromDate(new Date()),
    };

    this.isModeTableOrCardsHide = true;
    this.hidePagination = true;
    this.isValid = false;

    this.isDiscardChanges = false;

    this.next = false;
    this.isEditTableMode = false;

    this.filterMonths = [];

    this.noResultWowAnalysis = false;

    this.entities = [
      {
        entity: 'location',
        query: {
          status: true,
          sort: 'territory',
          pageName: PAGE_NAME_RESOURCE.wowAnalysis,
        },
      },
      {
        entity: 'emea-allocation/summary/product',
        query: { pageName: PAGE_NAME_RESOURCE.wowAnalysis },
      },
    ];

    this.crudService = new CrudService<any>(this.http, 'emea-wow-analysis');
    this.searchInputsWowVariation = [
      new MultiSelectSearchInput({
        key: 'location_id',
        hover: 'Territory',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'product_group',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'fiscal_year',
        hover: 'Fiscal Year',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];

    this.currentQuarter = this.getQuarterFromDate(new Date());
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinner.show();
    this.tooltipMessage = 'Export';
    this.getDataFilter();

    this.crudService
      .getEntity('setupManagement/currentSetup', {
        pageName: PAGE_NAME_RESOURCE.wowAnalysis,
      })
      .subscribe((response: any) => {
        let currentWeek = response.currentWeek.toUpperCase();
        if (currentWeek.length > 6) {
          let curreentSplitWeek = currentWeek.substring(0, 6);
          this.currentWeekLabel = `${curreentSplitWeek.substring(
            0,
            1
          )}${curreentSplitWeek.substring(3)}`;
        } else {
          this.currentWeekLabel = `${currentWeek.substring(
            0,
            1
          )}${currentWeek.substring(3)}`;
        }
      });
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  dataFilterTerritory(data: any) {
    data.forEach((item: any) => {
      this.territoryFilter.push({ value: item.territory, id: item.id });
    });

    const selectTerritory = new MultiSelectSearchInput({
      key: 'location_id',
      hover: 'Territory',
      type: 'text',
      hasAutocomplete: true,
      classCss: 'filter-territory',
      options: this.territoryFilter,
    });

    this.searchInputsWowVariation[0] = selectTerritory;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];
  }

  dataFilterProduct(data: any) {
    data.forEach((item: any) => {
      this.productFilter.push({ value: item.product_group });
    });

    const selectProduct = new MultiSelectSearchInput({
      key: 'product_group',
      hover: 'Product',
      type: 'text',
      hasAutocomplete: true,
      options: this.productFilter,
      classCss: 'filter-ppm-wow-analysis',
    });

    this.searchInputsWowVariation[1] = selectProduct;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];
  }

  dataFilterFiscalYear() {
    const currentYear = this.checkCurrentYearAndQuarter();
    const initialValueFiscalYearFilter: { [key: string]: any } = {};

    for (let year = currentYear, i = 0; year <= currentYear; year++, i++) {
      this.fiscalYearFilter.push({
        key: i,
        value: `${year}`,
      });
    }

    initialValueFiscalYearFilter.value = this.fiscalYearFilter[0].value;

    const selectFiscalYear = new SelectSearchInput({
      key: 'fiscal_year',
      hover: 'Fiscal Year',
      type: 'text',
      hasAutocomplete: true,
      options: this.fiscalYearFilter,
      value: initialValueFiscalYearFilter.value,
      notClearable: true,
    });

    this.searchInputsWowVariation[2] = selectFiscalYear;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];

    if (initialValueFiscalYearFilter.value) {
      const fiscalYear = { fiscal_year: initialValueFiscalYearFilter.value };
      this.filter = { ...this.filter, ...fiscalYear };

      const dataTable = this.getDataTable(this.filter);
      this.hideSpinnerAfterAll([dataTable]);
    }
  }

  dataFilterQuarter() {
    let quarterFilter: any[] = [];

    quarterFilter = [
      { key: 'FQ1', value: 'FYQ1 ' },
      { key: 'FQ2', value: 'FYQ2 ' },
      { key: 'FQ3', value: 'FYQ3 ' },
      { key: 'FQ4', value: 'FYQ4 ' },
    ];

    const selectQuarter = new SelectSearchInput({
      key: 'fiscal_quarter',
      hover: 'Quarter',
      type: 'text',
      hasAutocomplete: true,
      options: quarterFilter,
      value: this.currentQuarter,
    });

    this.searchInputsWowVariation[3] = selectQuarter;
    this.searchInputsWowVariation = [...this.searchInputsWowVariation];
  }

  async getDataFilter(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.spinner.show();

      this.crudService.getDataFilters(this.entities).subscribe(
        (response: any[]) => {
          [this.territoryEntity, this.productFilterEntity] = response;

          this.dataFilterTerritory(this.territoryEntity);
          this.dataFilterProduct(this.productFilterEntity);
          this.dataFilterFiscalYear();
          // this.dataFilterQuarter();

          resolve();
        },
        (err: any) => {
          this.toastr.error(err.error.message, 'Error!');
          this.spinner.hide();
          reject();
        }
      );
    });
  }

  getDataTable(params?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService
        .getEntity(
          `emea-wow-analysis?pageName=${PAGE_NAME_RESOURCE.wowAnalysis}`,
          params
        )
        .subscribe((response: any) => {
          if (response?.table.rows.length > 0) {
            this.result = {
              table: {
                columns: response.table.columns,
                rows: new MatTableDataSource<any>(response.table.rows),
                type: response.table.dataType,
              },
              pageIndex: response.table.page,
              pageSize: response.table.totalRows === 0 ? 0 : '',
              pagesTotal: response.table.totalPages,
            };
            this.exportComponents(this.result);
            this.noResultWowAnalysis = false;
            this.isValid = false;
            resolve();
          } else {
            this.noResultWowAnalysis = true;
            this.isValid = true;
            resolve();
          }
        });
    });
  }

  nextQuarter(next: boolean) {
    this.spinner.show();
    this.next = !this.next;
    this.getQuarter(new Date());
    this.filter.fiscal_year = this.checkQuarterAndYear();
    this.filter.fiscal_quarter = this.getQuarterFromDate(new Date());
    const dataTable = this.getDataTable(this.filter);
    this.hideSpinnerAfterAll([dataTable]);
  }

  getQuarter(date: Date) {
    let month = date.getMonth() + 1;
    if (this.next) month = date.getMonth() + 4;
    this.filter.fiscal_quarter = this.getQuarterFromDate(new Date());

    this.filterMonths = [];

    switch (month) {
      case 4:
      case 5:
      case 6:
        this.filterMonths.push(
          { label: 'April', value: 'april' },
          { label: 'May', value: 'may' },
          { label: 'June', value: 'june' }
        );
        break;
      case 7:
      case 8:
      case 9:
        this.filterMonths.push(
          { label: 'July', value: 'july' },
          { label: 'August', value: 'august' },
          { label: 'September', value: 'september' }
        );
        break;
      case 10:
      case 11:
      case 12:
        this.filterMonths.push(
          { label: 'October', value: 'october' },
          { label: 'November', value: 'november' },
          { label: 'December', value: 'december' }
        );
        break;
      default:
        this.filterMonths.push(
          { label: 'January', value: 'january' },
          { label: 'February', value: 'february' },
          { label: 'March', value: 'march' }
        );
        break;
    }
    this.filterMonths.push({
      label: 'Quarter',
      value: 'quarter',
    });
  }

  checkQuarterAndYear() {
    if (this.next && this.filter.fiscal_quarter === 'FQ1') {
      return new Date().getFullYear();
    }
    if (!this.next && this.filter.fiscal_quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  toggleBreadcrumb(toDisable: boolean) {
    // integrar com o endpoint
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/emea-wow-analysis', {
      disable: toDisable,
    });
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  onSearchEvent(value: any) {
    this.formValue = { ...value };
    this.dataFormTerritory(this.formValue);
    this.dataFormProducts(this.formValue);
    this.dataFormFiscalYear(this.formValue);

    if (this.formValue.fiscal_year) {
      this.applyFilter();
    }
  }

  applyFilter() {
    this.spinner.show();
    const formSearchValues = { ...this.formValue };
    this.filter = { ...this.filter, ...formSearchValues };

    const dataTable = this.getDataTable(this.filter);
    this.hideSpinnerAfterAll([dataTable]);
  }

  dataFormTerritory(formValue: any) {
    if (formValue.location_id) {
      this.territories = [];

      const currentTerritory = formValue.location_id.map(
        (item: any) => item.id
      );

      if (formValue.location_id.length == 0) {
        formValue.location_id.forEach((item: any) =>
          this.territories.push(item.value)
        );
      } else if (formValue.location_id.length > 0) {
        formValue.location_id.forEach((item: any) =>
          this.territories.push(` ${item.value}`)
        );
      }

      if (this.territories.length == this.territoryFilter.length) {
        this.territories = ['ALL'];
      } else if (this.territories.length == 0) {
        this.territories = ['ALL'];
      }

      formValue.location_id = currentTerritory;
    } else {
      formValue.location_id = '';
      this.territories = ['ALL'];
    }
  }

  dataFormProducts(formValue: any) {
    if (formValue.product_group) {
      const currentProduct = formValue.product_group.map(
        (item: any) => item.value
      );

      formValue.product_group = currentProduct;
    } else {
      formValue.product_group = '';
    }
  }

  dataFormFiscalYear(formValue: any) {
    this.filter.fiscal_year = this.checkQuarterAndYear();
    formValue.fiscal_year = formValue.fiscal_year
      ? this.filter.fiscal_year
      : '';
  }

  checkCurrentYearAndQuarter() {
    if (!this.filter.fiscal_year && this.filter.fiscal_quarter === 'FQ4')
      return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getQuarterFromDate(date: Date) {
    let month = date.getMonth() + 1;
    this.next === true
      ? (month = date.getMonth() + 4)
      : (month = date.getMonth() + 1);
    if (month >= 4 && month <= 6) return 'FQ1';
    if (month >= 7 && month <= 9) return 'FQ2';
    if (month >= 10 && month <= 12) return 'FQ3';
    return 'FQ4';
  }

  exportComponents(data: any) {
    this.exportResult = {
      table: {
        columns: data.table.columns,
        rows: data.table.rows,
      },
    };
    this.resultExport = this.exportResult;
  }

  prepareExportTable(data: any) {
    const rows: any = data.table.rows.filteredData.map((row: any) => row);
    const columns = data.table.columns.map((item: any) => ({
      title: item.label,
      props: item.prop,
    }));

    const tableRows: { [key: string]: [] }[] = [];

    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: [] } = {};
      columns.forEach((column: any) => {
        if (rows[i][column.props]) {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        } else {
          tableRow[column.props] = rows[i][column.props]
            ? rows[i][column.props].value
            : '';
        }
      });
      tableRows.push(tableRow);
    }

    return { columns: columns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.resultExport);
    this.excelService.exportJsonAsExcelFile(fullJsonExport, 'wow_analysis');
  }

  backButton() {
    this.router.navigate(['home/emea-allocation']);
  }
}
