import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { lomMockupResponse } from './lom-mockup';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import { EntityItem } from 'src/app/interfaces/ka-execution-interface';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Tab } from 'src/app/interfaces/tab.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { FormsModule, NgModel } from '@angular/forms';

@Component({
  selector: 'app-lom',
  templateUrl: './lom.component.html',
  styleUrls: ['./lom.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'closed',
        style({
          height: '67px',
          overflow: 'hidden',
        })
      ),
      transition('closed <=> open', [animate('0.4s linear')]),
    ]),
  ],
})
export class LomComponent implements OnInit {
  lomMockupResponse: any;
  eventTable!: string;
  holdFlagForm: FormGroup;

  @Output('callActions') callActions = new EventEmitter();
  @ViewChild('ExecutionOverview')
  ExecutionOverview!: TemplateRef<any>;
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('modalCreateEditToBookActions', { static: true })
  modalCreateEditToBookActions: TemplateRef<any> | any;
  crudService!: CrudService<any>;
  entities: EntityItem[];

  currentQuarter!: string;
  currentYear!: number;
  responseFilters!: any[];
  searchInputs!: InputBase<string>[];
  selectedDay!: string;
  selectedWoiTarget!: number;
  formSearchLom: any;
  filter: any;

  hidePartialContent: boolean = true;
  textshowOrHideFilters: String = 'Show Filters';
  tabs: Tab[] = [];
  tabsIndex: { [key: string]: any };
  disableApply: boolean;
  event: { edit: string };

  responseTable: any;
  headerResult: any[] = [];

  modalForm: FormGroup;
  rowSelected: any;
  days: { value: string; viewValue: string }[];

  showNoResults = false;
  applyed: boolean = false;
  toBookWeeks!: string;
  currentWeekReference!: string;
  selectedDays!: any;
  quarterValue: string;
  saveActionClick: boolean = false;
  events: any;

  disabledButtonExport: boolean;

  constructor(
    private app: ElementRef,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private excelSheetsService: ExcelSheetsService,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private translatorService: TranslatorService,
    private toastr: ToastrService
  ) {
    this.crudService = new CrudService<any>(this.http, 'material/woi');
    this.lomMockupResponse = lomMockupResponse;
    this.holdFlagForm = this.formBuilder.group({
      holdFlag: ['', Validators.required],
    });
    this.event = {
      edit: 'edit',
    };
    this.entities = this.getEntities();
    this.searchInputs = this.createSearchInputs();
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.quarterValue = 'F' + this.currentQuarter;
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;
    this.tabsIndex = {
      'EXECUTION OVERVIEW': 0,
    };
    this.disableApply = true;
    this.days = [
      { value: '7 Days', viewValue: '7 Days' },
      { value: '14 Days', viewValue: '14 Days' },
      { value: '21 Days', viewValue: '21 Days' },
      { value: '28 Days', viewValue: '28 Days' },
    ];
    this.modalForm = this.formBuilder.group({
      woiDays: ['', Validators.required],
      woiTarget: new FormControl('', Validators.required),
      id: new FormControl(''),
    });
    this.filter = {
      ...this.filter,
      fiscalYear: this.currentYear,
      quarter: `F${this.currentQuarter}`,
    };
    this.disabledButtonExport = false;
  }

  ngOnInit(): void {
    this.loadData();
    // this.cdr.detectChanges();
  }

  get woiTarget() {
    return this.modalForm.get('woiTarget');
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  saveAction(event?: Event) {
    event?.preventDefault();

    if (this.modalForm.status === 'VALID') {
      this.spinner.show();
      this.modalForm.patchValue({
        id: this.rowSelected.product.materialId,
      });

      let woiDays = this.modalForm.value.woiDays.replace(/\D/g, '');
      const putData = {
        id: this.modalForm.value.id,
        woiDays: Number(woiDays),
        woiTarget: Number(this.modalForm.value.woiTarget),
      };

      this.updateData(putData);
    }
  }

  updateData(putData: any) {
    this.crudService
      .updateEntity(this.rowSelected.product.materialId, putData)
      .subscribe(
        response => {
          this.toastr.success(
            `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
            `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Channel`
          );
          this.saveActionClick = true;
          this.currencyValue(this.filter);
          this.dialog.closeAll();
          this.modalForm.reset();
        },
        error => {
          this.toastr.error(error.error.message, error.error.title);
          this.spinner.hide();
        }
      );
  }

  onWoiDaysSelectionChange(event: any) {
    const newWoiDaysValue = event.value;

    this.modalForm.patchValue({
      woiDays: newWoiDaysValue,
    });
  }

  onTargetWOIInput(event: any) {
    const input = event.target.value;
    const cleanInput = input.replace(/[^0-9.]/g, '');

    this.modalForm.patchValue({ woiTarget: cleanInput });

    if (cleanInput === '0' || cleanInput === '') {
      this.modalForm.get('woiTarget')?.setErrors({ required: true });
    } else {
      this.modalForm.get('woiTarget')?.setErrors(null);
    }
  }

  validateKeyPress(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    const charTyped = String.fromCharCode(charCode);

    if (!/[\d.]/.test(charTyped)) {
      event.preventDefault();
    }
  }

  onEventActions(event: MouseEvent, column?: string, rows?: any) {
    this.modalForm.reset();
    if (event.type === this.event.edit) {
    }
    this.saveActionClick = false;
    this.rowSelected = rows;
    this.selectedDay = rows.woi.woiDays ? `${rows.woi.woiDays} Days` : '';
    this.selectedWoiTarget =
      rows.woiTarget.value !== '-' ? rows.woiTarget.value : '';

    this.modalForm.patchValue({ woiTarget: this.selectedWoiTarget });
    this.modalForm.patchValue({
      woiDays: this.selectedDay,
    });

    if (column === 'actions') {
      const dialogRef = this.dialog.open(this.modalCreateEditToBookActions, {
        panelClass: 'modal-emea-location-actions',
      });
    }
  }

  allTabs() {
    this.tabs = [
      {
        label: 'EXECUTION OVERVIEW',
        tab: 'EXECUTION OVERVIEW',
        template: this.ExecutionOverview,
      },
    ];
  }

  loadData() {
    this.spinner.show();
    this.currencyValue(this.filter);

    const promises = [this.getDataFilter()];
    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  currencyValue(params: any) {
    this.crudService
      .getEntity(`execution-overview?`, params)
      .subscribe((response: any) => {
        if (response) {
          this.responseTable = response;

          this.calculateTotalSubtotal(
            this.columnWithTotalValue(response.header)
          );

          response.rows?.forEach((row: any) => {
            if (response.header) {
              this.addTypeShowEvent(response.header);

              response.header.forEach((header: any) => {
                if (
                  this.responseTable[header] &&
                  typeof this.responseTable[header].value === 'number'
                ) {
                  let currencyString =
                    this.responseTable[header].value.toString();
                  let currencyFormatted = currencyString.replace(/\./g, ',');

                  this.responseTable[header].value = currencyFormatted;
                }
              });
            }
          });
          this.detectChanges();

          this.showNoResults = false;
          const promises = [response];
          this.disabledButtonExport = false;
          this.hideSpinnerAfterAll(promises);
        } else {
          this.disabledButtonExport = true;
          this.responseTable = [];
          this.showNoResults = true;
          this.spinner.hide();
        }
      });
    this.detectChanges();
  }

  replaceDots(rows: any, header: any): any {
    if (typeof rows[header].value === 'number') {
      let valueCurrency = rows[header].value.toLocaleString();

      if (
        header !== 'woiTarget' ||
        header !== 'woi' ||
        header !== 'woiPrediction'
      ) {
        return valueCurrency;
      } else {
        return rows[header].value;
      }
    } else {
      if (rows[header].value === '' && header !== 'actions') {
        rows[header].value = '-';
      }
      return rows[header].value;
    }
  }

  private getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  getEntities() {
    return (this.entities = [
      {
        entity: 'material',
        query: { status: true, pageName: PAGE_NAME_RESOURCE.lom },
      },
      {
        entity: 'material/products/summary',
        query: { status: true, pageName: PAGE_NAME_RESOURCE.lom },
      },
      {
        entity: 'setupManagement/currentSetup',
        query: { pageName: PAGE_NAME_RESOURCE.allocationRetail },
      },
    ]);
  }

  createSearchInputs(product?: any): any[] {
    const originFilter: any = [
      { id: 1, value: 'JAG' },
      { id: 2, value: 'MAN' },
    ];
    const quarterEntities = [
      { key: '1', value: 'FQ1' },
      { key: '2', value: 'FQ2' },
      { key: '3', value: 'FQ3' },
      { key: '4', value: 'FQ4' },
    ];
    const fiscalYearFilter: any[] = [];

    for (
      let year = this.currentYear, i = 0;
      year <= this.currentYear;
      year++, i++
    ) {
      fiscalYearFilter.push({
        key: i,
        value: `${year}/${year + 1}`,
      });
    }

    return (this.searchInputs = [
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'Product',
        type: 'text',
        hasAutocomplete: true,
        options: product,
        module: 'lom',
        classCss: 'multi-select-filter-lom-product',
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
        options: originFilter,
        classCss: 'select-filter-lom-origin',
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Quarter',
        warn: false,
        value: `F${this.currentQuarter}`,
        options: quarterEntities,
        classCss: 'select-filter-lom-quarter',
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
        warn: false,
        value: `${this.currentYear}/${this.currentYear + 1}`,
        options: fiscalYearFilter,
        classCss: 'select-filter-lom-fiscalYear',
        notClearable: true,
      }),
    ]);
  }

  extractWeek(weekString: any) {
    this.currentWeekReference = weekString.currentWeek;
    const transformWk = weekString.currentWeek;
    const newWk = transformWk.replace('week', 'Wk ');

    const toBookArray = weekString.toBook.split(', ');
    const startIndex = toBookArray.findIndex((week: any) => week === newWk);
    const newToBook = toBookArray.slice(startIndex).join(', ');
    this.toBookWeeks = newToBook;
  }

  async getDataFilter(): Promise<void> {
    try {
      const productFilter: any[] = [];

      this.responseFilters = await this.crudService
        .getDataFilters(this.entities)
        .toPromise();

      this.responseFilters.forEach((item: any) => {
        if (!Array.isArray(item)) {
          this.extractWeek(item);
        }

        if (Array.isArray(item)) {
          item.forEach((data: any) => {
            if (data.productDesc) {
              productFilter.push({
                value: data.productDesc,
                key: data.productDesc,
              });
            }
          });
        }
      });
      this.createSearchInputs(productFilter);
    } catch (error) {
      console.error(error);
    }
  }

  // @HostListener('window:scroll', [])
  // onWindowScroll() {
  //   this.scrollPage();
  // }
  // scrollPage() {
  //   const tableHeader = this.app.nativeElement.querySelector('#table thead tr');
  //   const tableFooter = this.app.nativeElement.querySelector('#footer');

  //   if (tableHeader && tableFooter) {
  //     const windowTop = window.scrollY || window.pageYOffset;
  //     const tableTop =
  //       this.app.nativeElement.getBoundingClientRect().top + windowTop;
  //     const footerTop = tableFooter.getBoundingClientRect().top + windowTop;
  //     const offset = this.hidePartialContent ? 170 : 250;
  //     const height = `${windowTop - offset - tableTop}px`;
  //     if (windowTop + tableHeader.offsetHeight <= footerTop) {
  //       tableHeader.classList.add('fixed-header');
  //       tableHeader.style.top = `${height}`;
  //     } else {
  //       tableHeader.classList.add('fixed-header');
  //       tableHeader.style.top = `${
  //         footerTop - tableTop - tableHeader.offsetHeight
  //       }px`;
  //     }
  //   }
  //   this.cdr.detectChanges();
  // }

  expecificProp(prop: string): boolean {
    return (
      prop === 'product' ||
      prop === 'salesModel' ||
      prop === 'country' ||
      prop === 'origin'
    );
  }

  isPropNumber(prop: string): boolean {
    return (
      prop !== 'product' &&
      prop !== 'salesModel' &&
      prop !== 'country' &&
      prop !== 'origin'
    );
  }

  isPropString(header: string): boolean {
    return (
      header === 'product' ||
      header === 'salesModel' ||
      header === 'country' ||
      header === 'origin'
    );
  }

  columnWithTotalValue(header: any) {
    return header.filter((header: any) => {
      return !(
        header.props === 'product' ||
        header.props === 'salesModel' ||
        header.props === 'origin' ||
        header.props === 'actions'
      );
    });
  }

  filterAwaysVisibleColumns() {
    if (this.responseTable && this.responseTable.header) {
      return this.responseTable.header.filter((item: any) =>
        item.props && item.alwaysVisible
          ? item.props && item.alwaysVisible
          : item.alwaysVisible != false
      );
    }
  }

  private calculateTotalSubtotal(subtotals: any) {
    const totalSubtotal: any = { totalsByColumn: {} };
    subtotals.forEach((header: any) => {
      totalSubtotal.totalsByColumn[header.props] = 0;
    });

    this.responseTable.rows.forEach((row: any) => {
      subtotals.forEach((header: any) => {
        if (row[header.props].value !== '') {
          let numericValue = row[header.props].value;
          if (!isNaN(numericValue)) {
            totalSubtotal.totalsByColumn[header.props] += numericValue;
          }
        }
      });
    });

    this.responseTable.totals = totalSubtotal;

    return totalSubtotal;
  }

  toggleColumnTable(header?: any, event?: string) {
    if (event === 'show') {
      header.expanded = true;
      const clickedOtherContent = header.otherContent;

      this.responseTable.header.filter((item: any) => {
        if (item.otherContent === clickedOtherContent) {
          item.alwaysVisible = true;
        }
      });
    } else {
      header.expanded = false;
      const clickedOtherContent = header.otherContent;

      this.responseTable.header.forEach((item: any) => {
        if (
          header.props !== item.props &&
          item.otherContent === clickedOtherContent
        ) {
          item.alwaysVisible = false;
        }
      });
    }
  }

  addTypeShowEvent(response: any) {
    this.eventTable = 'hide-columns';
    response.forEach((header: any) => {
      if (header.expansive === true) {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
      return header;
    });
  }

  editEvent(item: any) {
    this.callActions.emit({ item, type: 'edit' });
  }

  prepareToEdit() {
    const dialogRef = this.dialog.open(this.modalCreateEdit, {
      panelClass: 'modal-emea-location',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.holdFlagForm.reset();
    });
  }

  prepareToEditLocationActions(id: number) {}

  formattedDataTableToExport(data: any[], totals: any) {
    const formattedData: any[] = [];

    data.forEach((item: any) => {
      const formattedItem: any = {};
      this.headerResult.forEach((header: any) => {
        formattedItem[header.title] = item[header.props].value;
      });
      formattedData.push(formattedItem);
    });

    let totalRow: { [key: string]: any } = {};
    this.headerResult.forEach((header: any) => {
      totalRow[header.title] = totals[header.props];
    });
    totalRow['PRODUCT'] = 'TOTAL';
    formattedData.push(totalRow);

    return formattedData;
  }

  exportToExcelData(): void {
    const dataToExport: any[] = [];
    this.headerResult = [];
    this.responseTable.header.forEach((item: any) => {
      if (item.props !== 'actions') {
        this.headerResult.push(item);
      }
    });

    const formattedRows = this.formattedDataTableToExport(
      this.responseTable.rows,
      this.responseTable.totals.totalsByColumn
    );

    if (formattedRows.length > 0) {
      dataToExport.push({
        sheet: 'lom-execution-overview-export',
        header: this.headerResult.map((header: any) => ({
          title: header.title,
        })),
        rows: formattedRows,
      });
    }

    console.log(formattedRows);

    this.excelSheetsService.exportJsonAsExcelFile(
      dataToExport,
      `export-lom-execution-overview`
    );
  }

  toggleHideFilters() {
    if (this.hidePartialContent) {
      this.textshowOrHideFilters = 'Hide Filters';
    } else {
      this.textshowOrHideFilters = 'Show Filters';
    }
    this.hidePartialContent = !this.hidePartialContent;
  }

  dataFormSearchOrigin(formSearchName: any) {
    formSearchName.origin
      ? formSearchName.origin
      : (formSearchName.origin = '');
  }

  dataFormSearchQuarter(formSearchName: any) {
    formSearchName.quarter
      ? (formSearchName.quarter = formSearchName.quarter)
      : '';
  }
  dataFormSearchProduct(formSearchName: any) {
    if (Array.isArray(formSearchName.ppm)) {
      const currentOrigin = formSearchName.ppm.map((item: any) => item.value);
      formSearchName.ppm = currentOrigin;
    } else {
      formSearchName.ppm = '';
    }
  }

  dataFormSearchFiscalYear(formSearchName: any) {
    formSearchName.fiscalYear = formSearchName.fiscalYear?.split('/')[0] || '';
  }

  async onSearchEvent(form: any) {
    this.formSearchLom = { ...form };
    this.dataFormSearchProduct(this.formSearchLom);
    this.dataFormSearchOrigin(this.formSearchLom);
    this.dataFormSearchFiscalYear(this.formSearchLom);
    this.dataFormSearchQuarter(this.formSearchLom);

    this.filter = {
      ...this.formSearchLom,
    };
  }

  private deleteProperty(item: any) {
    if (item) {
      delete item.actions;
      delete item.nameFormControl;
      delete item.formControl;
      delete item.event;
      delete item.panelTrigger;
      return item;
    }
  }

  applyFilter() {
    this.spinner.show();
    let formSearchValues: any = {};
    this.deleteProperty(this.formSearchLom);
    formSearchValues = { ...this.formSearchLom };
    this.filter = { ...formSearchValues, ...this.filter };
    this.applyed = true;
    this.currencyValue(this.filter);
    this.quarter(formSearchValues.quarter);
  }

  quarter(quarter: string) {
    if (quarter) this.quarterValue = quarter;
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
