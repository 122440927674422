import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-simple-table-expansive-tabs',
  templateUrl: './simple-table-expansive-tabs.component.html',
  styleUrls: ['./simple-table-expansive-tabs.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SimpleTableExpansiveTabsComponent implements OnInit {
  @Input() dataTable!: any;
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @Output('toggleColumnTable') toggleColumnTableEvent = new EventEmitter();
  @Output('toggleRowTable') toggleRowTableEvent = new EventEmitter();
  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;

  headers: any = [];
  content: any = [];
  dataSource: any = [];
  displayedDef: any[] = [];
  displayedColumns: any;
  expandAll = false;
  translateYAbsoluteItem: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.dataTable?.currentValue) {
      this.headers = [];
      this.content = [];
      this.displayedDef = [];
      this.displayedColumns = [];
      this.loadTable(changes.dataTable.currentValue);
    }
    if (changes.displayedColumns?.currentValue) {
      this.displayedColumns = changes.displayedColumns.currentValue;
    }
  }

  loadTable(updateDataTable?: any) {
    this.dataTable = updateDataTable || this.dataTable;

    if (this.dataTable?.table) {
      this.dataSource = this.dataTable.table.rows;
      this.displayedDef = this.dataTable?.table.columns;
      this.displayedColumns = this.displayedDef
        .filter((item: any) =>
          item.props && item.secondColumn === false && item.alwaysVisible
            ? item.props && item.secondColumn === false && item.alwaysVisible
            : (item.secondColumn = true) && item.alwaysVisible != false
        )
        .map((item: any) => item.props);
    }
  }

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0]?.clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0]?.clientHeight;
    const headerTitle =
      document.getElementsByClassName('title')[0]?.clientHeight;
    const matRipple =
      document.getElementsByClassName('table-body')[0]?.clientHeight;
    const clientHeightTop = appHeaderHeight + breadCrumbHeight + headerTitle;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement.offsetTop + clientHeightTop;

    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  getAddRowId(indexRow: number) {
    return indexRow;
  }

  mouseEnter(indexRow: number, row: any) {
    const kaValue: string = row['ka'].value;
    const tableRows = document.querySelectorAll('td');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.add(`hover-row${kaValue}`);
      }
    });
  }

  mouseLeave(indexRow: number, row: any) {
    const kaValue: string = row['ka'].value;
    const tableRows = document.querySelectorAll('td');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.remove(`hover-row${kaValue}`);
      }
    });
  }

  toggleColumnTable(col?: any, event?: string) {
    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContent === col.otherContent
    );

    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );

    const indexCol = this.displayedColumns.findIndex(
      (item: any) => item === col?.otherContent
    );

    columsToShowHide.shift();
    if (event === 'show') {
      col.expanded = true;
      groupColumnsToShowHide[0].isFirstCard = true;
      groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
        true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expanded = true;
      });

      this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
    } else {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
    }

    this.toggleColumnTableEvent.emit({
      col,
      event,
    });
    this.cdr.detectChanges();
  }

  toggleExpandAll(col: any, element: any): void {
    this.resetExpandedColumns();
    element.expanded = !element.expanded;

    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContentVertical === col.otherContentVertical
    );

    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );

    if (element.expanded && col.otherContentVertical) {
      col.expandedVertical = true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expandedVertical = true;
      });

      const expandedData = this.dataSource.filteredData.reduce(
        (acc: any[], row: any) => {
          // Adiciona a linha atual
          acc.push(row);
          // Adiciona as familyColor abaixo da família 'OPERADORA'
          if (
            row.family &&
            row.family.name === 'OPERADORA' &&
            element.family &&
            element.family.familyColor.includes(row)
          ) {
            acc.push(...element.family.familyColor);
          }
          return acc;
        },
        []
      );

      // Atualiza o dataSource com os dados expandidos
      this.dataSource.data = expandedData;
      this.dataSource._filterData(this.dataSource.filter as any);
    } else if (col.otherContentVertical && !element.expanded) {
      col.expandedVertical = false;

      const filteredData = this.dataSource.filteredData.filter((row: any) => {
        // Mantém a linha atual
        if (row === element) {
          return true;
        }
        // Mantém a família 'OPERADORA' e as familyColor abaixo dela
        if (
          row.family &&
          row.family.value === 'OPERADORA' &&
          element.family &&
          element.family.familyColor.includes(row)
        ) {
          return true;
        }
        return false;
      });

      this.dataSource.data = filteredData;
      this.dataSource.filteredData = filteredData;
      this.dataSource._updatePaginator(filteredData.length);
      this.dataSource._filterData([this.dataSource.filter]);
    }

    this.displayedColumns = columsToShowHide;
    this.cdr.markForCheck();
  }

  toggleColumnTableVertical(col?: any, event?: string) {
    this.resetExpandedColumns();

    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContentVertical === col.otherContentVertical
    );

    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );

    if (event === 'show') {
      col.expandedVertical = true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expandedVertical = true;
      });

      this.displayedColumns = columsToShowHide;
    } else {
      col.expandedVertical = false;
      this.displayedColumns = this.displayedDef
        .filter((item: any) =>
          item.props && item.secondColumn === false && item.alwaysVisible
            ? item.props && item.secondColumn === false && item.alwaysVisible
            : (item.secondColumn = true) && item.alwaysVisible != false
        )
        .map((item: any) => item.props);
    }

    this.toggleColumnTableEvent.emit({
      col,
      event,
    });
    this.cdr.detectChanges();
  }

  expandedRow(col?: any, row?: any, event?: string): void {
    this.resetExpandedColumns();
    const auxValueFamily: string = row.family.auxValue;

    if (event === 'show') {
      this.dataSource.filteredData.forEach((row: any) => {
        if (row.family.familyValue === auxValueFamily && !row.rowFamily) {
          row.expandedVertical = true;
        }
      });
      row.expandedVertical = true;
      this.toggleColumnTableVertical(col, event);
    } else {
      this.dataSource.filteredData.forEach((row: any) => {
        if (row.family.familyValue === auxValueFamily && !row.rowFamily) {
          row.expandedVertical = false;
        }
      });

      row.expandedVertical = false;
      this.toggleColumnTableVertical(col, event);
    }

    this.toggleRowTableEvent.emit({
      col,
      event,
    });
    this.cdr.detectChanges();
  }

  resetExpandedColumns(): void {
    this.displayedDef.forEach((header: any) => {
      if (header?.expanded) {
        delete header.expanded;
      }
    });
  }
}
