<ngx-spinner></ngx-spinner>
<div id="ka-info" class="container-ka-info">
  <div class="header-title">
    <div class="title">KA INFO</div>
    <div class="container-export">
      <button
        class="upload-button"
        matTooltip="{{ tooltipMessageUpload }}"
        (click)="uploadButton()"></button>

      <button
        class="export-button"
        (click)="exportTable()"
        [class.disabled]="isValid"
        [disabled]="isValid"
        matTooltip="{{ tooltipMessage }}"></button>
    </div>
  </div>

  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"
    (applyFilterEvent)="applyFilter()"
    [enableApplyFilter]="enableApplyFilter"
    [showButtonApply]="true">
  </app-search>

  <mat-divider></mat-divider>

  <div>
    <h4 style="color: #303030">Allocation</h4>
  </div>

  <div class="no-filter-selected-session" *ngIf="showNoResults">
    <p>NO RECORDS FOUND.</p>
  </div>

  <div *ngIf="showTable" class="table-container">
    <app-table-subtotal
      [dataTable]="dataTable"
      (inputChanged)="inputChange()"
      (toggleColumnTable)="toggleColumnTable($event)"
      (updateSubtotalValues)="addSubtotalValuesDynamic($event)"
      [disableTableInput]="disableTableInput">
    </app-table-subtotal>
  </div>

  <ng-template #dialogConfirmationDiscardSaveChanges>
    <header class="title-modal" matDialogTitle>CONFIRMATION</header>
    <mat-dialog-content>{{ messageModalChanges }}</mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        NO
      </button>

      <button
        *ngIf="isDiscardChanges"
        class="button modal-action-button"
        mat-button
        matDialogClose="discard">
        YES
      </button>

      <button
        *ngIf="!isDiscardChanges"
        class="button modal-action-button"
        mat-button
        matDialogClose="save">
        YES
      </button>
    </mat-dialog-actions>
  </ng-template>

  <div class="buttons-right">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>

    <div *ngIf="!showNoResults" [class.bottons-fixed]="buttonFixed">
      <button
        class="button cancel-action-button"
        mat-button
        (click)="onCancel()">
        CANCEL
      </button>

      <button
        class="button save-action-button"
        mat-button
        (click)="handleSave()">
        SAVE
      </button>
    </div>
  </div>
</div>
