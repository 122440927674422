import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  constructor() {}

  public exportJsonAsExcelFile(json: any, excelFileName: string) {
    const headers: { [key: string]: any } = {};
    json.columns.forEach((column: any) => {
      headers[column.props] = column.title;
    });
    json.rows.unshift(headers);
    const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      json.rows,
      { skipHeader: true },
    );

    if (excelFileName === 'order_book_fail_reduction') {
      workSheet['!cols'] = [
        { wch: 27 },
        { wch: 31 },
        { wch: 27 },
      ];
    } 

    const workBook: XLSX.WorkBook = { Sheets: { [json.sheet || 'data']: workSheet }, SheetNames: [json.sheet || 'data'] };
    const excelBuffer: any = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string) {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, `${fileName}_exported${EXCEL_EXTENSION}`);
  }

  public exportTableExcel(data: any) {
    const simpleTableRows = data.simpleTable.rows;
    const simpleTableColumns = data.simpleTable.headers
      .map((item: any) => ({ title: item.title, props: item.props }),);
    simpleTableColumns.push({ title: 'ka', props: 'ka', });

    const tableExpandableRows = data.expandedTable.rows;
    const tableExpandableColumns = data.expandedTable.headers
      .map((item: any) => ({ title: item.title, props: item.props }),);

    const tableColumns = [...simpleTableColumns, ...tableExpandableColumns];
    const tableRows: { [key: string]: any; }[] = [];

    for (let i = 0; i < tableExpandableRows.length; i++) {
      const tableRow: { [key: string]: any } = {};
      tableColumns.forEach((column: any) => {
        if (simpleTableRows[i][column.props]) {
          tableRow[column.props] = simpleTableRows[i][column.props].value;
        } else {
          tableRow[column.props] = tableExpandableRows[i][column.props].value;
        }
      });
      tableRows.push(tableRow);
    }
    return { columns: tableColumns, rows: tableRows };
  }

}
