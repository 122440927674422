import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalAlertService {
  private firstModalOpenSubject = new BehaviorSubject<boolean>(false);
  firstModalOpen$ = this.firstModalOpenSubject.asObservable();

  private secondModalOpenSubject = new BehaviorSubject<boolean>(false);
  secondModalOpen$ = this.secondModalOpenSubject.asObservable();

  openFirstModal() {
    this.firstModalOpenSubject.next(true);
  }

  closeFirstModal() {
    this.firstModalOpenSubject.next(false);
  }

  openSecondModal() {
    this.secondModalOpenSubject.next(true);
  }

  closeSecondModal() {
    this.secondModalOpenSubject.next(false);
  }
}
