import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { AuthorizationComponent } from './authorization.component';

@NgModule({
  declarations: [
    AuthorizationComponent
  ],
  imports: [
    CommonModule,
    ShareComponentsModule
  ]
})
export class AuthorizationModule { }
