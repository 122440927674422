export const orderAllocationCustomerMockup: any = [
  {
    id: 44,
    country: 'Belgium',
    salesModel: 'PARH0000BR',
    productGroup: 'ARUBA',
    colorDesc: 'GRAPHITE',
    ram: '2',
    rom: '64',
    sim: 'SIM Unlock',
    customer: 'LENOVO',
  },
  {
    id: 44,
    country: 'Belgium',
    salesModel: 'PA3D0006BR',
    productGroup: 'DEFENDER',
    colorDesc: 'ICEBURG',
    ram: '2',
    rom: '64',
    sim: 'Dual SIM Unlock',
    customer: 'MODERNLABS',
  },
  {
    id: 45,
    country: 'Luxembourg',
    salesModel: 'PARH0000BR',
    productGroup: 'CANYON23',
    colorDesc: 'GLASS BLACK',
    ram: '8',
    rom: '64',
    sim: 'Dual SIM Unlock',
    customer: 'BECKER',
  },
  {
    id: 45,
    country: 'Luxembourg',
    salesModel: 'PA3D0006BR',
    productGroup: 'BORAG22',
    colorDesc: 'SUNRISE RED',
    ram: '8',
    rom: '64',
    sim: 'Dual SIM Unlock',
    customer: 'BENOIT',
  },
  {
    id: 121,
    country: 'Belarus',
    salesModel: 'PARK0025BR',
    productGroup: 'CYPRUS21',
    colorDesc: 'GRAPHITE',
    ram: '12',
    rom: '64',
    sim: 'SIM Unlock',
    customer: 'CBD',
  },
  {
    id: 121,
    country: 'Belarus',
    salesModel: 'PARK0024BR',
    productGroup: 'CYPRUS21',
    colorDesc: 'AQUARIUS',
    ram: '8',
    rom: '64',
    sim: 'Dual SIM Unlock',
    customer: 'DREBES',
  },
  {
    id: 43,
    country: 'Netherlands',
    salesModel: 'PAD30001BR',
    productGroup: 'MALTA',
    colorDesc: 'WATER RED',
    ram: '8',
    rom: '64',
    sim: 'SIM Unlock',
    customer: 'CENCOSUD',
  },
  {
    id: 43,
    country: 'Netherlands',
    salesModel: 'PALS0002BR',
    productGroup: 'GUAM+',
    colorDesc: 'JASPER',
    ram: '12',
    rom: '128',
    sim: 'Dual SIM Unlock',
    customer: 'ELETROMAR',
  },
  {
    id: 40,
    country: 'France',
    salesModel: 'PAD30004BR',
    productGroup: 'ROBUSTA S',
    colorDesc: 'WHITE',
    ram: '6',
    rom: '64',
    sim: 'SIM Unlock',
    customer: 'NOVALAR',
  },
  {
    id: 40,
    country: 'France',
    salesModel: 'PABK0011BR',
    productGroup: 'SHELBY',
    colorDesc: 'SILVER',
    ram: '4',
    rom: '128',
    sim: 'SIM Unlock',
    customer: 'MARTINS',
  },
  {
    id: 31,
    country: 'Albania',
    salesModel: 'PA760023BR',
    productGroup: 'ANDY ROW',
    colorDesc: 'METALLIC BLUSH GOLD',
    ram: '12',
    rom: '64',
    sim: 'Dual SIM Unlock',
    customer: 'SIRI',
  },
  {
    id: 31,
    country: 'Albania',
    salesModel: 'PA840006BR',
    productGroup: 'LACROSSE',
    colorDesc: 'STARRY BLACK',
    ram: '8',
    rom: '128',
    sim: 'SIM Unlock',
    customer: 'ALLIED',
  },
];
