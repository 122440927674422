<div class="table-columns-tabs">
  <div class="controls">
    <div class="tabs">
      <button
        (click)="onChangeTab(row.label)"
        *ngFor="let row of thead"
        [class.active]="row.label === tab">
        {{ row.label }}
      </button>
    </div>

    <ng-template *ngIf="showMenuColumns" [ngIf]="visible">
      <div class="show-columns" id="showColumns">
        <div
          (click)="toggleMenuColumns(showSubMenuColumns)"
          class="select-columns"
          [class.active]="showSubMenuColumns">
          <span>Columns</span>
        </div>
        <ul [class.active]="showSubMenuColumns">
          <li *ngFor="let row of showColumns">
            <button (click)="togleHideColumns(row.key, row.children)">
              <div
                class="checkbox"
                [class.active]="!getValidatorHideColumn(row.key)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 16 16">
                  <path
                    fill="currentColor"
                    d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06a.733.733 0 0 1 1.047 0l3.052 3.093l5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </div>
              <span>{{ row.label }}</span>
            </button>
            <ul *ngIf="row.submenu">
              <li>
                <button (click)="togleHideColumns(row.submenu, row.children)">
                  <div
                    class="checkbox"
                    [class.active]="!getValidatorHideColumn(row.submenu)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 16 16">
                      <path
                        fill="currentColor"
                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06a.733.733 0 0 1 1.047 0l3.052 3.093l5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </div>
                  <span>{{ row.submenu }}</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>

  <div class="no-filter-selected-session" *ngIf="!visible">
    <p>NO RECORDS FOUND.</p>
  </div>

  <ng-template [ngIf]="visible">
    <div class="content-tables">
      <ng-template
        *ngFor="let table of theadSelected; let keyTable = index"
        [ngIf]="showTables[keyTable]">
        <div class="overflow-table">
          <table>
            <thead>
              <tr>
                <ng-template *ngFor="let th of table" [ngIf]="true">
                  <th
                    *ngIf="getValidatorHideColumn(th.key) === false"
                    [class.border-first]="table.length === 4"
                    [class.border-second]="
                      table.length === 1 || table.length === 19
                    ">
                    <div [class.checkWeek]="this.checkWeek(th.key)">
                      <span>{{ th.label }}</span>
                      <span *ngIf="th.description">{{ th.description }}</span>
                    </div>
                  </th>
                </ng-template>
              </tr>
            </thead>
            <tbody *ngFor="let row of data">
              <tr
                *ngFor="let item of row.docs; let keyDocs = index"
                class="body"
                id="{{ getAddRowId(keyDocs, row?.origin) }}"
                (mouseenter)="mouseEnter(keyDocs, row?.origin)"
                (mouseleave)="mouseLeave(keyDocs, row?.origin)">
                <ng-template *ngFor="let th of table" [ngIf]="true">
                  <td
                    [class.active]="th.active"
                    *ngIf="getValidatorHideColumn(th.key) === false">
                    <span
                      *ngIf="keyDocs === 0"
                      [class.negative]="row[th.key] < 0"
                      >{{ row[th.key] }}
                    </span>
                    <div [class.checkWeek]="this.checkWeek(th.key)">
                      <span [class.negative]="item[th.key] < 0">
                        <app-value-currency
                          [value]="
                            this.getCount(item[th.key], th.key)
                          "></app-value-currency>
                      </span>
                    </div>
                  </td>
                </ng-template>
              </tr>
              <tr>
                <ng-template
                  *ngFor="let th of table; let keyTh = index"
                  [ngIf]="true">
                  <td
                    class="subtotal"
                    *ngIf="getValidatorHideColumn(th.key) === false">
                    <span class="bold" *ngIf="keyTh === 0 && keyTable === 0">
                      SUBTOTAL
                    </span>
                    <span
                      *ngIf="keyTh > 0 || keyTable > 0"
                      [class.checkWeek]="this.checkWeek(th.key)"
                      [class.negative]="this.getCountSubtotal(row, th.key) < 0">
                      <app-value-currency
                        [value]="this.getCountSubtotal(row, th.key)">
                      </app-value-currency>
                    </span>
                  </td>
                </ng-template>
              </tr>
            </tbody>

            <tbody *ngIf="tab === 'To Book'">
              <tr>
                <ng-template
                  *ngFor="let th of table; let keyTh = index"
                  [ngIf]="true">
                  <td
                    class="totalSubtotal"
                    *ngIf="getValidatorHideColumn(th.key) === false">
                    <span class="bold" *ngIf="keyTh === 0 && keyTable === 0">
                      TOTAL
                    </span>
                    <span
                      *ngIf="keyTh > 0 || keyTable > 0"
                      [class.checkWeek]="this.checkWeek(th.key)"
                      [class.negative]="this.getTotalOfSubtotals(th.key) < 0">
                      <app-value-currency
                        [value]="this.getTotalOfSubtotals(th.key)">
                      </app-value-currency>
                    </span>
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
