import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-emea-allocation',
  templateUrl: './emea-allocation.component.html',
  styleUrls: ['./emea-allocation.component.scss'],
})
export class EmeaAllocationComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  pages: any[] = [
    {
      name: 'Territory Allocation',
      url: '../../../assets/images/ico-territory-allocation.svg',
      path: 'territory-allocation',
    },
    {
      name: 'Order Allocation',
      url: '../../../assets/images/ico-order-allocation.svg',
      path: 'order-allocation',
    },
    {
      name: 'Territory and Products View',
      url: '../../../assets/images/ico-emea-territory-and-products-view.svg',
      path: 'emea-territory-products-view',
    },
    {
      name: 'Wow Analysis',
      url: '../../../assets/images/ico-emea-wow-analysis.svg',
      path: 'emea-wow-analysis',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }

  ngOnInit(): void {
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'EMEA ALLOCATION',
          this.pages
        );
      });
      if (this.itemsList.length === 0) {
        this.router.navigate(['unauthorized']);
      }
    }
  }

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
