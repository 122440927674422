import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { MatTableModule } from '@angular/material/table';
import { BrazilDemandsComponent } from './brazil-demands/brazil-demands.component';
import { CarriersInfoComponent } from './carriers-info/carriers-info.component';
import { KaManagementComponent } from './ka-management/ka-management.component';
import { LomComponent } from './lom/lom.component';
import { AllocationKaAllocationComponent } from './allocation-ka-allocation/allocation-ka-allocation.component';
import { KaAllocationComponent } from './ka-allocation.component';
import { KaInfoComponent } from './ka-info/ka-info.component';
import { FairDistributionComponent } from './fair-distribution/fair-distribution.component';
import { KaAllocationUploadComponent } from './ka-allocation-upload/ka-allocation-upload.component';
import { SupplySummaryComponent } from './supply-summary/supply-summary.component';
import { KaExecutionComponent } from './ka-execution/ka-execution.component';
import { KaInfoUploadComponent } from './ka-info/ka-info-upload/ka-info-upload.component';
@NgModule({
  declarations: [
    AllocationKaAllocationComponent,
    BrazilDemandsComponent,
    CarriersInfoComponent,
    KaManagementComponent,
    LomComponent,
    KaAllocationComponent,
    KaInfoComponent,
    KaInfoUploadComponent,
    FairDistributionComponent,
    KaAllocationUploadComponent,
    SupplySummaryComponent,
    KaExecutionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ShareDirectiviesModule,
    ShareComponentsModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  bootstrap: [],
})
export class KaAllocationModule {}
