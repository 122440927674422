export const tableFairDistributionMockup: any = {
  table: {
    headers: [
      {
        title: 'FAMILY',
        props: 'family',
        otherContentVertical: 'family', // Identificador para as outras colunas que serão expandidas a partir
        firtsTitle: true,
        alwaysVisible: true,
        verticalColumn: true, // Indicador que está coluna tem colunas e linhas para expansível
        secondColumn: true, // Indicador que está coluna é expansível
        style: { width: '12%' },
      },
      {
        title: 'KA',
        props: 'ka',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: 'JAN',
        props: 'jan',
        otherContent: 'jan',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK01',
        props: 'wk01Jan',
        otherContent: 'jan',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK02',
        props: 'wk02Jan',
        otherContent: 'jan',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK03',
        props: 'wk03Jan',
        otherContent: 'jan',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK04',
        props: 'wk04Jan',
        otherContent: 'jan',
        firtsTitle: true,
        secondColumn: true,
        alwaysVisible: false,
      },
      {
        title: 'WK05',
        props: 'wk05Jan',
        otherContent: 'jan',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'FEB',
        props: 'feb',
        otherContent: 'feb',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK06',
        props: 'wk06Feb',
        otherContent: 'feb',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK07',
        props: 'wk07Feb',
        otherContent: 'feb',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK08',
        props: 'wk08Feb',
        otherContent: 'feb',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK09',
        props: 'wk09Feb',
        otherContent: 'feb',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK010',
        props: 'wk010Feb',
        otherContent: 'feb',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'MAR',
        props: 'mar',
        otherContent: 'mar',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: true,
      },
      {
        title: 'WK011',
        props: 'wk011Mar',
        otherContent: 'mar',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK012',
        props: 'wk012Mar',
        otherContent: 'mar',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK013',
        props: 'wk013Mar',
        otherContent: 'mar',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK014',
        props: 'wk014Mar',
        otherContent: 'mar',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WK015',
        props: 'wk015Mar',
        otherContent: 'mar',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'MLS TOTAL',
        props: 'mlsTotal',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: false,
      },
      {
        title: 'DEMAND',
        props: 'demand',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: '%DEMAND',
        props: 'percentageDemand',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: false,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: 'OCT',
        props: 'mlsTotalOct',
        // subTitle: 'OCT', // Usado para exibir um segundo título na mesma coluna
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: 'OCT+NOV',
        props: 'mlsTotalOctNov',
        // subTitle: 'OCT+NOV',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: 'OCT+NOV+DEC',
        props: 'mlsTotalOctNovDec',
        // subTitle: 'OCT+NOV+DEC',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: 'GAP MLS VS DEMAND',
        props: 'gapMlsVsDemand',
        firtsTitle: true,
        alwaysVisible: true,
        hideIconExpand: true,
        secondColumn: false,
      },
      {
        title: "GAP",
        props: "gap",
        otherContent: 'gap',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: true
      },
      {
        title: 'GAP OCT',
        props: 'gapOct',
        otherContent: 'gap',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'GAP NOV',
        props: 'gapNov',
        otherContent: 'gap',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'GAP DEC',
        props: 'gapDec',
        otherContent: 'gap',
        firtsTitle: true,
        alwaysVisible: false,
        secondColumn: true,
      },
      {
        title: 'WOI',
        props: 'woi',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: false,
      },
      {
        title: 'SIC',
        props: 'sic',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: false,
      },
      {
        title: 'ACTIVATIONS',
        props: 'activations',
        otherContentVertical: 'family',
        firtsTitle: true,
        alwaysVisible: true,
        secondColumn: false,
      },
    ],
    rows: [
      {
        rowFamily: true,
        expandedVertical: false,
        family: {
          expandedIcon: true,
          value: 'Jimmy+ 4 4/64',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'Claro_BR',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        family: {
          value: '',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'TIM_BRA',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        family: {
          value: '',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'RETAIL_BRA',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        family: {
          value: '',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'VIVO',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        enableTotal: true,
        family: {
          value: 'TOTAL',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: '',
        },
        jan: {
          value: 0,
        },
        wk01Jan: {
          value: 0,
          summableValue: true,
        },
        wk02Jan: {
          value: 0,
          summableValue: true,
        },
        wk03Jan: {
          value: 0,
          summableValue: true,
        },
        wk04Jan: {
          value: 0,
          summableValue: true,
        },
        wk05Jan: {
          value: 0,
          summableValue: true,
        },
        feb: {
          value: 400,
        },
        wk06Feb: {
          value: 0,
          summableValue: true,
        },
        wk07Feb: {
          value: 0,
          summableValue: true,
        },
        wk08Feb: {
          value: 0,
          summableValue: true,
        },
        wk09Feb: {
          value: 0,
          summableValue: true,
        },
        wk010Feb: {
          value: 0,
          summableValue: true,
        },
        mar: {
          value: 1200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 1600,
        },
        demand: {
          value: 1600,
        },
        percentageDemand: {
          value: '50,00%',
        },
        mlsTotalOct: {
          value: 1600,
        },
        mlsTotalOctNov: {
          value: 1600,
        },
        mlsTotalOctNovDec: {
          value: 1600,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        enableRetail: true,
        family: {
          value: 'RETAIL',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: '',
        },
        jan: {
          value: '0%',
        },
        wk01Jan: {
          value: '0%',
          summableValue: true,
        },
        wk02Jan: {
          value: '0%',
          summableValue: true,
        },
        wk03Jan: {
          value: '0%',
          summableValue: true,
        },
        wk04Jan: {
          value: '0%',
          summableValue: true,
        },
        wk05Jan: {
          value: '0%',
          summableValue: true,
        },
        feb: {
          value: '0%',
        },
        wk06Feb: {
          value: '0%',
          summableValue: true,
        },
        wk07Feb: {
          value: '0%',
          summableValue: true,
        },
        wk08Feb: {
          value: '0%',
          summableValue: true,
        },
        wk09Feb: {
          value: '0%',
          summableValue: true,
        },
        wk010Feb: {
          value: '0%',
          summableValue: true,
        },
        mar: {
          value: '50%',
        },
        wk011Mar: {
          value: '0%',
          summableValue: true,
        },
        wk012Mar: {
          value: '0%',
          summableValue: true,
        },
        wk013Mar: {
          value: '0%',
          summableValue: true,
        },
        wk014Mar: {
          value: '0%',
          summableValue: true,
        },
        wk015Mar: {
          value: '0%',
          summableValue: true,
        },
        mlsTotal: {
          value: '56,25%',
        },
        demand: {
          value: '56,25%',
        },
        mlsTotalOct: {
          value: '56,25%',
        },
        mlsTotalOctNov: {
          value: '56,25%',
        },
        mlsTotalOctNovDec: {
          value: '56,25%',
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        enableRetail: true,
        family: {
          value: 'OPERADORA',
          auxValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: '',
        },
        jan: {
          value: '0%',
        },
        wk01Jan: {
          value: '0%',
          summableValue: true,
        },
        wk02Jan: {
          value: '0%',
          summableValue: true,
        },
        wk03Jan: {
          value: '0%',
          summableValue: true,
        },
        wk04Jan: {
          value: '0%',
          summableValue: true,
        },
        wk05Jan: {
          value: '0%',
          summableValue: true,
        },
        feb: {
          value: '0%',
        },
        wk06Feb: {
          value: '0%',
          summableValue: true,
        },
        wk07Feb: {
          value: '0%',
          summableValue: true,
        },
        wk08Feb: {
          value: '0%',
          summableValue: true,
        },
        wk09Feb: {
          value: '0%',
          summableValue: true,
        },
        wk010Feb: {
          value: '0%',
          summableValue: true,
        },
        mar: {
          value: '50%',
        },
        wk011Mar: {
          value: '0%',
          summableValue: true,
        },
        wk012Mar: {
          value: '0%',
          summableValue: true,
        },
        wk013Mar: {
          value: '0%',
          summableValue: true,
        },
        wk014Mar: {
          value: '0%',
          summableValue: true,
        },
        wk015Mar: {
          value: '0%',
          summableValue: true,
        },
        mlsTotal: {
          value: '43,75%',
        },
        demand: {
          value: '43,75%',
        },
        mlsTotalOct: {
          value: '43,75%',
        },
        mlsTotalOctNov: {
          value: '43,75%',
        },
        mlsTotalOctNovDec: {
          value: '43,75%',
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gap: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      /* Cor family: Jimmy */
      {
        expandedVertical: false,
        family: {
          expandedIcon: false,
          value: 'Jimmy+ 4 4/64 - Meteor Gray',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'Claro_BR',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        expandedVertical: false,
        family: {
          value: '',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'TIM_BRA',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        expandedVertical: false,
        family: {
          value: '',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'RETAIL_BRA',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        expandedVertical: false,
        family: {
          value: '',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: 'VIVO',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
      },
      {
        expandedVertical: false,
        enableTotal: true,
        family: {
          value: 'TOTAL',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: '',
        },
        jan: {
          value: 0,
        },
        wk01Jan: {
          value: 0,
          summableValue: true,
        },
        wk02Jan: {
          value: 0,
          summableValue: true,
        },
        wk03Jan: {
          value: 0,
          summableValue: true,
        },
        wk04Jan: {
          value: 0,
          summableValue: true,
        },
        wk05Jan: {
          value: 0,
          summableValue: true,
        },
        feb: {
          value: 400,
        },
        wk06Feb: {
          value: 0,
          summableValue: true,
        },
        wk07Feb: {
          value: 0,
          summableValue: true,
        },
        wk08Feb: {
          value: 0,
          summableValue: true,
        },
        wk09Feb: {
          value: 0,
          summableValue: true,
        },
        wk010Feb: {
          value: 0,
          summableValue: true,
        },
        mar: {
          value: 1200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 1600,
        },
        demand: {
          value: 1600,
        },
        percentageDemand: {
          value: '50,00%',
        },
        mlsTotalOct: {
          value: 1600,
        },
        mlsTotalOctNov: {
          value: 1600,
        },
        mlsTotalOctNovDec: {
          value: 1600,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      {
        expandedVertical: false,
        enableRetail: true,
        family: {
          value: 'RETAIL',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: '',
        },
        jan: {
          value: '0%',
        },
        wk01Jan: {
          value: '0%',
          summableValue: true,
        },
        wk02Jan: {
          value: '0%',
          summableValue: true,
        },
        wk03Jan: {
          value: '0%',
          summableValue: true,
        },
        wk04Jan: {
          value: '0%',
          summableValue: true,
        },
        wk05Jan: {
          value: '0%',
          summableValue: true,
        },
        feb: {
          value: '0%',
        },
        wk06Feb: {
          value: '0%',
          summableValue: true,
        },
        wk07Feb: {
          value: '0%',
          summableValue: true,
        },
        wk08Feb: {
          value: '0%',
          summableValue: true,
        },
        wk09Feb: {
          value: '0%',
          summableValue: true,
        },
        wk010Feb: {
          value: '0%',
          summableValue: true,
        },
        mar: {
          value: '50%',
        },
        wk011Mar: {
          value: '0%',
          summableValue: true,
        },
        wk012Mar: {
          value: '0%',
          summableValue: true,
        },
        wk013Mar: {
          value: '0%',
          summableValue: true,
        },
        wk014Mar: {
          value: '0%',
          summableValue: true,
        },
        wk015Mar: {
          value: '0%',
          summableValue: true,
        },
        mlsTotal: {
          value: '56,25%',
        },
        demand: {
          value: '56,25%',
        },
        mlsTotalOct: {
          value: '56,25%',
        },
        mlsTotalOctNov: {
          value: '56,25%',
        },
        mlsTotalOctNovDec: {
          value: '56,25%',
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      {
        expandedVertical: false,
        enableTotal: true,
        family: {
          value: 'OPERADORA',
          auxValue: 'Jimmy+ 4 4/64',
          familyValue: 'Jimmy+ 4 4/64',
        },
        ka: {
          value: '',
        },
        jan: {
          value: '0%',
        },
        wk01Jan: {
          value: '0%',
          summableValue: true,
        },
        wk02Jan: {
          value: '0%',
          summableValue: true,
        },
        wk03Jan: {
          value: '0%',
          summableValue: true,
        },
        wk04Jan: {
          value: '0%',
          summableValue: true,
        },
        wk05Jan: {
          value: '0%',
          summableValue: true,
        },
        feb: {
          value: '0%',
        },
        wk06Feb: {
          value: '0%',
          summableValue: true,
        },
        wk07Feb: {
          value: '0%',
          summableValue: true,
        },
        wk08Feb: {
          value: '0%',
          summableValue: true,
        },
        wk09Feb: {
          value: '0%',
          summableValue: true,
        },
        wk010Feb: {
          value: '0%',
          summableValue: true,
        },
        mar: {
          value: '50%',
        },
        wk011Mar: {
          value: '0%',
          summableValue: true,
        },
        wk012Mar: {
          value: '0%',
          summableValue: true,
        },
        wk013Mar: {
          value: '0%',
          summableValue: true,
        },
        wk014Mar: {
          value: '0%',
          summableValue: true,
        },
        wk015Mar: {
          value: '0%',
          summableValue: true,
        },
        mlsTotal: {
          value: '43,75%',
        },
        demand: {
          value: '43,75%',
        },
        mlsTotalOct: {
          value: '43,75%',
        },
        mlsTotalOctNov: {
          value: '43,75%',
        },
        mlsTotalOctNovDec: {
          value: '43,75%',
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      /* Outra Familia */
      {
        rowFamily: true,
        family: {
          expandedIcon: true,
          value: 'Fiji 2/32',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: 'Claro_BR',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
        expanded: false,
      },
      {
        rowFamily: true,
        family: {
          value: '',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: 'TIM_BRA',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
        expanded: false,
      },
      {
        rowFamily: true,
        family: {
          value: '',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: 'RETAIL_BRA',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
        expanded: false,
      },
      {
        rowFamily: true,
        family: {
          value: '',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: 'VIVO',
        },
        jan: {
          value: 100,
        },
        wk01Jan: {
          value: 500,
          summableValue: true,
        },
        wk02Jan: {
          value: 500,
          summableValue: true,
        },
        wk03Jan: {
          value: 500,
          summableValue: true,
        },
        wk04Jan: {
          value: 500,
          summableValue: true,
        },
        wk05Jan: {
          value: 500,
          summableValue: true,
        },
        feb: {
          value: 200,
        },
        wk06Feb: {
          value: 500,
          summableValue: true,
        },
        wk07Feb: {
          value: 500,
          summableValue: true,
        },
        wk08Feb: {
          value: 500,
          summableValue: true,
        },
        wk09Feb: {
          value: 500,
          summableValue: true,
        },
        wk010Feb: {
          value: 500,
          summableValue: true,
        },
        mar: {
          value: 200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 300,
        },
        demand: {
          value: 300,
        },
        percentageDemand: {
          value: '18,75%',
        },
        mlsTotalOct: {
          value: 300,
        },
        mlsTotalOctNov: {
          value: 300,
        },
        mlsTotalOctNovDec: {
          value: 300,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 300,
        },
        activations: {
          value: 0,
        },
        expanded: false,
      },
      {
        rowFamily: true,
        enableTotal: true,
        family: {
          value: 'TOTAL',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: '',
        },
        jan: {
          value: 0,
        },
        wk01Jan: {
          value: 0,
          summableValue: true,
        },
        wk02Jan: {
          value: 0,
          summableValue: true,
        },
        wk03Jan: {
          value: 0,
          summableValue: true,
        },
        wk04Jan: {
          value: 0,
          summableValue: true,
        },
        wk05Jan: {
          value: 0,
          summableValue: true,
        },
        feb: {
          value: 400,
        },
        wk06Feb: {
          value: 0,
          summableValue: true,
        },
        wk07Feb: {
          value: 0,
          summableValue: true,
        },
        wk08Feb: {
          value: 0,
          summableValue: true,
        },
        wk09Feb: {
          value: 0,
          summableValue: true,
        },
        wk010Feb: {
          value: 0,
          summableValue: true,
        },
        mar: {
          value: 1200,
        },
        wk011Mar: {
          value: 500,
          summableValue: true,
        },
        wk012Mar: {
          value: 500,
          summableValue: true,
        },
        wk013Mar: {
          value: 500,
          summableValue: true,
        },
        wk014Mar: {
          value: 500,
          summableValue: true,
        },
        wk015Mar: {
          value: 500,
          summableValue: true,
        },
        mlsTotal: {
          value: 1600,
        },
        demand: {
          value: 1600,
        },
        percentageDemand: {
          value: '50,00%',
        },
        mlsTotalOct: {
          value: 1600,
        },
        mlsTotalOctNov: {
          value: 1600,
        },
        mlsTotalOctNovDec: {
          value: 1600,
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        enableRetail: true,
        family: {
          value: 'RETAIL',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: '',
        },
        jan: {
          value: '0%',
        },
        wk01Jan: {
          value: '0%',
          summableValue: true,
        },
        wk02Jan: {
          value: '0%',
          summableValue: true,
        },
        wk03Jan: {
          value: '0%',
          summableValue: true,
        },
        wk04Jan: {
          value: '0%',
          summableValue: true,
        },
        wk05Jan: {
          value: '0%',
          summableValue: true,
        },
        feb: {
          value: '0%',
        },
        wk06Feb: {
          value: '0%',
          summableValue: true,
        },
        wk07Feb: {
          value: '0%',
          summableValue: true,
        },
        wk08Feb: {
          value: '0%',
          summableValue: true,
        },
        wk09Feb: {
          value: '0%',
          summableValue: true,
        },
        wk010Feb: {
          value: '0%',
          summableValue: true,
        },
        mar: {
          value: '50%',
        },
        wk011Mar: {
          value: '0%',
          summableValue: true,
        },
        wk012Mar: {
          value: '0%',
          summableValue: true,
        },
        wk013Mar: {
          value: '0%',
          summableValue: true,
        },
        wk014Mar: {
          value: '0%',
          summableValue: true,
        },
        wk015Mar: {
          value: '0%',
          summableValue: true,
        },
        mlsTotal: {
          value: '56,25%',
        },
        demand: {
          value: '56,25%',
        },
        mlsTotalOct: {
          value: '56,25%',
        },
        mlsTotalOctNov: {
          value: '56,25%',
        },
        mlsTotalOctNovDec: {
          value: '56,25%',
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
      {
        rowFamily: true,
        enableTotal: true,
        family: {
          value: 'OPERADORA',
          auxValue: 'Fiji 2/32',
        },
        ka: {
          value: '',
        },
        jan: {
          value: '0%',
        },
        wk01Jan: {
          value: '0%',
          summableValue: true,
        },
        wk02Jan: {
          value: '0%',
          summableValue: true,
        },
        wk03Jan: {
          value: '0%',
          summableValue: true,
        },
        wk04Jan: {
          value: '0%',
          summableValue: true,
        },
        wk05Jan: {
          value: '0%',
          summableValue: true,
        },
        feb: {
          value: '0%',
        },
        wk06Feb: {
          value: '0%',
          summableValue: true,
        },
        wk07Feb: {
          value: '0%',
          summableValue: true,
        },
        wk08Feb: {
          value: '0%',
          summableValue: true,
        },
        wk09Feb: {
          value: '0%',
          summableValue: true,
        },
        wk010Feb: {
          value: '0%',
          summableValue: true,
        },
        mar: {
          value: '50%',
        },
        wk011Mar: {
          value: '0%',
          summableValue: true,
        },
        wk012Mar: {
          value: '0%',
          summableValue: true,
        },
        wk013Mar: {
          value: '0%',
          summableValue: true,
        },
        wk014Mar: {
          value: '0%',
          summableValue: true,
        },
        wk015Mar: {
          value: '0%',
          summableValue: true,
        },
        mlsTotal: {
          value: '43,75%',
        },
        demand: {
          value: '43,75%',
        },
        mlsTotalOct: {
          value: '43,75%',
        },
        mlsTotalOctNov: {
          value: '43,75%',
        },
        mlsTotalOctNovDec: {
          value: '43,75%',
        },
        gapMlsVsDemand: {
          value: 0,
        },
        gapOct: {
          value: 0,
        },
        gapNov: {
          value: 0,
        },
        gapDec: {
          value: 0,
        },
        woi: {
          value: 8,
        },
        sic: {
          value: 0,
        },
        activations: {
          value: 0,
        },
      },
    ],
  },
};

// {
//   family: {
//     value: '',
//     auxValue: 'Jimmy+ 4 4/64',
//   },
//   ka: {
//     value: 'Tim_BR',
//   },
//   jan: {
//     value: 100,
//   },
//   wk01Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk02Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk03Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk04Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk05Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   feb: {
//     value: 200,
//   },
//   wk06Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk07Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk08Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk09Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk010Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   mar: {
//     value: 200,
//   },
//   wk011Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk012Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk013Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk014Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk015Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   mlsTotal: {
//     value: 300,
//   },
//   demand: {
//     value: 300,
//   },
//   percentageDemand: {
//     value: '18,75%',
//   },
//   woi: {
//     value: 8,
//   },
//   sic: {
//     value: 300,
//   },
//   activations: {
//     value: 0,
//   },
// },
// {
//   family: {
//     value: '',
//     auxValue: 'Jimmy+ 4 4/64',
//   },
//   ka: {
//     value: 'Retail_BRA',
//   },
//   jan: {
//     value: 100,
//   },
//   wk01Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk02Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk03Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk04Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk05Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   feb: {
//     value: 600,
//   },
//   wk06Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk07Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk08Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk09Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk010Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   mar: {
//     value: 700,
//   },
//   wk011Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk012Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk013Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk014Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk015Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   mlsTotal: {
//     value: 700,
//   },
//   demand: {
//     value: 700,
//   },
//   percentageDemand: {
//     value: '43,75%',
//   },
//   woi: {
//     value: 8,
//   },
//   sic: {
//     value: 300,
//   },
//   activations: {
//     value: 0,
//   },
// },
// {
//   family: {
//     value: '',
//     auxValue: 'Jimmy+ 4 4/64',
//   },
//   ka: {
//     value: 'Vivo_BRA',
//   },
//   jan: {
//     value: 100,
//   },
//   wk01Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk02Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk03Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk04Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   wk05Jan: {
//     value: 500,
//     summableValue: true,
//   },
//   feb: {
//     value: 200,
//   },
//   wk06Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk07Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk08Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk09Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   wk010Feb: {
//     value: 500,
//     summableValue: true,
//   },
//   mar: {
//     value: 200,
//   },
//   wk011Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk012Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk013Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk014Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk015Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   mlsTotal: {
//     value: 300,
//   },
//   demand: {
//     value: 300,
//   },
//   percentageDemand: {
//     value: '18,75%',
//   },
//   woi: {
//     value: 8,
//   },
//   sic: {
//     value: 300,
//   },
//   activations: {
//     value: 0,
//   },
// },
// {
//   enableTotal: true,
//   family: {
//     value: 'TOTAL',
//     auxValue: 'Jimmy+ 4 4/64',
//   },
//   ka: {
//     value: '',
//   },
//   jan: {
//     value: 0,
//   },
//   wk01Jan: {
//     value: 0,
//     summableValue: true,
//   },
//   wk02Jan: {
//     value: 0,
//     summableValue: true,
//   },
//   wk03Jan: {
//     value: 0,
//     summableValue: true,
//   },
//   wk04Jan: {
//     value: 0,
//     summableValue: true,
//   },
//   wk05Jan: {
//     value: 0,
//     summableValue: true,
//   },
//   feb: {
//     value: 400,
//   },
//   wk06Feb: {
//     value: 0,
//     summableValue: true,
//   },
//   wk07Feb: {
//     value: 0,
//     summableValue: true,
//   },
//   wk08Feb: {
//     value: 0,
//     summableValue: true,
//   },
//   wk09Feb: {
//     value: 0,
//     summableValue: true,
//   },
//   wk010Feb: {
//     value: 0,
//     summableValue: true,
//   },
//   mar: {
//     value: 1200,
//   },
//   wk011Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk012Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk013Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk014Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   wk015Mar: {
//     value: 500,
//     summableValue: true,
//   },
//   mlsTotal: {
//     value: 1600,
//   },
//   demand: {
//     value: 1600,
//   },
//   percentageDemand: {
//     value: '50,00%',
//   },
//   woi: {
//     value: 8,
//   },
//   sic: {
//     value: 0,
//   },
//   activations: {
//     value: 0,
//   },
// },
// {
//   enableRetail: true,
//   family: {
//     value: 'RETAIL',
//     auxValue: 'Jimmy+ 4 4/64',
//   },
//   ka: {
//     value: '',
//   },
//   jan: {
//     value: '0%',
//   },
//   wk01Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk02Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk03Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk04Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk05Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   feb: {
//     value: '0%',
//   },
//   wk06Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk07Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk08Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk09Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk010Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   mar: {
//     value: '50%',
//   },
//   wk011Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk012Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk013Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk014Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk015Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   mlsTotal: {
//     value: '56,25%',
//   },
//   demand: {
//     value: '56,25%',
//   },
//   percentageDemand: {
//     value: '18,75%',
//   },
//   woi: {
//     value: 8,
//   },
//   sic: {
//     value: 0,
//   },
//   activations: {
//     value: 0,
//   },
// },
// {
//   enableTotal: true,
//   family: {
//     value: 'OPERADORA',
//     auxValue: 'Jimmy+ 4 4/64',
//   },
//   ka: {
//     value: '',
//   },
//   jan: {
//     value: '0%',
//   },
//   wk01Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk02Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk03Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk04Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk05Jan: {
//     value: '0%',
//     summableValue: true,
//   },
//   feb: {
//     value: '0%',
//   },
//   wk06Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk07Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk08Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk09Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk010Feb: {
//     value: '0%',
//     summableValue: true,
//   },
//   mar: {
//     value: '50%',
//   },
//   wk011Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk012Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk013Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk014Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   wk015Mar: {
//     value: '0%',
//     summableValue: true,
//   },
//   mlsTotal: {
//     value: '43,75%',
//   },
//   demand: {
//     value: '43,75%',
//   },
//   percentageDemand: {
//     value: '18,75%',
//   },
//   woi: {
//     value: 8,
//   },
//   sic: {
//     value: 0,
//   },
//   activations: {
//     value: 0,
//   },
// },
