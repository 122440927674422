import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brazil-demands',
  templateUrl: './brazil-demands.component.html',
  styleUrls: ['./brazil-demands.component.scss'],
})
export class BrazilDemandsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
