import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-expandable-manager',
  templateUrl: './table-expandable-manager.component.html',
  styleUrls: ['./table-expandable-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpandableManagerComponent implements OnInit {
  @Input() dataSourceTableManager: any = {};
  @Input() allocationForms!: FormGroup[];
  @Input() filterKaRetail: any = true;
  @Output('inputChanged') inputChangedEvent = new EventEmitter();
  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;

  translateYAbsoluteItem: number = 0;
  dataSource: any = [];
  displayedDef: any[] = [];
  headersDisplayDef: any[] = [];
  displayedColumns: any[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSourceTableManager.currentValue) {
      this.loadTable(changes.dataSourceTableManager.currentValue);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement?.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  loadTable(updateDataTable?: any) {
    this.dataSourceTableManager = updateDataTable;
    this.displayedDef =
      this.dataSourceTableManager[0].expandedTableResult.table.columns;
    this.displayedColumns = this.displayedDef
      .filter((item: any) => item.props === item.otherContent)
      .map((item: any) => item.props);
    this.headersDisplayDef = this.displayedDef.filter((header: any) =>
      this.displayedColumns.includes(header.props)
    );
  }

  toggleColumnTable(col: any, event: string) {
    const groupColumnsToShowHide = this.displayedDef.filter(
      (item: any) => item.otherContent === col.otherContent
    );
    const columsToShowHide = groupColumnsToShowHide.map(
      (item: any) => item.props
    );
    const indexCol = this.displayedColumns.findIndex(
      (item: any) => item === col?.otherContent
    );

    groupColumnsToShowHide.shift();
    columsToShowHide.shift();

    if (event === 'show') {
      col.expanded = true;
      groupColumnsToShowHide[0].isFirstCard = true;
      groupColumnsToShowHide[groupColumnsToShowHide.length - 1].isLastCard =
        true;
      groupColumnsToShowHide.forEach((column: any) => {
        column.expanded = true;
      });

      this.headersDisplayDef.splice(indexCol + 1, 0, ...groupColumnsToShowHide);
      this.displayedColumns.splice(indexCol + 1, 0, ...columsToShowHide);
    } else {
      col.expanded = false;
      this.headersDisplayDef.splice(
        indexCol + 1,
        groupColumnsToShowHide.length
      );
      this.displayedColumns.splice(indexCol + 1, columsToShowHide.length);
    }
    this.displayedColumns = [...this.displayedColumns];
  }
}
