<ngx-spinner></ngx-spinner>
<div class="container-lom">
  <div class="header-title">
    <div class="title">LOM</div>
    <div class="container-export">
      <button
        class="export-button"
        (click)="exportToExcelData()"
        matTooltip="Export"
        matTooltipPosition="left"
        [class.disabled]="disabledButtonExport"
        [disabled]="disabledButtonExport"></button>
      <!-- <button class="edit-to-book" (click)="prepareToEdit()">
        EDIT TO BOOK
      </button> -->
    </div>
  </div>
  <div class="tabs-lom">
    <app-tab [tabs]="tabs" [method]="this" #TabComponent> </app-tab>
    <div class="hide-filters">
      <a class="link-hide" (click)="toggleHideFilters()">{{
        textshowOrHideFilters
      }}</a>
    </div>
  </div>

  <div #ExecutionOverview>
    <div
      class="filters"
      [class.form-hide-show]="hidePartialContent"
      [@openClose]="hidePartialContent ? 'open' : 'closed'">
      <app-search
        [filters]="searchInputs"
        class="search"
        (searchEvent)="onSearchEvent($event)"
        [showMoreFiltersLink]="false"
        [showButtonApply]="true"
        [enableApplyFilter]="disableApply"
        (applyFilterEvent)="applyFilter()">
      </app-search>
    </div>
    <mat-divider></mat-divider>

    <section
      class="quarter-currentWeek-toBook"
      *ngIf="responseTable && !showNoResults">
      <div class="quarter">
        <h4>{{ this.quarterValue }}</h4>
      </div>
      <div class="container-reference-and-button">
        <span class="reference-table"
          ><b>CURRENT WEEK </b>{{ currentWeekReference | uppercase
          }}<b> TO BOOK </b>{{ toBookWeeks | uppercase }}
        </span>
      </div>
    </section>

    <ng-template #modalCreateEdit>
      <form
        class="form modalEditToBookLocation emea-location-edit-to-book-lsls">
        <header class="title-modal" matDialogTitle>EDIT TO BOOK</header>
        <mat-dialog-content class="test">
          <mat-form-field appearance="outline" class="aquin">
            <mat-label>To Book LOM</mat-label>
            <mat-select
              formControlName="holdFlag"
              name="holdFlag"
              disableOptionCentering>
              <mat-option class="input-emea-location-edit-to-book">{{
                'Week +0'
              }}</mat-option>
              <mat-option class="input-emea-location-edit-to-book">{{
                'Week +1'
              }}</mat-option>
              <mat-option class="input-emea-location-edit-to-book">{{
                'Week +2'
              }}</mat-option>
              <mat-option class="input-emea-location-edit-to-book">{{
                'Week +3'
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="button modal-cancel-button" mat-button matDialogClose>
            CANCEL
          </button>
          <button class="button modal-action-button" mat-button matDialogClose>
            CONFIRM
          </button>
        </mat-dialog-actions>
      </form>
    </ng-template>

    <ng-template #modalCreateEditToBookActions>
      <form
        class="form modalCreateEditToBookActions"
        [formGroup]="modalForm"
        (ngSubmit)="saveAction($event)">
        <header class="title-modal" matDialogTitle>EDIT WOI</header>
        <mat-dialog-content>
          <mat-form-field appearance="outline">
            <mat-label class="required">WOI</mat-label>
            <mat-select
              (selectionChange)="onWoiDaysSelectionChange($event)"
              formControlName="woiDays"
              name="woiDays"
              [value]="selectedDay">
              <mat-option
                *ngFor="let day of days"
                class="input-emea-location-edit-to-book-actions"
                [value]="day.value">
                {{ day.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="custom-form-field"
            [color]="'primary'">
            <mat-label>Target WOI</mat-label>
            <input
              matInput
              formControlName="woiTarget"
              name="woiTarget"
              id="WoiTargetInput"
              (input)="onTargetWOIInput($event)"
              (keypress)="validateKeyPress($event)"
              [value]="selectedWoiTarget" />
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button class="button modal-cancel-button" mat-button matDialogClose>
            CANCEL
          </button>
          <button type="submit" class="button modal-action-button" mat-button>
            SAVE
          </button>
        </mat-dialog-actions>
      </form>
    </ng-template>

    <!-- criar componete especifico para a table lom -->
    <div class="no-filter-selected-session" *ngIf="showNoResults">
      <p>NO RECORDS FOUND.</p>
    </div>

    <div class="container-table-lom">
      <table id="table">
        <thead *ngIf="this.responseTable">
          <tr>
            <th *ngFor="let header of this.filterAwaysVisibleColumns()">
              <div
                [ngClass]="{
                  'container-header': !expecificProp(header.props)
                }">
                <span
                  [ngClass]="{
                    'left-columns': expecificProp(header.props)
                  }"
                  >{{ header.title }}</span
                >
                <ng-container *ngIf="header.typeShowEvent">
                  <div
                    *ngIf="
                      header.props === header.otherContent &&
                      !header.hideIconExpand
                    "
                    class="cell-icon-arrow">
                    <mat-icon
                      *ngIf="!header.expanded"
                      (click)="toggleColumnTable(header, 'show')">
                      {{ 'arrow_left' }}
                    </mat-icon>

                    <mat-icon
                      *ngIf="header.expanded"
                      id="table"
                      (click)="toggleColumnTable(header, 'hide')">
                      {{ 'arrow_right' }}
                    </mat-icon>
                  </div>
                </ng-container>
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="this.responseTable">
          <ng-container
            *ngFor="let rows of this.responseTable.rows; let i = index">
            <tr
              id="body-lom"
              [ngClass]="{
                'hover-row': rows.woi.value < rows.woiTarget.value,
                'hover-value-yellow': rows.woi.value === rows.woiTarget.value,
                'hover-value-green': rows.woi.value > rows.woiTarget.value,
                'hover-row-value-gray':
                  rows.woiTarget.value === '-' || rows.woi.value === ''
              }">
              <ng-container
                *ngFor="let header of this.filterAwaysVisibleColumns()">
                <td
                  [ngClass]="{
                    'centrelize-items': isPropNumber(header.props),
                    'centrelize-items-right': isPropString(header.props)
                  }"
                  [class.row-value]="rows.woi.value < rows.woiTarget.value"
                  [class.row-value-yellow]="
                    rows.woi.value === rows.woiTarget.value
                  "
                  [class.row-value-green]="
                    rows.woi.value > rows.woiTarget.value
                  "
                  [class.row-value-gray]="
                    rows.woiTarget.value === '-' || rows.woi.value === ''
                  "
                  (click)="onEventActions($event, header.props, rows)">
                  <span [class.negative-value]="rows[header.props]?.value < 0">
                    <div
                      *ngIf="header.props === 'actions'"
                      class="icon-action"
                      (click)="editEvent(this.lomMockupResponse)"
                      matTooltip="Edit"
                      matTooltipPosition="left"></div>
                    {{ replaceDots(rows, header.props) }}
                  </span>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>

        <tfoot id="footer">
          <ng-container *ngIf="this.responseTable">
            <tr>
              <ng-container
                *ngFor="let header of this.filterAwaysVisibleColumns()">
                <td class="total">
                  <span *ngIf="header.props === 'product'" class="title-total"
                    >TOTAL
                  </span>
                  <ng-container>
                    <div class="total-value">
                      <app-value-currency
                        [class.negative-value]="
                          this.responseTable.totals.totalsByColumn[
                            header.props
                          ] < 0
                        "
                        [value]="
                          this.responseTable.totals.totalsByColumn[
                            header.props
                          ] === ''
                            ? ''
                            : this.responseTable.totals.totalsByColumn[
                                header.props
                              ]
                        ">
                      </app-value-currency>
                    </div>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tfoot>
      </table>
    </div>
  </div>

  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
