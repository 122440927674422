<section class="component-demand" *ngFor="let item of dataChart">
  <h1 class="title-chart">{{ item.title }}</h1>
  <div class="container-component">
    <div class="container-chart">
      <div echarts [options]="item.option"></div>
    </div>
    <div class="container-cards">
      <div class="card">
        <h4>{{ item.card[0].totalBrDemand }}</h4>
        <p>{{ valueCard(item.card[0].valueBrDemand) }}</p>
      </div>
      <div class="card">
        <h4>{{ item.card[1].totalMls }}</h4>
        <p>{{ valueCard(item.card[1].valueMls) }}</p>
      </div>
    </div>
  </div>
</section>
