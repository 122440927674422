import { Country } from './../../../interfaces/country.interface';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';

import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { Router } from '@angular/router';

import {
  InputBase,
  SelectSearchInput,
  MultiSelectSearchInput,
} from 'src/app/components/input/input-base';
import {
  customerSettingsFiltersMockList,
  customerSettingsTableMockList,
} from './customer-settings-mockup';
const STATUS = {
  ALL: { value: 'All', id: '' },
  ACTIVE: { value: 'Active', id: true },
  INACTIVE: { value: 'Inactive', id: false },
};
import { TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslatorService } from 'src/app/services/generic/translator.service';
@Component({
  selector: 'app-emea-customer-settings',
  templateUrl: './emea-customer-settings.component.html',
  styleUrls: ['./emea-customer-settings.component.scss'],
})
export class EmeaCustomerSettingsComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  responseFilters = customerSettingsFiltersMockList;
  searchInputs!: InputBase<any>[];
  pagination: Pagination;
  currentUser!: any;
  formSearchValues!: { [key: string]: any };
  filter: any = {};
  isModeTableOrCardsHide: boolean = true;
  result!: ResultInterface;
  customerSettingsForm: FormGroup;
  hidePagination: boolean = true;
  noResultCustomerSettings: boolean = false;
  isEdit: any = false;
  geo_id: any = {};

  crudService: CrudService<any>;
  crudServiceGeo: CrudService<any>;
  crudServiceEmeaCustomerSettings: CrudService<any>;
  crudServiceCustomerName: CrudService<any>;

  responseFiltersMockup = customerSettingsFiltersMockList;
  responseTableMockup = customerSettingsTableMockList;
  dataTableMockup: any[] = [];
  testDadosTable: any[] = [];

  geosEntity: any[] = [];
  countryEntity: any[] = [];
  countries: any[] = [];
  statusEntities: any[] = [];
  customerNameEntities: any[] = [];
  customerNumberEntities: any[] = [];

  geosFilter: any[] = [];
  countryFilter: any[] = [];
  allStatus: any[] = [];
  customerNameFilter: any[] = [];
  customerNumberFilter: any[] = [];

  event = {
    delete: 'delete',
    edit: 'edit',
  };

  statusEntity: any[] = [
    { name: 'Active', value: true },
    { name: 'Inactive', value: false },
  ];

  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.emeaCustomerSettings },
    },
    {
      entity: `emea-customer-settings/summary/customer-name`,
      query: { pageName: PAGE_NAME_RESOURCE.emeaCustomerSettings },
    },
    {
      entity: `emea-customer-settings/summary/customer-number`,
      query: { pageName: PAGE_NAME_RESOURCE.emeaCustomerSettings },
    },
  ];

  protected onDestroy$ = new Subject();

  public geoMultiCtrl: FormControl = new FormControl('');
  public geoMultiFilterCtrl: FormControl = new FormControl('');
  public filteredGeoMulti: ReplaySubject<any> = new ReplaySubject(1);

  public countryMultiCtrl: FormControl = new FormControl('');
  public countryMultiFilterCtrl: FormControl = new FormControl('');
  public filteredCountryMulti: ReplaySubject<any> = new ReplaySubject(1);

  public statusMultiCtrl: FormControl = new FormControl('');
  public statusMultiFilterCtrl: FormControl = new FormControl('');
  public filteredStatusMulti: ReplaySubject<any> = new ReplaySubject(1);

  public customerNameMultiCtrl: FormControl = new FormControl('');
  public customerNameMultiFilterCtrl: FormControl = new FormControl('');
  public filteredCustomerNameMulti: ReplaySubject<any> = new ReplaySubject(1);

  public customerNumberMultiCtrl: FormControl = new FormControl('');
  public customerNumberMultiFilterCtrl: FormControl = new FormControl('');
  public filteredCustomerNumberMulti: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private modalService: ModalActionsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    protected http: HttpClient,
    private permissionService: PermissionService,
    private router: Router,
    private toastr: ToastrService,
    private translatorService: TranslatorService
  ) {
    this.crudService = new CrudService<any>(this.http, 'user');
    this.crudServiceGeo = new CrudService<any>(this.http, 'geo');
    this.crudServiceCustomerName = new CrudService<any>(
      this.http,
      'emea-customer-settings/summary/customer-name'
    );
    this.crudServiceEmeaCustomerSettings = new CrudService<any>(
      this.http,
      'emea-customer-settings'
    );
    this.pagination = { page: 0, size: 10 };
    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = { ...this.filter, geo_id: this.currentUser.geoId };
    this.geo_id = { ...this.filter, geo_id: this.currentUser.geoId };

    this.customerSettingsForm = this.formBuilder.group({
      id: [''],
      geo: [''],
      countryId: [''],
      customerName: [''],
      customerNumber: [''],
      status: [''],
    });

    this.searchInputs = [
      new SelectSearchInput({
        key: 'geo_id',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
      }),
      new MultiSelectSearchInput({
        key: 'customerName',
        hover: 'Customer Name',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        limitedValue: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerNumber',
        hover: 'Customer Number',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        limitedValue: true,
      }),
    ];
  }

  protected filterGeoSingle() {
    if (!this.geosEntity) {
      return;
    }

    let search = this.geoMultiFilterCtrl.value;
    if (!search) {
      this.filteredGeoMulti.next(this.geosEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredGeoMulti.next(
      this.geosEntity.filter(item => item.geo.toLowerCase().startsWith(search))
    );
  }

  protected filterCountryMulti() {
    if (!this.countries) {
      return;
    }

    let search = this.countryMultiFilterCtrl.value;
    if (!search) {
      this.filteredCountryMulti.next(this.countries.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredCountryMulti.next(
      this.countries.filter(item =>
        item.country.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterStatusMulti() {
    if (!this.statusEntities) {
      return;
    }

    let search = this.statusMultiFilterCtrl.value;
    if (!search) {
      this.filteredStatusMulti.next(this.statusEntities.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredStatusMulti.next(
      this.statusEntities.filter(item =>
        item.value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCustomerNameMulti() {
    if (!this.customerNameEntities) {
      return;
    }

    let search = this.customerNameMultiFilterCtrl.value;
    if (!search) {
      this.filteredCustomerNameMulti.next(this.customerNameEntities.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredCustomerNameMulti.next(
      this.customerNameEntities.filter(item =>
        item.value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCustomerNumberMulti() {
    if (!this.customerNumberFilter) {
      return;
    }

    let search = this.customerNumberMultiFilterCtrl.value;
    if (!search) {
      this.filteredCustomerNumberMulti.next(
        this.customerNumberEntities.slice()
      );
      return;
    }
    search = search.toLowerCase();

    this.filteredCustomerNumberMulti.next(
      this.customerNumberEntities.filter(item =>
        item.value.toLowerCase().startsWith(search)
      )
    );
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getDataFilter(this.entities).then(() =>
      this.getCustomerSettings(this.pagination || undefined, this.filter)
    );
  }

  getCustomerSettings(page?: any, params?: any) {
    this.spinner.show();
    if (!params) {
      params = {};
    }
    params.geo_id = this.currentUser.geoId;
    params.pageName = PAGE_NAME_RESOURCE.emeaCustomerSettings;

    this.crudServiceEmeaCustomerSettings
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        if (response.rows.length > 0) {
          this.updateComponents(response);
          this.noResultCustomerSettings = false;
          this.spinner.hide();
        } else {
          this.noResultCustomerSettings = true;
          this.spinner.hide();
        }
      });
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getCustomerSettings(paginated, this.filter);
  }

  dataFilterGeo(geosEntity: any) {
    const initialValueGeo: { [key: string]: any } = {};

    geosEntity.forEach((item: Geo) => {
      this.geosFilter.push({ value: item.geo, id: item.id });
      if (item.id === this.currentUser.geoId) {
        initialValueGeo.value = item.geo;
        initialValueGeo.id = item.id;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geo_id',
      hover: 'GEO',
      hasAutocomplete: true,
      options: this.geosFilter,
      value: initialValueGeo.value,
      disabled: true,
      notClearable: true,
      classCss: 'customer-settings-filters',
    });

    this.searchInputs[0] = selectGeo;
    this.searchInputs[0].notClearable = true;
  }

  dataFilterCountry(countryEntity: any) {
    countryEntity.forEach((item: Country) => {
      this.countryFilter.push({ value: item.country });
    });

    const selectCountry = new MultiSelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      hasAutocomplete: true,
      options: this.countryFilter,
      classCss: 'customer-settings-filters',
    });

    this.searchInputs[1] = selectCountry;
  }

  dataFilterStatus() {
    this.allStatus.push(STATUS.ALL, STATUS.ACTIVE, STATUS.INACTIVE);

    const selectStatus = new SelectSearchInput({
      key: 'status',
      hover: 'Status',
      hasAutocomplete: true,
      classCss: 'col-status-customer-settings',
      options: this.allStatus,
      value: STATUS.ALL.value,
    });

    this.searchInputs[2] = selectStatus;
  }

  dataFilterCustomerName(customerNameEntities: any) {
    customerNameEntities.forEach((item: any) => {
      if (item.customerName) {
        this.customerNameFilter.push({
          value: item.customerName,
          id: item.id,
        });
      }
    });

    const selectCustomerName = new MultiSelectSearchInput({
      key: 'customerName',
      hover: 'Customer Name',
      hasAutocomplete: true,
      options: this.customerNameFilter,
      classCss: 'customer-settings-filters',
      limitedValue: true,
    });

    this.searchInputs[3] = selectCustomerName;
  }

  dataFilterCustomerNumber(customerNumberEntities: any) {
    customerNumberEntities.forEach((item: any) => {
      this.customerNumberFilter.push({
        value: item.customerNumber,
        id: item.id,
      });
    });

    const selectCustomerNumber = new MultiSelectSearchInput({
      key: 'customerNumber',
      hover: 'Customer Number',
      hasAutocomplete: true,
      options: this.customerNumberFilter,
      classCss: 'customer-settings-filters',
      limitedValue: true,
    });

    this.searchInputs[4] = selectCustomerNumber;
  }

  async getDataFilter(filters: any) {
    this.filter = [];
    this.spinner.show();
    this.geosFilter = [];
    this.countryFilter = [];
    this.allStatus = [];
    this.customerNameFilter = [];
    this.customerNumberFilter = [];

    this.crudServiceEmeaCustomerSettings
      .getDataFilters(filters)
      .subscribe((response: any[]) => {
        [
          this.geosEntity,
          this.customerNameEntities,
          this.customerNumberEntities,
        ] = response;

        this.dataFilterGeo(this.geosEntity);
        this.dataFilterStatus();
        this.dataFilterCustomerName(this.customerNameEntities);
        this.dataFilterCustomerNumber(this.customerNumberEntities);

        this.crudService
          .getEntity(`country?geo_id=${this.geo_id.geo_id}`, {
            distinct: 'country',
            pageName: PAGE_NAME_RESOURCE.emeaCustomerSettings,
          })
          .subscribe((response: any) => {
            this.countryEntity = response;
            this.dataFilterCountry(this.countryEntity);
            this.searchInputs = [...this.searchInputs];
          });

        this.crudService
          .getEntity(`country?geo_id=${this.geo_id.geo_id}`, {
            pageName: PAGE_NAME_RESOURCE.emeaCustomerSettings,
          })
          .subscribe((response: any) => {
            this.countries = response;
          });

        this.searchInputs = [...this.searchInputs];
      });
  }

  dataFormSearchValuesGeo(formSearchValuesGeo: any) {
    if (formSearchValuesGeo.geo_id) {
      const currentGeo = this.geosEntity.filter((item: any) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchValuesGeo.geo_id.toLocaleUpperCase())
      );
      formSearchValuesGeo.geo_id = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchValuesGeo.geo_id = '';
    }
  }

  dataFormSearchValuesCountryId(formSearchValuesCountryId: any) {
    if (formSearchValuesCountryId.countryId) {
      const onlyCountriesValues = formSearchValuesCountryId.countryId.map(
        (item: any) => item.value
      );
      const filteredCountries = this.countries.filter(country =>
        onlyCountriesValues.includes(country.country)
      );
      formSearchValuesCountryId.countryId = filteredCountries.map(
        country => country.id
      );
    } else {
      formSearchValuesCountryId.countryId = '';
    }
  }

  dataFormSearchValuesStatus(formSearchValuesStatus: any) {
    if (formSearchValuesStatus.status === STATUS.ACTIVE.value) {
      formSearchValuesStatus.status = true;
    } else if (formSearchValuesStatus.status === STATUS.INACTIVE.value) {
      formSearchValuesStatus.status = false;
    } else {
      formSearchValuesStatus.status = '';
    }
  }

  dataFormSearchValuesCustomerName(formSearchValuesCustomerName: any) {
    if (formSearchValuesCustomerName.customerName) {
      const currentCustomerName = formSearchValuesCustomerName.customerName.map(
        (item: any) => item.value
      );
      formSearchValuesCustomerName.customerName = currentCustomerName;
    } else {
      formSearchValuesCustomerName.customerName = '';
    }
  }

  dataFormSearchValuesCustomerNumber(formSearchValuesCustomerName: any) {
    if (formSearchValuesCustomerName.customerNumber) {
      const currentCustomerNumber =
        formSearchValuesCustomerName.customerNumber.map(
          (item: any) => item.value
        );
      formSearchValuesCustomerName.customerNumber = currentCustomerNumber;
    } else {
      formSearchValuesCustomerName.customerNumber = '';
    }
  }

  onSearchEvent(value: any) {
    this.formSearchValues = { ...value };

    this.dataFormSearchValuesGeo(this.formSearchValues);
    this.dataFormSearchValuesCountryId(this.formSearchValues);
    this.dataFormSearchValuesStatus(this.formSearchValues);
    this.dataFormSearchValuesCustomerName(this.formSearchValues);
    this.dataFormSearchValuesCustomerNumber(this.formSearchValues);

    this.pagination.page = 0;
    this.filter = { ...this.formSearchValues };
    this.getCustomerSettings(this.pagination, this.filter);
  }

  updateComponents(response: any) {
    this.result = {
      table: {
        columns: [
          {
            label: 'Geo',
            prop: 'geo',
            sticky: true,
          },
          {
            label: 'Country',
            prop: 'country',
            sticky: true,
          },
          {
            label: 'Customer Name',
            prop: 'customerName',
            sticky: true,
          },
          {
            label: 'Customer Number',
            prop: 'customerNumber',
            sticky: true,
          },
          {
            label: 'Status',
            prop: 'status',
            sticky: true,
          },
        ],
        rows: new MatTableDataSource<any>(
          this.dataTables(JSON.stringify(response.rows))
        ),
      },
      pageIndex: response.page,
      pageSize: response.totalRows,
      pagesTotal: response.totalPages,
    };
    const actions = this.permissionService.setTablePermissions(
      'emea-customer-settings'
    );
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataMapToTable(data: any): any[] {
    return JSON.parse(data).map((item: any) => {
      return {
        id: item.id,
        geo: item.country.geos.geo,
        country: item.country.country,
        customerName: item.customerName,
        customerNumber: item.customerNumber,
        status: item.status,
      };
    });
  }

  dataTables(data: any) {
    const array = this.dataMapToTable(data);
    const dataTables: any[] = [];

    array.forEach((item: any) => {
      dataTables.push(item);
      item.status = item.status ? 'Active' : 'Inactive';
    });
    return [...dataTables];
  }

  clearGeo(event: any) {
    this.geoMultiCtrl.setValue(null);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.prepareToEdit(event.item.id);
    }
  }

  changeGeoMultiCtrl() {
    this.geoMultiCtrl.setValue(null);
    this.filteredGeoMulti.next(this.geosEntity.slice());
    this.geoMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterGeoSingle();
      });
  }

  changeCountryMultiFilterCtrl() {
    this.countryMultiCtrl.setValue(null);
    this.filteredCountryMulti.next(this.countries.slice());
    this.countryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMulti();
      });
  }

  changeStatusMultiFilterCtrl() {
    this.statusMultiCtrl.setValue(null);
    this.filteredStatusMulti.next(this.statusEntities.slice());
    this.statusMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterStatusMulti();
      });
  }

  changeCustomerNameMultiCtrl() {
    this.customerNameMultiCtrl.setValue(null);
    this.filteredCustomerNameMulti.next(this.customerNameEntities.slice());
    this.customerNameMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerNameMulti();
      });
  }

  changeCustomerNumberMultiCtrl() {
    this.customerNumberMultiCtrl.setValue(null);
    this.filteredCustomerNumberMulti.next(this.customerNumberEntities.slice());
    this.customerNumberMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerNumberMulti();
      });
  }

  prepareToEdit(data: any) {
    // preenche os campos do edit
    this.changeGeoMultiCtrl();
    this.changeCountryMultiFilterCtrl();
    this.changeStatusMultiFilterCtrl();
    this.changeCustomerNameMultiCtrl();
    this.changeCustomerNumberMultiCtrl();

    this.spinner.show();
    this.isEdit = true;

    // valor default dos filros
    this.crudServiceEmeaCustomerSettings
      .fetchEntity(data)
      .subscribe((item: any) => {
        this.countryMultiCtrl.setValue(item.countryId);
        this.geoMultiCtrl.setValue(item.country.geo_id);
        this.customerNumberMultiCtrl.setValue(item.customerNumber);
        this.customerNameMultiCtrl.setValue(item.customerName);
        this.statusMultiCtrl.setValue(item.status);

        this.customerSettingsForm.controls.id.setValue(item.id);
        this.customerSettingsForm.controls.countryId.setValue(item.countryId);
        this.customerSettingsForm.controls.geo.setValue(item.country.geo_id);
        this.customerSettingsForm.controls.customerNumber.setValue(
          item.customerNumber
        );
        this.customerSettingsForm.controls.customerName.setValue(
          item.customerName
        );
        this.customerSettingsForm.controls.status.setValue(item.status);

        const modal = this.modalService.createModal(this.modalCreateEdit);
        modal.afterClosed().subscribe(() => {
          this.customerSettingsForm.reset();
        });
        this.spinner.hide();
      });
  }

  resetFormModalCreateEdit() {
    this.customerSettingsForm.reset();
  }

  onUpdate() {
    this.isEdit = true;
    let updateAllowed = true;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);

    dialog.afterClosed().subscribe((result: any) => {
      this.spinner.show();

      const customer: any = this.customerSettingsForm.controls.status.value;
      const customerId: any = this.customerSettingsForm.controls.id.value;

      if (result) {
        if (updateAllowed) {
          this.crudServiceEmeaCustomerSettings
            .updateEntity(customerId, {
              status: customer,
            })
            .subscribe(
              () => {
                this.toastr.success(
                  `${this.translatorService.getMessage(
                    'CRUD_SUCCESS_UPDATED'
                  )}`,
                  `${this.translatorService.getTitle(
                    'CRUD_SUCCESS_UPDATED'
                  )} Association`
                );
                this.modalService.closeAll();
                this.customerSettingsForm.reset();
                this.resetFormModalCreateEdit();
                this.getCustomerSettings(
                  this.pagination || undefined,
                  this.filter
                );
              },
              (err: any) => {
                this.toastr.error(err.error.message, err.error.title);
                this.spinner.hide();
              }
            );
        }
      }
      this.spinner.hide();
    });
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }
}
