import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  GoogleLoginProvider, LoginProvider,
  SocialUser, SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  AsyncSubject, BehaviorSubject, Observable, ReplaySubject,
} from 'rxjs';

import { TokenProvider } from 'src/app/helpers/authorization/token-provider';
import { environment } from 'src/environments/environment';
import { SharedinfoService } from '../generic/sharedinfo.service';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  public user: SocialUser | undefined;
  private providers: Map<string, LoginProvider> = new Map();
  private autoLogin: boolean = false;
  private initialized: boolean = false;
  private authState$: ReplaySubject<SocialUser> = new ReplaySubject(1);
  private initState$: AsyncSubject<boolean> = new AsyncSubject();
  private inputData = new BehaviorSubject<any>(true);
  currentInputData = this.inputData.asObservable();

  constructor(
    @Inject('SocialAuthServiceConfig') config?: SocialAuthServiceConfig | Promise<SocialAuthServiceConfig>,
    private http?: HttpClient,
    private router?: Router,
    private sharedService?: SharedinfoService,
  ) {
    if (config instanceof Promise) {
      config.then((responseConfig) => this.initialize(responseConfig));
    } else {
      this.initialize(config);
    }
  }

  setInputData(data: any) {
    this.inputData.next(data);
  }

  get authState(): Observable<SocialUser> {
    return this.authState$.asObservable();
  }

  get initState(): Observable<boolean> {
    return this.initState$.asObservable();
  }

  public getInstance(): Window {
    return window;
  }

  public isLoggedIn(): boolean {
    return this.getToken() !== null;
  }

  public signIn(data: any) {
    return this.http?.post<SocialUser>(environment.urlAuth, data);
  }

  public storeToken(data: any) {
    localStorage.setItem('access_token', data);
  }

  public getToken(): any {
    return localStorage.getItem('access_token');
  }

  public storeUserPermissions(data: any) {
    localStorage.setItem('user_permissions', JSON.stringify(data) || '');
  }

  public getUserPermissions(): any {
    const userPermissions = localStorage.getItem('user_permissions');
    return userPermissions ? JSON.parse(userPermissions) : [];
  }

  public refreshAuthToken(providerId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.initialized) {
        reject(TokenProvider.ERR_NOT_INITIALIZED);
      } else if (providerId !== GoogleLoginProvider.PROVIDER_ID) {
        reject(TokenProvider.ERR_NOT_SUPPORTED_FOR_REFRESH_TOKEN);
      } else {
        const providerObject = this.providers.get(providerId);
        if (providerObject) {
          providerObject.getLoginStatus({ refreshToken: true })
            .then((user: SocialUser) => {
              user.provider = providerId;
              this.user = user;
              this.authState$.next(user);
              window.localStorage.setItem('access_token', user.idToken);
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject(TokenProvider.ERR_LOGIN_PROVIDER_NOT_FOUND);
        }
      }
    });
  }

  public signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_permissions');
    localStorage.removeItem('notifications');
    localStorage.removeItem('disabled_button_export_ppm');
    localStorage.removeItem('last_update');
    this.router?.navigate(['authorization']);
    this.sharedService?.emitChange({ isAuthorized: false });
  }

  async startRefreshTokenTimer() {
    const token = this.getToken();
    if (!!token) {
      const googleId = GoogleLoginProvider.PROVIDER_ID;
      await this.refreshAuthToken(googleId);
      return true;
    }
    return false;
  }

  private initialize(config?: SocialAuthServiceConfig) {
    this.autoLogin = config?.autoLogin === false ? config.autoLogin : false;
    config?.providers?.forEach((item) => {
      this.providers.set(item.id, item.provider);
    });

    Promise.all(Array.from(this.providers.values())
      .map((provider) => provider.initialize()))
      .then(() => {
        if (this.autoLogin) {
          const loginStatusPromises: any[] = [];
          let loggedIn = false;
          this.providers.forEach((provider: LoginProvider, key: string) => {
            const promise = provider.getLoginStatus();
            loginStatusPromises.push(promise);
            promise.then((user: SocialUser) => {
              user.provider = key;
              this.user = user;
              this.authState$.next(user);
              loggedIn = true;
            });
          });
          Promise.all(loginStatusPromises).catch(() => {
            if (!loggedIn) {
              this.user = undefined;
              this.authState$.next(undefined);
            }
          });
        }
      })
      .catch((error) => {
        error(error);
      })
      .finally(() => {
        this.initialized = true;
        this.initState$.next(this.initialized);
        this.initState$.complete();
      });
  }
}
