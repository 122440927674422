export const orderAllocationMockup: any = [{
  "territory": "France",
  "customer": "Lenovo",
},
{
  "territory": "Benelux",
  "customer": "Modelabs",
},
{
  "territory": "Iberia",
  "customer": "B2W",
},
{
  "territory": "Israel",
  "customer": "ALLIED",
},
{
  "territory": "Central/Eastern Europe & Nordics",
  "customer": "AMAZON",
},
{
  "territory": "Middle East & Africa",
  "customer": "PONTO FRIO",
},
{
  "territory": "UK & Ireland",
  "customer": "NRO",
}]
