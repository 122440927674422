<div #tableBody class="table-body">
  <table mat-table [dataSource]="dataSource">
    <ng-container
      *ngFor="let colDef of columnsLabel; let i = index; trackBy: trackByIndex">
      <ng-container
        *ngIf="colDef.sticky && !colDef.wowVariation"
        [matColumnDef]="colDef.label">
        <div
          *ngIf="
            colDef.label !== 'actions' &&
            colDef.label !== 'permissions' &&
            colDef.label !== 'customer number'
          ">
          ''
          <div *ngIf="colDef.allowSelection; else columnTitleBlock">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()">
              </mat-checkbox>
            </th>
          </div>
          <ng-template #columnTitleBlock>
            <ng-container>
              <th
                mat-header-cell
                [class.align-column-and-row]="
                  !hasStatus &&
                  !hasComma &&
                  hasCalculation &&
                  colDef.prop !== 'marketName' &&
                  colDef.prop !== 'product'
                "
                [class.align-columns]="
                  !hasStatus &&
                  !hasComma &&
                  !hasCalculation &&
                  colDef.prop !== 'product' &&
                  colDef.prop !== 'dataType'
                "
                *matHeaderCellDef
                [ngStyle]="colDef.style">
                {{ colDef.label }}
              </th>
            </ng-container>
          </ng-template>
          <div *ngIf="colDef.allowSelection; else columnDataBlock">
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? checkboxToggle(element) : null"
                [checked]="selection.isSelected(element)"
                [aria-label]="checkboxLabel(element)">
              </mat-checkbox>
            </td>
          </div>
          <ng-template #columnDataBlock>
            <td
              mat-cell
              [class.align-column-and-row]="
                !hasStatus &&
                !hasComma &&
                hasCalculation &&
                colDef.prop !== 'marketName' &&
                colDef.prop !== 'product'
              "
              [class.current-week]="colDef.currentWeek"
              [class.teste]="
                !hasStatus &&
                !hasComma &&
                !hasCalculation &&
                colDef.prop !== 'product' &&
                colDef.prop !== 'dataType'
              "
              *matCellDef="let element"
              [ngStyle]="colDef.style">
              <label
                *ngIf="hasStatus && colDef.prop !== 'colorPick'"
                [class.status-disable]="
                  element['active'] === 'Inactive' || !element['active']
                ">
                {{ colDef.prop ? element[colDef.prop] : element[colDef.label] }}
              </label>
              <!--color pick ka management-->
              <label
                *ngIf="colDef.prop === 'colorPick'"
                [ngStyle]="{
                  'background-color': element[colDef.prop]
                }"
                class="circle-color-pick"></label>
              <label
                *ngIf="
                  !hasStatus &&
                  !hasComma &&
                  !hasCalculation &&
                  element[colDef.prop] === 'Wow Delta'
                "
                class="wow-delta"
                >{{
                  colDef.prop ? element[colDef.prop] : element[colDef.label]
                }}
              </label>
              <label
                *ngIf="
                  !hasStatus &&
                  !hasComma &&
                  !hasCalculation &&
                  colDef.prop === 'check' &&
                  element[colDef.prop] !== 'Wow Delta'
                "
                [class.text-green-check]="element[colDef.prop] > 0"
                [class.text-red-check]="element[colDef.prop] < 0">
                <app-value-currency
                  [value]="
                    colDef.prop ? element[colDef.prop] : element[colDef.label]
                  ">
                </app-value-currency>
              </label>
              <label
                *ngIf="
                  !hasStatus &&
                  !hasComma &&
                  !hasCalculation &&
                  colDef.prop !== 'check' &&
                  element[colDef.prop] !== 'Wow Delta'
                "
                [class.text-green]="element[colDef.prop] > 0"
                [class.text-red]="element[colDef.prop] < 0"
                ><app-value-currency
                  [value]="
                    colDef.prop ? element[colDef.prop] : element[colDef.label]
                  ">
                </app-value-currency>
              </label>
              <label *ngIf="!hasStatus && !hasComma && hasCalculation">
                <app-value-currency
                  [class.negative-value]="element[colDef.prop]?.value < 0"
                  [value]="
                    colDef.prop
                      ? element[colDef.prop].value
                      : element[colDef.label].value
                  "></app-value-currency>
              </label>
              <!-- flex one plan variation -->
              <label *ngIf="!hasStatus && hasComma">
                <app-value-currency
                  [class.negative-value]="element[colDef.prop] < 0"
                  [value]="
                    isNumberFlexOnePlanVariation(element[colDef.prop])
                  "></app-value-currency>
              </label>
            </td>
            <td mat-footer-cell *matFooterCellDef="let element">
              <span
                class="table-row-total-spacer"
                *ngIf="
                  colDef.prop === 'marketName' || colDef.prop === 'product'
                "
                >TOTAL</span
              >
              <span>
                <app-value-currency
                  [value]="
                    colDef.totalAddedValue === '' ? '-' : colDef.totalAddedValue
                  "></app-value-currency>
              </span>
            </td>
          </ng-template>
        </div>
        <div *ngIf="colDef.label === 'permissions'">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <img
              class="icon-permission"
              src="../../../assets/images/ico-permission.svg"
              (click)="systemFunction(element)"
              matTooltip="Permissions"
              matTooltipPosition="left" />
          </td>
        </div>
        <div *ngIf="colDef.delete || colDef.edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <img
              *ngIf="colDef.view && !element.viewNotActive"
              class="icon-action"
              src="../../../assets/images/ico-read.svg"
              (click)="viewEvent(element)"
              matTooltip="View"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.view && element.viewNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/ico-read-disabled.svg" />
            <img
              *ngIf="colDef.edit && !element.editNotActive"
              class="icon-action"
              src="../../../assets/images/ico-edit.svg"
              (click)="editEvent(element)"
              matTooltip="Edit"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.edit && element.editNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/ico-edit-disable.svg"
              matTooltip="{{ tooltipMessage }}"
              matTooltipPosition="left" />
            <img
              *ngIf="colDef.delete && !element.deleteNotActive"
              class="icon-action"
              src="../../../assets/images/icon-delete.svg"
              (click)="deleteEvent(element)"
              matTooltip="Delete"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.delete && element.deleteNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/icon-delete-disabled.svg" />
          </td>
        </div>
        <div *ngIf="colDef.run">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <div
              *ngIf="colDef.run"
              id="svg-container"
              class="rotate-animation icon-run-action"
              matTooltip="Run"
              (click)="systemFunction(element)"
              [matTooltipPosition]="'left'">
              <svg
                #svgElement
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                (click)="rotateSvg(svgElement)">
                <path
                  d="M12.5 20C12.5 15.8545 16 12.5 20 12.5C21.9219 12.5 24 13.5 25.5 15.5C25.5 15.5 25.5 14.375 25.5 14.0341C25.5 13.6932 25.75 13 26.5 13C27.25 13 27.5 13.6932 27.5 14.0341C27.5 14.375 27.5 19 27.5 19H23.75C23.75 19 22.5 19 22.5 18C22.5 17 23.75 17 23.75 17H24C23.5 16 22 14.5 20 14.5C17 14.5 14.5 16.6114 14.5 20H12.5Z"
                  fill="#FF554D" />
                <path
                  d="M27.5 20C27.5 24.1455 24 27.5 20 27.5C18.0781 27.5 16 26.5 14.5 24.5C14.5 24.5 14.5 25.625 14.5 25.9659C14.5 26.3068 14.25 27 13.5 27C12.75 27 12.5 26.3068 12.5 25.9659C12.5 25.625 12.5 21 12.5 21H16.25C16.25 21 17.5 21 17.5 22C17.5 23 16.25 23 16.25 23H16C16.5 24 18 25.5 20 25.5C23 25.5 25.5 23.3886 25.5 20H27.5Z"
                  fill="#FF554D" />
              </svg>
            </div>
          </td>
        </div>
      </ng-container>
      <!-- Handle data to fiscal calendar with grouping of rows -->
      <ng-container
        *ngIf="!colDef.sticky && tableGrouping && !colDef.wowVariation"
        [matColumnDef]="colDef.label">
        <th mat-header-cell *matHeaderCellDef>{{ colDef.label }}</th>
        <tr>
          <td
            mat-cell
            [ngClass]="isWichMonth(element)"
            *matCellDef="let element"
            [ngStyle]="colDef.style">
            <label *ngIf="element[colDef.prop]['showValue']">{{
              element[colDef.prop]['value']
            }}</label>
          </td>
        </tr>
      </ng-container>
      <ng-container
        *ngIf="colDef.wowVariation && colDef.sticky"
        [matColumnDef]="colDef.label">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
          {{ colDef.prop }}
        </th>
        <td mat-cell *matCellDef="let element">
          <label
            *ngIf="
              currentColumn(colDef.label) &&
              !isProduct(colDef.label) &&
              !isVariation(colDef.label)
            "
            class="bg-blue-wow">
            <span>
              <app-value-currency
                [value]="element[colDef.label].value"></app-value-currency>
            </span>
          </label>
          <label
            *ngIf="
              !currentColumn(colDef.label) &&
              !isProduct(colDef.label) &&
              !isVariation(colDef.label)
            "
            class="text-wow"
            [ngStyle]="{ color: element[colDef.label + '_color'] }">
            <span>
              <app-value-currency
                [value]="element[colDef.label].value"></app-value-currency>
            </span>
          </label>
          <label
            *ngIf="
              isProduct(colDef.label) &&
              !currentColumn(colDef.label) &&
              !isVariation(colDef.label)
            ">
            <span>
              <app-value-currency
                [value]="element[colDef.label].value"></app-value-currency>
            </span>
          </label>
          <label
            *ngIf="
              isVariation(colDef.label) &&
              !isProduct(colDef.label) &&
              !currentColumn(colDef.label)
            "
            class="text-variation">
            <span>
              <app-value-currency
                [value]="element[colDef.label].value"></app-value-currency>
            </span>
          </label>
        </td>

        <td mat-footer-cell *matFooterCellDef="let element">
          <span
            *ngIf="colDef.label === 'product'"
            class="table-row-total-spacer"
            >TOTAL</span
          >
          <span>
            <app-value-currency
              *ngIf="colDef.label !== 'product' && colDef.label !== 'origin'"
              [value]="
                colDef.totalAddedValue === '' ? '-' : colDef.totalAddedValue
              "></app-value-currency>
          </span>
        </td>
      </ng-container>
      <!--end of handle-->
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <div *ngIf="!tableGrouping">
      <tr
        mat-row
        class="row-color"
        [class.brand]="i % 2 !== 0"
        *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </div>
    <div *ngIf="tableGrouping">
      <tr
        mat-row
        class="row-color"
        class="example-element-row"
        *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </div>
    <div *ngIf="(!hasStatus && !hasComma && hasCalculation) || hasTotalRow">
      <tr
        mat-footer-row
        class="table-row-total"
        *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </div>
  </table>
</div>
