import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DinamicFormService } from 'src/app/services/generic/dynamic-form.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { InputBase } from '../input/input-base';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '140px',
        })
      ),
      state(
        'closed',
        style({
          overflow: 'hidden',
        })
      ),
      transition('open <=> closed', [animate('0.4s 0.1s ease-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() filters: InputBase<string>[] | null = [];
  @Output() searchEvent = new EventEmitter<any>();
  @Input() fullWidthRow: boolean = false;
  @Input() showMoreFiltersLink: boolean = false;
  @Input() showButtonApply: boolean = false;
  @Input() showMoreFiltersLinkWithMinWidth: boolean = false;
  @Input() enableApplyFilter: boolean = false;
  @Input() typeGEO: string = '';
  @Output() applyFilterEvent = new EventEmitter<any>();
  isHeightModified: boolean = false;
  hidePartialContent: boolean = false;
  textshowOrHideFilters: String = 'All filters';
  form!: FormGroup;
  payLoad = '';

  constructor(private qcs: DinamicFormService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters?.currentValue) {
      this.filters = [...changes.filters.currentValue];
      changes.filters.currentValue.forEach((filter: any) => {
        if (filter.disabled) {
          this.form?.controls[filter.key].disable();
        } else {
          this.form?.controls[filter.key]?.enable();
        }
      });
    }
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.filters as InputBase<string>[]);
    this.filters!.forEach(filter => {
      if (!filter.classCss) {
        filter.classCss = `${this.filters!.length < 5 ? 4 : 6}`;
      }
    });
  }

  onSearchEvent(value: any) {
    this.searchEvent.emit(value);
  }

  applyFilter() {
    this.applyFilterEvent.emit();
  }

  toggleFilters() {
    if (this.hidePartialContent) {
      this.textshowOrHideFilters = 'All filters';
    } else {
      this.textshowOrHideFilters = 'Less filters';
    }
    this.hidePartialContent = !this.hidePartialContent;
    this.isHeightModified = !this.isHeightModified;
  }
}
