<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">CHANNEL</span>
    <button
      *ngIf="hasPermissionCreate"
      class="button modal-action-button"
      mat-button
      (click)="prepareToCreate()">
      NEW CHANNEL
    </button>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results
    [data]="result"
    (eventActions)="onEventActions($event)"
    (resultPaginator)="onChangePaginator($event)">
  </app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>
<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="channelForm">
    <header class="title-modal" matDialogTitle>
      {{ isEdit ? 'EDIT CHANNEL' : 'NEW CHANNEL' }}
    </header>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <mat-icon class="material-icons align-arrow-right"
          >arrow_drop_down</mat-icon
        >
        <input
          type="text"
          formControlName="country_id"
          matInput
          [matAutocomplete]="countryAutocomplete" />
        <mat-autocomplete
          #countryAutocomplete="matAutocomplete"
          autoActiveFirstOption
          disableOptionCentering
          panelClass="panel-select-channel">
          <mat-option
            *ngFor="let country of filteredOptions | async"
            [value]="country.country"
            matTooltip="{{ country.country }}">
            {{ country.country }}
          </mat-option>
        </mat-autocomplete>
        <button
          *ngIf="channelForm.controls['country_id'].value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="channelForm.controls['country_id'].reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Channel</mat-label>
        <input
          matInput
          formControlName="channel"
          autocomplete="off"
          maxlength="50"
          appInputAlphanumeric />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input
          matInput
          formControlName="description"
          autocomplete="off"
          maxlength="150"
          appInputAlphanumeric />
      </mat-form-field>
      <br />
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        *ngIf="!isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onCreate()">
        SAVE
      </button>
      <button
        *ngIf="isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onUpdate()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    {{ isEdit ? 'Do you confirm to edit CHANNEL?' : messageModalDelete }}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
