<div class="mat-elevation-z6 table-pagination">
  <div class="table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      *ngIf="!noRecordFoundRolloverReport">
      <ng-container
        *ngFor="let column of displayedColumns"
        [matColumnDef]="column.props">
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{
            'number-values': hasNumberValues(column.props)
          }">
          {{ column.title | uppercase }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="dcm-rollover"
          [ngClass]="{
            'number-values': columnWithNumberValues(element[column.props]),
            'negative-values':
              columnWithNumberValues(element[column.props]) &&
              element[column.props] < 0
          }">
          <ng-container *ngIf="columnWithNullValues(element[column.props])">
            -
          </ng-container>
          <!-- pipe `number` para formatar valores numéricos -->
          <ng-container
            *ngIf="
              columnWithNumberValues(element[column.props]);
              else notNumber
            ">
            {{ element[column.props] | number }}
          </ng-container>
          <ng-template #notNumber>
            <div
              [ngClass]="{
                'executed-value': columnStatusExecuted(
                  column.props,
                  element[column.props]
                ),
                'not-executed-value': columnStatusNotExecuted(
                  column.props,
                  element[column.props]
                )
              }">
              {{ element[column.props] }}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnProps; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnProps"></tr>
    </table>
  </div>

  <div class="no-filter-selected-session" *ngIf="noRecordFoundRolloverReport">
    <p>NO RECORD FOUND</p>
  </div>

  <mat-paginator
    [pageSize]="10"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
    [hidePageSize]="true"
    class="paginator-dcm"
    (page)="handlePaginator($event)">
  </mat-paginator>
</div>
