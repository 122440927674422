<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="header-title">
    <span class="title">OVERBOOKED</span>
    <div class="container-export">
      <span
        class="material-icons"
        (click)="exportTable(tabs)"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="!isValid"
          [disabled]="!isValid"></button>
      </span>
    </div>
  </div>
  <div class="to-book-content">
    <app-tab
      [tabs]="tabs"
      [method]="this"
      [messagesDialog]="messages"
      [isEditTableMode]="isEditTableMode"
      (click)="tabClick($event)"
      #TabComponent></app-tab>
  </div>

  <ng-template #OrderAdmin>
    <app-search
      (searchEvent)="onSearchEvent($event, 'orderAdmin')"
      (applyFilterEvent)="applyFilter('orderAdmin')"
      [filters]="searchInputsOrderAdmin"
      [showButtonApply]="true"
      [enableApplyFilter]="enableApplyFilterOrderAdmin"
      [showMoreFiltersLink]="true"
      [showMoreFiltersLinkWithMinWidth]="true">
    </app-search>
    <mat-divider></mat-divider>
    <section class="header-table-allocation">
      <div class="title-section">
        <h3 class="t-section">Order Admin</h3>
        <h4 class="st-section"></h4>
      </div>
      <!-- <div class="button-filter">
        <button
          *ngFor="let item of filterTable"
          (click)="handleFilterButton(item)"
          [class.active]="item.value">
          {{ item.label }}
        </button>
      </div> -->
    </section>
    <div class="no-filter-selected-session" *ngIf="orderAdminSelected">
      <p>PLEASE,<br />START BY FILTERING ORDER ADMIN FIRST</p>
    </div>
    <div class="no-filter-selected-session" *ngIf="noResultOrderAdmin">
      <p>NO RECORDS FOUND.</p>
    </div>
    <app-table-overbooked
      [dataTable]="dataOrderAdmin"
      *ngIf="!noResultOrderAdmin">
    </app-table-overbooked>
  </ng-template>

  <ng-template #SalesManager>
    <app-search
      (searchEvent)="onSearchEvent($event, 'salesManager')"
      (applyFilterEvent)="applyFilter('salesManager')"
      [filters]="searchInputsSalesManager"
      [showButtonApply]="true"
      [enableApplyFilter]="enableApplyFilterSalesManager"
      [showMoreFiltersLink]="true"
      [showMoreFiltersLinkWithMinWidth]="true">
    </app-search>
    <mat-divider></mat-divider>
    <section class="header-table-allocation">
      <div class="title-section">
        <h3 class="t-section">Sales Manager</h3>
        <h4 class="st-section"></h4>
      </div>
    </section>
    <div class="no-filter-selected-session" *ngIf="salesManagerSelected">
      <p>PLEASE,<br />START BY FILTERING SALES MANAGERS FIRST</p>
    </div>
    <div class="no-filter-selected-session" *ngIf="noResultSalesManager">
      <p>NO RECORDS FOUND.</p>
    </div>
    <app-table-overbooked
      [dataTable]="dataSalesManager"
      *ngIf="!noResultSalesManager">
    </app-table-overbooked>
  </ng-template>

  <ng-template #Customer>
    <app-search
      (searchEvent)="onSearchEvent($event, 'customer')"
      (applyFilterEvent)="applyFilter('customer')"
      [filters]="searchInputsCustomer"
      [showButtonApply]="true"
      [enableApplyFilter]="enableApplyFilterCustomer"
      [showMoreFiltersLink]="true"
      [showMoreFiltersLinkWithMinWidth]="true">
    </app-search>
    <mat-divider></mat-divider>
    <section class="header-table-allocation">
      <div class="title-section">
        <h3 class="t-section">Customer</h3>
        <h4 class="st-section"></h4>
      </div>
    </section>
    <div class="no-filter-selected-session" *ngIf="customerSelected">
      <p>PLEASE,<br />START BY FILTERING CUSTOMER FIRST</p>
    </div>
    <div class="no-filter-selected-session" *ngIf="noResultCustomer">
      <p>NO RECORDS FOUND.</p>
    </div>
    <app-table-overbooked [dataTable]="dataCustomer" *ngIf="!noResultCustomer">
    </app-table-overbooked>
  </ng-template>

  <ng-template #Product>
    <app-search
      (searchEvent)="onSearchEvent($event, 'product')"
      (applyFilterEvent)="applyFilter('product')"
      [filters]="searchInputsProduct"
      [showButtonApply]="true"
      [enableApplyFilter]="enableApplyFilterProduct"
      [showMoreFiltersLink]="true"
      [showMoreFiltersLinkWithMinWidth]="true">
    </app-search>
    <mat-divider></mat-divider>
    <section class="header-table-allocation">
      <div class="title-section">
        <h3 class="t-section">Product</h3>
        <h4 class="st-section"></h4>
      </div>
    </section>
    <div class="no-filter-selected-session" *ngIf="productSelected">
      <p>PLEASE,<br />START BY FILTERING PRODUCT FIRST</p>
    </div>
    <div class="no-filter-selected-session" *ngIf="noResultProduct">
      <p>NO RECORDS FOUND.</p>
    </div>
    <app-table-overbooked [dataTable]="dataProduct" *ngIf="!noResultProduct">
    </app-table-overbooked>
  </ng-template>

  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
