<div class="content-color-bg-cga">
  <div class="login-page">
    <div class="content-main-bg-logo">
      <div class="content-ps-logo">
        <div class="content-img-bg-motorola">
          <img src="../../../assets/images/logo-motorola-login.svg" class="logo-motorola">
        </div>
      </div>
    </div>
    <div class="content-main-bg-auth">
      <div class="content-login-gca">
        <span class="global-costumer-allocation">
          GLOBAL
          CUSTOMER
          ALLOCATION
        </span>
      </div>
      <div class="content-sso-label">
        <span class="enter-with-sso-access">
          Enter With SSO access
        </span>
      </div>
      <div class="content-main-ps-auth">
        <button *ngIf="initStateLogin" mat-button class="google-sign-in" (click)="authetication()">
          <img [src]="'assets/images/google.svg'" alt="google" class="google-sign-logo" />
          <span class="sign-google-span"> Sign In With Google </span>
        </button>
      </div>
    </div>
  </div>
</div>
