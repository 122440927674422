import { Observable } from 'rxjs';

export class InputBase<T> {
  value: any;
  key: string;
  label: string;
  hover: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: { key: string; value: string }[];
  classCss?: string;
  notClearable?: boolean;
  hasAutocomplete?: boolean;
  hasMultiple?: boolean;
  subscription?: boolean;
  filteredOptions?: Observable<any[]>;
  hasCheckFlowException?: boolean;
  disabled?: boolean;
  limitedValue?: boolean;
  limit?: boolean;
  valueForFilterLimit?: number;
  warn?: boolean;
  id?: number;
  module?: string;
  largeInput?: boolean;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      hover?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: { key: string; value: string }[];
      classCss?: string;
      notClearable?: boolean;
      hasAutocomplete?: boolean;
      hasMultiple?: boolean;
      subscription?: boolean;
      filteredOptions?: Observable<any[]>;
      hasCheckFlowException?: boolean;
      disabled?: boolean;
      limitedValue?: boolean;
      limit?: boolean;
      valueForFilterLimit?: number;
      warn?: boolean;
      d?: number;
      module?: string;
      largeInput?: boolean;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.hover = options.hover || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.classCss = options.classCss || '';
    this.notClearable = options.notClearable || false;
    this.hasAutocomplete = options.hasAutocomplete || false;
    this.hasMultiple = options.hasMultiple || false;
    this.subscription = options.subscription || false;
    this.filteredOptions = options.filteredOptions || undefined;
    this.hasCheckFlowException = options.hasCheckFlowException || false;
    this.disabled = options.disabled || false;
    this.limitedValue = options.limitedValue || false;
    this.limit = options.limit || false;
    this.largeInput = options.largeInput || false;
    this.valueForFilterLimit = options.valueForFilterLimit;
    this.warn = options.warn || false;
    this.module = options.module || '';
  }
}

export class SelectInput extends InputBase<string> {
  controlType = 'select';
}

export class TextboxInput extends InputBase<string> {
  controlType = 'textbox';
}

export class SelectSearchInput extends InputBase<string> {
  controlType = 'search';
}

export class DatePickerSearchInput extends InputBase<string> {
  controlType = 'datePicker';
}
export class MultiSelectSearchInput extends InputBase<any> {
  controlType = 'multiselect-search';
}
