import { Geo } from './../../../interfaces/geo.interface';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { Country } from 'src/app/interfaces/country.interface';
import { Material } from 'src/app/interfaces/material.interface';
import { User } from 'src/app/interfaces/user.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';

@Component({
  selector: 'app-missing',
  templateUrl: './missing.component.html',
  styleUrls: ['./missing.component.scss'],
})
export class MissingComponent implements OnInit {
  tooltipMessage: String = '';
  dataOrderAdmin: any = '';
  dataSalesManager: any = '';
  dataProduct: any = '';
  dataCustomer: any = '';
  dataSalesModel: any;
  noResultSalesManager = false;
  noResultProduct = false;
  noResultCustomer = false;
  noResultSalesModel = false;
  noResultOrderAdmin = false;
  currentUser!: any;
  enableApplyFilter = true;
  orderAdminSelected = true;
  salesManagerSelected = true;
  productSelected = true;
  customerSelected = true;
  salesModelSelected = true;
  searchInputsOrderAdmin: InputBase<string>[];
  searchInputsSalesManager: InputBase<string>[];
  searchInputsCustomer: InputBase<string>[];
  searchInputsProduct: InputBase<string>[];
  searchInputsSalesModel: InputBase<string>[];
  crudService: CrudService<Material>;
  orderAdminService: any;
  countriesEntities: { [key: string]: any } = {};
  geosEntities: { [key: string]: any } = {};
  formSearchOrderAdmin!: { [key: string]: any };
  formSearchSalesManager!: { [key: string]: any };
  formSearchProduct!: { [key: string]: any };
  formSearchCustomer!: { [key: string]: any };
  formSearchSalesModel!: { [key: string]: any };
  orderAdminFullData: any = [];
  productFamilyFullData: any = [];
  customerShortAllData: any = [];
  dataExport: any;
  isValid: boolean = false;
  tabs: { [key: string]: number } = {
    'ORDER ADMIN': 0,
    'SALES MANAGER': 1,
    CUSTOMER: 2,
    PRODUCT: 3,
  };
  selectedTabIndex = 0;

  keysFilters = [
    'ppm',
    'orderAdminId',
    'salesManagerId',
    'customerShortId  ',
    'origin',
    'fiscalYear',
    'quarter',
    'geoId',
    'countryId',
  ];

  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.missing },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.missing },
    },
    {
      entity: 'customer-short',
      query: {
        status: true,
        sort: 'customerShort',
        pageName: PAGE_NAME_RESOURCE.missing,
      },
    },
    {
      entity: 'material',
      query: { pageName: PAGE_NAME_RESOURCE.missing },
    },
    {
      entity: 'material/products/summary',
      query: { pageName: PAGE_NAME_RESOURCE.missing },
    },
    {
      entity: 'user',
      query: {
        status: true,
        order_admin: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.missing,
      },
    },
    {
      entity: 'user',
      query: {
        status: true,
        sales_manager: true,
        sort: 'last_name',
        pageName: PAGE_NAME_RESOURCE.missing,
      },
    },
    {
      entity: 'material-family',
      query: { pageName: PAGE_NAME_RESOURCE.missing },
    },
  ];

  constructor(
    protected http: HttpClient,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelSheetsService: ExcelSheetsService
  ) {
    this.crudService = new CrudService<Material>(this.http, '');
    this.searchInputsSalesModel = [
      new MultiSelectSearchInput({
        key: 'salesModel',
        hover: 'Sales Model',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        classCss: 'full-width-panel-options',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        largeInput: true
      }),
      new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        // classCss: 'order-admin',
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
    ];
    this.searchInputsOrderAdmin = [
      new MultiSelectSearchInput({
        key: 'orderAdminId',
        hover: 'Order Admin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'order-admin',
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'PPM',
        type: 'text',
        classCss: 'full-width-panel-options',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'salesManagerId',
        hover: 'Sales Manager',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        classCss: 'filters-position',
      }),
      new MultiSelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        classCss: 'filters-position',
      }),
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
      }),
    ];

    // PosiÃ§Ã£o dos filtros em Sales Manager
    this.searchInputsSalesManager = this.changePosition(
      this.searchInputsOrderAdmin,
      0,
      2,
      0,
      1
    );

    // PosiÃ§Ã£o dos filtros em Customer
    this.searchInputsCustomer = this.changePosition(
      this.searchInputsOrderAdmin,
      3,
      0,
      2,
      1
    );

    // PosiÃ§Ã£o dos filtros em Product
    this.searchInputsProduct = this.changePosition(
      this.searchInputsOrderAdmin,
      1,
      0,
      1,
      3
    );

    // PosiÃ§Ã£o dos filtros em Sales Model
    this.searchInputsSalesModel = this.changePosition(
      this.searchInputsSalesModel,
      1,
      3
    );
  }

  ngOnInit(): void {
    [this.currentUser] = this.authService.getUserPermissions();
    this.getDataFilter();
  }

  dataFilterSalesModel(salesModelEntities: any) {
    const salesModelFilter: any[] = [];

    salesModelEntities.forEach((item: any) => {
      salesModelFilter.push({ value: item.salesModel, id: item.id });
    });

    // para que a propriedade classCss='first-chield-position' pegue apenas no primeiro filtro [0], pois tem um css especifico
    const selectSalesModelFirstFilter = new MultiSelectSearchInput({
      key: 'salesModel',
      hover: 'Sales Model',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: salesModelFilter,
      classCss: 'first-chield-position',
    });

    // antes:
    // const selectSalesModel = new MultiSelectSearchInput({
    //   key: 'salesModel',
    //   hover: 'Sales Model',
    //   type: 'text',
    //   hasAutocomplete: true,
    //   hasCheckFlowException: true,
    //   options: salesModelFilter,
    // });

    this.searchInputsSalesModel[0] = selectSalesModelFirstFilter;
  }

  dataFilterOrderAdim(orderAdminsEntities: any, customerShortEntities?: any) {
    const orderAdminsFilter: any[] = [];
    const initialValueOrderAdmin: { [key: string]: any } = {};

    orderAdminsEntities.forEach((item: User) => {
      const viewName: string = `${item.last_name}, ${item.name}`;

      if (
        orderAdminsFilter.findIndex(
          obj => obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
        ) === -1
      ) {
        orderAdminsFilter.push({ value: viewName, id: item.id });

        if (
          item.email.includes(this.currentUser.email) &&
          this.currentUser.profileType.includes('orderAdmin')
        ) {
          initialValueOrderAdmin.value = viewName;
          initialValueOrderAdmin.id = item.id;
        }
      }
    });

    let selectCustomerShort;

    if (initialValueOrderAdmin.id || initialValueOrderAdmin.value) {
      this.formSearchOrderAdmin = { orderAdminId: initialValueOrderAdmin.id };
      const customerShortFilter: any = [];
      customerShortEntities.forEach((item: any) => {
        if (item.orderAdminId == initialValueOrderAdmin.id) {
          customerShortFilter.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });
      selectCustomerShort = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        options: [],
        classCss: 'filters-position',
        disabled: customerShortFilter.length > 0 ? false : true,
      });
    } else {
      selectCustomerShort = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        options: [],
      });
    }

    // para que a propriedade classCss='first-chield-position' pegue apenas no primeiro filtro [0], pois tem um css especifico
    const selectMultiOrderAdminFirstFilter = new MultiSelectSearchInput({
      key: 'orderAdminId',
      hover: 'Order Admin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: orderAdminsFilter,
      value: [initialValueOrderAdmin],
      classCss: 'first-chield-position',
    });

    const selectMultiOrderAdmin = new MultiSelectSearchInput({
      key: 'orderAdminId',
      hover: 'Order Admin',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: orderAdminsFilter,
      value: [initialValueOrderAdmin],
      classCss: 'filters-position',
    });

    this.searchInputsOrderAdmin[0] = selectMultiOrderAdminFirstFilter;
    this.searchInputsOrderAdmin[3] = selectCustomerShort;

    this.searchInputsSalesManager[2] = selectMultiOrderAdmin;
    this.searchInputsSalesManager[3] = selectCustomerShort;

    this.searchInputsCustomer[2] = selectMultiOrderAdmin;
    this.searchInputsProduct[1] = selectMultiOrderAdmin;
    this.searchInputsSalesModel[3] = selectMultiOrderAdmin;
  }

  dataFilterPpm(materialsEntities: any, family: any) {
    const ppmFilter: any[] = [];
    const ppmFamily: any[] = [];
    materialsEntities.forEach((item: any) => {
      if (
        item.productDesc &&
        ppmFilter.findIndex(
          obj => obj.value.toUpperCase() === item.allocationBz?.toUpperCase()
        ) === -1
      ) {
        ppmFilter.push({
          key: item.productDesc,
          value: item.productDesc,
          id: item.productId,
        });
      }
    });

    family.forEach((item: any, index = 0) => {
      ppmFamily.push({
        id: index,
        value: item.family,
      });
    });

    // para que a propriedade classCss='first-chield-position' pegue apenas no primeiro filtro [0], pois tem um css especifico

    const selectPpmFilter = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'PPM',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: ppmFilter,
      classCss: 'full-width-panel-options',
      largeInput: true
    });

    const selectPpmFamily = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'Family',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: ppmFamily,
      classCss: 'full-width-panel-options-first-chield-position',
    });

    this.searchInputsOrderAdmin[1] = selectPpmFilter;
    this.searchInputsSalesManager[1] = selectPpmFilter;
    this.searchInputsCustomer[1] = selectPpmFilter;
    this.searchInputsProduct[0] = selectPpmFamily;
    this.searchInputsSalesModel[1] = selectPpmFilter;
  }

  dataFilterSalesManager(salesManagersEntities: any) {
    const salesManagerFilter: any[] = [];
    const initialValueSalesManager: { [key: string]: any } = {};

    salesManagersEntities.forEach((item: User) => {
      const viewName: string = `${item.last_name}, ${item.name} (${item.email})`;

      if (salesManagerFilter.findIndex(obj => obj.value === viewName) === -1) {
        salesManagerFilter.push({
          value: `${item.last_name}, ${item.name} (${item.email})`,
          id: item.id,
        });

        if (
          item.email.includes(this.currentUser.email) &&
          this.currentUser.profileType.includes('salesManager')
        ) {
          initialValueSalesManager.value = viewName;
          initialValueSalesManager.id = item.id;
          [initialValueSalesManager.orderAdmin] = item.orderAdmins.map(
            (orderAdmin: any) => ({
              id: orderAdmin.orderAdmin.id,
              value: `${orderAdmin.orderAdmin.last_name}, ${orderAdmin.orderAdmin.name}`,
            })
          );
          // Initial value Order Admin em Order Admin
          this.searchInputsOrderAdmin[1].value =
            initialValueSalesManager.orderAdmin;
          // Initial value Order Admin em Sales Manager
          this.searchInputsSalesManager[2].value =
            initialValueSalesManager.orderAdmin;
          // Initial value Order Admin em Customer
          this.searchInputsCustomer[2].value =
            initialValueSalesManager.orderAdmin;
          // Initial value Order Admin em Product
          this.searchInputsProduct[1].value =
            initialValueSalesManager.orderAdmin;
        }
      }
    });

    // para que a propriedade classCss='first-chield-position' pegue apenas no primeiro filtro [0], pois tem um css especifico
    const selectMultiSalesManagerFirstFilter = new MultiSelectSearchInput({
      key: 'salesManagerId',
      hover: 'Sales Manager',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: salesManagerFilter,
      value: [initialValueSalesManager],
      classCss: 'first-chield-position',
    });

    const selectMultiSalesManager = new MultiSelectSearchInput({
      key: 'salesManagerId',
      hover: 'Sales Manager',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: salesManagerFilter,
      value: [initialValueSalesManager],
      classCss: 'filters-position',
    });

    this.searchInputsOrderAdmin[2] = selectMultiSalesManager;
    this.searchInputsSalesManager[0] = selectMultiSalesManagerFirstFilter;
    this.searchInputsCustomer[3] = selectMultiSalesManager;
    this.searchInputsProduct[2] = selectMultiSalesManager;
    this.searchInputsSalesModel[2] = selectMultiSalesManager;
  }

  dataFilterCustomerShort(
    customerShortEntities: any,
    orderAdminsEntities?: any
  ) {
    const customerShortFilter: any[] = [];
    const orderAdminsFilter: any[] = [];
    const initialValueOrderAdmin: { [key: string]: any } = {};

    orderAdminsEntities.forEach((item: User) => {
      const viewName: string = `${item.last_name}, ${item.name}`;

      if (
        orderAdminsFilter.findIndex(
          obj => obj.value.toLocaleUpperCase() === viewName.toLocaleUpperCase()
        ) === -1
      ) {
        orderAdminsFilter.push({ value: viewName, id: item.id });

        if (
          item.email.includes(this.currentUser.email) &&
          this.currentUser.profileType.includes('orderAdmin')
        ) {
          initialValueOrderAdmin.value = viewName;
          initialValueOrderAdmin.id = item.id;
        }
      }
    });
    customerShortEntities.forEach((item: any) => {
      customerShortFilter.push({
        value: item.customerShort,
        id: item.id,
      });
    });

    // para que a propriedade classCss='first-chield-position' pegue apenas no primeiro filtro [0], pois tem um css especifico
    const selectCustomerShortFirstFilter = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: customerShortFilter,
      classCss: 'first-chield-position',
    });

    const selectCustomerShort = new MultiSelectSearchInput({
      key: 'customerShortId',
      hover: 'Customer Short',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: customerShortFilter,
      classCss: 'filters-position',
    });

    let customerShortOrderAdmin;
    if (initialValueOrderAdmin.id || initialValueOrderAdmin.value) {
      this.formSearchOrderAdmin = { orderAdminId: initialValueOrderAdmin.id };
      const customerShortFilter: any = [];
      customerShortEntities.forEach((item: any) => {
        if (item.orderAdminId == initialValueOrderAdmin.id) {
          customerShortFilter.push({
            value: item.customerShort,
            id: item.id,
          });
        }
      });
      customerShortOrderAdmin = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        options: customerShortFilter,
        disabled: customerShortFilter.length > 0 ? false : true,
      });
    } else {
      customerShortOrderAdmin = new MultiSelectSearchInput({
        key: 'customerShortId',
        hover: 'Customer Short',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
        disabled: true,
        options: [],
      });
    }

    this.searchInputsOrderAdmin[3] = customerShortOrderAdmin;
    this.searchInputsSalesManager[3] = customerShortOrderAdmin;
    this.searchInputsCustomer[0] = selectCustomerShortFirstFilter;
    this.searchInputsProduct[3] = customerShortOrderAdmin;
    this.searchInputsSalesModel[4] = selectCustomerShort;
  }

  dataFilterGeo(geosEntities: any) {
    const geoFilter: any[] = [];
    const initialValueGeoFilter: { [key: string]: any } = {};

    geosEntities.forEach((item: Geo) => {
      geoFilter.push({ value: item.geo, id: item.id });
      if (item.id === this.currentUser.geoId) {
        initialValueGeoFilter.value = item.geo;
        initialValueGeoFilter.id = item.id;
      }
    });

    const selectGeo: any = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: geoFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.searchInputsOrderAdmin[5] = selectGeo;
    this.searchInputsSalesManager[5] = selectGeo;
    this.searchInputsCustomer[5] = selectGeo;
    this.searchInputsProduct[5] = selectGeo;
    this.searchInputsSalesModel[5] = selectGeo;
  }

  dataFilterCountry(countryEntities: any) {
    const countryFilter: any[] = [];
    const initialValueCountryFilter: { [key: string]: any } = {};

    countryEntities.forEach((item: Country) => {
      countryFilter.push({ value: item.country, id: item.id });
      if (item.id === this.currentUser.countryId) {
        initialValueCountryFilter.value = item.country;
        initialValueCountryFilter.id = item.id;
      }
    });

    const selectMultiCountry = new SelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      options: countryFilter,
      value: initialValueCountryFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.searchInputsOrderAdmin[6] = selectMultiCountry;
    this.searchInputsSalesManager[6] = selectMultiCountry;
    this.searchInputsCustomer[6] = selectMultiCountry;
    this.searchInputsProduct[6] = selectMultiCountry;
    this.searchInputsSalesModel[6] = selectMultiCountry;
  }

  getDataFilter() {
    let customerShortEntities;
    let materialsEntities;
    let orderAdminsEntities;
    let salesManagerEntities;
    let salesModelEntities;
    let productFamily;
    this.spinner.show();

    this.crudService
      .getDataFilters(this.entities)
      .subscribe((response: any) => {
        [
          this.geosEntities,
          this.countriesEntities,
          customerShortEntities,
          salesModelEntities,
          materialsEntities,
          orderAdminsEntities,
          salesManagerEntities,
          productFamily,
        ] = response;
        this.productFamilyFullData = productFamily;
        this.orderAdminFullData = orderAdminsEntities;
        this.customerShortAllData = customerShortEntities;
        this.dataFilterSalesModel(salesModelEntities);
        this.dataFilterOrderAdim(orderAdminsEntities, customerShortEntities);
        this.dataFilterSalesManager(salesManagerEntities);
        this.dataFilterPpm(materialsEntities, productFamily);
        this.dataFilterCustomerShort(
          customerShortEntities,
          orderAdminsEntities
        );
        this.dataFilterGeo(this.geosEntities);
        this.dataFilterCountry(this.countriesEntities);

        // Inputs Order Admin
        this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];

        // Inputs Sales Manager
        this.searchInputsSalesManager = [...this.searchInputsSalesManager];

        // Inputs Customer
        this.searchInputsCustomer = [...this.searchInputsCustomer];

        // Inputs Product
        this.searchInputsProduct = [...this.searchInputsProduct];

        // Inputs Sales Model
        this.searchInputsSalesModel = [...this.searchInputsSalesModel];

        this.spinner.hide();
      });

    this.searchInputsSalesModel = [...this.searchInputsSalesModel];
    this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
    this.searchInputsSalesManager = [...this.searchInputsSalesManager];
    this.searchInputsCustomer = [...this.searchInputsCustomer];
    this.searchInputsProduct = [...this.searchInputsProduct];
  }

  formOrderAdminTab(tab: any) {
    let formSearchValues = {};

    formSearchValues = { ...this.formSearchOrderAdmin };
    let formOrderAdmin = this.deleteProperty(formSearchValues);
    this.orderAdminSelected = false;
    this.getDataTable(formOrderAdmin, tab);
  }

  formSalesManagerTab(tab: any) {
    let formSearchValues = {};

    formSearchValues = { ...this.formSearchSalesManager };
    let formSalesManager = this.deleteProperty(formSearchValues);
    this.salesManagerSelected = false;
    this.getDataTable(formSalesManager, tab);
  }

  formProductTab(tab: any) {
    let formSearchValues = {};

    formSearchValues = { ...this.formSearchProduct };
    let formProduct = this.deleteProperty(formSearchValues);
    this.productSelected = false;
    this.getDataTable(formProduct, tab);
  }

  formCustomerTab(tab: any) {
    let formSearchValues = {};

    formSearchValues = { ...this.formSearchCustomer };
    let formCustomer = this.deleteProperty(formSearchValues);
    this.customerSelected = false;
    this.getDataTable(formCustomer, tab);
  }

  formSalesModelTab(tab: any) {
    let formSearchValues = {};

    formSearchValues = { ...this.formSearchSalesModel };
    let formSalesModel = this.deleteProperty(formSearchValues);
    this.salesModelSelected = false;
    this.getDataTable(formSalesModel, tab);
  }

  applyFilter(tab?: any) {
    if (tab === 'orderAdmin') {
      this.formOrderAdminTab(tab);
    }
    if (tab === 'salesManager') {
      this.formSalesManagerTab(tab);
    }
    if (tab === 'ppm') {
      this.formProductTab(tab);
    }
    if (tab === 'customerShort') {
      this.formCustomerTab(tab);
    }
    if (tab === 'salesModel') {
      this.formSalesModelTab(tab);
    }
  }

  deleteProperty(item: any) {
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  tableReport(report: any, search: any) {
    return this.crudService.getEntity(
      `missing/allocation-missing-report?report=${report}`,
      search
    );
  }

  tabOrderAdmin(search: any, tab: string) {
    if (tab === 'orderAdmin') {
      this.noResultOrderAdmin = false;
      this.tableReport(tab, search).subscribe((response: any) => {
        if (response == '') {
          this.noResultOrderAdmin = true;
          this.dataOrderAdmin = '';
          this.dataExport = '';
          this.controlExportButton();
        } else {
          this.dataOrderAdmin = response;
          this.dataExport = response;
          this.controlExportButton();
        }
        this.spinner.hide();
      });
    }
  }

  tabSalesManager(search: any, tab: string) {
    if (tab === 'salesManager') {
      this.noResultSalesManager = false;
      this.tableReport(tab, search).subscribe((response: any) => {
        if (response == '') {
          this.noResultSalesManager = true;
          this.dataSalesManager = '';
          this.dataExport = '';
          this.controlExportButton();
        } else {
          this.dataSalesManager = response;
          this.dataExport = response;
          this.controlExportButton();
        }
        this.spinner.hide();
      });
    }
  }

  tabProduct(search: any, tab: string) {
    if (tab === 'ppm') {
      let headers: any[] = [];
      let rows: any[] = [];
      let responseProduct: any[] = [];
      let responseTableProduct: any[] = [];

      this.noResultProduct = false;
      this.tableReport(tab, search).subscribe((response: any) => {
        if (response == '') {
          this.noResultProduct = true;
          this.dataProduct = '';
          this.dataExport = response;
          this.controlExportButton();
        }
        if (response != '') {
          response[0].headers.forEach((item: any) => {
            if (item.props === 'ppm') {
              item.title = 'FAMILY';
            }
            responseProduct.push(item);
          });
          response[1].rows.forEach((item: any) => {
            rows.push(item);
          });
          headers = this.changePosition(responseProduct, 2, 0, 1, 4, 4, 6);
          responseTableProduct.push({ headers }, { rows });
          this.dataProduct = responseTableProduct;
          this.dataExport = responseTableProduct;
          this.controlExportButton();
        }
        this.spinner.hide();
      });
    }
  }

  tabCustomerShort(search: any, tab: string) {
    if (tab === 'customerShort') {
      this.noResultCustomer = false;
      this.tableReport(tab, search).subscribe((response: any) => {
        if (response == '') {
          this.noResultCustomer = true;
          this.dataCustomer = '';
          this.dataExport = response;
          this.controlExportButton();
        } else {
          this.dataCustomer = response;
          this.dataExport = response;
          this.controlExportButton();
        }
        this.spinner.hide();
      });
    }
  }

  getDataTable(search: any, tab?: any) {
    this.spinner.show();
    this.tabOrderAdmin(search, tab);
    this.tabSalesManager(search, tab);
    this.tabProduct(search, tab);
    this.tabCustomerShort(search, tab);
  }

  changePosition(
    arr: any,
    fromFirst: number, // posiÃ§Ã£o para onde serÃ¡ enviado o primeiro Ã­ndice
    toFirst: number, // posiÃ§Ã£o do primeiro Ã­ndice
    fromSecond?: number, // posiÃ§Ã£o para onde serÃ¡ enviado o segundo Ã­ndice
    toSecond?: number, // posiÃ§Ã£o do segundo Ã­ndice
    fromThird?: number, // posiÃ§Ã£o para onde serÃ¡ enviado o terceiro Ã­ndice
    toThird?: number // posiÃ§Ã£o do terceiro Ã­ndice
  ) {
    if (fromFirst) arr.splice(toFirst, 0, arr.splice(fromFirst, 1)[0]);
    if (fromSecond) arr.splice(toSecond, 0, arr.splice(fromSecond, 1)[0]);
    if (fromThird) arr.splice(toThird, 0, arr.splice(fromThird, 1)[0]);
    return arr;
  }

  dataformSearchOrderAdmin(formSearchName: any, tab?: any) {
    if (formSearchName.nameFormControl === 'orderAdminId') {
      if (formSearchName.orderAdminId) {
        const customerOptions: any = [];
        this.customerShortAllData.forEach((item: any) => {
          formSearchName.orderAdminId.forEach((data: any) => {
            if (data.id == item.orderAdminId) {
              customerOptions.push({
                value: item.customerShort,
                id: item.id,
              });
            }
          });
        });
        const currentOrderAdmin = formSearchName.orderAdminId.map(
          (item: any) => item.id
        );
        formSearchName.orderAdminId = currentOrderAdmin;
        const actualCustomer = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: customerOptions,
          classCss: 'filters-position',
          disabled: customerOptions.length > 0 ? false : true,
        });

        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[3] = actualCustomer;
        } else if (tab === 'salesManager') {
          this.searchInputsSalesManager[3] = actualCustomer;
        } else if (tab === 'ppm') {
          this.searchInputsProduct[3] = actualCustomer;
        }
      } else {
        formSearchName.orderAdminId = '';
        const actualCustomer = new MultiSelectSearchInput({
          key: 'customerShortId',
          hover: 'Customer Short',
          type: 'text',
          hasAutocomplete: true,
          hasCheckFlowException: true,
          options: [],
          disabled: true,
        });
        if (tab === 'orderAdmin') {
          this.searchInputsOrderAdmin[3] = actualCustomer;
        } else if (tab === 'salesManager') {
          this.searchInputsSalesManager[3] = actualCustomer;
        } else if (tab === 'ppm') {
          this.searchInputsProduct[3] = actualCustomer;
        }
      }
    } else {
      if (formSearchName.orderAdminId) {
        let orderFinal = formSearchName.orderAdminId.map(
          (item: any) => item.id
        );
        formSearchName.orderAdminId = orderFinal;
      } else {
        formSearchName.orderAdminId = '';
      }
    }
  }

  dataformSearchSalesManager(formSearchName: any) {
    if (formSearchName.salesManagerId) {
      const currentSalesManagerId = formSearchName.salesManagerId.map(
        (item: User) => item.id
      );
      formSearchName.salesManagerId = currentSalesManagerId;
    } else {
      formSearchName.salesManagerId = '';
    }
  }

  dataformSearchCustomerShort(formSearchName: any) {
    if (formSearchName.customerShortId) {
      const currentCustomer = formSearchName.customerShortId.map(
        (item: any) => item.id
      );
      formSearchName.customerShortId = currentCustomer;
    } else {
      formSearchName.customerShortId = '';
    }
  }

  dataformSearchSalesModel(formSearchName: any) {
    if (formSearchName.salesModel) {
      const currentSalesModel = formSearchName.salesModel.map(
        (item: any) => item.value
      );
      formSearchName.salesModel = currentSalesModel;
    } else {
      formSearchName.salesModel = '';
    }
  }

  dataformSearchOrigin(formSearchName: any) {
    if (formSearchName.origin) {
      const currentOrigin = formSearchName.origin.map(
        (item: any) => item.value
      );
      formSearchName.origin = currentOrigin;
    } else {
      formSearchName.origin = '';
    }
  }

  dataformSearchGeo(formSearchName: any) {
    if (formSearchName.geoId) {
      const currentGeo = this.geosEntities.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchName.geoId.toLocaleUpperCase())
      );
      formSearchName.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchName.geoId = '';
    }
  }

  dataformSearchCountry(formSearchName: any) {
    if (formSearchName.countryId) {
      const currentCountry = this.countriesEntities.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(formSearchName.countryId.toLocaleUpperCase())
      );
      formSearchName.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      formSearchName.countryId = '';
    }
  }

  dataformSearchPpm(formSearchName: any, tab?: any) {
    if (formSearchName.ppm) {
      if (tab == 'ppm') {
        const currentIdsFamily: any = [];
        this.productFamilyFullData.forEach((item: any) => {
          formSearchName.ppm.forEach((data: any) => {
            if (item.family == data.value) {
              item.ids.forEach((value: any) => {
                currentIdsFamily.push(value);
              });
            }
          });
        });
        formSearchName.ppm = '';
        formSearchName.materialId = currentIdsFamily.map((item: any) => item);
      } else {
        const currentPPM = formSearchName.ppm.map((item: any) => item.value);
        formSearchName.ppm = currentPPM;
      }
    } else if (!formSearchName.ppm) {
      formSearchName.ppm = '';
    }
  }

  onSearchEvent(form: any, tab?: any) {
    if (tab === 'orderAdmin') {
      this.formSearchOrderAdmin = { ...form };

      this.dataformSearchOrderAdmin(this.formSearchOrderAdmin, tab);
      this.dataformSearchSalesManager(this.formSearchOrderAdmin);
      this.dataformSearchCustomerShort(this.formSearchOrderAdmin);
      this.dataformSearchOrigin(this.formSearchOrderAdmin);
      this.dataformSearchPpm(this.formSearchOrderAdmin);
      this.dataformSearchGeo(this.formSearchOrderAdmin);
      this.dataformSearchCountry(this.formSearchOrderAdmin);
    }
    if (tab === 'salesManager') {
      this.formSearchSalesManager = { ...form };

      this.dataformSearchOrderAdmin(this.formSearchSalesManager, tab);
      this.dataformSearchSalesManager(this.formSearchSalesManager);
      this.dataformSearchCustomerShort(this.formSearchSalesManager);
      this.dataformSearchOrigin(this.formSearchSalesManager);
      this.dataformSearchPpm(this.formSearchSalesManager);
      this.dataformSearchGeo(this.formSearchSalesManager);
      this.dataformSearchCountry(this.formSearchSalesManager);
    }
    if (tab === 'ppm') {
      this.formSearchProduct = { ...form };

      this.dataformSearchOrderAdmin(this.formSearchProduct, tab);
      this.dataformSearchSalesManager(this.formSearchProduct);
      this.dataformSearchCustomerShort(this.formSearchProduct);
      this.dataformSearchOrigin(this.formSearchProduct);
      this.dataformSearchPpm(this.formSearchProduct, tab);
      this.dataformSearchGeo(this.formSearchProduct);
      this.dataformSearchCountry(this.formSearchProduct);
    }
    if (tab === 'customerShort') {
      this.formSearchCustomer = { ...form };

      this.dataformSearchOrderAdmin(this.formSearchCustomer);
      this.dataformSearchSalesManager(this.formSearchCustomer);
      this.dataformSearchCustomerShort(this.formSearchCustomer);
      this.dataformSearchOrigin(this.formSearchCustomer);
      this.dataformSearchPpm(this.formSearchCustomer);
      this.dataformSearchGeo(this.formSearchCustomer);
      this.dataformSearchCountry(this.formSearchCustomer);
    }
    if (tab === 'salesModel') {
      this.formSearchSalesModel = { ...form };

      this.dataformSearchOrderAdmin(this.formSearchSalesModel);
      this.dataformSearchSalesManager(this.formSearchSalesModel);
      this.dataformSearchCustomerShort(this.formSearchSalesModel);
      this.dataformSearchOrigin(this.formSearchSalesModel);
      this.dataformSearchPpm(this.formSearchSalesModel);
      this.dataformSearchGeo(this.formSearchSalesModel);
      this.dataformSearchCountry(this.formSearchSalesModel);
      this.dataformSearchSalesModel(this.formSearchSalesModel);
    }

    this.searchInputsOrderAdmin = [...this.searchInputsOrderAdmin];
    this.searchInputsSalesManager = [...this.searchInputsSalesManager];
    this.searchInputsCustomer = [...this.searchInputsCustomer];
    this.searchInputsProduct = [...this.searchInputsProduct];
  }

  controlExportButton() {
    if (this.selectedTabIndex == 0 && this.dataOrderAdmin != '') {
      this.dataExport = this.dataOrderAdmin;
      this.isValid = true;
      this.tooltipMessage = 'Export'
    } else if (this.selectedTabIndex == 1 && this.dataSalesManager != '') {
      this.dataExport = this.dataSalesManager;
      this.isValid = true;
      this.tooltipMessage = 'Export'
    } else if (this.selectedTabIndex == 2 && this.dataCustomer != '') {
      this.dataExport = this.dataCustomer;
      this.isValid = true;
      this.tooltipMessage = 'Export'
    } else if (this.selectedTabIndex == 3 && this.dataProduct != '') {
      this.dataExport = this.dataProduct;
      this.isValid = true;
      this.tooltipMessage = 'Export'
    } else {
      this.isValid = false;
      this.tooltipMessage = ''
    }
  }

  prepareExportTable(data: any) {
    const columns = data[0].headers;
    let rowsTemp: any = [];
    if (data[1].rows[0].jag) {
      rowsTemp = rowsTemp.concat(
        data[1].rows[0].jag.concat(data[1].rows[0].sub_jag)
      );
    }
    if (data[1].rows[1].man) {
      rowsTemp = rowsTemp.concat(
        data[1].rows[1].man.concat(data[1].rows[1].sub_man)
      );
    }
    if (data[1].rows[0].man) {
      rowsTemp = rowsTemp.concat(
        data[1].rows[0].man.concat(data[1].rows[0].sub_man)
      );
    }
    if (data[1].rows[1].jag) {
      rowsTemp = rowsTemp.concat(
        data[1].rows[1].jag.concat(data[1].rows[1].sub_jag)
      );
    }

    if (data[1].rows[1].total) {
      rowsTemp = rowsTemp.concat(data[1].rows[1].total);
    }
    if (data[1].rows.length > 2) {
      rowsTemp = rowsTemp.concat(data[1].rows[2].total);
    }
    return { columns: columns, rows: rowsTemp };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.dataExport);
    Object.defineProperty(fullJsonExport, 'sheet', {
      value: 'missing',
    });
    Object.defineProperty(fullJsonExport, 'headers', {
      value: true,
    });
    const dataFinal: any = [fullJsonExport];
    this.excelSheetsService.exportJsonAsExcelFile(dataFinal, 'missing');
  }

  tabClick(event: any) {
    this.changeTab(event.target.innerText);
  }

  changeTab(label: any) {
    if (this.tabs[label] || this.tabs[label] === 0) {
      this.selectedTabIndex = this.tabs[label];
      this.controlExportButton();
    }
  }

  backButton() {
    this.router.navigate(['home/customer-allocation-retail']);
  }
}
