<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">COUNTRY</span>
    <button *ngIf="hasPermissionCreate" class="button modal-action-button" mat-button (click)="prepareToCreate()">NEW COUNTRY</button>
  </div>
  <app-search (searchEvent)="onSearchEvent($event)" [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results [data]="result" (eventActions)="onEventActions($event)" (resultPaginator)="onChangePaginator($event)">
  </app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">BACK</button>
</div>
<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="countryForm">
    <header class="title-modal" matDialogTitle>{{isEdit ? 'EDIT COUNTRY': 'NEW COUNTRY'}}</header>
    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <input matInput formControlName="country" autocomplete="off" maxlength="50" appInputAlphanumeric />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>GEO</mat-label>
        <mat-icon class="material-icons align-arrow-right">arrow_drop_down</mat-icon>
        <input type="text" formControlName="geo_id" matInput [matAutocomplete]="geoAutocomplete">
        <mat-autocomplete #geoAutocomplete="matAutocomplete" autoActiveFirstOption disableOptionCentering panelClass="panel-select-country">
          <mat-option *ngFor="let geo of filteredOptions | async" [value]="geo.geo" matTooltip="{{ geo.geo }}">
            {{geo.geo}}
          </mat-option>
        </mat-autocomplete>
        <button *ngIf="countryForm.controls['geo_id'].value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="countryForm.controls['geo_id'].reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <mat-label>ISO Code</mat-label>
        <input matInput formControlName="iso_code" autocomplete="off" maxlength="10" appInputAlphanumeric />
      </mat-form-field>
      <br />
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>CANCEL</button>
      <button *ngIf="!isEdit" class="button modal-action-button" mat-button (click)="onCreate()">SAVE</button>
      <button *ngIf="isEdit" class="button modal-action-button" mat-button (click)="onUpdate()">SAVE</button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>
  <mat-dialog-content>
    {{isEdit ? 'Do you confirm to edit COUNTRY?': 'Do you confirm to remove COUNTRY?'}}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>NO</button>
    <button class="button modal-action-button" mat-button matDialogClose="false">YES</button>
  </mat-dialog-actions>
</ng-template>
