import { Injectable } from '@angular/core';
import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { EMPTY, BehaviorSubject } from 'rxjs';
import { SharedinfoService } from '../generic/sharedinfo.service';
import { AuthService } from './auth.service';
import { PermissionType } from '../../helpers/authorization/permission-type';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  hasPermissionCreate$ = new BehaviorSubject<any>(false);
  authService = new AuthService();
  currentPermissions: any = [];

  constructor(private sharedinfoService: SharedinfoService) {
    this.sharedinfoService.getPermissionStorage().subscribe(() => {
      this.currentPermissions = this.authService.getUserPermissions();
    });
  }

  public setTablePermissions(entityName: string): any {
    const actions: any = {
      label: 'actions',
      sticky: true,
      edit: false,
      delete: false,
      style: { width: '8%', 'text-align': 'center' },
    };
    const entityPermissions = this.currentPermissions;
    entityPermissions.forEach((permission: any) => {
      if (permission.resource === entityName) {
        switch (permission.permission) {
          case PermissionType.update:
            actions.edit = true;
            break;
          case PermissionType.delete:
            actions.delete = true;
            break;
        }
      }
    });

    if (actions.edit === false && actions.delete === false) {
      return EMPTY;
    }
    return actions;
  }

  public setActionsCardsPermission(entityName: string) {
    const persmissionCards: any = { iconEdit: false, iconDelete: false };
    const entityPermissions = this.currentPermissions;
    entityPermissions.forEach((permission: any) => {
      if (permission.resource === entityName) {
        switch (permission.permission) {
          case PermissionType.update:
            persmissionCards.iconEdit = true;
            break;
          case PermissionType.delete:
            persmissionCards.iconDelete = true;
            break;
        }
      }
    });
    return persmissionCards;
  }

  public setButtonCreatePermission(entityName: string) {
    let hasPermissionCreate: boolean = false;
    const entityPermissions = this.currentPermissions;
    entityPermissions.forEach((permission: any) => {
      if (
        permission.resource === entityName &&
        permission.permission === PermissionType.create
      ) {
        switch (permission.permission) {
          case PermissionType.create:
            hasPermissionCreate = true;
            break;
        }
      }
    });
    return hasPermissionCreate;
  }

  public setButtonEditPermission(entityName: string) {
    let hasPermissionEdit: boolean = false;
    const entityPermissions = this.currentPermissions;
    entityPermissions.forEach((permission: any) => {
      if (
        permission.resource === entityName &&
        permission.permission === PermissionType.update
      ) {
        switch (permission.permission) {
          case PermissionType.update:
            hasPermissionEdit = true;
            break;
        }
      }
    });
    return hasPermissionEdit;
  }

  public enableMudulesByPermissions(allModules: CardModule[]) {
    const { currentPermissions } = this;
    const modules = [
      ...new Set(currentPermissions.map((item: any) => item.category)),
    ];
    const showModules: CardModule[] = [];
    modules.forEach((module: any) => {
      allModules.forEach((card: any) => {
        if (
          module === card.name ||
          (module === 'ADMINISTRATION' && card.name === 'ADMIN')
        ) {
          showModules.push(card);
        }
      });
    });
    return showModules;
  }

  public enablePagesByPermissions(
    currentModule: string,
    allPages: CardModule[]
  ) {
    const { currentPermissions } = this;
    const modules = currentPermissions.filter(
      (permission: any) => permission.category === currentModule
    );
    const pages = [...new Set(modules.map((module: any) => module.resource))];
    const showPages: CardModule[] = [];
    pages.forEach((page: any) => {
      allPages.forEach((card: any) => {
        if (page === card.path) {
          showPages.push(card);
        }
      });
    });
    return showPages.sort((a, b) => a.name.localeCompare(b.name));
  }

  gethasPermissionCreate() {
    return this.hasPermissionCreate$.asObservable();
  }
}
