export const TableSellIn: any = {
  headers: [
    {
      title: 'Family',
      props: 'family',
      status: true,
    },
    {
      title: 'Origin',
      props: 'origin',
      status: true,
    },
    {
      title: 'Customer Short',
      props: 'customerShort',
      status: true,
    },
    {
      title: 'Channel',
      props: 'channel',
      status: true,
    },
    {
      title: 'Jan',
      props: 'jan',
      status: true,
    },
    {
      title: 'WK01',
      props: 'wk01jan',
      status: true,
    },
    {
      title: 'WK02',
      props: 'wk02jan',
      status: true,
    },
    {
      title: 'WK03',
      props: 'wk03jan',
      status: true,
    },
    {
      title: 'WK04',
      props: 'wk04jan',
      status: true,
    },
    {
      title: 'WK05',
      props: 'wk05jan',
      status: true,
    },
    {
      title: 'FEB',
      props: 'feb',
      status: true,
    },
    {
      title: 'WK01',
      props: 'wk01feb',
      status: true,
    },
    {
      title: 'WK02',
      props: 'wk02feb',
      status: true,
    },
    {
      title: 'WK03',
      props: 'wk03feb',
      status: true,
    },
    {
      title: 'WK04',
      props: 'wk04feb',
      status: true,
    },
    {
      title: 'WK05',
      props: 'wk05feb',
      status: true,
    },
    {
      title: 'MAR',
      props: 'mar',
      status: true,
    },
    {
      title: 'WK01',
      props: 'wk01mar',
      status: true,
    },
    {
      title: 'WK02',
      props: 'wk02mar',
      status: true,
    },
    {
      title: 'WK03',
      props: 'wk03mar',
      status: true,
    },
    {
      title: 'WK04',
      props: 'wk04mar',
      status: true,
    },
    {
      title: 'WK05',
      props: 'wk05mar',
      status: true,
    },
    {
      title: 'Total Quarter',
      props: 'totalQuarter',
      status: true,
    },
  ],
  rows: [
    {
      family: 'HAWAIIPLUSLED22 4/128 - SOFT BEIGE',
      origin: 'JAG',
      data: [
        {
          family: 'HAWAIIPLUSLED22 4/128 - SOFT BEIGE',
          customerShort: 'Magalu',
          origin: 'JAG',
          channel: 'Mag',
          jan: 5000,
          wk01jan: 1000,
          wk02jan: 1000,
          wk03jan: 1000,
          wk04jan: 1000,
          wk05jan: 1000,
          feb: 5000,
          wk01feb: 1000,
          wk02feb: 1000,
          wk03feb: 1000,
          wk04feb: 1000,
          wk05feb: 1000,
          mar: '',
          wk01mar: '',
          wk02mar: '',
          wk03mar: '',
          wk04mar: '',
          wk05mar: '',
          totalQuarter: 10000,
        },
        {
          family: 'HAWAIIPLUSLED22 4/128 - SOFT BEIGE',
          origin: 'JAG',
          customerShort: 'Americanas',
          channel: 'B2W',
          jan: 5000,
          wk01jan: 1000,
          wk02jan: 1000,
          wk03jan: 1000,
          wk04jan: 1000,
          wk05jan: 1000,
          feb: 5000,
          wk01feb: 1000,
          wk02feb: 1000,
          wk03feb: 1000,
          wk04feb: 1000,
          wk05feb: 1000,
          mar: '',
          wk01mar: '',
          wk02mar: '',
          wk03mar: '',
          wk04mar: '',
          wk05mar: '',
          totalQuarter: 10000,
        },
        {
          family: 'HAWAIIPLUSLED22 4/128 - SOFT BEIGE',
          origin: 'JAG',
          customerShort: 'fast Shop',
          channel: 'FS',
          jan: 5000,
          wk01jan: 1000,
          wk02jan: 1000,
          wk03jan: 1000,
          wk04jan: 1000,
          wk05jan: 1000,
          feb: 5000,
          wk01feb: 1000,
          wk02feb: 1000,
          wk03feb: 1000,
          wk04feb: 1000,
          wk05feb: 1000,
          mar: '',
          wk01mar: '',
          wk02mar: '',
          wk03mar: '',
          wk04mar: '',
          wk05mar: '',
          totalQuarter: 10000,
        },
      ],
    },
  ],
};
