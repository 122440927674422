import { Component, EventEmitter, HostListener, Output } from '@angular/core';

import { AuthService } from './services/authorization/auth.service';
import { SharedinfoService } from './services/generic/sharedinfo.service';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @Output('isAuthorized') isAuthorized = new EventEmitter();
  isLoggedIn!: Boolean;
  userPermission!: any;
  idleDetect: number = environment.idleDetection;
  idleTimeout: number = environment.idleOnTimeOut * 60;

  constructor(
    private router: Router,
    private authService: AuthService,
    private sharedInfoService: SharedinfoService,
    private idleDetection: Idle
  ) {
    this.configureIdle();
    this.configureLocalStorageSync();
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
    if (!!this.isLoggedIn && !!this.userPermission.length) {
      this.sharedInfoService
        .refreshPermission()
        ?.subscribe((res: any) =>
          this.sharedInfoService.setPermissionStorage(res)
        );
    }

  }

  configureIdle() {
    this.idleDetection.setIdle(this.idleDetect);
    this.idleDetection.setTimeout(this.idleTimeout);
    this.idleDetection.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  configureLocalStorageSync() {
    this.idleDetection.onTimeout.subscribe(() => {
      this.authService.signOut();
      this.router.navigate(['/authorization']);
      window.location.reload();
    });
    this.idleDetection.watch();
  }

  isTokenAuthorized(token: any) {
    if (!token.access_token && !token.user_permissions) {
      this.router.navigate(['/authorization']);
    }
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: any) {
    this.isTokenAuthorized(event.view.localStorage);
  }
}
