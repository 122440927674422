import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { CustomTooltip } from '../../helpers/CustomTooltip';
import { ModalService } from 'src/app/services/generic/modal-status.service';

const INDEX_INTRO_TABLE = 0;
const INDEX_MIDDLE_TABLE = 1;
const INDEX_END_TABLE = 2;

const INDEX_ATS = 0;
const INDEX_MLS = 1;

@Component({
  selector: 'app-table-manager',
  templateUrl: './table-manager.component.html',
  styleUrls: ['./table-manager.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class TableManagerComponent implements OnInit {
  showAtsView: boolean = true;
  showMlsView: boolean = true;

  @Input() dataSource!: any;
  @Input() showSubtotalTitle!: any;
  @Input() allocationForms!: FormGroup[];
  @Input() eventTable!: string;
  @Input() keyDataSource!: number;
  @Input() valuesSubTotal!: any;
  @Input() origins!: any;
  @Input() valuesSubMlsAndTotalAllocationExpanded!: any;
  @Input() totalToBook!: any;
  @Output('inputValueChanged') inputValueChangedEvent = new EventEmitter();
  @Output('toggleTable') toggleTableEvent = new EventEmitter();
  @Output('onBlurInput') onBlurInputEvent = new EventEmitter();
  @Output() valueIdentified = new EventEmitter<any>();

  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;
  headers: any = [];
  content: any = [];
  displayedColumns: any = [];
  displayedContent: any = [];
  translateYAbsoluteItem: number = 0;
  offsetTopSubTotal: number = 0;
  columnsNames: string[] = [];
  hasExpanded: boolean = false;
  hasRowsExpanded: boolean = false;
  allFormControlNames!: any[];
  formControlsArray!: FormArray[];
  keyContent = 'summarized';

  valuesTotalAllocation: { col: string; value: number }[] = [];
  valuesSubMlsAndTotalAllocation: { col: string; value: number }[] = [];
  valuesMLS: { col: string; value: number }[] = [];
  valuesDelta: { col: string; value: number }[] = [];
  valuesSubstraction: { col: string; value: number }[] = [];
  valuesMLSexpanded: { col: string; value: number; origin: string }[] = [];
  valuesTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  copyValuesSubMlsAndTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  valuesDeltaExpanded: { col: string; value: number; origin: string }[] = [];
  valuesSubstractionExpanded: { col: string; value: number; origin: string }[] =
    [];
  hiddenHeaderATS: boolean = false;
  isSubTotalEmpty: boolean = true;

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.dataSource = changes.dataSource.currentValue;
      this.headers = this.dataSource.headers;
      this.content = this.dataSource.content;
      this.formControlsArray = this.dataSource.formControlsArray;
      this.displayedColumns = [];
      this.displayedContent = [];


      if (!changes.dataSource.currentValue.displayedColumns) {
        this.headers.forEach((header: any) => {
          if (header.props === header.otherContent || header.alwaysVisible) {
            this.displayedColumns.push(header);
            this.columnsNames.push(header.props);
          }
        });
      } else {
        this.displayedColumns =
          changes.dataSource.currentValue.displayedColumns;
        this.columnsNames = this.displayedColumns.map(
          (header: any) => header.props
        );
      }
    }

    if (changes.eventTable.currentValue) {
      if (changes.eventTable.currentValue.includes('show-columns-rows')) {
        this.keyContent = 'expanded';
        this.hasRowsExpanded = true;
        this.startingUpdateTableValuesExpanded();
      } else if (
        changes.eventTable.currentValue.includes('hide-columns-rows')
      ) {
        this.keyContent = 'summarized';
        this.hasRowsExpanded = false;
      }
    }
    this.displayedContent = JSON.parse(JSON.stringify(this.content));

    
    this.updateContent(this.columnsNames);
    // this.startingUpdateTableValues();
    // this.startingUpdateTableValuesExpanded();

    if (Object.keys(this.valuesSubTotal.expanded['JAG']).length === 0) {
      this.isSubTotalEmpty = true;
    } else {
      this.isSubTotalEmpty = false;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  identifyValueNetOpen(rowValue: any, column: any) {
    if (column === 'netOpenPo') {
      this.valueIdentified.emit({ rowValue, column });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.modalService.isModalOpen()) {
      this.calculateTranslateYAbsoluteItem();
      this.setHideClassHeader();
    }
  }

  calculateTranslateYAbsoluteItem() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;

    const appFooterSubTotal = document.getElementById('subtotal1')?.offsetTop;

    this.offsetTopSubTotal = appFooterSubTotal || 0;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
    this.setHideClassHeader();
  }

  setHideClassHeader(): void {
    if (
      window.innerWidth > 1794 &&
      Object.keys(this.dataSource.content[0].expanded[0].rows).length < 22
    ) {
      this.hiddenHeaderATS = true;
    } else {
      this.hiddenHeaderATS = false;
    }
  }

  getAddRowId(indexRow: number, origin: string) {
    if (this.hasRowsExpanded) {
      return indexRow + origin;
    }
    return indexRow;
  }

  mouseEnter(indexRow: number, origin: string) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow + origin;

    tableRows.forEach((row: any) => {
      if (this.hasRowsExpanded) {
        if (row.id == rowId) {
          row.classList.add('hover-row');
        }
      } else {
        if (row.id == indexRow) {
          row.classList.add('hover-row');
        }
      }
    });
    this.cdr.detectChanges();
  }

  mouseLeave(indexRow: number, origin: string) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow + origin;

    tableRows.forEach((row: any) => {
      if (this.hasRowsExpanded) {
        if (row.id == rowId) {
          row.classList.remove('hover-row');
        }
      } else {
        if (row.id == indexRow) {
          row.classList.remove('hover-row');
        }
      }
    });
    this.cdr.detectChanges();
  }

  toggleColumnTable(col: any, indexCol: number, event: string) {
    const columnsAlwaysVisible: any[] = [];
    const groupColumnsToShowHide = this.headers.filter((item: any) => {
      if (item.alwaysVisible) {
        columnsAlwaysVisible.push(item);
      }
      if (item.otherContent === col.otherContent || item.alwaysVisible) {
        item.expanded = true;
        return item;
      }
      return false;
    });

    groupColumnsToShowHide.shift();
    this.columnsNames = [];

    if (event.includes('show')) {
      col.expanded = true;
      this.displayedColumns.splice(indexCol + 1, 0, ...groupColumnsToShowHide);
    } else if (event.includes('hide')) {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, groupColumnsToShowHide.length);
      this.displayedColumns.splice(indexCol + 1, 0, ...columnsAlwaysVisible);
    }

    if (event === 'show-columns-rows') {
      this.hasRowsExpanded = true;
    } else if (event === 'hide-columns-rows') {
      this.hasRowsExpanded = false;
    }

    if (this.hasRowsExpanded) {
      this.keyContent = 'expanded';
      this.startingUpdateTableValuesExpanded();
    } else {
      this.keyContent = 'summarized';
      this.startingUpdateTableValues();
    }

    this.displayedColumns = Array.from(new Set(this.displayedColumns));
    this.displayedContent = JSON.parse(JSON.stringify(this.content));
    this.updateContent(this.columnsNames);

    if (this.headers.filter((obj: any) => obj.expanded === true).length) {
      this.hasExpanded = true;
    } else {
      this.hasExpanded = false;
    }
    this.setTableScroll();

    this.toggleTableEvent.emit(event);
  }

  updateContent(columnsNames: any[]) {
    this.displayedContent = this.displayedContent.map((content: any) => {
      if (content[this.keyContent]) {
        content[this.keyContent].forEach((element: any) => {
          element.rows = element.rows.map((item: any) => {
            const row = { ...item };
            row.value = {};
            this.displayedColumns.forEach((column: any) => {
              if (!columnsNames.includes(column.props)) {
                columnsNames.push(column.props);
              }
              row.value[column.props] = item.value[column.props];
            });
            return row;
          });
        });
      }
      return content;
    });
  }

  onChangeValueInput(
    item: {
      columnName: any;
      value: any;
      row: any;
      indexRow: number;
      content: any;
      indexContent: number;
      keyContent: any;
      indexGroup: number;
    },
    event: any
  ) {
    const valueInput: number = event.target.value
      ? event.target.value.replaceAll(',', '')
      : 0;
    const columnNameMonth = item.columnName.split('wk')[0];
    const columnsNamesWeeks = this.columnsNames.filter((column: string) =>
      column.includes(columnNameMonth)
    );
    columnsNamesWeeks.shift();

    const subtotalWeek: any = { 0: 0, 1: 0 };
    let subtotalWeekCustomer = 0;
    let subtotalMonth = 0;
    let totalMonth: any = { 0: 0, 1: 0 };

    item.value.value = valueInput;
    item.value.edit = true;
    item.row.value[item.columnName] = item.value;
    item.content[item.keyContent][item.indexGroup].rows[item.indexRow].value[
      item.columnName
    ].value = valueInput;

    item.content[item.keyContent].forEach(
      (content: any, indexContent: number) => {
        content.rows.forEach((row: any) => {
          if (!row.enableATS && !row.enableMLS) {
            const rowValue = parseFloat(row.value[item.columnName].value) || 0;
            subtotalWeek[indexContent] += rowValue;

            subtotalMonth = 0;
            columnsNamesWeeks.forEach((columnName: any) => {
              subtotalMonth += Number(row.value[columnName].value);
            });
            row.value[columnNameMonth].value = subtotalMonth;
            totalMonth[indexContent] += subtotalMonth;

            if (
              row.value[item.columnName].customerShortId ===
              item.value.customerShortId
            ) {
              subtotalWeekCustomer += rowValue;
            }
          }
        });
        return content;
      }
    );

    const options = {
      totalMonth,
      subtotalWeek,
      subtotalWeekCustomer,
      columnNameMonth,
      columnsNamesWeeks,
    };

    this.updateValuesInRealTime(item, options);

    this.inputValueChangedEvent.emit({
      indexTable: this.keyDataSource,
      indexContent: item.indexContent,
      indexGroup: item.indexGroup,
      content: item.content,
      statusTable: item.keyContent,
      columnNameWeek: item.columnName,
      columnNameMonth,
    });
  }

  updateValuesInRealTime(item: any, options: any) {
    let totalATS = 0;
    let weekSubtotal = 0;
    let weekMLS = 0;
    let rowMonthSubtotal = 0;

    const statusTable = item.keyContent;

    this.displayedContent[item.indexContent].summarized[0].rows.filter(
      (row: any) =>
        row.value[item.columnName].customerShortId ===
        item.value.customerShortId
    )[0].value[item.columnName].value = options.subtotalWeekCustomer;

    this.displayedContent[item.indexContent][statusTable][
      item.indexGroup
    ].subtotal[item.columnName] = options.subtotalWeek[item.indexGroup];

    this.displayedContent[item.indexContent][statusTable][
      item.indexGroup
    ].subtotal[item.columnName.split('wk')[0]] =
      options.totalMonth[item.indexGroup];

    if (item.keyContent === 'expanded') {
      weekSubtotal = 0;
      this.displayedContent[item.indexContent].summarized[0].rows.forEach(
        (row: any) => {
          if (!row.enableATS && !row.enableMLS) {
            weekSubtotal += Number(row.value[item.columnName].value);

            rowMonthSubtotal = 0;
            options.columnsNamesWeeks.forEach((columnNameWeek: string) => {
              rowMonthSubtotal += Number(row.value[columnNameWeek].value);
            });
            row.value[options.columnNameMonth].value = rowMonthSubtotal;
          }
        }
      );

      weekMLS =
        this.displayedContent[item.indexContent].summarized[0].rows[1].value[
          item.columnName
        ].value;
      this.displayedContent[item.indexContent].summarized[0].rows[0].value[
        item.columnName
      ].value = weekMLS - weekSubtotal;
      this.displayedContent[item.indexContent].summarized[0].subtotal[
        item.columnName
      ] = weekSubtotal;
    }

    item.content = this.displayedContent[item.indexContent];

    this.startingUpdateTableValues();
    this.startingUpdateTableValuesExpanded();
  }

  startingUpdateTableValues() {
    this.valuesMLS = [];
    this.valuesSubstraction = [];
    this.valuesDelta = [];
    this.valuesTotalAllocation = [];

    /* Obtendo todos os valores de MLS em um array de referência valuesMLS */
    Object.entries(this.content[0].summarized[0].rows[INDEX_MLS].value).forEach(
      (row: any) => {
        this.headers.forEach((header: any) => {
          if (header.weekInTime === 'current' || header.weekInTime === 'next') {
            if (row[0] === header.props) {
              const hasColumnInvaluesMLS = this.valuesMLS.filter(
                (v: any) => v.col === header.props
              );
              if (!hasColumnInvaluesMLS.length)
                this.valuesMLS.push({
                  col: row[0],
                  value: parseFloat(row[1].value),
                });
              this.valuesMLS.forEach((key: any) => {
                if (key.col === row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumnInValuesSubstraction =
                this.valuesSubstraction.filter((v: any) => v.col == row[0]);
              if (!hasColumnInValuesSubstraction.length)
                this.valuesSubstraction.push({ col: row[0], value: 0 });
              this.valuesSubstraction.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumnInValuesDelta = this.valuesDelta.filter(
                (v: any) => v.col == row[0]
              );
              if (!hasColumnInValuesDelta.length)
                this.valuesDelta.push({ col: row[0], value: 0 });
              this.valuesDelta.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = 0;
                }
              });

              const hasColumnInValuesTotalAllocation =
                this.valuesTotalAllocation.filter((v: any) => v.col == row[0]);
              if (!hasColumnInValuesTotalAllocation.length)
                this.valuesTotalAllocation.push({ col: row[0], value: 0 });
              this.valuesTotalAllocation.forEach((key: any) => {
                if (key.col == row[0]) {
                  key.col = row[0];
                  key.value = 0;
                }
              });
            }
          }
        });
      }
    );

    this.valuesSubMlsAndTotalAllocation = [];

    /* Armazenando o total de alocações em um array de referência valuesSubMlsAndTotalAllocation */
    for (let i = 0; i < this.content[0].summarized[0].rows.length; i++) {
      this.headers.forEach((header: any) => {
        if (
          header.currentMonth ||
          header.weekInTime === 'current' ||
          header.weekInTime === 'next'
        ) {
          Object.entries(this.content[0].summarized[0].rows[i].value).forEach(
            (row: any) => {
              if (
                row[1].customerShort !== 'ATS' &&
                row[1].customerShort !== 'MLS'
              ) {
                if (row[0] === header.props) {
                  const hasColumn = this.valuesSubMlsAndTotalAllocation.filter(
                    (v: any) => v.col === header.props
                  );
                  if (!hasColumn.length)
                    this.valuesSubMlsAndTotalAllocation.push({
                      col: row[0],
                      value: 0,
                    });
                  this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
                    if (key.col === row[0]) {
                      key.col = row[0];
                      key.value += parseFloat(row[1].value);
                    }
                  });
                }
              }
            }
          );
        }
      });
    }

    this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
      this.valuesTotalAllocation.forEach((value: any) => {
        if (key.col === value.col) value.value = key.value;
      });
    });

    /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    Object.entries(this.content[0].summarized[0].rows[INDEX_MLS].value).forEach(
      (row: any) => {
        this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
          if (key.col === row[0])
            key.value = parseFloat(row[1].value) - parseFloat(key.value);
        });
      }
    );

    this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
      this.valuesSubstraction.forEach((value: any) => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    let acumulativeDelta: any = 0;

    for (let i = 0; i < this.valuesSubstraction.length; i++) {
      if (this.isSubTotalEmpty) {
        acumulativeDelta =
          this.valuesSubstraction[i]?.value + this.valuesMLS[i + 1]?.value;
      } else {
        acumulativeDelta =
          this.valuesSubstraction[i]?.value -
          this.valuesSubTotal.summarized[this.valuesSubstraction[i]?.col]
            ?.value +
          this.valuesMLS[i + 1]?.value;
      }
      this.valuesSubstraction.forEach((row: any, indexRow: number) => {
        if (indexRow === i + 1)
          row.value =
            parseFloat(acumulativeDelta) -
            this.valuesTotalAllocation[i + 1]?.value;
      });
    }

    if (!this.isSubTotalEmpty) {
      for (let i = 0; i < this.valuesSubstraction.length; i++) {
        this.valuesSubstraction[i].value =
          Number(this.valuesSubstraction[i].value) -
          Number(
            this.valuesSubTotal.summarized[this.valuesSubstraction[i]?.col]
              ?.value
          );
      }
    }

    this.valuesSubstraction.forEach((key: any) => {
      this.valuesDelta.forEach((value: any) => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    this.valuesSubMlsAndTotalAllocation.forEach((key: any) => {
      this.valuesDelta.forEach((value: any) => {
        if (key.col === value.col) {
          key.value = parseFloat(value.value);
        }
      });
    });

    /* Adicionando na tabela summarized os valores de ATS */
    Object.entries(
      this.displayedContent[0].summarized[0]?.rows[INDEX_ATS]?.value
    ).forEach((row: any) => {
      this.valuesDelta.forEach((key: any) => {
        if (key.col === row[0]) row[1].value = parseFloat(key.value);
      });
    });

    let valuesTotalATS: { col: string; value: number }[] = [];

    /* Atualizando os valores de Sub Total no Mês atual */
    this.displayedColumns.forEach((header: any) => {
      Object.entries(
        this.displayedContent[0]?.summarized[0]?.rows[INDEX_ATS]?.value
      ).forEach((row: any) => {
        if (header?.props.includes('wk')) {
          if (row[0] === header.props) {
            const column = row[0].split('wk');

            const hasColumnInValuesTotalATS = valuesTotalATS.filter(
              (v: any) => v.col == column[0]
            );
            if (!hasColumnInValuesTotalATS.length)
              valuesTotalATS.push({
                col: column[0],
                value: Number(row[1].value),
              });
            valuesTotalATS.forEach((key: any) => {
              if (key.col == column[0]) {
                key.col = column[0];
                key.value = Number(row[1].value);
              }
            });
          }
        }
      });
    });

    Object.entries(
      this.displayedContent[0]?.summarized[0]?.rows[INDEX_ATS]?.value
    ).forEach((row: any) => {
      valuesTotalATS.forEach((key: any) => {
        if (row[0] === key.col) {
          row[1].value = key.value;
        }
      });
    });
  }

  startingUpdateTableValuesExpanded() {
    // this.valuesSubstractionExpanded = [];
    this.copyValuesSubMlsAndTotalAllocationExpanded = [];

    for (let i = 0; i < this.content[0].expanded.length; i++) {
      Object.entries(this.content[0].expanded[i].rows[INDEX_MLS].value).forEach(
        (row: any) => {
          this.valuesSubMlsAndTotalAllocationExpanded.forEach((header: any) => {
            if (row[0] == header.col) {
              const hasColumnInSubMlsAndTotalAllocationExpanded =
                this.copyValuesSubMlsAndTotalAllocationExpanded.filter(
                  (v: any) => v.col === header.col && v.origin === row[1].origin
                );
              if (!hasColumnInSubMlsAndTotalAllocationExpanded.length)
                this.copyValuesSubMlsAndTotalAllocationExpanded.push({
                  col: header.col,
                  value: Number(0),
                  origin: row[1].origin,
                });

              const hasColumnInvaluesMLS = this.valuesMLSexpanded.filter(
                (v: any) => v.col === header.col && v.origin === row[1].origin
              );
              if (!hasColumnInvaluesMLS.length)
                this.valuesMLSexpanded.push({
                  col: header.col,
                  value: parseFloat(row[1].value),
                  origin: row[1].origin,
                });
              this.valuesMLSexpanded.forEach((key: any) => {
                if (key.col === header.col && key.origin === row[1].origin) {
                  key.value = parseFloat(row[1].value);
                }
              });

              const hasColumn = this.valuesSubstractionExpanded.filter(
                (v: any) => v.col === header.col && v.origin === row[1].origin
              );
              if (!hasColumn.length)
                this.valuesSubstractionExpanded.push({
                  col: header.col,
                  value: 0,
                  origin: row[1].origin,
                });
              this.valuesSubstractionExpanded.forEach((key: any) => {
                if (key.col === header.col && key.origin === row[1].origin) {
                  key.value = 0;
                }
              });

              const hasColumnValuesDeltaExpanded =
                this.valuesDeltaExpanded.filter(
                  (v: any) => v.col === header.col && v.origin === row[1].origin
                );
              if (!hasColumnValuesDeltaExpanded.length)
                this.valuesDeltaExpanded.push({
                  col: header.col,
                  value: 0,
                  origin: row[1].origin,
                });
              this.valuesDeltaExpanded.forEach((key: any) => {
                if (key.col === header.col && key.origin === row[1].origin) {
                  key.value = 0;
                }
              });

              if (!hasColumnValuesDeltaExpanded.length)
                this.valuesTotalAllocationExpanded.push({
                  col: header.col,
                  value: 0,
                  origin: row[1].origin,
                });
              this.valuesTotalAllocationExpanded.forEach((key: any) => {
                if (key.col === header.col && key.origin === row[1].origin) {
                  key.value = 0;
                }
              });
            }
          });
        }
      );
    }

    /* Armazenando o total de alocações em um array de referência */

    this.content[0].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(this.content[0].expanded[indexRow]?.rows).forEach(
        (key: any) => {
          if (!key[1].enableATS && !key[1].enableMLS) {
            Object.entries(key[1].value).forEach((value: any) => {
              this.copyValuesSubMlsAndTotalAllocationExpanded.forEach(
                (subTotal: any) => {
                  if (
                    subTotal.col == value[0] &&
                    subTotal.origin == value[1]?.origin
                  ) {
                    subTotal.value =
                      parseFloat(subTotal.value) + parseFloat(value[1].value);
                  }
                }
              );
            });
          }
        }
      );
    });

    this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesTotalAllocationExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    // /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    this.valuesMLSexpanded.forEach((row: any) => {
      this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
        if (key.col === row.col && key.origin === row.origin) {
          key.value = parseFloat(row.value) - parseFloat(key.value);
        }
      });
    });

    this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesSubstractionExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    let acumulativeDelta: any = 0;

    for (let i = 0; i < this.valuesSubstractionExpanded.length; i++) {
      if (
        this.valuesSubstractionExpanded[i]?.origin ===
        this.valuesMLSexpanded[i + 1]?.origin
      ) {
        if (this.isSubTotalEmpty) {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value;
        } else {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value -
            this.valuesSubTotal.expanded[
              this.valuesSubstractionExpanded[i]?.origin
            ][this.valuesSubstractionExpanded[i]?.col].value;
        }
        this.valuesSubstractionExpanded.forEach(
          (row: any, indexRow: number) => {
            if (indexRow === i + 1) {
              row.value =
                parseFloat(acumulativeDelta) -
                this.valuesTotalAllocationExpanded[i + 1]?.value;
            }
          }
        );
      }
    }

    if (!this.isSubTotalEmpty) {
      Object.entries(this.valuesSubTotal.expanded).forEach((subTotal: any) => {
        this.valuesSubstractionExpanded.forEach((delta: any) => {
          if (Object.keys(subTotal[1]).length !== 0) {
            if (delta.origin === subTotal[0]) {
              delta.value =
                parseFloat(delta.value) -
                parseFloat(subTotal[1][delta.col].value);
            }
          }
        });
      });
    }

    this.valuesSubstractionExpanded.forEach((key: any) => {
      this.valuesDeltaExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    /* Adicionando na tabela expanded os valores de ATS */
    this.displayedContent[0].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(this.displayedContent[0].expanded[indexRow]?.rows).forEach(
        (key: any) => {
          if (key[1].enableATS) {
            Object.entries(key[1].value).forEach((value: any) => {
              this.valuesDeltaExpanded.forEach((subTotal: any) => {
                if (
                  subTotal.col === value[0] &&
                  subTotal.origin === value[1]?.origin
                ) {
                  value[1].value = parseFloat(subTotal.value);
                }
              });
            });
          }
        }
      );
    });

    /* Atualizando os valores de Sub Total no Mês atual */

    let valuesExpandedTotalATS: {
      col: string;
      value: number;
      origin: string;
    }[] = [];

    this.valuesDeltaExpanded.forEach((subTotal: any) => {
      if (subTotal.origin) {
        const column = subTotal.col.split('wk');

        const hasColumnInValuesTotalATS = valuesExpandedTotalATS.filter(
          (v: any) => v.col == column[0] && v.origin === subTotal.origin
        );
        if (!hasColumnInValuesTotalATS.length)
          valuesExpandedTotalATS.push({
            col: column[0],
            value: Number(subTotal.value),
            origin: subTotal.origin,
          });
        valuesExpandedTotalATS.forEach((key: any) => {
          if (key.col == column[0] && key.origin === subTotal.origin) {
            key.col = column[0];
            key.value = Number(subTotal.value);
          }
        });
      }
    });

    this.displayedContent[0].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(this.displayedContent[0].expanded[indexRow]?.rows).forEach(
        (key: any) => {
          if (key[1].enableATS) {
            Object.entries(key[1].value).forEach((value: any) => {
              valuesExpandedTotalATS.forEach((subTotal: any) => {
                if (
                  value[0] === subTotal.col &&
                  value[1].origin === subTotal.origin
                ) {
                  value[1].value = Number(subTotal.value);
                }
              });
            });
          }
        }
      );
    });
  }

  onBlurInput(
    refValue: {
      indexContent: number;
      indexGroup: number;
      indexRow: number;
      indexCol: string;
    },
    event: any
  ) {
    const columnNameMonth = refValue.indexCol.split('wk')[0];

    this.displayedContent[refValue.indexContent][this.keyContent][
      refValue.indexGroup
    ].rows[refValue.indexRow].value[refValue.indexCol].value = event.target
      .value
      ? event.target.value.replace(',', '')
      : 0;
    if (this.keyContent === 'summarized') {
      const item = {
        event,
        content: this.displayedContent[refValue.indexContent],
        value: {
          valueTyped: event.target.value
            ? event.target.value.replace(',', '')
            : 0,
          statusTable: this.keyContent,
          indexContent: refValue.indexContent,
          indexRow: refValue.indexRow,
          indexCol: refValue.indexCol,
          columnNameMonth,
          row: this.content[refValue.indexContent].summarized[0].rows[
            refValue.indexRow
          ],
          rowWeek:
            this.content[refValue.indexContent].summarized[0].rows[
              refValue.indexRow
            ].value[refValue.indexCol],
        },
        displayedColumns: this.displayedColumns,
      };

      this.onBlurInputEvent.emit(item);
    }
    this.startingUpdateTableValues();
    this.startingUpdateTableValuesExpanded();
  }

  getColorAlert(key: any) {
    return key.toLocaleUpperCase().includes('OVERBOOKED');
  }

  showProductName(key: any, value: any) {
    const expandedRows = this.dataSource.content[0].expanded;
    const hasMultipleExpandedRows = expandedRows.length > 1;

    if (hasMultipleExpandedRows) {
      const firstExpandedRow = expandedRows[0];
      const secondExpandedRow = expandedRows[1];

      const hasEnoughRows =
        Object.keys(firstExpandedRow.rows).length > 14 &&
        Object.keys(secondExpandedRow.rows).length > 14;

      this.showAtsView = hasEnoughRows;
      this.showMlsView = hasEnoughRows;

      if (
        key === 'origin' &&
        this.translateYAbsoluteItem <= this.offsetTopSubTotal
      ) {
        for (let i = 0; i < this.origins.length; i++) {
          return this.origins[i].value;
        }
      } else {
        for (let i = 0; i < this.origins.length - 1; i++) {
          return this.origins[i + 1].value;
        }
      }
    } else {
      const hasEnoughRows = Object.keys(expandedRows[0].rows).length > 14;

      this.showAtsView = hasEnoughRows;
      this.showMlsView = hasEnoughRows;

      return value;
    }

    this.showAtsView = false;
    this.showMlsView = false;
  }

  showDataHeader(key: any, rows: any): any {
    const expandedRows = this.dataSource.content[0].expanded;
    const hasMultipleExpandedRows = expandedRows.length > 1;

    if (hasMultipleExpandedRows) {
      if (
        Object.keys(this.dataSource.content[0].expanded[0].rows).length > 14 &&
        Object.keys(this.dataSource.content[0].expanded[1].rows).length > 14
      ) {
        this.showAtsView = true;

        if (this.hasRowsExpanded) {
          if (this.translateYAbsoluteItem <= this.offsetTopSubTotal) {
            const expandedJag = [
              {
                // ATS
                value:
                  this.dataSource.content[0].expanded[0].rows[0].value[key]
                    .value,
              },
              {
                // MLS
                value:
                  this.dataSource.content[0].expanded[0].rows[1].value[key]
                    .value,
              },
            ];
            return expandedJag;
          } else {
            const expandedMan = [
              // ATS
              {
                value:
                  this.dataSource.content[0].expanded[1].rows[0].value[key]
                    .value,
              },
              // MLS
              {
                value:
                  this.dataSource.content[0].expanded[1].rows[1].value[key]
                    .value,
              },
            ];
            return expandedMan;
          }
        } else {
          if (this.translateYAbsoluteItem <= this.offsetTopSubTotal) {
            const summarizedJag = [
              {
                // ATS
                value:
                  this.dataSource.content[0].summarized[0].rows[0].value[key]
                    .value,
              },
              {
                // MLS
                value:
                  this.dataSource.content[0].summarized[0].rows[1].value[key]
                    .value,
              },
            ];
            return summarizedJag;
          } else {
            const summarizedMan = [
              // ATS
              {
                value:
                  this.dataSource.content[0].summarized[0].rows[0].value[key]
                    .value,
              },
              // MLS
              {
                value:
                  this.dataSource.content[0].expanded[1].rows[1].value[key]
                    .value,
              },
            ];
            return summarizedMan;
          }
        }
      } else {
        this.showAtsView = false;
      }
    } else {
      if (
        Object.keys(this.dataSource.content[0].expanded[0].rows).length > 14
      ) {
        this.showAtsView = true;

        if (
          Object.keys(rows).length ==
          Object.keys(this.dataSource.content[0].expanded[0].rows).length
        ) {
          if (this.translateYAbsoluteItem <= this.offsetTopSubTotal) {
            const expandedJag = [
              {
                // ATS
                value:
                  this.dataSource.content[0].expanded[0].rows[0].value[key]
                    .value,
              },
              {
                // MLS
                value:
                  this.dataSource.content[0].expanded[0].rows[1].value[key]
                    .value,
              },
            ];
            return expandedJag;
          } else {
            const expandedMan = [
              // ATS
              {
                value:
                  this.dataSource.content[0].expanded[1].rows[0].value[key]
                    .value,
              },
              // MLS
              {
                value:
                  this.dataSource.content[0].expanded[1].rows[1].value[key]
                    .value,
              },
            ];
            return expandedMan;
          }
        } else if (
          Object.keys(rows).length ==
          Object.keys(this.dataSource.content[0].summarized[0].rows).length
        ) {
          if (this.translateYAbsoluteItem <= this.offsetTopSubTotal) {
            const summarizedJag = [
              {
                // ATS
                value:
                  this.dataSource.content[0].summarized[0].rows[0].value[key]
                    .value,
              },
              {
                // MLS
                value:
                  this.dataSource.content[0].summarized[0].rows[1].value[key]
                    .value,
              },
            ];
            return summarizedJag;
          }
        }
      } else {
        this.showAtsView = false;
      }
    }
  }

  isBrokeNumber(col: any) {
    const isBrokeNumber = col.slice(col.length - 3);
    if (!isNaN(isBrokeNumber)) {
      return true;
    } else {
      return false;
    }
  }

  showValuesATSandMLS(key: any) {
    const columnsExcept = [
      'ppm',
      'origin',
      'customerShort',
      'channel',
      'appTaxes',
      'missing',
      'netOpenPo',
      'toBook',
      'overbooked',
      'booked',
      'sellIn',
    ];

    if (columnsExcept.includes(key)) {
      return false;
    } else {
      return true;
    }
  }

  showSubTotalValue(value: number, key: any) {
    if (this.keyContent === 'summarized' && key === 'toBook') {
      return this.totalToBook;
    }
    return value;
  }

  isCurrentWeek(labelTable: any) {
    if (labelTable.indexOf('wk') > -1) {
      return true;
    }
    return false;
  }

  hasNetOpenPo(key: any, data: any) {
    if (key === 'netOpenPo') {
      if (data[key].flagged) {
        return true;
      }
      return false;
    }
    return false;
  }

  isColumnCustomerShort(labelTable: any) {
    if (labelTable.indexOf('customer') > -1) {
      return true;
    }
    return false;
  }

  updateValuesTable(params: any, item: any, valuesSubTotal: any) {
    for (const row of item.content.summarized[0].rows) {
      if (
        !row.enableATS &&
        !row.enableMLS &&
        row.value[item.value.indexCol].customerShortId ===
          params.customerShortId
      ) {
        row.value[item.value.indexCol].value = 0;
      }
    }

    item.content.summarized[0].subtotal = valuesSubTotal;

    const columnsNames = item.displayedColumns.map(
      (header: any) => header.props
    );

    const columnsNamesWeeks = columnsNames
      .filter((column: string) => column.includes(params.month))
      .slice(1);

    for (const row of item.content.summarized[0].rows) {
      if (!row.enableATS && !row.enableMLS) {
        let rowMonthSubtotal = 0;
        for (const columnNameWeek of columnsNamesWeeks) {
          rowMonthSubtotal += Number(row.value[columnNameWeek].value);
        }
        row.value[params.month].value = rowMonthSubtotal;
      }
    }
  }

  updateValuesAfterTaxesTable(
    params: any,
    item: any,
    valuesSubTotal: any,
    totalResult: any
  ) {
    for (const row of item.content.summarized[0].rows) {
      if (
        !row.enableATS &&
        !row.enableMLS &&
        row.value[item.value.indexCol].customerShortId ===
          params.customerShortId
      ) {
        row.value[item.value.indexCol].value = Number(totalResult);
      }
    }

    item.content.summarized[0].subtotal = valuesSubTotal;

    const columnsNames = item.displayedColumns.map(
      (header: any) => header.props
    );

    const columnsNamesWeeks = columnsNames
      .filter((column: string) => column.includes(params.month))
      .slice(1);

    for (const row of item.content.summarized[0].rows) {
      if (!row.enableATS && !row.enableMLS) {
        let rowMonthSubtotal = 0;
        for (const columnNameWeek of columnsNamesWeeks) {
          rowMonthSubtotal += Number(row.value[columnNameWeek].value);
        }
        row.value[params.month].value = rowMonthSubtotal;
      }
    }
  }

  formatNumberWithCommas(number: any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  generateTooltip(rowValue: any, key: any) {
    const customerShort = rowValue.customerShort;
    const hasMultipleExpandedRows =
      this.dataSource.content[0].expanded.length > 1;

    if (
      (customerShort === 'ATS' || customerShort === 'MLS') &&
      this.keyContent === 'summarized'
    ) {
      const jagData = this.getRowData(0, customerShort, key);
      const manData = hasMultipleExpandedRows
        ? this.getRowData(1, customerShort, key)
        : '';

      return hasMultipleExpandedRows
        ? `${customerShort}: (${jagData}) | (${manData})`
        : `${customerShort}: (${jagData})`;
    }
    return '';
  }

  private getRowData(index: number, customerShort: string, key: any): string {
    const value =
      this.dataSource.content[0].expanded[index]?.rows[
        customerShort === 'ATS' ? 0 : 1
      ].value[key].value;
    const origin =
      this.dataSource.content[0].expanded[index]?.rows[
        customerShort === 'ATS' ? 0 : 1
      ].value[key].origin;
    return `${origin ? origin : ''}:${this.formatNumberWithCommas(value)}`;
  }
}
