import { SelectionModel } from '@angular/cdk/collections';
import {
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  Component,
  Input,
  ViewChild,
  HostListener
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { ResultInterface } from '../../results/results.interface';

@Component({
  selector: 'app-simple-table-emea',
  templateUrl: './simple-table-emea.component.html',
  styleUrls: ['./simple-table-emea.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class SimpleTableEmeaComponent implements OnChanges {
  @Output('callActions') callActions = new EventEmitter();
  @Input() data!: ResultInterface;
  @Input() hasTotalRow!: boolean;
  @ViewChild('simpleTable') simpleTable: any;
  selection = new SelectionModel<any>(true, []);
  panelOpenState = false;
  dataSource: any;
  displayedColumns: any;
  columnsCustomer: any = [];
  columnsLabelsTableCustomer: any[] = [];
  columnsLabel: any = [];
  hasStatus: any;
  hasComma: any;
  hasCalculation: any;
  tooltipMessage: any;
  tableGrouping: boolean = false;
  currentWeek: string = '';
  currentPeriod: string = '';
  translateYAbsoluteItem: number = 0;
  screenWidth!: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      this.columnsLabel = this.data.table?.columns;
      this.displayedColumns = this.data.table?.columns?.map(
        (column: any) => column.label
      );
      this.dataSource = this.data.table?.rows;
      this.hasStatus = this.data.table?.hasStatus;
      this.hasComma = this.data.table?.hasComma;
      this.hasCalculation = this.data.table?.hasCalculation;
      this.tooltipMessage = this.data.table?.tooltipMessage;

      this.screenWidth = window.innerWidth
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  wowWeek(data: any) {
    if (data.includes('_')) {
      return true;
    }
    return false;
  }

  changeWeek(data: string) {
    if (data.includes('week')) {
      return data.replace('ee', '');
    }
    return data;
  }

  checkType(value: any) {
    return typeof value === 'object';
  }

  isBoolean(value: any) {
    return typeof value === 'boolean';
  }

  isNumber(value: any) {
    return typeof value === 'number';
  }

  editEvent(item: any) {
    this.callActions.emit({ item, type: 'edit' });
  }

  deleteEvent(item: any) {
    this.callActions.emit({ item, type: 'delete' });
  }

  systemFunction(item: any) {
    this.callActions.emit({ item, type: 'systemFunction' });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.callActions.emit({ item: this.selection, type: 'select' });
      return;
    }
    this.selection.select(...this.dataSource.data);
    this.callActions.emit({ item: this.selection, type: 'select' });
  }

  checkboxToggle(element: any) {
    this.selection.toggle(element);
    this.callActions.emit({ item: this.selection, type: 'select' });
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  isCurrentWeek(labelTable: any) {
    const currentYear = new Date().getFullYear();
    const year = currentYear.toString();
    if (
      labelTable === this.currentWeek &&
      this.currentPeriod.includes(`${year}`)
    ) {
      return true;
    }
    return false;
  }

  isCurrentTwo(enteredTable: boolean, position: number, label: any){
    if(enteredTable){
      if(position===0){
        return true;
      }else{
        return false;
      }
    }else{
      return this.isCurrentWeek(label);
    }
  }

  currentColumn(label: any) {
    if (label.includes('tot')) {
      return true;
    }
    return false;
  }

  isProduct(label: any) {
    if (label.includes('product')) {
      return true;
    }
    return false;
  }

  isVariation(label: any) {
    if (label.includes('variation')) {
      return true;
    }
    return false;
  }

  breakTitleColumns(label: string): string {
    if (this.screenWidth < 1739 && label.includes('-')) {
      const parts = label.split('-');
      return parts.join(' -<br>');
    } else {
      return label;
    }
  }

  trackByIndex = (i: number) => i;
}
