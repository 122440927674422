import {
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class SimpleTableComponent implements OnInit {
  @Input() dataTable: any = {};
  @Input() indexGroup: number = 0;
  @Input() hideHeader: boolean = false;
  @Input() filterKaRetail: boolean = true;
  @ViewChild('simpleTable') simpleTable: any;

  translateYAbsoluteItem: number = 0;
  dataSource: any[] = [];
  displayedDef: any[] = [];
  displayedColumns: any[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dataTable?.currentValue) {
      this.dataTable = changes.dataTable.currentValue;
      this.loadSimpleTable();
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setTableScroll();
  }

  setTableScroll() {
    const scrollContainer = this.simpleTable._elementRef.nativeElement;

    if (this.dataSource) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple + 245;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.simpleTable._elementRef.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  loadSimpleTable() {
    this.dataSource = this.dataTable.table.rows;
    this.displayedDef = this.dataTable.table.columns;
    this.displayedColumns = this.dataTable.table.columns.map(
      (item: any) => item.props
    );
  }

  getAddRowId(indexRow: number) {
    return indexRow;
  }

  mouseEnter(indexRow: number, row: any) {
    const kaValue: string = row['ka'].value;
    const tableRows = document.querySelectorAll('td');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.add(`hover-row${kaValue}`);
      }
    });
  }

  mouseLeave(indexRow: number, row: any) {
    const kaValue: string = row['ka'].value;
    const tableRows = document.querySelectorAll('td');
    const rowId = indexRow;

    tableRows.forEach((row: any) => {
      if (row.id == rowId) {
        row.classList.remove(`hover-row${kaValue}`);
      }
    });
  }

  isNotEmpty(value: string) {
    if (value !== '') return true;
    return false;
  }
}
