import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumberAndOperators]',
})
export class OnlyNumberAndOperatorsDirective {
  private hasEqualSign = false;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '=', '+', '-'];

    if (!this.hasEqualSign && (event.key === '+' || event.key === '-')) {
      event.preventDefault();
      return;
    }

    if (allowedKeys.indexOf(event.key) === -1 && !this.isControlKey(event)) {
      event.preventDefault();
    }

    if (event.key === '=' && event) {
      this.hasEqualSign = true;
    }

    if (event.key === 'Backspace' && this.hasEqualSign) {
      if (event.target instanceof HTMLInputElement) {
        const cursorPos = event.target.selectionStart;
        if (cursorPos !== null) {
          const value = event.target.value;
          if (value.charAt(cursorPos - 1) === '=') {
            this.hasEqualSign = false;
          }
        }
      }
    }
  }

  private isControlKey(event: KeyboardEvent): boolean {
    return event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Enter' || event.key === 'Tab';
  }
}
