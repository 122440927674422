import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomHttpParamEncoder } from 'src/app/helpers/customHttpParamEncoder';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService<T> {

  baseUrl: String = environment.api.gcaBackEnd

  constructor(
    protected readonly http: HttpClient,
  ) { }

  downloadFile(endpoint: string, query?: any, type?: any | 'text'){
    const params = this.convertObjToHttpParams(query);
    const url = `${this.baseUrl}${endpoint}`;
    return this.http.get(url, {
      responseType: type,
      params
    });
  }

  private convertObjToHttpParams(args?: any) {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (args) {
      Object.keys(args).forEach(
        (key) => {
          params = params.append(key, args[key]);
        },
      );
    }
    return params;
  }
}
