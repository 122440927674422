<p class="title">Quarter Allocation</p>
<div class="container">
  <div class="container-cards" *ngIf="objectLength === 5">
    <div class="container-child" *ngFor="let item of getDatas">
      <p class="container-title">{{ item.title }}</p>
      <div class="container-information">
        <p class="container-subtitle">
          {{ item.subtitle01 }}:
          <span class="container-value">{{ item.value01 }}</span>
        </p>
        <p class="container-subtitle">
          {{ item.subtitle02 }}:
          <span class="container-value">{{ item.value02 }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
