export enum PermissionRouter {
  admin = 'ADMIN',
  setup = 'SETUP',
  materials = 'MATERIALS',
  onePlan = 'ONE PLAN',
  kaAllocation = 'KA ALLOCATION',
  customerAllocation = 'CUSTOMER ALLOCATION',
  emeaAllocation = 'EMEA ALLOCATION',
  lom = 'LOM',
}
