<ngx-spinner></ngx-spinner>
<div id="fair-distribution" class="container">
  <div class="header-title">
    <span class="title">FAIR DISTRIBUTION</span>
    <div class="container-export">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="isValid"
          [disabled]="isValid"></button>
      </span>
    </div>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    (applyFilterEvent)="applyFilter()"
    [filters]="searchInputs"
    [showButtonApply]="true"
    [showMoreFiltersLink]="false"
    [showMoreFiltersLinkWithMinWidth]="false"
    [enableApplyFilter]="enableApplyFilter">
  </app-search>
  <mat-divider class="divider"></mat-divider>
  <ng-template #blockFilterSelected>
    <div class="table-dynamic-list">
      <app-simple-table-expansive-tabs
        *ngIf="!showNoResults"
        [dataTable]="tableResult"
        (toggleColumnTable)="toggleColumnTable($event)"
        (toggleRowTable)="toggleRowTable($event)">
      </app-simple-table-expansive-tabs>
    </div>
  </ng-template>
  <div class="no-filter-selected-session" *ngIf="showNoResults">
    <p>NO RECORDS FOUND.</p>
  </div>
  <div
    class="no-filter-selected-session"
    *ngIf="noFilterSelected; else blockFilterSelected">
    <p>PLEASE,<br />START BY FILTERING A KA</p>
  </div>
  <div class="container-menu">
    <button class="button back-action-button" mat-button (click)="backButton()">
      BACK
    </button>
  </div>
</div>
