import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import {
  interval, Observable, of, throwError,
} from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

const MILLISECONDS_IN_10_MINUTES: number = 10000 * 60;

@Injectable({
  providedIn: 'root',
})

export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private injector: Injector,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeToken = interval(MILLISECONDS_IN_10_MINUTES);
    const authService = this.injector.get(AuthService);
    const tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `${authService.getToken()}`,
      },
    });
    // start token resfresh
    timeToken.subscribe(() => authService.startRefreshTokenTimer());
    return next.handle(tokenizedReq).pipe(catchError((error) => this.handleError(error)));
  }

  handleError(err: HttpErrorResponse): Observable<any> {
    const authService = this.injector.get(AuthService);
    const isAuthorized = authService.getToken();
    const isPermissions = authService.getUserPermissions();
    if (!!isAuthorized && !!isPermissions.length) {
      if (err.error.message === 'Token Expired') {
        authService.signOut();
        return of(err.error.message);
      } else if (err.status === 401 || err.status === 403) {
        this.router.navigateByUrl('/unauthorized');
        return of(err.message);
      }
    } else {
      this.router.navigateByUrl('/');
    }
    return throwError(err);
  }
}
