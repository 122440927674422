import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { TransformText } from 'src/app/helpers/transformText';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { Tab } from 'src/app/interfaces/tab.interface';
import {
  pageLocation,
  paramsException,
  paramsLocation,
  updateException,
  updateLocation,
} from 'src/app/interfaces/location.interface';
import { modalParametrization } from './location.mockup';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class LocationComponent implements OnInit {
  @ViewChild('modalCreateEdit', { static: true }) modalCreateEdit:
    | TemplateRef<any>
    | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;

  @ViewChild('modalCreateEditException', { static: true })
  modalCreateEditException: TemplateRef<any> | any;

  @ViewChild('modalViewParametrization', { static: true })
  modalViewParametrization: TemplateRef<any> | any;

  @ViewChild('Location') Location!: TemplateRef<any>;
  @ViewChild('Exception') Exception!: TemplateRef<any>;
  searchInputs!: InputBase<string>[];
  searchInputsException!: InputBase<string>[];
  regexCheck = new TransformText();
  dataSource: any;
  displayedColumns: any;
  result!: ResultInterface;
  resultException!: ResultInterface;
  locationForm: FormGroup;
  exceptionForm: FormGroup;
  isEdit: any = false;
  isEditException: any = false;
  noResultLocation: boolean = false;
  noResultException: boolean = false;
  isModeTableAccordion: boolean = true;
  hasPermissionCreate!: boolean;
  user: any[] = [];
  countryEntity: any[] = [];
  countries: any[] = [];
  geosEntity: any[] = [];
  locationEntity: any[] = [];
  locationFilterEntity: any[] = [];
  orderDateEntity: any[] = [];
  orderTypeEntity: any[] = [];
  plantLocationEntity: any[] = [];
  storageLocationEntity: any[] = [];
  customerOrderTypeEntity: any[] = [];
  shippingPointEntity: any[] = [];
  crudService: CrudService<any>;
  deleteService: CrudService<any>;
  crudServiceException: CrudService<any>;
  crudServiceViewParametrizationLocation: CrudService<any>;
  crudServiceViewParametrizationException: CrudService<any>;
  deleteServiceException: CrudService<any>;
  isModeTableOrCardsHide: boolean = true;
  pagination: Pagination;
  filter: any = {};
  filterException: any = {};
  event = {
    delete: 'delete',
    edit: 'edit',
    view: 'view',
  };
  messageModalDeleteConfirmation!: string;
  actualItem: any;
  filteredOptions!: Observable<any[]>;
  filteredOptionsGeo!: Observable<any[]>;
  currentUser!: any;

  tabs: Tab[] = [];
  tabsIndex: { [key: string]: any };
  messages: { [key: string]: string };
  isEditTableMode: boolean;
  selectedTabIndex: number;
  storeTab: number[];
  dataTypeEntity: any[] = [];
  formSearch!: { [key: string]: any };

  dataView: any = '';
  territoryView: string;

  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.Location },
    },
    {
      entity: 'country',
      query: {
        sort: 'country',
        distinct: 'country',
        pageName: PAGE_NAME_RESOURCE.Location,
      },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.user },
    },
    {
      entity: 'location',
      query: {
        status: true,
        sort: 'territory',
        pageName: PAGE_NAME_RESOURCE.Location,
      },
    },
  ];

  protected onDestroy$ = new Subject();
  public countryMultiCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public countryMultiFilterCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredCountryMulti: ReplaySubject<any> = new ReplaySubject(1);

  public geoMultiCtrl: FormControl = new FormControl('', Validators.required);
  public geoMultiFilterCtrl: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredGeoMulti: ReplaySubject<any> = new ReplaySubject(1);
  public orderDateMultiCtrl: FormControl = new FormControl('');
  public orderDateMultiFilterCtrl: FormControl = new FormControl('');
  public filteredOrderDateMulti: ReplaySubject<any> = new ReplaySubject(1);

  public orderTypeMultiCtrl: FormControl = new FormControl('');
  public orderTypeMultiFilterCtrl: FormControl = new FormControl('');
  public filteredOrderTypeMulti: ReplaySubject<any> = new ReplaySubject(1);

  public plantLocationMultiCtrl: FormControl = new FormControl('');
  public plantLocationMultiFilterCtrl: FormControl = new FormControl('');
  public filteredPlantLocationMulti: ReplaySubject<any> = new ReplaySubject(1);

  public storageLocationMultiCtrl: FormControl = new FormControl('');
  public storageLocationMultiFilterCtrl: FormControl = new FormControl('');
  public filteredStorageLocationMulti: ReplaySubject<any> = new ReplaySubject(
    1
  );

  public customerOrderTypeMultiCtrl: FormControl = new FormControl('');
  public customerOrderTypeMultiFilterCtrl: FormControl = new FormControl('');
  public filteredCustomerOrderTypeMulti: ReplaySubject<any> = new ReplaySubject(
    1
  );
  public shippingPointMultiCtrl: FormControl = new FormControl('');
  public shippingPointMultiFilterCtrl: FormControl = new FormControl('');
  public filteredShippingPointMulti: ReplaySubject<any> = new ReplaySubject(1);
  clickScreenSub: any;
  utilitiesService: any;

  public countryMultiCtrlException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public countryMultiFilterCtrlException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredCountryMultiException: ReplaySubject<any> = new ReplaySubject(
    1
  );

  public geoMultiCtrlException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public geoMultiFilterCtrlException: FormControl = new FormControl(
    '',
    Validators.required
  );

  public territoryCrtlException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public territoryCrtlFilterException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredTerritoryMultiException: ReplaySubject<any> =
    new ReplaySubject(1);

  public dataTypeMultiCtrlException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public dataTypeMultiCtrlFilterException: FormControl = new FormControl(
    '',
    Validators.required
  );
  public filteredDataTypeMultiException: ReplaySubject<any> = new ReplaySubject(
    1
  );

  public filteredGeoMultiException: ReplaySubject<any> = new ReplaySubject(1);
  public orderDateMultiCtrlException: FormControl = new FormControl('');
  public orderDateMultiFilterCtrlException: FormControl = new FormControl('');
  public filteredOrderDateMultiException: ReplaySubject<any> =
    new ReplaySubject(1);

  public orderTypeMultiCtrlException: FormControl = new FormControl('');
  public orderTypeMultiFilterCtrlException: FormControl = new FormControl('');
  public filteredOrderTypeMultiException: ReplaySubject<any> =
    new ReplaySubject(1);

  public plantLocationMultiCtrlException: FormControl = new FormControl('');
  public plantLocationMultiFilterCtrlException: FormControl = new FormControl(
    ''
  );
  public filteredPlantLocationMultiException: ReplaySubject<any> =
    new ReplaySubject(1);

  public storageLocationMultiCtrlException: FormControl = new FormControl('');
  public storageLocationMultiFilterCtrlException: FormControl = new FormControl(
    ''
  );
  public filteredStorageLocationMultiException: ReplaySubject<any> =
    new ReplaySubject(1);

  public customerOrderTypeMultiCtrlException: FormControl = new FormControl('');
  public customerOrderTypeMultiFilterCtrlException: FormControl =
    new FormControl('');
  public filteredCustomerOrderTypeMultiException: ReplaySubject<any> =
    new ReplaySubject(1);
  public shippingPointMultiCtrlException: FormControl = new FormControl('');
  public shippingPointMultiFilterCtrlException: FormControl = new FormControl(
    ''
  );
  public filteredShippingPointMultiException: ReplaySubject<any> =
    new ReplaySubject(1);

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService,
    private authService: AuthService,
    private validateFormService: ValidateFormService,
    private cdr: ChangeDetectorRef
  ) {
    this.pagination = { page: 0, size: 10 };
    this.crudService = new CrudService<any>(this.http, 'location');
    this.deleteService = new CrudService<any>(
      this.http,
      'location/allocations'
    );
    this.crudServiceException = new CrudService<any>(
      this.http,
      'location-exception'
    );
    this.deleteServiceException = new CrudService<any>(
      this.http,
      'location-exception/3'
    );

    this.crudServiceViewParametrizationLocation = new CrudService<any>(
      this.http,
      'location-parametrizations?territoryId='
    );

    this.crudServiceViewParametrizationException = new CrudService<any>(
      this.http,
      'http://localhost:3000/location-exceptions-parametrizations?locationExceptionId='
    );

    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('location');
    });
    this.locationForm = this.formBuilder.group({
      id: [''],
      territory: ['', Validators.required],
    });
    this.exceptionForm = this.formBuilder.group({
      id: [''],
      // territory: [''],
    });

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = { ...this.filter, geoId: this.currentUser.geoId };
    this.filterException = {
      ...this.filterException,
      geoId: this.currentUser.geoId,
    };

    this.territoryView = '';

    this.tabsIndex = {
      LOCATION: 0,
      EXCEPTION: 1,
    };
    this.selectedTabIndex = 0;
    this.storeTab = [0];
    this.messages = {
      save: 'Do you confirm ka allocation data updated?',
      discard: 'Are you sure you want to exit before saving your changes?',
      negativeValues:
        'Are you sure you want to save the allocation with negative fields?',
      noFilters: 'PLEASE, START BY FILTERING THE CONTENT',
    };
    this.isEditTableMode = false;

    this.dataTypeEntity = [
      {
        value: 'Orders',
      },
      {
        value: 'SAB',
      },
      {
        value: 'SIC/WOI',
      },
      {
        value: 'MX',
      },
      {
        value: 'Supply',
      },
      {
        value: 'PDP',
      },
      {
        value: 'BOH',
      },
    ];

    this.searchInputs = [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'territory',
        hover: 'Territory',
        type: 'text',
        classCss: 'location-filters',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        classCss: 'location-filters',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'isoCode',
        hover: 'ISO Code',
        classCss: 'location-filters',
        hasAutocomplete: true,
      }),
    ];

    this.searchInputsException = [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO',
        type: 'text',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'locationId',
        hover: 'Territory',
        type: 'text',
        classCss: 'location-filters',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'countryId',
        hover: 'Country',
        classCss: 'location-filters',
        hasAutocomplete: true,
      }),
      new MultiSelectSearchInput({
        key: 'isoCode',
        hover: 'ISO Code',
        classCss: 'location-filters',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'dataType',
        hover: 'DATA TYPE',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];
  }

  checkValid(tab?: string): any {
    let customerShortValid: any = '';

    if (tab === 'location') {
      customerShortValid = this.locationForm.controls;

      let result = true;

      if (customerShortValid.territory.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Territory');
        result = false;
      }

      if (this.countryMultiCtrl.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Country');
        result = false;
      }

      if (this.geoMultiCtrl.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Geo');
        result = false;
      }

      return result;
    }
    if (tab === 'exception') {
      const customerShortValidException = this.exceptionForm.controls;

      let result = true;

      if (this.territoryCrtlException.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Territory');
        result = false;
      }

      if (this.countryMultiCtrlException.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Country');
        result = false;
      }

      if (this.geoMultiCtrlException.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Geo');
        result = false;
      }

      if (this.dataTypeMultiCtrlException.status === 'INVALID') {
        this.toastr.warning('Mandatory field!', 'Data Type');
        result = false;
      }

      return result;
    }
  }

  clearControlValue(control: any) {
    control.setValue(null);
  }

  protected filterCountryMulti() {
    if (!this.countries) {
      return;
    }

    let search = this.countryMultiFilterCtrl.value;
    if (!search) {
      this.filteredCountryMulti.next(this.countries.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredCountryMulti.next(
      this.countries.filter(item =>
        item.country.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCountryMultiException() {
    if (!this.countries) {
      return;
    }

    let searchException = this.countryMultiFilterCtrlException.value;

    if (!searchException) {
      this.filteredCountryMultiException.next(this.countries.slice());
      return;
    }

    searchException = searchException.toLowerCase();

    this.filteredCountryMultiException.next(
      this.countries.filter(item =>
        item.country.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterTerritoryMultiException() {
    if (!this.locationFilterEntity) {
      return;
    }

    let searchException = this.territoryCrtlFilterException.value;

    if (!searchException) {
      this.filteredTerritoryMultiException.next(
        this.locationFilterEntity.slice()
      );
      return;
    }

    searchException = searchException.toLowerCase();

    this.filteredTerritoryMultiException.next(
      this.locationFilterEntity.filter(item =>
        item.territory.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterDataTypeMultiException() {
    if (!this.dataTypeEntity) {
      return;
    }

    let searchException = this.dataTypeMultiCtrlFilterException.value;

    if (!searchException) {
      this.filteredDataTypeMultiException.next(this.dataTypeEntity.slice());
      return;
    }

    searchException = searchException.toLowerCase();

    this.filteredDataTypeMultiException.next(
      this.dataTypeEntity.filter(item =>
        item.value.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterGeoSingle() {
    if (!this.geosEntity) {
      return;
    }

    let search = this.geoMultiFilterCtrl.value;
    if (!search) {
      this.filteredGeoMulti.next(this.geosEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredGeoMulti.next(
      this.geosEntity.filter(item => item.geo.toLowerCase().startsWith(search))
    );
  }

  protected filterOrderDateMulti() {
    if (!this.orderDateEntity) {
      return;
    }

    let search = this.orderDateMultiFilterCtrl.value;
    if (!search) {
      this.filteredOrderDateMulti.next(this.orderDateEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredOrderDateMulti.next(
      this.orderDateEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterOrderDateMultiException() {
    if (!this.orderDateEntity) {
      return;
    }

    let searchException = this.orderDateMultiFilterCtrlException.value;
    if (!searchException) {
      this.filteredOrderDateMultiException.next(this.orderDateEntity.slice());
      return;
    }
    searchException = searchException.toLowerCase();

    this.filteredOrderDateMultiException.next(
      this.orderDateEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterOrderTypeMulti() {
    if (!this.orderTypeEntity) {
      return;
    }

    let search = this.orderTypeMultiFilterCtrl.value;
    if (!search) {
      this.filteredOrderTypeMulti.next(this.orderTypeEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredOrderTypeMulti.next(
      this.orderTypeEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterOrderTypeMultiException() {
    if (!this.orderTypeEntity) {
      return;
    }

    let searchException = this.orderTypeMultiFilterCtrlException.value;
    if (!searchException) {
      this.filteredOrderTypeMultiException.next(this.orderTypeEntity.slice());
      return;
    }
    searchException = searchException.toLowerCase();

    this.filteredOrderTypeMultiException.next(
      this.orderTypeEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterPlantLocationMulti() {
    if (!this.plantLocationEntity) {
      return;
    }

    let search = this.plantLocationMultiFilterCtrl.value;
    if (!search) {
      this.filteredPlantLocationMulti.next(this.plantLocationEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredPlantLocationMulti.next(
      this.plantLocationEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterPlantLocationMultiException() {
    if (!this.plantLocationEntity) {
      return;
    }

    let searchException = this.plantLocationMultiFilterCtrlException.value;
    if (!searchException) {
      this.filteredPlantLocationMultiException.next(
        this.plantLocationEntity.slice()
      );
      return;
    }
    searchException = searchException.toLowerCase();

    this.filteredPlantLocationMultiException.next(
      this.plantLocationEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterStorageLocationMulti() {
    if (!this.storageLocationEntity) {
      return;
    }

    let search = this.storageLocationMultiFilterCtrl.value;
    if (!search) {
      this.filteredStorageLocationMulti.next(
        this.storageLocationEntity.slice()
      );
      return;
    }
    search = search.toLowerCase();

    this.filteredStorageLocationMulti.next(
      this.storageLocationEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterStorageLocationMultiException() {
    if (!this.storageLocationEntity) {
      return;
    }

    let search = this.storageLocationMultiFilterCtrlException.value;
    if (!search) {
      this.filteredStorageLocationMultiException.next(
        this.storageLocationEntity.slice()
      );
      return;
    }
    search = search.toLowerCase();

    this.filteredStorageLocationMultiException.next(
      this.storageLocationEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCustomerOrderTypeMulti() {
    if (!this.customerOrderTypeEntity) {
      return;
    }

    let search = this.customerOrderTypeMultiFilterCtrl.value;
    if (!search) {
      this.filteredCustomerOrderTypeMulti.next(
        this.customerOrderTypeEntity.slice()
      );
      return;
    }
    search = search.toLowerCase();

    this.filteredCustomerOrderTypeMulti.next(
      this.customerOrderTypeEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterCustomerOrderTypeMultiException() {
    if (!this.customerOrderTypeEntity) {
      return;
    }

    let searchException = this.customerOrderTypeMultiFilterCtrlException.value;
    if (!searchException) {
      this.filteredCustomerOrderTypeMultiException.next(
        this.customerOrderTypeEntity.slice()
      );
      return;
    }
    searchException = searchException.toLowerCase();

    this.filteredCustomerOrderTypeMultiException.next(
      this.customerOrderTypeEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(searchException)
      )
    );
  }

  protected filterShippingPointMulti() {
    if (!this.shippingPointEntity) {
      return;
    }

    let search = this.shippingPointMultiFilterCtrl.value;
    if (!search) {
      this.filteredShippingPointMulti.next(this.shippingPointEntity.slice());
      return;
    }
    search = search.toLowerCase();

    this.filteredShippingPointMulti.next(
      this.shippingPointEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(search)
      )
    );
  }

  protected filterShippingPointMultiException() {
    if (!this.shippingPointEntity) {
      return;
    }

    let searchException = this.shippingPointMultiFilterCtrlException.value;
    if (!searchException) {
      this.filteredShippingPointMultiException.next(
        this.shippingPointEntity.slice()
      );
      return;
    }
    searchException = searchException.toLowerCase();

    this.filteredShippingPointMultiException.next(
      this.shippingPointEntity.filter(item =>
        item.summary_value.toLowerCase().startsWith(searchException)
      )
    );
  }

  ngOnInit(): void {
    this.loadData();
  }

  private filterOptions(
    value: string,
    entities: any[],
    property: string
  ): any[] {
    const filterValue = value?.toLocaleUpperCase() || '';
    return entities.filter((option: any) =>
      option[property]?.toLocaleUpperCase().includes(filterValue)
    );
  }

  loadData(tab: string = '') {
    if (tab === 'location' || tab === '') {
      this.getDataFilter(this.entities).then(() =>
        this.getLocation(this.pagination || undefined, this.filter)
      );
      this.getParametrizationFields();
    }
    if (tab === 'exception' || tab === '') {
      this.getDataFilter(this.entities).then(() =>
        this.getException(this.pagination || undefined, this.filter)
      );
      this.getParametrizationFields();
    }
  }

  getLocation(page?: pageLocation, params?: paramsLocation) {
    if (!params) {
      params = {};
    }
    params.geo_id = this.currentUser.geoId;

    if (params.countryId && Array.isArray(params.countryId)) {
      let countryArray: any = [];
      params.countryId.forEach((item: any) => {
        countryArray.push(item.value);
      });
      params.countryId = countryArray.join(',');
    } else {
      delete params.countryId;
    }

    if (params.isoCode && Array.isArray(params.isoCode)) {
      let codeArray: any = [];
      params.isoCode.forEach((item: any) => {
        codeArray.push(item.value);
      });
      params.isoCode = codeArray.join(',');
    } else {
      delete params.isoCode;
    }

    if (params.territory && Array.isArray(params.territory)) {
      let terrArray: any = [];
      params.territory.forEach((item: any) => {
        terrArray.push(item.value);
      });
      params.territory = terrArray.join(',');
    } else {
      delete params.territory;
    }

    this.spinner.show();

    this.crudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        if (response.rows.length > 0) {
          this.updateComponents(response);
          this.noResultLocation = false;
        } else {
          this.noResultLocation = true;
        }

        this.spinner.hide();
      });
  }

  getException(page?: pageLocation, params?: paramsException) {
    if (!params) {
      params = {};
    }

    this.spinner.show();

    this.crudServiceException
      .getPaged(page, params || this.filterException)
      .subscribe((response: any) => {
        if (response.rows.length > 0) {
          this.updateComponentsException(response);
          this.noResultException = false;
        } else {
          this.noResultException = true;
        }

        this.spinner.hide();
      });
  }

  private filterEntitiesByType(entities: any[], type: string): any[] {
    return entities
      .filter((item: any) => item.summary_type === type)
      .sort((a, b) =>
        a.summary_value > b.summary_value
          ? 1
          : b.summary_value > a.summary_value
          ? -1
          : 0
      );
  }

  async getParametrizationFields() {
    this.orderDateEntity = [];
    this.orderTypeEntity = [];
    this.plantLocationEntity = [];
    this.storageLocationEntity = [];
    this.customerOrderTypeEntity = [];
    this.shippingPointEntity = [];

    this.crudService
      .getEntity('location-summary')
      .subscribe((response: any) => {
        this.orderDateEntity = this.filterEntitiesByType(
          response,
          'ORDER_DATE_TYPE_CODE'
        );
        this.orderTypeEntity = this.filterEntitiesByType(
          response,
          'ORDER_TYPE'
        );
        this.plantLocationEntity = this.filterEntitiesByType(
          response,
          'PLANT_LOCATION'
        );
        this.storageLocationEntity = this.filterEntitiesByType(
          response,
          'STORAGE_LOCATION'
        );
        this.customerOrderTypeEntity = this.filterEntitiesByType(
          response,
          'CUSTOMER_ORDER_TYPE'
        );
        this.shippingPointEntity = this.filterEntitiesByType(
          response,
          'SHIPPING_POINT'
        );
      });
  }

  async getDataFilter(filters: any) {
    this.spinner.show();

    filters.forEach((item: any) => {
      item.query['geo_id'] = [this.currentUser.geoId];
    });

    this.crudService.getDataFilters(filters).subscribe((response: any[]) => {
      [
        this.geosEntity,
        this.countryEntity,
        this.countries,
        this.locationFilterEntity,
      ] = response;

      this.dataFilterGeo(this.geosEntity);
      this.dataFilterTerritory(this.locationFilterEntity);
      this.dataFilterCountry(this.countryEntity);
      this.dataFilterIsoCode(this.countries);
      this.dataFilterDataType(this.dataTypeEntity);
    });
  }

  dataFilterGeo(response: any) {
    const initialValueGeoFilter: { [key: string]: any } = {};
    const geosFilter: any[] = [];

    response.forEach((item: Geo) => {
      geosFilter.push({ value: item.geo, id: item.id });

      if (item.id === this.currentUser.geoId) {
        initialValueGeoFilter.value = item.geo;
        initialValueGeoFilter.id = item.id;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      hasAutocomplete: true,
      options: geosFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
    });

    const selectGeoException = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO',
      type: 'text',
      hasAutocomplete: true,
      options: geosFilter,
      value: initialValueGeoFilter.value,
      disabled: true,
    });

    this.searchInputs[0] = selectGeo;
    this.searchInputs[0].notClearable = true;
    this.searchInputs = [...this.searchInputs];

    this.searchInputsException[0] = selectGeoException;
    this.searchInputsException[0].notClearable = true;
    this.searchInputsException = [...this.searchInputsException];
  }

  dataFilterTerritory(response: any) {
    const territoryFilter: any[] = [];

    response.forEach((item: any) => {
      territoryFilter.push({ value: item.territory, id: item.territory });
    });

    const selectTerritory = new MultiSelectSearchInput({
      key: 'territory',
      hover: 'Territory',
      type: 'text',
      hasAutocomplete: true,
      classCss: 'location-filters',
      options: territoryFilter,
    });

    const selectTerritoryException = new MultiSelectSearchInput({
      key: 'locationId',
      hover: 'Territory',
      type: 'text',
      hasAutocomplete: true,
      classCss: 'exception-filters',
      options: territoryFilter,
    });

    this.searchInputs[1] = selectTerritory;
    this.searchInputs = [...this.searchInputs];

    this.searchInputsException[1] = selectTerritoryException;
    this.searchInputsException = [...this.searchInputsException];
  }

  dataFilterCountry(response: any) {
    const countryFilter: any[] = [];

    response.forEach((item: Country) => {
      countryFilter.push({ value: item.country, id: item.id });
    });

    const selectCountry = new MultiSelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'array',
      classCss: 'location-filters',
      hasAutocomplete: true,
      options: countryFilter,
    });

    const selectCountryException = new MultiSelectSearchInput({
      key: 'countryId',
      hover: 'Country',
      type: 'array',
      classCss: 'exception-filters',
      hasAutocomplete: true,
      options: countryFilter,
    });

    this.searchInputs[2] = selectCountry;
    this.searchInputs = [...this.searchInputs];

    this.searchInputsException[2] = selectCountryException;
    this.searchInputsException = [...this.searchInputsException];
  }

  dataFilterIsoCode(response: any) {
    const isoCodeFilter: any[] = [];

    response.forEach((item: Country) => {
      isoCodeFilter.push({ value: item.iso_code, id: item.iso_code });
    });

    const selectIsoCode = new MultiSelectSearchInput({
      key: 'isoCode',
      hover: 'ISO Code',
      type: 'text',
      classCss: 'location-filters',
      hasAutocomplete: true,
      options: isoCodeFilter,
    });

    const selectIsoCodeException = new MultiSelectSearchInput({
      key: 'isoCode',
      hover: 'ISO Code',
      type: 'text',
      classCss: 'exception-filters',
      hasAutocomplete: true,
      options: isoCodeFilter,
    });

    const allCountries = this.countries;
    this.countries = [];

    allCountries.forEach((country: any) => {
      this.countries.push({
        key: country.id,
        id: country.id,
        country: country.country + ' - ' + country.iso_code,
      });
    });

    this.searchInputs[3] = selectIsoCode;
    this.searchInputs = [...this.searchInputs];

    this.searchInputsException[3] = selectIsoCodeException;
    this.searchInputsException = [...this.searchInputsException];
  }

  dataFilterDataType(response: any) {
    const dataTypeFilter: any[] = [];

    response.forEach((item: any) => {
      dataTypeFilter.push({ value: item.value });
    });

    const selectDataType = new SelectSearchInput({
      key: 'dataType',
      hover: 'Data Type',
      type: 'text',
      classCss: 'exception-filters',
      hasAutocomplete: true,
      options: dataTypeFilter,
    });

    this.searchInputsException[4] = selectDataType;
    this.searchInputsException = [...this.searchInputsException];
  }

  updateComponents(response: updateLocation) {
    this.result = {
      table: {
        columns: [
          {
            label: 'geo',
            prop: 'geo',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'territory',
            prop: 'territory',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'country',
            prop: 'country',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
            accordion: true,
          },
          {
            label: 'iso code',
            prop: 'iso_code',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
            accordion: true,
          },
        ],
        rows: new MatTableDataSource<any>(
          this.dataTables(JSON.stringify(response.rows))
        ),
      },
      pageIndex: response.page,
      pageSize: response.totalRows,
      pagesTotal: response.totalPages,
      isModeTableAccordion: true,
      selectedTab: 'emea_location',
    };
    const actions = this.permissionService.setTablePermissions('location');
    actions.view = true;

    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  updateComponentsException(response: updateException) {
    this.resultException = {
      table: {
        columns: [
          {
            label: 'geo',
            prop: 'geo',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'data type',
            prop: 'data_type',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'territory',
            prop: 'territory',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
          },
          {
            label: 'country',
            prop: 'country',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
            accordion: true,
          },
          {
            label: 'iso code',
            prop: 'iso_code',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '15%' },
            accordion: true,
          },
        ],
        rows: new MatTableDataSource<any>(
          this.dataTablesException(JSON.stringify(response.rows))
        ),
      },
      pageIndex: response.page,
      pageSize: response.totalRows,
      pagesTotal: response.totalPages,
      isModeTableAccordion: true,
      selectedTab: 'emea_exception',
    };

    const actions = this.permissionService.setTablePermissions('location');
    actions.view = true;

    if (actions.label === 'actions') {
      this.resultException.table?.columns?.push(actions);
    }
  }

  getCountries(data: any) {
    let countries =
      data.length + `${data.length > 1 ? ' Countries' : ' Country'}`;

    return countries;
  }

  getIsoCode(data: any) {
    let isoCode =
      data.length + `${data.length > 1 ? ' ISO Codes' : ' ISO Code'}`;

    return isoCode;
  }
  dataMapToTable(data: any): any[] {
    return JSON.parse(data).map((item: any) => {
      return {
        id: item.id,
        geo: item.geo ? item.geo.geo : '',
        territory: item.territory ? item.territory : item.territory,
        country: item.countries ? this.getCountries(item.countries) : '',
        countriesDetails: item.countries ? item.countries : '',
        iso_code: item.countries ? this.getIsoCode(item.countries) : '',
        iso_codes_details: item.countries ? item.countries : '',
        editNotActive: !item.territory,
        deleteNotActive: !item.territory,
        viewNotActive: !item.territory,
        showActions: !item.territory,
      };
    });
  }

  dataMapToTableException(data: any): any[] {
    return JSON.parse(data).map((item: any) => {
      return {
        id: item.id,
        geo: item.geo ? item.geo.geo : '',
        data_type: item.dataType,
        territory: item.territory ? item.territory : item.location.territory,
        country: item.countries ? this.getCountries(item.countries) : '',
        countriesDetails: item.countries ? item.countries : '',
        iso_code: item.countries ? this.getIsoCode(item.countries) : '',
        iso_codes_details: item.countries ? item.countries : '',
        editNotActive: item.territory,
        deleteNotActive: item.territory,
        showActions: item.territory,
      };
    });
  }

  dataTables(data: any) {
    const user = this.dataMapToTable(data);
    const dataTables: any[] = [];
    user.forEach((item: any) => {
      dataTables.push(item);
    });
    return [...dataTables];
  }

  dataTablesException(data: any) {
    const user = this.dataMapToTableException(data);
    const dataTables: any[] = [];
    user.forEach((item: any) => {
      dataTables.push(item);
    });
    return [...dataTables];
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getLocation(paginated, this.filter);
  }

  onEventActions(event: any, tab: string) {
    if (event.type === this.event.edit) {
      if (tab === 'location') {
        this.prepareToEdit(event.item.id);
      } else if (tab === 'exception') {
        this.prepareToEditExeption(event.item.id);
      }
    } else if (event.type === this.event.delete) {
      if (tab === 'location') {
        this.onDelete(event.item.id);
        this.messageModalDeleteConfirmation = `There are allocations associated with the location: ${event.item.territory}. If you continue, the allocations will be deleted. Do you want to continue with the deletion?`;
      } else if (tab === 'exception') {
        this.onDeleteException(event.item.id);
        this.messageModalDeleteConfirmation = `There are allocations associated with the location: ${event.item.territory}. If you continue, the allocations will be deleted. Do you want to continue with the deletion?`;
      }
    } else if (event.type === this.event.view) {
      this.viewParametrization(tab, event.item.id);
    }
  }

  prepareToEdit(id: number) {
    this.countryMultiCtrl.setValue([]);
    this.geoMultiCtrl.setValue([]);

    this.countryMultiCtrl.setValue(null);
    this.filteredCountryMulti.next(this.countries.slice());
    this.countryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMulti();
      });

    this.geoMultiCtrl.setValue(null);
    this.filteredGeoMulti.next(this.geosEntity.slice());
    this.geoMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMulti();
      });

    this.orderDateMultiCtrl.setValue(null);
    this.filteredOrderDateMulti.next(this.orderDateEntity.slice());
    this.orderDateMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderDateMulti();
      });

    this.filteredOrderTypeMulti.next(this.orderTypeEntity.slice());
    this.orderTypeMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderTypeMulti();
      });

    this.plantLocationMultiCtrl.setValue(null);
    this.filteredPlantLocationMulti.next(this.plantLocationEntity.slice());
    this.plantLocationMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterPlantLocationMulti();
      });

    this.storageLocationMultiCtrl.setValue(null);
    this.filteredStorageLocationMulti.next(this.storageLocationEntity.slice());
    this.storageLocationMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterStorageLocationMulti();
      });

    this.customerOrderTypeMultiCtrl.setValue(null);
    this.filteredCustomerOrderTypeMulti.next(
      this.customerOrderTypeEntity.slice()
    );
    this.customerOrderTypeMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerOrderTypeMulti();
      });

    this.shippingPointMultiCtrl.setValue(null);
    this.filteredShippingPointMulti.next(this.shippingPointEntity.slice());
    this.shippingPointMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterShippingPointMulti();
      });

    this.spinner.show();
    this.isEdit = true;
    this.crudService.fetchEntity(id).subscribe((item: any) => {
      const countrySelected: any = [];
      item.countries?.map((countrySel: any) =>
        countrySelected.push(countrySel.id)
      );

      this.countryMultiCtrl.setValue(countrySelected);

      this.geoMultiCtrl.setValue(item.geoId);

      if (item.params.length > 0) {
        const orderDateSelected: any = [];
        const orderTypeSelected: any = [];
        const planLocationSelected: any = [];
        const storageLocationSelected: any = [];
        const customerOrderTypeSelected: any = [];
        const shippingPointSelected: any = [];

        item.params?.map((value: any) => {
          if (value.type === 'ORDER_DATE_TYPE_CODE') {
            orderDateSelected.push(value.id);
          }
          if (value.type === 'ORDER_TYPE') {
            orderTypeSelected.push(value.id);
          }
          if (value.type === 'PLANT_LOCATION') {
            planLocationSelected.push(value.id);
          }
          if (value.type === 'STORAGE_LOCATION') {
            storageLocationSelected.push(value.id);
          }
          if (value.type === 'CUSTOMER_ORDER_TYPE') {
            customerOrderTypeSelected.push(value.id);
          }
          if (value.type === 'SHIPPING_POINT') {
            shippingPointSelected.push(value.id);
          }
        });
        this.orderDateMultiCtrl.setValue(orderDateSelected);
        this.orderTypeMultiCtrl.setValue(orderTypeSelected);
        this.plantLocationMultiCtrl.setValue(planLocationSelected);
        this.storageLocationMultiCtrl.setValue(storageLocationSelected);
        this.customerOrderTypeMultiCtrl.setValue(customerOrderTypeSelected);
        this.shippingPointMultiCtrl.setValue(shippingPointSelected);
      }

      this.locationForm.controls.id.setValue(item.id);
      this.locationForm.controls.territory.setValue(item.territory);
      this.actualItem = item;
      const modal = this.modalService.createModal(this.modalCreateEdit);
      modal.afterClosed().subscribe(() => {
        this.locationForm.reset();
      });
      this.spinner.hide();
    });
  }

  prepareToEditExeption(id: number) {
    this.countryMultiCtrlException.setValue([]);
    this.geoMultiCtrlException.setValue([]);

    this.countryMultiCtrlException.setValue(null);
    this.filteredCountryMultiException.next(this.countries.slice());
    this.countryMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMultiException();
      });

    this.geoMultiCtrlException.setValue(null);
    this.filteredGeoMultiException.next(this.geosEntity.slice());
    this.geoMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMultiException();
      });

    this.territoryCrtlException.setValue(null);
    this.filteredTerritoryMultiException.next(
      this.locationFilterEntity.slice()
    );
    this.territoryCrtlFilterException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterTerritoryMultiException();
      });

    this.dataTypeMultiCtrlException.setValue(null);
    this.filteredDataTypeMultiException.next(this.dataTypeEntity.slice());
    this.dataTypeMultiCtrlFilterException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterDataTypeMultiException();
      });

    this.orderDateMultiCtrlException.setValue(null);
    this.filteredOrderDateMultiException.next(this.orderDateEntity.slice());
    this.orderDateMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderDateMultiException();
      });

    this.orderTypeMultiCtrlException.setValue(null);
    this.filteredOrderTypeMultiException.next(this.orderTypeEntity.slice());
    this.orderTypeMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderTypeMultiException();
      });

    this.plantLocationMultiCtrlException.setValue(null);
    this.filteredPlantLocationMultiException.next(
      this.plantLocationEntity.slice()
    );
    this.plantLocationMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterPlantLocationMultiException();
      });

    this.storageLocationMultiCtrlException.setValue(null);
    this.filteredStorageLocationMultiException.next(
      this.storageLocationEntity.slice()
    );
    this.storageLocationMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterStorageLocationMultiException();
      });

    this.customerOrderTypeMultiCtrlException.setValue(null);
    this.filteredCustomerOrderTypeMultiException.next(
      this.customerOrderTypeEntity.slice()
    );
    this.customerOrderTypeMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerOrderTypeMultiException();
      });

    this.shippingPointMultiCtrlException.setValue(null);
    this.filteredShippingPointMultiException.next(
      this.shippingPointEntity.slice()
    );
    this.shippingPointMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterShippingPointMultiException();
      });

    this.spinner.show();
    this.isEditException = true;

    this.crudServiceException.fetchEntity(id).subscribe((item: any) => {
      const countrySelected: any = [];
      item.countries?.map((countrySel: any) =>
        countrySelected.push(countrySel.id)
      );

      this.territoryCrtlException.setValue(item.locationId);
      this.countryMultiCtrlException.setValue(countrySelected);
      this.geoMultiCtrlException.setValue(item.geoId);
      this.dataTypeMultiCtrlException.setValue(item.dataType);

      if (item.params.length > 0) {
        const orderDateSelected: any = [];
        const orderTypeSelected: any = [];
        const planLocationSelected: any = [];
        const storageLocationSelected: any = [];
        const customerOrderTypeSelected: any = [];
        const shippingPointSelected: any = [];

        item.params?.map((value: any) => {
          if (value.type === 'ORDER_DATE_TYPE_CODE') {
            orderDateSelected.push(value.id);
          }
          if (value.type === 'ORDER_TYPE') {
            orderTypeSelected.push(value.id);
          }
          if (value.type === 'PLANT_LOCATION') {
            planLocationSelected.push(value.id);
          }
          if (value.type === 'STORAGE_LOCATION') {
            storageLocationSelected.push(value.id);
          }
          if (value.type === 'CUSTOMER_ORDER_TYPE') {
            customerOrderTypeSelected.push(value.id);
          }
          if (value.type === 'SHIPPING_POINT') {
            shippingPointSelected.push(value.id);
          }
        });

        this.orderDateMultiCtrlException.setValue(orderDateSelected);
        this.orderTypeMultiCtrlException.setValue(orderTypeSelected);
        this.plantLocationMultiCtrlException.setValue(planLocationSelected);
        this.storageLocationMultiCtrlException.setValue(
          storageLocationSelected
        );
        this.customerOrderTypeMultiCtrlException.setValue(
          customerOrderTypeSelected
        );
        this.shippingPointMultiCtrlException.setValue(shippingPointSelected);
      }

      this.exceptionForm.controls.id.setValue(item.id);

      this.actualItem = item;
      const modal = this.modalService.createModal(
        this.modalCreateEditException
      );
      modal.afterClosed().subscribe(() => {
        this.exceptionForm.reset();
      });
      this.spinner.hide();
    });
  }

  viewParametrization(tab: string, id: any) {
    this.spinner.show();

    if (tab === 'location') {
      const dataView: any = [];
      this.territoryView = '';

      this.crudServiceViewParametrizationLocation
        .getEntity(`location-parametrizations?territoryId=${id}`)
        .subscribe((item: any) => {
          if (item[0].params.length > 0) {
            const orderDateSelected: any = [];
            const orderTypeSelected: any = [];
            const planLocationSelected: any = [];
            const storageLocationSelected: any = [];
            const customerOrderTypeSelected: any = [];
            const shippingPointSelected: any = [];

            item[0].params?.map((value: any) => {
              if (value.type === 'ORDER_DATE_TYPE_CODE') {
                orderDateSelected.push(value.value);
                dataView.push({
                  title: 'Order Date',
                  value: orderDateSelected,
                });
              }
              if (value.type === 'ORDER_TYPE') {
                orderTypeSelected.push(value.value);
                dataView.push({
                  title: 'Order Type',
                  value: orderTypeSelected,
                });
              }
              if (value.type === 'PLANT_LOCATION') {
                planLocationSelected.push(value.value);
                dataView.push({
                  title: 'Plant Location',
                  value: planLocationSelected,
                });
              }
              if (value.type === 'STORAGE_LOCATION') {
                storageLocationSelected.push(value.value);
                dataView.push({
                  title: 'Storage Location',
                  value: storageLocationSelected,
                });
              }
              if (value.type === 'CUSTOMER_ORDER_TYPE') {
                customerOrderTypeSelected.push(value.value);
                dataView.push({
                  title: 'Customer Order',
                  value: customerOrderTypeSelected,
                });
              }
              if (value.type === 'SHIPPING_POINT') {
                shippingPointSelected.push(value.value);
                dataView.push({
                  title: 'Shiping Point',
                  value: shippingPointSelected,
                });
              }
            });
          }

          const dataViewFinal = this.removingItemsByKeys(dataView, 'title');

          this.dataView = dataViewFinal;

          this.territoryView = item[0].territory;
          this.modalService.createModal(this.modalViewParametrization);
          this.spinner.hide();
        });
    }

    if (tab === 'exception') {
      const dataView: any = [];
      this.territoryView = '';

      this.crudServiceViewParametrizationException
        .getEntity(
          `location-exceptions-parametrizations?locationExceptionId=${id}`
        )
        .subscribe((item: any) => {
          if (item[0].params.length > 0) {
            const orderDateSelected: any = [];
            const orderTypeSelected: any = [];
            const planLocationSelected: any = [];
            const storageLocationSelected: any = [];
            const customerOrderTypeSelected: any = [];
            const shippingPointSelected: any = [];

            item[0].params?.map((value: any) => {
              if (value.type === 'ORDER_DATE_TYPE_CODE') {
                orderDateSelected.push(value.value);
                dataView.push({
                  title: 'Order Date',
                  value: orderDateSelected,
                });
              }
              if (value.type === 'ORDER_TYPE') {
                orderTypeSelected.push(value.value);
                dataView.push({
                  title: 'Order Type',
                  value: orderTypeSelected,
                });
              }
              if (value.type === 'PLANT_LOCATION') {
                planLocationSelected.push(value.value);
                dataView.push({
                  title: 'Plant Location',
                  value: planLocationSelected,
                });
              }
              if (value.type === 'STORAGE_LOCATION') {
                storageLocationSelected.push(value.value);
                dataView.push({
                  title: 'Storage Location',
                  value: storageLocationSelected,
                });
              }
              if (value.type === 'CUSTOMER_ORDER_TYPE') {
                customerOrderTypeSelected.push(value.value);
                dataView.push({
                  title: 'Customer Order',
                  value: customerOrderTypeSelected,
                });
              }
              if (value.type === 'SHIPPING_POINT') {
                shippingPointSelected.push(value.value);
                dataView.push({
                  title: 'Shiping Point',
                  value: shippingPointSelected,
                });
              }
            });
          }

          const dataViewFinal = this.removingItemsByKeys(dataView, 'title');

          this.dataView = dataViewFinal;

          this.locationFilterEntity.forEach((location: any) => {
            if (item[0].locationId === location.id) {
              this.territoryView = location.territory;
            }
          });

          this.modalService.createModal(this.modalViewParametrization);
          this.spinner.hide();
        });
    }
  }

  removingItemsByKeys(array: any[], key: string): any[] {
    const keys = new Set<string>();
    const result: any[] = [];

    for (const item of array) {
      if (!keys.has(item[key])) {
        keys.add(item[key]);
        result.push(item);
      }
    }

    return result;
  }

  ngAfterViewInit(): void {
    this.allTabs();
    this.cdr.detectChanges();
  }

  allTabs() {
    this.tabs = [
      { label: 'Location', tab: 'LOCATION', template: this.Location },
      {
        label: 'Exception',
        tab: 'EXCEPTION',
        template: this.Exception,
      },
    ];
  }

  onActiveTabIndexChange(index: number) {
    this.selectedTabIndex = index; // atualiza a variável com o índice selecionado
    // this.checkTabClick(index);
  }

  // checkTabClick(index: number) {
  //   if (index == 0) {
  //     this.getLocation();
  //   } else if (index == 1) {
  //     this.getException();
  //   }
  // }

  filterIdsByValues(entities: any[], array: string[]): number[] {
    return entities
      .filter(item => array.includes(item.summary_value))
      .map(item => item.summary_id);
  }

  prepareToCreate() {
    this.spinner.show();
    this.countryMultiCtrl.setValue([]);
    this.geoMultiCtrl.setValue([]);
    this.plantLocationMultiCtrl.setValue([]);
    this.storageLocationMultiCtrl.setValue([]);
    this.customerOrderTypeMultiCtrl.setValue([]);

    // country
    this.countryMultiCtrl.setValue(null);
    this.filteredCountryMulti.next(this.countries.slice());
    this.countryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMulti();
      });

    // geo
    this.geoMultiCtrl.setValue(this.currentUser.geoId);
    this.filteredGeoMulti.next(this.geosEntity.slice());
    this.geoMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMulti();
      });

    //  order date
    const defaultValueOrderDate = [
      'YBOR',
      'YVOR',
      'YBIN',
      'YVIN',
      'YSFD',
      'YBOI',
      'YBSD',
      'YVSD',
    ];

    const defaultValueOrderDateIds = this.filterIdsByValues(
      this.orderDateEntity,
      defaultValueOrderDate
    );

    this.orderDateMultiCtrl.setValue(defaultValueOrderDateIds);
    this.filteredOrderDateMulti.next(this.orderDateEntity.slice());
    this.orderDateMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderDateMulti();
      });

    // order type
    const defaultValueOrderType = [
      'LI-Std Sales Order',
      'EM-Std Sales Order',
      'LI-Internal Order',
      'EM-Internal Order',
      'HUB - Replenishment',
      'LI-Ecommerce Order',
      'Free of Charge',
      'LI-Non-chargeable',
      'EM-Non-chargeable',
      'LI-DC Reple. Order',
    ];

    const defaultValueOrderTypeIds = this.filterIdsByValues(
      this.orderTypeEntity,
      defaultValueOrderType
    );

    this.orderTypeMultiCtrl.setValue(defaultValueOrderTypeIds);
    this.filteredOrderTypeMulti.next(this.orderTypeEntity.slice());
    this.orderTypeMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderTypeMulti();
      });

    // plant location
    this.plantLocationMultiCtrl.setValue(null);
    this.filteredPlantLocationMulti.next(this.plantLocationEntity.slice());
    this.plantLocationMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterPlantLocationMulti();
      });

    // storage location
    this.storageLocationMultiCtrl.setValue(null);
    this.filteredStorageLocationMulti.next(this.storageLocationEntity.slice());
    this.storageLocationMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterStorageLocationMulti();
      });

    // custom order
    this.customerOrderTypeMultiCtrl.setValue(null);
    this.filteredCustomerOrderTypeMulti.next(
      this.customerOrderTypeEntity.slice()
    );
    this.customerOrderTypeMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerOrderTypeMulti();
      });

    // shipping point
    const defaultValueShippingPoint = ['CN50', 'CN60', 'OE65', 'OEC6', 'SC03'];

    const defaultValueShippingPointIds = this.filterIdsByValues(
      this.shippingPointEntity,
      defaultValueShippingPoint
    );

    this.shippingPointMultiCtrl.setValue(defaultValueShippingPointIds);
    this.filteredShippingPointMulti.next(this.shippingPointEntity.slice());
    this.shippingPointMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterShippingPointMulti();
      });

    this.isEdit = false;
    const createModal = this.modalService.createModal(this.modalCreateEdit);
    createModal.afterClosed().subscribe(() => {
      this.locationForm.reset();
    });
    this.spinner.hide();
  }

  prepareToCreateException() {
    this.spinner.show();
    this.geoMultiCtrlException.setValue([]);
    this.countryMultiCtrlException.setValue([]);
    this.territoryCrtlException.setValue([]);
    this.dataTypeMultiCtrlException.setValue([]);
    this.plantLocationMultiCtrlException.setValue([]);
    this.storageLocationMultiCtrlException.setValue([]);
    this.customerOrderTypeMultiCtrlException.setValue([]);

    // country
    this.countryMultiCtrlException.setValue(null);
    this.filteredCountryMultiException.next(this.countries.slice());
    this.countryMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMultiException();
      });

    // geo
    this.geoMultiCtrlException.setValue(this.currentUser.geoId);
    this.filteredGeoMultiException.next(this.geosEntity.slice());
    this.geoMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCountryMultiException();
      });

    // territory
    this.territoryCrtlException.setValue(null);
    this.filteredTerritoryMultiException.next(
      this.locationFilterEntity.slice()
    );
    this.territoryCrtlFilterException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterTerritoryMultiException();
      });

    // data type
    this.dataTypeMultiCtrlException.setValue(null);
    this.filteredDataTypeMultiException.next(this.dataTypeEntity.slice());
    this.dataTypeMultiCtrlFilterException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterDataTypeMultiException();
      });

    // order type
    const defaultValueOrderType = [
      'LI-Std Sales Order',
      'EM-Std Sales Order',
      'LI-Internal Order',
      'EM-Internal Order',
      'HUB - Replenishment',
      'LI-Ecommerce Order',
      'Free of Charge',
      'LI-Non-chargeable',
      'EM-Non-chargeable',
      'LI-DC Reple. Order',
    ];

    const defaultValueOrderTypeIds = this.filterIdsByValues(
      this.orderTypeEntity,
      defaultValueOrderType
    );

    this.orderTypeMultiCtrlException.setValue(defaultValueOrderTypeIds);
    this.filteredOrderTypeMultiException.next(this.orderTypeEntity.slice());
    this.orderTypeMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderTypeMultiException();
      });

    // order date
    const defaultValueOrderDate = [
      'YBOR',
      'YVOR',
      'YBIN',
      'YVIN',
      'YSFD',
      'YBOI',
      'YBSD',
      'YVSD',
    ];

    const defaultValueOrderDateIds = this.filterIdsByValues(
      this.orderDateEntity,
      defaultValueOrderDate
    );

    this.orderDateMultiCtrlException.setValue(defaultValueOrderDateIds);
    this.filteredOrderDateMultiException.next(this.orderDateEntity.slice());
    this.orderDateMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterOrderDateMultiException();
      });

    // plantion location
    this.plantLocationMultiCtrlException.setValue(null);
    this.filteredPlantLocationMultiException.next(
      this.plantLocationEntity.slice()
    );
    this.plantLocationMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterPlantLocationMultiException();
      });

    // storage location
    this.storageLocationMultiCtrlException.setValue(null);
    this.filteredStorageLocationMultiException.next(
      this.storageLocationEntity.slice()
    );
    this.storageLocationMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterStorageLocationMultiException();
      });

    // custom order
    this.customerOrderTypeMultiCtrlException.setValue(null);
    this.filteredCustomerOrderTypeMultiException.next(
      this.customerOrderTypeEntity.slice()
    );
    this.customerOrderTypeMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterCustomerOrderTypeMultiException();
      });

    // shipping point
    const defaultValueShippingPoint = ['CN50', 'CN60', 'OE65', 'OEC6', 'SC03'];

    const defaultValueShippingPointIds = this.filterIdsByValues(
      this.shippingPointEntity,
      defaultValueShippingPoint
    );

    this.shippingPointMultiCtrlException.setValue(defaultValueShippingPointIds);
    this.filteredShippingPointMultiException.next(
      this.shippingPointEntity.slice()
    );
    this.shippingPointMultiFilterCtrlException.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.filterShippingPointMultiException();
      });

    this.isEditException = false;
    const createModal = this.modalService.createModal(
      this.modalCreateEditException
    );
    createModal.afterClosed().subscribe(() => {
      this.exceptionForm.reset();
    });
    this.spinner.hide();
  }

  showInvalidsControlForm() {
    Object.keys(this.locationForm.controls).forEach((key: string) =>
      this.locationForm.controls[key].markAsTouched()
    );
  }

  showInvalidsControlFormException() {
    Object.keys(this.exceptionForm.controls).forEach((key: string) =>
      this.exceptionForm.controls[key].markAsTouched()
    );
  }

  onCreate() {
    if (this.checkValid('location')) {
      if (this.validateFormService.onValidateFields(this.locationForm)) {
        this.spinner.show();
        const location: any = this.locationForm.value;
        if (this.countryMultiCtrl.value) {
          location.countryId = this.countryMultiCtrl.value.map(
            (country: any) => ({ id: country })
          );
        }

        const controlsToCreateParams = [
          this.orderDateMultiCtrl,
          this.orderTypeMultiCtrl,
          this.plantLocationMultiCtrl,
          this.storageLocationMultiCtrl,
          this.customerOrderTypeMultiCtrl,
          this.shippingPointMultiCtrl,
        ];

        location.params = this.createParamsFromControls(controlsToCreateParams);

        location.geoId = this.geoMultiCtrl.value;
        delete location.id;

        this.crudService.createEntity(location).subscribe(
          () => {
            this.toastr.success(
              `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
              `${this.translatorService.getTitle('CRUD_SUCCESS')} Association`
            );
            this.modalService.closeAll();
            this.locationForm.reset();
            this.loadData('location');
            this.spinner.hide();
          },
          (err: any) => {
            this.toastr.error(err.error.message, err.error.title);
            this.spinner.hide();
          }
        );
      } else {
        this.showInvalidsControlForm();
      }
    }
  }

  onCreateExeption() {
    if (this.checkValid('exception')) {
      if (this.validateFormService.onValidateFields(this.exceptionForm)) {
        this.spinner.show();
        const exception: any = this.exceptionForm.value;

        exception.locationId = this.territoryCrtlException.value;
        exception.dataType = this.dataTypeMultiCtrlException.value
          ? this.dataTypeMultiCtrlException.value
          : '';
        delete exception.territory;

        if (this.countryMultiCtrlException.value) {
          exception.countries = this.countryMultiCtrlException.value.map(
            (country: any) => ({ id: country })
          );
        }

        const controlsToCreateParams = [
          this.orderTypeMultiCtrlException,
          this.plantLocationMultiCtrlException,
          this.storageLocationMultiCtrlException,
          this.customerOrderTypeMultiCtrlException,
          this.orderDateMultiCtrlException,
          this.shippingPointMultiCtrlException,
        ];

        exception.params = this.createParamsFromControls(
          controlsToCreateParams
        );

        exception.geoId = this.geoMultiCtrlException.value;
        delete exception.id;

        this.crudServiceException.createEntity(exception).subscribe(
          () => {
            this.toastr.success(
              `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
              `${this.translatorService.getTitle('CRUD_SUCCESS')} Exception`
            );
            this.modalService.closeAll();
            this.exceptionForm.reset();
            this.loadData('exception');
            this.spinner.hide();
          },
          (err: any) => {
            let responseErrorTitle = err.error.title;
            let responseErrorMessage = err.error.message;

            let newErrorTitle = responseErrorTitle.replace(
              'location',
              'Territory'
            );
            let newErrorMessage = responseErrorMessage.replace(
              'location',
              'territory'
            );

            this.toastr.error(newErrorMessage, newErrorTitle);
            this.spinner.hide();
          }
        );
      } else {
        this.showInvalidsControlFormException();
      }
    }
  }

  onUpdate() {
    this.isEdit = true;
    let updateAllowed = true;
    if (this.checkValid('location')) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        this.spinner.show();
        const location: any = this.locationForm.value;
        if (result) {
          if (updateAllowed) {
            if (this.countryMultiCtrl.value) {
              location.countryId = this.countryMultiCtrl.value.map(
                (country: any) => ({ id: country })
              );
            }

            const controlsToCreateParams = [
              this.orderDateMultiCtrl,
              this.orderTypeMultiCtrl,
              this.plantLocationMultiCtrl,
              this.storageLocationMultiCtrl,
              this.customerOrderTypeMultiCtrl,
              this.shippingPointMultiCtrl,
            ];

            location.params = this.createParamsFromControls(
              controlsToCreateParams
            );

            location.geoId = this.geoMultiCtrl.value;
            if (location.id) {
              this.crudService.updateEntity(location.id, location).subscribe(
                () => {
                  this.toastr.success(
                    `${this.translatorService.getMessage(
                      'CRUD_SUCCESS_UPDATED'
                    )}`,
                    `${this.translatorService.getTitle(
                      'CRUD_SUCCESS_UPDATED'
                    )} Association`
                  );
                  this.modalService.closeAll();
                  this.locationForm.reset();
                  this.resetFormModalCreateEdit();
                  this.loadData('location');
                },
                (err: any) => {
                  this.toastr.error(err.error.message, err.error.title);
                  this.spinner.hide();
                }
              );
            } else {
              delete location.id;
              this.crudService.createEntity(location).subscribe(
                () => {
                  this.toastr.success(
                    `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
                    `${this.translatorService.getTitle(
                      'CRUD_SUCCESS'
                    )} Association`
                  );
                  this.modalService.closeAll();
                  this.locationForm.reset();
                  this.resetFormModalCreateEdit();
                  this.loadData('location');
                },
                (err: any) => {
                  this.toastr.error(err.error.message, err.error.title);
                  this.spinner.hide();
                }
              );
            }
          }
        }
        this.spinner.hide();
      });
    }
  }

  onUpdateException() {
    this.isEditException = true;
    let updateAllowed = true;
    if (this.checkValid('exception')) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);
      dialog.afterClosed().subscribe((result: any) => {
        this.spinner.show();
        const exception: any = this.exceptionForm.value;
        if (result) {
          if (updateAllowed) {
            if (this.countryMultiCtrlException.value) {
              exception.countries = this.countryMultiCtrlException.value.map(
                (country: any) => ({ id: country })
              );
            }

            const controlsToCreateParams = [
              this.orderTypeMultiCtrlException,
              this.plantLocationMultiCtrlException,
              this.storageLocationMultiCtrlException,
              this.customerOrderTypeMultiCtrlException,
              this.orderDateMultiCtrlException,
              this.shippingPointMultiCtrlException,
            ];

            exception.params = this.createParamsFromControls(
              controlsToCreateParams
            );

            exception.geoId = this.geoMultiCtrlException.value;
            exception.locationId = this.territoryCrtlException.value;
            exception.dataType = this.dataTypeMultiCtrlException.value
              ? this.dataTypeMultiCtrlException.value
              : '';

            if (exception.id) {
              this.crudServiceException
                .updateEntity(exception.id, exception)
                .subscribe(
                  () => {
                    this.toastr.success(
                      `${this.translatorService.getMessage(
                        'CRUD_SUCCESS_UPDATED'
                      )}`,
                      `${this.translatorService.getTitle(
                        'CRUD_SUCCESS_UPDATED'
                      )} Association`
                    );
                    this.modalService.closeAll();
                    this.exceptionForm.reset();
                    this.resetFormModalCreateEdit();
                    this.loadData('exception');
                  },
                  (err: any) => {
                    this.toastr.error(err.error.message, err.error.title);
                    this.spinner.hide();
                  }
                );
            } else {
              delete exception.id;
              this.crudServiceException.createEntity(exception).subscribe(
                () => {
                  this.toastr.success(
                    `${this.translatorService.getMessage('CRUD_SUCCESS')}`,
                    `${this.translatorService.getTitle(
                      'CRUD_SUCCESS'
                    )} Association`
                  );
                  this.modalService.closeAll();
                  this.exceptionForm.reset();
                  this.resetFormModalCreateEdit();
                  this.loadData('exception');
                },
                (err: any) => {
                  this.toastr.error(err.error.message, err.error.title);
                  this.spinner.hide();
                }
              );
            }
          }
        }
        this.spinner.hide();
      });
    }
  }

  createParamsFromControl(control: any): any[] {
    if (control.value) {
      return control.value.map((item: any) => ({ id: item }));
    }
    return [];
  }

  createParamsFromControls(controls: any[]): any[] {
    let params: any = [];
    for (const control of controls) {
      params = params.concat(this.createParamsFromControl(control));
    }
    return params;
  }

  showCrudSuccessRemoved(tab?: string): void {
    if (tab === 'location' || tab === '') {
      this.toastr.success(
        `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
        `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Location`
      );

      this.locationForm.reset();
      this.exceptionForm.reset();
      this.loadData('');
    }

    if (tab === 'exception' || tab === '') {
      this.toastr.success(
        `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
        `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} Exception`
      );

      this.exceptionForm.reset();
      this.loadData(tab);
    }

    this.spinner.hide();
  }

  onDeleteConfirm(id: number) {
    this.isEdit = 'delete';
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.deleteService.deleteEntity(id).subscribe(
          () => {
            this.showCrudSuccessRemoved('location');
          },
          (err: any) => {
            this.toastr.error(err.error.title, 'Error!');
            this.spinner.hide();
          }
        );
      }
    });
  }

  onDeleteConfirmException(id: number) {
    this.isEditException = 'delete';
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.deleteServiceException.deleteEntity(id).subscribe(
          () => {
            this.showCrudSuccessRemoved('exception');
          },
          (err: any) => {
            this.toastr.error(err.error.title, 'Error!');
            this.spinner.hide();
          }
        );
      }
    });
  }

  onDelete(id: number) {
    this.isEdit = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.crudService.deleteEntity(id).subscribe(
          () => {
            this.showCrudSuccessRemoved('location');
          },
          (err: any) => {
            this.onDeleteConfirm(id);
            this.toastr.error(err.error.message, err.error.title);
            this.spinner.hide();
          }
        );
      }
    });
  }

  onDeleteException(id: number) {
    this.isEditException = false;
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();
        this.crudServiceException.deleteEntity(id).subscribe(
          () => {
            this.showCrudSuccessRemoved('exception');
          },
          (err: any) => {
            this.onDeleteConfirmException(id);
            this.spinner.hide();
          }
        );
      }
    });
  }

  onSearchEvent(form: any, tab: string) {
    if (tab == 'location') {
      const formSearchValues = { ...form };

      if (formSearchValues.geoId) {
        const currentGeo = this.geosEntity.filter((item: Geo) =>
          item.geo
            .toLocaleUpperCase()
            .includes(formSearchValues.geoId.toLocaleUpperCase())
        );
        formSearchValues.geoId = currentGeo[0] ? currentGeo[0].id : 0;
      } else {
        formSearchValues.geoId = '';
      }

      if (form.territory) {
        const currentTerritory = this.locationFilterEntity.filter((item: any) =>
          item.territory
            .toLocaleUpperCase()
            .includes(formSearchValues.territory)
        );

        formSearchValues.territory = form.territory ? form.territory : 0;
      } else {
        formSearchValues.territory = '';
      }

      if (formSearchValues.country) {
        const currentCountry = this.countries.filter((item: Country) =>
          item.country
            .toLocaleUpperCase()
            .includes(formSearchValues.country.toLocaleUpperCase())
        );
        formSearchValues.country = currentCountry[0] ? currentCountry[0].id : 0;
      } else {
        formSearchValues.country = '';
      }
      this.pagination.page = 0;
      this.filter = { ...formSearchValues };

      this.getLocation(this.pagination, this.filter);
    }

    if (tab == 'exception') {
      this.formSearch = { ...form };

      this.dataFormSearchGeo(this.formSearch);
      this.dataFormSearchTerritory(this.formSearch);
      this.dataFormSearchCountry(this.formSearch);
      this.dataFormSearchIsoCode(this.formSearch);
      this.dataFormSearchDataType(this.formSearch);

      this.pagination.page = 0;
      this.filterException = { ...this.formSearch };

      this.getException(this.pagination, this.filterException);
    }
  }

  dataFormSearchGeo(formSearchValues: any) {
    if (formSearchValues.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formSearchValues.geoId.toLocaleUpperCase())
      );

      formSearchValues.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formSearchValues.geoId = '';
    }
  }

  dataFormSearchTerritory(formSearchValues: any) {
    if (formSearchValues.locationId) {
      let currentTerritory: any[] = [];

      this.locationFilterEntity.forEach((item: any) => {
        formSearchValues.locationId.forEach((data: any) => {
          if (item.territory == data.value) {
            currentTerritory.push(item.id);
          }
        });
      });

      formSearchValues.locationId = currentTerritory ? currentTerritory : '';
    } else {
      formSearchValues.locationId = '';
    }
  }

  dataFormSearchCountry(formSearchValues: any) {
    if (formSearchValues.countryId) {
      let currentCountry: any[] = [];

      this.countries.forEach((item: any) => {
        formSearchValues.countryId.forEach((data: any) => {
          if (item.country.includes(data.value)) {
            currentCountry.push(item.id);
          }
        });
      });

      formSearchValues.countryId = currentCountry ? currentCountry : '';
    } else {
      formSearchValues.countryId = '';
    }
  }

  dataFormSearchIsoCode(formSearchValues: any) {
    if (formSearchValues.isoCode) {
      let currentIsoCode: any[] = [];

      formSearchValues.isoCode.forEach((item: any) => {
        currentIsoCode.push(item.value);
      });

      formSearchValues.isoCode = currentIsoCode ? currentIsoCode : '';
    } else {
      formSearchValues.isoCode = '';
    }
  }

  dataFormSearchDataType(formSearchValues: any) {
    if (formSearchValues.dataType) {
      formSearchValues.dataType = formSearchValues.dataType
        ? formSearchValues.dataType
        : '';
    } else {
      formSearchValues.dataType = '';
    }
  }

  resetFormModalCreateEdit() {
    this.locationForm.reset();
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }
}
