import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent implements OnChanges {
  @Input() public value!: any;
  @Input() public tab!: any;
  @Output() changeDate = new EventEmitter<any>();

  @Input() public disableButton!: boolean;

  public valueFormated: any = this.value;
  public valueFormatedOpenPoCalendar: any = this.value;

  public showClearFilterDate: boolean = true;

  public tabValue: string = '';

  minDate: Date = new Date();
  maxDate: Date = new Date();

  dataDisabled!: boolean;

  constructor() {
    this.minDate.setFullYear(this.minDate.getFullYear() - 5);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 5);
  }

  ngOnInit(): void {
    this.value = new Date(this.value + 'T00:00:00');
    this.onChangeText(
      {
        target: {
          value: this.value,
        },
      },
      false
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tab?.currentValue) {
      this.tabValue = changes.tab?.currentValue;
    }

    if (changes.disableButton?.currentValue) {
      this.dataDisabled = changes.disableButton?.currentValue;
    }

    this.dataDisabled = changes.disableButton?.currentValue;
  }

  onChangeText(e: any, emit: boolean = true) {
    const value = e.target.value;
    this.value = value;
    this.value = new Date(value);

    const newDate = new Date(value);
    const monthNames: any = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day: number = newDate.getDate();
    const monthInt: number = newDate.getMonth();
    const monthName = monthNames[monthInt];
    const year = newDate.getFullYear();

    const monthDate = monthInt > 8 ? monthInt + 1 : `0${monthInt + 1}`;

    this.valueFormated = `${day}/${monthName}`;
    this.valueFormatedOpenPoCalendar = `${day}/${monthDate}/${year}`;

    if (emit) {
      this.changeDate.emit(this.value);
    }
  }
}
