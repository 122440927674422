import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { chartOption } from './line-marker-chart.mockup';
@Component({
  selector: 'app-line-marker-chart',
  templateUrl: './line-marker-chart.component.html',
  styleUrls: ['./line-marker-chart.component.scss'],
})
export class LineMarkerChartComponent implements OnInit {
  @Input() dataSource: any;
  chartOption: any;
  lineChartLegend: string[];
  lineChartDataRetail: string[];
  lineChartDataClaro: string[];
  lineChartDataTim: string[];
  lineChartDataVivo: string[];
  quarterLabel: string[];

  constructor() {
    this.lineChartLegend = [];
    this.lineChartDataRetail = [];
    this.lineChartDataClaro = [];
    this.lineChartDataTim = [];
    this.lineChartDataVivo = [];
    this.quarterLabel = [];
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.quarterLabel = this.getQuarterFromDate(new Date());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource.currentValue) {
      this.getDataSource(changes.dataSource.currentValue);
      this.createChart();
    }
  }

  getDataSource(data: any) {
    this.lineChartLegend = [];
    this.lineChartDataRetail = [];
    this.lineChartDataClaro = [];
    this.lineChartDataTim = [];
    this.lineChartDataVivo = [];

    Object.entries(data).forEach((data: any) => {
      data[1].source.forEach((data: any) => {
        this.lineChartLegend.push(data.product);
        data.dataRetail?.forEach((retail: any) => {
          this.lineChartDataRetail.push(retail);
        });
        data.dataClaro?.forEach((claro: any) => {
          this.lineChartDataClaro.push(claro);
        });
        data.dataTim?.forEach((tim: any) => {
          this.lineChartDataTim.push(tim);
        });
        data.dataVivo?.forEach((vivo: any) => {
          this.lineChartDataVivo.push(vivo);
        });
      });
    });
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return ['April', 'April + May', 'April + May + June'];
    }
    if (month >= 7 && month <= 9) {
      return ['July', 'July + August', 'July + August + September'];
    }
    if (month >= 10 && month <= 12) {
      return ['October', 'October + November', 'October + November + December'];
    }
    return ['January', 'January + February', 'January + February + March'];
  }

  createChart() {
    this.chartOption = {
      grid: {
        top: 30,
        left: 55,
        right: 50,
        bottom: 125,
        height: 190,
      },
      xAxis: {
        type: 'category',
        data: this.quarterLabel,
        axisTick: {
          show: true,
          inside: true,
          alignWithLabel: false,
          length: 400,
          lineStyle: {
            color: '#E3E3E3',
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#e4e4e4',
          },
        },
        axisLabel: {
          margin: 25,
          fontSize: 10,
          color: '#303030',
        },
      },
      yAxis: {
        splitLine: { show: false },
        type: 'value',
        min: 0,
        splitNumber: 4,
        axisLabel: {
          formatter: '{value} %',
          color: '#303030',
          fontSize: 10,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#e4e4e4',
          },
        },
      },
      legend: {
        left: 5,
        bottom: 105,
        icon: 'circle',
        itemWidth: 8.5,
        selectedMode: false,
        data: this.lineChartLegend,
        textStyle: {
          color: '#303030',
          fontSize: 10,
          fontFamily: 'Roboto',
        },
      },
      tooltip: {
        borderColor: '#fff',
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            color: '#fff',
            width: 0.1,
            opacity: 0.0,
          },
        },
        textStyle: {
          fontSize: 12,
        },
      },
      series: [
        {
          type: 'line',
          data: this.lineChartDataClaro,
          name: this.lineChartLegend[1],
          color: '#F99C9C',
        },
        {
          type: 'line',
          data: this.lineChartDataTim,
          name: this.lineChartLegend[2],
          color: '#C9ACFC',
        },
        {
          type: 'line',
          data: this.lineChartDataVivo,
          name: this.lineChartLegend[3],
          color: '#A3E9F3',
        },
        {
          type: 'line',
          data: this.lineChartDataRetail,
          name: this.lineChartLegend[0],
          color: '#A9F39B',
        },
      ],
      media: [
        {
          query: {
            maxWidth: 800,
          },
          option: {
            xAxis: {
              axisLabel: {
                fontSize: 7.55,
              },
            },
          },
        },
      ],
    };
  }
}
