import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { CardInterface } from 'src/app/components/cards/card.interface';
import {
  InputBase,
  SelectSearchInput,
  TextboxInput,
} from 'src/app/components/input/input-base';
import { ResultInterface } from 'src/app/components/results/results.interface';
import { CustomTooltip } from 'src/app/helpers/CustomTooltip';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import { Acessories, Material } from 'src/app/interfaces/material.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { User } from 'src/app/interfaces/user.interface';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { CrudService } from 'src/app/services/generic/crud.service';
import { TranslatorService } from 'src/app/services/generic/translator.service';
import { ValidateFormService } from 'src/app/services/generic/validate.from.service';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import getEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import { ExcelService } from '../../services/generic/excel.service';
import STATUS from 'src/assets/constants/status';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CustomTooltip },
  ],
})
export class MaterialsComponent implements OnInit {
  @ViewChild('modalEdit', { static: true }) modalEdit: TemplateRef<any> | any;
  @ViewChild('modalDevice', { static: true }) modalDevice: TemplateRef<any> | any;
  @ViewChild('dialogConfirmation', { static: true }) dialogConfirmation:
    | TemplateRef<any>
    | any;
  searchInputs: InputBase<string>[];
  result!: ResultInterface;
  exportResult!: ResultInterface;
  materialsForm: FormGroup;
  deviceForm: FormGroup;
  countryEntities: any[] = [];
  allocationBzWithSuffix: any;
  geosEntity: any[] = [];
  statusEntity: any[] = [];
  statusEntityEdit: any = [{ value: 'Active' }, { value: 'Inactive' }];
  isNPIEntity: any[] = [];

  isSuffixed = false;

  tooltipMessage: String = '';
  geo: any;
  country: any;
  isValid: any = true;
  fieldsValid: any[] = [];

  noResultMaterial: boolean = false;

  disabledInput: boolean = true;
  crudService: CrudService<Material>;
  crudServiceAcessories: CrudService<Acessories>;
  pagination: Pagination;
  nameFirstSelectedCountry: String = '';
  filterExport: any = { page: 0, size: 0 };
  filter: any = {
    status: true,
    pageName: PAGE_NAME_RESOURCE.materialManagement,
  };
  event = {
    delete: 'delete',
    edit: 'edit',
  };
  currentUser!: any;
  resultMaterial: any;
  entities: Array<getEntities> = [
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.materialManagement },
    },
    {
      entity: 'country',
      query: {
        sort: 'country',
        pageName: PAGE_NAME_RESOURCE.materialManagement,
      },
    },
    {
      entity: 'material',
      query: { status: true, pageName: PAGE_NAME_RESOURCE.materialManagement },
    },
  ];
  currentCountries: any[] = [];
  internalNameFilter: any[] = [];
  originFilter: any[] = [];
  marketNameFilter: any[] = [];
  eanFilter: any[] = [];
  colorDescFilter: any[] = [];
  productGroupFilter: any[] = [];

  initialValueGeoFilter: { [key: string]: any } = {};
  initialValueCountryFilter: { [key: string]: any } = {};

  originNewDevice: Array<any>;

  constructor(
    private modalService: ModalActionsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private validateFormService: ValidateFormService,
    private translatorService: TranslatorService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private excelService: ExcelService
  ) {
    this.pagination = { page: 0, size: 10 };
    this.crudService = new CrudService<Material>(this.http, 'material');
    this.crudServiceAcessories = new CrudService<Acessories>(this.http, 'material');
    this.searchInputs = [
      new SelectSearchInput({
        key: 'geoId',
        hover: 'GEO of Material',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'countryId',
        hover: 'Country of Material',
        type: 'text',
        hasAutocomplete: true,
        disabled: true,
        notClearable: true,
      }),
      new SelectSearchInput({
        key: 'internalName',
        hover: 'Internal Name',
        type: 'text',
        hasAutocomplete: true,
      }),
      new TextboxInput({
        key: 'salesModel',
        hover: 'Sales model',
        subscription: true,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        classCss: 'origin-material',
      }),
      new SelectSearchInput({
        key: 'status',
        hover: 'Status',
        // classCss: 'materials-status',
      }),
      new SelectSearchInput({
        key: 'upcCode',
        hover: 'EAN',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'colorDesc',
        hover: 'Color Desc',
        type: 'text',
        hasAutocomplete: true,
      }),
      new SelectSearchInput({
        key: 'marketName',
        hover: 'Market Name',
        type: 'text',
        hasAutocomplete: true,
      }),
      new TextboxInput({
        key: 'allocationBz',
        hover: 'Allocation BZ',
        subscription: true,
      }),
      new SelectSearchInput({
        key: 'productGroup',
        hover: 'Product Group',
        type: 'text',
        hasAutocomplete: true,
      }),
    ];

    [this.currentUser] = this.authService.getUserPermissions();
    this.filter = {
      ...this.filter,
      geoId: this.currentUser.geoId,
      countryId: this.currentUser.countryId,
    };

    this.materialsForm = this.formBuilder.group({
      id: [''],
      countries: { value: null, disabled: true },
      salesModel: { value: null, disabled: true },
      status: { value: null },
      marketName: { value: null, disabled: true },
      localMarketName: [''],
      productGroup: [''],
      internalName: { value: null, disabled: true },
      allocationBz: { value: null, disabled: true },
      productLongDesc: { value: null, disabled: true },
      ram: { value: null, disabled: true },
      rom: { value: null, disabled: true },
      segment: { value: null, disabled: true },
      colorDesc: { value: null, disabled: true },
      localColorDesc: [''],
      upcCode: { value: null, disabled: true },
      origin: { value: null, disabled: true },
      isNpi: ['', Validators.required],
      suffix: [''],
    });

    this.deviceForm = this.formBuilder.group({
      salesModel: new FormControl('', Validators.required),
      origin: new FormControl('', Validators.required),
    })

    this.originNewDevice = [
      {value: 'MAN'},
      {value: 'JAG'}
    ]
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getMaterials(this.pagination || undefined, this.filter);
    this.getDataFilter();
  }

  getMaterials(page?: any, params?: any) {
    this.spinner.show();
    this.filter = {
      status: true,
      ...this.filter,
    };
    this.crudService
      .getPaged(page, params || this.filter)
      .subscribe((response: any) => {
        if (response.rows.length == 0) {
          this.noResultMaterial = true;
          this.isValid = false;
          this.spinner.hide();
        } else {
          this.filterExport.size = response.totalRows;
          this.noResultMaterial = false;
          this.isValid = true;
          this.getExportMaterials(this.filterExport, this.filter);
          this.updateComponents(response);
          this.spinner.hide();
        }
      });
  }

  getExportMaterials(params?: any, filter?: any) {
    this.spinner.show();
    this.crudService.getPaged(params, filter).subscribe((response: any) => {
      this.exportComponents(response);
      this.spinner.hide();
    });
  }

  dataFilterGeo(response: any) {
    const geoFilter: any[] = [];

    this.initialValueGeoFilter = {};
    response[0].forEach((item: any) => {
      this.geosEntity[item.geo] = item.id;
      geoFilter.push({ value: item.geo, id: item.id });
      if (item.id === this.currentUser.geoId) {
        this.initialValueGeoFilter.value = item.geo;
        this.initialValueGeoFilter.id = item.id;
      }
    });

    const selectGeo = new SelectSearchInput({
      key: 'geoId',
      hover: 'GEO of Material',
      type: 'text',
      hasAutocomplete: true,
      options: geoFilter,
      value: this.initialValueGeoFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.searchInputs[0] = selectGeo;
  }

  dataFilterCountry() {
    const countryFilter: any[] = [];

    this.initialValueCountryFilter = {};
    this.countryEntities.forEach((item: Country) => {
      countryFilter.push({
        value: item.country + ' - ' + item.iso_code,
        id: item.id,
      });
      if (item.id === this.currentUser.countryId) {
        this.initialValueCountryFilter.value = item.country;
        this.initialValueCountryFilter.id = item.id;
      }
    });

    const selectCountry = new SelectSearchInput({
      key: 'countryId',
      hover: 'Country of Material',
      type: 'text',
      hasAutocomplete: true,
      options: countryFilter,
      value: this.initialValueCountryFilter.value,
      disabled: true,
      notClearable: true,
    });

    this.searchInputs[1] = selectCountry;
  }

  dataFilterInternalName(item: any) {
    if (
      item.internalName &&
      this.internalNameFilter.findIndex(
        obj => obj.value.toUpperCase() === item.internalName?.toUpperCase()
      ) === -1
    ) {
      this.internalNameFilter.push({
        value: item.internalName,
        id: item.internalName,
      });
    }

    this.internalNameFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );

    this.searchInputs[2].options = this.internalNameFilter;
  }

  dataFilterOrigin(item: any) {
    if (
      item.origin &&
      this.originFilter.findIndex(
        obj => obj.value.toUpperCase() === item.origin?.toUpperCase()
      ) === -1
    ) {
      this.originFilter.push({ value: item.origin, id: item.origin });
    }

    this.originFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );
    this.searchInputs[4].options = this.originFilter;
  }

  dataFilterStatus() {
    this.statusEntity = [
      { value: 'Active' },
      { value: 'Inactive' },
      { value: 'All' },
    ];

    const selectStatus = new SelectSearchInput({
      key: 'status',
      hover: 'Status',
      classCss: 'status-material',
      options: this.statusEntity,
      value: this.statusEntity[0].value,
    });

    this.searchInputs[5] = selectStatus;
  }

  dataFilterMarketName(item: any) {
    if (
      item.marketName &&
      this.marketNameFilter.findIndex(
        obj => obj.value.toUpperCase() === item.marketName?.toUpperCase()
      ) === -1
    ) {
      this.marketNameFilter.push({
        value: item.marketName,
        id: item.marketName,
      });
    }

    this.marketNameFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );
    this.searchInputs[8].options = this.marketNameFilter;
  }

  dataFilterEanFilter(item: any) {
    if (
      item.upcCode &&
      this.eanFilter.findIndex(
        obj => obj.value.toUpperCase() === item.upcCode?.toUpperCase()
      ) === -1
    ) {
      this.eanFilter.push({ value: item.upcCode, id: item.upcCode });
    }

    this.eanFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );
    this.searchInputs[6].options = this.eanFilter;
  }

  dataFilterColorDescFilter(item: any) {
    if (
      item.colorDesc &&
      this.colorDescFilter.findIndex(
        obj => obj.value.toUpperCase() === item.colorDesc?.toUpperCase()
      ) === -1
    ) {
      this.colorDescFilter.push({ value: item.colorDesc, id: item.colorDesc });
    }

    this.colorDescFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );
    this.searchInputs[7].options = this.colorDescFilter;
  }

  dataFilterProductGroupFilter(item: any) {
    if (
      item.productGroup &&
      this.productGroupFilter.findIndex(
        obj => obj.value.toUpperCase() === item.productGroup?.toUpperCase()
      ) === -1
    ) {
      this.productGroupFilter.push({
        value: item.productGroup,
        id: item.productGroup,
      });
    }

    this.productGroupFilter.sort((current: any, next: any) =>
      current.value.localeCompare(next.value)
    );

    this.searchInputs[10].options = this.productGroupFilter;
  }

  getDataFilter() {
    let materialsEntities;
    this.internalNameFilter = [];
    this.originFilter = [];
    this.marketNameFilter = [];
    this.eanFilter = [];
    this.colorDescFilter = [];
    this.productGroupFilter = [];

    this.spinner.show();

    this.crudService
      .getDataFilters(this.entities)
      .subscribe((response: any) => {
        [this.geosEntity, this.countryEntities, materialsEntities] = response;

        this.dataFilterGeo(response);
        this.dataFilterCountry();

        materialsEntities.forEach((item: Material) => {
          this.dataFilterInternalName(item);
          this.dataFilterOrigin(item);
          this.dataFilterMarketName(item);
          this.dataFilterEanFilter(item);
          this.dataFilterColorDescFilter(item);
          this.dataFilterProductGroupFilter(item);
        });

        this.dataFilterStatus();

        this.isNPIEntity = [{ value: 'Yes' }, { value: 'No' }];

        this.searchInputs = [...this.searchInputs];
      });
  }

  updateComponents(materials: any) {
    this.result = {
      cards: this.dataCards(materials.rows),
      table: {
        columns: [
          {
            label: 'geo of materials',
            prop: 'geo',
            sticky: true,
            style: { 'word-wrap': 'break-word', width: '6.6%' },
          },
          {
            label: 'country of materials',
            prop: 'country',
            sticky: true,
            style: {
              'word-wrap': 'break-word',
              width: '6.6%',
              padding: '0 10px',
            },
          },
          {
            label: 'sales model',
            prop: 'salesModel',
            sticky: true,
            style: {
              'word-wrap': 'break-word',
              width: '6.6%',
              padding: '0 10px',
            },
          },
          {
            label: 'origin',
            prop: 'origin',
            sticky: true,
            style: {
              'word-wrap': 'break-word',
              width: '6.6%',
              padding: '0 10px',
            },
          },
          {
            label: 'product group',
            prop: 'productGroup',
            sticky: true,
            style: { width: '6.6%', padding: '8px' },
          },
          {
            label: 'internal name',
            prop: 'internalName',
            sticky: true,
            style: { width: '6.6%', padding: '0 10px' },
          },
          {
            label: 'market name',
            prop: 'marketName',
            sticky: true,
            style: { width: '6.6%', padding: '0 10px' },
          },
          {
            label: 'local market name',
            prop: 'localMarketName',
            sticky: true,
            style: { width: '6.6%', padding: '0 10px' },
          },
          {
            label: 'color desc',
            prop: 'colorDesc',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'local color',
            prop: 'localColorDesc',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'suffix',
            prop: 'suffix',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'type',
            prop: 'deviceType',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'status',
            prop: 'status',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'segment',
            prop: 'segment',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'ean',
            prop: 'upcCode',
            sticky: true,
            style: { width: '6.6%', padding: '10px' },
          },
          {
            label: 'allocation bz',
            prop: 'allocationBz',
            sticky: true,
            style: { width: '6.6%', padding: '8px' },
          },
        ],
        rows: new MatTableDataSource<User>(this.dataTables(materials.rows)),
        hasStatus: true,
      },
      pageIndex: materials.page,
      pageSize: materials.totalRows,
      pagesTotal: materials.totalPages,
    };
    const actions = this.permissionService.setTablePermissions(
      'material-management'
    );
    delete actions?.delete;
    if (actions.label === 'actions') {
      this.result.table?.columns?.push(actions);
    }
  }

  dataTables(data: any) {
    const dataTables: any[] = [];

    data.forEach((item: any) => {
      item.geo =
        item.countries.length > 0
          ? item.countries
              .map((country: any) => country.geos.geo)
              .filter(
                (geo: string, index: number, unique: string[]) =>
                  index === unique.indexOf(geo)
              )
              .toString()
              .replaceAll(',', ', ')
          : '';
      item.country =
        item.countries.length > 0
          ? item.countries
              .map((country: any) => country.country)
              .toString()
              .replaceAll(',', ', ')
          : '';
      item.editNotActive = false;
      item.deleteNotActive = false;
      item.active = true;
      item.status = item.status ? 'Active' : 'Inactive';
      item.suffix = item.allocationBz.split('_')[1] ? item.allocationBz.split('_')[1] : '';
      dataTables.push(item);
    });
    return [...dataTables];
  }

  dataCards(data: any) {
    const materials: Material[] = data;
    const dataCards: CardInterface[] = [];
    const actionCards = this.permissionService.setActionsCardsPermission(
      'material-management'
    );
    materials.forEach((item: any) => {
      dataCards.push({
        id: item.id,
        title: item.marketName,
        subtitle: item.productLongDesc,
        iconEdit: { visible: actionCards.iconEdit, enabled: true },
        iconDelete: { visible: actionCards.iconDelete, enabled: false },
        attributes: [
          {
            key: 'Geo of Material',
            value: item.countries.map((country: any) => country.geos.geo),
            isTag: true,
            keyExpanded: true,
          },
          {
            key: 'Country of Material',
            value: item.countries.map((country: any) => country.country),
            isTag: true,
            keyExpanded: true,
          },
          {
            key: 'Sales Model',
            value: [item.salesModel],
            isTag: true,
            keyExpanded: true,
          },
          {
            key: 'Internal name',
            value: item.internalName ? [item.internalName] : '',
            isTag: true,
            keyExpanded: true,
          },
          {
            key: 'Segment',
            value: item.segment ? [item.segment] : '',
            isTag: true,
            keyExpanded: true,
          },
          {
            key: 'Color Desc',
            value: item.colorDesc ? [item.colorDesc] : '',
            isTag: true,
            keyExpanded: true,
          },
        ],
      });
    });
    return dataCards;
  }

  onChangePaginator(paginated: any) {
    this.pagination = paginated;
    this.getMaterials(paginated, this.filter);
  }

  onEventActions(event: any) {
    if (event.type === this.event.edit) {
      this.prepareToEdit(event.item.id);
    }
  }

  setValueId(material: any) {
    this.materialsForm.controls.id.setValue(material.id);
  }

  setValueCountry(material: any) {
    this.materialsForm.controls.countries.setValue(
      material.countries?.map((country: Country) => country.country)
    );
  }

  setValueSalesModel(material: any) {
    this.materialsForm.controls.salesModel.setValue(material.salesModel);
  }

  setValueStatus(material: any) {
    this.materialsForm.controls.status.setValue(
      material.status ? 'Active' : 'Inactive'
    );
  }

  setValueLocalMarketName(material: any) {
    this.materialsForm.controls.localMarketName.setValue(
      material.localMarketName
    );
  }

  setValueMarketName(material: any) {
    this.materialsForm.controls.marketName.setValue(material.marketName);
  }

  setValueInternalName(material: any) {
    const internalName = material.internalName;
    const index = internalName.indexOf('_');
    const newValue =
      index !== -1 ? internalName.substring(0, index) : internalName;
    this.materialsForm.controls.internalName.setValue(newValue);
  }

  setValueProductGroup(material: any) {
    const productGroup = material.productGroup;
    const index = productGroup.indexOf('_');
    const newValue =
      index !== -1 ? productGroup.substring(0, index) : productGroup;
    this.materialsForm.controls.productGroup.setValue(newValue);
  }
    
  setValueProductLongDesc(material: any) {
    this.materialsForm.controls.productLongDesc.setValue(
      material.productLongDesc
    );
  }

  setValueRam(material: any) {
    this.materialsForm.controls.ram.setValue(material.ram);
  }

  setValueRom(material: any) {
    this.materialsForm.controls.rom.setValue(material.rom);
  }

  setValueSegment(material: any) {
    this.materialsForm.controls.segment.setValue(material.segment);
  }

  setValueColorDesc(material: any) {
    this.materialsForm.controls.colorDesc.setValue(material.colorDesc);
  }

  setValueLocalColorDesc(material: any) {
    this.materialsForm.controls.localColorDesc.setValue(
      material.localColorDesc
    );
  }

  setValueUpcCode(material: any) {
    this.materialsForm.controls.upcCode.setValue(material.upcCode);
  }

  setValueOrigin(material: any) {
    this.materialsForm.controls.origin.setValue(material.origin);
  }

  setValueIsNPi(material: any) {
    this.materialsForm.controls.isNpi.setValue(material.isNpi ? 'Yes' : 'No');
  }

  setValueSuffix(material: any) {
    const allocationBzParts = material.allocationBz.split('_');
    const suffix = allocationBzParts[1];
    this.materialsForm.controls.suffix.setValue(suffix);
  }

  setValueAllozationBz(material: any) {
    const allocationBz = material.allocationBz;
    const index = allocationBz.indexOf('_');
    const newValue =
      index !== -1 ? allocationBz.substring(0, index) : allocationBz;
    this.materialsForm.controls.allocationBz.setValue(newValue);
  }

  prepareToEdit(id: number) {
    this.currentCountries = [];
    this.spinner.show();

    this.crudService.fetchEntity(id).subscribe((material: Material) => {
      this.setValueId(material);
      this.setValueCountry(material);
      this.setValueSalesModel(material);
      this.setValueStatus(material);
      this.setValueMarketName(material);
      this.setValueLocalMarketName(material);
      this.setValueInternalName(material);
      this.setValueProductGroup(material);
      this.setValueAllozationBz(material);
      this.setValueProductLongDesc(material);
      this.setValueRam(material);
      this.setValueRom(material);
      this.setValueSegment(material);
      this.setValueColorDesc(material);
      this.setValueLocalColorDesc(material);
      this.setValueUpcCode(material);
      this.setValueOrigin(material);
      this.setValueIsNPi(material);
      this.setValueSuffix(material);

      if (material.countries!.length > 0) {
        material.countries!.sort((current: any, next: any) =>
          current.country.localeCompare(next.country)
        );
        const firstSelectedCountry = material.countries![0].country;

        this.nameFirstSelectedCountry = `${firstSelectedCountry}`;
        material.countries?.map((row: any) => {
          this.currentCountries.push({ id: row.id });
        });
      }

      const modal = this.modalService.createModal(this.modalEdit);
      modal.afterClosed().subscribe(() => {
        this.materialsForm.reset();
      });
      this.spinner.hide();
    });
  }

  messageToastUpdated() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_UPDATED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_UPDATED')} Material`
    );
  }

  messageToastSuccessDelete() {
    this.toastr.success(
      `${this.translatorService.getMessage('CRUD_SUCCESS_REMOVED')}`,
      `${this.translatorService.getTitle('CRUD_SUCCESS_REMOVED')} User`
    );
  }

  messageToastErrorSalesManager(err: any) {
    this.toastr.error(
      err.error.message,
      err.error.title.replace(/_/g, ' ').replace('SALES MANAGER', '')
    );
  }

  messageToastErrorOrderAdmin(err: any) {
    this.toastr.error(
      err.error.message,
      err.error.title.replace(/_/g, ' ').replace('ORDER ADMIN', '')
    );
  }

  countriesValue() {
    return this.materialsForm.value.countries
      ? this.materialsForm?.value?.countries.map((idCountry: number) => ({
          id: idCountry,
        }))
      : (this.materialsForm.value.countries = this.currentCountries);
  }

  statusValue() {
    return this.materialsForm.value.status === 'Active';
  }

  updateAllocationBzWithSuffix(): void {
    let allocationBz = this.materialsForm.controls.allocationBz.value;
    let productGroup = this.materialsForm.controls.productGroup.value;
    let internalName = this.materialsForm.controls.internalName.value;
    const suffix = this.materialsForm.controls.suffix.value;

    if (suffix) {
      allocationBz = allocationBz.replace(/(_[^_]+)?$/, '_' + suffix);
      productGroup = productGroup.replace(/(_[^_]+)?$/, '_' + suffix);
      internalName = internalName.replace(/(_[^_]+)?$/, '_' + suffix);
    } else {
      allocationBz = allocationBz.replace(/_[^_]+$/, '');
      productGroup = productGroup.replace(/_[^_]+$/, '');
      internalName = internalName.replace(/_[^_]+$/, '');
    }

    this.materialsForm.controls.suffix.setValue(suffix);
    this.materialsForm.value.allocationBz = allocationBz;
    this.materialsForm.value.productGroup = productGroup;
    this.materialsForm.value.internalName = internalName;
  }

  onUpdate() {
    const Countries: any = [];
    if (this.currentCountries?.length > 0) {
      Countries.push(this.currentCountries);
    }

    if (
      this.validateFormService.onValidateFields(this.materialsForm) &&
      this.materialsForm.valid
    ) {
      const dialog = this.modalService.createConfirm(this.dialogConfirmation);

      dialog.afterClosed().subscribe((result: any) => {
        if (result) {
          this.spinner.show();
          this.updateAllocationBzWithSuffix();

          this.materialsForm.value.countries = this.countriesValue();
          this.materialsForm.value.status = this.statusValue();

          this.crudService
            .updateEntity(
              this.materialsForm.controls.id.value,
              this.materialsForm.value
            )
            .subscribe(
              () => {
                this.messageToastUpdated();
                this.loadData();
                this.modalService.closeAll();
              },
              (err: any) => {
                this.toastr.error(err.error.message, err.error.title);
                this.spinner.hide();
              }
            );
        }
      });
    }
  }

  onDelete(id: number) {
    const dialog = this.modalService.createConfirm(this.dialogConfirmation);

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.spinner.show();

        this.crudService.deleteEntity(id).subscribe(
          () => {
            this.messageToastSuccessDelete();
            this.loadData();
          },
          (err: any) => {
            this.spinner.hide();
            this.crudService.fetchEntity(id).subscribe((user: any) => {
              user.sales_manager
                ? this.messageToastErrorSalesManager(err)
                : this.messageToastErrorOrderAdmin(err);
            });
          }
        );
      }
    });
  }

  dataFormValueGeo(formValue: any) {
    if (formValue.geoId) {
      const currentGeo = this.geosEntity.filter((item: Geo) =>
        item.geo
          .toLocaleUpperCase()
          .includes(formValue.geoId.toLocaleUpperCase())
      );
      formValue.geoId = currentGeo[0] ? currentGeo[0].id : 0;
    } else {
      formValue.geoId = this.initialValueGeoFilter.id;
    }
  }

  dataFormValueCountry(formValue: any) {
    if (formValue.countryId) {
      const splitCountry = formValue.countryId.replace(/ - .*/, '');
      const currentCountry = this.countryEntities.filter((item: Country) =>
        item.country
          .toLocaleUpperCase()
          .includes(splitCountry.toLocaleUpperCase())
      );
      formValue.countryId = currentCountry[0] ? currentCountry[0].id : 0;
    } else {
      formValue.countryId = this.initialValueCountryFilter.id;
    }
  }

  dataFormvalueInternalName(formValue: any) {
    formValue.internalName = formValue.internalName
      ? formValue.internalName
      : '';
  }

  dataFormValueMarketName(formValue: any) {
    formValue.marketName = formValue.marketName ? formValue.marketName : '';
  }

  dataFormValueOrigin(formValue: any) {
    formValue.origin = formValue.origin ? formValue.origin : '';
  }

  dataFormValueStatus(formValue: any) {
    if (formValue.status === 'Active') {
      formValue.status = true;
    } else if (formValue.status === 'Inactive') {
      formValue.status = false;
    } else {
      formValue.status = '';
    }
  }

  dataFormValueUpcCode(formValue: any) {
    formValue.upcCode = formValue.upcCode ? formValue.upcCode : '';
  }

  dataFormValueColorDesc(formValue: any) {
    formValue.colorDesc = formValue.colorDesc ? formValue.colorDesc : '';
  }

  dataFormValueSalesModel(formValue: any) {
    formValue.salesModel = formValue.salesModel ? formValue.salesModel : '';
  }

  dataFormValueAllocationBz(formValue: any) {
    formValue.allocationBz = formValue.allocationBz
      ? formValue.allocationBz
      : '';
  }

  dataFormValueProductGroup(formValue: any) {
    formValue.productGroup = formValue.productGroup
      ? formValue.productGroup
      : '';
  }

  onSearchEvent(value: any) {
    const formValue = { ...value };

    this.dataFormValueGeo(formValue);
    this.dataFormValueCountry(formValue);
    this.dataFormvalueInternalName(formValue);
    this.dataFormValueMarketName(formValue);
    this.dataFormValueOrigin(formValue);
    this.dataFormValueStatus(formValue);
    this.dataFormValueUpcCode(formValue);
    this.dataFormValueColorDesc(formValue);
    this.dataFormValueSalesModel(formValue);
    this.dataFormValueAllocationBz(formValue);
    this.dataFormValueProductGroup(formValue);

    this.pagination.page = 0;
    this.filter = {
      ...formValue,
      pageName: PAGE_NAME_RESOURCE.materialManagement,
    };
    this.getMaterials(this.pagination, this.filter);

    this.fieldsValid = [
      (this.geo = this.filter.geoId),
      (this.country = this.filter.countryId),
    ];
  }

  exportComponents(dataMaterials: any) {
    this.exportResult = {
      table: {
        columns: [
          { label: 'geo of materials', prop: 'geo' },
          { label: 'country of materials', prop: 'country' },
          { label: 'sales model', prop: 'salesModel' },
          { label: 'origin', prop: 'origin' },
          { label: 'product group', prop: 'productGroup' },
          { label: 'internal name', prop: 'internalName' },
          { label: 'market name', prop: 'marketName' },
          { label: 'local market name', prop: 'localMarketName' },
          { label: 'color desc', prop: 'colorDesc' },
          { label: 'local color', prop: 'localColorDesc' },
          { label: 'suffix', prop: 'suffix' },
          { label: 'type', prop: 'deviceType' },
          { label: 'status', prop: 'status' },
          { label: 'segment', prop: 'segment' },
          { label: 'ean', prop: 'upcCode' },
          { label: 'allocation bz', prop: 'allocationBz' },
        ],
        rows: this.dataTables(dataMaterials.rows)
      },
    };
    this.resultMaterial = this.exportResult;
  }

  prepareExportTable(data: any) {
    const rows = data.table.rows;
    const geo = rows[0].countries[0].geos.geo;
    const country = rows[0].countries[0].country;

    rows.map((item: any) => {
      const status = item.status;

      const originMaterial = { geo: geo, country: country, status: status };
      Object.assign(item, originMaterial);
    });

    const columns = data.table.columns.map((item: any) => ({
      title: item.label,
      props: item.prop,
    }));

    const tableRows: { [key: string]: any }[] = [];

    rows.forEach((rows: string) => {
      const tableRow: { [key: string]: any } = {};

      columns.forEach((column: any) => {
        rows[column.props] !== null
          ? (tableRow[column.props] = rows[column.props])
          : (tableRow[column.props] = rows[column.props]);
      });
      tableRows.push(tableRow);
    });

    return { columns: columns, rows: tableRows };
  }

  exportTable() {
    const fullJsonExport = this.prepareExportTable(this.resultMaterial);
    this.excelService.exportJsonAsExcelFile(fullJsonExport, 'material');
  }

  newDevice() {
    const modal = this.modalService.createModal(this.modalDevice);

    modal.afterClosed().subscribe(() => {
      this.deviceForm.reset();
    });
  }

  onCreateDevice() {
    if (this.deviceForm.valid) {
      const params = {
        salesModel: this.deviceForm.value.salesModel.toUpperCase(),
        origin: this.deviceForm.value.origin.toUpperCase(),
      }

      this.spinner.show()

      this.crudServiceAcessories.postEntity(`material-allowed`, params).subscribe((response: any) => {
        if (response.level == 'sucess') {
          this.toastr.success('Registered successfully!', 'New device created');
          this.modalService.closeAll();
          this.loadData();
          this.spinner.hide()
        }
      },
      err => {
        this.toastr.error(err.error.message, err.error.title);
        this.spinner.hide()
      })

    } else {
      this.checkValid()
     }
  }

  checkValid() {
    if (this.deviceForm.controls.salesModel.status === 'INVALID') {

      if (this.deviceForm.controls.salesModel.value == '') {        
        this.toastr.warning('Mandatory field!', 'Sales Model');
      } else {
        if (this.deviceForm.controls.salesModel.value == null) {
          this.toastr.warning('Mandatory field!', 'Sales Model');
        } else {
          this.toastr.warning('Just numbers and letters!', 'Sales Model');
        }
      }
    }
    if (this.deviceForm.controls.origin.status === 'INVALID') {
      this.toastr.warning('Mandatory field!', 'Origin');
    }
  }

  backButton() {
    this.router.navigate(['home/setup']);
  }

  onSelectCountry(materialsForm: FormGroup) {
    const countries = this.countryEntities.filter(item =>
      materialsForm.value.countries.includes(item.id)
    );
    this.nameFirstSelectedCountry =
      countries.length > 0 ? `${countries[0].country}` : '';
  }
}
