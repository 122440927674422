import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  InputBase,
  MultiSelectSearchInput,
  SelectSearchInput,
} from 'src/app/components/input/input-base';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/services/generic/crud.service';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { KaInfo } from 'src/app/interfaces/ka-info.interface';
import { TableColumn } from 'src/app/interfaces/ka-info.interface';
import { TableRow } from 'src/app/interfaces/ka-info.interface';
import { Country } from 'src/app/interfaces/country.interface';
import { Geo } from 'src/app/interfaces/geo.interface';
import STATUS from 'src/assets/constants/status';
import PAGE_NAME_RESOURCE from 'src/assets/constants/pageNamesResources';
import GetEntities from 'src/app/interfaces/genericInterfaces/getEntities';
import { ToastrService } from 'ngx-toastr';
import { KAInfoResponse } from './ka-info-mockup';
import { MatTableDataSource } from '@angular/material/table';
import { TableSubtotalComponent } from 'src/app/components/table-subtotal/table-subtotal.component';
import { ExcelSheetsService } from 'src/app/services/generic/excelSheets.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-ka-info',
  templateUrl: './ka-info.component.html',
  styleUrls: ['./ka-info.component.scss'],
})
export class KaInfoComponent implements OnInit {
  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: TemplateRef<any> | any;
  @ViewChild(TableSubtotalComponent)
  tableSubtotalComponent!: TableSubtotalComponent;

  dataTable: any = [];
  tableHeader: string[] = [];
  tableRows: string[] = [];
  eventTable!: string;
  showTabTable: boolean = true;
  toggleColumnTableValues: any[] = [];
  enableApplyFilter = true;

  isSave: boolean = false;

  KaInfoResponseTable = KAInfoResponse;
  searchInputs!: InputBase<string>[];
  formSearchValues!: any;
  subtotalValues: any = {};
  showNoResults = false;
  showTable = false;

  currentUser!: any;
  currentQuarter!: string;
  currentYear!: number;

  mlsLastUpdate: any;
  tooltipMessage: any;

  kaFilter: any[] = [];
  familyFilter: any[] = [];
  originFilter: any[] = [];
  filter: any = {};
  countries: Country[] = [];
  geos: Geo[] = [];

  summedValue: number[] = [];

  crudService: CrudService<any>;
  putCrudService: any;
  kaInfoCrudService: CrudService<KaInfo>;
  isEditTableMode: boolean = false;
  hasPermissionCreate!: boolean;

  buttonFixed: boolean = false;

  productFamilyEntity: string[] = [];
  quarterEntities = [
    { key: '1', value: 'FQ1' },
    { key: '2', value: 'FQ2' },
    { key: '3', value: 'FQ3' },
    { key: '4', value: 'FQ4' },
  ];
  entities: Array<GetEntities> = [
    {
      entity: 'kaManagement',
      query: { sort: 'ka', pageName: PAGE_NAME_RESOURCE.kaInfo },
    },
    {
      entity: 'material-family',
      query: { pageName: PAGE_NAME_RESOURCE.kaInfo },
    },
    {
      entity: 'country',
      query: { sort: 'country', pageName: PAGE_NAME_RESOURCE.kaInfo },
    },
    {
      entity: 'geo',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.kaInfo },
    },
    {
      entity: 'kaAllocation',
      query: { sort: 'geo', pageName: PAGE_NAME_RESOURCE.kaInfo },
    },
  ];

  statusEntity = [
    { key: 'active', value: STATUS.active },
    { key: 'inactive', value: STATUS.inactive },
    { key: 'ALL', value: 'All' },
  ];

  tabKASelected: number = 0;
  tabTable: 'demand' | 'plan' = 'demand';
  tabKA: any = {
    CLARO_BRA: 0,
    TIM_BRA: 1,
    VIVO_BRA: 2,
    RETAIL_BRA: 3,
  };

  keysFilters = ['fiscalYear', 'quarter'];

  editButtons: boolean = false;
  messageModalChanges: string = 'Teste';
  messages: { [key: string]: string } = {
    save: 'Do you confirm data updated?',
    discard: 'Are you sure you want to exit before saving your changes?',
  };
  tooltipMessageUpload: any = 'Upload';
  respondeKaInfoBrazil: any;

  isDiscardChanges: boolean = false;
  isValid: boolean = false;
  disableTableInput: boolean = false;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private modalService: ModalActionsService,
    private excelSheetsService: ExcelSheetsService,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.crudService = new CrudService<any>(this.http, 'ka-info');
    this.kaInfoCrudService = new CrudService<KaInfo>(this.http, 'kaInfo');
    this.putCrudService = new CrudService<any>(
      this.http,
      'ka-Info-brazil/publish'
    );
    [this.currentUser] = this.authService.getUserPermissions();
    this.currentQuarter = this.getQuarterFromDate(new Date());
    this.currentYear =
      this.currentQuarter !== 'Q4'
        ? new Date().getFullYear()
        : new Date().getFullYear() - 1;
    this.filter = {
      ...this.filter,
      fiscalYear: this.currentYear,
      quarter: `F${this.currentQuarter}`,
    };
    this.searchInputs = this.createSearchInputs();
    this.sharedInfoService.getPermissionStorage().subscribe(() => {
      this.hasPermissionCreate =
        this.permissionService.setButtonCreatePermission('user');
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    const promises = [
      this.getDataFilter(this.entities),
      this.getDataTable(this.filter),
    ];
    this.hideSpinnerAfterAll(promises);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.spinner.hide();
    });
  }

  createSearchInputs(): any[] {
    return [
      new MultiSelectSearchInput({
        key: 'kaId',
        hover: 'KA',
        hasCheckFlowException: true,
      }),
      new MultiSelectSearchInput({
        key: 'ppm',
        hover: 'Family',
        type: 'text',
        hasAutocomplete: true,
        hasCheckFlowException: true,
      }),
      new SelectSearchInput({
        key: 'fiscalYear',
        hover: 'Fiscal Year',
        warn: false,
      }),
      new SelectSearchInput({
        key: 'quarter',
        hover: 'Quarter',
        warn: false,
      }),
      new SelectSearchInput({
        key: 'origin',
        hover: 'Origin',
        hasCheckFlowException: true,
      }),
    ];
  }

  private dataFilterKa(response: any) {
    Object.entries(response).forEach((item: any) => {
      if (item[1].ka !== 'RETAIL_BRA') {
        this.kaFilter.push({ value: item[1].ka, key: item[1].id });
      }
    });

    const selectKa = new MultiSelectSearchInput({
      key: 'kaId',
      hover: 'KA',
      hasCheckFlowException: true,
      classCss: 'multi-select-ka-filter-ka-info',
      options: this.kaFilter,
    });

    this.searchInputs[0] = selectKa;
    this.searchInputs = [...this.searchInputs];
  }

  private dataFilterFamily(response: any) {
    Object.entries(response).forEach((item: any, index = 0) => {
      this.familyFilter.push({ value: item[1].family, id: index });
    });

    const selectFamily = new MultiSelectSearchInput({
      key: 'ppm',
      hover: 'Family',
      type: 'text',
      hasAutocomplete: true,
      hasCheckFlowException: true,
      classCss: 'multi-select-filter-ka-info',
      options: this.familyFilter,
    });

    this.searchInputs[1] = selectFamily;
    this.searchInputs = [...this.searchInputs];
  }

  private dataFilterFiscalYear() {
    const fiscalYearFilter: any[] = [];

    for (
      let year = this.currentYear - 6, i = 0;
      year <= this.currentYear + 6;
      year++, i++
    ) {
      fiscalYearFilter.push({
        key: i,
        value: `${year}/${year + 1}`,
      });
    }

    const selectFiscalYear = new SelectSearchInput({
      key: 'fiscalYear',
      hover: 'Fiscal Year',
      options: fiscalYearFilter,
      classCss: 'select-ka-filter-ka-info',
      value: `${this.currentYear}/${this.currentYear + 1}`,
      warn: true,
    });

    this.searchInputs[2] = selectFiscalYear;
    this.searchInputs = [...this.searchInputs];
  }

  private dataFilterQuarter() {
    const selectQuarter = new SelectSearchInput({
      key: 'quarter',
      hover: 'Quarter',
      options: this.quarterEntities,
      classCss: 'select-ka-filter-ka-info',
      value: `F${this.currentQuarter}`,
      warn: true,
    });

    this.searchInputs[3] = selectQuarter;
    this.searchInputs = [...this.searchInputs];
  }

  private dataFilterOrigin() {
    this.originFilter.push(
      { value: 'ALL' },
      { value: 'JAG' },
      { value: 'MAN' }
    );

    const selectFilter = new SelectSearchInput({
      key: 'origin',
      hover: 'Origin',
      classCss: 'multi-select-filter-ka-info',
      options: this.originFilter,
    });

    this.searchInputs[4] = selectFilter;
    this.searchInputs = [...this.searchInputs];
  }

  verifyPermissionEdit(data: any) {
    const userPermission = this.authService.getUserPermissions();
    const canEditAllocation = userPermission.some(
      (permission: any) =>
        permission.resource === 'ka-info' &&
        (permission.permission === 'UPDATE' ||
          permission.permission === 'CREATE')
    );

    if (!canEditAllocation) {
      data.headers.forEach((header: any) => {
        data.rows.forEach((rowKey: any) => {
          rowKey.kaProductList.forEach((row: any) => {
            if (row[header.props].hasOwnProperty('enableInput')) {
              row[header.props].enableInput = false;
            }
          });
        });
      });
    }
  }

  private async getDataFilter(filters?: any): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.tooltipMessage = 'Export';
      this.spinner.show();

      this.kaInfoCrudService.getDataFilters(filters).subscribe(
        (response: any) => {
          this.productFamilyEntity = response[1];
          this.mlsLastUpdate = response[4].mlsLastUpdate;

          this.dataFilterKa(response[0]);
          this.dataFilterFamily(response[1]);
          this.dataFilterQuarter();
          this.dataFilterOrigin();
          this.dataFilterFiscalYear();
          this.searchInputs = [...this.searchInputs];
          resolve();
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  private getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'Q1';
    }
    if (month >= 7 && month <= 9) {
      return 'Q2';
    }
    if (month >= 10 && month <= 12) {
      return 'Q3';
    }
    return 'Q4';
  }

  private dataFormValuesKa(formSearch: any) {
    if (formSearch.kaId) {
      const currentKa = formSearch.kaId.map((item: any) => item.key);
      formSearch.kaId = currentKa;
    } else {
      formSearch.kaId = '';
    }
  }

  private dataFormValuesFamily(formSearch: any) {
    const currentIdsFamily: any = [];

    if (formSearch.ppm) {
      this.productFamilyEntity.forEach((item: any) => {
        formSearch.ppm.forEach((data: any) => {
          if (item.family === data.value) {
            item.ids.forEach((value: any) => {
              currentIdsFamily.push(value);
            });
          }
        });
      });
      formSearch.materialId = currentIdsFamily.map((item: any) => item);
    } else {
      formSearch.materialId = formSearch.materialId
        ? formSearch.materialId
        : '';
    }
  }

  private dataFormValuesFiscalYear(formSearch: any) {
    if (formSearch.fiscalYear) {
      formSearch.fiscalYear = formSearch.fiscalYear
        ? formSearch.fiscalYear.split('/')[0]
        : new Date().getFullYear();
    } else {
      formSearch.fiscalYear = '';
    }
  }

  private dataFormValuesOrigin(formSearch: any) {
    formSearch.origin ? formSearch.origin : '';
  }

  onSearchEvent(value: any) {
    this.dataFormValuesKa(value);
    this.dataFormValuesFamily(value);
    this.dataFormValuesFiscalYear(value);
    this.dataFormValuesOrigin(value);
    this.deleteProperty(value, 'kaInfo');

    this.formSearchValues = { ...value };
    this.enableApplyFilter = Boolean(
      this.formSearchValues[this.keysFilters[0]] !== '' &&
        this.formSearchValues[this.keysFilters[1]] !== '' &&
        this.formSearchValues[this.keysFilters[1]] !== null
    );
    this.filter = {
      ...this.formSearchValues,
      pageName: PAGE_NAME_RESOURCE.kaInfo,
    };
  }

  async getDataTable(params?: KaInfo): Promise<any> {
    this.summedValue = [];
    const kaInfoParams = Object.assign({}, params, {
      pageName: PAGE_NAME_RESOURCE.kaInfo,
    });
    return new Promise<void>((resolve, reject) => {
      this.resetValues();
      this.crudService.getEntity(`ka-Info-brazil/get?`, kaInfoParams).subscribe(
        (response: any) => {
          if (response?.rows?.length > 0) {
            this.verifyPermissionEdit(response);
            this.dataTableResult(response.headers, response.rows);
            this.addSubtotalValues(response.headers, response.rows);
            this.addTypeShowEvent(response.headers);
            this.addIdRowSpan(this.dataTable)
            this.showTable = true;
            this.isValid = false;
            resolve();
          } else {
            this.isValid = true;
            this.showNoResults = true;
            this.spinner.hide();
          }
        },
        (err: KaInfo) => {
          this.isValid = true;
          this.showNoResults = true;
          this.spinner.hide();
          reject(err);
        }
      );
    });
  }

  private dataTableResult(headers?: TableColumn[], rows?: TableRow[]) {
    if (headers !== undefined && rows !== undefined) {
      this.dataTable = {
        table: {
          columns: headers,
          rows: new MatTableDataSource<string>(
            this.convertToDataSource(JSON.stringify(rows))
          ),
        },
      };
    }
  }

  private addIdRowSpan(data: any): void {
    let idCounter = 0;

    data.table.rows.filteredData.forEach((rows: any) => {
      rows.kaProductList.forEach((row: any) => {
        if((row['initDeal'].rowSpan || row['deal'].rowSpan) || 
          (row['initDeal'].materialIds.length === 1 || row['deal'].materialIds.length === 1)) {
          idCounter++;
          row['deal'].id = idCounter;
          row['initDeal'].id = idCounter;
        };
      });
    });
  }

  private addTypeShowEvent(response: TableColumn[]) {
    this.eventTable = 'hide-columns-rows';

    response.forEach((header: any): void => {
      if (header.expansive === true) {
        header.typeShowEvent = 'show-columns-rows';
        header.typeHideEvent = 'hide-columns-rows';
      } else {
        header.typeShowEvent = 'show-columns';
        header.typeHideEvent = 'hide-columns';
      }
    });
  }

  disableInputBasedOnOriginFilter() {
    if (
      this.formSearchValues &&
      this.formSearchValues.origin &&
      (this.formSearchValues.origin === 'JAG' ||
        this.formSearchValues.origin === 'MAN')
    ) {
      this.disableTableInput = true;
    } else {
      this.disableTableInput = false;
    }
  }

  private resetValues() {
    this.dataTable = [];
    this.summedValue = [];
    this.showNoResults = false;
    this.showTable = false;
    this.toggleColumnTableValues = [];
  }

  applyFilter() {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          this.spinner.show();
          this.discardChanges();
          this.modalService.closeAll();
          let formSearchValues = {};
          formSearchValues = { ...this.formSearchValues };
          let params = this.deleteProperty(formSearchValues);

          if (params.length > 0) {
            this.hideSpinnerAfterAll([this.getDataTable(params)]);
          } else {
            this.hideSpinnerAfterAll([this.getDataTable(this.filter)]);
          }
          this.disableInputBasedOnOriginFilter();
        }
      });
    } else {
      this.spinner.show();
      this.discardChanges();
      this.modalService.closeAll();
      let formSearchValues = {};
      formSearchValues = { ...this.formSearchValues };
      let params = this.deleteProperty(formSearchValues);

      if (params.length > 0) {
        this.hideSpinnerAfterAll([this.getDataTable(params)]);
      } else {
        this.hideSpinnerAfterAll([this.getDataTable(this.filter)]);
      }
      this.disableInputBasedOnOriginFilter();
    }
  }

  private deleteProperty(item: any, tab?: string) {
    if (tab === 'kaInfo') {
      delete item.ppm;
    }
    if (item.origin == 'ALL' || item.origin == undefined) {
      item.origin = '';
    }
    delete item.action;
    delete item.nameFormControl;
    delete item.action;
    delete item.formControl;
    delete item.event;
    delete item.panelTrigger;
    return item;
  }

  private hasEditProperty(obj: any): boolean {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    if (
      'edit' in obj ||
      'editDeal' in obj ||
      'editInitDeal' in obj ||
      obj.hasOwnProperty('edit') ||
      obj.hasOwnProperty('editInitDeal') ||
      obj.hasOwnProperty('editDeal')
    ) {
      return true;
    }

    for (const key in obj) {
      if (this.hasEditProperty(obj[key])) {
        return true;
      }
    }

    return false;
  }

  handleSave() {
    const dialog = this.getDialogModal('save');
    dialog.afterClosed().subscribe((result: string) => {
      if (result === 'save') {
        this.spinner.show();
        this.onSave();
      }
    });
  }

  private onSave(typeSave?: string) {
    const newDataToSave: {
      fiscalYear?: string;
      fiscalQuarter?: string;
      month?: string;
      kaId?: number;
      identifier?: string;
      materialId?: string;
      materialIds?: any;
      type?: string;
      value?: number;
      deal?: number;
      initDeal?: number;
    }[] = [];

    for (let i = 0; i < this.dataTable.table?.rows.filteredData.length; i++) {
      Object.entries(this.dataTable.table?.rows.filteredData[i]).forEach(
        (row: any) => {
          if (row[0] === 'kaProductList') {
            row[1].forEach((item: any) => {
              if (this.hasEditProperty(item)) {
                for (const key in item) {
                  if (
                    item.hasOwnProperty(key) &&
                    item[key].hasOwnProperty('edit') &&
                    !item[key].hasOwnProperty('editInitDeal') &&
                    !item[key].hasOwnProperty('editDeal')
                  ) {
                    newDataToSave.push({
                      fiscalYear: item[key].fiscalYear,
                      fiscalQuarter: item[key].quarter,
                      month: item[key].month,
                      kaId: item[key].kaId,
                      identifier: item[key].identifier,
                      materialId: item[key].materialId,
                      type: item[key].type,
                      value: item[key].value,
                    });
                  }
                  if (
                    item.hasOwnProperty(key) &&
                    item[key].hasOwnProperty('editDeal') &&
                    !item[key].hasOwnProperty('edit') &&
                    !item[key].hasOwnProperty('editInitDeal')
                  ) {
                    newDataToSave.push({
                      fiscalYear: item[key].fiscalYear,
                      fiscalQuarter: item[key].quarter,
                      kaId: item[key].kaId,
                      materialIds: item[key].materialIds,
                      deal: item[key].value,
                    });
                  }
                  if (
                    item.hasOwnProperty(key) &&
                    item[key].hasOwnProperty('editInitDeal') &&
                    !item[key].hasOwnProperty('editDeal') &&
                    !item[key].hasOwnProperty('edit')
                  ) {
                    newDataToSave.push({
                      fiscalYear: item[key].fiscalYear,
                      fiscalQuarter: item[key].quarter,
                      kaId: item[key].kaId,
                      materialIds: item[key].materialIds,
                      // deal: item[key].value,
                      initDeal: item[key].value,
                    });
                  }
                }
              }
            });
          }
        }
      );
    }
    this.handlePutSave(newDataToSave, typeSave);
  }

  handlePutSave(dataToSave: any, typeSave: any) {
    this.putCrudService.updateEntity('', dataToSave).subscribe(
      () => {
        if (dataToSave !== undefined) {
          this.isEditTableMode = false;
          this.isSave = true;
          this.modalService.closeAll();
          if (!typeSave) {
            const subtitleMessage = 'Updated successfully!';
            const titleMessage = 'Ka Info';
            this.toastr.success(`${subtitleMessage}`, `${titleMessage}`);
            this.disableInputBasedOnOriginFilter();
            this.hideSpinnerAfterAll([this.getDataTable(this.filter)]);
          }
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.error.message, 'Error!');
      }
    );
  }

  inputChange() {
    this.isEditTableMode = true;

    let num: number = 0;

    for (const item of this.dataTable.table.rows.filteredData) {
      num += item.kaProductList.length;
    }

    if (num > 8) {
      this.buttonFixed = true;
    } else {
      this.buttonFixed = false;
    }

    this.toggleBreadcrumb(true);
  }

  discardChanges() {
    this.isEditTableMode = false;
    this.toggleBreadcrumb(false);
  }

  private checkDiscardAllocation() {
    if (this.isEditTableMode) {
      const dialog = this.getDialogModal('discard');
      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          this.spinner.show();
          this.discardChanges();
          this.modalService.closeAll();
          this.hideSpinnerAfterAll([this.getDataTable(this.filter)]);
          this.disableInputBasedOnOriginFilter();
        }
      });
    } else {
      this.spinner.show();
      this.discardChanges();
      this.modalService.closeAll();
      this.hideSpinnerAfterAll([this.getDataTable(this.filter)]);
      this.disableInputBasedOnOriginFilter();
    }
  }

  public onCancel() {
    this.checkDiscardAllocation();
  }

  private getDialogModal(message: string) {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.messageModalChanges = this.messages[message];
    this.isDiscardChanges = message === 'discard';
    return dialog;
  }

  private convertToDataSource(data: string) {
    const array = JSON.parse(data);
    const dataTable: string[] = [];

    array.forEach((item: string) => {
      dataTable.push(item);
    });
    return [...dataTable];
  }

  toggleColumnTable({ col, event }: any) {
    this.toggleColumnTableValues = [];
    this.toggleColumnTableValues.push(col, event);
  }

  private addSubtotalValues(header?: TableColumn[], rows?: TableRow[]) {
    let headerName: any = [];
    if (header !== undefined && rows !== undefined) {
      header.map((header: any) => {
        headerName = header.props;

        rows
          .map((item: any) => {
            if (item.kaSubtotal[headerName]?.summableValue) {
              return item.kaSubtotal[headerName].value;
            }
          })
          .reduce((acc: number, item: any) => {
            if (acc !== undefined && item !== undefined) {
              return (this.summedValue = acc + item);
            }
          }, 0);
        header.totalAddedValue = this.summedValue;
      });
    }
  }

  addSubtotalValuesDynamic(header?: any) {
    let headerName: any = [];
    header.kaProductList.map((header: any) => {
      headerName = header;
    });
  }

  fixedTabKA() {
    this.tabKASelected = this.tabKASelected + 1;
    setTimeout(() => {
      this.tabKASelected = this.tabKASelected - 1;
    }, 10);
  }

  private handleExportTable(
    data: any,
    rows: any,
    subtotalRow: any,
    sheet: string
  ) {
    const tableRows: { [key: string]: string }[] = [];

    const columns: any[] = data?.columns.map((item: any) => ({
      title: item.title,
      props: item.props,
      totalAddedValue: item.totalAddedValue || false,
      otherContent: item.otherContent || false,
    }));

    for (let i = 0; i < rows.length; i++) {
      const tableRow: { [key: string]: string } = {};
      columns.forEach((column: any) => {
        if (column.title.includes('KA')) {
          tableRow[column.props] = rows[i][column.props]?.value || '';
        } else if (column.title === 'DEAL CWV') {
          tableRow[column.props] = rows[i][column.props]?.valueOriginal || 0;
        } else if (column.title === 'INITIAL DEAL') {
          tableRow[column.props] =
            rows[i][column.props]?.valueOriginal ||
            rows[i][column.props]?.value ||
            0;
        } else {
          tableRow[column.props] = rows[i][column.props]?.value || 0;
        }
      });
      tableRows.push(tableRow);
    }

    const tableSubtotalRow: { [key: string]: string } = {};
    columns.forEach((column: any) => {
      if (column.title.includes('KA')) {
        tableSubtotalRow[column.props] = 'TOTAL';
      } else {
        tableSubtotalRow[column.props] =
          subtotalRow[column.props]?.value || '-';
      }
    });
    tableRows.push(tableSubtotalRow);

    return {
      columns,
      rows: tableRows,
      sheet: sheet,
      headers: true,
    };
  }

  private exportTableOnePlan() {
    let allocateJsonExport: any = [];
    let sheet: string;
    this.dataTable.table.rows.filteredData.forEach((row: any) => {
      const rows = row.kaProductList;
      const subtotal = row.kaSubtotal;
      const data = this.dataTable.table;

      row.kaProductList.forEach((row: any) => {
        if (row.ka.value) {
          sheet = row.ka.value;
        }
      });

      allocateJsonExport.push(
        this.handleExportTable(data, rows, subtotal, sheet)
      );
    });

    this.excelSheetsService.exportJsonAsExcelFile(
      allocateJsonExport,
      'ka-info'
    );
  }

  exportTable() {
    this.exportTableOnePlan();
  }

  toggleBreadcrumb(toDisable: boolean) {
    this.breadcrumbService.set('/home', { disable: toDisable });
    this.breadcrumbService.set('/home/ka-allocation', {
      disable: toDisable,
    });
  }

  backButton() {
    if (this.isEditTableMode) {
      this.isDiscardChanges = true;
      const dialog = this.getDialogModal('discard');

      dialog.afterClosed().subscribe((result: string) => {
        if (result === 'discard') {
          this.router.navigate(['home/ka-allocation']);
        }
      });
    } else {
      this.router.navigate(['home/ka-allocation']);
    }
  }

  uploadButton() {
    this.router.navigate(['home/ka-allocation/ka-info/ka-info-upload']);
  }
}
