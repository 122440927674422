<div class="input-date-default" *ngIf="tabValue !== 'openPoCalendar'">
  <input
    [value]="value"
    (dateChange)="onChangeText($event)"
    (change)="onChangeText($event)"
    [matDatepicker]="picker" />
  <div class="absolute-text">{{ valueFormated }}</div>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>

<div class="input-date-default" *ngIf="tabValue === 'openPoCalendar'">
  <input
    [value]="value"
    (dateChange)="onChangeText($event)"
    (change)="onChangeText($event)"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate" />
  <div class="absolute-text">{{ valueFormatedOpenPoCalendar }}</div>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
