import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stacked-column',
  templateUrl: './stacked-column.component-chart.html',
  styleUrls: ['./stacked-column.component-chart.scss'],
})
export class StackedColumnComponent implements OnInit {
  @Input() dataChart?: any[];
  chartOption: any;
  data: any;
  dataSource: any[] = [];
  dataTotalAllocationQuarter: any[] = [];
  dataDealDemand: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataChart.currentValue) {
      this.getdataSource(changes.dataChart.currentValue);
      this.createChart();
    }
  }

  getdataSource(data: any) {
    this.dataSource = [];
    this.dataTotalAllocationQuarter = [];
    this.dataDealDemand = [];

    Object.entries(data).forEach((data: any) => {
      data[1].source.forEach((data: any) => {
        this.dataSource.push(data.product);
        this.dataTotalAllocationQuarter.push(data.totalAllocationQuarter);
        this.dataDealDemand.push(data.dealDemand);
      });
    });
  }

  createChart() {
    this.chartOption = {
      grid: {
        left: '7%',
        right: '2%',
        top: 20,
        height: 120,
      },

      legend: {
        left: 5,
        itemGap: 20,
        itemWidth: 8.5,
        top: 175,
        icon: 'circle',
        textStyle: {
          fontSize: 10,
        },
        selectedMode: false,
      },

      tooltip: {
        textStyle: {
          fontSize: 12,
        },
      },

      xAxis: {
        type: 'category',
        data: this.dataSource,
        axisLabel: {
          color: '#303030',
          fontSize: 10,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#e3e3e3',
          },
        },
        axisTick: { show: false },
        splitLine: {
          show: true,
        },
      },

      yAxis: {
        type: 'value',
        splitNumber: 4,
        axisLabel: {
          fontSize: 10,
          axisLabel: {},
        },
        splitLine: {
          show: false,
        },
      },

      series: [
        {
          data: this.dataTotalAllocationQuarter,
          name: 'Total Allocation Quarter',
          type: 'bar',
          barWidth: '30px',
          itemStyle: { color: '#a5d6e5' },
        },
        {
          data: this.dataDealDemand,
          name: 'Deal/Demand',
          type: 'bar',
          barWidth: '30px',
          itemStyle: { color: '#107392' },
        },
      ],

      media: [
        {
          query: {
            maxWidth: 800,
          },
          option: {
            grid: {
              left: '12%',
            },
          },
        },
      ],
    };
  }
}
