export const orderAllocationTableMockup: any = {
  expandedTable: {
    headers: [
      {
        title: 'COUNTRY',
        props: 'country',
        otherContent: 'country',
        alwaysVisible: true,
      },
      {
        title: 'SALES MODEL',
        props: 'salesModel',
        alwaysVisible: true,
      },
      {
        title: 'PRODUCT',
        props: 'product',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'COLOR',
        props: 'color',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'MEMORY',
        props: 'memory',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'SIM',
        props: 'sim',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'SO NUMBER',
        props: 'soNumber',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'CUST PO NUMBER',
        props: 'custPoNumber',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'SO LINE NUMBER',
        props: 'soLineNumber',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'CRSD',
        props: 'crsd',
        otherContent: 'country',
        alwaysVisible: false,
      },
      {
        title: 'ORDER QTY',
        props: 'orderQTY',
        otherContent: 'country',
        alwaysVisible: false,
        hasSubtotal: true,
      },
      {
        title: 'CUSTOMER',
        props: 'customer',
        alwaysVisible: true,
      },
      {
        title: '2 TIER CUSTOMER',
        props: 'twoTierCustomer',
        alwaysVisible: true,
      },
      {
        title: 'OPEN QTY',
        props: 'openQTY',
        alwaysVisible: true,
        hasSubtotal: true,
      },
      {
        title: 'UNRELEASED',
        props: 'unreleased',
        alwaysVisible: true,
        hasSubtotal: true,
      },
      {
        title: 'SHIPPED',
        props: 'shipped',
        alwaysVisible: true,
        hasSubtotal: true,
      },
      {
        title: 'WK01',
        props: 'januarywk01',
        alwaysVisible: true,
        weekInTime: 'previous',
        hasSubtotal: true,
        isWeek: true,
      },
      {
        title: 'WK02',
        props: 'januarywk02',
        alwaysVisible: true,
        weekInTime: 'current',
        hasSubtotal: true,
        isWeek: true,
      },
      {
        title: 'WK03',
        props: 'januarywk03',
        alwaysVisible: true,
        nextMonth: true,
        isCurrentMoreTwo: true,
        hasSubtotal: true,
        isWeek: true,
      },
      {
        title: 'WK04',
        props: 'januarywk04',
        alwaysVisible: true,
        nextMonth: true,
        isCurrentMoreTwo: true,
        hasSubtotal: true,
        isWeek: true,
      },
      {
        title: 'OPPORTUNITY',
        props: 'opportunity',
        alwaysVisible: true,
        weekInTime: 'januaryOpportunity',
        hasSubtotal: true,
      },
      {
        title: 'FQ4 TOTAL',
        props: 'fq4Total',
        alwaysVisible: true,
      },
      {
        title: 'GEO COMMENTS',
        props: 'geoComments',
        alwaysVisible: true,
      },
      {
        title: 'SALES COMMENTS',
        props: 'salesComments',
        alwaysVisible: true,
      },
    ],
    rows: [
      {
        country: {
          id: 1,
          value: 'Fr',
        },
        salesModel: {
          value: 'PARX0005FR',
        },
        product: {
          value: 'Malta',
        },
        color: {
          value: 'Bluebird',
        },
        memory: {
          value: '2/32',
        },
        sim: {
          value: 'Dual Sim Unlock',
        },
        soNumber: {
          value: '4982440978',
        },
        custPoNumber: {
          value: '1000230710',
        },
        soLineNumber: {
          value: 30,
        },
        crsd: {
          value: '2022/05/01',
        },
        orderQTY: {
          value: 1,
        },
        customer: {
          value: 'SARL ICP LOGISTIQUE',
        },
        twoTierCustomer: {
          value: 'SARL ICP LOGISTIQUE',
          enableInput: true,
        },
        openQTY: {
          value: 1,
        },
        unreleased: {
          value: 0,
        },
        shipped: {
          value: 0,
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        opportunity: {
          value: 0,
          enableInput: true,
        },
        fq4Total: {
          value: '',
        },
        geoComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
      {
        country: {
          id: 2,
          value: 'Fr',
        },
        salesModel: {
          value: 'PARX0005FR',
        },
        product: {
          value: 'Malta',
        },
        color: {
          value: 'Bluebird',
        },
        memory: {
          value: '2/32',
        },
        sim: {
          value: 'Dual Sim Unlock',
        },
        soNumber: {
          value: '4401594490',
        },
        custPoNumber: {
          value: '1000241971',
        },
        soLineNumber: {
          value: 10,
        },
        crsd: {
          value: '2022/05/01',
        },
        orderQTY: {
          value: 1000,
        },
        customer: {
          value: 'MODELABS MOBILE SAS',
        },
        twoTierCustomer: {
          value: 'MODELABS MOBILE SAS',
          enableInput: true,
        },
        openQTY: {
          value: 500,
        },
        unreleased: {
          value: 500,
        },
        shipped: {
          value: 0,
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        opportunity: {
          value: 0,
          enableInput: true,
        },
        fq4Total: {
          value: '',
        },
        geoComments: {
          value: 'geo comment 2',
          comment: true, // deve ficar false se o comentario for apagado
        },
        salesComments: {
          value: 'sales coments 2',
          comment: true, // deve ficar false se o comentario for apagado
        },
      },
      {
        country: {
          id: 3,
          value: 'Fr',
        },
        salesModel: {
          value: 'PARX0005FR',
        },
        product: {
          value: 'Malta',
        },
        color: {
          value: 'Bluebird',
        },
        memory: {
          value: '2/32',
        },
        sim: {
          value: 'Dual Sim Unlock',
        },
        soNumber: {
          value: '44011428012',
        },
        custPoNumber: {
          value: '1000263429',
        },
        soLineNumber: {
          value: 20,
        },
        crsd: {
          value: '2022/05/01',
        },
        orderQTY: {
          value: 3000,
        },
        customer: {
          value: 'MODELABS MOBILE SAS',
        },
        twoTierCustomer: {
          value: 'MODELABS MOBILE SAS',
          enableInput: true,
        },
        openQTY: {
          value: 1000,
        },
        unreleased: {
          value: 1000,
        },
        shipped: {
          value: 1000,
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        opportunity: {
          value: 0,
          enableInput: true,
        },
        fq4Total: {
          value: '',
        },
        geoComments: {
          value: 'geo comment 3',
          comment: true, // deve ficar false se o comentario for apagado
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
      {
        country: {
          id: 4,
          value: 'Fr',
        },
        salesModel: {
          value: 'PARX0005FR',
        },
        product: {
          value: 'Malta',
        },
        color: {
          value: 'Bluebird',
        },
        memory: {
          value: '2/32',
        },
        sim: {
          value: 'Dual Sim Unlock',
        },
        soNumber: {
          value: '44011428012',
        },
        custPoNumber: {
          value: '1000263429',
        },
        soLineNumber: {
          value: 30,
        },
        crsd: {
          value: '2022/05/01',
        },
        orderQTY: {
          value: 3000,
        },
        customer: {
          value: 'MODELABS MOBILE SAS',
        },
        twoTierCustomer: {
          value: 'MODELABS MOBILE SAS',
          enableInput: true,
        },
        openQTY: {
          value: 1000,
        },
        unreleased: {
          value: 1000,
        },
        shipped: {
          value: 1000,
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        opportunity: {
          value: 0,
          enableInput: true,
        },
        fq4Total: {
          value: '',
        },
        geoComments: {
          value: 'geo comment 4',
          comment: true, // deve ficar false se o comentario for apagado
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
      {
        country: {
          id: 5,
          value: 'Fr',
        },
        salesModel: {
          value: 'PARX0009FR',
        },
        product: {
          value: 'Malta',
        },
        color: {
          value: 'Bluebird',
        },
        memory: {
          value: '2/32',
        },
        sim: {
          value: 'Dual Sim Unlock',
        },
        soNumber: {
          value: '44011922012',
        },
        custPoNumber: {
          value: '1000263429',
        },
        soLineNumber: {
          value: 30,
        },
        crsd: {
          value: '2022/05/01',
        },
        orderQTY: {
          value: 3000,
        },
        customer: {
          value: 'LENOVO (FRANCE) SAS',
        },
        twoTierCustomer: {
          value: 'LENOVO (FRANCE) SAS',
          enableInput: true,
        },
        openQTY: {
          value: 70,
        },
        unreleased: {
          value: 30,
        },
        shipped: {
          value: 100,
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        opportunity: {
          value: 0,
          enableInput: true,
        },
        fq4Total: {
          value: '',
        },
        geoComments: {
          value: 'geo comment 5',
          comment: true, // deve ficar false se o comentario for apagado
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
      {
        country: {
          id: 6,
          value: 'Fr',
        },
        salesModel: {
          value: 'PARX0009FR',
        },
        product: {
          value: 'Malta',
        },
        color: {
          value: 'Bluebird',
        },
        memory: {
          value: '2/32',
        },
        sim: {
          value: 'Dual Sim Unlock',
        },
        soNumber: {
          value: '44011922012',
        },
        custPoNumber: {
          value: '29348092384',
        },
        soLineNumber: {
          value: 30,
        },
        crsd: {
          value: '2022/05/01',
        },
        orderQTY: {
          value: 3000,
        },
        customer: {
          value: 'LENOVO (FRANCE) SAS',
        },
        twoTierCustomer: {
          value: 'LENOVO (FRANCE) SAS',
          enableInput: true,
        },
        openQTY: {
          value: 200,
        },
        unreleased: {
          value: 200,
        },
        shipped: {
          value: 1600,
        },
        januarywk01: {
          value: 0,
          enableInput: true,
        },
        januarywk02: {
          value: 0,
          enableInput: true,
        },
        januarywk03: {
          value: 0,
          enableInput: true,
        },
        januarywk04: {
          value: 0,
          enableInput: true,
        },
        opportunity: {
          value: 0,
          enableInput: true,
        },
        fq4Total: {
          value: '',
        },
        geoComments: {
          value: 'geo comment 6',
          comment: true, // deve ficar false se o comentario for apagado
        },
        salesComments: {
          value: '',
          comment: false, // deve ficar true caso seja adicionado um comentario
        },
      },
    ],
  },
};
