export const KaExecutionMockup: any = {
  headers: [
    {
      title: 'KA',
      props: 'ka',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'PPM',
      props: 'ppm',
      otherContent: 'ppm',
      alwaysVisible: true,
    },
    {
      title: 'ORIGIN',
      props: 'origin',
      otherContent: 'ppm',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'SALES MODEL',
      props: 'salesModel',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'ALLOC',
      props: 'alloc',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'INVOICED',
      props: 'invoiced',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'OPEN ORDERS',
      props: 'openOrders',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'ENTERED',
      props: 'entered',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'SSD',
      props: 'ssd',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'DELIVERY',
      props: 'delivery',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'ACTUAL',
      props: 'actual',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'GAP',
      props: 'gap',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'MISSING ORDERS',
      props: 'missingOrders',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'MISSING SSD',
      props: 'missingSsd',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'OVERBOOKING',
      props: 'overbooking',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'TO BOOK',
      props: 'toBook',
      alwaysVisible: true,
      enableInput: true,
    },
  ],
  rows: [
    {
      ka: 'CLARO_BR',
      data: [
        {
          ka: { value: 'CLARO_BR', id: 0 },
          ppm: { value: 'XT2095 - Malta 2/32 - Ice Flow' },
          origin: { value: 'JAG' },
          salesModel: { value: 'PAMU0001BR' },
          alloc: { value: 2140, summableValue: true },
          invoiced: { value: 200, summableValue: true },
          openOrders: { value: 150, summableValue: true },
          entered: { value: 180, summableValue: true },
          ssd: { value: -220, summableValue: true },
          delivery: { value: 300, summableValue: true },
          actual: { value: 250, summableValue: true },
          gap: { value: 50, summableValue: true },
          missingOrders: { value: 120, summableValue: true },
          missingSsd: { value: 80, summableValue: true },
          overbooking: { value: 80, summableValue: true },
          toBook: { value: 90, summableValue: true },
        },
        {
          ka: { value: 'CLARO_BR', id: 1 },
          ppm: { value: 'XT2095 - Malta 2/32 - Ice Flow' },
          origin: { value: 'JAG' },
          salesModel: { value: 'PAMU0001BR' },
          alloc: { value: 2140, summableValue: true },
          invoiced: { value: 200, summableValue: true },
          openOrders: { value: 150, summableValue: true },
          entered: { value: 180, summableValue: true },
          ssd: { value: -220, summableValue: true },
          delivery: { value: 300, summableValue: true },
          actual: { value: 250, summableValue: true },
          gap: { value: 50, summableValue: true },
          missingOrders: { value: -500, summableValue: true },
          missingSsd: { value: 80, summableValue: true },
          overbooking: { value: 80, summableValue: true },
          toBook: { value: 90, summableValue: true },
        },
      ],
    },
    {
      ka: 'VIVO_BRA',
      data: [
        {
          ka: { value: 'VIVO_BRA', id: 0 },
          ppm: { value: 'XT2095 - Malta 2/32 - Ice Flow' },
          origin: { value: 'JAG' },
          salesModel: { value: 'PAMU0001BR' },
          alloc: { value: 2140, summableValue: true },
          invoiced: { value: 200, summableValue: true },
          openOrders: { value: 150, summableValue: true },
          entered: { value: 180, summableValue: true },
          ssd: { value: 220, summableValue: true },
          delivery: { value: 300, summableValue: true },
          actual: { value: 250, summableValue: true },
          gap: { value: 50, summableValue: true },
          missingOrders: { value: 120, summableValue: true },
          missingSsd: { value: 80, summableValue: true },
          overbooking: { value: 80, summableValue: true },
          toBook: { value: 90, summableValue: true },
        },
      ],
    },
  ],
};

export const KaExecutionMockupPlus1: any = {
  headers: [
    {
      title: 'KA',
      props: 'ka',
      hideIconExpand: true,
      alwaysVisible: true,
    },
    {
      title: 'PPM',
      props: 'ppm',
      otherContent: 'ppm',
      alwaysVisible: true,
    },
    {
      title: 'ORIGIN',
      props: 'origin',
      otherContent: 'ppm',
      hideIconExpand: true,
      alwaysVisible: false,
    },
    {
      title: 'SALES MODEL',
      props: 'salesModel',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'ALLOC',
      props: 'alloc',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'INVOICED',
      props: 'invoiced',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'OPEN ORDERS',
      props: 'openOrders',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'ENTERED',
      props: 'entered',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'SSD',
      props: 'ssd',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'DELIVERY',
      props: 'delivery',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'ACTUAL',
      props: 'actual',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'GAP',
      props: 'gap',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'MISSING ORDERS',
      props: 'missingOrders',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'MISSING SSD',
      props: 'missingSsd',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'OVERBOOKING',
      props: 'overbooking',
      alwaysVisible: true,
      enableInput: true,
    },
    {
      title: 'TOBOOK',
      props: 'toBook',
      alwaysVisible: true,
      enableInput: true,
    },
  ],
  rows: [
    {
      ka: 'CLARO_BR',
      data: [
        {
          ka: { value: 'CLARO_BR', id: 0 },
          ppm: { value: 'XT2095 - Malta 2/32 - Ice Flow' },
          origin: { value: 'JAG' },
          salesModel: { value: 'PAMU0001BR' },
          alloc: { value: 3000, summableValue: true },
          invoiced: { value: 250, summableValue: true },
          openOrders: { value: 180, summableValue: true },
          entered: { value: 200, summableValue: true },
          ssd: { value: -300, summableValue: true },
          delivery: { value: 400, summableValue: true },
          actual: { value: 350, summableValue: true },
          gap: { value: 70, summableValue: true },
          missingOrders: { value: 150, summableValue: true },
          missingSsd: { value: 100, summableValue: true },
          overbooking: { value: 100, summableValue: true },
          toBook: { value: 120, summableValue: true },
        },
        {
          ka: { value: 'CLARO_BR', id: 1 },
          ppm: { value: 'XT2095 - Malta 2/32 - Ice Flow' },
          origin: { value: 'JAG' },
          salesModel: { value: 'PAMU0001BR' },
          alloc: { value: 2700, summableValue: true },
          invoiced: { value: 180, summableValue: true },
          openOrders: { value: 130, summableValue: true },
          entered: { value: 160, summableValue: true },
          ssd: { value: -180, summableValue: true },
          delivery: { value: 250, summableValue: true },
          actual: { value: 200, summableValue: true },
          gap: { value: 40, summableValue: true },
          missingOrders: { value: -400, summableValue: true },
          missingSsd: { value: 70, summableValue: true },
          overbooking: { value: 70, summableValue: true },
          toBook: { value: 80, summableValue: true },
        },
      ],
    },
    {
      ka: 'VIVO_BRA',
      data: [
        {
          ka: { value: 'VIVO_BRA', id: 0 },
          ppm: { value: 'XT2095 - Malta 2/32 - Ice Flow' },
          origin: { value: 'JAG' },
          salesModel: { value: 'PAMU0001BR' },
          alloc: { value: 2300, summableValue: true },
          invoiced: { value: 220, summableValue: true },
          openOrders: { value: 160, summableValue: true },
          entered: { value: 190, summableValue: true },
          ssd: { value: 200, summableValue: true },
          delivery: { value: 350, summableValue: true },
          actual: { value: 280, summableValue: true },
          gap: { value: 60, summableValue: true },
          missingOrders: { value: 140, summableValue: true },
          missingSsd: { value: 90, summableValue: true },
          overbooking: { value: 90, summableValue: true },
          toBook: { value: 100, summableValue: true },
        },
      ],
    },
  ],
};
