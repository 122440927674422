import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ModalActionsService } from 'src/app/services/modal-actions.service';
import { Tab } from 'src/app/interfaces/tab.interface';
import { MatButton } from '@angular/material/button';
import { CrudService } from 'src/app/services/generic/crud.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnChanges {
  @ViewChild('dialogConfirmationDiscardSaveChanges', { static: true })
  dialogConfirmationDiscardSaveChanges: TemplateRef<any> | any;
  @ViewChild('AllocationKaAllocationComponent', { static: false })
  AllocationKaAllocationComponent: any;
  @ViewChild('saveChangesButton') saveChangesButton!: MatButton;
  @Input() tabs: Tab[] = [];
  @Input() method: any;
  @Input() isEditTableMode!: boolean;
  @Input() messagesDialog: any;
  @Input() secondTab!: boolean;
  @Input() resultTabs!: any;
  @Input() resultTabsCalendar!: any;
  @Input() noRecordFound!: any;
  @Output() activeTabIndexChange = new EventEmitter<number>();
  @Output() selectedTab = new EventEmitter<number>();
  // @Output() changeTabDate = new EventEmitter<any>();

  @Output() onTabOpenPo: any = new EventEmitter<any>();
  @Output() paramsCalendar: any = new EventEmitter<any>();
  @Output() cancel: any = new EventEmitter<any>();

  apiCrudService: CrudService<any>;

  isDiscardChanges = false;
  selectedTabIndex = 0;
  selectedTabIndexOnePlan = 0;
  messageModalChanges!: string;
  dataTabAwaitingShipping!: any;
  titleAwaitingShipping: string = 'Hold Flag';

  dataTabOrderDummy!: any;
  titleOrderDummy: string = 'Status Dummy';

  dataTabOpenPoHoldFlag!: any;

  dataMonth!: string[];

  dataCalendarValue: any = 'undefined';

  selectedTabMonth!: string;
  modeTableOpenPO: boolean;

  disableDateMan: boolean = true;
  disableDateJag: boolean = true;

  calendarAPI: any = [];

  paramsMan: Object = { origin: 'MAN' };
  paramsJag: Object = { origin: 'JAG' };
  params: any[] = [];

  filter: any = {
    quarter: this.getQuarterFromDate(new Date()),
  };

  dateMan = { start_date: '', end_date: '', id: 0 };
  dateJag = { start_date: '', end_date: '', id: 0 };

  dateManTable: any = {};
  dateJagTable: any = {};

  disabledSaveButton: boolean = true;

  noRecordFoundAs!: boolean;
  noRecordFoundOd!: boolean;
  noRecordFoundOp!: boolean;

  constructor(
    private modalService: ModalActionsService,
    protected http: HttpClient
  ) {
    this.apiCrudService = new CrudService<any>(this.http, 'crsd');
    this.modeTableOpenPO = true;
    this.filter = {
      ...this.filter,
      year: this.checkCurrentYearAndQuarter(),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resultTabs) {
      this.dataTabAwaitingShipping = this.resultTabs[0];
      this.dataTabOrderDummy = this.resultTabs[1];
      this.dataTabOpenPoHoldFlag = this.resultTabs[2];

      this.dataMonth = this.getMonthsArray();
    }

    if (changes.noRecordFound?.currentValue.length > 0) {
      this.noRecordFoundAs =
        changes.noRecordFound?.currentValue[0].noRecordFoundAs;
      this.noRecordFoundOd =
        changes.noRecordFound?.currentValue[0].noRecordFoundOd;
      this.noRecordFoundOp =
        changes.noRecordFound?.currentValue[0].noRecordFoundOp;
    }

    if (changes.resultTabsCalendar?.currentValue) {
      this.dateMan.id = changes.resultTabsCalendar?.currentValue[0].id;
      this.dateMan.start_date =
        changes.resultTabsCalendar?.currentValue[0].startDate;
      this.dateMan.end_date =
        changes.resultTabsCalendar?.currentValue[0].endDate;
      this.dateJag.id = changes.resultTabsCalendar?.currentValue[1].id;
      this.dateJag.start_date =
        changes.resultTabsCalendar?.currentValue[1].startDate;
      this.dateJag.end_date =
        changes.resultTabsCalendar?.currentValue[1].endDate;

      this.paramsMan = { ...this.paramsMan, ...this.dateMan };
      this.paramsJag = { ...this.paramsJag, ...this.dateJag };

      this.dateManTable['startDate'] =
        changes.resultTabsCalendar?.currentValue[0].startDate;
      this.dateManTable['endDate'] =
        changes.resultTabsCalendar?.currentValue[0].endDate;
      this.dateManTable['initialDay'] =
        changes.resultTabsCalendar?.currentValue[0].initialDay;
      this.dateManTable['finalDay'] =
        changes.resultTabsCalendar?.currentValue[0].finalDay;
      this.dateManTable['qtd'] =
        changes.resultTabsCalendar?.currentValue[0].qtd;

      this.dateJagTable['startDate'] =
        changes.resultTabsCalendar?.currentValue[1].startDate;
      this.dateJagTable['endDate'] =
        changes.resultTabsCalendar?.currentValue[1].endDate;
      this.dateJagTable['initialDay'] =
        changes.resultTabsCalendar?.currentValue[1].initialDay;
      this.dateJagTable['finalDay'] =
        changes.resultTabsCalendar?.currentValue[1].finalDay;
      this.dateJagTable['qtd'] =
        changes.resultTabsCalendar?.currentValue[1].qtd;

      this.dataCalendarValue =
        changes.resultTabsCalendar?.currentValue[0].startDate;
    }
  }

  getQuarterFromDate(date: Date) {
    const month = date.getMonth() + 1;
    if (month >= 4 && month <= 6) {
      return 'FQ1';
    }
    if (month >= 7 && month <= 9) {
      return 'FQ2';
    }
    if (month >= 10 && month <= 12) {
      return 'FQ3';
    }
    return 'FQ4';
  }

  checkCurrentYearAndQuarter() {
    if (this.filter.quarter === 'FQ4') return new Date().getFullYear() - 1;
    return new Date().getFullYear();
  }

  getMonthsArray(): string[] {
    const months: string[] = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    months.push(monthNames[currentMonth].substring(0, 3));

    this.selectedTabMonth = monthNames[currentMonth].substring(0, 3);

    for (let i = 1; i <= 5; i++) {
      const nextMonthIndex = (currentMonth + i) % 12;
      months.push(monthNames[nextMonthIndex].substring(0, 3));
    }

    return months;
  }

  onChangeDay(event: any, origin: string, startOrEnd?: string) {
    this.disabledSaveButton = false;

    if (origin === 'MAN') {
      if (startOrEnd === 'start') {
        this.dateMan.start_date = this.formattedValue(event);
        this.disableDateMan = false;
      }

      if (startOrEnd === 'end') {
        this.dateMan.end_date = this.formattedValue(event);
      }
    }

    if (origin === 'JAG') {
      if (startOrEnd === 'start') {
        this.dateJag.start_date = this.formattedValue(event);
        this.disableDateJag = false;
      }

      if (startOrEnd === 'end') {
        this.dateJag.end_date = this.formattedValue(event);
      }
    }

    this.paramsMan = { ...this.paramsMan, ...this.dateMan };
    this.paramsJag = { ...this.paramsJag, ...this.dateJag };

    this.params = [this.paramsMan, this.paramsJag];
  }

  formattedValue(date: string) {
    const originalDate = new Date(date);

    const year = originalDate.getFullYear();
    const month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
    const day = ('0' + originalDate.getDate()).slice(-2);

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  tabClick(event: MouseEvent): void {
    this.selectedTab.emit(this.selectedTabIndex);
    const target = event.target as HTMLElement;
    if (this.isEditTableMode && this.isTabLabelClicked(event)) {
      this.showConfirmationModal(this.messagesDialog.discard).subscribe(
        result => {
          if (result) {
            this.method.discardChanges();
            this.changeTab(target.innerText);
            this.changeTabOnePlan(target.innerText);
          }
        }
      );
    } else {
      this.changeTab(target.innerText);
      this.changeTabOnePlan(target.innerText);
    }
  }

  private showConfirmationModal(message: string): Observable<boolean> {
    const dialog = this.modalService.createConfirm(
      this.dialogConfirmationDiscardSaveChanges
    );
    this.isDiscardChanges = true;
    this.messageModalChanges = message;
    return dialog.afterClosed();
  }

  private isTabLabelClicked(event: MouseEvent) {
    const target = event.target as HTMLElement;
    return target != null && target.className.includes('mat-tab-label');
  }

  private changeTab(label: string) {
    this.tabs.forEach((tab: Tab) => {
      if (tab.tab === label) {
        const tabIndex = this.tabs.indexOf(tab);
        this.selectedTabIndex = tabIndex;
        this.activeTabIndexChange.emit(tabIndex);
      }
    });
  }

  private changeTabOnePlan(label: string) {
    this.tabs.forEach((tab: Tab) => {
      if (tab.tab === label.toLocaleUpperCase()) {
        const tabIndex = this.tabs.indexOf(tab);
        this.selectedTabIndexOnePlan = tabIndex;
        this.activeTabIndexChange.emit(tabIndex);
      }
    });
  }

  saveOpenPoCalendar() {
    this.paramsCalendar.emit(this.params);
    this.resetTable();
  }

  resetTable(): void {
    // Restaurar os valores originais da tabela
    this.dateMan.start_date = '';
    this.dateMan.end_date = '';
    this.dateJag.start_date = '';
    this.dateJag.end_date = '';
    this.paramsMan = { ...this.paramsMan, ...this.dateMan };
    this.paramsJag = { ...this.paramsJag, ...this.dateJag };
    this.params = [this.paramsMan, this.paramsJag];
    this.dataCalendarValue = 'undefined';
  }

  cancelOpenPoCalendar() {
    this.disabledSaveButton = true;
    // Restaurar as datas de MAN e JAG para os valores originais
    this.dateMan.start_date = this.dateManTable.startDate;
    this.dateMan.end_date = this.dateManTable.endDate;
    this.dateJag.start_date = this.dateJagTable.startDate;
    this.dateJag.end_date = this.dateJagTable.endDate;
    this.paramsMan = { ...this.paramsMan, ...this.dateMan };
    this.paramsJag = { ...this.paramsJag, ...this.dateJag };
    this.params = [this.paramsMan, this.paramsJag];
    this.dataCalendarValue = 'undefined';

    this.cancel.emit(this.params);
  }

  switchMode() {
    this.modeTableOpenPO = !this.modeTableOpenPO;

    this.onTabOpenPo.emit(this.modeTableOpenPO);
  }
}
