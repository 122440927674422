export const tableExecutiveOverviewMockup: any = {
  table: {
    columns: [
      {
        label: 'PRODUCT',
        prop: 'product',
        sticky: true,
        style: { width: '7%' },
      },
      {
        label: 'DATA TYPE',
        prop: 'dataType',
        sticky: true,
        style: { width: '8%' },
      },
      {
        label: 'WK01',
        prop: 'wk01',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK02',
        prop: 'wk02',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK03',
        prop: 'wk03',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK04',
        prop: 'wk04',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK05',
        prop: 'wk05',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK06',
        prop: 'wk06',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK07',
        prop: 'wk07',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK08',
        prop: 'wk08',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK09',
        prop: 'wk09',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK10',
        prop: 'wk10',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK11',
        prop: 'wk11',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK12',
        prop: 'wk12',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'WK13',
        prop: 'wk13',
        sticky: true,
        style: { width: '4%' },
      },
      {
        label: 'TOTAL JAN',
        prop: 'totJan',
        sticky: true,
        style: { width: '6%' },
      },
      {
        label: 'TOTAL FEV',
        prop: 'totFev',
        sticky: true,
        style: { width: '6%' },
      },
      {
        label: 'TOTAL MAR',
        prop: 'totMar',
        sticky: true,
        style: { width: '6%' },
      },
      {
        label: 'F14 TOTAL',
        prop: 'f14Total',
        sticky: true,
        style: { width: '6%' },
      },
    ],
    rows: [
      {
        product: 'Aruba',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
      {
        product: 'Berlin',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
      {
        product: 'Carpi',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
      {
        product: 'Capri Plus',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
      {
        product: 'Cebu',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
      {
        product: 'Aruba Plus',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
      {
        product: 'Berlin Plus',
        dataType: 'Latest Allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 600,
        wk04: 5000,
        wk05: 1000,
        wk06: 2000,
        wk07: 600,
        wk08: 5000,
        wk09: 1000,
        wk10: 2000,
        wk11: 600,
        wk12: 5000,
        wk13: 2000,
        totJan: 8600,
        totFev: 8600,
        totMar: 8800,
        f14Total: 27800,
      },
      {
        product: '',
        dataType: 'Previous allocation',
        wk01: 50,
        wk02: 2000,
        wk03: 300,
        wk04: 6000,
        wk05: 900,
        wk06: 2000,
        wk07: 300,
        wk08: 6000,
        wk09: 900,
        wk10: 2000,
        wk11: 300,
        wk12: 6000,
        wk13: 2000,
        totJan: 9200,
        totFev: 9200,
        totMar: 9400,
        f14Total: 29600,
      },
      {
        product: '',
        dataType: 'Wow Delta',
        wk01: 0,
        wk02: 0,
        wk03: 300,
        wk04: -1000,
        wk05: 100,
        wk06: 0,
        wk07: 300,
        wk08: -1000,
        wk09: 100,
        wk10: 0,
        wk11: 300,
        wk12: -1000,
        wk13: 0,
        totJan: -600,
        totFev: -600,
        totMar: -600,
        f14Total: -1800,
      },
    ],
    dataType: 'wow-analysis',
  },
};
