import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  ElementRef,
  SimpleChanges,
  ViewChild,
  OnChanges,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-table-customer-view',
  templateUrl: './table-customer-view.component.html',
  styleUrls: ['./table-customer-view.component.scss'],
})
export class TableCustomerViewComponent implements OnChanges {
  @Input() dataSource!: any;
  @Input() keyDataSource!: number;
  @Input() eventTable!: string;
  @Input() showSubtotalTitle!: any;
  @Input() origins!: any;
  @Output('toggleTable') toggleTableEvent = new EventEmitter();
  @Output() valueIdentified = new EventEmitter<any>();

  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;
  headers: any = [];
  content: any = [];
  displayedColumns: any = [];
  displayedContent: any = [];
  translateYAbsoluteItem: number = 0;
  offsetTopSubTotal: number = 0;
  hasExpanded: boolean = false;
  hasRowsExpanded: boolean = false;
  keyContent = 'summarized';
  columnsNames: string[] = [];
  showItemsProduct: boolean = false;
  currentVisibleCustomerShort: string = '';
  visibleItems: any[] = [];

  constructor(private cdr: ChangeDetectorRef, private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.dataSource = changes.dataSource.currentValue;
      this.headers = this.dataSource.headers;
      this.content = this.dataSource.content;
      this.displayedColumns = [];
      this.displayedContent = [];

      if (!changes.dataSource.currentValue.displayedColumns) {
        this.headers.forEach((header: any) => {
          if (header.props === header.otherContent || header.alwaysVisible) {
            this.displayedColumns.push(header);
            this.columnsNames.push(header.props);
          }
        });
      } else {
        this.displayedColumns =
          changes.dataSource.currentValue.displayedColumns;
        this.columnsNames = this.displayedColumns.map(
          (header: any) => header.props
        );
      }
    }

    if (changes.eventTable.currentValue) {
      if (changes.eventTable.currentValue.includes('show-columns-rows')) {
        this.keyContent = 'expanded';
        this.hasRowsExpanded = true;
      } else if (
        changes.eventTable.currentValue.includes('hide-columns-rows')
      ) {
        this.keyContent = 'summarized';
        this.hasRowsExpanded = false;
      }
    }
    this.displayedContent = JSON.parse(JSON.stringify(this.content));

    this.updateContent(this.columnsNames);
  }

  ngOnInit(): void {
    this.updateVisibleItems();
  }

  ngAfterViewInit() {
    this.setTableScroll();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.calculateTranslateYAbsoluteItem();
    this.updateVisibleItems();
  }

  updateVisibleItems(): void {
    const tableBody = this.el.nativeElement.querySelector('.table-body');
    const tableRows = tableBody.querySelectorAll('tr');

    tableRows.forEach((row: any) => {
      const rowRect = row.getBoundingClientRect();

      if (rowRect.top >= 0 && rowRect.bottom <= window.innerHeight) {
        if (
          row.cells[0].textContent !== ' 0 ' &&
          row.cells[0].textContent !== '0' &&
          row.cells[0].textContent !== ' ' &&
          row.cells[0].textContent !== ' SUBTOTAL '
        ) {
          this.currentVisibleCustomerShort = row.cells[0].textContent;
          console.log(row.cells[0].textContent);
        }
      }
    });
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  calculateTranslateYAbsoluteItem() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('line-title-btn')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;

    const appFooterSubTotal = document.getElementById('subtotal1')?.offsetTop;

    this.offsetTopSubTotal = appFooterSubTotal || 0;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement.offsetTop + clientHeightTop;

    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  updateContent(columnsNames: any[]) {
    this.displayedContent = this.displayedContent.map((content: any) => {
      if (content[this.keyContent]) {
        content[this.keyContent].forEach((element: any) => {
          element.rows = element.rows.map((item: any) => {
            const row = { ...item };
            row.value = {};
            this.displayedColumns.forEach((column: any) => {
              if (!columnsNames.includes(column.props)) {
                columnsNames.push(column.props);
              }
              row.value[column.props] = item.value[column.props];
            });
            return row;
          });
        });
      }
      return content;
    });
  }

  hasNetOpenPo(key: any, data: any) {
    if (key === 'netOpenPo') {
      if (data[key].flagged) {
        return true;
      }
      return false;
    }
    return false;
  }

  isCurrentWeek(labelTable: any) {
    if (labelTable.indexOf('wk') > -1) {
      return true;
    }
    return false;
  }

  isColumnCustomerShort(labelTable: any) {
    if (labelTable.indexOf('customer') > -1) {
      return true;
    }
    return false;
  }

  getColorAlert(key: any) {
    return key.toLocaleUpperCase().includes('OVERBOOKED');
  }

  getAddRowId(indexRow: number, origin: string, customerShort: string) {
    if (this.hasRowsExpanded) {
      return indexRow + origin + customerShort;
    }
    return indexRow + customerShort;
  }

  mouseEnter(indexRow: number, origin: string, customerShort: string) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow + origin + customerShort;

    tableRows.forEach((row: any) => {
      if (this.hasRowsExpanded) {
        if (row.id == rowId) {
          row.classList.add('hover-row');
        }
      } else {
        if (row.id == indexRow + customerShort) {
          row.classList.add('hover-row');
        }
      }
    });
    this.cdr.detectChanges();
  }

  mouseLeave(indexRow: number, origin: string, customerShort: string) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow + origin + customerShort;

    tableRows.forEach((row: any) => {
      if (this.hasRowsExpanded) {
        if (row.id == rowId) {
          row.classList.remove('hover-row');
        }
      } else {
        if (row.id == indexRow + customerShort) {
          row.classList.remove('hover-row');
        }
      }
    });
    this.cdr.detectChanges();
  }

  toggleColumnTable(col: any, indexCol: number, event: string) {
    const columnsAlwaysVisible: any[] = [];
    const groupColumnsToShowHide = this.headers.filter((item: any) => {
      if (item.alwaysVisible) {
        columnsAlwaysVisible.push(item);
      }
      if (item.otherContent === col.otherContent || item.alwaysVisible) {
        item.expanded = true;
        return item;
      }
      return false;
    });

    groupColumnsToShowHide.shift();
    this.columnsNames = [];

    if (event.includes('show')) {
      col.expanded = true;
      this.displayedColumns.splice(indexCol + 1, 0, ...groupColumnsToShowHide);
    } else if (event.includes('hide')) {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, groupColumnsToShowHide.length);
      this.displayedColumns.splice(indexCol + 1, 0, ...columnsAlwaysVisible);
    }

    if (event === 'show-columns-rows') {
      this.hasRowsExpanded = true;
    } else if (event === 'hide-columns-rows') {
      this.hasRowsExpanded = false;
    }

    if (this.hasRowsExpanded) {
      this.keyContent = 'expanded';
    } else {
      this.keyContent = 'summarized';
    }

    this.displayedColumns = Array.from(new Set(this.displayedColumns));
    this.displayedContent = JSON.parse(JSON.stringify(this.content));
    this.updateContent(this.columnsNames);

    if (this.headers.filter((obj: any) => obj.expanded === true).length) {
      this.hasExpanded = true;
    } else {
      this.hasExpanded = false;
    }
    this.setTableScroll();

    this.toggleTableEvent.emit(event);
  }

  showOriginTitle(key: any, value: any) {
    const expandedRows = this.dataSource.content[0].expanded;
    const hasMultipleExpandedRows = expandedRows.length > 1;

    if (hasMultipleExpandedRows) {
      if (
        key === 'origin' &&
        this.translateYAbsoluteItem <= this.offsetTopSubTotal
      ) {
        for (let i = 0; i < this.origins.length; i++) {
          return this.origins[i].value;
        }
      } else {
        for (let i = 0; i < this.origins.length - 1; i++) {
          return this.origins[i + 1].value;
        }
      }
    }
    return value;
  }

  identifyValueNetOpen(rowValue: any, column: any) {
    if (column === 'netOpenPo') {
      this.valueIdentified.emit({ rowValue, column });
    }
  }
}
