<div class="table-body">
  <table mat-table [dataSource]="dataSource">
    <ng-container
      *ngFor="let colDef of columnsLabel; let i = index; trackBy: trackByIndex">
      <ng-container *ngIf="colDef.sticky" [matColumnDef]="colDef.label">
        <div
          *ngIf="
            colDef.label !== 'actions' &&
            colDef.label !== 'permissions' &&
            !colDef.accordion
          ">
          ''
          <div *ngIf="colDef.allowSelection; else columnTitleBlock">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
          </div>
          <ng-template #columnTitleBlock>
            <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
              {{ colDef.label }}
            </th>
          </ng-template>
          <div *ngIf="colDef.allowSelection; else columnDataBlock">
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? checkboxToggle(element) : null"
                [checked]="selection.isSelected(element)"
                [aria-label]="checkboxLabel(element)">
              </mat-checkbox>
            </td>
          </div>
          <ng-template #columnDataBlock>
            <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
              <label
                *ngIf="hasStatus"
                [class.status-disable]="
                  element['active'] === 'Inactive' || !element['active']
                "
                >{{
                  colDef.prop ? element[colDef.prop] : element[colDef.label]
                }}
              </label>
              <label *ngIf="!hasStatus"
                >{{
                  colDef.prop ? element[colDef.prop] : element[colDef.label]
                }}
              </label>
            </td>
          </ng-template>
        </div>
        <div
          *ngIf="
            selectedTab === 'customer_short' || selectedTab === 'customer_list'
          ">
          <div *ngIf="colDef.accordion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
              {{ colDef.label }}
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
              <app-accordion-table
                [data]="element"
                [selectedTab]="selectedTab"></app-accordion-table>
            </td>
          </div>
        </div>
        <div *ngIf="selectedTab === 'emea_location'">
          <div *ngIf="colDef.accordion">
            <th
              mat-header-cell
              *matHeaderCellDef
              [ngStyle]="colDef.style"
              class="location-th">
              {{ colDef.label }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngStyle]="colDef.style"
              colspan="2"
              class="location-td">
              <app-accordion-table
                [data]="element"
                [selectedTab]="selectedTab"
                [result]="colDef.prop"></app-accordion-table>
            </td>
          </div>
        </div>
        <div *ngIf="selectedTab === 'emea_exception'">
          <div *ngIf="colDef.accordion">
            <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
              {{ colDef.label }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngStyle]="colDef.style"
              colspan="2">
              <app-accordion-table
                [data]="element"
                [selectedTab]="selectedTab"
                [result]="colDef.prop"></app-accordion-table>
            </td>
          </div>
        </div>
        <div *ngIf="colDef.label === 'permissions'">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <img
              class="icon-permission"
              src="../../../assets/images/ico-permission.svg"
              (click)="systemFunction(element)" />
          </td>
        </div>
        <div *ngIf="colDef.delete || colDef.edit">
          <th mat-header-cell *matHeaderCellDef [ngStyle]="colDef.style">
            {{ colDef.label }}
          </th>
          <td mat-cell *matCellDef="let element" [ngStyle]="colDef.style">
            <svg
              width="15"
              height="11"
              viewBox="0 0 15 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="
                (colDef.view &&
                  !element.viewNotActive &&
                  selectedTab === 'emea_location') ||
                selectedTab === 'emea_exception'
              "
              class="icon-action-view"
              (click)="viewEvent(element)"
              matTooltip="View"
              [matTooltipPosition]="'left'">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.49929 0C10.6487 0 13.3672 2.87124 14.6333 4.46463C15.1203 5.07749 15.1203 5.92251 14.6333 6.53537C13.3672 8.12876 10.6487 11 7.49929 11C4.34993 11 1.63141 8.12876 0.365251 6.53537C-0.12175 5.92251 -0.121751 5.07749 0.365251 4.46463C1.63141 2.87124 4.34993 0 7.49929 0ZM12.8136 4.9324C13.1072 5.25985 13.1072 5.74015 12.8136 6.0676C11.9248 7.0586 9.87985 9 7.49921 9C5.11857 9 3.07363 7.0586 2.18486 6.0676C1.89119 5.74015 1.89119 5.25985 2.18486 4.9324C3.07363 3.9414 5.11857 2 7.49921 2C9.87985 2 11.9248 3.9414 12.8136 4.9324ZM7.49849 7C8.32886 7 9.00201 6.32843 9.00201 5.5C9.00201 4.67157 8.32886 4 7.49849 4C6.66812 4 5.99497 4.67157 5.99497 5.5C5.99497 6.32843 6.66812 7 7.49849 7Z" />
            </svg>

            <img
              *ngIf="
                (colDef.edit &&
                  !element.editNotActive &&
                  selectedTab === 'customer_short') ||
                selectedTab === 'customer_list'
              "
              class="icon-action"
              src="../../../assets/images/ico-edit.svg"
              (click)="editEvent(element)"
              matTooltip="Edit"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.view && element.viewNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/icon-view-disable.svg"
              matTooltip="{{ tooltipMessage }}"
              matTooltipPosition="left" />

            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="
                (colDef.edit &&
                  !element.editNotActive &&
                  selectedTab === 'emea_location') ||
                selectedTab === 'emea_exception'
              "
              class="icon-action-edit"
              (click)="editEvent(element)"
              matTooltip="Edit"
              [matTooltipPosition]="'left'">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="m18 10 3 3 2-2-3-3-2 2zM8 20v3h3l9-9-3-3-9 9z" />
            </svg>

            <img
              *ngIf="colDef.edit && element.editNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/ico-edit-disable.svg"
              matTooltip="{{ tooltipMessage }}"
              matTooltipPosition="left" />
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="
                (colDef.delete &&
                  !element.deleteNotActive &&
                  selectedTab === 'emea_location') ||
                selectedTab === 'emea_exception'
              "
              class="icon-action-delete"
              (click)="deleteEvent(element)"
              matTooltip="Delete"
              [matTooltipPosition]="'left'">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 8a2 2 0 0 0-1.732 1H9a1 1 0 0 0 0 2h1v9a3 3 0 0 0 3 3h5a3 3 0 0 0 3-3v-9h1a1 1 0 1 0 0-2h-3.268A2 2 0 0 0 17 8h-3zm-2 11v-8h7v8a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2zm1.5-7a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 1 0v-7a.5.5 0 0 0-.5-.5zm1.5.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2.5-.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 1 0v-7a.5.5 0 0 0-.5-.5z" />
            </svg>

            <img
              *ngIf="
                (colDef.delete &&
                  !element.deleteNotActive &&
                  selectedTab === 'customer_short') ||
                selectedTab === 'customer_list'
              "
              class="icon-action"
              src="../../../assets/images/icon-delete.svg"
              (click)="deleteEvent(element)"
              matTooltip="Delete"
              [matTooltipPosition]="'left'" />
            <img
              *ngIf="colDef.delete && element.deleteNotActive"
              class="icon-action icon-action-disabled"
              src="../../../assets/images/icon-delete-disabled.svg" />
          </td>
        </div>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      class="row-color"
      [class.brand]="i % 2 !== 0"
      *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
  </table>
</div>
