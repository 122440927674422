<div #tableBody class="table-body">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container *ngFor="let colDef of columnsLabel; let i = index">
      <ng-container matColumnDef="{{ colDef.label }}">
        <th mat-header-cell *matHeaderCellDef>
          <div
            [class.current-week-red]="colDef.isCurrentWeek === true"
            [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1"
            [class.current-week]="colDef.label === currentWeekLabel"
            class="header">
            <span>{{ colDef.label }}</span>
          </div>

          <div
            *ngIf="translateYAbsoluteItem > 0"
            [class.current-week-red]="colDef.isCurrentWeek === true"
            [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
            [style.transform]="'translateY(' + translateYAbsoluteItem + 'px)'"
            [class.current-week]="colDef.label === currentWeekLabel"
            class="absoluteItem">
            <span>{{ colDef.label }}</span>
          </div>
        </th>

        <td
          mat-cell
          *matCellDef="let element"
          [class.wow-analysis]="element[colDef.prop].value !== 'Wow Delta'"
          [class.is-wow-delta]="element[colDef.prop].value === 'Wow Delta'"
          [class.text-green]="element[colDef.prop].value > 0"
          [class.text-red]="element[colDef.prop].value < 0"
          class="wow-analysis">
          <app-value-currency [value]="element[colDef.prop].value">
          </app-value-currency>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      class="row-color"
      [class.brand]="i % 2 !== 0"
      *matRowDef="let element; columns: displayedColumns; let i = index"></tr>
  </table>
</div>
