<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">EXECUTIVE OVERVIEW</span>
  </div>

  <div class="executive-overview-content">
    <div class="filters">
      <app-search [filters]="searchInputsExecutiveOverview"> </app-search>
    </div>

    <mat-divider></mat-divider>

    <div class="title-quarter">
      <span class="current-quarter">FQ{{ month }}</span>
    </div>

    <app-results
      [data]="result"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
      [hidePagination]="hidePagination"
      [hideResults]="true"></app-results>
  </div>

  <div class="buttons-right">
    <button (click)="backButton()" class="button back-action-button" mat-button>
      BACK
    </button>
  </div>
</div>
