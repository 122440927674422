import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  pages: any[] = [
    {
      name: 'Profile',
      url: '../../../assets/images/ico-profile.svg',
      path: 'profile',
    },
    { name: 'User', url: '../../../assets/images/ico-user.svg', path: 'user' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }

  ngOnInit() {
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'ADMINISTRATION',
          this.pages
        );
      });
    } else {
      this.router.navigate(['authorization']);
    }
  }

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    if (this.userPermission[0].country.toLocaleLowerCase() === 'brazil') {
      this.router.navigate(['home/setup']);
    } else {
      this.router.navigate(['home']);
    }
  }
}
