import {
  animate, style, transition, trigger,
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip-table',
  templateUrl: './custom-tooltip-table.component.html',
  styleUrls: ['./custom-tooltip-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('customTooltipTable', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(600, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CustomTooltipTableComponent {
  @Input() title = '';
  @Input() subtitle = '';
}
