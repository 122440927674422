<div class="content-table">
  <table mat-table [dataSource]="dataSource" id="table">
    <ng-container *ngFor="let col of displayedDef; let indexCol = index">
      <ng-container [matColumnDef]="col.props">
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.border-horizontal]="col.styleBorderHorizontal"
          [ngStyle]="col.style"
          [class.border-sypply]="col.props === 'totalSupply'">
          <div [class.content-header]="col.props !== 'family'">
            <div
              [class.title]="col.firtsTitle"
              [class.padding-firt-title]="
                col.props !== 'bohTotal' && col.props !== 'totalSupply'
              ">
              <div
                *ngIf="col.secondColumn === false"
                class="cell-first-columns">
                {{ col.month }}
                <div [class.space-name-on-top-header-weekly]="col.month">
                  {{ col.title }}
                </div>
              </div>
              <div
                class="columns-wrap"
                *ngIf="col.secondColumn === true && col.firstIndex">
                <div class="name-on-top-header-montly">
                  {{ col.subtitle }}
                </div>
                <div>{{ col.title }}</div>
                <ng-container *ngIf="col.typeShowEvent">
                  <div
                    *ngIf="
                      col.props === col.otherContent &&
                      !col.hideIconExpand &&
                      col.secondColumn === true
                    "
                    class="cell-icon-arrow">
                    <mat-icon
                      *ngIf="!col.expanded"
                      (click)="toggleColumnTable(col, 'show')">
                      {{ 'arrow_left' }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="col.expanded"
                      (click)="toggleColumnTable(col, 'hide')">
                      {{ 'arrow_right' }}
                    </mat-icon>
                  </div>
                </ng-container>
              </div>

              <div
                class="cell-width-second-columns"
                *ngIf="
                  col.secondColumn === true &&
                  !col.firstIndex &&
                  !col.indexMonth
                ">
                <span></span>
                {{ col.title }}
                <ng-container *ngIf="col.typeShowEvent">
                  <div
                    *ngIf="
                      col.props === col.otherContent &&
                      !col.hideIconExpand &&
                      col.secondColumn === true
                    "
                    class="cell-icon-arrow">
                    <mat-icon
                      *ngIf="!col.expanded"
                      (click)="toggleColumnTable(col, 'show')">
                      {{ 'arrow_left' }}
                    </mat-icon>
                    <mat-icon
                      *ngIf="col.expanded"
                      (click)="toggleColumnTable(col, 'hide')">
                      {{ 'arrow_right' }}
                    </mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </th>

        <td
          mat-cell
          *matCellDef="let element; let indexRow = index"
          [class.bg-blue]="col.totalCommit || col.totalWow"
          [class.border-horizontal]="col.styleBorderHorizontal"
          [class.border-dashed]="col.firtsTitle && col.firstIndex"
          [class.notFamily]="col.props !== 'family'"
          (click)="expandedRow(indexRow, tableOrigin)"
          style="cursor: pointer">
          <app-value-currency
            [class.negative-value]="element[col.props]?.value < 0"
            *ngIf="col.props !== 'family'"
            [value]="
              element[col.props]?.value === '' ? '' : element[col.props]?.value
            ">
          </app-value-currency>
          <span *ngIf="col.props === 'family' && !element[col.props]?.color">
            {{
              element[col.props]?.value === '' ? '' : element[col.props]?.value
            }}
          </span>
          <div
            style="display: flex; justify-content: space-between"
            *ngIf="col.props === 'family' && element[col.props]?.color">
            <span>
              {{
                element[col.props]?.value === ''
                  ? ''
                  : element[col.props]?.value
              }}
            </span>
            <span
              id="{{ tableOrigin + '-' + tdName + indexRow + '-one' }}"
              class="show-icon">
              <mat-icon class="icon-color">expand_more</mat-icon>
            </span>
            <span
              id="{{ tableOrigin + '-' + tdName + indexRow + '-two' }}"
              class="hide-icon">
              <mat-icon class="icon-color">expand_less</mat-icon>
            </span>
          </div>
          <div class="content-one" [attr.name]="tdName + indexRow">
            <div
              class="color-family"
              *ngFor="let color of element[col.props]?.color">
              <span *ngIf="col.props === 'family'">
                {{ color.name }}
              </span>
              <app-value-currency
                *ngIf="col.props !== 'family'"
                [class.negative-value]="color.value < 0"
                [value]="color.value === '' ? '' : color.value">
              </app-value-currency>
            </div>
          </div>
        </td>
        <td
          [class.notFamily]="col.props !== 'family'"
          [class.border-horizontal]="col.styleBorderHorizontal"
          mat-cell
          *matCellDef="let element; let indexRow = index">
          <div>
            <div>
              <app-value-currency
                [value]="
                  element[col.props]?.value === ''
                    ? ''
                    : element[col.props]?.value
                "></app-value-currency>
            </div>
          </div>
        </td>

        <td
          mat-footer-cell
          *matFooterCellDef="let element; let indexRow = index"
          [class.border-left]="col.month && !col.firstWeek"
          [class.border-horizontal]="col.styleBorderHorizontal"
          [class.border-sypply]="col.props === 'totalSupply'">
          <span *ngIf="col.props === 'family'" class="table-row-total-spacer"
            >TOTAL</span
          >
          <span
            class="values-total"
            [class.negative-value]="col.totalAddedValue < 0">
            <app-value-currency
              *ngIf="
                col.props !== 'family' &&
                col.props !== 'origin' &&
                col.props !== 'wow' &&
                col.props !== 'commit'
              "
              [value]="
                col.totalAddedValue === '' ? '-' : col.totalAddedValue
              "></app-value-currency>
          </span>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="row-color"
      [class.brand]="i % 2 !== 0"
      *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    <tr
      mat-footer-row
      class="table-row-total"
      id="footer"
      *matFooterRowDef="displayedColumns; sticky: true"></tr>
  </table>
</div>
