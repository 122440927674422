export const KaInfoUploadTable: any = {
  table: {
    page: 0,
    rows: [
      {
        fileName: 'Example.File.Xls',
        user: 'Souza, Leandro',
        date: '05-18-2022',
        status: 'Sucess',
      },
    ],
    totalPages: 10,
    totalRows: 1,
  },
};
