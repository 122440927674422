<div>
  <form [formGroup]="form">
    <div *ngFor="let question of questions" class="form-row">
      <app-input [inputs]="question" [form]="form"></app-input>
    </div>
    <div class="form-row">
      <button type="submit" [disabled]="!form.valid">Save</button>
    </div>
  </form>
  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div>
</div>
