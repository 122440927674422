import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DcmRollover } from 'src/app/interfaces/dcm-rollover.interface';
import Pagination from 'src/app/interfaces/pagination.interface';
import { CrudService } from 'src/app/services/generic/crud.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})

export class NotificationsComponent implements OnInit {
  crudService: CrudService<any>;
  paginationNotifications: Pagination;
  asyncTabs!: any[];
  viewAllLength!: number;
  viewUnreadLength!: number;
  viewReadedLength!: number;
  viewArchivedLength!: number;

  dataSourceAllNotifications = new MatTableDataSource<DcmRollover>();
  dataSourceUnreadNotifications = new MatTableDataSource<DcmRollover>();
  dataSourceReadedNotifications = new MatTableDataSource<DcmRollover>();
  dataSourceArchivedNotifications = new MatTableDataSource<DcmRollover>();

  limitAllNotifications!: number;
  limitUnreadNotifications!: number;
  limitReadNotifications!: number;
  limitArchivedNotifications!: number;

  pageAllNotifications!: number;
  pageUnreadNotifications!: number;
  pageReadNotifications!: number;
  pageArchivedNotifications!: number;

  totalRowsAllNotifications!: number;
  totalRowsUnreadNotifications!: number;
  totalRowsReadNotifications!: number;
  totalRowsArchivedNotifications!: number;

  awaitingNotifications: boolean = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {
    this.crudService = new CrudService<any>(this.http, 'dcm');
    this.paginationNotifications = { page: 1, limit: 20};
  }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.spinner.show();
    this.awaitingNotifications = false;

    const promises = [
      this.getNotifications(this.paginationNotifications),
      this.getUnreadNotifications(this.paginationNotifications),
      this.getReadNotifications(this.paginationNotifications),
      this.getArchivedNotifications(this.paginationNotifications),
    ];

    this.hideSpinnerAfterAll(promises);
  }

  updateTableNotifications(update: boolean) {
    if (update === true) {
      this.loadData();
    }
  }

  async getNotifications(page: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`dcmViewAllNotifications`, { limit: page.limit, page: page.page }).subscribe((response: any) => {
        this.viewAllLength = response.notifications.length;
        this.dataSourceAllNotifications.data = response.notifications;
        this.pageAllNotifications = response.page;
        this.totalRowsAllNotifications = response.totalRows;
        this.limitAllNotifications = response.limit;

        if (this.dataSourceAllNotifications.filteredData.length === 0) {
          this.dataSourceAllNotifications.filteredData = [{message: 'Not Record Notifications' }];
        }

        this.tabs()

        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getUnreadNotifications(page: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`unread-notifications`, { limit: page.limit, page: page.page }).subscribe((response: any) => {
        this.viewUnreadLength = response.notifications.length;
        this.dataSourceUnreadNotifications.data = response.notifications;
        this.pageUnreadNotifications = response.page
        this.totalRowsUnreadNotifications = response.totalRows
        this.limitUnreadNotifications = response.limit;
  
        if (this.dataSourceUnreadNotifications.filteredData.length === 0) {
          this.dataSourceUnreadNotifications.filteredData = [{message: 'Not Unread Notifications' }];
        }

        this.tabs()

        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getReadNotifications(page: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`read-notifications`, { limit: page.limit, page: page.page }).subscribe((response: any) => {
        this.viewReadedLength = response.notifications.length;
        this.dataSourceReadedNotifications.data = response.notifications;
        this.pageReadNotifications = response.page
        this.totalRowsReadNotifications = response.totalRows
        this.limitReadNotifications = response.limit;
  
        if (this.dataSourceReadedNotifications.filteredData.length === 0) {
          this.dataSourceReadedNotifications.filteredData = [{message: 'Not Read Notifications' }];
        }

        this.tabs()

        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async getArchivedNotifications(page: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.crudService.getEntity(`archived-notifications`, { limit: page.limit, page: page.page }).subscribe((response: any) => {
        this.viewArchivedLength = response.notifications.length;
        this.dataSourceArchivedNotifications.data = response.notifications;
        this.pageArchivedNotifications = response.page
        this.totalRowsArchivedNotifications = response.totalRows
        this.limitArchivedNotifications = response.limit;
  
        if (this.dataSourceArchivedNotifications.filteredData.length === 0) {
          this.dataSourceArchivedNotifications.filteredData = [{message: 'Not Archived Notifications' }];
        }

        this.tabs()

        resolve();
      },  
      (err: any) => {
        this.toastr.error(err.error.message, 'Error!');
        this.spinner.hide();
        reject();
      })
    })
  }

  async tabs() {
    // fazer o loop e adicionar de forma dinamica
    return (this.asyncTabs = [
      {
        label: 'Unread',
        view: this.viewUnreadLength,
        viewNot: 'Not Unread Notifications',
        dataSource: this.dataSourceUnreadNotifications,
        page: this.pageUnreadNotifications,
        totalRows: this.totalRowsUnreadNotifications,
        limit: this.limitUnreadNotifications
      },
      {
        label: 'Readed',
        view: this.viewReadedLength,
        viewNot: 'Not Readed Notifications',
        dataSource: this.dataSourceReadedNotifications,
        page: this.pageReadNotifications,
        totalRows: this.totalRowsReadNotifications,
        limit: this.limitReadNotifications
      },
      {
        label: 'Archived',
        view: this.viewArchivedLength,
        viewNot: 'Not Archived Notifications',
        dataSource: this.dataSourceArchivedNotifications,
        page: this.pageArchivedNotifications,
        totalRows: this.totalRowsArchivedNotifications,
        limit: this.limitArchivedNotifications
      },
      {
        label: 'View All',
        view: this.viewAllLength,
        viewNot: 'Not Record Found',
        dataSource: this.dataSourceAllNotifications,
        page: this.pageAllNotifications,
        totalRows: this.totalRowsAllNotifications,
        limit: this.limitAllNotifications
      },
    ]);
  }

  async hideSpinnerAfterAll(promises: any): Promise<any> {
    await Promise.all(promises).then(() => {
      this.awaitingNotifications = true;
      this.spinner.hide();
    });
  }

  onChangePaginator(paginated: any, tab: any) {
    if (tab.label === 'Unread') {
      // função para a paginação do unread
      this.spinner.show()
      const promises = [
      this.getUnreadNotifications(paginated),
      ];
      this.hideSpinnerAfterAll(promises);
    }

    if (tab.label === 'Readed') {
      // função para a paginação do read
      this.spinner.show()
      const promises = [
      this.getReadNotifications(paginated),
      ];
      this.hideSpinnerAfterAll(promises);
    }

    if (tab.label === 'Archived') {
      // função para a paginação do archived
      this.spinner.show()
      const promises = [
      this.getArchivedNotifications(paginated),
      ];
      this.hideSpinnerAfterAll(promises);
    }

    if (tab.label === 'View All') {
      // função para a paginação do all
      this.spinner.show()
      const promises = [
      this.getNotifications(paginated),
      ];
      this.hideSpinnerAfterAll(promises);
    }
  }

  backButton() {
    this.router.navigate(['home/dcm/dashboards']);
  }
}
