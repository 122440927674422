<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">USER</span>
    <button
      *ngIf="hasPermissionCreate"
      class="button modal-action-button"
      mat-button
      (click)="prepareToCreate()">
      NEW USER
    </button>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"></app-search>
  <span class="line"></span>
  <app-results
    class="table-user"
    [data]="result"
    (eventActions)="onEventActions($event)"
    (resultPaginator)="onChangePaginator($event)"></app-results>
  <button class="button back-action-button" mat-button (click)="backButton()">
    BACK
  </button>
</div>

<ng-template #modalCreateEdit class="custom-dialog-container">
  <form [formGroup]="userForm" class="form-user">
    <header class="title-modal" matDialogTitle>
      {{ isEdit ? 'EDIT USER' : 'NEW USER' }}
    </header>

    <mat-dialog-content>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="last_name"
          autocomplete="off"
          maxlength="50"
          appInputAlphanumeric />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          autocomplete="off"
          maxlength="50"
          appInputAlphanumeric />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input
          matInput
          formControlName="email"
          autocomplete="off"
          maxlength="50" />
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-content class="mat-content">
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <mat-icon class="material-icons align-arrow-right"
          >arrow_drop_down</mat-icon
        >
        <input
          type="text"
          formControlName="country_id"
          matInput
          [matAutocomplete]="countryAutocomplete" />
        <mat-autocomplete
          #countryAutocomplete="matAutocomplete"
          autoActiveFirstOption
          disableOptionCentering
          panelClass="panel-select-channel">
          <mat-option
            *ngFor="let country of filteredOptionsUser | async"
            [value]="country.country"
            matTooltip="{{ country.country }}">
            {{ country.country }}
          </mat-option>
        </mat-autocomplete>
        <button
          *ngIf="userForm.controls['country_id'].value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="userForm.controls['country_id'].reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select
          formControlName="status"
          name="status"
          disableOptionCentering
          panelClass="panel-select-user">
          <mat-option
            *ngFor="let status of statusEntity"
            [value]="status.value">
            {{ status.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Profile</mat-label>
        <mat-icon class="material-icons align-arrow-right"
          >arrow_drop_down</mat-icon
        >
        <input
          type="text"
          formControlName="profile_id"
          matInput
          [matAutocomplete]="profileAutocomplete" />
        <mat-autocomplete
          #profileAutocomplete="matAutocomplete"
          autoActiveFirstOption
          disableOptionCentering
          panelClass="panel-select-channel">
          <mat-option
            *ngFor="let profile of filteredOptionsProfile | async"
            [value]="profile.profile"
            matTooltip="{{ profile.profile }}">
            {{ profile.profile }}
          </mat-option>
        </mat-autocomplete>
        <button
          *ngIf="userForm.controls['profile_id'].value"
          matSuffix
          mat-icon-button
          type="button"
          aria-label="Clear"
          (click)="userForm.controls['profile_id'].reset()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-content class="mat-content-sales-order">
      <mat-form-field appearance="outline" class="sales-order">
        <mat-label>Sales manager?</mat-label>
        <mat-select
          formControlName="sales_manager"
          name="sales_manager"
          disableOptionCentering
          panelClass="panel-select-user"
          [(value)]="selected_sales_manager"
          (selectionChange)="updateSelectFields('sales_manager')">
          <mat-option
            *ngFor="let salesManager of salesManagerEntity"
            [value]="salesManager.value">
            {{ salesManager.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="sales-order">
        <mat-label>Order admin?</mat-label>
        <mat-select
          formControlName="order_admin"
          name="order_admin"
          disableOptionCentering
          panelClass="panel-select-user"
          [(value)]="selected_order_admin"
          (selectionChange)="updateSelectFields('order_admin')">
          <mat-option
            *ngFor="let orderAdmin of orderAdminEntity"
            [value]="orderAdmin.value">
            {{ orderAdmin.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button class="button modal-cancel-button" mat-button matDialogClose>
        CANCEL
      </button>
      <button
        *ngIf="!isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onCreateUser()">
        SAVE
      </button>
      <button
        *ngIf="isEdit"
        class="button modal-action-button"
        mat-button
        (click)="onUpdateUser()">
        SAVE
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #dialogConfirmation>
  <header class="title-modal" matDialogTitle>CONFIRMATION</header>

  <mat-dialog-content>
    {{
      isEdit ? 'Do you confirm to edit User?' : 'Do you confirm to remove User?'
    }}
  </mat-dialog-content>

  <mat-dialog-actions>
    <button class="button modal-cancel-button" mat-button matDialogClose>
      NO
    </button>
    <button
      class="button modal-action-button"
      mat-button
      matDialogClose="false">
      YES
    </button>
  </mat-dialog-actions>
</ng-template>
