import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lom',
  templateUrl: './lom.component.html',
  styleUrls: ['./lom.component.scss'],
})
export class LomComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
