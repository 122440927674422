<div>
  <div class="card-full">
    <ng-container *ngFor="let cardData of cardDataArr">
      <mat-card class="example-card mat-elevation-z4">
        <mat-card-title>{{ cardData.title }}</mat-card-title>
        <div class="card-container">
          <ng-container
            *ngFor="let subtitle of cardData.subtitles; let i = index">
            <div class="subtitles-container">
              <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
              <div class="card-value-container">
                <p [class.negative-value]="cardData.values[i] < 0">
                  {{ cardData.values[i] | number }}
                </p>
              </div>
            </div>
            <mat-divider
              vertical
              class="mat-divider-vertical"
              *ngIf="i !== cardData.subtitles.length - 1"></mat-divider>
          </ng-container>
        </div>
        <mat-card-footer
          [ngStyle]="{
            'border-bottom': '7px solid ' + cardData.color
          }"></mat-card-footer>
      </mat-card>
    </ng-container>
  </div>
</div>
