import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-table-columns-tabs',
  templateUrl: './table-columns-tabs.component.html',
  styleUrls: ['./table-columns-tabs.component.scss'],
})
export class TableColumnsTabsComponent implements OnInit {
  @Input() public showMenuColumns: boolean = true;
  @Input() public visible: any = 0;
  @Input() public tab: any = 0;
  @Input() public thead: any = 0;
  @Input() public data: any = 0;
  @Output() changeTab = new EventEmitter<any>();

  public showColumns: any[] = [];
  public theadSelected: any[] = [];
  public showSubMenuColumns: boolean = false;

  public hideColumns: any[] = [];

  public showTables: any[] = [];

  constructor(private toastr: ToastrService) {
    document.addEventListener('click', function (event: any) {}, false);
  }

  @HostListener('document:click', ['$event']) documentClickEvent(
    event: MouseEvent
  ) {
    const target: any = event.target;
    if (!target.closest('#showColumns')) {
      this.showSubMenuColumns = false;
    }
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.thead) {
      this.thead = changes.thead.currentValue;
      this.theadSelected = changes.thead.currentValue.filter(
        (obj: any) => obj.label === this.tab
      )[0].thead;
      this.theadSelected.map((row: any, key: number) => {
        this.showTables[key] = this.getVisibleTable(row);
      });
    }

    if (changes.tab) {
      this.theadSelected = this.thead.filter(
        (obj: any) => obj.label === changes.tab.currentValue
      )[0].thead;
      const columns: any[] = [];
      this.theadSelected.map((tables: any, key: any) => {
        tables.map((row: any) => {
          if (!row.parent) {
            columns.push({
              label: row.label,
              key: row.key,
              submenu:
                tables.filter((obj: any) => obj.parent === row.label).length > 0
                  ? row.label + ' Details'
                  : false,
              children: tables.filter((obj: any) => obj.parent === row.label),
            });
          }
        });
      });
      this.showColumns = columns;
    }
    if (changes.data) {
      this.data = changes.data.currentValue;
    }
  }

  onChangeTab(tab: any) {
    this.changeTab.emit(tab);
    this.clearColumnsTab();
  }

  clearColumnsTab() {
    this.hideColumns = [];
    this.showSubMenuColumns = false;
  }

  getCountSubtotal(data: any, key: any) {
    let total: any = 0;
    data.docs.map((row: any) => {
      total = total + parseFloat(row[key]);
    });
    if (key.includes('linePercentage')) {
      return `${total}%`;
    }
    return total || total === 0 ? total : '';
  }

  getCount(data: any, key: any) {
    if (data !== undefined) {
      if (key.includes('WK') && data.length > 0) {
        return parseFloat(data);
      }
    }
    return data;
  }

  checkWeek(key: any) {
    return key.includes('WK') || key.includes('linePercentage');
  }

  // subtotal geral JAG + MAN
  getTotalOfSubtotals(key: any) {
    let totalGeneral: any = 0;
    let totalPercentage: any = 0;
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].docs.map((row: any) => {
        if (key.includes('WK')) {
          totalGeneral += parseFloat(row[key]);
        }
        totalPercentage += parseFloat(row[key]);
      });
    }
    if (key.includes('linePercentage')) {
      return `${totalPercentage}%`;
    }
    return totalGeneral ? totalGeneral : '';
  }

  toggleMenuColumns(menu: boolean) {
    this.showSubMenuColumns = !menu;
  }

  togleHideColumns(menu: any, children: any[]) {
    try {
      if (this.hideColumns.filter((obj: any) => obj === menu).length > 0) {
        if (children.length > 0) {
          this.hideColumns = this.hideColumns.filter(
            (obj: any) => obj !== menu && obj !== menu + ' Details'
          );
          children.map((children: any) => {
            this.hideColumns = this.hideColumns.filter(
              (obj: any) => obj !== children.key
            );
          });
        } else {
          this.hideColumns = this.hideColumns.filter(
            (obj: any) => obj !== menu
          );
        }
      } else {
        const validator: any[] = [];
        this.showColumns.map((row: any, key: number) => {
          validator.push(this.getValidatorHideColumn(row.key));
        });
        if (
          validator.filter((obj: any) => obj === true).length + 1 >=
          this.showColumns.length
        )
          throw 'You must have at least one column.';
        if (children.length > 0) {
          this.hideColumns.push(menu);
          this.hideColumns.push(menu + ' Details');
          children.map((children: any) => {
            this.hideColumns.push(children.key);
          });
        } else {
          this.hideColumns.push(menu);
        }
      }

      this.theadSelected.map((row: any, key: number) => {
        this.showTables[key] = this.getVisibleTable(row);
      });
    } catch (err: any) {
      this.toastr.error(err);
    }
  }

  getVisibleTable(table: any) {
    const totalHiddens: any[] = [];
    table.map((row: any, key: number) => {
      this.hideColumns.filter((obj: any) => obj === row.key).length
        ? totalHiddens.push(true)
        : totalHiddens.push(false);
    });
    if (
      totalHiddens.filter((obj: any) => obj === true).length === table.length
    ) {
      return false;
    } else {
      return true;
    }
  }

  getValidatorHideColumn(menu: any) {
    let hasOpen: Boolean = false;
    if (this.hideColumns.filter((obj: any) => obj === menu).length > 0) {
      hasOpen = true;
    } else {
      hasOpen = false;
    }
    return hasOpen;
  }

  getAddRowId(indexRow: number, origin: string) {
    if (origin) {
      return indexRow + origin;
    }
    return indexRow;
  }

  mouseEnter(indexRow: number, origin: any) {
    const tableRows = document.querySelectorAll('.content-tables .body');
    const rowId = indexRow + origin;

    if (origin) {
      tableRows.forEach((row: any) => {
        if (row.id == rowId) {
          row.classList.add('hover-row');
        }
      });
    } else {
      tableRows.forEach((row: any) => {
        if (row.id == indexRow) {
          row.classList.add('hover-row');
        }
      });
    }
  }

  mouseLeave(indexRow: number, origin: any) {
    const tableRows = document.querySelectorAll('.content-tables .body');
    const rowId = indexRow + origin;

    if (origin) {
      tableRows.forEach((row: any) => {
        if (row.id == rowId) {
          row.classList.remove('hover-row');
        }
      });
    } else {
      tableRows.forEach((row: any) => {
        if (row.id == indexRow) {
          row.classList.remove('hover-row');
        }
      });
    }
  }
}
