<div class="overflow-table">
  <ng-container>
    <table>
      <thead>
        <tr>
          <th *ngFor="let th of tableHead">
            <div>
              <span>{{ th.title }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let row of tableBody">
        <tr *ngFor="let jag of row.jag" class="tr-hover">
          <td *ngFor="let head of tableHead">
            <span *ngIf="checkPerc(head.key)">{{ jag[head.props] }}%</span>
            <span *ngIf="jag[head.props] >= 0 && !checkPerc(head.key)"
              ><app-value-currency
                [value]="jag[head.props]"></app-value-currency>
            </span>
            <span *ngIf="jag[head.props] < 0" class="negative">
              <app-value-currency [value]="jag[head.props]"></app-value-currency
            ></span>
            <span *ngIf="checkString(jag[head.props])">{{
              jag[head.props]
            }}</span>
          </td>
        </tr>
        <tr *ngFor="let jag of row.sub_jag">
          <td *ngFor="let head of tableHead" class="subtotal">
            <span *ngIf="checkPerc(head.key)">{{ jag[head.props] }}%</span>
            <span *ngIf="jag[head.props] >= 0 && !checkPerc(head.key)">
              <app-value-currency
                [value]="jag[head.props]"></app-value-currency>
            </span>
            <span *ngIf="jag[head.props] < 0" class="negative">
              <app-value-currency [value]="jag[head.props]"></app-value-currency
            ></span>
            <span *ngIf="checkString(jag[head.props])" class="bold">{{
              jag[head.props]
            }}</span>
          </td>
        </tr>
        <tr *ngFor="let man of row.man" class="tr-hover">
          <td *ngFor="let head of tableHead">
            <span *ngIf="checkPerc(head.key)">{{ man[head.props] }}%</span>
            <span *ngIf="man[head.props] >= 0 && !checkPerc(head.key)">
              <app-value-currency
                [value]="man[head.props]"></app-value-currency>
            </span>
            <span *ngIf="man[head.props] < 0" class="negative">
              <app-value-currency [value]="man[head.props]"></app-value-currency
            ></span>
            <span *ngIf="checkString(man[head.props])">{{
              man[head.props]
            }}</span>
          </td>
        </tr>
        <tr *ngFor="let man of row.sub_man">
          <td *ngFor="let head of tableHead" class="subtotal">
            <span *ngIf="checkPerc(head.key)">{{ man[head.props] }}%</span>
            <span *ngIf="man[head.props] >= 0 && !checkPerc(head.key)">
              <app-value-currency
                [value]="man[head.props]"></app-value-currency>
            </span>
            <span *ngIf="man[head.props] < 0" class="negative">
              <app-value-currency
                [value]="man[head.props]"></app-value-currency>
            </span>
            <span *ngIf="checkString(man[head.props])" class="bold">{{
              man[head.props]
            }}</span>
          </td>
        </tr>
        <tr *ngFor="let total of row.total">
          <td *ngFor="let head of tableHead" class="total">
            <span *ngIf="checkPerc(head.key)">{{ total[head.props] }}%</span>
            <span *ngIf="total[head.props] >= 0 && !checkPerc(head.key)">
              <app-value-currency
                [value]="total[head.props]"></app-value-currency>
            </span>
            <span *ngIf="total[head.props] < 0" class="negative" currencyMask>
              <app-value-currency
                [value]="total[head.props]"></app-value-currency>
            </span>
            <span *ngIf="checkString(total[head.props])" class="bold">{{
              total[head.props]
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
