<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">FISCAL CALENDAR</span>
    <div class="container-export-fiscal-calendar">
      <span
        class="material-icons"
        (click)="exportTable()"
        matTooltip="{{ tooltipMessage }}">
        <button
          class="export-button"
          [class.disabled]="!isValid"
          [disabled]="!isValid"></button>
      </span>
    </div>
  </div>
  <app-search
    (searchEvent)="onSearchEvent($event)"
    [filters]="searchInputs"
    [fullWidthRow]="fullWidthRow"></app-search>
  <span class="line"></span>
  <div class="container-fiscal-calendar" *ngIf="!showNoResults">
    <h1 class="quarter-value">{{ quarterValue }}</h1>
    <app-results
      [data]="result"
      [isModeTableOrCardsHide]="isModeTableOrCardsHide"
      [hidePagination]="hidePagination"></app-results>
  </div>
  <div class="no-filter-selected-session" *ngIf="showNoResults">
    <p>NO RECORDS FOUND.</p>
  </div>
  <button
    class="back-action-button margin-top-30"
    mat-button
    (click)="backButton()">
    BACK
  </button>
</div>
