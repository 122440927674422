<div class="header-table-card">
  <ng-container *ngFor="let col of headersDisplayDef; let indexCol = index">
    <div class="header-card" [ngStyle]="col.style">
      <mat-card [class.card-column-expandable]="col.expanded"
        [class.card-main-column-expandable]="col.props === col.otherContent"
        [class.first-card-column-expandable]="col.isFirstCard" [class.last-card-column-expandable]="col.isLastCard"
        [class.card-column-disable]="!col.enableContent"
        [class.card-column-actual-week]="col.weekInTime === 'current' || col.currentMonth"
        [class.card-white]="col.introConclusionHeader">
        <div>
          <span>{{ col.title }}</span>
        </div>
        <div *ngIf="col.props === col.otherContent && !col.hideIconExpand">
          <mat-icon *ngIf="!col.expanded" (click)="toggleColumnTable(col, 'show')">
            {{ 'arrow_left' }}
          </mat-icon>
          <mat-icon *ngIf="col.expanded" (click)="toggleColumnTable(col, 'hide')">
            {{ 'arrow_right' }}
          </mat-icon>
        </div>
      </mat-card>
    </div>
  </ng-container>
</div>



<div *ngFor="let data of dataSourceTableManager; index as i" class="grid-table">
  <app-table-expandable #tableExpandable [filterKaRetail]="filterKaRetail" [dataTable]="data.expandedTableResult" [allocationForm]="allocationForms[i]"
    [indexGroup]="i" [hideHeader]="true" [displayedColumns]="displayedColumns">
  </app-table-expandable>
  <!-- <app-simple-table #simpleTable [dataTable]="data.simpleTableResult" [indexGroup]="i" [hideHeader]="true">
  </app-simple-table> -->
  <p>SUBTOTAL {{ i }}</p>
</div>
