import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { BreadcrumbModule } from 'xng-breadcrumb';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from 'angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './modules/home.module';
import { ShareComponentsModule } from './components/share.components.module';
import { AuthorizationModule } from './modules/authorization/authorization.module';
import { environment } from '../environments/environment';
import { AuthService } from './services/authorization/auth.service';
import { TokenInterceptorService } from './services/authorization/interceptor/token.interceptor.service';
import { UnauthorizedModule } from './modules/authorization/unauthorized/unauthorized.module';
import { PermissionService } from './services/authorization/permission.service';
import { SharedinfoService } from './services/generic/sharedinfo.service';
import { NgxEchartsModule } from 'ngx-echarts';
import { ModalService } from './services/generic/modal-status.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [AppComponent],
  exports: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HomeModule,
    BreadcrumbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ShareComponentsModule,
    AuthorizationModule,
    SocialLoginModule,
    UnauthorizedModule,
    CurrencyMaskModule,
    NgIdleKeepaliveModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    AuthService,
    PermissionService,
    SharedinfoService,
    ModalService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.gapiClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
