import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'app-table-overbooked',
  templateUrl: './table-overbooked.component.html',
  styleUrls: ['./table-overbooked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableOverbookedComponent implements OnInit {
  @ViewChild('overflowTable') overflowTable!: ElementRef<HTMLInputElement>;
  @Input() dataTable: any = {};

  tableHead: Array<string | number | any>;
  tableBody: Array<string | number | any>;
  tableTotal: Array<string | number | any>;

  offsetTopSubTotal: number;
  translateYAbsoluteItem: number;

  constructor() {
    this.tableHead = [];
    this.tableBody = [];
    this.tableTotal = [];

    this.offsetTopSubTotal = 0;
    this.translateYAbsoluteItem = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataTable.currentValue) {
      this.dataTable = changes.dataTable.currentValue;
      this.loadDataTable();
    }
  }

  ngOnInit(): void {}

  loadDataTable() {
    this.tableHead = [];
    this.tableBody = [];
    this.tableTotal = [];

    if (this.dataTable.headers) {
      this.dataTable.headers.forEach((value: any) => {
        this.tableHead.push(value);
      });
    }
    if (this.dataTable.rows) {
      this.dataTable.rows.forEach((value: any) => {
        this.tableBody.push(value);
      });
    }
    if (this.dataTable.total) {
      this.dataTable.total.forEach((value: any) => {
        this.tableTotal.push(value);
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0].clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const headerTitle =
      document.getElementsByClassName('header-title')[0].clientHeight;
    const matRipple =
      document.getElementsByClassName('mat-tab-labels')[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight + headerTitle + matRipple;
    const appFooterSubTotal = document.getElementById('subtotal1')?.offsetTop;
    this.offsetTopSubTotal = appFooterSubTotal || 0;
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const offsetTopElement =
      this.overflowTable.nativeElement.offsetTop + clientHeightTop;
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement - 27;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  isNumber(row: any) {
    if (!isNaN(row)) {
      return true;
    } else {
      return false;
    }
  }

  isSubtotal(row: any) {
    if (row.orderAdmin == 'SUBTOTAL') {
      return true;
    } else if (row.salesManager === 'SUBTOTAL') {
      return true;
    } else if (row.customerShort === 'SUBTOTAL') {
      return true;
    } else if (row.family === 'SUBTOTAL') {
      return true;
    } else {
      return false;
    }
  }
}
