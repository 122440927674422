import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { MatTableModule } from '@angular/material/table';
import { CustomerAllocationRetailComponent } from './customer-allocation-retail.component';
import { AllocationRetailComponent } from './allocation-retail/allocation-retail.component';
import { PpmComponent } from './allocation-retail/ppm/ppm.component';
import { MissingComponent } from './missing/missing.component';
import { ToBookComponent } from './to-book/to-book.component';
import { OverbookedComponent } from './overbooked/overbooked.component';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { FamilyNameComponent } from './allocation-retail/family-name/family-name.component';
import { MknToBookComponent } from './allocation-retail/family-name/mkn-to-book/mkn-to-book.component';
import { MknSellInComponent } from './allocation-retail/family-name/mkn-sell-in/mkn-sell-in.component';
import { MknBookedComponent } from './allocation-retail/family-name/mkn-booked/mkn-booked.component';

@NgModule({
  declarations: [
    CustomerAllocationRetailComponent,
    AllocationRetailComponent,
    PpmComponent,
    MissingComponent,
    ToBookComponent,
    OverbookedComponent,
    CustomerViewComponent,
    FamilyNameComponent,
    MknToBookComponent,
    MknSellInComponent,
    MknBookedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ShareDirectiviesModule,
    ShareComponentsModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
})
export class CustomerAllocationRetailModule {}
