import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LomComponent } from './lom.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LomComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    ShareComponentsModule,
  ],
  providers: [],
  bootstrap: [],
})
export class LomModule {}
