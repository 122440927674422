<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="line-title-btn">
    <span class="title">MISSING</span>
    <span class="material-icons" (click)="exportTable()">
      <button
        class="export-button"
        [class.disabled]="!isValid"
        [disabled]="!isValid"
        matTooltip="{{ tooltipMessage }}"></button>
    </span>
  </div>
  <div class="missing-content">
    <mat-tab-group
      (click)="tabClick($event)"
      [selectedIndex]="selectedTabIndex">
      <mat-tab label="ORDER ADMIN">
        <app-search
          (searchEvent)="onSearchEvent($event, 'orderAdmin')"
          [filters]="searchInputsOrderAdmin"
          (applyFilterEvent)="applyFilter('orderAdmin')"
          [showButtonApply]="true"
          [enableApplyFilter]="enableApplyFilter"
          [showMoreFiltersLink]="true"
          [showMoreFiltersLinkWithMinWidth]="true"
          [ngClass]="'missing'">
        </app-search>
        <mat-divider></mat-divider>
        <section class="header-table-allocation">
          <div class="title-section">
            <h3 class="t-section">Order Admin</h3>
            <h4 class="st-section"></h4>
          </div>
        </section>
        <div class="no-filter-selected-session" *ngIf="orderAdminSelected">
          <p>PLEASE,<br />START BY FILTERING ORDER ADMIN FIRST</p>
        </div>
        <div class="no-filter-selected-session" *ngIf="noResultOrderAdmin">
          <p>NO RECORDS FOUND.</p>
        </div>
        <app-table-missing
          [dataTable]="dataOrderAdmin"
          *ngIf="!noResultOrderAdmin">
        </app-table-missing>
      </mat-tab>

      <mat-tab label="SALES MANAGER">
        <app-search
          (searchEvent)="onSearchEvent($event, 'salesManager')"
          [filters]="searchInputsSalesManager"
          (applyFilterEvent)="applyFilter('salesManager')"
          [showButtonApply]="true"
          [enableApplyFilter]="enableApplyFilter"
          [showMoreFiltersLink]="true"
          [showMoreFiltersLinkWithMinWidth]="true"
          [ngClass]="'missing'">
        </app-search>
        <mat-divider></mat-divider>
        <section class="header-table-allocation">
          <div class="title-section">
            <h3 class="t-section">Sales Manager</h3>
            <h4 class="st-section"></h4>
          </div>
        </section>
        <div class="no-filter-selected-session" *ngIf="salesManagerSelected">
          <p>PLEASE,<br />START BY FILTERING SALES MANAGER</p>
        </div>
        <div class="no-filter-selected-session" *ngIf="noResultSalesManager">
          <p>NO RECORDS FOUND.</p>
        </div>
        <app-table-missing
          [dataTable]="dataSalesManager"
          *ngIf="!noResultSalesManager">
        </app-table-missing>
      </mat-tab>

      <mat-tab label="CUSTOMER">
        <app-search
          (searchEvent)="onSearchEvent($event, 'customerShort')"
          [filters]="searchInputsCustomer"
          (applyFilterEvent)="applyFilter('customerShort')"
          [showButtonApply]="true"
          [enableApplyFilter]="enableApplyFilter"
          [showMoreFiltersLink]="true"
          [showMoreFiltersLinkWithMinWidth]="true"
          [ngClass]="'missing'">
        </app-search>
        <mat-divider></mat-divider>
        <section class="header-table-allocation">
          <div class="title-section">
            <h3 class="t-section">Customer</h3>
            <h4 class="st-section"></h4>
          </div>
        </section>
        <div class="no-filter-selected-session" *ngIf="customerSelected">
          <p>PLEASE,<br />START BY FILTERING CUSTOMER</p>
        </div>
        <div class="no-filter-selected-session" *ngIf="noResultCustomer">
          <p>NO RECORDS FOUND.</p>
        </div>
        <app-table-missing [dataTable]="dataCustomer" *ngIf="!noResultCustomer">
        </app-table-missing>
      </mat-tab>

      <mat-tab label="PRODUCT">
        <app-search
          (searchEvent)="onSearchEvent($event, 'ppm')"
          [filters]="searchInputsProduct"
          (applyFilterEvent)="applyFilter('ppm')"
          [showButtonApply]="true"
          [enableApplyFilter]="enableApplyFilter"
          [showMoreFiltersLink]="true"
          [showMoreFiltersLinkWithMinWidth]="true"
          [ngClass]="'missing'">
        </app-search>
        <mat-divider></mat-divider>
        <section class="header-table-allocation">
          <div class="title-section">
            <h3 class="t-section">Product</h3>
            <h4 class="st-section"></h4>
          </div>
        </section>
        <div class="no-filter-selected-session" *ngIf="productSelected">
          <p>PLEASE,<br />START BY FILTERING PRODUCT</p>
        </div>
        <div class="no-filter-selected-session" *ngIf="noResultProduct">
          <p>NO RECORDS FOUND.</p>
        </div>
        <app-table-missing [dataTable]="dataProduct" *ngIf="!noResultProduct">
        </app-table-missing>
      </mat-tab>
    </mat-tab-group>

    <button (click)="backButton()" class="button back-action-button" mat-button>
      BACK
    </button>
  </div>
</div>
