<ngx-spinner></ngx-spinner>
<div class="container">
  <div class="title">
    <h1>Home</h1>
    <div *ngIf="showNotifications" class="notifications">
      <button (click)="handleOpenNotification()">
        <b>Notifications</b>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 1024 1024">
          <path
            fill="currentColor"
            d="M512 64a64 64 0 0 1 64 64v64H448v-64a64 64 0 0 1 64-64z" />
          <path
            fill="currentColor"
            d="M256 768h512V448a256 256 0 1 0-512 0v320zm256-640a320 320 0 0 1 320 320v384H192V448a320 320 0 0 1 320-320z" />
          <path
            fill="currentColor"
            d="M96 768h832q32 0 32 32t-32 32H96q-32 0-32-32t32-32zm352 128h128a64 64 0 0 1-128 0z" />
        </svg>
        <span *ngIf="notificationsLength > 0">{{ notificationsLength }}</span>
      </button>
      <ul [class.active]="notificationsVisibile">
        <div class="scroll">
          <li *ngIf="notificationsLength === 0">
            <span class="not-found">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="-2 -3 24 24">
                <path
                  fill="currentColor"
                  d="m12.8 1.613l6.701 11.161c.963 1.603.49 3.712-1.057 4.71a3.213 3.213 0 0 1-1.743.516H3.298C1.477 18 0 16.47 0 14.581c0-.639.173-1.264.498-1.807L7.2 1.613C8.162.01 10.196-.481 11.743.517c.428.276.79.651 1.057 1.096zm-2.22.839a1.077 1.077 0 0 0-1.514.365L2.365 13.98a1.17 1.17 0 0 0-.166.602c0 .63.492 1.14 1.1 1.14H16.7c.206 0 .407-.06.581-.172a1.164 1.164 0 0 0 .353-1.57L10.933 2.817a1.12 1.12 0 0 0-.352-.365zM10 14a1 1 0 1 1 0-2a1 1 0 0 1 0 2zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1z" />
              </svg>
              No notifications found.
            </span>
          </li>
          <li class="new" *ngFor="let row of notifications">
            <a (click)="handleVisibleNotification(row)">
              <span class="message">{{ row.description }}</span>
              <span class="date">{{ row.createdAt }}</span>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
  <div class="container-menu">
    <nav-card [itemsList]="itemList"></nav-card>
  </div>
</div>
