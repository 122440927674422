<div #tableBody class="table-body">
  <div *ngIf="viewMonth !== true">
    <h3 class="title-table">{{ title }}</h3>
  </div>

  <div *ngIf="viewMonth === true" class="title-tab">
    <h3 class="title-table">{{ title }}</h3>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container *ngFor="let colDef of columnsLabel; let i = index">
      <ng-container matColumnDef="{{ colDef.label }}">
        <th mat-header-cell *matHeaderCellDef>
          <span
            *ngIf="colDef.tooltipInfo"
            customTooltipTable=""
            customTooltipTableSubtitle="{{ colDef.tooltipInfo?.subtitle }}">
            {{ changeWeek(colDef.entered ? colDef.entered : colDef.label) }}
          </span>
          <span *ngIf="!colDef.tooltipInfo && !colDef.setup">
            {{ changeWeek(colDef.entered ? colDef.entered : colDef.label) }}
          </span>

          <span *ngIf="!colDef.tooltipInfo && colDef.setup">
            {{ colDef.label }}
          </span>
        </th>

        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [ngStyle]="colDef.style"
          [class.bg-blue]="isCurrentTwo(colDef)"
          [class.bg-active]="colDef.currentWeek === 'isCurrent'">
          <label
            *ngIf="hasStatus"
            [class.status-disable]="
              element['active'] === 'Inactive' || !element['active']
            ">
            {{ colDef.prop ? element[colDef.prop] : element[colDef.label] }}
          </label>

          <label
            *ngIf="!hasStatus && isNumber(element[colDef.label])"
            [class.text-green]="element[colDef.label] > 0"
            [class.text-red]="element[colDef.label] < 0">
            {{ element[colDef.label] !== 0 ? element[colDef.label] : '' }}
          </label>

          <label
            style="font-weight: bold"
            *ngIf="
              !hasStatus &&
              !isNumber(element[colDef.label]) &&
              !isBoolean(element[colDef.label]) &&
              !isStatus(element[colDef.label])
            ">
            {{ element[colDef.label] }}
          </label>

          <img
            *ngIf="isTrue(element[colDef.label])"
            class="icon-check"
            src="../../../assets/images/ico-check.svg" />

          <img
            *ngIf="isPrevious(element[colDef.label])"
            class="icon-check"
            src="../../../assets/images/ICO-check-disable.svg" />

          <img
            *ngIf="isNext(element[colDef.label])"
            class="icon-check"
            src="../../../assets/images/ico-check.svg" />
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      class="row-color"
      [class.brand]="i % 2 !== 0"
      *matRowDef="let element; columns: displayedColumns; let i = index"></tr>
  </table>
</div>
