import { Component, Input, OnInit } from '@angular/core';
import { CardData } from 'src/app/interfaces/card-color.interface';

@Component({
  selector: 'app-cards-color',
  templateUrl: './cards-color.component.html',
  styleUrls: ['./cards-color.component.scss'],
})
export class CardsColorComponent implements OnInit {
  @Input() cardDataArr: CardData[] = [];
  constructor() {}

  ngOnInit(): void {}
}
