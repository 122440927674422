import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import CardModule from 'src/app/interfaces/genericInterfaces/cardModule.interface';
import { AuthService } from 'src/app/services/authorization/auth.service';
import { PermissionService } from 'src/app/services/authorization/permission.service';
import { SharedinfoService } from 'src/app/services/generic/sharedinfo.service';

@Component({
  selector: 'app-ka-allocation',
  templateUrl: './ka-allocation.component.html',
  styleUrls: ['./ka-allocation.component.scss'],
})
export class KaAllocationComponent implements OnInit {
  isAuthorized: any;
  userPermission: any;
  itemsList: CardModule[] = [];
  pages: any[] = [
    {
      name: 'KA Management',
      url: '../../../assets/images/ico-ka-management.svg',
      path: 'ka-management',
    },
    {
      name: 'KA Allocation',
      url: '../../../assets/images/ico-ka-allocation-ka-allocation.svg',
      path: 'ka-allocation',
    },
    {
      name: 'Execution',
      url: '../../../assets/images/ico-ka-execution.svg',
      path: 'ka-execution',
    },
    // TODO: Hidden for homologation
    // Ronaldo Reis - 19-05-2023
    {
      name: 'KA Info',
      url: '../../../assets/images/ico-ka-allocation-ka-info.svg',
      path: 'ka-info',
    },
    {
      name: 'Fair Distribution',
      url: '../../../assets/images/ico-fair-distribution.svg',
      path: 'fair-distribution',
    },
    // {
    //   name: 'Carriers Info', title: 'ka info', url: '../../../assets/images/ico-ka-info.svg', path: 'carries-info',
    // },
    // { name: 'Brazil Demands', url: '../../../assets/images/ico-br-demands.svg', path: 'brazil-demand' },
    // { name: 'Lom', url: '../../../assets/images/ico-lom.svg', path: 'lom' },
    //{ name: 'Ka Allocation Upload', url: '../../../assets/images/ico-ka-allocation-upload.svg', path: 'ka-allocation-upload' },
    {
      name: 'Supply Summary',
      url: '../../../assets/images/ico-supply-summary.svg',
      path: 'supply-summary',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionService: PermissionService,
    private sharedInfoService: SharedinfoService
  ) {
    this.isAuthorized = this.authService.isLoggedIn();
    this.userPermission = this.authService.getUserPermissions();
  }

  ngOnInit() {
    if (this.isAuthorized && this.userPermission.length > 0) {
      this.sharedInfoService.getPermissionStorage().subscribe(() => {
        this.itemsList = this.permissionService.enablePagesByPermissions(
          'KA ALLOCATION',
          this.pages
        );
      });
    } else {
      this.router.navigate(['home']);
    }
  }

  goTo(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  backButton() {
    this.router.navigate(['home']);
  }
}
