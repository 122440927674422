<table #tableHtml [class]="template" id="table-line-input">
    <thead>
        <tr>
            <th *ngFor="let head of header">
                <div class="th-relative-fixed" [class]="head.align">
                    <span [style.opacity]="translateYAbsoluteItem > 0 ? 0 : 1" class="item-head">
                        {{head.label}}
                    </span>
                    <span
                        *ngIf="translateYAbsoluteItem > 0"
                        [style.opacity]="translateYAbsoluteItem < 0 ? 0 : 1"
                        [style.transform]="'translateY('+translateYAbsoluteItem+'px)'"
                        class="item-head absoluteItem">
                        {{head.label}}
                    </span>
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of rows">
            <td *ngFor="let head of header" [class]="head.align">
                <ng-template [ngIf]="head.input !== true">
                    <span [class.negative]="row[head.value] < 0">
                        <app-value-currency [value]="row[head.value]"></app-value-currency>
                    </span>
                </ng-template>
                <ng-template [ngIf]="head.input === true">
                    <input
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        [value]="row[head.value] || '0'
                        | convertValue
                        | number: '1.0-0':'en-US'"
                        maxlength="9"
                        autocomplete="off"
                        matInput
                        class="input-number"
                        (keyup)="onChangeEvent({row: row, field: head.value}, $event)"
                    />
                </ng-template>
            </td>
        </tr>
    </tbody>
</table>
