import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { ShareComponentsModule } from 'src/app/components/share.components.module';
import { ShareDirectiviesModule } from 'src/app/directives/share.directivies.module';
import { UploadOnePlanComponent } from './upload-one-plan/upload-one-plan.component';
import { OnePlanPrincipalComponent } from './one-plan.component';
import { OnePlanVariationComponent } from './one-plan-variation/one-plan-variation.component';
import { OnePlanComponent } from './one-plan/one-plan.component';

@NgModule({
  declarations: [
    OnePlanPrincipalComponent,
    UploadOnePlanComponent,
    OnePlanVariationComponent,
    OnePlanComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ShareDirectiviesModule,
    ShareComponentsModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    CurrencyMaskModule,
  ],
  providers: [],
  bootstrap: [],
})
export class OnePlanModule {}
