import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  DataCard,
  DataChart,
  DataInput,
} from 'src/app/interfaces/demand-vs-mls.interface';

@Component({
  selector: 'app-column-marker-chart',
  templateUrl: './column-marker-chart.component.html',
  styleUrls: ['./column-marker-chart.component.scss'],
})
export class ColumnMarkerChartComponent implements OnInit {
  dataChart: any;
  dataTitleQuarter: string = '';
  dataTitleMonth01: string = '';
  dataTitleMonth02: string = '';
  dataTitleMonth03: string = '';
  dataChartQuarter: Array<DataChart> = [];
  dataChartMonth01: Array<DataChart> = [];
  dataChartMonth02: Array<DataChart> = [];
  dataChartMonth03: Array<DataChart> = [];
  dataCardQuarter: Array<DataCard> = [];
  dataCardMonth01: Array<DataCard> = [];
  dataCardMonth02: Array<DataCard> = [];
  dataCardMonth03: Array<DataCard> = [];

  dataFormatter!: any;

  lengthZoom: Array<number> = [];
  lengthZoomMedia: Array<number> = [];

  optionMonth01!: any;
  optionMonth02!: any;
  optionMonth03!: any;
  optionQuarter!: any;

  @Input() dataColumn!: DataInput;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataColumn.currentValue) {
      this.getDataChart(this.dataColumn);
    }
  }

  restartValues() {
    this.dataChartQuarter = [];
    this.dataChartMonth01 = [];
    this.dataChartMonth02 = [];
    this.dataChartMonth03 = [];
    this.dataCardQuarter = [];
    this.dataCardMonth01 = [];
    this.dataCardMonth02 = [];
    this.dataCardMonth03 = [];
    this.optionMonth01 = '';
    this.optionMonth02 = '';
    this.optionMonth03 = '';
    this.optionQuarter = '';
    this.lengthZoom = [];
    this.lengthZoomMedia = [];
  }

  getDataChart(data: any) {
    this.restartValues();

    Object.entries(data).forEach((item: any) => {
      if (item[1].source[0].values.length > 14) {
        this.lengthZoom.push(14);
      } else {
        this.lengthZoom.push(100);
      }
      if (item[1].source[0].values.length > 9) {
        this.lengthZoomMedia.push(9);
      } else {
        this.lengthZoomMedia.push(100);
      }

      this.dataTitleMonth01 = item[1].source[0].month;
      this.dataTitleMonth02 = item[1].source[1].month;
      this.dataTitleMonth03 = item[1].source[2].month;
      this.dataTitleQuarter = item[1].source[3].month;

      item[1].source[0].values.forEach((data: any) => {
        if (data.product) {
          this.dataChartMonth01.push(data);
        } else {
          this.dataCardMonth01.push(data);
        }
      });

      item[1].source[1].values.forEach((data: any) => {
        if (data.product) {
          this.dataChartMonth02.push(data);
        } else {
          this.dataCardMonth02.push(data);
        }
      });

      item[1].source[2].values.forEach((data: any) => {
        if (data.product) {
          this.dataChartMonth03.push(data);
        } else {
          this.dataCardMonth03.push(data);
        }
      });

      item[1].source[3].values.forEach((data: any) => {
        if (data.product) {
          this.dataChartQuarter.push(data);
        } else {
          this.dataCardQuarter.push(data);
        }
      });
    });

    this.optionMonth01 = this.getData(
      this.dataChartMonth01,
      this.dataTitleMonth01
    );
    this.optionMonth02 = this.getData(
      this.dataChartMonth02,
      this.dataTitleMonth02
    );
    this.optionMonth03 = this.getData(
      this.dataChartMonth03,
      this.dataTitleMonth03
    );
    this.optionQuarter = this.getData(
      this.dataChartQuarter,
      this.dataTitleQuarter
    );

    this.dataChart = [
      {
        option: this.optionMonth01,
        title: this.dataTitleMonth01,
        card: this.dataCardMonth01,
      },
      {
        option: this.optionMonth02,
        title: this.dataTitleMonth02,
        card: this.dataCardMonth02,
      },
      {
        option: this.optionMonth03,
        title: this.dataTitleMonth03,
        card: this.dataCardMonth03,
      },
      {
        option: this.optionQuarter,
        title: this.dataTitleQuarter,
        card: this.dataCardQuarter,
      },
    ];
  }

  valueCard(item: number) {
    return item.toLocaleString('en-GB');
  }

  getData(dataChart: Array<DataChart>, month: string) {
    const dataChartProduct: any[] = [];
    const dataChartColor: any[] = [];
    const dataChartsBrDemand: any[] = [];
    const dataChartsMlsJune: any[] = [];

    Object.entries(dataChart).forEach((data: any) => {
      dataChartProduct.push(data[1].product);
      dataChartColor.push(data[1].color);
      dataChartsBrDemand.push(data[1].brDemand);
      dataChartsMlsJune.push(data[1].mls);
    });

    return this.createChart(
      dataChartProduct,
      dataChartColor,
      dataChartsBrDemand,
      dataChartsMlsJune,
      month
    );
  }

  createChart(
    dataProduct: Array<string>,
    dataColor: Array<string>,
    dataBrDemand: Array<number>,
    dataMlsJune: Array<number>,
    month: string
  ) {
    return {
      grid: {
        left: '4%',
        right: '4%',
        top: 12,
      },
      legend: {
        data: ['BR Demand', `MLS ${month}`],
        selectedMode: false,
        icon: 'circle',
        itemWidth: 8.5,
        left: '1%',
        bottom: '-2%',
        textStyle: {
          fontSize: 8,
          fontFamily: 'roboto',
        },
      },
      tooltip: {
        borderColor: '#fff',
        textStyle: {
          fontSize: 12,
          fontFamily: 'roboto',
          color: '#303030',
        },
      },
      xAxis: [
        {
          type: 'category',
          data: dataColor,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
            },
          },
          position: 'bottom',
          axisLabel: {
            interval: 0,
            margin: 20,
            fontSize: 8,
            color: '#303030',
            fontFamily: 'roboto',
          },
        },
        {
          type: 'category',
          data: dataProduct,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
            },
          },
          position: 'bottom',
          axisLabel: {
            interval: 0,
            margin: 10,
            fontSize: 8,
            color: '#303030',
            fontFamily: 'roboto',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitNumber: 4,
          axisLabel: {
            formatter: function (value: number) {
              return value.toLocaleString('en-GB');
            },
            fontSize: 8,
            fontFamily: 'roboto',
            color: '#303030',
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
            },
          },
          splitLine: {
            show: false,
          },
        },
      ],
      dataZoom: [
        {
          show: true,
          brushSelect: false,
          showDataShadow: false,
          height: 12,
          bottom: '11%',
          minSpan: this.lengthZoom[0],
          maxSpan: this.lengthZoom[0],
          textStyle: {
            fontSize: 0,
          },
        },
        {
          type: 'inside',
          zoomOnMouseWheel: false,
          minSpan: this.lengthZoom[0],
          maxSpan: this.lengthZoom[0],
        },
        {
          show: true,
          handleIcon: 'pin',
          brushSelect: false,
          yAxisIndex: 0,
          filterMode: 'empty',
          width: 12,
          height: '52%',
          showDataShadow: false,
          left: '97%',
          top: 25,
          textStyle: {
            fontSize: 10,
          },
          labelFormatter: function (value: number) {
            return value.toLocaleString('en-GB');
          },
        },
      ],
      series: [
        {
          name: 'BR Demand',
          type: 'bar',
          barWidth: 16,
          data: dataBrDemand,
          color: '#7D859A',
          tooltip: {
            valueFormatter: function (value: number) {
              return value.toLocaleString('en-GB');
            },
          },
        },
        {
          name: `MLS ${month}`,
          type: 'bar',
          barWidth: 16,
          data: dataMlsJune,
          color: '#39E6F3',
          tooltip: {
            valueFormatter: function (value: number) {
              return value.toLocaleString('en-GB');
            },
          },
        },
        {
          type: 'line',
          data: dataMlsJune,
          lineStyle: {
            color: '#ff5e00',
            width: 2,
          },
          color: '#ff5e00',
          tooltip: {
            valueFormatter: function (value: number) {
              return value.toLocaleString('en-GB');
            },
          },
        },
      ],
      media: [
        {
          query: {
            maxWidth: 1100,
          },
          option: {
            grid: {
              left: '5%',
            },
            dataZoom: [
              {
                show: true,
                showDataShadow: false,
                height: 12,
                bottom: '11%',
                minSpan: this.lengthZoomMedia[0],
                maxSpan: this.lengthZoomMedia[0],
              },
              {
                type: 'inside',
                zoomOnMouseWheel: false,
                minSpan: this.lengthZoomMedia[0],
                maxSpan: this.lengthZoomMedia[0],
              },
              {
                show: true,
                handleIcon: 'pin',
                yAxisIndex: 0,
                filterMode: 'empty',
                width: 12,
                height: '55%',
                showDataShadow: false,
                left: '97%',
                top: 20,
                textStyle: {
                  fontSize: 10,
                },
              },
            ],
          },
        },
      ],
    };
  }
}
