import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  ElementRef,
  SimpleChanges,
  ViewChild,
  HostListener,
  OnInit,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { CONSTS } from 'src/assets/constants/consts';

@Component({
  selector: 'app-table-manager-market-name',
  templateUrl: './table-manager-market-name.component.html',
  styleUrls: ['./table-manager-market-name.component.scss'],
})
export class TableManagerMarketNameComponent implements OnInit {
  @Input() dataSource!: any;
  @Input() keyDataSource!: number;
  @Input() eventTable!: string;
  @Input() showSubtotalTitle!: any;
  @Input() valuesSubTotal!: any;
  @Output('toggleTable') toggleTableEvent = new EventEmitter();
  @Output('inputValueChangedEvent') inputValueChangedEvent = new EventEmitter();
  @Output('onBlurInput') onBlurInputEvent = new EventEmitter();
  @Output() valueIdentified = new EventEmitter<any>();

  @ViewChild('tableDynamic') tableDynamic!: ElementRef<HTMLInputElement>;
  headers: any = [];
  content: any = [];
  displayedColumns: any = [];
  displayedContent: any = [];
  translateYAbsoluteItem: number = 0;
  offsetTopSubTotal: number = 0;
  hasExpanded: boolean = false;
  hasRowsExpanded: boolean = false;
  keyContent = 'summarized';
  columnsNames: string[] = [];

  valuesMlsTotalAllocation: { col: string; value: number }[] = [];
  valuesMLSsummarized: { col: string; value: number }[] = [];
  valuesDeltaSummarized: { col: string; value: number }[] = [];
  valuesSubstractionSummarized: { col: string; value: number }[] = [];
  valuesSubTotalSummarized: any = [{}];
  valuesTotalAllocation: { col: string; value: number }[] = [];

  valuesMLSexpanded: { col: string; value: number; origin: string }[] = [];
  valuesSubstractionExpanded: { col: string; value: number; origin: string }[] =
    [];
  valuesMlsTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  valuesDeltaExpanded: { col: string; value: number; origin: string }[] = [];
  valuesTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  copyValuesSubMlsAndTotalAllocationExpanded: {
    col: string;
    value: number;
    origin: string;
  }[] = [];
  formControlsArray!: FormArray[];
  isSubTotalEmpty: boolean = true;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.dataSource = changes.dataSource.currentValue;
      this.headers = this.dataSource.headers;
      this.content = this.dataSource.content;
      this.formControlsArray = this.dataSource.formControlsArray;
      this.displayedColumns = [];
      this.displayedContent = [];

      if (!changes.dataSource.currentValue.displayedColumns) {
        this.headers.forEach((header: any) => {
          if (header.props === header.otherContent || header.alwaysVisible) {
            this.displayedColumns.push(header);
            this.columnsNames.push(header.props);
          }
        });
      } else {
        this.displayedColumns =
          changes.dataSource.currentValue.displayedColumns;
        this.columnsNames = this.displayedColumns.map(
          (header: any) => header.props
        );
      }
    }

    if (changes.eventTable.currentValue) {
      if (changes.eventTable.currentValue.includes('show-columns-rows')) {
        this.keyContent = 'expanded';
        this.hasRowsExpanded = true;
      } else if (
        changes.eventTable.currentValue.includes('hide-columns-rows')
      ) {
        this.keyContent = 'summarized';
        this.hasRowsExpanded = false;
      }
    }
    this.displayedContent = JSON.parse(JSON.stringify(this.content));
    
    this.updateContent(this.columnsNames);

    if (this.valuesSubTotal.length > 0) {
      this.isSubTotalEmpty = false;
    } else {
      this.isSubTotalEmpty = true;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setTableScroll();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.calculateTranslateYAbsoluteItem();
  }

  setTableScroll() {
    const scrollContainer = this.tableDynamic.nativeElement;

    if (this.dataSource.hasScroll) {
      scrollContainer.addEventListener('wheel', (evt: any) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    } else {
      scrollContainer.removeEventListener('wheel', (evt: any) => {
        evt.preventDefault();
      });
    }
  }

  identifyValueNetOpen(rowValue: any, column: any) {
    if (column === 'netOpenPo') {
      this.valueIdentified.emit({ rowValue, column });
    }
  }

  calculateTranslateYAbsoluteItem() {
    const appHeaderHeight =
      document.getElementsByClassName('navbar')[0]?.clientHeight;
    const breadCrumbHeight = document.getElementsByClassName(
      'xng-breadcrumb-root'
    )[0].clientHeight;
    const clientHeightTop =
      appHeaderHeight + breadCrumbHeight;

    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const offsetTopElement =
      this.tableDynamic.nativeElement.offsetTop + clientHeightTop;
      
    if (verticalOffset >= offsetTopElement) {
      this.translateYAbsoluteItem = verticalOffset - offsetTopElement;
    } else {
      this.translateYAbsoluteItem = 0;
    }
  }

  updateContent(columnsNames: any[]) {
    this.displayedContent = this.displayedContent.map((content: any) => {
      if (content[this.keyContent]) {
        content[this.keyContent].forEach((element: any) => {
          element.rows = element.rows.map((item: any) => {
            const row = { ...item };
            row.value = {};
            this.displayedColumns.forEach((column: any) => {
              if (!columnsNames.includes(column.props)) {
                columnsNames.push(column.props);
              }
              row.value[column.props] = item.value[column.props];
            });
            return row;
          });
        });
      }
      return content;
    });
  }

  mouseEnter(indexRow: string, origin: string) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow + origin;

    tableRows.forEach((row: any) => {
      if (this.hasRowsExpanded) {
        if (row.id == rowId) {
          row.classList.add('hover-row');
        }
      } else {
        if (row.id == indexRow) {
          row.classList.add('hover-row');
        }
      }
    });
    this.cdr.detectChanges();
  }

  mouseLeave(indexRow: string, origin: string) {
    const tableRows = document.querySelectorAll('tr');
    const rowId = indexRow + origin;

    tableRows.forEach((row: any) => {
      if (this.hasRowsExpanded) {
        if (row.id == rowId) {
          row.classList.remove('hover-row');
        }
      } else {
        if (row.id == indexRow) {
          row.classList.remove('hover-row');
        }
      }
    });
    this.cdr.detectChanges();
  }

  toggleColumnTable(col: any, indexCol: number, event: string) {
    const columnsAlwaysVisible: any[] = [];
    const groupColumnsToShowHide = this.headers.filter((item: any) => {
      if (item.alwaysVisible) {
        columnsAlwaysVisible.push(item);
      }
      if (item.otherContent === col.otherContent || item.alwaysVisible) {
        item.expanded = true;
        return item;
      }
      return false;
    });

    groupColumnsToShowHide.shift();
    this.columnsNames = [];

    if (event.includes('show')) {
      col.expanded = true;
      this.displayedColumns.splice(indexCol + 1, 0, ...groupColumnsToShowHide);
    } else if (event.includes('hide')) {
      col.expanded = false;
      this.displayedColumns.splice(indexCol + 1, groupColumnsToShowHide.length);
      this.displayedColumns.splice(indexCol + 1, 0, ...columnsAlwaysVisible);
    }

    if (event === 'show-columns-rows') {
      this.hasRowsExpanded = true;
    } else if (event === 'hide-columns-rows') {
      this.hasRowsExpanded = false;
    }

    if (this.hasRowsExpanded) {
      this.keyContent = 'expanded';
    } else {
      this.keyContent = 'summarized';
    }

    this.displayedColumns = Array.from(new Set(this.displayedColumns));
    this.displayedContent = JSON.parse(JSON.stringify(this.content));
    this.updateContent(this.columnsNames);

    if (this.headers.filter((obj: any) => obj.expanded === true).length) {
      this.hasExpanded = true;
    } else {
      this.hasExpanded = false;
    }
    this.setTableScroll();

    this.toggleTableEvent.emit(event);
  }

  onChangeValueInput(
    item: {
      columnName: any;
      value: any;
      row: any;
      indexRow: number;
      content: any;
      indexContent: number;
      keyContent: any;
      indexGroup: number;
    },
    event: any
  ) {
    const valueInput: number = event.target.value
      ? event.target.value.replaceAll(',', '')
      : 0;
    const columnNameMonth = item.columnName.split('wk')[0];
    const columnsNamesWeeks = this.columnsNames.filter((column: string) =>
      column.includes(columnNameMonth)
    );
    columnsNamesWeeks.shift();

    const subtotalWeek: any = { 0: 0, 1: 0 };
    let subtotalWeekCustomer = 0;
    let subtotalMonth = 0;
    let totalMonth: any = { 0: 0, 1: 0 };

    item.value.value = valueInput;
    item.value.edit = true;
    item.row.value[item.columnName] = item.value;
    item.content[item.keyContent][item.indexGroup].rows[item.indexRow].value[
      item.columnName
    ].value = valueInput;

    item.content[item.keyContent].forEach(
      (content: any, indexContent: number) => {
        content.rows.forEach((row: any) => {
          if (!row.enableATS && !row.enableMLS) {
            const rowValue = parseFloat(row.value[item.columnName].value) || 0;
            subtotalWeek[indexContent] += rowValue;

            subtotalMonth = 0;
            columnsNamesWeeks.forEach((columnName: any) => {
              subtotalMonth += Number(row.value[columnName].value);
            });
            row.value[columnNameMonth].value = subtotalMonth;
            totalMonth[indexContent] += subtotalMonth;

            if (
              row.value[item.columnName].customerShortId ===
              item.value.customerShortId
            ) {
              subtotalWeekCustomer += rowValue;
            }
          }
        });
        return content;
      }
    );
    
    this.displayedContent[item.indexContent][item.keyContent][item.indexGroup].
      subtotal[item.columnName] = subtotalWeek[item.indexGroup];

    this.inputValueChangedEvent.emit({
      indexTable: this.keyDataSource,
      indexContent: item.indexContent,
      indexGroup: item.indexGroup,
      content: item.content,
      statusTable: item.keyContent,
      columnNameWeek: item.columnName,
      columnNameMonth,
    });
    this.cdr.detectChanges();

    const idPpm = item.content.idPpm;
    this.startingUpdateTableValuesSummarized(item.indexContent, idPpm);
    this.startingUpdateTableValuesExpanded(item.indexContent, item.indexGroup, idPpm);
  }

  startingUpdateTableValuesSummarized(indexContent: number, idPpm: number): void {
    this.valuesMlsTotalAllocation = [];

    Object.entries(
      this.content[indexContent].summarized[0].rows[CONSTS.INDEX_MLS].value
    ).forEach((row: any) => {
      this.headers.forEach((header: any) => {
        if (header.weekInTime === 'current' || header.weekInTime === 'next') {
          if (row[0] === header.props) {
            const hasColumnInvaluesMLS = this.valuesMLSsummarized.filter(
              (v: any) => v.col === header.props
            );
            if (!hasColumnInvaluesMLS.length)
              this.valuesMLSsummarized.push({
                col: row[0],
                value: parseFloat(row[1].value),
              });
            this.valuesMLSsummarized.forEach((key: any) => {
              if (key.col === row[0]) {
                key.col = row[0];
                key.value = parseFloat(row[1].value);
              }
            });

            const hasColumnInValuesSubstraction =
              this.valuesSubstractionSummarized.filter(
                (v: any) => v.col == row[0]
              );
            if (!hasColumnInValuesSubstraction.length)
              this.valuesSubstractionSummarized.push({
                col: row[0],
                value: 0,
              });
            this.valuesSubstractionSummarized.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = parseFloat(row[1].value);
              }
            });

            const hasColumnInValuesDelta = this.valuesDeltaSummarized.filter(
              (v: any) => v.col == row[0]
            );
            if (!hasColumnInValuesDelta.length)
              this.valuesDeltaSummarized.push({ col: row[0], value: 0 });
            this.valuesDeltaSummarized.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = 0;
              }
            });

            const hasColumnInValuesTotalAllocation =
              this.valuesTotalAllocation.filter((v: any) => v.col == row[0]);
            if (!hasColumnInValuesTotalAllocation.length)
              this.valuesTotalAllocation.push({ col: row[0], value: 0 });
            this.valuesTotalAllocation.forEach((key: any) => {
              if (key.col == row[0]) {
                key.col = row[0];
                key.value = 0;
              }
            });
          }
        }
      });
    });

    /* Armazenando o total de alocações em um array de referência */

    for (
      let i = 0;
      i < this.content[indexContent].summarized[0].rows.length;
      i++
    ) {
      this.headers.forEach((header: any) => {
        if (
          header.currentMonth ||
          header.weekInTime === 'current' ||
          header.weekInTime === 'next'
        ) {
          Object.entries(
            this.content[indexContent].summarized[0].rows[i].value
          ).forEach((row: any) => {
            if (
              row[1].customerShort !== 'ATS' &&
              row[1].customerShort !== 'MLS'
            ) {
              if (row[0] === header.props) {
                const hasColumn = this.valuesMlsTotalAllocation.filter(
                  (v: any) => v.col === header.props
                );
                if (!hasColumn.length)
                  this.valuesMlsTotalAllocation.push({
                    col: row[0],
                    value: 0,
                  });
                this.valuesMlsTotalAllocation.forEach((key: any) => {
                  if (key.col === row[0]) {
                    key.col = row[0];
                    key.value += parseFloat(row[1].value);
                  }
                });
              }
            }
          });
        }
      });
    }

    this.valuesMlsTotalAllocation.forEach((key: any) => {
      this.valuesTotalAllocation.forEach(value => {
        if (key.col === value.col) value.value = key.value;
      });
    });

    /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    Object.entries(
      this.content[indexContent].summarized[0].rows[CONSTS.INDEX_MLS].value
    ).forEach((row: any) => {
      this.valuesMlsTotalAllocation.forEach((key: any) => {
        if (key.col === row[0]) {
          key.value = parseFloat(row[1].value) - parseFloat(key.value);
        }
      });
    });

    let acumulativeDelta: any = 0;

    this.valuesMlsTotalAllocation.forEach((key: any) => {
      this.valuesSubstractionSummarized.forEach(value => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    const valuesSubTotalOnlyProduct = this.valuesSubTotal.find((item: any) => item.idPpm === idPpm);

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */

    for (let i = 0; i < this.valuesSubstractionSummarized.length; i++) {
      if (this.isSubTotalEmpty) {
        acumulativeDelta =
          this.valuesSubstractionSummarized[i]?.value +
          this.valuesMLSsummarized[i + 1]?.value;
      } else {
        acumulativeDelta =
          this.valuesSubstractionSummarized[i]?.value -
          valuesSubTotalOnlyProduct.summarized[this.valuesSubstractionSummarized[i]?.col]
            ?.value +
          this.valuesMLSsummarized[i + 1]?.value;
      }

      this.valuesSubstractionSummarized.forEach(
        (row: any, indexRow: number) => {
          if (indexRow === i + 1)
            row.value =
              parseFloat(acumulativeDelta) -
              this.valuesTotalAllocation[i + 1]?.value;
        }
      );
    }

    if (!this.isSubTotalEmpty) {
      for (let i = 0; i < this.valuesSubstractionSummarized.length; i++) {
        this.valuesSubstractionSummarized[i].value =
          Number(this.valuesSubstractionSummarized[i].value) -
          Number(
            valuesSubTotalOnlyProduct.summarized[this.valuesSubstractionSummarized[i]?.col]
              ?.value
          );
      }
    }

    this.valuesSubstractionSummarized.forEach((key: any) => {
      this.valuesDeltaSummarized.forEach((value: any) => {
        if (key.col === value.col) {
          value.value = key.value;
        }
      });
    });

    /* Adicionando na tabela summarized os valores de ATS */
    Object.entries(
      this.displayedContent[indexContent].summarized[0].rows[CONSTS.INDEX_ATS]
        .value
    ).forEach((row: any) => {
      this.valuesDeltaSummarized.forEach((key: any) => {
        if (key.col === row[0]) {
          row[1].value = parseFloat(key.value);
        }
      });
    });

    let valuesTotalATS: { col: string; value: number }[] = [];

    /* Atualizando os valores de Sub Total no Mês atual */
    this.headers.forEach((header: any) => {
      Object.entries(
        this.displayedContent[indexContent].summarized[0].rows[CONSTS.INDEX_ATS]
          .value
      ).forEach((row: any) => {
        if (header?.props.includes('wk')) {
          if (row[0] === header.props) {
            const column = row[0].split('wk');

            const hasColumnInValuesTotalATS = valuesTotalATS.findIndex(
              v => v.col === column[0]
            );

            if (hasColumnInValuesTotalATS === -1)
              valuesTotalATS.push({
                col: column[0],
                value: Number(row[1].value),
              });
            valuesTotalATS.forEach((key: any) => {
              if (key.col == column[0]) {
                key.col = column[0];
                key.value = Number(row[1].value);
              }
            });
          }
        }
      });
    });

    Object.entries(
      this.displayedContent[indexContent].summarized[0].rows[CONSTS.INDEX_ATS]
        .value
    ).forEach((row: any) => {
      valuesTotalATS.forEach((key: any) => {
        if (row[0] === key.col) {
          row[1].value = key.value;
        }
      });
    });
  }

  startingUpdateTableValuesExpanded(indexContent: number, indexGroup: number, idPpm: number): void {
    this.valuesSubstractionExpanded = [];
    this.copyValuesSubMlsAndTotalAllocationExpanded = [];
    this.valuesTotalAllocationExpanded = [];
    this.valuesMLSexpanded = [];
    this.valuesDeltaExpanded = [];

    // for (let i = 0; i < this.content[indexContent].expanded.length; i++) {
      Object.entries(this.content[indexContent].expanded[indexGroup].rows[CONSTS.INDEX_MLS].value).forEach(
        (row: any) => {
          this.headers.forEach((header: any) => {
            if (header.weekInTime === 'current' || header.weekInTime === 'next') {
              if (row[0] == header.props) {
                const hasColumnInSubMlsAndTotalAllocationExpanded =
                  this.copyValuesSubMlsAndTotalAllocationExpanded.filter(
                    (v: any) => v.col === header.props && v.origin === row[1].origin
                  );
                if (!hasColumnInSubMlsAndTotalAllocationExpanded.length)
                  this.copyValuesSubMlsAndTotalAllocationExpanded.push({
                    col: header.props,
                    value: Number(0),
                    origin: row[1].origin,
                  });

                const hasColumnInvaluesMLS = this.valuesMLSexpanded.filter(
                  (v: any) => v.col === header.props && v.origin === row[1].origin
                );
                if (!hasColumnInvaluesMLS.length)
                  this.valuesMLSexpanded.push({
                    col: header.props,
                    value: parseFloat(row[1].value),
                    origin: row[1].origin,
                  });
                this.valuesMLSexpanded.forEach((key: any) => {
                  if (key.col === header.props && key.origin === row[1].origin) {
                    key.value = parseFloat(row[1].value);
                  }
                });

                const hasColumn = this.valuesSubstractionExpanded.filter(
                  (v: any) => v.col === header.props && v.origin === row[1].origin
                );
                if (!hasColumn.length)
                  this.valuesSubstractionExpanded.push({
                    col: header.props,
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesSubstractionExpanded.forEach((key: any) => {
                  if (key.col === header.props && key.origin === row[1].origin) {
                    key.value = 0;
                  }
                });

                const hasColumnValuesDeltaExpanded =
                  this.valuesDeltaExpanded.filter(
                    (v: any) => v.col === header.props && v.origin === row[1].origin
                  );
                if (!hasColumnValuesDeltaExpanded.length)
                  this.valuesDeltaExpanded.push({
                    col: header.props,
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesDeltaExpanded.forEach((key: any) => {
                  if (key.col === header.props && key.origin === row[1].origin) {
                    key.value = 0;
                  }
                });

                if (!hasColumnValuesDeltaExpanded.length)
                  this.valuesTotalAllocationExpanded.push({
                    col: header.props,
                    value: 0,
                    origin: row[1].origin,
                  });
                this.valuesTotalAllocationExpanded.forEach((key: any) => {
                  if (key.col === header.props && key.origin === row[1].origin) {
                    key.value = 0;
                  }
                });
              }
            }
          });
        }
      );
    // }

    /* Armazenando o total de alocações em um array de referência */

    for (
      let i = 0;
      i < this.content[indexContent].expanded[indexGroup].rows.length;
      i++
    ) {
      this.headers.forEach((header: any) => {
        if (
          header.currentMonth ||
          header.weekInTime === 'current' ||
          header.weekInTime === 'next'
        ) {
          Object.entries(
            this.content[indexContent].expanded[indexGroup].rows[i].value
          ).forEach((row: any) => {
            if (
              row[1].customerShort !== 'ATS' &&
              row[1].customerShort !== 'MLS'
            ) {
              if (row[0] === header.props) {
                this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
                  if (key.col === row[0] && key.origin === row[1].origin) {
                    key.col = row[0];
                    key.value += parseFloat(row[1].value);
                  }
                });
              }
            }
          });
        }
      });
    }

    this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesTotalAllocationExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    // /* Subtraindo os valores alocados com os valores de MLS, gerando os valores originais de ATS */
    this.valuesMLSexpanded.forEach((row: any) => {
      this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
        if (key.col === row.col && key.origin === row.origin) {
          key.value = parseFloat(row.value) - parseFloat(key.value);
        }
      });
    });


    this.copyValuesSubMlsAndTotalAllocationExpanded.forEach((key: any) => {
      this.valuesSubstractionExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    const valuesSubTotalOnlyProduct = this.valuesSubTotal.find((item: any) => item.idPpm === idPpm);

    /* Acumulando os valores restantes de ATS + valores de MLS da próxima semana
     * O resultado acumulado é subtraído pelo valor Total Alocado na semana
     */
    let acumulativeDelta: any = 0;

    for (let i = 0; i < this.valuesSubstractionExpanded.length; i++) {
      if (
        this.valuesSubstractionExpanded[i]?.origin ===
        this.valuesMLSexpanded[i + 1]?.origin
      ) {
        if (this.isSubTotalEmpty) {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value
        } else {
          acumulativeDelta =
            this.valuesSubstractionExpanded[i]?.value +
            this.valuesMLSexpanded[i + 1]?.value -
            valuesSubTotalOnlyProduct.expanded[
              this.valuesSubstractionExpanded[i]?.origin
            ][this.valuesSubstractionExpanded[i]?.col].value;
        }

        this.valuesSubstractionExpanded.forEach(
          (row: any, indexRow: number) => {
            if (indexRow === i + 1) {
              row.value =
                parseFloat(acumulativeDelta) -
                this.valuesTotalAllocationExpanded[i + 1]?.value;
            }
          }
        );
      }
    }

    if (!this.isSubTotalEmpty) {
      Object.entries(valuesSubTotalOnlyProduct.expanded).forEach((subTotal: any) => {
        this.valuesSubstractionExpanded.forEach((delta: any) => {
          if (Object.keys(subTotal[1]).length !== 0) {
            if (delta.origin === subTotal[0]) {
              delta.value =
                parseFloat(delta.value) -
                parseFloat(subTotal[1][delta.col].value);
            }
          }
        });
      });
    }

    this.valuesSubstractionExpanded.forEach((key: any) => {
      this.valuesDeltaExpanded.forEach((value: any) => {
        if (key.col === value.col && key.origin === value.origin) {
          value.value = key.value;
        }
      });
    });

    /* Adicionando na tabela expanded os valores de ATS */
    this.displayedContent[indexContent].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(this.displayedContent[indexContent].expanded[indexRow]?.rows).forEach(
        (key: any) => {
          if (key[1].enableATS) {
            Object.entries(key[1].value).forEach((value: any) => {
              this.valuesDeltaExpanded.forEach((subTotal: any) => {
                if (
                  subTotal.col === value[0] &&
                  subTotal.origin === value[1]?.origin
                ) {
                  value[1].value = parseFloat(subTotal.value);
                }
              });
            });
          }
        }
      );
    });

    /* Atualizando os valores de Sub Total no Mês atual */

    let valuesExpandedTotalATS: {
      col: string;
      value: number;
      origin: string;
    }[] = [];

    this.valuesDeltaExpanded.forEach((subTotal: any) => {
      if (subTotal.origin) {
        const column = subTotal.col.split('wk');

        const hasColumnInValuesTotalATS = valuesExpandedTotalATS.filter(
          (v: any) => v.col == column[0] && v.origin === subTotal.origin
        );
        if (!hasColumnInValuesTotalATS.length)
          valuesExpandedTotalATS.push({
            col: column[0],
            value: Number(subTotal.value),
            origin: subTotal.origin,
          });
        valuesExpandedTotalATS.forEach((key: any) => {
          if (key.col == column[0] && key.origin === subTotal.origin) {
            key.col = column[0];
            key.value = Number(subTotal.value);
          }
        });
      }
    });

    this.displayedContent[indexContent].expanded.forEach((row: any, indexRow: number) => {
      Object.entries(this.displayedContent[indexContent].expanded[indexRow]?.rows).forEach(
        (key: any) => {
          if (key[1].enableATS) {
            Object.entries(key[1].value).forEach((value: any) => {
              valuesExpandedTotalATS.forEach((subTotal: any) => {
                if (
                  value[0] === subTotal.col &&
                  value[1].origin === subTotal.origin
                ) {
                  value[1].value = Number(subTotal.value);
                }
              });
            });
          }
        }
      );
    });
  }

  onBlurInput(
    refValue: {
      indexContent: number;
      indexGroup: number;
      indexRow: number;
      indexCol: string;
    },
    event: any
  ) {
    const columnNameMonth = refValue.indexCol.split('wk')[0];

    this.displayedContent[refValue.indexContent][this.keyContent][
      refValue.indexGroup
    ].rows[refValue.indexRow].value[refValue.indexCol].value = event.target
      .value
      ? event.target.value.replace(',', '')
      : 0;
    if (this.keyContent === 'summarized') {
      const item = {
        event,
        content: this.displayedContent[refValue.indexContent],
        value: {
          valueTyped: event.target.value
            ? event.target.value.replace(',', '')
            : 0,
          statusTable: this.keyContent,
          indexContent: refValue.indexContent,
          indexRow: refValue.indexRow,
          indexCol: refValue.indexCol,
          columnNameMonth,
          row: this.content[refValue.indexContent].summarized[0].rows[
            refValue.indexRow
          ],
          rowWeek:
            this.content[refValue.indexContent].summarized[0].rows[
              refValue.indexRow
            ].value[refValue.indexCol],
        },
        displayedColumns: this.displayedColumns,
      };

      this.onBlurInputEvent.emit(item);
    }
    // this.startingUpdateTableValuesSummarized(refValue.indexContent);
    // this.startingUpdateTableValuesExpanded();
  }

  updateValuesTable(params: any, item: any, valuesSubTotal: any) {
    for (const row of item.content.summarized[0].rows) {
      if (
        !row.enableATS &&
        !row.enableMLS &&
        row.value[item.value.indexCol].customerShortId ===
          params.customerShortId
      ) {
        row.value[item.value.indexCol].value = 0;
      }
    }

    item.content.summarized[0].subtotal = valuesSubTotal;

    const columnsNames = item.displayedColumns.map(
      (header: any) => header.props
    );

    const columnsNamesWeeks = columnsNames
      .filter((column: string) => column.includes(params.month))
      .slice(1);

    for (const row of item.content.summarized[0].rows) {
      if (!row.enableATS && !row.enableMLS) {
        let rowMonthSubtotal = 0;
        for (const columnNameWeek of columnsNamesWeeks) {
          rowMonthSubtotal += Number(row.value[columnNameWeek].value);
        }
        row.value[params.month].value = rowMonthSubtotal;
      }
    }
  }

  getColorAlert(key: any) {
    return key.toLocaleUpperCase().includes('OVERBOOKED');
  }

  showSubTotalValue(value: number, key: any) {
    return value;
  }

  getAddRowId(indexRow: number, indexContent: number, origin: string) {
    if (this.hasRowsExpanded) {
      return indexRow + '' + indexContent + origin;
    }
    return indexRow + '' + indexContent;
  }

  isCurrentWeek(labelTable: any) {
    if (labelTable.indexOf('wk') > -1) {
      return true;
    }
    return false;
  }

  isColumnCustomerShort(labelTable: any) {
    if (labelTable.indexOf('customer') > -1) {
      return true;
    }
    return false;
  }

  hasNetOpenPo(key: any, data: any) {
    if (key === 'netOpenPo') {
      if (data[key].flagged) {
        return true;
      }
      return false;
    }
    return false;
  }
}
